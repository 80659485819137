import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import './ScheduleMain.css';

import firebase from '../../../Firebase';

import PageHeader_t from '../../../Interfaces/PageHeader_t';
import Client_t from '../../../Interfaces/Client_t';
import Workout_t from '../../../Interfaces/Workout_t';

import PageHeader from '../../../components/Base/PageHeader';
import Calendar from '../../../components/Calendar/Calendar';
import AssignModalClientCell from '../../../components/Clients/AssignModalClientCell';


import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

function ScheduleMain(props: any) {

    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};
    let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];

    let pageOptions: PageHeader_t[] = [{ name:"Month", alert:false },
                                       { name:"Week", alert:false },
                                       { name:"Day", alert:false },
                                       { name:"List", alert:false },];

    const [initSet, setInitSet] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    const [searchString, setSearchString] = useState("");
    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
    const [imgUrl, setImgUrl] = useState('');
    const [clients, setClients] = useState<Client_t[]>([]);
    const [allClients, setAllClients] = useState<Client_t[]>([]);
    const [showClientList, setShowClientList] = useState(false);
    const [clientNameFocus, setClientNameFocus] = useState(false);

    const [isCreatingProgram, setIsCreatingProgram] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getPersonalClients();
    }

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    function getPersonalClients() {
        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }


        /*************************************************/
        //setIsLoading(true);    // TODO; uncomment

        let testClient: Client_t = {
            first_name:"Connor", 
            last_name:"Holowachuk", 
            full_name:"Connor Holowachuk", 
            id: "abc123", 
            gender: "m",
            email: "connor_chuk@yahoo.com",
            weight: 155,
            height: 72,
            isMetric: false,
            birthdate: "00-00-0000",
            tags: [goalDescriptors[0], "Gain Muscle"],
            isSelected: true
        };

        //setSelectedClient(testClient);



        /*************************************************/

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_clients/${thisUser.uid}/clients`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let clientsList = snapshot.val();
                let clientListLength = Object.keys(clientsList).length;
                let temp: Client_t[] = [];
                for (const key of Object.keys(clientsList)) {
                    let thisClient = clientsList[key];

                    let thisClientRef = database.ref(`user_profile/${key}`)
                    thisClientRef.on('value', function(snapshotClient) {
                        if (snapshotClient.exists() === true) {
                            let thisUser = snapshotClient.val();
                            let newClient: Client_t = {
                                first_name:thisUser.first_name, 
                                last_name:thisUser.last_name, 
                                full_name:thisUser.full_name, 
                                id: key, 
                                gender: thisUser.gender,
                                email: thisUser.email,
                                weight: thisUser.weight,
                                height: thisUser.height,
                                isMetric: thisUser.units_kg,
                                birthdate: thisUser.birth_date,
                                tags: [goalDescriptors[0], "Gain Muscle"],
                                isSelected: false
                            };
                            temp.push(newClient);

                            if (temp.length === clientListLength) {
                                // all workouts have been pulled - set the state vars to temp
                                setClients(clients.concat(temp));
                                setAllClients(allClients.concat(temp));
                                //setIsLoading(false);

                            }
                        }
                    });

                }

            } else {

                //setIsLoading(false);
            }
        });
    }

    function clientSelected(c: Client_t) {
        setShowClientList(false);
        setSelectedClient(c);
        getImage(c.id);
        setSearchString("");
        //pullClientFolders(c.id);
    }

    function getImage(forID: string) {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function inputChanged(toString: any, forInput: string) {
        switch (forInput) {
            case 'client-name':
                setSearchString(toString);
                var temp = [];
                for (var i = 0; i < allClients.length; i++) {
                    let searchString = toString.toUpperCase();
                    let nameString = allClients[i].full_name.toUpperCase();
                    let positiveSearch_name = nameString.includes(searchString);

                    var positiveSearch_email = false;
                    let emailString = allClients[i].email;
                    if (emailString != undefined && emailString != null) {

                    }

                    if (positiveSearch_name === true) {
                        temp.push(allClients[i]);
                    }
                }

                setClients(temp);
                if (toString.split('').length > 2) {
                    if (showClientList === false) {
                        setShowClientList(true);
                    }
                }
                break;
            // case 'default-duration':
            //     if (!isNaN(toString)) {
            //         setDefaultDurationDays(toString as number);
            //         updateDateRangeByDuration(toString as number);
            //         setShowSetDurationButton(false);
            //     }
            //     break;
            default:
                break;
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            searchButtonPressed()
        }
    }

     function searchButtonPressed() {

    }

    function toggleShowClientList() {
        setShowClientList(!showClientList);
    }

    function removeClient() {
        setSelectedClient(initClient);
    }

    window.addEventListener('click', function(e){   
        checkFilterClientsMenu(e);
    });

    function checkFilterClientsMenu(e: any) {
        if (document === null || document.getElementById('schedule-client-filter-menu') === null || document.getElementById('schedule-client-filter-button') === null || e.target === null) { return; }
        if (document.getElementById('schedule-client-filter-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('schedule-client-filter-button')!.contains(e.target as Node))) {
                if (showClientList === true) {
                    setShowClientList(false);
                }
            }
        }
    }

	return (
		<div className="schedule-main">
            <div className="home-header">
                <PageHeader 
                    pageName="Schedule" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.addNewWorkout !== undefined ? props.addNewWorkout() : null}
                    addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
                    addNewClient={() => props.addNewClient !== undefined ? props.addNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
			<div className="schedule-main-calendar-header">
                <div className="page-title-header-container">
                    <h1>Schedule</h1>
                </div>
                <div hidden={isCreatingProgram} className="schedule-main-calendar-header-filter-container">
                    <div id="schedule-client-filter-button" onClick={() => toggleShowClientList()} className="schedule-main-calendar-header-filter-button schedule-main-calendar-header-filter-button-clients">
                        <div hidden={selectedClient.id === ""} className="schedule-main-calendar-header-filter-button-clients-inner">
                            <div className="client-card-image-container">
                                <img  
                                    className="client-card-image" 
                                    hidden={imgUrl === ''}
                                    src={imgUrl} 
                                    alt={`Image for ${selectedClient.first_name}`} />
                            </div>
                            <div className="assign-modal-body-client-name-input-container-selected-client-text-container">
                                <h4>{selectedClient.full_name}</h4>
                            </div>
                            <div onClick={() => removeClient()} className="assign-modal-body-client-name-input-container-selected-client-remove-container">
                                <Close className="assign-modal-body-client-name-input-container-selected-client-remove-icon"/>
                            </div> 
                        </div>
                        <div hidden={selectedClient.id !== ""} className="schedule-main-calendar-header-filter-button-clients-inner">
                            <p>Showing My Schedule</p>
                        </div>
                    </div>

                    <CSSTransition in={showClientList} timeout={200} classNames="menu" unmountOnExit>
                        <div id="schedule-client-filter-menu" className="assign-modal-body-client-name-menu-container schedule-main-calendar-header-filter-menu schedule-main-calendar-header-filter-menu-clients">
                            <div className={`schedule-main-calendar-header-filter-menu-search-container ${clientNameFocus ? 'schedule-main-calendar-header-filter-menu-highlighted' : ''}`}>
                                <IonInput value={searchString} 
                                    id="assign-to-client-input"
                                    autofocus={true}
                                    placeholder="Search for a Athlete by name..."
                                    inputmode="text"
                                    clearOnEdit={true}
                                    type="text"
                                    onKeyPress={e => inputUpdated(e.key)}
                                    onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                                    onFocus={(e) => setClientNameFocus(true)}
                                    onBlur={(e) => setClientNameFocus(false)}/>
                            </div>
                            <div className="schedule-main-calendar-header-filter-menu-list-container">
                                {
                                    clients.map((item: Client_t, index: number) => (
                                        <AssignModalClientCell 
                                            key={`client-${index}`}
                                            client={item} 
                                            index={index} 
                                            clientSelected={() => clientSelected(item)}/>
                                    ))
                                }
                            </div>
                            
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className="page-container-no-padding" hidden={pageNumber != 0}>
                
                <div className="schedule-main-calendar-content">
                    <Calendar 
                        isBookable={true}
                        client={selectedClient}
                        createNewWorkout={(e: any) => props.createNewWorkout(e)}
                        addNewAppointmentPressed={(e: any) => props.addNewAppointmentPressed(e)}
                        isCreatingProgramUpdated={(e: boolean) => setIsCreatingProgram(e)}
                        createPhasePressedWithWorkouts={(w: Workout_t[]) => props.createPhasePressedWithWorkouts(w)}
                        editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => props.editWorkoutForDay(workout, calendarDay, client)}/>
                </div>
                
            </div>
		</div>
	)
}

export default ScheduleMain;