import React, { useState } from 'react';
import firebase from '../../Firebase';
import axios from 'axios';

import CreateWorkoutExerciseListCell from '../../components/Workouts/WorkoutBuilder/CreateWorkoutExerciseListCell';

import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';

import './WorkoutProfileExercises.css';
import './WorkoutProfileCommon.css'

import WorkoutProfileExerciseCard from './WorkoutProfileExerciseCard';


function WorkoutProfileExercise(props: any) {

	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0,
						downloads: 0,
						timesCompleted: 0,
						exercises: [],
						exerciseSeries: []
					};

    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [exerciseSeries, setExerciseSeries] = useState<ExerciseSeries_t[]>([]);
	const [initSet, setInitSet] = useState(false);

	if (initSet === false) {
		setInitSet(true);
		setInit();
	}

	function setInit() {
		if (props.workout === undefined || props.workout.exerciseSeries === undefined) { return; }
		setWorkout(props.workout);
		setExerciseSeries(props.workout.exerciseSeries);
	}

	function switchIsShowing(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        if (thisExercise.id === forExercise.id) {
                            thisExercise.isShowing = thisExercise.isShowing === undefined ? true : !thisExercise.isShowing;
                        } else {
                            thisExercise.isShowing = false;
                        }
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        thisExercise.isShowing = false;
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                }
            })
        );
    }

	return (
		<div className="client-profile-page-container">
			<div hidden={workout.exercises!.length === 0} className="client-profile-page-exercise-list-container">
				{ exerciseSeries.map((item: ExerciseSeries_t, index: number) => (
					<div className="client-profile-page-exercise-list-cell-container">
	                    <CreateWorkoutExerciseListCell
	                        key={`${item.title}-${index}`}
	                        exerciseSeries={item}
	                        exerciseSeriesIndex={index}
	                        maxExerciseSeriesIndex={exerciseSeries.length - 1}
	                        moveExerciseSeriesUp={(e: number) => null}
	                        moveExerciseSeriesDown={(e: number) => null}
	                        moveUpExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
	                        moveDownExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
	                        editExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t) => null}
	                        removeExerciseSeries={(eS: ExerciseSeries_t, i: number) => null}
	                        addToSupersetPressed={() => null}
	                        switchIsShowing={(e: Exercise_t) => switchIsShowing(item, e)}
	                        hideEditingFeatures={true}
	                    />
                    </div>
                ))}
			</div>
			<div hidden={workout.exercises!.length > 0} className="client-profile-page-blank">
				<h2>No exercises for this workout</h2>
				<p>It looks like {workout.title} doesn't contain any exercises</p>
			</div>
		</div>
	)
}

/*
{workout.exercises!.map((item: Exercise_t, index: number) => (
					<WorkoutProfileExerciseCard 
						exercise={item}
						exerciseIndex={index}/>
				))}
*/

export default WorkoutProfileExercise;