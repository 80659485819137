import React, { useState, useEffect } from 'react';
import {Line, Scatter} from 'react-chartjs-2';
import firebase from '../../Firebase';
import axios from 'axios';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import Plan_t from '../../Interfaces/Plan_t';
import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

import ClientGoalProgress from '../../components/Clients/ClientGoalProgress';
import PowerChart from '../../components/Charts/PowerChart';
import ClientPlanCell from '../../components/Clients/ClientPlanCell';

import './ClientProfileGeneral.css';
import './ClientProfileCommon.css'

import '@material-ui/core';
import Add from '@material-ui/icons/Add';
import MailOutline from '@material-ui/icons/MailOutline';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Wc from '@material-ui/icons/Wc';
import AccessibilityIcon from '@material-ui/icons/Accessibility';

function ClientProfileGeneral(props: any) {

	let initPlan: Plan_t = {title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []};
	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};
	let initClient: Client_t = {first_name:'',
								last_name:'',
								full_name:'',
								id:'', 
								isMetric: true, 
								gender: 'b', 
								birthdate: '00-00-0000',
								height: 0, 
								weight: 0,
								goalIndex: 0,
								experienceIndex: 0};
	let initDataSets = {
        labels:['1','2','3','4','5','6','7','8','9','10'],
        datasets: [
            {
                label: 'Stats',
                backgroundColor: 'rgba(73,89,193,0.0)',
                borderColor: `rgba(73,89,193,1.0)`,
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 1,
                type: 'line',
                data: [120,100,80,120,140,60,70,110,120,120]
            },
            {
                label: 'Stats_2',
                backgroundColor: 'rgba(217,46,46,0.0)',
                borderColor: `rgba(217,46,46,1.0)`,
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 1,
                type: 'line',
                data: [140,120,120,60,70,120,100,80,120,100]
            }
        ]
    };
    var chartOptions_line: any = {
        maintainAspectRatio: false,
        responsive: true,
        "tooltips": {
            "enabled": true,
            "mode": "index",
            "intersect": true,
            "bodyFontSize": 14
        },
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        scales:{
            yAxes: [{
                display: false,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: props.stepSize !== undefined ? props.stepSize : 20,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` W`;
                    }
                }
            }],
            xAxes: [{
                display: false,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false
        }
    };

	const [client, setClient] = useState<Client_t>(initClient);
	const [latestWorkout, setLatestWorkout] = useState<Workout_t>(initWorkout);
	const [currentPlan, setCurrentPlan] = useState<Plan_t>(initPlan);
	const [clientSet, setClientSet] = useState(false);
	const [isLoadingChart, setIsLoadingChart] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [goalCompletion, setGoalCompletion] = useState('--.-');
	const [remainingInGoal, setReaminingInGoal] = useState("--");
	const [goalDescString, setGoalDescString] = useState("");
	const [goalDNE, setGoalDNE] = useState(false);
	const [activeGoals, setActiveGoals] = useState<Goal_t[]>([]);

	const [myLogs, setMyLogs] = useState<any[]>([
            {
                metric: 20,
                timestamp: 1641084238000,
            },
            {
                metric: 60,
                timestamp: 1642639438000,
            }
        ]
    );

	const [tonnage, setTonnage] = useState(0);
	const [isKg, setIsKg] = useState(false);
	const [completedSets, setCompletedSets] = useState(0);
	const [trainerName, setTrainerName] = useState("");

	const [dataSetsDNE, setDataSetsDNE] = useState(false);
	const [powerDelta, setPowerDelta] = useState(0);

	const [dataSets, setDataSets] = useState(initDataSets);

	const [initSet, setInitSet] = useState(false);

	const [inc, setInc] = useState(0);

	if (initSet === false) {
		setInitSet(true);
		setInit();
	}

	function setInit() {

		if (props.client === null || props.client === undefined) { return; }
		fetchMoreWorkouts();
		getSummaryStats(120);
		getGoals();
	}

	// useEffect(() => {
	// 	if (props.client !== null && props.client !== undefined) {
	// 		fetchMoreWorkouts();
	// 	}
	    
	// }, [props.client]);

	if (clientSet === false) {
		setClientSet(true);
		setClient(props.client ? props.client : initClient);
		if (props.client !== null && props.client !== undefined) {
			getPreferences();
			
		}
	}


	// function getGoals() {
	// 	let database = firebase.database();
	// 	let ref = database.ref(`goals/${props.client.id}`);
	// 	return;
	// 	ref.on('value', function(snapshot) {
	// 		if (snapshot.exists() === true) {
	// 			let data = snapshot.val();

	// 			setGoalDNE(false);

	// 			// Get ref data from .goals
	// 			let goalsData: any[] = data.goals;

	// 			let mostRecentGoalData = goalsData[goalsData.length - 1];

	// 			// Get most recent data from .goal_logs
	// 			let goalLogsData: any[] = data.goal_logs;
	// 			if (goalLogsData === undefined) { return; }
	// 			let mostRecentLogSet = goalLogsData[goalLogsData.length - 1];
	// 			let mostRecentLogs = mostRecentLogSet.logs;
	// 			let lastLog = mostRecentLogs[mostRecentLogs.length - 1];

	// 			// // console.log("---- GOALS -----");
	// 			// // console.log(mostRecentGoalData);
	// 			// // console.log(lastLog);

	// 			let startingMetric = mostRecentGoalData.starting_metric;
	// 			let targetMetric = mostRecentGoalData.target_metric;
	// 			let deltaMetric_target = targetMetric - startingMetric;

	// 			let mostRecentMetricLog = lastLog.metric;
	// 			let deltaMetric_mostRecent = targetMetric - mostRecentMetricLog;//mostRecentMetricLog - startingMetric;
	// 			// 	let workoutGoals = [{title: 'Get Fit', desc:'Improve overall health'}, {title: 'Loose Weight', desc:'Reach a lower target bodyweight'}, {title: 'Gain Muscle', desc:'Reach a higher target bodyweight'}, {title: 'Muscle Toning', desc:'Lower body fat percentage'}, {title: 'Body Building', desc:'Gain muscle and lower BPF'}, {title: 'Power Lifting', desc:'Increase lifting capacity'}];
	// 			let workoutGoalMetrics = [{title: 'Target workout days per week', units: 'days / week'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target body fat percentage', units: '% BF'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target max. lift', units: '_weight_'}];
	// 			let thisGoalMetrics = workoutGoalMetrics[mostRecentGoalData.index];
	// 			var thisGoalsUnits = thisGoalMetrics.units;
	// 			if (thisGoalsUnits === '_weight_') {
	// 				thisGoalsUnits = props.client.isMetric ? "KGs" : "LBS";
	// 			}

	// 			let remainingRounded = Math.abs(Math.round(deltaMetric_mostRecent * 10) / 10);
	// 			let remainingString = `${remainingRounded < 10 ? '0' : ''}${remainingRounded}${remainingRounded % 1 === 0 ? '.0' : ''} ${thisGoalsUnits}`
	// 			setReaminingInGoal(remainingString)

	// 			let completion = 1 - Math.abs(deltaMetric_mostRecent / deltaMetric_target);
	// 			let completionRounded = Math.round(completion * 1000) / 10;
	// 			let completionString = `${completionRounded < 10 ? '0' : ''}${completionRounded}${completionRounded % 1 === 0 ? '.0' : ''}`
	// 			setGoalCompletion(completionString);

	// 			// Goal description string
	// 			let workoutGoalTitles = ["Workout vol", "Cutting", "Bulking", "Cutting", "Changing", "Lifting"];
	// 			let goalDescString_temp = `${workoutGoalTitles[mostRecentGoalData.index]} from ${Math.round(startingMetric)}${thisGoalsUnits} to ${Math.round(targetMetric)}${thisGoalsUnits}`;
	// 			setGoalDescString(goalDescString_temp);
	// 		} else {
	// 			setGoalDNE(true);
	// 		}
	// 	});
	// }

	function fetchMoreWorkouts() {

		setIsLoading(true);
		let sendData = {
			user_id: props.client.id,
			get_number: 1,
			starting_at: 0
		};
		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getUserHistory`,
		sendData,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {

			setIsLoading(false);
		 	let logData = response.data;
		 	let loggedWorkouts = response.data.logs;

		 	if (loggedWorkouts.length === 0) { 
		 		setDataSetsDNE(true);
		 		return; 
		 	}

		 	setDataSetsDNE(false);

		 	for (var i = 0; i < loggedWorkouts.length; i++) {
		 		pullWorkoutData(loggedWorkouts[i]);
		 	}
		 	
		 	
		})
		.catch(e => {
			// console.log("ERROR");
			// console.log(e);
			setIsLoading(false);
		})
	}

	function pullWorkoutData(forLogObj: any) {

		/*
			forLogObj = {
				"completed_date":"2021-11-1 12:30:16",
				"start_date":"2021-11-1 12:30:9",
				"workout_id":"-Mi3Tfsa8RO7P0L9WUpe",
				"log_id":"-MnRylc_UDeiK8SYAxbi"
			}
		*/

		// Pull workout data
		let database = firebase.database();
		let ref = database.ref(`workouts/${forLogObj.workout_id}`);
		ref.once('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let data = snapshot.val();
				//// console.log(data);
				let createdByData = data.created_by;
				let createdBy:CreatedBy_t = {
					name: createdByData.name,
					id: createdByData.id
				};

				var muscleList: number[] = [];
				let exerciseKeys = Object.keys(data.exercises);
				for (var i = 0; i < exerciseKeys.length; i++) {
					let thisExercise = data.exercises[exerciseKeys[i]];
					//// console.log(thisExercise);
					let muscles: any[] = thisExercise.muscles;
					for (var j = 0; j < muscles.length; j++) {
						let thisMuscleGroup: number = muscles[j].group;
						if (!muscleList.includes(thisMuscleGroup)) {
							muscleList.push(thisMuscleGroup);
						}
					}
				}
				//// console.log(muscleList);
				//setMuscleGroups(muscleGoups.concat(muscleList));

				let newWorkout: Workout_t = {
					title: data.title,
					id: forLogObj.workout_id,
					createdBy: createdBy,
					duration: data.duration,
					imageName: data.image_name,
					targetGender: data.target_gender,
					totalSets: Number(data.total_sets),
					totalReps: Number(data.total_reps),
					difficulty: Number(data.difficulty),
					startDate: forLogObj.start_date,
					endDate: forLogObj.completed_date
				}
				//getImage(data.image_name);
				setLatestWorkout(newWorkout);
				setIsLoading(false);
				//;
			}
		});

		if (props.client === undefined || props.client.id === undefined) { return; }
		// Pull log data
		let ref_log = database.ref(`workout_logs/${props.client.id}/${forLogObj.log_id}`);
		ref_log.once('value', function(snapshot) {
			if (snapshot.exists()) {
				let data = snapshot.val();
				//// console.log("LOG DATA:", data);

				// Pull and set trainer's name
				if (data.trainer !== undefined) {
					updateTrainerName(data.trainer);
				}
				

				var tonnageTemp = 0;
				var completedSetsTemp = 0;
				var isKG = false;

				if (data.exercise_series !== undefined && data.exercise_series.length > 0) {
					// Parse exercise series setStats
					for (var i = 0; i < data.exercise_series.length; i++) {
						let thisSeriesData: any = data.exercise_series[i];
						//// console.log("thisSeriesData: ", thisSeriesData);
						if (thisSeriesData.sets !== undefined) {
							let thisSetsList: any[] = thisSeriesData.sets;
							for (var j = 0; j < thisSetsList.length; j++) {
								let thisSetsData = thisSetsList[j];
								//// console.log("thisSetsData: ", thisSetsData);
								if (thisSetsData.exercise_set_stats !== undefined) {
									let thisExerciseSetStatList: any[] = thisSetsData.exercise_set_stats;
									for (var k = 0; k < thisSetsData.exercise_set_stats.length; k++) {
										let thisSetStat = thisSetsData.exercise_set_stats[k];

										let completedReps = thisSetStat.reps_completed === undefined ? (thisSetStat.reps_anticipated === undefined ? 0 : thisSetStat.reps_anticipated) : thisSetStat.reps_completed;
										let weight = thisSetStat.weight === undefined ? 0 : thisSetStat.weight;
										tonnageTemp += completedReps * weight;
										isKG = thisSetStat.weight_units === undefined ? false : thisSetStat.weight_units === "KG";

										completedSetsTemp += 1;
									}
								}
							}
						}
					}
				}

				setTonnage(tonnageTemp);
				setCompletedSets(completedSetsTemp);
				setIsKg(isKG);
			}
		});
	}

	function updateTrainerName(forID: string) {
		let database = firebase.database();
		let ref = database.ref(`personal_trainer_profile/${forID}`);
		ref.once('value', function(snapshot) {
			if (snapshot.exists()) {
				let data = snapshot.val();
				if (data.full_name !== undefined) {
					setTrainerName(data.full_name);
				}
			}
		});
	}

	function getGoals() {

        if (props.client === undefined) { return; }

        setIsLoading(true);

        let database = firebase.database();
        let ref = database.ref(`goals/${props.client.id}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                setGoalDNE(false);

                // Get ref data from .goals
                let goalsData: any[] = data.goals;

                let goalKeys = Object.keys(goalsData);

                var goalsList: Goal_t[] = [];
                var activeGoalsList: Goal_t[] = [];

                for (var i = 0; i < goalKeys.length; i++) {
                    let thisKey: any = goalKeys[i];
                    let thisGoalData: any = goalsData[thisKey];

                    //// console.log("--- RAW GOAL DATA FOR: ", thisKey, i, thisGoalData);

                    var actionList: GoalActions_t[] = [];

                    if (thisGoalData.actions !== undefined) {
                        let goalActionsData: any = thisGoalData.actions;
                        let goalActionsKeys: string[] = Object.keys(goalActionsData);
                        for (var j = 0; j < goalActionsKeys.length; j++) {
                            let thisActionData: any = goalActionsData[goalActionsKeys[j]];

                            var subActionList: GoalActions_t[] = [];

                            if (thisActionData.subActions !== undefined) {
                                let goalSubActionData: any = thisActionData.subActions;
                                let goalSubActionsKeys: string[] = Object.keys(goalSubActionData);
                                for (var k = 0; k < goalSubActionsKeys.length; k++) {
                                    let thisSubActionData: any = goalSubActionData[goalSubActionsKeys[k]];

                                    let thisSubAction: GoalActions_t = {
                                        index: k,
                                        goalDesc: thisSubActionData.goalDesc !== undefined ? thisSubActionData.goalDesc : "",
                                        durationDays: thisSubActionData.durationDays !== undefined ? thisSubActionData.durationDays : 0,
                                        action: thisSubActionData.action !== undefined ? thisSubActionData.action : "",
                                        metric: thisSubActionData.metric !== undefined ? thisSubActionData.metric : -1,
                                        metricUnit: thisSubActionData.metricUnit !== undefined ? thisSubActionData.metricUnit : "",
                                        timeframe: thisSubActionData.timeframe !== undefined ? thisSubActionData.timeframe : "per day",
                                    }
                                    subActionList.push(thisSubAction);
                                }
                            }

                            let thisAction: GoalActions_t = {
                                index: j,
                                goalDesc: thisActionData.goalDesc !== undefined ? thisActionData.goalDesc : "",
                                durationDays: thisActionData.durationDays !== undefined ? thisActionData.durationDays : 0,
                                subActions: subActionList
                            }
                            actionList.push(thisAction);
                        }
                    }


                    let thisGoal: Goal_t = {
                        type: (thisGoalData.type as ("custom" | "get-fit" | "loose-weight" | "gain-weight" | "muscle-toning" | "body-building" | "power-lifting" | "cardio-endurance")) ? thisGoalData.type : "custom",
                        customType: thisGoalData.custom_type !== undefined ? thisGoalData.custom_type : "",
                        targetMetric: thisGoalData.target_metric !== undefined ? thisGoalData.target_metric : 0,
                        targetUnitsKG: thisGoalData.target_units_kg !== undefined ? thisGoalData.target_units_kg : false,
                        targetTimestamp: thisGoalData.target_timestamp !== undefined ? thisGoalData.target_timestamp : 0,
                        startingTimestamp: thisGoalData.starting_timestamp !== undefined ? thisGoalData.starting_timestamp : 0,
                        actions: actionList,
                        isEditing: false,
                        id: thisKey,
                        isComplete:  thisGoalData.complete !== undefined ? thisGoalData.complete : false
                    };

                   // // console.log("--- PARSED GOAL DATA FOR: ", thisKey, i, thisGoalData);

                    goalsList.push(thisGoal);
                    if (thisGoal.isComplete === undefined || thisGoal.isComplete === false) {
                        activeGoalsList.push(thisGoal);
                    }
                }

                //setAllGoals(goalsList);
                setActiveGoals(activeGoalsList);
                if (activeGoalsList.length > 0) {
                	pullLogs(activeGoalsList[0]);
                }
                
            }
            //setIsLoading(false);
        });
    }

    async function pullLogs(forGoal: Goal_t) {
        if (forGoal.type === undefined) { return; }

        var newLogList: any[] = [];

        switch (forGoal.type) {
            case "custom": 
                
                break;
            case "get-fit": 
                
                break;
            case "loose-weight": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "gain-weight": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "muscle-toning": 
                newLogList = await getBodyMeasurementLogs('body_fat_percentage');
                break;
            case "body-building": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "power-lifting": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "cardio-endurance":
                
                break;
            default: 
                break;
        }

        //// console.log("ClientGoalProgressCard | Setting my logs to:", newLogList);

        setMyLogs(newLogList);

        // var measureReverse = [];
        // for (var i = newLogList.length - 1; i >= 0; i--) {
        //     measureReverse.push(newLogList[i]);
        // }

        // setMyLogs_reversed(measureReverse);

    }

    async function getBodyMeasurementLogs(metric_id: string) {
        if (props.client === undefined) { return []; }

        return new Promise<any>(resolve => {
            let clientID = props.client.id;

            let database = firebase.database();
            let ref = database.ref(`body_measurements/${clientID}/logs`); // Change to database path
            ref.once('value', function(snapshot) {

                var logComp: any[] = [];

                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    let dataKeys = Object.keys(data);

                    for (var i = 0 ; i < dataKeys.length; i++) {
                        let thisLog = data[dataKeys[i]];

                        //// console.log("CLIENT PROFILE GENERAL >> ClientGoalProgressCard | body measure log: ", thisLog, metric_id, thisLog[metric_id])
                        let isKG = thisLog.isKG !== undefined ? thisLog.isKG : false;
                        var unit = "";
                        if (metric_id === 'body_fat_percentage') {
                            unit = '%';
                        } else if (metric_id === 'body_weight' || metric_id === 'lean_mass') {
                            unit = isKG ? "KGs" : "LBS";
                        } else {
                            unit = isKG ? "cm" : "in";
                        }

                        let thisLogComp: any = {
                            metric: thisLog[metric_id] !== undefined ? thisLog[metric_id] : NaN,
                            logged_by: thisLog.logged_by !== undefined ? thisLog.logged_by : clientID,
                            timestamp: thisLog.timestamp !== undefined ? thisLog.timestamp : -1,
                            units: unit
                        }
                        logComp.push(thisLogComp);
                    }
                }

                resolve(logComp);
            })
        });
    }

	function getDateString() {
		if (!latestWorkout.endDate) {
			return 'Date unknown'
		}

		let monthStrings = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

		let date_time = latestWorkout.endDate.split(' ');
		let date = date_time[0];
		let time = date_time[1];

		let dates = date.split('-');
		let times = time.split(':');

		let hour = Number(times[0]);
		let isPm = hour >= 12;
		let hour_adj = hour === 12 || hour === 24 ? 12 : hour % 12;
		let mins = Number(times[1]);

		
		return `${monthStrings[Number(dates[1]) - 1]} ${dates[2]}, ${dates[0]}  ${hour_adj < 10 ? '0' : ''}${hour_adj}:${mins < 10 ? '0' : ''}${mins}${isPm ? 'pm' : 'am'}`
	}

	function getTime() {
		if (!latestWorkout.endDate || !latestWorkout.startDate) {
			return 'Duration unknown'
		}

		let end_date_time = latestWorkout.endDate.split(' ');
		let end_time = end_date_time[1];
		let end_times = end_time.split(':');
		var end_h = Number(end_times[0]);
		let end_m = Number(end_times[1]);

		let start_date_time = latestWorkout.startDate.split(' ');
		let start_time = start_date_time[1];
		let start_times = start_time.split(':');
		let start_h = Number(start_times[0]);
		let start_m = Number(start_times[1]);

		if (end_h < start_h) { end_h += 12;}
		let start_mins = start_m + (60 * start_h);
		let end_mins = end_m + (60 * end_h);
		let mins_diff = end_mins - start_mins;

		let hours_diff = Math.floor(mins_diff / 60);
		let mins_adj = mins_diff - (hours_diff * 60);

		if (hours_diff > 0) {
			return `${hours_diff < 10 ? '0' : ''}${hours_diff} hour${hours_diff === 1 ? '' : 's'} ${mins_adj < 10 ? '0' : ''}${mins_adj} minute${mins_adj === 1 ? '' : 's'}`;
		} else {
			return `${mins_adj < 10 ? '0' : ''}${mins_adj} minute${mins_adj === 1 ? '' : 's'}`;
		}
	}


	function getSummaryStats(withDaysToShow: number) {
        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }    // exit if no user ID is found

        let sendData = {
            user_id: props.client.id,
            days_to_show: withDaysToShow
        };

        setIsLoadingChart(true);
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getAllStats`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            let data = response.data;
            if (data !== undefined && data.error === 0) {
                let results = data.results;
                // // console.log(`GET ALL STATS RESULTS:`);
                // // console.log(results);
                //setWeeklyWorkouts(results.weekly_workouts);
                //setWeeklyTotalLift(results.weekly_lift);

                let liftStats = results.lift_stats !== undefined ? results.lift_stats : [];
                let powerStats = results.power_stats !== undefined ? results.power_stats : [];
                let maxWeightStats = results.maximum_weight !== undefined ? results.maximum_weight : [];
                let formStats = results.form_accuracy_stats !== undefined ? results.form_accuracy_stats : [];
                var restStats = results.rest_time_stats !== undefined ? results.rest_time_stats : [];
                let ROMStats = results.ROM_stats !== undefined ? results.ROM_stats : [];
                let repWeightDistStats = results.rep_weight_distribution !== undefined ? results.rep_weight_distribution : [];
                let totalLiftingTimeStats = results.total_lift_time_stats !== undefined ? results.total_lift_time_stats : [];

                // Filter data
                if (restStats.length > 0) {
                    for (var i = 0; i < restStats.length; i++) {
                        restStats[i] = restStats[i] < 0 ? 0 : (restStats[i] > 1000000 ? 0 : restStats[i]);
                    }
                }

                let firstPower = powerStats[0];
                let lastPower = powerStats[powerStats.length - 1];

                let dP = firstPower - lastPower;
                let dP_percent = lastPower === 0 ? dP : (dP / lastPower) * 100;
                setPowerDelta(dP_percent);
                //setChartLiftData(liftStats);
                // // console.log(`Setting power data:`);
                // // console.log(powerStats);
                processLineChartData([powerStats]);
                //setChartPowerData(chartPowerData.concat(powerStats));
                //setInc(inc + 1);
                // setChartMaxWeightData(maxWeightStats);
                // setChartFormData(formStats);
                // setChartRestData(restStats);
                // setChartROMData(ROMStats);
                // setChartRepWeightDist(repWeightDistStats);
                // setTimeSpentLifting(avgTimeSpentLiftingPercentage);

                //// console.log(repWeightDistStats);

                setIsLoadingChart(false);
            }
        })
        .catch(e => {
            // console.log(e);
        })
    }

    function processLineChartData(forStats: number[][]) {
        let chartLineColours = [`73,89,193`,`217,46,46`,`46,217,46`,`217,46,217`,`46,217,46`];
        let statsMaxIndex = forStats.length > 5 ? 5 : forStats.length;    // Restrict max number of data sets to 5

        var maxStatsLength = 0;
        var dataSetsTemp = [];
        var labelsTemp = [];

        for (var i = 0; i < statsMaxIndex; i++) {
            var thisStat = forStats[i];

            // if (props.units[i] === '%') {
            //     var statTemp = [];
            //     for (var j = 0; j < thisStat.length; j++) {
            //         statTemp.push(thisStat[j] * 100);
            //     }
            //     thisStat = statTemp;
            // }
            

            let thisDataSet = {
                label: `Stats_${i}`,
                backgroundColor: `rgba(${chartLineColours[1]},0.0)`,
                borderColor: `rgba(${chartLineColours[1]},1.0)`,
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 1,
                type: 'line',
                data: thisStat
            }

            dataSetsTemp.push(thisDataSet);


            // Calculate Average
            if (i < 2) {
                var avgStat = 0;

                var maxIndex = thisStat.length;
                for (var j = 0; j < maxIndex; j++) {
                    let statVal = thisStat[j] !== undefined ? thisStat[j] : 0.0;

                    avgStat += statVal;
                }

                avgStat /= maxIndex === 0 ? 1 : maxIndex;

                // if (i === 0) {
                //     setAverageStat_a(avgStat);
                // } else if (i === 1) {
                //     setAverageStat_b(avgStat);
                // }
            }

            if (thisStat.length > maxStatsLength) {
                maxStatsLength = thisStat.length;
            }
        }

        for (var i = 0; i < maxStatsLength; i++) {
            labelsTemp.push(`${i}`);
        }

        // if (maxStatsLength === 0) {
        //     setNumStats(0);
        // }

        let finalDataSet = {
            labels: labelsTemp,
            datasets: dataSetsTemp
        };
        setDataSets(finalDataSet);
    }

	function getPreferences() {
		let database = firebase.database();
		let ref = database.ref(`user_pref/${props.client.id}`);
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let data = snapshot.val();
				let experienceIndex = data.experience;
				let goalIndex = data.goal;
				setClient({...props.client, goalIndex: goalIndex, experienceIndex: experienceIndex});
			}
		});
	}

	function getDOBString() {
		if (client.birthdate === '00-00-0000' || client.birthdate === undefined) {
			return 'No record'
		}
		let monthStrings = ['January','February','March','April','May','June','July','August','September','October','November','December'];
		let dates = client.birthdate.split('-');
		let monthString = monthStrings[Number(dates[1])]
		return `${monthString} ${dates[0]}, ${dates[2]}`;
	}

	function getGenderString() {
		if (client.gender === 'm') {
			return 'Male'
		} else if (client.gender === 'f') {
			return 'Female'
		} else {
			return 'Not specified'
		}
	}

	function getWorkoutGoalString() {
		let workoutGoals = ['Get Fit', 'Loose Weight', 'Gain Muscle', 'Muscle Toning', 'Body Building', 'Power Lifting']
		return client.goalIndex === undefined ? workoutGoals[0] : workoutGoals[client.goalIndex];
	}

	function getExperienceString() {
		let workoutLevels = ['Sedentary', '1-2 days/week', '3-4 days/week', '5-6 days/week', 'Every day'];
		return client.experienceIndex === undefined ? workoutLevels[0] : workoutLevels[client.experienceIndex];
	}

	function getWeight() {
		return `${client.weight} ${client.isMetric ? "KGs" : "LBS"}`;
	}

	function getHeight() {
		if (client.height === undefined) { return 'Unspecified'; }
		let a = client.isMetric ? Math.floor(client.height / 100) : Math.floor(client.height / 12);
		let b = client.isMetric ? client.height % 100 : client.height % 12;
		return `${a} ${client.isMetric ? 'm' : 'ft'} ${b} ${client.isMetric ? 'cm' : 'in'}`;
	}

	function getGoalName() {
        if (activeGoals[0] === undefined || activeGoals[0].type === undefined) { return 'No'; }

        var resultString = "Goal";
        switch (activeGoals[0].type) {
            case "custom": 
                resultString = "Custom";
                break;
            case "get-fit": 
                resultString = "Custom";
                break;
            case "loose-weight": 
                resultString = "Loose Weight";
                break;
            case "gain-weight": 
                resultString = "Gain Mass";
                break;
            case "muscle-toning": 
                resultString = "Lowering Body Fat";
                break;
            case "body-building": 
                resultString = "Bodybuilding";
                break;
            case "power-lifting": 
                resultString = "Power Lifting";
                break;
            case "cardio-endurance":
                resultString = "Cardio Endurance";
                break;
            default: 
                break;
        }

        return resultString;
    }

	return (
		<div className="client-profile-page-container">
			<div hidden={true} className="client-profile-general-full-card">
				<div hidden={isLoading} className="client-profile-general-full-card-content">
					<div className="client-profile-general-full-card-col-2">
						<p>Recently completed workout</p>
						<h4>{latestWorkout.title}</h4>
					</div>
					<div className="client-profile-general-full-card-col-4">
						<p>Completed on</p>
						<h4>{getDateString()}</h4>
					</div>
					<div className="client-profile-general-full-card-col-4">
						<p>Durration</p>
						<h4>{getTime()} mins</h4>
					</div>
				</div>
				<div hidden={!isLoading} className="client-profile-general-full-card-content client-profile-general-full-card-content-loading">
					<p>Loading...</p>
				</div>
			</div>
			
			<div className="client-profile-general-full-card client-profile-general-full-card-no-line">
				<div className="client-profile-general-card-header">
					<h4>Most Recent Workout</h4>
				</div>
				<div hidden={isLoading} className="client-profile-general-card-body client-profile-general-card-body-workout">
					<div className="client-profile-general-card-body-col">
						<div className="client-profile-general-card-body-row">
							<div className="client-profile-general-card-body-row-title-container">
								<h4>Workout</h4>
							</div>
							<div className="client-profile-general-card-body-row-content-container">
								<h4>{latestWorkout.title}</h4>
							</div>
						</div>
						<div className="client-profile-general-card-body-row">
							<div className="client-profile-general-card-body-row-title-container">
								<h4>Completed</h4>
							</div>
							<div className="client-profile-general-card-body-row-content-container">
								<h4>{getDateString()}</h4>
							</div>
						</div>
						<div className="client-profile-general-card-body-row">
							<div className="client-profile-general-card-body-row-title-container">
								<h4>Duration</h4>
							</div>
							<div className="client-profile-general-card-body-row-content-container">
								<h4>{getTime()}</h4>
							</div>
						</div>
					</div>
					<div className="client-profile-general-card-body-col">
						<div className="client-profile-general-card-body-row">
							<div className="client-profile-general-card-body-row-title-container">
								<h4>Volume</h4>
							</div>
							<div className="client-profile-general-card-body-row-content-container">
								<h4>{completedSets} of {latestWorkout.totalSets} sets</h4>
							</div>
						</div>
						<div className="client-profile-general-card-body-row">
							<div className="client-profile-general-card-body-row-title-container">
								<h4>Tonnage</h4>
							</div>
							<div className="client-profile-general-card-body-row-content-container">
								<h4>{tonnage} {isKg ? "KG" : "LBS"}</h4>
							</div>
						</div>
						<div className="client-profile-general-card-body-row">
							<div className="client-profile-general-card-body-row-title-container">
								<h4>Trainer</h4>
							</div>
							<div className="client-profile-general-card-body-row-content-container">
								<h4>{trainerName === "" ? "No Trainer" : trainerName}</h4>
							</div>
						</div>
					</div>
				</div>
				<div hidden={!isLoading} className="client-profile-general-card-body client-profile-general-card-body-workout client-profile-general-card-body-workout-loading">
					<p>Loading details...</p>
				</div>
			</div>
			<div className="client-profile-general-full-card ">
				<div className="client-profile-general-card-header">
					<h4>{activeGoals.length === 0 ? 'Goals' : `${getGoalName()} Goal Progress`}</h4>
					<div onClick={() => props.logMeasurementPressed()} className="client-profile-page-goals-header-button client-profile-page-goals-header-button-dark client-profile-general-card-header-button">
                        <Add className="client-profile-page-goals-header-button-icon"/>
                        <p>New Log</p>
                    </div>
				</div>
				<div hidden={activeGoals.length === 0} className="client-profile-general-card-body">
					<ClientGoalProgress
						hideBottom={true}
	                    goal={activeGoals[0]}
	                    myLogs={myLogs}
	                    client={props.client}/>
				</div>
				<div hidden={activeGoals.length !== 0} className="client-profile-general-card-body client-profile-general-card-body-no-plans">
					<h4>No Goals have been set</h4>
					<p>No Goals were found for {props.client === undefined ? 'your Client' : props.client.first_name}. Go ahead and add a Goal to track their progress along their fitness journey.</p>
					<div onClick={() => props.newGoalPressed()} className="client-profile-general-card-body-no-plans-buttons">
						<p>Add a Goal</p>
					</div>
				</div>
			</div>
			<div hidden={true} className="client-profile-general-card-row">
				<div onClick={() => props.goalsPressed()} hidden={goalDNE} className="client-profile-general-half-card">
					<div className="client-profile-general-card-header">
						<h4>Goal Progress</h4>
					</div>
					<div className="client-profile-general-half-card-content">
						<h3>{goalCompletion}%</h3>
						<p>{remainingInGoal} remaining in goal</p>
						<p>{goalDescString}</p>
					</div>
				</div>
				<div onClick={() => props.goalsPressed()} hidden={!goalDNE} className="client-profile-general-half-card">
					<div className="client-profile-general-card-header">
						<h4> Goal Progress</h4>
					</div>
					<div className="client-profile-general-half-card-content client-profile-general-half-card-content-empty">
						<h5>No Goal found</h5>
						<p>+ Tap to create a new goal</p>
					</div>
				</div>
				<div className="client-profile-general-half-card">
					<div className="client-profile-general-card-header">
						<h4>Lifting Improvement</h4>
					</div>
					<div hidden={isLoadingChart || dataSetsDNE} className="client-profile-general-half-card-content">
						<div  className="client-profile-general-half-card-chart-container ">
							<Line
		                        data={dataSets}
		                        options={chartOptions_line}
		                    />
						</div>
						<p>{`Average power is ${powerDelta === 0 ? 'steady at' : (powerDelta > 0 ? 'up by' : 'down by')} ${powerDelta}% over this past week`}</p>
					</div>
					<div hidden={!isLoadingChart || dataSetsDNE} className="client-profile-general-half-card-content client-profile-general-half-card-content-loading">
						<p>Loading...</p>
					</div>
					<div hidden={!dataSetsDNE} className="client-profile-general-half-card-content client-profile-general-half-card-content-empty">
						<h5>No history found</h5>
						<p>Log workouts to view progression</p>
					</div>
				</div>
			</div>
			<div className="client-profile-general-full-card client-profile-general-full-card-plans">
				<div className="client-profile-general-card-header client-profile-general-card-header-plans">
					<h4>Previously Assigned Plans</h4>
					<p>({props.allPlanObjs === undefined ? 0 : props.allPlanObjs.length})</p>
				</div>
				<div hidden={props.allPlanObjs === undefined || props.allPlanObjs.length === 0} className="client-profile-general-card-content-plans-container">
					{
						props.allPlanObjs.map((item: any, index: number) => (
							<ClientPlanCell 
								planObj={item} 
								client={client}
								index={index}/>
						))
					}
				</div>
				<div hidden={props.allPlanObjs !== undefined && props.allPlanObjs.length > 0} className="client-profile-general-card-content-plans-container client-profile-general-card-content-plans-container-empty">
					<h5>No plan history found</h5>
					<p>Assign a plan to view their to view their plan timeline</p>
				</div>
			</div>
		</div>
	)
}

export default ClientProfileGeneral;