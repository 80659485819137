import React from 'react';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './FormTextInput.css';

interface FormTextInputProps {
  placeholder: string;
  inputChanged: any;
  onKeyPress?: any;
  alert: boolean;
  value?: any;
  alertText?: string;
  inputMode?: "decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url" | undefined;
  hideText?: boolean;
  isSearch?: boolean;
  isOptional?: boolean;
  autofocus?: boolean;
  noHide?: boolean;
}



interface FormTextInputState {
  labelIsHidden: boolean;
  message: string;
  isFocused: boolean;
}

class FormTextInput extends React.Component<FormTextInputProps, FormTextInputState> {
  static defaultProps: FormTextInputProps = {
    placeholder: "Enter value",
    inputChanged: null,
    alert: false,
    alertText: '',
    inputMode: "text",
    hideText: false,
    isSearch: false,
    isOptional: false
  }
  constructor(props: any) {
    super(props);
    this.state = {labelIsHidden: true, message: "",isFocused: false}
  }
  setText(newText: string) {
    let shouldHide = newText === "" || newText === null;
    this.setState({labelIsHidden: shouldHide, message: newText,isFocused: true});
    this.props.inputChanged(newText);
  }
  focusChanged(toFocus: boolean) {
    this.setState({...this.state, isFocused: toFocus});
  }
  
  render() {
    return (
      <div className="form-input-container">
       <p hidden={(this.props.noHide === undefined || this.props.noHide === false) && (this.state.labelIsHidden || this.props.isSearch === true)} className="form-text-input-label">{this.props.placeholder}</p>
       <p hidden={!this.props.alert} className="form-text-input-alert-label">{this.props.alertText}</p>
       <p hidden={this.props.alert === true || this.props.isOptional === undefined || this.props.isOptional === false} className="form-text-input-optional-label">Optional</p>
       <div className={this.props.alert ? "form-text-input-container-alert" : `form-text-input-container ${this.state.isFocused === true ? 'form-text-input-container-focused' : ''}`}>
         <IonInput value={this.props.value === undefined ? this.state.message : this.props.value} 
                   placeholder={(this.props.noHide !== undefined && this.props.noHide === true) ? `Enter a ${this.props.placeholder.toLowerCase()}` : this.props.placeholder} 
                   autofocus={this.props.autofocus !== undefined ? this.props.autofocus : false}
                   inputmode={this.props.inputMode}
                   clearOnEdit={this.props.hideText}
                   onFocus={(e) => this.focusChanged(true)}
                   onBlur={(e) => this.focusChanged(false)}
                   type={this.props.hideText ? "password" : "text"}
                   onIonChange={e => this.setText(e.detail.value!)}
                   onKeyPress={e => this.props.onKeyPress !== undefined ? this.props.onKeyPress(e) : null}></IonInput>
       </div>
     </div>
    )
  }
}

//       <p hidden={this.state.labelIsHidden || this.props.isSearch === true} className="form-text-input-label">{this.props.placeholder}</p>



export default FormTextInput;
