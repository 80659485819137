import React, { useState, useEffect } from 'react';
import './Overview.css';
import './common.css';

import WorkoutSummaryCard from '../components/Cards/WorkoutSummaryCard';
import GoalCard from '../components/Cards/GoalCard';
import ExerciseSummaryCard from '../components/Cards/ExerciseSummaryCard';

function OverviewWorkout(props: any) {

    
    const [scoringMetrics, setScoringMetrics] = useState<any[]>([]);
    const [logData, setLogData] = useState<any>();
    const [goalData, setGoalData] = useState<any>();
    const [exerciseList, setExerciseList] = useState<any[]>([])
    const [totalScore, setTotalScore] = useState(0);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize()
    }, [initSet])

    useEffect(() => {
        generateExerciseList();
    }, [props.logData])
    

    const initialize = () => {

        generateExerciseList();


    }

    const generateExerciseList = () => {
    	if (!props.logData || !props.logData.log || !props.logData.log.exercise_series) return;

    	var exerciseListTemp: any[] = [];
    	for (var i = 0; i < props.logData.log.exercise_series.length; i++) {
    		let thisSeries = props.logData.log.exercise_series[i];
    		if (thisSeries && thisSeries.exercises) {
    			for (var j = 0; j < thisSeries.exercises.length; j++) {
    				let thisExercise = thisSeries.exercises[j];
    				if (thisExercise) {
    					exerciseListTemp.push(thisExercise);
    				}
    			}
    		}
    	}
    	// console.log("LE LIST:", exerciseListTemp)
    	setExerciseList(exerciseListTemp);
    }



	return (
		<div className={ "performance-analysis-page" }>
			<div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                    <WorkoutSummaryCard  
                        scoringMetrics={ scoringMetrics }
                        clientName={ props.clientName }
                        score={ totalScore }
                        logData={ props.logData }/>
                </div>
            </div>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                    <ExerciseSummaryCard  
                        logData={ props.logData }
                        exerciseList={ exerciseList }
                        clientName={ props.clientName }
                        openSetsPressed={ () => props.openSetsPressed() }/>
                </div>
            </div>

		</div>
	)
}

export default OverviewWorkout;