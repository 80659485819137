import React, { useState } from 'react';
import './NodePlayground.css';

import CurrentStretchChart from '../../components/Charts/CurrentStretchChart';

import NodeInstance from '../../NodeInstance';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import Workout_t from '../../Interfaces/Workout_t';
import CurrentWorkout_t from '../../Interfaces/CurrentWorkout_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import RepStats_t from '../../Interfaces/RepStats_t';
import SetStats_t from '../../Interfaces/SetStats_t';
import MotionData_t from '../../Interfaces/MotionData_t';

function NodePlayground(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);

    const [motionStream, setMotionStream] = useState<number[][]>([[],[],[],[]]);
    //const [motionStream, setMotionStream] = useState<MotionData_t[]>([]);
    const [motionStreamSingle, setMotionStreamSingle] = useState<number[]>([]);
    const [readCount, setReadCount] = useState(0);

    const [allNodeUUIDs, setAllNodeUUIDs] = useState<string[]>([]);
    const [connectedNodeUUIDs, setConnectedNodeUUIDs] = useState<string[]>([]);

    const [lastMotionData, setLastMotionData] = useState<MotionData_t>({quaternion: {w:1,x:0,y:0,z:0}, acceleration:{x:0,y:0,z:1}, timestamp: 0})
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.nodeManager !== undefined) {
            props.nodeManager.setMyNodesCallback(myNodesCallback);
            configNodes();
        }
    }

    function myNodesCallback(nodes: NodeInstance[]) {
        // console.log("NODE PLAYGROUND: nodes", nodes);
        var listTemp: string[] = [];

        for (var i = 0; i < nodes.length; i++) {
            let thisNode: NodeInstance = nodes[i];
            //thisNode.setConnectionCallback(nodeConnectionCallback);
            //thisNode.setFullStreamState(true);
            //thisNode.setStreamCallback(streamCallback);
            listTemp.push(thisNode.getUUID());
        }

        setAllNodeUUIDs(listTemp);
    }

    function nodeConnectionCallback(toState: boolean) {

        var mismatchUUID: string = "";
        for (var i = 0; i < allNodeUUIDs.length; i++) {
            let thisNodeUUID = allNodeUUIDs[i];
            let thisNode: NodeInstance = props.nodeManager.getNodeInstanceByUUID(thisNodeUUID);
            if (thisNode !== null && thisNode !== undefined) {
                let isConnected = thisNode.getIsConnected();
                if (isConnected) {

                    var onList = false;
                    for (var j = 0; j < connectedNodeUUIDs.length; j++) {
                        if (connectedNodeUUIDs[j] === thisNodeUUID) {
                            onList = true;
                        }
                    }

                    if (!onList) {
                        mismatchUUID = thisNodeUUID;
                    }
                }
            }
        }

        let newList: string[] = connectedNodeUUIDs;
        newList.push(mismatchUUID);
        setConnectedNodeUUIDs(newList);
    }

    function configNodes() {
        if (props.nodeManager !== undefined) {
            // if (TESTING) {
            //     props.nodeManager.setMotionManagerDataUpdatedCallback(motionManagerDataUpdatedCallback);
            // } else {
            //     props.nodeManager.setRepUpdatedCallback(repUpdatedCallback);
            // }


            let numNodesConnected_temp = props.nodeManager.getNumberOfConnectedNodes();
            let numMyNodes_temp = props.nodeManager.getMyNodesList().length;

            //props.nodeManager.setConnectionAlertCallback(connectionAlertCallback);
            //props.nodeManager.setMotionDataCallback(dataUpdated);

            //if (numNodesConnected_temp > 0) {
                let connectedList: NodeInstance[] = props.nodeManager.getMyNodesList();
                for (var i = 0; i < connectedList.length; i++) {
                    let thisNode: NodeInstance = connectedList[i];
                    //thisNode.setLocationIndex(i);
                    //thisNode.setNodeDumpCallback(nodeDumpCallback);
                    //thisNode.setFullStreamState(true);
                    //thisNode.setStreamCallback(streamCallback);
                }
            //}
        }
    }


    function q_normalize(q: {w: number, x: number, y: number, z: number}) 
    {
        var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};
        const quaternionMagSq = Math.sqrt((q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z));
        result.w = q.w / quaternionMagSq;
        result.x = q.x / quaternionMagSq;
        result.y = q.y / quaternionMagSq;
        result.z = q.z / quaternionMagSq;

        return result;
    }

    function q_invert(q: {w: number, x: number, y: number, z: number})
    {
        var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};
        const quaternionMagSq = (q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z);
        result.w = q.w / quaternionMagSq;
        result.x = -1 * q.x / quaternionMagSq;
        result.y = -1 * q.y / quaternionMagSq;
        result.z = -1 * q.z / quaternionMagSq;

        return result;
    }

    function q_multiply(q: {w: number, x: number, y: number, z: number}, p: {w: number, x: number, y: number, z: number})
    {
        var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};

        result.w = q.w*p.w - q.x*p.x - q.y*p.y - q.z*p.z;
        result.x = q.x*p.w + q.w*p.x - q.z*p.y + q.y*p.z;
        result.y = q.y*p.w + q.z*p.x + q.w*p.y - q.x*p.z;
        result.z = q.z*p.w - q.y*p.x + q.x*p.y + q.w*p.z;

        return result;
    }

    function angle_between_quaternions(q_a: {w: number, x: number, y: number, z: number}, q_b: {w: number, x: number, y: number, z: number}) {
        let q_inv = q_invert(q_a);
        let res = q_multiply(q_inv, q_b);

        //res.w = res.w > 1.0 ? 1.0 : (res.w < -1.0 ? 1.0 : res.w);

        //// console.log(res.w, Math.acos(res.w));

        let d_theta = 2.0 * Math.acos(res.w) * 57.2957795131;

        return d_theta;
    }

    function streamCallback_b(m_buff: MotionData_t, forNode: NodeInstance) {
        if (m_buff.quaternion === undefined) { return; }

        //let motionStream_temp: MotionData_t[] = JSON.parse(JSON.stringify(motionStream));

        // if (motionStream.length >= 20) {
        //     //motionStream_temp.push(m_buff);
        //     // let motionStream_temp: MotionData_t[] = motionStream;
        //     // motionStream_temp.shift();
        //     // motionStream_temp.push(m_buff);

        //     // // for (var i = 1; i < 20; i++) {
        //     // //     motionStream_temp_b.push(JSON.parse(JSON.stringify(motionStream[i])));
        //     // // }

        //     // motionStream_temp.push(m_buff);

        //     // // console.log("L:", motionStream_temp.length, motionStream.length);
        //     setMotionStream(motionStream.map((item: MotionData_t, index: number) => {
        //         if (index < 19) {
        //             return motionStream[index + 1];
        //         } else {
        //             return m_buff;
        //         }
        //     }));
        // } else {
        //     let motionStream_temp: MotionData_t[] = motionStream;
        //     // console.log("PRE L:",-1, motionStream_temp.length);
        //     motionStream_temp.push(m_buff);
        //     setMotionStream(motionStream_temp);
        // }
        

    }

    function streamCallback(m_buff: MotionData_t, forNode: NodeInstance) {

        if (m_buff.quaternion === undefined) { return; }

        let d_theta = angle_between_quaternions(m_buff.quaternion, lastMotionData.quaternion);

        var newBuffSingle: number[] = motionStreamSingle;

        if (newBuffSingle.length >= 20) {
            newBuffSingle = pushPopArray(newBuffSingle);//([d_theta]);
        }

        newBuffSingle.push(d_theta);

        setLastMotionData(m_buff);

        setMotionStreamSingle(newBuffSingle);

    }

    function streamCallback_old(m_buff: MotionData_t[], forNode: NodeInstance) {

        //if (forNode.getLocationIndex() !== 0) { return; }

        // // console.log("streamCallback: ", m_buff.length);

        // var maxLength = 20;

        // let dataTemp: number[][] = motionStream;

        // var newBuff: number[][] = motionStream; //[dataTemp[0] === undefined ? [] : dataTemp[0], dataTemp[1] === undefined ? [] : dataTemp[1], dataTemp[2] === undefined ? [] : dataTemp[1], dataTemp[3] === undefined ? [] : dataTemp[3]];
        // var newBuffSingle: number[] = motionStreamSingle;

        // for (var i = 0; i < m_buff.length; i++) {
        //     let thisM: MotionData_t = m_buff[i];

        //     if (newBuff[0].length >= 20) {
        //         newBuff[0] = pushPopArray(newBuff[0]);
        //     } else {
        //         newBuff[0].push(thisM.quaternion.w);
        //     }

        //     if (newBuff[1].length >= 20) {
        //         newBuff[1] = pushPopArray(newBuff[1]);
        //     } else {
        //         newBuff[1].push(thisM.quaternion.x);
        //     }

        //     if (newBuff[2].length >= 20) {
        //         newBuff[2] = pushPopArray(newBuff[2]);
        //     } else {
        //         newBuff[2].push(thisM.quaternion.y);
        //     }

        //     if (newBuff[3].length >= 20) {
        //         newBuff[3] = pushPopArray(newBuff[3]);
        //     } else {
        //         newBuff[3].push(thisM.quaternion.z);
        //     }

        //     if (newBuffSingle.length >= 20) {
        //         newBuffSingle = pushPopArray(newBuffSingle);
        //     } else {
        //         newBuffSingle.push(thisM.quaternion.w);
        //     }
            

        //     // let numArray: number[] = [thisM.quaternion.w,thisM.quaternion.x,thisM.quaternion.y,thisM.quaternion.z];
        //     // newBuff.push(numArray);
        // }

        // //// console.log(newBuff);
        // setMotionStream(newBuff);
        // setReadCount(readCount + 1);

        // setMotionStreamSingle(newBuffSingle);
        // if (motionStream.length < maxLength) {
            
        // }

    }

    function pushPopArray(thing: any[]) {

        var tempThing: any[] = [];
        for (var i = 1; i < thing.length; i++) {
            tempThing.push(thing[i]);
        }

        return tempThing;

    }



    function toggleStreaming() {
        if (props.nodeManager !== undefined) {
            if (isStreaming) {
                props.nodeManager.endDataStream();
            } else {
                props.nodeManager.beginDataStream();

                setTimeout(() => {
                    let connectedList: NodeInstance[] = props.nodeManager.getMyNodesList();
                    for (var i = 0; i < connectedList.length; i++) {
                        let thisNode: NodeInstance = connectedList[i];
                        if (thisNode.getIsConnected() === true) {
                            thisNode.requestDataDump();
                        }
                    }
                }, 500);
            }
           
        }

        setIsStreaming(!isStreaming);
    }

	return (
		<div className="node-playground">
			<div className="page-title-header-container">
                <h1>Physio Demo</h1>
                <div className="node-playground-header-button-container">
                    <div onClick={() => toggleStreaming()} className={`node-playground-header-button ${isStreaming ? 'node-playground-header-button-stream' : ''}`}>
                        <p>{isStreaming ? 'End stream' : 'Begin'}</p>
                    </div>
                </div>
            </div>
            <div className="node-playground-content">
                <div hidden={true} className="node-playground-content-connected-list">
                    {
                        connectedNodeUUIDs.map((item: string, index: number) => (
                            <div className="node-playground-content-connected-cell">
                                <h4>{index + 1}</h4>
                                <p>{item}</p>
                            </div>
                        ))
                    }
                </div>
                <div className="node-playground-content-graph-container">
                    <CurrentStretchChart
                        title={"Cumulative Angle"}
                        subTitle={""}
                        units={['º']}
                        stepSize={0.5}
                        statNames={['Cumulative Angle']}
                        chartType='line'
                        loading={false}
                        stats={[motionStreamSingle]}
                        readCount={readCount}/>
                </div>
            </div>
		</div>
	)
}

export default NodePlayground;