import React, { useState, useEffect, useRef } from 'react';
import { IonInput } from '@ionic/react';

import './CreateWorkoutExerciseListCellSetRow.css';

import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';
import Tempo_t from '../../../Interfaces/Tempo_t';

import FormTempoInput from '../../../components/Base/FormTempoInput';

import '@material-ui/core';
import Delete from '@material-ui/icons/Delete';

function CreateWorkoutExerciseListCellSetRow(props: any) {

    const inputRef_rep = useRef<any>(null);
    const inputRef_pwm = useRef<any>(null);
    const inputRef_tempo = useRef<any>(null);
    const inputRef_rest = useRef<any>(null);

    const [tempValReps, setTempValReps] = useState<number | undefined>();
    const [tempValPWM, setTempValPWM] = useState<number | undefined>();
    const [tempValTempo, setTempValTempo] = useState<Tempo_t | undefined>();
    const [tempValRest, setTempValRest] = useState<number | undefined>();

    const [focusedReps, setFocusedReps] = useState(false);
    const [focusedPWM, setFocusedPWM] = useState(false);
    const [focusedTempo, setFocusedTempo] = useState(false);
    const [focusedRest, setFocusedRest] = useState(false);    

    const [edittingReps, setEdittingReps] = useState(false);
    const [edittingPWM, setEdittingPWM] = useState(false);
    const [edittingTempo, setEdittingTempo] = useState(false);
    const [edittingRest, setEdittingRest] = useState(false);

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function getSetLetter() {
        let offsetASCIICode = props.exerciseSeriesIndex + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function getTempoString(forExercise: Exercise_t, type: number, atIndex: number) {
        if (forExercise.tempos === undefined || atIndex >= forExercise.tempos.length) { 
            return "-";
        }

        switch(type) {
            case 0:
                return forExercise.tempos[atIndex].eccentric === -1 ? '-' : (forExercise.tempos[atIndex].eccentric === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].eccentric)}`);
                break;
            case 1:
                return forExercise.tempos[atIndex].first_pause === -1 ? '-' : (forExercise.tempos[atIndex].first_pause === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].first_pause)}`);
                break;
            case 2:
                return forExercise.tempos[atIndex].concentric === -1 ? '-' : (forExercise.tempos[atIndex].concentric === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].concentric)}`);
                break;
            case 3:
                return forExercise.tempos[atIndex].second_pause === -1 ? '-' : (forExercise.tempos[atIndex].second_pause === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].second_pause)}`);
                break;
            default:
                return "-";
                break;
        }
    }

    function getSingleRepString(rep: number) {
        return rep === -1 ? 'AMRAP' : rep;
    }

    function getPercentWMString(forExercise: Exercise_t, atIndex: number) {
        if (forExercise.percent_wms === undefined) {
            return '-- %'
        } 

        var result = 0;
        if (forExercise.percent_wms.length - 1 >= atIndex) {
            result = forExercise.percent_wms[atIndex];
        } else {
            result = forExercise.percent_wms[forExercise.percent_wms.length - 1]
        }

        return result < 0 ? 'N/A' : `${Math.round(result) < 10 ? '0' : ''}${Math.round(result * 10) / 10}%`
    }

    function getSingleRestString(forExercise: Exercise_t, index_rep: number) {
        if (forExercise.restTime === undefined) {
            return 'N/A';
        }

        if (forExercise.restTime.length - 1 >= index_rep) {
            // 
            return forExercise.restTime[index_rep] < 0 ? 'N/A' : `${Math.round(forExercise.restTime[index_rep])}s`;
        } else {
            // (item.restTime[item.restTime.length - 1])}s
            return 'N/A';
        }


        //return `${(item.restTime.length - 1 >= index_rep ? ( : `
    }

    function setEdittingState(forInput: string, toState: boolean) {
        if (props.isEditable === undefined || props.isEditable === false) { return; }

        switch(forInput) {
            case 'reps':
                setEdittingReps(toState);
                if (!toState) {
                    inputRef_rep.current.blur();
                } else {
                    inputRef_rep.current.setFocus();
                }
                break;
            case 'pwm':
                setEdittingPWM(toState);
                if (!toState) {
                    inputRef_pwm.current.blur();
                } else {
                    inputRef_pwm.current.setFocus();
                }
                break;
            case 'tempo':
                setEdittingTempo(toState);
                // if (!toState) {
                //     inputRef_tempo.current.blur();
                // } else {
                //     inputRef_tempo.current.setFocus();
                // }
                break;
            case 'rest':
                setEdittingRest(toState);
                if (!toState) {
                    inputRef_rest.current.blur();
                } else {
                    inputRef_rest.current.setFocus();
                }
                break;
            default:
                break;
        }
    }

    function setInputFocus(forInput: string, focusState: boolean) {
        switch(forInput) {
            case 'reps':
                //setEdittingReps(toState);
                break;
            default:
                break;
        }

        if (!focusState) { endEditting() }
    }

    function inputChanged(forInput: string, toString: any) {
        switch (forInput) {
            case 'reps':
                let numReps = Number(toString);
                if (!isNaN(numReps) && numReps !== props.reps) {
                    setTempValReps(numReps);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.editedReps(numReps);
                }
                
                break;
            case 'pwm':
                let numPWM = Number(toString);
                if (!isNaN(numPWM) && toString !== getPercentWMString(props.exercise, props.setIndex)) {
                    setTempValPWM(numPWM);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.editedPWM(numPWM);
                }
                
                break;
            case 'tempo':
                let tempoRaw = toString as Tempo_t;
                if (tempoRaw !== props.exercise.tempos[props.setIndex]) {
                    setTempValTempo(tempoRaw);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.editedTempo(tempoRaw);
                }
                
                break;
            case 'rest':
                let numRest = Number(toString);
                if (!isNaN(numRest) && toString !== getSingleRestString(props.exercise, props.setIndex)) {
                    setTempValRest(numRest);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.editedRest(numRest);
                }
                
                break;
            default:
                break;
        }
    }

    function inputUpdated(forInput: string, e: any) {
        // if (e === "Enter") {
        //     endEditting()

        // }
    }

    function endEditting() {
        setEdittingReps(false);
        setEdittingPWM(false);
        setEdittingTempo(false);
        setEdittingRest(false);

        setTempValReps(undefined);
        setTempValPWM(undefined);
        setTempValTempo(undefined);
        setTempValRest(undefined);
    }

	return (
		<div className="create-workout-exercise-list-cell-rep-row-container">
            <div className={`create-workout-exercise-list-cell-name-container create-workout-exercise-list-cell-rep-col-container ${props.isEditable !== undefined && props.isEditable === true ? 'create-workout-exercise-list-cell-name-container-editable' : ''}`}>
                <h2><div className="create-workout-exercise-list-cell-name-container-letter" hidden={!props.showSetLetter}>{getSetLetter()}{props.exerciseIndex + 1} </div>{`${(props.setIndex < 10 ? '0' : '')}${props.setIndex + 1}`}</h2>
                <div onClick={() => props.removePressed()} hidden={props.isEditable === undefined || props.isEditable === false} className="create-workout-exercise-list-cell-name-remove-button">
                    <Delete className="create-workout-exercise-list-cell-name-remove-button-icon"/>
                </div>
            </div>
            <div className={`create-workout-exercise-list-cell-name-container create-workout-exercise-list-cell-param-container-small create-workout-exercise-list-cell-rep-col-container`}>
                <div hidden={edittingReps} onMouseOver={() => setEdittingState('reps', true)} className={props.isEditable !== undefined && props.isEditable === true ? 'create-workout-exercise-list-cell-rep-col-container-editable' : 'create-workout-exercise-list-cell-rep-col-container-noneditable'}>
                    <h2>{props.exercise.repRanges !== undefined && props.setIndex < props.exercise.repRanges.length ? `${getSingleRepString(props.exercise.repRanges[props.setIndex].min)}-${getSingleRepString(props.exercise.repRanges[props.setIndex].max)}` : `${getSingleRepString(props.reps)}${props.exercise.type === 0 ? '' : 's'}`}</h2>
                </div>
                <div  hidden={!edittingReps} className="create-workout-exercise-list-cell-rep-col-container-editable create-workout-exercise-list-cell-rep-col-container-editable-focused">
                    <IonInput 
                        value={tempValReps}
                        placeholder={"--"}
                        ref={(ref) => inputRef_rep.current = ref}
                        autofocus={true}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onMouseLeave={() => setEdittingState('reps', false)}
                        onKeyPress={e => inputUpdated('reps', e.key)}
                        onIonChange={e => inputChanged('reps', e.detail.value!)}
                        onFocus={(e) => setInputFocus('reps', true)}
                        onBlur={(e) => setInputFocus('reps', false)}/>
                </div>
            </div>
            <div className={`create-workout-exercise-list-cell-name-container create-workout-exercise-list-cell-param-container-small create-workout-exercise-list-cell-rep-col-container `}>
                <div hidden={edittingPWM} onMouseOver={() => setEdittingState('pwm', true)} className={props.isEditable !== undefined && props.isEditable === true ? 'create-workout-exercise-list-cell-rep-col-container-editable' : 'create-workout-exercise-list-cell-rep-col-container-noneditable'}>
                    <h2>{getPercentWMString(props.exercise, props.setIndex)}</h2>
                </div>
                <div hidden={!edittingPWM} className="create-workout-exercise-list-cell-rep-col-container-editable create-workout-exercise-list-cell-rep-col-container-editable-focused">
                    <IonInput 
                        value={tempValPWM}
                        placeholder={"--"}
                        ref={(ref) => inputRef_pwm.current = ref}
                        autofocus={true}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onMouseLeave={() => setEdittingState('pwm', false)}
                        onKeyPress={e => inputUpdated('pwm', e.key)}
                        onIonChange={e => inputChanged('pwm', e.detail.value!)}
                        onFocus={(e) => setInputFocus('pwm', true)}
                        onBlur={(e) => setInputFocus('pwm', false)}/>
                </div>
            </div>
            <div hidden={props.exercise.type !== 2} className={`create-workout-exercise-list-cell-name-container create-workout-exercise-list-cell-param-container-small create-workout-exercise-list-cell-rep-col-container`}>
                <div className={props.isEditable !== undefined && props.isEditable === true ? 'create-workout-exercise-list-cell-rep-col-container-editable' : 'create-workout-exercise-list-cell-rep-col-container-noneditable'}>
                    <h2>{props.exercise.distances !== undefined && props.setIndex < props.exercise.distances.length ? (props.exercise.distances[props.setIndex] < 0 ? 'N/A' : `${props.exercise.distances[props.setIndex]}m`) : 'N/A'}</h2>
                </div>
            </div>
            
            <div hidden={props.exercise.type !== 0} className={`create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small create-workout-exercise-list-cell-rep-col-container`}>
                <div hidden={edittingTempo} onMouseOver={() => setEdittingState('tempo', true)} className={props.isEditable !== undefined && props.isEditable === true ? 'create-workout-exercise-list-cell-rep-col-container-editable' : 'create-workout-exercise-list-cell-rep-col-container-noneditable'}>
                    <h2>
                        {getTempoString(props.exercise, 0, props.setIndex)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                        {getTempoString(props.exercise, 1, props.setIndex)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                        {getTempoString(props.exercise, 2, props.setIndex)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                        {getTempoString(props.exercise, 3, props.setIndex)}
                    </h2>
                </div>
                <div onMouseLeave={() => setEdittingState('tempo', false)} hidden={!edittingTempo} className="create-workout-exercise-list-cell-rep-col-container-editable create-workout-exercise-list-cell-rep-col-container-editable-focused">
                    <FormTempoInput 
                        noBorder={true}
                        forceFocus={edittingTempo}
                        thisTempo={tempValTempo}
                        tempoUpdated={(e: Tempo_t) => inputChanged('tempo', e)}/>
                </div>
            </div>
            <div className={`create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small create-workout-exercise-list-cell-rep-col-container`}>
                <div hidden={edittingRest} onMouseOver={() => setEdittingState('rest', true)} className={props.isEditable !== undefined && props.isEditable === true ? 'create-workout-exercise-list-cell-rep-col-container-editable' : 'create-workout-exercise-list-cell-rep-col-container-noneditable'}>
                    <h2>{getSingleRestString(props.exercise, props.setIndex)}</h2>
                </div>
                <div hidden={!edittingRest} className="create-workout-exercise-list-cell-rep-col-container-editable create-workout-exercise-list-cell-rep-col-container-editable-focused">
                    <IonInput 
                        value={tempValRest}
                        placeholder={"--"}
                        ref={(ref) => inputRef_rest.current = ref}
                        autofocus={true}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onMouseLeave={() => setEdittingState('rest', false)}
                        onKeyPress={e => inputUpdated('rest', e.key)}
                        onIonChange={e => inputChanged('rest', e.detail.value!)}
                        onFocus={(e) => setInputFocus('rest', true)}
                        onBlur={(e) => setInputFocus('rest', false)}/>
                </div>
            </div>
        </div>
	)
}

export default CreateWorkoutExerciseListCellSetRow;