import React, { useState, useEffect } from 'react';
import './WorkoutPlanListPlanWorkout.css';

import firebase from '../../../../Firebase';

import CreateWorkoutExerciseListCell from '../../../../components/Workouts/WorkoutBuilder/CreateWorkoutExerciseListCell';

import Workout_t from '../../../../Interfaces/Workout_t';
import ExerciseSeries_t from '../../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../../Interfaces/Exercise_t';
import Tempo_t from '../../../../Interfaces/Tempo_t';
import Muscles_t from '../../../../Interfaces/Muscles_t';

import '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

function WorkoutPlanListPlanWorkout(props: any) {

    let initWorkout: Workout_t = {
            title: '',
            id: '',
            createdBy: {name: '', id: ''},
            duration: 0,
            imageName: '',
            targetGender: '',
            totalSets: 0,
            totalReps: 0,
            difficulty: 0
        };

    const pageOptionTitles = ["Exercise Breakdown", "Muscles"]
    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    const [initSet, setInitSet] = useState(false);
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [workoutDay, setWorkoutDay] = useState(0);
    const [exerciseSeries, setExerciseSeries] = useState<ExerciseSeries_t[]>([]);
    const [exerciseSeries_original, setExerciseSeries_original] = useState<ExerciseSeries_t[]>([]);
    const [workoutHasBeenEdited, setWorkoutHasBeenEdited] = useState(false);
    //const [addExerciseToBlockIndex, setAddExerciseToBlockIndex] = useState(-1);

    const [muscleList, setMuscleList] = useState<Muscles_t[]>([]);
    const [musclesDiv, setMusclesDiv] = useState(<div/>);
    const [durationString, setDurationString] = useState('00 minutes');
    const [pageIndex, setPageIndex] = useState(0);

    const [purposedMerge, setPurposedMerge] = useState(false);
    const [purposedIndex, setPurposedIndex] = useState(-1);
    const [purposedIndex_exercise, setPurposedIndex_exercise] = useState(-1);

    const [isSaving, setIsSaving] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.workout !== undefined) {
            setWorkout(props.workout);
            if (props.workout.exerciseSeries !== undefined) {
                setExerciseSeries(props.workout.exerciseSeries);
                setExerciseSeries_original(JSON.parse(JSON.stringify(props.workout.exerciseSeries)));
            }
            configMusclesList([props.workout])
        }
        if (props.day !== undefined) {
            setWorkoutDay(props.day + 1);
        }
    }

    useEffect(() => {
        if (props.workout !== undefined) {
            setWorkout(props.workout);
            if (props.workout.exerciseSeries !== undefined) {
                setExerciseSeries(props.workout.exerciseSeries);
                setExerciseSeries_original(JSON.parse(JSON.stringify(props.workout.exerciseSeries)));
            }
            configMusclesList([props.workout])
        }
    }, [props.workout])

    useEffect(() => {
        if (props.day !== undefined) {
            setWorkoutDay(props.day + 1);
        }
    }, [props.day])

    function configMusclesList(withWorkouts: Workout_t[]) {

        var tempList: Muscles_t[] = [];
        var muscleGroups: string[] = [];
        var durationSeconds_temp = 0;

        for (var i = 0; i < withWorkouts.length; i++) {
            let thisWorkout: Workout_t = withWorkouts[i];
            if (thisWorkout.exercises !== undefined && thisWorkout.exercises.length > 0) {
                var returnString = '';
                for (var j = 0; j < thisWorkout.exercises.length; j++) {
                    let thisExercise = thisWorkout.exercises[j];
                    let repArr = thisExercise.reps ?? [];

                    if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                        for (var k = 0; k < thisExercise.muscles.length; k++) {
                            let thisMuscle = thisExercise.muscles[k];
                            let thisMuscleGroup = thisMuscle.group;
                            if (!tempList.includes(thisMuscle)) {
                                tempList.push(thisMuscle);
                            }
                            let thisMuscleGroupString = muscle_groups[thisMuscleGroup];
                            if (!muscleGroups.includes(thisMuscleGroupString)) muscleGroups.push(thisMuscleGroupString);
                        }
                    }

                    let usingCoachRestTime = thisExercise.restTime !== undefined && thisExercise.restTime.length > 0;

                    for (var k = 0; k < repArr.length; k++) {
                        let thisRep = repArr[k];
                        thisRep = isNaN(thisRep) || thisRep < 0 ? 0 : thisRep;
                        durationSeconds_temp += 3 * thisRep;
                        if (usingCoachRestTime && thisExercise.restTime && thisExercise.restTime[k] !== undefined && thisExercise.restTime[k] > 0) {
                            durationSeconds_temp += thisExercise.restTime[k]
                        } else {
                            durationSeconds_temp += 60;
                        }
                    }
                    durationSeconds_temp += 120;
                }
            }
        }

        let durationMins = Math.round(durationSeconds_temp / 60);
        let resp_duration = `${durationMins} minute${ durationMins === 1 ? '' : 's'}`;
        setDurationString(resp_duration)

        setMuscleList(tempList);

        var musclesDivTemp = [];
        for (var i = 0; i < muscleGroups.length; i++) {
            let thisM = muscleGroups[i];
            if (musclesDivTemp.length < 3) {
                musclesDivTemp.push(
                    <div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill-${ thisM }` }>
                        <p>{ thisM }</p>
                    </div>
                );
            }
        }

        let resp_muscles_div = <div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-container` }>{musclesDivTemp}</div>;
        setMusclesDiv(resp_muscles_div)

        //return { duration_string: resp_duration, muscles_div: resp_muscles_div };
    }

    function getMuscleGroupList() {
        var groups: String[] = [];

        if (!workout) return [];

        var returnString = '';
        if (workout.exercises !== undefined && workout.exercises.length > 0) {
            for (var i = 0; i < workout.exercises.length; i++) {
                let thisExercise = workout.exercises[i];
                if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                    for (var j = 0; j < thisExercise.muscles.length; j++) {
                        let thisMuscleGroupIndex= thisExercise.muscles[j].group;
                        let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
                        if (!returnString.includes(thisMuscleGroupString)) {
                            groups.push(thisMuscleGroupString);
                            let finalString = `${thisMuscleGroupString}, `;
                            returnString += finalString;
                        }
                    }
                }
            }
        }

        return groups;
    }

    function getExerciseCount() {
        return workout.exercises !== undefined ? workout.exercises.length : 0;
    }

    function getVolume() {
        var vol = 0;
        var vol_sets = 0;
        if (workout.exercises !== undefined) {
            for (var i = 0; i < workout.exercises.length; i++) {
                let thisExercise: Exercise_t = workout.exercises[i];
                if (thisExercise.reps !== undefined) {
                    vol_sets += thisExercise.reps.length;
                    for (var j = 0; j < thisExercise.reps.length; j++) {
                        vol += thisExercise.reps[j];
                    }
                }
            }
        }

        return {vol: vol, vol_sets: vol_sets};
    }

    function getSeries() {
        return workout.exerciseSeries !== undefined ? workout.exerciseSeries : [];
    }

    function switchIsShowing(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        if (thisExercise.id === forExercise.id) {
                            thisExercise.isShowing = thisExercise.isShowing === undefined ? true : !thisExercise.isShowing;
                        } else {
                            thisExercise.isShowing = false;
                        }
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        thisExercise.isShowing = false;
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                }
            })
        );
    }

    function addNewBlock() {
        //setAddExerciseToBlockIndex(-1);
        props.addNewExerciseToWorkoutModalPressed({
            callback: addNewExerciseToSelectedWorkout,
            isBlock: true,
            blockIndex: -1
        })
        //setShowNewExercise(true);
    }

    function addToExistingBlock(blockIndex: number) {
        //setAddExerciseToBlockIndex(blockIndex);

        // get existing number of sets
        var numSets = -1;
        if (exerciseSeries[blockIndex] !== undefined && exerciseSeries[blockIndex].exercises !== undefined && exerciseSeries[blockIndex].exercises[0] !== undefined) {
            let thisExericse = exerciseSeries[blockIndex].exercises[0];
            numSets = thisExericse.reps !== undefined ? thisExericse.reps.length : -1;
        }

        props.addNewExerciseToWorkoutModalPressed({
            callback: addNewExerciseToSelectedWorkout,
            isBlock: false,
            blockIndex: blockIndex,
            forceSetCount: numSets
        })
        //setShowNewExercise(true);
    }

    function removeExerciseSeries(forExerciseSeries: ExerciseSeries_t) {
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.filter((item: ExerciseSeries_t, index: number) => {
                
                if (item.index === forExerciseSeries.index) {
                    return false;
                } else {
                    return true;
                }
            }
        ));
    }

    function removeExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        setWorkoutHasBeenEdited(true);



        if (forExerciseSeries.exercises && forExerciseSeries.exercises.length === 0) {
            setExerciseSeries(exerciseSeries.filter((item: ExerciseSeries_t, index: number) => {
                return item.index !== forExerciseSeries.index
            }))
        } else {
            setExerciseSeries(
                exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                    
                    if (item.index === forExerciseSeries.index) {
                        var seriesTemp = item;
                        var tempExerciseArray: Exercise_t[] = [];
                        for (var i = 0; i < seriesTemp.exercises.length; i++) {
                            let thisExercise: Exercise_t = seriesTemp.exercises[i];
                            if (thisExercise.id !== forExercise.id) {
                                tempExerciseArray.push(thisExercise);
                            }
                        }
                        seriesTemp.exercises = tempExerciseArray;
                        return seriesTemp;
                    } else {
                        return item;
                    }
                }
            ));
        }
    }

    function editedSetContent(exercise: Exercise_t, setIndex: number, toValue: any, reason: string, forExerciseSeries: ExerciseSeries_t) {
        // console.log("editedReps() ->", exercise, setIndex, toValue, `REASON: ${reason}`, forExerciseSeries);
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise: Exercise_t = item.exercises[i];
                        if (thisExercise.id === exercise.id) {
                            switch (reason) {
                                case 'reps':
                                    let repsTemp = thisExercise.reps;
                                    if (repsTemp !== undefined) {
                                        repsTemp![setIndex] = Number(toValue);
                                        // console.log("Adjusting reps to: ", repsTemp, "  From:", thisExercise.reps);
                                        thisExercise.reps = repsTemp;
                                    }
                                    break;
                                case 'pwm':
                                    let pwmsTemp = thisExercise.percent_wms;
                                    if (pwmsTemp !== undefined) {
                                        pwmsTemp![setIndex] = Number(toValue);
                                        // console.log("Adjusting PWMs to: ", pwmsTemp, "  From:", thisExercise.percent_wms);
                                        thisExercise.percent_wms = pwmsTemp;
                                    } else if (thisExercise.reps !== undefined) {
                                        pwmsTemp = [];
                                        for (var i = 0; i < thisExercise.reps!.length; i++) {
                                            pwmsTemp.push(i === setIndex ? Number(toValue) : 0);
                                        }
                                        thisExercise.percent_wms = pwmsTemp;
                                    }
                                    break;
                                case 'tempo':
                                    let temposTemp = thisExercise.tempos;
                                    if (temposTemp !== undefined) {
                                        temposTemp![setIndex] = toValue as Tempo_t;
                                        // console.log("Adjusting PWMs to: ", temposTemp, "  From:", thisExercise.tempos);
                                        thisExercise.tempos = temposTemp;
                                    } 
                                    break;
                                case 'rest':
                                    let restTemp = thisExercise.restTime;
                                    if (restTemp !== undefined) {
                                        restTemp![setIndex] = Number(toValue);
                                        // console.log("Adjusting PWMs to: ", restTemp, "  From:", thisExercise.restTime);
                                        thisExercise.restTime = restTemp;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            
                            
                        } 
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    return item;
                }
            })
        );
    }

    function addSetPressed(exercise: Exercise_t, forExerciseSeries: ExerciseSeries_t) {
        setWorkoutHasBeenEdited(true);

        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise: Exercise_t = item.exercises[i];
                        if (thisExercise.id === exercise.id) {

                            // REPS
                            let repsTemp = thisExercise.reps;
                            if (repsTemp !== undefined && repsTemp.length > 0) {
                                thisExercise.reps!.push(thisExercise.reps![thisExercise.reps!.length - 1]);
                            } else {
                                thisExercise.reps = [12];
                            }

                            // PWMs
                            let pwmsTemp = thisExercise.percent_wms;
                            if (pwmsTemp !== undefined && pwmsTemp.length !== 0) {
                                thisExercise.percent_wms!.push(pwmsTemp[pwmsTemp.length - 1]);
                            } else {
                                pwmsTemp = [0];
                            }

                            // Tempo
                            let temposTemp = thisExercise.tempos;
                            if (temposTemp !== undefined && temposTemp.length !== 0) {
                                thisExercise.tempos!.push(temposTemp[temposTemp.length - 1]);
                            } else {
                                if (repsTemp !== undefined && repsTemp.length > 0) {
                                    for (var i = 0; i < repsTemp.length; i++) {
                                        thisExercise.tempos!.push({concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1});
                                    }
                                } else {
                                    thisExercise.tempos = [{concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1}];
                                }
                                
                            }

                            // Rest time
                            let restTemp = thisExercise.restTime;
                            if (restTemp !== undefined && restTemp.length !== 0) {
                                thisExercise.restTime!.push(restTemp[restTemp.length - 1]);
                            } else {
                                thisExercise.restTime = [-1];
                            }

                        } 
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    return item;
                }
            })
        );
    }

    function removeSetPressed(exercise: Exercise_t, setIndex: number, forExerciseSeries: ExerciseSeries_t) {
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise: Exercise_t = item.exercises[i];
                        if (thisExercise.id === exercise.id) {

                            // REPS
                            let repsTemp = thisExercise.reps;
                            if (repsTemp !== undefined) {
                                repsTemp!.splice(setIndex,1);
                                thisExercise.reps = repsTemp;
                            }

                            // PWMs
                            let pwmsTemp = thisExercise.percent_wms;
                            if (pwmsTemp !== undefined) {
                                pwmsTemp!.splice(setIndex,1);
                                thisExercise.percent_wms = pwmsTemp;
                            } else if (thisExercise.reps !== undefined) {
                                pwmsTemp = [];
                                for (var i = 0; i < thisExercise.reps!.length - 1; i++) {
                                    pwmsTemp.push(0);
                                }
                                thisExercise.percent_wms = pwmsTemp;
                            }

                            // Tempo
                            let temposTemp = thisExercise.tempos;
                            if (temposTemp !== undefined) {
                                temposTemp!.splice(setIndex,1);
                                thisExercise.tempos = temposTemp;
                            } 

                            // Rest time
                            let restTemp = thisExercise.restTime;
                            if (restTemp !== undefined) {
                                restTemp!.splice(setIndex,1);
                                thisExercise.restTime = restTemp;
                            }

                        } 
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    return item;
                }
            })
        );
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function addNewExerciseToSelectedWorkout(addExerciseToBlockIndex: number, exercise?: Exercise_t) {
        //setShowNewExercise(false);
        if (exercise !== undefined) {
            setWorkoutHasBeenEdited(true);
            if (addExerciseToBlockIndex < 0) {
                // Adding a new block
                let thisSeriesIndex = exerciseSeries.length;
                var newExerciseSeries: ExerciseSeries_t = {
                    index: thisSeriesIndex,
                    title: getSetLetter(thisSeriesIndex),
                    exercises: [exercise]
                };
                setExerciseSeries(exerciseSeries.concat(newExerciseSeries));
            } else {
                // Adding to block at specified index
                let newNumSets = exercise && exercise.reps ? exercise.reps.length : 0;
                setExerciseSeries(exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                    if (index === addExerciseToBlockIndex) {
                        let seriesTemp = item;
                        var newExerciseListTemp = seriesTemp.exercises ?? [];
                        for (var i = 0; i < newExerciseListTemp.length; i++) {
                            let thisExerciseTemp = newExerciseListTemp[i];
                            if (newNumSets > 0 && thisExerciseTemp) {
                                var newRepArry = [];
                                let existingRepArry = thisExerciseTemp.reps ?? [];
                                for (var j = 0; j < newNumSets; j++) {
                                    let thisNumReps = existingRepArry[j] ?? ( existingRepArry[existingRepArry.length - 1] ?? 12 );
                                    newRepArry.push(thisNumReps)
                                }
                                newExerciseListTemp[i].reps = newRepArry;
                            } else {

                            }
                        }
                        newExerciseListTemp.push(exercise)
                        seriesTemp.exercises = newExerciseListTemp;
                        return seriesTemp;
                    } else {
                        return item;
                    }
                }));
            }
        }
    }

    function revertToOriginal() {
        setExerciseSeries([]);
        setExerciseSeries(JSON.parse(JSON.stringify(exerciseSeries_original)));
        setWorkoutHasBeenEdited(false);
    }

    function save() {
        setIsSaving(true);
        setWorkoutHasBeenEdited(false);

        let database = firebase.database();
        let ref = database.ref(`workouts/${workout.id}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let workoutData = snapshot.val();    // Workout object from db

                // re-compose exercise list
                var totalRepCount = 0;
                var totalSetCount = 0;
                var restTimes: string[] = [];
                var durationSeconds = 0;
                var tagsFinal: string[] = [];
                var exerciseListFinal: any[] = [];
                var exerciseList_ts: Exercise_t[] = [];
                var exerciseSeriesFinal: any[] = [];

                let exerciseSeriesList = exerciseSeries;
                if (exerciseSeriesList.length > 0) {
                    for (var series_index = 0; series_index < exerciseSeriesList.length; series_index++) {
                        let thisSeries: ExerciseSeries_t = exerciseSeriesList[series_index];
                        let thisSeriesExerciseList = thisSeries.exercises;

                        let offsetASCIICode = series_index + 65;
                        let seriesChar = String.fromCharCode(offsetASCIICode);

                        var thisSeriesExerciseJSONList: any[] = [];

                        if (thisSeriesExerciseList.length > 0) {
                            for (var exercise_index = 0; exercise_index < thisSeriesExerciseList.length; exercise_index++) {
                                let thisExercise: Exercise_t = thisSeriesExerciseList[exercise_index];

                                // 1. Sum the rep and set counts, and sum total duration from activity
                                let thisExerciseSetCount = thisExercise.reps !== undefined ? thisExercise.reps.length : 0;
                                totalSetCount += thisExerciseSetCount;
                                if (thisExerciseSetCount > 0) {
                                    for (var j = 0; j < thisExerciseSetCount; j++) {
                                        totalRepCount += thisExercise.reps !== undefined ? thisExercise.reps[j] : 0;
                                        durationSeconds += thisExercise.reps !== undefined ? thisExercise.reps[j] * 3 : 36;
                                    }
                                }

                                // 2. Calculate rest times for exercise and sum total duration from rest
                                let rests = thisExercise.restTime;
                                let restCount = rests !== undefined ? rests.length : 0;
                                var restSum = 0;
                                var containsNullRestTime = false;
                                if (rests !== undefined && restCount > 0) {
                                    for (var j = 0; j < restCount; j++) {
                                        if (rests[j] === -1) { 
                                            containsNullRestTime = true; 
                                            durationSeconds += 45;
                                        } else {
                                            durationSeconds += rests[j];
                                        }
                                        restSum += rests[j];
                                    }
                                }
                                let avgExerciseRest = restCount > 0 && containsNullRestTime === false ? Math.ceil(restSum / restCount) : -1;
                                restTimes.push(String(avgExerciseRest));

                                // 3. Tempo JSON list
                                var tempoJSONList: any[] = [];
                                if (thisExercise.tempos !== undefined && thisExercise.tempos.length > 0) {
                                    for (var j = 0; j < thisExercise.tempos.length; j++) {
                                        let thisTempo: Tempo_t = thisExercise.tempos[j];
                                        let thisTempoJSONObj = {
                                            concentric: thisTempo.concentric,
                                            first_pause: thisTempo.first_pause,
                                            eccentric: thisTempo.eccentric,
                                            second_pause: thisTempo.second_pause
                                        };
                                        tempoJSONList.push(thisTempoJSONObj);
                                    }
                                }

                                // 4. Create JSON formatted exercise for exercise list
                                let thisExerciseJSON: any = {
                                    created_by: {
                                        id: thisExercise.createdBy !== undefined ? thisExercise.createdBy.id : 'eigen_fit',
                                        name: thisExercise.createdBy !== undefined ? thisExercise.createdBy.name : 'Eigen Fit'
                                    },
                                    key: thisExercise.id,
                                    equipment: thisExercise.equipment !== undefined ? thisExercise.equipment : {bar: -1, bench: -1, cable_attachment: -1, machine: -1},
                                    muscles: thisExercise.muscles ?? [],
                                    reps: thisExercise.reps !== undefined ? thisExercise.reps : [],
                                    setup: thisExercise.setup !== undefined ? thisExercise.setup : 'No setup specified',
                                    steps: thisExercise.steps !== undefined ? thisExercise.steps : [],
                                    tags: thisExercise.tags !== undefined ? thisExercise.tags : [],
                                    title: thisExercise.title,
                                    type: thisExercise.type,
                                    percent_wms: thisExercise.percent_wms !== undefined ? thisExercise.percent_wms : [],
                                    rest_times: thisExercise.restTime !== undefined ? thisExercise.restTime : [],
                                    tempos: tempoJSONList,
                                    rep_ranges: thisExercise.repRanges !== undefined ? thisExercise.repRanges : [],
                                }

                                if (thisExercise.note !== undefined) {
                                    thisExerciseJSON['note'] = thisExercise.note;
                                }

                                if (thisExercise.loadingPattern !== undefined) {
                                    thisExerciseJSON['loading_pattern'] = thisExercise.loadingPattern;
                                }

                                exerciseListFinal.push(thisExerciseJSON);

                                exerciseList_ts.push(thisExercise);


                                // 5. Create JSON formatted series for series list
                                let thisExerciseForSeriesJSON = {
                                    title: `${seriesChar}${exercise_index + 1}`,
                                    index: exercise_index,
                                    id: thisExercise.id
                                };
                                thisSeriesExerciseJSONList.push(thisExerciseForSeriesJSON);

                                // 6. Create tags list
                                if (thisExercise.tags !== undefined && thisExercise.tags.length > 0) {
                                    for (var j = 0; j < thisExercise.tags.length; j++) {
                                        let thisTag = thisExercise.tags[j];
                                        if (tagsFinal.indexOf(thisTag) < 0) {
                                            tagsFinal.push(thisTag);
                                        }
                                    }
                                }
                            }
                        }

                        let thisSeriesJSON = {
                            title: seriesChar,
                            index: series_index,
                            type: thisSeriesExerciseJSONList.length > 1 ? 1 : 0,
                            exercises: thisSeriesExerciseJSONList
                        };
                        exerciseSeriesFinal.push(thisSeriesJSON);
                    }
                }

                let durationMinutes = Math.ceil(durationSeconds / 60);
                var editedWorkout: any = JSON.parse(JSON.stringify(workoutData));

                editedWorkout['exercises'] = exerciseListFinal;
                editedWorkout['exercise_series'] = exerciseSeriesFinal;
                editedWorkout['duration'] = durationMinutes;
                editedWorkout['total_reps'] = totalRepCount;
                editedWorkout['total_sets'] = totalSetCount;
                editedWorkout['rest_times'] = restTimes;

                // console.log(editedWorkout);

                ref.set(editedWorkout);

                props.workoutUpdated(exerciseSeries);

                setTimeout(() => {
                    setIsSaving(false);
                }, 300);
            }
        });
    }

    //***** DRAG RELATED FUNCTIONS *****
    function dragPosUpdated(x: number, y: number, item: ExerciseSeries_t, index: number) {

        if (document === null) { return; }
        let thisBlockDiv = document.getElementById(`block-${index}`);
        if (thisBlockDiv === null) { return; }

        let cellHeight_block = 57;
        let cellHeight_exercise = 57;

        var bounds = thisBlockDiv.getBoundingClientRect();
        let min_bound = bounds.top;
        let max_bound = bounds.bottom;
        let isInsideBlock = y >= min_bound && y <= max_bound;

        let blockSpacing = 16;
        for (var i = 0; i < exerciseSeries.length; i++) {
            let thisSeries: ExerciseSeries_t = exerciseSeries[i];
            let thisBlockDiv_new = document.getElementById(`block-${i}`);
            if (thisBlockDiv_new !== null) { 
                let bounds_new = thisBlockDiv_new.getBoundingClientRect();
                let min_bound_new = bounds_new.top;
                let max_bound_new = bounds_new.bottom;
                let isInsideBlock_new = y >= min_bound_new && y <= max_bound_new;
                let isAboveBlock = y < min_bound_new && y > min_bound_new - blockSpacing;
                if (isInsideBlock_new) {
                    setPurposedMerge(true);
                    setPurposedIndex(i);
                    if (thisSeries.exercises !== undefined && thisSeries.exercises.length > 0) {
                        let yPosInBlock_new = y - min_bound_new;
    
                        let yPosRemainder_new = yPosInBlock_new - cellHeight_block;
                        var divisionIndex_new = Math.floor(yPosRemainder_new / cellHeight_exercise);
                        divisionIndex_new = divisionIndex_new < 0 ? 0 : divisionIndex_new;
                        divisionIndex_new = divisionIndex_new > thisSeries.exercises.length - 1 ? thisSeries.exercises.length - 1 : divisionIndex_new;
                        setPurposedIndex_exercise(divisionIndex_new);
                    } else {
                        setPurposedIndex_exercise(-1);
                    }

                    
                } else if (isAboveBlock) {
                    setPurposedMerge(false);
                    setPurposedIndex(i);
                    setPurposedIndex_exercise(-1);
                }
            }
        }
    }

    function cellPicked(item: ExerciseSeries_t, index: number) {
        setPurposedIndex(index);
        setPurposedIndex_exercise(-1);
    }

    function cellDropped(item: ExerciseSeries_t, index: number) {
        if (index < 0) { 
            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            setPurposedMerge(false);
            return; 
        }

        if (purposedIndex === index) {
            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            setPurposedMerge(false);
            return; 
        }
        

        if (purposedMerge) {
            var currentSeriesList: ExerciseSeries_t[] = JSON.parse(JSON.stringify(exerciseSeries));
            var newSeriesList_b: ExerciseSeries_t[] = JSON.parse(JSON.stringify(currentSeriesList));


            // 1. Add exercises to purposed block
            let currentExercises: Exercise_t[] = currentSeriesList[index].exercises;

            var series_temp: ExerciseSeries_t = currentSeriesList[purposedIndex];
            var exerciseList_temp: Exercise_t[] = series_temp.exercises;
            let numRepsOfExistingExercise = exerciseList_temp && exerciseList_temp[0] && exerciseList_temp[0].reps ? exerciseList_temp[0].reps.length : 0;

            for (var i = 0; i < currentExercises.length; i++) {
                let thisExerciseTemp = currentExercises[i];
                let newExerciseReps: number[] = thisExerciseTemp.reps ?? [];
                var thisExerciseTempReps: number[] = [];
                for (var j = 0; j < numRepsOfExistingExercise; j++) {
                    let thisNumReps = newExerciseReps[j] ?? ( newExerciseReps[newExerciseReps.length - 1] ?? 12 );
                    thisExerciseTempReps.push(thisNumReps)
                }
                thisExerciseTemp.reps = thisExerciseTempReps;
                exerciseList_temp.push(thisExerciseTemp);
            }

            if (currentExercises.length === 1) {
                // Single exercise block -- add this exercise to the purposed location
                let purposedIndex_exercise_adj = purposedIndex_exercise < 0 ? 0 : purposedIndex_exercise;
                //exerciseList_temp.push(currentExercises[0]);
                exerciseList_temp = array_move_open(exerciseList_temp, exerciseList_temp.length - 1, purposedIndex_exercise_adj);
                
            } else {

            }
            series_temp.exercises = exerciseList_temp;
            newSeriesList_b[purposedIndex] = series_temp;
            
            // Remove original series
            var newSeriesList: ExerciseSeries_t[] = [];
            for (var i = 0; i < newSeriesList_b.length; i++) {
                if (i !== index) {
                    newSeriesList.push(newSeriesList_b[i]);
                }
            }
            //newSeriesList_b.filter((item: ExerciseSeries_t, atIndex: number) => { return atIndex !== index; })
            setWorkoutHasBeenEdited(true);
            setExerciseSeries(newSeriesList);
            //props.exerciseSeriesListUpdated(newSeriesList);

            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            setPurposedMerge(false);
            return;
        }

        
        var currentSeriesList: ExerciseSeries_t[] = exerciseSeries;
        var newSeriesList: ExerciseSeries_t[] = array_move_open(currentSeriesList, index, purposedIndex);
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(newSeriesList);
        //props.exerciseSeriesListUpdated(newSeriesList);
                
        setPurposedIndex(-1);
        setPurposedIndex_exercise(-1);
    }

    function cellPicked_exercise(item: ExerciseSeries_t, index: number, exercise_index: number) {
        setPurposedIndex(-1);
        setPurposedIndex_exercise(exercise_index);
    }

    function cellDropped_exercise(item: ExerciseSeries_t, index: number, exercise_index: number) {

        if (purposedMerge === true) {
            // Will merge into existing block
            if (purposedIndex_exercise === -1) {
                // no bueno for now
            } else {
                var currentSeriesList: ExerciseSeries_t[] = JSON.parse(JSON.stringify(exerciseSeries));
                var newSeriesList_b = JSON.parse(JSON.stringify(currentSeriesList));

                // 1. Add this exercise to purposed location
                // 1.a) Get exercise
                let movedExercise: Exercise_t = currentSeriesList[index].exercises[exercise_index];
                

                // 1.b) Push exercise to purposed location list
                var newSeries_temp: ExerciseSeries_t = currentSeriesList[purposedIndex];
                
                // 1.b.2) Ensure the new exercise conforms to the existing number of sets
                let numRepsOfExistingExercise = newSeries_temp.exercises && newSeries_temp.exercises[0] && newSeries_temp.exercises[0].reps ? newSeries_temp.exercises[0].reps.length : 0;
                var newExerciseRepList: number[] = [];
                let newExerciseReps: number[] = movedExercise.reps ?? [];
                for (var j = 0; j < numRepsOfExistingExercise; j++) {
                    let thisNumReps = newExerciseReps[j] ?? ( newExerciseReps[newExerciseReps.length - 1] ?? 12 );
                    newExerciseRepList.push(thisNumReps)
                }
                movedExercise.reps = newExerciseRepList;
                newSeries_temp.exercises.push(movedExercise);

                // 1.c) Move exercise just pushed to end of list into purposedIndex_exercise
                newSeries_temp.exercises = array_move_open(newSeries_temp.exercises, newSeries_temp.exercises.length - 1, purposedIndex_exercise)
                // 1.d) Update list
                newSeriesList_b[purposedIndex] = newSeries_temp;//.exercises = newExerciseList;

                // 2. Remove exercise from original block
                var ogSeries: ExerciseSeries_t = currentSeriesList[index];
                var ogSeriesExerciseList: Exercise_t[] = ogSeries.exercises;
                var ogSeriesExerciseList_temp: Exercise_t[] = [];
                for (var i = 0; i < ogSeriesExerciseList.length; i++) {
                    if (i !== exercise_index) {
                        ogSeriesExerciseList_temp.push(ogSeriesExerciseList[i]);
                    }
                }
                ogSeries.exercises = ogSeriesExerciseList_temp;

                newSeriesList_b[index] = ogSeries;

                // console.log("newSeriesList_b:", newSeriesList_b);
                setWorkoutHasBeenEdited(true);
                setExerciseSeries(newSeriesList_b);
                //props.exerciseSeriesListUpdated(newSeriesList_b);

                setPurposedMerge(false);
                setPurposedIndex(-1);
                setPurposedIndex_exercise(-1);
            }

            return;
        }
        // console.log("NO MERGE")
        if (purposedIndex_exercise === -1) {
            // Move to create new block
            let newSeries: ExerciseSeries_t = {
                title: getSetLetter(purposedIndex),
                index: purposedIndex,
                exercises: [item.exercises[exercise_index]],
                type: 0, // 0 = single exercise, 1 = superset
                isShowing: false
            }

            let oldSeries: ExerciseSeries_t = item;
            oldSeries.exercises.splice(exercise_index, 1);

            var currentSeriesList: ExerciseSeries_t[] = exerciseSeries;
            currentSeriesList[index] = oldSeries;
            currentSeriesList.push(newSeries);



            var newSeriesList_a: ExerciseSeries_t[] = array_move_open(currentSeriesList, currentSeriesList.length - 1, purposedIndex);
            setWorkoutHasBeenEdited(true);

            for (var i = 0; i < newSeriesList_a.length; i++) {
                newSeriesList_a[i].title = getSetLetter(i);
                newSeriesList_a[index].index = i;
            }
            setExerciseSeries(newSeriesList_a);
            //props.exerciseSeriesListUpdated(newSeriesList_a);

            setPurposedMerge(false);
            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            return;       
        }

        if (index === purposedIndex) {
            // Moved exercise within block
            var currentSeriesList: ExerciseSeries_t[] = exerciseSeries;
            let edittedSeries = currentSeriesList[index];
            let exercisesTemp = array_move_open(edittedSeries.exercises, exercise_index, purposedIndex_exercise);
            //edittedSeries.exercises = exercisesTemp;
            var newSeriesList = JSON.parse(JSON.stringify(currentSeriesList));
            newSeriesList[index].exercises = exercisesTemp
            //var newSeriesList: ExerciseSeries_t[] = array_move_open(currentSeriesList, index, purposedIndex);
            setWorkoutHasBeenEdited(true);
            setExerciseSeries(newSeriesList);
            //props.exerciseSeriesListUpdated(newSeriesList);
        } else {
        }

        setPurposedMerge(false);
        setPurposedIndex(-1);
        setPurposedIndex_exercise(-1);
    }

    function array_move_open(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    function dragPosUpdated_exercise(x: number, y: number, item: ExerciseSeries_t, index: number, exercise_index: number) {
        if (document === null) { return; }
        let thisBlockDiv = document.getElementById(`block-${index}`);
        if (thisBlockDiv === null) { return; }

        let cellHeight_block = 57;
        let cellHeight_exercise = 57;

        var bounds = thisBlockDiv.getBoundingClientRect();
        let min_bound = bounds.top;
        let max_bound = bounds.bottom;
        let isInsideBlock = y >= min_bound && y <= max_bound;

        if (y === 0) return;

        if (isInsideBlock) {
            let yPosInBlock = y - min_bound;
            
            let yPosRemainder = yPosInBlock - cellHeight_block;
            var divisionIndex = Math.floor(yPosRemainder / cellHeight_exercise);
            divisionIndex = divisionIndex < 0 ? 0 : divisionIndex;
            divisionIndex = divisionIndex > item.exercises.length - 1 ? item.exercises.length - 1 : divisionIndex;
            setPurposedIndex(index);
            setPurposedIndex_exercise(divisionIndex);
            setPurposedMerge(false); // CHANGED setPurposedMerge(false);
        } else {
            //// console.log("OUTSIDE");
            if (exerciseSeries.length === 1) {
                setPurposedIndex_exercise(-1);
                let isBelowBlock = y > max_bound;
                if (isBelowBlock) {
                    setPurposedIndex(1);
                } else {
                    setPurposedIndex(0);
                }
                setPurposedMerge(false);
            } else {
                let blockSpacing = 16;
                for (var i = 0; i < exerciseSeries.length; i++) {
                    let thisSeries: ExerciseSeries_t = exerciseSeries[i];
                    let thisBlockDiv_new = document.getElementById(`block-${i}`);
                    if (thisBlockDiv_new !== null) { 
                        let bounds_new = thisBlockDiv_new.getBoundingClientRect();
                        let min_bound_new = bounds_new.top;
                        let max_bound_new = bounds_new.bottom;
                        let isInsideBlock_new = y >= min_bound_new && y <= max_bound_new;
                        let isAboveBlock = y < min_bound_new && y > min_bound_new - blockSpacing;
                        if (isInsideBlock_new) {
                            setPurposedMerge(true);
                            setPurposedIndex(i);
                            if (thisSeries.exercises !== undefined && thisSeries.exercises.length > 0) {
                                let yPosInBlock_new = y - min_bound_new;
            
                                let yPosRemainder_new = yPosInBlock_new - cellHeight_block;
                                var divisionIndex_new = Math.floor(yPosRemainder_new / cellHeight_exercise);
                                divisionIndex_new = divisionIndex_new < 0 ? 0 : divisionIndex_new;
                                divisionIndex_new = divisionIndex_new > thisSeries.exercises.length - 1 ? thisSeries.exercises.length - 1 : divisionIndex_new;
                                setPurposedIndex_exercise(divisionIndex_new);
                            } else {
                                setPurposedIndex_exercise(-1);
                            }

                            
                        } else if (isAboveBlock) {
                            setPurposedMerge(false);
                            setPurposedIndex(i);
                            setPurposedIndex_exercise(-1);
                        }
                    }
                }
            }

        }
        //// console.log(isInsideBlock ? "Inside" : "Outside");

    }

    function editExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        let dataObj= {
            callback: saveEditedExercise,
            isBlock: false,
            blockIndex: -1,
            isEditing: true,
            forExerciseSeries: forExerciseSeries,
            forExercise: forExercise
        };
        props.addNewExerciseToWorkoutModalPressed(dataObj);    
    }

    function saveEditedExercise(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, newExercise: Exercise_t) {
        setWorkoutHasBeenEdited(true);

        let newNumSets = newExercise.reps ? newExercise.reps.length : 0;


        setExerciseSeries(exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
            if (item.title === forExerciseSeries.title) {
                let exerciseListTemp = item.exercises ?? [];
                for (var i = 0; i < exerciseListTemp.length; i++) {
                    let thisExerciseTemp = exerciseListTemp[i]
                    if (thisExerciseTemp && thisExerciseTemp.id === forExercise.id) {
                        exerciseListTemp[i] = newExercise;
                    } else if (thisExerciseTemp) {
                        // Force all other exercises in this superset to have the same number of sets as this one
                        let thisRepArr = thisExerciseTemp.reps ?? [];
                        if (newNumSets > 0 && newNumSets !== thisRepArr.length) {
                            var newRepArry: number[] = [];
                            for (var j = 0; j < newNumSets; j++) {
                                let thisRepNum = thisRepArr[j] ?? thisRepArr[thisRepArr.length - 1];
                                newRepArry.push(thisRepNum);
                            }
                            exerciseListTemp[i] = { ...thisExerciseTemp, reps: newRepArry }
                        }

                    } else {

                    }
                }
                return { ...item, exercises: exerciseListTemp };
            } else {
                return item;
            }
        }));

    }

	return (
		<div className="workout-plan-list-plan-workout">
            <div hidden={!workoutHasBeenEdited} className="assign-modal-body-row-edit-plan-header-button-container workout-plan-list-plan-workout-header-card-header">
                <div onClick={() => revertToOriginal()} className="assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-dark">
                    <p>Revert & Cancel</p>
                </div>
                <div onClick={() => save()} className={`assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-blue workout-plan-list-plan-body-header-button-blue ${!workoutHasBeenEdited ? 'assign-modal-body-row-edit-plan-header-button-blue-unselected' : ''}`}>
                    <p>Save Changes</p>
                </div>
            </div>
            <div className="workout-plan-list-plan-workout-body-container">
                <div className="workout-plan-list-plan-header-card-body">
                    <div className="workout-plan-list-plan-header-card-section-title-container">
                        <h4>Workout Details</h4>
                        <p>{workout.description === undefined || workout.description === "" ? "No description of this workout was provided" : workout.description}</p>
                    </div>
                    <div className="workout-plan-list-plan-header-card-body-row-container">
                        <div className="workout-plan-list-plan-header-card-body-row">
                            <div className="workout-plan-list-plan-header-card-body-col">
                                <p>Volume</p>
                                <h4>{getVolume().vol_sets} sets, {getVolume().vol} reps</h4>
                            </div>
                            <div className="workout-plan-list-plan-header-card-body-col">
                                <p>Muscles</p>
                                <div>{ musclesDiv }</div>
                            </div>
                        </div>
                        <div className="workout-plan-list-plan-header-card-body-row">
                            <div className="workout-plan-list-plan-header-card-body-col">
                                <p>Exercise Count</p>
                                <h4>{getExerciseCount()} Exercise{getExerciseCount() === 1 ? '' : 's'}, {getSeries().length === 0 ? "No" : getSeries().length} Series</h4>
                            </div>
                            <div className="workout-plan-list-plan-header-card-body-col">
                                <p>Target Duration</p>
                                <h4>{ durationString }</h4>
                            </div>
                        </div>
                    </div>
                    <div className={ "workout-plan-list-plan-header-card-menu-container" }>
                        {
                            pageOptionTitles.map((item: string, index: number) => (
                                <div className={ `workout-plan-list-plan-header-card-menu-item ${ pageIndex === index ? "workout-plan-list-plan-header-card-menu-item-selected" : ""}` } onClick={ () => setPageIndex(index) } key={`phase-menu-option-${item}`}>
                                    <p>{ item }</p>
                                    <div hidden={ pageIndex !== index } className={ "workout-plan-list-plan-header-card-menu-selected-bar" }></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div hidden={isSaving || pageIndex !== 0} className="workout-plan-list-plan-body-card workout-plan-list-plan-workout-body-card">
                    {/*<div className="workout-plan-list-plan-header-card-header workout-plan-list-plan-body-card-header workout-plan-list-plan-workout-body-card-header">  
                        <div className="workout-plan-list-plan-body-card-header-text">
                            <h4>Exercise Composition</h4>
                        </div>
                        
                    </div>*/}
                    <div className="workout-plan-list-plan-body-card-header">
                        <div className={ "workout-plan-list-plan-body-card-header-icon-wrapper" }>
                            <div className="workout-plan-list-plan-body-card-header-icon-container workout-plan-list-plan-body-card-header-icon-container-workout">
                                <div className={ "workout-plan-list-plan-body-card-header-icon-workout" }></div>
                                {/*<img className="workout-plan-list-plan-body-card-header-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/phase.png`}/>*/}
                            </div>
                        </div>
                        <div className={ "workout-plan-list-plan-body-card-header-content-wrapper" }>
                            <div className="workout-plan-list-plan-body-card-header-text-container workout-plan-list-plan-body-card-header-text-container-workout">
                                <h4><span>{ workout ? workout.title : "Unnamed workout"}</span> Exercise Composition</h4>
                            </div>
                            <div className={ "workout-plan-list-plan-body-card-header-phase-count-text-container" }>
                                <p>{workout && workout.exerciseSeries ? workout.exerciseSeries.length : 0} Blocks</p>
                            </div>
                        </div>
                    </div>
                    <div className="workout-plan-list-plan-body-card-content workout-plan-list-plan-workout-body-card-content">
                        { exerciseSeries.map((item: ExerciseSeries_t, index: number) => (
                            <div className="workout-plan-list-plan-workout-body-card-content-series-cell-container">
                                
                                <div hidden={purposedIndex !== index || purposedMerge === true} className="create-workout-exercise-list-cell-purposed-container"/>
                                <div className={ "workout-plan-list-plan-workout-body-card-content-series-cell-wrapper" }>
                                    <div className={ "workout-plan-list-plan-workout-body-card-content-series-cell-branch-container" }>
                                         <div className={ "workout-plan-list-plan-workout-body-card-content-series-cell-branch" }/>
                                    </div>
                                    <div className={ "workout-plan-list-plan-workout-body-card-content-series-cell-branch-dot" }></div>
                                    <div className={ "workout-plan-list-plan-workout-body-card-content-series-cell-wrapper-cell" }>
                                        <CreateWorkoutExerciseListCell
                                            key={`${item.title}-${index}`}
                                            isEditable={false}
                                            quickAdd={true}
                                            exerciseSeries={item}
                                            exerciseSeriesIndex={index}
                                            // -- Dragging related functions
                                            purposedIndex={purposedIndex}
                                            purposedIndex_exercise={purposedIndex_exercise}
                                            purposedMerge={purposedMerge}
                                            dragPosUpdated={(x: number, y: number) => dragPosUpdated(x,y,item,index)}
                                            dragPosUpdated_exercise={(x: number, y: number, ex_i: number) => dragPosUpdated_exercise(x,y,item,index,ex_i)}
                                            cellPicked={() => cellPicked(item, index)}
                                            cellDropped={() => cellDropped(item, index)}
                                            cellPicked_exercise={(ex_i: number) => cellPicked_exercise(item, index, ex_i)}
                                            cellDropped_exercise={(ex_i: number) => cellDropped_exercise(item, index, ex_i)}
                                            // -- End dragging related functions
                                            maxExerciseSeriesIndex={exerciseSeries.length - 1}
                                            moveExerciseSeriesUp={(e: number) => null}
                                            moveExerciseSeriesDown={(e: number) => null}
                                            moveUpExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
                                            moveDownExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
                                            editExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t) => editExercisePressed(eS, e)}
                                            removeExerciseSeries={(eS: ExerciseSeries_t, i: number) => removeExerciseSeries(eS)}
                                            addToSupersetPressed={() => addToExistingBlock(index)}
                                            switchIsShowing={(e: Exercise_t) => switchIsShowing(item, e)}
                                            hideEditingFeatures={false}
                                            removeExercisePressed={(exercise: Exercise_t) => removeExercisePressed(item, exercise)}
                                            addSetPressed={(exercise: Exercise_t) => addSetPressed(exercise, item)}
                                            removePressed={(exercise: Exercise_t, setIndex: number) => removeSetPressed(exercise, setIndex, item)}
                                            editedReps={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'reps', item)}
                                            editedPWM={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'pwm', item)}
                                            editedTempo={(exercise: Exercise_t, setIndex: number, val: Tempo_t) => editedSetContent(exercise, setIndex, val, 'tempo', item)}
                                            editedRest={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'rest', item)}
                                        />
                                    </div>
                                </div>
                        </div>
                        ))}
                        <div hidden={purposedIndex !== exerciseSeries.length} className="create-workout-exercise-list-cell-purposed-container"/>
                        <div className="assign-modal-body-add-button-container">
                            <div onClick={() => addNewBlock()} className="assign-modal-body-add-button">
                                <p>+ Add another Block</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={isSaving || pageIndex !== 1 } className="workout-plan-list-plan-body-card">
                        <div className="workout-plan-list-plan-header-card-header workout-plan-list-plan-body-card-header-noborder">  
                            <div className="workout-plan-list-plan-body-card-header-text">
                                <h4>Working Muscles</h4>
                                <p>({muscleList.length})</p>
                            </div>
                        </div>
                        <div className="workout-plan-list-plan-body-card-header-tag-container">
                            <h4>Groups</h4>
                                {
                                    getMuscleGroupList().map((item: String, index: number) => (
                                        <div className="workout-plan-list-plan-body-card-header-tag">
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        <div className="workout-plan-list-plan-body-card-content-body">
                            <div className="workout-plan-list-plan-muscle-selector-muscle-images-container">
                                {
                                    muscleList.map((item: Muscles_t, index: number) => (
                                        <div className="workout-plan-list-plan-muscle-selector-body-img-container">
                                            <img className="workout-plan-list-plan-muscle-selector-body-img" src={`${process.env.PUBLIC_URL}/assets/images/body_muscles/body-${item.group}-${item.muscle}.png`}/>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="workout-plan-list-plan-muscle-selector-empty-body-container">
                                <img className="workout-plan-list-plan-muscle-selector-empty-body-img" src={`${process.env.PUBLIC_URL}/assets/images/body_muscles/body-blank.png`}/>
                            </div>
                        </div>
                    </div>
                    <div className="workout-plan-list-plan-card-spacer"/>
                <div hidden={!isSaving} className="workout-plan-list-plan-body-card workout-plan-list-plan-workout-body-card workout-plan-list-plan-workout-body-loading">
                    <div className="client-list-container-loading-spinner"/>
                    <p>Saving changes...</p>
                </div>
            </div>
		</div>
	)
}

export default WorkoutPlanListPlanWorkout;