import React, { useEffect, useState } from "react"
import firebase from '../../Firebase';

import './ChatPreview.css'
import dateTimeParser from "../ClientProfile/Chat/dateTimeParser";


const ChatPreview = (props: any) => {

    const [imgUrl, setImgUrl] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientInitials, setClientInitials] = useState("");

    useEffect(() => {
        if (props.userUID && props.userUID !== "") getImage(props.userUID);
    }, [props.userUID])

    useEffect(() => {
        if (!props.clientName || props.clientName === "") {
            setClientInitials("--");
            return;
        }
        let names = props.clientName.split(" ");
        if (names && names.length > 1) {
            let fName = names[0];
            let lName = names[names.length - 1];

            let fName_letters = fName.split("");
            let fName_fLetter = fName_letters && fName_letters.length > 0 ? fName_letters[0] : "";

            let lName_letters = lName.split("");
            let lName_fLetter = lName_letters && lName_letters.length > 0 ? lName_letters[0] : "";

            let initials = `${fName_fLetter}${lName_fLetter}`
            setClientInitials(initials);
        }
    }, [props.clientName])

    function getImage(withUID: string) {
        if (props.userUID === undefined) { return; }
        const storage = firebase.storage();
        // console.log(props.userUID);
        storage.ref('profileImages').child(withUID).child('profileImage/image.jpg').getDownloadURL()
        .then(fireBaseUrl => {
            setImgUrl(fireBaseUrl);
        })
        .catch(error => {
            // console.log('No image found');
            // console.log(error);
        })
    }

    const handleClick = () => {
        props.setSelectedChat(props.index);
    }

    return (
        <button className={ `chat-preview-button ${ props.isSelected ? 'chat-preview-button-selected' : '' }` } onClick={handleClick}>
            <div className="chat-preview-container">
                <div className="chat-preview-items-container">
                    <div className="chat-preview-profile-icon-username-container">
                        <div hidden={ !props.unread } className={ "chat-preview-profile-icon-unread-dot" }></div>
                        <div className="chat-preview-profile-icon-container">
                            {
                                !imgUrl || imgUrl === "" ?
                                <h4>{clientInitials}</h4>
                                :
                                <img className="chat-preview-profile-icon-image" src={imgUrl}/>
                            }
                        </div>
                        <div className={ "chat-preview-profile-info-container" }>
                            <div className="chat-preview-username-container">
                                <p>{props.clientName}</p>
                            </div>
                            <div className="chat-preview-timestamp-container">
                                <p>{dateTimeParser(props.timestamp)}</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/*<div className={props.unread ? "chat-preview-text-container-unread" : "chat-preview-text-container"}>
                    <p>{props.message.length > 100 ?
                        `${props.message.substring(0, 100)}...` :
                        props.message}</p>
                </div>*/}
            </div>
        </button>
    )
}
export default ChatPreview
