
import React, { useState, useEffect } from 'react';
import './PerformanceFilterCard.css';

import ScoringMetricRow from './ScoringMetricRow';
import ExerciseBreakdownRow from './ExerciseBreakdownRow';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function PerformanceFilterCard(props: any) {

    const [exerciseList, setExerciseList] = useState<any[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<any | undefined>(undefined);
    const [selectedSetIndex, setSelectedSetIndex] = useState(-1);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.exerciseList) {
        	setExerciseList(props.exerciseList);
        	setSelectedExercise(props.exerciseList[0] ?? undefined);
        }
    }, [props.exerciseList])

    useEffect(() => {
    	setSelectedExercise(props.selectedExercise);
	}, [props.selectedExercise])

	useEffect(() => {
    	setSelectedSetIndex(props.selectedSetIndex);
	}, [props.selectedSetIndex])

    const initialize = () => {
        
    }

    const handleExerciseSelected = (ex: any) => {
    	props.updateSelectedExercise(ex);
    }

    const handleSetSelected = (i: number, ex: any) => {
    	props.updateSelectedSetIndex(i);
    	props.updateSelectedExercise(ex);
    }



	return (
		<div className={ "performance-filter-card" }>
			<div className={ "performance-filter-card-header" }>
                <h4>Filter Performance Data</h4>
            </div>
            {
            	exerciseList.length === 0 ?
            	<div className={ "performance-filter-card-body-empty-container" }>
            		<p>No exercises completed</p>
            	</div>
            	:
            	<div className={ "performance-filter-card-body-container" }>
	                <div className={ "performance-filter-card-body-content" }>
	                    {
	                        exerciseList.map((item: any, index: number) => (
	                            <div className={ "performance-filter-card-row" }>
	                           		<div className={ "performance-filter-card-row-header" } onClick={ () => handleExerciseSelected(item) }>
		                            	<div className={ `performance-filter-card-row-header-selected-icon ${selectedExercise && selectedExercise.id === item.id ? "performance-filter-card-row-header-selected-icon-selected" : "" }` }></div>
		                            	<h4>{ item.title }</h4>
		                            </div>
		                            <div className={ "performance-filter-card-row-set-container" } hidden={ !item.sets || item.sets.length < 1 }>
		                            	<div className={ `performance-filter-card-row-set-pill ${selectedExercise && selectedExercise.id === item.id && selectedSetIndex === -1 ? "performance-filter-card-row-set-pill-selected" : ""}` } onClick={ () => handleSetSelected(-1, item) }>
		                            		<p>{ selectedExercise && selectedExercise.id === item.id && selectedSetIndex === -1 ? "Reviewing all sets" : "Review all sets" }</p>
		                            	</div>
		                            	{/*{
		                            		(item.sets ?? []).map((item_set: any, index_set: number) => (
		                            			<div className={ `performance-filter-card-row-set-pill ${selectedExercise && selectedExercise.id === item.id && selectedSetIndex === index_set ? "performance-filter-card-row-set-pill-selected" : ""}` } onClick={ () => handleSetSelected(index_set, item) }>
				                            		<p>Set { index_set + 1 }</p>
				                            	</div>
		                            		))
		                            	}*/}
		                            </div>
	                            </div>
	                        ))
	                    }
	                </div>
	            </div>
            }
            
            <div className={ "performance-filter-card-selector-container" }>
                <div className={ "performance-filter-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default PerformanceFilterCard;