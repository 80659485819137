import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './WorkoutPlanListCycleOverview.css';
import firebase from '../../../../Firebase';
import Mixpanel from '../../../../Mixpanel';
import axios from 'axios';

import FormTextInput from '../../../../components/Base/FormTextInput';

import Workout_t from '../../../../Interfaces/Workout_t'
import Folder_t from '../../../../Interfaces/Folder_t';
import Plan_t from '../../../../Interfaces/Plan_t';

import WorkoutPlanListCycleOverviewCard from './WorkoutPlanListCycleOverviewCard';
import WorkoutPlanListCycleOverviewCardPlan from './WorkoutPlanListCycleOverviewCardPlan';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import Add from '@material-ui/icons/Add';
import PlayArrow from '@material-ui/icons/PlayArrow';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import FileCopy from '@material-ui/icons/FileCopy';

function WorkoutPlanListCycleOverview(props: any) {

    let initFolder: Folder_t = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
            isOpen: false
        };

    const [initSet, setInitSet] = useState(false);
    const [thisFolder, setThisFolder] = useState<Folder_t>(initFolder);
    const [cycles, setCycles] = useState<Folder_t[]>([]);
    const [phases, setPhases] = useState<Folder_t[]>([]);

    const [isCopying, setIsCopying] = useState(false);
    
    const [creatingCycleState, setCreatingCycleState] = useState(0);
    const [showAddCycleMenu, setShowAddCycleMenu] = useState(false);
    const [cyclesToAdd, setCyclesToAdd] = useState(3);
    const [showMenu, setShowMenu] = useState(false);

    const [editingFolderName, setEditingFolderName] = useState(false);
    const [newFolderName, setNewFolderName] = useState("My folder");

    //const [purposedPhase, setPurposedPhase] = useState(initFolder);
    const [cycleIndex_purposed, setCycleIndex_purposed] = useState(-1);
    const [phaseIndex_purposed, setPhaseIndex_purposed] = useState(-1);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.folder !== undefined) {
            setThisFolder(props.folder);
            setNewFolderName(props.folder.title);
            configureCycles();
        }
    }, [props.folder])

    function initialize() {
        if (props.folder !== undefined) {
            setThisFolder(props.folder);
            setNewFolderName(props.folder.title);
            configureCycles();
        }
    }

    function configureCycles() {
        var cycleListTemp: Folder_t[] = [];
        var phaseListTemp: Folder_t[] = [];
        if (props.folder.children !== undefined && props.folder.children.length > 0) {
            for (var i = 0; i < props.folder.children.length; i++) {
                let thisChild = props.folder.children[i];
                if (thisChild.type === "cycle") {
                    cycleListTemp.push(thisChild);
                } else if (thisChild.type === "plan") {
                    phaseListTemp.push(thisChild);
                }
            }
        }

        setCycles(cycleListTemp);
        setPhases(phaseListTemp);
    }

    function cycleSelected(c: Folder_t) {
        props.cycleSelected(c);
        props.turnOnDisplay(c);

        Mixpanel.track("Open Cycle pressed", {location: "cycle_overview"});
    }

    function planPressed(p: Folder_t, c: Folder_t) {
        // console.log(`WorkoutPlanListCycleOverview | planPressed (!!!!!): `, p, p.path, `FOLDER: `, c, c.path);
        props.cycleSelected(p);
        props.turnOnDisplay(c);
        Mixpanel.track("Open Plan pressed", {location: "cycle_overview"});
    }

    function addPlan(p: Folder_t, c: Folder_t) {
        // console.log(`WorkoutPlanListCycleOverview | addPlan: `, p, p.path, `FOLDER: `, c, c.path);
        props.addPlanPressed(p,c);
    }

    function toggleShowAddCycleMenu() {
        

        if (showAddCycleMenu === false) {
            Mixpanel.track("Add Cycle pressed", {to_state: 'N/A', location: "cycle_overview"});
        }

        setCreatingCycleState(0);
        setShowAddCycleMenu(!showAddCycleMenu);
    }

    function adjustNumberOfPhasesInCycle(adj: number) {
        if (cyclesToAdd + adj > 0) {
            Mixpanel.track("Adjust number of phases in cycle", {to_num_phases: cyclesToAdd + adj, location: "cycle_overview"});
            setCyclesToAdd(cyclesToAdd + adj);
        }
    }

    function createNewCycle() {
        props.createNewCycle(cyclesToAdd);
        setCreatingCycleState(1);

        Mixpanel.track("Cycle added", {num_phases: cyclesToAdd, location: "cycle_overview"});


        setTimeout(() => {
            setCreatingCycleState(2);
            setTimeout(() => {
                setCreatingCycleState(0);
                setShowAddCycleMenu(false);
            }, 2200);
        }, 500);
    }

    window.addEventListener('click', function(e){   
        checkAddCycleMenuHide(e);
        checkOptionsMenuHide(e);
        
    });

    function checkAddCycleMenuHide(e: any) {
        if (document === null || document.getElementById('cycle-overview-add-phases-menu') === null || document.getElementById('cycle-overview-add-phases-menu-button') === null || e.target === null) { return; }
        if (document.getElementById('cycle-overview-add-phases-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('cycle-overview-add-phases-menu-button')!.contains(e.target as Node))) {
                if (showAddCycleMenu === true) {
                    setShowAddCycleMenu(false);
                }
            }
        }
    }

    function checkOptionsMenuHide(e: any) {
        if (document === null || document.getElementById('cycle-overview-menu') === null || document.getElementById('cycle-overview-button') === null || e.target === null) { return; }

        if (document.getElementById('cycle-overview-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('cycle-overview-button')!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    }

    function deleteFolderPressed() {
        setShowMenu(false);
        props.deleteFolderPressed();
    }

    function addCyclePressed() {

        setShowMenu(false);

        if (cycles.length > 0 && phases.length === 0) {
            // Add a cycle
            props.addCyclePressed()
        } else {
            // Add a phase
            props.addPlanPressedInFolder();
        }

        
        
    }

    function toggleMenuShow() {
        setShowMenu(!showMenu);
    }

    function editFolderNamePressed() {
        setEditingFolderName(true);
        setShowMenu(false);
    }

    function inputChanged(folderName: string) {
        setNewFolderName(folderName);
    }

    function saveFolderName() {
        setThisFolder({...thisFolder, title: newFolderName});
        props.updateFolderName(newFolderName);
        setEditingFolderName(false);
    }




    //**************** DRAGGING FUNCTIONS ******************//

    function cellDropped(phase: Folder_t, phase_index: number, cycle: Folder_t, cycle_index: number) {


        // 1. Compose single list of all phases (before shuffling), irrespective of cycle
        var phaseList: Folder_t[] = [];
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            let thisCycleFolder: Folder_t = cycles[c_i];
            for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                let thisPhaseFolder: Folder_t = thisCycleFolder.children[p_i];
                phaseList.push(thisPhaseFolder);
            }
        }       

        // 2. Get index of phaseList for the phase being moved
        var currentPhaseListIndex = -1;
        var currentPhaseListIndex_set = false;
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            let thisCycleFolder: Folder_t = cycles[c_i];
            for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                if (!currentPhaseListIndex_set) {
                    currentPhaseListIndex += 1;
                }
                if (c_i === cycle_index && p_i === phase_index) {
                    currentPhaseListIndex_set = true;
                }
            }
        }

        // 3. Get index of phaseList for the new location of the phase
        var newPhaseListIndex = -1;
        var newPhaseListIndex_set = false;
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            let thisCycleFolder: Folder_t = cycles[c_i];
            for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                if (!newPhaseListIndex_set) {
                    newPhaseListIndex += 1;
                }

                // TODO: take into account indecies greater than the length of the cycle
                if (c_i === cycleIndex_purposed && p_i === phaseIndex_purposed) {
                    newPhaseListIndex_set = true;
                }
            }
        }    

        // 4. Configure new phase list array
        // console.log("Adjusting array with indecies: (old, new)", currentPhaseListIndex, newPhaseListIndex);
        let phaseList_old: Folder_t[] = JSON.parse(JSON.stringify(phaseList));
        let phaseList_new: Folder_t[] = array_move_open(phaseList, currentPhaseListIndex, newPhaseListIndex);
        // console.log(phaseList_old, phaseList_new);


        // 5. re-compose cycle list
        var cycles_temp: Folder_t[] = [];
        var phaseList_new_index = 0;
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            var currentCycleFolder: Folder_t = JSON.parse(JSON.stringify(cycles[c_i]));
            var currentCycleFolder_phaseList_temp: Folder_t[] = [];
            for (var p_i = 0; p_i < currentCycleFolder.children.length; p_i++) {
                var oldPhaseObj: Folder_t = JSON.parse(JSON.stringify(phaseList_old[phaseList_new_index]));
                var currentPhaseObj: Folder_t = JSON.parse(JSON.stringify(phaseList_new[phaseList_new_index]));
                if (currentPhaseObj.phaseName !== undefined) {
                    currentPhaseObj.phaseName = `Phase ${phaseList_new_index + 1}`
                } else {
                    currentPhaseObj.title = `Phase ${phaseList_new_index + 1}`
                }
                // Preserve the ID, path, creation string, and timestamp of the original object
                // currentPhaseObj.phaseName = oldPhaseObj.phaseName;
                currentPhaseObj.id = oldPhaseObj.id;
                // currentPhaseObj.plan_id = oldPhaseObj.plan_id;
                currentPhaseObj.path = oldPhaseObj.path;
                currentPhaseObj.createdBy_ID = oldPhaseObj.createdBy_ID;
                currentPhaseObj.timestamp = oldPhaseObj.timestamp;

                currentCycleFolder_phaseList_temp.push(currentPhaseObj);
                phaseList_new_index += 1;
            }
            currentCycleFolder.children = currentCycleFolder_phaseList_temp;
            cycles_temp.push(currentCycleFolder);
        }   

        setCycles(cycles_temp);

        // 6. Update database to save changes
        
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let rootRef = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root`);

        for (var p_i = 0; p_i < phaseList.length; p_i++) {
            let originalPhaseObj: Folder_t = phaseList_old[p_i];
            let newPhaseObj: Folder_t = phaseList_new[p_i];

            let path_ids = originalPhaseObj.path;
            let splitPath_ids = path_ids.split('/');
            let depth = splitPath_ids.length;

            // console.log(`Creating folder at ${path_ids}`);

            var composedPath_ids = "";
            for (var i = 1; i < depth; i++) {
                composedPath_ids += "/";
                composedPath_ids += splitPath_ids[i];
                composedPath_ids += i === depth - 1 ? "" : "/children";
            }
            // console.log(`Final FB location at ${composedPath_ids}`, newPhaseObj.plan_id, originalPhaseObj, newPhaseObj);
            let phaseRef = rootRef.child(composedPath_ids);

            // Update the name of the phase
            if (newPhaseObj.phaseName === undefined || newPhaseObj.phaseName === "") {
                // The new phase is not build yet -- it does not have a phase name. It's name should therefore be "Phase x"
                // where 'x' is the phase number of the original phase.
                if (originalPhaseObj.phaseName === undefined || originalPhaseObj.phaseName === "") {
                    // Original phase also was not built -- use the original phase's .title as the new phase's "name"
                    phaseRef.child("name").set(originalPhaseObj.title);
                } else {
                    // Orinal phase was built already -- use the original phase's .phaseName as the new phase's "name"
                    phaseRef.child("name").set(originalPhaseObj.phaseName);
                }
            } else {
                phaseRef.child("name").set(newPhaseObj.title);
            }
            
            // Update the phase name
            if (newPhaseObj.phaseName === undefined || newPhaseObj.phaseName === "") {
                phaseRef.child("phase_name").set(null);
            } else {
                if (originalPhaseObj.phaseName === undefined || originalPhaseObj.phaseName === "") {
                    // Original phase also was not built -- use the original phase's .title as the new phase's "name"
                    phaseRef.child("phase_name").set(originalPhaseObj.title);
                } else {
                    // Orinal phase was built already -- use the original phase's .phaseName as the new phase's "name"
                    phaseRef.child("phase_name").set(originalPhaseObj.phaseName);
                }
            }
            //phaseRef.child("phase_name").set(newPhaseObj.phaseName === undefined || newPhaseObj.phaseName === "" ? null : newPhaseObj.phaseName);
            phaseRef.child("plan_id").set(newPhaseObj.plan_id === undefined || newPhaseObj.plan_id === "" ? null : newPhaseObj.plan_id);

        }


        /*
        for (var c_i = 0; c_i < cycles_temp.length; c_i++) {
            var currentCycleFolder: Folder_t = JSON.parse(JSON.stringify(cycles_temp[c_i]));
            for (var p_i = 0; p_i < currentCycleFolder.children.length; p_i++) {
                let thisPhaseObj: Folder_t = currentCycleFolder.children[p_i];
                let path_ids = thisPhaseObj.path;
                let splitPath_ids = path_ids.split('/');
                let depth = splitPath_ids.length;

                // console.log(`Creating folder at ${path_ids}`);

                var composedPath_ids = "";
                for (var i = 1; i < depth; i++) {
                    composedPath_ids += "/";
                    composedPath_ids += splitPath_ids[i];
                    composedPath_ids += i === depth - 1 ? "" : "/children";
                }
                // console.log(`Final FB location at ${composedPath_ids}`);
                let phaseRef = rootRef.child(composedPath_ids);
                //phaseRef.child("title").set(thisPhaseObj.title);
                
                //phaseRef.child("phase_name").set(thisPhaseObj.phaseName === undefined || thisPhaseObj.phaseName === "" ? null : thisPhaseObj.phaseName);
            }
        }   

        */
        /*
        // Only need to shuffle around phases after the moved phase
        var cycleComposition: Folder_t[] = [];
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            if (c_i < cycle_index) {
                // Entire cycle is before moving cycle - go ahead and add to cycleComposition without adjustment
                cycleComposition.push(cycles[c_i]);
            } else { // c_i >= cycle_index
                let thisCycleFolder: Folder_t = cycles[c_i];
                var phaseComposition_temp: Folder_t[] = [];
                for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                    if (c_i === cycle_index && p_i === phase_index) {
                        // This is the cycle that was picked up -- ignore!
                    } else if (c_i === cycleIndex_purposed && p_i === phaseIndex_purposed) {
                        // This is where the phase was dropped
                        phaseComposition_temp.push(phase);
                    } else {

                    }
                    if (cycle_index === c_i) {
                        // Adjusting cycle
                    }
                }

            }
        }*/


        setCycleIndex_purposed(-1);
        setPhaseIndex_purposed(-1);
    }

    function array_move_open(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    function cellPicked(phase: Folder_t, phase_index: number, cycle: Folder_t, cycle_index: number) {
        
    }

    function updateDraggingPosition_phase(phase: Folder_t, phase_index: number, cycle: Folder_t, cycle_index: number, x: number, y: number) {
        let phasesContainer = document.getElementById('workout-plan-content-add-container')
        if (y === 0) return;
        
        if (phasesContainer !== null && phasesContainer !== undefined) {

            let cycleHeight = 382;
            let phaseHeight = 110;
            let upperMargin = 82;

            let phasesContainer_bounds = phasesContainer.getBoundingClientRect();
            let phasesWidth = (phasesContainer_bounds.width - 36) / 3;
            
            let y_adj = y - phasesContainer_bounds.top - upperMargin;

            
            var thisColIndex = Math.floor(y_adj / phaseHeight);
            thisColIndex = thisColIndex % 3;
            thisColIndex = thisColIndex < 0 ? 0 : (thisColIndex > cycle.children.length - 1 ? cycle.children.length - 1 : thisColIndex);
            
            var thisCycleIndex = Math.floor(y_adj / cycleHeight);
            thisCycleIndex = thisCycleIndex < 0 ? 0 : (thisCycleIndex > cycles.length - 1 ? cycles.length - 1 : thisCycleIndex);

            setCycleIndex_purposed(thisCycleIndex);
            setPhaseIndex_purposed(thisColIndex);

            // Get purposed phase info
            let purposedCycle_temp: Folder_t = cycles[thisCycleIndex];
            if (purposedCycle_temp === undefined || purposedCycle_temp.children === undefined) { return; }

            let purposedPhase_temp: Folder_t = purposedCycle_temp.children[thisColIndex];
            if (purposedPhase_temp === undefined) { return; }


            /*
            let phasesContainer_bounds = phasesContainer.getBoundingClientRect();
            let phasesWidth = (phasesContainer_bounds.width - 36) / 3;
            let x_adj = x - phasesContainer_bounds.left + (phasesWidth / 2);
            let y_adj = y - phasesContainer_bounds.top - upperMargin;

            
            var thisColIndex = Math.floor(x_adj / phasesWidth);
            thisColIndex = thisColIndex < 0 ? 0 : (thisColIndex > cycle.children.length - 1 ? cycle.children.length - 1 : thisColIndex);
            
            var thisCycleIndex = Math.floor(y_adj / cycleHeight);
            thisCycleIndex = thisCycleIndex < 0 ? 0 : (thisCycleIndex > cycles.length - 1 ? cycles.length - 1 : thisCycleIndex);

            setCycleIndex_purposed(thisCycleIndex);
            setPhaseIndex_purposed(thisColIndex);

            // Get purposed phase info
            let purposedCycle_temp: Folder_t = cycles[thisCycleIndex];
            if (purposedCycle_temp === undefined || purposedCycle_temp.children === undefined) { return; }

            let purposedPhase_temp: Folder_t = purposedCycle_temp.children[thisColIndex];
            if (purposedPhase_temp === undefined) { return; }
            */
            
            //setPurposedPhase(purposedPhase_temp);
            // console.log(thisColIndex, thisCycleIndex, purposedPhase_temp.title, purposedPhase_temp.id);
        }
    }

    
    function copyToClientsFolderPressed() {
        setShowMenu(false);
        if (props.copyFolderToClientPressed !== undefined) {
            props.copyFolderToClientPressed({parent: thisFolder, callback: confirmopyToClientsFolder});
        }
    }

    function confirmopyToClientsFolder() {

    }

    async function pastePhasePressed(selectedFolder: Plan_t) {
        if (!selectedFolder || !selectedFolder.id || !selectedFolder.path || !props.copiedPhase || !props.copiedPhase.id) return;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        // 1. Create copies of the workouts
        let originalWorkoutIdList: string[] = [];
        let newWorkoutIdList: string[] = [];
        if (props.copiedPhase.workoutObjects) {
            for (var i = 0; i < props.copiedPhase.workoutObjects.length; i++) {
                originalWorkoutIdList.push(props.copiedPhase.workoutObjects[i].id);
                newWorkoutIdList.push("");
            }
        }
        
        var workoutPromiseList: any[] = [];
        for (var i = 0; i < originalWorkoutIdList.length; i++) {
            let thisID = originalWorkoutIdList[i];
            let newPromise = duplicateWorkout(thisID, trainerUID);
            workoutPromiseList.push(newPromise);
        }

        try {
            setIsCopying(true);
            let allResp = await Promise.allSettled(workoutPromiseList);
            if (allResp && allResp.length > 0) {
                for (var i = 0; i < allResp.length; i++) {
                    let thisResp = allResp[i];
                    if (thisResp.status === "fulfilled" && thisResp.value !== undefined && thisResp.value.new_id !== undefined && thisResp.value.original_id !== undefined) {
                        let thisOGId = thisResp.value.original_id;
                        let thisNewId = thisResp.value.new_id;
                        for (var j = 0; j < originalWorkoutIdList.length; j++) {
                            let thisWorkoutId = originalWorkoutIdList[j];
                            if (thisWorkoutId === thisOGId && newWorkoutIdList[j] !== undefined) {
                                newWorkoutIdList[j] = thisNewId;
                            }
                        }
                    }
                }
            }

            // 2. Create a copy of the plan
            let thisPhaseID = selectedFolder.id;

            let seperatedPath: string[] = selectedFolder.path.split("/");
            if (selectedFolder.path === "" || seperatedPath.length === 0) return;

            var composedMasterPath_DB = "";
            var composedMasterPath_raw = "";
            for (var i = 0; i < seperatedPath.length; i++) {
                let thisElement: string = seperatedPath[i];
                if (thisElement !== undefined && thisElement !== "") {
                    composedMasterPath_DB += `/${thisElement}${ i < seperatedPath.length - 1 ? '/children' : ''}`;
                    composedMasterPath_raw += `/${thisElement}`;
                }
            }

            composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
            composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;

            let phaseObj = {
                "availability": 1,
                "created_by": trainerUID,
                "created_timestamp": Date.now(),
                "description": props.copiedPhase.description ?? "No description of this plan was provided",
                "location_path": selectedFolder.path,
                "periodization_string": props.copiedPhase.periodization_string ?? "Accumulation",
                "title": selectedFolder.title,
                "workouts": newWorkoutIdList ?? []
            }

            // console.log("Workout_Plan_List_Cycle_Overview: pasting phase", props.copiedPhase, selectedFolder, phaseObj)

            let database = firebase.database();
            let thisRootPath = `workout_plans/trainer_plans/${trainerUID}/folders_root${composedMasterPath_DB}`

            let ref_planID = database.ref(`${thisRootPath}/plan_id`);
            await ref_planID.set(thisPhaseID);

            let ref_phaseName = database.ref(`${thisRootPath}/phase_name`);
            await ref_phaseName.set(selectedFolder.title);

            let ref_name = database.ref(`${thisRootPath}/name`);
            await ref_name.set(`${props.copiedPhase.title && props.copiedPhase.title !== "" ? props.copiedPhase.title : selectedFolder.title ?? ""} (copy)`);

            let refPhases = database.ref(`workout_plans/plans/${thisPhaseID}`);
            await refPhases.set(phaseObj);

            props.updatePlans();
            setTimeout(() => {
                setIsCopying(false);
            }, 500)

        } catch (err) {
            setIsCopying(false);
        }

        

    }

    async function duplicateWorkout(workout_id: string, trainer_id: string) {
        return new Promise<{ new_id: string, original_id: string, error: number }>( async (resolve, reject) => {
            try {
                if (workout_id === "") {
                    resolve({ new_id: workout_id, original_id: workout_id, error: -1 });
                    return;
                } else {
                    let sendData = { workout_id: workout_id, copier_uid: trainer_id };
                    let resp = await axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/duplicateWorkout`, sendData, { headers: {'Content-Type': 'application/json'} });

                    if (resp && resp.data && resp.data.error === -1 && resp.data.new_workout_id !== undefined && resp.data.new_workout_id !== "") {

                        let newWorkoutID = resp.data.new_workout_id;

                        resolve({ new_id: newWorkoutID, original_id: workout_id, error: -1 });
                    } else {
                        resolve({ new_id: workout_id, original_id: workout_id, error: 0 });
                    }
                }
            } catch (err) {
                // console.log("Workout_Plan_List_Cycle_Overview: duplicateWorkout | ERROR:", err)
                resolve({ new_id: workout_id, original_id: workout_id, error: 1 });
            }
        })
    }

	return (
		<div id="workout-plan-content-add-container" className="workout-plan-list-cycle-overview">
		    <div className="workout-plan-list-cycle-overview-header-card">
                <div className="workout-plan-list-cycle-overview-header-card-icon-container">
                    <PlayArrow className="workout-plan-list-cycle-overview-header-card-icon"/>
                </div>    
                <div hidden={editingFolderName} className="workout-plan-list-cycle-overview-header-card-text-container">
                    <h1>{thisFolder.title}</h1>
                </div> 
                <div hidden={!editingFolderName} className="workout-plan-list-cycle-overview-header-card-text-container workout-plan-list-cycle-overview-header-card-text-container-editing">
                    <FormTextInput 
                        value={newFolderName}
                        placeholder="Folder name " 
                        alert={false} 
                        alertText=""
                        isSearch={true}
                        inputChanged={(e: string) => inputChanged(e)}/>
                    <div onClick={() => saveFolderName()} className="workout-plan-list-cycle-overview-header-card-text-container-save-button">
                        <Check className="workout-plan-list-cycle-overview-header-card-text-container-save-button-icon"/>
                        <p>Save</p>
                    </div>
                </div>
                <div id="cycle-overview-button" onClick={() => toggleMenuShow()} className="workout-plan-list-cycle-overview-header-card-menu-button-container workout-plan-header-card-menu-button-container">
                    <p>Options</p>
                    {/*<MoreHoriz className="workout-plan-list-cycle-overview-header-card-menu-button-icons"/>*/}
                </div> 
                <div hidden={true} id="cycle-overview-button" onClick={() => toggleMenuShow()} className="workout-plan-list-cycle-overview-header-card-menu-button-container ">
                    <p>Options</p>
                    {/*<MoreHoriz className="workout-plan-list-cycle-overview-header-card-menu-button-icons"/>*/}
                </div> 
                {/* ASSIGN BUTTONS FOR COACHING FOLDERS */}
                <div hidden={(props.isClient !== undefined && props.isClient === true) || (cycles.length === 0 && phases.length > 0)} onClick={() => props.assignClientToFolder()} className="workout-plan-list-header-button workout-plan-list-header-button-blue workout-plan-list-cycle-overview-header-card-menu-button-container-button-right">
                    <AssignmentInd className="workout-plan-list-header-upper-content-container-buttons-icon"/>
                    <p>Assign Program</p>
                </div>
                <div hidden={(props.isClient !== undefined && props.isClient === true) || (cycles.length > 0 && phases.length === 0)} className="workout-plan-list-header-button workout-plan-list-header-button-blue workout-plan-list-header-button workout-plan-list-header-button-blue-unselected workout-plan-list-cycle-overview-header-card-menu-button-container-button-right">
                    <AssignmentInd className="workout-plan-list-header-upper-content-container-buttons-icon"/>
                    <p>Assign Phase</p>
                    <div className="workout-plan-list-header-button-tooltip">
                        <p>You can only assign stand-alone Phases, or Folders containing Cycles. Click on a Phase below and then 'Assign' to assign to an Athlete.</p>
                    </div>
                </div>
                {/* ASSIGN BUTTONS FOR ATHLETE FOLDERS */}
                <div hidden={(props.isClient === undefined || props.isClient === false) || (cycles.length === 0 && phases.length > 0)} onClick={() => props.assignClientToFolder()} className="workout-plan-list-header-button workout-plan-list-header-button-blue">
                    <AssignmentInd className="workout-plan-list-header-upper-content-container-buttons-icon"/>
                    <p>Set as Current Program</p>
                </div>
                <div hidden={(props.isClient === undefined || props.isClient === false) || (cycles.length > 0 && phases.length === 0)} className="workout-plan-list-header-button workout-plan-list-header-button-blue workout-plan-list-header-button workout-plan-list-header-button-blue-unselected">
                    <AssignmentInd className="workout-plan-list-header-upper-content-container-buttons-icon"/>
                    <p>Set as Current Program</p>
                    <div className="workout-plan-list-header-button-tooltip">
                        <p>You can only set the current program to stand-alone Phases, or Folders containing Cycles. Click on a Phase below and then 'Set as Current Program' to assign to this Athlete.</p>
                    </div>
                </div>

               {/* <div id="cycle-overview-add-phases-menu-button" onClick={() => addCyclePressed()} className="workout-plan-list-cycle-overview-header-button-dark">
                    <Add className="workout-plan-list-cycle-overview-header-button-dark-icon"/>
                    <p>{cycles.length === 0 && phases.length > 0 ? "Add Phase" : "Add Cycle"}</p>
                </div>*/}
                
                <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                    <div id="cycle-overview-menu" className="workout-plan-list-cycle-overview-header-card-menu-container">
                        <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                            <p>Folder Options</p>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-header-card-menu-header-row-divider" }></div>
                        <div onClick={() => addCyclePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <Add className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>{cycles.length === 0 && phases.length > 0 ? "Add Phase" : "Add Cycle"}</p>
                        </div>
                        <div onClick={() => editFolderNamePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <Edit className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Edit Folder Name</p>
                        </div>
                        <div hidden={props.isClient === undefined || props.isClient === false} onClick={() => props.copyFolder()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <FileCopy className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Copy to My Folders</p>
                        </div>
                        <div hidden={props.isClient !== undefined && props.isClient === true} onClick={() => copyToClientsFolderPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <FileCopy className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Copy to Client's Folder</p>
                        </div>
                        <div onClick={() => deleteFolderPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <DeleteOutline className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Delete Program</p>
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition in={showAddCycleMenu} timeout={200} classNames="menu" unmountOnExit>
                    <div id="cycle-overview-add-phases-menu" className="workout-plan-list-header-add-menu-container workout-plan-list-header-add-menu-container-cycle">
                        <div hidden={creatingCycleState !== 0} className="workout-plan-list-header-add-menu-content workout-plan-list-header-add-menu-content-cycles">
                            <div className="workout-plan-list-header-add-menu-triangle workout-plan-list-header-add-menu-triangle-cycles"/>
                            <div className="workout-plan-list-header-add-menu-content-header">
                                <h4>Add another Cycle</h4>
                                <p>Select the number of Phases to include in the new Cycle</p>
                            </div>
                            <div className="workout-plan-list-header-add-menu-content-input-container workout-plan-list-header-add-menu-content-input-container-cycles">
                                <div onClick={() => adjustNumberOfPhasesInCycle(-1)} className="workout-plan-list-header-add-menu-content-input-content-button">
                                    <h3>-</h3>
                                </div>
                                <div className="workout-plan-list-header-add-menu-content-input-content">
                                    <h4>{cyclesToAdd}</h4>
                                </div>
                                <div onClick={() => adjustNumberOfPhasesInCycle(1)} className="workout-plan-list-header-add-menu-content-input-content-button">
                                    <h3>+</h3>
                                </div>
                            </div>
                            <div onClick={() => createNewCycle()} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-full workout-plan-list-header-add-menu-content-input-button-blue`}>
                                Create cycle
                            </div>
                        </div>
                        <div hidden={creatingCycleState !== 1} className="workout-plan-list-header-add-menu-content workout-plan-list-header-add-menu-content-loading">
                            <div className="workout-plan-list-header-add-menu-triangle"/>
                            <p>Creating cycle...</p>
                        </div>
                        <div hidden={creatingCycleState !== 2} className="workout-plan-list-header-add-menu-content workout-plan-list-header-add-menu-content-complete">
                            <div className="workout-plan-list-header-add-menu-triangle"/>
                            <h4>Created!</h4>
                            <p>Your new cycle was successfully created!</p>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <div className="workout-plan-list-cycle-overview-header-card-line"/>
            <div hidden={ !isCopying } className={ "workout-plan-list-cycle-overview-loading-overlay" }>
                <div className="workout-plan-content-loading-spinner"/>
                <p>Copying { props.copiedPhase && props.copiedPhase.title !== "" ? props.copiedPhase.title : "Unnamed Phase" }...</p>
            </div>
            <div hidden={cycles.length === 0 && phases.length > 0} className="workout-plan-list-cycle-overview-body-container">
                { (cycles.map((item: Folder_t, index: number) => (
                    <WorkoutPlanListCycleOverviewCard 
                        cycle={item}
                        cycleSelected={() => cycleSelected(item)}
                        planSelected={(p: Folder_t) => planPressed(p, item)}
                        addPlan={(p: Folder_t) => addPlan(p, item)}
                        copiedPhase={ props.copiedPhase }
                        pastePhasePressed={(p: Plan_t) => pastePhasePressed(p)}
                        editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                        assignClientToPlan={(p: Plan_t) => props.assignClientToPlan(p)} 
                        duplicatePlanPressed={(p: Plan_t) => props.duplicatePlanPressed(p)}
                        deletePlanPressed={(p: Plan_t) => props.deletePlanPressed(p)}
                        deleteCyclePressed={ (c: Folder_t) => props.deleteCyclePressed(c) }
                        addPhaseToCycle={ (c: Folder_t) => props.addPhaseToCycle(c) }
                        //purposedPhase={purposedPhase}
                        cycleIndex={index}
                        cycleIndex_purposed={cycleIndex_purposed}
                        phaseIndex_purposed={phaseIndex_purposed}
                        isLast={ index === cycles.length - 1 }
                        cellDropped={(phase: Folder_t, phase_index: number) => cellDropped(phase, phase_index, item,index)}
                        cellPicked={(phase: Folder_t, phase_index: number) => cellPicked(phase, phase_index, item,index)}
                        updateDraggingPosition_phase={(phase: Folder_t, phase_index: number, x: number, y: number) => updateDraggingPosition_phase(phase, phase_index, item, index, x, y)}/>
                )))}
                <div className={ "workout-plan-list-cycle-overview-body-new-cycle-button-container" } onClick={ () => addCyclePressed() }>
                    <div className={ "workout-plan-list-cycle-overview-body-new-cycle-button-inner" }>
                        <div className={ "workout-plan-list-cycle-overview-body-new-cycle-button-icon-container" }>
                            <Add className={ "workout-plan-list-cycle-overview-body-new-cycle-button-icon" }/>
                        </div>
                        <p>Add a Cycle</p>
                    </div>
                </div>
            </div>
            
            <div hidden={cycles.length > 0 && phases.length === 0} className="workout-plan-list-cycle-overview-body-container workout-plan-list-cycle-overview-body-container-phases">
                <div className="workout-plan-list-cycle-overview-body-container-phases-header">
                    <h4>Stand-alone phases in your Folder, "{thisFolder.title}"</h4>
                </div>
                <div className="workout-plan-list-cycle-overview-body-container-phases-content">
                    {phases.map((item: Folder_t, index: number) => (
                        <div className="workout-plan-list-cycle-overview-card-content-container-inner workout-plan-list-cycle-overview-card-content-container-inner-phases">
                            {/*<div hidden={props.phaseIndex_purposed === undefined || props.phaseIndex_purposed !== index ||
                                         props.cycleIndex_purposed === undefined || props.cycleIndex_purposed !== props.cycleIndex} 
                                 className="workout-plan-list-cycle-overview-card-content-phase-purposed-bar"></div>*/}
                            <div className="workout-plan-list-cycle-overview-card-content-phase-container workout-plan-list-cycle-overview-card-content-phase-container-phases">
                                <WorkoutPlanListCycleOverviewCardPlan
                                    allowDrag={false}
                                    phase={item}
                                    index={index}
                                    maxIndex={phases.length - 1}
                                    copiedPhase={ props.copiedPhase }
                                    pastePhasePressed={(p: Plan_t) => pastePhasePressed(p)}
                                    assignClientToPlan={(p: Plan_t) => props.assignClientToPlan(p)} 
                                    duplicatePlanPressed={(p: Plan_t) => props.duplicatePlanPressed(p)}
                                    planPressed={() => planPressed(item, props.folder)}
                                    editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                                    deletePlanPressed={(p: Plan_t) => props.deletePlanPressed(p)}
                                    cellDropped={() => props.cellDropped(item,index)}
                                    cellPicked={() => props.cellPicked(item,index)}
                                    dragPosUpdated={(x: number, y: number) => props.updateDraggingPosition_phase(item,index,x,y)}
                                    isPurposed={props.phaseIndex_purposed === index && props.cycleIndex_purposed === props.cycleIndex}/>
                            </div>
                        
                        </div>
                    ))}
                </div>
                
            </div>
		</div>
	)
}

export default WorkoutPlanListCycleOverview;