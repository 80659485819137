import React, { useState, useEffect } from 'react';
import './RepXWeightBreakdown.css';
import './FullAnalysisComposition.css';

import SetStats_t from '../../Interfaces/SetStats_t';

function RepXWeightBreakdown(props: any) {

    const [options, setOptions] = useState<string[]>(["Some Exercise","Another Exercise"]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [setStats, setSetStats] = useState<SetStats_t[][][]>([]);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
        if (props.workout === undefined) { return; }
        var temp = [];
        for (var i = 0; i < props.workout.exercises.length; i++) {
            let exerciseName = props.workout.exercises[i].title;
            temp.push(exerciseName);
        }
        setOptions(temp);
    }

    useEffect(() => {
        setSetStats(props.setStats);
    }, [props]);

    function setPage(index: number) {
        setSelectedIndex(index);
    }

    function getPageInfo(forIndex: number) {
        var returnObj = (<div className="rep-x-weight-breakdown-info-blank-container">No completed sets yet</div>);
        if (setStats !== undefined && 1 <= setStats.length && setStats[0] !== undefined && forIndex < setStats[0].length && setStats[0][forIndex] !== undefined) {
            return (

            <div className="rep-x-weight-breakdown-info-row-container">
                <div className="rep-x-weight-breakdown-info-row-content">
                    <div className="rep-x-weight-breakdown-info-row-header-container">Set</div>
                    <div className="rep-x-weight-breakdown-info-row-header-container">Reps</div>
                    <div className="rep-x-weight-breakdown-info-row-header-container">Weight</div>
                    <div className="rep-x-weight-breakdown-info-row-header-container">Power</div>
                    <div className="rep-x-weight-breakdown-info-row-header-container">ROM</div>
                </div>
                {setStats[0][forIndex].map((item: SetStats_t, index: number) => (
                    <div className="rep-x-weight-breakdown-info-row-content">
                        <div className="rep-x-weight-breakdown-info-row-name-container">Set {index + 1}</div>
                        <div className="rep-x-weight-breakdown-info-row-reps-container">{item.manualEntryReps} reps</div>
                        <div className="rep-x-weight-breakdown-info-row-weight-container">{item.weight} {item.isKG ? "KGs" : "LBS"}</div>
                        <div className="rep-x-weight-breakdown-info-row-reps-container">{Math.floor(item.averagePower)} W</div>
                        <div className="rep-x-weight-breakdown-info-row-weight-container">{Math.floor(item.averageROM * 100)} %</div>
                    </div>
                ))}
            </div>)
        }

        return returnObj;
    }

	return (
		<div className="full-analysis-composition-container">
			<div className="full-analysis-composition-selector-container">
                {options.map((item: string, index: number) => (
                    <div 
                        onClick={() => setPage(index)}
                        className={"full-analysis-composition-selector-option " + (selectedIndex === index ? "full-analysis-composition-selector-option-selected" : "full-analysis-composition-selector-option-unselected")}>
                            {item}
                            <div hidden={selectedIndex !== index} className="full-analysis-composition-selector-option-line"></div>
                    </div>
                ))}
            </div>
            <div className="rep-x-weight-breakdown-info-container">
                {props.workout.exercises.map((item: any, index: number) => (
                    <div hidden={selectedIndex !== index}>
                        {getPageInfo(index)}
                    </div>
                ))}
            </div>
		</div>
	)
}

export default RepXWeightBreakdown;