import axios from 'axios';
import firebase from 'firebase';

const API_ENDPOINT = "getUnreadMessages";

const getUnreadMessages = async (trainerUID: string | undefined)=> {
    return new Promise<any>( async (resolve, reject) => {
        try {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { reject(); return; }
            let trainerUIDNew = currentUser.uid;

            const params = {
                user_id: trainerUID ?? trainerUIDNew,
            }

            axios.post(
                `https://us-central1-movement-tracker-457bc.cloudfunctions.net/${API_ENDPOINT}`,
                params,
                { headers: {'Content-Type': 'application/json'} })
                .then( response => {
                    resolve(response.data)
                })
        }
        catch (err){
            // console.log("getUnreadMessages: ERROR!", err);
            reject(err)
        }
    })
}

export default getUnreadMessages
