import React, { useState, useContext, useEffect } from "react";
import ProfileIcon from "../../../components/ProfileIcon/ProfileIcon";
import './ClientChatMessage.css'
import dateTimeParser from "./dateTimeParser";
import firebase from "firebase";
import {AppContext} from "../../../AppContext";

const ClientChatMessage = ((props: {
        messageID: string;
        chatID: string;
        userUID: string;
        currentUserID: any;
        sentByClient: boolean;
        message: string;
        timestamp: number;
        userName: string;
        read_by: any;
        renderedThroughMessages: boolean
    }) => {

    const { getUnreadMessagesCount } = useContext(AppContext);
    const [contentUrl, setContentUrl] = useState("");
    const [contentType, setContentType] = useState<'image' | 'video' | undefined>(undefined);
    const [formattedMessage, setFormattedMessage] = useState("");
    const [contentFailedToLoad, setContentFailedToLoad] = useState(false);

    const [imgUrl, setImgUrl] = useState("");
    const [clientInitials, setClientInitials] = useState("");

    const addReadBy = (chatID: string, messageID: string, userID: string | undefined) => {
        const ref = firebase.database().ref(`messages/chats/${chatID}/chat_messages/${messageID}/read_by`);

        ref.once('value', (snapshot) => {
            if (!snapshot.exists()) {
                // console.log(userID, chatID)
                ref.set([userID]);
            } else {
                ref.push(userID);
            }
        });
    };


    useEffect(() => {
        if (props.read_by !== null && props.read_by !== undefined) {
            const read_by_arr: string[] =Object.values(props.read_by)
            if (!read_by_arr.includes(props.currentUserID)) {
                addReadBy(props.chatID, props.messageID, props.currentUserID)
                getUnreadMessagesCount(props.currentUserID)
            }
        } else {
            addReadBy(props.chatID, props.messageID, props.currentUserID)
            getUnreadMessagesCount(props.currentUserID)
        }

        configureFormattedMessage();
    }, [])

    useEffect(() => {
        if (!props.userName || props.userName === "") {
            setClientInitials("--");
            return;
        }
        let names = props.userName.split(" ");
        if (names && names.length > 1) {
            let fName = names[0];
            let lName = names[names.length - 1];

            let fName_letters = fName.split("");
            let fName_fLetter = fName_letters && fName_letters.length > 0 ? fName_letters[0] : "";

            let lName_letters = lName.split("");
            let lName_fLetter = lName_letters && lName_letters.length > 0 ? lName_letters[0] : "";

            let initials = `${fName_fLetter}${lName_fLetter}`
            setClientInitials(initials);
        }
    }, [props.userName])

    useEffect(() => {
        if (props.userUID && props.userUID !== "") getImage(props.userUID)
    }, [props.userUID])

    const getImage = (withUID: string) => {
        if (!withUID || withUID === "") { return; }

        const storage = firebase.storage();
        storage.ref('profileImages').child(withUID).child('profileImage/image.jpg').getDownloadURL()
        .then(fireBaseUrl => {
            setImgUrl(fireBaseUrl)
        })
        .catch(error => {
            
        })
    }

    const configureFormattedMessage = () => {

        console.log("MESSAGE RERENDER", props.messageID)

        let formattedMessageTemp = "";
        switch (props.message) {
            case "_CALL_REQUEST_":
                setContentType(undefined);
                formattedMessageTemp = `${props.userName} requested a call`
                break;
            case "__IMAGE__":
                setContentType('image');
                loadContent('image');
                formattedMessageTemp = `Loading image...`
                break;
            case "__VIDEO__":
                setContentType('video');
                loadContent('video');
                formattedMessageTemp = `Loading video...`
                break;
            default:
                setContentType(undefined);
                formattedMessageTemp = props.message;
                break
        }

        setFormattedMessage(formattedMessageTemp);
    }

    const loadContent = async (type: 'image' | 'video' | undefined) => {
        if (!type) return;
        let thisContentLocation = `chats/${props.chatID}/${props.messageID}/${ type === 'image' ? 'image.jpg' : 'video.mp4' }`;

        try {
            const contentLocationFinal = await getImageURL(thisContentLocation);
            console.log("contentLocationFinal:", contentLocationFinal)
            setContentUrl(contentLocationFinal);
            setContentFailedToLoad(false);
        } catch (err) {
            setContentUrl("");
            setContentFailedToLoad(true);
        }
    }

    function getImageURL(atLocation: string) {
        return new Promise<string>( async (resolve, reject) => {
            try {
                if (props.userUID === undefined || atLocation === "") { 
                    reject("ERROR,getImageURL : provide valid input data")
                    return; 
                }

                const storage = firebase.storage();
                let fireBaseUrl = await storage.ref(atLocation).getDownloadURL()
                resolve(fireBaseUrl);

            } catch (err) {
                reject("ERROR,getImageURL : failed to fetch URL")
                return; 
            }
        })
       
    }

    return (
        <div className={props.sentByClient ? "client-chat-message-container" : "trainer-chat-message-container"}>
            
            <div className={ "chat-message-profile-container" }>
                <div className={ "chat-message-icon-container" }>
                    {
                        imgUrl === "" ? 
                        <h4>{ clientInitials }</h4>
                        :
                        <img className="chat-message-icon-container-image" src={ imgUrl }/>
                    }
                </div>
            </div>
            <div className={ "chat-message-content-container" }>
                <div className={props.sentByClient ? "client-username-timestamp-row" : "trainer-username-timestamp-row"}>
                    <div className={props.sentByClient ? "client-username-container" : "trainer-username-container"}
                         style={{marginTop: props.renderedThroughMessages ? '0' : '10px'}}>
                        <p>{props.userName}</p>
                    </div>
                    <div className={props.sentByClient ? "client-timestamp-container" : "trainer-timestamp-container"}
                         style={{marginTop: props.renderedThroughMessages ? '6px' : '12px'}}>
                        <p>{dateTimeParser(props.timestamp)}</p>
                    </div>
                </div>
                {
                    contentType === undefined ?
                    <div className={props.sentByClient ? "client-message-text-container" : "trainer-message-text-container"}>
                        <p>{ formattedMessage }</p>
                    </div>
                    :
                    contentType === "image" ?
                    <div className={ props.sentByClient ? "client-message-video-container" : "trainer-message-video-container" }>
                        <img className="client-message-video-container-image" src={ contentUrl }/>
                    </div>
                    :
                    <div className={ props.sentByClient ? "client-message-video-container" : "trainer-message-video-container" }>
                        <video controls={ true } src={ contentUrl } className="client-message-video-container-video">
                            <source src={ contentUrl } />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                }
            </div>
            
            
        </div>
    )
})

export default ClientChatMessage
