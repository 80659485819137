import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './activityFeed.css';

import ActivityFeedRow from './ActivityFeedRow';

import ActivityFeedItem_t from '../../Interfaces/ActivityFeedItem_t';

const weekdayStrings = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function ActivityFeed(props: any) {

    const [initSet, setInitSet] = useState(false);

    const [todayData, setTodayData] = useState<ActivityFeedItem_t[]>([])
    const [yesterdayData, setYesterdayData] = useState<ActivityFeedItem_t[]>([])
    const [twoAgoData, setTwoAgoData] = useState<ActivityFeedItem_t[]>([])
    const [threeAgoData, setThreeAgoData] = useState<ActivityFeedItem_t[]>([])
    const [fourAgoData, setFourAgoData] = useState<ActivityFeedItem_t[]>([])
    const [fiveAgoData, setFiveAgoData] = useState<ActivityFeedItem_t[]>([])

    const [twoAgoTitle, setTwoAgoTitle] = useState("Two days ago")
    const [threeAgoTitle, setThreeAgoTitle] = useState("Three days ago")
    const [fourAgoTitle, setFourAgoTitle] = useState("Four days ago")
    const [fiveAgoTitle, setFiveAgoTitle] = useState("Five days ago")

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            initialize()
        }
    }, [initSet])

    const initialize = async () => {

        
        let todayDataTemp = await pullActivityData(getDayTimestamp(Date.now()));
        setTodayData(todayDataTemp);

        let yesterdayDataTemp = await pullActivityData(getDayTimestamp(Date.now() - (24 * 3600 * 1000)));
        setYesterdayData(yesterdayDataTemp);

        let twoAgoTS = Date.now() - (2 * 24 * 3600 * 1000)
        let twoAgoDate = new Date(twoAgoTS);
        setTwoAgoTitle(weekdayStrings[twoAgoDate.getDay()] ?? "Two days ago");
        let twoAgoDataTemp = await pullActivityData(getDayTimestamp(twoAgoTS));
        setTwoAgoData(twoAgoDataTemp);

        let threeAgoTS = Date.now() - (3 * 24 * 3600 * 1000)
        let threeAgoDate = new Date(threeAgoTS);
        setThreeAgoTitle(weekdayStrings[threeAgoDate.getDay()] ?? "Three days ago");
        let threeAgoDataTemp = await pullActivityData(getDayTimestamp(threeAgoTS));
        setThreeAgoData(threeAgoDataTemp);

        let fourAgoTS = Date.now() - (4 * 24 * 3600 * 1000)
        let fourAgoDate = new Date(fourAgoTS);
        setFourAgoTitle(weekdayStrings[fourAgoDate.getDay()] ?? "Four days ago");
        let fourAgoDataTemp = await pullActivityData(getDayTimestamp(fourAgoTS));
        setFourAgoData(fourAgoDataTemp);

        let fiveAgoTS = Date.now() - (5 * 24 * 3600 * 1000)
        let fiveAgoDate = new Date(fiveAgoTS);
        setFiveAgoTitle(weekdayStrings[fiveAgoDate.getDay()] ?? "Five days ago");
        let fiveAgoDataTemp = await pullActivityData(getDayTimestamp(fiveAgoTS));
        setFiveAgoData(fiveAgoDataTemp);

        // console.log("DONE:", todayDataTemp)
    }

    const getDayTimestamp = (ts: number) => {

        let dateTemp = new Date(ts)
        let y = dateTemp.getFullYear();
        let m = dateTemp.getMonth();
        let d = dateTemp.getDate();

        let date = new Date(y,m,d,23,59,0,0);
        return date.getTime();
    }

    const pullActivityData = (forTS: number) => {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                let database = firebase.database();
                let currentUser = firebase.auth().currentUser;
                if (!currentUser) { return; }
                let trainerUID = currentUser.uid;
                // 1. Pull trainer clients

                
                let snapshot_clients = await database.ref(`personal_trainer_clients/${trainerUID}/clients`).once('value');
                let data_clients: any | null = snapshot_clients.exists() ? snapshot_clients.val() : null;

                if (!data_clients) return;

                // console.log("data_clients:", data_clients)
                let clientKeys = Object.keys(data_clients);
                var promiseList: any[] = [];

                // 2. Get workout log data
                let todayDate = new Date(forTS);
                let thisDate = todayDate.getDate();
                let thisMo = todayDate.getMonth();
                let thisYear = todayDate.getFullYear();

                if (clientKeys) {
                    for (var i = 0; i < clientKeys.length; i++) {
                        let thisKey = clientKeys[i];
                        let thisPromise = getDayWorkoutData(thisKey, thisYear, thisMo, thisDate);
                        promiseList.push(thisPromise);
                    }
                }

                var results_raw_workouts = await Promise.allSettled(promiseList);


                // 3. Get log updates
                var promiseListLogs: any[] = [];
                let thisTimestamp = forTS - (24 * 3600 * 1000);
                if (clientKeys) {
                    for (var i = 0; i < clientKeys.length; i++) {
                        let thisKey = clientKeys[i];
                        let thisPromise = getGoals(thisKey, thisTimestamp);
                        promiseListLogs.push(thisPromise);
                    }
                }

                var results_raw_goals = await Promise.allSettled(promiseListLogs);


                var todayDataTemp: ActivityFeedItem_t[] = [];

                for (var i = 0; i < results_raw_workouts.length; i++) {
                    let thisWorkoutResultRaw: any = results_raw_workouts[i];
                    if (thisWorkoutResultRaw && thisWorkoutResultRaw.value) {
                        let thisWorkoutResult = thisWorkoutResultRaw.value;
                        if (thisWorkoutResult) {
                            if (thisWorkoutResult.starting_timestamp !== -1) {
                                let ts = thisWorkoutResult.ending_timestamp && thisWorkoutResult.ending_timestamp > 0 ? thisWorkoutResult.ending_timestamp : thisWorkoutResult.starting_timestamp ?? -1;
                                todayDataTemp.push({ client_id: thisWorkoutResult.client_id ?? "", type: "workout", data: thisWorkoutResult, timestamp: ts });
                            } else if (thisWorkoutResult.is_missed) {
                                let thisDayTS = getDayTimestamp(forTS);
                                let todayDayTS = getDayTimestamp(Date.now());
                                if (thisDayTS < todayDayTS) {
                                    todayDataTemp.push({ client_id: thisWorkoutResult.client_id ?? "", type: "workout_missed", data: thisWorkoutResult, timestamp: Date.now() });
                                }
                            }
                        }
                    }
                }

                for (var i = 0; i < results_raw_goals.length; i++) {
                    let thisGoalResultRaw: any = results_raw_goals[i];
                    if (thisGoalResultRaw && thisGoalResultRaw.value) {
                        let thisGoalResult = thisGoalResultRaw.value;
                        let logKeys = thisGoalResult && thisGoalResult.logs ? Object.keys(thisGoalResult.logs) : [];
                        if (logKeys.length > 0) {
                            let thisLogKey = logKeys[logKeys.length - 1];
                            let thisLogObj = thisGoalResult.logs[thisLogKey];
                            let ts = thisLogObj.timestamp ?? -1;
                            todayDataTemp.push({ client_id: thisGoalResult.client_id ?? "", type: "goal_log", data: thisGoalResult, timestamp: ts });
                        }
                    }
                }

                todayDataTemp.sort((a, b) => b.timestamp - a.timestamp)

                resolve(todayDataTemp);
            } catch (err) {
                resolve([])
            }
        })
    }

    const getGoals = (client_id: string, timestamp: number) => {
        return new Promise<{ client_id: string, logs: any[] }>(async (resolve, reject) => {
            if (!client_id || client_id === "") resolve({ client_id: client_id, logs: [] });
            try {
                let database = firebase.database();
                let snapshot_goals = await database.ref(`goals/${client_id}/goals`).once('value');
                let data_goals = snapshot_goals.exists() ? snapshot_goals.val() : null;
                if (!data_goals) resolve({ client_id: client_id, logs: [] });

                let goalKeys = Object.keys(data_goals);

                let thisGoalKey = goalKeys ? goalKeys[goalKeys.length - 1] ?? null : null
                if (!thisGoalKey) { 
                    resolve({ client_id: client_id, logs: [] }); 
                    return; 
                }


                let goalLogs = await getGoalLogs(client_id, thisGoalKey, timestamp);

                resolve({ client_id: client_id, logs: goalLogs })
            } catch (err) {
                resolve({ client_id: client_id, logs: [] });
            }
        })
    }

    const getGoalLogs = (client_id: string, goal_id: string, timestamp: number) => {
        return new Promise<any[]>(async (resolve, reject) => {
            if (!client_id || client_id === "") resolve([]);
            try {
                let database = firebase.database();
                let snapshot_goals = await database.ref(`goals/${client_id}/goal_logs/${goal_id}/logs`).orderByChild('timestamp').startAt(timestamp).endAt(timestamp + (24 * 3600 * 1000)).once('value');
                let data_goals = snapshot_goals.exists() ? snapshot_goals.val() : null;
                if (!data_goals) resolve([]);

                resolve(data_goals)

                resolve([])
            } catch (err) {
                resolve([]);
            }
        })
    }

    const getDayWorkoutData = (client_id: string, yr_index: number, mo_index: number, d_index: number) => {
        return new Promise<{ client_id: string, title: string, starting_timestamp: number, ending_timestamp: number, is_complete: boolean, is_missed: boolean }>(async (resolve, reject) => {
            if (!client_id || client_id === "") resolve({ client_id: client_id, title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false, is_missed: false });
            try {
                let database = firebase.database();
                let snapshot_log_date = await database.ref(`workout_plans/client_assigned_plans/${client_id}/schedule/${yr_index}/${mo_index}/${d_index}`).once('value');
                let data_log_date = snapshot_log_date.exists() ? snapshot_log_date.val() : null;
                if (!data_log_date) resolve({ client_id: client_id, title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false, is_missed: false });
                ///log/log_id

                if (data_log_date.log && data_log_date.log.log_id && data_log_date.log.log_id !== "") {
                    let logData: { title: string, starting_timestamp: number, ending_timestamp: number, is_complete: boolean } = await getWorkoutLogData(client_id, data_log_date.log.log_id);
                    resolve({ ...logData, client_id: client_id, is_missed: false })
                } else {
                    if (data_log_date.workout_id && data_log_date.workout_id !== "") {
                        let woTitle = await getWorkoutTitle(data_log_date.workout_id);
                        resolve({ client_id: client_id, title: woTitle, starting_timestamp: -1, ending_timestamp: -1, is_complete: false, is_missed: true });
                    } else {
                        resolve({ client_id: client_id, title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false, is_missed: false });
                    }
                }

                
            } catch (err) {
                resolve({ client_id: client_id, title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false, is_missed: false });
            }
        })
    }

    const getWorkoutLogData = (client_id: string, log_id: string) => {
        return new Promise<{ title: string, starting_timestamp: number, ending_timestamp: number, is_complete: boolean }>(async (resolve, reject) => {
            if (!client_id || client_id === "" || !log_id || log_id === "") resolve({ title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false });
            try {
                let database = firebase.database();
                let snapshot_log = await database.ref(`workout_logs/${client_id}/${log_id}`).once('value');
                let data_log = snapshot_log.exists() ? snapshot_log.val() : null;
                if (!data_log) resolve({ title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false });

                let startedTimestamp = data_log.starting_timestamp ?? -1;
                let endingTimestamp = data_log.ending_timestamp ?? -1;
                let workoutID = data_log.workout_id ?? ""
                let workoutTitle = await getWorkoutTitle(workoutID)
                let isComplete = endingTimestamp > 0;

                resolve({ title: workoutTitle, starting_timestamp: startedTimestamp, ending_timestamp: endingTimestamp, is_complete: isComplete });

            } catch (err) {
                resolve({ title: "", starting_timestamp: -1, ending_timestamp: -1, is_complete: false });
            }
        })
    }

    const getWorkoutTitle = (workout_id: string) => {
        return new Promise<string>(async (resolve, reject) => {
            if (!workout_id || workout_id === "") resolve("");
            try {
                let database = firebase.database();
                let snapshot_log = await database.ref(`workouts/${workout_id}/title`).once('value');
                let data_log = snapshot_log.exists() ? snapshot_log.val() : "";
                resolve(data_log);

            } catch (err) {
                resolve("")
            }
        })
    }



	return (
		<div className={ "activity-feed" }>
			<div className={ "activity-feed-header" }>
            
            </div>
            <div className={ "activity-feed-content" }>
                <div className={ "activity-feed-today-container" }>
                    <div className={ "activity-feed-today-header-container" }>
                        <h4>Today</h4>
                    </div>
                    {
                        todayData.map((item: ActivityFeedItem_t, index: number) => (
                            <ActivityFeedRow 
                                item={ item }
                                index={ index }
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
                                openClientProfile={(c: string) => props.openClientProfile(c)}
                                key={ `activity-item-${item.timestamp ?? -1}-${index}` }/>
                        ))
                    }
                    <div className={ "activity-feed-end-container" }>
                        <div className={ "activity-feed-end-icon" }/>
                        <p>{ todayData.length === 0 ? `Nothing new to show today.` : `End of Today's Feed` }</p>
                    </div>
                </div>
                <div className={ "activity-feed-today-container" }>
                    <div className={ "activity-feed-today-header-container" }>
                        <h4>Yesterday</h4>
                    </div>
                    {
                        yesterdayData.map((item: ActivityFeedItem_t, index: number) => (
                            <ActivityFeedRow 
                                item={ item }
                                index={ index }
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
                                openClientProfile={(c: string) => props.openClientProfile(c)}
                                key={ `activity-item-${item.timestamp ?? -1}-${index}` }/>
                        ))
                    }
                    <div className={ "activity-feed-end-container" }>
                        <div className={ "activity-feed-end-icon" }/>
                        <p>{ yesterdayData.length === 0 ? `Nothing new to show yesterday.` : `End of Yesterday's Feed` }</p>
                    </div>
                </div>
                <div className={ "activity-feed-today-container" }>
                    <div className={ "activity-feed-today-header-container" }>
                        <h4>{ twoAgoTitle }</h4>
                    </div>
                    {
                        twoAgoData.map((item: ActivityFeedItem_t, index: number) => (
                            <ActivityFeedRow 
                                item={ item }
                                index={ index }
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
                                openClientProfile={(c: string) => props.openClientProfile(c)}
                                key={ `activity-item-${item.timestamp ?? -1}-${index}` }/>
                        ))
                    }
                    <div className={ "activity-feed-end-container" }>
                        <div className={ "activity-feed-end-icon" }/>
                        <p>{ twoAgoData.length === 0 ? `Nothing new to show ${twoAgoTitle}.` : `End of ${twoAgoTitle}'s Feed` }</p>
                    </div>
                </div>
                <div className={ "activity-feed-today-container" }>
                    <div className={ "activity-feed-today-header-container" }>
                        <h4>{ threeAgoTitle }</h4>
                    </div>
                    {
                        threeAgoData.map((item: ActivityFeedItem_t, index: number) => (
                            <ActivityFeedRow 
                                item={ item }
                                index={ index }
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
                                openClientProfile={(c: string) => props.openClientProfile(c)}
                                key={ `activity-item-${item.timestamp ?? -1}-${index}` }/>
                        ))
                    }
                    <div className={ "activity-feed-end-container" }>
                        <div className={ "activity-feed-end-icon" }/>
                        <p>{ threeAgoData.length === 0 ? `Nothing new to show ${threeAgoTitle}.` : `End of ${threeAgoTitle}'s Feed` }</p>
                    </div>
                </div>
                <div className={ "activity-feed-today-container" }>
                    <div className={ "activity-feed-today-header-container" }>
                        <h4>{ fourAgoTitle }</h4>
                    </div>
                    {
                        fourAgoData.map((item: ActivityFeedItem_t, index: number) => (
                            <ActivityFeedRow 
                                item={ item }
                                index={ index }
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
                                openClientProfile={(c: string) => props.openClientProfile(c)}
                                key={ `activity-item-${item.timestamp ?? -1}-${index}` }/>
                        ))
                    }
                    <div className={ "activity-feed-end-container" }>
                        <div className={ "activity-feed-end-icon" }/>
                        <p>{ fourAgoData.length === 0 ? `Nothing new to show ${fourAgoTitle}.` : `End of ${fourAgoTitle}'s Feed` }</p>
                    </div>
                </div>
                <div className={ "activity-feed-today-container" }>
                    <div className={ "activity-feed-today-header-container" }>
                        <h4>{ fiveAgoTitle }</h4>
                    </div>
                    {
                        fiveAgoData.map((item: ActivityFeedItem_t, index: number) => (
                            <ActivityFeedRow 
                                item={ item }
                                index={ index }
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
                                openClientProfile={(c: string) => props.openClientProfile(c)}
                                key={ `activity-item-${item.timestamp ?? -1}-${index}` }/>
                        ))
                    }
                    <div className={ "activity-feed-end-container" }>
                        <div className={ "activity-feed-end-icon" }/>
                        <p>{ fiveAgoData.length === 0 ? `Nothing new to show ${fiveAgoTitle}.` : `End of ${fiveAgoTitle}'s Feed` }</p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default ActivityFeed;