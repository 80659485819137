import React, { useState, useEffect } from 'react';
import firebase from '../../../../Firebase';
import Mixpanel from '../../../../Mixpanel';

import './WorkoutPlanListCycleOverviewCardPlan.css';

import '@material-ui/core';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import ArrowForward from '@material-ui/icons/ArrowForward';

import Workout_t from '../../../../Interfaces/Workout_t'
import CreatedBy_t from '../../../../Interfaces/CreatedBy_t'
import Exercise_t from '../../../../Interfaces/Exercise_t'
import ExerciseSeries_t from '../../../../Interfaces/ExerciseSeries_t'
import Folder_t from '../../../../Interfaces/Folder_t';
import Plan_t from '../../../../Interfaces/Plan_t';
import Tempo_t from '../../../../Interfaces/Tempo_t';

import '@material-ui/core';
import DragIndicator from '@material-ui/icons/DragIndicator';
import FileCopy from '@material-ui/icons/FileCopy';
import Edit from '@material-ui/icons/Edit';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Close from '@material-ui/icons/Close';


function WorkoutPlanListCycleOverviewCardPlan(props: any) {
    let initWorkout: Workout_t = {
        title: '',
        id: '',
        createdBy: {name: '', id: ''},
        duration: 0,
        imageName: '',
        targetGender: '',
        totalSets: 0,
        totalReps: 0,
        difficulty: 0,
        exerciseSeries: [],
        exercises: []
    };

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [workouts, setWorkouts] = useState<Workout_t[]>([]);
    const [repScheme, setRepScheme] = useState<number[]>([]);
    const [avgReps, setAvgReps] = useState(0);
    const [volume, setVolume] = useState(0);
    const [volume_sets, setVolume_sets] = useState(0);

    const [isDragging, setIsDragging] = useState(false);

    const [plan, setPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullPlanDetails();
    }

    useEffect(() => {
        pullPlanDetails();
    }, [props.phase])

    function pullPlanDetails() {
        if (props.phase === undefined || props.phase.plan_id === undefined || props.phase.plan_id === "") { return; }

        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${props.phase.plan_id}`); // Change to database path
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setupWorkouts(data);
                
                //// console.log("PULLED PLAN", props.planFolder.plan_id, data.path);

                let thisPlan: Plan_t = {
                    title: data.title !== undefined && data.title !== "" ? data.title : "Plan Name Not Provided",
                    description: data.description !== undefined && data.description !== "" ? data.description : "No description of this plan was provided",
                    id: props.phase.plan_id,
                    createdBy_ID: data.created_by,
                    created_timestamp: data.created_timestamp,
                    workout_ids: [],
                    availability: 0,
                    path: data.location_path,
                    repScheme: [],
                    phase_name: data.phase_name !== undefined && data.phase_name !== "" ? data.phase_name : 'Unknown phase',
                    periodization_string: data.periodization_string
                };

                setPlan(thisPlan);
                
            }
        });
    }

    async function setupWorkouts(data: any) {
        var workoutListDataTemp: any[] = [];
        if (data.workouts !== null && data.workouts !== undefined && data.workouts.length > 0) {
            for (var i = 0; i < data.workouts.length; i++) {
                let thisWorkoutID = data.workouts[i];
                let thisWorkoutData: any = await getWorkoutInfo(thisWorkoutID);
                thisWorkoutData['id'] = thisWorkoutID;
                workoutListDataTemp.push(thisWorkoutData);
            }
        }

        parsePersonalWorkouts(workoutListDataTemp);
    }

    async function getWorkoutInfo(workoutID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();

            let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
            thisWorkoutRef.on('value', function(snapshotWorkout) {
                if (snapshotWorkout.exists() === true) {
                    let thisWorkout = snapshotWorkout.val();
                    resolve(thisWorkout);
                }
            });
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    async function parsePersonalWorkouts(workoutList: any) {

        var temp: Workout_t[] = [];
        for (const key of Object.keys(workoutList)) {

            if (workoutList[key].id === "") {
                let newWorkout: Workout_t = {
                    title: "Rest Day",
                    description: '',
                    id: "",
                    createdBy: {name: "", id: ""},
                    exercises: [],
                    exerciseSeries: [],
                    duration: 0,
                    imageName: "",
                    targetGender: "",
                    totalSets: 0,
                    totalReps: 0,
                    difficulty: 0,
                    goals: [],
                    downloads: 0,
                    timesCompleted: 0
                };
                temp.push(newWorkout);
            } else {
                let thisWorkout = workoutList[key];

                let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

                // Configure Exercise Series
                var seriesList_temp: ExerciseSeries_t[] = [];
                let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
                if (containsExerciseSeries === true) {
                    for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                        let thisSeriesObj = thisWorkout.exercise_series[i];
                        let thisSetLetter = getSetLetter(i);

                        var exerciseList_temp: Exercise_t[] = [];
                        if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                            for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                                let thisExerciseObj = thisSeriesObj.exercises[j];
                                let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                                exerciseList_temp.push(exTemp);
                            }
                        }

                        var seriesTemp: ExerciseSeries_t = {
                            title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                            index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                            type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                            exercises: exerciseList_temp
                        };

                        seriesList_temp.push(seriesTemp);
                    }
                } else {
                    // Workout was created "pre-series". Create a new placeholder series for each existing exercise
                    if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                        for (var i = 0; i < thisWorkout.exercises.length; i++) {
                            let thisExerciseTemp: any = thisWorkout.exercises[i];
                            let thisSetLetter = getSetLetter(i);
                            var seriesTemp: ExerciseSeries_t = {
                                title: thisSetLetter,
                                index: i,
                                type: 0,
                                exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                            };
                            seriesList_temp.push(seriesTemp);
                        }
                    }
                }

                var exerciseList: Exercise_t[] = [];
                if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

                    var reps_avg = 0.0;
                    var reps_avg_count = 0;
                    var exerciseReps: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var a: number[] = [];
                        if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                                let thisRep = thisWorkout.exercises[i].reps[j];
                                a.push(thisRep);
                                reps_avg += thisRep;
                                reps_avg_count += 1;
                            }
                        }
                        exerciseReps[thisWorkout.exercises[i].key] = a;

                        if (i === 0) {
                            setRepScheme(a);
                        }
                    }

                    setAvgReps(reps_avg / reps_avg_count);

                    var exerciseTempos: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                        if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                                let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                                let thisTempo: Tempo_t = {
                                    concentric: thisTempoObj.concentric,
                                    eccentric: thisTempoObj.eccentric,
                                    first_pause: thisTempoObj.first_pause,
                                    second_pause: thisTempoObj.second_pause
                                };
                                b.push(thisTempo);
                            }
                        }
                        exerciseTempos[thisWorkout.exercises[i].key] = b;
                    }

                    let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
                    exerciseList = e;


                    // push exercises into according slots in seriesList_temp
                    if (exerciseList.length > 0) {
                        for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                            let thisExercise: Exercise_t = exerciseList[ex_index];
                            let thisExercise_ID = thisExercise.id;

                            // Loop through seriesList_temp to find thisExercise's matching location
                            if (seriesList_temp.length > 0) {
                                for (var i = 0; i < seriesList_temp.length; i++) {
                                    let thisSeries = seriesList_temp[i];
                                    if (thisSeries.exercises.length > 0) {
                                        for (var j = 0; j < thisSeries.exercises.length; j++) {
                                            let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                            let thisSeriesExercise_ID = thisSeriesExercise.id;
                                            if (thisSeriesExercise_ID === thisExercise_ID) {
                                                // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                                // for the case of multiple of the same exercises in a workout
                                                if (thisSeriesExercise.title === '') {
                                                    seriesList_temp[i].exercises[j] = thisExercise;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
                let newWorkout: Workout_t = {
                    title: thisWorkout.title,
                    description: thisWorkout.description !== undefined ? thisWorkout.description: '',
                    id: thisWorkout.id,
                    createdBy: createdBy,
                    exercises: exerciseList,
                    exerciseSeries: seriesList_temp,
                    duration: Number(thisWorkout.duration),
                    imageName: thisWorkout.image_name,
                    targetGender: thisWorkout.target_gender,
                    totalSets: Number(thisWorkout.total_sets),
                    totalReps: Number(thisWorkout.total_reps),
                    difficulty: Number(thisWorkout.difficulty),
                    goals: thisWorkout.goals,
                    downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
                    timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
                };
                temp.push(newWorkout);
            }
        }

        var totalVol = 0;
        var totalVolSets = 0;
        for (var i = 0; i < temp.length; i++) {
            totalVol += temp[i].totalReps;
            totalVolSets += temp[i].totalSets;
        }

        setVolume(totalVol);
        setVolume_sets(totalVolSets);
        setAvgReps(totalVol / totalVolSets);

        setWorkouts(workouts.concat(temp));
        //setAllWorkouts(allWorkouts.concat(temp));
        setIsLoading(false);
    }

    async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: [thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }

    function getRepSchemeString() {
        var repSchemeString = "";
        if (repScheme.length > 0) {
            for (var i = 0; i < repScheme.length; i++) {
                repSchemeString += `${repScheme[i]}&emsp;`;
            }
        }
        return repSchemeString;
    }

    function getPeriodizationString() {

        if (plan.periodization_string !== undefined && plan.periodization_string !== "") {
            return plan.periodization_string;
        }

        if (avgReps === 0) { return '' }

        if (avgReps <= 8) {
            return 'Intensification';
        } else {
            return 'Accumulation';
        }
    }

    function editPressed() {
        var planTemp = plan;
        planTemp.workoutObjects = workouts;
        props.editPlanPressed(planTemp);
    }

    function assignPlanPressed() {
        var planTemp = plan;
        planTemp.workoutObjects = workouts;

        var wo_ids: string[] = [];
        for (var i = 0; i < workouts.length; i++) {
            if (workouts[i] && workouts[i].id !== undefined) wo_ids.push(workouts[i].id)
        }

        planTemp.workout_ids = wo_ids;
        props.assignClientToPlan(planTemp);
    }

    function duplicatePlanPressed() {
        var planTemp = plan;
        planTemp.workoutObjects = workouts;
        props.duplicatePlanPressed(planTemp);
    }

    function updateDraggingState(toState: boolean) {
        setIsDragging(toState);
        if (!toState) {
            props.cellDropped();
        } else {
            props.cellPicked();
        }
    }

    function updateDraggingPosition(x: number, y: number) {
        props.dragPosUpdated(x, y);
    }

	return (
		<div 
            draggable={props.allowDrag === undefined ? true : props.allowDrag} 
            onDrag={(e: any) => updateDraggingPosition(e.clientX, e.clientY)}
            onDrop={(e: any) => console.log("onDrop", e)}
            onDragStart={(e: any) => updateDraggingState(true)}
            onDragStartCapture={(e: any) => console.log("onDragStartCapture", e)}
            onDragEnd={(e: any) => updateDraggingState(false)}
            onDragEndCapture={(e: any) => console.log(`onDragEndCapture: (x:${e.clientX}, y: ${e.clientY})`)}
            onClick={() => null} 
            className={`workout-plan-list-cycle-overview-card-content-cell 
                        ${isDragging ? 'workout-plan-list-cycle-overview-card-content-cell-dragging' : ''}
                        ${props.index === props.maxIndex ? 'workout-plan-list-cycle-overview-card-content-cell-no-boarder' : ''} 
                        ${props.isPurposed !== undefined && props.isPurposed === true ? 'workout-plan-list-cycle-overview-card-content-cell-purposed' : ''}`}>
            
            <div className="workout-plan-list-cycle-overview-card-content-cell-header">
                <div className={ `workout-plan-list-cycle-overview-card-content-cell-header-dot ${props.phase.plan_id !== undefined && props.phase.plan_id !== "" ? "" : "workout-plan-list-cycle-overview-card-content-cell-header-dot-dark" }` }></div>
                <div className="workout-plan-list-cycle-overview-card-content-cell-header-icon-wrapper">
                    <div className={ `workout-plan-list-cycle-overview-card-content-cell-header-icon-container ${ props.phase.plan_id !== undefined && props.phase.plan_id !== "" ? "" : "workout-plan-list-cycle-overview-card-content-cell-header-icon-container-dark" }` }>
                        <img className="workout-plan-list-cycle-overview-card-content-cell-header-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/phase${ props.phase.plan_id !== undefined && props.phase.plan_id !== "" ? "" : "-dark" }.png`}/>
                    </div>
                </div>
            </div>
            <div hidden={props.phase.plan_id !== undefined && props.phase.plan_id !== ""} className="workout-plan-list-cycle-overview-card-content-cell-content">
                {/*<p>{props.phase.plan_id === undefined || props.phase.plan_id === "" || props.phase.phaseName === undefined ? "Phase Placeholder" : (props.phase.phaseName === "Unknown phase" ? "Unknown" : (props.phase.phaseName === "" ? "Stand-alone Phase" : props.phase.phaseName))}</p>
                <h4>This Phase has not been built.</h4>
                <div className="workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-button">
                    <p>Build this Phase</p>
                    <ArrowForward className="workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-icon"/>
                </div>*/}
                <div className="workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-button" onClick={ () => props.copiedPhase !== undefined ? props.pastePhasePressed(props.phase) : props.planPressed(props.phase)}>
                    <p>{ props.copiedPhase !== undefined ? `Paste ${ props.copiedPhase.title && props.copiedPhase.title !== "" ? props.copiedPhase.title : "Unnamed Phase" } here` : "Build this Phase" }</p>
                    <ArrowForward className="workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-icon"/>
                </div>
                <div className="workout-plan-list-cycle-overview-card-content-cell-content-inner">
                    <div className="workout-plan-list-cycle-overview-card-content-cell-header-text-container workout-plan-list-cycle-overview-card-content-cell-header-text-container-no-plan">
                        <div hidden={props.allowDrag !== undefined && props.allowDrag === false} className="workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-container">
                            <DragIndicator className="workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-icon"/>
                        </div>
                        <p>{props.phase.title}</p>
                        <h4>{props.phase.plan_id === undefined || props.phase.plan_id === "" || props.phase.phaseName === undefined ? "Phase Placeholder" : (props.phase.phaseName === "Unknown phase" ? "Unknown" : (props.phase.phaseName === "" ? "Stand-alone Phase" : props.phase.phaseName))}</h4>
                        {/*<div hidden={!(props.phase.plan_id !== undefined && props.phase.plan_id !== "")} className={`workout-plan-list-cycle-overview-card-content-cell-header-periodization-container ${getPeriodizationString() === "Accumulation" ? "workout-plan-list-cycle-overview-card-content-cell-header-periodization-container-green" : "workout-plan-list-cycle-overview-card-content-cell-header-periodization-container-blue"}`}>
                            <p>{getPeriodizationString()}</p>
                        </div>*/}
                   </div>
                   {
                        props.numberOfPhases !== undefined && props.numberOfPhases > 1 && 
                        <div className={ "workout-plan-list-cycle-overview-card-content-header-buttons-container workout-plan-list-cycle-overview-card-content-header-buttons-container-single" }>
                            <div className={ "workout-plan-list-cycle-overview-card-content-header-button workout-plan-list-cycle-overview-card-content-header-button-delete" } onClick={ () => props.deletePlanPressed(props.phase) }>
                                <DeleteOutline className={ "workout-plan-list-cycle-overview-card-content-header-button-icon" }/>
                            </div>
                        </div>
                    } 
                   <div className={ "workout-plan-list-cycle-overview-card-content-cell-header-body-container" }>
                        <div className={ "workout-plan-list-cycle-overview-card-content-cell-header-body-container-overlay-wrapper" }>
                            <div className={ "workout-plan-list-cycle-overview-card-content-cell-header-body-container-overlay" }/>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-card-content-cell-content-col" }>
                            <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-a"/>
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-a"/>
                            </div>
                             <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-b"/>
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-b"/>
                            </div>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-card-content-cell-content-col" }>
                            <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-c"/>
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-c"/>
                            </div>
                             <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-d"/>
                                 <div className="workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-d"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={!(props.phase.plan_id !== undefined && props.phase.plan_id !== "")} className="workout-plan-list-cycle-overview-card-content-cell-content">
                <div hidden={!isLoading} className="workout-plan-list-cycle-overview-card-content-cell-content-inner workout-plan-list-cycle-overview-card-content-cell-content-inner-loading">
                    <p>Loading plan details...</p>
                </div>
                <div hidden={isLoading} className="workout-plan-list-cycle-overview-card-content-cell-content-inner">
                    <div className={ "workout-plan-list-cycle-overview-card-content-header-buttons-container" }>
                        <div className={ "workout-plan-list-cycle-overview-card-content-header-button workout-plan-list-cycle-overview-card-content-header-button-delete" } onClick={ () => props.deletePlanPressed(props.phase) }>
                            <Close className={ "workout-plan-list-cycle-overview-card-content-header-button-icon" }/>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-card-content-header-button" } onClick={ () => duplicatePlanPressed() }>
                            <FileCopy className={ "workout-plan-list-cycle-overview-card-content-header-button-icon" }/>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-card-content-header-button" } onClick={ () => props.planPressed() }>
                            <Edit className={ "workout-plan-list-cycle-overview-card-content-header-button-icon" }/>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-card-content-header-button workout-plan-list-cycle-overview-card-content-header-button-blue" } onClick={ () => assignPlanPressed() }>
                            <AssignmentInd className={ "workout-plan-list-cycle-overview-card-content-header-button-icon workout-plan-list-cycle-overview-card-content-header-button-icon-blue" }/>
                        </div>
                    </div>
                    <div className="workout-plan-list-cycle-overview-card-content-cell-header-text-container">
                        <div hidden={props.allowDrag !== undefined && props.allowDrag === false} className="workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-container">
                            <DragIndicator className="workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-icon"/>
                        </div>
                        <p>{props.phase.plan_id === undefined || props.phase.plan_id === "" || props.phase.phaseName === undefined ? "Phase Placeholder" : (props.phase.phaseName === "Unknown phase" ? "Unknown" : (props.phase.phaseName === "" ? "Stand-alone Phase" : props.phase.phaseName))}</p>
                        <h4>{props.phase.title}</h4>
                    </div>
                    <div className={ "workout-plan-list-cycle-overview-card-content-cell-header-body-container" }>
                        <div className={ "workout-plan-list-cycle-overview-card-content-cell-content-col" }>
                            <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                <div className="workout-plan-list-cycle-overview-card-content-cell-content-rep-scheme-container">
                                    <span>Rep scheme </span>
                                    {repScheme.map((item: number, index: number) => ( <h4>{item}</h4> ))}
                                </div>
                            </div>
                             <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                <span>Contains</span>
                                <h4>{workouts.length} Workout{workouts.length === 1 ? '' : 's'}</h4>
                            </div>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-card-content-cell-content-col" }>
                            <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                <div className="workout-plan-list-cycle-overview-card-content-cell-content-rep-scheme-container">
                                    <span>Periodization</span>
                                    <h4>{ getPeriodizationString() }</h4>
                                </div>
                            </div>
                             <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                                <span>Average Volume</span>
                                <h4>{ volume_sets } set{ volume_sets === 1 ? '' : 's' }, { volume } rep{ volume === 1 ? '' : 's' }</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

/*

<div hidden={true} onClick={() => editPressed()} className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container">
                        <p>Edit</p>
                        <ArrowForward className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon"/>
                    </div>


<div hidden={isLoading} className="workout-plan-list-cycle-overview-card-content-cell-content-inner">
                    <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                        <p>Series A Rep Scheme</p>
                        <div className="workout-plan-list-cycle-overview-card-content-cell-content-rep-scheme-container">
                            {repScheme.map((item: number, index: number) => (
                                <h4>{item}</h4>
                            ))}
                        </div>
                        
                    </div>
                    <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                        <p>Average Volume</p>
                        <h4>{volume} reps</h4>
                    </div>
                    <div className="workout-plan-list-cycle-overview-card-content-cell-content-row">
                        <p>Number of Workouts</p>
                        <h4>{workouts.length} Workouts</h4>
                    </div>
                </div>

                <div className="workout-plan-list-cycle-overview-card-content-cell-content-options">
                    
                    <div onClick={() => props.planPressed()} className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container">
                        <p>View Phase</p>
                        <ArrowForward className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon"/>
                    </div>
                    <div onClick={() => editPressed()} className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container">
                        <p>Edit</p>
                        <ArrowForward className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon"/>
                    </div>
                </div>

                <div onClick={() => props.planPressed()} className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container">
                        <p>View Phase</p>
                        <ArrowForward className="workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon"/>
                    </div>
*/

export default WorkoutPlanListCycleOverviewCardPlan;