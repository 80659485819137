import React from 'react';
import { IonLabel, IonInput, IonTextarea, IonItem } from '@ionic/react';
import './FormTextInput.css';
import './FormLongTextInput.css';

interface FormTextInputProps {
  placeholder: string;
  inputChanged: any;
  alert: boolean;
  value?: any;
  alertText?: string;
  inputMode?: "decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url" | undefined;
  hideText?: boolean;
  isSearch?: boolean;
  isOptional?: boolean;
  focusChanged?: any;
}



interface FormTextInputState {
  labelIsHidden: boolean;
  message: string;
  isFocused: boolean;
}

class FormLongTextInput extends React.Component<FormTextInputProps, FormTextInputState> {
  static defaultProps: FormTextInputProps = {
    placeholder: "Enter value",
    inputChanged: null,
    alert: false,
    alertText: '',
    inputMode: "text",
    hideText: false,
    isSearch: false
  }
  constructor(props: any) {
    super(props);
    this.state = {labelIsHidden: true, message: "",isFocused: false}
  }
  setText(newText: string) {
    let shouldHide = newText === "" || newText === null;
    this.setState({labelIsHidden: shouldHide, message: newText,isFocused: true});
    this.props.inputChanged(newText);
  }
  focusChanged(toFocus: boolean) {
    this.setState({...this.state, isFocused: toFocus});
    if (this.props.focusChanged !== undefined) {
      this.props.focusChanged(toFocus);
    }
  }

  render() {
    return (
      <div className="form-long-input-container">
       <p hidden={this.props.isSearch === true} className="form-text-input-label">{this.props.placeholder}</p>
       <p hidden={!this.props.alert} className="form-text-input-alert-label">{this.props.alertText}</p>
       <p hidden={this.props.alert === true || this.props.isOptional === undefined || this.props.isOptional === false} className="form-text-input-optional-label">Optional</p>
       <div className={this.props.alert ? "form-long-text-input-container-alert" : `form-long-text-input-container ${this.state.isFocused === true ? 'form-long-text-input-container-focused' : ''}`}>
         <IonTextarea value={this.props.value === undefined ? this.state.message : this.props.value} 
                   placeholder={this.props.placeholder} 
                   inputmode={this.props.inputMode}
                   clearOnEdit={this.props.hideText}
                   onFocus={(e) => this.focusChanged(true)}
                   onBlur={(e) => this.focusChanged(false)}
                   class="ion-no-padding"
                   autoGrow={true}
                   rows={3}
                   maxlength={5000}
                   onIonChange={e => this.setText(e.detail.value!)}></IonTextarea>
       </div>
     </div>
    )
  }
}


export default FormLongTextInput;
