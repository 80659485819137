import React, { useState } from 'react';
import './Name.css';
import './RegisterPage.css';
import TextInput from '../../components/Base/TextInput';
import StandardButton from '../../components/Base/StandardButton';
import { IonReactRouter } from '@ionic/react-router';


function Name(props: any) {

  function textInputChanged(text: string) {
    let regEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/;
    let passedRegEx = regEx.test(text);

    let chars = text.split("");
    let words = text.split(" ");
    let enoughNames = ((words.length === 2) && chars[chars.length - 1] != " ") || (words.length > 2);
    
    let isValid = passedRegEx && enoughNames;
    props.validation(isValid);

    if (isValid === true) {
      props.resultUpdated(text);
    }
  }

  return (
    <div className="header-container">
      <h1>Welcome to Eigen Fitness!</h1>
      <h1>We're excited to get your started as a Coaching Partner.</h1>
      <p>Let's get started by filling in your first and last name below.</p>
      <div>
        <TextInput placeholder="My full name" input="" inputChanged={(e: string) => textInputChanged(e)}></TextInput>
        <p hidden={!props.showError} className="register-error-text">Please enter your full name above</p>
      </div>

   </div>
  )
}

export default Name;