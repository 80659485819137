import React, { useState, useEffect } from 'react';
import {Line, Bar, Scatter} from 'react-chartjs-2';
import './IntraWorkoutStats.css';

import SetStats_t from '../../../Interfaces/SetStats_t';


import IntraWorkoutStatsCard from './IntraWorkoutStatsCard';
// import FullAnalysisComposition from '../../../components/Charts/FullAnalysisComposition';
// import RepXWeightBreakdown from '../../../components/Charts/RepXWeightBreakdown';

function IntraWorkoutStats(props: any) {

    const [chartLiftData, setChartLiftData] = useState<number[]>([]);
    const [chartPowerData, setChartPowerData] = useState<number[]>([]);
    const [chartRestData, setChartRestData] = useState<number[]>([]);
    const [chartROMData, setChartROMData] = useState<number[]>([]);
    const [chartFormData, setChartFormData] = useState<number[]>([]);
    const [chartMaxWeightData, setChartMaxWeightData] = useState<number[]>([]);
    const [chartRepWeightDist, setChartRepWeightDist] = useState<any[]>([]);

    const [selectedExerciseSeriesIndex, setSelectedExerciseSeriesIndex] = useState(0);
    const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
    const [selectedSetIndex, setSelectedSetIndex] = useState(-1);


    const [initSet, setInitSet] = useState(false);
    const [setStats, setSetStats] = useState<SetStats_t[]>();
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {

        if (props.selectedExerciseSeriesIndex !== undefined) {
            setSelectedExerciseSeriesIndex(props.selectedExerciseSeriesIndex);
        }

        if (props.selectedExerciseIndex !== undefined) {
            setSelectedExerciseIndex(props.selectedExerciseIndex);
        }


        if (props.selectedSetIndex !== undefined) {
            setSelectedSetIndex(props.selectedSetIndex);
        }

        // if (props.workout !== undefined && props.workout.exerciseSeries !== undefined && props.selectedExerciseSeriesIndex < props.workout.exerciseSeries.length) {
        //     setSelectedExerciseSeries(props.workout.exerciseSeries[props.selectedExerciseSeriesIndex]);

        //     //let setIndexTemp = props.selectedSetIndex < 0 ? 0 : props.selectedSetIndex;
        //     setSelectedExercise(props.workout.exerciseSeries[props.selectedExerciseSeriesIndex].exercises[props.selectedExerciseIndex]);
        // }

        updateStats();

    }, [props.selectedExerciseSeriesIndex, props.selectedExerciseIndex, props.selectedSetIndex]);

    useEffect(() => {


        

        updateStats();

        


        // setSetStats(props.setStats);

        // if (props.setStats === undefined || props.setStats.length === 0) { return; }
        // let stats: SetStats_t[][] = props.setStats;

        // var points_temp: any[] = [];
        // for (var i = 0; i < stats.length; i++) {
        //     let thisExerciseStats = stats[i];
        //     for (var j = 0; j < thisExerciseStats.length; j++) {
        //         let thisSetStats = thisExerciseStats[j];
        //         let numReps = thisSetStats.repStats.length;
        //         points_temp.push({x:numReps, y:thisSetStats.weight});
        //     }
        // }

        // var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        // tempDataSets.datasets[0].data = points_temp;

        // setDataSets(tempDataSets);

    }, [props.currentWorkout]);

    function updateStats() {
        if (props.currentWorkout === undefined) { return; }
        var tonnageList: number[] = [];


        if (props.selectedSetIndex === -1) {
            // Generate exercise specific stats
            if (props.selectedExerciseSeriesIndex < props.currentWorkout.setStats.length) {
                // console.log("B")
                
                for (var i = 0; i < props.currentWorkout.setStats[props.selectedExerciseSeriesIndex].length; i++) {
                    let thisSetStats: SetStats_t[] = props.currentWorkout.setStats[props.selectedExerciseSeriesIndex][i];
                    let thisExStat: SetStats_t = selectedExerciseIndex < thisSetStats.length ? thisSetStats[props.selectedExerciseIndex] : thisSetStats[0];


                    // Calculate Tonnage
                    let thisTonnage = thisExStat.manualEntryReps * thisExStat.weight;
                    tonnageList.push(thisTonnage);
                }

                //return Math.round(tonnageSum);
            }

        } else {
            // Generate set specific stats

        }

        setChartLiftData(tonnageList);
    }

	return (
		<div className="intra-workout-stats-container">
            <div hidden={props.selectedSetIndex !== -1} className="intra-workout-stats-card-container">
                <IntraWorkoutStatsCard
                    title={"Tonnage"}
                    subTitle={"Explore how your client's tonnage (reps x weight) has varried over time over this exercise."}
                    units={['LBS']}
                    stepSize={250}
                    statNames={['Average Tonnage']}
                    chartType='line'
                    loading={false}
                    stats={[chartLiftData]}/>
            </div>
            
		</div>
	)
}

/*
<div className="intra-workout-stats-card">
                <div className="intra-workout-stats-card-header-container">
                    <p>Workout Stats Overview</p>
                    <h3>Set-by-set Analysis</h3>
                </div>
                <FullAnalysisComposition
                    setStats={setStats}/>
            </div>
            <div className="intra-workout-stats-card">
                <div className="intra-workout-stats-card-header-container">
                    <p>In-depth analysis</p>
                    <h3>Reps vs Weight Distribution</h3>
                </div>
                <div className="intra-workout-stats-chart-container">
                    <Scatter
                        data={dataSets}
                        options={chartOptions}
                    />
                </div>
            </div>
            <div className="intra-workout-stats-card">
                <div className="intra-workout-stats-card-header-container">
                    <p>List View</p>
                    <h3>Rep x Weight Exercise Breakdown</h3>
                </div>
                <div className="intra-workout-stats-chart-container-large">
                    <RepXWeightBreakdown
                        workout={props.workout}
                        setStats={setStats}/>
                </div>
            </div>
*/

/*
useEffect(() => {
        setSetStats(props.setStats);

        if (props.setStats === undefined || props.setStats.length === 0) { return; }
        let stats: SetStats_t[][] = props.setStats;

        var points_temp: any[] = [];
        for (var i = 0; i < stats.length; i++) {
            let thisExerciseStats = stats[i];
            for (var j = 0; j < thisExerciseStats.length; j++) {
                let thisSetStats = thisExerciseStats[j];
                let numReps = thisSetStats.repStats.length;
                points_temp.push({x:numReps, y:thisSetStats.weight});
            }
        }

        var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        tempDataSets.datasets[0].data = points_temp;

        setDataSets(tempDataSets);

    }, [props]);
*/

export default IntraWorkoutStats;