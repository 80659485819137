import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import axios from 'axios';

import './TrainersListCell.css';

import Trainer_t from '../../Interfaces/Trainer_t';
import Location_t from '../../Interfaces/Locations/Location_t';


function TrainersListCell(props: any) {

    let defaultTrainer: Trainer_t = {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        full_name: "",
        isVerified: false,
        permissions: 2
    };

    const [initSet, setInitSet] = useState(false);
    const [trainer, setTrainer] = useState<Trainer_t>(defaultTrainer);
    const [numClients, setNumClients] = useState(0);

    const [resendState, setResendState] = useState(0);
    const [locations, setLocations] = useState<Location_t[]>([]);
    const [thisLocationID, setThisLocationID] = useState("");

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.trainer !== undefined) {
            pullTrainerInfo(props.trainer);
        }
    }

    useEffect(() => {
        if (props.trainer !== undefined) {
            pullTrainerInfo(props.trainer);
        }
    }, [props.trainer])

    useEffect(() => {
        if (props.locations !== undefined) {
            setLocations(props.locations);
        }
    }, [props.locations])

    function pullTrainerInfo(trainerPlaceholder: Trainer_t) {
        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerPlaceholder.id}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                let updatedTrainerObj: Trainer_t = {
                    id: trainerPlaceholder.id,
                    email: data.email !== undefined ? data.email : "",
                    first_name: data.first_name !== undefined ? data.first_name : "",
                    last_name: data.last_name !== undefined ? data.last_name : "",
                    full_name: data.full_name !== undefined ? data.full_name : "",
                    isVerified: data.is_configured !== undefined ? data.is_configured : true,
                    permissions: trainerPlaceholder.permissions,
                    joined_date: data.joined_date !== undefined ? data.joined_date : "",
                    experienceIndex: data.years_experience !== undefined ? data.years_experience : "",
                    location_id: trainerPlaceholder.location_id,
                    location: trainerPlaceholder.location
                };

                setThisLocationID(trainerPlaceholder.location_id === undefined ? "" : trainerPlaceholder.location_id);
                setTrainer(updatedTrainerObj);
                pullTrainerClientList(updatedTrainerObj);
                props.objectUpdated(updatedTrainerObj);
            }
        });
    }

    function pullTrainerClientList(trainerObj: Trainer_t) {

        let sendData = {
            trainer_id: trainerObj.id
        };

        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getTrainerClientList`,
            sendData, { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response.data);
            let data = response.data;
            setNumClients(data.count);
        })
        .catch(e => {
            // console.log(e);
        })
    }

    function resendPressed() {

        if (resendState !== 0) { return; }
        setResendState(1);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                if (data.studio_id !== undefined || trainer.id === undefined) {
                    let notificationQueueRef = database.ref(`notification_queue/user_queue/${trainer.id}`);
                    notificationQueueRef.push({
                        info: {
                            studio_id: data.studio_id,
                            studio_name: "",
                            invitation_from: trainerUID,
                            new_trainer_id: trainer.id
                        },
                        notification_sent: false,
                        notify_timestamp: Date.now(),
                        type: 'studio-invitation-trainer'
                    });

                    setTimeout(() => {
                        setResendState(2);
                    }, 250);
                }
            }
        });
    }

    function getLocationName() {
        if (thisLocationID === "") { return ""; }

        for (var i = 0; i < locations.length; i++) {
            let lID = locations[i].id;
            if (lID === thisLocationID) {
                return locations[i].title;
            }
        }
        return "";
    }

	return (
		<div onClick={() => props.trainerSelected(trainer)} className="trainers-list-cell">
			<div className={`trainers-list-cell-col trainers-list-cell-col-header ${trainer.isVerified ? '' : 'trainers-list-cell-col-header-unverified'}`}>
                <h4>{trainer.isVerified ? trainer.full_name : "Unverified Coach"}</h4>
            </div>
            <div className="trainers-list-cell-col trainers-list-cell-col-md">
                <h4>{trainer.email}</h4>
            </div>
            {/*<div hidden={!trainer.isVerified} className="trainers-list-cell-col trainers-list-cell-col-md">
                <h4>{numClients === 0 ? 'No' : `${numClients}`} Athlete{numClients === 1 ? '' : 's'}</h4>
            </div>*/}
            <div hidden={!trainer.isVerified} className="trainers-list-cell-col trainers-list-cell-col-md">
                <h4>{getLocationName()}</h4>
            </div>
            <div hidden={trainer.isVerified} className="trainers-list-cell-col trainers-list-cell-col-md trainers-list-cell-col-resend-container">
                <div onClick={() => resendPressed()} className={`trainers-list-cell-col-resend-button ${resendState === 2 ? 'trainers-list-cell-col-resend-button-sent' : ''}`}>
                    <p>{resendState === 0 ? 'Resend Invite' : (resendState === 1 ? 'Sending...' : 'Invite sent!')}</p>
                </div>
            </div>
		</div>
	)
}

export default TrainersListCell;