import React, { useState, useEffect } from 'react';
import './ROMChart.css';
import './ChartCommon.css';

import SetStats_t from '../../Interfaces/SetStats_t';

import {Line, Bar} from 'react-chartjs-2';


function ROMChart(props: any) {

    let initDataSets = {
        labels: [],
        datasets: [
            {
                label: 'ROM trend',
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            },
            {
                label: 'ROM',
                backgroundColor: 'rgba(73,89,193,0.05)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                data: []
            }
        ]
    }

    const [dataSets, setDataSets] = useState(initDataSets);

    var chartOptions: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 0.1,
                    callback: function(value: any, index: any, values: any) {
                        return Math.floor(value * 100) + ` %`;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
      }

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.setStats === undefined || props.setStats.length === 0) { return; }
        let stats: SetStats_t[][][] = props.setStats;

        var labels_temp: string[] = [];
        var points_temp: number[] = [];
        for (var i = 0; i < stats.length; i++) {
            let thisSeriesStats: SetStats_t[][] = stats[i];

            var setCount = 0;

            for (var j = 0; j < thisSeriesStats.length; j++) {
                let thisSetStats: SetStats_t[] = thisSeriesStats[j];

                for (var k = 0; k < thisSetStats.length; k++) {
                    let thisExerciseStat: SetStats_t = thisSetStats[k];

                    let numReps = thisExerciseStat.manualEntryReps;
                    labels_temp.push(String(setCount + 1));
                    points_temp.push(thisExerciseStat.averageROM);

                    setCount += 1;
                }
            }
        }


        // for (var i = 0; i < stats.length; i++) {
        //     let thisExerciseStats = stats[i];
        //     for (var j = 0; j < thisExerciseStats.length; j++) {
        //         let thisSetStats = thisExerciseStats[j];
        //         labels_temp.push(String(j + 1));
        //         points_temp.push(thisSetStats.averageROM);
        //     }
        // }

        var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        tempDataSets.labels = labels_temp;
        tempDataSets.datasets[0].data = points_temp;
        tempDataSets.datasets[1].data = points_temp;

        setDataSets(tempDataSets);

    }, [props]);

	return (
		<div className="chart-container">
			<Bar
                data={dataSets}
                options={chartOptions}
            />
		</div>
	)
}

export default ROMChart;