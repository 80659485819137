import React, { useState } from 'react';
import './HelpAndFeedback.css';

import PageHeader_t from '../../Interfaces/PageHeader_t';

import PageHeader from '../../components/Base/PageHeader';

import '@material-ui/core';
import Timeline from '@material-ui/icons/Timeline';
import Star from '@material-ui/icons/Star';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import Add from '@material-ui/icons/Add';
import GroupAdd from '@material-ui/icons/GroupAdd';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import PostAdd from '@material-ui/icons/PostAdd';
import Assignment from '@material-ui/icons/Assignment';
import CalendarToday from '@material-ui/icons/CalendarToday';
import FolderShared from '@material-ui/icons/FolderShared';

function HelpAndFeedback(props: any) {

    let pageOptions: PageHeader_t[] = [{ name:"Walkthroughs", alert:false }];

    const [pageNumber, setPageNumber] = useState(0);

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    function openVideo() {
        // "https://youtu.be/ISF5rtH0lRU"
        window.open("https://youtu.be/ISF5rtH0lRU", '_blank', 'noopener,noreferrer');
    }

	return (
        <div className="page-container">
            <div className="home-header">
                <PageHeader 
                    pageName="Help & Feedback" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.addNewWorkout !== undefined ? props.addNewWorkout() : null}
                    addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
                    addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
    		<div className="help-and-feedback-content-container">
    			<div className="help-and-feedback-content-container-inner">
                    <div className="home-container-clients-column-container-left-welcome-header">
                        <div className="home-container-clients-column-container-left-welcome-header-content">
                            <h1>Eigen Walkthroughs</h1>
                            <h4>Get an overview of Eigen's features, training techniques, and tools that save you time training.</h4>
                            <div onClick={() => openVideo()} className="home-container-clients-column-container-left-welcome-video-button">
                                <p>Getting started walkthrough</p>
                                <ArrowForward className="home-container-clients-column-container-left-welcome-video-button-icon"/>
                            </div>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-img-container">
                            <img className="-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/welcome-bg.png`}/>
                        </div>
                    </div>
                    <div className="help-and-feedback-content-section">
                        <div className="help-and-feedback-content-section-header">
                            <h4>Most popular</h4>
                            <p>Watch video walkthroughs of the most asked questions</p>
                        </div>
                        <div className="help-and-feedback-content-section-content">
                            <div className="help-and-feedback-content-section-content-row">
                                <div className="help-and-feedback-content-section-card">
                                    <div className="help-and-feedback-content-section-card-img-container help-and-feedback-content-section-card-img-container-0">
                                        <img className="" src={`${process.env.PUBLIC_URL}/assets/images/support/get-started.svg`}/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Getting started</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card">
                                    <div className="help-and-feedback-content-section-card-img-container help-and-feedback-content-section-card-img-container-1">
                                        <img className="" src={`${process.env.PUBLIC_URL}/assets/images/support/organize-folders.svg`}/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Organizing Programs</h4>
                                        <p>Make the most of Folders, Cycles, and Phases to simplify programming</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card">
                                    <div className="help-and-feedback-content-section-card-img-container help-and-feedback-content-section-card-img-container-2">
                                        <img className="" src={`${process.env.PUBLIC_URL}/assets/images/support/stay-on-track.svg`}/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Setting Client Goals</h4>
                                        <p>Help your Client stay on track to reach their fitness goals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="help-and-feedback-content-section-content-row">
                                <div className="help-and-feedback-content-section-card">
                                    <div className="help-and-feedback-content-section-card-img-container help-and-feedback-content-section-card-img-container-3">
                                        <img className="" src={`${process.env.PUBLIC_URL}/assets/images/support/new-order.svg`}/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Ordering Nodes</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card">
                                    <div className="help-and-feedback-content-section-card-img-container help-and-feedback-content-section-card-img-container-4">
                                        <img className="" src={`${process.env.PUBLIC_URL}/assets/images/support/search-nodes.svg`}/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Connecting Nodes</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card">
                                    <div className="help-and-feedback-content-section-card-img-container help-and-feedback-content-section-card-img-container-5">
                                        <img className="" src={`${process.env.PUBLIC_URL}/assets/images/support/training.svg`}/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Training with Nodes</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="help-and-feedback-content-section">
                        <div className="help-and-feedback-content-section-header">
                            <h4>Eigen Basics</h4>
                            <p>Learn about Eigen core features to jump start your newest training programs.</p>
                        </div>
                        <div className="help-and-feedback-content-section-content">
                            <div className="help-and-feedback-content-section-content-row">
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <GroupAdd className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Create a new Athlete</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <FitnessCenter className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Create a new Workout</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <PostAdd className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Create a new Program/Phase</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                            </div>
                            <div className="help-and-feedback-content-section-content-row">
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <Assignment className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Assigning a Program</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <CalendarToday className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Working with Schedules</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <FolderShared className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Managing Athlete Folders</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="help-and-feedback-content-section">
                        <div className="help-and-feedback-content-section-header">
                            <h4>Advanced Topics</h4>
                            <p>Take advantage of Eigen's expert tools that make complex programming simple.</p>
                        </div>
                        <div className="help-and-feedback-content-section-content">
                            <div className="help-and-feedback-content-section-content-row">
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <FitnessCenter className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Build a Phase in Schedule</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <GroupAdd className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Advanced set building</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <PostAdd className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Custom Loading Patterns</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                            </div>
                            <div className="help-and-feedback-content-section-content-row">
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <Assignment className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Assigning a Program</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <CalendarToday className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Working with Schedules</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                                <div className="help-and-feedback-content-section-card help-and-feedback-content-section-card-small">
                                    <div className="help-and-feedback-content-section-card-icon-container">
                                        <FolderShared className="help-and-feedback-content-section-card-icon"/>
                                    </div>
                                    <div className="help-and-feedback-content-section-card-text-container">
                                        <h4>Managing Athlete Folders</h4>
                                        <p>Learn how to use the platform and programming with best practices</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="help-and-feedback-content-section help-and-feedback-content-section-feedback">
                        <div className="help-and-feedback-content-section-header">
                            <h4>How can we help?</h4>
                            <p>Reach out to us and let us know about any <span>new features</span> you'd love to see, <span>troubles</span> you've run in to, or just <span>how your day is going</span>!</p>
                        </div>
                        <div onClick={() => props.contactCardPressed()} className="home-container-clients-column-container-left-welcome-video-button">
                            <p>Reach out</p>
                            <ArrowForward className="home-container-clients-column-container-left-welcome-video-button-icon"/>
                        </div>
                    </div>
                </div>
    		</div>
        </div>
	)
}

export default HelpAndFeedback;