import React, { useState } from 'react';
import firebase from '../../../Firebase';
import axios from 'axios';

import './Trainers.css';

import Trainer_t from '../../../Interfaces/Trainer_t';
import Client_t from '../../../Interfaces/Client_t';
import PageHeader_t from '../../../Interfaces/PageHeader_t';
import Location_t from '../../../Interfaces/Locations/Location_t';

import TrainersListCell from '../../../components/Studio/TrainersListCell';
import Search from '../../../components/Base/Search';
import TextInput from '../../../components/Base/TextInput';
import StandardButton from '../../../components/Base/StandardButton';
import ClientCell from '../../../components/Clients/ClientCell';
import PageHeader from '../../../components/Base/PageHeader';


import '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

function Trainers(props: any) {

    let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];

    let pageOptions: PageHeader_t[] = [{ name:"Coaches at My Studio", alert:false }];

    const [isLoading, setIsLoading] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [trainers, setTrainers] = useState<Trainer_t[]>([]);
    const [allTrainers, setAllTrainers] = useState<Trainer_t[]>([]);
    const [initSet, setInitSet] = useState(false);

    const [locations, setLocations] = useState<Location_t[]>([]);
    const [edittingLocation, setEdittingLocation] = useState(false);
    const [isEditted, setIsEditted] = useState(false);


    const [selectedTrainer, setSelectedTrainer] = useState<Trainer_t | undefined>();
    const [selectedTrainerClientList, setSelectedTrainerClientList] = useState<any[]>([]);

    const [selectedTrainerLocID, setSelectedTrainerLocID] = useState("");

    const [studioID, setStudioID] = useState("");

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullTrainers();

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setIsLoading(true);

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                if (data.studio_id !== undefined && data.studio_id !== "") {
                    pullLocations(data.studio_id);
                    setStudioID(data.studio_id);
                } else {
                    setStudioID("");
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        });

    }

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    function textInputChanged(toString: string) {
        var temp = [];
        for (var i = 0; i < allTrainers.length; i++) {
            let searchString = toString.toUpperCase();
            let nameString = allTrainers[i].full_name.toUpperCase();
            let positiveSearch_name = nameString.includes(searchString);

            var positiveSearch_email = false;
            let emailString = allTrainers[i].email;
            if (emailString != undefined && emailString != null) {

            }

            if (positiveSearch_name === true) {
                temp.push(allTrainers[i]);
            }
        }

        setTrainers(temp);
    }

    function pullLocations(forStudioID: string) {

        let database = firebase.database();
        let ref_studio = database.ref(`training_studios/${forStudioID}/locations`);
        ref_studio.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                parseLocations(data);
            } else {
                setIsLoading(false);
            }
        });
    }

    function parseLocations(locationList: any) {
        if (locationList === undefined) { return; }
        let locationKeys = Object.keys(locationList);

        var locationsTemp: Location_t[] = [];

        for (var i = 0; i < locationKeys.length; i++) {
            let thisLocationKey = locationKeys[i];
            let thisLocationObj = locationList[thisLocationKey];


            let newLocation: Location_t = {
                id: thisLocationKey,
                title: thisLocationObj.name !== undefined ? thisLocationObj.name : "My Location",
                owner_id: thisLocationObj.owner_id,
                location: thisLocationObj.location !== undefined ? thisLocationObj.location : {
                    address_string: "",
                    unit_string: "",
                    google_place_id: "",
                    coordinates: {lat: 0.0, lng: 0.0},
                    isHQ: false
                },
                times: thisLocationObj.times !== undefined ? thisLocationObj.times : {
                    monday: {open: 420, close: 1080, isOpen: true},
                    tuesday: {open: 420, close: 1080, isOpen: true},
                    wednesday: {open: 420, close: 1080, isOpen: true},
                    thursday: {open: 420, close: 1080, isOpen: true},
                    friday: {open: 420, close: 1080, isOpen: true},
                    saturday: {open: 420, close: 1080, isOpen: true},
                    sunday: {open: 420, close: 1080, isOpen: true}
                },
                trainers: []
            }

            locationsTemp.push(newLocation);
        }
        // console.log("LOCATIONS:", locationsTemp)
        setLocations(locationsTemp);

        setIsLoading(false);

    }

    function pullTrainers() {
        

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                if (data.studio_id !== undefined) {
                    // Studio has been added to account
                    pullStudioInfo(data.studio_id);
                } else {
                    configureStudio(data, trainerUID);
                }
            }
        });
    }

    function pullStudioInfo(studioID: string) {
        let database = firebase.database();
        let ref = database.ref(`training_studios/${studioID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let studioTrainerList: any[] = data.trainers;
                var trainersTemp: Trainer_t[] = [];
                for (var i = 0; i < studioTrainerList.length; i++) {
                    let thisTrainerObj = studioTrainerList[i];
                    let newTrainer: Trainer_t = {
                        id: thisTrainerObj.id !== undefined ? thisTrainerObj.id : "unknown",
                        email: "",
                        first_name: "",
                        last_name: "",
                        full_name: "",
                        isVerified: false,
                        permissions: thisTrainerObj.permission !== undefined ? thisTrainerObj.permission : 2,
                        location_id: thisTrainerObj.location_id
                    }
                    trainersTemp.push(newTrainer);
                }
                setAllTrainers(trainersTemp);
                setTrainers(trainersTemp);
            }
        });
    }

    function configureStudio(userObj: any, trainerUID: string) {

        let database = firebase.database();

        // Configure Studio
        let refStudio = database.ref(`training_studios`).push({
            name: userObj.company_name !== undefined ? userObj.company_name : "My Studio",
            city: userObj.city !== undefined ? userObj.city : "Unknown City",
            owner_id: trainerUID,
            owner_email: userObj.email,
            created: Date.now(),
            trainers: [{id: trainerUID, permission: 0}]
        });

        let studioID = refStudio.key;

        // Add studio ID to user's profile
        database.ref(`personal_trainer_profile/${trainerUID}/studio_id`).set(studioID);
    }

    function updateTrainerObject(withObj: Trainer_t, atIndex: number) {
        allTrainers.map((item: Trainer_t, index: number) => {
            if (index === atIndex) {
                return withObj
            } else {
                return item;
            }
        })
    }

    function trainerSelected(t: Trainer_t) {
        setWorkflowState(1);

        setSelectedTrainerLocID(t.location_id === undefined ? '' : t.location_id);

        setSelectedTrainer(t);

        let sendData = {
            trainer_id: t.id
        };

        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getTrainerClientList`,
            sendData, { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response.data);
            let data = response.data;

            var listTemp: any[] = [];
            for (var i = 0; i < Object.keys(data.list).length; i++) {
                let thisID = Object.keys(data.list)[i];
                let thisObj: any = data.list[thisID];
                thisObj["id"] = thisID;

                listTemp.push(thisObj);
            }
            setSelectedTrainerClientList(listTemp);

            setWorkflowState(0);
        })
        .catch(e => {
            // console.log(e);
            setWorkflowState(0);
        })
    }

    function clientSelected(withID: string) {
        setWorkflowState(2);
        let database = firebase.database();
        let ref = database.ref(`user_profile/${withID}`)
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let thisUser = snapshot.val();

                let newClient: Client_t = {
                    first_name:thisUser.first_name, 
                    last_name:thisUser.last_name, 
                    full_name:thisUser.full_name, 
                    id: withID, 
                    gender: thisUser.gender,
                    email: thisUser.email,
                    weight: thisUser.weight,
                    height: thisUser.height,
                    isMetric: thisUser.units_kg,
                    birthdate: thisUser.birth_date,
                    tags: [goalDescriptors[0], "Gain Muscle"],
                    isSelected: false
                };
                setWorkflowState(0);
                if (props.clientSelected !== undefined) {
                    props.clientSelected(newClient)
                }
            } else {
                setWorkflowState(0);
            }
        });
    }

    function getSelectedLocation() {
        if (locations.length > 0) {
            var loc: Location_t | null = null;
            for (var i = 0; i < locations.length; i++) {
                if (locations[i].id === selectedTrainerLocID) {
                    loc = locations[i];
                }
            }
            return loc;
        } else {
            return null;
        }
    }

    function getSelectedLocationName() {
        let loc: null | Location_t = getSelectedLocation();
        if (loc === null) { return locations.length === 0 ? '' : locations[0].title; }

        return loc.title;
    }

    function updateSelectedLocation(loc: Location_t) {
        // console.log("updateSelectedLocation", trainers, loc, selectedTrainer)
        setIsEditted(true);
        setEdittingLocation(false);

        setSelectedTrainerLocID(loc.id);

        if (selectedTrainer !== undefined) {
            setTrainers(trainers.map((item: Trainer_t, index: number) => {
                if (item.id === selectedTrainer.id) {
                    return {...item, location_id: loc.id, location: loc};
                } else {
                    return item;
                }
            }))
        }
        
    }

    function saveProfileChanges() {

        if (studioID === "" || selectedTrainer === undefined) { return; }

        setIsLoading(true);
        setIsEditted(false);
        setEdittingLocation(false);

        let database = firebase.database();
        let ref = database.ref(`training_studios/${studioID}/trainers`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let studioTrainerList: any[] = data;
                
                for (var i = 0; i < studioTrainerList.length; i++) {
                    let thisTrainerObj = studioTrainerList[i];
                    if (thisTrainerObj.id !== undefined && thisTrainerObj.id === selectedTrainer.id) {
                        ref.child(`${i}`).child('location_id').set(selectedTrainerLocID);
                    }
                }
                setTimeout(() => {
                    setIsLoading(false);
                }, 800);
                
            } else {
                setIsLoading(false);
            }
        });

    }


	return (
		<div className="trainers-page">
			<div className="home-header">
                <PageHeader 
                    pageName="Coaches" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
                    addNewWorkoutPlan={() => props.createNewWorkoutPlan !== undefined ? props.createNewWorkoutPlan() : null}
                    addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
            <div className="trainers-page-container">
                <div hidden={!isLoading} className="my-coaching-profile-curtain">
                    <div className="workout-plan-content-loading-spinner"/>
                    <p>{isEditted ? "Saving" : "Loading"} your Studio profile...</p>
                </div>
                <div className="trainers-page-content">
                    <div className="page-title-header-container">
                        <h1>Coaches at My Studio</h1>
                    </div>
                    <div className="trainers-page-upper-content">
                        <div className="trainers-page-upper-content-search-container">
                            <Search 
                                placeholder="Search by name or email..." 
                                inputChanged={(e: string) => textInputChanged(e)}/>
                        </div>
                        <div hidden={props.showTitle !== undefined && props.showTitle === true} className="trainers-page-upper-content-showing-number-container">
                            <p>Showing {trainers.length} of {allTrainers.length}</p>
                        </div>
                        <div hidden={props.showTitle !== undefined && props.showTitle === true} className="trainers-page-upper-content-add-button-container">
                            <div hidden={props.hideAddNewClient !== undefined && props.hideAddNewClient === true} onClick={() => props.addNewTrainerPressed()} className="trainers-page-upper-content-add-button">
                                <Add className="trainers-page-upper-content-add-button-icon"/>
                                <p>Add Coaches</p>
                            </div>
                        </div>
                    </div>
                    <div className="trainers-page-content-list-container">
                        <div className="trainers-page-content-list-header">
                            <div className="trainers-page-content-list-header-col trainers-page-content-list-header-col-header">
                                <p>COACH NAME</p>
                            </div> 
                            <div className="trainers-page-content-list-header-col trainers-page-content-list-header-col-md">
                                <p>EMAIL</p>
                            </div> 
                            <div className="trainers-page-content-list-header-col trainers-page-content-list-header-col-md">
                                <p>LOCATION</p>
                            </div> 
                        </div>     
                        <div className="trainers-page-content-list-body">
                            {
                                trainers.map((item: Trainer_t, index: number) => (
                                    <TrainersListCell 
                                        trainer={item} 
                                        index={index}
                                        locations={locations}
                                        objectUpdated={(e: Trainer_t) => updateTrainerObject(e, index)}
                                        trainerSelected={(e: Trainer_t) => trainerSelected(e)}/>
                                ))
                            }
                        </div>                   
                    </div>
                </div>
                <div onClick={() => saveProfileChanges()} hidden={!isEditted} className="my-studio-page-info-panel-container-section-header-save-button trainers-header-save-button">
                    <p>Save Changes</p>
                </div>
                <div className="trainers-page-info-panel-container">
                    <div hidden={selectedTrainer !== undefined} className="trainers-page-info-panel-container-empty">
                        <h4>No Coach Selected</h4>
                        <p>Select a Coach for more information</p>
                    </div>
                    <div hidden={selectedTrainer === undefined} className="trainers-page-info-panel-container-content">
                        <div className="trainers-page-info-panel-container-content-section trainers-page-info-panel-container-content-section-line">
                            <div className="trainers-page-info-panel-container-content-section-header">
                                <h4>Coach Details</h4>
                            </div>
                            <div className="trainers-page-info-panel-container-content-section-body">
                                <div className="my-studio-page-info-panel-input-row">
                                    <div className="my-studio-page-info-panel-input-row-header">
                                        <h4>Main Location</h4>
                                    </div>
                                    <div onClick={() => props.addLocationPressed()} hidden={locations.length > 0} className="my-studio-page-info-panel-input-row-location-button">
                                        <Add className="my-studio-page-info-panel-input-row-location-button-icon"/>
                                        <h4>Add a Location</h4>
                                    </div>
                                    <div hidden={locations.length === 0} className="my-studio-page-info-panel-input-row-text my-studio-page-info-panel-input-row-text-dropdown">
                                        <div onClick={() => setEdittingLocation(!edittingLocation)} hidden={selectedTrainerLocID === ""} className="my-studio-page-info-panel-input-row-text-dropdown-selection">
                                            <h4>{getSelectedLocationName()}</h4>
                                        </div>
                                        <div onClick={() => setEdittingLocation(!edittingLocation)} hidden={selectedTrainerLocID !== ""} className="my-studio-page-info-panel-input-row-text-dropdown-selection my-studio-page-info-panel-input-row-text-dropdown-selection-empty">
                                            <h4>No Location Added</h4>
                                        </div>
                                        <div onClick={() => setEdittingLocation(!edittingLocation)} hidden={edittingLocation} className="my-studio-page-info-panel-input-row-text-dropdown-icon-container">
                                            <ExpandMore className="my-studio-page-info-panel-input-row-text-dropdown-icon"/>
                                        </div>
                                        <div onClick={() => setEdittingLocation(!edittingLocation)} hidden={!edittingLocation} className="my-studio-page-info-panel-input-row-text-dropdown-icon-container">
                                            <ExpandLess className="my-studio-page-info-panel-input-row-text-dropdown-icon"/>
                                        </div>
                                        <div hidden={!edittingLocation} className="my-studio-page-info-panel-input-row-text-dropdown-menu">
                                            {
                                                locations.map((item: Location_t, index: number) => (
                                                    <div onClick={() => updateSelectedLocation(item)} className="my-studio-page-info-panel-input-row-text-dropdown-menu-row">
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="trainers-page-info-panel-container-content-section trainers-page-info-panel-container-content-section-line">
                            <div className="trainers-page-info-panel-container-content-section-header">
                                <h4>Recent Workouts</h4>
                            </div>
                            <div className="trainers-page-info-panel-container-content-section-body">

                            </div>
                        </div>
                        <div hidden={workflowState === 0} className="trainers-page-info-panel-container-content-loading-curtain">
                            <div className="trainers-page-info-panel-container-loading-container">
                                <div className="client-list-container-loading-spinner"/>
                                <p>Loading {workflowState === 1 ? 'Coach' : 'Athlete'} Info...</p>
                            </div>
                        </div>
                        <div className="trainers-page-info-panel-container-content-section">

                            <div className="trainers-page-info-panel-container-content-section-header">
                                <h4>Athlete List</h4>
                                <p>({selectedTrainerClientList.length})</p>
                            </div>
                            <div className="trainers-page-info-panel-container-content-section-body">
                                {
                                    selectedTrainerClientList.map((item: any, index: number) => (
                                        <div onClick={() => clientSelected(item.id)} className="trainers-page-info-panel-container-content-section-body-row">
                                            <div className="trainers-page-info-panel-container-content-section-body-row-col">
                                                <h4>{item.full_name}</h4>
                                            </div>
                                            <div hidden={item.last_workout_date === "00-00-0000"} className="trainers-page-info-panel-container-content-section-body-row-col">
                                                <h4>{item.last_workout_date}</h4>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default Trainers;