import React, { useState, useEffect } from 'react';
import './NewWorkout_Confirm.css';

import Workout_t from '../../../Interfaces/Workout_t';
import Client_t from '../../../Interfaces/Client_t';

import NewWorkoutClientCard from './NewWorkoutClientCard';
import NewWorkoutWorkoutCard from './NewWorkoutWorkoutCard';
import NewWorkoutNodeCard from './NewWorkoutNodeCard';
import StandardButton from '../../../components/Base/StandardButton';

function NewWorkoutConfirmPage(props: any) {

    let initClient: Client_t = {first_name:'',
                                last_name:'',
                                full_name:'',
                                id:'', 
                                isMetric: true, 
                                gender: 'b', 
                                birthdate: '00-00-0000',
                                height: 0, 
                                weight: 0,
                                goalIndex: 0,
                                experienceIndex: 0};

    let initWorkout: Workout_t = {
                        title: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        duration: 0,
                        imageName: '',
                        targetGender: '',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0
                    };

    const [client, setClient] = useState<Client_t>(initClient);
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        // console.log("CHANGING WORKOUT")
        setWorkout(props.workout);
    }, [props.workout]);

    useEffect(() => {
        // console.log("CHANGING CLIENT")
        setClient(props.client);
    }, [props.client]);

    function initialize() {
        // console.log("New Workout Confirm Page Workout prop:");
        // console.log(props.workout);
        setClient(props.client);
        setWorkout(props.workout);
    }

    function beginButtonPressed() {
        // console.log("BEGIN!");
        props.beginWorkout();
    }

	return (
		<div className="new-workout-confirm">
            <div className="new-workout-workout-header">
                <h1>Confirm & begin your Workout</h1>
            </div>
			<NewWorkoutClientCard
                client={client}/>
            <NewWorkoutWorkoutCard
                workout={workout}/>
            <NewWorkoutNodeCard
                nodesAssigned={props.nodesAssigned}
                nodeManager={props.nodeManager}/>
            <div className="new-workout-confirm-button-container">
                <StandardButton
                    buttonText="Begin workout"
                    isLarge={false}
                    callBack={() => beginButtonPressed()}/>
            </div>
		</div>
	)
}

export default NewWorkoutConfirmPage;