import React, { useState } from 'react';
import firebase from '../../Firebase';

import Client_t from '../../Interfaces/Client_t';
import Note_t from '../../Interfaces/Note_t';

import './ClientProfileNotes.css';
import './ClientProfileCommon.css'

import '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Add from '@material-ui/icons/Add';
import FilterList from '@material-ui/icons/FilterList';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

function ClientProfileNotes(props: any) {

	let noteTypes = ['General','Form Improvement', 'Stretch', 'Injury Risk', 'Other'];
	let noteTypeColours = ['193,73,193','73,89,193','73,193,89','193,73,89','255,255,255'];

	let testNote = {
		title: "My first note",
		id: "abc-123",
		createdBy: {name: "Johnny Appleseed", "id": "dnfjdnjsf"},
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		time_stamp: 1630489554,
		client_id: "LRAYWIcH4IOUb7zzLWIWA6G1VdE2",
		type: 0,
		trainer_id: "3fCdlRTmfWSuozlTNHFoou8c1LU2",
		// tags: {},
		remind_me_next_time: true,
		seen_by: ["LRAYWIcH4IOUb7zzLWIWA6G1VdE2","3fCdlRTmfWSuozlTNHFoou8c1LU2"]
	};

	const [notesList, setNotesList] = useState<Note_t[]>([]);
	const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
    	if (props.client === null || props.client === undefined) { return; }
    	fetchNotes();
    }

	function addNotePressed() {
		props.addNotePressed();
	}

	function fetchNotes() {

		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_notes/${trainerUID}/${props.client.id}`); // Change to database path
		ref.on('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        let data = snapshot.val();

		        var notesTemp: Note_t[] = [];
		        let dataKeys = Object.keys(data);
		        for (var i = 0; i < dataKeys.length; i++) {
		        	let thisData = data[dataKeys[i]];

		        	let newNote: Note_t = {
		        		title: thisData.title,
		        		id: dataKeys[i],
		        		createdBy: {
		        			name: "", id: ""
		        		},
		        		body: thisData.note,
		        		time_stamp: thisData.timestamp,
		        		client_id: props.client.id,
		        		type: thisData.type,
		        		trainer_id: trainerUID,
		        		remind_me_next_time: thisData.remind_me_next_time,
		        		seen_by: thisData.seen_by
		        	}

		        	notesTemp.push(newNote);
		        }

		        setNotesList(notesTemp);
		        // Parse and use data here
		        
		    } else {

		    }
		});
	}

	function getDateString(timestampData: number) {

        let monthStrings = ['January','February','March','April','May','June','July','August','September','October','November','December'];

        let timestamp = new Date(timestampData);
        let mo = monthStrings[timestamp.getMonth()];
        let da = timestamp.getDate();
        let yr = timestamp.getFullYear();
        let hh = timestamp.getHours();
        let mm = timestamp.getMinutes();


        return ` Note created on ${mo} ${da}, ${yr} at ${hh % 12}:${mm < 10 ? `0${mm}` : mm} ${hh > 12 ? 'PM' : 'AM'}`
    }

    function getNoteTypeColourStyle(type: number) {
    	return { background: `rgba(${noteTypeColours[type]},0.2)`, color: `rgba(${noteTypeColours[type]})` };
    }

	return (
		<div className="client-profile-page-container">
			<div className="client-profile-page-blank" hidden={notesList.length !== 0}>
				<h2>Notes Log Empty</h2>
				<p>It looks like there aren't any notes for {props.client !== undefined ? props.client.first_name : ""} yet.</p>
				<div onClick={() => addNotePressed()} className="client-profile-page-add-first-note-button">
					<p>Add your first note</p>
					<AddCircleOutline className="client-profile-page-add-first-note-button-icon"/>
				</div>
			</div>
			<div hidden={notesList.length === 0} className="client-profile-page-note-container">
				<div hidden={true} onClick={() => addNotePressed()} className="client-profile-page-note-container-add-new-note-cell">
					<h4>Add another note</h4>
					<p>Create a new note in {props.client !== undefined ? props.client.first_name : ""}'s profile</p>
					<AddCircleOutline className="client-profile-page-add-new-note-button-icon"/>
				</div>
				<div className="client-profile-page-note-header-container">
					<h4>Client Notes</h4>
					<div className="client-profile-page-note-header-buttons-container">
						<div onClick={() => addNotePressed()} className="client-profile-page-note-header-button client-profile-page-note-header-button-blue">
							<Add className="client-profile-page-note-header-button-icon client-profile-page-note-header-button-blue-icon"/>
							<p>New Note</p>
						</div>
						<div onClick={() => addNotePressed()} className="client-profile-page-note-header-button client-profile-page-note-header-button-dark">
							<FilterList className="client-profile-page-note-header-button-icon client-profile-page-note-header-button-dark-icon"/>
							<p>View</p>
							<KeyboardArrowDown className="client-profile-page-note-header-button-icon client-profile-page-note-header-button-dark-icon client-profile-page-note-header-button-dark-icon-arrow"/>
						</div>
					</div>
				</div>
				<div className="client-profile-page-note-container">
					{
						notesList.map((item: Note_t, index: number) => (
							<div className="client-profile-page-note-cell">
								<div className="client-profile-page-note-cell-header">
									<h2>{item.title}</h2>
									<p>{getDateString(item.time_stamp)}</p>
									<div className="client-profile-page-note-cell-type-container" style={getNoteTypeColourStyle(item.type)}>
										<p>{noteTypes[item.type]}</p>
									</div>
								</div>
								<div className="client-profile-page-note-cell-body">
									<p>{item.body}</p>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}

export default ClientProfileNotes;