import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './CalendarMonthCell.css';


import Workout_t from '../../Interfaces/Workout_t';
import Plan_t from '../../Interfaces/Plan_t';
import ScheduleDay_t from '../../Interfaces/ScheduleDay_t';
import WorkoutLogBrief_t from '../../Interfaces/WorkoutLogBrief_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import Muscles_t from '../../Interfaces/Muscles_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import Client_t from '../../Interfaces/Client_t';
import CalendarDateCell_t from '../../Interfaces/CalendarDateCell_t';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Add from '@material-ui/icons/Add';

function CalendarMonthCell(props: any) {

    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
    let initWorkout: Workout_t = {
                        title: '',
                        description: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        duration: 0,
                        imageName: '',
                        targetGender: 'b',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0,
                        goals: [],
                        location: 'Gym'
                    };

    const [initSet, setInitSet] = useState(false);

    const [thisTimestamp, setThisTimestamp] = useState(0);
    const [thisDay, setThisDay] = useState(0);
    const [thisMonth, setThisMonth] = useState(0);
    const [thisYear, setThisYear] = useState(0);

    const [musclesString, setMusclesString] = useState('');
    const [durationString, setDurationString] = useState('');
    const [volumeString, setVolumeString] = useState('');

    const [clientName, setClientName] = useState("");

    const [currentWorkoutID, setCurrentWorkoutID] = useState("");

    const [isDragging, setIsDragging] = useState(false);

    const [draggingDay, setDraggingDay] = useState<any | undefined>();

    const [calendarDay, setCalendarDay] = useState<any>({
        timestamp: -1,
        workoutState: "none",
        show_proposed: false
    });

    const [isScheduledWithTrainer, setIsScheduledWithTrainer] = useState(false);
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);

    const [isLoadingWorkout, setIsLoadingWorkout] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.calendarDay !== undefined) {
            let newDate = new Date(props.calendarDay.timestamp);

            setThisTimestamp(props.calendarDay.timestamp);
            setThisDay(newDate.getDate());
            setThisMonth(newDate.getMonth());
            setThisYear(newDate.getFullYear());

            // Check if this workout has been scheduled
            if (props.calendarDay.scheduleDay !== undefined && props.calendarDay.scheduleDay.scheduled !== undefined) {
                setIsScheduledWithTrainer(true);
            }
        }

        setDraggingDay(props.draggingDay);

        if (props.calendarDay !== undefined && props.calendarDay.scheduleDay !== undefined) {
            let thisScheduledDay: ScheduleDay_t = props.calendarDay.scheduleDay;
            pullWorkout(thisScheduledDay.workout_id);
        }
    }

    useEffect(() => {
        setDraggingDay(props.draggingDay);
    }, [props.draggingDay]);

    useEffect(() => {
        setDraggingDay(props.draggingDay);
    }, [props.draggingDay]);

    useEffect(() => {
        if (props.calendarDay !== undefined) {
            let newDate = new Date(props.calendarDay.timestamp);

            setThisTimestamp(props.calendarDay.timestamp);
            setThisDay(newDate.getDate());
            setThisMonth(newDate.getMonth());
            setThisYear(newDate.getFullYear());

            setCalendarDay(props.calendarDay);

            setClientName("")

            if (props.calendarDay.scheduleDay !== undefined) {
                let thisScheduledDay: ScheduleDay_t = props.calendarDay.scheduleDay;
                pullWorkout(thisScheduledDay.workout_id);
            }
        }
    }, [props.calendarDay]);

    function pullWorkout(workoutID: string) {
        if (workoutID === "" || workoutID === currentWorkoutID) { return; }

        setCurrentWorkoutID(workoutID);

        setIsLoadingWorkout(true);

        let database = firebase.database();
        let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
        thisWorkoutRef.on('value', function(snapshotWorkout) {
            if (snapshotWorkout.exists() === true) {
                let thisWorkout = snapshotWorkout.val();

                parsePersonalWorkouts(thisWorkout, workoutID);
            } else {
                setIsLoadingWorkout(false);
            }
        });
    }

    async function parsePersonalWorkouts(thisWorkout: any, key: string) {

        let newDate = new Date(props.calendarDay.timestamp);
        let newDateD = newDate.getDate();
        let newDateM = newDate.getMonth();
        let newDateY = newDate.getFullYear();

        let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

        // console.log(`${newDateD}-${newDateM}-${newDateY} A`);

        // Configure Exercise Series
        var seriesList_temp: ExerciseSeries_t[] = [];
        let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
        if (containsExerciseSeries === true) {
            for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                let thisSeriesObj = thisWorkout.exercise_series[i];
                let thisSetLetter = getSetLetter(i);

                var exerciseList_temp: Exercise_t[] = [];
                if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                    for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                        let thisExerciseObj = thisSeriesObj.exercises[j];
                        let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                        exerciseList_temp.push(exTemp);
                    }
                }

                var seriesTemp: ExerciseSeries_t = {
                    title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                    index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                    type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                    exercises: exerciseList_temp
                };

                seriesList_temp.push(seriesTemp);
            }
        } else {
            // Workout was created "pre-series". Create a new placeholder series for each existing exercise
            if (thisWorkout.exercises !== null && thisWorkout.exercises !== undefined && thisWorkout.exercises.length > 0) {
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    let thisExerciseTemp: any = thisWorkout.exercises[i];
                    let thisSetLetter = getSetLetter(i);
                    var seriesTemp: ExerciseSeries_t = {
                        title: thisSetLetter,
                        index: i,
                        type: 0,
                        exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                    };
                    seriesList_temp.push(seriesTemp);
                }
            }
        }

        var exerciseList: Exercise_t[] = [];
        if (thisWorkout.exercises !== null && thisWorkout.exercises !== undefined && thisWorkout.exercises.length > 0) {

            var exerciseReps: any = {};
            for (var i = 0; i < thisWorkout.exercises.length; i++) {
                var a: number[] = [];
                if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                    for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                        let thisRep = thisWorkout.exercises[i].reps[j];
                        a.push(thisRep);
                        
                    }
                }
                exerciseReps[thisWorkout.exercises[i].key] = a;
            }

            var exerciseTempos: any = {};
            for (var i = 0; i < thisWorkout.exercises.length; i++) {
                var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                    for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                        let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                        let thisTempo: Tempo_t = {
                            concentric: thisTempoObj.concentric,
                            eccentric: thisTempoObj.eccentric,
                            first_pause: thisTempoObj.first_pause,
                            second_pause: thisTempoObj.second_pause
                        };
                        b.push(thisTempo);
                    }
                }
                exerciseTempos[thisWorkout.exercises[i].key] = b;
            }
            // console.log(`${newDateD}-${newDateM}-${newDateY} B1`);
            let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
            exerciseList = e;
            // console.log(`${newDateD}-${newDateM}-${newDateY} B2`, exerciseList);

            // push exercises into according slots in seriesList_temp
            if (exerciseList.length > 0) {
                for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                    let thisExercise: Exercise_t = exerciseList[ex_index];
                    let thisExercise_ID = thisExercise.id;

                    // Loop through seriesList_temp to find thisExercise's matching location
                    if (seriesList_temp.length > 0) {
                        for (var i = 0; i < seriesList_temp.length; i++) {
                            let thisSeries = seriesList_temp[i];
                            if (thisSeries.exercises.length > 0) {
                                for (var j = 0; j < thisSeries.exercises.length; j++) {
                                    let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                    let thisSeriesExercise_ID = thisSeriesExercise.id;
                                    if (thisSeriesExercise_ID === thisExercise_ID) {
                                        // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                        // for the case of multiple of the same exercises in a workout
                                        if (thisSeriesExercise.title === '') {
                                            seriesList_temp[i].exercises[j] = thisExercise;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
        
        let newWorkout: Workout_t = {
            title: thisWorkout.title,
            description: thisWorkout.description !== undefined ? thisWorkout.description: '',
            id: key,
            createdBy: createdBy,
            exercises: exerciseList,
            exerciseSeries: seriesList_temp,
            duration: Number(thisWorkout.duration),
            imageName: thisWorkout.image_name,
            targetGender: thisWorkout.target_gender,
            totalSets: Number(thisWorkout.total_sets),
            totalReps: Number(thisWorkout.total_reps),
            difficulty: Number(thisWorkout.difficulty),
            goals: thisWorkout.goals,
            downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
            timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
        };

        setWorkout(newWorkout);
        getDuration(newWorkout)
        getMusclesString(newWorkout);
        // getVolumeString(newWorkout);

        // console.log(`${newDateD}-${newDateM}-${newDateY} C`);

        setTimeout(() => {
            setIsLoadingWorkout(false);
        }, 100);
        
    }

    async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: [thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        } else {
                            // console.log("---> getExercises, ", completedIDs.length, exercises.length)
                        }
                    }
                });
            }
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function updateDraggingState(toState: boolean) {
        setIsDragging(toState);
        if (!toState) {
            props.cellDropped();
        } else {
            props.cellPicked();
        }
    }

    function updateDraggingPosition(x: number, y: number) {
        props.dragPosUpdated(x, y);
    }

    function isDraggedOver() {
        if (draggingDay === undefined) { return false; }

        let draggingDayDay = new Date(draggingDay.timestamp);

        if (draggingDayDay.getDate() === thisDay && draggingDayDay.getMonth() === thisMonth && draggingDayDay.getFullYear() === thisYear) {
            return true;
        }
         return false;
    }

    function getScheduledTimeString() {

        if (props.calendarDay.scheduleDay !== undefined && props.calendarDay.scheduleDay.scheduled !== undefined && props.calendarDay.scheduleDay.scheduled.starting_timestamp !== undefined) {
            let bookingTimestamp = props.calendarDay.scheduleDay.scheduled.starting_timestamp;
            let bookingDate = new Date(bookingTimestamp);

            let hr = bookingDate.getHours();
            var hr_adj = hr % 12;
            hr_adj = hr_adj === 0 ? 12 : hr_adj;
            let min = bookingDate.getMinutes();

            return `${hr_adj}:${min < 10 ? '0' : ''}${min} ${hr < 12 ? 'AM' : 'PM'}`
        } else {
            return `Booking Error`
        }

        
    }

    function getPhaseName() {
        if (props.calendarDay === undefined || props.calendarDay.scheduleDay === undefined) { return ''; }

        return props.calendarDay.scheduleDay.phase.name;
    }

    function getCycleName() {
        if (props.calendarDay === undefined || props.calendarDay.scheduleDay === undefined) { return ''; }

        return `Cycle ${props.calendarDay.scheduleDay.cycle.index + 1}`;
    }

    function getShowCyclePhases() {
        if (props.calendarDay === undefined || props.calendarDay.scheduleDay === undefined) { return false; }
        return props.calendarDay.scheduleDay.cycle.index >= 0;
    }

    function getMusclesString(forWorkout: Workout_t) {
        var muscleGroupTags_temp: string[] = [];
        let exercises = forWorkout.exercises;
        if (exercises === undefined) { 
            setMusclesString('Unspecified');
            return; 
        }
        for (var i = 0; i < exercises.length; i++) {
            let thisExercise: Exercise_t = exercises[i];

            let thisExerciseMuscles = thisExercise.muscles ?? [];
            //let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

            for (var j = 0; j < thisExerciseMuscles.length; j++) {
                let thisMuscle: Muscles_t = thisExerciseMuscles[j];
                let thisMuscleGroup = Number(thisMuscle.group);

                let thisMuscleString = muscle_groups[thisMuscleGroup];
                if (!muscleGroupTags_temp.includes(thisMuscleString)) {
                    muscleGroupTags_temp.push(thisMuscleString);
                }
            }
        }
        var string_temp = "";
        for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
            string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
        }

        setMusclesString(string_temp);
    }

    function getDuration(forWorkout: Workout_t) {

        let defaultTempo: Tempo_t = {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0};
        let defaultRest = 45;

        var totalTime = 0;

        if (forWorkout.exerciseSeries !== undefined) {
            for (var i = 0; i < forWorkout.exerciseSeries.length; i++) {
                let thisExerciseSeries: ExerciseSeries_t = forWorkout.exerciseSeries[i];
                if (thisExerciseSeries.exercises !== undefined) {
                    for (var j = 0; j < thisExerciseSeries.exercises.length; j++) {
                        let thisExercise: Exercise_t = thisExerciseSeries.exercises[j];

                        if (thisExercise.reps !== undefined) {
                            for (var k = 0; k < thisExercise.reps.length; k++) {
                                let thisRep = thisExercise.reps[k];
                                let thisTempo: Tempo_t = thisExercise.tempos !== undefined && thisExercise.tempos[k] !== undefined ? thisExercise.tempos[k] : defaultTempo;
                                let thisRest = thisExercise.restTime !== undefined && thisExercise.restTime[k] !== undefined ? thisExercise.restTime[k] : defaultRest;
                                thisRest = thisRest <= 0 ? defaultRest : thisRest;

                                let thisTimePerRep = thisTempo.concentric + thisTempo.eccentric + thisTempo.first_pause + thisTempo.second_pause;
                                thisTimePerRep = thisTimePerRep <= 0 ? 3 : thisTimePerRep;

                                let thisSetTime = (thisTimePerRep * thisRep) + thisRest;
                                totalTime += thisSetTime;
                            }
                        }
                    }
                }
            }
        }

        let totalTime_m = Math.floor(totalTime / 60);

        setDurationString(`${totalTime_m} minutes`)
    }

    function getClientName() {
        if (props.calendarDay !== undefined && props.calendarDay.scheduleDay !== undefined && props.calendarDay.scheduleDay.client_id !== undefined) {

            if (clientName === "") {
                let database = firebase.database();
                let ref = database.ref(`user_profile/${props.calendarDay.scheduleDay.client_id}/full_name`);
                ref.once('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let data = snapshot.val();
                        setClientName(data);
                    } else {
                        setClientName("");
                    }
                });
            } else {
                return clientName;
            }

            return props.calendarDay.scheduleDay.client_id;


        }
        return "Unknown Client"
    }

    function editPressed() {
        if (calendarDay.scheduleDay === undefined) { return; }
        let clientObj: Client_t = {
            id: calendarDay.scheduleDay.client_id,
            first_name: clientName === "" ? "Your Client" : clientName,
            last_name: "",
            full_name: clientName === "" ? "Your Client" : clientName
        }
        props.editWorkoutForDay(workout, calendarDay, clientObj);
    }

    function getHasBeenAddedToPhase() {
        let phaseWorkoutList: CalendarDateCell_t[] = props.workoutsInPhase;
        var foundInPhase = false;

        for (var i = 0; i < phaseWorkoutList.length; i++) {
            let thisEventObj: CalendarDateCell_t = phaseWorkoutList[i];

            if (thisEventObj.timestamp === calendarDay.timestamp) {
                foundInPhase = true;
            }
        }

        return foundInPhase;
    }

	return (
		<div className={`${calendarDay === undefined || calendarDay.scheduleDay === undefined ? (isDraggedOver() ? 'calendar-month-day-cell-proposed' : '' ) : 'calendar-month-day-cell'}  
                         ${workout.id === "" ? 'calendar-month-day-cell-resting' : ''}
                         ${isDragging ? 'calendar-month-day-cell-dragging' : ''} ${props.smallCard ? 'calendar-month-day-cell-small' : '' }
                         ${props.isCreatingProgram !== undefined && props.isCreatingProgram === true && getHasBeenAddedToPhase() ? 'calendar-month-day-cell-in-phase' : ''}`}
            draggable={!props.smallCard} 
            onDrag={(e: any) => updateDraggingPosition(e.clientX, e.clientY)}
            onDrop={(e: any) => console.log("onDrop", e)}
            onDragStart={(e: any) => updateDraggingState(true)}
            onDragStartCapture={(e: any) => console.log("onDragStartCapture", e)}
            onDragEnd={(e: any) => updateDraggingState(false)}
            onDragEndCapture={(e: any) => console.log(`onDragEndCapture: (x:${e.clientX}, y: ${e.clientY})`)}>
			<div hidden={isLoadingWorkout || workout.id === ""} className="calendar-month-day-cell-inner">
                <div hidden={props.isCreatingProgram !== undefined && props.isCreatingProgram === true}>
                    <div hidden={isScheduledWithTrainer} className="calendar-month-day-cell-scheduled-container">
                        <div className="calendar-month-day-cell-scheduled-nosched-circle"/>
                        <p>Not scheduled</p>
                    </div>
                    <div hidden={!isScheduledWithTrainer} className="calendar-month-day-cell-scheduled-container">
                        <CalendarToday className="calendar-month-day-cell-scheduled-sched-icon"/>
                        <p>{getScheduledTimeString()}</p>
                    </div>
                </div>
                <div className="calendar-month-day-cell-content-container">
                    <h4>{props.smallCard ? getClientName() : workout.title}</h4>
                    <div hidden={props.smallCard} className="calendar-month-day-cell-content-row-container">
                        <p>{musclesString}</p>
                    </div>
                    <div className="calendar-month-day-cell-content-row-container">
                        <p>{durationString}</p>
                    </div>
                    <div hidden={props.smallCard || !getShowCyclePhases()} className="calendar-month-day-cell-content-cyclephase-container">
                        <div className="calendar-month-day-cell-content-phase-container">
                            <div className="calendar-month-day-cell-scheduled-nosched-circle calendar-month-day-cell-content-phase-circle calendar-month-day-cell-content-cycle-circle"/>
                            <p>{getCycleName()}</p>
                        </div>
                        <div className="calendar-month-day-cell-content-phase-container">
                            <div className="calendar-month-day-cell-scheduled-nosched-circle calendar-month-day-cell-content-phase-circle"/>
                            <p>{getPhaseName()}</p>
                        </div>
                    </div>
                    <div hidden={props.isCreatingProgram !== undefined && props.isCreatingProgram === true}>
                        <div onClick={() => props.addToProgramPressed()} hidden={props.smallCard || getShowCyclePhases()} className="calendar-month-day-cell-content-book-button-container">
                            <p>New Phase</p>
                            <ArrowForward className="calendar-month-day-cell-content-book-button-icon"/>
                        </div>
                        <div onClick={() => editPressed()} hidden={props.smallCard === true || getShowCyclePhases() || props.editWorkoutForDay === undefined} className="calendar-month-day-cell-content-book-button-container">
                            <p>Edit Workout</p>
                            <ArrowForward className="calendar-month-day-cell-content-book-button-icon"/>
                        </div>
                        <div onClick={() => isScheduledWithTrainer ? props.editAppointmentPressedForClient() : props.addNewAppointmentPressed()} hidden={calendarDay === undefined || calendarDay.scheduleDay === undefined || calendarDay.scheduleDay.scheduleDay !== undefined} className="calendar-month-day-cell-content-book-button-container">
                            <p>{isScheduledWithTrainer ? "Change booking" : "Book a time"}</p>
                            <ArrowForward className="calendar-month-day-cell-content-book-button-icon"/>
                        </div>
                    </div>
                    <div hidden={props.isCreatingProgram === undefined || props.isCreatingProgram === false}>
                        <div onClick={() => props.addToProgramPressed()} hidden={getHasBeenAddedToPhase()} className="calendar-month-day-cell-content-book-button-container">
                            <Add className="calendar-month-day-cell-content-book-button-icon calendar-month-day-cell-content-book-button-icon-left"/>
                            <p>Add to Phase</p>
                        </div>
                        <div onClick={() => props.removeFromProgramPressed()} hidden={!getHasBeenAddedToPhase()} className="calendar-month-day-cell-content-book-button-container calendar-month-day-cell-content-book-button-container-remove">
                            <Close className="calendar-month-day-cell-content-book-button-icon calendar-month-day-cell-content-book-button-icon-left calendar-month-day-cell-content-book-button-icon-remove"/>
                            <p>Remove</p>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={isLoadingWorkout || workout.id !== ""} className="calendar-month-day-cell-inner calendar-month-day-cell-inner-rest">
                <p>Rest Day</p>
            </div>
            <div hidden={!isLoadingWorkout} className="calendar-month-day-cell-inner calendar-month-day-cell-inner-loading">
                <div className="create-workout-exercise-configure-loading-spinner calendar-month-day-cell-inner-loading-spinner"/>
                <p>Loading...</p>
            </div>
		</div>
	)
}

export default CalendarMonthCell;