import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import './FormBodyMeasurementInput.css';
import FormTextInput from './FormTextInput';



function FormBodyMeasurementInput(props: any) {

    const [showLabel, setShowLabel] = useState(false);
    const [message, setMessage] = useState(-1);
    const [initValueSet, setInitValueSet] = useState(false);

    function changeValue(to: string) {
        setMessage(Number(to));
        let hide = to === undefined || to === null || message < 0;
        setShowLabel(!hide);
        props.inputChanged(Number(message));
    }

    return (
        <div className="form-body-input-container">
            <div className="form-body-header-container">
                <div hidden={props.desc === undefined || props.desc === ""} className="form-body-header-container-label-container form-body-header-container-label-container-with-desc">
                    <h4 className="form-body-input-label">{props.placeholder}</h4>
                    <p>{props.desc}</p>
                </div>
                <div hidden={props.desc !== undefined && props.desc !== ""} className="form-body-header-container-label-container">
                    <h4 className="form-body-input-label">{props.placeholder}</h4>
                </div>
                <div hidden={props.alert === true || props.optional === undefined || props.optional === false} className="form-body-optional-container">
                    <p>Optional</p>
                </div>
                <p hidden={!props.alert} className="form-body-input-alert-label">{props.alertText}</p>
            </div>
            <div className="form-body-container">
                <div className={props.alert? "form-body-alert-full" : "form-body-full"}>
                    <IonInput value={message < 0 ? null : message} 
                       placeholder={props.placeholder}
                       inputmode="numeric"
                       type="number"
                       onIonChange={e => changeValue(e.detail.value!)}/>
                </div>
                <div className="form-body-container-unit-container">
                    <p>{props.units}</p>
                </div>
            </div>
        </div>
    )
}

//hidden={showLabel === false}

export default FormBodyMeasurementInput;
