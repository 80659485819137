import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';
import './ClientGoalProgressCard.css';

import ClientGoalProgress from './ClientGoalProgress';
import GoalSkillColumn from '../Goals/GoalSkillColumn';
import ClientGoalProgressCardLogCell from './ClientGoalProgressCardLogCell';

import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';
import BodyMeasurement_t from '../../Interfaces/BodyMeasurement_t';

import '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';



function ClientGoalProgressCard(props: any) {

    const [myLogs, setMyLogs] = useState<any[]>([
            {
                metric: 20,
                timestamp: 1641084238000,
            },
            {
                metric: 60,
                timestamp: 1642639438000,
            },
            // {
            //     metric: 95,
            //     timestamp: 1642725838000,
            // },
            // {
            //     metric: 105,
            //     timestamp: 1643762638000,
            // },
            // {
            //     metric: 200,
            //     timestamp: 1644799438000,
            // }
        ]
    );

    const [myLogs_reversed, setMyLogs_reversed] = useState<any[]>([]);

    const [updatedGoalCount, setUpdatedGoalCount] = useState(0);
    const [showActions, setShowActions] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [oldGoalRef, setOldGoalRef] = useState<Goal_t>({type: "custom",targetMetric:0,targetUnitsKG:false,targetTimestamp:0,startingTimestamp:0,isEditing:false})
    const [updatedGoal, setUpdatedGoal] = useState<Goal_t>({type: "custom",targetMetric:0,targetUnitsKG:false,targetTimestamp:0,startingTimestamp:0,isEditing:false})
    const [showMenu, setShowMenu] = useState(false);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.goal !== undefined) {
            setUpdatedGoal(props.goal);
            setOldGoalRef(props.goal);
            pullLogs();
        }
    }

    useEffect(() => {
        //// console.log("CLIENT GOAL PROGRESS CARD | props.goal Updated: ", props.goal, props.goal.actions);
        if (props.goal !== undefined) {
            setUpdatedGoal(props.goal);
            setOldGoalRef(props.goal);
        }
    }, [props.goal]);

    useEffect(() => {
        setUpdatedGoalCount(updatedGoalCount + 1);
    }, [updatedGoal]);


    async function pullLogs() {
        if (props.goal === undefined || props.goal.type === undefined) { return; }

        var newLogList: any[] = [];

        switch (props.goal.type) {
            case "custom": 
                
                break;
            case "get-fit": 
                
                break;
            case "loose-weight": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "gain-weight": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "muscle-toning": 
                newLogList = await getBodyMeasurementLogs('body_fat_percentage');
                break;
            case "body-building": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "power-lifting": 
                newLogList = await getBodyMeasurementLogs('body_weight');
                break;
            case "cardio-endurance":
                
                break;
            default: 
                break;
        }

        //// console.log("ClientGoalProgressCard | Setting my logs to:", newLogList);

        setMyLogs(newLogList);

        var measureReverse = [];
        for (var i = newLogList.length - 1; i >= 0; i--) {
            measureReverse.push(newLogList[i]);
        }

        setMyLogs_reversed(measureReverse);

    }

    async function getBodyMeasurementLogs(metric_id: string) {
        if (props.client === undefined || props.goal.id === undefined) { return []; }

        return new Promise<any>(resolve => {
            let clientID = props.client.id;

            let database = firebase.database();
            let ref = database.ref(`body_measurements/${clientID}/logs`); // Change to database path
            ref.once('value', function(snapshot) {

                var logComp: any[] = [];

                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    let dataKeys = Object.keys(data);

                    for (var i = 0 ; i < dataKeys.length; i++) {
                        let thisLog = data[dataKeys[i]];

                        //// console.log("ClientGoalProgressCard | body measure log: ", thisLog, metric_id, thisLog[metric_id])
                        let isKG = thisLog.isKG !== undefined ? thisLog.isKG : false;
                        var unit = "";
                        if (metric_id === 'body_fat_percentage') {
                            unit = '%';
                        } else if (metric_id === 'body_weight' || metric_id === 'lean_mass') {
                            unit = isKG ? "KGs" : "LBS";
                        } else {
                            unit = isKG ? "cm" : "in";
                        }

                        let thisLogComp: any = {
                            metric: thisLog[metric_id] !== undefined ? thisLog[metric_id] : NaN,
                            logged_by: thisLog.logged_by !== undefined ? thisLog.logged_by : clientID,
                            timestamp: thisLog.timestamp !== undefined ? thisLog.timestamp : -1,
                            units: unit
                        }
                        logComp.push(thisLogComp);
                    }
                }

                resolve(logComp);
            })
        });
    }

    function getGoalName() {
        if (props.goal === undefined || props.goal.type === undefined) { return 'Goal'; }

        var resultString = "Goal";
        switch (props.goal.type) {
            case "custom": 
                resultString = "Custom Goal";
                break;
            case "get-fit": 
                resultString = "Custom Goal";
                break;
            case "loose-weight": 
                resultString = "Loose Weight";
                break;
            case "gain-weight": 
                resultString = "Gain Mass";
                break;
            case "muscle-toning": 
                resultString = "Lowering Body Fat";
                break;
            case "body-building": 
                resultString = "Bodybuilding";
                break;
            case "power-lifting": 
                resultString = "Power Lifting";
                break;
            case "cardio-endurance":
                resultString = "Cardio Endurance";
                break;
            default: 
                break;
        }

        return resultString;
    }

    function getGoalDesc() {
        if (props.goal === undefined || props.goal.type === undefined) { return `Your Client's goal has not been defined`; }

        var resultString = "Goal";
        var units = "";

        switch (props.goal.type) {
            case "custom": 
                resultString = "a custom goal";
                break;
            case "get-fit": 
                units = "days/wk"
                resultString = "a custom goal";
                break;
            case "loose-weight": 
                resultString = "to loose weight";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "gain-weight": 
                resultString = "to gain muscle mass";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "muscle-toning": 
                resultString = "to lower body fat";
                units = "%";
                break;
            case "body-building": 
                resultString = "to body build";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "power-lifting": 
                resultString = "to power lift";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "cardio-endurance":
                resultString = "improve cardio endurance";
                units = "mins";
                break;
            default: 
                break;
        }

        let startingTimestamp = props.goal.startingTimestamp;
        let targetTimestamp = props.goal.targetTimestamp;

        let dt_total = targetTimestamp - startingTimestamp;
        let dt_total_days = Math.round(dt_total / (24 * 60 * 60 * 1000));

        let mostRecentMetric = myLogs.length > 0 ? myLogs[myLogs.length - 1].metric : -1;

        let returnString = `${props.client_name}'s goal is ${resultString}${mostRecentMetric === -1 ? '' : ` from ${mostRecentMetric}${units}`} to ${ props.goal.targetMetric}${units} in ${dt_total_days} days`

        return returnString;
    }

    function getMetricHeaderString() {
        if (props.goal === undefined || props.goal.type === undefined) { return 'METRIC'; }

        var resultString = "METRIC";
        switch (props.goal.type) {
            case "custom": 
                resultString = "CUSTOM METRIC";
                break;
            case "get-fit": 
                resultString = "WORKOUTS";
                break;
            case "loose-weight": 
                resultString = "BODY WEIGHT";
                break;
            case "gain-weight": 
                resultString = "BODY WEIGHT";
                break;
            case "muscle-toning": 
                resultString = "BODY FAT %";
                break;
            case "body-building": 
                resultString = "BODY WEIGHT";
                break;
            case "power-lifting": 
                resultString = "MAX LIFT";
                break;
            case "cardio-endurance":
                resultString = "CARDIO MINS";
                break;
            default: 
                break;
        }

        return resultString;
    }

    function toggleShowActions() {
        setShowActions(!showActions);
    }

    function toggleShowLogs() {
        setShowLogs(!showLogs);
    }

    function toggleShowSettings() {
        setShowSettings(!showSettings);
    }

    function toggleMenuShow() {
        setShowMenu(!showMenu);
    }

    function goalActionUpdated(ga: GoalActions_t, atIndex: number) {
        if (updatedGoal.actions !== undefined) {
            setUpdatedGoal({...updatedGoal, actions: updatedGoal.actions.map((item: GoalActions_t, index: number) => {
                if (atIndex === index) {
                    return ga;
                } else {
                    return item;
                }
            })})
        };
    }

    function saveChanges() {

        if (props.client === undefined || props.goal.id === undefined) { return; }

        setUpdatedGoalCount(0);
        let thisGoalObj: any = {
            type: updatedGoal.type,
            target_metric: updatedGoal.targetMetric,
            target_units_kg: updatedGoal.targetUnitsKG,
            target_timestamp: updatedGoal.targetTimestamp,
            starting_timestamp: updatedGoal.startingTimestamp
        };

        if (updatedGoal.customType !== undefined) {
            thisGoalObj['custom_type'] = updatedGoal.customType;
        }

        if (updatedGoal.actions !== undefined) {
            var actionsList: any[] = [];
            for (var j = 0; j < updatedGoal.actions.length; j++) {
                let thisAction = updatedGoal.actions[j];
                let thisActionObj: any = {
                    index: thisAction.index,
                    goal_desc: thisAction.goalDesc,
                    duration_days: thisAction.durationDays
                };
                if (thisAction.subActions !== undefined) {
                    var subActionsList: any[] = [];
                    for (var k = 0; k < thisAction.subActions.length; k++) {
                        let thisSubAction = thisAction.subActions[k];
                        let thisSubActionObj: any = {
                            index: thisSubAction.index,
                            goal_desc: thisSubAction.goalDesc,
                            duration_days: thisSubAction.durationDays,
                            action: thisSubAction.action === undefined ? "" : thisSubAction.action,
                            metric: thisSubAction.metric === undefined ? 1 : thisSubAction.metric,
                            metric_unit: thisSubAction.metricUnit === undefined ? "" : thisSubAction.metricUnit,
                            timeframe: thisSubAction.timeframe === undefined ? "" : thisSubAction.timeframe,
                        };
                        subActionsList.push(thisSubActionObj);
                    }
                    thisActionObj['sub_actions'] = subActionsList;
                }
                actionsList.push(thisAction);
            }

            thisGoalObj['actions'] = actionsList;
        }
        

        let clientID = props.client.id;
        let goalID = props.goal.id;

        let database = firebase.database();
        let ref = database.ref(`goals/${clientID}/goals/${goalID}`); // Change to database path
        ref.set(thisGoalObj);
    }

    function editPressed() {
        setShowMenu(false);
        props.editGoal();
    }

    function deletePressed() {
        setShowMenu(false);
        props.getUserConfirmation({
            title: `Are you sure you want to remove ${props.client.first_name}'s Goal?`,
            text: `Removing this Goal will permanently erase this Goal from their account. This action cannot be undone.`,
            isRed: true,
            callback: deleteConfrimed,
            ctaText: "Remove"
        });
    }

    function deleteConfrimed() {
        props.deleteGoal();
    }

    function getActionsHaveUpdated() {
        var hasUpdated = false;

        if (updatedGoal.actions !== undefined && oldGoalRef.actions !== undefined) {
            for (var i = 0; i < updatedGoal.actions.length; i++) {
                if (hasUpdated === false) {
                    let thisUpdatedAction = updatedGoal.actions[i];
                    let oldRefAction = oldGoalRef.actions[i];
                    if (oldRefAction === undefined) {
                        hasUpdated = true;
                    } else {
                        if (oldRefAction.goalDesc !== thisUpdatedAction.goalDesc) {
                            hasUpdated = true;
                        } else {
                            if (thisUpdatedAction.subActions !== undefined && oldRefAction.subActions !== undefined) {
                                for (var j = 0; j < thisUpdatedAction.subActions.length; j++) {
                                    let thisUpdatedSubAction = thisUpdatedAction.subActions[j];
                                    let oldRefSubAction = oldRefAction.subActions[j];
                                    if (oldRefSubAction === undefined) {
                                        hasUpdated = true;
                                    } else {
                                        if (oldRefSubAction.goalDesc !== thisUpdatedSubAction.goalDesc) {
                                            hasUpdated = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return hasUpdated;
    }

    window.addEventListener('click', function(e){   

        let buttonID = `goal-${props.index}-${props.goal.id === undefined ? '' : props.goal.id}-button`;
        let menuID = `goal-${props.index}-${props.goal.id === undefined ? '' : props.goal.id}-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    });


	return (
		<div className="client-goal-progress-card">
            <div className="client-goal-progress-card-header">
                <div className="client-goal-progress-card-header-title">
                    <h4>{getGoalName()} Progress</h4>
                    <div className="client-goal-progress-card-header-active">
                        <p>Active</p>
                    </div>
                </div>
                <p className="client-goal-progress-card-header-desc">{getGoalDesc()}</p>
                <div id={`goal-${props.index}-${props.goal.id === undefined ? '' : props.goal.id}-button`} onClick={() => toggleMenuShow()} hidden={props.goal.isComplete !== undefined && props.goal.isComplete === true} className="client-goal-progress-card-header-menu-button">
                    <MoreHoriz className="client-goal-progress-card-header-menu-button-icon"/>
                    <p>Options</p>
                </div>
                <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                    <div id={`goal-${props.index}-${props.goal.id === undefined ? '' : props.goal.id}-menu`} className="workout-plan-list-cycle-overview-header-card-menu-container client-goal-progress-card-header-menu-container">
                        <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                            <p>Goal Options</p>
                        </div>
                        <div onClick={() => editPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <Edit className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Edit Information</p>
                        </div>
                        <div onClick={() => deletePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <Close className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Remove Goal</p>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <div className="client-goal-progress-card-chart-container">
                <ClientGoalProgress
                    goal={props.goal}
                    myLogs={myLogs}
                    client={props.client}/>
            </div>
            <div className="client-goal-progress-card-info-container">
                <div className="client-goal-progress-card-info-header-container">
                    <div onClick={() => toggleShowActions()} className="client-goal-progress-card-info-header-text-container">
                        <h4>Actions for this Goal</h4>
                        <div hidden={showActions} className="client-goal-progress-card-info-expand-icon-container">
                            <ExpandMore className="client-goal-progress-card-info-expand-icon"/>
                        </div>
                        <div hidden={!showActions} className="client-goal-progress-card-info-expand-icon-container">
                            <ExpandLess className="client-goal-progress-card-info-expand-icon"/>
                        </div>
                    </div>

                    <div hidden={!showActions || props.goal.actions === undefined ||  props.goal.actions.length === 0 || !getActionsHaveUpdated()} onClick={() => saveChanges()} className="client-goal-progress-card-info-save-button">
                        <p>Save Changes</p>
                    </div>
                </div>
                <div hidden={!showActions } className="client-goal-progress-card-info-content">
                    <div hidden={props.goal.actions !== undefined && props.goal.actions.length > 0} className="client-goal-progress-card-info-content-inner client-goal-progress-card-info-content-inner-goals-empty">
                        <h4>No Actions for this Goal</h4>
                        <p>No actions were set any actions for this goal when it was created. Click the button below to get started adding Actions to help keep your client on track.</p>
                        <div onClick={() => editPressed()} className="client-goal-progress-card-info-content-button">
                            <p>Add Goal Actions</p>
                        </div>
                    </div>
                    <div hidden={props.goal === undefined || props.goal.actions === undefined || props.goal.actions.length === 0} className="client-goal-progress-card-info-content-inner client-goal-progress-card-info-content-inner-actions-container">
                        {
                            props.goal === undefined || props.goal.actions === undefined ? null : props.goal.actions.map((item: GoalActions_t, index: number) => (
                                <GoalSkillColumn
                                    goalAction={item} 
                                    index={index}
                                    client={props.client}
                                    forceEdit={true}
                                    goalActionUpdated={(e: GoalActions_t) => goalActionUpdated(e, index)}/>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="client-goal-progress-card-info-container">
                <div  className="client-goal-progress-card-info-header-container">
                    <div onClick={() => toggleShowLogs()} className="client-goal-progress-card-info-header-text-container">
                        <h4>Log History</h4>
                        <div hidden={showLogs} className="client-goal-progress-card-info-expand-icon-container">
                            <ExpandMore className="client-goal-progress-card-info-expand-icon"/>
                        </div>
                        <div hidden={!showLogs} className="client-goal-progress-card-info-expand-icon-container">
                            <ExpandLess className="client-goal-progress-card-info-expand-icon"/>
                        </div>
                    </div>
                </div>
                <div hidden={!showLogs || myLogs_reversed.length === 0} className="client-goal-progress-card-info-content">
                    <div className="client-goal-progress-card-info-content-log-list-header">
                        <div className="client-goal-progress-card-info-content-log-list-header-col client-goal-progress-card-info-content-log-list-header-col-0">
                            <p>LOG DATE</p>
                        </div>
                        <div className="client-goal-progress-card-info-content-log-list-header-col client-goal-progress-card-info-content-log-list-header-col-1">
                            <p>{getMetricHeaderString()}</p>
                        </div>
                        <div className="client-goal-progress-card-info-content-log-list-header-col client-goal-progress-card-info-content-log-list-header-col-2">
                            <p>LOGGED BY</p>
                        </div>
                    </div>
                    <div className="client-goal-progress-card-info-content-log-list-content">
                        {
                            myLogs_reversed.map((item: any, index: number) => (
                                <ClientGoalProgressCardLogCell logData={item} index={index}/>
                            ))
                        }
                    </div>
                </div>
                <div hidden={!showLogs || myLogs_reversed.length > 0} className="client-goal-progress-card-info-content client-goal-progress-card-info-content-inner-goals-empty">
                    <h4>No Logs for this Goal</h4>
                    <p>There haven't been any logs for this goal yet. Goal logs typically come from body measurement updates or workouts, so go ahead and log some to get started.</p>
                    <div onClick={() => props.logMeasurementPressed()} className="client-goal-progress-card-info-content-button">
                        <p>Add a Measurement Log</p>
                    </div>
                </div>
            </div>
            <div hidden={true} className="client-goal-progress-card-info-container">
                <div className="client-goal-progress-card-info-header-container">
                    <div onClick={() => toggleShowSettings()} className="client-goal-progress-card-info-header-text-container">
                        <h4>Goal Settings</h4>
                        <div hidden={showSettings} className="client-goal-progress-card-info-expand-icon-container">
                            <ExpandMore className="client-goal-progress-card-info-expand-icon"/>
                        </div>
                        <div hidden={!showSettings} className="client-goal-progress-card-info-expand-icon-container">
                            <ExpandLess className="client-goal-progress-card-info-expand-icon"/>
                        </div>
                    </div>
                </div>
                <div hidden={!showSettings} className="client-goal-progress-card-info-content">
                    
                </div>
            </div>
		</div>
	)
}

export default ClientGoalProgressCard;