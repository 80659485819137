import React, { useState } from 'react';
import firebase from '../../Firebase';

import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import './WorkoutHistoryCard.css';

import '@material-ui/core';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Timer from '@material-ui/icons/Timer';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import DataUsage from '@material-ui/icons/DataUsage';

function WorkoutHistoryCard(props: any) {

	let initWorkout: Workout_t = {
					title: '',
					id: '',
					createdBy: {name: '', id: ''},
					duration: 0,
					imageName: '',
					targetGender: '',
					totalSets: 0,
					totalReps: 0,
					difficulty: 3,
					startDate: undefined,
					endDate: undefined
				}

	const [imgUrl, setImgUrl] = useState('');
	const [initSet, setInitSet] = useState(false);
	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	const [muscleGoups, setMuscleGroups] = useState<number[]>([]);

	if (initSet === false) {
		setInitSet(true);
		setInit();
		//getImage();
	}

	function setInit() {
		let database = firebase.database();
		let ref = database.ref(`workouts/${props.logData.workout_id}`);
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let data = snapshot.val();
				//// console.log(data);
				let createdByData = data.created_by;
				let createdBy:CreatedBy_t = {
					name: createdByData.name,
					id: createdByData.id
				};

				var muscleList: number[] = [];
				let exerciseKeys = Object.keys(data.exercises);
				for (var i = 0; i < exerciseKeys.length; i++) {
					let thisExercise = data.exercises[exerciseKeys[i]];
					//// console.log(thisExercise);
					let muscles: any[] = thisExercise.muscles;
					for (var j = 0; j < muscles.length; j++) {
						let thisMuscleGroup: number = muscles[j].group;
						if (!muscleList.includes(thisMuscleGroup)) {
							muscleList.push(thisMuscleGroup);
						}
					}
				}
				//// console.log(muscleList);
				setMuscleGroups(muscleGoups.concat(muscleList));

				let newWorkout: Workout_t = {
					title: data.title,
					id: props.workout_id,
					createdBy: createdBy,
					duration: data.duration,
					imageName: data.image_name,
					targetGender: data.target_gender,
					totalSets: Number(data.total_sets),
					totalReps: Number(data.total_reps),
					difficulty: Number(data.difficulty),
					startDate: props.logData.start_date,
					endDate: props.logData.completed_date
				}
				getImage(data.image_name);
				setWorkout(newWorkout);
				//;
			}
		});
	}

	

	function getImage(forName: string) {
		const storage = firebase.storage();
		storage.ref('images/workouts/').child(forName).getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       })
	}

	function getDateString() {
		if (!workout.endDate) {
			return 'Date unknown'
		}

		let monthStrings = ['January','February','March','April','May','June','July','August','September','October','November','December'];

		let date_time = workout.endDate.split(' ');
		let date = date_time[0];
		let time = date_time[1];

		let dates = date.split('-');
		return ` Completed on ${monthStrings[Number(dates[1]) - 1]} ${dates[2]}, ${dates[0]}`
	}

	function getTime() {
		if (!workout.endDate || !workout.startDate) {
			return 'Duration unknown'
		}

		let end_date_time = workout.endDate.split(' ');
		let end_time = end_date_time[1];
		let end_times = end_time.split(':');
		var end_h = Number(end_times[0]);
		let end_m = Number(end_times[1]);

		let start_date_time = workout.startDate.split(' ');
		let start_time = start_date_time[1];
		let start_times = start_time.split(':');
		let start_h = Number(start_times[0]);
		let start_m = Number(start_times[1]);

		if (end_h < start_h) { end_h += 12;}
		let start_mins = start_m + (60 * start_h);
		let end_mins = end_m + (60 * end_h);
		let mins_diff = end_mins - start_mins;

		return mins_diff;
	}

	function getMuscleNames() {
		let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
		var muscleString = " ";
		if (muscleGoups.length > 0) {
			for (var i = 0; i < muscleGoups.length; i++) {
				let thisMuscleGroup: number = muscleGoups[i];
				let thisMuscleGroupString = muscle_groups[thisMuscleGroup];
				muscleString += `${i === 0 ? '' : ','} ${thisMuscleGroupString}`;
			}
		} else {
			muscleString = " No muscles specified"
		}
		return muscleString;
	}

	function getTonnage() {
		var units = "LBS";
		var totalTonnage = 0;
		let exerciseStats = props.logData.exercises;
		if (exerciseStats === undefined) { return `0 KGs`};
		let keys = Object.keys(exerciseStats);
		for (var i = 0; i < keys.length; i++) {
			let thisExercise = exerciseStats[keys[i]];
			if (thisExercise.set_stats) {
				let thisSetStats: any[] = thisExercise.set_stats;
				let SSKeys: any[] = Object.keys(thisSetStats);
				for (var j = 0; j < SSKeys.length; j++) {
					let thisSS: any = thisSetStats[SSKeys[j]];
					units = thisSS.weight_unit !== undefined ? thisSS.weight_unit : "LBS";
					let thisTonnage = Number(thisSS.reps_completed) * Number(thisSS.weight);
					totalTonnage += thisTonnage;
				}
			}
		}

		return `${totalTonnage} ${units}`
	}

	function getActivePercentage() {
		let totalTimeMins: any = getTime() ;
		let totalTimeSecs = Number(totalTimeMins) * 60;
		var timeResting = 0;

		let exerciseStats = props.logData.exercises;
		if (exerciseStats === undefined) { return `0.0%`};
		let keys = Object.keys(exerciseStats);
		for (var i = 0; i < keys.length; i++) {
			let thisExercise = exerciseStats[keys[i]];
			if (thisExercise.set_stats) {
				let thisSetStats: any[] = thisExercise.set_stats;
				let SSKeys: any[] = Object.keys(thisSetStats);
				for (var j = 0; j < SSKeys.length; j++) {
					let thisSS: any = thisSetStats[SSKeys[j]];
					let thisRestTime = Number(thisSS.rest_time !== undefined ? thisSS.rest_time : thisSS.restTime);
					timeResting += thisRestTime;
				}
			}
		}

		let percentage = totalTimeSecs > 0 ? timeResting / totalTimeSecs : 0;
		let scaledPercentage = 100 - Math.floor(percentage * 100);
		return `${scaledPercentage < 0 ? 0 : scaledPercentage}%`;

	}


	return (
		<div className="workout-history-card">
			<div className="wworkout-history-card-image-container">
				<div className="workout-history-card-image-circle ">
					<img className="workout-card-image" src={imgUrl} alt={`Image for ${props.logData.workout_id}`} />
				</div>
			</div>	
			<div className="workout-history-card-info-container ">
				<div className="workout-history-card-info-header-container">
					<h2>{workout.title}</h2>
					<p><CalendarToday style={{ fontSize: 14 }} className="workout-history-card-inline-icon"/> {getDateString()}</p>
					<p><AccessibilityIcon style={{ fontSize: 14 }} className="workout-history-card-inline-icon"/> {getMuscleNames()}</p>
					<div className= "workout-history-card-pill-green">Trained with me</div>
				</div>
				<div className="workout-history-card-info-content">
					<div className="workout-history-card-info-content-container ">
						<p className="workout-history-card-info-header-label">Workout Length</p>
						<p className="workout-history-card-info-label"><Timer style={{ fontSize: 14 }}/> {getTime()} minutes</p>
					</div>
					<div className="workout-history-card-info-content-container ">
						<p className="workout-history-card-info-header-label">Tonnage</p>
						<p className="workout-history-card-info-label"><FitnessCenter style={{ fontSize: 14 }}/> {getTonnage()}</p>
					</div>
					<div className="workout-history-card-info-content-container ">
						<p className="workout-history-card-info-header-label">Active time</p>
						<p className="workout-history-card-info-label"><DataUsage style={{ fontSize: 14 }}/> {getActivePercentage()} Active</p>
					</div>
				</div>
			</div>	
		</div>
	)
}

export default WorkoutHistoryCard;

