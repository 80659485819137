import React, { useState, useEffect } from 'react';
import './DashboardMenu.css';
import { IonReactRouter } from '@ionic/react-router';
import firebase from '../Firebase';
import Mixpanel from '../Mixpanel';

import ColorScheme_t from '../Interfaces/CoachingProfile/ColorScheme_t';

import '@material-ui/core';

import DonutLarge from '@material-ui/icons/DonutLarge';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import CalendarToday from '@material-ui/icons/CalendarToday';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import BluetoothConnected from '@material-ui/icons/BluetoothConnected';
import BluetoothSearching from '@material-ui/icons/BluetoothSearching';
import Inbox from '@material-ui/icons/Inbox';
import Settings from '@material-ui/icons/Settings';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import Feedback from '@material-ui/icons/Feedback';
import ArrowForward from '@material-ui/icons/ArrowForward';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Store from '@material-ui/icons/Store';
import People from '@material-ui/icons/People';
import AccountBox from '@material-ui/icons/AccountBox';
import Accessibility from '@material-ui/icons/Accessibility';
import Send from '@material-ui/icons/Send';

// import '@mui/icons-material'
// import PieChartOutline from '@mui/icons-material/PieChartOutline'


function DashboardMenu(props: any) {
	let pageNames = ['Dashboard','Workouts','Clients','Messages','Nodes','Settings','Profile','Unknown','Unknown','Unknown','Unknown','Unknown','Unknown','Unknown','Unknown'];

	//const [selectedIndex, setSelectedIndex] = useState(0);
	const [imgUrl, setImgUrl] = useState('');
	const [studioImageURL, setStudioImageURL] = useState("");
    const [gymName, setGymName] = useState("");

	const [initSet, setInitSet] = useState(false);

	const [colorScheme, setColorScheme] = useState<ColorScheme_t>({
        title: '',
        background: '#1F1F21',
        primary: '#5B62FF',
        heading_text: '#ffffff',
        body_text: '#9DA0AD',
        button_text: '#28292C'
    })

	if (!initSet) {
		initialize();
	}

	function initialize() {
		setInitSet(true);
		getImage();
		setupStudio();
	}

	function setupStudio() {
		let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                if (data.studio_id !== undefined && data.studio_id !== "") {
                    getStudioProfile(data.studio_id);
                }
            }
        });
	}

	function getStudioProfile(forStudioID: string) {



        let database = firebase.database();
        let ref = database.ref(`training_studios/${forStudioID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setGymName(data.name);
                getStudioProfileImage(forStudioID);
                if (data.theme !== undefined) {
                    setColorScheme(data.theme);
                }
            }
        });
    }

    function getStudioProfileImage(forStudioID: string) {

        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref(`images/studio_profile/${forStudioID}/profile_image.png`).getDownloadURL()
       .then(fireBaseUrl => {
         setStudioImageURL(fireBaseUrl);

       })
       .catch(error => {
           setStudioImageURL("");
       })
    }

    function getColorForType(theme: ColorScheme_t, type: 'background' | 'primary' | 'heading_text' | 'body_text' | 'button_text', rgb?: boolean, a?: number) {
        return rgb === undefined || rgb === false ? theme[type] : hexToRgb(theme[type], a === undefined ? 1 : a);
    }

    function hexToRgb(hex: string, a: number) {

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let rgbString = result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : `rgba(0, 0, 0, ${a})`

        // console.log("RESULT:",rgbString, result, hex, a);


        return rgbString;
    }


	function getImage() {
		if (props.profile.id === '' || props.profile.id === undefined || props.profile.id === null) { return; }
		const storage = firebase.storage();

		//gs://movement-tracker-457bc.appspot.com/profileImages/sW16Hi1curbFsybCDooZBoNzfzC3/profileImage/image.jpg
		storage.ref('profileImages').child(props.profile.id).child('profileImage/image.jpg').getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		// console.log('No image found');
       	})

       	storage.ref('profileImages').child(props.profile.id).child('profileImage/image.jpg').getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		// console.log('No image found');
       	})
	}

	function getList() {
		let list = [];
		let pageNames = ['Dashboard','Workouts','Clients','Nodes'];
		for (var i = 0; i < pageNames.length; i++) {
			let a = <div >
						<div className="icon-container">

						</div>
						<p>{pageNames[i]}</p>
						<div className={props.selectedIndex === i ? "selected-dot" : ""}/>
					</div>;

			list.push(a);
		}

		return list;
	}

	function cellSelected(index: number) {
		//setSelectedIndex(index);

		Mixpanel.track("Menu button pressed",{"to_page": pageNames[index], "from_page": pageNames[props.selectedIndex]})
		//Mixpanel.flush();
		props.pagesChanged(index);
	}

	function profilePressed() {
		Mixpanel.track("Menu button pressed",{"to_page": 'Profile', "from_page": pageNames[props.selectedIndex]})
		props.profilePressed();
	}

	function resumeWorkout() {
		Mixpanel.track("Menu button pressed",{"to_page": 'Resume Workout', "from_page": pageNames[props.selectedIndex]})
		props.resumeWorkout();
	}

	function contactCardPressed() {
		props.contactCardPressed();
	}

	function getRemainingDaysInTrial() {
		if (props.profile === undefined || props.profile.joined_timestamp === undefined || props.profile.joined_timestamp <= 0) {
			return 31;
		}

		let dt = Date.now() - props.profile.joined_timestamp;
		let dt_days = dt / (60 * 60 * 24 * 1000);

		return Math.floor(30 - dt_days)
	}

	return (
		<div className="menu">
			<div className="menu-container" >
				<div className="menu-eigen-icon-container">
					<div className="menu-eigen-text-container show-on-desktop">
						<h4>Eigen.</h4>
					</div>
				</div>
				<div className="menu-content-container-upper">
					<div
						onClick={() => resumeWorkout()}
						hidden={props.showWorkoutSession === undefined || props.showWorkoutSession === false}
						className="workout-running-card">
						<FitnessCenter className="menu-icon menu-icon-menu-icon-white"/>
						<div className={`menu-text show-on-desktop menu-text-selected`}>
							<h4>Active Workout</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 0 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(0)}>
						<DonutLarge className={`menu-icon menu-icon-${props.selectedIndex === 0 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 0 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 0 ? 'selected' : 'unselected'}`}>
							<h4>Dashboard</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 1 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(1)}>
						<CalendarToday className={`menu-icon menu-icon-${props.selectedIndex === 1 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 1 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 1 ? 'selected' : 'unselected'}`}>
							<h4>Schedule</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 2 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(2)}>
						<DynamicFeed className={`menu-icon menu-icon-${props.selectedIndex === 2 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 2 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 2 ? 'selected' : 'unselected'}`}>
							<h4>Programs</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 3 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(3)}>
						<PeopleOutline className={`menu-icon menu-icon-${props.selectedIndex === 3 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 3 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 3 ? 'selected' : 'unselected'}`}>
							<h4>Athletes</h4>
						</div>
					</div>

					<div className={`menu-cell ${props.selectedIndex === 4 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(4)}>
						<Send className={`menu-icon menu-icon-${props.selectedIndex === 4 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 4 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 4 ? 'selected' : 'unselected'}`}>
							<h4>Messages</h4>
						</div>
					</div>

					{/*<div className="menu-section-header show-on-desktop">
						<h4>Nodes</h4>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 4 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(4)}>
						<BluetoothConnected className={`menu-icon menu-icon-${props.selectedIndex === 4 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 4 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 4 ? 'selected' : 'unselected'}`}>
							<h4>My Nodes</h4>
						</div>
					</div>
					<div hidden={true} className={`menu-cell ${props.selectedIndex === 5 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(5)}>
						<BluetoothSearching className={`menu-icon menu-icon-${props.selectedIndex === 5 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 5 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 5 ? 'selected' : 'unselected'}`}>
							<h4>Discover New</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 6 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(6)}>
						<LocalShipping className={`menu-icon menu-icon-${props.selectedIndex === 6 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 6 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 6 ? 'selected' : 'unselected'}`}>
							<h4>Order Nodes</h4>
						</div>
					</div>

					<div className={`menu-cell ${props.selectedIndex === 7 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(7)}>
						<Accessibility className={`menu-icon menu-icon-${props.selectedIndex === 7 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 7 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 7 ? 'selected' : 'unselected'}`}>
							<h4>Physiotherapy</h4>
						</div>
					</div>*/}


					<div className="menu-section-header show-on-desktop">
						<h4>Studio</h4>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 8 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(8)}>
						<AccountBox className={`menu-icon menu-icon-${props.selectedIndex === 8 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 8 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 8 ? 'selected' : 'unselected'}`}>
							<h4>Coaching Profile</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 9 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(9)}>
						<People className={`menu-icon menu-icon-${props.selectedIndex === 9 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 9 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 9 ? 'selected' : 'unselected'}`}>
							<h4>Coaches</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 10 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(10)}>
						<Store className={`menu-icon menu-icon-${props.selectedIndex === 10 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 10 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 10 ? 'selected' : 'unselected'}`}>
							<h4>Studio Settings</h4>
						</div>
					</div>
				</div>
				<div className="menu-content-container-lower">
					<div className={`menu-cell ${props.selectedIndex === 11 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(11)}>
						<Feedback className={`menu-icon menu-icon-${props.selectedIndex === 11 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 11 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 11 ? 'selected' : 'unselected'}`}>
							<h4>Help & Feedback</h4>
						</div>
					</div>
					<div className={`menu-cell ${props.selectedIndex === 12 ? 'selected-cell' : 'unselected-cell'}`} onClick={() => cellSelected(12)}>
						<Settings className={`menu-icon menu-icon-${props.selectedIndex === 12 ? 'selected' : 'unselected'}`}/>
						<div className={props.selectedIndex === 12 ? "selected-dot" : ""}/>
						<div className={`menu-text show-on-desktop menu-text-${props.selectedIndex === 12 ? 'selected' : 'unselected'}`}>
							<h4>Settings</h4>
						</div>
					</div>
					{/*<div hidden={getRemainingDaysInTrial() < 0}className="menu-cell-trial">
						<h4>Free PRO Trial Period</h4>
						<p>{getRemainingDaysInTrial()} days remaining</p>
					</div>
					<div hidden={getRemainingDaysInTrial() >= 0}className="menu-cell-trial">
						<h4>Free PRO Trial Period</h4>
						<p>Trail period is complete</p>
					</div>*/}
					<div style={{background: getColorForType(colorScheme, 'background')}} className="menu-bottom-gym-container">
						<div className="menu-bottom-gym-icon-container">
							<img className="" src={studioImageURL}/>
						</div>
						<div className="menu-bottom-gym-name-container">
							<p style={{color: getColorForType(colorScheme, 'primary')}}>{gymName !== undefined && gymName !== "" ? gymName: "My Training Studio"}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DashboardMenu;

/*

*/
