import React, { useState } from 'react';
import axios from 'axios';
import firebase from './Firebase';
import * as tf from '@tensorflow/tfjs';

import MotionData_t from './Interfaces/MotionData_t';
import MLStoreData_t from './Interfaces/MLStoreData_t';

import Exercise_t from './Interfaces/Exercise_t';
import MotionInfo_t from './Interfaces/MotionInfo_t';
import MonitorLocations_t from './Interfaces/MonitorLocations_t';

import NodeInstance from './NodeInstance';
import NodeManager from './NodeManager';

interface MLCoreLocation {
	location_index: number;
	model: any;
}

class MLCore {

	// private variables
	//private NodeManagerInstance: NodeManager;
	private nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];

	private model: any = null;
	private exercise: Exercise_t;

	private modelLoaded = false;
	private modelError = false;

	private locationModels: MLCoreLocation[] = [];

	// public variables


	// callback methods
	private callback_modelLoaded: any = null;
	private callback_modelError: any = null;


	/**
	 *	Eigen Fit MLCore constructor
	 *  ----------------------------------------------------------------------------------------------------
	 *
	 */
	constructor(exercise: Exercise_t) {
		// console.log(`> MLCore: constructor | New MLCore instance created for ${exercise.title} [${exercise.id}]`);

		for (var i = 0; i < 9; i++) {
			this.locationModels.push({
				location_index: i,
				model: null
			});
		}

		this.exercise = exercise;
		//this.NodeManagerInstance = manager;
		this.loadModel(exercise);

		// for (var i = 0; i < 9; i++) {
		// 	this.loadModelForLocation(exercise, i);
		// }
		
	}


	public setModelLoadedCallback(callback: any) {
		this.callback_modelLoaded = callback;
	}

	public setModelErrorCallback(callback: any) {
		this.callback_modelError = callback;
	}



	/**
	 *	MLCore.setExercise
	 *  Set the MLCore instance exercise referrence. Once set, the model will automatically be loaded.
	 *	@param		exercise 		<Exercise_t>		Exercise object of which the ML model should be retrieved for
	 *
	 */
	public setExercise(exercise: Exercise_t) {
		this.exercise = exercise;
		//this.loadModel(exercise);
		for (var i = 0; i < 9; i++) {
		 	this.loadModelForLocation(exercise, i);
		}
	}

	/**
	 *	MLCore.loadModel
	 *  Loads the JSON ML model from external db into MLCore instance
	 *	@param		exercise 		<Exercise_t>		Exercise object of which the ML model should be retrieved for
	 *
	 */
	private loadModel(forExercise: Exercise_t, locationIndex?: number) {

		this.modelLoaded = false;
       	this.modelError = false;

       	if (forExercise.id === "") { return; }
       	let thisExerciseID = forExercise.id;
       	let rootURL = 'https://www.eigenfitnessapi.com:5000/models'//'http://ec2-18-236-132-162.us-west-2.compute.amazonaws.com/models'
		let loadURL = `${rootURL}/${thisExerciseID}/${locationIndex === undefined ? 0 : locationIndex}/model.json` //`https://eigenfitness.com/ml_models/${thisExerciseID}/${locationIndex === undefined ? 0 : locationIndex}/model.json`; //`https://eigenfitness.com/ml_models/-Mu9gFRssbfLvS5q_rPS/model.json`;
		tf.loadLayersModel(loadURL)
		.then((loaded_model: any) => {
        	// console.log(`> MLCore: loadModel | Successfully loaded model for exercise: ${forExercise.title} [${forExercise.id}] | URL: ${loadURL}`);
        	// console.log(loaded_model.summary());
        	this.model = loaded_model;

        	this.modelLoaded = true;
        	this.modelError = false;

        	if (this.callback_modelLoaded !== null) {
        		this.callback_modelLoaded();
        		this.callback_modelError(false);
        	}
        })
        .catch((err: any) => {
        	// console.log(`> MLCore: loadModel | ERROR in tf.loadLayersModel for exercise: ${forExercise.title} [${forExercise.id}] | URL: ${loadURL}`);
        	// console.log(err);
        	this.modelLoaded = false;
        	this.modelError = true;

        	if (this.callback_modelError !== null) {
        		this.callback_modelError(true);
        	}
        })
	}

	private loadModelForLocation(forExercise: Exercise_t, locationIndex: number) {

		this.modelLoaded = false;
       	this.modelError = false;

		let loadURL = `https://eigenfitness.com/ml_models/${forExercise.id}/${`${locationIndex}`}/model.json`; //`https://eigenfitness.com/ml_models/-Mu9gFRssbfLvS5q_rPS/model.json`;
		tf.loadLayersModel(loadURL)
        .then((loaded_model: any) => {
        	// console.log(`> MLCore: loadModel | Successfully loaded model for exercise: ${forExercise.title} [${forExercise.id}] | ${this.nodeLocationNames[locationIndex]} | URL: ${loadURL}`);
        	// console.log(loaded_model.summary());
        	

        	if (this.locationModels[locationIndex] !== undefined) {
        		this.locationModels[locationIndex].model = loaded_model;
        	}

        	//this.modelLoaded = true;
        	//this.modelError = false;

        	// if (this.callback_modelLoaded !== null) {
        	// 	this.callback_modelLoaded();
        	// 	this.callback_modelError(false);
        	// }
        })
        .catch((err: any) => {
        	// console.log(`> MLCore: loadModel | ERROR in tf.loadLayersModel for exercise: ${forExercise.title} [${forExercise.id}] | ${this.nodeLocationNames[locationIndex]} | URL: ${loadURL}`);
        	// console.log(err);
        	this.modelLoaded = false;
        	this.modelError = true;

        	if (this.callback_modelError !== null) {
        		this.callback_modelError(true);
        	}
        })
	}

	/**
	 *	MLCore.predict
	 *  Run predict on the loaded ML model
	 *	@param		data 		<number[]>		Data buffer of length 448.
	 *	@return					Object 			{ prediction_ratings: number, predicted_index: number, predicted_max_rating: number }
	 *
	 */
	public predict(data: number[]) {

		if (data.length !== 448) {
			// console.log(`> MLCore: predict | ERROR! Data length is ${data.length}, but should be 448. Double check incoming data stream. Will exit to prevent TF crash`);
			return -1;
		}

		if (this.modelError === true) {
			// console.log(`> MLCore: predict | ERROR! An error presented when loadeding the model into MLCore instance.`);
			return -1;
		}

		if (this.modelLoaded === false) {
			// console.log(`> MLCore: predict | ERROR! Model has not been loaded into MLCore instance. Call <MLCore>.loadModel(forExercise: Exercise_t) to do so.`);
			return -1;
		}

		let tensor_temp = tf.tensor(data);
        let tensor = tf.reshape(tensor_temp, [1,448,1]);
        let _model: tf.LayersModel = this.model;

        let results = _model.predict(tensor) as tf.Tensor;
        let tensor_result = results.dataSync();

        let tensor_result_keys = Object.keys(tensor_result);
        var maxRating = 0.0;
        var maxIndex = 0;
        for (var i = 0; i < tensor_result_keys.length; i++) {
            let thisRating: number = tensor_result[i];
            if (thisRating > maxRating) {
                maxRating = thisRating;
                maxIndex = i;
            }
        }

        return {
        	prediction_ratings: tensor_result,
        	predicted_index: maxIndex,
        	predicted_max_rating: maxRating
        };
	}

	public predict_location(data: number[], locationIndex: number) {

		if (data.length !== 448) {
			// console.log(`> MLCore: predict | ERROR! Data length is ${data.length}, but should be 448. Double check incoming data stream. Will exit to prevent TF crash`);
			return -1;
		}

		let _model: tf.LayersModel = this.locationModels[locationIndex].model;

		if (_model === null || _model === undefined) {
			// console.log(`> MLCore: predict_location() | ERROR! An error presented when loadeding the model into MLCore instance.`);
			return -1;
		}

		let tensor_temp = tf.tensor(data);
        let tensor = tf.reshape(tensor_temp, [1,448,1]);
        

        let results = _model.predict(tensor) as tf.Tensor;
        let tensor_result = results.dataSync();

        let tensor_result_keys = Object.keys(tensor_result);
        var maxRating = 0.0;
        var maxIndex = 0;
        for (var i = 0; i < tensor_result_keys.length; i++) {
            let thisRating: number = tensor_result[i];
            if (thisRating > maxRating) {
                maxRating = thisRating;
                maxIndex = i;
            }
        }

        return {
        	prediction_ratings: tensor_result,
        	predicted_index: maxIndex,
        	predicted_max_rating: maxRating
        };
	}


	private dataStream: number[] = [];

	public appendStream(withData: MotionData_t[]) {



	}



	// Data logging methods

	private mlReadingsToStore: MLStoreData_t[] = [];
	private repNum: number = 0;

	public storeReading(motionData: MotionData_t[], node: NodeInstance, repNum: number, label: number) {
		repNum = this.repNum;
		// console.log(`> MLCore: storeReading | Will store reading of ${motionData.length} points and label \'${label}\' at rep # ${repNum} for Node ${node.getSetSIN()}.${node.getDeviceSIN()}`);
		if (repNum > this.mlReadingsToStore.length - 1) {
			// Rep DNE. Add blank reps between existsing place and new one
			//let blankMotionData: MotionData_t = {quaternion: {w:1.0,x:0,y:0,z:0}, acceleration: {x:0,y:0,z:0}};
			// console.log(`> MLCore: storeReading | Reading does not exist at rep # ${repNum} (mlReadingsToStore length = ${this.mlReadingsToStore.length}). Will add another starting at index ${this.mlReadingsToStore.length - 1} and ending at index ${repNum}`);
			let blankRep: MLStoreData_t = {
				motionData: [[],[],[],[],[],[],[],[],[]],
				nodes: [],
				label: -1,
				timestamp: Date.now()
			}
			//for (var i = this.mlReadingsToStore.length - 1; i < repNum; i++) {
				this.mlReadingsToStore.push(blankRep);
			//}
		}

		// Check if node exists in ref
		var nodeExists = false;
		for (var i = 0; i < this.mlReadingsToStore[repNum].nodes.length; i++) {
			let thisNode = this.mlReadingsToStore[repNum].nodes[i];
			if (thisNode.getUUID() === node.getUUID()) { nodeExists = true; }
		}

		if (!nodeExists) { 
			// console.log(node.getUUID(), "DNE, will add ", `${node.getSetSIN()}.${node.getDeviceSIN()}`)
			this.mlReadingsToStore[repNum].nodes.push(node);
		}
		let readingLocation = node.getLocationIndex();
		this.mlReadingsToStore[repNum].motionData[readingLocation] = motionData;
		this.mlReadingsToStore[repNum].label = label;
		// console.log(`> MLCore: storeReading | Reading location is: ${readingLocation}`);
		// console.log(`> MLCore: storeReading | completed storing reading. Object is:`);
		for (var i = 0; i < this.mlReadingsToStore[repNum].motionData.length; i++) {
			// console.log(`Location ${i}:  `,`LABEL: ${ this.mlReadingsToStore[repNum].label}  `,this.mlReadingsToStore[repNum].motionData[i]);
		}

		this.repNum += 1;
	}

	public updateStoredReadingLabels(labels: []) {
		for (var i = 0; i < this.mlReadingsToStore.length; i++) {
			if (i < labels.length) {
				this.mlReadingsToStore[i].label = labels[i];
			}
		}
	}

	private clearStoredReadings () {
		this.mlReadingsToStore = [];
		this.repNum = 0;
	}

	public pushStorageToDB(exerciseID: string, modelName: string) {

		// console.log(`> MLCore: pushStorageToDB | Pushing object to addMLData API endpoint with exerciseID: ${exerciseID} and modelName: ${modelName}.`);

		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let userUID = currentUser.uid;

		// console.log(`> MLCore: pushStorageToDB | User UID is ${userUID}.`);


		var entriesTemp = [];

		// console.log(`> MLCore: pushStorageToDB | Will begin data parsing for length ${this.mlReadingsToStore.length}`);
		for (var i = 0; i < this.mlReadingsToStore.length; i++) {
			let thisReading = this.mlReadingsToStore[i];

			// console.log(i, thisReading.motionData.length, thisReading.label, thisReading.nodes.length);

			var dataSetTemp: number[][] = [[],[],[],[],[],[],[],[],[]];
			for (var j = 0; j < thisReading.nodes.length; j++) {
				let thisNode: NodeInstance = thisReading.nodes[j];
				let thisNodeLocationIndex: number = thisNode.getLocationIndex();
				let thisDataSet = thisReading.motionData[thisNodeLocationIndex];

				var dataTemp = [];
				for (var k = 0; k < thisDataSet.length; k++) {
					let thisThisDataPoint = thisDataSet[k];
					dataTemp.push(thisThisDataPoint.quaternion.w);
					dataTemp.push(thisThisDataPoint.quaternion.x);
					dataTemp.push(thisThisDataPoint.quaternion.y);
					dataTemp.push(thisThisDataPoint.quaternion.z);

					dataTemp.push(thisThisDataPoint.acceleration.x);
					dataTemp.push(thisThisDataPoint.acceleration.y);
					dataTemp.push(thisThisDataPoint.acceleration.z);
				}

				dataSetTemp[thisNodeLocationIndex] = dataTemp;
			}

			let entryObj = {
				label: thisReading.label,
				timestamp: thisReading.timestamp,
				user_id: userUID,
				data: dataSetTemp
			}

			entriesTemp.push(entryObj);
		}


		let sendData = {
		    exercise_id: exerciseID,
		    model_name: modelName,
		    entries: entriesTemp
		};

		// console.log(`> MLCore: pushStorageToDB | Send object is:`);
		// console.log(sendData);

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/addMLData`,
		sendData,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
		    // console.log("SUCCESS");
		    // console.log(response.data);
		    let data = response.data;
		    this.clearStoredReadings();
		})
		.catch(e => {
			// console.log("ERROR");
		    // console.log(e);
		})
	}


	

}

export default MLCore;