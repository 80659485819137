import React, { useState, useEffect } from 'react';
import './AssignModalClientFolders.css';

import Client_t from '../../../Interfaces/Client_t';
import Workout_t from '../../../Interfaces/Workout_t';
import Folder_t from '../../../Interfaces/Folder_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';

import PlanFolderSelector from '../../Base/PlanFolderSelector';

import '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

function AssignModalClientFolders(props: any) {

    let initFolder: Folder_t = {
            title: "My Folder",
            id: "folder1",
            path: "folder1",
            type: "folder",
            children: [],
            isPlan: false
        };
    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};

    const [initSet, setInitSet] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState<Folder_t>(initFolder);
    const [rootFolder, setRootFolder] = useState<Folder_t>(initFolder);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.clientFolders !== undefined) {
            let rootFolderTemp: Folder_t = {
                title: "Root Folder",
                id: "root",
                path: "/",
                type: "folder",
                children: props.clientFolders,
                isPlan: false
            };
            setRootFolder(rootFolderTemp)
        }
    }, [props.clientFolders]);

    function initialize() {
        
    }

    function folderSelected(f: Folder_t) {
        props.folderSelected(f);
    }

	return (
		<div className="assign-modal-client-folders">
            <div className="assign-modal-client-folders-container">
                <div hidden={props.hideTitle !== undefined && props.hideTitle === true} className="assign-modal-client-folders-header">
                    <h4>Select a location for this new Folder</h4>
                    <p>Choose one if your Client's Folders to place this newly assigned Folder into.</p>
                    <div onClick={() => props.backButtonPressed()} className="assign-modal-client-folders-header-back-button">
                        <ArrowBack className="assign-modal-client-folders-header-back-button-icon"/>
                    </div>
                </div>
                <div className="assign-modal-client-folders-content">
                    <div className="assign-modal-client-folders-content-header">
                        <h4>{props.client === undefined || props.client.first_name === undefined || props.client.first_name === "" ? "Your Client" : props.client.first_name}'s Folder structure</h4>
                    </div>
                    <div className="assign-modal-client-folders-content-body">
            			<PlanFolderSelector 
                            isClientFolder={true}
                            clientID={props.client === undefined || props.client.id === undefined ? "" : props.client.id}
                            noCycleSelect={true}
                            incomingSelectedFolder={rootFolder}
                            folderSelected={(f: Folder_t) => folderSelected(f)}/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default AssignModalClientFolders;