import axios from 'axios';

const API_ENDPOINT = "sendChatMessage";

const sendChatMessage = async (chatID: string | string[] | undefined, message: {})=> {
    return new Promise<any>( async (resolve, reject) => {
        try {
            const params = {
                chatID: chatID,
                message: message
            }

            axios.post(
                `https://us-central1-movement-tracker-457bc.cloudfunctions.net/${API_ENDPOINT}`,
                params,
                { headers: {'Content-Type': 'application/json'} })
                .then( response => {
                    resolve(response)
                })
        }
        catch (err){
            // console.log("postCheckAndRetrieveChat: ERROR!", err);
            reject(err)
        }
    })
}

export default sendChatMessage
