import axios from 'axios';
import firebase from 'firebase';
const API_ENDPOINT = "checkAndRetrieveChat";

const postCheckAndRetrieveChat = async (userUID: string | undefined)=> {
    return new Promise<any>( async (resolve, reject) => {
        try {

            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { reject(); return; }
            let trainerUID = currentUser.uid;

            const params = {
                user_id: userUID ?? trainerUID,
            }
            // console.log("postCheckAndRetrieveChat with:", params)
            axios.post(
                `https://us-central1-movement-tracker-457bc.cloudfunctions.net/${API_ENDPOINT}`,
                params,
                { headers: {'Content-Type': 'application/json'} })
                .then( response => {
                    resolve(response.data)
                })
                .catch((err: any) => {
                    // console.log("postCheckAndRetrieveChat AXIOS ERROR:", err)
                    reject(err)
                })
        }
        catch (err){
            // console.log("postCheckAndRetrieveChat: ERROR!", err);
            reject(err)
        }
    })
}

export default postCheckAndRetrieveChat
