import React, { useState } from 'react';
import firebase from '../../Firebase';
import axios from 'axios';

import WorkoutHistoryCard from '../../components/Workouts/WorkoutHistoryCard';

import Workout_t from '../../Interfaces/Workout_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';

import './ClientProfileHistory.css';
import './ClientProfileCommon.css'


function ClientProfileHistory(props: any) {

	
	const numberToPull = 5;
	const [initSet, setInitSet] = useState(false);
	const [workoutList, setWorkoutList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [numOfLogs, setNumOfLogs] = useState(0);

	if (initSet === false) {
		setInitSet(true);
		setInit();
	}

	function setInit() {

		if (props.client === null || props.client === undefined) { return; }
		fetchMoreWorkouts();
	}

	function fetchMoreWorkouts() {
		setIsLoading(true);
		let sendData = {
			user_id: props.client.id,
			get_number: numberToPull,
			starting_at: workoutList.length
		};

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getUserHistory`,
		sendData,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {

			setIsLoading(false);
		 	let logData = response.data;
		 	let loggedWorkouts = response.data.logs;

		 	if (loggedWorkouts.length === 0) { return; }

		 	setNumOfLogs(logData.log_count);

		 	var tempWorkoutBuffer: string[] = [];
		 	for (var i = 0; i < loggedWorkouts.length; i++) {
		 		tempWorkoutBuffer.push(loggedWorkouts[i]);
		 	}
		 	setWorkoutList(workoutList.concat(tempWorkoutBuffer));
		 	
		})
		.catch(e => {
			// console.log(e);
			setIsLoading(false);
		})
	}

	function addWorkoutToList(retrievedData: any) {

		// let database = firebase.database();
		// let ref = database.ref(`workouts/${retrievedData.workout_id}`);
		// ref.on('value', function(snapshot) {
		// 	if (snapshot.exists() === true) {
		// 		let data = snapshot.val();
		// 		// console.log(data);
		// 		let createdByData = data.created_by;
		// 		let createdBy:CreatedBy_t = {
		// 			name: createdByData.name,
		// 			id: createdByData.id
		// 		};

		// 		let newWorkout: Workout_t = {
		// 			title: data.title,
		// 			id: retrievedData.id,
		// 			createdBy: createdBy,
		// 			duration: data.duration,
		// 			imageName: data.image_name,
		// 			targetGender: data.target_gender,
		// 			totalSets: Number(data.total_sets),
		// 			totalReps: Number(data.total_reps),
		// 			difficulty: Number(data.difficulty)
		// 		}
		// 	}
		// });
	}

	return (
		<div className="client-profile-page-container">
			<div hidden={workoutList.length === 0}>
				{workoutList.map((item, index) => {
					return (
						<WorkoutHistoryCard 
							key={`workouthistorycard-${index}`}
							logData={item}/>
					)
				})}
				<div 
					hidden={workoutList.length >= numOfLogs}
					onClick={() => fetchMoreWorkouts()} 
					className="client-profile-history-load-more-container">
					{isLoading ? "Loading..." : "Load more workouts"}
				</div>
			</div>
			<div className="client-profile-page-blank" hidden={workoutList.length != 0}>
				<h2>Workout Log Empty</h2>
				<p>It looks like {props.client !== undefined ? props.client.first_name : ""} hasn't logged any workouts yet.</p>
			</div>
		</div>
	)
}

export default ClientProfileHistory;