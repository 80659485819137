import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './CreateWorkoutExerciseListCell.css';

import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';
import Tempo_t from '../../../Interfaces/Tempo_t';

import CreateWorkoutExerciseListCellSetRow from './CreateWorkoutExerciseListCellSetRow';
import CreateWorkoutExerciseListCellVideo from './CreateWorkoutExerciseListCellVideo';

import '@material-ui/core';
import Add from '@material-ui/icons/Add'
import UpArrow from '@material-ui/icons/ArrowUpward';
import DownArrow from '@material-ui/icons/ArrowDownward';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Close from '@material-ui/icons/Close';
import Queue from '@material-ui/icons/Queue';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import MoveDown from '@material-ui/icons/PlaylistAdd';
import Merge from '@material-ui/icons/MergeType';
import DragIndicator from '@material-ui/icons/DragIndicator';

function CreateWorkoutExerciseListCell(props: any) {

    let muscleNames: string[][] = [
        [ // Abs
          "Rectus Abdominis",
          "External Obliques",
          "Internal Obliques"
        ],
        [ // Arms
          "Biceps Short Head",
          "Biceps Long Head",
          "Triceps Long Head",
          "Triceps Lateral Head",
          "Triceps Medial Head",
          "Brachioradialis",
          "Flexor Carpi"
        ],
        [ // Back
          "Trapezius",
          "Rhomboid Major",
          "Latissimus Dorsi",
          "Teres Major",
          "Erector Spinae"
        ],
        [ // Chest
          "Pectoralis Minor", //Clavicular Head
          "Pectoralis Major" //Sternocosial Head
        ],
        [ // Shoulders
          "Deltoid Anterior Head",
          "Deltoid Posterior Head",
          "Deltoid Lateral Head"
        ],
        [ // Legs
          "Gastrocnemius Lateral Head",
          "Gastrocnemius Medial Head",
          "Soleus",
          "Tibialis Anterior",
          "Rectus Femoris",
          "Vastus Lateralis",
          "Vastus Medialis",
          "Adductor Longus",
          "Adductor Mangus",
          "Bicep Femoris",
          "Semitendinosus"
        ],
        [ // Butt
          "Gluteus Medius",
          "Gluteus Maximus"
        ]
    ];

    let initExerciseSeries: ExerciseSeries_t = {
        title: "",
        index: 0,
        exercises: []
    }

    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    const [initSet, setInitSet] = useState(false);
    const [exerciseIndex, setExerciseIndex] = useState(0);
    const [maxExerciseIndex, setMaxExerciseIndex] = useState(5);
    const [isShowing, setIsShowing] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const [cellIsPicked, setCellIsPicked] = useState(false);
    //const [exercise, setExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[],reps:[]});
    const [exerciseSeries, setExerciseSeries] = useState<ExerciseSeries_t>(initExerciseSeries);
    const [isDragging_exercise, setIsDragging_exercise] = useState(false);

    const [musclesDiv, setMusclesDiv] = useState(<div/>);
    const [durationString, setDurationString] = useState('00 minutes');

    const [purposedIndex, setPurposedIndex] = useState(-1);
    const [purposedIndex_exercise, setPurposedIndex_exercise] = useState(-1);
    const [purposedMerge, setPurposedMerge] = useState(false);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        //setExercise(props.exercise);
        //setExerciseIndex(props.exerciseIndex);
        //setMaxExerciseIndex(props.maxExerciseIndex);
        
    }

    useEffect(() => {
        // console.log("CreateWorkoutExerciseListCell: use effect for purposedIndex_exercise triggered: ", props.purposedIndex_exercise);
        if (props.purposedIndex_exercise !== undefined) {

            setPurposedIndex_exercise(props.purposedIndex_exercise);
        }
    }, [props.purposedIndex_exercise])

    useEffect(() => {
        if (props.purposedIndex !== undefined) {

            setPurposedIndex(props.purposedIndex);
        }
    }, [props.purposedIndex]);

    useEffect(() => {
        if (props.purposedMerge !== undefined) {
            setPurposedMerge(props.purposedMerge);
        }
    }, [props.purposedMerge])


    function toggleIsShowing() {
        if (props.exerciseSeries.exercises.length === 1) {
            // Single exercise
            switchIsShowing(props.exerciseSeries.exercises[0])
        } else {
            // Exercise series (superset)
            setIsShowing(!isShowing)
        }
        
    }

    useEffect(() => {

        configureParams()
        
    }, [props.exerciseSeries])


    function configureParams() {
        var muscleGroups: string[] = [];
        var durationSeconds_temp = 0;

        if (props.exerciseSeries && props.exerciseSeries.exercises) {
            let eList = props.exerciseSeries.exercises;
            for (var i = 0; i < eList.length; i++) {
                let thisE: Exercise_t = eList[i];
                if (thisE && thisE.muscles && thisE.muscles.length > 0) {
                    let muscleList = thisE.muscles;

                    for (var m = 0; m < muscleList.length; m++) {
                        let thisMuscle = muscleList[m];
                        let thisMuscleGroup = thisMuscle.group;
                        let thisMuscleGroupString = muscle_groups[thisMuscleGroup];
                        if (!muscleGroups.includes(thisMuscleGroupString)) muscleGroups.push(thisMuscleGroupString);
                    }
                }

                let repArr = thisE.reps ?? [];

                let usingCoachRestTime = thisE.restTime !== undefined && thisE.restTime.length > 0;
                for (var k = 0; k < repArr.length; k++) {
                    let thisRep = repArr[k];
                    thisRep = isNaN(thisRep) || thisRep < 0 ? 0 : thisRep;
                    durationSeconds_temp += 3 * thisRep;
                    if (usingCoachRestTime && thisE.restTime && thisE.restTime[k] !== undefined && thisE.restTime[k] > 0) {
                        durationSeconds_temp += thisE.restTime[k]
                    } else {
                        durationSeconds_temp += 60;
                    }
                }
                durationSeconds_temp += 120;
            }
        }

        var musclesDivTemp = [];
        for (var i = 0; i < muscleGroups.length; i++) {
            let thisM = muscleGroups[i];
            if (musclesDivTemp.length < 3) {
                musclesDivTemp.push(
                    <div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill-${ thisM }` }>
                        <p>{ thisM }</p>
                    </div>
                );
            }
        }

        let resp_muscles_div = <div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-container` }>{musclesDivTemp}</div>;
        setMusclesDiv(resp_muscles_div)        

        let durationMins = Math.round(durationSeconds_temp / 60);
        let resp_duration = `${durationMins} minute${ durationMins === 1 ? '' : 's'}`;
        setDurationString(resp_duration)

    }

    function getMuscleDiv(thisE: Exercise_t) {
        var muscleGroups: string[] = [];
        if (thisE && thisE.muscles && thisE.muscles.length > 0) {
            let muscleList = thisE.muscles;

            for (var m = 0; m < muscleList.length; m++) {
                let thisMuscle = muscleList[m];
                let thisMuscleGroup = thisMuscle.group;
                let thisMuscleGroupString = muscle_groups[thisMuscleGroup];
                if (!muscleGroups.includes(thisMuscleGroupString)) muscleGroups.push(thisMuscleGroupString);
            }
        }

        var musclesDivTemp = [];
        for (var i = 0; i < muscleGroups.length; i++) {
            let thisM = muscleGroups[i];
            if (musclesDivTemp.length < 3) {
                musclesDivTemp.push(
                    <div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill-${ thisM }` }>
                        <p>{ thisM }</p>
                    </div>
                );
            }
        }

        let resp_muscles_div = <div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-container` }>{musclesDivTemp}</div>;

        return resp_muscles_div;
    }    


    function upArrowPressed() {
        // console.log("MOVING UP");
        if (props.exerciseSeriesIndex > 0) {
            // console.log("UPUPUP");
            props.moveExerciseSeriesUp(props.exerciseSeriesIndex);
        }
    }

    function downArrowPressed() {
        // console.log("MOVING DOWN");
        if (props.exerciseSeriesIndex < props.maxExerciseSeriesIndex) {
            // console.log("DOWNDOWNDOWN");
            props.moveExerciseSeriesDown(props.exerciseSeriesIndex);
        }
    }

    function removeExercise(forExercise: Exercise_t) {
        if (forExercise === undefined || forExercise === null) { return; }
        props.removeExercise(forExercise);
    }

    function getMusclesString(forExercise: Exercise_t) {
        if (forExercise !== undefined && forExercise !== null && forExercise.muscles !== undefined && forExercise.muscles.length > 0) {
           
            var muscleStringTemp = '';
            for (var i = 0; i < forExercise.muscles.length; i++) {
                let thisMuscle = forExercise.muscles[i];
                let thisString = muscleNames[thisMuscle.group][thisMuscle.muscle];
                muscleStringTemp += i === 0 ? thisString : `, ${thisString}`;
            }
            return muscleStringTemp;
            // console.log(muscleStringTemp);
        } else {
            return '';
        }
    }

    function getRestTimeString(forExercise: Exercise_t) {
        if (forExercise !== undefined && forExercise !== null && forExercise.restTime !== undefined && forExercise.restTime.length > 0) {
            var min = 1000;
            var max = 0;
            for (var i = 0; i < forExercise.restTime.length; i++) {
                let thisTime = forExercise.restTime[i];
                if (thisTime < min) { min = thisTime; }
                if (thisTime > max) { max = thisTime; }
            }
            if (min === -1 || max === -1) {
                return 'N/A';
            } else if (min === max) {
                return `${Math.round(min)}s`;
            } else {
                return `${Math.round(min)}s - ${Math.round(max)}s`;
            }
        } else {
            return 'N/A'
        }
    }

    function getTempoString(forExercise: Exercise_t, type: number, atIndex: number) {
        if (forExercise === null || forExercise === undefined) { return '-'; }
        if (forExercise.tempos === undefined || atIndex >= forExercise.tempos.length) { 
            return "-";
        }

        switch(type) {
            case 0:
                return forExercise.tempos[atIndex].eccentric === -1 ? '-' : (forExercise.tempos[atIndex].eccentric === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].eccentric)}`);
                break;
            case 1:
                return forExercise.tempos[atIndex].first_pause === -1 ? '-' : (forExercise.tempos[atIndex].first_pause === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].first_pause)}`);
                break;
            case 2:
                return forExercise.tempos[atIndex].concentric === -1 ? '-' : (forExercise.tempos[atIndex].concentric === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].concentric)}`);
                break;
            case 3:
                return forExercise.tempos[atIndex].second_pause === -1 ? '-' : (forExercise.tempos[atIndex].second_pause === -2 ? 'X' : `${Math.round(forExercise.tempos[atIndex].second_pause)}`);
                break;
            default:
                return "-";
                break;
        }
    }

    function getPercentWMString(forExercise: Exercise_t, atIndex: number) {
        if (forExercise === null || forExercise === undefined || forExercise.percent_wms === undefined) {
            return '-- %'
        } 

        var result = 0;
        if (forExercise.percent_wms.length - 1 >= atIndex) {
            result = forExercise.percent_wms[atIndex];
        } else {
            result = forExercise.percent_wms[forExercise.percent_wms.length - 1]
        }

        return result < 0 ? 'N/A' : `${Math.round(result) < 10 ? '0' : ''}${Math.round(result * 10) / 10}%`
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function getSingleRepString(rep: number) {
        return rep === -1 ? 'AMRAP' : rep;
    }

    function getRepsString(forExercise: Exercise_t) {
        if (forExercise !== undefined && forExercise !== null && forExercise.reps !== undefined) {
            var returnString = "";
            for (var i = 0; i < forExercise.reps!.length; i++) {

                if (forExercise.repRanges !== undefined && i < forExercise.repRanges.length) {
                    // Rep range in use
                    returnString += `${getSingleRepString(forExercise.repRanges![i].min)}-${getSingleRepString(forExercise.repRanges![i].max)}`;
                    returnString += forExercise.type === 0 ? '' : 's';
                    returnString += i === forExercise.reps!.length - 1 ? "" : ", ";
                } else {
                    // Using single target rep
                    returnString += `${getSingleRepString(forExercise.reps![i])}`;
                    returnString += forExercise.type === 0 ? '' : 's';
                    returnString += i === forExercise.reps!.length - 1 ? "" : ", ";
                }
                
            }

            return returnString;
        } else {
            return "N/A";
        }
    }

    function getDistances(forExercise: Exercise_t) {
        if (forExercise === null || forExercise === undefined || forExercise.type !== 2 || forExercise.distances === undefined) { return 'N/A'; }
        var min = 1000000;
        var max = 0;
        for (var i = 0; i < forExercise.distances.length; i++) {
            let thisTime = forExercise.distances[i];
            if (thisTime < min) { min = thisTime; }
            if (thisTime > max) { max = thisTime; }
        }
        if (min === -1 || max === -1) {
            return 'N/A';
        } else if (min === max) {
            return `${Math.round(min)}m`;
        } else {
            return `${Math.round(min)}s - ${Math.round(max)}m`;
        }

    }

    function getSingleRestString(forExercise: Exercise_t, index_rep: number) {
        if (forExercise === null || forExercise === undefined || forExercise.restTime === undefined) {
            return 'N/A';
        }

        if (forExercise.restTime.length - 1 >= index_rep) {
            // 
            return forExercise.restTime[index_rep] < 0 ? 'N/A' : `${Math.round(forExercise.restTime[index_rep])}s`;
        } else {
            // (item.restTime[item.restTime.length - 1])}s
            return 'N/A';
        }


        //return `${(item.restTime.length - 1 >= index_rep ? ( : `
    }

    function switchIsShowing(forExercise: Exercise_t) {
        if (forExercise === null || forExercise === undefined) { return; }
        props.switchIsShowing(forExercise);
    }

    function moveUpExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        // Reject invalid cases
        if (forExerciseSeries.exercises.length === 0 || (forExerciseSeries.exercises.length > 0 && exerciseIndex === 0)) {
            return;
        }

        if (props.moveUpExercisePressed !== undefined) {
            props.moveUpExercisePressed(forExerciseSeries, forExercise, exerciseIndex);
        }

    }

    function moveDownExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
         // Reject invalid cases
        if (forExerciseSeries.exercises.length === 0 || (exerciseIndex === forExerciseSeries.exercises.length - 1)) {
            return;
        }

        if (props.moveDownExercisePressed !== undefined) {
            props.moveDownExercisePressed(forExerciseSeries, forExercise, exerciseIndex);
        }
    }

    function removeFromSuperset(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        props.removeFromSupersetPressed(forExerciseSeries, forExercise, exerciseIndex);
    }

    function mergeDownToSuperset(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        props.mergeDownToSuperset(forExerciseSeries, forExercise, exerciseIndex);
    }


    

    function editedReps(exercise: Exercise_t, setIndex: number, toReps: number) {
        //// console.log("CreateWorkoutExerciseListCell | editedReps: toReps = ", isNaN(toReps) ? 0 : toReps, setIndex, exercise);
        props.editedReps(exercise, setIndex, isNaN(toReps) ? 0 : toReps);
    }

    function editedPWM(exercise: Exercise_t, setIndex: number, toPWM: number) {
        //// console.log("CreateWorkoutExerciseListCell | editedReps: toPWM = ", isNaN(toPWM) ? 0 : toPWM, setIndex, exercise);
        props.editedPWM(exercise, setIndex, isNaN(toPWM) ? 0 : toPWM);
    }

    function editedTempo(exercise: Exercise_t, setIndex: number, toTempo: Tempo_t) {
        //// console.log("CreateWorkoutExerciseListCell | editedTempo: toTempo = ", toTempo, setIndex, exercise);
        props.editedTempo(exercise, setIndex, toTempo);
    }

    function editedRest(exercise: Exercise_t, setIndex: number, toRest: number) {
        //// console.log("CreateWorkoutExerciseListCell | editedRest: toRest = ", isNaN(toRest) ? 0 : toRest, setIndex, exercise);
        props.editedRest(exercise, setIndex, isNaN(toRest) ? 0 : toRest);
    }


    //***** DRAG RELATED FUNCTIONS *****
    function updateDraggingPosition(x: number, y: number) {
        if (isDragging_exercise === true) { return; }
        //// console.log("X CreateWorkoutExerciseListCell | Cell Dragging position updated: ", x, y);
        if (props.dragPosUpdated !== undefined) {
            props.dragPosUpdated(x, y);
        }

        if (isShowing === true && props.exerciseSeries.exercises.length === 1) {
            setIsShowing(false);
        }
        
    }

    function updateDraggingState(toState: boolean) {
        setIsDragging(toState);
        if (!toState) {
            if (cellIsPicked) {
                props.cellDropped();
            }
            setCellIsPicked(false);
        } else {
            //setIsShowing(false);
            setCellIsPicked(true);
            props.cellPicked();
        }
    }

    function updateDraggingPosition_exercise(x: number, y: number, item: Exercise_t, index: number) {
        //// console.log("Z CreateWorkoutExerciseListCell | Cell Dragging --- EXERCISE --- position updated: ", x, y);
        setIsDragging_exercise(true);
        setCellIsPicked(false);
        if (props.dragPosUpdated_exercise !== undefined) {
            props.dragPosUpdated_exercise(x, y, index);
        }
    }

    function dragStartHandle(event: any, exercise: Exercise_t, index: number) {
        //// console.log(`Drag START ${exercise.title} at${index}!`, event);
        setPurposedIndex_exercise(index);
        if (exercise.isShowing !== undefined && exercise.isShowing === true) {
            props.switchIsShowing(exercise);
        }

        props.cellPicked_exercise(index);
    }

    function dragEndHandle(event: any, exercise: Exercise_t, index: number) {
        // console.log(`Drag END ${exercise.title} at ${index}!`, event);
        setIsDragging_exercise(false);
        setPurposedIndex_exercise(-1);
        props.cellDropped_exercise(index);
    }

    return (
        <div id={`block-${props.exerciseSeriesIndex}`}
            className={`create-workout-exercise-list-cell-container ${purposedMerge && props.exerciseSeriesIndex === purposedIndex ? 'create-workout-exercise-list-cell-container-merge' : ''}`}
            draggable={true} 
            onDrag={(e: any) => updateDraggingPosition(e.clientX, e.clientY)}
            onDrop={(e: any) => console.log("onDrop", e)}
            onDragStart={(e: any) => updateDraggingState(true)}
            onDragStartCapture={(e: any) => console.log("onDragStartCapture", e)}
            onDragEnd={(e: any) => updateDraggingState(false)}
            onDragEndCapture={(e: any) => console.log(`onDragEndCapture: (x:${e.clientX}, y: ${e.clientY})`)}>
            <div className={ `create-workout-exercise-list-adjust-container ${ props.exerciseSeries.exercises.length > 1 ? 'create-workout-exercise-list-adjust-container-sm' : '' }`}>
                {/*<div hidden={props.hideEditingFeatures !== undefined && props.hideEditingFeatures === true} onClick={() => upArrowPressed()}><UpArrow style={{ fontSize: 14 }} className={props.exerciseSeriesIndex > 0 ? "create-workout-exercise-list-adjust-arrow-active" : "create-workout-exercise-list-adjust-arrow-inactive"}/></div>*/}
                <div hidden={props.hideEditingFeatures === undefined || props.hideEditingFeatures === false} onClick={() => toggleIsShowing()} className="create-workout-exercise-list-series-show-arrow-icon-container">
                    <div hidden={props.exerciseSeries.exercises.length === 1 && props.exerciseSeries.exercises[0] !== null ? (props.exerciseSeries.exercises[0].isShowing !== null && props.exerciseSeries.exercises[0].isShowing !== undefined ? props.exerciseSeries.exercises[0].isShowing : false) : isShowing} className="create-workout-exercise-list-series-show-arrow-icon-container-inner"><ExpandMore style={{ fontSize: 14 }} className="create-workout-exercise-list-series-show-arrow-icon"/></div>
                    <div hidden={props.exerciseSeries.exercises.length === 1 && props.exerciseSeries.exercises[0] !== null ? !(props.exerciseSeries.exercises[0].isShowing !== null && props.exerciseSeries.exercises[0].isShowing !== undefined ? props.exerciseSeries.exercises[0].isShowing : false) :!isShowing} className="create-workout-exercise-list-series-show-arrow-icon-container-inner"><ExpandLess style={{ fontSize: 14 }} className="create-workout-exercise-list-series-show-arrow-icon"/></div>
                </div>
                <div className={ "create-workout-exercise-list-adjust-container-letter-wrapper" }>
                    <div  className="create-workout-exercise-list-adjust-circle">
                        <p>{getSetLetter(props.exerciseSeriesIndex)}</p>
                    </div>
                </div>
                <div onClick={() => toggleIsShowing()} className="create-workout-exercise-list-adjust-container-inner">
                    <div className="create-workout-exercise-list-adjust-container-inner-header">
                        <div hidden={props.hideEditingFeatures !== undefined && props.hideEditingFeatures === true} className="create-workout-exercise-list-adjust-container-drag"><DragIndicator className="create-workout-exercise-list-adjust-container-drag-icon"/></div>

                        <div  hidden={props.exerciseSeries.exercises.length === 1} className="create-workout-exercise-list-adjust-text">
                            <h4>{getSetLetter(props.exerciseSeriesIndex)} Series{ props.exerciseSeries.exercises.length === 2 ? ' superset' : ' circuit' }</h4>
                            <p>({ props.exerciseSeries.exercises.length } x { props.exerciseSeries.exercises[0] && props.exerciseSeries.exercises[0].reps ? props.exerciseSeries.exercises[0].reps.length : 0 } sets)</p>
                        </div>
                        <div hidden={props.exerciseSeries.exercises.length > 1} className="create-workout-exercise-list-cell-name-container create-workout-exercise-list-cell-name-container-header">
                            <h4>{props.exerciseSeries.exercises[0] === undefined || props.exerciseSeries.exercises[0] === null ? 'Empty Block' : props.exerciseSeries.exercises[0].title}</h4>
                        </div>
                        {/*<div hidden={props.exerciseSeries.exercises.length > 1} className="create-workout-exercise-list-cell-param-container">
                            <h2>{props.exerciseSeries.exercises[0] === null ? "" : getRepsString(props.exerciseSeries.exercises[0])}</h2>
                        </div>
                        <div hidden={props.exerciseSeries.exercises === undefined || props.exerciseSeries.exercises[0] === undefined || props.exerciseSeries.exercises[0] === null ? true : (props.exerciseSeries.exercises.length > 1 || props.exerciseSeries.exercises[0].type !== 0)} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                            <h2>
                                {props.exerciseSeries.exercises[0] === null ? "" : getTempoString(props.exerciseSeries.exercises[0], 0, 0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                                {props.exerciseSeries.exercises[0] === null ? "" : getTempoString(props.exerciseSeries.exercises[0], 1, 0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                                {props.exerciseSeries.exercises[0] === null ? "" : getTempoString(props.exerciseSeries.exercises[0], 2, 0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                                {props.exerciseSeries.exercises[0] === null ? "" : getTempoString(props.exerciseSeries.exercises[0], 3, 0)}
                            </h2>
                        </div>
                        <div hidden={props.exerciseSeries.exercises === undefined || props.exerciseSeries.exercises[0] === undefined || props.exerciseSeries.exercises[0] === null ? true : (props.exerciseSeries.exercises.length > 1 || props.exerciseSeries.exercises[0].type !== 2)} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                            <h2>{getDistances(props.exerciseSeries.exercises[0])}</h2>
                        </div>
                        <div hidden={props.exerciseSeries.exercises === undefined ? true : (props.exerciseSeries.exercises.length > 1)} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                            <h2>{getRestTimeString(props.exerciseSeries.exercises[0])}</h2>
                        </div>*/}
                    </div>
                    <div hidden={ props.exerciseSeries.exercises.length > 1 } className="create-workout-exercise-list-adjust-container-inner-body">
                        <div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                            <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <div className="workout-plan-list-plan-workout-card-content-cell-content-rep-scheme-container">
                                    <span>Reps </span>
                                    <h4>{ props.exerciseSeries.exercises[0] === null ? "" : getRepsString(props.exerciseSeries.exercises[0]) }</h4>
                                </div>
                            </div>
                             <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <span>Muscles</span>
                                <h4>{ musclesDiv }</h4>
                            </div>
                        </div>
                        {/*<div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                            <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <div className="workout-plan-list-plan-workout-card-content-cell-content-rep-scheme-container">
                                    <span>Target Duration</span>
                                    <h4>{ durationString }</h4>
                                </div>
                            </div>
                             <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <span>Rest time</span>
                                <h4>{ getRestTimeString(props.exerciseSeries.exercises[0]) }</h4>
                            </div>
                        </div>*/}
                        <div className={ "create-workout-exercise-list-adjust-container-inner-body-video-container" }>
                            <div className={ "create-workout-exercise-list-adjust-container-inner-body-video-container-curtain" }></div>
                            <CreateWorkoutExerciseListCellVideo exerciseID={ props.exerciseSeries.exercises[0] === null ? "" : props.exerciseSeries.exercises[0].id }/>
                        </div>
                    </div>
                </div>
                {/*<div hidden={props.hideEditingFeatures !== undefined && props.hideEditingFeatures === true} onClick={() => downArrowPressed()}><DownArrow style={{ fontSize: 14 }} className={`create-workout-exercise-list-adjust-arrow-down ${props.exerciseSeriesIndex < props.maxExerciseSeriesIndex ? "create-workout-exercise-list-adjust-arrow-active" : "create-workout-exercise-list-adjust-arrow-inactive"}`}/></div>*/}                
                <div hidden={props.hideEditingFeatures !== undefined && props.hideEditingFeatures === true} className="create-workout-exercise-list-cell-superset-button-container">
                    <div hidden={props.exerciseSeries.exercises.length !== 1} onClick={() => props.editExercisePressed(props.exerciseSeries, props.exerciseSeries.exercises[0])} className="create-workout-exercise-list-cell-superset-button">
                        <Edit className="create-workout-exercise-list-cell-superset-button-icon"/>
                    </div>
                    <div onClick={() => props.addToSupersetPressed()} className="create-workout-exercise-list-cell-superset-button">
                        <Add className="create-workout-exercise-list-cell-superset-button-icon"/>
                    </div>
                    <div onClick={() => props.removeExerciseSeries(props.exerciseSeries)} className="create-workout-exercise-list-cell-superset-button">
                        <Close className="create-workout-exercise-list-cell-superset-button-icon create-workout-exercise-list-cell-superset-button-icon-remove"/>
                    </div>
                    
                </div>
                {/*<div onClick={() => props.addToSupersetPressed()} hidden={props.isEditable === undefined || props.isEditable === false} className="create-workout-exercise-list-cell-superset-button-small-container">
                    <Add className="create-workout-exercise-list-cell-superset-button-small-icon"/>
                </div>
                <div onClick={() => props.removeExerciseSeries(props.exerciseSeries)} hidden={props.isEditable === undefined || props.isEditable === false} className="create-workout-exercise-list-cell-superset-button-small-container create-workout-exercise-list-cell-remove-button-small-container">
                    <Delete className="create-workout-exercise-list-cell-superset-button-small-icon"/>
                </div>*/}
            </div>
            <div hidden={!isShowing && props.exerciseSeries.exercises.length === 1} className="create-workout-exercise-list-cell-content-container">
                {
                    props.exerciseSeries.exercises.map((item: Exercise_t, index: number) => (
                        <div className="create-workout-exercise-list-cell-content-outter">
                            <div hidden={!(purposedIndex_exercise === index && purposedIndex === props.exerciseSeriesIndex)} className="create-workout-exercise-list-cell-content-purposed"/>
                            <div hidden={ !props.exerciseSeries.exercises || props.exerciseSeries.exercises.length === 1 } className={ "create-workout-exercise-list-cell-content-branch-container" }>
                                <div className={ "create-workout-exercise-list-cell-content-branch" }/>
                            </div>
                            <div className={ "create-workout-exercise-list-cell-content-branch-dot" }/>
                            <div 
                                onDragStart={(e) => dragStartHandle(e,item,index)}
                                onDragEnd={(e) => dragEndHandle(e,item,index)}
                                onDrag={(e: any) => updateDraggingPosition_exercise(e.clientX, e.clientY, item, index)}
                                draggable={props.exerciseSeries.exercises.length > 1} 
                                id={`workout-exercise-list-cell-${props.exerciseSeriesIndex}-${item === null ? "" : item.id}`}  
                                className={`create-workout-exercise-list-cell-content-outter-container ${props.exerciseSeries.exercises.length > 1 ? "create-workout-exercise-list-cell-content-outter-container-multi" : ""}`}>
                                
                                <div className="create-workout-exercise-list-cell-content">
                                    <div hidden={props.exerciseSeries.exercises.length === 1} className="create-workout-exercise-list-cell-content-upper">
                                        <div className={ "create-workout-exercise-list-adjust-container-inner-header" }>
                                            <div onClick={() => switchIsShowing(item)} className="create-workout-exercise-list-cell-content-multi-lettering-container">
                                                <p>{getSetLetter(props.exerciseSeriesIndex)}{index + 1}</p>
                                            </div>
                                            <div className="create-workout-exercise-list-cell-content-upper-drag-container">
                                                <DragIndicator className="create-workout-exercise-list-cell-content-upper-drag-icon"/>
                                            </div>
                                            <div onClick={() => switchIsShowing(item)} className="create-workout-exercise-list-cell-name-container">
                                                <div hidden={true}><p>{getMusclesString(item)}</p></div>
                                                <h2>{item === null ? "" : item.title}</h2>
                                            </div>
                                        </div>
                                        <div className="create-workout-exercise-list-adjust-container-inner-body">
                                            <div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                                                <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                                    <div className="workout-plan-list-plan-workout-card-content-cell-content-rep-scheme-container">
                                                        <span>Reps </span>
                                                        <h4>{ item === null ? "" : getRepsString(item) }</h4>
                                                    </div>
                                                </div>
                                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                                    <span>Muscles</span>
                                                    <h4>{ getMuscleDiv(item) }</h4>
                                                </div>
                                            </div>
                                            {/*<div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                                                <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                                    <div className="workout-plan-list-plan-workout-card-content-cell-content-rep-scheme-container">
                                                        <span>Target Duration</span>
                                                        <h4>{ durationString }</h4>
                                                    </div>
                                                </div>
                                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                                    <span>Rest time</span>
                                                    <h4>{ getRestTimeString(props.exerciseSeries.exercises[0]) }</h4>
                                                </div>
                                            </div>*/}
                                            <div className={ "create-workout-exercise-list-adjust-container-inner-body-video-container" }>
                                                <div className={ "create-workout-exercise-list-adjust-container-inner-body-video-container-curtain" }></div>
                                                <CreateWorkoutExerciseListCellVideo exerciseID={ item === null ? "" : item.id }/>
                                            </div>
                                        </div>
                                        
                                        {/*<div onClick={() => switchIsShowing(item)} className="create-workout-exercise-list-cell-param-container">
                                            <div hidden={true}><p>{item === null ? "" : item.type === 0 ? (item.repRanges !== undefined && item.repRanges.length > 0 ? "Rep ranges" : "Reps") : 'Duration'}</p></div>
                                            <h2>{getRepsString(item)}</h2>
                                        </div>
                                        <div onClick={() => switchIsShowing(item)} hidden={item === null ? true : item.type !== 0} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                            <div hidden={true}><p>Tempo</p></div>
                                            <h2>
                                                {getTempoString(item, 0, 0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                                                {getTempoString(item, 1, 0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                                                {getTempoString(item, 2, 0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                                                {getTempoString(item, 3, 0)}
                                            </h2>
                                        </div>
                                        <div onClick={() => switchIsShowing(item)} hidden={item === null ? true : item.type !== 2} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                            <div hidden={true}><p>Distances</p></div>
                                            <h2>{getDistances(item)}</h2>
                                        </div>
                                        <div onClick={() => switchIsShowing(item)} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                            <div hidden={true}><p>Rest Time</p></div>
                                            <h2>{getRestTimeString(item)}</h2>
                                        </div>*/}
                                        <div hidden={props.hideEditingFeatures !== undefined && props.hideEditingFeatures === true} className="create-workout-exercise-list-cell-superset-button-container">
                                            <div onClick={() => props.editExercisePressed(props.exerciseSeries, item)} className="create-workout-exercise-list-cell-superset-button">
                                                <Edit className="create-workout-exercise-list-cell-superset-button-icon"/>
                                            </div>
                                            <div onClick={() => props.removeExercisePressed(item)} className="create-workout-exercise-list-cell-superset-button">
                                                <Close className="create-workout-exercise-list-cell-superset-button-icon create-workout-exercise-list-cell-superset-button-icon-remove"/>
                                            </div>
                                            {/*<div onClick={() => props.removeExercisePressed(item)} hidden={props.isEditable === undefined || props.isEditable === false} className="create-workout-exercise-list-cell-remove-icon-container">
                                                <Delete className="create-workout-exercise-list-cell-remove-icon-small"/>
                                            </div>*/}
                                        </div>
                                        
                                        {/*<div onClick={() => switchIsShowing(item)} hidden={item === null ? true : item.isShowing !== undefined && item.isShowing === true} className="create-workout-exercise-list-cell-expand-icon-container">
                                            <ExpandMore className="create-workout-exercise-list-cell-expand-icon"/>
                                        </div>
                                        <div onClick={() => switchIsShowing(item)} hidden={item === null ? true : item.isShowing === undefined || (item.isShowing !== undefined && item.isShowing === false)} className="create-workout-exercise-list-cell-expand-icon-container">
                                            <ExpandLess className="create-workout-exercise-list-cell-expand-icon"/>
                                        </div>*/}
                                    </div>
                                    <CSSTransition in={item === null ? true : !(item.isShowing === undefined || item.isShowing === false)} timeout={200} classNames="menu" unmountOnExit>
                                        <div className="create-workout-exercise-list-cell-content-lower">
                                            <div className="create-workout-exercise-list-cell-content-lower-reps-list-container">
                                                <div className="create-workout-exercise-list-cell-content-lower-reps-list-header">
                                                    <div className="create-workout-exercise-list-cell-name-container">
                                                        <p>SET NUMBER</p>
                                                    </div>
                                                    <div className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                                        <p>{item === null ? "" : item.type === 0 ? (item.repRanges !== undefined && item.repRanges.length > 0 ? "REP RANGE" : "REPS") : "DURATION"}</p>
                                                    </div>
                                                    <div className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                                        <p>{item === null ? "" : item.type === 2 ? '% MAX. HR' : '%WM'}</p>
                                                    </div>
                                                    <div hidden={item === null ? true : item.type === 1} className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                                        <p>{item === null ? "" : item.type === 2 ? 'DISTANCE' : 'TEMPO'}</p>
                                                    </div>
                                                    <div className="create-workout-exercise-list-cell-param-container create-workout-exercise-list-cell-param-container-small">
                                                        <p>REST TIME</p>
                                                    </div> 
                                                </div>
                                                <div className="create-workout-exercise-list-cell-content-lower-reps-list-content">
                                                    {
                                                        item === null ? null : item.reps === undefined ? null : item.reps.map((item_rep: number, index_rep: number) => (
                                                            <CreateWorkoutExerciseListCellSetRow
                                                                reps={item_rep}
                                                                setIndex={index_rep}
                                                                exercise={item}
                                                                exerciseIndex={index}
                                                                exerciseSeriesIndex={props.exerciseSeriesIndex}
                                                                isEditable={props.isEditable !== undefined && props.isEditable === true}
                                                                showSetLetter={props.exerciseSeries !== undefined && props.exerciseSeries.exercises !== undefined && props.exerciseSeries.exercises.length > 1}
                                                                removePressed={() => props.removePressed(item, index_rep)}
                                                                editedReps={(r: number) => editedReps(item, index_rep, r)}
                                                                editedPWM={(r: number) => editedPWM(item, index_rep, r)}
                                                                editedTempo={(t: Tempo_t) => editedTempo(item, index_rep, t)}
                                                                editedRest={(r: number) => editedRest(item, index_rep, r)}/>
                                                        ))
                                                    }
                                                    <div onClick={() => props.addSetPressed !== undefined ? props.addSetPressed(item) : null} hidden={props.quickAdd === undefined || props.quickAdd === false} className="create-workout-exercise-list-cell-content-lower-reps-list-content-add-set-button">
                                                        <p>+ Add another set</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div hidden={props.hideEditingFeatures !== undefined && props.hideEditingFeatures === true} className="create-workout-exercise-list-cell-content-lower-options-container">
                                                <div onClick={() => moveUpExercisePressed(props.exerciseSeries, item, index)} className={`${props.exerciseSeries.exercises.length === 0 || (props.exerciseSeries.exercises.length > 0 && index === 0) ? 'create-workout-exercise-list-cell-content-lower-options-unselected-content' : ''} create-workout-exercise-list-cell-content-lower-options-content`}>
                                                    <div className="create-workout-exercise-list-cell-content-lower-options-content-inner">
                                                        <UpArrow className="create-workout-exercise-list-cell-content-lower-options-icon"/>
                                                        <p>Up</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => moveDownExercisePressed(props.exerciseSeries, item, index)} className={`${props.exerciseSeries.exercises.length === 0 || (props.exerciseSeries.exercises.length > 0 && index === props.exerciseSeries.exercises.length - 1) ? 'create-workout-exercise-list-cell-content-lower-options-unselected-content' : ''} create-workout-exercise-list-cell-content-lower-options-content`}>
                                                    <div className="create-workout-exercise-list-cell-content-lower-options-content-inner">
                                                        <DownArrow className="create-workout-exercise-list-cell-content-lower-options-icon"/>
                                                        <p>Down</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => props.exerciseSeries.exercises.length <= 1 ? null : removeFromSuperset(props.exerciseSeries, item, index)} className={`${props.exerciseSeries.exercises.length <= 1 ? 'create-workout-exercise-list-cell-content-lower-options-unselected-content' : ''} create-workout-exercise-list-cell-content-lower-options-content`}>
                                                    <div className="create-workout-exercise-list-cell-content-lower-options-content-inner">
                                                        <MoveDown className="create-workout-exercise-list-cell-content-lower-options-icon"/>
                                                        <p>Make a Block</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => props.exerciseSeriesIndex === props.maxExerciseSeriesIndex ? null : mergeDownToSuperset(props.exerciseSeries, item, index)} className={`${props.exerciseSeriesIndex === props.maxExerciseSeriesIndex ? 'create-workout-exercise-list-cell-content-lower-options-unselected-content' : ''} create-workout-exercise-list-cell-content-lower-options-content`}>
                                                    <div className="create-workout-exercise-list-cell-content-lower-options-content-inner">
                                                        <Merge className="create-workout-exercise-list-cell-content-lower-options-icon create-workout-exercise-list-cell-content-lower-options-icon-merge"/>
                                                        <p>Merge</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => props.editExercisePressed(props.exerciseSeries, item)} className="create-workout-exercise-list-cell-content-lower-options-content">
                                                    <div className="create-workout-exercise-list-cell-content-lower-options-content-inner">
                                                        <Edit className="create-workout-exercise-list-cell-content-lower-options-icon"/>
                                                        <p>Edit Details</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => props.removeExerciseSeries(props.exerciseSeries, index)} className="create-workout-exercise-list-cell-content-lower-options-content create-workout-exercise-list-cell-content-lower-options-content-red">
                                                    <div className="create-workout-exercise-list-cell-content-lower-options-content-inner">
                                                        <Delete className="create-workout-exercise-list-cell-content-lower-options-icon"/>
                                                        <p>Remove</p>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </CSSTransition>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CreateWorkoutExerciseListCell;