import React, { useState } from 'react';
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import './Profile.css';
import './Dashboard/DashboardPageCommon.css';
import firebase from '../Firebase';
import TextInput from '../components/Base/TextInput';
import StandardButton from '../components/Base/StandardButton';



function Profile(props: any) {

	let defaultProfile = {
		city: "",
		company_name: "",
		email: "",
		first_name: "",
		full_name: "",
		last_name: "",
		works_for_org: false,
		years_experience: 5
	}

	const [initSet, setInitSet] = useState(false);
	const [profile, setProfile] = useState<any>(defaultProfile);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        let currentUser = firebase.auth().currentUser;
        if (currentUser === null) { return; }
		let trainerUID = currentUser.uid;

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_profile/${trainerUID}`); // Change to database path
		ref.on('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        let data = snapshot.val();
		        setProfile(data);
		        
		        // Parse and use data here
		        
		    }
		});
    }

	function attemptLogout() {
		let modalData = {
			title: "Are you sure you want to log out? ",
			text: "If you haven't finished a workout, all progress will be lost. Otherwise, all data will be saved for your next login.",
			isRed: true,
			callback: logout,
			ctaText: "Logout"
		};
		props.getUserConfirmation(modalData);
	}

	function logout() {
		props.logout();
	}

	function getYearsString() {
		var returnString = profile.years_experience === 0 ? "Just starting" : `${profile.years_experience} years`;
		return returnString;
	}

	return (
		<div className="page-container">
			<div className="logout-header-container">
				<p>Your training portfolio</p>
				<h1>{props.profile.full_name}</h1>
				<div className="logout-button" onClick={() => attemptLogout()}>Logout</div>
			</div>
			<div className="profile-info-container">
				<div className="profile-card-container">
					<h2>My Information</h2>
					<div className="profile-info-section-container">
						<p>Trainer Name</p>
						<h4>{profile.full_name}</h4>
					</div>
					<div className="profile-info-section-container">
						<p>Email Address</p>
						<h4>{profile.email}</h4>
					</div>
					<div className="profile-info-section-container">
						<p>My experience</p>
						<h4>{getYearsString()}</h4>
					</div>
				</div>
				<div className="profile-card-container">
					<h2>Gym Information</h2>
					<div hidden={profile.works_for_org} className="profile-info-section-container">
						<p>Workout Location</p>
						<h4>My workout facility</h4>
					</div>
					<div hidden={!profile.works_for_org} className="profile-info-section-container">
						<p>Gym Name</p>
						<h4>{profile.company_name}</h4>
					</div>
					<div className="profile-info-section-container">
						<p>City</p>
						<h4>{profile.city}</h4>
					</div>
				</div>
			</div>
		</div>
	)

}

export default Profile;