import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import TextInput from '../../../components/Base/TextInput';
import WorkoutCard from '../../../components/Workouts/WorkoutCard';
import './SelectWorkout.css';
import '../DashboardPageCommon.css';
import firebase from '../../../Firebase';

import Workout_t from '../../../Interfaces/Workout_t';
import Client_t from '../../../Interfaces/Client_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';

function SelectWorkout(props: any) {

	const [workoutsPulled, setWorkoutsPulled] = useState(false);
	const [workoutList, setWorkoutList] = useState<Workout_t[]>([]);

	getWorkouts();

	function getWorkouts() {
		if (workoutsPulled === true) { return; }
		setWorkoutsPulled(true);

		let database = firebase.database();
		let ref = database.ref('workouts') // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let woList = snapshot.val();

				var temp: Workout_t[] = [];
				var i = 0;
				for (const key of Object.keys(woList)) {
					let thisWorkout = woList[key];
					let crBy = thisWorkout.created_by;
					let createdBy: CreatedBy_t = { name: crBy.name, id: crBy.id };

					let newWorkout: Workout_t = {
						title: thisWorkout.title,
						id: key,
						createdBy: createdBy,
						duration: thisWorkout.duration,
						imageName: thisWorkout.image_name,
						targetGender: thisWorkout.target_gender,
						totalSets: thisWorkout.total_sets,
						totalReps: thisWorkout.total_reps,
						difficulty: thisWorkout.difficulty
					}

					if (i < 10) { temp.push(newWorkout); }
					i += 1;
				}

				setWorkoutList(temp);
			}
		});
	}

	function getBirthdate() {
		return props.client.birthdate === "00-00-0000" || props.client.birthdate === null || props.client.birthdate === undefined ? "Not specified" : props.client.birthdate;
	}

	function getAge() {

		if (props.client.birthdate === "00-00-0000" || props.client.birthdate === null || props.client.birthdate === undefined) {
			return "Not specified"
		}

		let dates = props.client.birthdate.split("-");
		let year = dates[2];
		let month = dates[1];
		let day = dates[0];
		let bd = new Date(year, month, day);
		let bd_date = new Date(bd);
		let today = new Date();

		let yearDiff = today.getFullYear() - bd_date.getFullYear();
		return yearDiff;
	}

	function getGender() {
		let genders: any = {'m' : 'Male', 'f': 'Female', 'b': 'Unspecified'};
		let specifiedGender: any = props.client.gender;
		return genders[specifiedGender];
	}

	function getTags() {
		if (props.client.tags === undefined || props.client.tags === null || props.client.tags.length === 0) {
			return <div></div>;
		}

		var list: any = [];
		for (var i = 0; i < props.client.tags.length; i++) {
			let newPill = <div className="pill">{props.client.tags[i]}</div>
			list.push(newPill);
		}

		return list;
	}

	function getNotes() {
		if (props.client.notes === undefined || props.client.notes === null || props.client.tags.notes === 0) {
			return "No notes avaliable"
		}
	}

	function textInputChanged(e: string) {
		// console.log(`Search: ${e}`);
	}

	return (
		<div className="client-container">
			<div>
				<p onClick={() => props.backButtonPressed()}>&larr; Back to Clients</p>
				<h1>Choose {props.client.first_name}'s Workout</h1>
			</div>
			<div className="client-card">
				<div className="client-card-image-container">
					<div className="client-card-image"/>
				</div>
				<div className="client-card-content-container">
					<div className="client-card-content-body">
						<div className="client-card-user-info-container">
							<p><b>Birthdate </b> {getBirthdate()}</p>
							<p><b>Age </b> { getAge() }</p>
							<p><b>Weight </b> { props.client.weight } { props.client.isMetric ? "KG" : "LBS"}</p>
							<p><b>Gender </b> { getGender() }</p>
						</div>
						<div className="client-card-user-notes-container">
							<p><b>Notes about client </b></p>
							<p>{ getNotes() }</p>
						</div>
					</div>
					<div className="pill-container">
						{ getTags() }
					</div>
				</div>
			</div>
			<div className="search-container">
				<h2>Search for a Workout</h2>
				<TextInput isSearch={true} placeholder="Workout Name" input="" inputChanged={(e: string) => textInputChanged(e)}></TextInput>
				<div className="scroller-container">
					<p>MY WORKOUTS</p>
					<div className="workout-card-container">
						{workoutList.map(item => (
							<WorkoutCard workout={item}/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default SelectWorkout;
