import React, {useEffect, useState} from "react"
import './Messages.css'
import PageHeader from "../../components/Base/PageHeader";
import PageHeader_t from "../../Interfaces/PageHeader_t";
import ChatPreview from "./ChatPreview";
import getChatPreview from "../ClientProfile/Chat/getChatPreview"
import ClientChat from "../ClientProfile/Chat/ClientChat";
import firebase from "firebase";

const Messages = (props: any) => {
    const currentUser = firebase.auth().currentUser;
    const trainerUID = currentUser?.uid;

    const [initSet, setInitSet] = useState(false);
    const [chatPreviews, setChatPreviews] = useState<any>([])
    const [selectedChat, setSelectedChat] = useState(0)
    const [pageNumber, setPageNumber] = useState(0);
    const [value, setValue] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!chatPreviews[selectedChat]?.clientID){
                setValue((prevValue: number) => prevValue + 1);
            }
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);


    let pageOptions: PageHeader_t[] = [
        { name:"Inbox", alert:false }
    ];

    function pageSelected(newPage: string) {
        for (let i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    const retrieveChatPreviews = async () => {
        console.log(">>> retrieveChatPreviews")

        const retrievedRawChatPreviews = await getChatPreview(trainerUID)
        console.log(">>> retrieveChatPreviews | ", retrievedRawChatPreviews.data)
        return retrievedRawChatPreviews.data
    }

    const getAllClients = async () => {

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_clients/${trainerUID}/clients`);
        let snapshot = await ref.once('value');

        if (snapshot.exists()) {
            let val = snapshot.val();
            return val;
        } else {
            return [];
        }
    }

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet ) {
            const fetchChatPreviewData = async () => {
                setIsLoading(true);
                const retrievedRawChatPreviews = await retrieveChatPreviews()
                const retrievedAllClients = await getAllClients();
                setIsLoading(false);
                setChatPreviews(parseChatPreviews(retrievedRawChatPreviews, retrievedAllClients))
            }
            fetchChatPreviewData()
        }
    }, [initSet])

    const parseChatPreviews = (rawChatPreviews: any, allClients: any) => {
        let result = []
        // console.log(">>> parseChatPreviews", rawChatPreviews)

        for (let chatID in rawChatPreviews) {
            const chatPreviewData = rawChatPreviews[chatID]
            const {clientID, client_name, last_message, unread} = chatPreviewData

            if (clientID && clientID !== "") {
                result.push({
                    chatID: chatID,
                    clientID: clientID,
                    client_name: client_name,
                    last_message: last_message ?? { message: "", read_by: [], sent_by: "", timestamp: 0 },
                    unread: unread
                })
            }
        }

        

        console.log("result:", result)
        let orderedResult = result.sort((a, b) => b.last_message.timestamp - a.last_message.timestamp);

        let allClientKeys = Object.keys(allClients);
        for (var i = 0; i < allClientKeys.length; i++) {
            let thisClientID = allClientKeys[i];
            var userFound = false;
            for (var j = 0; j < orderedResult.length; j++) {
                let thisChatObj = orderedResult[j];
                let thisChatUserId = thisChatObj.clientID;
                if (thisChatUserId === thisClientID) userFound = true;
            }

            if (!userFound) {
                // User was not found in existing messages. Add them add the end of the messages list.
                let thisClientObject = allClients[thisClientID];
                orderedResult.push({
                    chatID: "",
                    clientID: thisClientID,
                    client_name: thisClientObject && thisClientObject.full_name ? thisClientObject.full_name : "Unnamed Client",
                    last_message: thisClientObject && thisClientObject.timestamp_added !== undefined ? thisClientObject.timestamp_added : 0,
                    unread: false
                });
            }
        }

        return orderedResult;
    }

    useEffect(() => {
        // console.log(`current selected Chat: ${selectedChat}`)
    }, [selectedChat]);

  return (
    <div className="messages-page-container">
        <PageHeader
            pageName="Messages"
            pageOptions={pageOptions}
            pageSelected={(e: string) => pageSelected(e)}
            workoutButtonPressed={ () => props.openClientInDynamicTrain("") }/>
        <div className="messages-content-container">
            <div className="messages-preview-container">
                <div className="messages-header">
                    <div className="messages-header-text-container">
                        <h1 className="messages-header-text">Messages</h1>
                    </div>
                </div>
                {/*<div className={ "messages-header-bottom" }>
                    <h4>All messages</h4>
                    <p>({ chatPreviews.length })</p>
                </div>*/}
                <div className="messages-container">
                    {
                        chatPreviews.map((preview: any, index: number) => (
                            <ChatPreview
                                userUID={ preview.clientID }
                                clientName={ preview.client_name }
                                message={
                                    preview.last_message.sent_by === preview.clientID ?
                                    `${preview.client_name}: ${preview.last_message.message}` :
                                    `You: ${preview.last_message.message}`
                                }
                                timestamp={ preview.last_message.timestamp }
                                unread={ preview.unread }
                                index={ index }
                                isSelected={ chatPreviews[selectedChat]?.clientID === preview.clientID }
                                setSelectedChat={ setSelectedChat }/>
                        ))
                    }
                </div>
            </div>
            <div className={ "messages-client-chat-container" }>
                <div className={ "messages-client-chat-container-inner" }>
                    <ClientChat
                        key={ [chatPreviews, selectedChat, value,chatPreviews[selectedChat]?.clientID] }
                        client={{ 
                            id: chatPreviews[selectedChat]?.clientID,
                            first_name: chatPreviews[selectedChat]?.client_name
                        }}
                        openScheduleMessages={ (c: string) => props.openScheduleMessages(c) }
                        renderedThroughMessages={ true }/>
                    </div>
                </div>

        </div>
    </div>

    )
}
export default Messages
