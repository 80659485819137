import React, { useState, useEffect, useRef } from 'react';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';

import axios from 'axios';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';

import './AssignModal.css';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import Folder_t from '../../Interfaces/Folder_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';

//import WorkoutPlanListPlanOverviewCardPlan from '../../pages/Dashboard/WorkoutWorkflow/WorkoutPlanListPages/WorkoutPlanListPlanOverviewCardPlan';
import CreateWorkoutExerciseListCell from '../Workouts/WorkoutBuilder/CreateWorkoutExerciseListCell';
import WorkoutPlanListPlanWorkout from '../../pages/Dashboard/WorkoutWorkflow/WorkoutPlanListPages/WorkoutPlanListPlanWorkout';
import AssignModalWorkoutCell from './AssignComponents/AssignModalWorkoutCell';
import AssignModalClientCell from '../Clients/AssignModalClientCell';
import CreateWorkoutExerciseConfigure from '../Modals/CreateWorkoutExerciseConfigure';
import DateSelector from '../Base/DateSelector';
import DateRangeSelector from '../Base/DateRangeSelector';
import CheckBox from '../Base/CheckBox';
import AssignModalClientFolders from './AssignComponents/AssignModalClientFolders';

import sendPushNotification from '../../api/notifications/push/sendPushNotification';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';

function AssignModal(props: any) {

    //const inputRef = useRef<any>(null);
    let initWorkout: Workout_t = {
                        title: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        exercises: [],
                        duration: 80,
                        imageName: '',
                        targetGender: '',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0
                    };

    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};
    let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];
    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    const [initSet, setInitSet] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [searchString, setSearchString] = useState("");
    const [showClientList, setShowClientList] = useState(false);
    const [clientNameFocus, setClientNameFocuse] = useState(false);
    const [defaultDurationFocus, setDefaultDurationFocus] = useState(false);
    const [clients, setClients] = useState<Client_t[]>([]);
    const [allClients, setAllClients] = useState<Client_t[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
    const [hardsetClient, setHardsetClient] = useState(false);
    const [defaultDate, setDefaultDate] = useState(0);
    const [selectedTimestamp, setSelectedTimestamp] = useState(0);

    const [workoutList, setWorkoutList] = useState<Workout_t[]>([]);
    const [workoutList_editted, setWorkoutList_editted] = useState<Workout_t[]>([]);

    const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>(initWorkout);
    const [selectedWorkoutOriginal, setSelectedWorkoutOriginal] = useState<Workout_t>(initWorkout);
    const [workoutDay, setWorkoutDay] = useState(0);
    const [exerciseSeries, setExerciseSeries] = useState<ExerciseSeries_t[]>([]);

    const [autoMoveAlongPlan, setAutoMoveAlongPlan] = useState(true);
    const [todayAdvanceAlert, setTodayAdvanceAlert] = useState(true);
    const [dailyAdvanceAlert, setDailyAdvanceAlert] = useState(false);
    const [weeklyAdvanceAlert, setWeeklyAdvanceAlert] = useState(false);
    const [hideFuturePhases, setHideFuturePhases] = useState(true);

    const [showEdit, setShowEdit] = useState(false);
    const [showingWorkout, setShowingWorkout] = useState(false);
    const [showNewExercise, setShowNewExercise] = useState(false);
    const [workoutHasBeenEdited, setWorkoutHasBeenEdited] = useState(false);
    const [addExerciseToBlockIndex, setAddExerciseToBlockIndex] = useState(-1);

    const [defaultDurationDays, setDefaultDurationDays] = useState(30);
    const [showSetDurationButton, setShowSetDurationButton] = useState(false);

    const [assigningingFolder, setAssigningingFolder] = useState(false);
    const [assigningingFolderShowEdit, setAssigningingFolderShowEdit] = useState(false);

    const [edittingCycleIndex, setEdittingCycleIndex] = useState(-1);
    const [edittingPhaseIndex, setEdittingPhaseIndex] = useState(-1);

    const [selectedFolder, setSelecteFolder] = useState<Folder_t>({
            title: "My Folder",
            id: "folder1",
            path: "folder1",
            type: "folder",
            children: [],
            isPlan: false
        });
    const [cycleList, setCycleList] = useState<Folder_t[]>([]);

    const [clientFolders, setClientFolders] = useState<Folder_t[]>([]);

    const [selectedClientFolder, setSelectedClientFolder] = useState<Folder_t | undefined>();

    const [savedPlanInfo, setSavedPlanInfo] = useState<any | undefined>(undefined);

    const [imgUrl, setImgUrl] = useState('');

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getPersonalClients();
        let dDate = Date.now(); //props.information !== undefined && props.information.folder !== undefined ? Date.now() : Date.now() + (30 * 24 * 60 * 60 * 1000);
        setDefaultDate(dDate);    // Set starting cal date to 30 days from today
        setSelectedTimestamp(dDate);

        // console.log("ASSING MODAL: props.information.plan:", props.information.plan);
        var workoutList: Workout_t[] = [];
        if (props.information.plan !== undefined && props.information.plan.workout_ids !== undefined) {
            for (var i = 0; i < props.information.plan.workout_ids.length; i++) {
                let thisWorkoutObj: Workout_t = JSON.parse(JSON.stringify(initWorkout));
                thisWorkoutObj["id"] = props.information.plan.workout_ids[i];
                // console.log("WORKOUT ID:", i, props.information.plan.workout_ids[i]);
                workoutList.push(thisWorkoutObj);
            }

            setAssigningingFolder(false);
        }

        if (props.information !== undefined && props.information.folder !== undefined) {
            // Folder is present in parent information
            setAssigningingFolder(true);
            setAssigningingFolderShowEdit(false);
            setShowEdit(true);
            setIsLoadingPlans(true);
            getProgramDetails(props.information.folder);
            
        }

        if (props.information !== undefined && props.information.client !== undefined) {
            // Client is present in parent information
            setSelectedClient(props.information.client);
            setHardsetClient(true);
            getImage(props.information.client.id);
            setSearchString("");
            pullClientFolders(props.information.client.id);

            setSelectedClientFolder(props.information.folder);
        }



        // console.log("WORKOUT LIST:", workoutList);
        setWorkoutList(workoutList);
        setWorkoutList_editted(workoutList);

    }

    useEffect(() => {
        // console.log("WORKOUT LIST UDPATED TO: ", workoutList);
    }, [workoutList]);

    useEffect(() => {
        // console.log("WORKOUT LIST [EDITED] UDPATED TO: ", workoutList_editted);
        if (edittingCycleIndex !== -1 && edittingPhaseIndex !== -1) {
            // console.log("------------ WILL SAVE ---------------");
            setCycleList(cycleList.map((cycleObj: Folder_t, indexCycle: number) => {
                var cycleTemp = cycleObj;
                cycleTemp.children.map((phaseObj: Folder_t, indexPhase: number) => {
                    var phaseTemp = phaseObj;

                    if (edittingCycleIndex === indexCycle && edittingPhaseIndex === indexPhase) {
                        phaseTemp.workouts = workoutList_editted;
                    }
                    
                    return phaseTemp;
                })
                return cycleTemp;
            }))            
        }
    }, [workoutList_editted]);

    function getPersonalClients() {
        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }


        /*************************************************/
        //setIsLoading(true);    // TODO; uncomment

        let testClient: Client_t = {
            first_name:"Connor", 
            last_name:"Holowachuk", 
            full_name:"Connor Holowachuk", 
            id: "abc123", 
            gender: "m",
            email: "connor_chuk@yahoo.com",
            weight: 155,
            height: 72,
            isMetric: false,
            birthdate: "00-00-0000",
            tags: [goalDescriptors[0], "Gain Muscle"],
            isSelected: true
        };

        //setSelectedClient(testClient);



        /*************************************************/



        // console.log("CLIENT LIST | getPersonalClients()");

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_clients/${thisUser.uid}/clients`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let clientsList = snapshot.val();
                let clientListLength = Object.keys(clientsList).length;
                let temp: Client_t[] = [];
                for (const key of Object.keys(clientsList)) {
                    let thisClient = clientsList[key];

                    let thisClientRef = database.ref(`user_profile/${key}`)
                    thisClientRef.on('value', function(snapshotClient) {
                        if (snapshotClient.exists() === true) {
                            let thisUser = snapshotClient.val();
                            let newClient: Client_t = {
                                first_name:thisUser.first_name, 
                                last_name:thisUser.last_name, 
                                full_name:thisUser.full_name, 
                                id: key, 
                                gender: thisUser.gender,
                                email: thisUser.email,
                                weight: thisUser.weight,
                                height: thisUser.height,
                                isMetric: thisUser.units_kg,
                                birthdate: thisUser.birth_date,
                                tags: [goalDescriptors[0], "Gain Muscle"],
                                isSelected: false
                            };
                            temp.push(newClient);

                            if (temp.length === clientListLength) {
                                // all workouts have been pulled - set the state vars to temp
                                setClients(clients.concat(temp));
                                setAllClients(allClients.concat(temp));
                                setIsLoading(false);

                            }
                        }
                    });

                }

            } else {
                //setClientsBlank(true);
                setIsLoading(false);
            }
        });
    }

    async function getProgramDetails(forFolder: Folder_t) {
        // console.log("FOLDER:", forFolder);

        var folderFinal: Folder_t = forFolder;

        await Promise.all(forFolder.children.map(async (thisCycle: Folder_t, index: number) => {
            // Loop through folder's cycles
            const cycleData: Folder_t = await getCycleDetails(thisCycle);
            folderFinal.children[index] = cycleData;
        }));

        // console.log("DONE WITH FOLDER FINAL:", folderFinal);
        setCycleList(folderFinal.children);
        setIsLoadingPlans(false);
        configurePhaseDateRanges(Date.now());
    }

    async function getCycleDetails(forCycle: Folder_t) {

        var finalCycle: Folder_t = forCycle; 

        await Promise.all(forCycle.children.map(async (thisPlan: Folder_t, index: number) => {
            // Loop through folder's cycles
            const planWorkoutList: string[] = thisPlan.plan_id === undefined ? [] : await getPlanDetails(thisPlan.plan_id);
            // console.log("Plan workout ID list: ", thisPlan, thisPlan.id, thisPlan.title, index, planWorkoutList);

            var workoutListTemp: Workout_t[] = [];

            for (var i = 0; i < planWorkoutList.length; i++) {
                let thisWorkoutID: string = planWorkoutList[i];

                // Get raw workout data from database
                let thisWorkoutObj: any = await getWorkoutInfo(thisWorkoutID);

                // Parse raw data into Workout_t
                let thisWorkout: Workout_t = await parseWorkout(thisWorkoutObj);

                workoutListTemp.push(thisWorkout);
            }

            finalCycle.children[index].workouts = workoutListTemp;

            
        }));

        return finalCycle;
    }

    async function getPlanDetails(forPlanID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();
            let ref = database.ref(`workout_plans/plans/${forPlanID}/workouts`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let workoutIDsList: string[] = snapshot.val();
                    resolve(workoutIDsList);

                } else {
                    resolve([]);
                }
            });
        });
    }

    async function getWorkoutInfo(workoutID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();

            let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
            thisWorkoutRef.on('value', function(snapshotWorkout) {
                if (snapshotWorkout.exists() === true) {
                    let thisWorkout = snapshotWorkout.val();
                    thisWorkout["id"] = workoutID;
                    resolve(thisWorkout);
                }
            });
        });
    }

    async function parseWorkout(thisWorkout: any) {

        if (thisWorkout.id === "") {
            return {
                title: "Rest Day",
                description: '',
                id: "",
                createdBy: {name: "", id: ""},
                exercises: [],
                exerciseSeries: [],
                duration: 0,
                imageName: "",
                targetGender: "",
                totalSets: 0,
                totalReps: 0,
                difficulty: 0,
                goals: [],
                downloads: 0,
                timesCompleted: 0
            };
        }

        let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

        // Configure Exercise Series
        var seriesList_temp: ExerciseSeries_t[] = [];
        let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
        if (containsExerciseSeries === true) {
            for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                let thisSeriesObj = thisWorkout.exercise_series[i];
                let thisSetLetter = getSetLetter(i);

                var exerciseList_temp: Exercise_t[] = [];
                if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                    for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                        let thisExerciseObj = thisSeriesObj.exercises[j];
                        let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                        exerciseList_temp.push(exTemp);
                    }
                }

                var seriesTemp: ExerciseSeries_t = {
                    title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                    index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                    type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                    exercises: exerciseList_temp
                };

                seriesList_temp.push(seriesTemp);
            }
        } else {
            // Workout was created "pre-series". Create a new placeholder series for each existing exercise
            if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    let thisExerciseTemp: any = thisWorkout.exercises[i];
                    let thisSetLetter = getSetLetter(i);
                    var seriesTemp: ExerciseSeries_t = {
                        title: thisSetLetter,
                        index: i,
                        type: 0,
                        exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                    };
                    seriesList_temp.push(seriesTemp);
                }
            }
        }

        var exerciseList: Exercise_t[] = [];
        if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

            var reps_avg = 0.0;
            var reps_avg_count = 0;
            var exerciseReps: any = {};
            for (var i = 0; i < thisWorkout.exercises.length; i++) {
                var a: number[] = [];
                if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                    for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                        let thisRep = thisWorkout.exercises[i].reps[j];
                        a.push(thisRep);
                        reps_avg += thisRep;
                        reps_avg_count += 1;
                    }
                }
                exerciseReps[thisWorkout.exercises[i].key] = a;

                if (i === 0) {
                    //setRepScheme(a);
                }
            }

            //setAvgReps(reps_avg / reps_avg_count);

            var exerciseTempos: any = {};
            for (var i = 0; i < thisWorkout.exercises.length; i++) {
                var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                    for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                        let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                        let thisTempo: Tempo_t = {
                            concentric: thisTempoObj.concentric,
                            eccentric: thisTempoObj.eccentric,
                            first_pause: thisTempoObj.first_pause,
                            second_pause: thisTempoObj.second_pause
                        };
                        b.push(thisTempo);
                    }
                }
                exerciseTempos[thisWorkout.exercises[i].key] = b;
            }

            let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
            exerciseList = e;


            // push exercises into according slots in seriesList_temp
            if (exerciseList.length > 0) {
                for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                    let thisExercise: Exercise_t = exerciseList[ex_index];
                    let thisExercise_ID = thisExercise.id;

                    // Loop through seriesList_temp to find thisExercise's matching location
                    if (seriesList_temp.length > 0) {
                        for (var i = 0; i < seriesList_temp.length; i++) {
                            let thisSeries = seriesList_temp[i];
                            if (thisSeries.exercises.length > 0) {
                                for (var j = 0; j < thisSeries.exercises.length; j++) {
                                    let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                    let thisSeriesExercise_ID = thisSeriesExercise.id;
                                    if (thisSeriesExercise_ID === thisExercise_ID) {
                                        // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                        // for the case of multiple of the same exercises in a workout
                                        if (thisSeriesExercise.title === '') {
                                            seriesList_temp[i].exercises[j] = thisExercise;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
        let newWorkout: Workout_t = {
            title: thisWorkout.title,
            description: thisWorkout.description !== undefined ? thisWorkout.description: '',
            id: thisWorkout.id,
            createdBy: createdBy,
            exercises: exerciseList,
            exerciseSeries: seriesList_temp,
            duration: Number(thisWorkout.duration),
            imageName: thisWorkout.image_name,
            targetGender: thisWorkout.target_gender,
            totalSets: Number(thisWorkout.total_sets),
            totalReps: Number(thisWorkout.total_reps),
            difficulty: Number(thisWorkout.difficulty),
            goals: thisWorkout.goals,
            downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
            timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
        };
        
        return newWorkout;
    }


    async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: [thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }





    function inputChanged(toString: any, forInput: string) {
        switch (forInput) {
            case 'client-name':
                setSearchString(toString);
                var temp = [];
                for (var i = 0; i < allClients.length; i++) {
                    let searchString = toString.toUpperCase();
                    let nameString = allClients[i].full_name.toUpperCase();
                    let positiveSearch_name = nameString.includes(searchString);

                    var positiveSearch_email = false;
                    let emailString = allClients[i].email;
                    if (emailString != undefined && emailString != null) {

                    }

                    if (positiveSearch_name === true) {
                        temp.push(allClients[i]);
                    }
                }

                setClients(temp);
                if (toString.split('').length > 2) {
                    if (showClientList === false) {
                        setShowClientList(true);
                    }
                }
                break;
            case 'default-duration':
                if (!isNaN(toString)) {
                    setDefaultDurationDays(toString as number);
                    updateDateRangeByDuration(toString as number);
                    setShowSetDurationButton(false);
                }
                break;
            default:
                break;
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            searchButtonPressed()
        }
    }

    function configurePhaseDateRanges(dDate: number) {

        const defaultDurationMS = defaultDurationDays * 3600 * 1000 * 24;
        var folderTemp: Folder_t = props.information.folder;
        var newStartTimestamp = dDate;
        if (folderTemp.children.length > 0) {
            // loop through cycles
            for (var i = 0; i < folderTemp.children.length; i++) {
                let thisChild: Folder_t = folderTemp.children[i];
                if (thisChild.children.length > 0) {
                    for (var j = 0; j < thisChild.children.length; j++) {
                        //let thisPhase: Folder_t = thisChild.children[j];
                        if (folderTemp.children[i].children[j].plan_id !== undefined && folderTemp.children[i].children[j].plan_id !== "" && folderTemp.children[i].children[j].workouts !== undefined && folderTemp.children[i].children[j].workouts!.length !== 0) {
                            let thisEndingTS = newStartTimestamp + defaultDurationMS;
                            folderTemp.children[i].children[j].starting_timestamp = newStartTimestamp;
                            folderTemp.children[i].children[j].ending_timestamp = thisEndingTS;
                            newStartTimestamp = thisEndingTS + (3600 * 1000 * 24);
                        } else {
                            folderTemp.children[i].children[j].starting_timestamp = newStartTimestamp;
                            folderTemp.children[i].children[j].ending_timestamp = newStartTimestamp;
                        }
                        
                    }
                }
            }
        }
        setSelecteFolder(folderTemp);
        setCycleList(folderTemp.children);
    }

    function targetDateChanged(toTS: number) {
        setSelectedTimestamp(toTS);

        // Move program accordingly
        var newStartTimestamp = toTS;
        const defaultDurationMS = defaultDurationDays * 3600 * 1000 * 24;
        setCycleList(cycleList.map((cycleObj: Folder_t, indexCycle: number) => {
            var cycleTemp = cycleObj;
            cycleTemp.children.map((phaseObj: Folder_t, indexPhase: number) => {
                var phaseTemp = phaseObj;
                let thisDuration = phaseTemp.ending_timestamp === undefined || phaseTemp.starting_timestamp === undefined ? defaultDurationMS : phaseTemp.ending_timestamp - phaseTemp.starting_timestamp;

                if (indexPhase === 0 && indexCycle === 0) {
                    phaseTemp.starting_timestamp = toTS;
                    phaseTemp.ending_timestamp = toTS + thisDuration;
                    newStartTimestamp = toTS + thisDuration + (3600 * 1000 * 24);
                } else {
                    phaseTemp.starting_timestamp = newStartTimestamp;
                    phaseTemp.ending_timestamp = newStartTimestamp + thisDuration;
                    newStartTimestamp = newStartTimestamp + thisDuration + (3600 * 1000 * 24);
                }
                
                return phaseTemp;
            })
            return cycleTemp;
        }))

        // setGoal({...goal, targetTimestamp: toTS});

        // if (useActions === true && goal.actions !== undefined && goal.actions.length > 0) {
        //     // update timestamps
        //     let layerA = goal.actions.length;

        //     let durationMs = toTS - Date.now();
        //     let durationDays = durationMs / (1000 * 60 * 60 * 24);
        //     setTotalDurationDays(Math.round(durationDays));

        //     let layerADuration = Math.round(durationDays / layerA);
            
        //     var updatedActions: GoalActions_t[] = [];

        //     for (var i = 0; i < layerA; i++) {
        //         let thisAction: GoalActions_t = goal.actions[i];
        //         thisAction.durationDays = layerADuration;

        //         var updatedSubActions: GoalActions_t[] = [];
        //         if (thisAction.subActions !== undefined && thisAction.subActions.length > 0) {
        //             let layerB = thisAction.subActions.length;
        //             let layerBDuration = Math.round(layerADuration / layerB);

        //             for (var j = 0; j < layerB; j++) {
        //                 let thisSubLayer = thisAction.subActions[j];
        //                 thisSubLayer.durationDays = layerBDuration;
        //                 updatedSubActions.push(thisSubLayer);
        //             }
        //         }

        //         thisAction.subActions = updatedSubActions;
        //         updatedActions.push(thisAction);
        //     }

        //     setGoal({...goal, actions: updatedActions, targetTimestamp: toTS});
        // }
    }

    function getDurationDays() {
        let todayDateTime = Date.now();
        let deltaTime = selectedTimestamp - todayDateTime;
        let deltaDays = Math.ceil(deltaTime / (1000 * 60 * 60 * 24));

        //if (assigningingFolder) {
            if (deltaDays === 0) {
                return 'Today';
            } else if (deltaDays > 0) {
                return `${deltaDays} day${deltaDays === 1 ? '' : 's'}`
            } else {
                return `${-1 * deltaDays} day${deltaDays === -1 ? '' : 's'} ago`
            }
            
        //}

        return deltaDays;
    }

    function searchButtonPressed() {

    }

    function clientSelected(c: Client_t) {
        setShowClientList(false);
        setSelectedClient(c);
        getImage(c.id);
        setSearchString("");
        pullClientFolders(c.id);
    }

    function pullClientFolders(clientID: string) {
        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/folders`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let dataKeys = Object.keys(data);


                // console.log("FOLDERS:", data, "KEYS:", dataKeys);

                var folderListTemp: Folder_t[] = [];
                for (var i = 0; i < dataKeys.length; i++) {
                    let thisKey = dataKeys[i];
                    let thisFolderObj = data[thisKey];
                    // console.log("this folder object:", thisFolderObj, thisKey);
                    let newFolder: Folder_t | null = parseFolderObj(thisFolderObj, thisKey);
                    if (newFolder !== null) {
                        folderListTemp.push(newFolder);
                    }


                }

                // console.log("FOLDER LIST:", folderListTemp)
                setClientFolders(folderListTemp);
            }
        });
    }

    function parseFolderObj(folderObj: any, folderID: string) {

        //if (folderObj.type === undefined || folderObj.type !== "folder") { return null; }

        var childrenList: Folder_t[] = [];

        var childIsCycle = false;
        if (folderObj.children !== undefined) {
            let childrenObjList = folderObj.children;
            let dataKeys = Object.keys(childrenObjList);
            for (var i = 0; i < dataKeys.length; i++) {
                let thisKey = dataKeys[i];
                let thisFolderObj = childrenObjList[thisKey];
                let thisChildFolder: Folder_t | null = parseFolderObj(thisFolderObj, thisKey);
                if (thisChildFolder !== null) {
                    childrenList.push(thisChildFolder);
                } else {
                    childIsCycle = true;
                }
                
            }
        }

        let newFolder: Folder_t = {
            id: folderID,
            title: folderObj.name === undefined ? "" : folderObj.name,
            type: folderObj.type === undefined ? "" : folderObj.type,
            path: folderObj.path === undefined ? "" : folderObj.path,
            children: childrenList,
            isPlan: false
        }

        return newFolder;
    }

    function removeClient() {
        setSelectedClient(initClient);
    }

    function getImage(forID: string) {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function getFormattedDate(ts: number) {
        let thisDate = new Date(ts);
        let thisDay = thisDate.getDate();
        let thisMo = moStrings[thisDate.getMonth()];
        let thisYr = thisDate.getFullYear();

        return `${thisMo} ${thisDay}, ${thisYr}`;
    }

    function toggleShowEdit() {
        setShowEdit(!showEdit);
    }

    function workoutDataDownloaded(w: Workout_t, forIndex: number) {
        setWorkoutList(workoutList.map((item: Workout_t, index: number) => {
            return index === forIndex ? w : item;
        }));
        setWorkoutList_editted(workoutList_editted.map((item: Workout_t, index: number) => {
            return index === forIndex ? w : item;
        }));
        // setWorkoutList_editted(workoutList_editted.map((item: Workout_t, index: number) => {
        //     return index === forIndex ? w : item;
        // }));
    }

    function workoutSelected(w: Workout_t, day: number) {
        setSelectedWorkout(w);
        setSelectedWorkoutOriginal(w);
        setShowingWorkout(true);
        setWorkoutHasBeenEdited(false);
        setWorkoutDay(day);
        setExerciseSeries(w.exerciseSeries === undefined ? [] : w.exerciseSeries);
    }

    /**
     *    Save Workout
     *    @brief     Saves changes to workout by copying the exerciseSeries array to the respective Workout object
     * 
     */
    function saveWorkout() {
        //setIsSaving(true);
        // console.log("SERIES:", exerciseSeries);
        // console.log("WORKOUTS:", workoutList_editted);

        setWorkoutList_editted(workoutList_editted.map((item: Workout_t, index: number) => {
            if (item.id === selectedWorkout.id) {
                var workoutTemp = JSON.parse(JSON.stringify(item));
                workoutTemp['exerciseSeries'] = exerciseSeries;
                // console.log(`SETTING WORKOUT AT INDEX -${index}- TO:`, workoutTemp, item);
                return workoutTemp;
            } else {
                return item;
            }
        }));

        setShowingWorkout(false);

        /*
        var composedSetInfo: any[] = [];

        for (var i = 0; i < exerciseSeries.length; i++) {
            let thisExerciseSeries = exerciseSeries[i];
            if (thisExerciseSeries.exercises !== undefined) {
                for (var j = 0; j < thisExerciseSeries.exercises.length; j++) {
                    let thisExercise = thisExerciseSeries.exercises[j];

                    var setInfoList: any[] = [];
                    if (thisExercise.reps !== undefined) {
                        for (var setIndex = 0; setIndex < thisExercise.reps.length; setIndex++) {
                            let thisSetObj: any = {
                                set_index: setIndex,
                                reps: thisExercise.reps[setIndex],
                                pwm: thisExercise.percent_wms !== undefined && thisExercise.percent_wms[setIndex] !== undefined ? thisExercise.percent_wms[setIndex] : -1,
                                tempo: thisExercise.tempos !== undefined && thisExercise.tempos[setIndex] !== undefined ? thisExercise.tempos[setIndex] : {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1},
                                rest: thisExercise.restTime !== undefined && thisExercise.restTime[setIndex] !== undefined ? thisExercise.restTime[setIndex] : -1,
                            }
                            setInfoList.push(thisSetObj);
                        }
                    }

                    let thisSetObj: any = {
                        exercise_id: thisExercise.id,
                        set_info: setInfoList
                    }
                    composedSetInfo.push(thisSetObj);
                }
            }
        }

        // console.log("FORMATTED SERIES DATA:", composedSetInfo);
        */
    }

    function getSeries() {
        return selectedWorkout.exerciseSeries !== undefined ? selectedWorkout.exerciseSeries : [];
    }

    function switchIsShowing(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        if (thisExercise.id === forExercise.id) {
                            thisExercise.isShowing = thisExercise.isShowing === undefined ? true : !thisExercise.isShowing;
                        } else {
                            thisExercise.isShowing = false;
                        }
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        thisExercise.isShowing = false;
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                }
            })
        );
    }

    function removeExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                
                if (item.index === forExerciseSeries.index) {
                    var seriesTemp = item;
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < seriesTemp.exercises.length; i++) {
                        let thisExercise: Exercise_t = seriesTemp.exercises[i];
                        if (thisExercise.id !== forExercise.id) {
                            tempExerciseArray.push(thisExercise);
                        }
                    }
                    seriesTemp.exercises = tempExerciseArray;
                    return seriesTemp;
                } else {
                    return item;
                }
            }
        ));
    }

    function removeExerciseSeries(forExerciseSeries: ExerciseSeries_t) {
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.filter((item: ExerciseSeries_t, index: number) => {
                
                if (item.index === forExerciseSeries.index) {
                    return false;
                } else {
                    return true;
                }
            }
        ));
    }

    function editedSetContent(exercise: Exercise_t, setIndex: number, toValue: any, reason: string, forExerciseSeries: ExerciseSeries_t) {
        // console.log("editedReps() ->", exercise, setIndex, toValue, `REASON: ${reason}`, forExerciseSeries);
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise: Exercise_t = item.exercises[i];
                        if (thisExercise.id === exercise.id) {
                            switch (reason) {
                                case 'reps':
                                    let repsTemp = thisExercise.reps;
                                    if (repsTemp !== undefined) {
                                        repsTemp![setIndex] = Number(toValue);
                                        // console.log("Adjusting reps to: ", repsTemp, "  From:", thisExercise.reps);
                                        thisExercise.reps = repsTemp;
                                    }
                                    break;
                                case 'pwm':
                                    let pwmsTemp = thisExercise.percent_wms;
                                    if (pwmsTemp !== undefined) {
                                        pwmsTemp![setIndex] = Number(toValue);
                                        // console.log("Adjusting PWMs to: ", pwmsTemp, "  From:", thisExercise.percent_wms);
                                        thisExercise.percent_wms = pwmsTemp;
                                    } else if (thisExercise.reps !== undefined) {
                                        pwmsTemp = [];
                                        for (var i = 0; i < thisExercise.reps!.length; i++) {
                                            pwmsTemp.push(i === setIndex ? Number(toValue) : 0);
                                        }
                                        thisExercise.percent_wms = pwmsTemp;
                                    }
                                    break;
                                case 'tempo':
                                    let temposTemp = thisExercise.tempos;
                                    if (temposTemp !== undefined) {
                                        temposTemp![setIndex] = toValue as Tempo_t;
                                        // console.log("Adjusting PWMs to: ", temposTemp, "  From:", thisExercise.tempos);
                                        thisExercise.tempos = temposTemp;
                                    } 
                                    break;
                                case 'rest':
                                    let restTemp = thisExercise.restTime;
                                    if (restTemp !== undefined) {
                                        restTemp![setIndex] = Number(toValue);
                                        // console.log("Adjusting PWMs to: ", restTemp, "  From:", thisExercise.restTime);
                                        thisExercise.restTime = restTemp;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            
                            
                        } 
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    return item;
                }
            })
        );
    }

    function addSetPressed(exercise: Exercise_t, forExerciseSeries: ExerciseSeries_t) {
        setWorkoutHasBeenEdited(true);

        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise: Exercise_t = item.exercises[i];
                        if (thisExercise.id === exercise.id) {

                            // REPS
                            let repsTemp = thisExercise.reps;
                            if (repsTemp !== undefined && repsTemp.length > 0) {
                                thisExercise.reps!.push(thisExercise.reps![thisExercise.reps!.length - 1]);
                            } else {
                                thisExercise.reps = [12];
                            }

                            // PWMs
                            let pwmsTemp = thisExercise.percent_wms;
                            if (pwmsTemp !== undefined && pwmsTemp.length !== 0) {
                                thisExercise.percent_wms!.push(pwmsTemp[pwmsTemp.length - 1]);
                            } else {
                                pwmsTemp = [0];
                            }

                            // Tempo
                            let temposTemp = thisExercise.tempos;
                            if (temposTemp !== undefined && temposTemp.length !== 0) {
                                thisExercise.tempos!.push(temposTemp[temposTemp.length - 1]);
                            } else {
                                if (repsTemp !== undefined && repsTemp.length > 0) {
                                    for (var i = 0; i < repsTemp.length; i++) {
                                        thisExercise.tempos!.push({concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1});
                                    }
                                } else {
                                    thisExercise.tempos = [{concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1}];
                                }
                                
                            }

                            // Rest time
                            let restTemp = thisExercise.restTime;
                            if (restTemp !== undefined && restTemp.length !== 0) {
                                thisExercise.restTime!.push(restTemp[restTemp.length - 1]);
                            } else {
                                thisExercise.restTime = [-1];
                            }

                        } 
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    return item;
                }
            })
        );
    }

    function removeSetPressed(exercise: Exercise_t, setIndex: number, forExerciseSeries: ExerciseSeries_t) {
        setWorkoutHasBeenEdited(true);
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise: Exercise_t = item.exercises[i];
                        if (thisExercise.id === exercise.id) {

                            // REPS
                            let repsTemp = thisExercise.reps;
                            if (repsTemp !== undefined) {
                                repsTemp!.splice(setIndex,1);
                                thisExercise.reps = repsTemp;
                            }

                            // PWMs
                            let pwmsTemp = thisExercise.percent_wms;
                            if (pwmsTemp !== undefined) {
                                pwmsTemp!.splice(setIndex,1);
                                thisExercise.percent_wms = pwmsTemp;
                            } else if (thisExercise.reps !== undefined) {
                                pwmsTemp = [];
                                for (var i = 0; i < thisExercise.reps!.length - 1; i++) {
                                    pwmsTemp.push(0);
                                }
                                thisExercise.percent_wms = pwmsTemp;
                            }

                            // Tempo
                            let temposTemp = thisExercise.tempos;
                            if (temposTemp !== undefined) {
                                temposTemp!.splice(setIndex,1);
                                thisExercise.tempos = temposTemp;
                            } 

                            // Rest time
                            let restTemp = thisExercise.restTime;
                            if (restTemp !== undefined) {
                                restTemp!.splice(setIndex,1);
                                thisExercise.restTime = restTemp;
                            }

                        } 
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    return item;
                }
            })
        );
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function exerciseConfigureCallback(blockIndex: number, data: any, planInfo?: any) {
        // console.log("RECIEVED: ", planInfo, data, blockIndex, addExerciseToBlockIndex)
        if (planInfo) setSavedPlanInfo(planInfo)
        addNewExerciseToSelectedWorkout(data, blockIndex);
        
    }

    function addNewBlock() {
        setAddExerciseToBlockIndex(-1);
        // setShowNewExercise(true);
        let thisPlanID = props.information === undefined || props.information.plan === undefined || props.information.plan.id === undefined ? "" : props.information.plan.id;
        
        let thisPlanInfo = savedPlanInfo ? savedPlanInfo : ( props.information === undefined === undefined ? undefined : props.information )
        props.showExerciseModal(exerciseConfigureCallback, -1, thisPlanInfo)
    }

    function addToExistingBlock(blockIndex: number) {
        // console.log("addToExistingBlock with index:", blockIndex)
        setAddExerciseToBlockIndex(blockIndex);
        //setShowNewExercise(true);
        let thisPlanInfo = savedPlanInfo ? savedPlanInfo : ( props.information === undefined === undefined ? undefined : props.information )
        props.showExerciseModal(exerciseConfigureCallback, blockIndex, thisPlanInfo)
    }

    function addNewExerciseToSelectedWorkout(exercise?: Exercise_t, blockIndex?: number) {
        setShowNewExercise(false);
        if (exercise !== undefined) {
            setWorkoutHasBeenEdited(true);
            // console.log("--addNewExerciseToSelectedWorkout: ", blockIndex)
            if (blockIndex === undefined || blockIndex < 0) {
                // Adding a new block
                let thisSeriesIndex = exerciseSeries.length;
                var newExerciseSeries: ExerciseSeries_t = {
                    index: thisSeriesIndex,
                    title: getSetLetter(thisSeriesIndex),
                    exercises: [exercise]
                };
                setExerciseSeries(exerciseSeries.concat(newExerciseSeries));
            } else {
                // Adding to block at specified index
                let biTemp = blockIndex ?? 0;
                setExerciseSeries(exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                    if (index === biTemp) {
                        let seriesTemp = item;
                        seriesTemp.exercises.push(exercise);
                        return seriesTemp;
                    } else {
                        return item;
                    }
                }));
            }
        }
    }

    function submit() {

        if (hardsetClient === true) {
            // Setting a program in client's folders to their current program - no need to select folder location
            
            if (assigningingFolder) {
                submitFolder();
                //submitFolder();
            } else {
                submitSinglePhase();
            }
        } else {
            if (assigningingFolder) {
                setWorkflowState(5);
                //submitFolder();
            } else {
                submitSinglePhase();
            }
        }

        
    }

    /**
     * Submit & Assign Folder
     * Assigns Folder and it's contents to a client
     * 
     */
    async function submitFolder() {

        setIsLoading(true);

        // 1. Place folder contents in Client's folder structure
        // 1.a) Generate path in Client's folder structure
        let addingToFolderLocation = selectedClientFolder === undefined ? "/" : selectedClientFolder.path;

        let seperatedPath: string[] = addingToFolderLocation.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);

        // 1.b) Add object to Client's folder structure
        var masterFolderKey: null | string = ""

        let masterFolderDBPath = `workout_plans/client_assigned_plans/${selectedClient.id}/folders${composedMasterPath_DB}`;
        let database = firebase.database();
        let masterFolderRef = database.ref(masterFolderDBPath);

        if (!hardsetClient || selectedClientFolder === undefined) {
            let masterFolderRefObj = await masterFolderRef.push({
                name: selectedFolder.title,
                template_id: selectedFolder.id,
                template_path: selectedFolder.path,
                type: "folder"
            });

            masterFolderKey = masterFolderRefObj.key;
            if (masterFolderKey === null) {
                return;
            }
            await masterFolderRef.child(masterFolderKey).child("path").set(`${addingToFolderLocation}/${masterFolderKey}`);

        } else {
            masterFolderKey = "";
            if (masterFolderKey === null) {
                return;
            }
        }
        

        

        // console.log("FOLDER PUSH KEY: ", masterFolderKey);

        

        

        var startingTS: number = defaultDate;
        var endingTS: number = 0;
        var startingPath = "";

        var cycleObjsList: any[] = [];

        for (var cycleIndex = 0; cycleIndex < cycleList.length; cycleIndex++) {
            let thisCycle: Folder_t = cycleList[cycleIndex];

            let containingPath = `${addingToFolderLocation}/${masterFolderKey}`;

            let thisCycleObj: any = {
                name: thisCycle.title,
                template_id: thisCycle.id,
                template_path: thisCycle.path,
                type: "cycle"
            };

            var thisCycleKey: string | null = "";
            if (!hardsetClient || selectedClientFolder === undefined) {
                let cycleFolderRefObj = await masterFolderRef.child(masterFolderKey).child("children").push(thisCycleObj);
                thisCycleKey = cycleFolderRefObj.key;
            } else {
                thisCycleKey = thisCycle.id;
            }


            if (thisCycleKey === null) {
                return;
            }

            if (!hardsetClient || selectedClientFolder === undefined) {
                await masterFolderRef.child(masterFolderKey).child("children").child(thisCycleKey).child("path").set(`${addingToFolderLocation}/${masterFolderKey}/${thisCycleKey}`);
                await masterFolderRef.child(masterFolderKey).child("children").child(thisCycleKey).child("id").set(thisCycleKey);
            }

            var phaseObjsList: any[] = [];
            for (var phaseIndex = 0; phaseIndex < thisCycle.children.length; phaseIndex++) {
                let thisPhase: Folder_t = thisCycle.children[phaseIndex];
                let thisPhaseWorkoutList: Workout_t[] = thisPhase.workouts === undefined ? [] : thisPhase.workouts;

                if (thisPhase.plan_id !== undefined && thisPhase.plan_id !== "") {
                    let phaseWorkoutList = getPlanWorkoutListFormatted(thisPhaseWorkoutList);

                    let phaseObj: any = {
                        name: thisPhase.title,
                        type: "plan",
                        phase_name: thisPhase.phaseName === undefined ? "" : thisPhase.phaseName,
                        template_id: thisPhase.id,
                        template_plan_id: thisPhase.plan_id,
                        template_location: thisPhase.path,
                        starting_timestamp: thisPhase.starting_timestamp === undefined ? -1 : thisPhase.starting_timestamp,
                        ending_timestamp: thisPhase.ending_timestamp === undefined ? -1 : thisPhase.ending_timestamp,
                        workouts: phaseWorkoutList
                    };

                    var thisPhaseKey: string | null = "";
                    if (!hardsetClient || selectedClientFolder === undefined) {
                        let phaseFolderRefObj = await masterFolderRef.child(masterFolderKey).child("children").child(thisCycleKey).child("children").push(phaseObj);
                        thisPhaseKey = phaseFolderRefObj.key;
                    } else {
                        thisPhaseKey = thisPhase.id;
                    }

                    if (thisPhaseKey === null) { return; }

                    let thisPhasePath = `${addingToFolderLocation}${masterFolderKey === "" ? "" : '/'}${masterFolderKey}/${thisCycleKey}/${thisPhaseKey}`;
                    if (!hardsetClient || selectedClientFolder === undefined) {
                        await masterFolderRef.child(masterFolderKey).child("children").child(thisCycleKey).child("children").child(thisPhaseKey).child("path").set(thisPhasePath);
                        await masterFolderRef.child(masterFolderKey).child("children").child(thisCycleKey).child("children").child(thisPhaseKey).child("id").set(thisPhaseKey);
                    }

                    if (phaseIndex === 0 && cycleIndex === 0) {
                        startingPath = thisPhasePath;
                    }
                    // Check if last phase to set ending TS
                    endingTS = thisPhase.ending_timestamp === undefined ? -1 : thisPhase.ending_timestamp;

                    phaseObj["key"] = thisPhaseKey;
                    phaseObj["path"] = thisPhasePath;
                    phaseObjsList.push(phaseObj);
                }
            }

            thisCycleObj["phases"] = phaseObjsList;
            thisCycleObj["key"] = thisCycleKey;
            thisCycleObj["path"] = `${containingPath}/thisCycleKey`;

            cycleObjsList.push(thisCycleObj);
        }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;
        
        let activeProgramoObj: any = {
            current_path: startingPath,
            master_path: `${addingToFolderLocation}${masterFolderKey === "" ? "" : '/'}${masterFolderKey}`,
            assigned_by: trainerUID,
            starting_timestamp: startingTS,
            ending_timestamp: endingTS,
            assigned_on: Date.now()
        }

        await database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/active_program`).set(activeProgramoObj);


        // Configure schedule

        var cumulativeTimestamp = defaultDate;
        let oneDayMS = 1000 * 3600 * 24;

        let scheduleRef = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/schedule`);

        // console.log("cycleObjsList = ", cycleObjsList);

        var addingDayCount = 1;

        for (var i = 0; i < cycleObjsList.length; i++) {
            let thisCycle = cycleObjsList[i];
            
            if (thisCycle.phases !== undefined && thisCycle.phases.length > 0) {
                //var cycleStartingTime = thisCycle.phases[0] === undefined || thisCycle.phases[0].starting_timestamp ? 0 : thisCycle.phases[0].starting_timestamp;
                for (var j = 0; j < thisCycle.phases.length; j++) {
                    let thisPhase = thisCycle.phases[j];

                    let thisPhaseStartTS = thisPhase.starting_timestamp === undefined ? defaultDate : thisPhase.starting_timestamp;
                    let thisPhaseEndTS = thisPhase.ending_timestamp === undefined ? defaultDate + (30 * oneDayMS) : thisPhase.ending_timestamp;

                    cumulativeTimestamp = thisPhaseStartTS;

                    if (thisPhase.workouts !== undefined) {
                        var k = 0;
                        while (cumulativeTimestamp < thisPhaseEndTS) {
                            let thisWorkout = thisPhase.workouts[k];

                            let thisWorkoutObj: any = {
                                path: thisPhase.path,
                                timestamp: cumulativeTimestamp,
                                workout_id: thisWorkout.workout_id === undefined ? "" : thisWorkout.workout_id,
                                cycle: {
                                    id: thisCycle.key,
                                    name: thisCycle.name,
                                    path: thisCycle.path,
                                    index: i
                                },
                                phase: {
                                    id: thisPhase.key,
                                    name: thisPhase.name,
                                    path: thisPhase.path,
                                    index: j
                                }
                            }

                            let newDate = new Date(cumulativeTimestamp);
                            let newDay = newDate.getDate();
                            let newMo = newDate.getMonth();
                            let newYr = newDate.getFullYear();

                            await scheduleRef.child(`${newYr}/${newMo}/${newDay}`).set(thisWorkoutObj);

                            cumulativeTimestamp += oneDayMS;

                            setLoadingMessage(`Configuring schedule day ${addingDayCount}...`)
                            k = k < thisPhase.workouts.length - 1 ? k + 1 : 0;
                            addingDayCount += 1;
                            //// console.log("k = ", k);
                        }
                    }
                }
            }
        }

        setIsLoading(false);
        setWorkflowState(1);

        notifyClient(selectedClient.first_name, selectedClient.email ?? "connor@eigenfitness.com", currentUser.displayName ?? "", props.information.plan.title);
    }

    function submitFolder_old() {
        // console.log(cycleList);
        if (selectedClient.first_name === "") {return;}
        setIsLoading(true);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;


        //var cycleList: any[] = [];
        var startingTS: number = defaultDate;
        var endingTS: number = 0;

        var cycleObjsList: any[] = [];

        for (var cycleIndex = 0; cycleIndex < cycleList.length; cycleIndex++) {
            let thisCycle: Folder_t = cycleList[cycleIndex];

            var phaseObjsList: any[] = [];
            for (var phaseIndex = 0; phaseIndex < thisCycle.children.length; phaseIndex++) {
                let thisPhase: Folder_t = thisCycle.children[phaseIndex];
                let thisPhaseWorkoutList: Workout_t[] = thisPhase.workouts === undefined ? [] : thisPhase.workouts;

                if (thisPhase.plan_id !== undefined && thisPhase.plan_id !== "") {
                    let phaseWorkoutList = getPlanWorkoutListFormatted(thisPhaseWorkoutList);

                    let phaseObj: any = {
                        title: thisPhase.title,
                        template_id: thisPhase.id,
                        template_plan_id: thisPhase.plan_id,
                        template_location: thisPhase.path,
                        starting_timestamp: thisPhase.starting_timestamp === undefined ? -1 : thisPhase.starting_timestamp,
                        ending_timestamp: thisPhase.ending_timestamp === undefined ? -1 : thisPhase.ending_timestamp,
                        workouts: phaseWorkoutList
                    };

                    phaseObjsList.push(phaseObj);

                    // Check if last phase to set ending TS
                    endingTS = thisPhase.ending_timestamp === undefined ? -1 : thisPhase.ending_timestamp;
                    // if (cycleIndex === cycleList.length - 1 && phaseIndex === thisCycle.children.length - 1) {
                        
                    // }
                }
                
            }

            var cycleObj: any = {
                title: thisCycle.title,
                template_id: thisCycle.id,
                template_location: thisCycle.path,
                phases: phaseObjsList
            }
            if (phaseObjsList.length > 0) {
                cycleObjsList.push(cycleObj);
            }
            
        }


        let dbObj = {
            assigned_by: trainerUID,
            cycle_index: 0,
            phase_index: 0,
            workout_index: 0,
            starting_timestamp: startingTS,
            ending_timestamp: endingTS,
            is_active: true,
            notification_settings: {
                day_of_expiration: {
                    active: todayAdvanceAlert,
                    alert_id: ''
                },
                day_before_expiration: {
                    active: dailyAdvanceAlert,
                    alert_id: ''
                },
                week_before_expiration: {
                    active: weeklyAdvanceAlert,
                    alert_id: ''
                }
            },
            template_folder_id: selectedFolder.id,
            template_folder_location: selectedFolder.path,
            cycles: cycleObjsList
        };

        // console.log("WILL SUBMIT:", dbObj);


        // Push to db workout_plans
        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/folders`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planArray = snapshot.val();
                if (planArray.length > 0) {
                    for (var i = 0; i < planArray.length; i++) {
                        planArray[i].is_active = false;
                        planArray[i].ending_timestamp = Date.now();
                    }
                }
                planArray.push(dbObj);
                ref.set(planArray).then((e: any) => {
                    setUserAlerts(trainerUID, planArray.length - 1, dbObj);
                }).catch((err: any) => {

                })
                
            } else {
                ref.set([dbObj]);
                setUserAlerts(trainerUID, 0, dbObj);
            }
            setTimeout(() => {
                setIsLoading(false);
                setWorkflowState(1);
            }, 700);
        }); 
    }

    function submitSinglePhase_old() {
        // console.log(workoutList_editted);
        if (selectedClient.first_name === "") {return;}

        setIsLoading(true);
        
        let planWorkoutList = getPlanWorkoutListFormatted(workoutList_editted);
        // console.log("FORMATTED WORKOUT DATA:", planWorkoutList);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let assignedPlanObj = {
            starting_timestamp: Date.now(),
            ending_timestamp: selectedTimestamp,
            is_active: true,
            template_plan_id: props.information === undefined || props.information.plan === undefined || props.information.plan.id === undefined ? '' : props.information.plan.id,
            day_index: 0,
            assigned_by: trainerUID,
            workouts: planWorkoutList,
            autoMove: autoMoveAlongPlan,
            hide_future_phases: hideFuturePhases,
            notification_settings: {
                day_of_expiration: {
                    active: todayAdvanceAlert,
                    alert_id: ''
                },
                day_before_expiration: {
                    active: dailyAdvanceAlert,
                    alert_id: ''
                },
                week_before_expiration: {
                    active: weeklyAdvanceAlert,
                    alert_id: ''
                }
            }
        };

        // console.log(assignedPlanObj);

        // Push to db workout_plans
        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/plans`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planArray = snapshot.val();
                if (planArray.length > 0) {
                    for (var i = 0; i < planArray.length; i++) {
                        planArray[i].is_active = false;
                        planArray[i].ending_timestamp = Date.now();
                    }
                }
                planArray.push(assignedPlanObj);
                ref.set(planArray).then((e: any) => {
                    setUserAlerts(trainerUID, planArray.length - 1, assignedPlanObj);
                }).catch((err: any) => {

                })

                
            } else {
                ref.set([assignedPlanObj]);
                setUserAlerts(trainerUID, 0, assignedPlanObj);
            }
            setTimeout(() => {
                setIsLoading(false);
                setWorkflowState(1);
            }, 700);
        });  
    }

    async function submitSinglePhase() {
        setIsLoading(true);

        if (selectedClient.first_name === "") return;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) return;
        let trainerUID = currentUser.uid;

        let thisInfo = savedPlanInfo ?? props.information;
        if (thisInfo === undefined || thisInfo.plan === undefined || thisInfo.plan.id === undefined) {
            // console.log("AssignModal : submitSinglePhase | undefined found:", props.information, savedPlanInfo);
            setIsLoading(false);
            setWorkflowState(2);
            return;
        }
        let thisPhase = thisInfo.plan;
        let planWorkoutList = getPlanWorkoutListFormatted(workoutList_editted);

        let sendData = {
            user_id: selectedClient.id,
            trainer_id: trainerUID,
            program_id: thisPhase.id,
            starting_timestamp: selectedTimestamp,
            duration_days: defaultDurationDays,
            plan_workout_list: planWorkoutList
        };

        console.log("sendData:", sendData)


        
        try {
            let baseURL = "https://us-central1-movement-tracker-457bc.cloudfunctions.net" //"http://localhost:5001/movement-tracker-457bc/us-central1"; //
            let response = await axios.post(`${baseURL}/assignProgram`, sendData, { headers: {'Content-Type': 'application/json'} });
            let data = response.data;
            console.log("ASSIGN RESP:", response.data);
            
            setIsLoading(false);
            setWorkflowState(1);
        } catch (err) {
            console.log("ASSIGN ERR:", err);
            setIsLoading(false);
            setWorkflowState(1);
        }
        

        
    }

    // async function submitSinglePhase() {
    //     // console.log(workoutList_editted);
    //     if (selectedClient.first_name === "") {return;}

    //     setIsLoading(true);
        
    //     let planWorkoutList = getPlanWorkoutListFormatted(workoutList_editted);
    //     // console.log("FORMATTED WORKOUT DATA:", planWorkoutList);

    //     let currentUser = firebase.auth().currentUser;
    //     if (!currentUser) { return; }
    //     let trainerUID = currentUser.uid;

    //     // -------------------------------------------------------------
    //     // STEPS TO ASSIGNING PHASE
    //     // 1. Add phase object to {..., id: "single_phases"} folder in Client's folder structure
    //     // 2. Setup active_program object to reflect single phase changes
    //     // 3. Configure schedule to reflect phase being assigned
    //     // -------------------------------------------------------------


    //     // -------------------------------------------------------------
    //     // 1. Add phase object to {..., id: "single_phases"} folder in Client's folder structure
    //     // 1.a) Create pahase object

    //     let thisInfo = savedPlanInfo ?? props.information;
    //     if (thisInfo === undefined || thisInfo.plan === undefined || thisInfo.plan.id === undefined) {
    //         // console.log("AssignModal : submitSinglePhase | undefined found:", props.information, savedPlanInfo);
    //         setIsLoading(false);
    //         setWorkflowState(2);
    //         return;
    //     }

    //     var startingTS: number = selectedTimestamp; //defaultDate;
    //     var endingTS: number = startingTS + (defaultDurationDays * 1000 * 3600 * 24);

    //     let thisPhase = thisInfo.plan;
    //     let workoutIdMapping = await createNewWorkouts(thisPhase)

    //     console.log("MAPPING:", workoutIdMapping)
    //     console.log("planWorkoutList:", planWorkoutList)

    //     var newPlanWorkoutList: any[] = [];
    //     for (var i = 0; i < planWorkoutList.length; i++) {
    //         let thisOldObj = planWorkoutList[i];
    //         let thisOldObjId = thisOldObj && thisOldObj.workout_id ? thisOldObj.workout_id : "";
    //         if (thisOldObjId === "") {
    //             newPlanWorkoutList.push(thisOldObj)
    //         } else {
    //             let newID = workoutIdMapping[thisOldObjId] ?? thisOldObjId;
    //             console.log(">>>>", i , newID, thisOldObjId, workoutIdMapping, thisOldObj);
    //             let newObject = {
    //                 workout_id: newID,
    //                 blocks: thisOldObj.blocks ?? []
    //             }
    //             newPlanWorkoutList.push(newObject)
    //         }
    //     }

    //     // console.log("AssignModal : thisPhase", thisPhase)
    //     let phaseObj: any = {
    //         name: thisPhase.title,
    //         type: "plan",
    //         phase_name: thisPhase.phase_name === undefined || thisPhase.phase_name === "" ? (thisPhase.phaseName === undefined ? "" : thisPhase.phaseName) : thisPhase.phase_name,
    //         template_id: thisPhase.id,
    //         template_plan_id: thisPhase.id,
    //         template_location: thisPhase.path,
    //         starting_timestamp: startingTS,
    //         ending_timestamp: endingTS,
    //         workouts: newPlanWorkoutList
    //     };

    //     // console.log("AssignModal : submitSinglePhase | Phase object = ", phaseObj, thisInfo.plan);

    //     let database = firebase.database();

    //     var singlePhasesRef_key: null | string = "";
    //     if (!hardsetClient) {
    //         // Only execute if not assigning a plan from user's folder structure
    //         // 1.b) Push the object to the client's folder structure in the single_phases folder
            
    //         let singlePhasesRef = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/folders/single_phases`);
    //         await singlePhasesRef.child("name").set("Stand-alone Phases");
    //         await singlePhasesRef.child("path").set("/single_phases");
    //         await singlePhasesRef.child("type").set("folder");
    //         let singlePhasesRef_obj = await singlePhasesRef.child("children").push(phaseObj);
    //         singlePhasesRef_key = singlePhasesRef_obj.key;

    //         // 1.c) Use push_id to set:
    //         //      - id property of plan in client's folder structure
    //         //      - path property of plan in client's folder structure
    //         singlePhasesRef_key = singlePhasesRef_key === null ? "error" : singlePhasesRef_key;
    //         singlePhasesRef.child("children").child(singlePhasesRef_key).child("id").set(singlePhasesRef_key);
    //         singlePhasesRef.child("children").child(singlePhasesRef_key).child("path").set(`single_phases/${singlePhasesRef_key}`);
    //     } else {
    //         singlePhasesRef_key = phaseObj.template_id;
    //     }

    //     if (singlePhasesRef_key === null) { return; }

    //     // -------------------------------------------------------------
    //     // 2. Setup active_program object to reflect single phase changes
    //     let activeProgramoObj: any = {
    //         current_path: `/single_phases/${singlePhasesRef_key}`,
    //         master_path: `/single_phases`,
    //         assigned_by: trainerUID,
    //         starting_timestamp: startingTS,
    //         ending_timestamp: endingTS,
    //         assigned_on: Date.now()
    //     }
    //     let activeProgramRef = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/active_program`);
    //     activeProgramRef.set(activeProgramoObj);

    //     // -------------------------------------------------------------
    //     // 3. Configure schedule to reflect phase being assigned

    //     var cumulativeTimestamp = selectedTimestamp;
    //     let oneDayMS = 1000 * 3600 * 24;

    //     let scheduleRef = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/schedule`);
        

    //     // console.log("MAPPING:", workoutIdMapping)
    //     if (thisPhase.workout_ids !== undefined) {
    //         var k = 0;
    //         while (cumulativeTimestamp < endingTS) {
    //             let thisWorkout_id_original = thisPhase.workout_ids[k];
    //             let thisWorkout_id = workoutIdMapping[thisWorkout_id_original] ?? thisWorkout_id_original

    //             let thisWorkoutObj: any = {
    //                 path: thisPhase.path,
    //                 timestamp: cumulativeTimestamp,
    //                 workout_id: thisWorkout_id === undefined ? "" : thisWorkout_id,
    //                 cycle: {
    //                     id: '',
    //                     name: 'Cycle 1',
    //                     path: '',
    //                     index: 0
    //                 },
    //                 phase: {
    //                     id: singlePhasesRef_key,
    //                     name: thisPhase.title === undefined ? "" : thisPhase.title,
    //                     path: `single_phases/${singlePhasesRef_key}`,
    //                     index: 0
    //                 }
    //             }

    //             let newDate = new Date(cumulativeTimestamp);
    //             let newDay = newDate.getDate();
    //             let newMo = newDate.getMonth();
    //             let newYr = newDate.getFullYear();

    //             await scheduleRef.child(`${newYr}/${newMo}/${newDay}`).set(thisWorkoutObj);

    //             cumulativeTimestamp += oneDayMS;

    //             k = k < thisPhase.workout_ids.length - 1 ? k + 1 : 0;
    //             //// console.log("k = ", k);
    //         }
    //     }



    //     notifyClient(selectedClient.first_name, selectedClient.email ?? "connor@eigenfitness.com", currentUser.displayName ?? "", thisInfo && thisInfo.plan && thisInfo.plan.title ? thisInfo.plan.title : "New Program");

    //     setIsLoading(false);
    //     setWorkflowState(1);

    // }

    async function createNewWorkouts(thisPhase: any) {

        return new Promise<any>(async (resolve, reject) => {
            // console.log("OOOOOOOO:", thisPhase)
            if (!thisPhase || !thisPhase.workout_ids) return {};
            let thisWorkoutIdList = thisPhase.workout_ids;

            var workoutMapping: any = {};
            var workoutIDPromises: any[] = [];

            for (var i = 0; i < thisWorkoutIdList.length; i++) {
                let thisID = thisWorkoutIdList[i];
                if (thisID !== "") {
                    var thisWorkoutObj: Workout_t | undefined = undefined;
                    for (var j = 0; j < workoutList_editted.length; j++) {
                        if (workoutList_editted[j] && workoutList_editted[j].id && workoutList_editted[j].id === thisID) {
                            thisWorkoutObj = workoutList_editted[j];
                        }
                    }

                    // console.log("oo2:", thisID, thisWorkoutObj);
                    if (thisWorkoutObj) {
                        workoutIDPromises.push(createWorkout(thisWorkoutObj, thisID));
                        //let newWorkoutID = await createWorkout(thisWorkoutObj)
                        // workoutMapping[thisID] = newWorkoutID;
                    }
                    
                }
            }

            let resps = await Promise.allSettled(workoutIDPromises)

            if (resps && resps.length > 0) {
                for (var i = 0; i < resps.length; i++) {
                    let thisResp = resps[i];
                    if (thisResp && thisResp.status && thisResp.status === "fulfilled" && thisResp.value) {
                        let thisVal = thisResp.value;
                        workoutMapping[thisVal.oldID] = thisVal.newID;
                    }
                }
            }
            

            resolve(workoutMapping);
        })
    }

    async function createWorkout(workout: Workout_t, oldID: string) {
        return new Promise<{ newID: string, oldID: string }>(async (resolve, reject) => {

            if (!workout || !workout.exerciseSeries) {
                reject("Assign Modal: createWorkout | Provide some valid data!");
                return;
            }
            let exerciseSeriesList = workout.exerciseSeries

            var totalRepCount = 0;
            var totalSetCount = 0;
            var restTimes: string[] = [];
            var durationSeconds = 0;
            var tagsFinal: string[] = [];
            var exerciseListFinal: any[] = [];
            var exerciseList_ts: Exercise_t[] = [];
            var exerciseSeriesFinal: any[] = [];


            if (exerciseSeriesList.length > 0) {
                for (var series_index = 0; series_index < exerciseSeriesList.length; series_index++) {
                    let thisSeries: ExerciseSeries_t = exerciseSeriesList[series_index];
                    let thisSeriesExerciseList = thisSeries.exercises;

                    let offsetASCIICode = series_index + 65;
                    let seriesChar = String.fromCharCode(offsetASCIICode);

                    var thisSeriesExerciseJSONList: any[] = [];

                    if (thisSeriesExerciseList.length > 0) {
                        for (var exercise_index = 0; exercise_index < thisSeriesExerciseList.length; exercise_index++) {
                            let thisExercise: Exercise_t = thisSeriesExerciseList[exercise_index];

                            // 1. Sum the rep and set counts, and sum total duration from activity
                            let thisExerciseSetCount = thisExercise.reps !== undefined ? thisExercise.reps.length : 0;
                            totalSetCount += thisExerciseSetCount;
                            if (thisExerciseSetCount > 0) {
                                for (var j = 0; j < thisExerciseSetCount; j++) {
                                    totalRepCount += thisExercise.reps !== undefined ? thisExercise.reps[j] : 0;
                                    durationSeconds += thisExercise.reps !== undefined ? thisExercise.reps[j] * 3 : 36;
                                }
                            }

                            // 2. Calculate rest times for exercise and sum total duration from rest
                            let rests = thisExercise.restTime;
                            let restCount = rests !== undefined ? rests.length : 0;
                            var restSum = 0;
                            var containsNullRestTime = false;
                            if (rests !== undefined && restCount > 0) {
                                for (var j = 0; j < restCount; j++) {
                                    if (rests[j] === -1) { 
                                        containsNullRestTime = true; 
                                        durationSeconds += 45;
                                    } else {
                                        durationSeconds += rests[j];
                                    }
                                    restSum += rests[j];
                                }
                            }
                            let avgExerciseRest = restCount > 0 && containsNullRestTime === false ? Math.ceil(restSum / restCount) : -1;
                            restTimes.push(String(avgExerciseRest));

                            // 3. Tempo JSON list
                            var tempoJSONList: any[] = [];
                            if (thisExercise.tempos !== undefined && thisExercise.tempos.length > 0) {
                                for (var j = 0; j < thisExercise.tempos.length; j++) {
                                    let thisTempo: Tempo_t = thisExercise.tempos[j];
                                    let thisTempoJSONObj = {
                                        concentric: thisTempo.concentric,
                                        first_pause: thisTempo.first_pause,
                                        eccentric: thisTempo.eccentric,
                                        second_pause: thisTempo.second_pause
                                    };
                                    tempoJSONList.push(thisTempoJSONObj);
                                }
                            }

                            // 4. Create JSON formatted exercise for exercise list
                            let thisExerciseJSON: any = {
                                created_by: {
                                    id: thisExercise.createdBy !== undefined ? thisExercise.createdBy.id : 'eigen_fit',
                                    name: thisExercise.createdBy !== undefined ? thisExercise.createdBy.name : 'Eigen Fit'
                                },
                                key: thisExercise.id,
                                equipment: thisExercise.equipment !== undefined ? thisExercise.equipment : {bar: -1, bench: -1, cable_attachment: -1, machine: -1},
                                muscles: thisExercise.muscles,
                                reps: thisExercise.reps !== undefined ? thisExercise.reps : [],
                                setup: thisExercise.setup !== undefined ? thisExercise.setup : 'No setup specified',
                                steps: thisExercise.steps !== undefined ? thisExercise.steps : [],
                                tags: thisExercise.tags !== undefined ? thisExercise.tags : [],
                                title: thisExercise.title,
                                type: thisExercise.type,
                                percent_wms: thisExercise.percent_wms !== undefined ? thisExercise.percent_wms : [],
                                rest_times: thisExercise.restTime !== undefined ? thisExercise.restTime : [],
                                tempos: tempoJSONList,
                                rep_ranges: thisExercise.repRanges !== undefined ? thisExercise.repRanges : [],
                            }

                            if (thisExercise.note !== undefined) {
                                thisExerciseJSON['note'] = thisExercise.note;
                            }

                            if (thisExercise.loadingPattern !== undefined) {
                                thisExerciseJSON['loading_pattern'] = thisExercise.loadingPattern;
                            }

                            exerciseListFinal.push(thisExerciseJSON);

                            exerciseList_ts.push(thisExercise);


                            // 5. Create JSON formatted series for series list
                            let thisExerciseForSeriesJSON = {
                                title: `${seriesChar}${exercise_index + 1}`,
                                index: exercise_index,
                                id: thisExercise.id
                            };
                            thisSeriesExerciseJSONList.push(thisExerciseForSeriesJSON);

                            // 6. Create tags list
                            if (thisExercise.tags !== undefined && thisExercise.tags.length > 0) {
                                for (var j = 0; j < thisExercise.tags.length; j++) {
                                    let thisTag = thisExercise.tags[j];
                                    if (tagsFinal.indexOf(thisTag) < 0) {
                                        tagsFinal.push(thisTag);
                                    }
                                }
                            }
                        }
                    }

                    let thisSeriesJSON = {
                        title: seriesChar,
                        index: series_index,
                        type: thisSeriesExerciseJSONList.length > 1 ? 1 : 0,
                        exercises: thisSeriesExerciseJSONList
                    };
                    exerciseSeriesFinal.push(thisSeriesJSON);
                }
            }

            let durationMinutes = Math.ceil(durationSeconds / 60);
            let sendJSON: any = {
                title: workout.title ?? "Untitled Workout",
                description: workout.description ?? "",
                created_timestamp: Date.now(),
                tags: tagsFinal,
                goals: workout.goals ?? [],
                duration: durationMinutes,
                total_reps: totalRepCount,
                total_sets: totalSetCount,
                image_name: workout.imageName === '' ? '' : `${workout.imageName}.png`,
                target_gender: workout.targetGender ?? "b",
                exercises: exerciseListFinal,
                exercise_series: exerciseSeriesFinal,
                rest_times: restTimes,
                difficulty: "0",
                workout_location: "gym",
                created_by: workout.createdBy,
                availability: 0
            };

            // console.log("------- WORKOUT DATA: -------");
            // console.log(sendJSON);

            try {
                let response = await axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/createWorkout`,
                    {body: sendJSON},
                    { headers: {'Content-Type': 'application/json'} })
                // console.log(response.data);
                let data = response.data;
                let workoutID = data.pushKey;
                resolve({ newID: workoutID, oldID: oldID })
            } catch (err) {
                reject(err)
            }
        })
    }

    function notifyClient(clientName: string, clientEmail: string, trainerName: string, programName: string) {
        //https://eigenfitness.com/email/reminder_trainer.php?email=connor_chuk@yahoo.com&trainer_email=connor@eigenfitness.com&cta_link=https://eigenfitness.com/openSchedule&cta_text=See Your Program&email_subject=Your Program is Ready!&email_title=Connor just assigned you a new Workout Program&body_a=Hey Connor, you have a new program&body_b=nothing
        
        // Send email
        axios.get(`https://eigenfitness.com/email/reminder_trainer.php?
            email=${clientEmail}
            &trainer_email=connor@eigenfitness.com
            &cta_link=https://eigenfitness.com/openSchedule
            &cta_text=See Your Program
            &email_subject=Your Program is Ready!
            &email_title=${trainerName} just assigned you a new Workout Program
            &body_a=Hey ${clientName}, your Coach, ${trainerName}, just assigned you a new program on Eigen Fitness. Check out the program ${programName}, and get a look at the path to crush your most ambitious fitness goals! 💪
            &body_b=nothing`,
            { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response);
            //// console.log(response.data);
            let data = response.data;
        })
        .catch(e => {
            // console.log(e);
        })

        // Send push notification
        let trainerNameSplit = (trainerName ?? "").split(" ");
        let fName = trainerNameSplit[0] ?? ""
        sendPushNotification(selectedClient.id, true, true, `Your new Program is ready 💪`, `Your Coach, ${fName}, just updated your workout program to ${programName}. Open Eigen Fit to check it out!`, {ding:"dong"})
    }

    function getPlanWorkoutListFormatted(list: Workout_t[]) {
        var planWorkoutList: any[] = [];
        for (var workout_index = 0; workout_index < list.length; workout_index++) {
            // Parse Workouts
            let thisWorkout: Workout_t = list[workout_index];
            var blocksList: any[] = [];

            if (thisWorkout.exerciseSeries !== undefined) {
                let thisWorkoutSeriesList: ExerciseSeries_t[] = thisWorkout.exerciseSeries;
                for (var block_index = 0; block_index < thisWorkoutSeriesList.length; block_index++) {
                    // Parse Blocks (aka Exercise Series - I'm using them synonomously here)
                    let thisBlock: ExerciseSeries_t = thisWorkoutSeriesList[block_index];
                    var exerciseList: any[] = [];

                    if (thisBlock.exercises !== undefined) {
                        let thisExerciseList: Exercise_t[] = thisBlock.exercises;
                        for (var exercise_index = 0; exercise_index < thisExerciseList.length; exercise_index++) {
                            let thisExercise: Exercise_t = thisExerciseList[exercise_index];
                            var setInfoList: any[] = [];
                            if (thisExercise.reps !== undefined) {
                                for (var setIndex = 0; setIndex < thisExercise.reps.length; setIndex++) {
                                    let thisSetObj: any = {
                                        set_index: setIndex,
                                        reps: thisExercise.reps[setIndex],
                                        pwm: thisExercise.percent_wms !== undefined && thisExercise.percent_wms[setIndex] !== undefined ? thisExercise.percent_wms[setIndex] : -1,
                                        tempo: thisExercise.tempos !== undefined && thisExercise.tempos[setIndex] !== undefined ? thisExercise.tempos[setIndex] : {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1},
                                        rest: thisExercise.restTime !== undefined && thisExercise.restTime[setIndex] !== undefined ? thisExercise.restTime[setIndex] : -1,
                                    }
                                    setInfoList.push(thisSetObj);
                                }
                            }

                            let thisExerciseObj: any = {
                                exercise_id: thisExercise.id,
                                index: exercise_index,
                                notes: thisExercise.note !== undefined ? thisExercise.note : "",
                                set_info: setInfoList
                            };
                            exerciseList.push(thisExerciseObj);
                        }
                    }

                    // Add blocks (series) to blocksList
                    let thisBlockObj: any = {
                        title: getSetLetter(block_index),
                        index: block_index,
                        exercise_info: exerciseList
                    };
                    blocksList.push(thisBlockObj);
                }
            }
            


            // Add workotus to planWorkoutList
            var thisWorkoutObj: any = {
                workout_id: thisWorkout.id,
                blocks: blocksList
            }
            planWorkoutList.push(thisWorkoutObj);
        }

        return planWorkoutList;
    }

    function setUserAlerts(trainerUID: string, planIndex: number, assignedPlanObj: any) {
        // Set alerts in queue if user has prompted to do so

        let database = firebase.database();
        let ref_alerts =  database.ref(`notification_queue/alert_queue/${trainerUID}`);
        let plan_ref = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/plans/${planIndex}/notification_settings`);

        let expiryDate = new Date(assignedPlanObj.ending_timestamp);
        expiryDate.setHours(7,0,0,0);
        let daysTimestamp = expiryDate.getTime();

        if (todayAdvanceAlert === true) {

            let todayAlertRef = ref_alerts.push({
                alert_timestamp: daysTimestamp,
                type: 'plan-expires-today',
                client_id: selectedClient.id,
                plan_ending_timestamp: assignedPlanObj.ending_timestamp,
                template_plan_id: assignedPlanObj.template_folder_id === undefined ? assignedPlanObj.template_plan_id : assignedPlanObj.template_folder_id,
                is_folder: assignedPlanObj.template_folder_id !== undefined
            });

            plan_ref.child('day_of_expiration/alert_id').set(todayAlertRef.key);
            
        }

        if (dailyAdvanceAlert === true) {
            let dailyAlertRef = ref_alerts.push({
                alert_timestamp: daysTimestamp - (24 * 60 * 60 * 1000),              // subtract one day's worth of ms
                type: 'plan-expires-tomorrow',
                client_id: selectedClient.id,
                plan_ending_timestamp: assignedPlanObj.ending_timestamp,
                template_plan_id: assignedPlanObj.template_folder_id === undefined ? assignedPlanObj.template_plan_id : assignedPlanObj.template_folder_id,
                is_folder: assignedPlanObj.template_folder_id !== undefined
            });

            plan_ref.child('day_before_expiration/alert_id').set(dailyAlertRef.key);
        }

        if (weeklyAdvanceAlert === true) {
            let weeklyAlertRef = ref_alerts.push({
                alert_timestamp: daysTimestamp - (7 * 24 * 60 * 60 * 1000),          // subtract one week's worth of ms
                type: 'plan-expires-next-week',
                client_id: selectedClient.id,
                plan_ending_timestamp: assignedPlanObj.ending_timestamp,
                template_plan_id: assignedPlanObj.template_folder_id === undefined ? assignedPlanObj.template_plan_id : assignedPlanObj.template_folder_id,
                is_folder: assignedPlanObj.template_folder_id !== undefined
            });

            plan_ref.child('week_before_expiration/alert_id').set(weeklyAlertRef.key);
        }
    }

    function updateDateRange(forCycle: number, forPhase: number, startingTS: number, endingTS: number) {

        var newStartTimestamp = defaultDate;
        const defaultDurationMS = defaultDurationDays * 3600 * 1000 * 24;
        setCycleList(cycleList.map((cycleObj: Folder_t, indexCycle: number) => {
            var cycleTemp = cycleObj;
            cycleTemp.children.map((phaseObj: Folder_t, indexPhase: number) => {
                var phaseTemp = phaseObj;

                if (phaseTemp.plan_id === undefined || phaseTemp.plan_id === "" || phaseTemp.workouts === undefined || phaseTemp.workouts.length === 0) {
                    phaseTemp.starting_timestamp = newStartTimestamp;
                    phaseTemp.ending_timestamp = newStartTimestamp;
                    return phaseTemp;
                }

                if (indexCycle === forCycle && indexPhase === forPhase) {
                    phaseTemp.starting_timestamp = startingTS;
                    phaseTemp.ending_timestamp = endingTS;
                    newStartTimestamp = endingTS;
                } else {

                    let thisDuration = phaseTemp.ending_timestamp === undefined || phaseTemp.starting_timestamp === undefined ? defaultDurationMS : (phaseTemp.ending_timestamp - phaseTemp.starting_timestamp) + (3600 * 1000 * 24);
                    var thisEndingTS = newStartTimestamp + thisDuration;

                    // Check if next phase is the edited phase
                    if ( (forPhase === 0 && indexCycle === forCycle - 1) || (indexCycle === forCycle && indexPhase === forPhase - 1)  ) {
                        thisEndingTS = startingTS - (3600 * 1000 * 24);
                    }

                    // Check if should update program start timestamp
                    if (forPhase === 0 && forCycle === 0) {
                        setDefaultDate(startingTS);
                    }

                    
                    phaseTemp.starting_timestamp = newStartTimestamp + (3600 * 1000 * 24);
                    phaseTemp.ending_timestamp = thisEndingTS;
                    newStartTimestamp = thisEndingTS;
                }
                
                return phaseTemp;
            })
            return cycleTemp;
        }))
    }

    function updateDateRangeByDuration(newDuration: number) {
        var newStartTimestamp = defaultDate;
        const defaultDurationMS = newDuration * 3600 * 1000 * 24;
        setCycleList(cycleList.map((cycleObj: Folder_t, indexCycle: number) => {
            var cycleTemp = cycleObj;
            cycleTemp.children.map((phaseObj: Folder_t, indexPhase: number) => {
                var phaseTemp = phaseObj;

                if (phaseTemp.plan_id === undefined || phaseTemp.plan_id === "" || phaseTemp.workouts === undefined || phaseTemp.workouts.length === 0) {
                    phaseTemp.starting_timestamp = newStartTimestamp;
                    phaseTemp.ending_timestamp = newStartTimestamp;
                    return phaseTemp;
                }

                let thisEndingTS = newStartTimestamp + defaultDurationMS;

                phaseTemp.starting_timestamp = newStartTimestamp;
                phaseTemp.ending_timestamp = thisEndingTS;
                newStartTimestamp = thisEndingTS + (3600 * 1000 * 24);
                
                return phaseTemp;
            })
            return cycleTemp;
        }))
    }

    function getCycleDateRangeString(cycleNum: number) {
        let thisCyclePhaseList = cycleList[cycleNum];
        if (thisCyclePhaseList === undefined || thisCyclePhaseList.children === undefined || thisCyclePhaseList.children.length === 0) { return 'Unknown date range'; }

        let starting_TS = thisCyclePhaseList.children[0].starting_timestamp === undefined ? defaultDate : thisCyclePhaseList.children[0].starting_timestamp;
        let ending_TS = thisCyclePhaseList.children[thisCyclePhaseList.children.length - 1].ending_timestamp === undefined ? defaultDate + (defaultDurationDays * 1000 * 3600 * 24) : thisCyclePhaseList.children[thisCyclePhaseList.children.length - 1].ending_timestamp;

        let thisDate = new Date(starting_TS);
        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        let endingDate = new Date(ending_TS === undefined ? 0 : ending_TS);
        let endingMoIndex = endingDate.getMonth();
        let endingDay = endingDate.getDate();
        let endingYear = endingDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear} - ${moStrings[endingMoIndex]} ${endingDay}, ${endingYear}`;
    }

    function getCycleDurationString(cycleNum: number) {
        let thisCyclePhaseList = cycleList[cycleNum];
        if (thisCyclePhaseList === undefined || thisCyclePhaseList.children === undefined || thisCyclePhaseList.children.length === 0) { return 'Unknown date range'; }

        let starting_TS = thisCyclePhaseList.children[0].starting_timestamp === undefined ? defaultDate : thisCyclePhaseList.children[0].starting_timestamp;
        let ending_TS = thisCyclePhaseList.children[thisCyclePhaseList.children.length - 1].ending_timestamp === undefined ? defaultDate + (defaultDurationDays * 1000 * 3600 * 24) : thisCyclePhaseList.children[thisCyclePhaseList.children.length - 1].ending_timestamp;

        let dt = (ending_TS === undefined ? 0 : ending_TS) - starting_TS;
        let dt_days = Math.ceil(dt / (1000 * 3600 * 24));

        return `${dt_days} days`
    }

    function getPhaseDurationString(item_phase: Folder_t) {
        let starting_TS = item_phase.starting_timestamp === undefined ? defaultDate : item_phase.starting_timestamp;
        let ending_TS = item_phase.ending_timestamp === undefined ? defaultDate + (defaultDurationDays * 1000 * 3600 * 24) : item_phase.ending_timestamp;
        let dt = (ending_TS === undefined ? 0 : ending_TS) - starting_TS;
        let dt_days = Math.ceil(dt / (1000 * 3600 * 24));

        // if (dt_days !== defaultDurationDays && showSetDurationButton === false) {
        //     setShowSetDurationButton(true);
        // }

        return `${dt_days} days`
    }

    function getShowSetDurationButton() {

        if (defaultDurationFocus) { return false; }

        var shouldShowButtonTemp = false;
        cycleList.map((cycleObj: Folder_t, indexCycle: number) => {
            var cycleTemp = cycleObj;
            cycleTemp.children.map((phaseObj: Folder_t, indexPhase: number) => {
                var item_phase = phaseObj;

                if (item_phase.plan_id !== undefined && item_phase.plan_id !== "") {
                    let starting_TS = item_phase.starting_timestamp === undefined ? defaultDate : item_phase.starting_timestamp;
                    let ending_TS = item_phase.ending_timestamp === undefined ? defaultDate + (defaultDurationDays * 1000 * 3600 * 24) : item_phase.ending_timestamp;
                    let dt = (ending_TS === undefined ? 0 : ending_TS) - starting_TS;
                    let dt_days = Math.ceil(dt / (1000 * 3600 * 24));

                    if (Number(dt_days) !== Number(defaultDurationDays)) {
                        shouldShowButtonTemp = true;
                    }
                }

                
                
                return item_phase;
            })
            return cycleTemp;
        });

        return shouldShowButtonTemp;
    }

    function resetDurationButtonPressed() {
        updateDateRangeByDuration(defaultDurationDays);
        setShowSetDurationButton(false);
    }

    function editPhaseButtonPressed(forCycle: number, forPhase: number) {
        let thisCycleFolder: Folder_t = cycleList[forCycle];
        if (thisCycleFolder === undefined) { return; }
        let thisPhaseFolder: Folder_t = thisCycleFolder.children[forPhase];
        if (thisPhaseFolder === undefined || thisPhaseFolder.workouts === undefined) { return; }

        setAssigningingFolderShowEdit(true);

        setEdittingCycleIndex(forCycle);
        setEdittingPhaseIndex(forPhase);

        setWorkoutList(thisPhaseFolder.workouts);
        setWorkoutList_editted(thisPhaseFolder.workouts);

    }

    function doneEditingPhase() {
        setAssigningingFolderShowEdit(false);
        setEdittingCycleIndex(-1);
        setEdittingPhaseIndex(-1);
    }

    function getCurrentPhaseName() {
        let defaultResp = "your Phase"
        if (edittingCycleIndex < 0 || edittingPhaseIndex < 0) { return defaultResp; }
        let thisCycleFolder: Folder_t = cycleList[edittingCycleIndex];
        if (thisCycleFolder === undefined) { return defaultResp; }
        let thisPhaseFolder: Folder_t = thisCycleFolder.children[edittingPhaseIndex];
        if (thisPhaseFolder === undefined || thisPhaseFolder.title === undefined) { return defaultResp; }

        return `your "${thisPhaseFolder.title}"`;
    }

    function getPhaseNumber(cycleIndex: number, phaseIndex: number) {
        if (cycleList.length === 0) { return 0; }

        var accumulatedNum = 1;

        for (var i = 0; i < cycleIndex; i++) {
            let thisCycle: Folder_t = cycleList[i];
            let thisCyclePhases = thisCycle.children;
            accumulatedNum += thisCyclePhases.length;
        }

        accumulatedNum += phaseIndex;

        return accumulatedNum;
    }

    function getPhasePeriodizationString(phase: Folder_t) {
        var avgReps = 0;
        var avgRepsCounter = 0;
        if (phase.workouts !== undefined) {
            for (var i = 0; i < phase.workouts.length; i++) {
                let thisWorkout: Workout_t = phase.workouts[i];
                if (thisWorkout.exerciseSeries !== undefined) {
                    for (var j = 0; j < thisWorkout.exerciseSeries.length; j++) {
                        let thisSeries: ExerciseSeries_t = thisWorkout.exerciseSeries[j];
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise: Exercise_t = thisSeries.exercises[k];
                            if (thisExercise.reps !== undefined) {
                                for (var x = 0; x < thisExercise.reps.length; x++) {
                                    let thisRep = thisExercise.reps[x];
                                    avgReps += thisRep;
                                    avgRepsCounter += 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (avgRepsCounter > 0) {
            avgReps /= avgRepsCounter;
        }

        if (avgReps === 0) {
            return ''
        } else if (avgReps <= 8) {
            return 'Intensification';
        } else {
            return 'Accumulation';
        }
    }

    function getPhaseRepSchemeString(phase: Folder_t) {
        var repScheme: number[] = [];
        var schemeSet = false;
        if (phase.workouts !== undefined) {
            for (var i = 0; i < phase.workouts.length; i++) {
                let thisWorkout: Workout_t = phase.workouts[i];
                if (thisWorkout.exerciseSeries !== undefined) {
                    for (var j = 0; j < thisWorkout.exerciseSeries.length; j++) {
                        let thisSeries: ExerciseSeries_t = thisWorkout.exerciseSeries[j];
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise: Exercise_t = thisSeries.exercises[k];
                            if (thisExercise.reps !== undefined && schemeSet === false) {
                                repScheme = thisExercise.reps;
                                schemeSet = true;
                            }
                        }
                    }
                }
            }
        }

        var repSchemeString = "";
        if (repScheme.length > 0) {
            for (var i = 0; i < repScheme.length; i++) {
                repSchemeString += `${repScheme[i]}  `;
            }
        }
        return repSchemeString;
    }

    function getPhaseNumWorkoutsString(phase: Folder_t) {
        return `${phase.workouts === undefined ? 0 : phase.workouts.length} workouts`
    }

	return (
		<div className="modal-background assign-modal-background">
            <div className={`assign-modal assign-modal-${showEdit ? 'max' : 'min'}`}>
                <div className="assign-modal-upper">
                    <h4>Assign "{props.information === undefined || props.information.plan === undefined || props.information.plan.title === undefined ? (props.information === undefined || props.information.folder === undefined ? "this Phase" : (props.information.folder.title === undefined ? "this Folder" : props.information.folder.title)) : props.information.plan.title}" to {hardsetClient ? selectedClient.full_name : "a Client"}</h4>
                    <div onClick={() => props.hideModalPressed()} className="assign-modal-upper-close-button-container"><Close className="assign-modal-upper-close-button-icon"/></div>
                </div>
                <div hidden={isLoading || workflowState !== 0} className="assign-modal-body">
                    <div className={`assign-modal-body-left assign-modal-body-left-${showEdit ? 'max' : 'min'}`}>
                        <div className="assign-modal-body-row-header">
                            <h4>Select Athlete and Program Duration</h4>
                        </div>
                        <div className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>Athlete</h4>
                            </div>
                            <div className="assign-modal-body-row-input-container">
                                <div hidden={selectedClient.first_name !== ""} className={`assign-modal-body-client-name-input-container ${clientNameFocus ? "assign-modal-body-client-name-input-container-focus" : ""}`}>
                                    <IonInput value={searchString} 
                                        id="assign-to-client-input"
                                        autofocus={true}
                                        placeholder="Search for a Athlete by name..."
                                        inputmode="text"
                                        clearOnEdit={true}
                                        type="text"
                                        onKeyPress={e => inputUpdated(e.key)}
                                        onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                                        onFocus={(e) => setClientNameFocuse(true)}
                                        onBlur={(e) => setClientNameFocuse(false)}/>
                                    <div hidden={showClientList} onClick={() => setShowClientList(true)} className="assign-modal-body-client-name-input-arrow-icon-container">
                                        <ExpandMore className="assign-modal-body-client-name-input-arrow-icon"/>
                                    </div>
                                    <div hidden={!showClientList} onClick={() => setShowClientList(false)} className="assign-modal-body-client-name-input-arrow-icon-container">
                                        <ExpandLess className="assign-modal-body-client-name-input-arrow-icon"/>
                                    </div>
                                </div>
                                <div hidden={selectedClient.first_name === ""} className="assign-modal-body-client-name-input-container assign-modal-body-client-name-input-container-selected-client">
                                    <div className="client-card-image-container">
                                        <img  
                                            className="client-card-image" 
                                            hidden={imgUrl === ''}
                                            src={imgUrl} 
                                            alt={`Image for ${selectedClient.first_name}`} />
                                    </div>
                                    <div className="assign-modal-body-client-name-input-container-selected-client-text-container">
                                        <h4>{selectedClient.full_name}</h4>
                                    </div>
                                    <div hidden={hardsetClient} onClick={() => removeClient()} className="assign-modal-body-client-name-input-container-selected-client-remove-container">
                                        <Close className="assign-modal-body-client-name-input-container-selected-client-remove-icon"/>
                                    </div>    
                                </div>
                            </div>
                            <CSSTransition in={showClientList} timeout={200} classNames="menu" unmountOnExit>
                                <div className="assign-modal-body-client-name-menu-container">
                                    {
                                        clients.map((item: Client_t, index: number) => (
                                            <AssignModalClientCell 
                                                client={item} 
                                                index={index} 
                                                clientSelected={() => clientSelected(item)}/>
                                        ))
                                    }
                                </div>
                            </CSSTransition>
                        </div>
                        <div hidden={selectedClient.first_name !== ""} className="assign-modal-body-curtain"></div>
                        <div className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>Program start date</h4>
                            </div>
                            <div className="assign-modal-body-row-input-container">
                                <DateSelector 
                                    dateSelected={(e: number) => targetDateChanged(e)}
                                    defaultTimestamp={defaultDate}/>
                                <div className="assign-modal-body-row-input-container-date-duration-container">
                                    <p>{getDurationDays()}</p>
                                </div>
                                {/*<div hidden={assigningingFolder} className="assign-modal-body-row-input-container-date-duration-container">
                                    <p>{getDurationDays()} days</p>
                                </div>
                                <div hidden={!assigningingFolder} className="assign-modal-body-row-input-container-date-duration-container">
                                    <p>{getDurationDays()}</p>
                                </div>*/}
                            </div>
                        </div>
                        <div hidden={true || assigningingFolder} className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>Progression</h4>
                            </div>
                            <div className="assign-modal-body-row-input-container">
                                <div className="assign-modal-body-row-input-row-container">
                                    <div className="assign-modal-body-row-input-container-checkbox-container">
                                        <CheckBox 
                                            forceState={autoMoveAlongPlan}
                                            stateChanged={(s: boolean) => setAutoMoveAlongPlan(s)}/>
                                    </div>
                                    <div className="assign-modal-body-row-input-container-checkbox-text-container">
                                        <h4>Automatically progress</h4>
                                        <p>Your Athlete will automatically progress to the next phase in this Cycle <span>after {getDurationDays()} days</span>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>{assigningingFolder ? "Default Phase Duration" : "Phase Duration"}</h4>
                            </div>
                            <div className="assign-modal-body-row-input-container">
                                <div className="assign-modal-body-row-input-row-container">
                                    <div className={`assign-modal-body-row-input-phase-duration-container ${defaultDurationFocus ? "assign-modal-body-row-input-phase-duration-container-focus" : ""}`}>
                                        <IonInput
                                            id="assign-to-client-default-duration-input"
                                            value={defaultDurationDays}
                                            placeholder="Enter duration..."
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onKeyPress={e => inputUpdated(e.key)}
                                            onIonChange={e => inputChanged(e.detail.value!, 'default-duration')}
                                            onFocus={(e) => setDefaultDurationFocus(true)}
                                            onBlur={(e) => setDefaultDurationFocus(false)}/>
                                            <div hidden={getShowSetDurationButton()} className="assign-modal-body-row-input-phase-duration-unit-container">
                                                <p>days</p>
                                            </div>
                                            <div hidden={!getShowSetDurationButton()} onClick={() => resetDurationButtonPressed()} className="assign-modal-body-row-input-phase-duration-button">
                                                <p>Reset</p>
                                                <ArrowForward className="assign-modal-body-row-input-phase-duration-button-icon"/>
                                            </div>
                                    </div>
                                    <div hidden={true} className="assign-modal-body-row-input-container-checkbox-text-container">
                                        <h4>Automatically progress</h4>
                                        <p>Your Athlete will automatically progress to the next phase in this Cycle <span>after {getDurationDays()} days</span>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>Notifications</h4>
                            </div>
                            <div className="assign-modal-body-row-input-container">
                                <div className="assign-modal-body-row-input-row-container">
                                    <div className="assign-modal-body-row-input-container-checkbox-container">
                                        <CheckBox 
                                            forceState={todayAdvanceAlert}
                                            stateChanged={(s: boolean) => setTodayAdvanceAlert(s)}/>
                                    </div>
                                    <div className="assign-modal-body-row-input-container-checkbox-text-container">
                                        <h4>Expiration date</h4>
                                        <p>Recieve a notification on <span>{getFormattedDate(selectedTimestamp)}</span>.</p>
                                    </div>
                                </div>
                                <div className="assign-modal-body-row-input-row-container">
                                    <div className="assign-modal-body-row-input-container-checkbox-container">
                                        <CheckBox 
                                            forceState={dailyAdvanceAlert}
                                            stateChanged={(s: boolean) => setDailyAdvanceAlert(s)}/>
                                    </div>
                                    <div className="assign-modal-body-row-input-container-checkbox-text-container">
                                        <h4>Day before expiration</h4>
                                        <p>Recieve a notification on <span>{getFormattedDate(selectedTimestamp - (60 * 60 * 24 * 1000))}</span>.</p>
                                    </div>
                                </div>
                                <div className="assign-modal-body-row-input-row-container">
                                    <div className="assign-modal-body-row-input-container-checkbox-container">
                                        <CheckBox 
                                            forceState={weeklyAdvanceAlert}
                                            stateChanged={(s: boolean) => setWeeklyAdvanceAlert(s)}/>
                                    </div>
                                    <div className="assign-modal-body-row-input-container-checkbox-text-container">
                                        <h4>Week before expiration</h4>
                                        <p>Recieve a notification on <span>{getFormattedDate(selectedTimestamp - (7 * 60 * 60 * 24 * 1000))}</span>.</p>
                                    </div>
                                </div>
                                <div hidden={!assigningingFolder} className="assign-modal-body-row-label-container assign-modal-body-row-label-container-extra-margin">
                                    <h4>More Options</h4>
                                </div>
                                <div hidden={!assigningingFolder} className="assign-modal-body-row-input-container">
                                    <div className="assign-modal-body-row-input-row-container">
                                        <div className="assign-modal-body-row-input-container-checkbox-container">
                                            <CheckBox 
                                                forceState={hideFuturePhases}
                                                stateChanged={(s: boolean) => setHideFuturePhases(s)}/>
                                        </div>
                                        <div className="assign-modal-body-row-input-container-checkbox-text-container">
                                            <h4>Hide Future Phases</h4>
                                            <p>Keep program private — prevent Athlete from viewing more than 1 phase at a time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => toggleShowEdit()} className="assign-modal-body-row-input-row-container assign-modal-body-row-input-row-container-edit">
                                    <h5>{showEdit ? "Done editting Program" : "Edit Program before assigning"}</h5>
                                    <div hidden={showEdit} className="assign-modal-body-row-input-row-edit-button-container">
                                        <ArrowForward className="assign-modal-body-row-input-row-edit-button-icon"/>
                                    </div>
                                    <div hidden={!showEdit} className="assign-modal-body-row-input-row-edit-button-container">
                                        <Check className="assign-modal-body-row-input-row-edit-button-icon"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div hidden={!showEdit || (assigningingFolder && !assigningingFolderShowEdit)} className="assign-modal-body-right">
                        <CSSTransition in={!showingWorkout} timeout={200} classNames="slide" unmountOnExit>
                            <div className="assign-modal-body-right-inner">
                                <div hidden={assigningingFolderShowEdit} className="assign-modal-body-row assign-modal-body-row-full">
                                    <div className="assign-modal-body-row-edit-plan-header-text-container">
                                        <div className="assign-modal-body-row-edit-plan-header">
                                            <h2>Edit your Program template before assigning</h2>
                                            <div className="assign-modal-body-row-edit-plan-header-optional-container">
                                                <p>Optional</p>
                                            </div>
                                        </div>
                                        <div className="assign-modal-body-row-edit-plan-header-bottom">
                                            <p>Select a Workout below to edit its contents, or press + to add another</p>
                                        </div>
                                    </div>
                                </div>
                                <div hidden={!assigningingFolderShowEdit} className="assign-modal-body-row assign-modal-body-row-full">
                                    <div className="assign-modal-body-row-edit-plan-header-text-container">
                                        <div className="assign-modal-body-row-edit-plan-header">
                                            <h2>Edit {getCurrentPhaseName()} template before assigning</h2>
                                            <div className="assign-modal-body-row-edit-plan-header-optional-container">
                                                <p>Optional</p>
                                            </div>
                                        </div>
                                        <div className="assign-modal-body-row-edit-plan-header-bottom">
                                            <p>Select exercises to edit set details.</p>
                                        </div>
                                        <div hidden={true} onClick={() => setShowingWorkout(false)} className="assign-modal-body-row-edit-plan-header-back-button">
                                            <ArrowBackIos className="assign-modal-body-row-edit-plan-header-back-button-icon"/>
                                        </div>
                                    </div>
                                    <div className="assign-modal-body-row-edit-plan-header-button-container">
                                        <div onClick={() => doneEditingPhase()} className={`assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-blue`}>
                                            <p>Finish Editing Phase</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="assign-modal-body-row-edit-plan-body">
                                    <div className="assign-modal-body-row-edit-plan-body-header-row">
                                        <h4>Workouts in "{props.information === undefined || props.information.plan === undefined || props.information.plan.title === undefined ? "this plan" : props.information.plan.title}"</h4>
                                        <p>({props.information === undefined || props.information.plan === undefined || props.information.plan.workout_ids === undefined ? "0" : props.information.plan.workout_ids.length})</p>
                                        <div hidden={true} className="assign-modal-body-row-edit-plan-body-header-row-add-button">
                                            <Add className="assign-modal-body-row-edit-plan-body-header-row-add-icon"/>
                                        </div>
                                    </div>
                                    {workoutList_editted.map((item: Workout_t, index: number) => (
                                        <AssignModalWorkoutCell 
                                            id={item.id} 
                                            index={index}
                                            workoutSelected={(w: Workout_t) => workoutSelected(w, index)}
                                            workoutData={item}
                                            workoutDataDownloaded={(w: Workout_t) => workoutDataDownloaded(w, index)}/>
                                    ))}
                                </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition in={showingWorkout} timeout={200} classNames="slide" unmountOnExit>
                            <div className="assign-modal-body-right-inner">
                                <div hidden={showNewExercise} className="assign-modal-body-row assign-modal-body-row-full assign-modal-body-row-full-workout">
                                    <div className="assign-modal-body-row-edit-plan-header-text-container">
                                        <div className="assign-modal-body-row-edit-plan-header">
                                            <h2>{selectedWorkout.title}</h2>
                                        </div>
                                        <div className="assign-modal-body-row-edit-plan-header-bottom">
                                            <p>Select exercises to edit set details.</p>
                                        </div>
                                        <div hidden={true} onClick={() => setShowingWorkout(false)} className="assign-modal-body-row-edit-plan-header-back-button">
                                            <ArrowBackIos className="assign-modal-body-row-edit-plan-header-back-button-icon"/>
                                        </div>
                                    </div>
                                    <div className="assign-modal-body-row-edit-plan-header-button-container">
                                        <div onClick={() => setShowingWorkout(false)} className="assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-dark">
                                            <p>{workoutHasBeenEdited ? 'Revert & Cancel' : 'Cancel'}</p>
                                        </div>
                                        <div onClick={() => workoutHasBeenEdited ? saveWorkout() : null} className={`assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-blue ${!workoutHasBeenEdited ? 'assign-modal-body-row-edit-plan-header-button-blue-unselected' : ''}`}>
                                            <p>Save Changes</p>
                                        </div>
                                    </div>
                                </div>
                                <CSSTransition in={!showNewExercise} timeout={200} classNames="slide" unmountOnExit>
                                    <div className="assign-modal-body-row-edit-plan-body assign-modal-body-row-edit-plan-body-program">
                                        { exerciseSeries.map((item: ExerciseSeries_t, index: number) => (
                                            <div className="workout-plan-list-plan-workout-body-card-content-series-cell-container">
                                                <CreateWorkoutExerciseListCell
                                                    key={`${item.title}-${index}`}
                                                    isEditable={true}
                                                    quickAdd={true}
                                                    exerciseSeries={item}
                                                    exerciseSeriesIndex={index}
                                                    maxExerciseSeriesIndex={exerciseSeries.length - 1}
                                                    moveExerciseSeriesUp={(e: number) => null}
                                                    moveExerciseSeriesDown={(e: number) => null}
                                                    moveUpExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
                                                    moveDownExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
                                                    editExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t) => null}
                                                    removeExerciseSeries={(eS: ExerciseSeries_t, i: number) => removeExerciseSeries(eS)}
                                                    addToSupersetPressed={() => addToExistingBlock(index)}
                                                    switchIsShowing={(e: Exercise_t) => switchIsShowing(item, e)}
                                                    hideEditingFeatures={true}
                                                    removeExercisePressed={(exercise: Exercise_t) => removeExercisePressed(item, exercise)}
                                                    addSetPressed={(exercise: Exercise_t) => addSetPressed(exercise, item)}
                                                    removePressed={(exercise: Exercise_t, setIndex: number) => removeSetPressed(exercise, setIndex, item)}
                                                    editedReps={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'reps', item)}
                                                    editedPWM={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'pwm', item)}
                                                    editedTempo={(exercise: Exercise_t, setIndex: number, val: Tempo_t) => editedSetContent(exercise, setIndex, val, 'tempo', item)}
                                                    editedRest={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'rest', item)}
                                                />
                                            </div>
                                        ))}
                                        {
                                            exerciseSeries.length > 0 ?
                                             <div className="assign-modal-body-add-button-container">
                                                <div onClick={() => addNewBlock()} className="assign-modal-body-add-button">
                                                    <p>+ Add another Block</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="assign-modal-body-add-to-restday-container">
                                                <div className="assign-modal-body-add-to-restday-header-container">
                                                    <h4>This is a rest day</h4>
                                                    <p>Turn this rest day into a working day by pressing "Add a Block" below.</p>
                                                </div>
                                                <div onClick={() => addNewBlock()} className="assign-modal-body-restday-add-button">
                                                    <p>+ Add a Block</p>
                                                </div>
                                            </div>
                                        }
                                       
                                    </div>
                                </CSSTransition>
                                <CSSTransition in={showNewExercise} timeout={200} classNames="slide" unmountOnExit>
                                    <div className="assign-modal-body-exercise-config-container">
                                        <CreateWorkoutExerciseConfigure
                                            exerciseToEdit={{title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]}}
                                            closeModal={() => setShowingWorkout(false)}
                                            onTheFlyWorkoutAdding={false}
                                            createNewExerciseFromWorkout={(s: string) => null}
                                            backToExerciseList={(e?:Exercise_t) => addNewExerciseToSelectedWorkout(e)}/>
                                    </div>
                                </CSSTransition>
                            </div>
                        </CSSTransition>
                    </div>
                    <div hidden={!showEdit || (!assigningingFolder || assigningingFolderShowEdit)} className="assign-modal-body-right">
                        <div hidden={!isLoadingPlans} className="assign-modal-body-right-loading-container">
                            <div className="client-list-container-loading-spinner"/>
                            <p>Loading Program...</p>
                        </div>
                        <CSSTransition in={!showingWorkout} timeout={200} classNames="slide" unmountOnExit>
                            <div className="assign-modal-body-right-inner">
                                <div className="assign-modal-body-row assign-modal-body-row-full">
                                    <div className="assign-modal-body-row-edit-plan-header-text-container">
                                        <div className="assign-modal-body-row-edit-plan-header">
                                            <h2>Edit your Folder template before assigning</h2>
                                            <div className="assign-modal-body-row-edit-plan-header-optional-container">
                                                <p>Optional</p>
                                            </div>
                                        </div>
                                        <div className="assign-modal-body-row-edit-plan-header-bottom">
                                            <p>Select date ranges for each Phase in this folder</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="assign-modal-body-row-edit-plan-body">
                                    {
                                        cycleList.map((item: Folder_t, index: number) => (
                                            <div className="assign-modal-body-folder-row-cycle-container">
                                                <div className="assign-modal-body-folder-row-cycle-header">
                                                    <div className="assign-modal-body-folder-row-cycle-header-cycle-number-container">
                                                        <h4>Cycle {index+1}</h4>
                                                    </div>
                                                    <div className="assign-modal-body-folder-row-cycle-header-cycle-circle"/>
                                                    <div className="assign-modal-body-folder-row-cycle-header-cycle-date-container">
                                                        <div className="assign-modal-body-folder-row-cycle-header-cycle-date-icon-container">
                                                            <CalendarToday className="assign-modal-body-folder-row-cycle-header-cycle-date-icon"/>
                                                        </div>
                                                        <h4>{getCycleDateRangeString(index)}</h4>
                                                        <p>({getCycleDurationString(index)})</p>
                                                    </div>
                                                    <div className="assign-modal-body-folder-row-cycle-header-cycle-dropdown-container">
                                                        <div hidden={item.isOpen === undefined || item.isOpen === false} className="assign-modal-body-folder-row-cycle-header-cycle-dropdown-content">
                                                            <ExpandMore className="assign-modal-body-folder-row-cycle-header-cycle-dropdown-icon"/>
                                                        </div>
                                                        <div hidden={item.isOpen !== undefined && item.isOpen === true} className="assign-modal-body-folder-row-cycle-header-cycle-dropdown-content">
                                                            <ExpandLess className="assign-modal-body-folder-row-cycle-header-cycle-dropdown-icon"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="assign-modal-body-folder-row-cycle-body">
                                                    {
                                                        item.children === undefined ? null : item.children.map((item_phase: Folder_t, index_phase: number) => (
                                                            <div className="assign-modal-body-folder-row-phase-container">
                                                                <div className="assign-modal-body-folder-row-phase-content-upper">
                                                                    <div className="assign-modal-body-folder-row-phase-content-upper-phase-number-container">
                                                                        <h4>Phase {getPhaseNumber(index, index_phase)}</h4>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-upper-phase-name-container">
                                                                        <h4>{item_phase.plan_id === undefined || item_phase.plan_id === "" ? "Unbuilt Phase" : ( item_phase.workouts === undefined || item_phase.workouts.length === 0 ? "Empty Phase" : item_phase.title)}</h4>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-upper-phase-info-container">
                                                                        <h4>{getPhasePeriodizationString(item_phase)}</h4>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-upper-phase-info-container">
                                                                        <h4>{getPhaseRepSchemeString(item_phase)}</h4>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-upper-phase-info-container">
                                                                        <h4>{getPhaseNumWorkoutsString(item_phase)}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className={`assign-modal-body-folder-row-phase-line-up ${index_phase === 0 ? 'assign-modal-body-folder-row-phase-line-up-short' : ''}`}/>
                                                                <div className={`assign-modal-body-folder-row-phase-circle ${item_phase.plan_id === undefined || item_phase.plan_id === "" || item_phase.workouts === undefined || item_phase.workouts.length === 0 ? "assign-modal-body-folder-row-phase-circle-unselected" : ""}`}/>
                                                                <div className={`assign-modal-body-folder-row-phase-content-lower ${item_phase.plan_id === undefined || item_phase.plan_id === "" ? "assign-modal-body-folder-row-phase-content-lower-unselected" : ""}`}>
                                                                    <div hidden={item_phase.plan_id !== undefined && item_phase.plan_id !== "" && item_phase.workouts !== undefined && item_phase.workouts.length !== 0} className="assign-modal-body-folder-row-phase-content-lower-curtain">
                                                                        <p>{item_phase.plan_id === undefined || item_phase.plan_id === "" ? "Build this Phase" : "Add workouts to this Phase"} in order to assign it</p>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-lower-date-range-container">
                                                                        <DateRangeSelector 
                                                                            dateSelected={(e: number) => targetDateChanged(e)}
                                                                            defaultDurationDays={defaultDurationDays}
                                                                            startingTimestamp={item_phase.starting_timestamp === undefined ? Date.now() : item_phase.starting_timestamp}
                                                                            endingTimestamp={item_phase.ending_timestamp === undefined ? Date.now() : item_phase.ending_timestamp}
                                                                            defaultTimestamp={defaultDate}
                                                                            updateDateRange={(startingTS: number, endingTS: number) => updateDateRange(index, index_phase, startingTS, endingTS)}/>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-lower-duration-container">
                                                                        <p>({getPhaseDurationString(item_phase)})</p>
                                                                    </div>
                                                                    <div className="assign-modal-body-folder-row-phase-content-lower-buttons-container">
                                                                        <div onClick={() => editPhaseButtonPressed(index, index_phase)} className="assign-modal-body-folder-row-phase-content-lower-button">
                                                                            <Edit className="assign-modal-body-folder-row-phase-content-lower-button-icon assign-modal-body-folder-row-phase-content-lower-button-icon-edit"/>
                                                                            <p>Edit Phase</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </CSSTransition>
                        {/*<CSSTransition in={showingWorkout} timeout={200} classNames="slide" unmountOnExit>
                            <div className="assign-modal-body-right-inner">
                                <div hidden={showNewExercise} className="assign-modal-body-row assign-modal-body-row-full assign-modal-body-row-full-workout">
                                    <div className="assign-modal-body-row-edit-plan-header-text-container">
                                        <div className="assign-modal-body-row-edit-plan-header">
                                            <h2>{selectedWorkout.title}</h2>
                                        </div>
                                        <div className="assign-modal-body-row-edit-plan-header-bottom">
                                            <p>Select exercises to edit set details.</p>
                                        </div>
                                        <div hidden={true} onClick={() => setShowingWorkout(false)} className="assign-modal-body-row-edit-plan-header-back-button">
                                            <ArrowBackIos className="assign-modal-body-row-edit-plan-header-back-button-icon"/>
                                        </div>
                                    </div>
                                    <div className="assign-modal-body-row-edit-plan-header-button-container">
                                        <div onClick={() => setShowingWorkout(false)} className="assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-dark">
                                            <p>{workoutHasBeenEdited ? 'Revert & Cancel' : 'Cancel'}</p>
                                        </div>
                                        <div onClick={() => workoutHasBeenEdited ? saveWorkout() : null} className={`assign-modal-body-row-edit-plan-header-button assign-modal-body-row-edit-plan-header-button-blue ${!workoutHasBeenEdited ? 'assign-modal-body-row-edit-plan-header-button-blue-unselected' : ''}`}>
                                            <p>Save Changes</p>
                                        </div>
                                    </div>
                                </div>
                                <CSSTransition in={!showNewExercise} timeout={200} classNames="slide" unmountOnExit>
                                    <div className="assign-modal-body-row-edit-plan-body assign-modal-body-row-edit-plan-body-program">
                                        { exerciseSeries.map((item: ExerciseSeries_t, index: number) => (
                                            <div className="workout-plan-list-plan-workout-body-card-content-series-cell-container">
                                                <CreateWorkoutExerciseListCell
                                                    key={`${item.title}-${index}`}
                                                    isEditable={true}
                                                    quickAdd={true}
                                                    exerciseSeries={item}
                                                    exerciseSeriesIndex={index}
                                                    maxExerciseSeriesIndex={exerciseSeries.length - 1}
                                                    moveExerciseSeriesUp={(e: number) => null}
                                                    moveExerciseSeriesDown={(e: number) => null}
                                                    moveUpExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
                                                    moveDownExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => null}
                                                    editExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t) => null}
                                                    removeExerciseSeries={(eS: ExerciseSeries_t, i: number) => removeExerciseSeries(eS)}
                                                    addToSupersetPressed={() => addToExistingBlock(index)}
                                                    switchIsShowing={(e: Exercise_t) => switchIsShowing(item, e)}
                                                    hideEditingFeatures={true}
                                                    removeExercisePressed={(exercise: Exercise_t) => removeExercisePressed(item, exercise)}
                                                    addSetPressed={(exercise: Exercise_t) => addSetPressed(exercise, item)}
                                                    removePressed={(exercise: Exercise_t, setIndex: number) => removeSetPressed(exercise, setIndex, item)}
                                                    editedReps={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'reps', item)}
                                                    editedPWM={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'pwm', item)}
                                                    editedTempo={(exercise: Exercise_t, setIndex: number, val: Tempo_t) => editedSetContent(exercise, setIndex, val, 'tempo', item)}
                                                    editedRest={(exercise: Exercise_t, setIndex: number, val: number) => editedSetContent(exercise, setIndex, val, 'rest', item)}
                                                />
                                            </div>
                                        ))}
                                        <div className="assign-modal-body-add-button-container">
                                            <div onClick={() => addNewBlock()} className="assign-modal-body-add-button">
                                                <p>+ Add another Block</p>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                                <CSSTransition in={showNewExercise} timeout={200} classNames="slide" unmountOnExit>
                                    <div className="assign-modal-body-exercise-config-container">
                                        <CreateWorkoutExerciseConfigure
                                            exerciseToEdit={{title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]}}
                                            closeModal={() => setShowingWorkout(false)}
                                            onTheFlyWorkoutAdding={false}
                                            createNewExerciseFromWorkout={(s: string) => null}
                                            backToExerciseList={(e?:Exercise_t) => addNewExerciseToSelectedWorkout(e)}/>
                                    </div>
                                </CSSTransition>
                            </div>
                        </CSSTransition>*/}
                    </div>
                </div>
                <div hidden={!isLoading} className="assign-modal-body-loading">
                    <div className="assign-modal-body-loading-inner">
                        <div className="client-list-container-loading-spinner"/>
                        <p>{loadingMessage === "" ? "Loading..." : loadingMessage}</p>
                    </div>
                </div>
                <div hidden={isLoading || workflowState !== 1} className="assign-modal-body-complete">
                    <h4>Program Assigned!</h4>
                    <p>Your Program, "{props.information === undefined || props.information.plan === undefined || props.information.plan.title === undefined ? "this plan" : props.information.plan.title}", has been assigned to {selectedClient.first_name}. Your next workout session together will automatically display this Program.</p>
                    <div onClick={() => props.hideModalPressed()} className="feedback-modal-cta-button assign-modal-body-complete-button">Finish</div>
                </div>
                <div hidden={isLoading || workflowState !== 2} className="assign-modal-body-complete">
                    <h4>Error Occured!</h4>
                    <p>It looks like a problem occured while trying to assign this program to {selectedClient.first_name}. We're looking into it!<br/>In the meantime, exit this page and try again.</p>
                    <div onClick={() => props.hideModalPressed()} className="feedback-modal-cta-button assign-modal-body-complete-button">Exit</div>
                </div>
                <div hidden={isLoading || workflowState !== 5} className="assign-modal-body-folders">
                    <AssignModalClientFolders 
                        client={selectedClient}
                        clientFolders={clientFolders}
                        folderSelected={(f: Folder_t) => setSelectedClientFolder(f)}
                        backButtonPressed={() => setWorkflowState(0)}/>
                </div>
                <div className="assign-modal-lower">
                    <div hidden={true} onClick={() => props.hideModalPressed()} className="feedback-modal-close-button">Cancel</div>
                    <div hidden={isLoading || workflowState !== 0} onClick={() => submit()} className={`feedback-modal-cta-button ${selectedClient.first_name === "" ? "assign-modal-cta-button-unselected" : ""}`}>{assigningingFolder ? "Assign program" : "Assign plan"}</div>
                    <div hidden={isLoading || workflowState !== 5} onClick={() => submitFolder()} className={`feedback-modal-cta-button ${selectedClientFolder === undefined ? "assign-modal-cta-button-unselected" : ""}`}>Finish Assigning Folder</div>
                </div>
            </div>
		</div>
	)
}

export default AssignModal;