import React from 'react';
import firebase from '../../../Firebase';

const pushDatabaseDirect = async (
		location: string,
		data: any
	) => {
    
    return new Promise<any>( async function(resolve, reject) {
    	
    	if (!location || location === "") reject(`API/pushDatabaseDirect: Provide a valid location string. <${location}>`);
    	if (data === undefined) reject(`API/pushDatabaseDirect: Provide valid dat to set. <${data}>`);

    	try {
    		// Get the logged in user
    		let database = firebase.database();
	        let ref = database.ref(location);

	        let pushRef = await ref.push(data);
		    let key = pushRef.key === null ? '' : pushRef.key;

	        resolve(key);
			
    	} catch (err) {
    		console.log("ERROR (API/pushDatabaseDirect)", err);
    		reject(err);
    	}
	});
}

export default pushDatabaseDirect;