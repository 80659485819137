import React, { useState } from 'react';
import './Name.css';
import './Experience.css';
import TextInput from '../../components/Base/TextInput';
import { IonReactRouter } from '@ionic/react-router';


function Experience(props: any) {

  props.validation(true);

  const [expIndex, setExpIndex] = useState(0);

  function expButtonPressed(years: number) {
    setExpIndex(years);
    let totalYearOptions = [0,1,5,10];
    props.resultUpdated(totalYearOptions[years]);
  }

  return (
    <div className="header-container">
      <h1>Last step, {props.firstName}!</h1>
      <h1>How many years have you offered coaching?</h1>
      <p>It doesn't have to be exact, but this will help us better match you with local athletes.</p>
      <div className="experience-container">
        <div onClick={() => expButtonPressed(0)} className={expIndex === 0 ? "experience-selected-container" : "experience-unselected-container"}>
          <p>0 - 1<br/>Year</p>
        </div>
        <div onClick={() => expButtonPressed(1)} className={expIndex === 1 ? "experience-selected-container" : "experience-unselected-container"}>
          <p>1 - 5<br/>Years</p>
        </div>
        <div onClick={() => expButtonPressed(2)} className={expIndex === 2 ? "experience-selected-container" : "experience-unselected-container"}>
          <p>5 - 10<br/>Years</p>
        </div>
        <div onClick={() => expButtonPressed(3)} className={expIndex === 3 ? "experience-selected-container" : "experience-unselected-container"}>
          <p>10+<br/>Years</p>
        </div>
      </div>
   </div>
  )
}

export default Experience;