import React, { useState, useEffect } from 'react';
import './ExecutiveSummaryCard.css';

import '@material-ui/core';
import BusinessCenter from '@material-ui/icons/BusinessCenter';



function ExecutiveSummaryCard(props: any) {

    const [score, setScore] = useState(0)
    
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            
        }
    }, [initSet])

    useEffect(() => {
        setScore(props.score ?? 0);
    }, [props.score])

    const getScoreString = () => {
        return score >= 9.5 ? "Perfect" : score > 8 ? "Good" : score > 6 ? "Okay" : "Poor"
    }

    const getSummaryString = () => {
        return score >= 9.5 ? "amazingly" : score > 8 ? "well" : score > 6 ? "satisfactory" : "poorly"
    }

    const getAdviceString = () => {
        return score >= 9.5 ? "All metrics are where they should be." : score > 8 ? "Most metrics are where they should be" : score > 6 ? "You may want to review their metrics with them." : "It is strongly recommended to review their metrics with them."
    }

    const getScoreImageString = () => {
        return score >= 9.5 ? "-green" : score > 8 ? "" : score > 6 ? "-yellow" : "-red"
    }

	return (
		<div className={ "executive-summary-card" }>
			<div className={ "executive-summary-card-header" }>
                <div className={ "executive-summary-card-header-icon-container" }>
                    <BusinessCenter className={ "executive-summary-card-header-icon" }/>
                </div>
                <h4>Executive Summary</h4>
            </div>
            <div className={ "executive-summary-card-body-container" }>
                <div className={ "executive-summary-card-body-status-container" }>
                    <h1>{ getScoreString() }</h1>
                </div>
                <div className={ "executive-summary-card-body-status-info-container" }>
                    <h4><span>{ props.clientName } has been performing { getSummaryString() }</span> with a 4-week score of { props.score }. {getAdviceString()}</h4>
                    <p>Refer below for a comprehensive breakdown on scoring metrics.</p>
                </div>
            </div>
            <div className={ "executive-summary-card-selector-container" }>
                <div className={ "executive-summary-card-selector-demo" }></div>
            </div>
            <div className={ "executive-summary-card-bg-container" }>
                <img className="executive-summary-card-bg-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/summary-card${getScoreImageString()}-bg.png`}/>
            </div>
		</div>
	)
}

export default ExecutiveSummaryCard;