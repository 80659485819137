import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './AddExerciseToWorkoutModal.css';

import Exercise_t from '../../Interfaces/Exercise_t';

import ExerciseBuilder from '../Exercises/ExerciseBuilder';
import CreateWorkoutExerciseConfigure from './CreateWorkoutExerciseConfigure';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import HelpOutline from '@material-ui/icons/HelpOutline';

function AddExerciseToWorkoutModal(props: any) {

    let initExercise = {title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]};

    const [initSet, setInitSet] = useState(false);
    const [exercise, setExercise] = useState<Exercise_t>(initExercise);
    const [showExerciseBuilder, setShowExerciseBuilder] = useState(false);
    const [defaultTitleString, setDefaultTitleString] = useState("");
    const [forceSetCount, setForceSetCount] = useState(-1);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.information !== undefined && props.information.isEditing !== undefined && props.information.isEditing === true && props.information.forExercise !== undefined) {
            setExercise(props.information.forExercise);
        } else {
            setExercise(initExercise);
        }
        console.log("--> AddExerciseToWorkoutModal | props.information:", props.information)
        if (props.information !== undefined && props.information.forceSetCount !== undefined) {
            setForceSetCount(props.information.forceSetCount);
        } else {
            setForceSetCount(-1);
        }
    }, [props.information])

    function addNewExerciseToSelectedWorkout(e?:Exercise_t) {
        console.log("addNewExerciseToSelectedWorkout:", e, props.information)
        if (props.information !== undefined && props.information.callback !== undefined) {
            if (props.information.forExerciseSeries !== undefined && props.information.forExercise !== undefined && props.information.blockIndex === -1) {
                // Sending from WorkoutPlanListPlanWorkout / editExercisePressed()
                props.information.callback(props.information.forExerciseSeries, props.information.forExercise, e);
            } else {
                props.information.callback(props.information.blockIndex !== undefined? props.information.blockIndex : -1, e, props.information.data !== undefined ? props.information.data : undefined);
            }
        }
        props.hideModalPressed();
    }

    function openExerciseBuilder(withTitle: string) {
        setDefaultTitleString(withTitle);
        setShowExerciseBuilder(true);
    }

    function closeExerciseBuilder(withExercise?: Exercise_t) {
        // console.log("closeExerciseBuilder: ", withExercise);
        setShowExerciseBuilder(false);
        if (withExercise !== undefined) {
            // console.log("setExercise: ", withExercise);
            setExercise(withExercise);
        }
    }

    

	return (
		<div className="modal-background assign-modal-background">
            <div className="assign-modal assign-modal-max add-exercise-to-workout-modal">
                <div hidden={showExerciseBuilder} className="add-exercise-to-workout-modal-upper">
                    <div className="add-exercise-to-workout-modal-upper-close-container">
                        <div onClick={() => props.hideModalPressed()} className="add-exercise-to-workout-modal-upper-close-button">
                            <Close className="add-exercise-to-workout-modal-upper-close-button-icon"/>
                        </div>
                    </div>
                    <div className="add-exercise-to-workout-modal-upper-text-container">
                        <h4>{exercise.id === "" ? `Add ${props.information !== undefined && props.information.isBlock === true ? 'a Block' : 'another Exercise'} to your Workout` : `Edit ${exercise.title}`}</h4>
                    </div>
                    <div className="add-exercise-to-workout-modal-upper-button-container">
                        
                    </div>
                </div>
                <div hidden={!showExerciseBuilder} className="add-exercise-to-workout-modal-upper">
                    <div className="add-exercise-to-workout-modal-upper-close-container">
                        <div onClick={() => closeExerciseBuilder()} className="add-exercise-to-workout-modal-upper-close-button">
                            <ArrowBack className="add-exercise-to-workout-modal-upper-close-button-icon"/>
                        </div>
                    </div>
                    <div className="add-exercise-to-workout-modal-upper-text-container">
                        <h4>Create a new Exercise</h4>
                    </div>
                    <div className="add-exercise-to-workout-modal-upper-button-container">

                    </div>
                </div>
                <CSSTransition in={!showExerciseBuilder} timeout={200} classNames="slide" unmountOnExit>
    			    <div hidden={showExerciseBuilder} className="assign-modal-body add-exercise-to-workout-modal-body add-exercise-to-workout-modal-body-exercise-config-container">
                        <div className="assign-modal-body-exercise-config-container ">
                            <CreateWorkoutExerciseConfigure
                                exerciseToEdit={exercise}
                                forceSetCount={ forceSetCount }
                                hideTitle={true}
                                topButton={true}
                                closeModal={() => null}
                                onTheFlyWorkoutAdding={false}
                                closePressed={() => props.hideModalPressed()}
                                createNewExerciseFromWorkout={(s: string) => openExerciseBuilder(s)}
                                saveLoadingPatternPressed={(r: number[]) => props.saveLoadingPatternPressed(r)}
                                backToExerciseList={(e?:Exercise_t) => addNewExerciseToSelectedWorkout(e)}/>
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition in={showExerciseBuilder} timeout={200} classNames="slide" unmountOnExit>
                    <div className="assign-modal-body-exercise-builder-container">
                        <ExerciseBuilder 
                            showHeader={true}
                            onTheFlyExerciseAdding={true}
                            defaultTitleString={defaultTitleString}
                            exerciseUpdated={(e: Exercise_t) => null}
                            validationUpdated={(e: boolean) => null}
                            updateSaveCallback={(f: any) => null}
                            closeModal={(e?: Exercise_t) => closeExerciseBuilder(e)}/>
                    </div>
                </CSSTransition>
                <div hidden={true} className="assign-modal-lower">
                    <div hidden={true} onClick={() => props.hideModalPressed()} className="feedback-modal-close-button">Cancel</div>
                    <div onClick={() => addNewExerciseToSelectedWorkout()} className={`feedback-modal-cta-button ${exercise.id === '' ? "assign-modal-cta-button-unselected" : ""}`}>Finish & Add exercise</div>
                </div>
            </div>
		</div>
	)
}

export default AddExerciseToWorkoutModal;