import React, { useState, useEffect } from 'react';
import './GoalLogRow.css';
import './GoalLogCard.css';


function GoalLogRow(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [timestamp, setTimestamp] = useState(0);
    const [metric, setMetric] = useState(0);
    const [completion, setCompletion] = useState(0);
    const [units, setUnits] = useState("LBS");
    const [mood, setMood] = useState(6);
    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            confgureRow();
        }
    }, [initSet])

    useEffect(() => {
        confgureRow();
    }, [props.log])

    const confgureRow = () => {
        if (!props.log) return;

        if (props.log.timestamp) setTimestamp(props.log.timestamp);
        if (props.log.metric) setMetric(props.log.metric);
        if (props.log.mood) setMood(props.log.mood * 2);
        if (props.goal && props.goal.target_units_kg !== undefined) setUnits(props.goal.target_units_kg ? "KG" : "LBS");
        if (props.goal && props.goal.target_metric && props.log.metric && props.firstLog) {
            let thisDelta = props.firstLog.metric ? Math.abs(props.log.metric - props.firstLog.metric) : 0;
            let totalDelta = props.firstLog.metric ? Math.abs(props.goal.target_metric - props.firstLog.metric) : 0;

            // console.log(props.firstLog.metric, props.log.metric, props.goal.target_metric, thisDelta, totalDelta, thisDelta / totalDelta);
            setCompletion(totalDelta === 0 ? 0 : Math.floor(thisDelta / totalDelta * 100))
        }
    }

    const getDateString = (timestamp: number, fullMonth: boolean, includeYear?: boolean) => {
        const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const moStringsFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        let thisDate = new Date(timestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo_index = thisDate.getMonth();
        let thisDate_mo = fullMonth ? moStringsFull[thisDate_mo_index] : moStrings[thisDate_mo_index];
        let thisDate_yr = thisDate.getFullYear();

        let thisDate_hr = thisDate.getHours();
        let thisDate_min = thisDate.getMinutes();

        return `${thisDate_mo} ${thisDate_day}${includeYear ? `, ${thisDate_yr}` : ''}, ${thisDate_hr % 12 < 10 ? '0' : ''}${thisDate_hr % 12}:${thisDate_min < 10 ? 0 : ''}${thisDate_min}${thisDate_hr < 12 ? 'am' : 'pm'}`;
    }

	return (
		<div className={ "goal-log-row" }>
			<div className={ "goal-log-card-body-col-content-col goal-log-card-body-col goal-log-card-body-col-name" }>
                <p>{ getDateString(timestamp, true, false) }</p>
            </div>
            <div className={ "goal-log-card-body-col-content-col goal-log-card-body-col goal-log-card-body-col-measurement" }>
                <p>{ metric } { units }</p>
            </div>
            <div className={ "goal-log-card-body-col-content-col goal-log-card-body-col goal-log-card-body-col-datapoints" }>
                <p>{ completion }%</p>
            </div>
            <div className={ "goal-log-card-body-col-content-col goal-log-card-body-col goal-log-card-body-col-score" }>
                <p>{ mood } / 10</p>
            </div>
		</div>
	)
}

export default GoalLogRow;