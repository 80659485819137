import React, { useState, useEffect } from 'react';
import './ScoringMetricRow.css';
import './ScoringMetricCard.css';


function ScoringMetricRow(props: any) {

    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            
        }
    }, [initSet])

	return (
		<div className={ "scoring-metric-row" }>
			<div className={ "scoring-metric-card-body-col-content-col scoring-metric-card-body-col scoring-metric-card-body-col-name" }>
                <div className={ `scoring-metric-card-body-col-name-icon scoring-metric-card-body-col-name-icon-${props.scoringMetric.status === "perfect" ? "green" : props.scoringMetric.status === "good" ? "blue" : props.scoringMetric.status === "okay" ? "yellow" : "red"}` }></div>
                <p>{ props.scoringMetric.title }</p>
            </div>
            <div className={ "scoring-metric-card-body-col-content-col scoring-metric-card-body-col scoring-metric-card-body-col-measurement" }>
                <p>{ props.scoringMetric.measurement_string }</p>
            </div>
            <div className={ "scoring-metric-card-body-col-content-col scoring-metric-card-body-col scoring-metric-card-body-col-datapoints" }>
                <p>{ props.scoringMetric.target_range_string }</p>
            </div>
            <div className={ "scoring-metric-card-body-col-content-col scoring-metric-card-body-col scoring-metric-card-body-col-score" }>
                <p>{ props.scoringMetric.score } / 10</p>
            </div>
		</div>
	)
}

export default ScoringMetricRow;