import React, { useState, useEffect } from 'react';
import './MyNodes.css';

import NodeSet_t from '../../../Interfaces/NodeSet_t';
import NodeInstance from '../../../NodeInstance';
import PageHeader_t from '../../../Interfaces/PageHeader_t';

import PageHeader from '../../../components/Base/PageHeader';
import NodeSetCell from '../../../components/Nodes/NodeSetCell';
import NodeListCell from '../../../components/Nodes/NodeListCell';
import InfoBlock from '../../../components/Base/InfoBlock';

import '@material-ui/core';
import AddCircleOutlineRounded from '@material-ui/icons/AddCircleOutlineRounded';

function MyNodes(props: any) {

    let pageOptions: PageHeader_t[] = [{ name:"All Nodes", alert:false }];

    const [pageNumber, setPageNumber] = useState(0);
    const [initSet, setInitSet] = useState(false);
    const [selectedSetIndex, setSelectedSetIndex] = useState(0);
    const [removeSetSIN, setRemoveSetSIN] = useState("");
    const [pingState, setPingState] = useState(false);
    const [nodeSets, setNodeSets] = useState<NodeSet_t[]>([]);

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    useEffect(() => {
        if (props.nodeManager !== undefined) {
            
            let t = props.nodeManager.getMyNodesList()
            configureNodeSets(t);
        }
        

        //configureNodeSets(props.myNodesList);
    }, [props.myNodesList]);

    function configureNodeSets(withList: any[]) {
        var tempNodeSets: NodeSet_t[] = [];

        setNodeSets(nodeSets.filter(item => false));
        setNodeSets([]);

        if (withList.length > 0) {
            for (var i = 0; i < withList.length; i++) {
                let thisNode: NodeInstance = withList[i];
                let thisSetSIN = thisNode.getSetSIN();

                var tempSetSINIndex = -1;
                if (tempNodeSets.length > 0) {
                    for (var j = 0; j < tempNodeSets.length; j++) {
                        let thisSet = tempNodeSets[j];
                        if (thisSet.setSIN === thisSetSIN) {
                            tempSetSINIndex = j;
                        }
                    }
                }

                if (tempSetSINIndex === -1) {
                    let newSet: NodeSet_t = {setSIN: thisSetSIN, UUIDs: [thisNode.getUUID()], setSize: 9}
                    tempNodeSets.push(newSet);
                } else {
                    tempNodeSets[tempSetSINIndex].UUIDs.push(thisNode.getUUID());
                }
            }
        }

        
        setNodeSets(nodeSets.concat(tempNodeSets));
    }

    function discoverNodesPressed() {
        props.discoverNodesPressed();
    }

    function setSelected(forSetSIN: string) {
        for (var i = 0; i < nodeSets.length; i++) {
            if (forSetSIN === nodeSets[i].setSIN) {
                setSelectedSetIndex(i);
            }
        }
    }

    function pingAllPressed(forSetSIN: string) {
        if (props.nodeManager !== undefined) {
            props.nodeManager.pingSet(forSetSIN);
        }
        if (forSetSIN === nodeSets[selectedSetIndex].setSIN) {
            setPingState(true);
            setTimeout(() => {
                setPingState(false);
                
            }, 500);
        }
    }

    function pingNodePressed(forUUID: string) {
        if (props.nodeManager !== undefined) {
            props.nodeManager.pingNode(forUUID);
        }
    }

    function forgetPressed(forSetSIN: string) {
        setNodeSets(nodeSets.filter(item => false));
        setNodeSets([]);
        //setRemoveSetSIN(forSetSIN);

        props.getUserConfirmation({
            title: "Remove entire set of Nodes?",
            text: "Are you sure you want to remove this set of Nodes? All settings for these Nodes will be lost, but you can easily re-connect under 'Discover Nodes'.",
            isRed: true,
            callback: forget,
            callbackParam: forSetSIN,
            ctaText: "Forget"
        })
        
    }

    function forget(forSetSIN: string) {
        // console.log("REMOVING NODES CONFIRMED");
        setSelectedSetIndex(0);
        if (props.nodeManager !== undefined) {
            // console.log(`REMOVING AT ${forSetSIN} `)
            props.nodeManager.removeSet(forSetSIN);

            setNodeSets(nodeSets.filter(item => false));
            //let t = props.nodeManager.getMyNodesList()
            //configureNodeSets(t);
        }
    }

    function getSelectedSINString() {
        if (nodeSets[selectedSetIndex] === undefined) {
            return;
        }
        return nodeSets.length > 0 ? `Set SIN ${nodeSets[selectedSetIndex].setSIN}` : "No Set Selected";
    }

    function refresh() {
        if (props.nodeManager !== undefined) {
            props.nodeManager.scanForNodes();
        }
    }

    function getNodeCells() {
        var list: any[] = [];
        if (nodeSets.length > 0) {
            let UUIDList = nodeSets[selectedSetIndex].UUIDs;
            if (UUIDList.length > 0) {
                for (var i = 0; i < UUIDList.length; i++) {
                    let thisUUID = UUIDList[i];
                    let newCell = <NodeListCell nodeManager={props.nodeManager} pingPressed={()=>pingNodePressed(thisUUID)} key={i} pingState={pingState} uuid={UUIDList[i]} index={i}/>
                    list.push(newCell);
                }
            }
        }

        return list;
    }

	return (
		<div className="my-nodes-container">
            <div className="home-header">
                <PageHeader 
                    pageName="My Nodes" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
                    addNewWorkoutPlan={() => props.createNewWorkoutPlan !== undefined ? props.createNewWorkoutPlan() : null}
                    addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
            
            <div hidden={nodeSets.length === 0} className="my-nodes-content-container">
                <div className="my-nodes-content-container-left">
                    <div className="page-title-header-container">
                        <h1>My Nodes</h1>
                    </div>
        			<div className="my-nodes-set-list-container">
                        {
                            nodeSets.map((item: NodeSet_t, index: number) => (
                                <NodeSetCell
                                    key={index}
                                    pingState={pingState && index === selectedSetIndex}
                                    isSelected={selectedSetIndex === index}
                                    setSIN={item.setSIN}
                                    totalNodes={item.setSize}
                                    connectedNodes={item.UUIDs.length}
                                    connectedNodesUUIDs={item.UUIDs}
                                    nodeManager={props.nodeManager}
                                    setSelected={(e: string) => setSelected(e)}
                                    pingAllPressed={(e: string) => pingAllPressed(e)}
                                    forgetPressed={(e: string) => forgetPressed(e)}/>
                            ))
                        }
                        
                    </div>
                </div>
                <div className="my-nodes-node-list-container">
                    <div className="my-nodes-node-list-container-card">
                        <div className="my-nodes-node-list-header-container">
                            <h2>{getSelectedSINString()}</h2>
                            <div className="my-nodes-node-list-header-button-container">
                                <div onClick={() => refresh()} className="my-nodes-node-list-header-button-refresh">Refresh</div>
                                <div onClick={() => pingAllPressed(nodeSets[selectedSetIndex].setSIN)} className="my-nodes-node-list-header-button-ping-all">Ping All</div>
                                <div onClick={() => forgetPressed(nodeSets[selectedSetIndex].setSIN)} className="my-nodes-node-list-header-button-forget">Forget Set</div>
                            </div>
                        </div>
                        <div className="my-nodes-node-list-content">
                            <InfoBlock infoText="Not sure which Node is which? Tap the cell below to send it a ping."/>
                            {getNodeCells()}
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={nodeSets.length > 0} className="page-title-header-container">
                <h1>My Nodes</h1>
            </div>
            <div hidden={nodeSets.length > 0} className="my-nodes-empty-container discover-nodes-empty-container">
                <div className="discover-nodes-empty-image-container">
                    <img className="discover-nodes-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/devices/nodes-x3-01.svg`}/>
                </div>
                <h2>No Nodes Sets found</h2>
                <p>Get started pairing your first set of Nodes by tapping below.</p>
                <div onClick={() => props.discoverNodesPressed()} className="my-nodes-cta-button">Pair Nodes <AddCircleOutlineRounded style={{ fontSize: 14 }} className="my-nodes-add-icon"/></div>
            </div>
		</div>
	)
}

export default MyNodes;