import React, { useState, useEffect } from 'react';
import './CalendarDayTimeline.css';

import ScheduleDay_t from '../../Interfaces/ScheduleDay_t';

import CalendarDayTimelineCell from './CalendarDayTimelineCell';

function CalendarDayTimeline(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [twoHourSection, setTwoHourSection] = useState<any[]>([]);
    const [trainerSchedule, setTrainerSchedule] = useState<ScheduleDay_t[]>([]);
    const [schedulingConflict, setSchedulingConflict] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {

    }, [props.dayAppointments])

    useEffect(() => {
        if (props.selectedDuration !== undefined) {

        }
    }, [props.selectedDuration])

    useEffect(() => {
        if (props.trainerSchedule !== undefined) {
            setTrainerSchedule(props.trainerSchedule);
        }
    }, [props.trainerSchedule])

    

    function initialize() {
        let minTime = props.dayStartTime === undefined ? 480 : props.dayStartTime;
        let maxTime = props.dayEndTime === undefined ? 1080 : props.dayEndTime;;

        let twoHourCount = Math.ceil((maxTime - minTime) / 60 / 2) + 1;
        var twoHourSection_temp: any[] = [];
        for (var i = 0; i < twoHourCount; i++) {
            twoHourSection_temp.push({
                start_time_hrs: (minTime / 60) + (2 * i)
            });
        }
        setTwoHourSection(twoHourSection_temp);
    }



    function getTimeString(item: any) {
        var startTime = item.start_time_hrs % 12;
        startTime = startTime === 0 ? 12 : startTime;

        return `${startTime}:00`
    }

    function getDuration(item: ScheduleDay_t) {
        if (item.scheduled === undefined) { return 0; }

        let startTime = item.scheduled.starting_timestamp === undefined ? 0 : item.scheduled.starting_timestamp;
        let endTime = item.scheduled.ending_timestamp === undefined ? 0 : item.scheduled.ending_timestamp;

        let dt_ms = endTime - startTime;
        let dt_min = Math.floor(dt_ms / 60000);

        return dt_min;
    }

    function getStartTime(item: ScheduleDay_t) {
        if (item.scheduled === undefined) { return 480; }

        let startTime = item.scheduled.starting_timestamp === undefined ? 0 : item.scheduled.starting_timestamp;
        let startDate = new Date(startTime);
        let startHr = startDate.getHours();// - (startDate.getTimezoneOffset() / 60);
        let startMin = startDate.getMinutes();


        return (startHr * 60) + startMin;
    }

    function getEndTime(item: ScheduleDay_t) {
        if (item.scheduled === undefined) { return 480; }

        let startTime = item.scheduled.ending_timestamp === undefined ? 0 : item.scheduled.ending_timestamp;
        let startDate = new Date(startTime);
        let startHr = startDate.getHours();// - (startDate.getTimezoneOffset() / 60);
        let startMin = startDate.getMinutes();


        return (startHr * 60) + startMin;
    }

    function checkForConflict() {

        if (props.trainerSchedule === undefined || props.trainerSchedule.length <= 0) { return false; }

        let thisStartTime_mins = props.selectedStartTime + props.dayStartTime;
        let thisEndTime_mins = thisStartTime_mins + props.selectedDuration;

        var conflictExists = false;
        for (var i = 0; i < props.trainerSchedule.length; i++) {
            let thisScheduleEvent: ScheduleDay_t = props.trainerSchedule[i];
            if (thisScheduleEvent.scheduled !== undefined) {
                // get start time in mins
                let startTime = getStartTime(thisScheduleEvent);
                let endTime = getEndTime(thisScheduleEvent);

                // console.log("Times: ", thisStartTime_mins, thisEndTime_mins, startTime, endTime);

                if (thisStartTime_mins > startTime && thisStartTime_mins < endTime) {
                    conflictExists = true;
                }
                if (thisEndTime_mins > startTime && thisEndTime_mins < endTime) {
                    conflictExists = true;
                }
                if (thisEndTime_mins >= endTime && thisStartTime_mins <= startTime) {
                    conflictExists = true;
                }
            }
        }

        props.conflictUpdated(conflictExists);
        //// console.log("CONFLICT: ", conflictExists);
        return conflictExists;
    }


	return (
		<div className="calendar-day-timeline">
            <div className="calendar-day-timeline-inner">
    			{
                    twoHourSection.map((item: any, index: number) => (
                        <div className={`calendar-day-timeline-section ${index === twoHourSection.length - 1 ? 'calendar-day-timeline-section-end' : ''}`}>
                            <div className="calendar-day-timeline-section-time-container">
                                <p>{getTimeString(item)}</p>
                            </div>
                            <div className="calendar-day-timeline-section-content">
                                <div className="calendar-day-timeline-section-content-inner"></div>
                                <div className="calendar-day-timeline-section-content-inner"></div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="calendar-day-timeline-appts-container">
                <div className="calendar-day-timeline-appts-container-inner">
                    <CalendarDayTimelineCell 
                        isScheduling={true}
                        schedulingConflict={checkForConflict()}
                        selectedClient={props.selectedClient}
                        selectedDuration={props.selectedDuration}
                        selectedStartTime={props.selectedStartTime}
                        dayStartTime={props.dayStartTime}/>
                    {
                        trainerSchedule.map((item: ScheduleDay_t, index: number) => (
                            <CalendarDayTimelineCell 
                                isScheduling={false}
                                clientID={item.client_id}
                                selectedClient={props.selectedClient}
                                selectedDuration={getDuration(item)}
                                selectedStartTime={getStartTime(item)}
                                dayStartTime={props.dayStartTime}/>
                        ))
                    }
                </div>
            </div>
		</div>
	)
}

export default CalendarDayTimeline;