import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import './Clients.css';
import './DashboardPageCommon.css';
import firebase from '../../Firebase';
import Client_t from '../../Interfaces/Client_t';
import PageHeader_t from '../../Interfaces/PageHeader_t';
import StandardButton from '../../components/Base/StandardButton';
import PageHeader from '../../components/Base/PageHeader';

import ClientList from './ClientWorkflow/ClientList'
import SelectWorkout from './ClientWorkflow/SelectWorkout'
import BlankPage from '../../components/PageElements/BlankPage'

import '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


function Clients(props: any) {

	let pageOptions: PageHeader_t[] = [{ name:"Client list", alert:false }, 
					   				   { name:"Clients at my gym", alert:false }]

	const [okayToProceed, setOkayToProceed] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [workflowState, setWorkflowState] = useState(0);
	const [selectedClient, setSelectedClient] = useState<Client_t>({first_name: '',last_name: '',full_name: '',id: '',isSelected: false});


	const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        // console.log("CLIENTS: INIT");
    }

	function clientSelected(client: Client_t) {
		setSelectedClient(client);
		props.showClientProfile(client);
		setOkayToProceed(true);
	}

	function workoutSelected() {
		setOkayToProceed(true);
	}

	function nextButtonPressed() {
		setWorkflowState(workflowState + 1);
		setOkayToProceed(false);
	}

	function backButtonPressed() {
		if (workflowState === 0) { return; }
		setWorkflowState(workflowState - 1);
	}

	function moreInfoSelected(client: Client_t) {
		props.moreInfoSelected(client);
	}

	function createNewClient() {
		props.createNewClient();
	}

	function pageSelected(newPage: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setPageNumber(i);
			}
		}
	}

	return (
		<div className="page-container">
			<div className="client-header">
				<PageHeader 
					pageName="Clients" 
					pageOptions={pageOptions}
					pageSelected={(e: string) => pageSelected(e)}
					workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
					addNewWorkout={() => props.addNewWorkout !== undefined ? props.addNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
			</div>
			<div className="page-container-no-padding" hidden={pageNumber != 0}>
				<ClientList 
					personalClients={true}
					addNewClientPressed={() => createNewClient()}
					moreInfoSelected={(e: Client_t) => moreInfoSelected(e)}
					clientSelected={(e: Client_t) => clientSelected(e)}/>
			</div>
			<div className="page-container-no-padding" hidden={pageNumber != 1}>
				<ClientList 
					personalClients={true}
					addNewClientPressed={() => createNewClient()}
					moreInfoSelected={(e: Client_t) => moreInfoSelected(e)}
					clientSelected={(e: Client_t) => clientSelected(e)}/>
			</div>
			<div className="page-container-no-padding" hidden={pageNumber != 2}>
				<BlankPage pageName="Conversations"/>
			</div>
			<div className="page-container-no-padding" hidden={workflowState != 1}>
				<SelectWorkout 
					client={selectedClient} 
					backButtonPressed={() => backButtonPressed()}
					workoutSelected={(e: Client_t) => clientSelected(e)}/>
			</div>
			
		</div>
	)
}

/*
*/

export default Clients;