import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import './NewWorkoutModal.css';
import './ConfirmModal.css';
import './FeedbackModal.css';

import FormLongTextInput from '../Base/FormLongTextInput';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

// modal data:
//	{ title: "", text: "", isRed: boolean, callback: any, ctaText: "" }
//
//
//


function FeedbackModal(props: any) {

	const [note, setNote] = useState("");
	const [workflowState, setWorkflowState] = useState(0);
	const [inputAlert, setInputAlert] = useState(false);
	

	function closePressed() {
		props.hideModalPressed();
	}

	function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'note':
                setNote(toString);
                if (inputAlert === true) {
                	if (toString.split("").length >= 5) {
                		setInputAlert(false);
                	}
                }
                break;
            default:
                break;
        }
    }

    function submit() {
    	if (note.split("").length >= 5) {
    		setWorkflowState(1);
    		 Mixpanel.track("Submitting user feedback");
    		 Mixpanel.people.increment("Feedback submitted");

			let currentUser = firebase.auth().currentUser;
			if (!currentUser) { return; }
			let trainerUID = currentUser.uid;

			let database = firebase.database();
			let ref = database.ref(`personal_trainer_profile/${trainerUID}`); // Change to database path
			ref.on('value', function(snapshot) {
			    if (snapshot.exists() === true) {
			        let data = snapshot.val();

			        let noteDataToPost: any = {			        	
			        	time_submitted: Date.now(),
			        	human_timestamp: new Date().toString(),
			        	note: note,
			        	user: {
			        		user_id: trainerUID,
			        		is_trainer: true,
			        		email: data.email !== undefined && data.email !== null ? data.email : "",
				        	first_name: data.first_name !== undefined && data.first_name !== null ? data.first_name : "",
				        	full_name: data.full_name !== undefined && data.full_name !== null ? data.full_name : "",
			        		company: data.company_name !== undefined && data.company_name !== null ? data.company_name : "",
			        		city: data.city !== undefined && data.city !== null ? data.city : "",
			        	},
			        	resolution: {
			        		has_responded: false
			        	}
			        }

			        let pushRef = database.ref(`issues/${trainerUID}`).push(noteDataToPost);
			        let pushKey = pushRef.key;

			        sendAdminEmail(noteDataToPost, pushKey);

			        setTimeout(() => {
			        	setWorkflowState(2);
			        }, 900);
			        
			        // Parse and use data here
			        
			    }
			});
    	}
    }

    function sendAdminEmail(data: any, key: any) {

    	let dbLocation = `https://console.firebase.google.com/u/1/project/movement-tracker-457bc/database/movement-tracker-457bc/data/issues/${data.user.user_id}/${key !== null ? key : ''}`
    	axios.get(`https://eigenfitness.com/email/user_feedback_submitted.php?submitted_on=${data.human_timestamp}&email=${data.user.email}&trainer_name=${data.user.full_name}&trainer_first_name=${data.user.first_name}&website=${dbLocation}&gym_name=${data.user.company}&city=${data.user.city}&note=${data.note}`,
        { headers: {'Content-Type': 'application/json'} })
	    .then(response => {
	        // console.log(response);
	        // console.log(response.data);
	        let data = response.data;
	    })
	    .catch(e => {
	        // console.log(e);
		})
    }

	return (
		<div className="modal-background feedback-modal-background">
			<div className="feedback-modal-container">
				<div onClick={() => closePressed()} className="feedback-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
				<div hidden={workflowState !== 0} className="feedback-modal-top">
					<h3>How can we help?</h3>
					<p>Whether you've found a bug that needs to be squashed, have a feature you'd love to see, or want to reach out and ask a question, let us know what's on your mind and we'll get back to you within 24 hours.</p>
				</div>
				<div hidden={workflowState !== 0} className="feedback-modal-body-container">
					<div className="feedback-modal-input-container">
						<FormLongTextInput 
                            value={note}
                            placeholder="What's on your mind?" 
                            alert={inputAlert} 
                            alertText=""
                            isSearch={true}
                            isOptional={false}
                            inputChanged={(e: string) => inputChanged(e, 'note')}/>
                    </div>
				</div>
				<div hidden={workflowState !== 0} className="feedback-modal-bottom">
					<div onClick={() => closePressed()} className="feedback-modal-close-button">Cancel</div>
					<div onClick={() => submit()} className="feedback-modal-cta-button">Send Message</div>
				</div>
				<div hidden={workflowState !== 1} className="feedback-modal-body-container feedback-modal-body-container-loading">
					<div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Sharing your message...</p>
				</div>
				<div hidden={workflowState !== 2} className="feedback-modal-body-container feedback-modal-body-container-done">
					<h4>Thanks for your message!</h4>
                    <p>We'll get back to you within 24 hours regarding your comments and questions. Talk soon!</p>
                    <div onClick={() => closePressed()} className="feedback-modal-cta-button feedback-modal-cta-button-done">Done</div>
				</div>
			</div>
		</div>
	)
}

export default FeedbackModal;

