import React, { useState } from 'react';
import firebase from '../../../Firebase';

import './CreatePlanWorkoutListCell.css';
import '../../Workouts/WorkoutBuilder/CreateWorkoutExerciseListCell.css';

import Workout_t from '../../../Interfaces/Workout_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';

import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import UpArrow from '@material-ui/icons/ArrowUpward';
import DownArrow from '@material-ui/icons/ArrowDownward';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';

function CreatePlanWorkoutConfigureCell(props: any) {
    let initWorkout: Workout_t = {
                        title: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        duration: 0,
                        imageName: '',
                        targetGender: '',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0
                    };
    let muscleNames: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]
        ]

    const [initSet, setInitSet] = useState(false);
    const [workoutIndex, setWorkoutIndex] = useState(0);
    const [maxWorkoutIndex, setMaxWorkoutIndex] = useState(5);
    const [exercise, setExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[],reps:[]});
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [imgUrl, setImgUrl] = useState('');
    const [musclesString, setMusclesString] = useState('');

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setWorkout(props.workout);
        setWorkoutIndex(props.workoutIndex);
        setMaxWorkoutIndex(props.maxWorkoutIndex);
        getImage();
        getMusclesString();
    }


    function getImage() {
        // const storage = firebase.storage();
        // //// console.log(props.client.id);
        // storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
        //    .then(fireBaseUrl => {
        //      setImgUrl(fireBaseUrl);
        //    })
        //    .catch(error => {
        //        //// console.log('No image found');
        //        //// console.log(error);
        //    })
    }

    function getMusclesString() {

        if (props.workout.id === "") { return; }

        let database = firebase.database();
        let ref = database.ref(`workouts/${props.workout.id}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let workoutObj = snapshot.val();

                // Determine previous workout muscle groups
                let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
                var muscleGroupTags_temp: string[] = [];
                let exercises = workoutObj.exercises;
                let exerciseKeys = Object.keys(exercises);
                for (var i = 0; i < exerciseKeys.length; i++) {
                    let thisKey = exerciseKeys[i];
                    let thisExercise = exercises[thisKey];

                    let thisExerciseMuscles = thisExercise.muscles;
                    let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

                    for (var j = 0; j < thisExerciseMusclesKeys.length; j++) {
                        let thisMuscleKey = thisExerciseMusclesKeys[j];
                        let thisMuscle = thisExerciseMuscles[thisMuscleKey];
                        let thisMuscleGroup = Number(thisMuscle.group);

                        let thisMuscleString = muscle_groups[thisMuscleGroup];
                        if (!muscleGroupTags_temp.includes(thisMuscleString)) {
                            muscleGroupTags_temp.push(thisMuscleString);
                        }
                    }
                }
                var string_temp = "";
                for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
                    string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
                }
                setMusclesString(string_temp);
            }
        });
    }

    function upArrowPressed() {
        // console.log("MOVING UP");
        if (workoutIndex > 0) {
            // console.log("UPUPUP");
            props.moveWorkoutUp(workoutIndex);
        }
    }

    function downArrowPressed() {
        // console.log("MOVING DOWN");
        if (workoutIndex < maxWorkoutIndex) {
            // console.log("DOWNDOWNDOWN");
            props.moveWorkoutDown(workoutIndex);
        }
    }

    function removeWorkout() {
        props.removeWorkout()
    }

    function getDifficultyString() {
        if (props.workout.id === "") { return ""; }
        let difficulties = ["Very easy", "Low intensity", "Mildly intense", "Moderatly intense", "Highly intense", "Extremely intense"]
        let difficultyString = difficulties[props.workout.difficulty];

        return difficultyString;
    }

    function getDurationString() {
        if (props.workout.id === "") { return ""; }
        return `${props.workout.duration} minutes`
    }

    function getIsRestDay() {
        return props.workout === undefined || props.workout.exerciseSeries === undefined || props.workout.exerciseSeries.length === 0;
    }

    function getNumberSets() {
        if (props.workout.id === "") { return 0; }
        if (props.workout.exercises === undefined) { return 0; }

        var numSets = 0;
        for (var i = 0; i < props.workout.exercises.length; i++) {
            let reps = props.workout.exercises[i].reps;
            if (reps !== undefined) {
                numSets += reps.length
            }
        }

        return numSets;
    }

    function editWorkout() {
        props.editWorkout()
    }

    function addToEmptyWorkout() {
        props.addToEmptyWorkout();
    }

    return (
        <div className="create-plan-workout-list-cell-wrapper">
            <div className="create-plan-workout-list-cell-arrow-container">
                <div onClick={() => upArrowPressed()} hidden={props.workoutIndex === 0} className="create-plan-workout-list-cell-arrow-up">
                    <UpArrow className="create-plan-workout-list-cell-arrow-up-icon"/>
                </div> 
                <div hidden={props.workoutIndex !== 0} className="create-plan-workout-list-cell-arrow-up create-plan-workout-list-cell-arrow-up-placeholder">
                </div> 
                <div onClick={() => downArrowPressed()} hidden={props.workoutIndex === props.maxWorkoutIndex} className="create-plan-workout-list-cell-arrow-up">
                    <DownArrow className="create-plan-workout-list-cell-arrow-up-icon"/>
                </div> 
                <div hidden={props.workoutIndex !== props.maxWorkoutIndex} className="create-plan-workout-list-cell-arrow-down create-plan-workout-list-cell-arrow-down-placeholder">
                </div> 
            </div>
            <div className="create-plan-workout-list-day-container">
                <p>Day</p>
                <h4>{props.workoutIndex + 1 < 10 ? '0' : ''}{props.workoutIndex + 1}</h4>
            </div>
            <div className="create-plan-workout-list-cell-container">
                <div className="create-plan-workout-list-placeholder-day-container">
                    <div hidden={getIsRestDay()} className="create-plan-workout-list-placeholder-day-container-content create-plan-workout-list-configure-cell-content">
                        <div className="create-plan-workout-list-configure-cell-info-card-col create-plan-workout-list-configure-cell-info-card-info-container create-plan-workout-list-configure-cell-info-card-info-container-title">
                            <p>{musclesString}</p>
                            <h4>{workout.title}</h4>
                        </div>
                        <div className="create-plan-workout-list-configure-cell-info-card-col create-plan-workout-list-configure-cell-info-card-info-container">
                            <p>Duration</p>
                            <h4>{getDurationString()}</h4>
                        </div>
                        <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-list-configure-cell-info-card-info-container">
                            <p>Volume</p>
                            <h4>{props.workout.exercises !== undefined ? props.workout.exercises.length : 0} exercise{props.workout.exercises !== undefined ? (props.workout.exercises.length === 1 ? '' : 's') : 's'}</h4>
                        </div>
                        <div onClick={() => editWorkout()} className="create-plan-workout-list-cell-button-container create-plan-workout-list-cell-edit-container">
                            <Edit className="create-plan-workoute-list-cell-button-icon create-plan-workoute-list-cell-edit-icon" style={{ fontSize: 18 }}/>
                            <p>Edit</p>
                        </div>
                        <div onClick={() => removeWorkout()} className="create-plan-workout-list-cell-button-container create-plan-workout-list-cell-remove-container">
                            <Close className="create-plan-workout-list-cell-button-icon create-plan-workoute-list-cell-remove-icon" style={{ fontSize: 18 }}/>
                            <p>Remove</p>
                        </div>
                    </div>
                    <div hidden={!getIsRestDay()} className="create-plan-workout-list-placeholder-day-container-content create-plan-workout-list-configure-cell-rest">
                        <div className="create-plan-workout-list-configure-cell-rest-title-container">
                            <p>Rest day</p>
                        </div>
                        <div onClick={() => addToEmptyWorkout()} className="create-plan-workout-list-configure-cell-rest-button-container">
                            <p>Create a new Workout</p>
                            <ArrowForward className="create-plan-workout-list-configure-cell-rest-button-icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*
<div className="create-workout-exercise-list-adjust-container">
                <div onClick={() => upArrowPressed()}><UpArrow style={{ fontSize: 14 }} className={workoutIndex > 0 ? "create-workout-exercise-list-adjust-arrow-active" : "create-workout-exercise-list-adjust-arrow-inactive"}/></div>
                <div className="create-workout-exercise-list-adjust-circle">
                    <p>{workoutIndex + 1}</p>
                </div>
                <div onClick={() => downArrowPressed()}><DownArrow style={{ fontSize: 14 }} className={workoutIndex < maxWorkoutIndex ? "create-workout-exercise-list-adjust-arrow-active" : "create-workout-exercise-list-adjust-arrow-inactive"}/></div>
            </div>
            <div className="create-plan-workout-list-configure-cell-content">
                <div className="workout-cell-image-container">
                    <img  
                        className="create-plan-workout-list-cell-configure-cell-image" 
                        hidden={imgUrl === ''}
                        src={imgUrl} 
                        alt={`Image for ${props.workout.title}`} />
                    </div>
                <div className="create-plan-workout-list-configure-cell-info-card-col create-plan-workout-list-configure-cell-info-card-info-container create-plan-workout-list-configure-cell-info-card-info-container-title">
                    <h4>{workout.title}</h4>
                    <p>{musclesString}</p>
                </div>
                <div className="create-plan-workout-list-configure-cell-info-card-col create-plan-workout-list-configure-cell-info-card-info-container">
                    <h4>{getDurationString()}</h4>
                    <div className="create-plan-workout-list-configure-cell-info-card-info-trained-with-me-container">
                        <div className="create-plan-workout-list-configure-cell-info-card-info-trained-with-me-icon"/>
                        <p>{getDifficultyString()}</p>
                    </div>
                </div>
                <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container">
                    <h4>{props.workout.exercises !== undefined ? props.workout.exercises.length : 0} exercises</h4>
                    <p>{getNumberSets()} sets total</p>
                </div>
                <div onClick={() => removeWorkout()} className="create-workout-exercise-list-cell-remove-container">
                    <Close className="create-workout-exercise-list-cell-remove-icon" style={{ fontSize: 18 }}/>
                </div>
            </div>
*/

export default CreatePlanWorkoutConfigureCell;