import React, { useState, useEffect } from 'react';
import './NodeStreamAnalyzeStream.css';

import {Line, Bar} from 'react-chartjs-2';

interface RepWindow_t {
    start_index: number;
    end_index: number;
    is_rep: boolean;
}

function NodeStreamAnalyzeStream(props: any) {

    

    // Quaternion plotting
    let initDataSets_q = {
        labels: [],
        datasets: [
            {
                label: 'q_w',
                backgroundColor: 'rgba(255,255,255,0)',
                hoverBackgroundColor: `rgba(255,255,255,0.9)`,
                borderColor: 'rgba(255,255,255,1)',
                hoverBorderColor: `rgba(255,255,255,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_x', // Blue
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_y',    // Red
                backgroundColor: 'rgba(193,89,73,0)',
                hoverBackgroundColor: `rgba(193,89,73,0.9)`,
                borderColor: 'rgba(193,89,73,1)',
                hoverBorderColor: `rgba(193,89,73,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_z', // Green
                backgroundColor: 'rgba(73,193,89,0)',
                hoverBackgroundColor: `rgba(73,193,89,0.9)`,
                borderColor: 'rgba(73,193,89,1)',
                hoverBorderColor: `rgba(73,193,89,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            }
        ]
    }
    var chartOptions_q: any = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0
        },
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        elements:{
            point:{
                borderWidth: 0,
                radius: 0,
                backgroundColor: 'rgba(0,0,0,0)'
            }
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 0.5,
                    callback: function(value: any, index: any, values: any) {
                        return Math.floor(value * 10) / 10 + ``;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
    }

    // Vector plotting
    let initDataSets_v = {
        labels: [],
        datasets: [
            {
                label: 'v_x', // Blue
                backgroundColor: 'rgba(73,89,193,1)',
                hoverBackgroundColor: `rgba(73,89,193,1)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 2,
                type: 'line',
                data: []
            },
            {
                label: 'v_y',    // Red
                backgroundColor: 'rgba(193,89,73,0)',
                hoverBackgroundColor: `rgba(193,89,73,0.9)`,
                borderColor: 'rgba(193,89,73,1)',
                hoverBorderColor: `rgba(193,89,73,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            },
            {
                label: 'v_z', // Green
                backgroundColor: 'rgba(73,193,89,0)',
                hoverBackgroundColor: `rgba(73,193,89,0.9)`,
                borderColor: 'rgba(73,193,89,1)',
                hoverBorderColor: `rgba(73,193,89,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            }
        ]
    }
    var chartOptions_v: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        elements:{
            point:{
                borderWidth: 0,
                radius: 0,
                backgroundColor: 'rgba(0,0,0,0)'
            }
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 5,
                    // max: 135,
                    min: 0,
                    callback: function(value: any, index: any, values: any) {
                        return Math.floor(value) + ``;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
    }

    const [runML, setRunML] = useState(false);

    const [detectedRepWindows, setDetectedRepWindows] = useState<RepWindow_t[]>([])

    const [dataSets_v, setDataSets_v] = useState(initDataSets_v);
    const [dataSets_q_int, setDataSets_q_int] = useState(initDataSets_v);
    const [dataSets_q, setDataSets_q] = useState(initDataSets_q);
    const [chartSettings_v, setChartSettings_v] = useState(chartOptions_v);
    const [chartSettings_q, setChartSettings_q] = useState(chartOptions_q);

    const [viewingLocationIndex, setViewingLocationIndex] = useState(0);

    const [initSet, setInitSet] = useState(false);

    const [numPts, setNumPts] = useState(1);

    const [resultString, setResultString] = useState("Init");

    const [repCount, setRepCount] = useState(0);
    const [posCount, setPosCount] = useState(0);
    const [posBCount, setPosBCount] = useState(0);

    const [latestRepIndex, setLatestRepIndex] = useState(0);

    const [peakStat, setPeakStat] = useState(0);
    const [peakStats, setPeakStats] = useState<number[]>([]);

    const maxPosCount = 2;
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.numPts !== undefined) {
            setNumPts(props.numPts);
        }
    }, [props.numPts])

    useEffect(() => {
        // console.log("Q STREAM UDPATED!!!")
        updateWindow(props.qStream, props.videoProgress);
    }, [props.qStream])

    useEffect(() => {
        updateWindow(props.qStream, props.videoProgress);
    }, [props.videoProgress])

    useEffect(() => {
        if (props.viewingLocationIndex !== undefined) {
            setViewingLocationIndex(props.viewingLocationIndex);
            setLatestRepIndex(0);
            setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }));
            setPeakStats(peakStats.filter((item, index) => { return false; }));
            setRepCount(0);
            //props.updateDetectedRepWindows([]);
        }
    }, [props.viewingLocationIndex])

    useEffect(() => {
        if (runML === true) {
            // console.log("detectedRepWindows from NodeStreamAnalyzeStream", detectedRepWindows)
            props.updateDetectedRepWindows(detectedRepWindows);
        }
    }, [detectedRepWindows])

    function updateWindow(withStream: {w: number, x: number, y: number, z: number}[], progress: number) {
        if (withStream === undefined || progress === undefined || withStream.length <= 0 || progress < 0 || progress > 1) { 
            //// console.log("Error in NodeStreamAnalyzeStream | updateWindow", props.qStream, progress)
            return; 
        } 

        let currentPoint = Math.floor(progress * numPts);
        var startingPoint = currentPoint - props.bufferLength;
        startingPoint = startingPoint < 0 ? 0 : startingPoint;
        let composedLength = currentPoint - startingPoint;

        //// console.log("--update window: ", withStream.length, `${Math.floor(progress * 10000) / 100}%`, composedLength, currentPoint, startingPoint)

        let qToRotate = {w: 0, x: 0, y: 1, z: 0};
        var qBuff: {w: number, x: number, y: number, z: number}[] = [];
        let leadingQ: {w: number, x: number, y: number, z: number} = withStream[latestRepIndex] === undefined ? {w: 0.0, x: 0.0, y: 0.0, z: 0.0} : withStream[latestRepIndex];
        for (var i = startingPoint; i < startingPoint + composedLength; i++) {
            let thisQ = i < latestRepIndex ? leadingQ: withStream[i];
            
            if (thisQ) {
                var rotatedQ: {w: number, x: number, y: number, z: number} = q_rotate(qToRotate, thisQ);
                rotatedQ.w = -1;
                qBuff.push(rotatedQ);
            } else {
                console.error("ERROR! NodeStreamAnalyzeStream | updateWindow(withStream) - thisQ is undefined", i, progress);
            }
            
        }

        let finalQList = startingPoint === 0 ? getQListWithLeadingZeros(qBuff, props.bufferLength) : qBuff;
        

        // Q stream
        var qlistToPlot = finalQList;

        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];
        for (var i = 0; i < qlistToPlot.length; i++) {
            let thisQEntry = qlistToPlot[i];
            labels_temp_q.push(`${i}`);

            points_temp_q_w.push(thisQEntry.w);
            points_temp_q_x.push(thisQEntry.x);
            points_temp_q_y.push(thisQEntry.y);
            points_temp_q_z.push(thisQEntry.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q);


        // ENTER ML TEST
        if (runML === false) { return; }
        if (props.mlCore === undefined || currentPoint <= props.pointOffset) { return; }




        let thisByteBuffer = getByteBuffer_noLabel(finalQList);
        //// console.log("finalQList:", finalQList);

        let res = props.mlCore.predict(thisByteBuffer);
        let resultStrings: string[] = ["Stationary", "Rep Complete", "Unspecified"];
        let thisResString = resultStrings[res.predicted_index];
        let thisResCertainty = Math.floor((res === undefined || res.prediction_ratings === undefined ? 100 : res.prediction_ratings[res.predicted_index]) * 1000) / 10;

        var numFilledVals = (startingPoint + composedLength) - latestRepIndex;
        numFilledVals = numFilledVals < 0 ? 0 : numFilledVals;
        numFilledVals = numFilledVals > 122 ? 122 : numFilledVals;
        //// console.log(`ML TEST RESULT: ___${thisResString} (${res.predicted_index})___ with a ${thisResCertainty}% certainty\t\tNum filled vals: ${numFilledVals}\t\t${currentPoint} | ${latestRepIndex}\t\t${startingPoint} | ${composedLength}`);
        setResultString(`${thisResString} at ${thisResCertainty}%`)


        
        
        //if (numFilledVals > 90) {
            // Cut up stream and analyze to make sure rep wasn't missed
            // var cutupPercentages = [10, 20, 30, 40, 50, 60, 70, 80, 90];
            // for (var i = 0; i < cutupPercentages.length; i++) {
            //     // 1. Calculate how many points to iterate over
            //     let thisCutupPercentage = cutupPercentages[i];
            //     let thisNumPoints = Math.floor((thisCutupPercentage / 100) * props.bufferLength);

            //     //// console.log("thisNumPoints:",thisCutupPercentage, thisNumPoints, finalQList.length, 122 - thisNumPoints);

            //     // 2. Compose the cutup list with only the points needed
            //     var listTemp: {w: number, x: number, y: number, z: number}[] = [];
            //     for (var j = finalQList.length - 1; j > 112 - thisNumPoints; j--) {
            //         let thisQPoint: {w: number, x: number, y: number, z: number} = finalQList[j];
            //         listTemp.push(thisQPoint);
            //         //// console.log("     thisQPoint",j, thisQPoint);
            //     }

            //     // 2.a flip direction of listTemp
            //     var listTempFinal: {w: number, x: number, y: number, z: number}[] = [];
            //     for (var j = listTemp.length - 1; j > 1; j--) {
            //         listTempFinal.push(listTemp[j]);
            //     }

            //     // 3. Add leading "zeros"
            //     let finalCutupQList: {w: number, x: number, y: number, z: number}[] = getQListWithLeadingZeros(listTempFinal, props.bufferLength);

            //     if (i == 3) {
            //         qlistToPlot = finalCutupQList;
            //     }

            //     // 4. Get byte buffer
            //     let thisCutupByteBuffer = getByteBuffer_noLabel(finalCutupQList);

            //     // 5. Run ML prediction
            //     let res_cutup = props.mlCore.predict(thisCutupByteBuffer);
            //     let thisResString_cutup = resultStrings[res_cutup.predicted_index];
            //     let thisResCertainty_cutup = Math.floor(res_cutup.prediction_ratings[res_cutup.predicted_index] * 1000) / 10;
            //     if (res_cutup.predicted_index >= 1) {
            //         // console.log(`              @${thisCutupPercentage}% (${thisNumPoints}) ___${thisResString_cutup}___ with a ${thisResCertainty_cutup}% certainty`);
            //         // console.log(`ML TEST RESULT: ___${thisResString}___ with a ${thisResCertainty}% certainty\t\tNum filled vals: ${0}`);//numBlankVals
            //     }
                
            // }

        //}

        





        



        
        if (res.predicted_index > 0 && thisResCertainty > 60 && numFilledVals >= 14) {
            

            if (posCount + 1 >= maxPosCount) {
                // Rep!!
                //calculateStats(qBuff);
                let endingIndex = currentPoint;

                // Work backwards to find starting location of rep
                let newWindow_temp: RepWindow_t = {
                    start_index: currentPoint - latestRepIndex > props.bufferLength ? currentPoint - props.bufferLength : latestRepIndex,
                    end_index: endingIndex,
                    is_rep: true
                }
                let newWindow_temp_length = newWindow_temp.end_index - newWindow_temp.start_index;

                let stepSize = 14;
                var validIndecies: number[] = [];
                var highestPositiveIndex = newWindow_temp.start_index;

                if (newWindow_temp_length > 60) {
                    for (var n = newWindow_temp.end_index - stepSize; n > newWindow_temp.start_index; n -= stepSize) {
                        let adjWindow_temp: RepWindow_t = {
                            start_index: n,
                            end_index: newWindow_temp.end_index,
                            is_rep: true
                        }

                        var qBuff_temp: {w: number, x: number, y: number, z: number}[] = [];
                        let numEmptyVals = 112 - n;

                        for (var i = adjWindow_temp.start_index; i < adjWindow_temp.end_index; i++) {
                            let thisQ = i < latestRepIndex ? leadingQ: withStream[i];
                            if (!thisQ) console.log("0930 - updateWindow 459", qToRotate, i, progress);
                            var rotatedQ: {w: number, x: number, y: number, z: number} = q_rotate(qToRotate, thisQ);
                            rotatedQ.w = -1;
                            qBuff_temp.push(rotatedQ);
                        }

                        let finalQList_temp = getQListWithLeadingZeros(qBuff_temp, props.bufferLength);
                        let thisByteBuffer_temp = getByteBuffer_noLabel(finalQList_temp);

                        let res_temp = props.mlCore.predict(thisByteBuffer_temp);
                        if (res_temp.predicted_index > 0) {
                            validIndecies.push(n)
                        }
                    }
                    highestPositiveIndex = validIndecies.length === 0 ? (currentPoint - latestRepIndex > props.bufferLength ? currentPoint - props.bufferLength : latestRepIndex) : Math.min(...validIndecies);
                }
                
                
                // console.log("validIndecies", validIndecies);



                let length_final = (endingIndex) - highestPositiveIndex;
                length_final = length_final <= stepSize + 4 ? newWindow_temp.start_index : length_final;

                let newWindow: RepWindow_t = {
                    start_index: highestPositiveIndex,//currentPoint - latestRepIndex > props.bufferLength ? currentPoint - props.bufferLength : latestRepIndex,
                    end_index: endingIndex,
                    is_rep: true
                }

                calculateStats(withStream, newWindow);
                // console.log("+++++++++++++++++++ REP +++++++++++++++++", res.predicted_index);
                setDetectedRepWindows(detectedRepWindows.concat(newWindow));


                setLatestRepIndex(endingIndex - 2);
                setPosCount(0);
                setRepCount(repCount + 1);

            } else {
                setPosCount(posCount + 1);
            }
        } 
        else if (false && res.predicted_index === 0 && repCount <= 1) {
            var cutupPercentages = [10, 20, 30, 40, 50, 60, 70, 80, 90];
            var validAtPercentages = [];
            for (var i = 0; i < cutupPercentages.length; i++) {
                // 1. Calculate how many points to iterate over
                let thisCutupPercentage = cutupPercentages[i];
                let thisNumPoints = Math.floor((thisCutupPercentage / 100) * props.bufferLength);

                //// console.log("thisNumPoints:",thisCutupPercentage, thisNumPoints, finalQList.length, 122 - thisNumPoints);

                // 2. Compose the cutup list with only the points needed
                var listTemp: {w: number, x: number, y: number, z: number}[] = [];
                for (var j = finalQList.length - 1; j > 112 - thisNumPoints; j--) {
                    let thisQPoint: {w: number, x: number, y: number, z: number} = finalQList[j];
                    listTemp.push(thisQPoint);
                    //// console.log("     thisQPoint",j, thisQPoint);
                }

                // 2.a flip direction of listTemp
                var listTempFinal: {w: number, x: number, y: number, z: number}[] = [];
                for (var j = listTemp.length - 1; j > 1; j--) {
                    listTempFinal.push(listTemp[j]);
                }

                // 3. Add leading "zeros"
                let finalCutupQList: {w: number, x: number, y: number, z: number}[] = getQListWithLeadingZeros(listTempFinal, props.bufferLength);

                if (i == 3) {
                    qlistToPlot = finalCutupQList;
                }

                // 4. Get byte buffer
                let thisCutupByteBuffer = getByteBuffer_noLabel(finalCutupQList);

                // 5. Run ML prediction
                let res_cutup = props.mlCore.predict(thisCutupByteBuffer);
                let thisResString_cutup = resultStrings[res_cutup.predicted_index];
                let thisResCertainty_cutup = Math.floor(res_cutup.prediction_ratings[res_cutup.predicted_index] * 1000) / 10;
                //// console.log(`              @${thisCutupPercentage}% (${thisNumPoints}) ___${thisResString_cutup}___ with a ${thisResCertainty_cutup}% certainty`);
                if (res_cutup.predicted_index >= 1) {
                    validAtPercentages.push(cutupPercentages[i]);
                    // // console.log(`ML TEST RESULT: ___${thisResString}___ with a ${thisResCertainty}% certainty\t\tNum filled vals: ${0}`);//numBlankVals
                }
                
            }

            if (validAtPercentages.length > 2) {
                // console.log(`ML TEST RESULT: positive detected at ${validAtPercentages.length} cut-up locations`, validAtPercentages);
                setPosBCount(posBCount + 1);
            } else {
                setPosBCount(0);
            }

            if (posBCount >= 1 && validAtPercentages.length > 2) {

                let thisMaxPercentage = Math.max(...validAtPercentages);
                let thisNumPoints = Math.floor((thisMaxPercentage / 100) * props.bufferLength);

                let endingIndex = currentPoint;
                let startingIndex = currentPoint - thisNumPoints + 5;

                // Work backwards to find starting location of rep
                let newWindow: RepWindow_t = {
                    start_index: startingIndex < 0 ? 0 : startingIndex,
                    end_index: endingIndex,
                    is_rep: false
                }

                // // Work backwards to find starting location of rep
                // let newWindow_temp: RepWindow_t = {
                //     start_index: currentPoint - latestRepIndex > props.bufferLength ? currentPoint - props.bufferLength : latestRepIndex,
                //     end_index: endingIndex,
                //     is_rep: true
                // }

                // console.log("+++++++++++++++++++ REP (back prop) +++++++++++++++++", res.predicted_index);



                // let newWindow_temp_length = newWindow_temp.end_index - newWindow_temp.start_index;

                // let stepSize = 14;
                // var validIndecies: number[] = [];
                // var highestPositiveIndex = newWindow_temp.start_index;

                // if (newWindow_temp_length > 60) {
                //     for (var n = newWindow_temp.end_index - stepSize; n > newWindow_temp.start_index; n -= stepSize) {
                //         let adjWindow_temp: RepWindow_t = {
                //             start_index: n,
                //             end_index: newWindow_temp.end_index,
                //             is_rep: true
                //         }

                //         var qBuff_temp: {w: number, x: number, y: number, z: number}[] = [];
                //         let numEmptyVals = 112 - n;

                //         for (var i = adjWindow_temp.start_index; i < adjWindow_temp.end_index; i++) {
                //             let thisQ = i < latestRepIndex ? leadingQ: withStream[i];
                //             if (!thisQ) // console.log("0930 - updateWindow 459", qToRotate, i, progress);
                //             var rotatedQ: {w: number, x: number, y: number, z: number} = q_rotate(qToRotate, thisQ);
                //             rotatedQ.w = -1;
                //             qBuff_temp.push(rotatedQ);
                //         }

                //         let finalQList_temp = getQListWithLeadingZeros(qBuff_temp, props.bufferLength);
                //         let thisByteBuffer_temp = getByteBuffer_noLabel(finalQList_temp);

                //         let res_temp = props.mlCore.predict(thisByteBuffer_temp);
                //         if (res_temp.predicted_index > 0) {
                //             validIndecies.push(n)
                //         }
                //     }
                //     highestPositiveIndex = validIndecies.length === 0 ? (currentPoint - latestRepIndex > props.bufferLength ? currentPoint - props.bufferLength : latestRepIndex) : Math.min(...validIndecies);
                // }

                // // console.log("validIndecies", validIndecies);



                // let length_final = (endingIndex) - highestPositiveIndex;
                // length_final = length_final <= stepSize + 4 ? newWindow_temp.start_index : length_final;

                // let newWindow: RepWindow_t = {
                //     start_index: highestPositiveIndex,//currentPoint - latestRepIndex > props.bufferLength ? currentPoint - props.bufferLength : latestRepIndex,
                //     end_index: endingIndex,
                //     is_rep: true
                // }




                calculateStats(withStream, newWindow);
                setDetectedRepWindows(detectedRepWindows.concat(newWindow));


                setLatestRepIndex(endingIndex - 2);
                setPosCount(0);
                setRepCount(repCount + 1);
                setPosBCount(0);

            }
        } else {
            setPosCount(0);
            setPosBCount(0);
        }


        //var qlistToPlot = finalQList;

        var points_temp_q_w: number[] = [];
        // var points_temp_q_x: number[] = [];
        // var points_temp_q_y: number[] = [];
        // var points_temp_q_z: number[] = [];
        // var labels_temp_q: string[] = [];
        // for (var i = 0; i < qlistToPlot.length; i++) {
        //     let thisQEntry = qlistToPlot[i];
        //     labels_temp_q.push(`${i}`);

        //     points_temp_q_w.push(thisQEntry.w);
        //     points_temp_q_x.push(thisQEntry.x);
        //     points_temp_q_y.push(thisQEntry.y);
        //     points_temp_q_z.push(thisQEntry.z);
        // }

        // var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        // tempDataSets_q.labels = labels_temp_q;
        // tempDataSets_q.datasets[0].data = points_temp_q_w;
        // tempDataSets_q.datasets[1].data = points_temp_q_x;
        // tempDataSets_q.datasets[2].data = points_temp_q_y;
        // tempDataSets_q.datasets[3].data = points_temp_q_z;

        // setDataSets_q(tempDataSets_q);
        
        // END ML TEST
   
    }

    function calculateStats_old(thisQuatList: {w: number, x: number, y: number, z: number}[]) {
        const readingsPerSecond = 13.625;
        let secondsPerReading = 1 / readingsPerSecond;

        var v_stream: {x: number, y: number, z: number}[] = [];

        let baseVector_q: {w: number, x: number, y: number, z: number} = {w: 0.0, x: 1.0, y: 0.0, z: 0.0};
        var lastVector: {x: number, y: number, z: number} = {x: baseVector_q.x, y: baseVector_q.y, z: baseVector_q.z};

        var oneDStream: number[] = [];
        let sumWindowSize = 6;
        var deltaMags: number[] = [];
        let minCutoff = 0.05;

        var thisNetAngle = 0.0;
        let n_x = {w: 0, x: 1.0,y: 0.0,z: 0.0};
        let n_y = {w: 0, x: 0.0,y: 1.0,z: 0.0};
        let n_z = {w: 0, x: 0.0,y: 0.0,z: 1.0};

        for (var i = 1; i < thisQuatList.length; i++) {
            let thisOrientationQ = thisQuatList[i];
            let lastQEntry = thisQuatList[i - 1];
            //let thisNewVector_q: {w: number, x: number, y: number, z: number} = q_inverse_rotate(baseVector_q, thisOrientationQ);
            //let thisNewVector_v: {x: number, y: number, z: number} = {x: thisNewVector_q.x, y: thisNewVector_q.y, z: thisNewVector_q.z};
            //v_stream.push(thisNewVector_v);

            var r_x = q_inverse_rotate(n_x, thisOrientationQ);
            var r_x_prev = q_inverse_rotate(n_x, lastQEntry);

            let rx_delta = {
                x: r_x.x - r_x_prev.x,
                y: r_x.y - r_x_prev.y,
                z: r_x.z - r_x_prev.z
            }
            let rx_delta_mag = Math.sqrt((rx_delta.x * rx_delta.x) + (rx_delta.y * rx_delta.y) + (rx_delta.z * rx_delta.z));
            rx_delta_mag = rx_delta_mag < minCutoff ? 0.0 : rx_delta_mag;


            var r_y = q_inverse_rotate(n_y, thisOrientationQ);
            var r_y_prev = q_inverse_rotate(n_y, lastQEntry);

            let ry_delta = {
                x: r_y.x - r_y_prev.x,
                y: r_y.y - r_y_prev.y,
                z: r_y.z - r_y_prev.z
            }
            let ry_delta_mag = Math.sqrt((ry_delta.x * ry_delta.x) + (ry_delta.y * ry_delta.y) + (ry_delta.z * ry_delta.z));
            ry_delta_mag = ry_delta_mag < minCutoff ? 0.0 : ry_delta_mag;


            var r_z = q_inverse_rotate(n_z, thisOrientationQ);
            var r_z_prev = q_inverse_rotate(n_z, lastQEntry);

            let rz_delta = {
                x: r_z.x - r_z_prev.x,
                y: r_z.y - r_z_prev.y,
                z: r_z.z - r_z_prev.z
            }
            let rz_delta_mag = Math.sqrt((rz_delta.x * rz_delta.x) + (rz_delta.y * rz_delta.y) + (rz_delta.z * rz_delta.z));
            rz_delta_mag = rz_delta_mag < minCutoff ? 0.0 : rz_delta_mag;


            let r_delta_mag = Math.sqrt((rx_delta_mag * rx_delta_mag) + (ry_delta_mag * ry_delta_mag) + (rz_delta_mag * rz_delta_mag));
            


            //let dist = getEuclideanDistance(thisNewVector_v, lastVector);


            var r_delta_mag_sum = r_delta_mag;
            for (var w = 0; w < sumWindowSize; w++) {
                let adjIndex = i - w;
                if (deltaMags[adjIndex]) {
                    r_delta_mag_sum += deltaMags[adjIndex];
                }
            }

            deltaMags.push(r_delta_mag);

            
            oneDStream.push(r_delta_mag_sum); //(i === 0 ? dist : dist + (isNaN(oneDStream[i - 1]) ? 0 : oneDStream[i - 1]));

            //lastVector = thisNewVector_v;
        }

        //setPeakStat(Math.floor(oneDStream[oneDStream.length - 1] * 10) / 20);

        // get max index of ROC
        var thisMax = 0.0;
        var thisMax_index = 0;
        for (var i = 0; i < oneDStream.length; i++) {
            if (!isNaN(oneDStream[i])) {
                if (oneDStream[i] > thisMax) {
                    thisMax = oneDStream[i];
                    thisMax_index = i;
                }
            }
        }

        // calc movement within peak window
        const peakWindowSize_low = 8; // -
        const peakWindowSize_high = 0; // -
        var minIndex = thisMax_index - peakWindowSize_low;
        minIndex = minIndex < 0 ? 0 : minIndex;
        var maxIndex = thisMax_index + peakWindowSize_high;

        var distSum = 0.0;

        for (var i = minIndex; i < maxIndex; i++) {
            var thisOrientationQ = thisQuatList[i];
            let thisNewVector_q: {w: number, x: number, y: number, z: number} = q_inverse_rotate(baseVector_q, thisOrientationQ);
            let thisNewVector_v: {x: number, y: number, z: number} = {x: thisNewVector_q.x, y: thisNewVector_q.y, z: thisNewVector_q.z};

            let dist = getEuclideanDistance(thisNewVector_v, lastVector);
            distSum += dist;
            lastVector = thisNewVector_v;
        }

        distSum = Math.floor(distSum * 100) / 100;
        setPeakStats(peakStats.concat(distSum));
        //setPeakStat(distSum);

        // console.log("Rep stats: ", distSum, thisMax_index, minIndex, maxIndex);

        // V stream
        var points_temp_v_x: number[] = [];
        var points_temp_v_y: number[] = [];
        var points_temp_v_z: number[] = [];
        var labels_temp_v: string[] = [];


        for (var i = 0; i < oneDStream.length; i++) {
            let thisVEntry = oneDStream[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry);
        }
        /*
        for (var i = 0; i < v_stream.length; i++) {
            let thisVEntry = v_stream[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry.x);
            points_temp_v_y.push(thisVEntry.y);
            points_temp_v_z.push(thisVEntry.z);
        }
        */

        var tempDataSets_v = JSON.parse(JSON.stringify(initDataSets_v));
        tempDataSets_v.labels = labels_temp_v;
        tempDataSets_v.datasets[0].data = points_temp_v_x;
        tempDataSets_v.datasets[1].data = points_temp_v_y;
        tempDataSets_v.datasets[2].data = points_temp_v_z;

        setDataSets_v(tempDataSets_v)


    }    

    function calculateStats(thisQuatList: {w: number, x: number, y: number, z: number}[], window: RepWindow_t) {
        


        var q_buff_temp: {w: number, x: number, y: number, z: number}[] = [];
        for (var i = window.start_index; i < window.end_index; i++) {
            q_buff_temp.push(thisQuatList[i]);
        }



        //// console.log("q_buff_temp: ", q_buff_temp.length, q_buff_temp);

        let startingQ = q_buff_temp[0];
        if (startingQ === undefined) {
            return;
        }
        let refV: {w: number, x: number, y: number, z: number} = {w: 0.0, x: 0.0, y: 1.0, z: 0.0};
        let startingV = q_rotate(refV, startingQ);

        var angles: number[] = [];
        for (var i = 1; i < q_buff_temp.length; i++) {
            let thisQ = q_buff_temp[i];
            if (!thisQ) console.log("0930 - calcStats", startingV, i);
            let thisV = q_rotate(refV, thisQ);
            let thisAngle = angle_between_vector_quaternions(startingV, thisV);
            if (!isNaN(thisAngle)) {
                angles.push(thisAngle);
            }
            
        }

        // console.log("angles: ", angles);

        // Generate angular deltra from starting Q
        var angleLabels = [];
        for (var a = 0; a < angles.length; a++) angleLabels.push(`${a}`);

        var tempDataSets_q_int = JSON.parse(JSON.stringify(initDataSets_v));
        tempDataSets_q_int.labels = angleLabels;
        tempDataSets_q_int.datasets[0].data = angles;

        setDataSets_q_int(tempDataSets_q_int)
        



        // Find max
        var maxAngle = angles[0];
        var maxAngle_index = 0;
        for (var i = 0; i < angles.length; i++) {
            if (angles[i] > maxAngle) {
                maxAngle = angles[i];
                maxAngle_index = i;
            }
        }

        // console.log('------------------------------------')
        // console.log(`----- ROM: ${Math.floor(maxAngle * 4) / 4}º`);

        let timeToPeak = 0.041 * maxAngle_index;


        let reachLength = 0.60;     // in meters
        let bodyWeight = 155;       // in pounds
        let bodyWeight_metric = bodyWeight / 2.21;
        let g_mag = 9.81;           // m/s^2

        let power = (0.5 * bodyWeight_metric * g_mag * reachLength) / timeToPeak;

        // console.log(`----- POWER: ${Math.floor(power * 100) / 100}W`)

        // AVERAGE CONCENTRIC SPEED
        //let velocity = reachLength / eccentricTime; // FOR PUSHUP
        let velocity = (reachLength * (maxAngle / 57.2957795131)) / timeToPeak;

        // console.log(`----- VELOCITY: ${Math.floor(velocity * 100) / 100}m/s`)

        // console.log('------------------------------------')

        // V stream
        var points_temp_v_x: number[] = [];
        var points_temp_v_y: number[] = [];
        var points_temp_v_z: number[] = [];
        var labels_temp_v: string[] = [];


        for (var i = 0; i < angles.length; i++) {
            let thisVEntry = angles[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry);
        }
        /*
        for (var i = 0; i < v_stream.length; i++) {
            let thisVEntry = v_stream[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry.x);
            points_temp_v_y.push(thisVEntry.y);
            points_temp_v_z.push(thisVEntry.z);
        }
        */

        var tempDataSets_v = JSON.parse(JSON.stringify(initDataSets_v));
        tempDataSets_v.labels = labels_temp_v;
        tempDataSets_v.datasets[0].data = points_temp_v_x;
        tempDataSets_v.datasets[1].data = points_temp_v_y;
        tempDataSets_v.datasets[2].data = points_temp_v_z;

        setDataSets_v(tempDataSets_v)


    }

    function calculateStats_old_a(thisQuatList: {w: number, x: number, y: number, z: number}[], window: RepWindow_t) {
        


        var q_buff_temp: {w: number, x: number, y: number, z: number}[] = [];
        for (var i = window.start_index; i < window.end_index; i++) {
            q_buff_temp.push(thisQuatList[i]);
        }



        //// console.log("q_buff_temp: ", q_buff_temp.length, q_buff_temp);

        let startingQ = q_buff_temp[0];
        if (startingQ === undefined) {
            return;
        }
        let refV: {w: number, x: number, y: number, z: number} = {w: 0.0, x: 0.0, y: 1.0, z: 0.0};
        let startingV = q_rotate(refV, startingQ);

        var angles: number[] = [];
        for (var i = 1; i < q_buff_temp.length; i++) {
            let thisQ = q_buff_temp[i];
            if (!thisQ) console.log("0930 - calcStats", startingV, i);
            let thisV = q_rotate(refV, thisQ);
            let thisAngle = angle_between_vector_quaternions(startingV, thisV);
            if (!isNaN(thisAngle)) {
                angles.push(thisAngle);
            }
            
        }

        //// console.log("angles: ", angles);


        // Find max
        var maxAngle = angles[0];
        var maxAngle_index = 0;
        for (var i = 0; i < angles.length; i++) {
            if (angles[i] > maxAngle) {
                maxAngle = angles[i];
                maxAngle_index = i;
            }
        }

        // console.log('------------------------------------')
        // console.log(`----- ROM: ${Math.floor(maxAngle * 4) / 4}º`);

        let timeToPeak = 0.041 * maxAngle_index;


        let reachLength = 0.60;     // in meters
        let bodyWeight = 155;       // in pounds
        let bodyWeight_metric = bodyWeight / 2.21;
        let g_mag = 9.81;           // m/s^2

        let power = (0.5 * bodyWeight_metric * g_mag * reachLength) / timeToPeak;

        // console.log(`----- POWER: ${Math.floor(power * 100) / 100}W`)

        // AVERAGE CONCENTRIC SPEED
        //let velocity = reachLength / eccentricTime; // FOR PUSHUP
        let velocity = (reachLength * (maxAngle / 57.2957795131)) / timeToPeak;

        // console.log(`----- VELOCITY: ${Math.floor(velocity * 100) / 100}m/s`)

        // console.log('------------------------------------')

        // V stream
        var points_temp_v_x: number[] = [];
        var points_temp_v_y: number[] = [];
        var points_temp_v_z: number[] = [];
        var labels_temp_v: string[] = [];


        for (var i = 0; i < angles.length; i++) {
            let thisVEntry = angles[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry);
        }
        /*
        for (var i = 0; i < v_stream.length; i++) {
            let thisVEntry = v_stream[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry.x);
            points_temp_v_y.push(thisVEntry.y);
            points_temp_v_z.push(thisVEntry.z);
        }
        */

        var tempDataSets_v = JSON.parse(JSON.stringify(initDataSets_v));
        tempDataSets_v.labels = labels_temp_v;
        tempDataSets_v.datasets[0].data = points_temp_v_x;
        tempDataSets_v.datasets[1].data = points_temp_v_y;
        tempDataSets_v.datasets[2].data = points_temp_v_z;

        setDataSets_v(tempDataSets_v)


    } 

    function getQListWithLeadingZeros(thisQuatList: {w: number, x: number, y: number, z: number}[], quatListBufferLength: number) {
        let thisQuatListLength = thisQuatList.length;
        let numZeroEntries = quatListBufferLength - thisQuatListLength;

        var thisComposedQuatList: {w: number, x: number, y: number, z: number}[] = [];
        // 1 - add leading 0 entries

        let leadingQ = thisQuatList[0] === undefined ? {w: 0, x: 0, y: 0, z: 0} : thisQuatList[0];
        for (var j = 0; j < numZeroEntries; j++) {
            thisComposedQuatList.push(leadingQ);
        }

        // 2 - add remaining recorded entries
        for (var j = 0; j < thisQuatListLength; j++) {
            thisComposedQuatList.push(thisQuatList[j]);
        }

        return thisComposedQuatList
    }

    function getByteBuffer_noLabel(thisListQuaternion: {w: number, x: number, y: number, z: number}[]) {
        // 1. Add full permutation q stream with label:"1" to indicate a rep detected
        var byteBuffer_temp: number[] = [];

        // 2. loop through each quaternion of this rep
        for (var j = 0; j < thisListQuaternion.length; j++) {
            let thisQuat: {w: number, x: number, y: number, z: number} = thisListQuaternion[j];

            // 4.c - loop through each component, make a byte, and push to buffer
            for (var k = 0; k < 4; k++) {
                switch (k) {
                    case 0 :
                        byteBuffer_temp.push(Math.floor(thisQuat.w * 128) + 128);
                        break;
                    case 1 :
                        byteBuffer_temp.push(Math.floor(thisQuat.x * 128) + 128);
                        break;
                    case 2 :
                        byteBuffer_temp.push(Math.floor(thisQuat.y * 128) + 128);
                        break;
                    case 3 :
                        byteBuffer_temp.push(Math.floor(thisQuat.z * 128) + 128);
                        break;
                    default:
                        // console.log("ERROR: check your 'k' loop in 4.c while composing CSV");
                        break;

                }
            }
        }

        return byteBuffer_temp;
    }


    // QUATERNION FUNCTIONS
    function q_multiply(q: any, p: any)
    {
        var result = {w: 1, x: 0, y: 0, z:0};

        result.w = q.w*p.w - q.x*p.x - q.y*p.y - q.z*p.z;
        result.x = q.x*p.w + q.w*p.x - q.z*p.y + q.y*p.z;
        result.y = q.y*p.w + q.z*p.x + q.w*p.y - q.x*p.z;
        result.z = q.z*p.w - q.y*p.x + q.x*p.y + q.w*p.z;

        return result;

    }

    function q_invert(q: any)
    {
        var result = {w: 1, x: 0, y: 0, z:0};
        if (!q) console.log("0930 - q_invert", q);
        const quaternionMagSq = (q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z);
        result.w = q.w / quaternionMagSq;
        result.x = -1 * q.x / quaternionMagSq;
        result.y = -1 * q.y / quaternionMagSq;
        result.z = -1 * q.z / quaternionMagSq;

        return result;
    }

    function angle_between_vector_quaternions(v_a: {w: number, x: number, y: number, z: number}, v_b: {w: number, x: number, y: number, z: number}) {
        
        let dp = ((v_a.x * v_b.x) + (v_a.y * v_b.y) + (v_a.z * v_b.z));
        let vectorMag_a = Math.sqrt((v_a.x * v_a.x) + (v_a.y * v_a.y) + (v_a.z * v_a.z));
        let vectorMag_b = Math.sqrt((v_b.x * v_b.x) + (v_b.y * v_b.y) + (v_b.z * v_b.z));

        let prod = dp / (vectorMag_a * vectorMag_b)

        let d_theta = Math.acos(prod) * 57.2957795131;//2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

        return d_theta;
    }

    function angle_between_quaternions(q_a: {w: number, x: number, y: number, z: number}, q_b: {w: number, x: number, y: number, z: number}) {
        if (!q_a || !q_b) console.log("0930 - angle_between_quaternions", q_a, q_b);
        let q_inv = q_invert(q_a);
        q_inv.x = q_inv.x * -1;
        q_inv.y = q_inv.y * -1;
        q_inv.z = q_inv.z * -1;
        let res = q_multiply(q_inv, q_b);

        //res.w = res.w > 1.0 ? 1.0 : (res.w < -1.0 ? 1.0 : res.w);

        //// console.log(res.w, Math.acos(res.w));
        let vectorMag = Math.sqrt((res.x * res.x) + (res.y * res.y) + (res.z * res.z));
        let d_theta = 2.0 * Math.acos(res.w) * 57.2957795131;//2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

        return d_theta;
    }

    function q_rotate(q: any, w: any)
    {
        var w_inv = {w: 1, x: 0, y: 0, z:0};
        var a_world = {w: 1, x: 0, y: 0, z:0};
        var w_a = {w: 1, x: 0, y: 0, z:0};
        var a_body = {w: 1, x: 0, y: 0, z:0};

        if (!q || !w) console.log("0930 - q_rotate", q, w);
        a_body    = q;
        w_inv     = q_invert(w);

        w_a       = q_multiply(w, a_body);
        a_world   = q_multiply(w_a, w_inv);

        return a_world;
    }

    function q_inverse_rotate(q: {w: number, x: number, y: number, z: number}, w: {w: number, x: number, y: number, z: number})
    {
        var a_world: {w: number, x: number, y: number, z: number} = q;
        if (!q || !w) console.log("0930 - q_inverse_rotate", w);
        var w_inv: {w: number, x: number, y: number, z: number} = q_invert(w);

        var w_a: {w: number, x: number, y: number, z: number} = q_multiply(w_inv, a_world);
        var a_body: {w: number, x: number, y: number, z: number} = q_multiply(w_a, w);

        return a_body;
    }

    function getEuclideanDistance(pointA: {x: number, y: number, z: number}, pointB: {x: number, y: number, z: number}) {
        let dx = pointA.x - pointB.x;
        let dy = pointA.y - pointB.y;
        let dz = pointA.z - pointB.z;

        let dist = Math.sqrt((dx*dx)+(dy*dy)+(dz*dz));

        return dist;
    }
    // END QUATERNION FUNCTIONS


    function toggleRunML() {
        if (runML) {
            setLatestRepIndex(0);
            setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }));
        }
        setRunML(!runML);
    }


	return (
		<div className="node-stream-analysis-stream">
            <div className="node-stream-analysis-stream-card">
    			<div className="node-stream-analysis-stream-header">
                    <h2>Q Stream Window</h2>
                    {/*<h4>{props.bufferLength}/{numPts} pts</h4>
                    <h4>{props.videoProgress === undefined ? '--.-' : Math.floor(props.videoProgress * 1000) / 10}%</h4>*/}
                    <h3>{resultString} | {repCount}</h3>
                    <div onClick={() => toggleRunML()} className="node-stream-analysis-stream-header-button">
                        <p>{runML ? 'Stop ML' : 'Start ML'}</p>
                    </div>
                </div>
                <div className="node-stream-analysis-stream-chart-container">
                    <Line
                        data={dataSets_q}
                        options={chartSettings_q}/>
                </div>
            </div>
            <div className="node-stream-analysis-stream-card node-stream-analysis-stream-card-stats"> 
                <div className="node-stream-analysis-stream-header">
                    <h2>Rep Window Cumulative Angle Delta</h2>
                    <h3>Peak: { dataSets_q_int && dataSets_q_int.datasets && dataSets_q_int.datasets[0] && dataSets_q_int.datasets[0].data && dataSets_q_int.datasets[0].data.length > 0 ? Math.floor(Math.max(...dataSets_q_int.datasets[0].data) * 10) / 10 : "No data" }º | Dur: { dataSets_q_int && dataSets_q_int.datasets && dataSets_q_int.datasets[0] && dataSets_q_int.datasets[0].data && dataSets_q_int.datasets[0].data.length ? Math.floor(dataSets_q_int.datasets[0].data.length * 0.041 * 100) / 100 : 0}s</h3>
                    {/*<div className="node-stream-analysis-stream-header-peak-stats">
                        {
                            peakStats.map((item: number, index: number) => (
                                <div><h3>{item}{index < peakStats.length - 1 ? ', ' : ''}</h3></div>
                            ))
                        }
                    </div>*/}
                    
                </div>
                <div className="node-stream-analysis-stream-chart-container">
                    <Line
                        data={dataSets_q_int}
                        options={chartSettings_v}/>
                </div>
            </div>
		</div>
	)
}

export default NodeStreamAnalyzeStream;