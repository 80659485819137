// Import Ionic Elements, React and Supporting Elements/Libraries
import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";

// Import CSS Files
import './NodeTest.css';
import './DashboardPageCommon.css';

// Import Data Types
import NodeInstance from '../../NodeInstance'
import MotionData_t from '../../Interfaces/MotionData_t';
import Peripheral_t from '../../Interfaces/Peripheral_t';

// Import libraries
import * as THREE from "three";
import GLTFLoader from 'three-gltf-loader';
//import DRACOLoader from 'draco-loader';
import NodeManager from '../../NodeManager';

interface AssignedMotionData_t {
	data: MotionData_t,
	peripheral: Peripheral_t
}

function NodeTest(props: any) {

	var THREE = require('three');
	 // 	scene: any,
		// renderer: any,
		// camera: any,
		// cube: any,
	let model: any, 								// Our character
		neck, 								// Reference to the neck bone in the skeleton
		waist, 								// Reference to the waist bone in the skeleton
		leftArm,
		rightArm,
		possibleAnims, 						// Animations found in our file
		mixer: any, 								// THREE.js animations mixer
		idle, 								// Idle, the default state our character returns to
		clock = new THREE.Clock(), 			// Used for anims, which run to a clock instead of frame rate 
		currentlyAnimating = false, 		// Used to check whether characters neck is being used in another anim
  		raycaster = new THREE.Raycaster(); 	// Used to detect the click on our character



	// Declare state variables and their setters
	const [initSet, setInitSet] = useState(false);
	const [nodeManager, setNodeManager] = useState<NodeManager>();	
	const [isStreaming, setIsStreaming] = useState(false);
	const [isTapEnabled, setIsTapEnabled] = useState(false);
	//const [latestQ, setLatestQ] = useState({w:0,x:0,y:0,z:0});
	const [motionData, setMotionData] = useState<AssignedMotionData_t[]>([]);
	const [mag, setMag] = useState(1);
	const [latestEuler, setLatestEuler] = useState({yaw:0.0,pitch:0.0,roll:0.0});
	const [lastUpdateTime, setLastUpdateTime] = useState(0);

	const [previousQuaternion, setPreviousQuaternion] = useState({w: 1, x: 0, y: 0, z: 0});

	const [renderer, setRenderer] = useState<any>();
	const [scene, setScene] = useState<any>();
	const [camera, setCamera] = useState<any>();
	const [cube, setCube] = useState<any>();
	const [userModel, setUserModel] = useState<any>();
	const [userArmTest, setUserArmTest] = useState<any>();
	const [userForearmTest, setUserForearmTest] = useState<any>();
	const [originSphere, setOriginSphere] = useState<any>();

	const [sp_x, setSp_x] = useState<any>();
	const [sp_y, setSp_y] = useState<any>();
	const [sp_z, setSp_z] = useState<any>();
	const [l_x, setL_x] = useState<any>();
	const [l_y, setL_y] = useState<any>();
	const [l_z, setL_z] = useState<any>();

	const sphere_axis_distance = 5;

	// Use the useEffect feature to update the local NodeManager instance when
	// prop is changed in dashboard
	// useEffect(() => {
	// 	setMotionData(motionData.filter(item => false));
	// 	if (props.nodeManager !== undefined) {
	// 		//props.nodeManager.setMotionDataCallback(dataUpdated);
			
	// 		let myNodes: NodeInstance[] = props.nodeManager.getMyNodesList();
	// 		if (myNodes.length > 0) {
	// 			var temp: AssignedMotionData_t[] = [];
	// 			for (var i = 0; i < myNodes.length; i++) {
	// 				let n = myNodes[i];
	// 				var okayToAdd = true;
	// 				if (motionData.length > 0) {
	// 					for (var j = 0; j < motionData.length; j++) {
	// 						if (n.getUUID() === motionData[j].peripheral.uuid) {
	// 							okayToAdd = false;
	// 						}
	// 					}
	// 				}

	// 				if (okayToAdd === true) {
	// 					// console.log(`Adding ${n.getUUID()} to list!`);
	// 					let a: AssignedMotionData_t = {
	// 						peripheral: n.getPeripheral(),
	// 						data: {
	// 							quaternion: {w:0,x:0,y:0,z:0},
	// 							acceleration: {x:0,y:0,z:0}
	// 						}
	// 					}
	// 					temp.push(a);
	// 				}
					
	// 			}
				
	// 			//setMotionData(motionData.concat(temp));
	// 		}

	// 		//props.nodeManager.setMotionDataCallback(dataUpdated);
	// 		setNodeManager(props.nodeManager);
	// 	}
	    
	// }, [props]);



	function initTestModel() {
		setInitSet(true);

		const canvas = document.querySelector('#c');
		const container = document.querySelector('#canvasContainer');

		const backgroundColor = 0x202B35;
		let s = new THREE.Scene();
		s.background = new THREE.Color(backgroundColor);

		// Add lights
	    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
	    hemiLight.position.set(0, 100, 0);
	    // Add hemisphere light to scene
	    s.add(hemiLight);

	    var spotLight = new THREE.DirectionalLight(),
    	spotLightHelper = new THREE.SpotLightHelper(spotLight);
    	spotLight.add(spotLightHelper);
    	s.add(spotLight);
 
	    // set position of spotLight,
	    // and helper bust be updated when doing that
	    spotLight.position.set(100, 200, 50);
	    spotLightHelper.update();

	    let r = new THREE.WebGLRenderer({ canvas, antialias: true });
	    r.setPixelRatio( window.devicePixelRatio );
	    
	    //renderer.setSize( window.innerWidth, window.innerHeight );
	    //renderer.setSize(500, 500);
	    let cam = new THREE.PerspectiveCamera(
			50,
			1,
			0.1,
			1000);

		cam.position.z = 40;//30;
		cam.position.x = 0;
		cam.position.y = 0;

		//cam.rotation.y = Math.PI;
	    

	    if (container !== null) {
	    	container.appendChild( r.domElement );
	    }


	 //    let stacy_txt = new THREE.TextureLoader().load('https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQrpqzXNss6YEiBdPLWNjW2Pb3UZgu5ti4h1_qpuDn0UZ8eWk6B&usqp=CAU')//('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy.jpg');
		// stacy_txt.flipY = false;

		// const stacy_mtl = new THREE.MeshPhongMaterial({
		//   color: 0xeeeeee,
		//   skinning: true });


	    const loader = new GLTFLoader();

	 //    var dracoLoader = new DRACOLoader();
		// dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
		// loader.setDRACOLoader( dracoLoader );
		//let URL = `https://threejs.org/examples/models/gltf/Soldier.glb` //${process.env.PUBLIC_URL}/assets/
		
		let URL = `${process.env.PUBLIC_URL}/assets/models/a/scene.gltf`;
		loader.setRequestHeader({["Content-Type"]: "application/json"});
		// console.log(loader);
		
	    loader.load( URL, function ( gltf: any ) { //https://threejs.org/examples/models/gltf/Soldier.glb

	    	// console.log(gltf);
	      	let model = gltf.scene;

	      	model.traverse( function ( child: any ) {
	      		// console.log(child);
				if ( child.isBone && child.name.includes('mixamorig1LeftArm') ) {

					//let armQ = new THREE.Quaternion(0, 0, 0.1986693, 0.9800666);
					//child.quaternion.copy(armQ);
					setUserArmTest(child);

				} else if (child.isBone && child.name.includes('mixamorig1LeftForeArm')) {
					//child.rotateZ(30);
					setUserForearmTest(child);
				}

			} );

			let model_scale_factor = 0.15;
			model.scale.set(model_scale_factor,model_scale_factor,model_scale_factor);
			model.position.y = -14;

			setUserModel(model);

			s.add(model);
			r.render( s, cam );
			update();
	      
		})

		setRenderer(r);
	    setCamera(cam);
	    setScene(s);
	}


	function update() {
		// if (mixer) {
		// 	mixer.update(clock.getDelta());
		// }

		// if (resizeRendererToDisplaySize(renderer)) {
		// 	const canvas = renderer.domElement;
		// 	camera.aspect = canvas.clientWidth / canvas.clientHeight;
		// 	camera.updateProjectionMatrix();
		// }

		renderer.render(scene, camera);
	}

	function resizeRendererToDisplaySize(renderer: any) {
		const canvas = renderer.domElement;
		let width = window.innerWidth;
		let height = window.innerHeight;
		let canvasPixelWidth = canvas.width / window.devicePixelRatio;
		let canvasPixelHeight = canvas.height / window.devicePixelRatio;

		const needResize =
		canvasPixelWidth !== width || canvasPixelHeight !== height;
		if (needResize) {
			renderer.setSize(width, height, false);
		}
		return needResize;
	}



	function init() {

		setInitSet(true);

		const canvas = document.querySelector('#c');
		const container = document.querySelector('#canvasContainer');

		const backgroundColor = 0x202B35;
		let s = new THREE.Scene();
		s.background = new THREE.Color(backgroundColor);

		// Add lights
	    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
	    hemiLight.position.set(0, 100, 0);
	    // Add hemisphere light to scene
	    s.add(hemiLight);

	    var spotLight = new THREE.DirectionalLight(),
    	spotLightHelper = new THREE.SpotLightHelper(spotLight);
    	spotLight.add(spotLightHelper);
    	s.add(spotLight);
 
	    // set position of spotLight,
	    // and helper bust be updated when doing that
	    spotLight.position.set(100, 200, 50);
	    spotLightHelper.update();

	    // let d = 8.25;
	    // let dirLight = new THREE.DirectionalLight(0xffffff, 0.74);
	    // dirLight.position.set(-8, 12, -8);
	    // dirLight.castShadow = true;
	    // dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
	    // dirLight.shadow.camera.near = 0.1;
	    // dirLight.shadow.camera.far = 1500;
	    // dirLight.shadow.camera.left = d * -1;
	    // dirLight.shadow.camera.right = d;
	    // dirLight.shadow.camera.top = d;
	    // dirLight.shadow.camera.bottom = d * -1;
	    // // Add directional Light to scene
	    // s.add(dirLight);
	    
	    //var renderer = new THREE.WebGLRenderer();
	    let r = new THREE.WebGLRenderer({ canvas, antialias: true });
	    r.setPixelRatio( window.devicePixelRatio );
	    
	    //renderer.setSize( window.innerWidth, window.innerHeight );
	    //renderer.setSize(500, 500);
	    let cam = new THREE.PerspectiveCamera( 75, 1, 0.1, 1000 );
	    

	    if (container !== null) {
	    	container.appendChild( r.domElement );
	    }
	    var geometry = new THREE.BoxGeometry( 0.1, 0.1, 0.1 );
	    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
	    let cu = new THREE.Mesh( geometry, material );
	    cu.castShadow = true;
	    cu.receiveShadow = true;
	    s.add( cu );

	    let sphere_size = 0.25;
	    let sphere_meshes = 6;

	    var originSphereMaterial = new THREE.MeshLambertMaterial( {color: 0x5F6C76} );
	    originSphereMaterial.flatShading = true;
	    var sphereGeometry = new THREE.SphereGeometry(sphere_size, sphere_meshes, sphere_meshes);
	    let sp_origin = new THREE.Mesh(sphereGeometry, originSphereMaterial);
	    sp_origin.castShadow = true;
	    sp_origin.receiveShadow = true;
	    s.add(sp_origin);


	    var sphereColors = [0x4959C1, 0xF02E4A, 0x49C16B];
	    for (var i = 0; i < 3; i ++) {
	    	let thisColor = sphereColors[i];
	    	var newSphereMaterial = new THREE.MeshLambertMaterial( {color: thisColor} );
	    	newSphereMaterial.flatShading = true;

	    	let sp_new = new THREE.Mesh(sphereGeometry, newSphereMaterial);
	    	sp_new.castShadow = true;
	    	sp_new.receiveShadow = true;
	    	sp_new.position.set(i === 0 ? sphere_axis_distance : 0, i === 1 ? sphere_axis_distance : 0, i === 2 ? sphere_axis_distance : 0);
	    	s.add(sp_new);

	    	

	    	// setup linking axis line
	    	var lineMaterial = new THREE.LineBasicMaterial( {color: thisColor} );
	    	var linePoints = [];
	    	linePoints.push( new THREE.Vector3(0,0,0) );
	    	linePoints.push( new THREE.Vector3(i === 0 ? sphere_axis_distance : 0, i === 1 ? sphere_axis_distance : 0, i === 2 ? sphere_axis_distance : 0) );

	    	var lineGeometry = new THREE.Geometry();//new THREE.BufferGeometry().setFromPoints( linePoints );
	    	lineGeometry.vertices = linePoints;
	    	var newLine = new THREE.Line( lineGeometry, lineMaterial );
	    	s.add(newLine);


	    	switch (i) {
	    		case 0:
	    			setSp_x(sp_new);
	    			setL_x(newLine);
	    			break;
	    		case 1:
	    			setSp_y(sp_new);
	    			setL_y(newLine);
	    			break;
	    		case 2:
	    			setSp_z(sp_new);
	    			setL_z(newLine);
	    			break;
	    		default:
	    			break;
	    	}
	    }

	    cam.position.z = 10;
	    var animate = function () {
	      requestAnimationFrame( animate );
	      cu.rotation.x += 0.01;
	      cu.rotation.y += 0.01;
	      r.render( s, cam );
	    };

	    

	    //let THREEq = new THREE.Quaternion(1,0,0,0);
		//cube.quaternion.copy(THREEq);
		r.render( s, cam );
	    //animate();

	    // console.log(r);
	    setRenderer(r);
	    setCamera(cam);
	    setScene(s);
	    setCube(cu);
	    setOriginSphere(sp_origin);
	}



	/**
	 *	toggleDataStream
	 *  ----------------------------------------------------------------------------------------------------
	 *	Toggles the state of data streaming. Calls the appropriate prop methods for the parent component to 
	 *	call the respective NodeManager functions while setting the local state var accordingly
	 *
	 */
	function toggleDataStream() {

		if (isStreaming === true) {
			props.endDataStream();
		} else {
			if (nodeManager !== undefined) {
				nodeManager.setMotionDataCallback(dataUpdated);
			}
			setMotionData(motionData.filter(item => false));
			props.beginDataStream();
		}

		setIsStreaming(!isStreaming);
	}


	/**
	 *	dataUpdated
	 *  ----------------------------------------------------------------------------------------------------
	 *	Callback from NodeManager. Called when Node motion data is updated
	 *	@param data {any[]} list of objects containing latest Node motion data for each connected Node
	 *
	 */

	function dataUpdated(data: any[]) {

		if (data !== undefined && data.length > 0) {
			var temp: AssignedMotionData_t[] = [];

			var quaternions: any[] = [];
			
			let last_quaternion = {w: 1, x: 0, y: 0, z:0};
			for (var i = 0; i < data.length; i++) {
				let d = data[i].data;
				let p = data[i].peripheral;
				let q = data[i].data.quaternion;
				//last_quaternion = q;

				quaternions.push(q);

				let newAssignedMotionData: AssignedMotionData_t = {data: d, peripheral: p};
				temp.push(newAssignedMotionData);
			}

			//updateAxisDisplay(last_quaternion);

			var d = new Date();
  			var n = d.getTime();
  			if (n - lastUpdateTime >= 1000) {

  				if (quaternions.length === 2) {
  					let re_referenced_forearm_quaternion = q_undo_rotate(quaternions[0], quaternions[1]);
  					let quaternion_forearm = new THREE.Quaternion(re_referenced_forearm_quaternion.z, re_referenced_forearm_quaternion.x, -re_referenced_forearm_quaternion.y, re_referenced_forearm_quaternion.w);
  					let quaternion_upperarm = new THREE.Quaternion(quaternions[1].x, quaternions[1].y, quaternions[1].z, quaternions[1].w);

  					if (userArmTest !== undefined) {
						userArmTest.quaternion.copy(quaternion_upperarm);
						update();
					}

					if (userForearmTest !== undefined) {
						userForearmTest.quaternion.copy(quaternion_forearm);
						update();
					}
  				} else {
  					let quaternion_upperarm = new THREE.Quaternion((quaternions[0].y + previousQuaternion.y) / 2, (quaternions[0].x + previousQuaternion.x) / 2, -(quaternions[0].z + previousQuaternion.z) / 2, (quaternions[0].w + previousQuaternion.w) / 2);
  					if (userArmTest !== undefined) {
						userArmTest.quaternion.copy(quaternion_upperarm);
						update();
					}

					setPreviousQuaternion(quaternion_upperarm);
  				}
				
				// cube.quaternion.copy(THREEq);
				// renderer.render( scene, camera );

				

				// if (userModel !== undefined) {
				// 	userModel.traverse( function ( child: any ) {
			 //      		//// console.log(child);
				// 		if ( child.isBone && child.name.includes('mixamorig1LeftForeArm') ) {
				// 			child.quaternion.copy(THREEq);
				// 		}
				// 	} );
				// }

				

				setLastUpdateTime(n);
			}

			setMotionData(motionData.filter(item => false));
  			setMotionData(motionData.concat(temp));
		}
	}


	function updateAxisDisplay(quaternion: any) {
		let n_x = {w: 0, x: 1.0,y: 0,z: 0};
    	let n_y = {w: 0, x: 0,y: 1.0,z: 0};
    	let n_z = {w: 0, x: 0,y: 0,z: 1.0};

    	// console.log(quaternion);

    	let NX = q_rotate(n_x, quaternion);
    	let NY = q_rotate(n_y, quaternion);
    	let NZ = q_rotate(n_z, quaternion);

    	sp_x.position.set(NX.x * sphere_axis_distance, NX.y * sphere_axis_distance, NX.z * sphere_axis_distance);
    	sp_y.position.set(NY.x * sphere_axis_distance, NY.y * sphere_axis_distance, NY.z * sphere_axis_distance);
    	sp_z.position.set(NZ.x * sphere_axis_distance, NZ.y * sphere_axis_distance, NZ.z * sphere_axis_distance);

    	l_x.geometry.vertices[1].x = NX.x * sphere_axis_distance;
    	l_x.geometry.vertices[1].y = NX.y * sphere_axis_distance;
    	l_x.geometry.vertices[1].z = NX.z * sphere_axis_distance;
    	l_x.geometry.verticesNeedUpdate = true;

    	l_y.geometry.vertices[1].x = NY.x * sphere_axis_distance;
    	l_y.geometry.vertices[1].y = NY.y * sphere_axis_distance;
    	l_y.geometry.vertices[1].z = NY.z * sphere_axis_distance;
    	l_y.geometry.verticesNeedUpdate = true;

    	l_z.geometry.vertices[1].x = NZ.x * sphere_axis_distance;
    	l_z.geometry.vertices[1].y = NZ.y * sphere_axis_distance;
    	l_z.geometry.vertices[1].z = NZ.z * sphere_axis_distance;
    	l_z.geometry.verticesNeedUpdate = true;
    	renderer.render( scene, camera );
	}




	function q_multiply(q: any, p: any)
	{
	    var result = {w: 1, x: 0, y: 0, z:0};

	    result.w = q.w*p.w - q.x*p.x - q.y*p.y - q.z*p.z;
	    result.x = q.x*p.w + q.w*p.x - q.z*p.y + q.y*p.z;
	    result.y = q.y*p.w + q.z*p.x + q.w*p.y - q.x*p.z;
	    result.z = q.z*p.w - q.y*p.x + q.x*p.y + q.w*p.z;

	    return result;

	}

	function q_invert(q: any)
	{
	    var result = {w: 1, x: 0, y: 0, z:0};
	    
	    const quaternionMagSq = (q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z);
	    result.w = q.w / quaternionMagSq;
	    result.x = -1 * q.x / quaternionMagSq;
	    result.y = -1 * q.y / quaternionMagSq;
	    result.z = -1 * q.z / quaternionMagSq;

	    return result;
	}

	function q_rotate(q: any, w: any)
	{
	    var w_inv = {w: 1, x: 0, y: 0, z:0};
	    var a_world = {w: 1, x: 0, y: 0, z:0};
	    var w_a = {w: 1, x: 0, y: 0, z:0};
	    var a_body = {w: 1, x: 0, y: 0, z:0};

	    a_body    = q;
	    w_inv     = q_invert(w);

	    w_a       = q_multiply(w, a_body);
	    a_world   = q_multiply(w_a, w_inv);

	    return a_world;
	}

	function q_undo_rotate(q: any, w: any)
	{
	    var w_inv = {w: 1, x: 0, y: 0, z:0};
	    var a_world = {w: 1, x: 0, y: 0, z:0};
	    var w_a = {w: 1, x: 0, y: 0, z:0};
	    var a_body = {w: 1, x: 0, y: 0, z:0};

	    a_world    = q;
	    w_inv     = q_invert(w);

	    w_a       = q_multiply(w_inv, a_world);
	    a_body    = q_multiply(w_a, w);

	    return a_body;
	}


	function getRoundedNumber(n: number) {
		return (Math.floor(n * 1000)) / 1000;
	}


	/**
	 *	scan
	 *  ----------------------------------------------------------------------------------------------------
	 *	Call to re-run 5s BLE scan for Nodes
	 *
	 */
	function scan() {
		if (nodeManager !== undefined) {
			nodeManager.scanForNodes();
		}
	}

	function toggleTapEnable() {

		if (nodeManager === undefined) { return; }
		if (isTapEnabled) {
			nodeManager.disableTap();
		} else {
			nodeManager.enableTap();
		}

		setIsTapEnabled(!isTapEnabled);
	}


	return (
		<div className="page-container">
			<div className="node-test-header-container">
				<div 
					onClick={() => toggleDataStream()}
					className={ isStreaming ? "node-test-button-dark" : "node-test-button-blue"}>
					{ isStreaming ? "End Stream" : "Begin Stream" }
				</div>
				<div 
					onClick={() => toggleTapEnable()}
					className={ isTapEnabled ? "node-test-button-dark" : "node-test-button-blue"}>
					{ isTapEnabled ? "Disable Tap" : "Enable Tap" }
				</div>
				<div 
					hidden={initSet}
					onClick={() => initTestModel()}
					className="node-test-button-dark">
					Start ThreeJS
				</div>
				
			</div>
			<div className="node-test-content-container">
				{motionData.map((item: AssignedMotionData_t, index: number) => (
					<div key={`node-data-cell-${index}`} className="node-test-row-container">
						<div className="node-test-row-header-container">
							<h4>{item.peripheral.name}</h4>
							<p>{item.peripheral.uuid}</p>
						</div>
						<div className="node-test-row-content-container">
							<div className="node-test-row-content-col">
								<p>W: {getRoundedNumber(item.data.quaternion.w)}</p>
								<p>X: {getRoundedNumber(item.data.quaternion.x)}</p>
							</div>
							<div className="node-test-row-content-col">
								<p>Y: {getRoundedNumber(item.data.quaternion.y)}</p>
								<p>Z: {getRoundedNumber(item.data.quaternion.z)}</p>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="node-test-model-container" id="canvasContainer">
				<canvas className="node-test-model" id="c"></canvas>
			</div>
		</div>
	)
}


/*

<div className="node-test-model-container" id="canvasContainer">
					<canvas className="node-test-model" id="c"></canvas>
				</div>
*/
export default NodeTest;




/*
	function init() {

	    const MODEL_PATH = 'https://threejs.org/examples/js/controls/TransformControls.js' //'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy_lightweight.glb';
	    
	    const canvas = document.querySelector('#c');
	    const container = document.querySelector('#canvasContainer');
	    const backgroundColor = 0x202B35;//0xf1f1f1;

	    // Init the scene
	    scene = new THREE.Scene();
	    scene.background = new THREE.Color(backgroundColor);
	    //scene.fog = new THREE.Fog(backgroundColor, 60, 100);

	    // Init the renderer
	    renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
	    renderer.shadowMap.enabled = true;
	    renderer.setPixelRatio(window.devicePixelRatio);
	    if (container) {
	    	container.appendChild(renderer.domElement);
	    }
	   

	     // Add a camera
		camera = new THREE.PerspectiveCamera(
		50,
		window.innerWidth / window.innerHeight,
		0.1,
		1000);

		camera.position.z = -30;//30;
		camera.position.x = 0;
		camera.position.y = -3;

		camera.rotation.y = Math.PI;

		let stacy_txt = new THREE.TextureLoader().load('https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQrpqzXNss6YEiBdPLWNjW2Pb3UZgu5ti4h1_qpuDn0UZ8eWk6B&usqp=CAU')//('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy.jpg');
		stacy_txt.flipY = false;

		const stacy_mtl = new THREE.MeshPhongMaterial({
		  color: 0xeeeeee,
		  skinning: true });


		
		const loader = new GLTFLoader();
		let URL = `https://threejs.org/examples/models/gltf/Soldier.glb` //${process.env.PUBLIC_URL}/assets/
		loader.setRequestHeader({["Content-Type"]: "application/json"});
		// console.log(loader);
		
	    loader.load( URL, function ( gltf: any ) { //https://threejs.org/examples/models/gltf/Soldier.glb

	      model = gltf.scene;
	      let fileAnimations = gltf.animations;
	      
	      model.traverse((o: any) => {

	        // console.log(`${o.name}, ${o.isBone}`);
	        
	        if (o.isMesh) {
	          o.castShadow = true;
	          o.receiveShadow = true;
	          o.material = stacy_mtl;
	        }

	        if (o.isBone && o.name.includes('mixamorigLeftLeg')) {
	         // o.rotateX(-30);

	        }        

	      });

	      //model.getObjectByName( 'mixamorigSpine' ).rotateZ(25);
	      //model.getObjectByName( 'mixamorigSpine' ).rotateY(25);
	      rightArm = model.getObjectByName( 'mixamorigRightArm' );
	      //rightArm.rotateZ(0.8);
	      // console.log(rightArm.quaternion);
		
	      model.scale.set(7, 7, 7);
	      model.position.y = -11;

	      scene.add(model);

	      //loaderAnim.remove();
	      /*
	      mixer = new THREE.AnimationMixer(model);

	      let clips = fileAnimations.filter((val: any) => val.name !== 'idle');
	      possibleAnims = clips.map((val: any) => {
	        let clip = THREE.AnimationClip.findByName(clips, val.name);

	        clip.tracks.splice(3, 3);
	        clip.tracks.splice(9, 3);

	        clip = mixer.clipAction(clip);
	        return clip;
	      });
	      
		})

		// Add lights
	    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
	    hemiLight.position.set(0, 50, 0);
	    // Add hemisphere light to scene
	    scene.add(hemiLight);

	    let d = 8.25;
	    let dirLight = new THREE.DirectionalLight(0xffffff, 0.74);
	    dirLight.position.set(-8, 12, -8);
	    dirLight.castShadow = true;
	    dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
	    dirLight.shadow.camera.near = 0.1;
	    dirLight.shadow.camera.far = 1500;
	    dirLight.shadow.camera.left = d * -1;
	    dirLight.shadow.camera.right = d;
	    dirLight.shadow.camera.top = d;
	    dirLight.shadow.camera.bottom = d * -1;
	    // Add directional Light to scene
	    scene.add(dirLight);


	    // Floor
	    let floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
	    let floorMaterial = new THREE.MeshPhongMaterial({
	      color: 0x202B35,
	      shininess: 0 });


	    let floor = new THREE.Mesh(floorGeometry, floorMaterial);
	    floor.rotation.x = -0.5 * Math.PI;
	    floor.receiveShadow = true;
	    floor.position.y = -11;
	    scene.add(floor);

	    update();
	}

	function resizeRendererToDisplaySize(renderer: any) {
		const canvas = renderer.domElement;
		let width = window.innerWidth;
		let height = window.innerHeight;
		let canvasPixelWidth = canvas.width / window.devicePixelRatio;
		let canvasPixelHeight = canvas.height / window.devicePixelRatio;

		const needResize =
		canvasPixelWidth !== width || canvasPixelHeight !== height;
		if (needResize) {
			renderer.setSize(width, height, false);
		}
		return needResize;
	}

	function update() {
		if (mixer) {
			mixer.update(clock.getDelta());
		}

		if (resizeRendererToDisplaySize(renderer)) {
			const canvas = renderer.domElement;
			camera.aspect = canvas.clientWidth / canvas.clientHeight;
			camera.updateProjectionMatrix();
		}

		renderer.render(scene, camera);
		requestAnimationFrame(update);
	}

	let mouseDown = false;
	var prevCoords: any = null;
	var cameraAngle = 0;

	function moveCamera(coords: any) {

		if (!prevCoords) {
			prevCoords = coords;
			return; 
		}

		let radius = 30.0;
		let xDiff = prevCoords.x - coords.x;
		let xScaler = 0.01;
		cameraAngle += xDiff * xScaler;

		camera.rotation.y = cameraAngle;
		camera.position.z = radius * Math.cos(cameraAngle);
		camera.position.x = radius * Math.sin(cameraAngle);

		update();

		//rightArm.rotateZ(THREE.Math.degToRad(cameraAngle * 0.1));
		/*
		if (rightArm) {
		rightArm.rotation.x = cameraAngle * 5;
		rightArm.rotation.y = cameraAngle * 5;
		rightArm.rotation.z = cameraAngle * 5;
		// console.log(cameraAngle * 5);
		}
		prevCoords = coords;
	}

	function getMousePos(e: any) {
    	return { x: e.clientX, y: e.clientY };
  	}

  	function getCoordinates(event: any) {
  		// console.log(`STUFFS: ${event.clientX}, ${// console.log(event.clientY)}`);
  		moveCamera({x: event.clientX, y: event.clientY});
  	}

  	function dataUpdated(data: any[]) {
		
		//// console.log("> NODE TEST: data updated!");
		//setMotionData([]);
		if (data !== undefined && data.length > 0) {
			var temp: AssignedMotionData_t[] = [];
			
			let last_quaternion = {w: 1, x: 0, y: 0, z:0};
			for (var i = 0; i < data.length; i++) {
				let d = data[i].data;
				let p = data[i].peripheral;
				let q = data[i].data.quaternion;
				last_quaternion = q;

				let newAssignedMotionData: AssignedMotionData_t = {data: d, peripheral: p};
				temp.push(newAssignedMotionData);
			}
			
			
			//setMotionData(motionData.filter(item => false));
			//setMotionData(motionData.concat(newAssignedMotionData));
			//setLatestQ(q);

			
			let m = Math.sqrt((q.w*q.w)+(q.x*q.x)+(q.y*q.y)+(q.z*q.z));
			setMag(m);

			var angles = {yaw: 0, pitch: 0, roll: 0}
        
	        // roll (x-axis rotation)
	        let sinr_cosp = 2 * (q.w * q.x + q.y * q.z);
	        let cosr_cosp = 1 - 2 * (q.x * q.x + q.y * q.y);
	        angles.roll = Math.atan2(sinr_cosp, cosr_cosp) * 133.74693877555;

	        // pitch (y-axis rotation)
	        let sinp = 2 * (q.w * q.y - q.z * q.x);
	        if (Math.abs(sinp) >= 1) {
	            angles.pitch = Math.sign(sinp) * 1.5707963268 * 133.74693877555; //Math.copysign(Double.pi / 2, sinp); // use 90 degrees if out of range
	        } else {
	            angles.pitch = Math.asin(sinp) * 133.74693877555;
	        }
	        
	        // yaw (z-axis rotation)
	        let siny_cosp = 2 * (q.w * q.z + q.x * q.y);
	        let cosy_cosp = 1 - 2 * (q.y * q.y + q.z * q.z);
	        angles.yaw = Math.atan2(siny_cosp, cosy_cosp) * 133.74693877555;

	        setLatestEuler(angles);
			

	        // Check if Three JS should be updated
	        
	        var d = new Date();
  			var n = d.getTime();
  			if (n - lastUpdateTime >= 1000) {
  				// console.log("A");
  				let THREEq = new THREE.Quaternion(last_quaternion.z,last_quaternion.x,last_quaternion.y,last_quaternion.w);
  				// console.log("B");
  				// console.log(THREEq);
  				// console.log(last_quaternion);
  				if (cube !== undefined) {
  					cube.quaternion.copy(THREEq);
  					// console.log("C");
	  				// console.log(renderer);
			      	renderer.render( scene, camera );
			      	// console.log("D");
  				}
  				
  				

	        	//rightArm = model.getObjectByName( 'mixamorigRightArm' );
	        	//rightArm.quaternion.copy(THREEq);
	        	//update();
	        	setLastUpdateTime(n);
	        	// console.log("E");
  			}

  			setMotionData(motionData.filter(item => false));
  			setMotionData(motionData.concat(temp));
  			updateCube();
	        
		}

	}
*/
	//update();









