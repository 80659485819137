import React, { useState, useEffect } from 'react';
import Mixpanel from '../../../Mixpanel';

import './CreateWorkoutExerciseList.css';
import CreateWorkoutExerciseListCell from './CreateWorkoutExerciseListCell';

import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';
import Tempo_t from '../../../Interfaces/Tempo_t';

function CreateWorkoutExerciseList(props: any) {

    let testMuscle: Muscles_t = {group: 0, muscle: 0};

    let testExerciseSeries: ExerciseSeries_t[] = [
        {
            title: "A",
            index: 0,
            exercises: [
                {   
                    title:'First exercise',
                    id:'abc',
                    type: 0,
                    reps: [16,12,8,12,16],
                    muscles:[{group: 0, muscle: 0}],
                    restTime: [40,40,40,30,30],
                    percent_wms: [60,75,85,80,70],
                    tempos: [
                        {eccentric: 1, first_pause: 0, concentric: 3, second_pause: 0},
                        {eccentric: 2, first_pause: 0, concentric: 2, second_pause: 0},
                        {eccentric: 2, first_pause: 0, concentric: 2, second_pause: 0},
                        {eccentric: 2, first_pause: 0, concentric: 2, second_pause: 0},
                        {eccentric: 2, first_pause: 0, concentric: 1, second_pause: 0}
                    ]
                },
                {   
                    title:'Second exercise',
                    id:'def',
                    type: 0,
                    reps: [16,12,8,12,16],
                    muscles:[{group: 0, muscle: 0}]
                }
            ]
        },
        {
            title: "B",
            index: 1,
            exercises: [
                {   
                    title:'Third exercise',
                    id:'ghi',
                    type: 0,
                    reps: [16,12,8,12,16],
                    muscles:[{group: 0, muscle: 0}]
                }
            ]
        }

    ]

    const [initSet, setInitSet] = useState(false);
    const [exerciseSeries, setExerciseSeries] = useState<ExerciseSeries_t[]>([]);
    //const [exercises, setExercises] = useState<Exercise_t[]>([]);

    const [addingToSuperset, setAddingToSuperset] = useState(-1);    // Set to -1 when adding new exercise, or to index of superset
    const [editingExercise, setEditingExercise] = useState(false);

    const [purposedMerge, setPurposedMerge] = useState(false);
    const [purposedIndex, setPurposedIndex] = useState(-1);
    const [purposedIndex_exercise, setPurposedIndex_exercise] = useState(-1);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        // console.log('CreateWorkoutExerciseList: exerciseSeries has been updated. Will notify parent');
        props.exerciseSeriesListUpdated(exerciseSeries);
    }, [exerciseSeries])

    useEffect(() => {
        // console.log("DINK | props.series: ", props.series)
        if (props.series !== undefined && props.series.length !== exerciseSeries.length) {
            setExerciseSeries(props.series)
        }
    }, [props.series])

    useEffect(() => {
        // console.log(`CHANGING SELECTED EXERCISE!`)
        // console.log(props.selectedExercise);
        //setConnected(props.isConnected);

        if (editingExercise) {

            setExerciseSeries(
                exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                    // console.log("ITEM:", item);

                    var exerciseList: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        if (thisExercise.id === props.selectedExercise.id) {
                            exerciseList.push(props.selectedExercise);
                        } else {
                            exerciseList.push(thisExercise);
                        }
                    }

                    return {...item, exercises: exerciseList};
                })
            );

            setEditingExercise(false);
            return;
        }


        if (props.selectedExercise !== undefined) {
            if (props.selectedExercise.title !== '') {
                //props.exerciseListUpdated(exercises.concat(props.selectedExercise));
                //setExercises(exercises.concat(props.selectedExercise));
                if (addingToSuperset === -1) {
                    // Create new exercise breakdown to hold this exercise
                    let newExerciseBreakdownIndex = exerciseSeries.length;
                    let thisNewExerciseBreakdown: ExerciseSeries_t = {
                        title: getSetLetter(newExerciseBreakdownIndex),
                        index: newExerciseBreakdownIndex,
                        exercises: [props.selectedExercise]
                    };
                    setExerciseSeries(exerciseSeries.concat(thisNewExerciseBreakdown));
                } else {
                    // Add to a superset
                    let newNumSets = props.selectedExercise && props.selectedExercise.reps ? props.selectedExercise.reps.length : 0;
                    setExerciseSeries(exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                        if (index === addingToSuperset) {
                            let seriesTemp = item;
                            var newExerciseListTemp = seriesTemp.exercises ?? [];
                            for (var i = 0; i < newExerciseListTemp.length; i++) {
                                let thisExerciseTemp = newExerciseListTemp[i];
                                if (newNumSets > 0 && thisExerciseTemp) {
                                    var newRepArry = [];
                                    let existingRepArry = thisExerciseTemp.reps ?? [];
                                    for (var j = 0; j < newNumSets; j++) {
                                        let thisNumReps = existingRepArry[j] ?? ( existingRepArry[existingRepArry.length - 1] ?? 12 );
                                        newRepArry.push(thisNumReps)
                                    }
                                    newExerciseListTemp[i].reps = newRepArry;
                                } else {

                                }
                            }
                            newExerciseListTemp.push(props.selectedExercise)
                            seriesTemp.exercises = newExerciseListTemp;
                            return seriesTemp;
                        } else {
                            return item;
                        }
                    }));


                    // setExerciseSeries(
                    //     exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                    //         // console.log("ITEM:", item);
                    //         if (index === addingToSuperset) {
                    //             // console.log(index, "CreateWorkoutExerciseList: useEffect | props.selectedExercise:", props.selectedExercise);
                    //             // console.log(index, "CreateWorkoutExerciseList: useEffect | item:", item);
                    //             var tempExerciseArray = item.exercises;
                    //             tempExerciseArray.push(props.selectedExercise);
                    //             return {...item, exercises: tempExerciseArray};
                    //         } else {
                    //             return item;
                    //         }
                    //     })
                    // );
                }
            }
        }
    }, [props.selectedExercise]);

    useEffect(() => {
        if (props.workoutsAdded !== undefined && props.workoutsAdded > 0) {
            setExerciseSeries(exerciseSeries.filter(item => false));
            setEditingExercise(false);
            setAddingToSuperset(-1);
        }
    }, [props.workoutsAdded])

    function initialize() {
        // setExerciseSeries(
        //     exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
        //         var tempExerciseArray: Exercise_t[] = [];
        //         for (var i = 0; i < item.exercises.length; i++) {
        //             let thisExercise = item.exercises[i];
        //             thisExercise.isShowing = false;
        //             tempExerciseArray.push(thisExercise);
        //         }
        //         return {...item, exercises: tempExerciseArray};
        //     })
        // );

        window.addEventListener('keypress', function(e) {   

            // console.log("PRESSED:", e.keyCode);

               switch (e.keyCode) {
                   case 2:              // 'ctrl + b' 
                       addAnotherExercise();
                       break;
                   
                   default:
                       break;
               }            
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function addAnotherExercise() {
        Mixpanel.track("Workout builder | Add new block");
        setAddingToSuperset(-1);
        setEditingExercise(false);
        props.addAnotherExercise();
    }

    function addToSupersetPressed(atIndex: number) {
        Mixpanel.track("Workout builder | Add to superset",{series_index: atIndex});
        setAddingToSuperset(atIndex);
        setEditingExercise(false);
        var numSets = -1;
        if (exerciseSeries[atIndex] !== undefined && exerciseSeries[atIndex].exercises !== undefined && exerciseSeries[atIndex].exercises[0] !== undefined) {
            let thisExericse = exerciseSeries[atIndex].exercises[0];
            numSets = thisExericse.reps !== undefined ? thisExericse.reps.length : -1;
        }
        // console.log("addToSupersetPressed", atIndex, numSets, exerciseSeries)
        props.addAnotherExercise(numSets);
    }

    function moveExerciseUp(atIndex: number) {
        Mixpanel.track("Workout builder | Move block up",{series_index: atIndex});
        let newArray = array_move(atIndex, atIndex - 1);
        setExerciseSeries(newArray);
    }

    function moveExerciseDown(atIndex: number) {
        Mixpanel.track("Workout builder | Move block down",{series_index: atIndex});
        let newArray = array_move(atIndex, atIndex + 1);
        setExerciseSeries(newArray);
    }

    function array_move(old_index: number, new_index: number) {
        var arr: ExerciseSeries_t[] = [];
        for (var i = 0; i < exerciseSeries.length; i ++) {
            arr.push(exerciseSeries[i]);
        }

        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push({title: "",index: 0,exercises: []});
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    function removeEntireExerciseSeries(seriesIndex: number) {

        var currentSeriesList: ExerciseSeries_t[] = JSON.parse(JSON.stringify(props.series));
        var newSeriesList: ExerciseSeries_t[] = [];
        for (var i = 0; i < currentSeriesList.length; i++) {
            if (i !== seriesIndex) {
                newSeriesList.push(currentSeriesList[i]);
            }
        }

        props.exerciseSeriesListUpdated(newSeriesList);
    }

    function removeExerciseSeries(forExerciseSeries: ExerciseSeries_t, atExerciseIndex: number) {
        //setExercises(exercises.filter((item, index) => atIndex !== index));

        // console.log("REMOVING:", forExerciseSeries, "atExerciseIndex", atExerciseIndex);
        if (forExerciseSeries.exercises.length <= 1) {
            if (exerciseSeries.length === 1) {
                setExerciseSeries([]);
            } else {
                var esTemp: ExerciseSeries_t[] = [];//exerciseSeries;

                for (var i = 0; i < exerciseSeries.length; i++) {
                    var thisEs = exerciseSeries[i];
                    
                    if (forExerciseSeries.index !== i) {
                        thisEs.index = esTemp.length;
                        thisEs.title = getSetLetter(esTemp.length);
                        esTemp.push(thisEs);
                    }
                }

                setExerciseSeries(esTemp);
                //setExerciseSeries(exerciseSeries.filter((item: ExerciseSeries_t, index: number) => forExerciseSeries.index !== index));
            }
        } else {
            // setExerciseSeries(
            //     exerciseSeries.filter((item: ExerciseSeries_t, index: number) => {
            //         if (item.index === forExerciseSeries.index) {
            //             var arr: Exercise_t[] = forExerciseSeries.exercises.filter((item, index) => atExerciseIndex !== index);
            //             return {...item, exercises: arr};
            //         } else {
            //             return item;
            //         }
            //     })
            // );
            var esTemp: ExerciseSeries_t[] = [];//exerciseSeries;

            for (var i = 0; i < exerciseSeries.length; i++) {
                let thisEs = exerciseSeries[i];
                let esEx = thisEs.exercises;

                let thisExListTemp: Exercise_t[] = [];
                for (var j = 0; j < esEx.length; j++) {
                    let thisEsEx: Exercise_t = esEx[j];
                    if (forExerciseSeries.index !== i || atExerciseIndex !== j) {
                        thisExListTemp.push(thisEsEx);
                        // console.log("ADDING AT i: ", i, ", j: ", j);
                    }
                }
                var exTemp = thisEs;
                exTemp.exercises = thisExListTemp;
                esTemp.push(exTemp);
            }
            setExerciseSeries(esTemp);
        }
    }

    function switchIsShowing(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        Mixpanel.track("Workout builder | Toggle exercise info display",{series_length: forExerciseSeries.exercises.length, exercise_title: forExercise.title});
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        if (thisExercise.id === forExercise.id) {
                            thisExercise.isShowing = thisExercise.isShowing === undefined ? true : !thisExercise.isShowing;
                        } else {
                            thisExercise.isShowing = false;
                        }
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                } else {
                    var tempExerciseArray: Exercise_t[] = [];
                    for (var i = 0; i < item.exercises.length; i++) {
                        let thisExercise = item.exercises[i];
                        thisExercise.isShowing = false;
                        tempExerciseArray.push(thisExercise);
                    }
                    return {...item, exercises: tempExerciseArray};
                }
            })
        );
    }

    function moveUpExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        Mixpanel.track("Workout builder | Move exercise up in series",{series_length: forExerciseSeries.exercises.length, exercise_index: exerciseIndex});
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var arr: Exercise_t[] = [];

                    let new_index = exerciseIndex - 1;
                    let old_index = exerciseIndex;

                    for (var i = 0; i < item.exercises.length; i ++) {
                        arr.push(item.exercises[i]);
                    }

                    if (new_index >= arr.length) {
                        var k = new_index - arr.length + 1;
                        while (k--) {
                            arr.push({title:'',id:'',type: 0,muscles:[]});
                        }
                    }
                    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);


                    return {...item, exercises: arr};
                } else {
                    return item;
                }
            })
        );
    }

    function moveDownExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        Mixpanel.track("Workout builder | Move exercise down in series",{series_length: forExerciseSeries.exercises.length, exercise_index: exerciseIndex});
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (item.index === forExerciseSeries.index) {
                    var arr: Exercise_t[] = [];

                    let new_index = exerciseIndex + 1;
                    let old_index = exerciseIndex;

                    for (var i = 0; i < item.exercises.length; i ++) {
                        arr.push(item.exercises[i]);
                    }

                    if (new_index >= arr.length) {
                        var k = new_index - arr.length + 1;
                        while (k--) {
                            arr.push({title:'',id:'',type: 0,muscles:[]});
                        }
                    }
                    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);


                    return {...item, exercises: arr};
                } else {
                    return item;
                }
            })
        );
    }

    function removeFromSupersetPressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        Mixpanel.track("Workout builder | Remove from superset",{series_length: forExerciseSeries.exercises.length, exercise_index: exerciseIndex});

        var esTemp: ExerciseSeries_t[] = [];//exerciseSeries;

        for (var i = 0; i < exerciseSeries.length; i++) {
            let thisEs = exerciseSeries[i];
            let esEx = thisEs.exercises;

            let thisExListTemp: Exercise_t[] = [];
            for (var j = 0; j < esEx.length; j++) {
                let thisEsEx: Exercise_t = esEx[j];
                if (forExerciseSeries.index !== i || exerciseIndex !== j) {
                    thisExListTemp.push(thisEsEx);
                }
            }

            var exTemp = thisEs;
            exTemp.exercises = thisExListTemp;
            if (i > forExerciseSeries.index) {
                exTemp.title = getSetLetter(esTemp.length);
            }
            esTemp.push(exTemp);
            if (i === forExerciseSeries.index) {
                let newExerciseBreakdownIndex = i + 1;
                let thisNewExerciseBreakdown: ExerciseSeries_t = {
                    title: getSetLetter(newExerciseBreakdownIndex),
                    index: newExerciseBreakdownIndex,
                    exercises: [forExercise]
                };
                esTemp.push(thisNewExerciseBreakdown);
            }
        }
        setExerciseSeries(esTemp);
    }

    function mergeDownToSuperset(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
        Mixpanel.track("Workout builder | Merge to next superset",{series_length: forExerciseSeries.exercises.length, exercise_index: exerciseIndex});
        let mergeToIndex = forExerciseSeries.index + 1;
        if (mergeToIndex >= exerciseSeries.length) { return; }

        var esTemp: ExerciseSeries_t[] = [];//exerciseSeries;

        for (var i = 0; i < exerciseSeries.length; i++) {
            let thisEs = exerciseSeries[i];

            if (thisEs.index === mergeToIndex) {
                thisEs.exercises.push(forExercise);
            }

            if (thisEs.index === forExerciseSeries.index) {
                var exercisesTemp: Exercise_t[] = [];
                if (thisEs.exercises.length > 0) {
                    for (var j = 0; j < thisEs.exercises.length; j++) {
                        if (j !== exerciseIndex) {
                            exercisesTemp.push(thisEs.exercises[j]);
                        }
                    }
                }
                thisEs.exercises = exercisesTemp;
            }

            if (thisEs.exercises.length > 0) {
                thisEs.title = getSetLetter(esTemp.length);
                thisEs.index = esTemp.length;
                esTemp.push(thisEs);
            }


        }
        setExerciseSeries(esTemp);










        /*
        // Remove exercise from it's current superset
        let esTemp: ExerciseSeries_t[] = exerciseSeries;

        let currentExercises = esTemp[forExerciseSeries.index].exercises;
        var exercisesTemp: Exercise_t[] = [];
        for (var i = 0; i < currentExercises.length; i++) {
            if (i !== exerciseIndex) {
                exercisesTemp.push(currentExercises[i]);
            }
        }

        esTemp[forExerciseSeries.index].exercises = exercisesTemp;

        // Add exercise to next set
        esTemp[mergeToIndex]
        */







    }

    // function moveUpExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
    //     setExerciseSeries(
    //         exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
    //             if (item.index === forExerciseSeries.index) {
    //                 var arr: Exercise_t[] = [];
    //                 let new_index = exerciseIndex - 1;
    //                 let old_index = exerciseIndex;
    //                 for (var i = 0; i < item.exercises.length; i++) {
    //                     let thisExercise = item.exercises[i];

    //                     for (var j = 0; j < exerciseSeries.length; j ++) {
    //                         arr.push(forExerciseSeries.exercises[j]);
    //                     }

    //                     if (new_index >= arr.length) {
    //                         var k = new_index - arr.length + 1;
    //                         while (k--) {
    //                             arr.push({title:'',id:'b',type: 0,muscles:[]});
    //                         }
    //                     }
    //                     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    //                 }
    //                 return {...item, exercises: arr};
    //             } else {
    //                 return item;
    //             }
    //         })
    //     );
    // }

    // function moveDownExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, exerciseIndex: number) {
    //     setExerciseSeries(
    //         exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
    //             if (item.index === forExerciseSeries.index) {
    //                 var arr: Exercise_t[] = [];
    //                 let new_index = exerciseIndex + 1;
    //                 let old_index = exerciseIndex;
    //                 for (var i = 0; i < item.exercises.length; i++) {
    //                     let thisExercise = item.exercises[i];

    //                     for (var j = 0; j < exerciseSeries.length; j ++) {
    //                         arr.push(forExerciseSeries.exercises[j]);
    //                     }

    //                     if (new_index >= arr.length) {
    //                         var k = new_index - arr.length + 1;
    //                         while (k--) {
    //                             arr.push({title:'',id:'',type: 0,muscles:[]});
    //                         }
    //                     }
    //                     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    //                 }
    //                 return {...item, exercises: arr};
    //             } else {
    //                 return item;
    //             }
    //         })
    //     );
    // }

    function editExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
        // console.log("EDITING (CreateWorkoutExerciseList): ", forExercise);
        Mixpanel.track("Workout builder | Edit exercise",{series_length: forExerciseSeries.exercises.length, exercise_title: forExercise.title});
        setEditingExercise(true);
        props.editExercisePressed(forExerciseSeries, forExercise);
    }

    function getTotalTime() {
        let defaultRestTime = 45;
        let defaultRepTime = 3;
        var totalSeconds = 0;

        for (var i = 0; i < exerciseSeries.length; i++) {
            let thisSeries = exerciseSeries[i];
            for (var j = 0; j < thisSeries.exercises.length; j++) {
                let thisExercise: Exercise_t = thisSeries.exercises[j];

                // Add rest times
                if (thisExercise.restTime !== undefined && thisExercise.restTime.length > 0) {
                    for (var k = 0; k < thisExercise.restTime.length; k++) {
                        let thisRestTime = thisExercise.restTime[k];
                        totalSeconds += thisRestTime;
                    }
                } else if (thisExercise.reps !== undefined && thisExercise.reps.length > 0) {
                    for (var k = 0; k < thisExercise.reps.length; k++) {
                        totalSeconds += defaultRestTime;
                    }
                }

                // Add tempos
                if (thisExercise.tempos !== undefined && thisExercise.tempos.length > 0) {
                    for (var k = 0; k < thisExercise.tempos.length; k++) {
                        var thisTempo: Tempo_t = thisExercise.tempos[k];
                        thisTempo = {
                            eccentric: thisTempo.eccentric < 0 ? 2 : thisTempo.eccentric,
                            first_pause: thisTempo.first_pause < 0 ? 0 : thisTempo.first_pause,
                            concentric: thisTempo.concentric < 0 ? 1 : thisTempo.concentric,
                            second_pause: thisTempo.second_pause < 0 ? 0 : thisTempo.second_pause
                        };
                        let thisTempoTime = thisTempo.concentric + thisTempo.eccentric + thisTempo.first_pause + thisTempo.second_pause;
                        let thisNumReps = thisExercise.reps !== undefined && thisExercise.reps[k] !== undefined ? thisExercise.reps[k] : 6;
                        totalSeconds += thisTempoTime * thisNumReps;
                    }
                } else if (thisExercise.reps !== undefined && thisExercise.reps.length > 0) {
                    for (var k = 0; k < thisExercise.reps.length; k++) {
                        let thisTempoTime = defaultRepTime;
                        let thisNumReps = thisExercise.reps[k];
                        totalSeconds += thisTempoTime * thisNumReps;
                        totalSeconds += defaultRepTime;
                    }
                }
            }
        }

        if (totalSeconds === 0) {
            return 'Define rest times and tempos for total time';
        }

        let mins = Math.abs(Math.floor(totalSeconds / 60));
        let secs = Math.abs(Math.floor(totalSeconds % 60));

        let finalString = `Total Time: ${mins < 10 ? '0' : ''}${mins}m ${secs < 10 ? '0' : ''}${secs}m`
        return finalString;
    }


    //***** DRAG RELATED FUNCTIONS *****
    function dragPosUpdated(x: number, y: number, item: ExerciseSeries_t, index: number) {

        if (document === null) { return; }
        let thisBlockDiv = document.getElementById(`block-${index}`);
        if (thisBlockDiv === null) { return; }

        let cellHeight_block = 57;
        let cellHeight_exercise = 57;

        var bounds = thisBlockDiv.getBoundingClientRect();
        let min_bound = bounds.top;
        let max_bound = bounds.bottom;
        let isInsideBlock = y >= min_bound && y <= max_bound;

        // if (isInsideBlock) {
        //     let yPosInBlock = y - min_bound;
            
        //     let yPosRemainder = yPosInBlock - cellHeight_block;
        //     var divisionIndex = Math.floor(yPosRemainder / cellHeight_exercise);
        //     divisionIndex = divisionIndex < 0 ? 0 : divisionIndex;
        //     divisionIndex = divisionIndex > item.exercises.length - 1 ? item.exercises.length - 1 : divisionIndex;
        //     // console.log("New purposed exercise index: ", divisionIndex);
        //     setPurposedIndex(index);
        //     setPurposedIndex_exercise(divisionIndex);
        // } else {

        // }

        let blockSpacing = 16;
        for (var i = 0; i < props.series.length; i++) {
            let thisSeries: ExerciseSeries_t = props.series[i];
            let thisBlockDiv_new = document.getElementById(`block-${i}`);
            if (thisBlockDiv_new !== null) { 
                let bounds_new = thisBlockDiv_new.getBoundingClientRect();
                let min_bound_new = bounds_new.top;
                let max_bound_new = bounds_new.bottom;
                let isInsideBlock_new = y >= min_bound_new && y <= max_bound_new;
                let isAboveBlock = y < min_bound_new && y > min_bound_new - blockSpacing;
                if (isInsideBlock_new) {
                    setPurposedMerge(true);
                    setPurposedIndex(i);
                    if (thisSeries.exercises !== undefined && thisSeries.exercises.length > 0) {
                        let yPosInBlock_new = y - min_bound_new;
    
                        let yPosRemainder_new = yPosInBlock_new - cellHeight_block;
                        var divisionIndex_new = Math.floor(yPosRemainder_new / cellHeight_exercise);
                        divisionIndex_new = divisionIndex_new < 0 ? 0 : divisionIndex_new;
                        divisionIndex_new = divisionIndex_new > thisSeries.exercises.length - 1 ? thisSeries.exercises.length - 1 : divisionIndex_new;
                        setPurposedIndex_exercise(divisionIndex_new);
                    } else {
                        setPurposedIndex_exercise(-1);
                    }

                    
                } else if (isAboveBlock) {
                    setPurposedMerge(false);
                    setPurposedIndex(i);
                    setPurposedIndex_exercise(-1);
                }
            }
        }

        /*
        let startingYPos = 260;
        let defaultCellHeight = 57;
        let defaultCellMargin = 12;

        let yPosRemainder = y - startingYPos;
        var divisionIndex = Math.floor(yPosRemainder / (defaultCellHeight + defaultCellMargin));
        divisionIndex = divisionIndex < 0 ? 0 : divisionIndex;
        divisionIndex = divisionIndex > props.series.length - 1 ? props.series.length - 1 : divisionIndex;
        // console.log("Moved to index: ", divisionIndex, index);

        setPurposedIndex(divisionIndex);

        if (divisionIndex !== index) {
            
            
            // var currentSeriesList: ExerciseSeries_t[] = props.series;
            

            // //if (divisionIndex > currentSeriesList.length - 1) { return;}

            // var newSeriesList: ExerciseSeries_t[] = [currentSeriesList[1], currentSeriesList[0]]; //array_move_open(currentSeriesList, index, divisionIndex);

            // props.exerciseSeriesListUpdated(newSeriesList);
            
        } 
        */
    }

    function cellPicked(item: ExerciseSeries_t, index: number) {
        setPurposedIndex(index);
        setPurposedIndex_exercise(-1);
    }

    function cellDropped(item: ExerciseSeries_t, index: number) {
        // console.log("CELL DROPPED", index);
        if (index < 0) { 
            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            setPurposedMerge(false);
            return; 
        }

        if (purposedIndex === index) {
            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            setPurposedMerge(false);
            return; 
        }

        if (purposedMerge) {
            var currentSeriesList: ExerciseSeries_t[] = JSON.parse(JSON.stringify(props.series));
            var newSeriesList_b: ExerciseSeries_t[] = JSON.parse(JSON.stringify(currentSeriesList));
            // 1. Add exercises to purposed block
            let currentExercises: Exercise_t[] = currentSeriesList[index].exercises;

            var series_temp: ExerciseSeries_t = currentSeriesList[purposedIndex];
            var exerciseList_temp: Exercise_t[] = series_temp.exercises;
            let numRepsOfExistingExercise = exerciseList_temp && exerciseList_temp[0] && exerciseList_temp[0].reps ? exerciseList_temp[0].reps.length : 0;

            for (var i = 0; i < currentExercises.length; i++) {
                let thisExerciseTemp = currentExercises[i];
                let newExerciseReps: number[] = thisExerciseTemp.reps ?? [];
                var thisExerciseTempReps: number[] = [];
                for (var j = 0; j < numRepsOfExistingExercise; j++) {
                    let thisNumReps = newExerciseReps[j] ?? ( newExerciseReps[newExerciseReps.length - 1] ?? 12 );
                    thisExerciseTempReps.push(thisNumReps)
                }
                thisExerciseTemp.reps = thisExerciseTempReps;
                exerciseList_temp.push(thisExerciseTemp);
            }

            // for (var i = 0; i < currentExercises.length; i++) {
            //     exerciseList_temp.push(currentExercises[i]);
            // }

            if (currentExercises.length === 1) {
                // Single exercise block -- add this exercise to the purposed location
                let purposedIndex_exercise_adj = purposedIndex_exercise < 0 ? 0 : purposedIndex_exercise;
                //exerciseList_temp.push(currentExercises[0]);
                exerciseList_temp = array_move_open(exerciseList_temp, exerciseList_temp.length - 1, purposedIndex_exercise_adj);
                
            } else {

            }
            series_temp.exercises = exerciseList_temp;
            newSeriesList_b[purposedIndex] = series_temp;
            
            // Remove original series
            var newSeriesList: ExerciseSeries_t[] = [];
            for (var i = 0; i < newSeriesList_b.length; i++) {
                if (i !== index) {
                    newSeriesList.push(newSeriesList_b[i]);
                }
            }
            //newSeriesList_b.filter((item: ExerciseSeries_t, atIndex: number) => { return atIndex !== index; })

            props.exerciseSeriesListUpdated(newSeriesList);

            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            setPurposedMerge(false);
            return;
        }

        
        var currentSeriesList: ExerciseSeries_t[] = props.series;
        var newSeriesList: ExerciseSeries_t[] = array_move_open(currentSeriesList, index, purposedIndex);
        props.exerciseSeriesListUpdated(newSeriesList);
                
        setPurposedIndex(-1);
        setPurposedIndex_exercise(-1);
    }

    function cellPicked_exercise(item: ExerciseSeries_t, index: number, exercise_index: number) {
        setPurposedIndex(-1);
        setPurposedIndex_exercise(exercise_index);
    }

    function cellDropped_exercise(item: ExerciseSeries_t, index: number, exercise_index: number) {

        if (purposedMerge === true) {
            // Will merge into existing block
            if (purposedIndex_exercise === -1) {
                // no bueno for now
                setPurposedMerge(false);
                setPurposedIndex(-1);
                setPurposedIndex_exercise(-1);
            } else {
                // console.log("HERE!!!!!!", purposedIndex, purposedIndex_exercise, index, exercise_index);
                var currentSeriesList: ExerciseSeries_t[] = JSON.parse(JSON.stringify(props.series));
                var newSeriesList_b = JSON.parse(JSON.stringify(currentSeriesList));

                // 1. Add this exercise to purposed location
                // 1.a) Get exercise
                let movedExercise: Exercise_t = currentSeriesList[index].exercises[exercise_index];


                // 1.b) Push exercise to purposed location list
                var newSeries_temp: ExerciseSeries_t = currentSeriesList[purposedIndex];

                // 1.b.2) Ensure the new exercise conforms to the existing number of sets
                let numRepsOfExistingExercise = newSeries_temp.exercises && newSeries_temp.exercises[0] && newSeries_temp.exercises[0].reps ? newSeries_temp.exercises[0].reps.length : 0;
                var newExerciseRepList: number[] = [];
                let newExerciseReps: number[] = movedExercise.reps ?? [];
                for (var j = 0; j < numRepsOfExistingExercise; j++) {
                    let thisNumReps = newExerciseReps[j] ?? ( newExerciseReps[newExerciseReps.length - 1] ?? 12 );
                    newExerciseRepList.push(thisNumReps)
                }
                movedExercise.reps = newExerciseRepList;
                newSeries_temp.exercises.push(movedExercise);


                // 1.c) Move exercise just pushed to end of list into purposedIndex_exercise
                newSeries_temp.exercises = array_move_open(newSeries_temp.exercises, newSeries_temp.exercises.length - 1, purposedIndex_exercise)
                // 1.d) Update list
                newSeriesList_b[purposedIndex] = newSeries_temp;//.exercises = newExerciseList;

                // 2. Remove exercise from original block
                var ogSeries: ExerciseSeries_t = currentSeriesList[index];
                var ogSeriesExerciseList: Exercise_t[] = ogSeries.exercises;
                var ogSeriesExerciseList_temp: Exercise_t[] = [];
                for (var i = 0; i < ogSeriesExerciseList.length; i++) {
                    if (i !== exercise_index) {
                        ogSeriesExerciseList_temp.push(ogSeriesExerciseList[i]);
                    }
                }
                ogSeries.exercises = ogSeriesExerciseList_temp;

                newSeriesList_b[index] = ogSeries;

                // console.log("newSeriesList_b:", newSeriesList_b);
                props.exerciseSeriesListUpdated(newSeriesList_b);

                setPurposedMerge(false);
                setPurposedIndex(-1);
                setPurposedIndex_exercise(-1);
            }

            return;
        }
        // console.log("NO MERGE")
        if (purposedIndex_exercise === -1) {
            // Move to create new block
            let newSeries: ExerciseSeries_t = {
                title: getSetLetter(purposedIndex),
                index: purposedIndex,
                exercises: [item.exercises[exercise_index]],
                type: 0, // 0 = single exercise, 1 = superset
                isShowing: false
            }

            let oldSeries: ExerciseSeries_t = item;
            oldSeries.exercises.splice(exercise_index, 1);

            var currentSeriesList: ExerciseSeries_t[] = props.series;
            currentSeriesList[index] = oldSeries;
            currentSeriesList.push(newSeries);
            var newSeriesList_a: ExerciseSeries_t[] = array_move_open(currentSeriesList, currentSeriesList.length - 1, purposedIndex);
            
            for (var i = 0; i < newSeriesList_a.length; i++) {
                newSeriesList_a[i].title = getSetLetter(i);
                newSeriesList_a[index].index = i;
            }
            props.exerciseSeriesListUpdated(newSeriesList_a);

            setPurposedMerge(false);
            setPurposedIndex(-1);
            setPurposedIndex_exercise(-1);
            return;       
        }

        if (index === purposedIndex) {
            // Moved exercise within block
            var currentSeriesList: ExerciseSeries_t[] = props.series;
            let edittedSeries = currentSeriesList[index];
            let exercisesTemp = array_move_open(edittedSeries.exercises, exercise_index, purposedIndex_exercise);
            //edittedSeries.exercises = exercisesTemp;
            var newSeriesList = JSON.parse(JSON.stringify(currentSeriesList));
            newSeriesList[index].exercises = exercisesTemp
            //var newSeriesList: ExerciseSeries_t[] = array_move_open(currentSeriesList, index, purposedIndex);
            props.exerciseSeriesListUpdated(newSeriesList);
        } else {

        }

        setPurposedMerge(false);
        setPurposedIndex(-1);
        setPurposedIndex_exercise(-1);
    }

    function array_move_open(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    function dragPosUpdated_exercise(x: number, y: number, item: ExerciseSeries_t, index: number, exercise_index: number) {
        if (document === null) { return; }
        let thisBlockDiv = document.getElementById(`block-${index}`);
        if (thisBlockDiv === null) { return; }

        let cellHeight_block = 57;
        let cellHeight_exercise = 57;

        var bounds = thisBlockDiv.getBoundingClientRect();
        let min_bound = bounds.top;
        let max_bound = bounds.bottom;
        let isInsideBlock = y >= min_bound && y <= max_bound;

        if (isInsideBlock) {
            let yPosInBlock = y - min_bound;
            
            let yPosRemainder = yPosInBlock - cellHeight_block;
            var divisionIndex = Math.floor(yPosRemainder / cellHeight_exercise);
            divisionIndex = divisionIndex < 0 ? 0 : divisionIndex;
            divisionIndex = divisionIndex > item.exercises.length - 1 ? item.exercises.length - 1 : divisionIndex;
            // console.log("New purposed exercise index: ", divisionIndex);
            setPurposedIndex(index);
            setPurposedIndex_exercise(divisionIndex);
            setPurposedMerge(false);
        } else {
            //// console.log("OUTSIDE");
            if (props.series.length === 1) {
                setPurposedIndex_exercise(-1);
                let isBelowBlock = y > max_bound;
                if (isBelowBlock) {
                    setPurposedIndex(1);
                } else {
                    setPurposedIndex(0);
                }
                setPurposedMerge(false);
            } else {
                let blockSpacing = 16;
                for (var i = 0; i < props.series.length; i++) {
                    let thisSeries: ExerciseSeries_t = props.series[i];
                    let thisBlockDiv_new = document.getElementById(`block-${i}`);
                    if (thisBlockDiv_new !== null) { 
                        let bounds_new = thisBlockDiv_new.getBoundingClientRect();
                        let min_bound_new = bounds_new.top;
                        let max_bound_new = bounds_new.bottom;
                        let isInsideBlock_new = y >= min_bound_new && y <= max_bound_new;
                        let isAboveBlock = y < min_bound_new && y > min_bound_new - blockSpacing;
                        if (isInsideBlock_new) {
                            setPurposedMerge(true);
                            setPurposedIndex(i);
                            if (thisSeries.exercises !== undefined && thisSeries.exercises.length > 0) {
                                let yPosInBlock_new = y - min_bound_new;
            
                                let yPosRemainder_new = yPosInBlock_new - cellHeight_block;
                                var divisionIndex_new = Math.floor(yPosRemainder_new / cellHeight_exercise);
                                divisionIndex_new = divisionIndex_new < 0 ? 0 : divisionIndex_new;
                                divisionIndex_new = divisionIndex_new > thisSeries.exercises.length - 1 ? thisSeries.exercises.length - 1 : divisionIndex_new;
                                setPurposedIndex_exercise(divisionIndex_new);
                            } else {
                                setPurposedIndex_exercise(-1);
                            }

                            
                        } else if (isAboveBlock) {
                            setPurposedMerge(false);
                            setPurposedIndex(i);
                            setPurposedIndex_exercise(-1);
                        }
                    }
                }
            }

        }
        //// console.log(isInsideBlock ? "Inside" : "Outside");

    }

	return (
		<div className="new-workout-modal-page-full-height">
            <div hidden={props.series.length === 0} className="new-workout-modal-page-header-text-container">
                <p>Exercise list for workout</p>
                <p className="new-workout-modal-page-header-text-right">{getTotalTime()}</p>
            </div>
            <div hidden={props.series.length === 0} className="create-workout-exercise-list-container">
    			{props.series.map((item: ExerciseSeries_t, index: number) => (
                    <div className="create-workout-exercise-list-cell-outter-container">
                        <div hidden={purposedIndex !== index || purposedMerge === true} className="create-workout-exercise-list-cell-purposed-container"/>
                        <CreateWorkoutExerciseListCell
                            key={`${item.title}-${index}`}
                            exerciseSeries={item}
                            exerciseSeriesIndex={index}
                            purposedIndex={purposedIndex}
                            purposedIndex_exercise={purposedIndex_exercise}
                            purposedMerge={purposedMerge}
                            dragPosUpdated={(x: number, y: number) => dragPosUpdated(x,y,item,index)}
                            dragPosUpdated_exercise={(x: number, y: number, ex_i: number) => dragPosUpdated_exercise(x,y,item,index,ex_i)}
                            cellPicked={() => cellPicked(item, index)}
                            cellDropped={() => cellDropped(item, index)}
                            cellPicked_exercise={(ex_i: number) => cellPicked_exercise(item, index, ex_i)}
                            cellDropped_exercise={(ex_i: number) => cellDropped_exercise(item, index, ex_i)}
                            maxExerciseSeriesIndex={props.series.length - 1}
                            moveExerciseSeriesUp={(e: number) => moveExerciseUp(e)}
                            moveExerciseSeriesDown={(e: number) => moveExerciseDown(e)}
                            moveUpExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => (moveUpExercisePressed(eS,e,i))}
                            moveDownExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => (moveDownExercisePressed(eS,e,i))}
                            removeFromSupersetPressed={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => (removeFromSupersetPressed(eS,e,i))}
                            mergeDownToSuperset={(eS: ExerciseSeries_t, e: Exercise_t, i: number) => (mergeDownToSuperset(eS,e,i))}
                            editExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t) => editExercisePressed(eS, e)}
                            removeExerciseSeries={(eS: ExerciseSeries_t, i: number) => removeExerciseSeries(eS, i)}
                            removeEntireExerciseSeries={(i: number) => removeEntireExerciseSeries(i)}
                            addToSupersetPressed={() => addToSupersetPressed(index)}
                            switchIsShowing={(e: Exercise_t) => switchIsShowing(item, e)}
                        />
                    </div>
                ))}
                <div hidden={purposedIndex !== props.series.length} className="create-workout-exercise-list-cell-purposed-container"/>
                <div onClick={() => addAnotherExercise()} className="create-workout-exercise-list-new-exercise-container">
                    <p>+ Add Block</p>
                    <div className="create-workout-exercise-list-empty-button-hotkey-container">
                        <p>ctrl</p>
                    </div>
                    <div className="create-workout-exercise-list-empty-button-hotkey-container">
                        <p>b</p>
                    </div>
                </div>
            </div>
            <div hidden={props.series.length > 0} className="create-workout-exercise-list-empty-container">
                <div className="create-workout-exercise-list-empty-image-container">
                    <img className="create-workout-exercise-list-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/workout-block-empty.png`}/>
                </div>
                <div className="create-workout-exercise-list-empty-content-container">
                    <h4>Exercise Blocks</h4>
                    <p>Exercise blocks can be composed of single exercises, or you can create supersets by adding multiple exercises to the block.<br/><br/>Tap 'Create a Block' below to select your first exercise and get started a building your new workout. You'll be able to edit, re-order, remove, and merge blocks as you go.</p>
                    <div onClick={() => addAnotherExercise()} className="create-workout-exercise-list-empty-button-container">
                        <p>Create a Block</p>
                        <div className="create-workout-exercise-list-empty-button-hotkey-container">
                            <p>ctrl</p>
                        </div>
                        <div className="create-workout-exercise-list-empty-button-hotkey-container">
                            <p>b</p>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

/*
                <p className="new-workout-modal-page-header-text-right">{props.series.length === 0 ? "Press \"Add First Series\" to begin" : "To add another, press \"Add Series\""}</p>

    {exercises.map((item, index) => (
                    <CreateWorkoutExerciseListCell
                        key={`${item.title}-${index}`}
                        exercise={item}
                        exerciseIndex={index}
                        maxExerciseIndex={exercises.length - 1}
                        moveExerciseUp={(e: number) => moveExerciseUp(e)}
                        moveExerciseDown={(e: number) => moveExerciseDown(e)}
                        removeExercise={() => removeExercise(index)}
                        addToSupersetPressed={() => addToSupersetPressed(index)}
                    />
                ))}
*/

export default CreateWorkoutExerciseList;