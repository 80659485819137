import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../../../Firebase';
import './WorkoutPlanListPlanOverview.css';
import './WorkoutPlanListCycleOverview.css';

import Workout_t from '../../../../Interfaces/Workout_t'
import Folder_t from '../../../../Interfaces/Folder_t';
import Plan_t from '../../../../Interfaces/Plan_t';

import WorkoutPlanListPlanOverviewCardPlan from './WorkoutPlanListPlanOverviewCardPlan';
import WorkoutPlanListCycleOverviewCardPlan from './WorkoutPlanListCycleOverviewCardPlan';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Add from '@material-ui/icons/Add';
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ArrowForward from '@material-ui/icons/ArrowForward';

function WorkoutPlanListPlanOverview(props: any) {

    let initFolder = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
            isOpen: false
        };

    const [initSet, setInitSet] = useState(false);
    const [thisCycle, setThisCycle] = useState<Folder_t>(initFolder);
    const [plans, setPlans] = useState<Folder_t[]>([]);
    const [showMenu, setShowMenu] = useState(false);

    const [cycleIndex_purposed, setCycleIndex_purposed] = useState(-1);
    const [phaseIndex_purposed, setPhaseIndex_purposed] = useState(-1);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.cycle !== undefined) {
            setThisCycle(props.cycle);
            configurePlans();
        }
    }, [props.cycle])

    function initialize() {
        if (props.cycle !== undefined) {
            setThisCycle(props.cycle);
            configurePlans();
        }
    }

    function configurePlans() {
        var cycleListTemp: Folder_t[] = [];
        if (props.cycle.children !== undefined && props.cycle.children.length > 0) {
            for (var i = 0; i < props.cycle.children.length; i++) {
                let thisChild = props.cycle.children[i];
                if (thisChild.type === "plan") {
                    cycleListTemp.push(thisChild);
                }
            }
        }

        setPlans(cycleListTemp);
    }

    function planPressed(p: Folder_t) {
        if (p.plan_id === undefined || p.plan_id === "") {
            props.addPlan(p);
        } else {
            props.planSelected(p);
        }
    }
    function toggleMenuShow() {
        setShowMenu(!showMenu);
    }

    function addPhasePressed() {
        setShowMenu(false);
    }

    function deleteCyclePressed() {
        setShowMenu(false);
        props.deleteCyclePressed();
    }

    window.addEventListener('click', function(e){   
        if (document === null || document.getElementById('plan-overview-menu') === null || document.getElementById('plan-overview-menu-button') === null || e.target === null) { return; }
        
        if (document.getElementById('plan-overview-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('plan-overview-menu-button')!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    });

    //**************** DRAGGING FUNCTIONS ******************//

    function cellDropped(phase: Folder_t, phase_index: number, cycle: Folder_t, cycle_index: number) {

        let cycles: Folder_t[] = [thisCycle];

        // 1. Compose single list of all phases (before shuffling), irrespective of cycle
        var phaseList: Folder_t[] = [];
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            let thisCycleFolder: Folder_t = cycles[c_i];
            for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                let thisPhaseFolder: Folder_t = thisCycleFolder.children[p_i];
                phaseList.push(thisPhaseFolder);
            }
        }       

        // 2. Get index of phaseList for the phase being moved
        var currentPhaseListIndex = -1;
        var currentPhaseListIndex_set = false;
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            let thisCycleFolder: Folder_t = cycles[c_i];
            for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                if (!currentPhaseListIndex_set) {
                    currentPhaseListIndex += 1;
                }
                if (c_i === cycle_index && p_i === phase_index) {
                    currentPhaseListIndex_set = true;
                }
            }
        }

        // 3. Get index of phaseList for the new location of the phase
        var newPhaseListIndex = -1;
        var newPhaseListIndex_set = false;
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            let thisCycleFolder: Folder_t = cycles[c_i];
            for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                if (!newPhaseListIndex_set) {
                    newPhaseListIndex += 1;
                }

                // TODO: take into account indecies greater than the length of the cycle
                if (c_i === cycleIndex_purposed && p_i === phaseIndex_purposed) {
                    newPhaseListIndex_set = true;
                }
            }
        }    

        // 4. Configure new phase list array
        // console.log("Adjusting array with indecies: (old, new)", currentPhaseListIndex, newPhaseListIndex);
        let phaseList_old: Folder_t[] = JSON.parse(JSON.stringify(phaseList));
        let phaseList_new: Folder_t[] = array_move_open(phaseList, currentPhaseListIndex, newPhaseListIndex);
        // console.log(phaseList_old, phaseList_new);


        // 5. re-compose cycle list
        var cycles_temp: Folder_t[] = [];
        var phaseList_new_index = 0;
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            var currentCycleFolder: Folder_t = JSON.parse(JSON.stringify(cycles[c_i]));
            var currentCycleFolder_phaseList_temp: Folder_t[] = [];
            for (var p_i = 0; p_i < currentCycleFolder.children.length; p_i++) {
                var oldPhaseObj: Folder_t = JSON.parse(JSON.stringify(phaseList_old[phaseList_new_index]));
                var currentPhaseObj: Folder_t = JSON.parse(JSON.stringify(phaseList_new[phaseList_new_index]));
                if (currentPhaseObj.phaseName !== undefined) {
                    currentPhaseObj.phaseName = `Phase ${phaseList_new_index + 1}`
                } else {
                    currentPhaseObj.title = `Phase ${phaseList_new_index + 1}`
                }
                // Preserve the ID, path, creation string, and timestamp of the original object
                // currentPhaseObj.phaseName = oldPhaseObj.phaseName;
                currentPhaseObj.id = oldPhaseObj.id;
                // currentPhaseObj.plan_id = oldPhaseObj.plan_id;
                currentPhaseObj.path = oldPhaseObj.path;
                currentPhaseObj.createdBy_ID = oldPhaseObj.createdBy_ID;
                currentPhaseObj.timestamp = oldPhaseObj.timestamp;

                currentCycleFolder_phaseList_temp.push(currentPhaseObj);
                phaseList_new_index += 1;
            }
            currentCycleFolder.children = currentCycleFolder_phaseList_temp;
            cycles_temp.push(currentCycleFolder);
        }   

        setThisCycle(cycles_temp[0]);
        setPlans(cycles_temp[0].children);

        // 6. Update database to save changes
        
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let rootRef = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root`);

        for (var p_i = 0; p_i < phaseList.length; p_i++) {
            let originalPhaseObj: Folder_t = phaseList_old[p_i];
            let newPhaseObj: Folder_t = phaseList_new[p_i];

            let path_ids = originalPhaseObj.path;
            let splitPath_ids = path_ids.split('/');
            let depth = splitPath_ids.length;

            // console.log(`Creating folder at ${path_ids}`);

            var composedPath_ids = "";
            for (var i = 1; i < depth; i++) {
                composedPath_ids += "/";
                composedPath_ids += splitPath_ids[i];
                composedPath_ids += i === depth - 1 ? "" : "/children";
            }
            // console.log(`Final FB location at ${composedPath_ids}`, newPhaseObj.plan_id, originalPhaseObj, newPhaseObj);
            let phaseRef = rootRef.child(composedPath_ids);

            // Update the name of the phase
            if (newPhaseObj.phaseName === undefined || newPhaseObj.phaseName === "") {
                // The new phase is not build yet -- it does not have a phase name. It's name should therefore be "Phase x"
                // where 'x' is the phase number of the original phase.
                if (originalPhaseObj.phaseName === undefined || originalPhaseObj.phaseName === "") {
                    // Original phase also was not built -- use the original phase's .title as the new phase's "name"
                    phaseRef.child("name").set(originalPhaseObj.title);
                } else {
                    // Orinal phase was built already -- use the original phase's .phaseName as the new phase's "name"
                    phaseRef.child("name").set(originalPhaseObj.phaseName);
                }
            } else {
                phaseRef.child("name").set(newPhaseObj.title);
            }
            
            // Update the phase name
            if (newPhaseObj.phaseName === undefined || newPhaseObj.phaseName === "") {
                phaseRef.child("phase_name").set(null);
            } else {
                if (originalPhaseObj.phaseName === undefined || originalPhaseObj.phaseName === "") {
                    // Original phase also was not built -- use the original phase's .title as the new phase's "name"
                    phaseRef.child("phase_name").set(originalPhaseObj.title);
                } else {
                    // Orinal phase was built already -- use the original phase's .phaseName as the new phase's "name"
                    phaseRef.child("phase_name").set(originalPhaseObj.phaseName);
                }
            }
            //phaseRef.child("phase_name").set(newPhaseObj.phaseName === undefined || newPhaseObj.phaseName === "" ? null : newPhaseObj.phaseName);
            phaseRef.child("plan_id").set(newPhaseObj.plan_id === undefined || newPhaseObj.plan_id === "" ? null : newPhaseObj.plan_id);

        }


        /*
        for (var c_i = 0; c_i < cycles_temp.length; c_i++) {
            var currentCycleFolder: Folder_t = JSON.parse(JSON.stringify(cycles_temp[c_i]));
            for (var p_i = 0; p_i < currentCycleFolder.children.length; p_i++) {
                let thisPhaseObj: Folder_t = currentCycleFolder.children[p_i];
                let path_ids = thisPhaseObj.path;
                let splitPath_ids = path_ids.split('/');
                let depth = splitPath_ids.length;

                // console.log(`Creating folder at ${path_ids}`);

                var composedPath_ids = "";
                for (var i = 1; i < depth; i++) {
                    composedPath_ids += "/";
                    composedPath_ids += splitPath_ids[i];
                    composedPath_ids += i === depth - 1 ? "" : "/children";
                }
                // console.log(`Final FB location at ${composedPath_ids}`);
                let phaseRef = rootRef.child(composedPath_ids);
                //phaseRef.child("title").set(thisPhaseObj.title);
                
                //phaseRef.child("phase_name").set(thisPhaseObj.phaseName === undefined || thisPhaseObj.phaseName === "" ? null : thisPhaseObj.phaseName);
            }
        }   

        */
        /*
        // Only need to shuffle around phases after the moved phase
        var cycleComposition: Folder_t[] = [];
        for (var c_i = 0; c_i < cycles.length; c_i++) {
            if (c_i < cycle_index) {
                // Entire cycle is before moving cycle - go ahead and add to cycleComposition without adjustment
                cycleComposition.push(cycles[c_i]);
            } else { // c_i >= cycle_index
                let thisCycleFolder: Folder_t = cycles[c_i];
                var phaseComposition_temp: Folder_t[] = [];
                for (var p_i = 0; p_i < thisCycleFolder.children.length; p_i++) {
                    if (c_i === cycle_index && p_i === phase_index) {
                        // This is the cycle that was picked up -- ignore!
                    } else if (c_i === cycleIndex_purposed && p_i === phaseIndex_purposed) {
                        // This is where the phase was dropped
                        phaseComposition_temp.push(phase);
                    } else {

                    }
                    if (cycle_index === c_i) {
                        // Adjusting cycle
                    }
                }

            }
        }*/


        setCycleIndex_purposed(-1);
        setPhaseIndex_purposed(-1);
    }

    function array_move_open(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    function cellPicked(phase: Folder_t, phase_index: number, cycle: Folder_t, cycle_index: number) {
        
    }

    function updateDraggingPosition_phase(phase: Folder_t, phase_index: number, cycle: Folder_t, cycle_index: number, x: number, y: number) {
       
        let cycles: Folder_t[] = [thisCycle];
        let phasesContainer = document.getElementById('workout-plan-content-add-container-cycles')
        if (phasesContainer !== null && phasesContainer !== undefined) {

            let cycleHeight = 222;
            let upperMargin = 82;

            let phasesContainer_bounds = phasesContainer.getBoundingClientRect();
            let phasesWidth = (phasesContainer_bounds.width - 36) / 3;
            let x_adj = x - phasesContainer_bounds.left + (phasesWidth / 2);
            let y_adj = y - phasesContainer_bounds.top - upperMargin;

            
            var thisColIndex = Math.floor(x_adj / phasesWidth);
            thisColIndex = thisColIndex < 0 ? 0 : (thisColIndex > cycle.children.length - 1 ? cycle.children.length - 1 : thisColIndex);
            
            var thisCycleIndex = 0; //Math.floor(y_adj / cycleHeight);

            setCycleIndex_purposed(thisCycleIndex);
            setPhaseIndex_purposed(thisColIndex);

            // Get purposed phase info
            let purposedCycle_temp: Folder_t = cycles[thisCycleIndex];
            if (purposedCycle_temp === undefined || purposedCycle_temp.children === undefined) { return; }

            let purposedPhase_temp: Folder_t = purposedCycle_temp.children[thisColIndex];
            if (purposedPhase_temp === undefined) { return; }

            
            //setPurposedPhase(purposedPhase_temp);
            // console.log(thisColIndex, thisCycleIndex, purposedPhase_temp.title, purposedPhase_temp.id);
        }
    }


	return (
		<div id="workout-plan-content-add-container-cycles" className="workout-plan-list-plan-overview">
			<div className="workout-plan-list-cycle-overview-header-card">
                <div className="workout-plan-list-cycle-overview-header-card-icon-container workout-plan-list-plan-overview-header-card-icon-container-cycle">
                    <List className="workout-plan-list-cycle-overview-header-card-icon workout-plan-list-plan-overview-header-card-icon-cycle"/>
                </div>    
                <div className="workout-plan-list-cycle-overview-header-card-text-container">
                    <h1>{thisCycle.title} Plan Overview</h1>
                </div> 
                <div id="plan-overview-menu-button" onClick={() => toggleMenuShow()} className="workout-plan-list-cycle-overview-header-card-menu-button-container">
                    <p>Options</p>
                    <MoreHoriz className="workout-plan-list-cycle-overview-header-card-menu-button-icons"/>
                </div> 
                <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                    <div id="plan-overview-menu" className="workout-plan-list-cycle-overview-header-card-menu-container">
                        <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                            <p>Cycle Options</p>
                        </div>
                        <div className={ "workout-plan-list-cycle-overview-header-card-menu-header-row-divider" }></div>
                        <div hidden={true} onClick={() => addPhasePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <Add className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Add a Phase</p>
                        </div>
                        <div onClick={() => deleteCyclePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <DeleteOutline className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Delete Cycle</p>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <div className="workout-plan-list-cycle-overview-card-header">
                <div className="workout-plan-list-cycle-overview-card-header-icon-container">
                    <List className="workout-plan-list-cycle-overview-card-header-icon"/>
                </div>
                <div className="workout-plan-list-cycle-overview-card-header-text-container">
                    <h4>Current Cycle</h4>
                    <p>({plans.length})</p>
                </div>
                <div hidden={true} className="workout-plan-list-cycle-overview-card-header-arrow-icon-container">
                    <ArrowForward className="workout-plan-list-cycle-overview-card-header-arrow-icon"/>
                </div>
            </div>
            <div className="workout-plan-list-cycle-overview-card-content-container">
                { plans.map((item: Folder_t, index: number) => (
                    <div className="workout-plan-list-cycle-overview-card-content-container-inner">
                        <div className="workout-plan-list-cycle-overview-card-content-phase-container">
                            <WorkoutPlanListCycleOverviewCardPlan
                                phase={item}
                                index={index}
                                maxIndex={plans.length - 1}
                                planPressed={() => planPressed(item)}
                                editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                                cellDropped={() => cellDropped(item,index,thisCycle,0)}
                                cellPicked={() => cellPicked(item,index,thisCycle,0)}
                                dragPosUpdated={(x: number, y: number) => updateDraggingPosition_phase(item,index,thisCycle,0,x,y)}
                                isPurposed={phaseIndex_purposed === index}/>
                        </div>
                    </div>
                ))}
            </div>
		</div>
	)
}

/*
<WorkoutPlanListPlanOverviewCardPlan
                        phase={item}
                        index={index}
                        maxIndex={plans.length - 1}
                        planPressed={() => planPressed(item)}
                        editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                        deletePlanPressed={() => props.deletePlanPressed()}/>
*/

export default WorkoutPlanListPlanOverview;