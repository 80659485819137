import React, { useState } from 'react';
import './ExerciseCell.css';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';

import Muscles_t from '../../Interfaces/Muscles_t';

import '@material-ui/core';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';

function ExerciseCell(props: any) {

    let muscles: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]
        ]

    let equipment = {
        machines: [
          "None",
          "Single Cables",
          "Double Cables",
          "Weighted Ab Crunch",
          "Weighted Trunk Roatator",
          "Parallel Bars",
          "Incline Chest Press",
          "Chest Press",
          "Decline Chest Press",
          "Pec Fly",
          "Shoulder Press",
          "Lateral Shoulder Raise",
          "Rear Delt Extensor",
          "Shrug Machine",
          "Deadlift Machine",
          "Spinal Errector",
          "Cable Pulldown",
          "Pulldown",
          "Low Pull Back",
          "High Pull Back",
          "Straight Pull Cable",
          "Leg Press",
          "Quad Extensor",
          "Lying Hamstring Curls",
          "Seated Hamstring Curls",
          "Standing Calves Raises",
          "Seated Calves Raises",
          "Hip Abductor",
          "Hip Adductor",
          "Squat Rack",
          "Bench Rack",
          "Shoudler Rack",
          "Double Arm Curl",
          "Single Arm Curl",
          "Tricep Extensor",
          "Overhead Tricep Extensor",
          "Machine Dips"
        ],
        benches: [
          "None",
          "Flat Bench",
          "Large Incline Bench",
          "Slight Incline Bench",
          "Large Decline Bench",
          "Slight Decline Bench",
          "L-seat Bench",
          "Bench Press",
          "Shoulder Press"
        ],
        bars: [
          "None",
          "Full Bar",
          "Short Bar",
          "Curl Bar",
          "Swiss Bar",
          "Tricep Bar",
          "Fixed Weight Barbell"
        ],
        cable_attachments: [
          "None",
          "Double Ropes",
          "Single Rope",
          "Flat Bar",
          "Curl Bar",
          "Double-D Handles",
          "Signle-D Handle",
          "Short Parallel Bar",
          "Pulldown Bar",
          "Side Grip Lat Bar",
          "Tricep V-Bar",
          "Head Harness",
          "Foot Harness",
        ]
      }

	const [imgUrl, setImgUrl] = useState('');
	const [musclesString, setMusclesString] = useState('');
	const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function getMusclesString() {
    	// console.log(props.exercise.muscles);

    	if (props.exercise !== undefined && props.exercise.muscles !== undefined && props.exercise.muscles.length > 0) {
    		var muscleString = "";
    		for (var i = 0; i < props.exercise.muscles.length; i++) {
    			let thisMuscle: Muscles_t = props.exercise.muscles[i];
    			let thisMuscleString = muscles[thisMuscle.group][thisMuscle.muscle];

    			muscleString += thisMuscleString;
    			muscleString += i === props.exercise.muscles.length - 1 ? '' : ', ';
    		}

    		return muscleString;
    	} else {
    		return 'No muscles defined'
    	}
    	return '';
    }
	
	// function getMusclesString() {
	// 	let database = firebase.database();
	// 	let ref = database.ref(`workouts/${props.workout.id}`);
	// 	ref.on('value', function(snapshot) {
	// 		if (snapshot.exists() === true) {
	// 			let workoutObj = snapshot.val();

	// 			// Determine previous workout muscle groups
	// 			let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
	// 			var muscleGroupTags_temp: string[] = [];
	// 			let exercises = workoutObj.exercises;
	// 			let exerciseKeys = Object.keys(exercises);
	// 			for (var i = 0; i < exerciseKeys.length; i++) {
	// 				let thisKey = exerciseKeys[i];
	// 				let thisExercise = exercises[thisKey];

	// 				let thisExerciseMuscles = thisExercise.muscles;
	// 				let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

	// 				for (var j = 0; j < thisExerciseMusclesKeys.length; j++) {
	// 					let thisMuscleKey = thisExerciseMusclesKeys[j];
	// 					let thisMuscle = thisExerciseMuscles[thisMuscleKey];
	// 					let thisMuscleGroup = Number(thisMuscle.group);

	// 					let thisMuscleString = muscle_groups[thisMuscleGroup];
	// 					if (!muscleGroupTags_temp.includes(thisMuscleString)) {
	// 						muscleGroupTags_temp.push(thisMuscleString);
	// 					}
	// 				}
	// 			}
	// 			var string_temp = "";
	// 			for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
	// 				string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
	// 			}
	// 			setMusclesString(string_temp);
	// 		}
	// 	});
	// }


	function cellSelected() {
		//props.cellSelected(props.workout);
	}

	function moreInfoSelected() {
		//props.moreInfoSelected(props.workout);
	}

	function getEquipmentRequired() {
		if (props.exercise !== undefined && props.exercise.equipment !== undefined) {
			return props.exercise.equipment.machine !== -1 || props.exercise.equipment.cable_attachment !== -1 || props.exercise.equipment.bar !== -1 || props.exercise.equipment.bench !== -1;
		} else {
			return false;
		}
	}

	function getEquipmentString() {
		if (!getEquipmentRequired()) {
			return 'No equipment specified for this exercise';
		}

		if (props.exercise !== undefined && props.exercise.equipment !== undefined) {
			var equipmentString = "";
			var numAdded = 0;

			if (props.exercise.equipment.machine >= 0) {
				equipmentString += props.exercise.equipment.machine + 1 < equipment.machines.length ? equipment.machines[props.exercise.equipment.machine + 1] : "Custom machine";
				numAdded += 1;
			} else if (props.exercise.equipment.machine === -2) {
                equipmentString += props.exercise.equipment.custom_machine_name !== undefined ? props.exercise.equipment.custom_machine_name : "Custom machine";
                numAdded += 1;
            }

			if (props.exercise.equipment.bench >= 0) {
				equipmentString += numAdded > 0 ? ", " : "";
				equipmentString += props.exercise.equipment.bench + 1 < equipment.benches.length ? equipment.benches[props.exercise.equipment.bench + 1] : "Custom bench";
				numAdded += 1;
			} else if (props.exercise.equipment.bench === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += props.exercise.equipment.custom_bench_name !== undefined ? props.exercise.equipment.custom_bench_name : "Custom bench";
                numAdded += 1;
            }

			if (props.exercise.equipment.cable_attachment >= 0) {
				equipmentString += numAdded > 0 ? ", " : "";
				equipmentString += props.exercise.equipment.cable_attachment + 1 < equipment.cable_attachments.length ? equipment.cable_attachments[props.exercise.equipment.cable_attachment + 1] : "Custom attachment";
				numAdded += 1;
			} else if (props.exercise.equipment.cable_attachment === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += props.exercise.equipment.custom_cable_attachment_name !== undefined ? props.exercise.equipment.custom_cable_attachment_name : "Custom attachment";
                numAdded += 1;
            }

			if (props.exercise.equipment.bar >= 0) {
				equipmentString += numAdded > 0 ? ", " : "";
				equipmentString += props.exercise.equipment.bar + 1 < equipment.bars.length ? equipment.bars[props.exercise.equipment.bar + 1] : "Custom bar";
			} else if (props.exercise.equipment.bar === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += props.exercise.equipment.custom_bar_name !== undefined ? props.exercise.equipment.custom_bar_name : "Custom bar";
            }

			return equipmentString;
		} else {
			return "No equipment specified for this exercise"
		}
	}

    function videoPressed() {
        if (props.exercise.videoLink !== undefined && props.exercise.videoLink !== "") {
            Mixpanel.track("Video pressed", {exercise_id: props.exercise.id, exercise_name: props.exercise.title, video_link: props.exercise.videoLink});
            window.location = props.exercise.videoLink
        } else {
            Mixpanel.track("Video pressed", {exercise_id: props.exercise.id, exercise_name: props.exercise.title, video_link: ""});
        }
    }

	return (
		<div onClick={() => cellSelected()} className={"exercise-cell-container"}>
			<div className="exercise-cell-info-card-content">
				<div className="exercise-cell-info-card-col-container">
	                <div className="exercise-cell-info-card-col exercise-cell-info-card-col-large exercise-cell-info-card-info-container">
	                    <h4>{props.exercise.title}</h4>
	                    <p>{getMusclesString()}</p>
	                </div>
	                <div className="exercise-cell-info-card-col exercise-cell-info-card-col-large exercise-cell-info-card-info-container">
	                    <h4>{getEquipmentRequired() ? "Equipment required" : "No equipment"}</h4>
	                    <p>{getEquipmentString()}</p>
	                </div>
	                <div className="exercise-cell-info-card-col exercise-cell-info-card-info-container">
	                    <h4>Eigen Library</h4>
	                    <div className="exercise-cell-info-card-info-trained-with-me-container">
	                        <div className="exercise-cell-info-card-info-trained-with-me-icon"/>
	                        <p>Created by Eigen Fitness</p>
	                    </div>
	                </div>
                    <div onClick={() => videoPressed()} hidden={props.exercise.videoLink === undefined || props.exercise.videoLink === ""} className="exercise-cell-info-card-col exercise-cell-info-card-col-video exercise-cell-info-card-info-container">
                        <Videocam className="exercise-cell-info-card-video-icon exercise-cell-info-card-video-icon-selected"/>
                    </div>
                    <div onClick={() => videoPressed()} hidden={props.exercise.videoLink !== undefined && props.exercise.videoLink !== ""}  className="exercise-cell-info-card-col exercise-cell-info-card-col-video exercise-cell-info-card-col-video-unselected exercise-cell-info-card-info-container">
                        <VideocamOff className="exercise-cell-info-card-video-icon exercise-cell-info-card-video-icon-unselected"/>
                    </div>
	            </div>
            </div>
		</div>
	)
}

/*
<a href={props.exercise.videoLink}>
                            <Videocam className="exercise-cell-info-card-video-icon exercise-cell-info-card-video-icon-selected"/>
                        </a>
*/

export default ExerciseCell; 


