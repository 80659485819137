import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './IntraWorkoutCurrentExerciseRepSummary.css';

import FormTempoInput from '../Base/FormTempoInput';

import CurrentWorkout_t from '../../Interfaces/CurrentWorkout_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import SetStats_t from '../../Interfaces/SetStats_t';
import RepStats_t from '../../Interfaces/RepStats_t';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function IntraWorkoutCurrentExerciseRepSummary(props: any) {

    const [initSet, setInitSet] = useState(false);

    // Input value states
    // const [inputVal_power, setInputVal_reps] = useState(0);
    // const [inputVal_weight, setInputVal_weight] = useState(0);
    // const [inputVal_tempo, setInputVal_tempo] = useState<Tempo_t>({eccentric: -1, concentric: -1, first_pause: -1, second_pause: -1});
    // const [inputVal_rest, setInputVal_rest] = useState(0);
    const [selectedRepStats, setSelectedRepStats] = useState<RepStats_t>({
                startTime: -1,
                power: -1,
                ROM: -1,
                formAccuracy: -1,
                tempo: {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1},
                flag: -1,
                stats_all_nodes: []
            });

    // Input focus states
    const [isFocused_power, setIsFocused_power] = useState(false);
    const [isFocused_velocity, setIsFocused_velocity] = useState(false);
    const [isFocused_rom, setIsFocused_rom] = useState(false);
    const [isFocused_tempo, setIsFocused_tempo] = useState(false);
    
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.selectedRepStats !== undefined) {
            setSelectedRepStats(props.selectedRepStats);
        }
    }

    useEffect(() => {
        if (props.selectedRepStats !== undefined) {
            setSelectedRepStats(props.selectedRepStats);
        }
    }, [props.selectedRepStats]);


    function updateInputFocusedState(toState: boolean, forInput: 'power' | 'velocity' | 'rom' | 'tempo') {
        switch (forInput) {
            case 'power':
                setIsFocused_power(toState);
                break;
            case 'velocity':
                setIsFocused_velocity(toState);
                break;
            case 'rom':
                setIsFocused_rom(toState);
                break;
            case 'tempo':
                setIsFocused_tempo(toState);
                break;
            
            default:
                break;
        }
    }

    function inputChanged(forInput: 'power' | 'velocity' | 'rom' | 'tempo', toString: any) {

        let statTemp: RepStats_t = selectedRepStats;

        switch (forInput) {
            case 'power':
                let numPow = Number(toString);
                if (!isNaN(numPow)) {
                    statTemp.power = numPow;
                    if (props.updateStat !== undefined) {
                        props.updateStat(statTemp)
                    }
                }
                break;
            case 'velocity':
                let numVel = Number(toString);
                if (!isNaN(numVel)) {
                    statTemp.peakVelocity = numVel;
                    if (props.updateStat !== undefined) {
                        props.updateStat(statTemp)
                    }
                }
                break;
            case 'rom':
                let numROM = Number(toString) / 100;
                if (!isNaN(numROM)) {
                    statTemp.ROM = numROM;
                    if (props.updateStat !== undefined) {
                        props.updateStat(statTemp)
                    }
                }
                break;
            case 'tempo':
                let tempoRaw = toString as Tempo_t;
                statTemp.tempo = tempoRaw;
                if (props.updateStat !== undefined) {
                    props.updateStat(statTemp)
                }
                break;
            default:
                break;
        }
    }


	return (
		<div className="intra-workout-current-exercise-rep-summary">
			<div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-md">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>POWER</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_power ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <IonInput
                        value={selectedRepStats.power <= 0 ? "" : `${selectedRepStats.power}`}
                        className="intra-workout-overview-summary-stats-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'power')}
                        onBlur={() => updateInputFocusedState(false, 'power')}
                        onIonChange={e => inputChanged('power', e.detail.value!)}/>
                    <div className="intra-workout-overview-summary-stats-input-units-container">
                        <p>W</p>
                    </div>
                </div>
            </div>
            <div hidden={true} className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-md">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>PEAK VELOCITY</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_velocity ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <IonInput
                        value={selectedRepStats.peakVelocity === undefined ? '--.-' : (selectedRepStats.peakVelocity <= 0 ? "" : `${selectedRepStats.peakVelocity}`)}
                        className="intra-workout-overview-summary-stats-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'velocity')}
                        onBlur={() => updateInputFocusedState(false, 'velocity')}
                        onIonChange={e => inputChanged('velocity', e.detail.value!)}/>
                    <div className="intra-workout-overview-summary-stats-input-units-container">
                        <p>m/s</p>
                    </div>
                </div>
            </div>
            <div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-sm">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>ROM</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_rom ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <IonInput
                        value={selectedRepStats.ROM <= 0 ? "--.-" : `${Math.round(selectedRepStats.ROM * 100)}`}
                        className="intra-workout-overview-summary-stats-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'rom')}
                        onBlur={() => updateInputFocusedState(false, 'rom')}
                        onIonChange={e => inputChanged('rom', e.detail.value!)}/>
                    <div className="intra-workout-overview-summary-stats-input-units-container">
                        <p>%</p>
                    </div>
                </div>
            </div>
            <div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-lg">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>TEMPO</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_tempo ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <FormTempoInput
                        thisTempo={selectedRepStats.tempo}
                        noBorder={true}
                        focusChanged={(e: boolean) => updateInputFocusedState(e, 'tempo')}
                        tempoUpdated={(t: Tempo_t) => inputChanged('tempo', t)}/>
                </div>
            </div>
		</div>
	)
}

export default IntraWorkoutCurrentExerciseRepSummary;