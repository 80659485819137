import React, { useState, useEffect } from 'react';
import './ROMCard.css';
import './common.css';

import StatGraph from './StatGraph';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function ROMCard(props: any) {

    const [exerciseList, setExerciseList] = useState<any[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<any | undefined>(undefined);
    const [selectedSetIndex, setSelectedSetIndex] = useState(-1);
    const [initSet, setInitSet] = useState(false);

    const [heroStat, setHeroStat] = useState(0);
    const [units, setUnits] = useState("º");
    const [hasLinear, setHasLinear] = useState(false);
    const [showingLinear, setShowingLinear] = useState(false);

    const [graphMetrics, setGraphMetrics] = useState<number[]>([]);
    const [graphMetricsLinear, setGraphMetricsLinear] = useState<number[]>([]);
 
    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.exerciseList) {
        	setExerciseList(props.exerciseList);
        	setSelectedExercise(props.exerciseList[0] ?? undefined);
        }
    }, [props.exerciseList])

    useEffect(() => {
    	setSelectedExercise(props.selectedExercise);
	}, [props.selectedExercise])

	useEffect(() => {
    	setSelectedSetIndex(props.selectedSetIndex);
	}, [props.selectedSetIndex])

	useEffect(() => {
		configureStats()
	}, [selectedSetIndex, selectedExercise])

    const initialize = () => {
        
    }

    const configureStats = () => {
    	// console.log("POWER STATS:", selectedExercise)
    	if (!selectedExercise || !selectedExercise.sets || selectedExercise.sets.length < 1) return;

    	if (selectedSetIndex === -1) {
    		configureStats_sets();
    	} else {
    		configureStats_reps();
    	}

    }

    const configureStats_sets = () => {
    	var setAvgs: number[] = [];
    	var setAvgsLinear: number[] = [];
    	var hasLinearTemp = false;
    	for (var i = 0; i < selectedExercise.sets.length; i++) {
    		let thisSet = selectedExercise.sets[i];
    		if (thisSet && thisSet.rep_stats && thisSet.rep_stats.length > 0) {
    			var thisSetStatSum = 0;
    			var thisSetStatCnt = 0;

    			var thisSetStatSum_linear = 0;
    			var thisSetStatCnt_linear = 0;


    			for (var j = 0; j < thisSet.rep_stats.length; j++) {
    				let thisRepStat = thisSet.rep_stats[j];
    				let thisVel = thisRepStat.ROM ?? 0;
    				
    				thisSetStatSum += thisVel;
    				thisSetStatCnt += 1;

    				if (thisRepStat.linearROM && thisRepStat.linearROM > 0) hasLinearTemp = true
    				if (hasLinearTemp) {
    					let thisVelLinear = thisRepStat.linearROM ?? 0;
    					thisSetStatSum_linear += thisVelLinear;
	    				thisSetStatCnt_linear += 1;
    				}
    			}
    			let thisSetStatAvg = thisSetStatCnt === 0 ? 0 : thisSetStatSum / thisSetStatCnt;
    			setAvgs.push(thisSetStatAvg);

    			let thisSetStatAvgLinear = thisSetStatCnt_linear === 0 ? 0 : thisSetStatSum_linear / thisSetStatCnt_linear;
    			setAvgsLinear.push(thisSetStatAvgLinear);

    		}
    	}
    	setHasLinear(hasLinearTemp);
    	setGraphMetricsLinear(setAvgsLinear);
    	setGraphMetrics(setAvgs);
    }

    const configureStats_reps = () => {
    	var repAvgs: number[] = [];
    	var repAvgsLinear: number[] = [];
    	var hasLinearTemp = false;
    	let thisSet = selectedExercise.sets[selectedSetIndex];
		if (thisSet && thisSet.rep_stats && thisSet.rep_stats.length > 0) {

			for (var j = 0; j < thisSet.rep_stats.length; j++) {
				let thisRepStat = thisSet.rep_stats[j];
				let thisVel = thisRepStat.ROM ?? 0;
				repAvgs.push(thisVel);

				if (thisRepStat.linearROM && thisRepStat.linearROM > 0) hasLinearTemp = true
				if (hasLinearTemp) {
					let thisVelLinear = thisRepStat.linearROM ?? 0;
					repAvgsLinear.push(thisVelLinear)
				}
			}
		}
		// console.log("repAvgs:", repAvgs)
		setHasLinear(hasLinearTemp);
    	setGraphMetricsLinear(repAvgsLinear);
		setGraphMetrics(repAvgs);
    }

    const getAvg = () => {
    	var avgSum = 0;
    	var avgCnt = 0;
    	var arr = showingLinear ? graphMetricsLinear : graphMetrics;
    	for (var i = 0; i < arr.length; i++) {
    		avgSum += arr[i];
    		avgCnt += 1;
    	}

    	return avgCnt === 0 ? 0 : Math.floor(avgSum / avgCnt * 100) / 100;
    }

    const getMax = () => {
    	return graphMetrics.length < 1 ? 0 : Math.max(...graphMetrics);
    }

    const getTargetMax = () => {
        if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.rom) return -1;
    	let velocity_max = selectedExercise.data.motion_info.target.rom.max ?? -1;

    	return velocity_max;
    }

    const getTargetMin= () => {
    	if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.rom) return -1;
    	let velocity_min = selectedExercise.data.motion_info.target.rom.min ?? -1;

    	return velocity_min;
    }

	return (
		<div className={ "performance-stat-card" }>
			<div className={ "performance-stat-card-header" }>
                <h4>Range of Motion</h4>
                <div hidden={ !hasLinear } className={ "performance-stat-card-header-options-container" }>
                	<div className={ `performance-stat-card-header-option ${showingLinear ? "" : "performance-stat-card-header-option-selected"}` } onClick={ () => setShowingLinear(false) }>
	                	<p>Angular</p>
	                </div>
	                <div className={ `performance-stat-card-header-option ${showingLinear ? "performance-stat-card-header-option-selected" : ""}` } onClick={ () => setShowingLinear(true) }>
	                	<p>Linear</p>
	                </div>
                </div>
            </div>
            {
            	exerciseList.length === 0 ?
            	<div className={ "performance-stat-card-body-empty-container" }>
            		<p>No exercises completed</p>
            	</div>
            	:
            	<div className={ "performance-stat-card-body-container" }>
	                <div className={ "performance-stat-card-body-content" }>
	                     <div className={ "performance-stat-card-body-hero-container" }>
	                     	<div className={ "performance-stat-card-body-hero-header-container performance-stat-card-body-hero-header-rom" }>
			                    <h1>{ getAvg() } { showingLinear ? "in" : units }</h1>
			                    <p>Average{ hasLinear ? ( showingLinear ? " Linear" : " Angular" ) : ""} ROM</p>
			                    {
			                    	getTargetMin() > 0 && getTargetMax() > 0 && 
			                    	<h4>Target { Math.round(getTargetMin()) } { units } – { Math.round(getTargetMax()) } { units }</h4>
			                    }
		                    </div>
		                </div>
		                <div className={ "performance-stat-card-body-chart-container" }>
		                	<StatGraph
		                		data={ showingLinear ? graphMetricsLinear : graphMetrics }
		                		units={ showingLinear ? "in" : units }
		                		color={ "#F272D6" }
		                		statAvg={ getAvg() }
		                		statMax={ getTargetMax() }
		                		statMin={ getTargetMin() }/>
		                </div>
	                </div>
	            </div>
            }
            
            <div className={ "performance-stat-card-selector-container" }>
                <div className={ "performance-stat-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default ROMCard;