import React, { useState, useEffect } from 'react';
import './ClientChatInput.css';
import firebase from "firebase";

import sendChatMessage from "./sendChatMessage";
import messageSentPushNotification from '../../../api/notifications/push/messageSentPushNotification';

import sendMessageIcon from '../../../theme/send-message-icon.png';


function ClientChatInput(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [chatId, setChatId] = useState('');
    const [clientFirstName, setClientFirstName] = useState("");
    const [isFocused, setFocused] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const [isLoadingSend, setIsLoadingSend] = useState(false);

    const [clientID, setClientID] = useState("")
    

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.chatId) setChatId(props.chatId);
    }, [props.chatId])

    useEffect(() => {
        if (props.client) {
            if (props.client.id) setClientID(props.client.id);
            if (props.client.first_name && props.client.first_name !== "") setClientFirstName(props.client.first_name.split(" ")[0]);
        }
    }, [props.client])

    const handleInputChange = (event: any) => {
        const message = event.target.value;
        setMessageText(message);
    };

    const handleFocus = () => {
        setFocused(true)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior of Enter key in textarea
            handleSend();
        }
    };

    const handleSend = async () => {

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        const newMessage = {
            sent_by: trainerUID,
            message: messageText,
            timestamp: Date.now()
        }

        console.log("newMessage:", newMessage, chatId)

        if (!chatId || chatId === "") return; 

        setIsLoadingSend(true);
        await sendChatMessage(chatId, newMessage);
        await messageSentPushNotification(clientID, "text", messageText)
        setIsLoadingSend(false);
        setMessageText('')


    }

	return (
		<div className={ `chat-input-container ${ isFocused ? 'chat-input-container-highlighted' : '' }` }>
            <div className="chat-input-container-inner">
                <textarea className="chat-input"
                          placeholder={ `What do you want to say to ${ clientFirstName === "" ? "your client" : clientFirstName }?` }
                          onChange={handleInputChange}
                          onFocus={ () => setFocused(true) }
                          onBlur={ () => setFocused(false) }
                          onKeyDown={handleKeyPress}
                          value={messageText}
                />
            </div>
            <button  className="chat-send-button-container"
                     onClick={handleSend}
                     disabled={!((isFocused && messageText.length > 0) || (messageText.length > 0))}
                     style={(isFocused && messageText.length > 0) || (messageText.length > 0) ? {background: '#5437DC'}
                         : {background: '#5437DC00'}}>
                <div className={ "chat-send-button-inner" }>
                    <div className={ "chat-send-button-inner-text-container" }>
                        <p style={{ color: messageText.length > 0 ? '#ffffff' : '#707484' }}>{ isLoadingSend ? "Sending..." : "Send" }</p>
                    </div>
                    <div className={ "chat-send-button-inner-icon-container" }>
                        {
                            isLoadingSend ? 
                            <div className="chat-send-button chat-send-button-loading">
                                <div className={ "chat-send-button-loading-spinner" }/>
                            </div>
                            :
                            <div className="chat-send-button" >
                                <img src={sendMessageIcon} className="chat-send-button-image" style={{ opacity: messageText.length > 0 ? 1.0 : 0.5 }} alt=''/>
                            </div>
                        }
                    </div>
                </div>
                
                
            </button >
        </div>
	)
}

export default ClientChatInput;