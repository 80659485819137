import React, { useState, useEffect } from 'react';
import './NewWorkoutModal.css';
import './NewClientModal.css';

import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import Tempo_t from '../../Interfaces/Tempo_t';

import MultiSelect from '../Base/MultiSelect';
import DualSelector from '../Base/DualSelector';
import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';
import FormDateInput from '../Base/FormDateInput';
import Selector from '../Base/Selector';
import SelectorWithPills from '../Base/SelectorWithPills';
import ToggleSwitch from '../Base/ToggleSwitch';
import FormBodyMeasurementInput from '../Base/FormBodyMeasurementInput';

import CreateWorkoutExerciseConfigure from './CreateWorkoutExerciseConfigure';
import CreateWorkoutExerciseList from '../Workouts/WorkoutBuilder/CreateWorkoutExerciseList';


import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Public from '@material-ui/icons/Public';
import Home from '@material-ui/icons/Home';
import Group from '@material-ui/icons/Group';
import Lock from '@material-ui/icons/Lock';

function NewWorkoutImage(props: any) {


	const [img, setImg] = useState('');
	const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        getImageName();
    }

	function getImageName() {
		const storage = firebase.storage();
    	const imagePathRoot = 'images/workouts';
    	const imageExtension = '.png';

		storage.ref(`${imagePathRoot}/${props.imageName}${imageExtension}`).getDownloadURL()
	    .then(fireBaseUrl => {
	    	setImg(fireBaseUrl);
	    })
	    .catch(error => {
	        
	    })
	}

	function imageSelected() {
		props.imageSelected();
	}

	return (
		<div onClick={() => imageSelected()} className="new-workout-modal-image-content">
			<img  
				className={props.isSelected ? 'new-workout-modal-image-selected' : 'new-workout-modal-image-unselected'} 
				hidden={img === ''}
				src={img} 
				alt={`Image for ${img}`} />
		</div>
	)
}

function NewWorkoutModal(props: any) {

	let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
	let pageNames = ["Workout Info", "Exercises", "Display Images", "Target Audience", "Availability"];
	let pageDescs = ["", "Configure your new Workout by adding Exercises, supersets, and specifying details for each set.", "Select an image for your workout, which will be visible in your Library and to your clients.", "Define who you're creating this workout for by their goals and/or gender.", "Specify who has access to this Workout, from only you to the entire Eigen Fitness community.", ""]
	const workoutDifficulties = ['Beginner', 'Light','Moderate','Difficult','Extremely Challenging'];

	let initWorkout: Workout_t = {
						title: '',
						description: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: 'b',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0,
						goals: [],
						location: 'Gym'
					};
	let initExerciseBreakdown: ExerciseSeries_t = {
		title: "",
		index: 0,
		exercises: []
	}

	let workoutLevels = ['Sedentary (0 days/week)', 'Been Before (1-2 days/week)', 'Moderate (3-4 days/week)', 'Intense (5-6 days/week)', 'Insane (every day)'];
	let workoutGoals = ['Get Fit', 'Loose Weight', 'Gain Muscle', 'Muscle Toning', 'Body Building', 'Power Lifting'];
	let imagePathTitle = ['abs-day','back-day','chest-blaster','classic-chest','insane-arms','kettle-ball-lift','leg-shaper','legs-day','lower-body','pull-day','quick-shoulders','ultimate-push','upper-body','upper-toning','upper'];

	const [workoutLevelIndex, setWorkoutLevelIndex] = useState(0);
	const [workoutGoalIndex, setWorkoutGoalIndex] = useState(0);

	const [workflowState, setWorkflowState] = useState(0);
	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	const [selectedWorkoutGoals, setSelectedWorkoutGoals] = useState<string[]>([]);

	const [selectedAvailabilityState, setSlectedAvailabilityState] = useState(0);

	const [alerts, setAlerts] = useState<boolean[]>([false,false,false,false,false,false,false]);
	const [imageNames, setImageNames] = useState<string[]>([]);
	const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
	const [initSet, setInitSet] = useState(false);
	const [createMore, setCreateMore] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [selectedSuperset, setSelectedSuperset] = useState<ExerciseSeries_t>(initExerciseBreakdown);
	const [exerciseList, setExerciseList] = useState<Exercise_t[]>([]);
	const [selectedExercise, setSelectedExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
	const [exerciseToEdit, setExerciseToEdit] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});

	const [exerciseSeriesList, setExerciseSeriesList] = useState<ExerciseSeries_t[]>([]);

	const [configuringExercise, setConfiguringExercise] = useState(false);

	const [workoutsAdded, setWorkoutsAdded] = useState(0);

	const [addToPlanID, setAddToPlanID] = useState("");
	const [addToPlanLocation, setAddToPlanLocation] = useState(-1);

	useEffect(() => {
		if (props.forcedPage !== undefined) { return; }
        if (props.selectedExercise !== undefined  && props.selectedExercise !== null) {
            setSelectedExercise(props.selectedExercise);
        }
        
    }, [props.selectedExercise]);

    useEffect(() => {
    	if (props.forcedPage !== undefined) { return; }
        if (props.exerciseToEdit !== undefined  && props.exerciseToEdit !== null) {
        	setExerciseToEdit(props.exerciseToEdit);
        }
    }, [props.exerciseToEdit]);



    useEffect(() => {
    	// console.log("XXXXX ADD TO PLAN: ", props.addToPlan);
    	if (props.addToPlan !== undefined && props.addToPlan.id !== undefined && props.addToPlan.id !== "") {
    		// console.log("XXXXX ADD TO PLAN ID: ", props.addToPlan.id);
    		setAddToPlanID(props.addToPlan.id)
    	}
	}, [props.addToPlan])

	useEffect(() => {
    	// console.log("XXXXX ADD TO PLAN | addToPlanLocation: ", props.addToPlanLocation);
    	if (props.addToPlanLocation !== undefined && props.addToPlanLocation !== -1) {
    		setAddToPlanLocation(props.addToPlanLocation)
    	}
	}, [props.addToPlanLocation])

    useEffect(() => {
    	if (props.forcedPage !== undefined) {
    		setWorkflowState(props.forcedPage);
    	}
    }, [props.forcedPage])

    useEffect(() => {
        if (props.forcedPage !== undefined) { return; }

        if (props.editWorkout !== undefined && props.editWorkout.id !== "") {
            setWorkout(props.editWorkout);
            setExerciseSeriesList(props.editWorkout.exerciseSeries);
            setExerciseList(props.editWorkout.exercises);
            setSlectedAvailabilityState(props.editWorkout.availability !== undefined ? props.editWorkout.availability : 0);
            setWorkflowState(1);
        }
        
    }, [props.editWorkout]);
    
    if (initSet === false) {

        setInitSet(true);
        initialize();
        getImageNames();
    }

    function initialize() {
    	if (props.forcedPage !== undefined) { return; }
    	let thisUser = firebase.auth().currentUser;

    	let createdByName: string = thisUser !== null && thisUser.displayName !== null ? thisUser.displayName! : 'Eigen Fitness';
    	let createdById: string = thisUser !== null  && thisUser.uid !== null ? thisUser.uid : 'eigen_fit'
        let createdByObj: CreatedBy_t = {
        	name: createdByName, 
        	id: createdById
        };
        setWorkout({...workout, createdBy:createdByObj});

        // Start mixpanel timing event
        Mixpanel.time_event("New workout created");
    }

    function getImageNames() {
    	if (props.forcedPage !== undefined) { return; }
    	const storage = firebase.storage();
    	const imagePathRoot = 'images/workouts';
    	const imageExtension = '.png';
		

		setImageNames(imagePathTitle);		
    }

	function getWorkflowClassName(state: number) {
		return workflowState === state ? "new-client-selected-workflow-state" : (state < workflowState ? "new-client-completed-workflow-state" : "new-client-unselected-workflow-state");
	}

	function nextButtonPressed() {
		// console.log("NEXT BUTTON PRESSED");
		
	}

	function clearAll() {
		setWorkout(initWorkout);
		setWorkflowState(0);
		setExerciseSeriesList(exerciseSeriesList.filter(item => false));
		setSelectedExercise({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]})
		setExerciseToEdit({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
		setExerciseList(exerciseList.filter(item => false));
		setSelectedSuperset(initExerciseBreakdown);
		setSelectedImageIndex(-1);
		setAlerts([false,false,false,false,false,false,false]);
		if (!createMore) {
			setSlectedAvailabilityState(0);
		}
		
		setSelectedWorkoutGoals(selectedWorkoutGoals.filter(item => false));
		setWorkoutGoalIndex(0);
		setWorkoutLevelIndex(0);
		setConfiguringExercise(false);
		setWorkoutsAdded(workoutsAdded + 1);
		if (!createMore) {
			clearPlanID();
		}
		
	}

	function clearPlanID() {
		setAddToPlanID("");
		setAddToPlanLocation(-1);
	}


	function continuePressed() {

		if (workflowState === 6) {

			if (props.editWorkout !== undefined && props.editWorkout.id !== "") {
				let w_temp: Workout_t = workout;
				w_temp.oldID = props.editWorkout.id;

				props.closeModal(workout);
				clearAll();

				return;
			}
			
			if (props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true) {
				props.closeModal(workout);
				clearAll();
			} else {
				props.closeModal();
				clearAll();
			}
			
			return;
		}

		if (validatePage() === true) {
			if (workflowState === 4) {
				configureNewWorkout();
			}

			if (props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true && workflowState === 1) {
				setWorkflowState(4);
			} else {
				setWorkflowState(workflowState + 1);
			}

			
		}
		
	}

	function backPressed() {

		if (props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true && workflowState === 4) {
			setWorkflowState(1);
		} else {
			setWorkflowState(workflowState - 1);
		}

		Mixpanel.track("New workout back pressed",{
            from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'
        });
	}

	function closeModal() {

		if (exerciseSeriesList.length > 0 || workout.title.split('').length >= 2) {
			props.getUserConfirmation({
				title: "Are you sure you want to exit?",
				text: `Exiting the Workout builder will erase your progress creating '${workout.title}'.`,
				isRed: false,
				callback: confirmCloseModal,
				ctaText: "Close"
			});

			Mixpanel.track("Close new workout modal requested",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
		} else {
			confirmCloseModal();
		}
	}

	function confirmCloseModal() {
		Mixpanel.track("Close new workout modal confirmed",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
		clearAll();
		clearPlanID();
		props.closeModal();

	}


	function inputChanged(toString: any, fromInput: string) {
		
		switch (fromInput) {
			case 'workout-name':
				let letters = toString.split('');
				setWorkout({...workout,title: toString});
				if (alerts[0] === true && letters.length >= 2) {
					setAlerts(alerts.map((item, index) => { return index === 0 ? false : item }));
				}
				break;
			case 'workout-description':
				let letters_desc = toString.split('');
				setWorkout({...workout, description: toString});
				// if (alerts[0] === true && letters_desc.length >= 2) {
				// 	setAlerts(alerts.map((item, index) => { return index === 1 ? false : item }));
				// }
				break;
			case 'workout-location':
				//let dropdownOptions = ['Gym or workout facility', 'At home or limited equipment area'];
				let dbOptions = ['Gym','Home'];
				setWorkout({...workout,location: dbOptions[Number(toString)]});
				break;
			case 'selected-gender' :
				let genderKeys = ['b','f','m'];
				//let genderKey = toString === 'Primarily Female' ? 'f' : (toString === 'Primarily Male' ? 'm' : 'b');
				setWorkout({...workout,targetGender: genderKeys[Number(toString)]});
				break;
			case 'workout-difficulty' :
				//let workoutDifficultyIndex = workoutDifficulties.indexOf(toString);
				setWorkout({...workout,difficulty: Number(toString)});
				break;
			default:
				// console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
				break;
		}
	}

	function validatePage(forPage?: number) {
		var okayToProceed = true;

		switch (forPage === undefined ? workflowState : forPage) {
			case 0: 			// Workout information page
				setAlerts(alerts.map((item, index) => { 
					switch (index) {
						case 0: 	// full name
							let letters_title = workout.title.split('');
							okayToProceed = letters_title.length >= 2;
							return letters_title.length < 2;
							break;
						// case 1: 	// workout description
						// 	let letters_desc = workout.description === undefined ? [''] : workout.description.split('');
						// 	okayToProceed = letters_desc.length >= 2;
						// 	return letters_desc.length < 2;
						// 	break;
						default:
							return false;
							break;
					}
				}))

				break;
			case 1:
				okayToProceed = exerciseSeriesList.length > 0;
				break;
			case 2:
				setAlerts(alerts.map((item, index) => { 
					switch (index) {
						case 3: 	// full name
							if (props.onTheFlyWorkoutAdding === undefined || props.onTheFlyWorkoutAdding === false) {
								okayToProceed = selectedImageIndex >= 0;
								return selectedImageIndex < 0;
							} else {
								okayToProceed = true;
								return false;
							}
							
							break;
						default:
							return false;
							break;
					}
				}))
				break;
			case 3:
				// okayToProceed = workout.goals !== undefined && workout.goals.length > 0;
				// setAlerts(alerts.map((item, index) => { return index !== 4 ? item : !okayToProceed }));
				break;
			case 4:
				okayToProceed = true;
			default:
				break;
		}

		// for (var i = 0; i < alerts.length; i++) {
		// 	if (alerts[i] === true) {
		// 		// console.log(alerts[i]);
		// 		okayToProceed = false;
		// 	}
		// }
		return okayToProceed;
	}

	function imageSelected(index: number) {
		setSelectedImageIndex(index);
		setWorkout({...workout,imageName: imagePathTitle[index]});
	}

	function goalsUpdated(goals: string[]) {
		setSelectedWorkoutGoals(goals);

		var goalIndeciesTemp: number[] = [];
		if (goals.length > 0) {
			for (var i = 0; i < goals.length; i++) {
				let thisIndex = workoutGoals.indexOf(goals[i]);
				goalIndeciesTemp.push(thisIndex);
			}
		}
		setWorkout({...workout,goals: goalIndeciesTemp});
	}

	function addAnotherExercise(forceNumReps?: number) {
		setExerciseToEdit({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
		setConfiguringExercise(true);

		let dataObj= {
			callback: backToExerciseList,
            isBlock: false,
            blockIndex: -1,
            forceSetCount: forceNumReps ?? -1
		};
		if (props.addNewExerciseToWorkoutModalPressed !== undefined) {
			props.addNewExerciseToWorkoutModalPressed(dataObj);
		}
		
		//props.addAnotherExercise();
	}

	function addAnotherExerciseToSuperSet(forExerciseBreakdown: ExerciseSeries_t) {
		setSelectedSuperset(forExerciseBreakdown);
	}

	function exerciseListUpdated(list: Exercise_t[]) {
		setExerciseList(list);
	}

	function exerciseSeriesListUpdated(list: ExerciseSeries_t[]) {
		// console.log("exerciseSeriesListUpdated: ", list);
		setExerciseSeriesList(exerciseSeriesList.filter((item: ExerciseSeries_t) => { return false; }))
		setExerciseSeriesList(list);
	}	

	function finishAndSaveEarly() {
		if (props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true && workflowState === 1) {
			setWorkflowState(5);
		} else {
			setWorkflowState(workflowState + 1);
		}

		configureNewWorkout();
	}

	function getPageValidation() {
		let passedExercises = exerciseSeriesList.length > 0;

		let letters_title = workout.title.split('');
		let passedTitle = letters_title.length >= 2;

		return passedExercises && passedExercises;
	}



	function configureNewWorkout() {

		setIsLoading(true);

		var totalRepCount = 0;
		var totalSetCount = 0;
		var restTimes: string[] = [];
		var durationSeconds = 0;
		var tagsFinal: string[] = [];
		var exerciseListFinal: any[] = [];
		var exerciseList_ts: Exercise_t[] = [];
		var exerciseSeriesFinal: any[] = [];


		if (exerciseSeriesList.length > 0) {
			for (var series_index = 0; series_index < exerciseSeriesList.length; series_index++) {
				let thisSeries: ExerciseSeries_t = exerciseSeriesList[series_index];
				let thisSeriesExerciseList = thisSeries.exercises;

				let offsetASCIICode = series_index + 65;
        		let seriesChar = String.fromCharCode(offsetASCIICode);

        		var thisSeriesExerciseJSONList: any[] = [];

				if (thisSeriesExerciseList.length > 0) {
					for (var exercise_index = 0; exercise_index < thisSeriesExerciseList.length; exercise_index++) {
						let thisExercise: Exercise_t = thisSeriesExerciseList[exercise_index];

						// 1. Sum the rep and set counts, and sum total duration from activity
						let thisExerciseSetCount = thisExercise.reps !== undefined ? thisExercise.reps.length : 0;
						totalSetCount += thisExerciseSetCount;
						if (thisExerciseSetCount > 0) {
							for (var j = 0; j < thisExerciseSetCount; j++) {
								totalRepCount += thisExercise.reps !== undefined ? thisExercise.reps[j] : 0;
								durationSeconds += thisExercise.reps !== undefined ? thisExercise.reps[j] * 3 : 36;
							}
						}

						// 2. Calculate rest times for exercise and sum total duration from rest
						let rests = thisExercise.restTime;
						let restCount = rests !== undefined ? rests.length : 0;
						var restSum = 0;
						var containsNullRestTime = false;
						if (rests !== undefined && restCount > 0) {
							for (var j = 0; j < restCount; j++) {
								if (rests[j] === -1) { 
									containsNullRestTime = true; 
									durationSeconds += 45;
								} else {
									durationSeconds += rests[j];
								}
								restSum += rests[j];
							}
						}
						let avgExerciseRest = restCount > 0 && containsNullRestTime === false ? Math.ceil(restSum / restCount) : -1;
						restTimes.push(String(avgExerciseRest));

						// 3. Tempo JSON list
						var tempoJSONList: any[] = [];
						if (thisExercise.tempos !== undefined && thisExercise.tempos.length > 0) {
							for (var j = 0; j < thisExercise.tempos.length; j++) {
								let thisTempo: Tempo_t = thisExercise.tempos[j];
								let thisTempoJSONObj = {
									concentric: thisTempo.concentric,
									first_pause: thisTempo.first_pause,
									eccentric: thisTempo.eccentric,
									second_pause: thisTempo.second_pause
								};
								tempoJSONList.push(thisTempoJSONObj);
							}
						}

						// 4. Create JSON formatted exercise for exercise list
						let thisExerciseJSON: any = {
							created_by: {
								id: thisExercise.createdBy !== undefined ? thisExercise.createdBy.id : 'eigen_fit',
								name: thisExercise.createdBy !== undefined ? thisExercise.createdBy.name : 'Eigen Fit'
							},
							key: thisExercise.id,
							equipment: thisExercise.equipment !== undefined ? thisExercise.equipment : {bar: -1, bench: -1, cable_attachment: -1, machine: -1},
							muscles: thisExercise.muscles,
							reps: thisExercise.reps !== undefined ? thisExercise.reps : [],
							setup: thisExercise.setup !== undefined ? thisExercise.setup : 'No setup specified',
							steps: thisExercise.steps !== undefined ? thisExercise.steps : [],
							tags: thisExercise.tags !== undefined ? thisExercise.tags : [],
							title: thisExercise.title,
							type: thisExercise.type,
							percent_wms: thisExercise.percent_wms !== undefined ? thisExercise.percent_wms : [],
							rest_times: thisExercise.restTime !== undefined ? thisExercise.restTime : [],
							tempos: tempoJSONList,
							rep_ranges: thisExercise.repRanges !== undefined ? thisExercise.repRanges : [],
						}

						if (thisExercise.note !== undefined) {
							thisExerciseJSON['note'] = thisExercise.note;
						}

						if (thisExercise.loadingPattern !== undefined) {
							thisExerciseJSON['loading_pattern'] = thisExercise.loadingPattern;
						}

						exerciseListFinal.push(thisExerciseJSON);

						exerciseList_ts.push(thisExercise);


						// 5. Create JSON formatted series for series list
						let thisExerciseForSeriesJSON = {
							title: `${seriesChar}${exercise_index + 1}`,
							index: exercise_index,
							id: thisExercise.id
						};
						thisSeriesExerciseJSONList.push(thisExerciseForSeriesJSON);

						// 6. Create tags list
						if (thisExercise.tags !== undefined && thisExercise.tags.length > 0) {
							for (var j = 0; j < thisExercise.tags.length; j++) {
								let thisTag = thisExercise.tags[j];
								if (tagsFinal.indexOf(thisTag) < 0) {
									tagsFinal.push(thisTag);
								}
							}
						}
					}
				}

				let thisSeriesJSON = {
					title: seriesChar,
					index: series_index,
					type: thisSeriesExerciseJSONList.length > 1 ? 1 : 0,
					exercises: thisSeriesExerciseJSONList
				};
				exerciseSeriesFinal.push(thisSeriesJSON);
			}
		}

		let durationMinutes = Math.ceil(durationSeconds / 60);
		let sendJSON: any = {
			title: workout.title,
			description: workout.description,
			created_timestamp: Date.now(),
			tags: tagsFinal,
			goals: workout.goals,
			duration: durationMinutes,
			total_reps: totalRepCount,
			total_sets: totalSetCount,
			image_name: workout.imageName === '' ? '' : `${workout.imageName}.png`,
			target_gender: workout.targetGender,
			exercises: exerciseListFinal,
			exercise_series: exerciseSeriesFinal,
			rest_times: restTimes,
			difficulty: String(workout.difficulty),
			workout_location: workout.location,
			created_by: workout.createdBy,
			availability: selectedAvailabilityState
		};
		// console.log("------- WORKOUT DATA: -------");
		// console.log(exerciseList);
		// console.log(sendJSON);




		

		// Track in Mixpanel
        let availabilityStrings = ["Public","Gym-only","Clients-only","Private"];
        Mixpanel.track("New workout created",{
            num_series: exerciseSeriesFinal.length,
            num_exercises: exerciseListFinal.length,
            image_name: workout.imageName,
            availability: selectedAvailabilityState < availabilityStrings.length ? availabilityStrings[selectedAvailabilityState] : 'unknown'
        });


        Mixpanel.people.increment("New workouts");

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/createWorkout`,
			{body: sendJSON},
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
		    // console.log(response.data);
		    let data = response.data;
		    let workoutID = data.pushKey;

		    let finalWorkout: Workout_t = {
				title: workout.title,
				id: workoutID,
				description: workout.description,
				tags: tagsFinal,
				goals: workout.goals,
				duration: durationMinutes,
				totalReps: totalRepCount,
				totalSets: totalSetCount,
				imageName: `${workout.imageName}.png`,
				targetGender: workout.targetGender,
				exerciseSeries: exerciseSeriesList,
				exercises: exerciseList_ts,
				restTimes: restTimes,
				difficulty: workout.difficulty,
				location: workout.location,
				createdBy: workout.createdBy,
				availability: selectedAvailabilityState
			};

			setWorkout(finalWorkout);


			// Check if adding workout to plan
			if (props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true) {
				// Case: adding new workout to plan and user has indicated they wish to add more workouts consecutively
				// console.log("Sending back to plan page...", finalWorkout);
				if (props.addWorkoutToPlan !== undefined) {
					// console.log("CONFIRM")
					props.addWorkoutToPlan(finalWorkout);
				}
			} else if (props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true && props.editWorkout !== undefined && props.editWorkout.id !== "") {
				// Case: editing workout
				// console.log("ZZZZZZZZ closing after workout created", finalWorkout, props.onTheFlyWorkoutAdding, props.editWorkout, createMore)
				let w_temp: Workout_t = finalWorkout;
				w_temp.oldID = props.editWorkout.id;
				props.closeModal(w_temp);
			}


			let thisUser = firebase.auth().currentUser;
		    let thisUserID = thisUser !== null ? thisUser.uid : '';
		    let database = firebase.database();

			// Check if adding to a plan (from Programs page)
			if (addToPlanID !== "") {
				// Adding to plan from Programs page
				// console.log("Will add to plan ID: ", addToPlanID)

				// 1. Pull plan's workouts list
				let ref_plan = database.ref(`workout_plans/plans/${addToPlanID}/workouts`);
				ref_plan.once('value', function(snapshot) {

					// 2. Add workout ID to plan's list
					if (snapshot.exists()) {
						let list: string[] = snapshot.val();
						// console.log("---- NEW WORKOTU MODAL | LIST:", list, addToPlanLocation, props.addToPlanLocation, list[addToPlanLocation]);
						if (addToPlanLocation && addToPlanLocation >= 0 && list.length - 1 >= addToPlanLocation) {
							list[addToPlanLocation] = workoutID;
							ref_plan.set(list);
						} else {
							list.push(workoutID);
							ref_plan.set(list);
						}
						
					} else {
						// Workout list DNE (incase of a bug). Create list and add to db
						let list: string[] = [workoutID];
						ref_plan.set(list);
					}
				});
			}

			// Check if workout is being added to a client's schedule
			// console.log("STUFF: ", props.selectedClient, props.selectedTimestamp, props.editWorkout);
			
			if (props.selectedClient !== undefined && props.selectedClient.id !== "" && 
				props.selectedTimestamp !== undefined && props.selectedTimestamp > 0) {

				let thisDate = new Date(props.selectedTimestamp);
				let thisDate_yr = thisDate.getFullYear();
				let thisDate_mo = thisDate.getMonth();
				let thisDate_dt = thisDate.getDate();

				if (props.editWorkout === undefined || props.editWorkout.id === '') {
					// Creating a new workout in the client's schedule
					let clientRef = database.ref(`workout_plans/client_assigned_plans/${props.selectedClient.id}/schedule/${thisDate_yr}/${thisDate_mo}/${thisDate_dt}`);
					let scheduleObj = {
						cycle: {
							id: "",
							index: -1,
							name: "",
							path: ""
						},
						phase: {
							id: "",
							index: -1,
							name: "",
							path: ""
						},
						path: "",
						timestamp: props.selectedTimestamp,
						workout_id: workoutID
					};

					clientRef.set(scheduleObj);
				} else {
					// Editting an existing workout in the client's schedule -- update the workout ID
					let clientRef_b = database.ref(`workout_plans/client_assigned_plans/${props.selectedClient.id}/schedule/${thisDate_yr}/${thisDate_mo}/${thisDate_dt}/workout_id`);
					clientRef_b.set(workoutID);
				}
			}

			// Check if workout is created to replace rest day
			// console.log("TRYING TO MAKE THIS WORK::", props.rest_day_information);
			if (props.rest_day_information !== undefined && props.rest_day_information.rest_day_index !== undefined && props.rest_day_information.plan !== undefined && props.rest_day_information.plan.id !== undefined) {
				//let splitpath = props.rest_day_information.plan.path.split('/');
				var composedPath = props.rest_day_information.plan.id;
				// for (var i = 1; i < splitpath.length; i++) {
				// 	composedPath += "/";
		  //           composedPath += splitpath[i];
		  //           composedPath += i === splitpath.length - 1 ? "" : "/children";
				// };

				// // console.log("Adding to composedPath: ", composedPath);

				let restDayReplaceRef = database.ref(`workout_plans/plans/${composedPath}/workouts/${props.rest_day_information.rest_day_index}`);
				restDayReplaceRef.set(workoutID);
			}

		    
		    var isSet = false;
			let ref = database.ref(`personal_trainer_workouts/${thisUserID}`);
			ref.once('value', function(snapshot) {
				if (isSet === false) {
					isSet = true;
					if (snapshot.exists() === true) {
				        var data: string[] = snapshot.val();
				        data.push(workoutID);
				        ref.set(data);
				        //setWorkflowState(6);
				        // console.log()
				        finishedCreatingWorkout();
				        return;
				    } else {
				    	ref.set([workoutID]);
				    	//setWorkflowState(6);
				    	finishedCreatingWorkout();
				    	return;
				    }
				} else {
					//setWorkflowState(6);
					finishedCreatingWorkout();
					return;
				}
			    
			});
		    
		})
		.catch(e => {
			finishedCreatingWorkout();
		    // console.log(e);
		})
	}

	function finishedCreatingWorkout() {
		setIsLoading(false);

		// console.log("YYYYYYY finishedCreatingWorkout", createMore);
		if (createMore === true) {
			clearAll();
		} else {
			// console.log("ZZZZZZZZ closing in finishedCreatingWorkout")
			confirmCloseModal();
			clearAll();
		}
	}

	function availabilitySelected(a: number) {
		setSlectedAvailabilityState(a);
	}

	function clearExerciseToEdit() {
		setExerciseToEdit({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
	}

	function editExercisePressed(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t) {
		// console.log("EDITING (newWorkoutModal): ", forExercise);	

		//setConfiguringExercise(true);

		let dataObj= {
			callback: saveEditedExercise,
            isBlock: false,
            blockIndex: -1,
            isEditing: true,
            forExerciseSeries: forExerciseSeries,
            forExercise: forExercise
		};
		props.addNewExerciseToWorkoutModalPressed(dataObj);	

		// clearExerciseToEdit();
  //   	setTimeout(() => {
		// 	setExerciseToEdit(forExercise);
		// }, 500);
		// setWorkflowState(1);
		// setConfiguringExercise(true);
	}

	function saveEditedExercise(forExerciseSeries: ExerciseSeries_t, forExercise: Exercise_t, newExercise: Exercise_t) {
		let newNumSets = newExercise && newExercise.reps ? newExercise.reps.length : 0;
        //console.log("USE EFFECT RESP | ", editingExercise, addingToSuperset, newNumSets, props.selectedExercise);
        setExerciseSeriesList(exerciseSeriesList.map((item: ExerciseSeries_t, index: number) => {
            if (item.title === forExerciseSeries.title) {
                let seriesTemp = item;
                var newExerciseListTemp = seriesTemp.exercises ?? [];
                for (var i = 0; i < newExerciseListTemp.length; i++) {
                    let thisExerciseTemp = newExerciseListTemp[i];
                    if (newNumSets > 0 && thisExerciseTemp) {
                        var newRepArry = [];
                        let existingRepArry = thisExerciseTemp.reps ?? [];
                        for (var j = 0; j < newNumSets; j++) {
                            let thisNumReps = existingRepArry[j] ?? ( existingRepArry[existingRepArry.length - 1] ?? 12 );
                            newRepArry.push(thisNumReps)
                        }
                        newExerciseListTemp[i].reps = newRepArry;
                    } else {

                    }
                }
                seriesTemp.exercises = newExerciseListTemp;
                return seriesTemp;
            } else {
                return item;
            }
        }));
	}



	function jumpToPagePressed(atIndex: number) {
        if (atIndex <= workflowState) {
            // moving backwards - all good in all cases
            Mixpanel.track("New workout jump page pressed",{
                from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                to_page: atIndex < pageNames.length ? pageNames[atIndex] : 'unknown',
                move_valid: true
            });
            setWorkflowState(atIndex);
        } else {
            // attempting to move forwards. Validate all pages from (including) the current page to the page before the moving-to page
            var moveValid = true;
            var furthestValidPageIndex = workflowState - 1;
            for (var i = workflowState; i < atIndex; i++) {
                let thisPageValid = validatePage(i);
                if (!thisPageValid) {
                    moveValid = false;
                } else {
                    if (moveValid === true) {
                        furthestValidPageIndex = i;
                    }
                    
                }
            }
            if (furthestValidPageIndex >= 0) {
                Mixpanel.track("New workout jump page pressed",{
                    from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                    to_page: furthestValidPageIndex + 1 < pageNames.length ? pageNames[furthestValidPageIndex + 1] : 'unknown',
                    move_valid: moveValid
                });
                setWorkflowState(furthestValidPageIndex + 1);
            }
        }
    }

    function backToExerciseList(addExerciseToBlockIndex: number, ex?: Exercise_t) {

    	if (ex !== undefined && ex !== null) {
    		// console.log("NEw workout modal: backToExerciseList | setting selected exercise to -> ", ex);
    		setSelectedExercise(ex);
    	}
    	setConfiguringExercise(false);
    }

    function updateToggleState(toState: boolean) {
    	// console.log("YYYYYYYYY CREATE MORE STATE:", toState);
    	setCreateMore(toState);
    	setCreateMore(toState);
    	setCreateMore(toState);
    	setCreateMore(toState);
    	setCreateMore(toState);
    }

    function getDateFromTimestamp(ts: number) {
    	let newDate = new Date(ts);
    	let moString = moStrings[newDate.getMonth()];

    	return `${moString} ${newDate.getDate()}, ${newDate.getFullYear()}`
    }

    useEffect(() => {
    	// console.log("TTTTTTTTTT CREATE MORE UPDATED TO: ", createMore);
    }, [createMore])

	return (
		<div className="new-client-container">
			<div className="new-workout-modal-header-container">
				<div className="new-workout-modal-header-close-container">
					<div onClick={() => closeModal()} className="new-workout-modal-header-close-button">
						<Close className="new-workout-modal-header-close-button-icon"/>
					</div>
				</div>
				<div className="new-workout-modal-header-content-container">
					<div className="new-workout-modal-header-content-icon-container">
						<FitnessCenter className="new-workout-modal-header-content-icon"/>
					</div>
					<div className="new-workout-modal-header-content-text-container">
						<h4>{props.editWorkout !== undefined && props.editWorkout.id !== "" ? "Edit Workout details" : "Create a new Workout"} {props.selectedClient !== undefined && props.selectedClient.id !== "" ? `in ${props.selectedClient.first_name}'s Schedule` : ''}</h4>
						<div hidden={props.selectedTimestamp === undefined || props.selectedTimestamp <= 0} className="new-workout-modal-header-content-text-container-date">
							<h4>{getDateFromTimestamp(props.selectedTimestamp)}</h4>
						</div>
					</div>
				</div>
				<div className="new-workout-modal-header-button-container">
					<div hidden={props.editWorkout !== undefined && props.editWorkout.id !== "" || (props.showCreateMore === false)} className="new-workout-modal-header-toggle-container">
						<ToggleSwitch 
							stateChanged={(e: boolean) => updateToggleState(e)}
							forceState={createMore}/>
						<p>Create more</p>
						<div className="new-workout-modal-header-toggle-info-container">
							<p>Easily create another workout after this one without leaving this page.</p>
						</div>
					</div>
					<div onClick={() => getPageValidation() ? finishAndSaveEarly() : null} className={`new-workout-modal-header-button-cta ${getPageValidation() ? '' : 'new-workout-modal-header-button-cta-unselected'}`}>
						<p>Finish & {props.editWorkout !== undefined && props.editWorkout.id !== "" ? "save changes" : "create Workout"}</p>
					</div>
				</div>
			</div>
			<div hidden={isLoading} className="new-client-content-container-wrapper">
				<div className="new-client-content-container">
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>General Workout Details</h2>
					</div>
					<div className="new-client-content-page-section-spacer"/>
					<div className="new-client-content-page-input-container">
						<FormTextInput 
							value={workout.title}
							placeholder="Workout Name" 
							alert={alerts[0]} 
							alertText="Please enter a workout name"
							autofocus={true}
							noHide={true}
							inputChanged={(e: string) => inputChanged(e, 'workout-name')}/>
						<div className="new-client-content-page-info-container">
							<div className="new-client-content-page-info-icon-container">
								<div className="new-client-content-page-info-icon"/>
							</div>
							<p>{props.onTheFlyWorkoutAdding !== undefined && props.onTheFlyWorkoutAdding === true ? 
									"Consider naming this Workout to describe the day in your Plan, such as 'Day #1', or outline the muscles worked, such as 'Chest Day'" :
									"Workout names typically include the muscle groups being worked, such as 'Chest Day'"
								}</p>
						</div>
					</div>
					<div className="new-client-content-page-section-spacer"/>
					<div className="new-client-content-page-input-container">
						<FormLongTextInput 
	                            value={workout.description}
	                            placeholder="Brief Description" 
	                            alert={alerts[1]} 
	                            isOptional={true}
	                            alertText="Please enter a short description of the workout"
	                            isSearch={false}
	                            inputChanged={(e: string) => inputChanged(e, 'workout-description')}/>
					</div>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>Access permisions</h2>
					</div>
					<div className="new-workout-modal-availability-row">
						<div onClick={() => availabilitySelected(0)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 0 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-floater">Recommended</div>
							<div className="new-workout-modal-availability-cell-header">
								<Public className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Public</h3>
							</div>
							<p>This workout will be available for download by all EigenFit users. Recommended to help boost your downloads and Trainer Rating.</p>
						</div>
						<div onClick={() => availabilitySelected(1)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 1 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-header">
								<Home className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Gym only</h3>
							</div>
							<p>This workout will be available for download and use by members of my gym. Perfect for sharing your tried & true workouts with trainers you know.</p>
						</div>
					</div>
					<div className="new-workout-modal-availability-row">
						<div onClick={() => availabilitySelected(2)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 2 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-header">
								<Group className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Clients only</h3>
							</div>
							<p>Only my clients will have access to use and download this workout. Great for keeping your workouts personal and solidifying your training style.</p>
						</div>
						<div onClick={() => availabilitySelected(3)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 3 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-header">
								<Lock className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Private</h3>
							</div>
							<p>This workout is only available during one-on-one sessions with my clients. Best for workouts that require a close eye and lots of communication.</p>
						</div>
					</div>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-spacer-5vh"/>
					{/*<div hidden={selectedAvailabilityState !== 0} className="new-client-content-page-section">
						<div className="new-client-content-page-section-header">
							<h2>Public search options</h2>
							<p>Configure who will see your publically available workouts</p>
						</div>
						<div className="new-client-content-page-section-selector-container">
							<h4>Workout difficulty</h4>
							<Selector
								placeholder="Workout difficulty"
								showTitle={false} 
								options={workoutDifficulties}
								inputChanged={(e: number) => inputChanged(e, 'workout-difficulty')}/>
						</div>
						<div className="new-client-content-page-section-selector-container">
							<h4>Target audience gender</h4>
							<Selector
								placeholder="Target audience gender"
								showTitle={false} 
								options={['Intended for anyone', 'Primarily female', 'Primarily male']}
								inputChanged={(e: number) => inputChanged(e, 'selected-gender')}/>
						</div>
						<div className="new-client-content-page-section-spacer-5vh"/>
						<div className="new-client-content-page-section-header">
							<h2>Public display image</h2>
						</div>
						<div className="new-workout-placeholder-image-container">
							{imageNames.map((item, index) => (
								<NewWorkoutImage 
									imageName={item} 
									isSelected={selectedImageIndex === index}
									imageSelected={() => imageSelected(index)}/>
							))}
						</div>
					</div>*/}
				</div>
				<div className="new-client-right-pane-content-container">
					<div className="new-client-content-page-section-header">
						<h2>Build your new Workout</h2>
						<p>Design your new Workout by adding & configuring Exercise Blocks.</p>
					</div>
					<div className="new-workout-modal-page-full-height new-workout-exercise-list-container">
						<CreateWorkoutExerciseList 
							selectedExercise={selectedExercise}
							workoutsAdded={workoutsAdded}
							series={exerciseSeriesList}
							exerciseListUpdated={(e: Exercise_t[]) => exerciseListUpdated(e)}
							exerciseSeriesListUpdated={(e: ExerciseSeries_t[]) => exerciseSeriesListUpdated(e)}
							editExercisePressed={(eS: ExerciseSeries_t, e: Exercise_t) => editExercisePressed(eS, e)}
							addAnotherExercise={(r?: number) => addAnotherExercise(r)}
							createNewExerciseFromWorkout={() => props.createNewExerciseFromWorkout()}/>
					</div>
				</div>
			</div>
			<div hidden={!isLoading} className="new-client-content-container-wrapper new-client-content-container-wrapper-loading">
				<div className="new-client-content-container-wrapper-loading-container">
					<div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Creating Workout...</p>
				</div>
			</div>
		</div>
	)
}

export default NewWorkoutModal;



/*
<div className={`new-client-header-container new-client-header-container-first-screen new-workout-header-container${workflowState === 0 ? '-coloured' : ''}`}>
				<div hidden={workflowState !== 0}>
					<div className="new-workout-header-icon-container new-workout-header-icon-container-first-screen">
						<FitnessCenter className="new-workout-header-icon"/>
					</div>
					<div className="new-client-header-text-container">
						<h1>{ props.editWorkout !== undefined && props.editWorkout.id !== "" ? `Edit ${props.editWorkout.title}` : `Create a new Workout`}</h1>
						<p>Composed of a series of Exercises, or supersets, Workouts are re-usable elements that you can use to build Plans.</p>
					</div>
					<div className="new-client-header-dots-container">
						<div className="new-client-header-dot-long"/>
						<div className="new-client-header-dot-short"/>
						<div className="new-client-header-dot-short"/>
					</div>
				</div>
				<div hidden={workflowState === 0}>
					<div className="new-client-header-upper-content">
						<div className="new-workout-header-icon-container">
							<FitnessCenter className="new-workout-header-icon"/>
						</div>
						<h2>{ props.editWorkout !== undefined && props.editWorkout.id !== "" ? `Edit ${props.editWorkout.title}` : `Create a new Workout`}</h2>
					</div>
					<div className="new-client-header-body-container">
						<div hidden={workflowState >= 5} className="new-client-header-body-header">
							<h1>Step {workflowState + 1}</h1>
							<p>{pageDescs[workflowState]}</p>
						</div>
						<div hidden={workflowState !== 5} className="new-client-header-body-header">
							<h1>Completing...</h1>
							<p>Hold tight as we create '{workout.title}' in your Workout Library</p>
						</div>
						<div hidden={workflowState !== 6} className="new-client-header-body-header">
							<h1>Complete!</h1>
							<p></p>
						</div>
						<div hidden={workflowState >= 5} className="new-client-header-body-content">
							{pageNames.map((item: string, index: number) => (
								<div onClick={() => jumpToPagePressed(index)} className={`new-client-header-body-row-container ${workflowState === index || (index === 1 && configuringExercise === true) ? 'new-client-header-body-row-container-selected' : ''}`}>
									<div className="new-client-header-body-row-circle">
										<h4>{index + 1}</h4>
									</div>
									<div className="new-client-header-body-row-text-container">
										<h4>{item}</h4>
									</div>
									<div hidden={index === pageNames.length - 1} className="new-client-header-body-row-line"/>
									<div hidden={index !== 1 || configuringExercise === false} className="new-client-header-body-sub-page-container">
										<div className="new-client-header-body-sub-page-icon-container">
											<ArrowForward className="new-client-header-body-sub-page-icon"/>
										</div>
										<h4>Configure Exercise</h4>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
*/

