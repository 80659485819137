import React from 'react';
import axios from 'axios'
import firebase from '../../../Firebase';

import getDatabaseDirect from '../../firebase/database/getDatabaseDirect';


const messageSentPushNotification = async (
		user_uid: string,
		message_type: "text" | "video",
		message_text?: string
	) => {
    
    return new Promise<boolean>( async function(resolve, reject) {
    	
    	try {

    		// 1. Get current user (trainer)'s name
    		let currentUser = firebase.auth().currentUser;
			if (!currentUser) { 
				reject("No logged in user found");
				return; 
			}
			let trainerUID = currentUser.uid;
			if (!trainerUID || trainerUID === "") {
    			reject(`No UID found for current user <${trainerUID}>`);
				return; 
    		}

    		let trainerNameData = await getDatabaseDirect(`personal_trainer_profile/${trainerUID}/first_name`);
    		if (!trainerNameData || trainerNameData === "") {
    			reject(`No name found for current user <${trainerUID}>`);
				return; 
    		}
    		

			let sendData = {
			    user_uid: user_uid,
	        	title: `${trainerNameData} sent you a ${ message_type === "text" ? "new message" : "video message" }`,
	        	body: message_text ?? "",
	        	subtitle: "",
	        	send_data: { target_location: 'application/trainer/messages' }
			};

			let resp = await axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/sendPushNotification`,
				sendData,
				{ headers: {'Content-Type': 'application/json'} });

			let respData = resp ? resp.data : null;
			
			console.log(respData);

			resolve(true);
			
    	} catch (err) {
    		//console.log("ERROR (sendPushNotification)", err);
    		reject(err);
    	}
	});
}

export default messageSentPushNotification;