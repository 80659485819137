import React, { useState, useEffect } from 'react';
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import './Home.css';
import './DashboardPageCommon.css';
import firebase from '../../Firebase';

import PageHeader_t from '../../Interfaces/PageHeader_t';
import Workout_t from '../../Interfaces/Workout_t';

import PageHeader from '../../components/Base/PageHeader';
import BlankPage from '../../components/PageElements/BlankPage';
import TextInput from '../../components/Base/TextInput';
import StandardButton from '../../components/Base/StandardButton';

import NodePlayground from './NodePlayground';

import HomeMain from './HomePages/HomeMain';
import Notifications from './HomePages/Notifications';
import NodeStreamVideoRecord from './HomePages/NodeStreamVideoRecord';
import NodeStreamAnalyze from './HomePages/NodeStreamAnalyze';
import NodeStreamAnalyzeMLData from './HomePages/NodeStreamAnalyzeMLData';
import NodeStreamAnalyzePhoneData from './HomePages/NodeStreamAnalyzePhoneData';


import '@material-ui/core';
import PlayArrow from '@material-ui/icons/PlayArrow';

import WeightSelector from '../../components/Base/WeightSelector';

const adminIDs = ["CLCA6mbza8TNpJcdaWAnM2Ea5jS2", "nU93EH0guoMYKqg2qc4Wiooe1A73"]

function Home(props: any) {

	// let pageOptions: PageHeader_t[] = [{ name:"Training Dashboard", alert:false },
	// 								   // { name: "Node Video Recorder", alert: false }
	// 								   ];
									   //{ name:"Expiring Plans", alert:false },
					   				   //{ name:"Notifications", alert:false },
					   				   //{ name:"Physio Demo", alert:false }
					   				   
	const [pageNumber, setPageNumber] = useState(0);
	const [pageOptions, setPageOptions] = useState([{ name:"Training Dashboard", alert:false }])
	const [testing, setTesting] = useState(true);

	const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            let currentUser = firebase.auth().currentUser;
			if (!currentUser) { return; }
			let trainerUID = currentUser.uid;
			if (adminIDs.includes(trainerUID)) {
				setPageOptions(pageOptions.concat({ name:"ML Train", alert:false }))
			}
        }
    }, [initSet])

	function getFirstName() {
		var fName = props.profile.first_name;
		return fName[0].toUpperCase() + fName.slice(1);
	}

	function pageSelected(newPage: string) {
		//test();
		//configureMultipleParams();

		//configureInjuryParams();
		//configureStretches();
		
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setPageNumber(i);
			}
		}
	}

	function test() {
		let database = firebase.database();
		let ref = database.ref(`ml_data/exercises/-NHvbu4P8rYDnocx9wL8`);
		ref.set(null);
	}

	function configureInjuryParams() {

		let exerciseID = "-NHvbu4P8rYDnocx9wL8"
		let param: any = {
			"types": {
				"0": {
					"title": "Flared elbows",
					"location": {
						"title": "rotator cuff",
						"type": 0,
						"id": "0"
					}, 
					"severity": 3,
					"tissue": "muscles and tendons",
					"cause": "You are bench pressing with your elows flared outwards, which puts excessive force on the tissues inside and arround your shoulder joint.",
					"effect": "This can lead to chronic injury and severe pain if not corrected.",
					"correction": {
						"0": {
							"title": "Elbow Position",
							"description": "Bring your elbows inwards, closer to your abdomin.",
							"img_locations": [""],
							"vid_locations": [""]
						},
						"1": {
							"title": "Bend the Bar",
							"description": "Imagine that you are trying to bend the bar through the bench pressing motion. This will move tension off of your shoulders and focus it on your pecs.",
							"img_locations": [""],
							"vid_locations": [""]
						},
						"2": {
							"title": "Contract Scapula",
							"description": "When preparing to begin your set, use your traps to bring your scapula together as if you're doing a cable row. This will strengthen your upper body and keep exsessive tension off of your shoudlers.",
							"img_locations": [""],
							"vid_locations": [""]
						}
					},
					"stretches": [
						"-NHLaeyzed2lu3QRgMw4",
						"-NHLaez0FB18wfEnWQS9",
						"-NHLaez0FB18wfEnWQSA",
						"-NHLaez1GOYdawFZawLE"
					]
				},
				"1": {
					"title": "Excessive ROM",
					"location": {
						"title": "rotator cuff",
						"type": 0,
						"id": "0"
					}, 
					"severity": 2,
					"tissue": "muscles and tendons",
					"cause": "Your elbows are dropping too far below your shoulder plane, which puts excessive force on the tissues inside and arround your shoulder joint.",
					"effect": "This can lead to chronic injury and severe pain if not corrected.",
					"correction": {
						"0": {
							"title": "Elbow ROM",
							"description": "End your eccentric phase of the rep when your elbows are inline with your torso, or perpindicular to the floor.",
							"img_locations": [""],
							"vid_locations": [""]
						},
						"1": {
							"title": "Slight Back Arch",
							"description": "Setting up your bench press position with a slight arch in your back so that your chest is raised can help reduce over-extending your ROM.",
							"img_locations": [""],
							"vid_locations": [""]
						},
						"2": {
							"title": "Bend the Bar",
							"description": "Imagine that you are trying to bend the bar through the bench pressing motion. This will move tension off of your shoulders and focus it on your pecs.",
							"img_locations": [""],
							"vid_locations": [""]
						},
						"3": {
							"title": "Contract Scapula",
							"description": "When preparing to begin your set, use your traps to bring your scapula together as if you're doing a cable row. This will strengthen your upper body and keep exsessive tension off of your shoudlers.",
							"img_locations": [""],
							"vid_locations": [""]
						}
					},
					"stretches": [
						"-NHLaeyzed2lu3QRgMw4",
						"-NHLaez0FB18wfEnWQS9",
						"-NHLaez0FB18wfEnWQSA",
						"-NHLaez1GOYdawFZawLE"
					]
				}
			}
		}

		let database = firebase.database();
		let ref = database.ref(`injury/${exerciseID}`);
        ref.set(param);
	}

	function configureStretches() {

		let params: any[] = [
			{
				"title": "Wall Rotator Cuff Stretch",
				"steps": [
					"With your chest pointed towards the wall, extend one arm fully and place that hand on the wall at shoulder height.",
					"Push your shoulder against the wall.",
					"Slowly rotate your trunk away from the wall. You should feel a strech inside of your shoulder.",
					"Careful not to over extend, hold the stretch for some time.",
					"Slowly rotate your trunk back towards the wall, and repeat on the other side."	
				],
				"requirements": [
					"Flat strech of wall, at least 6ft. in length"
				],
				"locations": [
					{
						"title": "rotator cuff",
						"type": 0,
						"id": "1"
					},
					{
						"title": "pec",
						"type": 1,
						"id": "3_1"
					}, 
					{
						"title": "delt",
						"type": 1,
						"id": "4_0"
					}, 
				]
			},
			{
				"title": "Side-lying external rotation",
				"steps": [
					"Lie down on the side opposite your injured arm.",
					"Bend the elbow of your injured arm to 90 degrees and rest the elbow on your side. Your forearm should rest across your abdomen.",
					"Hold a light dumbbell in the injured side’s hand and, keeping your elbow against your side, slowly raise the dumbbell toward the ceiling. Stop rotating your arm if you feel strain.",
					"Hold the dumbbell up for a few seconds before returning to the start position with your arm down.",
					"Repeat 3 sets of 10 up to 3 times per day. Increase reps to 20 when a set of 10 becomes easy."
				],
				"requirements": [
					"1x light dumbbell (2-10LBS)"
				],
				"locations": [
					{
						"title": "rotator cuff",
						"type": 0,
						"id": "1"
					},
					{
						"title": "delt",
						"type": 1,
						"id": "4_0"
					}, 
				]
			},
			{
				"title": "High-to-low rows",
				"steps": [
					"Attach a resistance band to something sturdy at or above shoulder height. Be sure it is secure so it doesn’t come lose when you pull on it.",
					"Get down on one knee so the knee opposite your injured arm is raised. Your body and lowered knee should be aligned. Rest your other hand on your raised knee.",
					"Holding the band securely with your arm outstretched, pull your elbow toward your body. Keep your back straight and squeeze your shoulder blades together and down as you pull. Your body should not move or twist with your arm.",
					"Return to start and repeat 3 sets of 10"
				],
				"requirements": [
					"1x light resistance band"
				],
				"locations": [
					{
						"title": "rotator cuff",
						"type": 0,
						"id": "1"
					},
					{
						"title": "delt",
						"type": 1,
						"id": "4_2"
					}, 
				]
			},
			{
				"title": "Reverse fly",
				"steps": [
					"Stand with your feet shoulder-width apart and your knees slightly bent. Keep your back straight and bend forward slightly at the waist.",
					"With a light weight in each hand, extend your arms and raise them away from your body. Do not lock your elbow. Squeeze your shoulder blades together as you do so. Do not raise your arms above shoulder height.",
					"Return to start and repeat 3 sets of 10."
				],
				"requirements": [
					"2x light dumbbells (2-10LSB)"
				],
				"locations": [
					{
						"title": "rotator cuff",
						"type": 0,
						"id": "1"
					},
					{
						"title": "pec",
						"type": 1,
						"id": "3_1"
					}, 
					{
						"title": "delt",
						"type": 1,
						"id": "4_0"
					}, 
				]
			}
		]

		let database = firebase.database();

		for (var i = 0; i < params.length; i++) {
			let thisParam = params[i];

			let ref = database.ref(`stretches`);
	        ref.push(thisParam);
		}

	}

	function configureMultipleParams() {

		let paramObjs: any = {
			"-NHvbu4P8rYDnocx9wL8": {	
				"leading_concentric": false,
	            "analysis_vector": {"x": 0, "y": 1, "z": 0},
	            "analyze_vector": true,
	            "body_weight_by_default": false,
	            "weight_scaler": 1,
	            "lever": {
	                "segment_id": "lower_body",
	                "default_length": 0.4,
	            },
	            "counting": {
	                "type": "mirrored",                        		// Options: "alternating", "mirrored", "single_side"
	                "minimum_monitor_locations": [ 2, 3 ],          // Locations needed to count reps
	                "auxiliary_monitor_locations": [ 9, 0, 1 ]         // Additional locations if enough Nodes available
	            }
			},
		}

       

        let paramKeys = Object.keys(paramObjs);

        let database = firebase.database();

        for (var i = 0; i < paramKeys.length; i++) {
        	let thisParamKey: string = paramKeys[i];
        	let thisParam: any = paramObjs[thisParamKey];

        	// console.log(thisParamKey, thisParam);

	        //let ref = database.ref(`ml_data/params/${thisExerciseID}`);
	        let ref = database.ref(`exercises/${thisParamKey}/motion_info`);
	        ref.set(thisParam);
        }

        // let database = firebase.database();
        // //let ref = database.ref(`ml_data/params/${thisExerciseID}`);
        // let ref = database.ref(`exercises/${thisExerciseID}/motion_info`);
        // ref.set(params);
	}


	return (
		<div className="page-container">
			<div className="home-header">
				<PageHeader 
					pageName="Dashboard" 
					pageOptions={pageOptions}
					pageSelected={(e: string) => pageSelected(e)}
					openMLTrainCallback={(b: boolean) => pageSelected(b ? "ML Train" : "Training Dashboard")}
					workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
					addNewWorkout={() => props.addNewWorkout !== undefined ? props.addNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewClient={() => props.addNewClient !== undefined ? props.addNewClient() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
			</div>
			<div className="page-container-no-padding" hidden={pageNumber != 0}>
				<HomeMain
					profile={props.profile}
					startNewWorkout={() => props.startNewWorkout()}
					clientPressed={(e: string) => props.clientPressed(e)}
					workoutSelected={(e: Workout_t) => props.workoutSelected(e)}
					openPrograms={() => props.openPrograms()}
					orderNodes={() => props.orderNodes()}
					openSchedule={() => props.openSchedule()}
					inviteCoach={() => props.inviteCoach()}
					openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}
					addNewClient={() => props.addNewClient !== undefined ? props.addNewClient() : null}
					addNewWorkout={() => props.addNewWorkout !== undefined ? props.addNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}
					addNewAppointmentPressed={(e: any) => props.addNewAppointmentPressed(e)}/>
			</div>
			<div className="page-container-no-padding" hidden={pageNumber != 1}>
				<NodeStreamAnalyze
					mlCore={props.mlCore}/>
				{/*<NodeStreamAnalyzePhoneData/>*/}
				{/*{<NodeStreamVideoRecord/>}*/}
				{/*<NodeStreamAnalyzeMLData/>*/}
			</div>
			
			<div className="page-container-no-padding" hidden={pageNumber != 2}>
				<Notifications/>
			</div>
			<div className="page-container-no-padding" hidden={pageNumber !== 3}>
				<NodePlayground
					nodeManager={props.nodeManager}
					mlCore={props.mlCore}/>

			</div>
		</div>
	)

}

/*
<div onClick={() => props.startNewWorkout()} className="clients-cta-button">Start a Workout <PlayArrow style={{ fontSize: 14 }} className="add-icon"/></div>
*/

export default Home;