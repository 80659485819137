import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './IntraWorkoutSeriesListSetCell.css';
import './IntraWorkoutSeriesList.css';

import SetStats_t from '../../../Interfaces/SetStats_t';
import Exercise_t from '../../../Interfaces/Exercise_t';


import '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

function IntraWorkoutSeriesListSetCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isFocused_reps, setIsFocused_reps] = useState(false);
    const [isFocused_weight, setIsFocused_weight] = useState(false);
    const [savedInputWeightData, setSavedInputWeightData] = useState(0);

    const [thisWeight, setThisWeight] = useState(-1);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function changeWeight(toWeight: string) {
        setSavedInputWeightData(Number(toWeight));
    }

    function updateInputFocusedState(toState: boolean, forInput: 'rep' | 'weight') {
        switch (forInput) {
            case 'rep':
                setIsFocused_reps(toState);
                break;
            case 'weight':
                setIsFocused_weight(toState);
                if (!toState) {
                    // Weight was clicked out of -- update parent component
                    props.changeWeight(savedInputWeightData);
                }
                break;
            default:
                break;
        }
    }

    function getShowCurrentSetIndicator(seriesIndex: number, setIndex: number, exerciseIndex: number) {
        return seriesIndex === props.currentWorkout.seriesIndex && setIndex === props.currentWorkout.setIndex && exerciseIndex === props.currentWorkout.exerciseIndex;
    }

    function getHighlightExercise(seriesIndex: number, setIndex: number, exerciseIndex: number) {
        if (seriesIndex < props.currentWorkout.seriesIndex) {
            return true;
        } else if (seriesIndex === props.currentWorkout.seriesIndex) {
            if (setIndex < props.currentWorkout.setIndex) {
                return true;
            } else if (setIndex === props.currentWorkout.setIndex) { 
                if (exerciseIndex <= props.currentWorkout.exerciseIndex) {
                    return true;
                }
            } 
        }
        return false;
    }

    function getNumCompletedReps(seriesIndex: number, exerciseIndex: number, setIndex: number) {

        if (props.currentWorkout === undefined) { return -1; }

        if (seriesIndex < props.currentWorkout.setStats.length && 
            setIndex < props.currentWorkout.setStats[seriesIndex].length && 
            exerciseIndex < props.currentWorkout.setStats[seriesIndex][setIndex].length) {

            let thisSetStat: SetStats_t = props.currentWorkout.setStats[seriesIndex][setIndex][exerciseIndex];
            return thisSetStat.manualEntryReps <= 0 ? thisSetStat.anticipatedReps : thisSetStat.manualEntryReps;
                            
        }

        return -1
    }

    function getTargetReps(forExercise: Exercise_t, setIndex: number) {
        let repArray: number[] = forExercise.reps !== undefined ? forExercise.reps! : [0];
        if (setIndex < repArray.length) {
            return repArray[setIndex];
        } else {
            return repArray[repArray.length - 1];
        }
    }

    function getWeightForSet() {

        if (isFocused_weight) {
            return savedInputWeightData;
        }
        

        if (props.currentWorkout === undefined) { return -1; }

        if (props.seriesIndex < props.currentWorkout.setStats.length && 
            props.setIndex < props.currentWorkout.setStats[props.seriesIndex].length && 
            props.exerciseIndex < props.currentWorkout.setStats[props.seriesIndex][props.setIndex].length) {

            let thisSetStat: SetStats_t = props.currentWorkout.setStats[props.seriesIndex][props.setIndex][props.exerciseIndex];
            let weightRounded = Math.round(thisSetStat.weight * 10);
            return weightRounded / 10; //`${weightRounded / 10}${thisSetStat.weight % 1 === 0 ? '.0' : ''}`;
        }

        return -1
    }

	return (
		<div onClick={() => props.setWasSelected()} className={props.selectedSeriesIndex === props.seriesIndex && props.selectedExerciseIndex === props.exerciseIndex && props.selectedSetIndex === props.setIndex ? "intra-workout-series-list-card-set-breakdown-set-exercises-row intra-workout-series-list-card-set-breakdown-set-exercises-row-selected" : "intra-workout-series-list-card-set-breakdown-set-exercises-row"}>
            <div hidden={!getShowCurrentSetIndicator(props.seriesIndex, props.setIndex, props.exerciseIndex)} className="intra-workout-series-list-card-set-breakdown-current-set-indicator" />
            <div className={getShowCurrentSetIndicator(props.seriesIndex, props.setIndex, props.exerciseIndex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-current" : (getHighlightExercise(props.seriesIndex, props.setIndex, props.exerciseIndex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container" : "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-unselected")}>
                <div hidden={!getHighlightExercise(props.seriesIndex, props.setIndex, props.exerciseIndex)} className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-check">
                    <Check className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-check"/>
                </div>
                <div hidden={getHighlightExercise(props.seriesIndex, props.setIndex, props.exerciseIndex) && !getShowCurrentSetIndicator(props.seriesIndex, props.setIndex,props.exerciseIndex)} className={`intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot ${getShowCurrentSetIndicator(props.seriesIndex, props.setIndex, props.exerciseIndex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-current" : "intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-unselected"}`}/>
                <h4>{props.series.title}{props.exerciseIndex + 1}-{(props.setIndex + 1) < 10 ? '0' : ''}{props.setIndex + 1}</h4>
            </div>
            <div className={getHighlightExercise(props.seriesIndex, props.setIndex, props.exerciseIndex) ? "intra-workout-series-list-card-exercise-breakdown-input-outter-container intra-workout-series-list-card-exercise-breakdown-input-outter-container-reps" :  "intra-workout-series-list-card-exercise-breakdown-input-outter-container intra-workout-series-list-card-exercise-breakdown-input-outter-container-reps intra-workout-series-list-card-exercise-breakdown-input-outter-container-unselected"}>
                <div className={`intra-workout-series-list-card-exercise-breakdown-input-container ${isFocused_reps ? 'intra-workout-series-list-card-exercise-breakdown-input-container-focused' : ''}`}>
                    <IonInput
                        value={getNumCompletedReps(props.seriesIndex, props.exerciseIndex, props.setIndex) < 0 ? "" : `${getNumCompletedReps(props.seriesIndex, props.exerciseIndex, props.setIndex)}`}
                        className="intra-workout-series-list-card-exercise-breakdown-input"
                        placeholder={`${getTargetReps(props.exerciseIndex, props.setIndex)}`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={(e) => updateInputFocusedState(true, 'rep')}
                        onBlur={(e) => updateInputFocusedState(false, 'rep')}
                        onIonChange={e => props.changeReps(e.detail.value!)}/>
                </div>
                <div className="intra-workout-series-list-card-exercise-breakdown-input-icon-container">
                    <Close className="intra-workout-series-list-card-exercise-breakdown-input-icon"/>
                </div>
                <div className={`intra-workout-series-list-card-exercise-breakdown-input-container ${isFocused_weight ? 'intra-workout-series-list-card-exercise-breakdown-input-container-focused' : ''}`}>
                    <IonInput
                        value={getWeightForSet() < 0 ? "" : `${getWeightForSet()}`}
                        className="intra-workout-series-list-card-exercise-breakdown-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'weight')}
                        onBlur={() => updateInputFocusedState(false, 'weight')}
                        onIonChange={e => changeWeight(e.detail.value!)}/>
                    <p>{props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" : "LBS"}</p>
                </div>
                <div className="intra-workout-series-list-card-exercise-breakdown-select-icon-container">
                    <ArrowForwardIos className="intra-workout-series-list-card-exercise-breakdown-select-icon"/>
                </div>
            </div>
        </div>
	)
}

export default IntraWorkoutSeriesListSetCell;