import React, { useState, useEffect } from 'react';
import firebase from '../../../Firebase';
import './NewWorkout_Workout_Day.css';

import Workout_t from '../../../Interfaces/Workout_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';
import Muscles_t from '../../../Interfaces/Muscles_t';
import Tempo_t from '../../../Interfaces/Tempo_t';
import Plan_t from '../../../Interfaces/Plan_t';
import ScheduleDay_t from '../../../Interfaces/ScheduleDay_t';
import WorkoutLogBrief_t from '../../../Interfaces/WorkoutLogBrief_t';

import '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

function NewWorkout_Workout_Day(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    const [initSet, setInitSet] = useState(false);
    const [isLoadingWorkout, setIsLoadingWorkout] = useState(false);

    const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>({
                        title: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        duration: 0,
                        imageName: '',
                        targetGender: '',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0
                    });

    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [musclesString, setMusclesString] = useState('');
    const [durationString, setDurationString] = useState('');
    const [volumeString, setVolumeString] = useState('');

    const [phaseIndex, setPhaseIndex] = useState(0);
    const [cycleIndex, setCycleIndex] = useState(0);

    const [currentPlan, setCurrentPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
    const [currentPlanPathNames, setCurrentPlanPathNames] = useState("");
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.selectedScheduleDay !== undefined) {
            if (props.selectedScheduleDay.workout_id !== "") {
                pullWorkout(props.selectedScheduleDay.workout_id);
            }
            if (props.selectedScheduleDay.phase !== undefined) {
                setPhaseIndex(props.selectedScheduleDay.phase.index);
            }
            if (props.selectedScheduleDay.cycle !== undefined) {
                setCycleIndex(props.selectedScheduleDay.cycle.index);
            }
        }
        if (props.selectedTimestamp !== undefined) {
            setSelectedTimestamp(props.selectedTimestamp);
        }
        if (props.currentPlan !== undefined) {
            setCurrentPlan(props.currentPlan);
        }
        if (props.currentPlanPathNames !== undefined) {
            setCurrentPlanPathNames(props.currentPlanPathNames);
        }
    }

    useEffect(() => {
        if (props.selectedTimestamp !== undefined) {
            setSelectedTimestamp(props.selectedTimestamp);
        }
    }, [props.selectedTimestamp])

    useEffect(() => {
        if (props.selectedScheduleDay !== undefined ) {
            if (props.selectedScheduleDay.workout_id !== "") {
                pullWorkout(props.selectedScheduleDay.workout_id);
            }
            if (props.selectedScheduleDay.phase !== undefined) {
                setPhaseIndex(props.selectedScheduleDay.phase.index);
            }
            if (props.selectedScheduleDay.cycle !== undefined) {
                setCycleIndex(props.selectedScheduleDay.cycle.index);
            }
        }
    }, [props.selectedScheduleDay])

    useEffect(() => {
        if (props.currentPlan !== undefined) {
            setCurrentPlan(props.currentPlan);
        }
    }, [props.currentPlan])

    useEffect(() => {
        if (props.currentPlanPathNames !== undefined) {
            setCurrentPlanPathNames(props.currentPlanPathNames);
        }
    }, [props.currentPlanPathNames])


    function pullWorkout(workoutID: string) {

        setIsLoadingWorkout(true);

        let database = firebase.database();
        let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
        thisWorkoutRef.on('value', function(snapshotWorkout) {
            if (snapshotWorkout.exists() === true) {
                let thisWorkout = snapshotWorkout.val();

                parsePersonalWorkouts(thisWorkout, workoutID);
            } else {
                setIsLoadingWorkout(false);
            }
        });
    }

    async function parsePersonalWorkouts(thisWorkout: any, key: string) {

        let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

        // Configure Exercise Series
        var seriesList_temp: ExerciseSeries_t[] = [];
        let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
        if (containsExerciseSeries === true) {
            for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                let thisSeriesObj = thisWorkout.exercise_series[i];
                let thisSetLetter = getSetLetter(i);

                var exerciseList_temp: Exercise_t[] = [];
                if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                    for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                        let thisExerciseObj = thisSeriesObj.exercises[j];
                        let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                        exerciseList_temp.push(exTemp);
                    }
                }

                var seriesTemp: ExerciseSeries_t = {
                    title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                    index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                    type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                    exercises: exerciseList_temp
                };

                seriesList_temp.push(seriesTemp);
            }
        } else {
            // Workout was created "pre-series". Create a new placeholder series for each existing exercise
            if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    let thisExerciseTemp: any = thisWorkout.exercises[i];
                    let thisSetLetter = getSetLetter(i);
                    var seriesTemp: ExerciseSeries_t = {
                        title: thisSetLetter,
                        index: i,
                        type: 0,
                        exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                    };
                    seriesList_temp.push(seriesTemp);
                }
            }
        }

        var exerciseList: Exercise_t[] = [];
        if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

            var exerciseReps: any = {};
            for (var i = 0; i < thisWorkout.exercises.length; i++) {
                var a: number[] = [];
                if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                    for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                        let thisRep = thisWorkout.exercises[i].reps[j];
                        a.push(thisRep);
                        
                    }
                }
                exerciseReps[thisWorkout.exercises[i].key] = a;
            }

            var exerciseTempos: any = {};
            for (var i = 0; i < thisWorkout.exercises.length; i++) {
                var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                    for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                        let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                        let thisTempo: Tempo_t = {
                            concentric: thisTempoObj.concentric,
                            eccentric: thisTempoObj.eccentric,
                            first_pause: thisTempoObj.first_pause,
                            second_pause: thisTempoObj.second_pause
                        };
                        b.push(thisTempo);
                    }
                }
                exerciseTempos[thisWorkout.exercises[i].key] = b;
            }

            let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
            exerciseList = e;


            // push exercises into according slots in seriesList_temp
            if (exerciseList.length > 0) {
                for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                    let thisExercise: Exercise_t = exerciseList[ex_index];
                    let thisExercise_ID = thisExercise.id;

                    // Loop through seriesList_temp to find thisExercise's matching location
                    if (seriesList_temp.length > 0) {
                        for (var i = 0; i < seriesList_temp.length; i++) {
                            let thisSeries = seriesList_temp[i];
                            if (thisSeries.exercises.length > 0) {
                                for (var j = 0; j < thisSeries.exercises.length; j++) {
                                    let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                    let thisSeriesExercise_ID = thisSeriesExercise.id;
                                    if (thisSeriesExercise_ID === thisExercise_ID) {
                                        // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                        // for the case of multiple of the same exercises in a workout
                                        if (thisSeriesExercise.title === '') {
                                            seriesList_temp[i].exercises[j] = thisExercise;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
        let newWorkout: Workout_t = {
            title: thisWorkout.title,
            description: thisWorkout.description !== undefined ? thisWorkout.description: '',
            id: key,
            createdBy: createdBy,
            exercises: exerciseList,
            exerciseSeries: seriesList_temp,
            duration: Number(thisWorkout.duration),
            imageName: thisWorkout.image_name,
            targetGender: thisWorkout.target_gender,
            totalSets: Number(thisWorkout.total_sets),
            totalReps: Number(thisWorkout.total_reps),
            difficulty: Number(thisWorkout.difficulty),
            goals: thisWorkout.goals,
            downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
            timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
        };

        setSelectedWorkout(newWorkout);
        getMusclesString(newWorkout);
        getDuration(newWorkout);
        getVolumeString(newWorkout);

        setTimeout(() => {
            setIsLoadingWorkout(false);
        }, 100);
        
    }

    async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: [thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function getSelectedDateString() {
        let thisDate = new Date(selectedTimestamp);
        let thisMoIndex = thisDate.getMonth();
        let thisYear = thisDate.getFullYear();
        let thisDay = thisDate.getDate();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
    }

    function getIsToday() {
        if (selectedTimestamp <= 0) { return false; }
        let todayTS = Date.now();
        let newDate = new Date(todayTS);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        let selectedDate = new Date(selectedTimestamp);
        let selectedDateMonth = selectedDate.getMonth();
        let selectedDateYear = selectedDate.getFullYear();
        let selectedDateDay = selectedDate.getDate();

        return newDateDay === selectedDateDay && newDateMonth === selectedDateMonth && newDateYear === selectedDateYear;
    }

    function getMusclesString(forWorkout: Workout_t) {
        var muscleGroupTags_temp: string[] = [];
        let exercises = forWorkout.exercises;
        if (exercises === undefined) { 
            setMusclesString('Unspecified');
            return; 
        }
        for (var i = 0; i < exercises.length; i++) {
            let thisExercise: Exercise_t = exercises[i];

            let thisExerciseMuscles = thisExercise.muscles;
            //let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

            for (var j = 0; j < thisExerciseMuscles.length; j++) {
                let thisMuscle: Muscles_t = thisExerciseMuscles[j];
                let thisMuscleGroup = Number(thisMuscle.group);

                let thisMuscleString = muscle_groups[thisMuscleGroup];
                if (!muscleGroupTags_temp.includes(thisMuscleString)) {
                    muscleGroupTags_temp.push(thisMuscleString);
                }
            }
        }
        var string_temp = "";
        for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
            string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
        }

        setMusclesString(string_temp);
    }

    function getDuration(forWorkout: Workout_t) {

        let defaultTempo: Tempo_t = {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0};
        let defaultRest = 45;

        var totalTime = 0;

        if (forWorkout.exerciseSeries !== undefined) {
            for (var i = 0; i < forWorkout.exerciseSeries.length; i++) {
                let thisExerciseSeries: ExerciseSeries_t = forWorkout.exerciseSeries[i];
                if (thisExerciseSeries.exercises !== undefined) {
                    for (var j = 0; j < thisExerciseSeries.exercises.length; j++) {
                        let thisExercise: Exercise_t = thisExerciseSeries.exercises[j];

                        if (thisExercise.reps !== undefined) {
                            for (var k = 0; k < thisExercise.reps.length; k++) {
                                let thisRep = thisExercise.reps[k];
                                let thisTempo: Tempo_t = thisExercise.tempos !== undefined && thisExercise.tempos[k] !== undefined ? thisExercise.tempos[k] : defaultTempo;
                                let thisRest = thisExercise.restTime !== undefined && thisExercise.restTime[k] !== undefined ? thisExercise.restTime[k] : defaultRest;
                                thisRest = thisRest <= 0 ? defaultRest : thisRest;

                                let thisTimePerRep = thisTempo.concentric + thisTempo.eccentric + thisTempo.first_pause + thisTempo.second_pause;
                                thisTimePerRep = thisTimePerRep <= 0 ? 3 : thisTimePerRep;

                                let thisSetTime = (thisTimePerRep * thisRep) + thisRest;
                                totalTime += thisSetTime;
                            }
                        }
                    }
                }
            }
        }

        let totalTime_m = Math.floor(totalTime / 60);

        setDurationString(`${totalTime_m} minutes`)
    }

    function getVolumeString(forWorkout: Workout_t) {

        var numExercises = 0;
        var numSets = 0;

        if (forWorkout.exerciseSeries !== undefined) {
            for (var i = 0; i < forWorkout.exerciseSeries.length; i++) {
                let thisExerciseSeries: ExerciseSeries_t = forWorkout.exerciseSeries[i];
                if (thisExerciseSeries.exercises !== undefined) {
                    for (var j = 0; j < thisExerciseSeries.exercises.length; j++) {
                        let thisExercise: Exercise_t = thisExerciseSeries.exercises[j];
                        numExercises += 1;
                        if (thisExercise.reps !== undefined) {
                            numSets += thisExercise.reps.length
                        }
                    }
                }
            }
        }

        setVolumeString(`${numExercises} exercises, ${numSets} sets`);
    }

    function useWorkoutPressed() {
        
    }

	return (
		<div className="new-workout-workout-plan-container-day-panel">

            <div hidden={isLoadingWorkout} className="new-workout-workout-plan-container-day-panel-inner">
                <div className="new-workout-workout-plan-container-day-panel-header">
                    <h4>{getSelectedDateString()}</h4>
                    <h2>{selectedWorkout.title === '' ? 'Unknown Workout' : selectedWorkout.title}</h2>
                    <div hidden={!getIsToday()} className="new-workout-workout-plan-container-day-panel-day-floater">
                        <p>Today's Workout</p>
                    </div>
                </div>
                <div className="new-workout-workout-plan-container-day-panel-body">
                    <div className="new-workout-workout-plan-container-day-panel-body-section">
                        <div hidden={true} className="new-workout-workout-plan-container-day-panel-body-section-row">
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                <p>Containing Folder</p>
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                <h4>{currentPlanPathNames}<span>{currentPlan.title}</span></h4>
                            </div>
                        </div>
                        <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                <p>Cycle</p>
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                <h4>Cycle {cycleIndex + 1}, Phase {phaseIndex + 1}</h4>
                            </div>
                        </div>
                        <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                <p>Muscle Groups</p>
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                <h4>{musclesString}</h4>
                            </div>
                        </div>
                        <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                <p>Volume</p>
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                <h4>{volumeString}</h4>
                            </div>
                        </div>
                        <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                <p>Duration</p>
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                <h4>{durationString}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="new-workout-workout-plan-container-day-panel-body-section">
                        <div className="new-workout-workout-plan-container-day-panel-body-section-header">
                            <h4>Notes from previous workout</h4>
                            <p>None</p>
                        </div>
                        <div className="new-workout-workout-plan-container-day-panel-body-section-note">
                            <div className="new-workout-workout-plan-container-day-panel-body-section-note-empty-image-container">
                                <img className="new-workout-workout-plan-container-day-panel-body-section-note-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/no-notes.svg`}/>
                                <p>No Notes found</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="new-workout-workout-plan-container-day-panel-body-section-faded-curtain"/>
                <div onClick={() => props.workoutSelected(selectedWorkout)} className="new-workout-workout-plan-container-day-panel-button">
                    <p>Continue with this Workout</p>
                    <ArrowForwardIos className="new-workout-workout-plan-container-day-panel-button-icon"/>
                </div>
            </div>
            <div hidden={!isLoadingWorkout} className="new-workout-workout-plan-container-day-panel-inner new-workout-workout-plan-container-day-panel-inner-loading">
                <div className="client-list-container-loading-spinner"/>
                <p>Loading workout...</p>
            </div>
        </div>
	)
}

export default NewWorkout_Workout_Day;