import React, { useState, useEffect } from 'react';
import './ExerciseBreakdown.css';
import './common.css';

import PerformanceFilterCard from '../components/Cards/PerformanceFilterCard';
import ExerciseBreakdownCard from '../components/Cards/ExerciseBreakdownCard';

import PowerCard from '../components/Cards/stats/PowerCard';
import VelocityCard from '../components/Cards/stats/VelocityCard';
import ROMCard from '../components/Cards/stats/ROMCard';
import EccentricCard from '../components/Cards/stats/EccentricCard';



function Performance(props: any) {
    
    const [exerciseList, setExerciseList] = useState<any[]>([])
    const [selectedExercise, setSelectedExercise] = useState<any | undefined>(undefined);
    const [selectedSetIndex, setSelectedSetIndex] = useState(-1);
    const [totalVolume, setTotalVolume] = useState(0);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize()
    }, [initSet])

    useEffect(() => {
        generateExerciseList();
    }, [props.logData])
    

    const initialize = () => {
        generateExerciseList();
    }

    const generateExerciseList = () => {
        if (!props.logData || !props.logData.log || !props.logData.log.exercise_series) return;

        var exerciseListTemp: any[] = [];
        for (var i = 0; i < props.logData.log.exercise_series.length; i++) {
            let thisSeries = props.logData.log.exercise_series[i];
            if (thisSeries && thisSeries.exercises) {
                for (var j = 0; j < thisSeries.exercises.length; j++) {
                    let thisExercise = thisSeries.exercises[j];
                    if (thisExercise) {
                        exerciseListTemp.push(thisExercise);
                    }
                }
            }
        }
        // console.log("LE LIST:", exerciseListTemp)
        setExerciseList(exerciseListTemp);
        if (exerciseListTemp && exerciseListTemp.length > 0) {
            setSelectedExercise(exerciseListTemp[0]);
            setSelectedSetIndex(-1);
        }
    }


	return (
		<div className={ "performance-analysis-page" }>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                    <PerformanceFilterCard 
                        selectedExercise={ selectedExercise }
                        selectedSetIndex={ selectedSetIndex }
                        exerciseList={ exerciseList }
                        updateSelectedExercise={ (e: any) => setSelectedExercise(e) }
                        updateSelectedSetIndex={ (s: number) => setSelectedSetIndex(s) }/>
                </div>
               
            </div>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                        <ROMCard 
                            selectedExercise={ selectedExercise }
                            selectedSetIndex={ selectedSetIndex }
                            exerciseList={ exerciseList }/>
                </div>
            </div>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                        <EccentricCard 
                            selectedExercise={ selectedExercise }
                            selectedSetIndex={ selectedSetIndex }
                            exerciseList={ exerciseList }/>
                </div>
            </div>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                        <VelocityCard 
                            selectedExercise={ selectedExercise }
                            selectedSetIndex={ selectedSetIndex }
                            exerciseList={ exerciseList }/>
                </div>
            </div>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                        <PowerCard 
                            selectedExercise={ selectedExercise }
                            selectedSetIndex={ selectedSetIndex }
                            exerciseList={ exerciseList }/>
                </div>
            </div>

            
		</div>
	)
}

export default Performance;