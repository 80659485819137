
import React, { useState, useEffect } from 'react';
import firebase from '../../../../Firebase';
import Mixpanel from '../../../../Mixpanel';

import './WorkoutPlanListPlanWorkoutCard.css';

import '@material-ui/core';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import ArrowForward from '@material-ui/icons/ArrowForward';

import Workout_t from '../../../../Interfaces/Workout_t'
import CreatedBy_t from '../../../../Interfaces/CreatedBy_t'
import Exercise_t from '../../../../Interfaces/Exercise_t'
import ExerciseSeries_t from '../../../../Interfaces/ExerciseSeries_t'
import Folder_t from '../../../../Interfaces/Folder_t';
import Plan_t from '../../../../Interfaces/Plan_t';
import Tempo_t from '../../../../Interfaces/Tempo_t';

import '@material-ui/core';
import DragIndicator from '@material-ui/icons/DragIndicator';
import FileCopy from '@material-ui/icons/FileCopy';
import Edit from '@material-ui/icons/Edit';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import DeleteOutline from '@material-ui/icons/DeleteOutline';



function WorkoutPlanListPlanWorkoutCard(props: any) {
    let initWorkout: Workout_t = {
        title: '',
        id: '',
        createdBy: {name: '', id: ''},
        duration: 0,
        imageName: '',
        targetGender: '',
        totalSets: 0,
        totalReps: 0,
        difficulty: 0,
        exerciseSeries: [],
        exercises: []
    };

    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [volume, setVolume] = useState(0);
    const [volume_sets, setVolume_sets] = useState(0);
    const [durationString, setDurationString] = useState('00 minutes');
    const [seriesCount, setSeriesCount] = useState(0);
    const [musclesDiv, setMusclesDiv] = useState(<div/>);

    const [isDragging, setIsDragging] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        configureCard();
    }

    useEffect(() => {
        configureCard();
    }, [props.workout])

    function configureCard() {
    	setWorkout(props.workout ?? initWorkout);
    	if (props.workout && props.workout.exerciseSeries) {
    		var vol_sets_temp = 0;
    		var vol_reps_temp = 0;
    		var durationSeconds_temp = 0;
    		var muscleGroups: string[] = [];

			if (props.workout && props.workout.exercises) {
				for (var j = 0; j < props.workout.exercises.length; j++) {
					let thisExercise: Exercise_t = props.workout.exercises[j];
					let repArr = thisExercise.reps ?? [];

					let usingCoachRestTime = thisExercise.restTime !== undefined && thisExercise.restTime.length > 0;

					for (var k = 0; k < repArr.length; k++) {
						let thisRep = repArr[k];
						thisRep = isNaN(thisRep) || thisRep < 0 ? 0 : thisRep;
						vol_reps_temp += thisRep;
						durationSeconds_temp += 3 * thisRep;
						vol_sets_temp += 1;
						if (usingCoachRestTime && thisExercise.restTime && thisExercise.restTime[k] !== undefined && thisExercise.restTime[k] > 0) {
							durationSeconds_temp += thisExercise.restTime[k]
						} else {
							durationSeconds_temp += 60;
						}
					}
					durationSeconds_temp += 120;

					if (thisExercise && thisExercise.muscles) {
						let muscleList = thisExercise.muscles;

						for (var m = 0; m < muscleList.length; m++) {
							let thisMuscle = muscleList[m];
							let thisMuscleGroup = thisMuscle.group;
							let thisMuscleGroupString = muscle_groups[thisMuscleGroup];
							if (!muscleGroups.includes(thisMuscleGroupString)) muscleGroups.push(thisMuscleGroupString);
						}
					}
				}
			}

    		let durationMins = Math.round(durationSeconds_temp / 60);
    		setDurationString(`${durationMins} minute${ durationMins === 1 ? '' : 's'}`)

    		setVolume_sets(vol_sets_temp);
    		setVolume(vol_reps_temp);
    		setSeriesCount(props.workout.exerciseSeries.length);
    		setIsLoading(false);


    		var musclesDivTemp = [];
    		for (var i = 0; i < muscleGroups.length; i++) {
    			let thisM = muscleGroups[i];
    			if (musclesDivTemp.length < 3) {
    				musclesDivTemp.push(
		    			<div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill workout-plan-list-plan-workout-card-content-cell-content-row-muscle-pill-${ thisM }` }>
		    				<p>{ thisM }</p>
		    			</div>
		    		);
    			}
    		}

    		setMusclesDiv(<div className={ `workout-plan-list-plan-workout-card-content-cell-content-row-muscle-container` }>{musclesDivTemp}</div>)

    	}
    }


    async function getWorkoutInfo(workoutID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();

            let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
            thisWorkoutRef.on('value', function(snapshotWorkout) {
                if (snapshotWorkout.exists() === true) {
                    let thisWorkout = snapshotWorkout.val();
                    resolve(thisWorkout);
                }
            });
        });
    }


    function editPressed() {
        if (props.editWorkoutPressed) props.editWorkoutPressed();
    }

    function copyPressed() {
        props.copyPressed()
    }

    function deletePressed() {
        props.deletePressed()
    }

    function updateDraggingState(toState: boolean) {
        setIsDragging(toState);
        if (!toState) {
            props.cellDropped();
        } else {
            props.cellPicked();
        }
    }

    function updateDraggingPosition(x: number, y: number) {
        props.dragPosUpdated(x, y);
    }

    function buildWorkoutPressed() {
    	if (props.buildWorkoutPressed) props.buildWorkoutPressed();
    }

	return (
		<div 
            draggable={props.allowDrag === undefined ? true : props.allowDrag} 
            onDrag={(e: any) => updateDraggingPosition(e.clientX, e.clientY)}
            onDrop={(e: any) => console.log("onDrop", e)}
            onDragStart={(e: any) => updateDraggingState(true)}
            onDragStartCapture={(e: any) => console.log("onDragStartCapture", e)}
            onDragEnd={(e: any) => updateDraggingState(false)}
            onDragEndCapture={(e: any) => console.log(`onDragEndCapture: (x:${e.clientX}, y: ${e.clientY})`)}
            onClick={() => null} 
            className={`workout-plan-list-plan-workout-card-content-cell 
                        ${isDragging ? 'workout-plan-list-plan-workout-card-content-cell-dragging' : ''}
                        ${props.index === props.maxIndex ? 'workout-plan-list-plan-workout-card-content-cell-no-boarder' : ''}
                        ${ props.purposedIndex !== undefined && props.purposedIndex === props.index && !isDragging ? 'workout-plan-list-plan-workout-card-content-cell-purposed' : '' }`}>
            
            <div className="workout-plan-list-plan-workout-card-content-cell-header">
                <div className={ `workout-plan-list-plan-workout-card-content-cell-header-dot ${props.workout.id !== undefined && props.workout.id !== "" ? "" : "workout-plan-list-plan-workout-card-content-cell-header-dot-dark" }` }></div>
                <div className="workout-plan-list-plan-workout-card-content-cell-header-icon-wrapper">
                    <div className={ `workout-plan-list-plan-workout-card-content-cell-header-icon-container ${ props.workout.id !== undefined && props.workout.id !== "" ? "" : "workout-plan-list-plan-workout-card-content-cell-header-icon-container-dark" }` }>
                        <div className={ `workout-plan-list-plan-workout-card-content-cell-header-icon ${ props.workout.id !== undefined && props.workout.id !== "" ? "" : "workout-plan-list-plan-workout-card-content-cell-header-icon-dark" }` }></div>
                    </div>
                </div>
            </div>
            <div hidden={props.workout.id !== undefined && props.workout.id !== ""} className="workout-plan-list-plan-workout-card-content-cell-content">
                <div className={ "workout-plan-list-plan-workout-card-content-cell-content-button-container" }>
                    
                    {
                        props.copiedWorkout !== undefined ?
                        <div className="workout-plan-list-plan-workout-card-content-cell-content-no-plan-add-button workout-plan-list-plan-workout-card-content-cell-content-no-plan-add-button-paste" onClick={ () => props.pasteWorkout() }>
                            <p>Paste <span>{ !props.copiedWorkout || props.copiedWorkout.title === "" ? "Unnamed Workout" : props.copiedWorkout.title }</span> here</p>
                            <ArrowForward className="workout-plan-list-plan-workout-card-content-cell-content-no-plan-add-icon"/>
                        </div>
                        :
                        <div className="workout-plan-list-plan-workout-card-content-cell-content-no-plan-add-button" onClick={ () => buildWorkoutPressed() }>
                            <p>Build this Workout</p>
                            <ArrowForward className="workout-plan-list-plan-workout-card-content-cell-content-no-plan-add-icon"/>
                        </div>
                    }
                </div>
                
                <div className="workout-plan-list-plan-workout-card-content-cell-content-inner">
                    <div className="workout-plan-list-plan-workout-card-content-cell-header-text-container workout-plan-list-plan-workout-card-content-cell-header-text-container-no-plan">
                        <div hidden={props.allowDrag !== undefined && props.allowDrag === false} className="workout-plan-list-plan-workout-card-content-cell-header-drag-handle-container">
                            <DragIndicator className="workout-plan-list-plan-workout-card-content-cell-header-drag-handle-icon"/>
                        </div>
                        <p>Day { `${props.index + 1 < 10 ? '0' : ''}${props.index + 1}` }</p>
                        <h4>{ props.workout.title }</h4>
                   </div> 
                   <div className={ "workout-plan-list-plan-workout-card-content-cell-header-body-container" }>
                        <div className={ "workout-plan-list-plan-workout-card-content-cell-header-body-container-overlay-wrapper" }>
                            <div className={ "workout-plan-list-plan-workout-card-content-cell-header-body-container-overlay" }/>
                        </div>
                        <div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                            <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title-a"/>
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content-a"/>
                            </div>
                             <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title-b"/>
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content-b"/>
                            </div>
                        </div>
                        <div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                            <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title-c"/>
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content-c"/>
                            </div>
                             <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-title-d"/>
                                 <div className="workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content workout-plan-list-plan-workout-card-content-cell-content-row-placeholder-content-d"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={!(props.workout.id !== undefined && props.workout.id !== "")} className="workout-plan-list-plan-workout-card-content-cell-content">
                <div hidden={!isLoading} className="workout-plan-list-plan-workout-card-content-cell-content-inner workout-plan-list-plan-workout-card-content-cell-content-inner-loading">
                    <p>Loading workout details...</p>
                </div>
                <div hidden={isLoading} className="workout-plan-list-plan-workout-card-content-cell-content-inner">
                    <div className={ "workout-plan-list-plan-workout-card-content-header-buttons-container" }>
                        <div className={ "workout-plan-list-plan-workout-card-content-header-button workout-plan-list-plan-workout-card-content-header-button-delete" } onClick={ () => deletePressed() }>
                            <DeleteOutline className={ "workout-plan-list-plan-workout-card-content-header-button-icon" }/>
                        </div>
                        <div className={ "workout-plan-list-plan-workout-card-content-header-button" } onClick={ () => copyPressed() }>
                            <FileCopy className={ "workout-plan-list-plan-workout-card-content-header-button-icon" }/>
                        </div>
                        <div className={ "workout-plan-list-plan-workout-card-content-header-button" } onClick={ () => editPressed() }>
                            <Edit className={ "workout-plan-list-plan-workout-card-content-header-button-icon" }/>
                        </div>
                    </div>
                    <div className="workout-plan-list-plan-workout-card-content-cell-header-text-container">
                        <div hidden={props.allowDrag !== undefined && props.allowDrag === false} className="workout-plan-list-plan-workout-card-content-cell-header-drag-handle-container">
                            <DragIndicator className="workout-plan-list-plan-workout-card-content-cell-header-drag-handle-icon"/>
                        </div>
                        <p>Day { `${props.index + 1 < 10 ? '0' : ''}${props.index + 1}` }</p>
                        <h4>{ props.workout.title }</h4>
                    </div>
                    <div className={ "workout-plan-list-plan-workout-card-content-cell-header-body-container" }>
                        <div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                            <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <div className="workout-plan-list-plan-workout-card-content-cell-content-rep-scheme-container">
                                    <span>Volume </span>
                                    <h4>{ volume_sets } set{ volume_sets === 1 ? '' : 's' }, { volume } rep{ volume === 1 ? '' : 's' }</h4>
                                </div>
                            </div>
                             <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <span>Muscles</span>
                                <h4>{ musclesDiv }</h4>
                            </div>
                        </div>
                        <div className={ "workout-plan-list-plan-workout-card-content-cell-content-col" }>
                            <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <div className="workout-plan-list-plan-workout-card-content-cell-content-rep-scheme-container">
                                    <span>Content</span>
                                    <h4>{ seriesCount } Series</h4>
                                </div>
                            </div>
                             <div className="workout-plan-list-plan-workout-card-content-cell-content-row">
                                <span>Target Duration</span>
                                <h4>{ durationString }</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}


export default WorkoutPlanListPlanWorkoutCard;