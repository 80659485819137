import React, { useState } from 'react';

import Client_t from '../../Interfaces/Client_t';
import Message_t from '../../Interfaces/Message_t';

import './ClientProfileConversations.css';
import './ClientProfileCommon.css'

function ClientProfileConversations(props: any) {

	const [messageList, setMessageList] = useState<Message_t[]>([]);

	return (
		<div className="client-profile-page-container">
			<div className="client-profile-page-blank" hidden={messageList.length != 0}>
				<h2>Conversations Log Empty</h2>
				<p>It looks like there aren't any conversations between you and {props.client !== undefined ? props.client.first_name : ""} yet.</p>
			</div>
		</div>
	)
}

export default ClientProfileConversations;