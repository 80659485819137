import React, {useState, useEffect} from 'react';
import firebase from '../../Firebase';

import './NewClientModal.css';
import './LogMeasurementModal.css';

import Client_t from '../../Interfaces/Client_t';
import BodyMeasurement_t from '../../Interfaces/BodyMeasurement_t';

import BodyMeasurementChart from '../Clients/BodyMeasurementChart';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function LogMeasurementModal(props: any) {

    let initClient: Client_t = {first_name:'Jimbo',last_name:'',full_name:'',id:'', isMetric: true, gender: 'f', birthdate: '00-00-0000', height: 0, weight: 0};

    const [initSet, setInitSet] = useState(false);

    const [changeMade, setChangeMade] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [client, setClient] = useState<Client_t>(initClient);
    const [bodyMeasurement, setBodyMeasurement] = useState<BodyMeasurement_t>({});
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.client !== undefined) {
            setClient(props.client);
        }
    }, [props.client])

    function initialize() {
        
    }

    function getWorkflowClassName(state: number) {
        return workflowState === state ? "new-client-selected-workflow-state" : (state < workflowState ? "new-client-completed-workflow-state" : "new-client-unselected-workflow-state");
    }

    function addMeasurementToDB() {
        let userID = client.id;
        // console.log(`Setting goal log in DB for user: ${userID}`);
        if (userID === undefined) { closeModal(); }

        let currentUser = firebase.auth().currentUser;
		if (!currentUser) { closeModal(); return; }
		let trainerUID = currentUser.uid;

		let t = Date.now();

        let bodyMeasurementLogObj = {
			timestamp: t,
			logged_by: trainerUID,
			isKG: client.isMetric,
			body_weight: bodyMeasurement.bodyWeight !== undefined ? bodyMeasurement.bodyWeight : -1,
			body_fat_percentage: bodyMeasurement.bodyFatPercentage !== undefined ? bodyMeasurement.bodyFatPercentage : -1,
			lean_mass: bodyMeasurement.leanMass !== undefined ? bodyMeasurement.leanMass : -1,
			height: bodyMeasurement.height !== undefined ? bodyMeasurement.height : -1,
			torso_length: bodyMeasurement.torsoLength !== undefined ? bodyMeasurement.torsoLength : -1,
			leg_length: bodyMeasurement.legLength !== undefined ? bodyMeasurement.legLength : -1,
			reach_length: bodyMeasurement.reachLength !== undefined ? bodyMeasurement.reachLength : -1,

			neck: bodyMeasurement.neck !== undefined ? bodyMeasurement.neck : -1,
			shoulders: bodyMeasurement.shoulders !== undefined ? bodyMeasurement.shoulders : -1,
			chest: bodyMeasurement.chest !== undefined ? bodyMeasurement.chest : -1,
			arm_L: bodyMeasurement.arm_L !== undefined ? bodyMeasurement.arm_L : -1,
			arm_R: bodyMeasurement.arm_R !== undefined ? bodyMeasurement.arm_R : -1,
			forearm_L: bodyMeasurement.forearm_L !== undefined ? bodyMeasurement.forearm_L : -1,
			forearm_R: bodyMeasurement.forearm_R !== undefined ? bodyMeasurement.forearm_R : -1,
			waistAtMinimum: bodyMeasurement.waistAtMinimum !== undefined ? bodyMeasurement.waistAtMinimum : -1,
			waistAtBellyButton: bodyMeasurement.waistAtBellyButton !== undefined ? bodyMeasurement.waistAtBellyButton : -1,
			glutes: bodyMeasurement.glutes !== undefined ? bodyMeasurement.glutes : -1,
			hips: bodyMeasurement.hips !== undefined ? bodyMeasurement.hips : -1,
			thighAtHamTie_L: bodyMeasurement.thighAtHamTie_L !== undefined ? bodyMeasurement.thighAtHamTie_L : -1,
			thighAtHamTie_R: bodyMeasurement.thighAtHamTie_R !== undefined ? bodyMeasurement.thighAtHamTie_R : -1,
			thighMid_L: bodyMeasurement.thighMid_L !== undefined ? bodyMeasurement.thighMid_L : -1,
			thighMid_R: bodyMeasurement.thighMid_R !== undefined ? bodyMeasurement.thighMid_R : -1,
			calf_L: bodyMeasurement.calf_L !== undefined ? bodyMeasurement.calf_L : -1,
			calf_R: bodyMeasurement.calf_R !== undefined ? bodyMeasurement.calf_R : -1,
		}

        let database = firebase.database();
		let ref = database.ref(`body_measurements/${userID}/logs`); // Change to database path
		ref.once('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        let data: any = snapshot.val();
		        
		        let dataKeys = Object.keys(data);
		        if (dataKeys.length > 0) {
		        	// data already exists - add to array
		        	let lastKey = dataKeys[dataKeys.length - 1];
		        	let nextKey = Number(lastKey) + 1;
		        	ref.child(String(nextKey)).set(bodyMeasurementLogObj);
		        } else {
		        	ref.set([bodyMeasurementLogObj]);
		        }
		        
		    } else {
		    	ref.set([bodyMeasurementLogObj]);
		    	
		    }

		    setTimeout(() => {
	            closeModal()
	        }, 1200);
		});
    }

    function continuePressed() {

        if (workflowState === 2) {
            props.closeModal();
            return;
        }

        if (workflowState === 0) {
            addMeasurementToDB();
        }

        setWorkflowState(workflowState + 1);
        
    }

    function inputChanged(toString: any, fromInput: string) {
        // console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)
        switch (fromInput) {
            
            default:
                // console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
                break;
        }
    }

    function updateBodyMeasurement(m: BodyMeasurement_t) {
    	setChangeMade(true);
    	setBodyMeasurement(m);
    }


    function backPressed() {
        setWorkflowState(workflowState - 1);
    }

    function closeModal() {
        props.closeModal();
    }

	return (
		<div className="new-client-container log-measurement-modal-container">
            <div className="log-measurement-modal-header-container">
            	<div className="log-measurement-modal-header-close-button-container">
					<div onClick={() => closeModal()} className="log-measurement-modal-header-close-button">
						<Close className="log-measurement-modal-header-close-button-close-icon"/>
					</div>
				</div>
                <h1>Log Measurement for {client.first_name !== "" ? client.first_name : "your Client"}</h1>
                <div onClick={() => continuePressed()} hidden={workflowState !== 0} className={`log-measurement-modal-cta-button ${changeMade ? 'log-measurement-modal-cta-button-unselected' : ''}`}>Log Measurements</div>

            </div>
            <div className="log-measurement-modal-content-container">
            	
    			<div hidden={workflowState !== 0}>
    				<div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="log-measurement-modal-chart-container">
                    	<BodyMeasurementChart 
							isMetric={client.isMetric}
							heightError={false}
							weightError={false}
							measurementUpdated={(e: BodyMeasurement_t) => updateBodyMeasurement(e)}/>
                    </div>
                    {/*<div className="new-workout-next-button-container new-workout-next-button-container-page-0">
						<div onClick={() => closeModal()} className="modal-back-button">Back</div>
						<div onClick={() => null} hidden={changeMade} className="modal-cta-button-unselected">Log Measurements</div>
						<div onClick={() => continuePressed()} hidden={!changeMade} className="modal-cta-button">Log Measurements</div>
					</div>*/}
                </div>
                <div className="log-measurement-modal-loading-container" hidden={workflowState !== 1}>
                	<div className="workout-plan-content-loading-spinner"/>
                    <p>Logging measurement...</p>
                </div>
                <div className="new-client-centered-container" hidden={workflowState !== 2}>
                    <h2>New measurement was recorded!</h2>
                    <p>You're doing a great jon helping {client.first_name} stay on track by logging another body measurement update.</p>
                    <div className="new-workout-next-button-container log-measurement-modal-done-button">
	                    <div onClick={() => continuePressed()} className="modal-cta-button">Finish Logging Measurement</div>
	                </div>
                </div>
            </div>
		</div>
	)
}

export default LogMeasurementModal;