import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Modal.css';

import ClientModal from './Modals/ClientModal';
import NewClientModal from './Modals/NewClientModal';
import NewWorkoutModal from './Modals/NewWorkoutModal';
import NewExerciseModal from './Modals/NewExerciseModal';
import NewNoteModal from './Modals/NewNoteModal';
import NewPlanModal from './Modals/NewPlanModal';
import NewGoalModal from './Modals/NewGoalModal';
import LogMeasurementModal from './Modals/LogMeasurementModal';
import AssignPlanToClientModal from './Modals/AssignPlanToClientModal';

import CreateWorkoutExerciseConfigure from './Modals/CreateWorkoutExerciseConfigure';
import CreatePlanWorkoutConfigure from './Plans/PlanBuilder/CreatePlanWorkoutConfigure';
import Exercise_t from '../Interfaces/Exercise_t';
import Workout_t from '../Interfaces/Workout_t';
import Plan_t from '../Interfaces/Plan_t';
import BodyMeasurement_t from '../Interfaces/BodyMeasurement_t';
import Client_t from '../Interfaces/Client_t';

function Modal(props: any) {

	let initFolder = {
	        title: "",
	        id: "",
	        path: "",
	        children: [],
	        isPlan: false,
	    };

	let initPlan: Plan_t = {title: "", description: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []};

	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};
	let initClient: Client_t = {first_name:'',last_name:'',full_name:'',id:'', isMetric: true, gender: 'f', birthdate: '00-00-0000', height: 0, weight: 0};

	const [modalSet, setModalSet] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalContentIndex, setModalContentIndex] = useState(0);

	const [smallModal, setSmallModal] = useState(false);

	const [selectedExercise, setSelectedExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[]});
	const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>(initWorkout);
	const [exerciseToEdit, setExerciseToEdit] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[]});
	const [editingWorkout, setEditingWorkout] = useState<Workout_t>(initWorkout);

	const [restDayInformation, setRestDayInformation] = useState<any>({});

	const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
	const [selectedTimestamp, setSelectedTimestamp] = useState<number>(0);

	const [preselectedWorkouts, setPreselectedWorkouts] = useState<Workout_t[]>([]);

	const [planToEdit, setPlanToEdit] = useState<Plan_t>(initPlan);

	const [onTheFlyWorkoutAdding, setOnTheFlyWorkoutAdding] = useState(false);
	const [onTheFlyExerciseAdding, setOnTheFlyExerciseAdding] = useState(false);
	const [defaultTitleString, setDefaultTitleString] = useState("")

	const [completedCallback, setCompletedCallback] = useState<any>(undefined);

	var newClientModalElement;

	if (modalSet === false) {
		setContent();
		setModalSet(true);
	}

	function setContent() {
		// console.log("MODAL | props.information:", props.information);
		// if (props.information !== undefined && props.information.completedCallback !== undefined) {
		// 	// console.log("--- XXXXXXXXXXXXXXX Will set callback to info: ", props.information.completedCallback);
		// 	setCompletedCallback(props.information.completedCallback);
		// }

		if (props.information !== undefined && props.information.selectedClient !== undefined) {
			setSelectedClient(props.information.selectedClient);
		}

		if (props.information !== undefined && props.information.selectedTimestamp !== undefined) {
			setSelectedTimestamp(props.information.selectedTimestamp);
		}		

		if (props.information !== undefined && props.information.preselected_workouts !== undefined) {
			setPreselectedWorkouts(props.information.preselected_workouts);
		}	

		if (props.information !== undefined && props.information.rest_day_index !== undefined) {
			setRestDayInformation(props.information);
		}


		if (props.information !== undefined && 
			props.information.selectedClient !== undefined &&
			props.information.selectedTimestamp !== undefined &&
			props.information.editingWorkout !== undefined) {
			setEditingWorkout(props.information.editingWorkout);
		}

		switch (props.reason) {
			case 'client-profile':
				setModalContentIndex(1);
				break;
			case 'new-client':
				setModalContentIndex(2);
				break;
			case 'new-workout':
				setModalContentIndex(3);
				break;
			case 'new-note':
				setModalContentIndex(5);
				break;
			case 'new-plan':
				setModalContentIndex(6);
				break;
			case 'edit-plan':
				setModalContentIndex(6);
				break;
			case 'assign-plan':
				setModalContentIndex(8);
				break;
			case 'new-goal':
				setModalContentIndex(9);
				break;
			case 'new-exercise':
				setModalContentIndex(10);
				break;
			case 'log-measurement':
				setModalContentIndex(11);
				break;
			default:
				break;
		}

		if (props.reason === 'new-client') {
			setSmallModal(true);
		} else {
			setSmallModal(false);
		}
	}



	useEffect(() => {
		// console.log("XXXXXXXXXXXXXXX Modal info: ", props.information);
		if (props.information !== undefined && props.information.plan_created_callback !== undefined) {
			// console.log("--- XXXXXXXXXXXXXXX Will set callback to info: ", props.information.plan_created_callback);
			setCompletedCallback(props.information.plan_created_callback);
		}


	}, [props.information])

/*
	

	useEffect(() => {
		// console.log("ZZZZZZZZZZZZZZZZZ completedCallback changed to: ", completedCallback, props.information.completedCallback);
		setCompletedCallback(props.information.completedCallback);
	}, [completedCallback])
*/
	function closeModal() {
		setDefaultTitleString("");
		props.hideModalPressed();
	}

	function addAnotherExercise() {
		setExerciseToEdit({title:'',id:'',type: 0,muscles:[]});
		setSelectedExercise({title:'',id:'',type: 0,muscles:[]});
		setModalContentIndex(4);
	}

	function addAnotherWorkout() {

		setModalContentIndex(7);
	}

	function editExercise(exercise: Exercise_t) {
		setExerciseToEdit(exercise);
		setModalContentIndex(4);
	}

	function backToExerciseList(exercise?: Exercise_t) {
		//// console.log(`Back to exercise list with: ${exercise !== null ? exercise!.title : 'nada'}`)
		if (exercise !== null && exercise !== undefined) {
			// console.log('CHAINGIN')
			setSelectedExercise(exercise!);
		}
		setModalContentIndex(3);	// Go back to newWorkoutModal
		setExerciseToEdit({title:'',id:'',type: 0,muscles:[]});
	}

	function backToWorkoutList(workout?: Workout_t) {
		//// console.log(`Back to workout list with: ${workout !== null ? workout!.title : 'nada'}`)
		if (workout !== null && workout !== undefined) {
			// console.log('CHAINGIN')
			setSelectedWorkout(workout!);
		}
		setModalContentIndex(3);	// Go back to newPlanModal
	}

	function createNewWorkoutFromPlan() {
		// Call from new plan modal when adding a new workout on the fly.
		// Should resume 
		setModalContentIndex(3);
		setOnTheFlyWorkoutAdding(true);
	}

	function backToPlan(workout?: Workout_t) {
		setModalContentIndex(6);
		setOnTheFlyWorkoutAdding(false);
		if (workout !== null && workout !== undefined) {
			// // console.log('CHAINGIN')
			addWorkoutToPlan(workout);
		}
	}

	function addWorkoutToPlan(workout: Workout_t) {
		setSelectedWorkout(workout!);
	}

	function createNewExerciseFromWorkout(searchString: string) {
		// Call from new workout modal when adding a new exercise on the fly.
		// Should resume 
		setModalContentIndex(10);
		setOnTheFlyExerciseAdding(true);
		setDefaultTitleString(searchString);
	}

	function backToWorkout(exercise?: Exercise_t) {
		setModalContentIndex(3);
		setOnTheFlyExerciseAdding(false);
		setDefaultTitleString("");
		if (exercise !== null && exercise !== undefined) {
			
			setExerciseToEdit(exercise);
		}	
	}

	function getShowDepth1() {
		if (onTheFlyWorkoutAdding === true) {
			return modalContentIndex === 3 || modalContentIndex === 4 || modalContentIndex === 10;
		} else if (onTheFlyExerciseAdding === true) {
			return modalContentIndex === 10;
		}

		return false;
	}

	function getShowDepth2() {
		if (onTheFlyWorkoutAdding === true && onTheFlyExerciseAdding === true) {
			return modalContentIndex === 10;
		}

		return false;
	}

	function depth2Pressed() {
		backToWorkout();
		backToPlan();
	}

	function editWorkout(workout: Workout_t) {
		setOnTheFlyWorkoutAdding(true);
		setEditingWorkout(workout);
		setModalContentIndex(3);
	}

	function addNewExerciseToWorkoutModalPressed(e: any) {

		if (props.information !== undefined) {
			setCompletedCallback(props.information.completedCallback)
		} 

		props.addNewExerciseToWorkoutModalPressed(e);
	}

	function fireCallback(withStuff: any) {
		// console.log("fireCallback: ", withStuff, completedCallback);
		if (completedCallback !== undefined) {
			completedCallback(withStuff);
		}
	}

	return (
		<div className="modal-background">

			<div className={`modal-container 
							${smallModal ? 'modal-container-small' : ''}
							${(onTheFlyExerciseAdding === true && onTheFlyWorkoutAdding === false) || (onTheFlyExerciseAdding === false && onTheFlyWorkoutAdding === true) ? 'modal-container-overlay-1' : ((onTheFlyExerciseAdding === true && onTheFlyWorkoutAdding === true) ? 'modal-container-overlay-2' : '')}`}>
				<div className="modal-content-container">
					
				<div className="modal-content-container" hidden={modalContentIndex != 2}>
						<NewClientModal 
							closeModal={() => closeModal()} 
							trainerInfo={props.profile}
							getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
							updateModalSize={(isSmall: boolean) => setSmallModal(isSmall)}/>
					</div>
					<CSSTransition in={modalContentIndex === 3} timeout={200} classNames="slide-right" unmountOnExit>
					<div className="modal-content-container">
						<NewWorkoutModal 
							selectedExercise={selectedExercise}
							exerciseToEdit={exerciseToEdit}
							editWorkout={editingWorkout}
							onTheFlyWorkoutAdding={onTheFlyWorkoutAdding}
							rest_day_information={restDayInformation}
							showCreateMore={(props.information !== undefined && props.information.showCreateMore === true) || props.information === undefined || props.information.showCreateMore === undefined}
							addToPlan={props.information !== undefined && props.information.addToPlan !== undefined ? props.information.addToPlan : initPlan}
							addToPlanLocation={props.information !== undefined && props.information.dayIndex !== undefined ? props.information.dayIndex : -1}
							closeModal={(e?: Workout_t) => onTheFlyWorkoutAdding ? backToPlan(e) : closeModal()}
							addWorkoutToPlan={(e: Workout_t) => addWorkoutToPlan(e)}
							addAnotherExercise={() => addAnotherExercise()}
							createNewExerciseFromWorkout={(s: string) => createNewExerciseFromWorkout(s)}
							editExercise={(e: Exercise_t) => editExercise(e)}
							getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
							addNewExerciseToWorkoutModalPressed={(data: any) => addNewExerciseToWorkoutModalPressed(data)}
							selectedClient={selectedClient}
							selectedTimestamp={selectedTimestamp}/>
					</div>
					</CSSTransition>
					<div className="modal-content-container" hidden={modalContentIndex != 6}>
						<NewPlanModal 
							folder={props.information !== undefined && props.information.folder !== undefined ? props.information.folder : initFolder}
							planToEdit={props.information !== undefined && props.information.plan !== undefined ? props.information.plan : initPlan}
							clientID={props.information !== undefined ? props.information.clientID : undefined}
							selectedWorkout={selectedWorkout}
							closeModal={() => closeModal()}
							addAnotherWorkout={() => addAnotherWorkout()}
							createNewWorkoutFromPlan={() => createNewWorkoutFromPlan()}
							editWorkout={(w: Workout_t) => editWorkout(w)}
							preselectedWorkouts={preselectedWorkouts}
							getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
							completedCallback={(e: any) => fireCallback(e)}/>
					</div>
					
					<div className="modal-content-container" hidden={modalContentIndex != 7}>
						<CreatePlanWorkoutConfigure
							backToWorkoutList={(e?:Workout_t) => backToWorkoutList(e)}
							closeModal={() => closeModal()}
							createNewWorkoutFromPlan={() => createNewWorkoutFromPlan()}/>
					</div>
					<div className="modal-content-container" hidden={modalContentIndex != 10}>
						<NewExerciseModal
							onTheFlyExerciseAdding={onTheFlyExerciseAdding}
							defaultTitleString={defaultTitleString}
							goToLibrary={() => props.goToLibrary()}
							closeModal={(e?: Exercise_t) => onTheFlyExerciseAdding ? backToWorkout(e) : closeModal()}
							getUserConfirmation={(e: any) => props.getUserConfirmation(e)}/>
					</div>
					<div className="modal-content-container" hidden={modalContentIndex != 11}>
						<LogMeasurementModal
							client={props.information}
							closeModal={() => closeModal()}
							backToWorkoutList={(e?:BodyMeasurement_t) => closeModal()}/>
					</div>
				</div>
			</div>
			<div hidden={!(onTheFlyWorkoutAdding === true && onTheFlyExerciseAdding === true)} className="modal-container-depth-2">
				<div onClick={() => onTheFlyExerciseAdding ? backToWorkout() : null}>
					<NewWorkoutModal 
						forcedPage={1}
						selectedExercise={selectedExercise}
						editWorkout={editingWorkout}
						onTheFlyWorkoutAdding={onTheFlyWorkoutAdding}
						closeModal={(e?: Workout_t) => onTheFlyWorkoutAdding ? backToPlan(e) : closeModal()}
						addAnotherExercise={() => addAnotherExercise()}
						createNewExerciseFromWorkout={(s: string) => createNewExerciseFromWorkout(s)}
						editExercise={(e: Exercise_t) => editExercise(e)}/>
				</div>
			</div>
			<div className="modal-container-depth-1">
				<div onClick={() => onTheFlyWorkoutAdding ? backToPlan() : null} hidden={onTheFlyWorkoutAdding === false}>
					<NewPlanModal 
						folder={props.information !== undefined && props.information.folder !== undefined ? props.information.folder : initFolder}
						selectedWorkout={selectedWorkout}
						closeModal={() => closeModal()}
						forcedPage={1}
						addAnotherWorkout={() => addAnotherWorkout()}
						preselectedWorkouts={preselectedWorkouts}
						createNewWorkoutFromPlan={() => createNewWorkoutFromPlan()}/>
				</div>
				<div onClick={() => onTheFlyExerciseAdding ? backToWorkout() : null}  hidden={onTheFlyExerciseAdding === false || onTheFlyWorkoutAdding === true}>
					<NewWorkoutModal 
						forcedPage={1}
						selectedExercise={selectedExercise}
						editWorkout={editingWorkout}
						onTheFlyWorkoutAdding={onTheFlyWorkoutAdding}
						closeModal={(e?: Workout_t) => onTheFlyWorkoutAdding ? backToPlan(e) : closeModal()}
						addAnotherExercise={() => addAnotherExercise()}
						createNewExerciseFromWorkout={(s: string) => createNewExerciseFromWorkout(s)}
						editExercise={(e: Exercise_t) => editExercise(e)}/>
				</div>
			</div>
			
		</div>
	)
}


/*
<div className="modal-content-container" hidden={modalContentIndex != 5}>
						<NewNoteModal 
							client={props.information}
							currentWorkout={props.currentWorkout}
							closeModal={() => closeModal()}/>
					</div>
<div className="modal-content-container" hidden={modalContentIndex != 1}>
						<ClientModal profile={props.information} />
					</div>
<div className="modal-content-container" hidden={modalContentIndex != 8}>
						<AssignPlanToClientModal
							plan={props.information !== undefined && props.information.plan !== undefined ? props.information.plan : initPlan}
							closeModal={() => closeModal()}/>
					</div>
					<div className="modal-content-container" hidden={modalContentIndex != 9}>
						<NewGoalModal
							client={props.information}
							closeModal={() => closeModal()}
							backToWorkoutList={(e?:Workout_t) => backToWorkoutList(e)}/>
					</div>
					<div className="modal-content-container" hidden={modalContentIndex != 4}>
						<CreateWorkoutExerciseConfigure
							exerciseToEdit={exerciseToEdit}
							closeModal={() => onTheFlyWorkoutAdding ? backToPlan() : closeModal()}
							onTheFlyWorkoutAdding={onTheFlyWorkoutAdding}
							createNewExerciseFromWorkout={(s: string) => createNewExerciseFromWorkout(s)}
							backToExerciseList={(e?:Exercise_t) => backToExerciseList(e)}/>
					</div>
*/

export default Modal;