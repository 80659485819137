import { useState } from 'react';

import firebase from './Firebase';
import { BLE } from '@ionic-native/ble';
import Client_t from './Interfaces/Client_t';
import Peripheral_t from './Interfaces/Peripheral_t';
import MotionData_t from './Interfaces/MotionData_t';
import RepStats_t from './Interfaces/RepStats_t';
import RepTiming_t from './Interfaces/RepTiming_t';
import Exercise_t from './Interfaces/Exercise_t';
import MotionInfo_t from './Interfaces/MotionInfo_t';
import MonitorLocations_t from './Interfaces/MonitorLocations_t';

import NodeInstance from './NodeInstance';
import NodeManager from './NodeManager';
import MLCore from './MLCore';


enum repTimeProgressionType {
	idle = 0,
	positive = 1, 
	up = 2, 
	negative = 3, 
	down = 4
}

enum repStateType {
	idle = 0,
	positive = 1, 
	negative = 2 
}

enum nodeLocationKey {
	fa_r = 0,		// Right Forearm
	fa_l = 1,		// Left Forearm
	b_r  = 2,		// Right Bicep
	b_l  = 3,		// Left Bicep
	s_r  = 4,		// Right Shin
	s_l  = 5,		// Left Shin
	t_r  = 6,		// Right Thigh
	t_l  = 7,		// Left Thigh
	w    = 8		// Waistband
}

interface NodeStreamAnalysis_t {
	locationIndex: number;
	motionData: MotionData_t[];
	positivePredictionCount: number;
	lastRepDetectedIndex: number;
	repCount: number;
}

interface RepWindow_t {
    start_index: number;
    end_index: number;
    is_rep: boolean;
}

interface RepTimes_t {
	positive: number;
	up: number;
	negative: number;
	down: number;
}

interface MotionManagerData_t {
	peripheral: Peripheral_t;
	location_index: number;
	motion_data: MotionData_t;
	last_time: number;
	last_signal: number;
	stream_history: number[];
	rep_time_progression: repTimeProgressionType;
	crossed_negative_first: boolean;
	no_regression_pos: boolean;
	no_regression_neg: boolean;
	in_flow: boolean;
	times: RepTimes_t;
	ROC: number;
	finalROC: number;
	signal_has_changed_state: boolean;
	has_finished_rep: boolean;
	start_motion_data?: MotionData_t;
	peak_motion_data?: MotionData_t;
	end_motion_data?: MotionData_t;
	max_ROM?: number;
}


/**
 *	NodeManager
 *	----------------------------------------------------------------------------------------------------
 *	@desc Handles all Node connections, lists of connected / discovered Nodes, data retrieval, etc.
 *		  Create and share one instance of this class throughout the application and make use of it
 * 		  to handle accessing Nodes and their motion data.
 *
 */
class MotionManager {

	private training_machine_learning_models = true;

	private repStats: RepStats_t[] = [];
	private lastMotionData: any = {};

	private currentWeight: number = 0;
	private unitsKG: boolean = false;

	private exerciseIsRotational: boolean = true;
	private leaverLength: number = 0.3;

	private NodeManagerInstance: NodeManager;
	private mlCoreInstance: MLCore;

	private startingRepTime: number = 0;

	private client: Client_t = {first_name:'',
								last_name:'',
								full_name:'',
								id:'', 
								isMetric: true, 
								gender: 'b', 
								birthdate: '00-00-0000',
								height: 0, 
								weight: 0,
								goalIndex: 0,
								experienceIndex: 0};

	private defaultMotionInfo: MotionInfo_t = {
								leading_concentric: true,
							    analysis_vector: {x: 0, y: 1, z: 0},
							    analyze_vector: true,
							    body_weight_by_default: false,
							    weight_scaler: 1.0,
							    lever: {
							        segment_id: "forearm",
							        default_length: 0.35,
							    },
							    counting: {
							        type: "mirrored",
							        minimum_monitor_locations: [0, 1],
							        auxiliary_monitor_locations: [2, 3]
							    }
							};

	private nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];

	private motionInfo: MotionInfo_t; 

	private alernating_rep_progression: number = 0;

	private dataBuffer : MotionManagerData_t[] = [];

	private streamBuffer: NodeStreamAnalysis_t[] = [];
	private lastRepDetectedIndex: number = 0;
	private repCount: number = 0;

	private exerciseID: string = "";
	private sessionID: string = "";

	/**
	 *	MotionManager constructor
	 *  ----------------------------------------------------------------------------------------------------
	 *
	 */
	constructor(nodes: NodeInstance[], toExercise: Exercise_t, toWeight: number, isKG: boolean, manager: NodeManager, mlCore: MLCore) {

		// console.log(`> MOTION MANAGER: NEW MOTION MANAGER CREATED FOR NODE LIST WITH LENGTH: ${nodes.length}. MOTION INFO IS:`);
		// console.log(toExercise["motionInfo"] === undefined ? "NO MOTION DATA EXISTS. WILL SET TO DEFAULT." : toExercise["motionInfo"]);

		this.currentWeight = toWeight;
		this.unitsKG = isKG;

		this.motionInfo = toExercise.motionInfo !== undefined ? toExercise.motionInfo : this.defaultMotionInfo;
		this.setExercise(toExercise);

		var d = new Date();
  		var n = d.getTime();

  		this.startingRepTime = n;

  		this.NodeManagerInstance = manager;
  		this.mlCoreInstance = mlCore

  		this.exerciseID = toExercise.id;

  		// Make sure the stream buffer is clear for each Node location
  		this.clearStreamBuffer();
  		this.lastRepDetectedIndex = 0;
  		this.repCount = 0;

  		this.repStats = [];

		// Set local callback functions		
		for (var i = 0; i < nodes.length; i++) {
			let thisNode: NodeInstance = nodes[i];
			thisNode.setMotionInfo(this.motionInfo);

			// console.log(`Node ${thisNode.getDeviceSIN()}, location_index: ${thisNode.getLocationIndex()}`);

			let l = thisNode.getLocationIndex();
			let newMotionManagerData: MotionManagerData_t = {
				peripheral: thisNode.getPeripheral(),
				location_index: l,
				motion_data: {
					quaternion: {w: 1, x: 0, y: 0, z: 0},
					acceleration: {x: 0, y: 0, z: 0},
					timestamp: Date.now()
				},
				last_time: 0,
				last_signal: 0,
				stream_history: [],
				rep_time_progression: repTimeProgressionType.idle,
				crossed_negative_first: false,
				no_regression_pos: false,
				no_regression_neg: false,
				in_flow: false,
				times: {positive:n,up:n,negative:n,down:n},
				ROC: 0.0,
				finalROC: 0.0,
				signal_has_changed_state: false,
				has_finished_rep: false,
			}
			this.lastMotionData[`${l}`] = newMotionManagerData;
		}
	}

	private clearStreamBuffer() {
		var streamBuffer_temp: NodeStreamAnalysis_t[] = []
		for (var i = 0; i < 9; i++) {
			let thisStreamObj: NodeStreamAnalysis_t = {
				locationIndex: i,
				motionData: [],
				positivePredictionCount: 0,
				lastRepDetectedIndex: 0,
				repCount: 0
			};

			streamBuffer_temp.push(thisStreamObj);
		}

		this.streamBuffer = streamBuffer_temp;
	}

	public getRepStats() {
		return this.repStats;
	}

	public setWeight(toWeight: number, isKG: boolean) {
		this.currentWeight = toWeight;
		this.unitsKG = isKG;
	}

	public setExercise(toExercise: Exercise_t) {

		// check to see if DB has most up-to-date motion info for this exercise
		let database = firebase.database();
		let ref = database.ref(`/exercises/${toExercise.id}/motion_info`); // Change to database path

		let _this = this;
		ref.on('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        let data = snapshot.val();
		        
		        let motionInfoObject: MotionInfo_t = {
		        	leading_concentric: data.leading_concentric !== undefined ? data.leading_concentric : _this.defaultMotionInfo.leading_concentric,
				    analysis_vector: data.analysis_vector !== undefined ? data.analysis_vector : _this.defaultMotionInfo.analysis_vector,
				    analyze_vector: data.analyze_vector !== undefined ? data.analyze_vector : _this.defaultMotionInfo.analyze_vector,
				    body_weight_by_default: data.body_weight_by_default !== undefined ? data.body_weight_by_default : _this.defaultMotionInfo.body_weight_by_default,
				    weight_scaler: data.weight_scaler !== undefined ? data.weight_scaler : _this.defaultMotionInfo.weight_scaler,
				    lever: {
				        segment_id: data.lever !== undefined && data.lever.segment_id !== undefined ? data.lever.segment_id : _this.defaultMotionInfo.lever.segment_id,
				        default_length: data.lever !== undefined && data.lever.default_length !== undefined ? data.lever.default_length : _this.defaultMotionInfo.lever.default_length,
				    },
				    counting: {
				        type: data.counting !== undefined && data.counting.type !== undefined ? data.counting.type : _this.defaultMotionInfo.counting.type,
				        minimum_monitor_locations: data.counting !== undefined && data.counting.minimum_monitor_locations !== undefined ? data.counting.minimum_monitor_locations : _this.defaultMotionInfo.counting.minimum_monitor_locations,
				        auxiliary_monitor_locations: data.counting !== undefined && data.counting.auxiliary_monitor_locations !== undefined ? data.counting.auxiliary_monitor_locations : _this.defaultMotionInfo.counting.auxiliary_monitor_locations
				    }
		        }

		        // console.log(">>>>> Setting motion data to:", toExercise.id, motionInfoObject);
		        
		        _this.motionInfo = motionInfoObject;
		        // // console.log(`MOTION MANAGER: setExercise | Setting the following motion info:`);
				// // console.log(_this.motionInfo);
		        
		    } else {
		    	_this.motionInfo = _this.defaultMotionInfo;
		    	// console.log(">>>>> NO MOTION DATA FOUND", toExercise.id);
		    }
		});
	}

	public getMotionInfo() {
		return this.motionInfo;
	}

	public printTestResults() {

		// var finalString = "ROC,Time Progression,POS,UP,NEG,DOWN,crossed_positive_first,no_regress_pos,no_regress_neg,roc_window_size,progression_window_size\n";
		// var t = "";
		// for (var i = 0; i < this.dataBuffer.length; i++) {
		// 	let thisSet = this.dataBuffer[i];
		// 	if (thisSet.times !== undefined) {
		// 		let s = `0,${thisSet.finalROC},${thisSet.rep_time_progression},${thisSet.times.positive},${thisSet.times.up},${thisSet.times.negative},${thisSet.times.down},${thisSet.crossed_negative_first},${thisSet.no_regression_pos},${thisSet.no_regression_neg},${this.motionInfo.filter_params.roc_window_size},${this.motionInfo.filter_params.progression_window_size}\n`;
		// 		t += s;
		// 		// console.log(s);
		// 	}
		// }
		// if (this.dataBuffer.length === 0) {
		// 	// console.log("NO DATA IN BUFFER :( ");
		// 	// console.log(this.dataBuffer);
		// }
		// // console.log(`DATA LENGTH: ${this.dataBuffer.length}`);
		// // console.log(finalString);
		// // console.log(t);

		//this.dataBuffer = [];
	}


	public dataUpdated(data: any[], toExercise: Exercise_t) {

		return;
	}

	/**
	 * 	Begin this session
	 *  ----------------------------------------------------------------------------------------------------
	 * 	To be called from NodeManager when user indicates they're done recording this set.
 	 *  Will log the data streams to database at /node_data_stream
 	 * 
 	 */ 
	public beginThisSession() {
		if (!this.training_machine_learning_models) { return; }

		// 1. Configure 'is_streaming' bits
		let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let userUID = currentUser.uid;

        

		let rootStreamPath = `node_stream_data/logs/${this.exerciseID}`;
        let database = firebase.database();
        let ref = database.ref(`${rootStreamPath}/${userUID}/is_streaming`);
        ref.set(true);
        let ref_stream = database.ref(`node_stream_data/abcd/is_streaming`);
        ref_stream.set(true);

        // create new set
        var thisSetName = "";
        var thisSetIndex = 0;
        var thisExerciseIndex = 0;
        var thisSeriesIndex = 0;
        var thisTargetReps = 0; 

        let thisSetRef = database.ref(`${rootStreamPath}/sessions`);
        let thisSetPushRef = thisSetRef.push({
            workout_id: "",
            timestamp: Date.now(),
            logged_by: userUID,
            name: "",
            set_index: thisSetIndex,
            exercise_index: thisExerciseIndex,
            series_index: thisSeriesIndex,
            target_reps: thisTargetReps,
            reps: {
                beep: 'bop'
            }
        });

        let thisPushKey = thisSetPushRef.key;
        if (thisPushKey !== null) {
            this.sessionID = thisPushKey;
            let currentSessionSetIDRef = database.ref(`${rootStreamPath}/current_session_id`);
            currentSessionSetIDRef.set(thisPushKey);

            let globalCurrentSessionIDRef = database.ref(`node_stream_data/abcd/current_session_id`);
            globalCurrentSessionIDRef.set(thisPushKey);
        }

        // console.log("> MOTION MANAGER [beginThisSession()] | SET SESSION BEGUN WITH ID: ", thisPushKey);

        // 5. Add this session ID to exercise session ID list
        let exerciseSessionsRef = database.ref(`node_stream_data/exercises/${this.exerciseID}/session_ids/${thisPushKey}`);
        exerciseSessionsRef.set({
            timestamp: Date.now(),
            logged_by: userUID,
            analysis_complete: false
        })

	}


	/**
	 * 	Log this Session
	 *  ----------------------------------------------------------------------------------------------------
	 * 	To be called from NodeManager when user indicates they're done recording this set.
 	 *  Will log the data streams to database at /node_data_stream
 	 * 
 	 */ 
	public logThisSession() {
		if (!this.training_machine_learning_models) { return; }

		let dataToStore: any = this.prepRepStatsForLog();

		let database = firebase.database();
		let ref = database.ref(`node_stream_data/logs/${this.exerciseID}/sessions/${this.sessionID}/data_stream`);
		ref.set(dataToStore);

	}

	private prepRepStatsForLog() {
		var finalDataList: any = {};
		for (var i = 0; i < this.streamBuffer.length; i++) {
			let thisStreamBuffer = this.streamBuffer[i];
			let thisData: MotionData_t[] = thisStreamBuffer.motionData;
			if (thisData.length > 0) {
				var thisMotionDataList: MotionData_t[] = [];
				for (var j = 0; j < thisData.length; j++) {
                    let thisMotionData: MotionData_t = thisData[j];
                    let thisMotionDataFinal: MotionData_t = {
                        quaternion: {
                            w: isNaN(thisMotionData.quaternion.w) ? 0 : thisMotionData.quaternion.w,
                            x: isNaN(thisMotionData.quaternion.x) ? 0 : thisMotionData.quaternion.x,
                            y: isNaN(thisMotionData.quaternion.y) ? 0 : thisMotionData.quaternion.y,
                            z: isNaN(thisMotionData.quaternion.z) ? 0 : thisMotionData.quaternion.z
                        },
                        acceleration: {
                            x: isNaN(thisMotionData.acceleration.x) ? 0 : thisMotionData.acceleration.x,
                            y: isNaN(thisMotionData.acceleration.y) ? 0 : thisMotionData.acceleration.y,
                            z: isNaN(thisMotionData.acceleration.z) ? 0 : thisMotionData.acceleration.z
                        },
                        timestamp: isNaN(thisMotionData.timestamp) ? 0 : thisMotionData.timestamp
                    }
                    thisMotionDataList.push(thisMotionDataFinal);
                }
				finalDataList[`${i}`] = thisMotionDataList;
			}
		}

		return finalDataList;
	}


	/**
	 * 	Node Motion Stream Updated
	 *  ----------------------------------------------------------------------------------------------------
	 * 	To be called from NodeManager when device receives a new batch of data from a Nodes. 
 	 *  Passes the given data along to MotionManager for further analysis.
 	 * 
 	 *  @param data 	 {MotionData_t[]}   A batch of the latest motion data from the Node
	 *	@param node 	 {NodeInstance}     Reference to the Node that is sharing the update
 	 * 
 	 */ 
	public NodeMotionStreamUpdated(data: MotionData_t[], node: NodeInstance)
	{
		//// console.log(`> MOTION MANAGER [NodeMotionStreamUpdated()] | ${node.getUUID()} is updated with list of length: ${data.length}`);
		//// console.log(data);
		var thisNodeLocationIndex = node.getLocationIndex();
		thisNodeLocationIndex = thisNodeLocationIndex < 0 ? 0 : thisNodeLocationIndex;

		// 1. Concatinate data to respective streamBuffer. To ensure the data stream emulates "stationary" motion
		//	  before the most recent rep, lastRepDetectedIndex is checked here (incase another Node caused a rep-detection).
		if (this.streamBuffer[thisNodeLocationIndex] !== undefined) {
			let currentStreamBufferLength = this.streamBuffer[thisNodeLocationIndex].motionData.length;
			for (var i = 0; i < data.length; i++) {
				let thisDataPoint = data[i];
				let newIndex = currentStreamBufferLength + i;
				//if (newIndex < this.lastRepDetectedIndex) {
				if (newIndex < this.streamBuffer[thisNodeLocationIndex].lastRepDetectedIndex) {
					// If the new index comes before the most recently detected rep, push the last value
					// in the existing stream buffer to maintain the emulated "stationary" data stream
					this.streamBuffer[thisNodeLocationIndex].motionData.push(this.streamBuffer[thisNodeLocationIndex].motionData[currentStreamBufferLength - 1]);
				} else {
					// Okay to push new data
					this.streamBuffer[thisNodeLocationIndex].motionData.push(thisDataPoint);
				}
			}
		}

		// 2. Run prediction on this Node
		this.runRepPredictionForSingleNode(thisNodeLocationIndex);

	}

	/**
     *  Run Rep Prediction | Single Node
     *  ----------------------------------------------------------------------------------------------------
     *  Parse this Node's data stream and run AI prediction for rep detection. If rep is detected, increment
     *  the local rep count, notify listening UI components through callbacks, and run stat analysis
     * 
     *  @param locationIndex 			{number}			Location index of the Node under analysis
     * 
     */
	private runRepPredictionForSingleNode(locationIndex: number) {

		// 1. Get full data stream for the given location index
		let thisMotionStream: MotionData_t[] = this.streamBuffer[locationIndex].motionData;
		if (thisMotionStream === undefined) {
			// console.log(`> MOTION MANAGER [runRepPredictionForSingleNode()]: ERROR! The motion stream for Node location ${locationIndex} is undefined. Will exit function.`);
			return;
		}

		// 2. Compose a full list of this Node's quaternions (stored in quaternionBuffer)
		// let leadingQuaternion: {w: number, x: number, y: number, z: number} =  thisMotionStream[this.lastRepDetectedIndex] === undefined ? 
		// 																			(thisMotionStream.length === 0 ? {w: 1, x: 0, y: 0, z: 0} : thisMotionStream[thisMotionStream.length - 1].quaternion) : 
		// 																			thisMotionStream[this.lastRepDetectedIndex].quaternion;

		let leadingQuaternion: {w: number, x: number, y: number, z: number} =  thisMotionStream[this.streamBuffer[locationIndex].lastRepDetectedIndex] === undefined ? 
																					(thisMotionStream.length === 0 ? {w: 1, x: 0, y: 0, z: 0} : thisMotionStream[thisMotionStream.length - 1].quaternion) : 
																					thisMotionStream[this.streamBuffer[locationIndex].lastRepDetectedIndex].quaternion;

		var quaternionBuffer: {w: number, x: number, y: number, z: number}[] = [];
		for (var i = 0; i < thisMotionStream.length; i++) {
			// if (i < this.lastRepDetectedIndex) {
			if (i < this.streamBuffer[locationIndex].lastRepDetectedIndex) {
				quaternionBuffer.push(leadingQuaternion);
			} else {
				let thisMotionData: MotionData_t = thisMotionStream[i];
				let thisQuaternion: {w: number, x: number, y: number, z: number} = thisMotionData.quaternion;
				quaternionBuffer.push(thisQuaternion);
			}
		}


		// 3. Run prediction on all unchecked data by moving a 112-point window forwards along the data stream until it hits the most recent data log
		var startingIndex = quaternionBuffer.length - 112;
        startingIndex = startingIndex < 0 ? 0 : startingIndex;
        for (var i = startingIndex; i < quaternionBuffer.length; i++) {
        	// 3.A Run prediction on a 112-point window ending at "i"
        	let ai_response = this.runRepPrediction_singleInstance(quaternionBuffer, i, locationIndex)
        	//// console.log("RESP:", this.nodeLocationNames[locationIndex], ai_response);

        	// 3.B Check that the number of logs since last rep detection is greater than a threshold to prevent 
        	//     multiple rep detections from the same data points
        	// let distanceFromLastRepIndex = i - this.lastRepDetectedIndex;
        	let distanceFromLastRepIndex = i - this.streamBuffer[locationIndex].lastRepDetectedIndex;
        	
        	// 3.C To continue confirming rep occured, verify that: 	
        	//		i. 		Prediction response exists, 	
        	//		ii.	 	Prediction label is not 0, 	
        	// 		iii. 	Prediction certainty >= threshold %, 	
        	//		iv.  	Distance since last rep > threshold
        	if (ai_response !== undefined && 
        		ai_response.predicted_index >= 1 && 
        		ai_response.certainty >= 60 && 
        		distanceFromLastRepIndex > 8) {

        		// 3.D To finalize confirming a rep occured, verify that more than 2 positive predictions have occured, and that motion occured in this stream
        		// if (this.streamBuffer[locationIndex].positivePredictionCount >= 2 && this.checkForMotion(quaternionBuffer, i + 2, this.lastRepDetectedIndex) === true) {
        		if (this.streamBuffer[locationIndex].positivePredictionCount >= 2 && this.checkForMotion(quaternionBuffer, i + 2, this.streamBuffer[locationIndex].lastRepDetectedIndex) === true) {

        			
        			// ----------------------------------------------------------------------------------------------------------------------
        			// -------------------------------------------------- REP IS DETECTED! --------------------------------------------------
        			// ----------------------------------------------------------------------------------------------------------------------

        			this.repCount += 1;
        			this.streamBuffer[locationIndex].positivePredictionCount = 0;

        			// Generate statistics for this rep
        			//this.generateStatsForRep_singleNode(locationIndex, quaternionBuffer, i, this.lastRepDetectedIndex);
        			this.generateStatsForRep_singleNode(locationIndex, quaternionBuffer, i, this.streamBuffer[locationIndex].lastRepDetectedIndex);
        			//this.lastRepDetectedIndex = i + 2;
        			this.streamBuffer[locationIndex].lastRepDetectedIndex = i + 2;

        			// console.log(`> MOTION MANAGER [runRepPredictionForSingleNode()] | REP _${this.repCount}_ DETECTED at location: "${this.nodeLocationNames[locationIndex]}" (${locationIndex}).\t\tOccured at stream index: ${this.lastRepDetectedIndex}`);
        			

        		} else {
					this.streamBuffer[locationIndex].positivePredictionCount = this.streamBuffer[locationIndex].positivePredictionCount + 1;

        		}
        	} else {
        		this.streamBuffer[locationIndex].positivePredictionCount = 0;
        	}
        }
	}


	/**
     *  Run Rep Prediction | Single Instance (and for single Node)
     *  ----------------------------------------------------------------------------------------------------
     *  Analyze 112-quaternion window of data stream from (atIndex - 112) to atIndex
     * 
     *  @param q_stream 				{quaternion[]}		The full quaternion data list of this Node
     * 	@param atIndex  				{number}			Ending index of the 112-point window
     * 
     */
	private runRepPrediction_singleInstance(q_stream: {w: number, x: number, y: number, z: number}[], atIndex: number, locationIndex: number) {

		// 1. Generate quaternion list of length 112, ending at the given "atIndex"
		let currentPoint = atIndex;
        var startingPoint = currentPoint - 112;
        startingPoint = startingPoint < 0 ? 0 : startingPoint;
        let composedLength = currentPoint - startingPoint;

        var qBuff: {w: number, x: number, y: number, z: number}[] = [];

        let qToRotate = {w: 0.0, x: 0.0, y: 1.0, z: 0.0};	// Vector along limb segment (Node's +y-axis)
        //var leadingQ: {w: number, x: number, y: number, z: number} = q_stream[this.lastRepDetectedIndex] === undefined ? {w: 0.0, x: 0.0, y: 0.0, z: 0.0} : q_stream[this.lastRepDetectedIndex];
        var leadingQ: {w: number, x: number, y: number, z: number} = q_stream[this.streamBuffer[locationIndex].lastRepDetectedIndex] === undefined ? {w: 0.0, x: 0.0, y: 0.0, z: 0.0} : q_stream[this.streamBuffer[locationIndex].lastRepDetectedIndex];
        
        

        for (var i = startingPoint; i < startingPoint + composedLength; i++) {
            //let thisQ = i < this.lastRepDetectedIndex ? leadingQ : q_stream[i];
            let thisQ = i < this.streamBuffer[locationIndex].lastRepDetectedIndex ? leadingQ : q_stream[i];

            let thisRotatedQ = this.q_rotate(qToRotate, thisQ);
            thisRotatedQ.w = -1;
            qBuff.push(thisRotatedQ);
        }

        // 1.A Add leading "zero" quaternions to list to ensure length is 112. This is only relevant when q_stream.length is less than 112
        let finalQList = startingPoint === 0 ? this.getQuaternionListWithLeadingZeros(qBuff, 112) : qBuff;

        // 1.B Generate 448 byte buffer from finalQList
        let thisByteBuffer = this.getQuaternionStreamByteBuffer(finalQList);

        // 2. RUN AI PREDICTION USING TENSORFLOW MODEL (see MLCore.tsx for model loading/prediction)
        let AIPrediction_result: any = this.mlCoreInstance.predict_location(thisByteBuffer, locationIndex);
        
        if (AIPrediction_result === -1) {
        	// Handle errors by returning a "stationary" result with 100% certainty
        	return { predicted_index: 0, certainty: 100 };
        }

        let AIPrediction_predictedIndex = AIPrediction_result.predicted_index;
        let AIPrediction_certainty = AIPrediction_result.prediction_ratings[AIPrediction_predictedIndex] * 100;

        // 3. Return formatted results from AI prediction
        return {
            predicted_index: AIPrediction_predictedIndex,
            certainty: AIPrediction_certainty
        };
	}

	/**
	 * 	Check for Motion
	 * 	----------------------------------------------------------------------------------------------------
     *  To prevent unwanted rep triggering that occurs when user is stationary, this function looks for movement
     *  in the purposed rep window. 
     * 
     *  @param q_stream 				{quaternion[]}		The full quaternion data list of this Node
     * 	@param endingIndex 				{number}			Ending index in q_stream of the rep under analysis
     * 	@param startingIndex 			{number}			Starting index in q_stream of the rep under analysis
     * 
     */
    private checkForMotion(q_stream: {w: number, x: number, y: number, z: number}[], endingIndex: number, startingIndex: number) {
        let repLengthPts = endingIndex - startingIndex;
        var startingIndex_adj = repLengthPts > 112 ? endingIndex - 112 : startingIndex;    // Restrict length of analysis to 112 points

        // 1. Compose list of quaternions (qBuff)
        let leadingQ = q_stream[startingIndex_adj] === undefined ? {w: 1, x: 0, y: 0, z: 0} : q_stream[startingIndex_adj];
        var qBuff: {w: number, x: number, y: number, z: number}[] = [];

        for (var i = startingIndex_adj; i <= endingIndex; i++) {
            let thisQ = q_stream[i] === undefined ? leadingQ : q_stream[i];
            qBuff.push(thisQ);
        }

        var integratedAngle = 0.0;
        let quatToRotate: {w: number, x: number, y: number, z: number} = {w: 0, x: 0, y: 1, z: 0};
        for (var i = 1; i < qBuff.length; i++) {
            let thisQ: {w: number, x: number, y: number, z: number} = qBuff[i];
            let lastQ: {w: number, x: number, y: number, z: number} = qBuff[i - 1];

            let thisRotatedQ = this.q_rotate(quatToRotate, thisQ);
            let lastRotatedQ = this.q_rotate(quatToRotate, lastQ);


            let deltaAngle = this.angle_between_vector_quaternions(thisRotatedQ, lastRotatedQ);

            integratedAngle += deltaAngle;
        }

        let angleThreshold = 8;

        return integratedAngle > angleThreshold;
    }

	/**
     *  Get Quaternion List with Leading Zeros
     *  ----------------------------------------------------------------------------------------------------
     *  Generates and returns a list of the indicated length with leading "zero" quaternions, which are just
     *  the first recorded quaternion - this emulates "stationary" motion before the recorded data begins.
     * 
     *  @param thisQuatList 			{quaternion[]}		The quaternion data list to be formatted
     *  @param quatListBufferLength		{number} 			Desired length of the formatted quaternion list
     * 
     */
	private getQuaternionListWithLeadingZeros(thisQuatList: {w: number, x: number, y: number, z: number}[], quatListBufferLength: number) {
		let thisQuatListLength = thisQuatList.length;
        let numZeroEntries = quatListBufferLength - thisQuatListLength;

        var thisComposedQuatList: {w: number, x: number, y: number, z: number}[] = [];
        // 1. Add leading "zero" quaternion entries
        for (var j = 0; j < numZeroEntries; j++) {
            thisComposedQuatList.push(thisQuatList[0] === undefined ? {w: 0, x: 0, y: 0, z: 0} : thisQuatList[0]);//{w: 0, x: 0, y: 0, z: 0});			/// TESTING!!!!!!!!! COULD BE ERROR HERE
        }

        // 2 - add remaining recorded entries
        for (var j = 0; j < thisQuatListLength; j++) {
            thisComposedQuatList.push(thisQuatList[j]);
        }

        return thisComposedQuatList
	}

	/**
     *  Get Quaternion Stream Byte Buffer
     *  ----------------------------------------------------------------------------------------------------
     *  Generates and returns a list of bytes representing quaternion data to be fed into ML model
     *  Format is: [(uint8_t)q[0].w, (uint8_t)q[0].x, (uint8_t)q[0].y, (uint8_t)q[0].z, (uint8_t)q[1].w, (uint8_t)q[1].x, (uint8_t)q[1].y, (uint8_t)q[1].z, ..... ]
     * 
     *  @param thisQuatList 			{quaternion[]}		The quaternion data list to be formatted
     * 
     */
	private getQuaternionStreamByteBuffer(thisQuatList: {w: number, x: number, y: number, z: number}[]) {
		var byteBuffer_temp: number[] = [];

		for (var j = 0; j < thisQuatList.length; j++) {
            let thisQuat: {w: number, x: number, y: number, z: number} = thisQuatList[j];

            for (var k = 0; k < 4; k++) {
                switch (k) {
                    case 0 :
                        byteBuffer_temp.push(Math.floor(thisQuat.w * 128) + 128);
                        break;
                    case 1 :
                        byteBuffer_temp.push(Math.floor(thisQuat.x * 128) + 128);
                        break;
                    case 2 :
                        byteBuffer_temp.push(Math.floor(thisQuat.y * 128) + 128);
                        break;
                    case 3 :
                        byteBuffer_temp.push(Math.floor(thisQuat.z * 128) + 128);
                        break;
                    default:
                        // console.log("> MOTION MANAGER [getQuaternionStreamByteBuffer()]: ERROR! check your 'k' value.");
                        break;

                }
            }
        }

        return byteBuffer_temp;
	}



	/**
     *  Generate Stats for Rep | Single Node
     *  ----------------------------------------------------------------------------------------------------
     *  Generates all stats for the latest given rep
     * 
     *  @param locationIndex 			{number}			Location index of the Node under analysis
     *  @param q_stream 				{quaternion[]}		The full quaternion data list of this Node
     * 	@param endingIndex 				{number}			Ending index in q_stream of the rep under analysis
     * 	@param startingIndex 			{number}			Starting index in q_stream of the rep under analysis
	 *
     */
	private generateStatsForRep_singleNode(locationIndex: number, q_stream: {w: number, x: number, y: number, z: number}[], endingIndex: number, startingIndex: number) {
		

		const quaternionSmoothLength = 1;	// Number of historical quaternions to smooth the stream over (length of windowed average)
		const generateStatsForRep_singleNode_verbose = false;

		if (generateStatsForRep_singleNode_verbose) {
			// console.log("generateStatsForRep_singleNode: ", locationIndex, q_stream.length, endingIndex, startingIndex);
		}

		// 1. Compose 112-point windowed list of quaternions from this Node's q_stream (stored in qBuff)
		let repLengthPts = endingIndex - startingIndex;

		// Restrict length of analysis to 112 points
        var startingIndex_adj = repLengthPts > 112 ? endingIndex - 112 : startingIndex;    

        let leadingQ = q_stream[startingIndex_adj] === undefined ? {w: 1, x: 0, y: 0, z: 0} : q_stream[startingIndex_adj];
        var qBuff: {w: number, x: number, y: number, z: number}[] = [];

        for (var i = startingIndex_adj; i <= endingIndex; i++) {
            let thisQ = q_stream[i] === undefined ? leadingQ : q_stream[i];

            // Smooth quaternions
            if (qBuff.length <= quaternionSmoothLength) {
                qBuff.push(thisQ);
            } else {
                var avgQ: {w: number, x: number, y: number, z: number} = thisQ;
                for (var k = 0; k < quaternionSmoothLength; k++) {
                    avgQ.w += qBuff[qBuff.length - 1 - k].w;
                    avgQ.x += qBuff[qBuff.length - 1 - k].x;
                    avgQ.y += qBuff[qBuff.length - 1 - k].y;
                    avgQ.z += qBuff[qBuff.length - 1 - k].z;
                }

                avgQ.w /= quaternionSmoothLength + 1;
                avgQ.x /= quaternionSmoothLength + 1;
                avgQ.y /= quaternionSmoothLength + 1;
                avgQ.z /= quaternionSmoothLength + 1;

                qBuff.push(avgQ);
            }
        }

        // 2. Generate 1-dimensional stream of rotated vector mags. Computed by indexing along qBuff, rotating each of the 3 3D unit vectors
        //    (i, j, k) by the quaternion at the given index, and also rotating the 3 unit vectors by the previous quaternion. Then, the euclidean
        //    distance of each unit vector is calculated (between current rotation and previous rotation), and the net magnitude of euclidean 
        //    distances is calculated. Finally, a windowed sum is performed.
        //    NOTE: This method isn't truly "euclidean distance", and isn't a true integration of the data. It produces distinct patterns in
        //          movement data, so it is the prefered method of determining rep statistics.

        const minCutoff = 0.04;						// Define the minimum result magnitude for noise rejection
        const sumWindowSize = 4;					// Windowed sum size

        // Define 3 unit vectors (i, j, k)
        let n_x = {w: 0, x: 1.0,y: 0.0,z: 0.0};		// i unit vector
        let n_y = {w: 0, x: 0.0,y: 1.0,z: 0.0};		// j unit vector
        let n_z = {w: 0, x: 0.0,y: 0.0,z: 1.0};		// k unit vector

        var deltaMags: number[] = [];
        var thisWindowDataStream: number[] = [];

        for (var i = 1; i < qBuff.length; i++) {
            let thisQEntry = qBuff[i];
            let lastQEntry = qBuff[i - 1];

            var r_x = this.q_inverse_rotate(n_x, thisQEntry);
            var r_x_prev = this.q_inverse_rotate(n_x, lastQEntry);
            let rx_delta = {
                x: r_x.x - r_x_prev.x,
                y: r_x.y - r_x_prev.y,
                z: r_x.z - r_x_prev.z
            }
            let rx_delta_mag = Math.sqrt((rx_delta.x * rx_delta.x) + (rx_delta.y * rx_delta.y) + (rx_delta.z * rx_delta.z));
            rx_delta_mag = rx_delta_mag < minCutoff ? 0.0 : rx_delta_mag;


            var r_y = this.q_inverse_rotate(n_y, thisQEntry);
            var r_y_prev = this.q_inverse_rotate(n_y, lastQEntry);
            let ry_delta = {
                x: r_y.x - r_y_prev.x,
                y: r_y.y - r_y_prev.y,
                z: r_y.z - r_y_prev.z
            }
            let ry_delta_mag = Math.sqrt((ry_delta.x * ry_delta.x) + (ry_delta.y * ry_delta.y) + (ry_delta.z * ry_delta.z));
            ry_delta_mag = ry_delta_mag < minCutoff ? 0.0 : ry_delta_mag;


            var r_z = this.q_inverse_rotate(n_z, thisQEntry);
            var r_z_prev = this.q_inverse_rotate(n_z, lastQEntry);
            let rz_delta = {
                x: r_z.x - r_z_prev.x,
                y: r_z.y - r_z_prev.y,
                z: r_z.z - r_z_prev.z
            }
            let rz_delta_mag = Math.sqrt((rz_delta.x * rz_delta.x) + (rz_delta.y * rz_delta.y) + (rz_delta.z * rz_delta.z));
            rz_delta_mag = rz_delta_mag < minCutoff ? 0.0 : rz_delta_mag;

            let r_delta_mag = Math.sqrt((rx_delta_mag * rx_delta_mag) + (ry_delta_mag * ry_delta_mag) + (rz_delta_mag * rz_delta_mag));            

            // Perform windowed sum
            var r_delta_mag_sum = r_delta_mag;
            for (var w = 0; w < sumWindowSize; w++) {
                let adjIndex = i - w;
                if (deltaMags[adjIndex]) {
                    r_delta_mag_sum += deltaMags[adjIndex];
                }
            }

            deltaMags.push(r_delta_mag);

            thisWindowDataStream.push(r_delta_mag_sum);
        }

        // Push a "0" to the end of data stream to ensure a final inflection point exists in the case a peak is occuring at end of stream
        thisWindowDataStream.push(0.0);


        // 3. The meat an potatoes - analyze thisWindowDataStream to pick out rep data

        // Define analysis windows
        var risingWindow: 		RepWindow_t | null 	= null;
        var holdWindow: 		RepWindow_t | null 	= null;
        var concentricWindow: 	RepWindow_t | null 	= null;
        var firstPauseWindow: 	RepWindow_t | null 	= null;
        var secondPauseWindow: 	RepWindow_t | null 	= null;

        var lastRepEndIndex = 0;


        // 3.A Determind data inflection points (from positive slope to negative slope)
        var risingIndecides: number[] = [];
        var firstPeak_index = 0;
        var firstPeak_val = 0;
        var inflectionPoints_pos_to_neg: {val: number, index: number}[] = [];

        for (var j = 1; j < thisWindowDataStream.length; j++) {

            let thisPoint: number = thisWindowDataStream[j];
            let lastPoint: number = thisWindowDataStream[j - 1];
            let delta = thisPoint - lastPoint;
            let delta_is_positive = delta > 0;
            let delta_is_positive_or_zero = delta >= 0;


            // i.	 [rising detection]. Add to rising deltas
            if (delta_is_positive) {
                risingIndecides.push(j);
            }

            // ii.	 [first peak detection]. Check if recorded peak is max peak
            if (thisPoint > firstPeak_val) {
                firstPeak_val = thisPoint;
                firstPeak_index = j;
            }

            // iii.	 Find inflection points
            
            if (j < thisWindowDataStream.length - 1) {
                let nextPoint: number = thisWindowDataStream[j + 1];
                let delta_next = nextPoint - thisPoint;
                let delta_next_is_positive = delta_next > 0;

                if (delta_is_positive_or_zero === true && delta_next_is_positive === false) {
                    inflectionPoints_pos_to_neg.push({index: j - 1, val: thisPoint});
                } 
            }
        }

        if (generateStatsForRep_singleNode_verbose) {
	        // console.log("inflectionPoints_pos_to_neg", inflectionPoints_pos_to_neg)
	    }

        if (inflectionPoints_pos_to_neg.length > 0) {
            var maxInflection_a: {val: number, index: number} = {index: 0, val: 0};
            var maxInflection_a_index = 0;

            for (var j = 0; j < inflectionPoints_pos_to_neg.length; j++) {
                let thisInflection: {val: number, index: number} = inflectionPoints_pos_to_neg[j];
                if (thisInflection.val > maxInflection_a.val) {
                    maxInflection_a = thisInflection;
                    maxInflection_a_index = j;
                }
            }

            

            // Run again to get second largest inflection point
            inflectionPoints_pos_to_neg.splice(maxInflection_a_index, 1);
            var maxInflection_b: {val: number, index: number} = {index: 0, val: 0};
            var maxInflection_b_index = 0;

            if (inflectionPoints_pos_to_neg.length > 0) {
                for (var j = 0; j < inflectionPoints_pos_to_neg.length; j++) {
                    let thisInflection: {val: number, index: number} = inflectionPoints_pos_to_neg[j];
                    if (thisInflection.val > maxInflection_b.val && Math.abs(thisInflection.index - maxInflection_a.index) > 6) {
                        maxInflection_b = thisInflection;
                        maxInflection_b_index = j;
                    }
                }
            }
            if (maxInflection_b.index === 0 && maxInflection_b.val === 0) {
                maxInflection_b = {index: maxInflection_a.index + 7, val: maxInflection_a.val * 0.8};
            }

            var firstInflectionPoint = maxInflection_a;
            var lastInflectionPoint = maxInflection_b;

            // Make sure the peak with the lowest index is set to "first Inflection Point" - if not, flip 'em
            if (firstInflectionPoint.index > lastInflectionPoint.index) {
                var copiedPoint = JSON.parse(JSON.stringify(firstInflectionPoint));
                firstInflectionPoint = maxInflection_b;
                lastInflectionPoint = copiedPoint;
            }

            let holdWindowWidth = lastInflectionPoint.index - firstInflectionPoint.index;
            if (holdWindowWidth < 14) {
                lastInflectionPoint.index = firstInflectionPoint.index + 14;
            }

            holdWindow = {
                start_index: firstInflectionPoint.index,
                end_index: lastInflectionPoint.index,
                is_rep: false
            }


            // A [rising detection]. Look before this window for beginning rise location
            let thisAdj_startIndex = firstInflectionPoint.index;
            let thisAdj_endIndex = lastInflectionPoint.index;

            let posSlopeCount = 0;
            let negSlopeCount = 0;
            let posSlopeCount_threshold = 2;
            let slopeMinInflectionIndex_local = 0;
            let slopeMinInflectionIndex_local_temp = 0;

            for (var j = 1; j < thisAdj_startIndex; j++) {
                let thisPoint = thisWindowDataStream[j];
                let lastPoint = thisWindowDataStream[j - 1];
                let slopePos = thisPoint > lastPoint;
                if (slopePos === true) {
                    posSlopeCount += 1;
                    if (posSlopeCount >= posSlopeCount_threshold && lastPoint !== 0) {
                        slopeMinInflectionIndex_local = slopeMinInflectionIndex_local_temp;
                    }
                    if (posSlopeCount === 1) {
                        slopeMinInflectionIndex_local_temp = j - posSlopeCount_threshold;
                    }
                    negSlopeCount = 0;
                } else {
                    negSlopeCount += 1;
                    if (negSlopeCount >= 3) {
                        posSlopeCount = 0;
                    }
                    
                }
            }

            slopeMinInflectionIndex_local = slopeMinInflectionIndex_local < 0 ? 0 : slopeMinInflectionIndex_local;

            // D look inside of this window for min. value
            if (generateStatsForRep_singleNode_verbose) {
            	// console.log("Adjusted indecies: ", firstInflectionPoint.index, lastInflectionPoint.index, thisAdj_startIndex, thisAdj_endIndex, thisAdj_endIndex - thisAdj_startIndex);
            }

            var windowIntegration = 0;

            var minPoint = 1000;
            var minPoint_index = thisAdj_startIndex;
            for (var j = thisAdj_startIndex; j < thisAdj_endIndex; j++) {
                let thisPoint = thisWindowDataStream[j];
                if (thisPoint < minPoint) {
                    minPoint = thisPoint;
                    minPoint_index = j;
                }
                windowIntegration += thisPoint;
            }

            risingWindow = {
                start_index: slopeMinInflectionIndex_local,
                end_index: minPoint_index,
                is_rep: false
            }

            let eccentricTime = (risingWindow.end_index - risingWindow.start_index) * 0.032;


            // E. Look for first pause inside of purple window, after rising window's end

            let firstPause_startIndex = risingWindow.end_index; // global index
            var firstPause_endIndex = risingWindow.end_index; // temporarily local index

            let firstPauseIndexStart: number = risingWindow.end_index;
            for (var j = firstPauseIndexStart; j < holdWindow.end_index; j++) {
                if (thisWindowDataStream[j] <= 0.08) {
                    firstPause_endIndex = j;
                }
            }

            firstPauseWindow = {
                start_index: firstPause_startIndex,
                end_index: firstPause_endIndex,
                is_rep: false
            }

            let firstPauseFrames = firstPauseWindow.end_index - firstPauseWindow.start_index;
            let firstPauseTime = firstPauseFrames * 0.032;

            // F. Look after hold (purple) window for end to eccentric phase
            if (generateStatsForRep_singleNode_verbose) {
	            // console.log("thisWindowDataStream:", thisWindowDataStream.length, thisWindowDataStream)
			}

            var minStreamPoint = 100;
            var minStreamPointIndex = firstPauseWindow.end_index;
            for (var j = holdWindow.end_index; j < thisWindowDataStream.length - 1; j++) {
                if (thisWindowDataStream[j] < minStreamPoint) {
                    minStreamPoint = thisWindowDataStream[j];
                    minStreamPointIndex = j;
                }
            }

            concentricWindow = {
                start_index: firstPauseWindow.end_index,
                end_index: minStreamPointIndex <= holdWindow.end_index ? minStreamPointIndex + 5 : minStreamPointIndex - 1,
                is_rep: false
            }

            let concentricFrames = concentricWindow.end_index - concentricWindow.start_index;
            let concentricTime = concentricFrames * 0.032;


            // G. Get second pause
            var secondPauseTime = 0.0;
            if (lastRepEndIndex > 0) {
                let thisRepStartIndex = risingWindow.start_index;
                let secondPauseFrames = thisRepStartIndex - lastRepEndIndex;
                secondPauseTime = secondPauseFrames * 0.032;

                secondPauseWindow = {
                    start_index: lastRepEndIndex,
                    end_index: thisRepStartIndex,
                    is_rep: false
                }
            }


            // H. Look inside of rising window for ROM
            if (generateStatsForRep_singleNode_verbose) {
            	// console.log("\t\tSearching Q's", risingWindow.start_index + 1, risingWindow.end_index)
            }

            var integratedAngle = 0.0;

            let quatToRotate = {w: 0, x: 0, y: 1, z: 0};

            for (var j = risingWindow.start_index + 1; j < risingWindow.end_index; j++) {
                let thisQ: {w: number, x: number, y: number, z: number} = qBuff[j]
                let lastQ: {w: number, x: number, y: number, z: number} = qBuff[j - 1];

                let thisRotatedQ = this.q_rotate(quatToRotate, thisQ);
                let lastRotatedQ = this.q_rotate(quatToRotate, lastQ);

                let deltaAngle = this.angle_between_vector_quaternions(thisRotatedQ, lastRotatedQ);

                if (generateStatsForRep_singleNode_verbose) {
	                //// console.log("\t\t\t\tthisQ:", j, thisQ);
	                //// console.log("\t\t\t\tlastQ:", j, lastQ);
	                // console.log("\t\t\t\tdelta angle:", j, deltaAngle);
	            }

                integratedAngle += deltaAngle;
            }



            lastRepEndIndex = concentricWindow.end_index;

            let totalTime = (thisWindowDataStream.length - 1) * 0.032;

            if (generateStatsForRep_singleNode_verbose) {
	            // console.log("\t\tREP CONTAINING WINDOW:", startingIndex, endingIndex, endingIndex - startingIndex)
	            // console.log("\t\tWINDOWS:");
	            // console.log("\t\t\t\trisingWindow:", risingWindow.start_index, risingWindow.end_index, risingWindow.end_index - risingWindow.start_index);
	            // console.log("\t\t\t\tholdWindow:", holdWindow.start_index, holdWindow.end_index, holdWindow.end_index - holdWindow.start_index);
	            // console.log("\t\t\t\tconWindow:", concentricWindow.start_index, concentricWindow.end_index, concentricWindow.end_index - concentricWindow.start_index);
	            // console.log("\t\t\t\tfirstPauseWindow:", firstPauseWindow.start_index, firstPauseWindow.end_index, firstPauseWindow.end_index - firstPauseWindow.start_index);
			}


			// console.log("\t\tTIMING:", Math.floor(eccentricTime * 1000) / 1000, Math.floor(firstPauseTime * 1000) / 1000, Math.floor(concentricTime * 1000) / 1000, Math.floor(secondPauseTime * 1000) / 1000)

            // POWER
            let reachLength = 0.35;     // in meters
            let bodyWeight = 155;       // in pounds
            let bodyWeight_metric = bodyWeight / 2.21;
            let g_mag = 9.81;           // m/s^2

            let power = (0.5 * bodyWeight_metric * g_mag * reachLength) / concentricTime;

            // console.log(`\t\tPOWER: ${Math.floor(power * 100) / 100}W`)

            // AVERAGE CONCENTRIC SPEED
            let velocity = (reachLength * (integratedAngle / 57.2957795131)) / eccentricTime;
            //let velocity = reachLength / concentricTime;    // Push ups
            // console.log(`\t\tVELOCITY: ${Math.floor(velocity * 100) / 100}m/s`)

            // console.log(`\t\tROM: ${Math.floor(integratedAngle * 100) / 100}º`)

            this.streamBuffer[locationIndex].repCount = this.streamBuffer[locationIndex].repCount + 1;

            // Configure a RepStat
            let numCompletedRepsAtLocation = this.streamBuffer[locationIndex].repCount;
            let mandatoryLocations = this.motionInfo.counting.minimum_monitor_locations;

            // Check if a rep already exists
            if (this.repStats[numCompletedRepsAtLocation] === undefined) {
            	// This rep has not been detected by any other Nodes. Make a new rep with this rep's info in stats_all_nodes
            	let rep: RepStats_t = {
					startTime: Date.now() - totalTime,
					power: power,
					ROM: integratedAngle,
					peakVelocity: velocity,
					ROM_pos: integratedAngle,
					ROM_neg: integratedAngle,
					formAccuracy: 100,
					tempo: {
						first_pause: firstPauseTime,
						second_pause: secondPauseTime,
						concentric: concentricTime,
						eccentric: eccentricTime
					},	
					stats_all_nodes: [
						{
							location: locationIndex,
							timestamp: Date.now(),
							power: power,
							ROM: integratedAngle,
							peakVelocity: velocity,
							tempo: {
								first_pause: firstPauseTime,
								second_pause: secondPauseTime,
								concentric: concentricTime,
								eccentric: eccentricTime
							}
						}
					],
					flag: -1,
					velocityStream: [],
					velocityStream_all: [],
					velocityStream_3d: [],
					
					motionData: [],
					motionData_all: [],
				};

				this.repStats.push(rep);

            } else {
            	// Rep has already been detected by another Node. Re-configure rep stat to take average of each Node's finding.
            	let thisRep = this.repStats[numCompletedRepsAtLocation];

            	var stats_all_nodes_temp: any[] = [];
            	if (thisRep.stats_all_nodes !== undefined) {
            		for (var n = 0; n < thisRep.stats_all_nodes.length; n++) {
            			stats_all_nodes_temp.push(thisRep.stats_all_nodes[n]);
            		}
            	}

            	stats_all_nodes_temp.push({
					location: locationIndex,
					timestamp: Date.now(),
					power: power,
					ROM: integratedAngle,
					peakVelocity: velocity,
					tempo: {
						first_pause: firstPauseTime,
						second_pause: secondPauseTime,
						concentric: concentricTime,
						eccentric: eccentricTime
					}
				});
            	

            	this.repStats[numCompletedRepsAtLocation] = {
					startTime: Date.now() - totalTime,
					power: thisRep.power === 0 ? power : (thisRep.power + power) / 2,
					ROM: thisRep.ROM === 0 ? integratedAngle : (thisRep.ROM + integratedAngle) / 2,
					peakVelocity: thisRep.peakVelocity === undefined || thisRep.peakVelocity === 0 ? velocity : (thisRep.peakVelocity + velocity) / 2,
					ROM_pos: thisRep.ROM_pos === undefined || thisRep.ROM_pos === 0 ? integratedAngle : (thisRep.ROM_pos + integratedAngle) / 2,
					ROM_neg: thisRep.ROM_pos === undefined || thisRep.ROM_pos === 0 ? integratedAngle : (thisRep.ROM_pos + integratedAngle) / 2,
					formAccuracy: 100,
					tempo: {
						first_pause: thisRep.tempo.first_pause === 0 ? firstPauseTime : (thisRep.tempo.first_pause + firstPauseTime) / 2,
						second_pause: thisRep.tempo.second_pause === 0 ? secondPauseTime : (thisRep.tempo.second_pause + secondPauseTime) / 2,
						concentric: thisRep.tempo.concentric === 0 ? concentricTime : (thisRep.tempo.concentric + concentricTime) / 2,
						eccentric: thisRep.tempo.eccentric === 0 ? eccentricTime : (thisRep.tempo.eccentric + eccentricTime) / 2, 
					},	
					stats_all_nodes: stats_all_nodes_temp,
					flag: -1,
					velocityStream: [],
					velocityStream_all: [],
					velocityStream_3d: [],
					
					motionData: [],
					motionData_all: [],
				};

            }




            

			//// console.log("V2 attempt: \t ", rep.tempo);

			
			//// console.log(`> MOTION MANAGER: List of reps: `);
			//// console.log(this.repStats);


			if (this.NodeManagerInstance !== null) {
				this.NodeManagerInstance.repUpdated(this.repStats);
			}



            // setVelocityLog(velocityLog.concat(velocity));
            // setPowerLog(powerLog.concat(power));
            // setCELog(ceLog.concat(integratedAngle)); //(concentricTime / eccentricTime));
            // setDurationLog(durationLog.concat(totalTime));
        }
	}

	

	private completedAlternatingRep(data: RepStats_t) {

		this.finishRep(data);
	}

	private completedLocations: number[] = [];
	private completedLocationsData: RepStats_t[] = [];

	public nodeDetectedRepComplete(repData: RepStats_t, node: NodeInstance) {

		let typeDescriptors = ["No alternating detection", "Alternating sides", "Independant Sides", "ERROR"];

		//// console.log(`> MOTION MANAGER: nodeDetectedRepComplete | checking rep with alternating type: ${typeDescriptors[this.motionInfo.alternating_type]} (${this.motionInfo.alternating_type}) and the following locations list:`);
		//// console.log(this.motionInfo.monitor_locations);
		//// console.log(`> MOTION MANAGER: nodeDetectedRepComplete | monitor_locations.length = ${this.motionInfo.monitor_locations.length}`);
		//// console.log(`> MOTION MANAGER: nodeDetectedRepComplete | alternating type = ${this.motionInfo.alternating_type}`);

		switch (this.motionInfo.counting.type) {
			case "mirrored":
				if (this.motionInfo.counting.minimum_monitor_locations.length === 1) {
	        		// One Node monitored for rep completion. If any rep detected on this Node, advance rep count
	        		let locationIndex = node.getLocationIndex();
	        		if (locationIndex === this.motionInfo.counting.minimum_monitor_locations[0]) {
	        			this.finishRep(repData);
	        		}
	        	} else if (this.motionInfo.counting.minimum_monitor_locations.length > 1) {
	        		// A portion of a multi-Node rep has been completed (rep where multiple limbs will count rep; ie. barbell shoulder press, barbell curl)
	        		var okayToCount = false;
	        		let nodeLocation = node.getLocationIndex();
	        		for (var i = 0; i < this.motionInfo.counting.minimum_monitor_locations.length; i++) {
	        			let thisLocation = this.motionInfo.counting.minimum_monitor_locations[i];
	        			if (thisLocation === nodeLocation) {
	        				okayToCount = true;
	        			}
	        		}

	        		if (okayToCount === true) {
	        			// console.log(`> MOTION MANAGER: Counting rep with location index ${nodeLocation}`);
	        			this.completedMultiNodeRep(repData, nodeLocation);
	        		} else {
	        			// console.log(`> MOTION MANAGER: Skipping rep with location index ${nodeLocation}`);
	        		}
	        		
	        	} else {
	        		// Error handler - no nodes added to monitoring list. Go ahead and count the rep anyway.
	        		this.finishRep(repData);
	        	}
				break;
			case "alternating":
				// Alternating Sides. Check to see if node is in list to count
	        	var okayToCount = false;
	    		let nodeLocation = node.getLocationIndex();
	    		for (var i = 0; i < this.motionInfo.counting.minimum_monitor_locations.length; i++) {
	    			let thisLocation = this.motionInfo.counting.minimum_monitor_locations[i];
	    			if (thisLocation === nodeLocation) {
	    				okayToCount = true;
	    			}
	    		}
	    		if (okayToCount === true) {
	    			this.completedAlternatingRep(repData);
	    		}
				break;
			case "single_side":
				// Independant sides. Count any reps with locations included in the locations list
	        	var okayToCount = false;
	        	if (this.motionInfo.counting.minimum_monitor_locations.length > 0) {
	        		let nodeLocation = node.getLocationIndex();
	        		for (var i = 0; i < this.motionInfo.counting.minimum_monitor_locations.length; i++) {
	        			let thisLocation = this.motionInfo.counting.minimum_monitor_locations[i];
	        			if (thisLocation === nodeLocation) {
	        				okayToCount = true;
	        			}
	        		}
	        	}
	        	
	        	if (okayToCount === true) {
	        		this.finishRep(repData);
	        	}

				break;
			default:
				// console.log("> MOTION MANAGER [nodeDetectedRepComplete()] | Unhandled counting type found in switch case:", this.motionInfo.counting.type);
		}
	}


	private completedMultiNodeRep(data: RepStats_t, location_index: number) {

		// console.log(`> MOTION MANAGER: completedMultiNodeRep | _____________________________ PARTIAL REP _________________________________ | location index = ${location_index}`);

		// if (this.completedLocations.length === 0 || this.completedLocations.indexOf(location_index) !== -1) {
		// 	// First partial rep completed, or partial rep has been completed before. Likely a stupid problem with one of the Nodes
		// 	// in the set.

		// console.log(`> MOTION MANAGER: completedMultiNodeRep | completed locations length: ${this.completedLocations.length}, index of location index <${location_index}>: ${this.completedLocations.indexOf(location_index)}`);
			


		// 	//this.finishRep(data);

		// } 
		this.completedLocationsData.push(data);
		this.completedLocations.push(location_index);

		

		var isComplete = true;



		/*
		TODO: REMOVE COMMENT
		for (var i = 0; i < this.motionInfo.monitor_locations.length; i++) {
			let thisLocationIndex =  this.motionInfo.monitor_locations[i].location_index;
			if (this.completedLocations.indexOf(thisLocationIndex) === -1) {
				// console.log(`> MOTION MANAGER: completedMultiNodeRep | Setting isComplete false for location ${location_index}`);
				isComplete = false;
			}
		}
		*/



		if (isComplete) {
			// console.log(`> MOTION MANAGER: completedMultiNodeRep | _____________________________ !~!~!REP COMPLETED!~!~! _____________________________`);
			
			// var tempRepStats: RepStats_t = {
			// 	startTime: 0,
			// 	power: 0,
			// 	ROM: 0,
			// 	formAccuracy: 0,
			// 	tempo: {
			// 		concentric: -1,
			// 		eccentric: -1,
			// 		first_pause: -1,
			// 		second_pause: -1
			// 	},
			// 	weight: data.weight,
			// 	isKG: data.isKG
			// }

			// if (this.completedLocationsData.length > 0) {
			// 	var avg_startTime = 0;
			// 	var avg_power = 0;
			// 	var avg_ROM = 0;
			// 	var avg_formAccuracy = 0;

			// 	var avg_tempo = {
			// 		concentric: 0,
			// 		eccentric: 0,
			// 		first_pause: 0,
			// 		second_pause: 0
			// 	};

			// 	for (var i = 0; i < this.completedLocationsData.length; i++) {
			// 		let thisRepStat: RepStats_t = this.completedLocationsData[i];

			// 		avg_startTime += thisRepStat.startTime;
			// 		avg_power += thisRepStat.power;
			// 		avg_ROM += thisRepStat.ROM;
			// 		avg_formAccuracy += thisRepStat.formAccuracy;
			// 		avg_tempo = {
			// 			first_pause: avg_tempo.first_pause + thisRepStat.tempo.first_pause,
			// 			second_pause: avg_tempo.second_pause + thisRepStat.tempo.second_pause,
			// 			eccentric: avg_tempo.eccentric + thisRepStat.tempo.eccentric,
			// 			concentric: avg_tempo.concentric + thisRepStat.tempo.concentric,
			// 		}
			// 	}

			// 	avg_startTime /= this.completedLocationsData.length;
			// 	// Power is the summation of all limbs; don't average this stat
			// 	avg_ROM /= this.completedLocationsData.length;
			// 	avg_formAccuracy /= this.completedLocationsData.length;
			// 	avg_tempo = {
			// 		first_pause: avg_tempo.first_pause / this.completedLocationsData.length,
			// 		second_pause: avg_tempo.second_pause / this.completedLocationsData.length,
			// 		eccentric: avg_tempo.eccentric / this.completedLocationsData.length,
			// 		concentric: avg_tempo.concentric / this.completedLocationsData.length,
			// 	}

			// 	tempRepStats.startTime = avg_startTime;
			// 	tempRepStats.power = avg_power;
			// 	tempRepStats.ROM = avg_ROM;
			// 	tempRepStats.formAccuracy = avg_formAccuracy;
			// 	tempRepStats.tempo = avg_tempo;

			// } else {
			// 	tempRepStats = data;
			// }

			// this.finishRep(tempRepStats);
			// this.completedLocations = [];
			// this.completedLocationsData = [];
		}

		// if (this.completedLocations.indexOf(location_index) === -1) {
		// 	// This body location has not completed a rep yet - add to completedLocations and check if all are present

		// 	// console.log(`> MOTION MANAGER: Will add location ${location_index} to array of length ${this.completedLocations.length}`);
		// 	this.completedLocations.push(location_index);

		// 	var isComplete = true;
		// 	for (var i = 0; i < this.motionInfo.monitor_locations.length; i++) {
		// 		let thisLocationIndex =  this.motionInfo.monitor_locations[i].location_index;
		// 		if (this.completedLocations.indexOf(thisLocationIndex) === -1) {
		// 			// console.log(`> MOTION MANAGER: Setting isComplete false for location ${location_index}`);
		// 			isComplete = false;
		// 		}
		// 	}

		// 	if (isComplete === true) {

		// 		// console.log(`> MOTION MANAGER: Complete with location ${location_index}! Will finish rep.`);

		// 		this.finishRep(data);

		// 		this.completedLocations = [];
		// 	}

		// } else {
		// 	// console.log(`> MOTION MANAGER: Location ${location_index} is already in array of length ${this.completedLocations.length}`);
		// }
	}

	private getAngle(starting_q: any, peak_q: any) {
		let quaternionInnerProduct = (starting_q.w * peak_q.w) + (starting_q.x * peak_q.x) + (starting_q.y * peak_q.y) + (starting_q.z * peak_q.z);
		let quaternionInnerProductSquared_2 = 2 * quaternionInnerProduct * quaternionInnerProduct;
		let d_theta = Math.acos(quaternionInnerProductSquared_2 - 1);

		return d_theta;
	}

	private finishRep(data: RepStats_t) {

		


		// var d_theta = 0.0;

		// var d = new Date();
		// var n = d.getTime();

		// let riseTime_temp = data.tempo.concentric;
		// let riseTimeCap_high = 300;
		// let riseTimeCap_low = 0.2;
		// let riseTime = riseTime_temp >= riseTimeCap_high ? riseTimeCap_high : (riseTime_temp <= riseTimeCap_low ? riseTimeCap_low : riseTime_temp);
		// let holdUpTime = data.tempo.first_pause;
		// let fallTime = data.tempo.eccentric;
		// let holdDownTime = data.tempo.second_pause;

		// // Generate Power Calculation
		// var power = 0.0;

		// var metricMass = 0.0; 
		// if (this.motionInfo.is_bodyweight === true ) {
		// 	// If bodyweight exists for client, use the given weight adjusted t KGs
		// 	metricMass = this.client.weight === undefined || this.client.weight === 0 ? 65 : (this.client.isMetric ? this.client.weight : this.client.weight * 2.2046);
		// 	// Scale by given bodyweight percentage to adjust for lever effects or coutnerweights
		// 	metricMass *= this.motionInfo.bodyweight_percentage;

		// } else {
		// 	// Adjust external weight to KGs
		// 	metricMass = this.unitsKG ? this.currentWeight : this.currentWeight / 2.2046;
		// 	// Scale by given weight percentage to adjust for lever effects or coutnerweights
		// 	metricMass *= this.motionInfo.weight_percentage;
		// }
		
		// let weight = metricMass * 9.80665;
			
		// if (this.exerciseIsRotational) {
		// 	// Use rotational power output calculation 

		// 	//let starting_q =  data.start_motion_data !== undefined ? data.start_motion_data.quaternion : {w:1.0,x:0,y:0,z:0};
		// 	//let peak_q = data.end_motion_data !== undefined ? data.end_motion_data.quaternion : {w:1.0,x:0,y:0,z:0};

		// 	//let quaternionInnerProduct = (starting_q.w * peak_q.w) + (starting_q.x * peak_q.x) + (starting_q.y * peak_q.y) + (starting_q.z * peak_q.z);
		// 	//let quaternionInnerProductSquared_2 = 2 * quaternionInnerProduct * quaternionInnerProduct;
		// 	//d_theta = Math.acos(quaternionInnerProductSquared_2 - 1);
		// 	d_theta = (data.ROM * Math.PI / 180); //this.getAngle(starting_q, peak_q);

		// 	//let starting_t = data.times.positive;
		// 	//let peak_t = data.times.up;
		// 	//let d_t = (peak_t - starting_t) / 1000;
		// 	var d_t = riseTime; // / 1000;
		// 	d_t = d_t < 0.01 ? 0.01 : d_t;

		// 	let omega = Math.abs(d_theta / d_t);


		// 	let rotationalPow = weight * this.leaverLength;

		// 	power = Math.floor((rotationalPow * omega) * 1000) / 1000;
		// 	power = power > 4000 ? 4000 : power;

		// 	//// console.log(`POWER OUTPUT: ${power}W | d_theta: ${d_theta} | d_t: ${d_t} | weight: ${weight}N`);

		// } else {
		// 	// Use linear power output calculation
		// }

		// // Generate ROM Calculation
		// let ROM = 0.0;
		// //if (this.exerciseIsRotational) {
		// 	let fullRomAngle = 75;
		// 	let fullRomAngle_rad = (fullRomAngle) * Math.PI / 180;
		// 	//let fullRomAngleRadians = fullRomAngle * 3.1415926536 / 180;
		// 	ROM = d_theta * 180 / Math.PI; //Math.ceil((d_theta / fullRomAngle_rad) * 100000) / 100000;
		// 	//ROM = ROM > 1.32 ? 1.32 : ROM;
		// //}
		// let d_theta_deg = d_theta * 180 / Math.PI;
		// var v2_attempt = d_theta_deg / riseTime;
		// v2_attempt /= 57.2957795131; // Convert to rad
		// v2_attempt *= 0.2667 // calc tangential velocity at end of 12" arm

		// //// console.log("V2 attempt:", Math.floor(v2_attempt * 100) / 100, d_theta_deg, riseTime, data.ROM_neg, data.ROM_pos)

		// // Compose data into RepStat object
		// let rep: RepStats_t = {
		// 	startTime: data.startTime,
		// 	power: power,
		// 	ROM: Math.round(ROM * 1000 * 1.000) / 1000,
		// 	ROM_pos: data.ROM_pos === undefined ? -1 : data.ROM_pos,
		// 	ROM_neg: data.ROM_neg === undefined ? -1 : data.ROM_neg,
		// 	formAccuracy: 100,
		// 	tempo: {
		// 		first_pause: Math.round(holdUpTime * 10) / 10,
		// 		second_pause: Math.round(holdDownTime * 10) / 10,
		// 		concentric: Math.round(riseTime * 10) / 10,
		// 		eccentric: Math.round(fallTime * 10) / 10
		// 	},	
		// 	flag: -1,
		// 	velocityStream: data.velocityStream === undefined ? [] : data.velocityStream,
		// 	velocityStream_all: data.velocityStream_all === undefined ? [] : data.velocityStream_all,
		// 	velocityStream_3d: data.velocityStream_3d === undefined ? [] : data.velocityStream_3d,
		// 	peakVelocity: v2_attempt,//data.peakVelocity === undefined ? -1 : data.peakVelocity,
		// 	motionData: data.motionData === undefined ? [] : data.motionData,
		// 	motionData_all: data.motionData_all === undefined ? [] : data.motionData_all,
		// 	raw_stream_data: data.raw_stream_data === undefined ? [] : data.raw_stream_data
		// };

		// //// console.log("V2 attempt: \t ", rep.tempo);

		// this.repStats.push(rep);
		// //// console.log(`> MOTION MANAGER: List of reps: `);
		// //// console.log(this.repStats);


		// if (this.NodeManagerInstance !== null) {
		// 	this.NodeManagerInstance.repUpdated(this.repStats);
		// }
	}



	// QUATERNION FUNCTIONS

    private q_normalize(q: {w: number, x: number, y: number, z: number}) 
    {
        var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};
        const quaternionMagSq = Math.sqrt((q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z));
        result.w = q.w / quaternionMagSq;
        result.x = q.x / quaternionMagSq;
        result.y = q.y / quaternionMagSq;
        result.z = q.z / quaternionMagSq;

        return result;
    }

    private q_multiply(q: any, p: any)
    {
        var result = {w: 1, x: 0, y: 0, z:0};

        result.w = q.w*p.w - q.x*p.x - q.y*p.y - q.z*p.z;
        result.x = q.x*p.w + q.w*p.x - q.z*p.y + q.y*p.z;
        result.y = q.y*p.w + q.z*p.x + q.w*p.y - q.x*p.z;
        result.z = q.z*p.w - q.y*p.x + q.x*p.y + q.w*p.z;

        return result;

    }

    private q_invert(q: any)
    {
        var result = {w: 1, x: 0, y: 0, z:0};
        
        const quaternionMagSq = (q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z);
        result.w = q.w / quaternionMagSq;
        result.x = -1 * q.x / quaternionMagSq;
        result.y = -1 * q.y / quaternionMagSq;
        result.z = -1 * q.z / quaternionMagSq;

        return result;
    }

    private angle_between_quaternions(q_a: {w: number, x: number, y: number, z: number}, q_b: {w: number, x: number, y: number, z: number}) {
        let q_inv = this.q_invert(q_a);
        q_inv.x = q_inv.x * -1;
        q_inv.y = q_inv.y * -1;
        q_inv.z = q_inv.z * -1;
        let res = this.q_multiply(q_inv, q_b);

        //res.w = res.w > 1.0 ? 1.0 : (res.w < -1.0 ? 1.0 : res.w);

        //// console.log(res.w, Math.acos(res.w));
        let vectorMag = Math.sqrt((res.x * res.x) + (res.y * res.y) + (res.z * res.z));
        let d_theta = 2.0 * Math.acos(res.w) * 57.2957795131;//2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

        return d_theta;
    }

    private angle_between_vector_quaternions(v_a: {w: number, x: number, y: number, z: number}, v_b: {w: number, x: number, y: number, z: number}) {
        
        let dp = ((v_a.x * v_b.x) + (v_a.y * v_b.y) + (v_a.z * v_b.z));
        let vectorMag_a = Math.sqrt((v_a.x * v_a.x) + (v_a.y * v_a.y) + (v_a.z * v_a.z));
        let vectorMag_b = Math.sqrt((v_b.x * v_b.x) + (v_b.y * v_b.y) + (v_b.z * v_b.z));


        let prod = dp / (vectorMag_a * vectorMag_b)

        //// console.log("PROD:", prod, Math.acos(prod), dp, vectorMag_a, vectorMag_b, vectorMag_a * vectorMag_b)

        let d_theta = Math.acos(prod) * 57.2957795131;//2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

        if (isNaN(d_theta) === true) {
            if (prod > 1) {
                return 0;
            } else if (prod < -1) {
                return 180;
            } else {
                return 0;
            }
        }
        return d_theta;
    }

    private q_rotate(q: any, w: any)
    {
        var w_inv = {w: 1, x: 0, y: 0, z:0};
        var a_world = {w: 1, x: 0, y: 0, z:0};
        var w_a = {w: 1, x: 0, y: 0, z:0};
        var a_body = {w: 1, x: 0, y: 0, z:0};

        a_body    = this.q_normalize(q);
        w_inv     = this.q_invert(this.q_normalize(w));

        w_a       = this.q_multiply(w, a_body);
        a_world   = this.q_multiply(w_a, w_inv);

        return a_world;
    }

    private q_inverse_rotate(q: {w: number, x: number, y: number, z: number}, w: {w: number, x: number, y: number, z: number})
    {
        var a_world: {w: number, x: number, y: number, z: number} = q;
        var w_inv: {w: number, x: number, y: number, z: number} = this.q_invert(w);

        var w_a: {w: number, x: number, y: number, z: number} = this.q_multiply(w_inv, a_world);
        var a_body: {w: number, x: number, y: number, z: number} = this.q_multiply(w_a, w);

        return a_body;
    }


    // END QUATERNION FUNCTIONS


}

export default MotionManager;