import React, { useState, useEffect } from 'react';
import './ToggleSwitch.css';


function ToggleSwitch(props: any) {

    const [isOn, setIsOn] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.forceState !== undefined) {
            setIsOn(props.forceState)
        }
    }, [props.forceState])

    useEffect(() => {
        if (props.isDisabled !== undefined) {
            setDisabled(props.isDisabled)
        }
    }, [props.isDisabled])

    function initialize() {
        if (props.forceState !== undefined) {
            setIsOn(props.forceState)
        }
    }

    function toggleLeSwitch() {
        props.stateChanged(!isOn);
        setIsOn(!isOn);
    }

	return (
		<div onClick={() => toggleLeSwitch()} className={`toggle-switch ${isOn ? 'toggle-switch-on' : ''}`}>
		    <div className={`toggle-switch-circle ${isOn ? 'toggle-switch-circle-on' : ''}`}/>
		</div>
	)
}

export default ToggleSwitch;