import React, { useState } from 'react';
import './Name.css';
import TextInput from '../../components/Base/TextInput';
import DualSelector from '../../components/Base/DualSelector';
import { IonReactRouter } from '@ionic/react-router';


function Company(props: any) {

  const [isValid, setIsValid] = useState(false);
  const [trainsAtGym, setTrainsAtGym] = useState(true);

  function textInputChanged(text: string) {
    let valid_temp = text.split("").length >= 3;
    setIsValid(valid_temp);
    props.validation(valid_temp);

    if (valid_temp === true) {
      props.resultUpdated(text);
    }
  }

  function optionChanged(toPosition: number) {
    if (toPosition === 0) {
      setTrainsAtGym(true);
      props.validation(isValid);
    } else if (toPosition === 1) {
      setTrainsAtGym(false);
      props.resultUpdated("");
      props.validation(true);
    }
  }

  return (
    <div className="header-container">
      <h1>Your account is all ready to go!</h1>
      <h1>Before we begin, let's talk about your coaching style</h1>
      <p>If you're part of a facility or organization, fill in the name below. If you coach from your own gym, just select "I coach at home"</p>
      <div>
        <DualSelector optionAText="I Coach at a Gym" optionBText="I Coach at Home" optionChanged={(e: number) => optionChanged(e)}/>
        <div hidden={!trainsAtGym}>
          <TextInput placeholder="My training organization" input="" inputChanged={(e: string) => textInputChanged(e)}/>
        </div>
      </div>
   </div>
  )
}

export default Company;