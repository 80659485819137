import React, { useState, useEffect } from 'react';
import './ClientPicker.css';
import { CSSTransition } from 'react-transition-group';
import { IonInput } from '@ionic/react';

import firebase from '../../../../Firebase';

import Client_t from '../../../../Interfaces/Client_t';

import ClientPickerCell from './ClientPickerCell';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

function ClientPicker(props: any) {

    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};
    let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];
    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];


    const [searchString, setSearchString] = useState("");
    const [showClientList, setShowClientList] = useState(false);
    const [clientNameFocus, setClientNameFocuse] = useState(false);
    const [clients, setClients] = useState<Client_t[]>([]);
    const [allClients, setAllClients] = useState<Client_t[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
    const [hardsetClient, setHardsetClient] = useState(false);
    const [imgUrl, setImgUrl] = useState('');

    const [forcedClientID, setForcedClientID] = useState("");

    const [initSet, setInitSet] = useState(false);

    useEffect(() => {
        checkForForcedClient()
    }, [props.forceClientID])

    useEffect(() => {
        checkForForcedClient()
    }, [props.allClients])

    function checkForForcedClient() {
        if (!props.forceClientID || props.forceClientID === "") return;
        if (selectedClient.id === props.forceClientID) return; 
        setForcedClientID(props.forceClientID);
        for (var i = 0; i < allClients.length; i++) {
            if (allClients[i].id === props.forceClientID) {
                clientSelected(allClients[i])
            }
        }
    }
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getPersonalClients();
        checkForForcedClient()
    }

    function getPersonalClients() {
        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }


        /*************************************************/
        //setIsLoading(true);    // TODO; uncomment

        let testClient: Client_t = {
            first_name:"Connor", 
            last_name:"Holowachuk", 
            full_name:"Connor Holowachuk", 
            id: "abc123", 
            gender: "m",
            email: "connor_chuk@yahoo.com",
            weight: 155,
            height: 72,
            isMetric: false,
            birthdate: "00-00-0000",
            tags: [goalDescriptors[0], "Gain Muscle"],
            isSelected: true
        };

        //setSelectedClient(testClient);



        /*************************************************/



        // console.log("CLIENT LIST | getPersonalClients()");

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_clients/${thisUser.uid}/clients`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let clientsList = snapshot.val();
                let clientListLength = Object.keys(clientsList).length;
                let temp: Client_t[] = [];
                for (const key of Object.keys(clientsList)) {
                    let thisClient = clientsList[key];

                    let thisClientRef = database.ref(`user_profile/${key}`)
                    thisClientRef.on('value', function(snapshotClient) {
                        if (snapshotClient.exists() === true) {
                            let thisUser = snapshotClient.val();
                            let newClient: Client_t = {
                                first_name:thisUser.first_name, 
                                last_name:thisUser.last_name, 
                                full_name:thisUser.full_name, 
                                id: key, 
                                gender: thisUser.gender,
                                email: thisUser.email,
                                weight: thisUser.weight,
                                height: thisUser.height,
                                isMetric: thisUser.units_kg,
                                birthdate: thisUser.birth_date,
                                tags: [goalDescriptors[0], "Gain Muscle"],
                                isSelected: false
                            };
                            temp.push(newClient);

                            if (temp.length === clientListLength) {
                                // all clients have been pulled - set the state vars to temp
                                setClients(clients.concat(temp));
                                setAllClients(allClients.concat(temp));
                                setIsLoading(false);

                            }
                        }
                    });

                }

            } else {
                //setClientsBlank(true);
                setIsLoading(false);
            }
        });
    }


    function inputChanged(toString: any, forInput: string) {
        setSearchString(toString);
        var temp = [];
        for (var i = 0; i < allClients.length; i++) {
            let searchString = toString.toUpperCase();
            let nameString = allClients[i].full_name.toUpperCase();
            let positiveSearch_name = nameString.includes(searchString);

            var positiveSearch_email = false;
            let emailString = allClients[i].email;
            if (emailString != undefined && emailString != null) {

            }

            if (positiveSearch_name === true) {
                temp.push(allClients[i]);
            }
        }

        setClients(temp);
        if (toString.split('').length > 2) {
            if (showClientList === false) {
                setShowClientList(true);
            }
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            searchButtonPressed()
        }
    }

    function searchButtonPressed() {

    }

    function removeClient() {
        setSelectedClient(initClient);
        props.clientUpdated("");
    }

    function clientSelected(c: Client_t) {
        setShowClientList(false);
        setSelectedClient(c);
        getImage(c.id);
        setSearchString("");

        props.clientUpdated(c.id);
        //pullClientFolders(c.id);
    }

    function getImage(forID: string) {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

	return (
		<div className={ "client-picker-row-input-container" }>
			<div className="client-picker-row-input-container">
                <div hidden={selectedClient.first_name !== ""} className={`client-picker-client-name-input-container ${clientNameFocus ? "client-picker-client-name-input-container-focus" : ""}`}>
                    <IonInput value={searchString} 
                        id="assign-to-client-input"
                        autofocus={true}
                        placeholder="Search for a Athlete by name..."
                        inputmode="text"
                        clearOnEdit={true}
                        type="text"
                        onKeyPress={e => inputUpdated(e.key)}
                        onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                        onFocus={(e) => setClientNameFocuse(true)}
                        onBlur={(e) => setClientNameFocuse(false)}/>
                    <div hidden={showClientList} onClick={() => setShowClientList(true)} className="client-picker-client-name-input-arrow-icon-container">
                        <ExpandMore className="client-picker-client-name-input-arrow-icon"/>
                    </div>
                    <div hidden={!showClientList} onClick={() => setShowClientList(false)} className="client-picker-client-name-input-arrow-icon-container">
                        <ExpandLess className="client-picker-client-name-input-arrow-icon"/>
                    </div>
                </div>
                <div hidden={selectedClient.first_name === ""} className="client-picker-client-name-input-container client-picker-client-name-input-container-selected-client">
                    <div className="client-card-image-container">
                        <img  
                            className="client-card-image" 
                            hidden={imgUrl === ''}
                            src={imgUrl} 
                            alt={`Image for ${selectedClient.first_name}`} />
                    </div>
                    <div className="client-picker-client-name-input-container-selected-client-text-container">
                        <h4>{selectedClient.full_name}</h4>
                    </div>
                    <div hidden={hardsetClient} onClick={() => removeClient()} className="client-picker-client-name-input-container-selected-client-remove-container">
                        <Close className="client-picker-client-name-input-container-selected-client-remove-icon"/>
                    </div>    
                </div>
            </div>
            <CSSTransition in={showClientList} timeout={200} classNames="menu" unmountOnExit>
                <div className="client-picker-client-name-menu-container">
                    {
                        clients.map((item: Client_t, index: number) => (
                            <ClientPickerCell 
                                client={item} 
                                index={index} 
                                clientSelected={() => clientSelected(item)}/>
                        ))
                    }
                </div>
            </CSSTransition>
		</div>
	)
}

export default ClientPicker;