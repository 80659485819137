import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';

import './ClientGoalProgressCardLogCell.css';


function ClientGoalProgressCardLogCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [loggerName, setLoggerName] = useState("Loading...");
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        // Pull logger's full_name
        // Attempt searching 

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        if (trainerUID === props.logData.logged_by) {
            
            let ref = database.ref(`personal_trainer_profile/${props.logData.logged_by}/full_name`); // Change to database path
            ref.on('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    setLoggerName(data !== undefined ? data : "Trainer");                    
                } else {
                    setLoggerName("Trainer");     
                }
            });
        } else {
            // Try clients first
            let ref = database.ref(`user_profile/${props.logData.logged_by}/full_name`); // Change to database path
            ref.on('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    setLoggerName(data !== undefined ? data : "You");                    
                } else {
                    // could not find in client list -- search inside of trainer profiles
                    let ref = database.ref(`personal_trainer_profile/${props.logData.logged_by}/full_name`); // Change to database path
                    ref.on('value', function(snapshot) {
                        if (snapshot.exists() === true) {
                            let data = snapshot.val();
                            setLoggerName(data !== undefined ? data : "Trainer");                    
                        } else {
                            setLoggerName("Trainer")
                        }
                    });
                }
            });
        }

        
    }

    function getDateString() {

        let forTimestamp: number = props.logData.timestamp !== undefined ? props.logData.timestamp : Date.now();

        let thisDate = new Date(forTimestamp);
        let thisDay = thisDate.getDate();
        let thisMoIndex = thisDate.getMonth()
        let thisMo = thisMoIndex + 1;
        let thisYr = thisDate.getFullYear();

        let thisHr = thisDate.getHours();
        let thisHrAdj = thisHr % 12;
        thisHrAdj = thisHrAdj === 0 ? 12 : thisHrAdj;
        let isPM = thisHr >= 12;
        let thisMin = thisDate.getMinutes();

        let thisTimeString = `${thisHrAdj < 10 ? '0' : ''}${thisHrAdj}:${thisMin < 10 ? '0' : ''}${thisMin} ${isPM ? 'p.m.' : 'a.m.'}`

        return `${thisYr}-${thisMo < 10 ? '0' : ''}${thisMo}-${thisDay < 10 ? '0' : ''}${thisDay}, ${thisTimeString}`;
    }

    function getMetricString() {
        if (props.logData === undefined || props.logData.metric === undefined) { return '--.-'; }

        return `${props.logData.metric < 0 ? '--.-' : props.logData.metric} ${props.logData.units}`
    }

	return (
		<div className="client-goal-progress-card-log-cell">
			<div className="client-goal-progress-card-log-cell-col client-goal-progress-card-log-cell-col-0">
                <h4>{getDateString()}</h4>
            </div>
            <div className="client-goal-progress-card-log-cell-col client-goal-progress-card-log-cell-col-1">
                <h4>{getMetricString()}</h4>
            </div>
            <div className="client-goal-progress-card-log-cell-col client-goal-progress-card-log-cell-col-2">
                <h4>{loggerName}</h4>
            </div>
		</div>
	)
}

export default ClientGoalProgressCardLogCell;