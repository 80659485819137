import React, { useState, useEffect } from 'react';
import './PowerCard.css';
import './common.css';

import StatGraph from './StatGraph';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function PowerCard(props: any) {

    const [exerciseList, setExerciseList] = useState<any[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<any | undefined>(undefined);
    const [selectedSetIndex, setSelectedSetIndex] = useState(-1);
    const [weight, setWeight] = useState(-1);
    const [initSet, setInitSet] = useState(false);

    const [heroStat, setHeroStat] = useState(0);
    const [units, setUnits] = useState("W");

    const [minStat, setMinStat] = useState(-1);
    const [maxStat, setMaxStat] = useState(-1);

    const [graphMetrics, setGraphMetrics] = useState<number[]>([]);
 
    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.exerciseList) {
        	setExerciseList(props.exerciseList);
        	setSelectedExercise(props.exerciseList[0] ?? undefined);
        }
    }, [props.exerciseList])

    useEffect(() => {
    	setSelectedExercise(props.selectedExercise);
	}, [props.selectedExercise])

	useEffect(() => {
    	setSelectedSetIndex(props.selectedSetIndex);
	}, [props.selectedSetIndex])

	useEffect(() => {
		configureStats()
	}, [selectedSetIndex, selectedExercise])

	useEffect(() => {
		if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.power_normalized) return;
    	let power_normalized_max = selectedExercise.data.motion_info.target.power_normalized.max ?? -1;
    	let power_normalized_min = selectedExercise.data.motion_info.target.power_normalized.min ?? -1;

    	setMaxStat(power_normalized_max);
    	setMinStat(power_normalized_min);
	}, [selectedExercise])

    const initialize = () => {
        
    }

    const configureStats = () => {
    	// console.log("POWER STATS:", selectedExercise)
    	if (!selectedExercise || !selectedExercise.sets || selectedExercise.sets.length < 1) return;

    	if (selectedSetIndex === -1) {
    		configureStats_sets();
    	} else {
    		configureStats_reps();
    	}

    }

    const configureStats_sets = () => {
    	var setAvgs: number[] = [];
    	for (var i = 0; i < selectedExercise.sets.length; i++) {
    		let thisSet = selectedExercise.sets[i];
    		if (thisSet && thisSet.rep_stats && thisSet.rep_stats.length > 0) {
    			var thisSetStatSum = 0;
    			var thisSetStatCnt = 0;
    			for (var j = 0; j < thisSet.rep_stats.length; j++) {
    				let thisRepStat = thisSet.rep_stats[j];
    				let thisWeight = thisSet.weight ?? -1;
    				let thisPower = thisRepStat.power ?? 0;
    				let thisAdjustedPower = thisWeight <= 0 ? thisPower : thisPower * thisWeight;
    				thisSetStatSum += thisAdjustedPower;
    				thisSetStatCnt += 1;
    			}
    			let thisSetStatAvg = thisSetStatCnt === 0 ? 0 : thisSetStatSum / thisSetStatCnt;
    			setAvgs.push(thisSetStatAvg);

    		}
    	}
    	setGraphMetrics(setAvgs);
    }

    const configureStats_reps = () => {
    	var repAvgs: number[] = [];
    	let thisSet = selectedExercise.sets[selectedSetIndex];
		if (thisSet && thisSet.rep_stats && thisSet.rep_stats.length > 0) {

			for (var j = 0; j < thisSet.rep_stats.length; j++) {
				let thisRepStat = thisSet.rep_stats[j];
				let thisWeight = thisSet.weight ?? -1;
				let thisPower = thisRepStat.power ?? 0;
				let thisAdjustedPower = thisWeight <= 0 ? thisPower : thisPower * thisWeight;
				repAvgs.push(thisAdjustedPower);
			}
		}
		setGraphMetrics(repAvgs);
    }

    const getAvg = () => {
    	var avgSum = 0;
    	var avgCnt = 0;
    	for (var i = 0; i < graphMetrics.length; i++) {
    		avgSum += graphMetrics[i];
    		avgCnt += 1;
    	}

    	return avgCnt === 0 ? 0 : Math.floor(avgSum / avgCnt);
    }

    const getMax = () => {
    	return graphMetrics.length < 1 ? 0 : Math.max(...graphMetrics);
    }

    const getTargetMax = () => {
    	if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.power_normalized) return -1;
    	let power_normalized_max = selectedExercise.data.motion_info.target.power_normalized.max ?? -1;

    	// find average weight
    	if (!selectedExercise.sets || selectedExercise.sets.length < 1) return -1;

    	if (selectedSetIndex === -1) {
    		// Need weight running average
    		var weightSum = 0;
	    	var weightCnt = 0;

	    	for (var i = 0; i < selectedExercise.sets.length; i++) {
	    		let thisSetStats = selectedExercise.sets[i];
	    		if (thisSetStats.weight && !isNaN(thisSetStats.weight) && thisSetStats.weight > 0) {
	    			weightSum += thisSetStats.weight;
	    			weightCnt += 1;
	    		}
	    	}

	    	weightSum = weightCnt === 0 ? 0 : weightSum / weightCnt;
	    	weightSum = weightSum <= 0 ? 1 : weightSum;
	    	let maxPow = power_normalized_max * weightSum;

	    	return maxPow;

    	} else {
    		// For individual set
    		if (!selectedExercise.sets[selectedSetIndex]) return -1;

    		let thisWeight = selectedExercise.sets[selectedSetIndex].weight ?? -1;
    		thisWeight = thisWeight <= 0 ? 1 : thisWeight;
    		let maxPow = power_normalized_max * thisWeight;
    		return maxPow;
    	}

    	return -1;
    }

    const getTargetMin= () => {
    	if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.power_normalized) return -1;
    	let power_normalized_min = selectedExercise.data.motion_info.target.power_normalized.min ?? -1;

    	// find average weight
    	if (!selectedExercise.sets || selectedExercise.sets.length < 1) return -1;

    	if (selectedSetIndex === -1) {
    		// Need weight running average
    		var weightSum = 0;
	    	var weightCnt = 0;

	    	for (var i = 0; i < selectedExercise.sets.length; i++) {
	    		let thisSetStats = selectedExercise.sets[i];
	    		if (thisSetStats.weight && !isNaN(thisSetStats.weight) && thisSetStats.weight > 0) {
	    			weightSum += thisSetStats.weight;
	    			weightCnt += 1;
	    		}
	    	}

	    	weightSum = weightCnt === 0 ? 0 : weightSum / weightCnt;
	    	weightSum = weightSum <= 0 ? 1 : weightSum;
	    	let minPow = power_normalized_min * weightSum;

	    	return minPow;

    	} else {
    		// For individual set
    		if (!selectedExercise.sets[selectedSetIndex]) return -1;

    		let thisWeight = selectedExercise.sets[selectedSetIndex].weight ?? -1;
    		thisWeight = thisWeight <= 0 ? 1 : thisWeight;
    		let minPow = power_normalized_min * thisWeight;
    		return minPow;
    	}
    	
    	return  -1;
    }

	return (
		<div className={ "performance-stat-card" }>
			<div className={ "performance-stat-card-header" }>
                <h4>Power Output</h4>
            </div>
            {
            	exerciseList.length === 0 ?
            	<div className={ "performance-stat-card-body-empty-container" }>
            		<p>No exercises completed</p>
            	</div>
            	:
            	<div className={ "performance-stat-card-body-container" }>
	                <div className={ "performance-stat-card-body-content" }>
	                     <div className={ "performance-stat-card-body-hero-container" }>
	                     	<div className={ "performance-stat-card-body-hero-header-container performance-stat-card-body-hero-header-power" }>
			                    <h1>{ getAvg() } { units }</h1>
			                    <p>Average Power Output</p>
			                    {
			                    	getTargetMin() > 0 && getTargetMax() > 0 && 
			                    	<h4>Target { Math.round(getTargetMin() * 100) / 100 } { units } – { Math.round(getTargetMax() * 100) / 100 } { units }</h4>
			                    }
		                    </div>
		                </div>
		                <div className={ "performance-stat-card-body-chart-container" }>
		                	<StatGraph
		                		data={ graphMetrics }
		                		units={ units }
		                		color={ "#AA72F2" }
		                		statAvg={ getAvg() }
		                		statMax={ getTargetMax() }
		                		statMin={ getTargetMin() }/>
		                </div>
	                </div>
	            </div>
            }
            
            <div className={ "performance-stat-card-selector-container" }>
                <div className={ "performance-stat-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default PowerCard;