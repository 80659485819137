import React, { useState } from 'react';
import firebase from '../../Firebase';

import BodyMeasurement_t from '../../Interfaces/BodyMeasurement_t';

import './ClientProfileMeasurements.css';

import BodyMeasurementChart from '../../components/Clients/BodyMeasurementChart';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';


function ClientProfileMeasurements(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    const [bodyMeasurement, setBodyMeasurement] = useState<BodyMeasurement_t>({});
    const [bodyMeasurementLogs, setBodyMeasurementLogs] = useState<BodyMeasurement_t[]>([]);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullLatestMeasurements();
    }

    function pullLatestMeasurements() {
        if (props.client === undefined) {return;}

        let clientID = props.client.id;

        let database = firebase.database();
        let ref = database.ref(`body_measurements/${clientID}/logs`); // Change to database path
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let dataKeys = Object.keys(data);

                if (dataKeys.length > 0) {
                    var logTemp: BodyMeasurement_t[] = [];
                    for (var i = 0; i < dataKeys.length; i++) {
                        let thisKey = dataKeys[i];
                        let thisMeasurementData = data[thisKey];

                        let newBodyMeasurement: BodyMeasurement_t = parseLogData(thisMeasurementData);

                        logTemp.push(newBodyMeasurement);
                    }

                    setBodyMeasurement(logTemp[logTemp.length - 1]);

                    var measureReverse = [];
                    for (var i = logTemp.length - 1; i >= 0; i--) {
                        measureReverse.push(logTemp[i]);
                    }
                    setBodyMeasurementLogs(measureReverse);
                }
            }
        });
    }

    function parseLogData(thisMeasurementData: any) {
        let newBodyMeasurement: BodyMeasurement_t = {
            bodyWeight: thisMeasurementData.body_weight === undefined ? -1 : thisMeasurementData.body_weight,
            bodyFatPercentage: thisMeasurementData.body_fat_percentage === undefined ? -1 : thisMeasurementData.body_fat_percentage,
            leanMass: thisMeasurementData.lean_mass === undefined ? -1 : thisMeasurementData.lean_mass,
            height: thisMeasurementData.height === undefined ? -1 : thisMeasurementData.height,
            legLength: thisMeasurementData.leg_legnth === undefined ? -1 : thisMeasurementData.leg_legnth,
            torsoLength: thisMeasurementData.torso_length === undefined ? -1 : thisMeasurementData.torso_length,
            reachLength: thisMeasurementData.reach_length === undefined ? -1 : thisMeasurementData.reach_length,
            neck: thisMeasurementData.neck === undefined ? -1 : thisMeasurementData.neck,
            shoulders: thisMeasurementData.shoulders === undefined ? -1 : thisMeasurementData.shoulders,
            chest: thisMeasurementData.chest === undefined ? -1 : thisMeasurementData.chest,
            arm_L: thisMeasurementData.arm_L === undefined ? -1 : thisMeasurementData.arm_L,
            arm_R: thisMeasurementData.arm_R === undefined ? -1 : thisMeasurementData.arm_R,
            forearm_L: thisMeasurementData.forearm_L === undefined ? -1 : thisMeasurementData.forearm_L,
            forearm_R: thisMeasurementData.forearm_R === undefined ? -1 : thisMeasurementData.forearm_R,
            waistAtMinimum: thisMeasurementData.waistAtMinimum === undefined ? -1 : thisMeasurementData.waistAtMinimum,
            waistAtBellyButton: thisMeasurementData.waistAtBellyButton === undefined ? -1 : thisMeasurementData.waistAtBellyButton,
            glutes: thisMeasurementData.glutes === undefined ? -1 : thisMeasurementData.glutes,
            hips: thisMeasurementData.hips === undefined ? -1 : thisMeasurementData.hips,
            thighAtHamTie_L: thisMeasurementData.thighAtHamTie_L === undefined ? -1 : thisMeasurementData.thighAtHamTie_L,
            thighAtHamTie_R: thisMeasurementData.thighAtHamTie_R === undefined ? -1 : thisMeasurementData.thighAtHamTie_R,
            thighMid_L: thisMeasurementData.thighMid_L === undefined ? -1 : thisMeasurementData.thighMid_L,
            thighMid_R: thisMeasurementData.thighMid_R === undefined ? -1 : thisMeasurementData.thighMid_R,
            calf_L: thisMeasurementData.calf_L === undefined ? -1 : thisMeasurementData.calf_L,
            calf_R: thisMeasurementData.calf_L === undefined ? -1 : thisMeasurementData.calf_L,

            log_timestamp: thisMeasurementData.timestamp === undefined ? -1 : thisMeasurementData.timestamp,
            logged_by: thisMeasurementData.logged_by === undefined ? "" : thisMeasurementData.logged_by,
            isKG: thisMeasurementData.isKG === undefined ? false : thisMeasurementData.isKG
        };

        return newBodyMeasurement;
    }

    function getDateString(forBodyMeasurement: BodyMeasurement_t) {

        if (forBodyMeasurement.log_timestamp === undefined) { return ''; }

        let forTimestamp = forBodyMeasurement.log_timestamp;
        let thisDate = new Date(forTimestamp);
        let thisDay = thisDate.getDate();
        let thisMoIndex = thisDate.getMonth()
        let thisMo = thisMoIndex + 1;
        let thisYr = thisDate.getFullYear();

        let thisHr = thisDate.getHours();
        let thisHrAdj = thisHr % 12;
        thisHrAdj = thisHrAdj === 0 ? 12 : thisHrAdj;
        let isPM = thisHr >= 12;
        let thisMin = thisDate.getMinutes();

        let thisTimeString = `${thisHrAdj < 10 ? '0' : ''}${thisHrAdj}:${thisMin < 10 ? '0' : ''}${thisMin} ${isPM ? 'p.m.' : 'a.m.'}`

        return `${thisYr}-${thisMo < 10 ? '0' : ''}${thisMo}-${thisDay < 10 ? '0' : ''}${thisDay}, ${thisTimeString}`;
    }

	return (
		<div className="client-profile-measurements">
            <div className="client-profile-measurements-body-card">
                <div className="client-profile-measurements-body-card-header">
                    <div className="client-profile-measurements-body-card-header-text-container">
                        <h4>Latest Body Measurements</h4>
                        <p>{getDateString(bodyMeasurement) === '' ? 'No records found' : `Recorded ${getDateString(bodyMeasurement)}`}</p>
                    </div>
                    <div onClick={() => props.logMeasurementPressed()} className="client-profile-measurements-body-card-header-button-container">
                        <Add className="client-profile-measurements-body-card-header-button-icon"/>
                        <p>Create New Log</p>
                    </div>
                </div>
                <div className="client-profile-measurements-body-container">
                    <div hidden={bodyMeasurementLogs.length > 0} className="client-profile-measurements-body-blank-container">
                        <h4>No Logs Found</h4>
                        <p>No body measurements were found for {props.client !== undefined ? props.client.first_name : "your Client"}. Press 'Create New Log' above to get started recording their body measurements.</p>
                    </div>
        			<div className={`client-profile-measurements-body-container-inner ${bodyMeasurementLogs.length === 0 ? 'client-profile-measurements-body-container-inner-hidden' : ''}`}>
                        <BodyMeasurementChart 
                            isEditable={false}
                            forceMeasurement={bodyMeasurement}/>
                    </div>
                </div>
            </div>
            <div className="client-profile-measurements-body-card client-profile-measurements-history-card">
                <div className="client-profile-measurements-body-card-header">
                    <div className="client-profile-measurements-body-card-header-text-container">
                        <h4>Log History</h4>
                    </div>
                </div>
                <div className="client-profile-measurements-card-content">
                    <div className="client-profile-measurements-card-content-list-container">
                        <div className="client-profile-measurements-card-content-list-header">
                            <div className="client-profile-measurements-card-content-list-header-col client-profile-measurements-card-content-list-col-0">
                                <p>LOG TIME</p>
                            </div>
                            <div className="client-profile-measurements-card-content-list-header-col client-profile-measurements-card-content-list-col-1">
                                <p>BODY WEIGHT</p>
                            </div>
                            <div className="client-profile-measurements-card-content-list-header-col client-profile-measurements-card-content-list-col-2">
                                <p>BODY FAT %</p>
                            </div>
                        </div>
                        <div className="client-profile-measurements-card-content-list-content">
                            {
                                bodyMeasurementLogs.map((item: BodyMeasurement_t, index: number) => (
                                    <div onClick={() => setBodyMeasurement(item)} className="client-profile-measurements-card-content-list-cell">
                                        <div className="client-profile-measurements-card-content-list-cell-col client-profile-measurements-card-content-list-col-0">
                                            <p>{getDateString(item)}</p>
                                        </div>
                                        <div className="client-profile-measurements-card-content-list-cell-col client-profile-measurements-card-content-list-col-1">
                                            <p>{item.bodyWeight !== undefined && item.bodyWeight !== -1 ? item.bodyWeight : '--.-'} {item.isKG !== undefined ? (item.isKG ? 'KGs' : 'LBS') : 'LBS'}</p>
                                        </div>
                                        <div className="client-profile-measurements-card-content-list-cell-col client-profile-measurements-card-content-list-col-2">
                                            <p>{item.bodyFatPercentage !== undefined && item.bodyFatPercentage !== -1 ? item.bodyFatPercentage : '--.-'}%</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="client-profile-list-spacer"/>
		</div>
	)
}

export default ClientProfileMeasurements;