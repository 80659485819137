import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import firebase from '../../../Firebase';
import './IntraWorkoutSeriesList.css';

import AlertBlock from '../../../components/Base/AlertBlock';

import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Workout_t from '../../../Interfaces/Workout_t';
import CurrentWorkout_t from '../../../Interfaces/CurrentWorkout_t';
import SetStats_t from '../../../Interfaces/SetStats_t';

import IntraWorkoutSeriesListSetCell from './IntraWorkoutSeriesListSetCell';

import '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

function IntraWorkoutSeriesList(props: any) {

    const [initSet, setInitSet] = useState(false);

    const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(0);
    const [selectedSetIndex, setSelectedSetIndex] = useState(0);
    const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
    const [totalSecondsCount, setTotalSecondsCount] = useState(0);
    const [sessionTime, setSessionTime] = useState(0);
    const [sessionStartUnixTime, setSessionStartUnixTime] = useState(0);

    const [latestSeriesIndex, setLatestSeriesIndex] = useState(0);
    const [latestSetIndex, setLatestetSetIndex] = useState(0);
    const [latestExerciseIndex, setLatestExerciseIndex] = useState(0);

    const [exerciseSeries, setExerciseSeries] = useState<ExerciseSeries_t[]>([]);

    //const [savedInputData, setSavedInputData] = useState<any>({toWeight: 0, forSeriesIndex: -1, forExerciseIndex: -1, setIndex: -1});
    const [savedInputData_weight, setSavedInputData_weight] = useState(0);
    const [savedInputData_seriesIndex, setSavedInputData_seriesIndex] = useState(0);
    const [savedInputData_exerciseIndex, setSavedInputData_exerciseIndex] = useState(0);
    const [savedInputData_setIndex, setSavedInputData_setIndex] = useState(0);


    const [showAlert, setShowAlert] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        var d = new Date();
        var n = d.getTime();
        var time = Math.floor(n / 1000);
        setSessionStartUnixTime(time);
        configureExerciseSeries();

        if (props.nodeManager !== undefined) {
            props.nodeManager.setConnectionAlertCallback(connectionAlertCallback);
        }
    }

    function connectionAlertCallback(allConnected: boolean) {
        setShowAlert(!allConnected);        
    }

    useEffect(() => {
        configureExerciseSeries();
    }, [props.workout]);

    useEffect(() => {
        if (props.currentWorkout !== undefined) {
            if (selectedSeriesIndex < props.currentWorkout.seriesIndex) {
                // Moving to next series. Collapse the previous series in list to show current series only, only if using Nodes
                
                setExerciseSeries(
                    exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                        if (props.currentWorkout.seriesIndex === index) {
                            return {...item, isShowing: true};
                        } else {
                            return {...item, isShowing: props.currentWorkout.usingNodes ? false : item.isShowing};
                        }
                    })
                );
            }
            if (props.currentWorkout.seriesIndex !== selectedSeriesIndex) {
                // setSelectedSeriesIndex(props.currentWorkout.seriesIndex);
                // setSelectedExerciseIndex(props.currentWorkout.exerciseIndex);
                // setSelectedSetIndex(props.currentWorkout.setIndex);
                setWasSelected(props.currentWorkout.seriesIndex, props.currentWorkout.exerciseIndex, props.currentWorkout.setIndex);
            }


            // Check if has advanced to next set 
            if (props.currentWorkout.setIndex !== latestSetIndex) { // || props.currentWorkout.seriesIndex !== latestSeriesIndex || props.currentWorkout.exerciseIndex !== latestExerciseIndex) {
                // console.log("INTRA WORKOUT SERIES LIST: ", props.currentWorkout.setIndex,latestSetIndex);
                setLatestetSetIndex(props.currentWorkout.setIndex);
                // props.movedToNextSet();
            }
            setLatestSeriesIndex(props.currentWorkout.seriesIndex);
            
            setLatestExerciseIndex(props.currentWorkout.exerciseIndex);
        }
        
    }, [props.currentWorkout.seriesIndex, props.currentWorkout.exerciseIndex, props.currentWorkout.setIndex]);

    useEffect(() => {
        setTotalSecondsCount(props.totalSecondsCount);

        if (props.currentWorkout === undefined) { return; }

        var d = new Date();
        var n = d.getTime();
        var time = Math.floor(n / 1000);
        setSessionTime(time - props.currentWorkout.startTime);
    }, [props.totalSecondsCount]);

    function getTimeString(seconds: number) {
        let secs = seconds % 60;
        let mins = Math.floor(seconds / 60);

        let secsString = `${secs < 10 ? '0' : ''}${secs}`;
        let minsString = `${mins < 10 ? '0' : ''}${mins}`;

        return `${minsString}m ${secsString}s`
    }

    function configureExerciseSeries() {
        if (props.workout !== undefined && props.workout.exerciseSeries !== undefined) {
            var seriesTemp: ExerciseSeries_t[] = [];
            for (var i = 0; i < props.workout.exerciseSeries.length; i++) {
                let thisSeries: ExerciseSeries_t = props.workout.exerciseSeries[i];
                thisSeries.isShowing = i === 0;
                seriesTemp.push(thisSeries);
            }
            setExerciseSeries(seriesTemp);
        }
    }

    function exerciseSelected(seriesIndex: number, exerciseIndex: number) {
        setSelectedSeriesIndex(seriesIndex)
        setSelectedExerciseIndex(exerciseIndex);
        setSelectedSetIndex(-1);

        if (props.exerciseSelected !== undefined) {
            props.exerciseSelected(seriesIndex, exerciseIndex);
        }
    }

    function setWasSelected(seriesIndex: number, exerciseIndex: number, setIndex: number) {
        setSelectedSeriesIndex(seriesIndex)
        setSelectedExerciseIndex(exerciseIndex);
        setSelectedSetIndex(setIndex);

        if (props.setWasSelected !== undefined) {
            props.setWasSelected(seriesIndex, exerciseIndex, setIndex);
        }
    }

    function toggleSeriesDisplay(seriesIndex: number) {
        setExerciseSeries(
            exerciseSeries.map((item: ExerciseSeries_t, index: number) => {
                if (seriesIndex === index) {
                    return {...item, isShowing: !item.isShowing};
                } else {
                    return item;
                }
            })
        );
    }

    function getReps(forSeries: ExerciseSeries_t) {
        var repCount = 0;
        for (var i = 0; i < forSeries.exercises.length; i++) {
            let thisExercise: Exercise_t = forSeries.exercises[i];
            let repArray: number[] = thisExercise.reps !== undefined ? thisExercise.reps! : [0];
            for (var j = 0; j < repArray.length; j++) {
                repCount += repArray[j];
            }
        }

        return `${repCount < 10 ? '0' : ''}${repCount}`;
    }

    function getTonnage(seriesIndex: number) {
        if (props.currentWorkout === undefined) { return 0; }

        var totalTonnage = 0;
        if (seriesIndex < props.currentWorkout.setStats.length && props.currentWorkout.setStats[seriesIndex].length > 0) {
            for (var i = 0; i < props.currentWorkout.setStats[seriesIndex].length; i++) {
                if (props.currentWorkout.setStats[seriesIndex][i].length > 0) {
                    for (var j = 0; j < props.currentWorkout.setStats[seriesIndex][i].length; j++) {
                        let thisSetStat: SetStats_t = props.currentWorkout.setStats[seriesIndex][i][j];
                        let thisTonnage = thisSetStat.manualEntryReps * thisSetStat.weight;
                        totalTonnage += thisTonnage;
                    }
                }
            }
        }
        /*
        let tt_mil = Math.floor(totalTonnage / 1000000);
        let tt_thou = Math.floor(totalTonnage / 1000);
        let tt_ones = Math.floor(totalTonnage);

        let tt_mil_string = tt_mil > 0 ? `${tt_mil},` : '';
        let tt_thou_string = tt_thou === 0 && tt_mil > 0 ? '000,' : ( tt_thou === 0 && tt_mil === 0 ? '' : `${tt_thou < 100 ? `0${tt_thou},` : (tt_thou < 10 ? `00${tt_thou}` : `${tt_thou}`)}`);
        let tt_ones_string = tt_ones === 0 && (tt_thou > 0 || tt_mil > 0) ? '000' : ( tt_ones === 0 && tt_thou === 0 && tt_mil === 0 ? '0.0' : `${tt_thou}`);
        */
        return totalTonnage;
    }

    function getTotalTonnage() {
        var totalTonnage = 0;
        for (var i = 0; i < exerciseSeries.length; i++) {
            totalTonnage += Number(getTonnage(i));
        }

        return totalTonnage;
    }

    function getThousandsSeperatedString(n: number) {
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function changeReps(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
        props.changeReps(toReps, forSeriesIndex, forExerciseIndex, setIndex);
    }

    function changeWeight(toWeight: number, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
        props.changeWeight(toWeight, forSeriesIndex, forExerciseIndex, setIndex);
        
        // setSavedInputData({...savedInputData, toWeight: Number(toWeight), forSeriesIndex: forSeriesIndex, forExerciseIndex: forExerciseIndex, setIndex: setIndex});
        // setSavedInputData_weight(Number(toWeight));
        // setSavedInputData_seriesIndex(forSeriesIndex);
        // setSavedInputData_exerciseIndex(forExerciseIndex);
        // setSavedInputData_setIndex(setIndex);
    }

    function blurInput(forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
        //if (forSeriesIndex === savedInputData.forSeriesIndex && forExerciseIndex === savedInputData.forExerciseIndex && setIndex === savedInputData.setIndex) {
            // Input corresponding to most recent weight change has lost focus -- update parent component
            // console.log("INTRA WORKOUT SERIES LIST | blurInput", [savedInputData_weight, savedInputData_seriesIndex, savedInputData_exerciseIndex, savedInputData_setIndex], [forSeriesIndex, forExerciseIndex, setIndex]);
            props.changeWeight(savedInputData_weight, savedInputData_seriesIndex, savedInputData_exerciseIndex, savedInputData_setIndex);
            //setSavedInputData({toWeight: 0, forSeriesIndex: -1, forExerciseIndex: -1, setIndex: -1});
            // setSavedInputData_weight(0);
            // setSavedInputData_seriesIndex(-1);
            // setSavedInputData_exerciseIndex(-1);
            // setSavedInputData_setIndex(-1);
        //}
    }

    function getTargetReps(forExercise: Exercise_t, setIndex: number) {
        let repArray: number[] = forExercise.reps !== undefined ? forExercise.reps! : [0];
        if (setIndex < repArray.length) {
            return repArray[setIndex];
        } else {
            return repArray[repArray.length - 1];
        }
    }

    function getHighlightSet(seriesIndex: number, setIndex: number) {
        if (seriesIndex < props.currentWorkout.seriesIndex) {
            return true;
        } else if (seriesIndex === props.currentWorkout.seriesIndex) {
            if (setIndex <= props.currentWorkout.setIndex) {
                return true;
            }
        }
        return false;
    }

    function getHighlightExercise(seriesIndex: number, setIndex: number, exerciseIndex: number) {
        if (seriesIndex < props.currentWorkout.seriesIndex) {
            return true;
        } else if (seriesIndex === props.currentWorkout.seriesIndex) {
            if (setIndex < props.currentWorkout.setIndex) {
                return true;
            } else if (setIndex === props.currentWorkout.setIndex) { 
                if (exerciseIndex <= props.currentWorkout.exerciseIndex) {
                    return true;
                }
            } 
        }
        return false;
    }

    function getShowCurrentSetIndicator(seriesIndex: number, setIndex: number, exerciseIndex: number) {
        return seriesIndex === props.currentWorkout.seriesIndex && setIndex === props.currentWorkout.setIndex && exerciseIndex === props.currentWorkout.exerciseIndex;

//        return seriesIndex === props.currentWorkout.seriesIndex && setIndex === props.currentWorkout.setIndex;
    }

    function getCurrentSetString() {
        if (props.currentWorkout !== undefined && props.workout !== undefined && props.workout.exerciseSeries !== undefined) {
            let currentSeries: ExerciseSeries_t = props.workout.exerciseSeries![props.currentWorkout.seriesIndex];
            let currentSeriesTitle = currentSeries.title;
            let exerciseIndex = props.currentWorkout.exerciseIndex;
            let setIndex = props.currentWorkout.setIndex + 1;

            return `${currentSeriesTitle}${exerciseIndex + 1}-${setIndex < 10 ? '0' : ''}${setIndex}`;
        } else {
            return 'A1-01';
        }
        
    }

    function getNumCompletedReps(seriesIndex: number, exerciseIndex: number, setIndex: number) {

        if (props.currentWorkout === undefined) { return -1; }

        if (seriesIndex < props.currentWorkout.setStats.length && 
            setIndex < props.currentWorkout.setStats[seriesIndex].length && 
            exerciseIndex < props.currentWorkout.setStats[seriesIndex][setIndex].length) {

            let thisSetStat: SetStats_t = props.currentWorkout.setStats[seriesIndex][setIndex][exerciseIndex];
            return thisSetStat.manualEntryReps;
                            
        }

        return -1
    }

    function getWeightForSet(seriesIndex: number, exerciseIndex: number, setIndex: number) {

        if (props.currentWorkout === undefined) { return -1; }

        if (seriesIndex < props.currentWorkout.setStats.length && 
            setIndex < props.currentWorkout.setStats[seriesIndex].length && 
            exerciseIndex < props.currentWorkout.setStats[seriesIndex][setIndex].length) {

            let thisSetStat: SetStats_t = props.currentWorkout.setStats[seriesIndex][setIndex][exerciseIndex];
            let weightRounded = Math.round(thisSetStat.weight * 10);
            return weightRounded / 10; //`${weightRounded / 10}${thisSetStat.weight % 1 === 0 ? '.0' : ''}`;
                            
        }

        return -1
    }

    function getSetBreakdownDIVs(forSeries: ExerciseSeries_t, seriesIndex: number) {
        var returnObj = [];

        var maxNumSets = 0;
        for (var i = 0; i < forSeries.exercises.length; i++) {
            let thisExercise: Exercise_t = forSeries.exercises[i];
            let repArray: number[] = thisExercise.reps !== undefined ? thisExercise.reps! : [0];
            if (repArray.length > maxNumSets) { maxNumSets = repArray.length; }
        }

        for (var i = 0; i < maxNumSets; i++) {
            let thisSetIndex = i;
            let obj =   <div className="intra-workout-series-list-card-set-breakdown-set-container">
                            <div hidden={true}><div hidden={!getShowCurrentSetIndicator(seriesIndex, i, 0)} className="intra-workout-series-list-card-set-breakdown-current-set-indicator" /></div>
                            <div hidden={true} onClick={() => setWasSelected(seriesIndex,0,0)} className={getHighlightSet(seriesIndex, i) ? "intra-workout-series-list-card-set-breakdown-set-title-container" : "intra-workout-series-list-card-set-breakdown-set-title-container intra-workout-series-list-card-set-breakdown-set-title-container-unselected"}>
                                <div className={getHighlightSet(seriesIndex, i) ? "intra-workout-series-list-card-set-breakdown-set-title-content" : "intra-workout-series-list-card-set-breakdown-set-title-content intra-workout-series-list-card-set-breakdown-set-title-content-unselected"}>
                                    <p>set</p>
                                    <h4>{(i + 1) < 10 ? '0' : ''}{i + 1}</h4>
                                </div>
                            </div>
                            <div className="intra-workout-series-list-card-set-breakdown-set-exercises-container">
                                {
                                    forSeries.exercises.map((item_ex: Exercise_t, index_ex: number) => (
                                        /*<div onClick={() => setWasSelected(seriesIndex,index_ex,thisSetIndex)} className={selectedSeriesIndex === seriesIndex && selectedExerciseIndex === index_ex && selectedSetIndex === thisSetIndex ? "intra-workout-series-list-card-set-breakdown-set-exercises-row intra-workout-series-list-card-set-breakdown-set-exercises-row-selected" : "intra-workout-series-list-card-set-breakdown-set-exercises-row"}>
                                            <div hidden={!getShowCurrentSetIndicator(seriesIndex, i, index_ex)} className="intra-workout-series-list-card-set-breakdown-current-set-indicator" />
                                            <div className={getShowCurrentSetIndicator(seriesIndex, i, index_ex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-current" : (getHighlightExercise(seriesIndex, i, index_ex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container" : "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-unselected")}>
                                                <div hidden={!getHighlightExercise(seriesIndex, i, index_ex)} className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-check">
                                                    <Check className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-check"/>
                                                </div>
                                                <div hidden={getHighlightExercise(seriesIndex, i, index_ex) && !getShowCurrentSetIndicator(seriesIndex, i,index_ex)} className={`intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot ${getShowCurrentSetIndicator(seriesIndex, i, index_ex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-current" : "intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-unselected"}`}/>
                                                <h4>{forSeries.title}{index_ex + 1}-{(i + 1) < 10 ? '0' : ''}{i + 1}</h4>
                                            </div>
                                            <div className={getHighlightExercise(seriesIndex, i, index_ex) ? "intra-workout-series-list-card-exercise-breakdown-input-outter-container intra-workout-series-list-card-exercise-breakdown-input-outter-container-reps" :  "intra-workout-series-list-card-exercise-breakdown-input-outter-container intra-workout-series-list-card-exercise-breakdown-input-outter-container-reps intra-workout-series-list-card-exercise-breakdown-input-outter-container-unselected"}>
                                                <div className="intra-workout-series-list-card-exercise-breakdown-input-container">
                                                    <IonInput
                                                        value={getNumCompletedReps(seriesIndex, index_ex, thisSetIndex) < 0 ? "" : `${getNumCompletedReps(seriesIndex, index_ex, thisSetIndex)}`}
                                                        className="intra-workout-series-list-card-exercise-breakdown-input"
                                                        placeholder={`${getTargetReps(item_ex, i)}`}
                                                        inputmode="numeric"
                                                        clearOnEdit={true}
                                                        type="number"
                                                        onIonChange={e => changeReps(e.detail.value!, seriesIndex, index_ex, thisSetIndex)}/>
                                                </div>
                                                <div className="intra-workout-series-list-card-exercise-breakdown-input-icon-container">
                                                    <Close className="intra-workout-series-list-card-exercise-breakdown-input-icon"/>
                                                </div>
                                                <div className="intra-workout-series-list-card-exercise-breakdown-input-container">
                                                    <IonInput
                                                        //value={getWeightForSet(seriesIndex, index_ex, thisSetIndex) < 0 ? "" : `${getWeightForSet(seriesIndex, index_ex, thisSetIndex)}`}
                                                        className="intra-workout-series-list-card-exercise-breakdown-input"
                                                        placeholder={`--.-`}
                                                        inputmode="numeric"
                                                        clearOnEdit={true}
                                                        type="number"
                                                        onBlur={() => blurInput(seriesIndex, index_ex, thisSetIndex)}
                                                        onIonChange={e => changeWeight(e.detail.value!, seriesIndex, index_ex, thisSetIndex)}/>
                                                    <p>{props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" : "LBS"}</p>
                                                </div>
                                                <div className="intra-workout-series-list-card-exercise-breakdown-select-icon-container">
                                                    <ArrowForwardIos className="intra-workout-series-list-card-exercise-breakdown-select-icon"/>
                                                </div>
                                            </div>
                                        </div>*/

                                        <IntraWorkoutSeriesListSetCell 
                                            currentWorkout={props.currentWorkout}
                                            series={forSeries}
                                            selectedSeriesIndex={selectedSeriesIndex}
                                            selectedExerciseIndex={selectedExerciseIndex}
                                            selectedSetIndex={selectedSetIndex}
                                            seriesIndex={seriesIndex}
                                            exerciseIndex={index_ex}
                                            setIndex={thisSetIndex}
                                            setWasSelected={() => setWasSelected(seriesIndex, index_ex, thisSetIndex)}
                                            changeWeight={(w: number) => changeWeight(w, seriesIndex, index_ex, thisSetIndex)}
                                            changeReps={(r: string) => changeReps(r, seriesIndex, index_ex, thisSetIndex)}/>
                                    ))
                                }
                            </div>
                        </div>;
            returnObj.push(obj);
        }

        return returnObj;
    }

    function alertPressed() {
        setShowAlert(false);
        if (props.alertPressed !== undefined) {
            props.alertPressed();
        }
    }

	return (
		<div className="intra-workout-series-list">
            <div onClick={() => alertPressed()} hidden={!showAlert} className="intra-workout-alert-container">
                <AlertBlock 
                    alertText="A Node has lost connection! Make sure you're in range and the Nodes are powered ON."/>
            </div>
            <div className="intra-workout-series-list-summary-header">
                <h4>Workout Overview</h4>
                <div className="intra-workout-series-list-summary-card">
                    <div className="intra-workout-series-list-summary-card-col">
                        <div className="intra-workout-series-list-summary-card-col-header">
                            <p>CURRENT SET</p>
                        </div>
                        <div className="intra-workout-series-list-summary-card-col-content intra-workout-series-list-summary-card-col-content-blue">
                            <h4>{getCurrentSetString()}</h4>
                        </div>
                    </div>
                    <div className="intra-workout-series-list-summary-card-col">
                        <div className="intra-workout-series-list-summary-card-col-header">
                            <p>TONNAGE</p>
                        </div>
                        <div className="intra-workout-series-list-summary-card-col-content">
                            <h4>{getThousandsSeperatedString(getTotalTonnage())} {props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" : "LBS"}</h4>
                        </div>
                    </div>
                    <div className="intra-workout-series-list-summary-card-col">
                        <div className="intra-workout-series-list-summary-card-col-header">
                            <p>RESTING TIME</p>
                        </div>
                        <div className="intra-workout-series-list-summary-card-col-content">
                            <h4>{getTimeString(sessionTime)}</h4>
                        </div>
                    </div>
                    
                    <div hidden={true} className="intra-workout-series-list-summary-card-col">
                        <div className="intra-workout-series-list-summary-card-col-header">
                            <p>Selected Weight</p>
                            <ArrowForward className="intra-workout-series-list-summary-card-col-header-icon"/>
                        </div>
                        <div className="intra-workout-series-list-summary-card-col-content">
                            <h4>{props.currentWorkout !== undefined ? props.currentWorkout.selectedWeight : '0.0'} {props.currentWorkout !== undefined && props.currentWorkout.isKG ? "KG" : "LBS"}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intra-workout-series-list-summary-body">
			{
                exerciseSeries.map((item: ExerciseSeries_t, index: number) => (
                    <div className="intra-workout-series-list-card">
                        <div onClick={() => toggleSeriesDisplay(index)} className="intra-workout-series-list-card-header-container">
                            <div hidden={item.isShowing !== undefined && item.isShowing === false} className="intra-workout-series-list-card-header-expand-icon-container">
                                <ExpandLess className="intra-workout-series-list-card-header-expand-icon"/>
                            </div>
                            <div hidden={item.isShowing === undefined || item.isShowing === true} className="intra-workout-series-list-card-header-expand-icon-container">
                                <ExpandMore className="intra-workout-series-list-card-header-expand-icon"/>
                            </div>
                            <div className="intra-workout-series-list-card-header-series-letter-container">
                                <h4>{item.title}</h4>
                            </div>
                            <div hidden={item.exercises.length === 1} className="intra-workout-series-list-card-header-series-title-container">
                                <h4>Series {item.title}</h4>
                                <p>({item.exercises.length})</p>
                            </div>
                            <div hidden={item.exercises.length > 1} className="intra-workout-series-list-card-header-series-title-container">
                                <h4>{item.exercises[0].title}</h4>
                            </div>
                            <div className="intra-workout-series-list-card-header-info-container">
                                <p>{getReps(item)} reps</p>
                                <p>{getThousandsSeperatedString(getTonnage(index))} {props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" : "LBS"}</p>
                            </div>
                            
                        </div>
                        <div hidden={item.isShowing !== undefined && item.isShowing === false} className="intra-workout-series-list-card-content-container">
                            <div hidden={item.exercises.length <= 1} className="intra-workout-series-list-card-exercise-breakdown-container">
                                {
                                    item.exercises.map((item_exercise: Exercise_t, index_exercise: number) => (
                                        <div onClick={() => exerciseSelected(index, index_exercise)} className={selectedSeriesIndex === index && selectedExerciseIndex === index_exercise && selectedSetIndex === -1 ? "intra-workout-series-list-card-exercise-breakdown-exercise-container intra-workout-series-list-card-exercise-breakdown-exercise-container-selected" : "intra-workout-series-list-card-exercise-breakdown-exercise-container"}>
                                            <div className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-container intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-small">
                                                <h4>{item.title}{index_exercise + 1}</h4>
                                            </div>
                                            <div className="intra-workout-series-list-card-exercise-breakdown-exercise-title-container">
                                                <h4>{item_exercise.title}</h4>
                                            </div>
                                            <div className="intra-workout-series-list-card-exercise-breakdown-icon-container">
                                                <ArrowForwardIos className="intra-workout-series-list-card-exercise-breakdown-icon"/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="intra-workout-series-list-card-set-breakdown-container">
                                <div className="intra-workout-series-list-card-set-breakdown-header-container">
                                    <div className="intra-workout-series-list-card-set-breakdown-header-content">
                                        <p>Set Number</p>
                                    </div>
                                    <div className="intra-workout-series-list-card-set-breakdown-header-content">
                                        <p>Reps</p>
                                    </div>
                                    <div className="intra-workout-series-list-card-set-breakdown-header-content">
                                        <p>Weight</p>
                                    </div>
                                </div>
                                <div className="intra-workout-series-list-card-set-breakdown-content-container">
                                    {getSetBreakdownDIVs(item, index)}
                                </div>
                            </div>
                            <div className="intra-workout-series-list-card-button-container">


                            </div>
                        </div>
                    </div>
                ))
            }
            </div>
		</div>
	)
}

export default IntraWorkoutSeriesList;