import React from 'react';
import firebase from '../../Firebase';

import getDatabaseDirect from '../firebase/database/getDatabaseDirect';
import setDatabaseDirect from '../firebase/database/setDatabaseDirect';
import pushDatabaseDirect from '../firebase/database/pushDatabaseDirect';

const createScheduledMessage = async ( 
		user_uid: string, 
		type: "event" | "scheduled_single" | "scheduled_recurring",
		target_ts: number,
		event_type?: ""
	) => {
    
    return new Promise<string>( async function(resolve, reject) {
    	
    	if (!user_uid || user_uid === "") reject(`API/createScheduledMessage: Provide a valid user UID string. <${user_uid}>`)

    	let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

    	try {
    		let locationExtension = type === "event" ? `event_triggered/${ event_type ?? "other" }`
    							  : type === "scheduled_single" ? "scheduled" 
    							  : "scheduled";

    		// Get the logged in user
    		let location = `notification_queue/queue/${user_uid}/${locationExtension}`;

    		let dataObject = 
    			type === "event" ?
    				{
    					"magic_delay": false,
    					"starting_timestamp": Date.now(),
						"ending_timestamp": Date.now() + (30 * 24 * 3600 * 1000),
						"send_day_time": (7 * 3600 * 1000),		// Time of day in ms
						"last_send_timestamp": Date.now(),
						"send_count": 0
    				}
    		  : type === "scheduled_single" ?
    		  		{
    		  			"status": true,
						"created_by": trainerUID,
						"created_timestamp": Date.now(),
						"message": " ",
						"recurring": false,
						"send_timestamp": target_ts,
						"send_count": 0
    		  		}
    		  :     {
    		  			"status": true,
    		  			"magic_delay": false,
						"created_timestamp": Date.now(),
						"message": " ",
						"recurring": true,
						"recurring_period": 86400000,  	// Period in ms
						"starting_timestamp": Date.now(),
						"ending_timestamp": Date.now() + (30 * 24 * 3600 * 1000),
						"send_day_time": (7 * 3600 * 1000),		// Time of day in ms
						"last_send_timestamp": Date.now(),
						"send_count": 0
    		  		}

    		if (!dataObject) {
    			reject("ERROR (API/createScheduledMessage) Data object not created. Check the scheduled message type");
    			return;
    		}

    		let resp = await pushDatabaseDirect(location, dataObject);
    		resolve(resp);
			
    	} catch (err) {
    		console.log("ERROR (API/getUserPushId)", err);
    		reject(err);
    	}
	});
}

export default createScheduledMessage;