import React, { useState } from 'react';
import './WorkoutCell.css';
import firebase from '../../Firebase';

function WorkoutCell(props: any) {

	const [imgUrl, setImgUrl] = useState('');
	const [daysSinceWorkout, setDaysSinceWorkout] = useState(-1);
	const [daysSinceWorkoutSet, setDaysSinceWorkoutSet] = useState(false);
	const [musclesString, setMusclesString] = useState('');
	
	if (daysSinceWorkoutSet === false) {
		setDaysSinceWorkoutSet(true);
		getImage();
		getMusclesString();
	}


	function getImage() {
		const storage = firebase.storage();
		//// console.log(props.client.id);
		storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}

	function getMusclesString() {
		let database = firebase.database();
		let ref = database.ref(`workouts/${props.workout.id}`);
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let workoutObj = snapshot.val();

				// Determine previous workout muscle groups
				let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
				var muscleGroupTags_temp: string[] = [];
				let exercises = workoutObj.exercises;
				let exerciseKeys = Object.keys(exercises);
				for (var i = 0; i < exerciseKeys.length; i++) {
					let thisKey = exerciseKeys[i];
					let thisExercise = exercises[thisKey];

					let thisExerciseMuscles = thisExercise.muscles;
					let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

					for (var j = 0; j < thisExerciseMusclesKeys.length; j++) {
						let thisMuscleKey = thisExerciseMusclesKeys[j];
						let thisMuscle = thisExerciseMuscles[thisMuscleKey];
						let thisMuscleGroup = Number(thisMuscle.group);

						let thisMuscleString = muscle_groups[thisMuscleGroup];
						if (!muscleGroupTags_temp.includes(thisMuscleString)) {
							muscleGroupTags_temp.push(thisMuscleString);
						}
					}
				}
				var string_temp = "";
				for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
					string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
				}
				setMusclesString(string_temp);
			}
		});
	}


	function cellSelected() {
		props.cellSelected(props.workout);
	}

	function moreInfoSelected() {
		props.moreInfoSelected(props.workout);
	}

	function getDaysSinceWorkoutString() {
		if (daysSinceWorkout < 0) {
			return "No workouts found";
		} else {
			return `${daysSinceWorkout} days ago`;
		}
	}

	function getDifficultyString() {
		let difficulties = ["Very easy", "Low intensity", "Mildly intense", "Moderatly intense", "Highly intense", "Extremely intense"]
		let difficultyString = difficulties[props.workout.difficulty];

		return difficultyString;
	}

	function getDurationString() {
		return `${props.workout.duration} minutes`
	}

	function getNumberSets() {
        if (props.workout.exercises === undefined) { return 0; }

        var numSets = 0;
        for (var i = 0; i < props.workout.exercises.length; i++) {
            let reps = props.workout.exercises[i].reps;
            if (reps !== undefined) {
                numSets += reps.length
            }
        }

        return numSets;
    }

	return (
		<div onClick={() => cellSelected()} className={"workout-cell-container"}>
			<div className="workout-cell-info-card-content">
				<div className="workout-cell-image-container">
					<img  
						className="workout-cell-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${props.workout.title}`} />
				</div>
				<div className="workout-cell-info-card-col-container">
	                <div className="workout-cell-info-card-col workout-cell-info-card-info-container">
	                    <h4>{props.workout.title}</h4>
	                    <p>{musclesString}</p>
	                </div>
	                <div className="workout-cell-info-card-col workout-cell-info-card-info-container">
	                    <h4>{getDurationString()}</h4>
	                    <div className="workout-cell-info-card-info-trained-with-me-container">
	                        <div className="workout-cell-info-card-info-trained-with-me-icon"/>
	                        <p>{getDifficultyString()}</p>
	                    </div>
	                </div>
	                <div className="workout-cell-info-card-col workout-cell-info-card-info-container">
	                    <h4>{props.workout.exercises !== undefined ? props.workout.exercises.length : 0} exercises</h4>
	                    <p>{getNumberSets()} sets total</p>
	                </div>
	                <div className="workout-cell-info-card-col workout-cell-info-card-info-container">
	                    <h4>{props.workout.timesCompleted} times used</h4>
	                    <p>{props.workout.downloads} user downloads</p>
	                </div>
	            </div>
            </div>
		</div>
	)
}

/*
	<div className="client-card-text-container">
				<p className="client-card-text-name">{props.workout.title}</p>
				<p className="client-card-text-email">{getDetailsString()}</p>
			</div>
			<div className="client-cell-standing-container">
				<div className="workout-cell-muscle-string-container">{ musclesString }</div>
			</div>
*/

export default WorkoutCell; 


