import React, { createContext, useEffect, useState } from 'react';
import firebase, { User } from "firebase";
import getUnreadMessages from "./pages/ClientProfile/Chat/getUnreadMessages"
import AppContext_t from "../src/Interfaces/AppContext_t"

const AppContext = createContext<AppContext_t>({
    unreadMessagesCount: 0,
    user: null,
    getUnreadMessagesCount: async (userUID: string) => {}
});

const AppProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [isUnreadMessagesFetched, setIsUnreadMessagesFetched] = useState(false);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
            if (!isUnreadMessagesFetched && user) {
                getUnreadMessagesCount(user.uid);
            }
        });

        // Fetch unread messages every 60 seconds
        const intervalId = setInterval(() => {
            if (user) {
                getUnreadMessagesCount(user.uid);
            }
        }, 60000);

        return () => {
            unsubscribe();
            clearInterval(intervalId); // Clear the interval when the component unmounts
        };
    }, [isUnreadMessagesFetched, user]);

    const getUnreadMessagesCount = async (userUID: string) => {
        //const trainerUID = user.uid;
        const unread = await getUnreadMessages(userUID);
        setUnreadMessagesCount(unread.unread);
        setIsUnreadMessagesFetched(true);
    };

    return (
        <AppContext.Provider value={{ unreadMessagesCount, user, getUnreadMessagesCount }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
