import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IonInput } from '@ionic/react';
import './CurrentExerciseModal.css';

import FormTempoInput from '../Base/FormTempoInput';
import WeightSelector from '../Base/WeightSelector';

import CurrentExerciseModalChart from '../Charts/CurrentExerciseModalChart';
import IntraWorkoutCurrentExerciseRepSummary from '../IntraWorkout/IntraWorkoutCurrentExerciseRepSummary';

import NodeInstance from '../../NodeInstance';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import Workout_t from '../../Interfaces/Workout_t';
import CurrentWorkout_t from '../../Interfaces/CurrentWorkout_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import RepStats_t from '../../Interfaces/RepStats_t';
import SetStats_t from '../../Interfaces/SetStats_t';
import MotionData_t from '../../Interfaces/MotionData_t';

import '@material-ui/core';
import Timeline from '@material-ui/icons/Timeline';
import Star from '@material-ui/icons/Star';
import Speed from '@material-ui/icons/Speed'
import Close from '@material-ui/icons/Close';
import Flag from '@material-ui/icons/Flag';
import Pause from '@material-ui/icons/Pause';
import Done from '@material-ui/icons/Done';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Remove from '@material-ui/icons/Remove';

function CurrentExerciseModal(props: any) {

    let initExercise: Exercise_t = {title:'',id:'',type: 0,muscles:[]};
    let initExerciseSeries: ExerciseSeries_t = {title: '', index: 0, exercises: [], type: 0, isShowing: false};

    let pageOptions = ["Exercise Summary", "Live Stats", "Rep-by-rep Stats"];
    let formFlagNames = ["General Form Alert", "Hyperextension", "Excesive Joint Tension", "Locked Joint", "Velocity Mismatch", "Uncontroled Negative", "Improper Stance"]
    const [currentExerciseSeries, setCurrentExerciseSeries] = useState<ExerciseSeries_t>(initExerciseSeries);
    const [currentExercise, setCurrentExercise] = useState<Exercise_t>(initExercise);
    const [currentSetIndex, setCurrentSetIndex] = useState(0);

    const [targetTempo, setTargetTempo] = useState<Tempo_t>({eccentric: -1, first_pause: -1, concentric: -1, second_pause: -1});

    const [pageIndex, setPageIndex] = useState(0);
    const [usingNodes, setUsingNodes] = useState(false);

    const [initSet, setInitSet] = useState(false);

    const [sessionTime, setSessionTime] = useState(0);
    const [offset, setOffset] = useState(0);

    const [workingMax, setWorkingMax] = useState(0);

    const [repStats, setRepStats] = useState<RepStats_t[]>([]);
        //     {
        //         startTime: 0,
        //         power: 148,
        //         ROM: 98,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 1,
        //         power: 142,
        //         ROM: 89,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 2,
        //         power: 138,
        //         ROM: 91,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 3,
        //         power: 158,
        //         ROM: 92,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 0,
        //         power: 148,
        //         ROM: 98,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 1,
        //         power: 142,
        //         ROM: 89,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 2,
        //         power: 138,
        //         ROM: 91,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 3,
        //         power: 158,
        //         ROM: 92,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 0,
        //         power: 148,
        //         ROM: 98,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 1,
        //         power: 142,
        //         ROM: 89,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 2,
        //         power: 138,
        //         ROM: 91,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 3,
        //         power: 158,
        //         ROM: 92,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 0,
        //         power: 148,
        //         ROM: 98,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 1,
        //         power: 142,
        //         ROM: 89,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 2,
        //         power: 138,
        //         ROM: 91,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        //     {
        //         startTime: 3,
        //         power: 158,
        //         ROM: 92,
        //         formAccuracy: 23,
        //         tempo: {concentric: 1, eccentric: 2, first_pause: 0, second_pause: 0},
        //         flag: -1
        //     },
        // ]);


        

    const [motionStream, setMotionStream] = useState<number[][]>([]);
    const [powerStats, setPowerStats] = useState<number[]>([]);
    const [romStats, setRomStats] = useState<number[]>([]);
    const [ceStats, setCEStats] = useState<number[]>([]);
    const [peakVelocityStats, setPeakVelocityStats] = useState<number[]>([]);

    const [velocityWaveform, setVelocityWaveform] = useState<number[]>([0,0,0,0.2,0.4,0.8,0.2,-0.2,-0.4,-0.8,-0.2,0,0,0]);

    const [showFlagMenu, setShowFlagMenu] = useState(false);
    const [showAllFlagsMenu, setShowAllFlagsMenu] = useState(false);
    const [selectedRepStatIndex, setSelectedRepStatIndex] = useState(0);
    const [selectedRepStats, setSelectedRepStats] = useState<RepStats_t>({
                startTime: 0,
                power: -1,
                ROM: -1,
                formAccuracy: -1,
                tempo: {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1},
                flag: -1,
                velocityStream: [],
                velocityStream_all: [],
                peakVelocity: -1,
                motionData_all: [],
                stats_all_nodes: []
            });

    const [TESTING, setTESTING] = useState(false);
    const [TESTING_STATS, setTESTING_STATS] = useState<any[]>([]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }


    

    function initialize() {
        fireSecondsTimer() 

        if (props.nodeManager !== undefined) {
            if (TESTING) {
                props.nodeManager.setMotionManagerDataUpdatedCallback(motionManagerDataUpdatedCallback);
            } else {
                props.nodeManager.setRepUpdatedCallback(repUpdatedCallback);
            }


            let numNodesConnected_temp = props.nodeManager.getNumberOfConnectedNodes();
            let numMyNodes_temp = props.nodeManager.getMyNodesList().length;

            //props.nodeManager.setConnectionAlertCallback(connectionAlertCallback);
            //props.nodeManager.setMotionDataCallback(dataUpdated);

            //if (numNodesConnected_temp > 0) {
                let connectedList: NodeInstance[] = props.nodeManager.getMyNodesList();
                for (var i = 0; i < connectedList.length; i++) {
                    let thisNode: NodeInstance = connectedList[i];
                    //thisNode.setLocationIndex(i);


                    //thisNode.setNodeDumpCallback(nodeDumpCallback);
                    //thisNode.setFullStreamState(false);
                    //thisNode.setStreamCallback(streamCallback);
                }
            //}
        }
    }

    function nodeDumpCallback(node?: NodeInstance, data?: any) {

        // if (props.mlCore !== undefined) {
        //     let result = props.mlCore.predict(data);
        //     // console.log("RESULT:");
        //     // console.log(result);
        // } else {
        //     // console.log(`> NodeTestMotion: nodeDumpCallback | ERROR! props.mlCore does not exist.`);
        // }


        /*
        if (node !== undefined) {
            let nodeLocationIndex = node.getLocationIndex();
            let nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];
            // console.log(`DATA DUMP FOR: ${nodeLocationNames[nodeLocationIndex]}`);
            if (props.nodeManager !== undefined) {
                props.nodeManager.requestDataDumpForAll(node, repStats.length + 1);
            }
        }


        // Update charts with dump data
        if (data === undefined || data === null) { return; }

        var q_buff: {w: number[], x: number[], y: number[], z: number[]} = {w: [], x:[], y: [], z: []};
        var v_buff: {x: number[], y: number[], z: number[]} = {x:[], y: [], z: []};


        for (var i = 0; i < data.length; i++) {
            let thisByte = data[i];
            let thisBytePosition = i % 7;

            switch (thisBytePosition) {
                case 0:
                    q_buff.w.push((thisByte - 128) / 122);
                    break;
                case 1:
                    q_buff.x.push((thisByte - 128) / 122);
                    break;
                case 2:
                    q_buff.y.push((thisByte - 128) / 122);
                    break;
                case 3:
                    q_buff.z.push((thisByte - 128) / 122);
                    break;
                case 4:
                    v_buff.x.push(thisByte); //((thisByte - 128) / 42);
                    break;
                case 5:
                    v_buff.y.push((thisByte - 128) / 42);
                    break;
                case 6:
                    v_buff.z.push((thisByte - 128) / 42);
                    break;
            }
        }

        let net_accel_threshold = 0.05;
        var magArray: number[] = [];

        var peakMag = -100;
        var finalRelevantIndex = 0;

        for (var i = 0; i < v_buff.x.length; i++) {

            let thisV_x = v_buff.x[i]; //Math.floor((v_buff.x[i] * 42) + 128);
            let thisV_y = v_buff.y[i]; //Math.floor((v_buff.y[i] * 42) + 128);
            let thisV_z = v_buff.z[i]; //Math.floor((v_buff.z[i] * 42) + 128);

            let mag = thisV_x; //Math.sqrt((thisV_x * thisV_x) + (thisV_y * thisV_y) + (thisV_z * thisV_z));
            magArray.push(mag);

            let magAbs = Math.abs(mag);
            if (magAbs > peakMag) {
                peakMag = magAbs;
            }

            if (magAbs < 0.01) {
                finalRelevantIndex = i;
            }
            //// console.log(`MAG: ${mag}`);

            // v_buff.x[i] = Math.abs(thisV_x) <= net_accel_threshold ? 0.0 : thisV_x;
            // v_buff.y[i] = Math.abs(thisV_y) <= net_accel_threshold ? 0.0 : thisV_y;
            // v_buff.z[i] = Math.abs(thisV_z) <= net_accel_threshold ? 0.0 : thisV_z;
        }

        var magArrayFinal: number[] = [];
        for (var i = 0; i < finalRelevantIndex; i++) {
            magArrayFinal.push(magArray[i]);
        }

        for (var i = 0; i < 5; i++) {
            magArrayFinal.push(0.0);
        }

        setVelocityWaveform(velocityWaveform.filter(item => false));
        setVelocityWaveform(magArrayFinal);

        setPeakVelocityStats(peakVelocityStats.concat(peakMag));
        */

    }

    function motionManagerDataUpdatedCallback(data: any[]) {
        setTESTING_STATS(data);
    }

    function repUpdatedCallback(repData: RepStats_t[]) {

        var reversedList: RepStats_t[] = [];
        for (var i = repData.length - 1; i >= 0; i--) {
            reversedList.push(repData[i]);
        }

        setRepStats(repStats.filter(item => false));
        setRepStats(repStats.concat(reversedList));

        var avgTempo: Tempo_t = {eccentric: 0, first_pause: 0, concentric: 0, second_pause: 0}
        var powerListTemp: number[] = [];
        var romListTemp: number[] = [];
        var ceListTemp: number[] = [];

        if (repData.length > 0) {
            for (var i = 0; i < repData.length; i++) {
                avgTempo.eccentric += repData[i].tempo.eccentric;
                avgTempo.concentric += repData[i].tempo.concentric;
                avgTempo.first_pause += repData[i].tempo.first_pause;
                avgTempo.second_pause += repData[i].tempo.second_pause;

                powerListTemp.push(repData[i].power);
                romListTemp.push(repData[i].ROM);
                ceListTemp.push(repData[i].tempo.concentric / repData[i].tempo.eccentric);
            }

            avgTempo.eccentric /= repData.length;
            avgTempo.concentric /= repData.length;
            avgTempo.first_pause /= repData.length;
            avgTempo.second_pause /= repData.length;

            avgTempo.eccentric = Math.round(avgTempo.eccentric * 10) / 10;
            avgTempo.concentric = Math.round(avgTempo.concentric * 10) / 10;
            avgTempo.first_pause = Math.round(avgTempo.first_pause * 10) / 10;
            avgTempo.second_pause = Math.round(avgTempo.second_pause * 10) / 10;
        }

        setPowerStats(powerListTemp);
        setRomStats(romListTemp);
        setCEStats(ceListTemp);

        tempoUpdated(avgTempo);

        let newestIndex = 0; // repData.length - 1;

        setSelected(repData[repData.length - 1], newestIndex);
        //setSelectedRepStatIndex(0);



        setVelocityWaveform(velocityWaveform.filter(item => false));
        let thisRepStat: RepStats_t = repData[newestIndex];
        if (thisRepStat !== undefined) {
            if (thisRepStat.velocityStream !== undefined) {
                setVelocityWaveform(velocityWaveform.concat(thisRepStat.velocityStream));
            }
        }


        // If not all components of avgTempo are still 0, return avgTempo. Otherwise, continue on to return 
        // specified target tempo
        if (avgTempo.eccentric !== 0 && avgTempo.concentric !== 0 && avgTempo.first_pause !== 0 && avgTempo.second_pause !== 0) {
            
        }
    }

    useEffect(() => {
        if (props.currentWorkout !== undefined) {

            setCurrentExerciseSeries(props.currentWorkout.workout.exerciseSeries![props.currentWorkout.seriesIndex]);
            setCurrentExercise(props.currentWorkout.workout.exerciseSeries![props.currentWorkout.seriesIndex].exercises![props.currentWorkout.exerciseIndex]);
            setCurrentSetIndex(props.currentWorkout.setIndex);

            let WM = getWM();
            setWorkingMax(WM);
        }
    }, [props.currentWorkout])

    function getWM() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return -1; }

        let thisExercise: Exercise_t = props.currentWorkout.workout.exerciseSeries[props.currentWorkout.seriesIndex].exercises[props.currentWorkout.exerciseIndex];
        if (thisExercise.userWorkingMaxHistory !== undefined && thisExercise.userWorkingMaxHistory.length > 0) {
            // Return the most recent WM in user's history
            let mostRecentWMObj = thisExercise.userWorkingMaxHistory[0];
            return Math.round(mostRecentWMObj.working_max * 10) / 10;
        } else {
            // No user history exists - generate avg. WM from set stats
            

            if (props.currentWorkout.setIndex > 0 && props.currentWorkout.setStats !== undefined && props.currentWorkout.setStats[props.currentWorkout.seriesIndex] !== undefined && props.currentWorkout.setStats[props.currentWorkout.seriesIndex].length > 0) {
                var avgWM = 0.0;
                var avgWM_count = 0;
                for (var i = 0; i < props.currentWorkout.setStats[props.currentWorkout.seriesIndex].length; i++) {
                    let thisSetStats: SetStats_t[] = props.currentWorkout.setStats[props.currentWorkout.seriesIndex][i];
                    let thisExStat: SetStats_t = props.currentWorkout.exerciseIndex < thisSetStats.length ? thisSetStats[props.currentWorkout.exerciseIndex] : thisSetStats[0];
                    avgWM += thisExStat.weight / (1.0278 - (0.0278 * thisExStat.manualEntryReps));
                    avgWM_count += 1;
                }

                avgWM = avgWM_count === 0 ? avgWM : avgWM / avgWM_count;
                avgWM = Math.round(avgWM * 10) / 10;

                return avgWM;
            } else {
                return -1
            }
            
        }
    }

    function nextButtonPressed() {
        setOffset(sessionTime);

        if (props.completeSet !== undefined) {
            props.completeSet();
        }
    }

    function fireSecondsTimer() {
        if (props.currentWorkout !== undefined) {
            var d = new Date();
              var n = d.getTime();
              var time = Math.floor(n / 1000);
              var dt = time - props.currentWorkout.startTime - offset;
              
              setSessionTime(dt < 0 ? 0 : dt);
        }
        setTimeout(fireSecondsTimer, 100);
        return;
    }

    function getTimeString(seconds: number) {
        let secs = seconds % 60;
        let mins = Math.floor(seconds / 60);

        let secsString = `${secs < 10 ? '0' : ''}${secs}`;
        let minsString = `${mins < 10 ? '0' : ''}${mins}`;

        return `${minsString}m ${secsString}s`
    }

    function pageSelected(index: number) {
        setPageIndex(index);
    }

    function getOptionClass(atIndex: number) {
        return atIndex === pageIndex ? "current-exercise-modal-menu-selected-subpage" : "current-exercise-modal-menu-unselected-subpage";
    }

    function changeReps(toReps: any) {
        if (props.currentWorkout === undefined) { return; }
        props.changeReps(Number(toReps), props.currentWorkout.seriesIndex, props.currentWorkout.exerciseIndex, props.currentWorkout.setIndex);
    }

    function tempoUpdated(toTempo: Tempo_t) {
        // setTargetTempo(toTempo);
        props.changeTempo(toTempo, props.currentWorkout.seriesIndex, props.currentWorkout.exerciseIndex, props.currentWorkout.setIndex);
    }

    function getCurrentTargetReps() {
        if (props.currentWorkout === undefined) { return; }
        if (props.currentWorkout.seriesIndex < props.currentWorkout.setStats.length && 
            props.currentWorkout.setIndex < props.currentWorkout.setStats[props.currentWorkout.seriesIndex].length && 
            props.currentWorkout.exerciseIndex < props.currentWorkout.setStats[props.currentWorkout.seriesIndex][props.currentWorkout.setIndex].length) {

            let thisSetStat: SetStats_t = props.currentWorkout.setStats[props.currentWorkout.seriesIndex][props.currentWorkout.setIndex][props.currentWorkout.exerciseIndex];
            return thisSetStat.manualEntryReps;
        }

        // if (currentExercise.reps !== undefined && currentSetIndex < currentExercise.reps.length) {
        //     return currentExercise.reps![currentSetIndex];
        // }
        
        return 0;
    }

    function getButtonString() {
        let moreExercisesInSuperset = false;
        if (props.currentWorkout !== undefined) {
            moreExercisesInSuperset = props.currentWorkout.exerciseIndex < currentExerciseSeries.exercises.length - 1;
        }

        return moreExercisesInSuperset === true ? 'Next in Series' : 'Finish Set';
    }

    function getTempo() {
        if (props.currentWorkout === undefined) { return; }

        


        if (props.currentWorkout.seriesIndex < props.currentWorkout.setStats.length && 
            props.currentWorkout.setIndex < props.currentWorkout.setStats[props.currentWorkout.seriesIndex].length && 
            props.currentWorkout.exerciseIndex < props.currentWorkout.setStats[props.currentWorkout.seriesIndex][props.currentWorkout.setIndex].length) {

            let thisSetStat: SetStats_t = props.currentWorkout.setStats[props.currentWorkout.seriesIndex][props.currentWorkout.setIndex][props.currentWorkout.exerciseIndex];
            return thisSetStat.tempo;
        }

        return {eccentric: 0, first_pause: 0, concentric: 0, second_pause: 0};
    }

    function getIsUsingNodes() {
        if (props.currentWorkout === undefined) { return false; }

        return props.currentWorkout.usingNodes;
    }

    function getAvgPower() {
        var avgPower = 0.0;

        if (repStats.length > 0) {
            for (var i = 0; i < repStats.length; i++) {
                avgPower += repStats[i].power;
            }

            avgPower /= repStats.length;
        }

        return Math.round(avgPower * 10) / 10;
    }

    function getAvgPeakVelocity() {
        

        if (peakVelocityStats.length > 0) {
            var avgPeakV = 0.0;
            for (var i = 0; i < peakVelocityStats.length; i++) {
                avgPeakV += peakVelocityStats[i];
            }

            avgPeakV /= peakVelocityStats.length;

            return Math.round(avgPeakV * 1000) / 1000;

        } else {
            return '0.000';
        }
    }

    function flagReasonSelected(reasonIndex: number) {
        setShowFlagMenu(false);
        setShowAllFlagsMenu(false);
        setRepStats(repStats.map((item: RepStats_t, index: number) => {
            if (index === selectedRepStatIndex) {
                let statTemp: RepStats_t = item;
                statTemp.flag = reasonIndex;
                return statTemp;
            } else {
                return item;
            }
        }));
    }

    function updateFlagState(toState: number, forIndex: number) {
        setRepStats(repStats.map((item: RepStats_t, index: number) => {
            if (index === forIndex) {
                let statTemp: RepStats_t = item;
                statTemp.flag = toState;
                return statTemp;
            } else {
                return item;
            }
        }));
    }

    function toggleFlagMenu() {
        setShowFlagMenu(!showFlagMenu);
    }

    function toggleAllFlagsMenu() {
        setShowAllFlagsMenu(!showAllFlagsMenu);
    }

    window.addEventListener('click', function(e){   
        checkFlagMenuHide(e);
        checkAllFlagsMenuHide(e);
    });

    function checkFlagMenuHide(e: any) {
        if (document === null || document.getElementById('flag-reason-menu') === null || document.getElementById('flag-reason-button') === null || e.target === null) { return; }
        if (document.getElementById('flag-reason-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('flag-reason-button')!.contains(e.target as Node))) {
                if (showFlagMenu === true) {
                    setShowFlagMenu(false);
                }
            }
        }
    }

    function checkAllFlagsMenuHide(e: any) {
        if (document === null || document.getElementById('all-flags-reason-menu') === null || document.getElementById('all-flags-reason-button') === null || e.target === null) { return; }
        if (document.getElementById('all-flags-reason-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('all-flags-reason-button')!.contains(e.target as Node))) {
                if (showAllFlagsMenu === true) {
                    setShowAllFlagsMenu(false);
                }
            }
        }
    }

    function setSelected(stats: RepStats_t, index: number) {
        setSelectedRepStatIndex(index);
        setSelectedRepStats(stats);

        // console.log("SET SELECTED:", stats.velocityStream_all);

        if (stats.velocityStream !== undefined) {
            //setVelocityWaveform(velocityWaveform.filter(item => false));
            //setVelocityWaveform(velocityWaveform.concat(stats.velocityStream));
        }
    }

    function streamCallback(m_buff: MotionData_t[]) {

    }


	return (
		<div className="modal-background current-exercise-modal-background">
            <div className="current-exercise-modal-container">
                <div className="current-exercise-modal-header-container">
                    <div className="current-exercise-modal-header-set-title-container">
                        <div className="current-exercise-modal-header-record-icon"/>
                        <h4>Recording {currentExerciseSeries.title === '' ? 'A' : currentExerciseSeries.title} Series Set {currentSetIndex + 1 < 10 ? '0' : ''}{currentSetIndex + 1}</h4>
                    </div>
                    <div className="current-exercise-modal-header-buttons-container">
                        <div id="flag-reason-button" className="current-exercise-modal-header-button current-exercise-modal-header-button-red">
                            <div onClick={() => flagReasonSelected(0)} className="current-exercise-modal-header-button-inner">
                                <Flag className="current-exercise-modal-header-button-icon current-exercise-modal-header-button-icon-flag"/>
                                <p>Flag Rep</p>
                            </div>
                            
                            <div onClick={() => toggleFlagMenu()} className="current-exercise-modal-header-button-dropdown-option">
                                <KeyboardArrowDown className="current-exercise-modal-header-button-dropdown-option-icon"/>
                            </div>
                        </div>
                        <div hidden={true} className="current-exercise-modal-header-button current-exercise-modal-header-button-grey">
                            <Pause className="current-exercise-modal-header-button-icon current-exercise-modal-header-button-icon-pause"/>
                            <p>Pause</p>
                        </div>
                        <div onClick={() => props.completeSet()} className="current-exercise-modal-header-button current-exercise-modal-header-button-blue">
                            <Done className="current-exercise-modal-header-button-icon current-exercise-modal-header-button-icon-done"/>
                            <p>Done Set</p>
                        </div>

                        <CSSTransition in={showFlagMenu} timeout={200} classNames="menu" unmountOnExit>
                            <div id="flag-reason-menu" className="workout-plan-list-cycle-overview-header-card-menu-container current-exercise-modal-flag-menu">
                                <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                                    <p>Flag Reason</p>
                                </div>
                                {
                                    formFlagNames.map((item: string, index: any) => (
                                        <div onClick={() => flagReasonSelected(index)} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                                
                            </div>
                        </CSSTransition>
                    </div>
                </div>
                <div className="current-exercise-modal-body-container">
                    <div className="current-exercise-modal-body-reps-container">
                        <div className="current-exercise-modal-body-reps-header-container">
                            <div className="current-exercise-modal-body-reps-header-col-container">
                                <h1>{repStats.length < 10 ? '0' : ''}{repStats.length} / 12</h1>
                                <p>Reps Detected</p>
                            </div>
                            <div className="current-exercise-modal-body-reps-header-col-container current-exercise-modal-body-reps-header-col-container-time">
                                <h1>{getTimeString(sessionTime)}</h1>
                                <p>Time Elapsed</p>
                            </div>
                        </div>
                        <div className="current-exercise-modal-body-reps-list-header-container">
                            <div className="current-exercise-modal-body-reps-list-header-text-container">
                                <h4>Rep Breakdown</h4>
                            </div>
                            <div className="current-exercise-modal-body-reps-list-header-button-container">
                                <p>Set Overview</p>
                                <ArrowForwardIos className="current-exercise-modal-body-reps-list-header-button-icon"/>
                            </div>
                        </div>
                        <div hidden={repStats.length === 0} className="current-exercise-modal-body-reps-list-container">
                            <div className="current-exercise-modal-body-reps-list-descs-container">
                                <div className="current-exercise-modal-body-reps-list-col current-exercise-modal-body-reps-list-col-0">
                                    Rep
                                </div>
                                <div className="current-exercise-modal-body-reps-list-col current-exercise-modal-body-reps-list-col-1">
                                    Power
                                </div>
                                <div className="current-exercise-modal-body-reps-list-col current-exercise-modal-body-reps-list-col-2">
                                    ROM
                                </div>
                                <div className="current-exercise-modal-body-reps-list-col current-exercise-modal-body-reps-list-col-3">
                                    Tempo
                                </div>
                                <div className="current-exercise-modal-body-reps-list-col current-exercise-modal-body-reps-list-col-4">
                                    Form
                                </div>
                            </div>
                            <div className="current-exercise-modal-body-reps-list-content">
                                {
                                    repStats.map((item: RepStats_t, index: number) => (
                                        <div className={`current-exercise-modal-body-reps-list-cell ${index === selectedRepStatIndex ? 'current-exercise-modal-body-reps-list-cell-selected' : ''}`}>
                                            <div hidden={index !== selectedRepStatIndex} className="current-exercise-modal-body-reps-list-cell-current-bar"/>
                                            <div onClick={() => setSelected(item, index)} className="current-exercise-modal-body-reps-list-col-cell current-exercise-modal-body-reps-list-col-0">
                                                <h4>{(repStats.length - index) < 10 ? '0' : ''}{(repStats.length - index)}</h4>
                                            </div>
                                            <div onClick={() => setSelected(item, index)} className="current-exercise-modal-body-reps-list-col-cell current-exercise-modal-body-reps-list-col-1">
                                                <h4>{Math.floor(item.power)}W</h4>
                                            </div>
                                            <div onClick={() => setSelected(item, index)} className="current-exercise-modal-body-reps-list-col-cell current-exercise-modal-body-reps-list-col-2">
                                                <h4>{Math.round(item.ROM * 100)}%</h4>
                                            </div>
                                            <div onClick={() => setSelected(item, index)} className="current-exercise-modal-body-reps-list-col-cell current-exercise-modal-body-reps-list-col-3">
                                                <h4>1 : 0 : 2 : 0</h4>
                                            </div>
                                            <div className="current-exercise-modal-body-reps-list-col-cell-buttons current-exercise-modal-body-reps-list-col-cell current-exercise-modal-body-reps-list-col-4">
                                                <div onClick={() => updateFlagState(1, index)} className={`current-exercise-modal-body-reps-list-col-cell-form-button ${item.flag !== undefined && item.flag >= 0 ? 'current-exercise-modal-body-reps-list-col-cell-form-button-red' : ''}`}>
                                                    <Flag className={`current-exercise-modal-body-reps-list-col-cell-form-button-icon ${item.flag !== undefined && item.flag >= 0 ? 'current-exercise-modal-body-reps-list-col-cell-form-button-icon-red' : ''}`}/>
                                                </div>
                                                <div onClick={() => updateFlagState(-1, index)} className={`current-exercise-modal-body-reps-list-col-cell-form-button ${item.flag !== undefined && item.flag === -1 ? 'current-exercise-modal-body-reps-list-col-cell-form-button-blue' : ''}`}>
                                                    <Remove className={`current-exercise-modal-body-reps-list-col-cell-form-button-icon ${item.flag !== undefined && item.flag === -1 ? 'current-exercise-modal-body-reps-list-col-cell-form-button-icon-blue' : ''}`}/>
                                                </div>
                                                <div onClick={() => updateFlagState(-2, index)} className={`current-exercise-modal-body-reps-list-col-cell-form-button ${item.flag !== undefined && item.flag === -2 ? 'current-exercise-modal-body-reps-list-col-cell-form-button-green' : ''}`}>
                                                    <Star className={`current-exercise-modal-body-reps-list-col-cell-form-button-icon ${item.flag !== undefined && item.flag === -2 ? 'current-exercise-modal-body-reps-list-col-cell-form-button-icon-green' : ''}`}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div hidden={repStats.length !== 0} className="current-exercise-modal-body-reps-list-container current-exercise-modal-body-reps-list-container-empty">
                            <h4>Get after it!</h4>
                            <p>Complete reps with Nodes to view rep breakdown here</p>
                        </div>
                    </div>
                    <div className="current-exercise-modal-body-content-container">
                        <div className="current-exercise-modal-body-content-header-container">
                            <h1>Rep {(repStats.length - selectedRepStatIndex) < 10 ? '0' : ''}{(repStats.length - selectedRepStatIndex)} Details</h1>
                            <div className="current-exercise-modal-body-content-header-page-names-container">
                                <div onClick={() => pageSelected(0)} className={`current-exercise-modal-body-content-header-page-name-cell ${pageIndex === 0 ? 'current-exercise-modal-body-content-header-page-name-cell-selected' : ''}`}>
                                    <p>Overview</p>
                                    <div hidden={pageIndex !== 0} className="current-exercise-modal-body-content-header-page-name-cell-selected-bar"/>
                                </div>
                                <div onClick={() => pageSelected(1)} className={`current-exercise-modal-body-content-header-page-name-cell ${pageIndex === 1 ? 'current-exercise-modal-body-content-header-page-name-cell-selected' : ''}`}>
                                    <p>Velocity</p>
                                    <div hidden={pageIndex !== 1} className="current-exercise-modal-body-content-header-page-name-cell-selected-bar"/>
                                </div>
                                <div onClick={() => pageSelected(2)} className={`current-exercise-modal-body-content-header-page-name-cell ${pageIndex === 2 ? 'current-exercise-modal-body-content-header-page-name-cell-selected' : ''}`}>
                                    <p>Power</p>
                                    <div hidden={pageIndex !== 2} className="current-exercise-modal-body-content-header-page-name-cell-selected-bar"/>
                                </div>
                                <div onClick={() => pageSelected(3)} className={`current-exercise-modal-body-content-header-page-name-cell ${pageIndex === 3 ? 'current-exercise-modal-body-content-header-page-name-cell-selected' : ''}`}>
                                    <p>Motion</p>
                                    <div hidden={pageIndex !== 3} className="current-exercise-modal-body-content-header-page-name-cell-selected-bar"/>
                                </div>
                            </div>
                            <div id="all-flags-reason-button" onClick={() => toggleAllFlagsMenu()} className={`current-exercise-modal-body-content-header-form-selector-container current-exercise-modal-body-content-header-form-selector-container-${selectedRepStats.flag === undefined || selectedRepStats.flag === -1 ? 'blue' : (selectedRepStats.flag === -2 ? 'green' : 'red')}`}>
                                <div hidden={selectedRepStats.flag === undefined || selectedRepStats.flag < 0} className="current-exercise-modal-body-content-header-form-selector-container-icon-container current-exercise-modal-body-content-header-form-selector-container-icon-container-red">
                                    <Flag className="current-exercise-modal-body-content-header-form-selector-container-icon"/>
                                </div>
                                <div hidden={selectedRepStats.flag === undefined || selectedRepStats.flag !== -1} className="current-exercise-modal-body-content-header-form-selector-container-icon-container current-exercise-modal-body-content-header-form-selector-container-icon-container-blue">
                                    <Remove className="current-exercise-modal-body-content-header-form-selector-container-icon"/>
                                </div>
                                <div hidden={selectedRepStats.flag === undefined || selectedRepStats.flag !== -2} className="current-exercise-modal-body-content-header-form-selector-container-icon-container current-exercise-modal-body-content-header-form-selector-container-icon-container-green">
                                    <Star className="current-exercise-modal-body-content-header-form-selector-container-icon"/>
                                </div>
                                <div className="current-exercise-modal-body-content-header-form-selector-text-container">
                                    <p>{selectedRepStats.flag === undefined || selectedRepStats.flag === -1 ? "Okay Form" : (selectedRepStats.flag === -2 ? 'Perfect Form' : formFlagNames[selectedRepStats.flag])}</p>
                                </div>
                            </div>
                            <CSSTransition in={showAllFlagsMenu} timeout={200} classNames="menu" unmountOnExit>
                            <div id="all-flags-reason-menu" className="workout-plan-list-cycle-overview-header-card-menu-container current-exercise-modal-flag-menu current-exercise-modal-all-flags-menu">
                                <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                                    <p>Form Analysis</p>
                                </div>
                                <div onClick={() => flagReasonSelected(-2)} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="current-exercise-modal-all-flags-menu-row-icon-container">
                                        <Star className="current-exercise-modal-all-flags-menu-row-icon current-exercise-modal-all-flags-menu-row-icon-green"/>
                                    </div>
                                    <p>Perfect Form</p>
                                </div>
                                <div onClick={() => flagReasonSelected(-1)} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="current-exercise-modal-all-flags-menu-row-icon-container">
                                        <Remove className="current-exercise-modal-all-flags-menu-row-icon current-exercise-modal-all-flags-menu-row-icon-blue"/>
                                    </div>
                                    <p>Okay Form</p>
                                </div>
                                <div className="current-exercise-modal-all-flags-menu-flags-container">
                                    <h4>Flags</h4>
                                    {
                                        formFlagNames.map((item: string, index: any) => (
                                            <div onClick={() => flagReasonSelected(index)} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                                <p>{item}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                
                                
                            </div>
                        </CSSTransition>
                        </div>
                        <div className="current-exercise-modal-body-content-body-container">
                            <div className="current-exercise-modal-body-content-body-section-container">
                                <div className="intra-workout-section-header-container">
                                    <div className="intra-workout-section-header-icon-container">
                                        <Timeline className="intra-workout-section-header-icon"/>
                                    </div> 
                                    <h4>Rep Summary</h4>
                                </div>
                                <div className="">
                                    <IntraWorkoutCurrentExerciseRepSummary 
                                        selectedRepStats={selectedRepStats}
                                        currentWorkout={props.currentWorkout}/>
                                </div>
                            </div>
                            <div className="current-exercise-modal-body-content-body-section-container">
                                <div className="intra-workout-section-header-container">
                                    <div className="intra-workout-section-header-icon-container">
                                        <Speed className="intra-workout-section-header-icon"/>
                                    </div> 
                                    <h4>Velocity Summary</h4>
                                    <div className="current-exercise-modal-body-content-body-section-more-info-button">
                                        <p>View all velocities</p>
                                        <ArrowForwardIos className="current-exercise-modal-body-content-body-section-more-info-button-icon"/>     
                                    </div>
                                </div>
                                <div className="current-exercise-modal-body-content-body-chart-container">
                                    <CurrentExerciseModalChart
                                        title={"Peak Velocity"}
                                        subTitle={""}
                                        units={['m/s']}
                                        stepSize={0.5}
                                        statNames={['Peak Velocity']}
                                        chartType='line'
                                        loading={false}
                                        stats={selectedRepStats.velocityStream_all === undefined ? [] : selectedRepStats.velocityStream_all}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

/*
<div className="current-exercise-modal-content-page-charts-row">
                            <div className="current-exercise-modal-content-page-chart-container current-exercise-modal-content-page-chart-container-full">
                                <CurrentExerciseModalChart
                                    title={"Velocity Plot"}
                                    subTitle={""}
                                    units={['m/s']}
                                    stepSize={0.1}
                                    statNames={['Velocity']}
                                    chartType='line'
                                    loading={false}
                                    stats={[velocityWaveform]}/>
                            </div>
                        </div>
*/

export default CurrentExerciseModal;