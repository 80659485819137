import React, { useState, useEffect } from 'react';
import './NewWorkout_Nodes.css';

import NodeSet_t from '../../../Interfaces/NodeSet_t';
import Client_t from '../../../Interfaces/Client_t';
import NodeInstance from '../../../NodeInstance';

import InfoBlock from '../../../components/Base/InfoBlock';
import AlertBlock from '../../../components/Base/AlertBlock';
import NodeListCell from '../../../components/Nodes/NodeListCell';

import '@material-ui/core';
import ControlCamera from '@material-ui/icons/ControlCamera';

function NewWorkoutNodePage(props: any) {

    let nodeOptions = [
        {
            name: "3 Nodes",
            nodes: [0,1,8]
        },
        {
            name: "5 Nodes",
            nodes: [0,1,4,5,8]
        },
        {
            name: "8 Nodes",
            nodes: [0,1,2,3,4,5,6,7]
        },
        {
            name: "9 Nodes",
            nodes: [0,1,2,3,4,5,6,7,8]
        }
    ];

    let nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];

    const [initSet, setInitSet] = useState(false);
    const [syncNodesStage, setSyncNodesStage] = useState(0);
    const [numberOfNodesIndex, setNumberOfNodesIndex] = useState(0);
    const [assignedNodesIndex, setAssignedNodesIndex] = useState(0);
    const [numberOfNodesConnected, setNumberOfNodesConnected] = useState(8);
    const [syncedNodes, setSyncedNodes] = useState<NodeInstance[]>([]);
    const [displayCalibrationError, setDisplayCalibrationError] = useState(false);
    const [readyToRecalibrate, setReadyToRecalibrate] = useState(false);
    const [showScanButton, setShowScanButton] = useState(false);

    const [showDisconnectionError, setShowDisconnectionError] = useState(false);

    const [shouldRunAnimation, setShouldRunAnimation] = useState(true);
    const [circleCSS, setCircleCSS] = useState("");

    if (props.nodeManager !== undefined) {
        props.nodeManager.setTapAndHoldCallback(tapAndHoldCallback);
        props.nodeManager.setCalibrationCallback(calibrationComplete);
        props.nodeManager.setCalibrationFailedCallback(calibrationFailed);
        props.nodeManager.setRecalibrationReadyCallback(recalibrationReady);
        
    } 
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setShowDisconnectionError(false);
        if (props.nodeManager !== undefined) {
            props.nodeManager.scanForNodes();
            let numNodes = props.nodeManager.getNumberOfConnectedNodes();
            setNumberOfNodesConnected(numNodes);
            props.nodeManager.setAllConnectionCallbacks(connectionCallback);
        }
    }

    useEffect(() => {
        
    }, [props.workout]);

    function openNodesPage() {
        props.openNodesPage();
    }

    function moveToNextStage() {

        if (syncNodesStage === 1) {
            if (props.nodeManager !== undefined) {
                // console.log("WILL TURN ON TAP");
                //props.nodeManager.setTapAndHoldCallback(tapAndHoldCallback);
                props.nodeManager.enableTap();
            } 
        }

        setSyncNodesStage(syncNodesStage + 1);
    }

    function numberOfNodesPressed(withIndex: number) {
        setNumberOfNodesIndex(withIndex);
    }

    function getNumberOfNodesCellCSS(forIndex: number) {
        return forIndex === numberOfNodesIndex ? "new-workout-nodes-info-number-of-nodes-cell-selected" : "new-workout-nodes-info-number-of-nodes-cell-unselected";
    }

    function getNumberOfNodesOverlayName() {
        let names = ["all-nodes-3.png", "all-nodes-5.png", "all-nodes-8.png", "all-nodes-9.png"];
        return names[numberOfNodesIndex];
    }

    function getNodeImages() {
        var composed = [];

        for (var i = 0; i < 9; i++) {
            let includeNodes: number[] = nodeOptions[numberOfNodesIndex].nodes;
            for (var j = 0; j < includeNodes.length; j++) {
                let thisNodeIndex = includeNodes[j];
                let colour = syncNodesStage === 0 || j === assignedNodesIndex ? "red" : (j < assignedNodesIndex ? "green" : "black");
                let imageTag = <img className="new-workout-body-image" src={`${process.env.PUBLIC_URL}/assets/images/body/nodes/${thisNodeIndex}/${colour}.png`}/>;
                composed.push(imageTag);
            }
        }

        return composed;
    }

    function getBodyLocationName() {
        let includeNodes: number[] = nodeOptions[numberOfNodesIndex].nodes;
        let nodeIndex = includeNodes[assignedNodesIndex];
        if (nodeIndex < nodeLocationNames.length) {
            return nodeLocationNames[nodeIndex];
        }
        return "";
    }

    function nextNodeSelected() {
        let includeNodes: number[] = nodeOptions[numberOfNodesIndex].nodes;
        // console.log(`NEXT NODE SELECTED ${assignedNodesIndex}`);
        if (assignedNodesIndex >= includeNodes.length) {
            props.nodesAssigned();
            if (props.nodeManager !== undefined) {
                // console.log("WILL TURN OFF TAP");
                props.nodeManager.disableTap();
            } 
        } else {
            // console.log('MOVING TO NEXT NODE');
            setAssignedNodesIndex(assignedNodesIndex + 1);
            
        }
    }

    function pingNodePressed(uuid: string) {
        if (props.nodeManager !== undefined) {
            props.nodeManager.pingNode(uuid);
        }
    }

    function connectionCallback(toState: boolean) {
        // console.log(`> NewWorkout_Nodes.tsx: Connection Callback fired with connection state updated to ${toState}`);
        if (props.nodeManager === undefined) { return; }
        var numNodesConnected = 0;
        let myNodes: NodeInstance[] = props.nodeManager.getMyNodesList();

        if (myNodes.length > 0) {
            for (var i = 0; i < myNodes.length; i++) {
                let thisNode: NodeInstance = myNodes[i];
                if (thisNode.getIsConnected() === true) {
                    numNodesConnected += 1;
                }
            }
        }

        let minNumNodes = nodeOptions[numberOfNodesIndex].nodes.length;
        let showConnectionError = minNumNodes > numNodesConnected;

        // console.log(`> NewWorkout_Nodes.tsx:  ${numNodesConnected} connected, needs at least ${minNumNodes}, error result is ${showConnectionError}`);
        

        if (showConnectionError === true && props.nodeManager !== undefined) {

            props.nodeManager.endGyroCalibration();
            props.nodeManager.scanForNodes();
            // console.log(`SYNC NODE STAGE IS ${syncNodesStage}`);
            if (syncNodesStage === 2) {
                // console.log(`> NewWorkout_Nodes.tsx: Will end gyro calibration!!`);
                
                setSyncNodesStage(1);
            }
        } else {
            setSyncNodesStage(0);
        }

        setShowDisconnectionError(showConnectionError);
        setNumberOfNodesConnected(numNodesConnected);
    }

    function tapAndHoldCallback(node: NodeInstance) {

        // console.log(`NODE PRESSED: ${node.getDeviceSIN()}`);

        var alreadySynced = false;
        if (syncedNodes.length > 0) {
            let thisTappedNodeUUID = node.getUUID();
            for (var i = 0; i < syncedNodes.length; i++) {
                let thisSyncedNode = syncedNodes[i];
                let thisSyncedNodeUUID = thisSyncedNode.getUUID();
                if (thisSyncedNodeUUID === thisTappedNodeUUID) {
                    alreadySynced = true;
                }
            }
        }

        // If the tapped Node has already been synced, exit
        if (alreadySynced === true ) { return; }

        let includeNodes: number[] = nodeOptions[numberOfNodesIndex].nodes;
        let nodeIndex = includeNodes[assignedNodesIndex];
        node.setLocationIndex(nodeIndex);

        if (props.nodeManager !== undefined) {
            props.nodeManager.setNodeLocation(node.getUUID(), nodeIndex);
        }

        node.setSyncedColour();
        // console.log(`WILL ADD PRESSED NODE: ${node.getDeviceSIN()}`);
        setSyncedNodes(syncedNodes.concat(node));
        nextNodeSelected();
    }

    function runCalibration() {
        // setDisplayCalibrationError(false);
        // if (props.nodeManager !== undefined) {
        //     setSyncNodesStage(2);
        //     props.nodeManager.runGyroCalibration();
        // }

        // setShouldRunAnimation(true);
        // runCalibrationAnimation(false);
    }

    function calibrationComplete() {
        // console.log("> NewWorkout_Nodes.tsx: CALIRATION COMPLETE");

        setSyncNodesStage(3);
        setShouldRunAnimation(false);

        if (displayCalibrationError === false) {
            
        } else {
            // console.log("> NewWorkout_Nodes.tsx: WOULD HAVE SHOWN UI ERROR!!");
        }
        
    }

    function calibrationFailed(forNode: NodeInstance) {
        // console.log(`> NewWorkout_Nodes.tsx: FAIL FOR ${forNode.getDeviceSIN()}`);
        
        setDisplayCalibrationError(true);
        setReadyToRecalibrate(false);


        setTimeout(() => {
            setShouldRunAnimation(false);
        }, 300);
        

        setTimeout(() => {
            setShouldRunAnimation(false);
            setShowScanButton(true);
        }, 5000);
    }

    function recalibrationReady() {
        setTimeout(() => {
            setReadyToRecalibrate(true);
            setShowScanButton(false);
        }, 2000);
        
    }

    function scanAgain() {
        if (props.nodeManager !== undefined) {
            props.nodeManager.scanForNodes();
            setShowScanButton(false);
            setTimeout(() => {
                setShowScanButton(true);
            }, 5000);
        }
    }

    function runCalibrationAnimation(isBlue: boolean) {
        
        setCircleCSS(isBlue ? "new-workout-nodes-circle-dark" : "new-workout-nodes-circle-blue")
        setTimeout(() => {
            if (shouldRunAnimation === false) {return;}
            runCalibrationAnimation(!isBlue);
            
        }, 600);
    }

	return (
		<div className="new-workout-nodes-container">
            
            <div hidden={syncNodesStage !== 0 || numberOfNodesConnected < 3} className="new-workout-nodes-info-container">
                <div className="new-workout-workout-header">
                    <h1>Connect Nodes</h1>
                </div>
                <div onClick={() => openNodesPage()} hidden={true} className="new-workout-nodes-info-container-info-block">
                    <InfoBlock  infoText={`You currently have ${numberOfNodesConnected} Nodes connected. Go to 'Nodes' in the main menu to connect more.`}/>
                </div>
                <div className="new-workout-nodes-info-text-container">
                    
                    <h4>How many Nodes will you use for this workout?</h4>
                    <p>Select the number of Nodes you will be providing your client with for this workout. Once selected, we'll configure which Node is where on their body.</p>
                </div>
                <div className="new-workout-nodes-info-number-of-nodes-container">
                    { nodeOptions.map((item: any, index: number) => (
                        <div 
                            hidden={item.nodes.length > numberOfNodesConnected}
                            onClick={() => numberOfNodesPressed(index)} 
                            className={`new-workout-nodes-info-number-of-nodes-cell ${getNumberOfNodesCellCSS(index)}`}>
                            {item.name}
                        </div>
                    ))}
                </div>
                <div onClick={() => moveToNextStage()} className="new-workout-nodes-info-number-of-nodes-confirm-button">Confirm using {nodeOptions[numberOfNodesIndex].name}</div>
                <div onClick={() => props.skipNodes()} className="new-workout-nodes-info-skip-button">Workout without Nodes</div>
            </div>
            <div hidden={numberOfNodesConnected >= 3} className="new-workout-nodes-info-container">
                <div className="new-workout-workout-header">
                    <h1>Connect Nodes</h1>
                </div>
                <div hidden={!(showDisconnectionError && syncNodesStage > 0)}>
                    <AlertBlock alertText="Uh oh! It looks like you lost connection to a Node. Make sure you're in range and the Node is on. You can also try power cycling the Node."/>
                </div>
                <div className="new-workout-nodes-info-text-container">
                    <h4>You need to connect more Nodes</h4>
                    <p>In order to get the most out of this workout, head over to the Nodes page to connect your Node sets. You will need to connect at least 3 to continue.</p>
                </div>
                <div onClick={() => openNodesPage()} className="new-workout-nodes-info-number-of-nodes-confirm-button">Connect Nodes</div>
                <div onClick={() => props.skipNodes()} className="new-workout-nodes-info-skip-button">Workout without Nodes</div>
            </div>
            <div hidden={syncNodesStage !== -1 || numberOfNodesConnected < 3} className="new-workout-nodes-info-container">
                
                <h4>Calibrate your Nodes</h4>
                <p>In order to maximize the Node's accuracy, they need a quick calibration before each session.</p>
                <div hidden={assignedNodesIndex >= nodeOptions[numberOfNodesIndex].nodes.length}>
                    <InfoBlock infoText="Place your Nodes on a flat surface, and be careful not to move them while calibration is taking place."/>
                </div>
                <div onClick={() => runCalibration()} className="new-workout-nodes-info-continue-button">Calibrate Nodes</div>
                
            </div>
            <div hidden={syncNodesStage !== -2 || numberOfNodesConnected < 3} className="new-workout-nodes-info-container">
                
                <h4>Calibrating Nodes...</h4>
                <p>Calibration taking place. This should only take a few seconds</p>
                <div className="new-workout-nodes-circle-container" hidden={displayCalibrationError && readyToRecalibrate}>
                    <div className={`new-workout-nodes-circle ${circleCSS}`}>
                        <ControlCamera className="new-workout-nodes-circle-icon" style={{ fontSize: 28 }}/>
                    </div>
                    <h3>Calibrating...</h3>
                </div>

                <div hidden={!displayCalibrationError}>
                    <AlertBlock alertText="Uh oh! It looks like there was an error calibrating a Node. Reset the red Nodes by turning the power switch off & back on and press Re-Calibrate below"/>
                    <div onClick={() => scanAgain()} hidden={!showScanButton} className="new-workout-nodes-info-skip-button">Re-scan for Nodes</div>
                </div>
                <div hidden={!(!displayCalibrationError && readyToRecalibrate)}>
                    <InfoBlock infoText="You're ready to re-calibrate your Nodes. Place your Nodes on a flat surface, and be careful not to move them while calibration is taking place."/>
                    <div className="new-workout-nodes-info-continue-button" onClick={() => runCalibration()}>Re-Calibrate Nodes</div>
                </div>
                
            </div>
            <div hidden={syncNodesStage !== 1 || numberOfNodesConnected < 3} className="new-workout-nodes-info-container">
                <div className="new-workout-nodes-info-text-container">
                    <h4>Let's Sync Your Nodes</h4>
                    <p>Now you can place your Nodes on your body. Use the provided straps to place them according to the image on the right.</p>
                </div>
                
                <div hidden={assignedNodesIndex >= nodeOptions[numberOfNodesIndex].nodes.length}>
                    <InfoBlock infoText="Push the nodes into the strap mount and turn counter-clockwise to lock them in place."/>
                </div>
                <div onClick={() => moveToNextStage()} className="new-workout-nodes-info-continue-button">Start Syncing Nodes</div>
                
            </div>
            <div hidden={syncNodesStage !== 2 || numberOfNodesConnected < 3} className="new-workout-nodes-info-container">
                <div className="new-workout-sync-nodes-header">
                    <p>Tap the Node on your</p>
                    <h4>{assignedNodesIndex < nodeOptions[numberOfNodesIndex].nodes.length ? `${getBodyLocationName()}` : "You're all set up!"}</h4>
                </div>
                <div hidden={assignedNodesIndex >= nodeOptions[numberOfNodesIndex].nodes.length}>
                    <InfoBlock infoText="To assign a Node, tap and hold the top of it with two fingers for about 3 seconds."/>
                </div>
                <div hidden={assignedNodesIndex < nodeOptions[numberOfNodesIndex].nodes.length} onClick={() => nextNodeSelected()} className="new-workout-nodes-info-continue-button new-workout-nodes-info-continue-button-final">Continue</div>
                <div className="new-workout-nodes-info-list-container">
                    { syncedNodes.map((item: NodeInstance, index: number) => (
                        <NodeListCell 
                            nodeManager={props.nodeManager} 
                            allowPing={false}
                            pingPressed={()=>pingNodePressed(item.getUUID())} 
                            key={index} 
                            pingState={false} 
                            uuid={item.getUUID()} 
                            title={item.getLocationString()}/>
                    ))}
                </div>
            </div>
            <div className="new-workout-nodes-body-container">
                <div className="new-workout-nodes-body-card">
                    <h4>Placement for {nodeOptions[numberOfNodesIndex].name}</h4>
                    <div className="new-workout-body-image-container">
                        <img className="new-workout-body-image" src={`${process.env.PUBLIC_URL}/assets/images/body/empty.png`}/>
                        { getNodeImages() }
                    </div>
                </div>
            </div>
		</div>
	)
}

export default NewWorkoutNodePage;

/*<img className="new-workout-body-image" src={`${process.env.PUBLIC_URL}/assets/images/body/${getNumberOfNodesOverlayName()}`}/>*/