import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import './Search.css';
import '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


function Search(props: any) {

	const [message, setMessage] = useState("");
	const [isFocused, setIsFocused] = useState(false);

	function setText(text: string) {
		setMessage(text);
		props.inputChanged(text);
	}
	function focusChanged(toFocus: boolean) {
		setIsFocused(toFocus);
	}
	return (
		<div className={`search-container  ${isFocused === true ? 'search-container-focused' : ''}`}>

			<div className="search-text-container">
				<SearchIcon className={message === "" ? "search-icon" : "search-icon-highlighted" }/>
				<IonInput value={message} 
                   	placeholder={props.placeholder}
                   	type="text"
                   	itemID={props.id === undefined ? "" : props.id}
                   	onFocus={(e) => focusChanged(true)}
					onBlur={(e) => focusChanged(false)}
                   	onIonChange={e => setText(e.detail.value!)}></IonInput>
			</div>
			<div>
			</div>
		</div>
	)
}


export default Search;