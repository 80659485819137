import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import firebase from '../../Firebase';
import './EditGoalModal.css';

import Selector from '../Base/Selector';
import FormTextInput from '../Base/FormTextInput';
import DateSelector from '../Base/DateSelector';
import ToggleSwitch from '../Base/ToggleSwitch';
import GoalSkillColumn from '../Goals/GoalSkillColumn';

import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';


function EditGoalModal(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    let workoutGoals = ['Get Fit', 'Loose Weight', 'Gain Muscle', 'Muscle Toning', 'Body Building', 'Power Lifting', 'Cardio Endurance'];
    let goalDescs = ['workout', 'loose', 'bulk to', 'tone to', 'body build to', 'power lift', 'improve endurance to'];
    let goalUnits = ['days/wk', '_weight_', '_weight_', '% BF', '_weight_', '_weight_', 'mins']
    let goalMetricDecs = ['Workouts', 'Body Weight', 'Body Weight', '% Body Fat', 'Body Weight', 'Max Lift', 'Working Mins']
    let workoutGoalTypes: ("custom" | "get-fit" | "loose-weight" | "gain-weight" | "muscle-toning" | "body-building" | "power-lifting" | "cardio-endurance")[] = ["get-fit", "loose-weight", "gain-weight", "muscle-toning", "body-building", "power-lifting", "cardio-endurance"];

    let initGoal: Goal_t = {
            type: "get-fit",
            targetMetric: 1,
            targetUnitsKG: true,
            targetTimestamp: 0,
            startingTimestamp: 0,
            isEditing: false,
            actions: []
        }

    const [goal, setGoal] = useState<Goal_t>(initGoal);
    const [totalDurationDays, setTotalDurationDays] = useState(112);
    const [initSelectedGoal, setInitSelectedGoal] = useState(0);
    const [useActions, setUseActions] = useState(false);
    const [defaultDate, setDefaultDate] = useState(0);
    const [initSet, setInitSet] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [usingActions, setUsingActions] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        // let dDate = Date.now() + (112 * 24 * 60 * 60 * 1000);
        // setDefaultDate(dDate);    // Set starting cal date to 3 mo's from today
        // setGoal({...goal, targetTimestamp: dDate, targetMetric: 5, type: 'get-fit', startingTimestamp: Date.now()});
        // if (props.goal !== undefined) {
        //     let goalTemp = props.goal;
        //     if (goalTemp.targetTimestamp <= 0) {
        //         goalTemp.targetTimestamp = defaultDate;
        //     }
        //     setGoal(goalTemp);
        // }
    }

    useEffect(() => {
        if (props.information !== undefined && props.information !== {}) {
            if (props.information.goal !== undefined) {
                
                var newGoalTemp: Goal_t = JSON.parse(JSON.stringify(props.information.goal));
                setDefaultDate(props.information.goal.targetTimestamp);

                let selectedGoalIndexTemp = workoutGoalTypes.indexOf(props.information.goal.type);

                if (selectedGoalIndexTemp >= 0) {
                    setInitSelectedGoal(selectedGoalIndexTemp);
                }

                if (props.information.goal.actions !== undefined && props.information.goal.actions.length > 0) {
                    setUseActions(true);
                } else {
                    setUseActions(false);
                    let actionLayers: GoalActions_t[] = getActionLayer(4,2,props.information.goal.targetTimestamp); 
                    newGoalTemp.actions = actionLayers; 
                }

                setGoal(newGoalTemp);

            } else {
                let startingTS = Date.now() + (112 * 24 * 60 * 60 * 1000);
                setDefaultDate(startingTS);
                let actionLayers: GoalActions_t[] = getActionLayer(4,2,startingTS);  

                setGoal({...goal, actions: actionLayers, targetUnitsKG: props.information.client !== undefined && props.information.client.isMetric !== undefined ? props.information.client.isMetric : true});

            }
            if (props.information.isEditing !== undefined) {
                setIsEditing(props.information.isEditing);
            }
        }
    }, [props.information])

    function inputChanged(toString: any, fromInput: string) {

        switch (fromInput) {
            case 'goal-type':
                let goalStringIndex = workoutGoals.indexOf(toString);
                let type = goalStringIndex >= 0 && goalStringIndex <= workoutGoalTypes.length ? workoutGoalTypes[goalStringIndex] : 'get-fit';
                

                var defaultTarget = 0;
                switch (type) {
                    case "custom":

                        break;
                    case "get-fit":
                        defaultTarget = 5;
                        break;
                    case "loose-weight":
                        defaultTarget = props.client !== undefined && props.client.weight !== undefined && props.client.weight !== 0 ? props.client.weight - 10 : 100;
                        break;
                    case "gain-weight":
                        defaultTarget = props.client !== undefined && props.client.weight !== undefined && props.client.weight !== 0 ? props.client.weight + 10 : 100;
                        break;
                    case "muscle-toning":
                        defaultTarget = 10;
                        break;
                    case "body-building":
                        defaultTarget = props.client !== undefined && props.client.weight !== undefined && props.client.weight !== 0 ? props.client.weight + 10 : 100;
                        break;
                    case "power-lifting":
                        defaultTarget = 500;
                        break;
                    case "cardio-endurance":
                        defaultTarget = 30;
                        break;
                    default: 
                        break;
                }

                setGoal({...goal, type: type, targetMetric: defaultTarget});

                break;

            case 'target-metric':
                setGoal({...goal, targetMetric: Number(toString)});
                break;

            default:
                break;
        }

    }

    function getActionLayer(layerA: number, layerB: number, startingTS: number) {

        let durationMs = startingTS - Date.now();
        let durationDays = durationMs / (1000 * 60 * 60 * 24);

        setTotalDurationDays(Math.round(durationDays));

        let layerADuration = Math.round(durationDays / layerA);
        let layerBDuration = Math.round(layerADuration / layerB);


        var actionLayersTemp: GoalActions_t[] = [];
        for (var i = 0; i < layerA; i++) {
            var subLayer: GoalActions_t[] = [];

            for (var j = 0; j < layerB; j++) {
                let newSubAction: GoalActions_t = {
                    index: j,
                    goalDesc: "",
                    durationDays: layerBDuration,
                    action: "",
                    metric: 1,
                    metricUnit: "",
                    timeframe: "per day"
                }
                subLayer.push(newSubAction);
            }

            let newAction: GoalActions_t = {
                index: i,
                goalDesc: "",
                durationDays: layerADuration,
                subActions: subLayer
            }

            actionLayersTemp.push(newAction);
        }

        // console.log("actionLayersTemp", actionLayersTemp);

        return actionLayersTemp;

        //setGoal({...goal, actions: actionLayersTemp});
    }

    function targetDateChanged(toTS: number) {
        setGoal({...goal, targetTimestamp: toTS});

        if (useActions === true && goal.actions !== undefined && goal.actions.length > 0) {
            // update timestamps
            let layerA = goal.actions.length;

            let durationMs = toTS - Date.now();
            let durationDays = durationMs / (1000 * 60 * 60 * 24);
            setTotalDurationDays(Math.round(durationDays));

            let layerADuration = Math.round(durationDays / layerA);
            
            var updatedActions: GoalActions_t[] = [];

            for (var i = 0; i < layerA; i++) {
                let thisAction: GoalActions_t = goal.actions[i];
                thisAction.durationDays = layerADuration;

                var updatedSubActions: GoalActions_t[] = [];
                if (thisAction.subActions !== undefined && thisAction.subActions.length > 0) {
                    let layerB = thisAction.subActions.length;
                    let layerBDuration = Math.round(layerADuration / layerB);

                    for (var j = 0; j < layerB; j++) {
                        let thisSubLayer = thisAction.subActions[j];
                        thisSubLayer.durationDays = layerBDuration;
                        updatedSubActions.push(thisSubLayer);
                    }
                }

                thisAction.subActions = updatedSubActions;
                updatedActions.push(thisAction);
            }

            setGoal({...goal, actions: updatedActions, targetTimestamp: toTS});
        }
    }

    function getGoalName() {
        let goalStringIndex = workoutGoalTypes.indexOf(goal.type);
        let goalString = goalStringIndex >= 0 && goalStringIndex <= workoutGoals.length ? workoutGoals[goalStringIndex] : 'Get Fit';
        return goalString;
    }

    function getGoalUnits() {

        let goalStringIndex = workoutGoalTypes.indexOf(goal.type);
        let goalUnitsString = goalUnits[goalStringIndex];
        return goalUnitsString === '_weight_' ? (goal.targetUnitsKG === true ? 'KGs' : 'LBS') : goalUnitsString; 
    }

    function getDateString() {
        let thisDate = new Date(goal.targetTimestamp);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
    }

    function savePressed() {
        
        setWorkflowState(1);
        

        let thisGoal: Goal_t = goal;
        let thisGoalObj: any = {
            type: thisGoal.type,
            target_metric: thisGoal.targetMetric,
            target_units_kg: thisGoal.targetUnitsKG,
            target_timestamp: thisGoal.targetTimestamp,
            starting_timestamp: isEditing ? thisGoal.startingTimestamp : Date.now(),
            actions: null
        };

        if (thisGoal.customType !== undefined) {
            thisGoalObj['custom_type'] = thisGoal.customType;
        }

        // console.log("thisGoal.actions: ", thisGoal.actions);
        if (thisGoal.actions !== undefined && useActions === true) {
            var actionsList: any[] = [];
            for (var j = 0; j < thisGoal.actions.length; j++) {
                let thisAction = thisGoal.actions[j];
                let thisActionObj: any = {
                    index: thisAction.index,
                    goal_desc: thisAction.goalDesc,
                    duration_days: thisAction.durationDays
                };
                if (thisAction.subActions !== undefined) {
                    var subActionsList: any[] = [];
                    for (var k = 0; k < thisAction.subActions.length; k++) {
                        let thisSubAction = thisAction.subActions[k];
                        let thisSubActionObj: any = {
                            index: thisSubAction.index,
                            goal_desc: thisSubAction.goalDesc,
                            duration_days: thisSubAction.durationDays,
                            action: thisSubAction.action === undefined ? "" : thisSubAction.action,
                            metric: thisSubAction.metric === undefined ? 1 : thisSubAction.metric,
                            metric_unit: thisSubAction.metricUnit === undefined ? "" : thisSubAction.metricUnit,
                            timeframe: thisSubAction.timeframe === undefined ? "" : thisSubAction.timeframe,
                        };
                        subActionsList.push(thisSubActionObj);
                    }
                    thisActionObj['sub_actions'] = subActionsList;
                }
                actionsList.push(thisAction);
            }

            thisGoalObj['actions'] = actionsList;
        }
        // console.log("thisGoalObj:", thisGoalObj);


        if (props.information.client === undefined) { return; }
        
        let clientID = props.information.client.id;
        
        let database = firebase.database();

        if (isEditing === true) {
            if (props.information.goal === undefined) { return; }
            let goalID = props.information.goal.id;

            let ref = database.ref(`goals/${clientID}/goals/${goalID}`); 
            ref.set(thisGoalObj).then((e: any) => {
                setTimeout(() => {
                    setWorkflowState(2);
                }, 600);
            }).catch((e: any) => {
                setWorkflowState(2);
            })
        } else {
            let ref = database.ref(`goals/${clientID}/goals`); 
            ref.push(thisGoalObj)
            setTimeout(() => {
                setWorkflowState(2);
            }, 1200);
        }
        
    }

    function updatedUsingActions(tostate: boolean) {
        setUseActions(tostate);
    }

    function goalActionUpdated(thisGoalAction: GoalActions_t, atIndex: number) {
        // console.log("Goal card | goalActionUpdated", atIndex, thisGoalAction);
        if (goal.actions === undefined) { return; }
        setGoal({...goal, actions: goal.actions.map((item: GoalActions_t, index: number) => {
            if (atIndex === index) {
                return thisGoalAction;
            } else {
                return item;
            }
        })})
    }

    function closeModal() {
        setGoal(initGoal);
        setDefaultDate(0);
        setInitSelectedGoal(0);
        setUseActions(false);
        props.hideModalPressed();
    }

	return (
        <div className="modal-background">
    		<div className="edit-goal-modal">
    			<div className="edit-goal-modal-header">
                    <h4>{isEditing ? "Update Goal Information" : "New Goal Information"}</h4>
                    <div onClick={() => closeModal()} className="confirm-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
                </div>
                <div hidden={workflowState !== 0} className="edit-goal-modal-content">
                    <div className="edit-goal-modal-content-header">
                        <h3>General Information</h3>
                    </div>
                    <div className="edit-goal-modal-content-body">
                        <div className="edit-goal-modal-content-body-input-container">
                            <p>Goal Type</p>
                            <Selector
                                placeholder="Select Type"
                                showTitle={false} 
                                options={workoutGoals}
                                forceSelected={initSelectedGoal}
                                inputChanged={(e: number) => inputChanged(e, 'goal-type')}/>
                        </div>
                        <div className="edit-goal-modal-content-body-input-container edit-goal-modal-content-body-input-container-metric">
                            <p>Target {goalMetricDecs[workoutGoalTypes.indexOf(goal.type)]}</p>
                            <FormTextInput 
                                value={goal.targetMetric}
                                placeholder="Target Metric" 
                                alert={false} 
                                alertText={""}
                                isSearch={true}
                                inputChanged={(e: string) => inputChanged(e, 'target-metric')}/>
                            <div className="goal-card-editing-body-upper-col-body-unit-container">
                                <p>{getGoalUnits()}</p>
                            </div>
                        </div>
                        <div className="edit-goal-modal-content-body-input-container">
                            <p>Goal Target Date</p>
                            <DateSelector 
                                dateSelected={(e: number) => targetDateChanged(e)}
                                isAbove={true}
                                defaultTimestamp={defaultDate}/>
                        </div>
                    </div>
                    <div className="edit-goal-modal-content-header">
                        <h3>Use Actions</h3>
                        <div className="edit-goal-modal-content-header-toggle-container">
                            <ToggleSwitch 
                                stateChanged={(e: boolean) => updatedUsingActions(e)}
                                forceState={useActions}/>
                        </div>
                    </div>
                    <CSSTransition in={useActions} timeout={200} classNames="menu" unmountOnExit appear>
                        <div>
                            <div className="goal-card-editing-body-actions-container">
                                <div className="goal-card-editing-body-actions-header-container">
                                    <p>SKILLS & ACTIONS FOR THIS GOAL</p>
                                </div>
                                
                               
                                <div className="edit-goal-modal-content-actions-list-columns">
                                    <div className="edit-goal-modal-content-actions-list-columns-inner">
                                        {
                                            goal.actions === undefined ? null : goal.actions.map((item: GoalActions_t, index: number) => (
                                                <GoalSkillColumn
                                                    goalAction={item} 
                                                    index={index}
                                                    client={props.client}
                                                    goalActionUpdated={(e: GoalActions_t) => goalActionUpdated(e, index)}/>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                <div hidden={workflowState !== 1} className="edit-goal-modal-content edit-goal-modal-content-loading">
                    <p>{isEditing ? 'Updating Goal...' : 'Creating Goal...'}</p>
                </div>
                <div hidden={workflowState !== 2} className="edit-goal-modal-content edit-goal-modal-content-complete">
                    <h4>Complete!</h4>
                    <p>{isEditing ? 'Updating' : 'Creating'} your Client's Goal is complete. They now have access to the goal's information from the Eigen Fit app.</p>
                </div>
                <div className="edit-goal-modal-button-container">
                    <div hidden={workflowState !== 0} onClick={() => savePressed()} className="edit-goal-modal-button">
                        <p>Save Changes</p>
                    </div>
                    <div hidden={workflowState !== 2} onClick={() => closeModal()} className="edit-goal-modal-button edit-goal-modal-button-finish">
                        <p>Finish</p>
                    </div>
                </div>
    		</div>
        </div>
	)
}

export default EditGoalModal;