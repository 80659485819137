import React, { useState } from 'react';
import './ExerciseList.css';
import '../DashboardPageCommon.css';
import '../../../components/PageElements/BlankPage.css';
import firebase from '../../../Firebase';
import Mixpanel from '../../../Mixpanel';
import axios from 'axios';

import Search from '../../../components/Base/Search';
import Selector from '../../../components/Base/Selector';
import FilterSingle from '../../../components/Base/FilterSingle';

import TextInput from '../../../components/Base/TextInput';
import StandardButton from '../../../components/Base/StandardButton';
import ExerciseCell from '../../../components/Workouts/ExerciseCell';


import Workout_t from '../../../Interfaces/Workout_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';
import MotionInfo_t from '../../../Interfaces/MotionInfo_t';
import Muscles_t from '../../../Interfaces/Muscles_t';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';

function ExerciseList(props: any) {

	let muscle_groups = ["all","Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    const [initSet, setInitSet] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const [searchStringError, setSearchStringError] = useState(false);
    const [searchState, setSearchState] = useState(0); // 0 = no search, 1 = loading, 2 = display results, 3 = no results found
    const [searchString, setSearchString] = useState('');
    const [searchGroup, setSearchGroup] = useState(0);
    const [searchResults, setSearchResults] = useState<Exercise_t[]>([]);
    const [showingExercises, setShowingExercises] = useState<Exercise_t[]>([]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setSearchState(1);
        searchExercises("abs");
    }

    function searchExercises(searchWith?: string, withGroup?: number) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let sendData = {
            text: searchWith === undefined || searchWith === null ? searchString : searchWith,
            muscle_group: withGroup === undefined || withGroup === null ? searchGroup : withGroup,
            user_id: ""//trainerUID
        };
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/searchExercises`,
            sendData,
            { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response.data);
            setSearchState(2);
            let responseData = response.data;
            let responseExerciseArray: any[] = responseData.resp;
            if (responseExerciseArray.length === 0) {
                // No exercises found for search. Present no results found page (state 3)
                //setSearchState(3);
            } else {
                var tempExerciseArray: Exercise_t[] = [];
                for (var i = 0; i < responseExerciseArray.length; i++) {
                    let thisExerciseObj = responseExerciseArray[i];
                    let thisExerciseMuscles: Muscles_t[] = [];

                    if (thisExerciseObj.muscles !== null && thisExerciseObj.muscles !== undefined) {

                        for (var j = 0; j < thisExerciseObj.muscles.length; j++) {
                            let thisMuscle = thisExerciseObj.muscles[j];
                            let newMuscle: Muscles_t = {
                                group: Number(thisMuscle.group),
                                muscle: Number(thisMuscle.muscle)
                            }
                            thisExerciseMuscles.push(newMuscle);
                        }
                    }

                    let newExercise: Exercise_t = {
                        title: thisExerciseObj.title,
                        id: thisExerciseObj.id,
                        createdBy: {name: thisExerciseObj.created_by.name, id: thisExerciseObj.created_by.id},
                        muscles: thisExerciseMuscles,
                        type: thisExerciseObj.type,
                        equipment: thisExerciseObj.equipment,
                        tags: thisExerciseObj.tags,
                        setup: thisExerciseObj.setup,
                        steps: thisExerciseObj.steps,
                        videoLink: thisExerciseObj.video_link
                    }
                    tempExerciseArray.push(newExercise);
                }


                var tempExerciseArray_adj = [];
                for (var i = 0; i < tempExerciseArray.length; i++) {
                    let i_adj = tempExerciseArray.length - 1 - i;
                    tempExerciseArray_adj.push(tempExerciseArray[i_adj]);
                }

                setSearchResults(tempExerciseArray_adj);
                setShowingExercises(tempExerciseArray_adj);
                setSearchString("");
            }
        })
        .catch(e => {
            // console.log(e);
            setSearchState(3);
        })
    }

	function moreInfoSelected(workout: Workout_t) {
		props.moreInfoSelected(workout);
	}

	function addNewExercisePressed() {
        Mixpanel.track("Add new exercise from exercise library page");
		props.addNewExercisePressed();
	}

	function muscleGroupSelected(muscleGroup: string) {
		let indexOfGroup = muscle_groups.indexOf(muscleGroup);
        if (indexOfGroup > 0) {
            setSearchGroup(indexOfGroup - 1);
            Mixpanel.track("Search muscle group in exercise library", {muscle_group: indexOfGroup});
            setSearchState(1);
            searchExercises(muscle_groups[indexOfGroup], indexOfGroup - 1);
        }
	}

	function textInputChanged(toString: string) {
  		var temp = [];
		for (var i = 0; i < searchResults.length; i++) {
			let searchString_a = toString.toUpperCase();
			let nameString = searchResults[i].title.toUpperCase();
			let positiveSearch_name = nameString.includes(searchString_a);

			if (positiveSearch_name === true) {
				temp.push(searchResults[i]);
			}
		}

		setShowingExercises(temp);
	}

	return (
		<div className="page-full-height-width page-full-height-width-exercise-list">
			<div hidden={searchState !== 2} className="client-container">
                <div className="client-list-upper-content">
                    <div className="exercise-list-upper-search-container">
                        <Search 
                            placeholder="Search by name or muscle..." 
                            inputChanged={(e: string) => textInputChanged(e)}/>
                    </div>
                    <FilterSingle 
                        placeholder="Muscle Group" 
                        alert={false} 
                        options={muscle_groups}
                        showTitle={false} 
                        id="exercise-library-list"
                        inputChanged={(e: string) => muscleGroupSelected(e)}/>
                    <div hidden={props.showTitle !== undefined && props.showTitle === true} className="client-list-upper-content-showing-number-container">
                        <p>Showing {showingExercises.length} of {searchResults.length}</p>
                    </div>
                    <div hidden={props.showTitle !== undefined && props.showTitle === true} className="client-list-upper-content-add-button-container exercise-list-upper-content-add-button-container">
                        <div hidden={props.hideAddNewClient !== undefined && props.hideAddNewClient === true} onClick={() => addNewExercisePressed()} className="client-list-upper-content-add-button">
                            <Add className="client-list-upper-content-add-button-icon"/>
                            <p>New Exercise</p>
                        </div>
                    </div>
                </div>
                <div className="client-list-list-headding exercise-list-list-headding">
                    <div className="client-list-list-headding-row client-list-list-headding-row-first exercise-list-headding-row-first">
                        <p>NAME</p>
                    </div>
                    <div className="client-list-list-headding-row exercise-list-headding-row">
                        <p>EQUIPMENT</p>
                    </div>
                    <div className="client-list-list-headding-row exercise-list-headding-row">
                        <p></p>
                    </div>
                    <div className="client-list-list-headding-row exercise-list-headding-row">
                        <p>LIBRARY</p>
                    </div>
                </div>
				<div className="cell-container">
					{showingExercises.map((item: Exercise_t, index: number) => (
						<ExerciseCell exercise={item}/>
					))}
				</div>
			</div>
            
			<div hidden={searchState !== 0} className="client-container">
				<div className="blank-page-container">
					<div className="blank-page-content">
		                <h4>No exercises added</h4>
		                <p>It looks like you haven't created any exercises yet.<br/>Not to worry, just press 'Add new exercise' to create one!</p>
		            </div>
				</div>
			</div>
			<div hidden={searchState !== 1} className="client-container">
				<div className="exercise-list-blank-page-container">
					<div className="exercise-list-blank-page-content">
		                <p>Loading exercises...</p>
		            </div>
				</div>
			</div>
		</div>
	)

}

/*
                <div className="exercise-list-upper-content">
                    <div className="exercise-list-upper-search-container">
                        <Search 
                            placeholder="Search by name or muscle..." 
                            inputChanged={(e: string) => textInputChanged(e)}/>
                    </div>
                    <FilterSingle 
                        placeholder="Muscle Group" 
                        alert={false} 
                        options={muscle_groups}
                        showTitle={false} 
                        id="exercise-library-list"
                        inputChanged={(e: string) => muscleGroupSelected(e)}/>
                    
                    <div hidden={props.showTitle !== undefined && props.showTitle === true} className="exercise-list-upper-content-add-button-container">
                        <div hidden={props.hideAddNewClient !== undefined && props.hideAddNewClient === true} onClick={() => addNewExercisePressed()} className="exercise-list-upper-content-add-button">
                            <p>New exercise</p>
                            <Add className="exercise-list-upper-content-add-button-icon"/>
                        </div>
                    </div>
*/

export default ExerciseList;

