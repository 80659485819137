import React, { useState } from 'react';
import { IonInput, IonTextarea } from '@ionic/react';
import { FileDrop } from 'react-file-drop';
import firebase from '../../../Firebase';
import './MyStudioPage.css';

import ColorScheme_t from '../../../Interfaces/CoachingProfile/ColorScheme_t';
import Location_t from '../../../Interfaces/Locations/Location_t';
import WeekDayTimes_t from '../../../Interfaces/Locations/WeekDayTimes_t';
import Amenity_t from '../../../Interfaces/Locations/Amenity_t';
import Trainer_t from '../../../Interfaces/Trainer_t';

import CheckBox from '../../../components/Base/CheckBox';


import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Add from '@material-ui/icons/Add';
import Image from '@material-ui/icons/Image';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';


function MyStudioPage(props: any) {

    const [colorSchemeOptions, setColorSchemeOptions] = useState<ColorScheme_t[]>([
        {
            title: "Classic",
            background: '#1F1F21',
            primary: '#5B62FF',
            heading_text: '#FFFFFF',
            body_text: '#ffffff',
            button_text: '#FFFFFF'
        },
        {
            title: "Black n' Yellow",
            background: '#000000',
            primary: '#FFEC00',
            heading_text: '#FFFFFF',
            body_text: '#ffffff',
            button_text: '#28292C'
        },
        {
            title: "Muted Pink",
            background: '#DABDB6',
            primary: '#ffffff',
            heading_text: '#FFFFFF',
            body_text: '#723A37',
            button_text: '#723A37'
        },
        {
            title: "Dark Luxe",
            background: '#000000',
            primary: '#E8BD70',
            heading_text: '#EDD185',
            body_text: '#97969B',
            button_text: '#000000'
        },
        {
            title: "Gentle Orange",
            background: '#BDBDBD',
            primary: '#FFC121',
            heading_text: '#FFFFFF',
            body_text: '#28292C',
            button_text: '#28292C'
        },
        {
            title: "Bold Red",
            background: '#F5F6F7',
            primary: '#DD2325',
            heading_text: '#484A52',
            body_text: '#484A52',
            button_text: '#ffffff'
        },
        {
            title: "Sunset",
            background: '#242424',
            primary: '#F7ED6C',
            heading_text: '#FFFFFF',
            body_text: '#FFFFFF',
            button_text: '#242424'
        },
        {
            title: "Bright Amethyst",
            background: '#EEF0F0',
            primary: '#5E45B4',
            heading_text: '#28292C',
            body_text: '#28292C',
            button_text: '#FFFFFF'
        },
        {
            title: "Sky Blue",
            background: '#F0F3FF',
            primary: '#ADC6E4',
            heading_text: '#2D2F35',
            body_text: '#28292C',
            button_text: '#ffffff'
        },
        {
            title: "Black and White",
            background: '#000000',
            primary: '#ffffff',
            heading_text: '#ffffff',
            body_text: '#ffffff',
            button_text: '#000000'
        },
        {
            title: "Lavinder",
            background: '#A580D4',
            primary: '#ffffff',
            heading_text: '#ffffff',
            body_text: '#EEE7F5',
            button_text: '#363A4C'
        },
        {
            title: "Electric",
            background: '#A6A8B2',
            primary: '#C1F50E',
            heading_text: '#000000',
            body_text: '#000000',
            button_text: '#000000'
        }])

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingHeaderImage, setIsLoadingHeaderImage] = useState(false);
    const [isEditted, setIsEditted] = useState(false);
    const [locations, setLocations] = useState<Location_t[]>([]);
    const [selectedLocationID, setSelectedLocationID] = useState<string>("");
    const [gymName, setGymName] = useState("");
    const [gymPhone, setGymPhone] = useState("");

    const [profileImageURL, setProfileImageURL] = useState("");
    const [uploadWorkflowState, setUploadWorkflowState] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);


    const [colorScheme, setColorScheme] = useState<ColorScheme_t>(colorSchemeOptions[3]);
    const [forceThemeToTrainers, setForceThemeToTrainers] = useState(true);
    const [infoPageIndex, setInfoPageIndex] = useState(0);

    const [edittingName, setEdittingName] = useState(false);
    const [edittingPhone, setEdittingPhone] = useState(false);
    const [edittingLocation, setEdittingLocation] = useState(false);

    const [studioID, setStudioID] = useState("");

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

        setIsLoading(true);

        // 1. Get user's profile
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                if (data.studio_id !== undefined && data.studio_id !== "") {
                    getStudioProfile(data.studio_id);
                    getProfileImage(data.studio_id);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        });

        
    }

    function getStudioProfile(forStudioID: string) {

        setStudioID(forStudioID);

        let database = firebase.database();
        let ref = database.ref(`training_studios/${forStudioID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setGymName(data.name);
                if (data.contact_phone_number !== undefined) {
                    setGymPhone(data.contact_phone_number);
                }
                // if (data.locations !== undefined) {
                //     parseLocations(data.locations);
                // }
                if (data.theme !== undefined) {
                    setColorScheme(data.theme);
                } else {
                    setColorScheme(colorSchemeOptions[0]);
                }

                if (data.force_theme_to_trainers !== undefined) {
                    setForceThemeToTrainers(data.force_theme_to_trainers);
                } 

                if (data.main_location_id !== undefined) {
                    setSelectedLocationID(data.main_location_id);
                } 

                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });

        let ref_studio = database.ref(`training_studios/${forStudioID}`);
        ref_studio.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                parseLocations(data.locations, data.trainers);
            } 
        });
    }

    function getProfileImage(forStudioID: string) {
        // 3. Load header image
        setIsLoadingHeaderImage(true);

        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref(`images/studio_profile/${forStudioID}/profile_image.png`).getDownloadURL()
       .then(fireBaseUrl => {
         setProfileImageURL(fireBaseUrl);
         setTimeout(() => {
             setIsLoadingHeaderImage(false);
         }, 800);
         
       })
       .catch(error => {
           setProfileImageURL("");
           setIsLoadingHeaderImage(false);
           //// console.log('No image found');
           //// console.log(error);
       })
    }

    function parseLocations(locationList: any, trainerList?: any[]) {
        let locationKeys = Object.keys(locationList);

        var locationsTemp: Location_t[] = [];

        for (var i = 0; i < locationKeys.length; i++) {
            let thisLocationKey = locationKeys[i];
            let thisLocationObj = locationList[thisLocationKey];

            var thisTainerList: Trainer_t[] = [];
            if (trainerList !== undefined) {
                for (var j = 0; j < trainerList.length; j++) {
                    let thisTrainerObj: any = trainerList[j];
                    if (thisTrainerObj.location_id !== undefined && thisTrainerObj.location_id === thisLocationKey) {
                        // This trainer exists at this location
                        thisTainerList.push({
                            id: thisTrainerObj.id !== undefined ? thisTrainerObj.id : "",
                            email: thisTrainerObj.email !== undefined ? thisTrainerObj.email : "",
                            first_name: thisTrainerObj.first_name !== undefined ? thisTrainerObj.first_name : "",
                            last_name: thisTrainerObj.last_name !== undefined ? thisTrainerObj.last_name : "",
                            full_name: thisTrainerObj.full_name !== undefined ? thisTrainerObj.full_name : "",
                            isVerified: true,
                            permissions: thisTrainerObj.permission !== undefined ? thisTrainerObj.permission : 2,
                            location_id: thisTrainerObj.location_id
                        })
                    }
                }
            }

            let newLocation: Location_t = {
                id: thisLocationKey,
                title: thisLocationObj.name !== undefined ? thisLocationObj.name : "My Location",
                owner_id: thisLocationObj.owner_id,
                location: thisLocationObj.location !== undefined ? thisLocationObj.location : {
                    address_string: "",
                    unit_string: "",
                    google_place_id: "",
                    coordinates: {lat: 0.0, lng: 0.0},
                    isHQ: false
                },
                times: thisLocationObj.times !== undefined ? thisLocationObj.times : {
                    monday: {open: 420, close: 1080, isOpen: true},
                    tuesday: {open: 420, close: 1080, isOpen: true},
                    wednesday: {open: 420, close: 1080, isOpen: true},
                    thursday: {open: 420, close: 1080, isOpen: true},
                    friday: {open: 420, close: 1080, isOpen: true},
                    saturday: {open: 420, close: 1080, isOpen: true},
                    sunday: {open: 420, close: 1080, isOpen: true}
                },
                trainers: thisTainerList
            }

            locationsTemp.push(newLocation);
        }

        setLocations(locationsTemp);

    }

    function getColorForType(theme: ColorScheme_t, type: 'background' | 'primary' | 'heading_text' | 'body_text' | 'button_text', rgb?: boolean, a?: number) {
        return rgb === undefined || rgb === false ? theme[type] : hexToRgb(theme[type], a === undefined ? 1 : a);
    }

    function hexToRgb(hex: string, a: number) {
        
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let rgbString = result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : `rgba(0, 0, 0, ${a})`

        // console.log("RESULT:",rgbString, result, hex, a);
        

        return rgbString;
    }

    function beginEdittingText(type: 'name' | 'phone') {
        switch (type) {
            case 'name':
                setEdittingName(true);
                setIsEditted(true);
                break;
            case 'phone' :
                setEdittingPhone(true);
                setIsEditted(true);
                break;
            default:
                break;
        }
    }

    function updateSelectedLocation(loc: Location_t) {
        setIsEditted(true);
        setEdittingLocation(false);
        setSelectedLocationID(loc.id);
    }

    function saveProfileChanges() {
        
        if (studioID === undefined || studioID === "") { return; }

        setIsLoading(true);

        let database = firebase.database();
        if (edittingName && gymName.split('').length > 3) {
            database.ref(`training_studios/${studioID}/name`).set(gymName);
        }

        if (edittingPhone && gymPhone.split('').length >= 7) {
            database.ref(`training_studios/${studioID}/contact_phone_number`).set(gymPhone);
        }

        database.ref(`training_studios/${studioID}/theme`).set(colorScheme);
        database.ref(`training_studios/${studioID}/force_theme_to_trainers`).set(forceThemeToTrainers);

        database.ref(`training_studios/${studioID}/main_location_id`).set(selectedLocationID);

        setTimeout(() => {
            //setIsLoading(false);
            setIsEditted(false);
            setEdittingPhone(false);
            setEdittingName(false);
            setEdittingLocation(false);
            getStudioProfile(studioID);
        }, 1200);
    }

    function uploadImage(files: FileList | null, event: any) {
        if (files === null) { return; }
        //if (props.information === undefined || props.information.path === undefined) { return; }
        // console.log('onDrop!', files, event);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setUploadWorkflowState(1);

        let storage = firebase.storage();
        let uploadTask = storage.ref(`images/studio_profile/${studioID}/profile_image.png`).put(files[0]);

        uploadTask.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        }, (error) => {
            // error
            setUploadWorkflowState(0);
        }, () => {
            // completed
            uploadTask.snapshot.ref.getDownloadURL()
            .then(fireBaseUrl => {
                setUploadWorkflowState(0);
                setProfileImageURL(fireBaseUrl);
            })
            .catch(error => {
                //// console.log('No image found');
                //// console.log(error);
                setUploadWorkflowState(0);
            })
        });
    }

    function updateTheme(toTheme: ColorScheme_t) {
        setIsEditted(true);
        setColorScheme(toTheme);
    }


    function updateForceThemeToTrainers(toState: boolean) {
        setForceThemeToTrainers(toState);
        setIsEditted(true);
    }

    function getSelectedLocation() {
        if (locations.length > 0) {
            var loc: Location_t | null = null;
            for (var i = 0; i < locations.length; i++) {
                if (locations[i].id === selectedLocationID) {
                    loc = locations[i];
                }
            }
            return loc;
        } else {
            return null;
        }
    }

    function getSelectedLocationName() {
        let loc: null | Location_t = getSelectedLocation();
        if (loc === null) { return locations.length === 0 ? '' : locations[0].title; }

        return loc.title;
    }

    function getSelectedLocationAddress() {
        let loc: null | Location_t = getSelectedLocation();
        if (loc === null) { return locations.length === 0 ? 'Unspecified Address' : locations[0].location.address_string; }

        return loc.location.address_string;
    }


	return (
		<div className="my-studio-page">
            <div hidden={!isLoading} className="my-coaching-profile-curtain">
                <div className="workout-plan-content-loading-spinner"/>
                <p>{isEditted ? "Saving" : "Loading"} your Studio profile...</p>
            </div>
			<div className="trainers-page-content my-studio-page-content">
                <div className="page-title-header-container">
                    <h1>My Studio</h1>
                </div>        
                <div className="my-studio-page-content-section my-studio-page-content-section-studio-card">
                    <div className="my-studio-page-content-section-header">
                        <h4>Studio Card</h4>
                        <div className="my-studio-page-content-section-header-button">
                            <p>Edit Studio Card</p>
                            <ArrowForward className="my-studio-page-content-section-header-button-icon"/>
                        </div>
                    </div>
                    <div style={{background: getColorForType(colorScheme, 'background'), borderColor: getColorForType(colorScheme, 'primary'),}} className="my-studio-card">
                        <div className="my-studio-card-text-container">
                            <div className="my-studio-card-text-header-container">
                                <h1 style={{color: getColorForType(colorScheme, 'heading_text')}}>{gymName}</h1>
                                <h4 style={{color: getColorForType(colorScheme, 'primary')}}>{locations.length === 0 ? "Unspecified Address" : getSelectedLocationAddress()}</h4>
                            </div>
                            <div className="my-studio-card-text-info-container">
                                <h4 style={{color: getColorForType(colorScheme, 'body_text')}}>{locations.length <= 1 ? "One Location" : `And ${locations.length} other Locations`}</h4>
                                <h4 style={{color: getColorForType(colorScheme, 'body_text')}}>124 Coaches</h4>
                            </div>
                        </div>
                        <div className="my-studio-card-img-container">
                            <img className="" src={profileImageURL}/>
                        </div>
                    </div>
                    <div className="my-studio-page-content-section-studio-card-info-text">
                        <p>This is what Athletes see when they find your Studio in the <a href="https://apps.apple.com/ca/app/eigen-fit/id1498333582">Eigen Fitness app</a>.</p>
                    </div>
                </div>
                <div className="my-studio-page-content-section my-studio-page-content-section-locations">
                    <div className="my-studio-page-content-section-header">
                        <h4>Locations</h4>
                    </div>
                    <div className="my-studio-page-content-section-locations-list">
                        <div className="my-studio-page-content-section-locations-list-header">
                            <div className="my-studio-page-content-section-locations-list-header-col my-studio-page-content-section-locations-list-header-col-name">
                                <p>NAME</p>
                            </div>
                            <div className="my-studio-page-content-section-locations-list-header-col my-studio-page-content-section-locations-list-header-col-address">
                                <p>ADDRESS</p>
                            </div>
                            <div className="my-studio-page-content-section-locations-list-header-col my-studio-page-content-section-locations-list-header-col-coaches">
                                <p>COACHES</p>
                            </div>
                        </div>
                        <div hidden={locations.length === 0} className="my-studio-page-content-section-locations-list-content">
                            {
                                locations.map((item: Location_t, index: number) => (
                                    <div className="my-studio-page-content-section-locations-list-row">
                                        <div className="my-studio-page-content-section-locations-list-row-col my-studio-page-content-section-locations-list-header-col-name">
                                            <p>{item.title}</p>
                                        </div>
                                        <div className="my-studio-page-content-section-locations-list-row-col my-studio-page-content-section-locations-list-header-col-address">
                                            <p>{item.location.address_string}</p>
                                        </div>
                                        <div className="my-studio-page-content-section-locations-list-row-col my-studio-page-content-section-locations-list-header-col-coaches">
                                            <p>{item.trainers === undefined || item.trainers.length === 0 ? 'No Coaches' : `${item.trainers.length} Coach${item.trainers.length === 1 ? '' : 'es'}`}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            <div onClick={() => props.addLocationPressed()} className="my-studio-page-content-section-locations-list-button">
                                <Add className="my-studio-page-content-section-locations-list-button-icon"/>
                                <p>Add another Location</p>
                            </div>
                        </div>
                        <div hidden={locations.length !== 0} className="my-studio-page-content-section-locations-list-content my-studio-page-content-section-locations-list-content-empty">
                            <div className="my-studio-page-content-section-locations-list-content-empty-img-container">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/no-locations.svg`}/>
                                
                            </div>
                            <div className="my-studio-page-content-section-locations-list-content-empty-text-container">
                                <h4>No Locations Found</h4>
                                <p>Add Studio Locations to organize your Coaching staff and Athlete Roster.</p>
                                <div onClick={() => props.addLocationPressed()} className="my-studio-page-content-section-locations-list-content-empty-button">
                                    <Add className="my-studio-page-content-section-locations-list-content-empty-button-icon"/>
                                    <p>Add a Location</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={() => saveProfileChanges()} hidden={!isEditted} className="my-studio-page-info-panel-container-section-header-save-button">
                <p>Save Changes</p>
            </div>
            <div className="trainers-page-info-panel-container my-studio-page-info-panel-container">
                
                <div hidden={false} className="trainers-page-info-panel-container-content">
                    <div className="trainers-page-info-panel-container-content-section trainers-page-info-panel-container-content-section-line">
                        <div className="trainers-page-info-panel-container-content-section-header my-studio-page-info-panel-container-section-header">
                            <h4>General Studio Card Settings</h4>
                        </div>
                        <div className="trainers-page-info-panel-container-content-section-body">
                            <div className="my-studio-page-info-panel-input-row">
                                <div className="my-studio-page-info-panel-input-row-header">
                                    <h4>Studio Name</h4>
                                </div>
                                <div onClick={() => beginEdittingText('name')} hidden={edittingName} className="my-studio-page-info-panel-input-row-text">
                                    <h4>{gymName}</h4>
                                </div>
                                <div hidden={!edittingName} className="my-studio-page-info-panel-input-row-text-input">
                                    <div className="my-profile-background-cell-row-input-container-inner my-profile-information-section-card-header-text-editable-inner">
                                        <IonTextarea value={gymName} 
                                            class=""
                                            placeholder={`Enter a Studio Name...`}
                                            inputmode="text"
                                            clearOnEdit={false}
                                            autoGrow={true}
                                            rows={1}
                                            maxlength={500}
                                            onKeyPress={e => null}
                                            onIonChange={e => setGymName(e.detail.value!)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="my-studio-page-info-panel-input-row">
                                <div className="my-studio-page-info-panel-input-row-header">
                                    <h4>Main Location</h4>
                                </div>
                                <div onClick={() => props.addLocationPressed()} hidden={locations.length > 0} className="my-studio-page-info-panel-input-row-location-button">
                                    <Add className="my-studio-page-info-panel-input-row-location-button-icon"/>
                                    <h4>Add a Location</h4>
                                </div>
                                <div hidden={locations.length !== 1} className="my-studio-page-info-panel-input-row-text">
                                    <h4>{locations.length === 0 ? '' : locations[0].title}</h4>
                                </div>
                                <div hidden={locations.length <= 1} className="my-studio-page-info-panel-input-row-text my-studio-page-info-panel-input-row-text-dropdown">
                                    <div onClick={() => setEdittingLocation(!edittingLocation)} className="my-studio-page-info-panel-input-row-text-dropdown-selection">
                                        <h4>{getSelectedLocationName()}</h4>
                                    </div>
                                    <div onClick={() => setEdittingLocation(!edittingLocation)} hidden={edittingLocation} className="my-studio-page-info-panel-input-row-text-dropdown-icon-container">
                                        <ExpandMore className="my-studio-page-info-panel-input-row-text-dropdown-icon"/>
                                    </div>
                                    <div onClick={() => setEdittingLocation(!edittingLocation)} hidden={!edittingLocation} className="my-studio-page-info-panel-input-row-text-dropdown-icon-container">
                                        <ExpandLess className="my-studio-page-info-panel-input-row-text-dropdown-icon"/>
                                    </div>
                                    <div hidden={!edittingLocation} className="my-studio-page-info-panel-input-row-text-dropdown-menu">
                                        {
                                            locations.map((item: Location_t, index: number) => (
                                                <div onClick={() => updateSelectedLocation(item)} className="my-studio-page-info-panel-input-row-text-dropdown-menu-row">
                                                    <h4>{item.title}</h4>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="my-studio-page-info-panel-input-row">
                                <div className="my-studio-page-info-panel-input-row-header">
                                    <h4>Phone Number</h4>
                                </div>
                                <div onClick={() => beginEdittingText('phone')} hidden={edittingPhone || gymPhone !== ""}className="my-studio-page-info-panel-input-row-location-button">
                                    <Add className="my-studio-page-info-panel-input-row-location-button-icon"/>
                                    <h4>Add contact number</h4>
                                </div>
                                <div onClick={() => beginEdittingText('phone')} hidden={edittingPhone || gymPhone === ""} className="my-studio-page-info-panel-input-row-text">
                                    <h4>{gymPhone}</h4>
                                </div>
                                <div hidden={!edittingPhone} className="my-studio-page-info-panel-input-row-text-input">
                                    <div className="my-profile-background-cell-row-input-container-inner my-profile-information-section-card-header-text-editable-inner">
                                        <IonTextarea value={gymPhone} 
                                            class=""
                                            placeholder={`Enter a contact number...`}
                                            inputmode="text"
                                            clearOnEdit={false}
                                            autoGrow={true}
                                            rows={1}
                                            maxlength={500}
                                            onKeyPress={e => null}
                                            onIonChange={e => setGymPhone(e.detail.value!)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="my-coaching-profile-drop-area">
                                    <FileDrop
                                        onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                        onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                        onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                        onDragOver={(event) => console.log('onDragOver', event)}
                                        onDragLeave={(event) => console.log('onDragLeave', event)}
                                        onDrop={(files, event) => uploadImage(files, event)}>
                                            <div hidden={profileImageURL !== ""} className="my-coaching-profile-drop-area-inner-container">
                                                <div className="my-coaching-profile-drop-area-icon-container">
                                                    <Image className="my-coaching-profile-drop-area-icon"/>
                                                </div>
                                                <h4>Drop an image here to upload</h4>
                                                <p>Files less than 10MB are recommended, in .png, .jpg, or .gif formats.</p>
                                            </div>
                                            <div hidden={profileImageURL === ""} className="my-coaching-profile-drop-area-inner-container my-coaching-profile-drop-area-inner-container-has-image">
                                                <div className="my-coaching-profile-drop-area-image-container">
                                                    <img className="" src={profileImageURL}/>
                                                </div>
                                                <div className="my-coaching-profile-drop-area-text-container">
                                                    <h4>Studio profile image</h4>
                                                    <div onClick={() => setProfileImageURL("")} className="my-profile-information-section-card-body-image-content-remove-button">
                                                        <Close className="my-profile-information-section-card-body-image-content-remove-button-icon"/>
                                                        <p>Remove image</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div hidden={uploadWorkflowState !== 1} className="upload-image-modal-loading-bar-container">
                                                <div className="upload-image-modal-loading-bar">
                                                    <div className="upload-image-modal-loading-bar-inner" style={{width: `${uploadProgress}%`}}/>
                                                </div>
                                                <p>{Math.round(uploadProgress)}%</p>
                                            </div>
                                            
                                    </FileDrop>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="trainers-page-info-panel-container-content-section trainers-page-info-panel-container-content-section-line">
                        <div className="trainers-page-info-panel-container-content-section-header my-studio-page-info-panel-container-section-header">
                            <h4>Studio Color Theme</h4>
                            <p>Choose a theme to match your studio's branding. This will appear to Athletes on Coaching Profiles and on your Studio Card.</p>
                        </div>
                        <div className="my-studio-page-info-panel-container-option">
                            <div className="my-studio-page-info-panel-container-option-checkbox-container">
                                <CheckBox 
                                    forceState={forceThemeToTrainers}
                                    stateChanged={(s: boolean) => updateForceThemeToTrainers(s)}/>
                            </div>
                            <div onClick={() => setForceThemeToTrainers(!forceThemeToTrainers)} className="my-studio-page-info-panel-container-option-text-container">
                                <h4>Apply to Coaching Profiles</h4>
                                <p>Keep your Studio’s branding consistent by applying the selected color theme to all Coaching Profiles.</p>
                            </div>
                        </div>
                        <div className="my-coaching-profile-page-content-section-content my-coaching-profile-page-content-section-content-themes">
                            {
                                colorSchemeOptions.map((item: ColorScheme_t, index: number) => (
                                    <div onClick={() => updateTheme(item)} className="my-coaching-profile-page-theme-card">
                                        <div className="my-coaching-profile-page-theme-card-theme" style={{background: getColorForType(item, 'background')}}>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-header-primary" style={{background: getColorForType(item, 'primary')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-header" style={{background: getColorForType(item, 'heading_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-body my-coaching-profile-page-theme-card-theme-body-top" style={{background: getColorForType(item, 'body_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-body" style={{background: getColorForType(item, 'body_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-body my-coaching-profile-page-theme-card-theme-body-bottom" style={{background: getColorForType(item, 'body_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-button" style={{background: getColorForType(item, 'primary')}}>
                                                <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-button-text" style={{background: getColorForType(item, 'button_text')}}></div>
                                            </div>


                                        </div>
                                        <div className="my-coaching-profile-page-theme-card-title">
                                            <CheckBox 
                                                forceState={item.title === colorScheme.title}
                                                stateChanged={(s: boolean) => updateTheme(item)}/>
                                            <h4>{item.title}</h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default MyStudioPage;