import React, { useState, useEffect } from 'react';
import { IonInput, IonTextarea } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import './GoalSkillColumnActionCard.css';

import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

function GoalSkillColumnActionCard(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    let actions = ["lift","workout","run","walk","swim","drink","eat","take","sleep","custom..."];
    let metricUnits = ["KGs","LBS","times","mins","hours","meters","km","ft","yards","miles","kCal","g carbs","g protein","g fat","grams","liters","mL","oz","quart","bottles","custom..."];
    let relevantMetricUnits = [
        ["KGs","LBS","bags"],
        ["times","mins","hours","pullups","pushups","situps","squats","deadlifts","benchpresses"],
        ["times","mins","hours","meters","km","ft","yards","miles"],
        ["times","mins","hours","meters","km","ft","yards","miles"],
        ["times","mins","hours","meters","km","ft","yards","miles"],
        ["times","kCal","g carbs","g protein","g fat","grams","liters","mL","oz","quart","shakes","bottles"],
        ["times","kCal","g carbs","g protein","g fat","grams","oz","quart","shakes","bottles"],
        ["suppliments","shakes","massages","saunas","ice baths"],
        ["hours","mins","times"],
        metricUnits
    ]
    
    let timeframes = ["per day","every other day","per week","per hour","per minute","per night"];

    let initGoalAction: GoalActions_t = {
        index: 0,
        goalDesc: "",
        durationDays: 1,
        action: "",
        metric: 1,
        metricUnit: "",
        timeframe: "per day"
    }
    const [showActionDropdown, setShowActionDropdown] = useState(false);
    const [showMetricDropdown, setShowMetricDropdown] = useState(false);
    const [showTimelineDropdown, setShowTimelineDropdown] = useState(false);
    
    // const [selectedAction, setSelectedAction] = useState("");
    // const [selectedMetric, setSelectedMetric] = useState(1);
    // const [selectedMetricUnits, setSelectedMetricUnits] = useState("");
    // const [selectedTimeframe, setSelectedTimeframe] = useState("");
    const [goalAction, setGoalAction] = useState<GoalActions_t>(initGoalAction)
    
    const [initSet, setInitSet] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.goalAction !== undefined) {
            setGoalAction(props.goalAction);
        }
    }

    useEffect(() => {
        if (props.forceEdit !== undefined && props.forceEdit === true) {
            setGoalAction(props.goalAction);
            return;
        } else {
            if (props.goalAction !== undefined && props.goalAction.durationDays !== goalAction.durationDays) {
                setGoalAction({...goalAction, durationDays: props.goalAction});
            }
        }
    }, [props.goalAction]);

    useEffect(() => {
        props.goalActionUpdated(goalAction);
    }, [goalAction])

    function toggleActionDropdown() {
        setShowActionDropdown(!showActionDropdown);
        setShowMetricDropdown(false);
        setShowTimelineDropdown(false);
    }

    function toggleMetricDropdown() {
        if (getSelectedActionIndex() === -1) {
            setShowMetricDropdown(false);
            setShowActionDropdown(false);
            setShowTimelineDropdown(false);
            return;
        }

        setShowMetricDropdown(!showMetricDropdown);
        setShowActionDropdown(false);
        setShowTimelineDropdown(false);
    }

    function toggleTimelineDropdown() {

        if (getSelectedActionIndex() === -1) {
            setShowMetricDropdown(false);
            setShowActionDropdown(false);
            setShowTimelineDropdown(false);
            return;
        }
        setShowTimelineDropdown(!showTimelineDropdown);
        setShowMetricDropdown(false);
        setShowActionDropdown(false);
    }

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'metric':
                setGoalAction({...goalAction, metric: Number(toString)});
                //setSelectedMetric(Number(toString));
                break;
            case 'action-desc':
            	setGoalAction({...goalAction, goalDesc: toString});
            default:
                break;

        }
    }

    function dropdownOptionSelected(option: string, dropdown: string) {
        switch (dropdown) {
            case 'action':
                //setSelectedAction(option);
                setGoalAction({...goalAction, action: option});
                break;
            case 'units':
                //setSelectedMetricUnits(option);
                setGoalAction({...goalAction, metricUnit: option});
                break;
            case 'timeframe':
                //setSelectedTimeframe(option);
                setGoalAction({...goalAction, timeframe: option});
                break;
            
            default:
                break;
        }

        setShowMetricDropdown(false);
        setShowActionDropdown(false);
        setShowTimelineDropdown(false);
    }

    function getSelectedActionIndex() {
        let i: number = actions.indexOf(goalAction.action !== undefined ? goalAction.action : "");
        return i;
    }

    function getRelevantMetrics() {
        let thisIndex = getSelectedActionIndex();
        if (thisIndex < 0) { return []; }

        return relevantMetricUnits[thisIndex];
    }


    window.addEventListener('click', function(e){   
        checkCloseActionMenu(e);
        checkCloseMetricMenu(e);
        checkCloseTimeframeMenu(e);
    });

    function checkCloseActionMenu(e: any) {
        let buttonID = `goal-skill-column-action-card-menu-${props.skillIndex}-${props.index}-button`;
        let menuID = `goal-skill-column-action-card-menu-${props.skillIndex}-${props.index}-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showActionDropdown === true) {
                    setShowActionDropdown(false);
                }
            }
        }
    }

    function checkCloseMetricMenu(e: any) {
        let buttonID = `goal-skill-card-metric-menu-${props.skillIndex}-${props.index}-button`;
        let menuID = `goal-skill-card-metric-menu-${props.skillIndex}-${props.index}-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showMetricDropdown === true) {
                    setShowMetricDropdown(false);
                }
            }
        }
    }

    function checkCloseTimeframeMenu(e: any) {
        let buttonID = `goal-skill-card-timeframe-menu-${props.skillIndex}-${props.index}-button`;
        let menuID = `goal-skill-card-timeframe-menu-${props.skillIndex}-${props.index}-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showTimelineDropdown === true) {
                    setShowTimelineDropdown(false);
                }
            }
        }
    }

    function getEndingDate() {
        let numDays = (goalAction.durationDays * (props.index + 1)) + (goalAction.durationDays * 2 * props.skillIndex);
        let currentTS = Date.now();

        let endingTS = currentTS + (numDays * 24 * 60 * 60 * 1000);

        let thisDate = new Date(endingTS);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`

    }

    function getDateSpan() {
    	let numDaysStart = (goalAction.durationDays * (props.index)) + (goalAction.durationDays * 2 * props.skillIndex);
    	let numDaysEnd = (goalAction.durationDays * (props.index + 1)) + (goalAction.durationDays * 2 * props.skillIndex);
        
        let currentTS = Date.now();
        let startingTS = currentTS + (numDaysStart * 24 * 60 * 60 * 1000);
        let endingTS = currentTS + (numDaysEnd * 24 * 60 * 60 * 1000);

        let thisStartingDate = new Date(startingTS);
        let thisEndingDate = new Date(endingTS);

        let thisStartingMoIndex = thisStartingDate.getMonth();
        let thisStartingDay = thisStartingDate.getDate();
        let thisEndingMoIndex = thisEndingDate.getMonth();
        let thisEndingDay = thisEndingDate.getDate();

        return `${moStrings[thisStartingMoIndex]} ${thisStartingDay} — ${moStrings[thisEndingMoIndex]} ${thisEndingDay}`
    }

    function getActionNumber() {
    	let actionNum = props.index + (2 * props.skillIndex);
    	return actionNum + 1;
    }


	return (
		<div className="goal-skill-column-action-card">
			<div className="goal-skill-column-action-card-header">
                <h4>Action {getActionNumber()}</h4>
                <p>{getDateSpan()}</p>
            </div>
            <div className="goal-skill-column-action-card-content">
            	<div className="goal-skill-column-action-card-content-input-container">
            		<IonTextarea value={goalAction.goalDesc} 
                        class="ion-no-padding"
                        placeholder={`Enter An Action...`}
                        inputmode="text"
                        clearOnEdit={false}
                        autoGrow={true}
                        rows={1}
                        maxlength={500}
                        onIonChange={e => inputChanged(e.detail.value!, 'action-desc')}/>
                </div>
            </div>
            <div className="goal-skill-column-action-card-options">


            </div>

            
		</div>
	)
}

/*

<div className="goal-skill-column-action-card-content">
                <p>{props.client !== undefined && props.client.first_name !== "" ? props.client.first_name : 'Your Client'} will </p>
                <div className="goal-skill-column-action-card-input-container-outter goal-skill-column-action-card-input-container-outter-action">
                    <div id={`goal-skill-column-action-card-menu-${props.skillIndex}-${props.index}-button`} onClick={() => toggleActionDropdown()} className="goal-skill-column-action-card-input-container goal-skill-column-action-card-input-container-action">
                        <p>{goalAction.action === "" ? "choose action..." : goalAction.action}</p>
                    </div>
                    <CSSTransition in={showActionDropdown} timeout={200} classNames="menu" unmountOnExit appear>
                        <div id={`goal-skill-column-action-card-menu-${props.skillIndex}-${props.index}-menu`} className="goal-skill-column-action-card-selector-menu-container">
                            {actions.map(item => (
                                <div 
                                    onClick={() => dropdownOptionSelected(item, 'action')}
                                    key={item} 
                                    className={`selector-menu-option ${item === goalAction.action ? "selector-menu-option-selected" : ""}`}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </CSSTransition>
                </div>
                <div className="goal-skill-column-action-card-input-container-outter goal-skill-column-action-card-input-container-outter-metric">
                    <div id={`goal-skill-card-metric-menu-${props.skillIndex}-${props.index}-button`} onClick={() => toggleMetricDropdown()} className={`goal-skill-column-action-card-input-container goal-skill-column-action-card-input-container-metric ${getSelectedActionIndex() === -1 ? 'goal-skill-column-action-card-input-container-unselected' : ''}`}>
                        <p>{goalAction.metricUnit === "" ? "set metric..." : `${goalAction.metric} ${goalAction.metricUnit}`}</p>
                    </div>
                    <CSSTransition in={showMetricDropdown} timeout={200} classNames="menu" unmountOnExit appear>
                        <div id={`goal-skill-card-metric-menu-${props.skillIndex}-${props.index}-menu`} className="goal-skill-column-action-card-selector-menu-container goal-skill-column-action-card-selector-menu-container-metric">
                            <div className="goal-skill-column-action-card-selector-menu-metric-input-container">
                                <p>Enter a target metric number below, and select a unit in the list on the right.</p>
                                <div className="goal-skill-card-header-input-container">
                                    <IonInput value={goalAction.metric} 
                                        class="ion-no-padding"
                                        placeholder={`Enter metric...`}
                                        inputmode="numeric"
                                        clearOnEdit={true}
                                        type="number"
                                        onIonChange={e => inputChanged(e.detail.value!, 'metric')}/>
                                </div>
                            </div>
                            <div className="goal-skill-column-action-card-selector-menu-metric-scroll-container">
                                {getRelevantMetrics().map((item: any) => (
                                    <div 
                                        onClick={() => dropdownOptionSelected(item, 'units')}
                                        key={item} 
                                        className={`selector-menu-option ${item === goalAction.metricUnit ? "selector-menu-option-selected" : ""}`}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                <div className="goal-skill-column-action-card-input-container-outter goal-skill-column-action-card-input-container-outter-timeline">
                    <div id={`goal-skill-card-timeframe-menu-${props.skillIndex}-${props.index}-button`} onClick={() => toggleTimelineDropdown()} className={`goal-skill-column-action-card-input-container goal-skill-column-action-card-input-container-timeline ${getSelectedActionIndex() === -1 ? 'goal-skill-column-action-card-input-container-unselected' : ''}`}>
                        <p>{goalAction.timeframe === "" ? "timeframe..." : goalAction.timeframe}</p>
                    </div>
                    <CSSTransition in={showTimelineDropdown} timeout={200} classNames="menu" unmountOnExit appear>
                        <div id={`goal-skill-card-timeframe-menu-${props.skillIndex}-${props.index}-menu`} className="goal-skill-column-action-card-selector-menu-container">
                            {timeframes.map(item => (
                                <div 
                                    onClick={() => dropdownOptionSelected(item, 'timeframe')}
                                    key={item} 
                                    className={`selector-menu-option ${item === goalAction.timeframe ? "selector-menu-option-selected" : ""}`}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </CSSTransition>
                </div>
                <p>for {goalAction.durationDays} days</p>
            </div>

*/

export default GoalSkillColumnActionCard;