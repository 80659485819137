import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import firebase from '../../Firebase';

import './WorkoutProfileMain.css';
import '../Dashboard/Clients.css';
import '../Dashboard/DashboardPageCommon.css';


import PageHeader_t from '../../Interfaces/PageHeader_t';
import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';

import PageHeader from '../../components/Base/PageHeader';

import WorkoutProfileGeneral from './WorkoutProfileGeneral'
import WorkoutProfileExercises from './WorkoutProfileExercises'


import '@material-ui/core';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Public from '@material-ui/icons/Public';


function WorkoutProfileMain(props: any) {

	let pageOptions: PageHeader_t[] = [{ name:"General", alert:false }, 
					   				   { name:"Exercises", alert:false }];

    let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};

	let history = useHistory();

	const [pageNumber, setPageNumber] = useState(0);
	const [imgUrl, setImgUrl] = useState('');
	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	

	useEffect(() => {
		setWorkout(props.workout);
		getImage();
	}, [props.workout])

	function getImage() {
		if (props.workout === undefined) { return; }
		const storage = firebase.storage();
		storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}


	function pageSelected(newPage: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setPageNumber(i);
			}
		}
	}

	function backButtonPressed() {
		history.push('/dashboard/workouts');
	}

	function startNewWorkout() {
		props.startWorkoutWithWorkout(props.workout);
	}

	return (
		<div className="page-container">
			<div className="workout-header">
				<PageHeader 
					pageName="Workout Information" 
					pageOptions={pageOptions}
					pageSelected={(e: string) => pageSelected(e)}
					showBack={true}
					backMessage="Back to all workouts"
					backButtonPressed={() => backButtonPressed()}
					isPartialMenu={true}
					workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
					addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
			</div>
			<div className="workout-profile-content-container">
				<div className="workout-profile-summary-container">
					<div className="workout-profile-summary-profile-image-container">
						<img  
							className="workout-profile-image" 
							hidden={imgUrl === ''}
							src={imgUrl} 
							alt={`Image for ${props.workout === undefined ? "" : props.workout.title}`} />
					</div>
					<div className="workout-profile-summary-header">
						<h3>{workout.title}</h3>
						<p>{workout.description !== undefined && workout.description !== "" ? workout.description : "No description provided ☹️"}</p>
					</div>
					<div className="workout-profile-summary-plan-container">
						<div className="workout-profile-summary-plan-inner-container">
							<div className="workout-profile-summary-plan-header-container">
								<h4>Workout Availability</h4>
							</div>
							<div className="workout-profile-summary-plan-card-container">
								<div className="workout-profile-summary-plan-card-header-icon-container">
									<Public className="workout-profile-summary-plan-card-header-icon"/>
								</div>
								<div className="workout-profile-summary-plan-card-header-container">
									<h4>All user access</h4>
									<p>{workout.title} can be downloaded and used by all EigenFit users and Eigen Trainers</p>
									
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="workout-profile-outer-page-container">
					<div hidden={pageNumber != 0} className="workout-profile-inner-page-container">
						<WorkoutProfileGeneral
							workout={props.workout}/>
					</div>
					<div hidden={pageNumber != 1} className="workout-profile-inner-page-container">
						<WorkoutProfileExercises
							workout={props.workout}/>
					</div>
				</div>
			</div>
		</div>
	)
}


/*
<div className="workout-profile-summary-contact-container">
						<div onClick={() => chatPressed()} className="workout-profile-summary-contact-icon-container workout-profile-summary-contact-icon-container-chat">
							<Chat className="workout-profile-summary-contact-icon workout-profile-summary-contact-icon-chat"/>
						</div>
						<div onClick={() => emailPressed()} className="workout-profile-summary-contact-icon-container workout-profile-summary-contact-icon-container-email">
							<Mail className="workout-profile-summary-contact-icon workout-profile-summary-contact-icon-email"/>
						</div>
						<div onClick={() => phonePressed()} className="workout-profile-summary-contact-icon-container workout-profile-summary-contact-icon-container-phone-unavailable">
							<Phone className="workout-profile-summary-contact-icon workout-profile-summary-contact-icon-phone-unavailable"/>
						</div>
					</div>
					<div className="workout-profile-summary-plan-container">
						<div className="workout-profile-summary-plan-inner-container">
							<div className="workout-profile-summary-plan-header-container">
								<h4>Currently assigned plan</h4>
							</div>
							<div hidden={currentPlan.id === ""} className="workout-profile-summary-plan-card-container">
								<div className="workout-profile-summary-plan-card-header-container">
									<p>{currentPlanPathNames}</p>
									<h4>{currentPlan.title}</h4>
								</div>
								<div className="workout-profile-summary-plan-card-completion-container">
									<div className="workout-profile-summary-plan-card-completion-loading-bar-container">
					                    <div className="workout-profile-summary-plan-card-completion-loading-bar-outer">
					                        <div className="workout-profile-summary-plan-card-completion-loading-bar-inner" style={getDownloadBarWidth()}/>
					                    </div>
					                </div> 
					                <div className="workout-profile-summary-plan-card-completion-loading-text-container">
										<p>{daysRemainingInPlan} days remaining</p>
									</div>
								</div>
							</div>
							<div hidden={currentPlan.id !== ""} className="workout-profile-summary-plan-card-container workout-profile-summary-plan-card-container-no-plan">
								<p>No plan assigned</p>
							</div>
						</div>
					</div>
*/

/*
<div className="page-container">
			<div className="workout-header">
				<PageHeader 
					pageName="Workout Information" 
					pageOptions={pageOptions}
					pageSelected={(e: string) => pageSelected(e)}
					showBack={true}
					backMessage="Back to all workouts"
					backButtonPressed={() => backButtonPressed()}/>
				<div onClick={() => startNewWorkout()} className="workouts-cta-button">Start a Workout <PlayArrow style={{ fontSize: 14 }} className="add-icon"/></div>
			</div>
			<div hidden={pageNumber != 0} className="workout-profile-inner-page-container">
				<WorkoutProfileGeneral
					workout={props.workout}/>
			</div>
			<div hidden={pageNumber != 1} className="workout-profile-inner-page-container">
				<WorkoutProfileExercises
					workout={props.workout}/>
			</div>
		</div>

*/

export default WorkoutProfileMain;

