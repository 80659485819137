import { useState } from 'react';

import firebase from './Firebase';
import { BLE } from '@ionic-native/ble';
import Peripheral_t from './Interfaces/Peripheral_t';
import Exercise_t from './Interfaces/Exercise_t';
import MotionData_t from './Interfaces/MotionData_t';
import RepStats_t from './Interfaces/RepStats_t';
import NodeInstance from './NodeInstance';
import MLCore from './MLCore';
import MotionManager from './MotionManager';

import Dashboard from './pages/Dashboard/Dashboard';


/**
 *	NodeManager
 *	----------------------------------------------------------------------------------------------------
 *	@desc Handles all Node connections, lists of connected / discovered Nodes, data retrieval, etc.
 *		  Create and share one instance of this class throughout the application and make use of it
 * 		  to handle accessing Nodes and their motion data.
 *
 */
class NodeManager {


	public test: string = "Here's a test string";
	private myNodes: NodeInstance[] = [];
	private discoveredNodes: NodeInstance[] = [];

	private mlCoreInstance: MLCore;

	private isScanning = false;

	private currentWeight: number = 0.0;
	private isKG: boolean = false;

	// Local instances of callback functions for super class to handle
	// Assign in constructor
	private myNodesUpdated_callback: any;
	private listUpdated_callback: any;
	private dataUpdated_callback: any;
	private streamUpdated_callback: any;
	private calibrationCallback: any;
	private calibrationFailedCallback: any;
	private recalibrationReadyCallback: any;
	private repUpdated_callback: any;
	private motionManagerDataUpdatedCallback: any;
	private connectionAlertCallback: any;
	private nodeStatusHasUpdatedCallback: any;
	private connectionToastCallback: any;
	private dataStream_callback: any;

	private tapAndHold_callback: any;

	private motionData: any[] = [];

	private currentExercise: Exercise_t = {title:'',id:'',type: 0,muscles:[]};
	private TESTING: boolean = false;
	private motionManager: MotionManager;// = new MotionManager([], this);


	/**
	 *	NodeManager constructor
	 *  ----------------------------------------------------------------------------------------------------
	 *	@param listUpdated_callback {method[NodeInstance]} assign to local method that will be called 
	 *		   when NodeManager updates Node list
	 *	@param dataUpdated_callback {method([number])} assign to local method that will be called when 
	 *		   NodeManager updates Node motion data
	 *
	 */
	constructor(listUpdated: any, nodesUpdated: any, dataUpdated: any, mlCore: any) {
		// set local callback functions		
		this.myNodesUpdated_callback = nodesUpdated;
		this.listUpdated_callback = listUpdated;
		this.dataUpdated_callback = dataUpdated;

		this.mlCoreInstance = mlCore;

		// pull user Nodes from db
		this.getUserNodes();
		//this.scanForNodes();

		//this.createTestNode();

		this.motionManager = new MotionManager(this.myNodes, this.currentExercise, this.currentWeight, this.isKG, this, mlCore);
	}

	public setNodeStatusHasUpdatedCallback(toCallback: any) {
		this.nodeStatusHasUpdatedCallback = toCallback;
	}

	public fireNodeStatusHasUpdatedCallback() {
		if (this.nodeStatusHasUpdatedCallback !== undefined) {
			//// console.log(`NODE MANAGER: fireNodeStatusHasUpdatedCallback`);
			this.nodeStatusHasUpdatedCallback();
		}
	}

	public setMotionDataCallback(toCallback: any) {
		this.dataUpdated_callback = toCallback;
	}

	public setDiscoveredNodesCallback(toCallback: any) {
		this.listUpdated_callback = toCallback;
	}

	public setMyNodesCallback(toCallback: any) {
		this.myNodesUpdated_callback = toCallback;
	}

	public setTapAndHoldCallback(toCallback: any) {
		this.tapAndHold_callback = toCallback;
	}

	public setRepUpdatedCallback(toCallback: any) {
		//// console.log("> NODE MANAGER: Rep updated callback has been set!");
		//// console.log(toCallback);
		this.repUpdated_callback = toCallback;
	}

	public setMotionManagerDataUpdatedCallback(toCallback: any) {
		this.motionManagerDataUpdatedCallback = toCallback;
	}

	public setStreamDataCallback(toCallback: any) {
		this.streamUpdated_callback = toCallback;
	}

	public motionManagerDataUpdated(data: any[]) {
		if (this.motionManagerDataUpdatedCallback !== undefined) {
			this.motionManagerDataUpdatedCallback(data);
		}
	}

	public setCalibrationFailedCallback(toCallback: any) {
		this.calibrationFailedCallback = toCallback;
	}

	public setDataStreamCallback(toCallback: any) {
		this.dataStream_callback = toCallback;
	}

	public setCurrentExercise(toExercise: Exercise_t) {
		//if (this.TESTING === true) { return; }
		//// console.log(`> NODEMANAGER: setCurrentExercise | Setting current exercise in motionManager to:`);
		//// console.log(toExercise);
		this.currentExercise = toExercise;
		this.motionManager.setExercise(toExercise);
	}

	public setCurrentExerciseTESTING(toExercise: Exercise_t) {
		this.currentExercise = toExercise;
		this.motionManager.setExercise(toExercise);
		this.TESTING = true;
	}

	public setAllConnectionCallbacks(toCallback: any) {
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				this.myNodes[i].setConnectionCallback(toCallback);
			}
		}
	}

	public setConnectionAlertCallback(toCallback: any) {
		this.connectionAlertCallback = toCallback;
	}

	public setConnectionToastCallback(toCallback: any) {
		this.connectionToastCallback = toCallback;
	}

	public nodeInstanceConnectionChanged(nowConnected: boolean) {
		var areAllConnected = true;
		var numConnected = 0;
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				let nodeIsConnected = this.myNodes[i].getIsConnected();
				let nodeLocation = this.myNodes[i].getLocationIndex();
				if (nodeIsConnected === false && nodeLocation !== -1) {
					areAllConnected = false;
				}

				if (nodeIsConnected) {
					numConnected += 1;
				}
			}
		}
		if (this.connectionAlertCallback !== undefined) {
			this.connectionAlertCallback(areAllConnected);
		}

		if (this.connectionToastCallback !== undefined) {
			this.connectionToastCallback(numConnected, nowConnected);
		}
		
	}

	public getMotionInfo() {
		return this.motionManager.getMotionInfo();
	}

	public getCurrentExercise() {
		return this.currentExercise;
	}

	public getRepStats() {
		if (this.motionManager === undefined) { return [] }
		return this.motionManager.getRepStats();
	}
	
	public setWeight(toWeight: number, isKG: boolean) {
		this.motionManager.setWeight(toWeight, isKG);
		this.currentWeight = toWeight;
		this.isKG = isKG;
	}

	public getMyNodesList() {
		return this.myNodes;
	}

	public getDiscoveredList() {
		return this.discoveredNodes;
	}


	public getNumberOfConnectedNodes() {
		var num = 0;

		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				let thisNode = this.myNodes[i];
				if (thisNode.getIsConnected() === true) {
					num += 1;
				}
			}
		}

		return num;
	}

	public getNodeInstanceByUUID(UUID: string) {

		if (this.discoveredNodes.length > 0) {
			for (var i = 0; i < this.discoveredNodes.length; i++) {
				let thisNode = this.discoveredNodes[i];
				let thisUUID = thisNode.getUUID();
				if (thisUUID === UUID) {
					return thisNode;
				}
			}
		}

		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				let thisNode = this.myNodes[i];
				let thisUUID = thisNode.getUUID();
				if (thisUUID === UUID) {
					return thisNode;
				}
			}
		}

		return null;
	}

	public setNodeLocation(UUID: string, locationIndex: number) {
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				let thisNode = this.myNodes[i];
				let thisUUID = thisNode.getUUID();
				if (thisUUID === UUID) {
					// console.log(`> NM: SETTING ${thisNode.getDeviceSIN()} TO INDEX ${locationIndex}`);
					this.myNodes[i].setLocationIndex(locationIndex);
				}
			}
		}
	}


	public testFn(stuff: string) {
		// console.log(`Hey this is some stuff: ${stuff}`);
		setTimeout(() => {
    		// console.log("Calling back from NodeManager...");
    		//this.callBack("toot!");
    		//Dashboard.prototype.testResponse.apply(this, ["toots"]);
    	}, 1000);
	}

	private createTestNode() {
		
		var temp: NodeInstance[] = [];//this.myNodes;
		let peripheral_A = {name: `TEST NODE:FFFFF.AA`, uuid: 'TEST_UUID_1234', signal: 80, battery: 60, isConnected: true};
		let thisNodeInstance_A = new NodeInstance(peripheral_A, this);
		temp.push(thisNodeInstance_A);

		let peripheral_B = {name: `TEST NODE:FFFFF.BB`, uuid: 'TEST_UUID_5678', signal: 70, battery: 70, isConnected: true};
		let thisNodeInstance_B = new NodeInstance(peripheral_B, this);
		temp.push(thisNodeInstance_B);

		let peripheral_C = {name: `TEST NODE:FFFFF.CC`, uuid: 'TEST_UUID_9ABC', signal: 60, battery: 80, isConnected: true};
		let thisNodeInstance_C = new NodeInstance(peripheral_C, this);
		temp.push(thisNodeInstance_C);
				
		this.discoveredNodes = temp;
		//// console.log("CREATING TEST NODE MY NODES LIST:");
		//// console.log(this.myNodes);
	}

	public pingSet(setSIN: string) {
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				let thisNode = this.myNodes[i];
				let thisNodeSetSIN = thisNode.getSetSIN();
				if (thisNodeSetSIN === setSIN) {
					thisNode.pingNode();
				}
			}
		}
	}

	public pingNode(uuid: string) {
		// console.log(`PING FOR ${uuid}`);
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				let thisNode = this.myNodes[i];
				let thisNodeUUID = thisNode.getUUID();


				if (thisNodeUUID === uuid) {
					thisNode.pingNode();
					//thisNode.sendGyroTest();
					//thisNode.startMotionDataStream();
				}
			}
		}
	}

	public setCalibrationCallback(toCallback: any) {
		this.calibrationCallback = toCallback;
	}

	public setRecalibrationReadyCallback(toCallback: any) {
		this.recalibrationReadyCallback = toCallback;
	}



	private failedNodes: NodeInstance[] = [];
	private powerCycledNodes: NodeInstance[] = [];

	public runGyroCalibration() {

		this.failedNodes = [];

		for (var i = 0; i < this.myNodes.length; i++) {
			let thisNode = this.myNodes[i];

			if (thisNode.getIsConnected() === true) {
				thisNode.runGyroTest();
			}
		}
	}

	public endGyroCalibration() {

		// console.log(`> NODE MANAGER: endGyroCalibration`);

		for (var i = 0; i < this.myNodes.length; i++) {
			let thisNode = this.myNodes[i];
			
			if (thisNode.getIsConnected() === true) {
				thisNode.endGyroTest();
			} 
		}
	}

	

	public gyroCalibrationCompleted(forUUID: string) {
		var completeAllCalibrations = true;
		for (var i = 0; i < this.myNodes.length; i++) {

			let thisNode = this.myNodes[i];
			let isDone = thisNode.getCalibrationState();
			let isConnected = thisNode.getIsConnected();
			//// console.log(`Calibration for ${thisNode.getUUID()} is ${isDone}`)
			if (isDone === false && isConnected === true) {
				completeAllCalibrations = false;
			}

			// console.log(`> NODE MANAGER: gyroCalibrationCompleted | node ${thisNode.getDeviceSIN()} is ${isConnected ? '' : 'NOT'} connected and is ${isDone ? '' : 'NOT'} complete calibration. ${isDone === false && isConnected === true} | ${completeAllCalibrations}`);

		}

		// console.log(`> NODE MANAGER: gyroCalibrationCompleted | ALL DONE CALIBRATION FOR UUID ${forUUID}! Is complete: ${completeAllCalibrations}`);

		if (completeAllCalibrations === true) {
			// console.log("> NODE MANAGER: ALL DONE CALIBRATION!");
			if (this.calibrationCallback !== null && this.calibrationCallback !== undefined) {
				this.calibrationCallback();
			} else {
				// console.log("> NODE MANAGER: CALIBRATION Callback is null / undefined");
			}
		} 

		// Check if in failedNodes list; if it is, remove it
		var listIndex = -1;
		if (this.failedNodes.length > 0) {
			for (var i = 0; i < this.failedNodes.length; i++) {
				let thisFailure = this.failedNodes[i];
				let thisFailureUUID = thisFailure.getUUID();
				if (thisFailureUUID === forUUID) {
					listIndex = i;
				}
			}
		}
		if (listIndex >= 0) {
			this.failedNodes.splice(listIndex, 1);
		}
	}

	public gyroCalibrationFailed(forNode: NodeInstance) {

		// Add to failedNodes array if not already in there
		var alreadyInArray = false;
		if (this.failedNodes.length > 0) {
			for (var i = 0; i < this.failedNodes.length; i++) {
				let thisFailure = this.failedNodes[i];
				let thisFailureUUID = thisFailure.getUUID();
				if (thisFailureUUID === forNode.getUUID()) {
					alreadyInArray = true;
				}
			}
		}

		if (alreadyInArray === false) {
			this.failedNodes.push(forNode);
		}

		if (this.calibrationFailedCallback !== null) {
			this.calibrationFailedCallback(forNode);
		}

		this.scanForNodes();
	}

	public nodePowerCycledForRecalibration(forNode: NodeInstance) {
		// console.log(`> NODE MANAGER: Power cycle complete for ${forNode.getDeviceSIN()}`);

		// Check if in failedNodes list; if it is, remove it
		var listIndex = -1;
		if (this.failedNodes.length > 0) {
			for (var i = 0; i < this.failedNodes.length; i++) {
				let thisFailure = this.failedNodes[i];
				let thisFailureUUID = thisFailure.getUUID();
				if (thisFailureUUID === forNode.getUUID()) {
					listIndex = i;
				}
			}
		}
		if (listIndex >= 0) {
			this.failedNodes.splice(listIndex, 1);
		}

		if (this.failedNodes.length === 0) {
			setTimeout(() => {
				this.endScan();
			}, 1000);
			
			if (this.recalibrationReadyCallback !== null) {
				this.recalibrationReadyCallback();
			}
		}
	}






	public getEstimatedBatteryTime() {
		var lowestBattery = 100;

		for (var i = 0; i < this.myNodes.length; i++) {
			//// console.log(`BATTERY CHECK FOR NODE ${i}`);
			let thisNode = this.myNodes[i];
			let thisLocation = thisNode.getLocationIndex();
			if (thisLocation !== -1) {
				let thisPeripheral = thisNode.getPeripheral();
				let thisBattery = thisPeripheral.battery;
				//// console.log(`BATTERY FOR NODE ${i} IS ${thisBattery}%`);
				if (thisBattery < lowestBattery) {
					lowestBattery = thisBattery;
				}
			}
		}

		let timeMins = Math.ceil(150 * lowestBattery / 100);

		return timeMins;
	}

	public getNumberOfSyncedNodes() {
		var num = 0;
		for (var i = 0; i < this.myNodes.length; i++) {
			let thisNode = this.myNodes[i];
			let thisLocation = thisNode.getLocationIndex();
			if (thisLocation !== -1) {
				num += 1;
			}
		}
		return num;
	}

	public getSyncedSINs() {
		var SINs: string[] = [];
		for (var i = 0; i < this.myNodes.length; i++) {
			let thisNode = this.myNodes[i];
			let thisLocation = thisNode.getLocationIndex();
			if (thisLocation !== -1) {
				let nodeSetSIN = thisNode.getSetSIN();
				if (SINs.includes(nodeSetSIN) === false) {
					SINs.push(nodeSetSIN);
				}
			}
		}

		return SINs;
	}

	/**
	 *	getUserNodes
	 *  ----------------------------------------------------------------------------------------------------
	 *	Call to get the logged in user's Nodes from database.
	 *	Will set this.myNodes to match list recorded for user in database.
	 *
	 */
	public getUserNodes() {

		// Get current firebase user. If no user exists, exit the method
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		// Flag bit to flip inside db callback function to prevent multiple pulls
		var canProceed = true;

		// Make new instance of this class for use inside of db callback function
		var self = this;

		

		// Create db reference for user's Node list and pull snapshot
		let database = firebase.database();
		let ref = database.ref(`personal_trainer_nodes/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (canProceed === false ) { return; }
			canProceed = false;

			// console.log(`> NODE MANAGER: Pulling user's Nodes from database.`);

			// Proceed if the user has Nodes in their list. If snapshot doesn't exist, user has no Nodes.
			if (snapshot.exists() === true) {
				// console.log('> NODE MANAGER: Nodes have been found')
				let existingList: any = snapshot.val();
				let listKeys = Object.keys(existingList);
				// console.log(snapshot.val());
				var temp: NodeInstance[] = [];
				for (var i = 0; i < listKeys.length; i++) {
					// console.log(`> NODE MANAGER: Addding element ${i}`)
					let thisNode = existingList[listKeys[i]];

					let peripheral = {name: `EigenFit Node :${thisNode.set_SIN}.${thisNode.device_SIN}`, uuid: thisNode.uuid, signal: 0, battery: 0, isConnected: false};
					let thisNodeInstance = new NodeInstance(peripheral, self);

					// console.log(thisNode.calibration);
					if (thisNode.calibration !== null && thisNode.calibration !== undefined) {
						if (thisNode.calibration.gyro !== null && thisNode.calibration.gyro !== undefined) {
							thisNodeInstance.updateCalibrationResultsFromSaved(thisNode.calibration.gyro);
						}
					}
					temp.push(thisNodeInstance);
				}
				self.myNodes = temp;
				self.myNodesUpdated_callback(self.myNodes);
			} else {
				self.myNodesUpdated_callback(self.myNodes);
				// console.log('> NODE MANAGER: No Nodes found')
			}
			self.scanForNodes();
		});
	}

	/**
	 *	removeMyNode
	 *  ----------------------------------------------------------------------------------------------------
	 *	Call to remove the given UUID from trainer's Node list in database.
	 *	@param withUUID {string} The UUID of the Node to be removed from Node list
	 *
	 */
	public removeMyNode(withUUID: string) {

		// Remove Node from my nodes list in NodeManager
		var removeAtIndex = -1;
		for (var i = 0; i < this.myNodes.length; i++) {
			if (this.myNodes[i].getUUID() === withUUID) {
				this.myNodes[i].disconnect();
				removeAtIndex = i;
			}
		}

		// console.log(`Removing at index ${removeAtIndex}`);
		if (removeAtIndex >= 0) {
			this.myNodes.splice(removeAtIndex, 1);
		}
		//this.myNodes.filter((item: NodeInstance) => item.getUUID() !== withUUID);
		this.myNodesUpdated_callback(this.myNodes);
		//this.scanForNodes();

		// Get current firebase user. If no user exists, exit the method
		let thisUser = firebase.auth().currentUser;
 		if (!thisUser) { return; }

 		// Flag bit to flip inside db callback function to prevent multiple pulls
 		var canProceed = true;

 		// Create db reference for user's Node list and pull snapshot
 		let database = firebase.database();
		let ref = database.ref(`personal_trainer_nodes/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (canProceed === false) { return; }
			canProceed = false;

			if (snapshot.exists() === true) {
				let existingList: [{uuid: string}] = snapshot.val();
				var removalIndex = 0;
				for (var i = 0; i < existingList.length; i++) {
					if (existingList[i].uuid === withUUID) { 
						removalIndex = i;
					}
				}
				
				for (var i = removalIndex; i < existingList.length; i++) {
					ref.child(`${i - 1}`).set(existingList[i]);
				}
				if (removalIndex === 0) {
					ref.child(`-1`).set(null);
				}
				ref.child(`${existingList.length - 1}`).set(null);
			}
		});
	}

	public removeSet(setSIN: string) {
		// Remove nodes from Node Manager 'myNodes' array
		let updatedNodes = this.myNodes.filter(item => item.getSetSIN() !== setSIN);
		this.myNodes = updatedNodes;

		this.myNodesUpdated_callback(this.myNodes);

		// Remove nodes from db
		// Get current firebase user. If no user exists, exit the method
		let thisUser = firebase.auth().currentUser;
 		if (!thisUser) { return; }

 		// Flag bit to flip inside db callback function to prevent multiple pulls
 		var canProceed = true;

 		// Create db reference for user's Node list and pull snapshot
 		let database = firebase.database();
		let ref = database.ref(`personal_trainer_nodes/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (canProceed === false) { return; }
			canProceed = false;

			if (snapshot.exists() === true) {

				let existingList: any = snapshot.val();
				let listKeys = Object.keys(existingList);
				var temp: NodeInstance[] = [];
				for (var i = 0; i < listKeys.length; i++) {
					let thisNode = existingList[listKeys[i]];
					let thisNodeSetSIN = thisNode.set_SIN;
					if (thisNodeSetSIN === setSIN) {
						ref.child(listKeys[i]).set(null);
					}
				}
			}
		});
	}


	public addSetToMyList(setSIN: string) {

		if (this.discoveredNodes.length > 0) {
 			var tempDiscoveredNodes: NodeInstance[] = [];
 			var tempMyNodes: NodeInstance[] = [];
			for (var i = 0; i < this.discoveredNodes.length; i++) {
				let thisNode = this.discoveredNodes[i];
				let thisNodeSetSIN = thisNode.getSetSIN();

				if (thisNodeSetSIN === setSIN) {
					let thisNodeInstance: NodeInstance = new NodeInstance(thisNode.getPeripheral(), this);
				 	tempMyNodes.push(thisNodeInstance);
				// 	//this.addNodeToUser(thisNode.getUUID());
				 	this.connectToPeripheral(thisNode);
				} else {
				 	tempDiscoveredNodes.push(thisNode);
				}
			}

			this.addMultipleNodesToUser(tempMyNodes);

			let fullMyNodes = this.myNodes.concat(tempMyNodes);
			this.myNodes = fullMyNodes;
			this.discoveredNodes = tempDiscoveredNodes;
			// Call the list updated callbacks to update listening UI components on list changes
 			this.myNodesUpdated_callback(this.myNodes);
			this.listUpdated_callback(this.discoveredNodes);
		}
	}

	public addToMyList(peripheral: Peripheral_t) {

		// console.log("----- ADDING TO MY LIST")
		let thisNodeInstance: NodeInstance = new NodeInstance(peripheral, this);

		// Add the NodeInstance to myNodes list
		this.myNodes.push(thisNodeInstance);

		// Remove the NodeInstance from the discoveredNodes list
		var removeAtIndex = -1;
		for (var i = 0; i < this.discoveredNodes.length; i++) {
			if (this.discoveredNodes[i].getUUID() === peripheral.uuid) {
				this.discoveredNodes[i].disconnect();
				removeAtIndex = i;
			}
		}

		// console.log(`Removing at index ${removeAtIndex}`);
		if (removeAtIndex >= 0) {
			this.discoveredNodes.splice(removeAtIndex, 1);
		}
 		//this.discoveredNodes.filter((item: NodeInstance) => item.getUUID() !== peripheral.uuid);

 		// Call the list updated callbacks to update listening UI components on list changes
 		this.myNodesUpdated_callback(this.myNodes);
		this.listUpdated_callback(this.discoveredNodes);

		// Add this Node to the trainer's personal Node list in the db
 		this.addNodeToUser(peripheral.uuid);

 		// Connect to the peripheral
 		this.connectToPeripheral(thisNodeInstance);
 	}

 	private addMultipleNodesToUser(nodes: NodeInstance[]) {
 		let thisUser = firebase.auth().currentUser;
 		if (!thisUser) { return; }

 		var canProceed = true;

 		let database = firebase.database();
		let ref = database.ref(`personal_trainer_nodes/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (canProceed === false) { return; }
			canProceed = false;

			//if (snapshot.exists() === true) {
				//let existingList: [] = snapshot.val();

			for (var i = 0; i < nodes.length; i++) {
				let thisNode = nodes[i];
				let newObj = {
					set_SIN: thisNode.getSetSIN(),
					device_SIN: thisNode.getDeviceSIN(),
					uuid: thisNode.getUUID()
				};
				ref.push(newObj);
			}
			//} 
		});
 	}

	private addNodeToUser(uuid: string) {
 		let thisUser = firebase.auth().currentUser;
 		if (!thisUser) { return; }

 		var canProceed = true;

 		let database = firebase.database();
		let ref = database.ref(`personal_trainer_nodes/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (canProceed === false) { return; }
			canProceed = false;

			if (snapshot.exists() === true) {
				let existingList: [] = snapshot.val();
				ref.child(`${existingList.length}`).set({uuid: uuid});
			} else {
				ref.child(`0`).set({uuid: uuid});
			}
		});
 	}





 	public NodeRepDataUpdated(repData: RepStats_t, node: NodeInstance) {
 		if (this.motionManager !== undefined) {
 			this.motionManager.nodeDetectedRepComplete(repData, node);
 		} else {
 			// console.log(`ERROR OCCURED when sending rep data to motion manager. No motion manager instance exists.`);
 		}
 	}

 	/**
 	 * 	Node Motion Stream Updated
 	 * 	----------------------------------------------------------------------------------------------------
 	 * 	To be called from NodeInstance when device receives a new batch of data from a Nodes. 
 	 *  Passes the given data along to MotionManager for further analysis.
 	 * 
 	 *  @param data 	 {MotionData_t[]}   A batch of the latest motion data from the Node
	 *	@param node 	 {NodeInstance}     Reference to the Node that is sharing the update
 	 * 
 	 */ 
 	public NodeMotionStreamUpdated(data: MotionData_t[], node: NodeInstance) {

 		if (this.dataStream_callback !== undefined) {
 			// console.log("Node mgr: will call dataStream_callback()", node.getUUID(), data.length, this.myNodes.length);
 			this.dataStream_callback(data, node);
 		} else {
 			if (this.motionManager !== undefined) {
	 			this.motionManager.NodeMotionStreamUpdated(data, node);
	 		} else {
	 			// console.log(`> NODE MANAGER: ERROR! [NodeMotionStreamUpdated()] No motion manager instance exists.`);
	 		}
 		}

 		

 		
 	}


	/**
	 *	NodeMotionDataUpdated
	 *  ----------------------------------------------------------------------------------------------------
	 *	Callback for when NodeInstance data is updated.
	 *	@param data {MotionData_t} 			  The motion data updated from the Node
	 *	@param forPeripheral {Peripheral_t}   Peripheral instance of the Node that has been updated
	 *
	 */
	public NodeMotionDataUpdated(data: MotionData_t, forPeripheral: Peripheral_t, locationIndex: number) {
		//// console.log(`> NODE MANAGER: Data updated for UUID '${forPeripheral.uuid}' | ${data}`);
		
		//// console.log(`> NODE MANAGER: Data updated \t\t location index ____${locationIndex}____ \t\t w is ____${data.quaternion.w}____\t\t length is ${this.motionData.length}`);
		let thisMotionDataObject = {data: data, peripheral: forPeripheral, locationIndex: locationIndex};		
		
		if (this.motionData !== undefined && this.motionData.length > 0) {
			// Motion data already exists in the list. Check if the updated peripheral 
			// is in the list; if it is, set peripheralIndex to the index in motionData

			var peripheralIndex = -1;
			for (var i = 0; i < this.motionData.length; i++) {
				if (this.motionData[i].peripheral.uuid === forPeripheral.uuid) {
					peripheralIndex = i;
				}
			}

			if (peripheralIndex >= 0) {
				// The updated peripheral has previously pushed data to the list.
				// Update the peripheral's data parameter to the latest MotionData
				//// console.log(`${locationIndex}: Push to existing at index ${peripheralIndex}`);
				this.motionData[peripheralIndex] = thisMotionDataObject; //.data = data;
			} else {
				// The updated peripheral hasn't previously pushed data to the list.
				// Push this set of data to the list.
				//// console.log(`${locationIndex}: Push to new location`);
				this.motionData.push(thisMotionDataObject);
			}
		} else {
			// No motion data exits - create list of only the updated peripheral's data
			//// console.log(`${locationIndex}: Push to empty array - first in motionData`);
			this.motionData = [thisMotionDataObject];
		}

		if (this.motionManager !== undefined) {
			//// console.log(this.motionData);
			this.motionManager.dataUpdated(this.motionData, this.currentExercise);
		}

		// Call the callback to update UI elements with latest data
		this.dataUpdated_callback(this.motionData);

	}

	/**
	 *	NodeStreamUpdated
	 *  ----------------------------------------------------------------------------------------------------
	 *	Callback for when NodeInstance quaternion stream is updated.
	 *	@param data {MotionData_t}[][] 		  The motion data updated from the Node
	 *	@param forNode {NodeInstance}	      Peripheral instance of the Node that has been updated
	 *
	 */
	public NodeStreamUpdated(data: MotionData_t, forNode: NodeInstance) {

		if (this.streamUpdated_callback !== null) {
			this.streamUpdated_callback(data, forNode);
		}
	} 


	/**
	 *	scanForNodes
	 *  ----------------------------------------------------------------------------------------------------
	 *  Call when scanning for new Nodes to connect to. Scan will last for 5000ms .
	 *
	 */
	public scanForNodes() {
		
		// Check if already scanning for Nodes - if this.isScanning is true, exit from the method.
		if (this.isScanning === true) { 
			BLE.stopScan();
			this.isScanning = false;
			setTimeout(() => {
				this.scanForNodes();
			}, 500);
			return; 
		}
    	this.isScanning = true;

    	// console.log("> NODE MANAGER: Will scan for peripherals ");

    	BLE.startScan([]).subscribe(peripheral => {
    		
    		// List of UUIDs of BLE peripherals that have the name "FreedomFit-02" but should be ignored.
    		// Includes testing devices (NRF51422 dev board) and other unwanted peripherals.
    		let whitelisted_UUIDs = [
    			"B51199A3-643C-6C94-F7F4-EDEFFF92C5D5",
    			"B76D628E-CDB9-D625-1742-AAF395CFC5F9"
    		];

    		
    		

    		let deviceName = peripheral.advertising !== null && peripheral.advertising !== undefined ? (peripheral.advertising.kCBAdvDataLocalName !== null && peripheral.advertising.kCBAdvDataLocalName !== undefined ? peripheral.advertising.kCBAdvDataLocalName : peripheral.name) : peripheral.name;
    		// Only regard discovered peripherals that have name "FreedomFit-2".
    		// Emit testing devices (NRF51422 dev board) by their UUID, according to whitelisted_UUIDs
        	if (deviceName !== undefined && deviceName.includes("EigenFit Node") && whitelisted_UUIDs.indexOf(peripheral.id) === -1) {

        		// Parse peripheral name to get set SIN and device SIN
        		let splitName = deviceName.split(':');
        		let fullSINString = splitName[1];
        		let splitSIN = fullSINString.split('.');
        		let setSIN = splitSIN[0];
        		let deviceSIN = splitSIN[1];

        		// console.log(`> NODE MANAGER: Node found. SIN:${setSIN}.${deviceSIN}. UUID:${peripheral.id}`);
        		// Check if Node has been previously disovered. If it has, set hasBeenAdded to 'true'.
        		var hasBeenAdded = false;
        		if (this.discoveredNodes.length > 0) {
	        		for (var i = 0; i < this.discoveredNodes.length; i++) {
	        			let thisUUID = this.discoveredNodes[i].getUUID();
	        			//if (thisUUID === peripheral.id) { hasBeenAdded = true; }
	        		}
	        	}


        		for (var i = 0; i < this.myNodes.length; i++) {
        			let thisNode = this.myNodes[i];
        			let thisUUID = thisNode.getUUID();
        			//// console.log(`Trying to match:\t*${thisUUID}*\t\t*${peripheral.id}`);
        			if (thisUUID === peripheral.id) { 
        				// console.log(`${thisUUID} has been added. Will connect`);
        				hasBeenAdded = true;
        				this.connectToPeripheral(thisNode); 
        			}
        		}

        		// If Node has not been added to discoveredNodes list, create new NodeInstance and push it to the list
        		if (hasBeenAdded === false) {
        			let thisPeriph = {name: deviceName, uuid: peripheral.id, signal: 90, battery: 90, isConnected: true};
					let thisNodeInstance = new NodeInstance(thisPeriph, this);
					this.discoveredNodes.push(thisNodeInstance);
					this.listUpdated_callback(this.discoveredNodes);
					//addDiscoveredNodes(thisPeriph);
				}
        	}
    	});
   
   		// Begin timeout to end BLE scan after 12000ms

   		if (this.failedNodes.length === 0) {
   			// console.log("> NODE MANAGER: Will set 12 second scan timeout");
   			setTimeout(() => {
	    		// Set isScanning flag to false and end scan
	    		this.endScan();
	    	}, 12000);
   		} else {
   			// console.log("> NODE MANAGER: Will set 4 MINUTE scan timeout");
   			setTimeout(() => {
	    		// Set isScanning flag to false and end scan
	    		this.endScan();
	    	}, 240000);
   		}
    	
	}

	private endScan() {

		if (this.myNodes.length > this.getNumberOfConnectedNodes()) {
			//// console.log("> NODE MANAGER: Not all Nodes are connected -- will check back in 12s");
   			setTimeout(() => {
	    		// Set isScanning flag to false and end scan
	    		this.endScan();
	    	}, 12000);
		} else {
			// console.log("> NODE MANAGER: Scan for peripherals has eneded.");

	    	this.isScanning = false;
	    	BLE.stopScan();
		}
		
	}

	public repUpdated(toReps: RepStats_t[]) {
		//// console.log(this.repUpdated_callback);
		if (this.repUpdated_callback !== undefined) {
			//// console.log("> NODE MANAGER: Rep updated callback will fire!");
			this.repUpdated_callback(toReps);
		} else {
			// console.log("> NODE MANAGER: ERROR!! Rep updated callback has not been set!");
		}
	} 

	/**
	 *  beginDataStream
	 *	----------------------------------------------------------------------------------------------------
	 *
	 */
	public beginDataStream() {
		// // console.log("> NODE MANAGER: CURRENT EXERCISE -> ");
		// // console.log(this.currentExercise);

		this.motionData = [];

		this.motionManager = new MotionManager(this.myNodes, this.currentExercise, this.currentWeight, this.isKG, this, this.mlCoreInstance);
		//this.motionManager.setWeight(this.currentWeight, this.isKG);
		//this.motionManager.setExercise(this.currentExercise);

		this.motionManager.beginThisSession();

		//// console.log(this.repUpdated_callback);
		setTimeout(() => {
		    // console.log("> NODE MANAGER: Beginning Stream");
			if (this.myNodes.length > 0) {
				for (var i = 0; i < this.myNodes.length; i++) {
					if (this.myNodes[i].getIsConnected() === true) {
						this.myNodes[i].startMotionDataStream();
					}
				}
			}
		}, 200); // TODO: update time to 4000mS
		
	}

	/**
	 *  endDataStream
	 *	----------------------------------------------------------------------------------------------------
	 *
	 */
	public endDataStream() {
		// console.log("> NODE MANAGER: Ending Stream");
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				this.myNodes[i].endMotionDataStream();
			}
		}

		if (this.motionManager !== undefined) {
			this.motionManager.logThisSession();
			this.motionManager.printTestResults();
		}
	}


	/**
	 *  Tap Handelling
	 *	----------------------------------------------------------------------------------------------------
	 *
	 */
	public enableTap() {
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				this.myNodes[i].allowTap();
			}
		}
	}

	public disableTap() {
		if (this.myNodes.length > 0) {
			for (var i = 0; i < this.myNodes.length; i++) {
				this.myNodes[i].endTap();
			}
		}
	}

	// Call from NodeInstance to indicate that a change in tap state has occured.
	// @param toState {boolean}		indicates if tap is present (true) or has been removed (false)
	public tapOccured(toState: boolean, forNode: NodeInstance) {
		// console.log("> NODE MANAGER: tapOccured", forNode.getUUID());
		if (this.tapAndHold_callback !== null) {
			this.tapAndHold_callback(forNode);
		}
	}

	// Call from NodeInstance to indicate that a tap-and-hold has occured
	public holdOccured(forNode: NodeInstance) {
		// console.log(`> NODE MANAGER: Tap and hold occured for Node with UUID: ${forNode.getUUID()}`);

		if (this.tapAndHold_callback !== null) {
			this.tapAndHold_callback(forNode);
		}
	}

	/**
	 *	connectToPeripheral
	 *  ----------------------------------------------------------------------------------------------------
	 *	Will connect to the given Node peripheral
	 *	@param node {NodeInstance} the instance of the Node to be connected to.
	 *
	 */
	private connectToPeripheral(node: NodeInstance) {
		node.connect();
		this.myNodesUpdated_callback(this.myNodes);
		this.listUpdated_callback(this.discoveredNodes);
	}
}

export default NodeManager;
