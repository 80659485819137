import React, { useState, useEffect } from 'react';
import './IntraWorkoutOverview.css';
import {Line, Bar} from 'react-chartjs-2';

import Exercise_t from '../../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import SetStats_t from '../../../Interfaces/SetStats_t'
import Tempo_t from '../../../Interfaces/Tempo_t';

import ExerciseListCell from './IntraWorkoutElements/ExerciseListCell';
import IntraWorkoutOverviewSummaryStats from './IntraWorkoutOverviewSummaryStats';

import '@material-ui/core';
import BarChart from '@material-ui/icons/BarChart';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import Timer from '@material-ui/icons/Timer';
import ShowChart from '@material-ui/icons/ShowChart';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowDropUp from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded';
import Check from '@material-ui/icons/Check';
import Timeline from '@material-ui/icons/Timeline';
import Accessibility from '@material-ui/icons/Accessibility';
import Add from '@material-ui/icons/Add';
import Note from '@material-ui/icons/Note';

function IntraWorkoutOverview(props: any) {

    const [initSet, setInitSet] = useState(false);
    let testWeight = 30;

    let muscleNames = [
        [ // Abs
          "Rectus Abdominis",
          "External Obliques",
          "Internal Obliques"
        ],
        [ // Arms
          "Biceps Short Head",
          "Biceps Long Head",
          "Triceps Long Head",
          "Triceps Lateral Head",
          "Triceps Medial Head"
        ],
        [ // Back
          "Trapezius",
          "Rhomboid Major",
          "Latissimus Dorsi",
          "Teres Major",
          "Erector Spinae"
        ],
        [ // Chest
          "Pectoralis Clavicular Head",
          "Pectoralis Sternocosial Head"
        ],
        [ // Shoulders
          "Deltoid Anterior Head",
          "Deltoid Posterior Head",
          "Deltoid Lateral Head"
        ],
        [ // Legs
          "Gastrocnemius",
          "Soleus",
          "Rectus Femoris",
          "Vastus Lateralis",
          "Vastus Medialis",
          "Adductor Longus",
          "Adductor Mangus",
          "Bicep Femoris"
        ],
        [ // Butt
          "Gluteus Medius",
          "Gluteus Maximus"
        ]
    ];

    /*let dataSets = {
        labels: ['0', '1', '2', '3', '4','1','2','3','4','1','2'],
        datasets: [
            {
                label: 'Weight',
                yAxisID: 'weight',
                backgroundColor: 'rgba(73,89,193,0.05)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                type: 'line',
                data: [100,120,140,160,160,100,120,140,160,160,100,120,140,160,160]
            },
            {
                label: 'Reps',
                yAxisID: 'reps',
                backgroundColor: 'rgba(240,46,74,0.1)',
                hoverBackgroundColor: `rgba(240,46,74,0.9)`,
                borderColor: 'rgba(240,46,74,1)',
                hoverBorderColor: `rgba(240,46,74,1)`,
                borderWidth: 1,
                data: [16,12,12,8,12,16,12,12,8,12,16,12,12,8,12]
            }
        ]
    }*/

    let initDataSets = {
        labels: [],
        datasets: [
            {
                label: 'Weight',
                yAxisID: 'weight',
                backgroundColor: 'rgba(73,89,193,0.05)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            },
            {
                label: 'Reps',
                yAxisID: 'reps',
                backgroundColor: 'rgba(240,46,74,0.1)',
                hoverBackgroundColor: `rgba(240,46,74,0.9)`,
                borderColor: 'rgba(240,46,74,1)',
                hoverBorderColor: `rgba(240,46,74,1)`,
                borderWidth: 1,
                data: []
            }
        ]
    }

    let initExercise: Exercise_t = {title:'',id:'',type: 0,muscles:[]};
    let initExerciseSeries: ExerciseSeries_t = {title: '', index: 0, exercises: [], type: 0, isShowing: false};

    const [dataSets, setDataSets] = useState(initDataSets);
    const [showingRestTime, setShowingRestTime] = useState(true);

    const [selectedExerciseSeries, setSelectedExerciseSeries] = useState<ExerciseSeries_t>(initExerciseSeries);
    const [selectedExercise, setSelectedExercise] = useState<Exercise_t>(initExercise);
    
    const [selectedExerciseSeriesIndex, setSelectedExerciseSeriesIndex] = useState(0);
    const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
    const [selectedSetIndex, setSelectedSetIndex] = useState(0);

    const [pwm_percentChange, setPwM_percentChange] = useState(0);

    const [ticker, setTicker] = useState(0);

    var chartOptions: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 10,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` LBS`;
                    }
                }
            },
            {
                id: 'reps',
                position: 'right',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 1,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` reps`;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:true,
            position: 'bottom'
        }
      }
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        updatePage();
    }

    useEffect(() => {
        updatePage();
        if (props.setStats === undefined || props.setStats.length === 0) { return; }
        let stats: SetStats_t[][][] = props.setStats;

        // var labels_temp: string[] = [];
        // var points_temp_weight: number[] = [];
        // var points_temp_reps: number[] = [];
        // for (var i = 0; i < stats.length; i++) {
        //     let thisExerciseStats = stats[i];
        //     for (var j = 0; j < thisExerciseStats.length; j++) {
        //         let thisSetStats = thisExerciseStats[j];
        //         labels_temp.push(String(j + 1));
        //         let numReps = thisSetStats.repStats.length; // !!
        //         points_temp_reps.push(numReps);
        //         points_temp_weight.push(thisSetStats.weight);
        //     }
        // }

        // var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        // tempDataSets.labels = labels_temp;
        // tempDataSets.datasets[0].data = points_temp_weight;
        // tempDataSets.datasets[1].data = points_temp_reps;

        // setDataSets(tempDataSets);
        

    }, [props]);


    // useEffect(() => {
    //     if (props.selectedSetIndex !== undefined) {
    //         setSelectedSetIndex(props.selectedSetIndex);
    //     }
    // }, [props.selectedSetIndex])

    // useEffect(() => {
    //     if (props.selectedExerciseIndex !== undefined) {
    //         setSelectedExerciseIndex(props.selectedExerciseIndex);
    //         if (props.workout)
    //     }
    // }, [props.selectedExerciseIndex])

    // useEffect(() => {
    //     if (props.selectedExerciseSeriesIndex !== undefined) {
    //         setSelectedExerciseSeriesIndex(props.selectedExerciseSeriesIndex);
    //     }
    // }, [props.selectedExerciseSeriesIndex])

    useEffect(() => {

        if (props.selectedExerciseSeriesIndex !== undefined) {
            setSelectedExerciseSeriesIndex(props.selectedExerciseSeriesIndex);
        }

        if (props.selectedExerciseIndex !== undefined) {
            setSelectedExerciseIndex(props.selectedExerciseIndex);
        }


        if (props.selectedSetIndex !== undefined) {
            setSelectedSetIndex(props.selectedSetIndex);
        }

        if (props.workout !== undefined && props.workout.exerciseSeries !== undefined && props.selectedExerciseSeriesIndex < props.workout.exerciseSeries.length) {
            setSelectedExerciseSeries(props.workout.exerciseSeries[props.selectedExerciseSeriesIndex]);

            //let setIndexTemp = props.selectedSetIndex < 0 ? 0 : props.selectedSetIndex;
            setSelectedExercise(props.workout.exerciseSeries[props.selectedExerciseSeriesIndex].exercises[props.selectedExerciseIndex]);
        }

        updatePage();

    }, [props.selectedExerciseSeriesIndex, props.selectedExerciseIndex, props.selectedSetIndex]);

    useEffect(() => {
        if (props.currentWorkout !== undefined) {
            // setSelectedExerciseSeriesIndex(props.currentWorkout.seriesIndex);
            // setSelectedExerciseIndex(props.currentWorkout.exerciseIndex);
            // setSelectedSetIndex(props.currentWorkout.setIndex);
            if (props.selectedExerciseSeriesIndex !== undefined) {
                setSelectedExerciseSeriesIndex(props.selectedExerciseSeriesIndex);
            }

            if (props.selectedExerciseIndex !== undefined) {
                setSelectedExerciseIndex(props.selectedExerciseIndex);
            }


            if (props.selectedSetIndex !== undefined) {
                setSelectedSetIndex(props.selectedSetIndex);
            }
        }

        updatePage();
        
    }, [props.currentWorkout]);

    function updatePage() {
        //updateWorkingMaxChange();
        setTicker(ticker + 1);
    }

    function getDescriptionString() {
        var temp = '';

        if (props.workout.description !== undefined) {
            temp = props.workout.description;
        }

        return temp;
    }

    function getMusclesString() {
        var temp = '';

        if (props.workout.exercises !== undefined && props.workout.exercises.length > 0) {
            temp = 'Muscles used in this workout are ';

            for (var i = 0; i < props.workout.exercises.length; i++) {
                let thisExercise: Exercise_t = props.workout.exercises[i];
                if (thisExercise.muscles.length > 0) {
                    for (var j = 0; j < thisExercise.muscles.length; j++) {
                        let thisMuscle = thisExercise.muscles[j];
                        let thisMuscleString = muscleNames[thisMuscle.group][thisMuscle.muscle];
                        if (!temp.includes(thisMuscleString)) {
                            temp += i + j === 0 ? thisMuscleString : `, ${thisMuscleString}`
                        }
                    }
                }
            }
            temp += '.';
        } else {
            temp = 'Muscles for this workout have not been defined'
        }

        return temp;
    }

    function toggleShowRest() {
        setShowingRestTime(!showingRestTime);
    }

    function getExerciseName() {
        return selectedExercise.title;
    }

    function getDuration() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return; }
        if (selectedSetIndex === -1) { 
            //return; 
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length) {

                var dt_sum = 0;
                for (var i = 0; i < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length; i++) {
                    let thisSetStats: SetStats_t[] = props.currentWorkout.setStats[selectedExerciseSeriesIndex][i];
                    let thisExStat = selectedExerciseIndex < thisSetStats.length ? thisSetStats[selectedExerciseIndex] : thisSetStats[0];
                    dt_sum += showingRestTime ? thisExStat.restTime : thisExStat.endUnixTime - thisExStat.startUnixTime;
                }

                let dt_m = Math.floor(dt_sum / 60);
                let dt_s = Math.floor(dt_sum % 60);

                return `${dt_m < 10 ? '0' : ''}${dt_m}m ${dt_s < 10 ? '0' : ''}${dt_s}s`;
            }
        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {
                
                let thisSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                let dt = showingRestTime ? thisSetStat.restTime : thisSetStat.endUnixTime - thisSetStat.startUnixTime;
                
                let dt_m = Math.floor(dt / 60);
                let dt_s = Math.floor(dt % 60);

                return `${dt_m < 10 ? '0' : ''}${dt_m}m ${dt_s < 10 ? '0' : ''}${dt_s}s`;
            }
        }        

        return '--m --s';
    }

    function getPWM() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return '--.-% WM'; }

        let wm = getWM();

        if (wm > 0) {

            if (selectedSetIndex >= 0 && 
                selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {
                
                let thisSetStat: SetStats_t = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                let percentWM = (thisSetStat.weight / wm) * 100;
                return `${Math.round(percentWM * 10) / 10}% WM`
            } else if (selectedSetIndex === -1) {

                return `${Math.round(wm * 10) / 10} ${props.currentWorkout.isKG ? "KG" : "LBS"}`
            }
        }




        return '--.-% WM';

    }

    function getWM() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return -1; }

        let thisExercise: Exercise_t = props.currentWorkout.workout.exerciseSeries[selectedExerciseSeriesIndex].exercises[selectedExerciseIndex];
        if (thisExercise.userWorkingMaxHistory !== undefined && thisExercise.userWorkingMaxHistory.length > 0) {
            // Return the most recent WM in user's history
            let mostRecentWMObj = thisExercise.userWorkingMaxHistory[0];
            return Math.round(mostRecentWMObj.working_max * 10) / 10;
        } else {
            // No user history exists - generate avg. WM from set stats
            

            if (props.currentWorkout.setStats !== undefined && props.currentWorkout.setStats[selectedExerciseSeriesIndex] !== undefined && props.currentWorkout.setStats[selectedExerciseSeriesIndex].length > 0) {
                var avgWM = 0.0;
                var avgWM_count = 0;
                for (var i = 0; i < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length; i++) {
                    let thisSetStats: SetStats_t[] = props.currentWorkout.setStats[selectedExerciseSeriesIndex][i];
                    let thisExStat: SetStats_t = selectedExerciseIndex < thisSetStats.length ? thisSetStats[selectedExerciseIndex] : thisSetStats[0];
                    avgWM += thisExStat.weight / (1.0278 - (0.0278 * thisExStat.manualEntryReps));
                    avgWM_count += 1;
                }

                avgWM = avgWM_count === 0 ? avgWM : avgWM / avgWM_count;
                avgWM = Math.round(avgWM * 10) / 10;

                return avgWM;
            } else {
                return -1
            }
            
        }
    }

    function getWorkingMaxWeight() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return; }

        let wm = getWM();

        if (wm < 0) {
            return 'Complete first set';
        } else {
            return `${wm} ${props.currentWorkout.isKG ? "KG" : "LBS"}`
        }

        // let thisExercise: Exercise_t = props.currentWorkout.workout.exerciseSeries[props.currentWorkout.seriesIndex].exercises[props.currentWorkout.exerciseIndex];
        // if (thisExercise.userWorkingMaxHistory !== undefined && thisExercise.userWorkingMaxHistory.length > 0) {
        //     // Return the most recent WM in user's history
        //     let mostRecentWMObj = thisExercise.userWorkingMaxHistory[0];
        //     return `Working max. is ${Math.round(mostRecentWMObj.working_max * 10) / 10} ${mostRecentWMObj.is_kg ? "KG" : "LBS"}`
        // } else {
        //     // No user history exists - generate avg. WM from set stats
            

        //     if (props.currentWorkout.setStats !== undefined && props.currentWorkout.setStats[selectedExerciseSeriesIndex] !== undefined && props.currentWorkout.setStats[selectedExerciseSeriesIndex].length > 0) {
        //         var avgWM = 0.0;
        //         var avgWM_count = 0;
        //         for (var i = 0; i < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length; i++) {
        //             let thisSetStats: SetStats_t[] = props.currentWorkout.setStats[selectedExerciseSeriesIndex][i];
        //             let thisExStat: SetStats_t = selectedExerciseIndex < thisSetStats.length ? thisSetStats[selectedExerciseIndex] : thisSetStats[0];
        //             avgWM += thisExStat.weight / (1.0278 - (0.0278 * thisExStat.manualEntryReps));
        //             avgWM_count += 1;
        //         }

        //         avgWM = avgWM_count === 0 ? avgWM : avgWM / avgWM_count;
        //         avgWM = Math.round(avgWM * 10) / 10;

                
        //     } else {
        //         return 'Complete first set to determine WM'
        //     }
            
        // }

        /*

        if (selectedSetIndex === -1) {


        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {
                
                let thisSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                let completedReps = thisSetStat.manualEntryReps;
                let brzycki_pwm = 1.0278 - (0.0278 * completedReps);
                let brzycki_workingMax = testWeight / brzycki_pwm;

                let workingMax = Math.round(brzycki_workingMax * 10) / 10;

                return `${workingMax}`;
            }
        }

        return '--.-';
        */
    }

    function getWorkingMaxChange() {

        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return -1; }

        if (selectedSetIndex === -1) {
            //setPwM_percentChange(0);

        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {

                if (selectedSetIndex > 0) {
                    // Calculate current PWM
                    let thisSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                    let completedReps = thisSetStat.manualEntryReps;
                    let brzycki_pwm = 1.0278 - (0.0278 * completedReps);

                    // Calculate previous PWM
                    let prev_SetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex - 1][selectedExerciseIndex];
                    let prev_completedReps = prev_SetStat.manualEntryReps;
                    let prev_brzycki_pwm = 1.0278 - (0.0278 * prev_completedReps);

                    let percentDiff = (brzycki_pwm - prev_brzycki_pwm) / prev_brzycki_pwm;
                    return Math.round(percentDiff * 1000) / 10;
                    //setPwM_percentChange(Math.round(percentDiff * 1000) / 10);
                }                
            }
        }

        return 0;
    }

    function getTonnage() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return -1; }
        if (selectedSetIndex === -1) { 
            //return; 
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length) {

                var tonnageSum = 0;
                for (var i = 0; i < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length; i++) {
                    let thisSetStats: SetStats_t[] = props.currentWorkout.setStats[selectedExerciseSeriesIndex][i];
                    let thisExStat: SetStats_t = selectedExerciseIndex < thisSetStats.length ? thisSetStats[selectedExerciseIndex] : thisSetStats[0];

                    let thisTonnage = thisExStat.manualEntryReps * thisExStat.weight;
                    tonnageSum += thisTonnage;
                }

                return Math.round(tonnageSum);
            }
        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {
                
                let thisSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                let thisTonnage = thisSetStat.manualEntryReps * thisSetStat.weight;

                return Math.round(thisTonnage);
            }
        }  

        return -1;
    }

    function getTonnageString() {
        let t = getTonnage();
        return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function getTonnageDescString() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return; }
        if (selectedSetIndex === -1) { 
            return "Total tonnage for this exercise"; 
            
        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {
                
                let thisSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                let thisTonnage = thisSetStat.manualEntryReps * thisSetStat.weight;

                return `Lifted ${thisSetStat.weight}${props.currentWorkout.isKG ? "KG" : "LBS"} at ${thisSetStat.manualEntryReps} reps`;
            }
        }  

        return 'Complete set for tonnage data'
    }

    function getPercentActive() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return; }
        if (selectedSetIndex === -1) { 
            return "--.- %"; 
            
        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {
                
                let thisSetStat: SetStats_t = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                let thisRestTime = thisSetStat.restTime;
                let thisTotalTime = thisSetStat.endUnixTime - thisSetStat.startUnixTime;

                let percentActive = (thisTotalTime - thisRestTime) / thisTotalTime;

                return `${Math.floor(percentActive * 1000) / 10} %`;
            }
        }  

        return '--.- %'
    }

    function getTonnageMaxChange() {

        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return -1; }

        if (selectedSetIndex === -1) {
            //setPwM_percentChange(0);

        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {

                if (selectedSetIndex > 0) {
                    // Calculate current PWM
                    let thisSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                    let thisTonnage = thisSetStat.manualEntryReps * thisSetStat.weight;

                    // Calculate previous PWM
                    let prevSetStat = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex - 1][selectedExerciseIndex];
                    let prevTonnage = prevSetStat.manualEntryReps * prevSetStat.weight;

                    let tonnageDelta = (thisTonnage - prevTonnage) / prevTonnage;
                    return Math.round(tonnageDelta * 1000) / 10;
                    //setPwM_percentChange(Math.round(percentDiff * 1000) / 10);
                }                
            }
        }

        return 0;
    }

    function getTempoString(type: number) {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return '-'; }

        if (selectedSetIndex === -1) {
            //setPwM_percentChange(0);

        } else {

            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {

                let thisSetStat: SetStats_t = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                if (thisSetStat.tempo === undefined) { return '-' };

                switch(type) {
                    case 0:
                        return thisSetStat.tempo.eccentric < 0 ? '-' : `${Math.round(thisSetStat.tempo.eccentric * 10) / 10}`;
                        break;
                    case 1:
                        return thisSetStat.tempo.first_pause < 0 ? '-' : `${Math.round(thisSetStat.tempo.first_pause * 10) / 10}`;
                        break;
                    case 2:
                        return thisSetStat.tempo.concentric < 0 ? '-' : `${Math.round(thisSetStat.tempo.concentric * 10) / 10}`;
                        break;
                    case 3:
                        return thisSetStat.tempo.second_pause < 0 ? '-' : `${Math.round(thisSetStat.tempo.second_pause * 10) / 10}`;
                        break;
                    default:
                        return "-";
                        break;
                }
            }
        }
        return '-'
    }

    function getTargetTempoString() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return 'No target tempo specified'; }

        if (selectedSetIndex === -1) {
            //setPwM_percentChange(0);

        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                    selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                    selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {

                let thisSetStat: SetStats_t = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                if (thisSetStat.targetTempo === undefined || thisSetStat.targetTempo.eccentric < 0 && thisSetStat.targetTempo.concentric < 0 && thisSetStat.targetTempo.first_pause < 0 && thisSetStat.targetTempo.second_pause < 0) { return 'No target tempo specified' };

                var tempoString = `${thisSetStat.targetTempo.eccentric}:${thisSetStat.targetTempo.first_pause}:${thisSetStat.targetTempo.concentric}:${thisSetStat.targetTempo.second_pause}`
                return `Target tempo was ${tempoString}`;
            }
        }

        return 'No target tempo specified'
    }

    function getTargetTempoRatioString() {
        if (props.currentWorkout === undefined || props.currentWorkout.setStats === undefined) { return 'Record tempo for C/E ratio data'; }

        if (selectedSetIndex === -1) {
            //setPwM_percentChange(0);

        } else {
            if (selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                    selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                    selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length) {

                let thisSetStat: SetStats_t = props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex][selectedExerciseIndex];
                if (thisSetStat.tempo === undefined) { return 'Record tempo for C/E ratio data' };

                var ceRatio = thisSetStat.tempo.concentric / thisSetStat.tempo.eccentric;
                return `This set's C/E ratio was ${ceRatio === 1.0 ? '1.000' : Math.round(ceRatio * 100) / 100}`;
            }
        }

        return 'Record tempo for C/E ratio data'

    }

    function getStatsExist() {
        return true;
        /*
        return selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                    selectedSetIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex].length && 
                    selectedExerciseIndex < props.currentWorkout.setStats[selectedExerciseSeriesIndex][selectedSetIndex].length;
        */
    }

    function getShowCurrentSetIndicator(seriesIndex: number, setIndex: number) {
        return seriesIndex === props.currentWorkout.seriesIndex && setIndex === props.currentWorkout.setIndex;
    }

    function getHighlightExercise(seriesIndex: number, setIndex: number, exerciseIndex: number) {
        if (seriesIndex < props.currentWorkout.seriesIndex) {
            return true;
        } else if (seriesIndex === props.currentWorkout.seriesIndex) {
            if (setIndex <= props.currentWorkout.setIndex) {
                return true;
            } else if (setIndex < props.currentWorkout.setIndex) {
                if (exerciseIndex <= props.currentWorkout.exerciseIndex) {
                    return true;
                }
            }
        }
        return false;
    }

	return (
		<div className="intra-workout-overview">
            <div className="intra-workout-header">
                <div hidden={selectedSetIndex < 0} className={getShowCurrentSetIndicator(props.selectedExerciseSeriesIndex, props.selectedSetIndex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-current" : (getHighlightExercise(props.selectedExerciseSeriesIndex, props.selectedSetIndex, props.selectedExerciseIndex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container" : "intra-workout-series-list-card-exercise-breakdown-exercise-letter-container intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-unselected")}>
                    <div hidden={!getHighlightExercise(props.selectedExerciseSeriesIndex, props.selectedSetIndex, props.selectedExerciseIndex)} className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-check">
                        <Check className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-check"/>
                    </div>
                    <div hidden={getHighlightExercise(props.selectedExerciseSeriesIndex, props.selectedSetIndex, props.selectedExerciseIndex) && !getShowCurrentSetIndicator(props.selectedExerciseSeriesIndex, props.selectedSetIndex)} className={`intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot ${getShowCurrentSetIndicator(props.selectedExerciseSeriesIndex, props.selectedSetIndex) ? "intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-current" : "intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-unselected"}`}/>
                    <h4>{props.currentWorkout.workout.exerciseSeries![props.selectedExerciseSeriesIndex].title}{props.selectedExerciseIndex + 1}-{(props.selectedSetIndex + 1) < 10 ? '0' : ''}{props.selectedSetIndex + 1}</h4>
                </div>
                <div hidden={selectedSetIndex >= 0} className="intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-small">
                    <h4>{props.currentWorkout.workout.exerciseSeries![props.selectedExerciseSeriesIndex].title}{props.selectedExerciseIndex + 1}</h4>
                </div>
                <div className="intra-workout-header-text-container">
                    <h1>{getExerciseName()}</h1>
                    <div hidden={selectedSetIndex < 0} className="intra-workout-header-text-container-set">
                        <h2>Set {props.selectedSetIndex + 1 < 10 ? '0' : ''}{props.selectedSetIndex + 1}</h2>
                    </div>
                </div>
            </div>
			<div className="intra-workout-body">
                <div className="intra-workout-section-container">
                    <div className="intra-workout-section-header-container">
                        <div className="intra-workout-section-header-icon-container">
                            <Timeline className="intra-workout-section-header-icon"/>
                        </div> 
                        <h4>Set Stats Summary</h4>
                        <div onClick={() => props.viewStats()} className="intra-workout-section-header-link-container">
                            <p>View all stats</p>
                            <ArrowForward className="intra-workout-section-header-link-icon"/>
                        </div>
                    </div>   
                    <div className="intra-workout-section-body-container">
                        <IntraWorkoutOverviewSummaryStats 
                            currentWorkout={props.currentWorkout}
                            selectedSetIndex={selectedSetIndex}
                            selectedExerciseSeriesIndex={selectedExerciseIndex}
                            selectedExerciseIndex={selectedExerciseIndex}
                            changeReps={(toReps: string) => props.changeReps(toReps)}
                            changeWeight={(toWeight: string) => props.changeWeight(toWeight)}
                            changeTempo={(toTempo: Tempo_t) => props.changeTempo(toTempo)}
                            changeRest={(toRest: string) => props.changeRest(toRest)}/>
                    </div>  
                </div>  
                <div className="intra-workout-section-container">
                    <div className="intra-workout-section-header-container">
                        <div className="intra-workout-section-header-icon-container">
                            <BarChart className="intra-workout-section-header-icon"/>
                        </div> 
                        <h4>Set Details</h4>
                    </div>   
                    <div className="intra-workout-section-body-container">
                        <div className="intra-workout-section-body-details-row-container">
                            <div className="intra-workout-section-body-details-header-col-container">
                                <p>Weight / WM</p>
                                <h3>{getPWM()}</h3>
                            </div> 
                            <div className="intra-workout-section-body-details-header-col-container">
                                <p>Tonnage</p>
                                <h3>{getTonnage() < 0 ? '--.-' : getTonnageString()} {props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" :  "LBS"}</h3>
                            </div> 
                            <div className="intra-workout-section-body-details-header-col-container">
                                <p>Average Velocity</p>
                                <h3>--.- m/s</h3>
                            </div> 
                        </div>
                        <div className="intra-workout-section-body-details-row-container">
                            <div className="intra-workout-section-body-details-content-col-container">
                                <p>Working Max</p>
                                <div className="intra-workout-section-body-details-content-col-number-container">
                                    <h4>{getWorkingMaxWeight()}</h4>
                                </div>
                            </div>
                            <div className="intra-workout-section-body-details-content-col-container">
                                <p>Tonnage Change</p>
                                <div className="intra-workout-section-body-details-content-col-number-container">
                                    <div hidden={getTonnageMaxChange() <= 0} className="intra-workout-overview-half-card-content-icon-container">
                                        <ArrowDropUp className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-up"/>
                                    </div>
                                    <div hidden={getTonnageMaxChange() >= 0} className="intra-workout-overview-half-card-content-icon-container">
                                        <ArrowDropDown className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-down"/>
                                    </div>
                                    <div hidden={getTonnageMaxChange() !== 0} className="intra-workout-overview-half-card-content-icon-container intra-workout-overview-half-card-content-icon-container-circle">
                                        <div className="intra-workout-overview-half-card-content-icon-circle"/>
                                    </div>
                                    <h4>{selectedSetIndex === 0 ? 'First set for this exercise' : (getTonnageMaxChange() === 0 ? 'No change from last set' : `${getTonnageMaxChange() > 0 ? 'Up' : 'Down'} by ${Math.abs(getTonnageMaxChange())}% from last set`)}</h4>
                                </div>
                            </div>
                        </div> 

                        <div className="intra-workout-section-body-details-row-container">
                            <div className="intra-workout-section-body-details-content-col-container">
                                <p>%WM Change</p>
                                <div className="intra-workout-section-body-details-content-col-number-container">
                                    <div hidden={getWorkingMaxChange() <= 0} className="intra-workout-overview-half-card-content-icon-container">
                                        <ArrowDropUp className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-up"/>
                                    </div>
                                    <div hidden={getWorkingMaxChange() >= 0} className="intra-workout-overview-half-card-content-icon-container">
                                        <ArrowDropDown className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-down"/>
                                    </div>
                                    <div hidden={getWorkingMaxChange() !== 0} className="intra-workout-overview-half-card-content-icon-container intra-workout-overview-half-card-content-icon-container-circle">
                                        <div className="intra-workout-overview-half-card-content-icon-circle"/>
                                    </div>
                                    <h4>{selectedSetIndex === 0 ? 'First set for this exercise' : (getWorkingMaxChange() === 0 ? 'No change from last set' : `${getWorkingMaxChange() > 0 ? 'Up' : 'Down'} by ${Math.abs(getTonnageMaxChange())}% from last set`)}</h4>
                                </div>
                            </div>
                            <div className="intra-workout-section-body-details-content-col-container">
                                <p>Active Percent</p>
                                <div className="intra-workout-section-body-details-content-col-number-container">
                                    <h4>{getPercentActive()}</h4>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>    
                <div className="intra-workout-section-container">
                    <div className="intra-workout-section-header-container">
                        <div className="intra-workout-section-header-icon-container">
                            <Accessibility className="intra-workout-section-header-icon"/>
                        </div> 
                        <h4>Form Analysis</h4>
                        <div className="intra-workout-section-header-add-container">
                            <Add className="intra-workout-section-header-add-icon"/>
                        </div>
                    </div>   
                    <div className="intra-workout-section-body-container">
                        <div className="intra-workout-section-body-empty-container">
                            <p>Add form flags to this set by pressing the + above</p>
                        </div>
                    </div>  
                </div> 
                <div className="intra-workout-section-container">
                    <div className="intra-workout-section-header-container">
                        <div className="intra-workout-section-header-icon-container">
                            <Note className="intra-workout-section-header-icon"/>
                        </div> 
                        <h4>Notes</h4>
                        <div className="intra-workout-section-header-add-container">
                            <Add className="intra-workout-section-header-add-icon"/>
                        </div>
                    </div>   
                    <div className="intra-workout-section-body-container">
                        <div className="intra-workout-section-body-empty-container">
                            <p>Add notes to this set by pressing the + above</p>
                        </div>
                    </div>  
                </div>  
            </div>
		</div>
	)
}

/*<div className="intra-workout-overview-full-card">
                <div className="intra-workout-overview-full-card-content">
                    <div className="intra-workout-overview-full-card-col-2">
                        <p>Exercise Name</p>
                        <h4>{getExerciseName()}</h4>
                    </div>
                    <div hidden={true}>{ticker}</div>
                    <div hidden={selectedSetIndex < 0} className="intra-workout-overview-full-card-col-4">
                        <p>Set Number</p>
                        <h4>{selectedExerciseSeries.title}{selectedExerciseIndex + 1} {`${selectedSetIndex + 1 < 10 ? '0' : ''}${selectedSetIndex + 1}`}</h4>
                    </div>
                    <div hidden={selectedSetIndex >= 0} className="intra-workout-overview-full-card-col-4">
                    </div>
                    <div onClick={() => toggleShowRest()} className="intra-workout-overview-full-card-col-4">
                        <p>{selectedSetIndex === -1 ? "Total " : ""}{showingRestTime ? "Rest Time" : "Working Time"}</p>
                        <h4>{getDuration()}</h4>
                    </div>
                </div>
            </div>
            <div hidden={getStatsExist()} className="intra-workout-overview-stats-empty">
                <h4>Set not complete</h4>
                <p>Completed this set to view stats</p>
            </div>
            <div hidden={!getStatsExist()} className="intra-workout-overview-card-row">
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <FitnessCenter className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header">
                        <h4>{selectedSetIndex < 0 ? 'Working Max' : 'Weight / WM'}</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content">
                        <h3>{getPWM()}</h3>
                        <p>{getWorkingMaxWeight()}</p>
                        <p>{selectedSetIndex === 0 ? 'First set for this exercise' : (getWorkingMaxChange() === 0 ? 'No change from previous set' : `${getWorkingMaxChange() > 0 ? 'Up' : 'Down'} by ${Math.abs(getWorkingMaxChange())}% from previous set`)}</p>
                        <div hidden={getWorkingMaxChange() <= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropUp className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-up"/>
                        </div>
                        <div hidden={getWorkingMaxChange() >= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropDown className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-down"/>
                        </div>
                        <div hidden={getWorkingMaxChange() !== 0} className="intra-workout-overview-half-card-content-icon-container intra-workout-overview-half-card-content-icon-container-circle">
                            <div className="intra-workout-overview-half-card-content-icon-circle"/>
                        </div>

                    </div>
                </div>
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <BarChart className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header">
                        <h4>{selectedSetIndex < 0 ? 'Total Tonnage' : 'Tonnage'}</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content">
                        <h3>{getTonnage() < 0 ? '--.-' : getTonnage()} {props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" :  "LBS"}</h3>
                        <p>{getTonnageDescString()}</p>
                        <p>{selectedSetIndex === 0 ? 'First set for this exercise' : (getTonnageMaxChange() === 0 ? 'No change from previous set' : `${getTonnageMaxChange() > 0 ? 'Up' : 'Down'} by ${Math.abs(getTonnageMaxChange())}% from previous set`)}</p>
                        <div hidden={getTonnageMaxChange() <= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropUp className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-up"/>
                        </div>
                        <div hidden={getTonnageMaxChange() >= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropDown className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-down"/>
                        </div>
                        <div hidden={getTonnageMaxChange() !== 0} className="intra-workout-overview-half-card-content-icon-container intra-workout-overview-half-card-content-icon-container-circle">
                            <div className="intra-workout-overview-half-card-content-icon-circle"/>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={!getStatsExist()} className="intra-workout-overview-card-row">
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <Timer className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header">
                        <h4>Average Tempo</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content">
                        <h3>
                            {getTempoString(0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                            {getTempoString(1)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                            {getTempoString(2)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                            {getTempoString(3)}
                        </h3>
                        <p>{getTargetTempoString()}</p>
                        <p>{getTargetTempoRatioString()}</p>
                    </div>
                </div>
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <ShowChart className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header intra-workout-overview-card-header-unavailable">
                        <h4>Average Velocity</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content intra-workout-overview-half-card-content-unavailable">
                        <h3>--.- m/s</h3>
                        <p>Eigen Nodes are required</p>
                        <p>for velocity monitoring</p>
                    </div>
                </div>
            </div>
<div className="intra-workout-overview-full-card">
                <div className="intra-workout-overview-full-card-content">
                    <div className="intra-workout-overview-full-card-col-2">
                        <p>Exercise Name</p>
                        <h4>{getExerciseName()}</h4>
                    </div>
                    <div hidden={true}>{ticker}</div>
                    <div hidden={selectedSetIndex < 0} className="intra-workout-overview-full-card-col-4">
                        <p>Set Number</p>
                        <h4>{selectedExerciseSeries.title}{selectedExerciseIndex + 1} {`${selectedSetIndex + 1 < 10 ? '0' : ''}${selectedSetIndex + 1}`}</h4>
                    </div>
                    <div hidden={selectedSetIndex >= 0} className="intra-workout-overview-full-card-col-4">
                    </div>
                    <div onClick={() => toggleShowRest()} className="intra-workout-overview-full-card-col-4">
                        <p>{selectedSetIndex === -1 ? "Total " : ""}{showingRestTime ? "Rest Time" : "Working Time"}</p>
                        <h4>{getDuration()}</h4>
                    </div>
                </div>
            </div>
            <div hidden={getStatsExist()} className="intra-workout-overview-stats-empty">
                <h4>Set not complete</h4>
                <p>Completed this set to view stats</p>
            </div>
            <div hidden={!getStatsExist()} className="intra-workout-overview-card-row">
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <FitnessCenter className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header">
                        <h4>{selectedSetIndex < 0 ? 'Working Max' : 'Weight / WM'}</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content">
                        <h3>{getPWM()}</h3>
                        <p>{getWorkingMaxWeight()}</p>
                        <p>{selectedSetIndex === 0 ? 'First set for this exercise' : (getWorkingMaxChange() === 0 ? 'No change from previous set' : `${getWorkingMaxChange() > 0 ? 'Up' : 'Down'} by ${Math.abs(getWorkingMaxChange())}% from previous set`)}</p>
                        <div hidden={getWorkingMaxChange() <= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropUp className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-up"/>
                        </div>
                        <div hidden={getWorkingMaxChange() >= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropDown className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-down"/>
                        </div>
                        <div hidden={getWorkingMaxChange() !== 0} className="intra-workout-overview-half-card-content-icon-container intra-workout-overview-half-card-content-icon-container-circle">
                            <div className="intra-workout-overview-half-card-content-icon-circle"/>
                        </div>

                    </div>
                </div>
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <BarChart className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header">
                        <h4>{selectedSetIndex < 0 ? 'Total Tonnage' : 'Tonnage'}</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content">
                        <h3>{getTonnage() < 0 ? '--.-' : getTonnage()} {props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" :  "LBS"}</h3>
                        <p>{getTonnageDescString()}</p>
                        <p>{selectedSetIndex === 0 ? 'First set for this exercise' : (getTonnageMaxChange() === 0 ? 'No change from previous set' : `${getTonnageMaxChange() > 0 ? 'Up' : 'Down'} by ${Math.abs(getTonnageMaxChange())}% from previous set`)}</p>
                        <div hidden={getTonnageMaxChange() <= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropUp className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-up"/>
                        </div>
                        <div hidden={getTonnageMaxChange() >= 0} className="intra-workout-overview-half-card-content-icon-container">
                            <ArrowDropDown className="intra-workout-overview-half-card-content-icon intra-workout-overview-half-card-content-icon-down"/>
                        </div>
                        <div hidden={getTonnageMaxChange() !== 0} className="intra-workout-overview-half-card-content-icon-container intra-workout-overview-half-card-content-icon-container-circle">
                            <div className="intra-workout-overview-half-card-content-icon-circle"/>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={!getStatsExist()} className="intra-workout-overview-card-row">
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <Timer className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header">
                        <h4>Average Tempo</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content">
                        <h3>
                            {getTempoString(0)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                            {getTempoString(1)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                            {getTempoString(2)}<span className="create-workout-exercise-list-cell-param-colon">:</span>
                            {getTempoString(3)}
                        </h3>
                        <p>{getTargetTempoString()}</p>
                        <p>{getTargetTempoRatioString()}</p>
                    </div>
                </div>
                <div className="intra-workout-overview-half-card">
                    <div className="intra-workout-overview-half-card-header-icon-container">
                        <ShowChart className="intra-workout-overview-half-card-header-icon"/>
                    </div>
                    <div className="intra-workout-overview-card-header intra-workout-overview-card-header-unavailable">
                        <h4>Average Velocity</h4>
                        <div className="intra-workout-overview-half-card-header-forward-icon-container">
                            <ArrowForward className="intra-workout-overview-half-card-header-forward-icon"/>
                        </div>
                    </div>
                    <div className="intra-workout-overview-half-card-content intra-workout-overview-half-card-content-unavailable">
                        <h3>--.- m/s</h3>
                        <p>Eigen Nodes are required</p>
                        <p>for velocity monitoring</p>
                    </div>
                </div>
            </div>







<div className="intra-workout-summary-overview-card">
                <div className="intra-workout-summary-overview-card-header-container">
                    <p>Workout overview</p>
                    <h3>{props.workout !== undefined ? props.workout.title : "For current workout"}</h3>
                    <div className="intra-workout-summary-overview-card-info-container">
                        <p>{getDescriptionString()}</p>
                        <p>{getMusclesString()}</p>
                    </div>
                    <div className="intra-workout-summary-overview-card-chart-header-container">
                        <h4>Weight and sets completed</h4>
                        <div onClick={() => props.seeAllStats()} className="intra-workout-summary-overview-card-chart-header-see-more-container">
                            <p>See all stats &rarr;</p>
                        </div>
                    </div>
                    
                    <div hidden={props.currentWorkout !== undefined ? (props.currentWorkout.setIndex + props.currentWorkout.exerciseIndex <= 1) : true} className="intra-workout-summary-overview-chart-container">
                        <Bar
                            data={dataSets}
                            options={chartOptions}
                        />
                    </div>
                    <div hidden={props.currentWorkout !== undefined ? (props.currentWorkout.setIndex + props.currentWorkout.exerciseIndex > 1) : true} className="intra-workout-summary-overview-chart-container">
                        <p>Complete 2 sets to see data</p>
                    </div>
                </div>
            </div>
            <div className="intra-workout-summary-overview-exercise-list-container">
                <div className="intra-workout-summary-overview-exercise-list-header-container">
                    <h4>Workout Progress</h4>
                    <p>Tap exercise for more information</p>
                </div>
                
                {props.workout.exercises.map((item: Exercise_t, index: number) => (
                    <ExerciseListCell
                        key={`${item.title}-${index}`}
                        currentExerciseIndex={props.currentWorkout !== undefined ? (props.currentWorkout.exerciseIndex) : 0}
                        currentSetIndex={props.currentWorkout !== undefined ? (props.currentWorkout.setIndex) : 0}
                        index={index}
                        isLastOnList={index === props.workout.exercises.length - 1}
                        exercise={item}/>
                ))}
            </div>
*/

export default IntraWorkoutOverview;