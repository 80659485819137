import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../../../Firebase';
import Mixpanel from '../../../../Mixpanel';
import axios from 'axios';
import './WorkoutPlanListPlan.css';
import './WorkoutPlanListCycleOverview.css';

import Workout_t from '../../../../Interfaces/Workout_t'
import CreatedBy_t from '../../../../Interfaces/CreatedBy_t'
import Exercise_t from '../../../../Interfaces/Exercise_t'
import ExerciseSeries_t from '../../../../Interfaces/ExerciseSeries_t'
import Folder_t from '../../../../Interfaces/Folder_t';
import Plan_t from '../../../../Interfaces/Plan_t';
import Tempo_t from '../../../../Interfaces/Tempo_t';
import Muscles_t from '../../../../Interfaces/Muscles_t';

import WorkoutPlanListPlanOverviewCardPlan from './WorkoutPlanListPlanOverviewCardPlan';
import WorkoutPlanListPlanWorkout from './WorkoutPlanListPlanWorkout';
import WorkoutPlanListPlanWorkoutCard from './WorkoutPlanListPlanWorkoutCard';
import FormTextInput from '../../../../components/Base/FormTextInput';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import Add from '@material-ui/icons/Add';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Delete from '@material-ui/icons/Delete'
import Check from '@material-ui/icons/Check';

function WorkoutPlanListPlan(props: any) {

    let initFolder = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
            isOpen: false
        };
    let initPlan: Plan_t = {
            title: "",
            description: "",
            id: "",
            createdBy_ID: "",
            created_timestamp: 0,
            workout_ids: [],
            availability: 0,
            path: "",
            repScheme: []
        };
    let initWorkout: Workout_t = {
            title: '',
            id: '',
            createdBy: {name: '', id: ''},
            duration: 0,
            imageName: '',
            targetGender: '',
            totalSets: 0,
            totalReps: 0,
            difficulty: 0
        };

    let muscles: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]
        ]

    const pageOptionTitles = ["Workouts", "Muscles"]

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [thisPlanFolder, setThisPlanFolder] = useState<Folder_t>(initFolder);
    const [workouts, setWorkouts] = useState<Workout_t[]>([]);
    const [plan, setPlan] = useState<Plan_t>(initPlan);
    const [repScheme, setRepScheme] = useState<number[]>([]);
    const [avgReps, setAvgReps] = useState(0);
    const [volume, setVolume] = useState(0);
    const [volume_sets, setVolume_sets] = useState(0);
    const [createdTimestamp, setCreatedTimestamp] = useState(-1);
    const [muscleList, setMuscleList] = useState<Muscles_t[]>([]);

    const [edittingPhaseName, setEdittingPhaseName] = useState(false);
    const [newPhaseName, setNewPhaseName] = useState("My phase");

    const [pageIndex, setPageIndex] = useState(0);

    const [showingWorkout, setShowingWorkout] = useState(false);
    const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>(initWorkout);
    const [selectedWorkoutDay, setSelectedWorkoutDay] = useState(0);
    const [removeAtIndex, setRemoveAtIndex] = useState(-1);

    const [workoutIndex_purposed, setWorkoutIndex_purposed] = useState(-1);

    const [showMenu, setShowMenu] = useState(false);

    let workoutDifficulties: string[] = ['For beginners', 'Light', 'Moderate', 'Difficult', 'Extreme'];
    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.planFolder !== undefined) {
            setThisPlanFolder(props.planFolder);
            pullPlanDetails(props.planFolder.phaseName);
            setNewPhaseName(props.planFolder.title);
            setEdittingPhaseName(false);
            setShowingWorkout(false);
            // console.log("THIS PLAN FOLDER: ", props.planFolder)
        } else {
            
            setWorkouts(workouts.filter(item => false));
            // console.log("CLEARING PLAN WORKOUTS: A");
        }
    }, [props.planFolder])

    function initialize() {
        if (props.planFolder !== undefined) {
            setThisPlanFolder(props.planFolder);
            pullPlanDetails(props.planFolder.phaseName);
        } else {
            
            setWorkouts(workouts.filter(item => false));
            // console.log("CLEARING PLAN WORKOUTS: B");
        }
    }

    function pullPlanDetails(phaseName?: string) {
        setWorkouts(workouts.filter(item => false));
        // console.log("CLEARING PLAN WORKOUTS: C");
        if (props.planFolder === undefined || props.planFolder.plan_id === undefined || props.planFolder.plan_id === "") { 
            return; 
        }

        

        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${props.planFolder.plan_id}`); // Change to database path
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setupWorkouts(data);

                // console.log("PULLED PLAN", props.planFolder.plan_id, data.path);

                let thisPlan: Plan_t = {
                    title: data.title !== undefined && data.title !== "" ? data.title : "Plan Name Not Provided",
                    description: data.description !== undefined && data.description !== "" ? data.description : "No description of this phase was provided",
                    id: props.planFolder.plan_id,
                    createdBy_ID: data.created_by,
                    created_timestamp: data.created_timestamp,
                    workout_ids: data.workouts,
                    availability: 0,
                    path: data.location_path,
                    repScheme: [],
                    phase_name: phaseName,
                    periodization_string: data.periodization_string
                };

                setNewPhaseName(thisPlan.title);

                setPlan(thisPlan);


                let createdTS = data.created_timestamp;
                setCreatedTimestamp(createdTS);
            }
        });
    }

    async function setupWorkouts(data: any) {
        var workoutListDataTemp: any[] = [];
        if (data.workouts !== null && data.workouts !== undefined && data.workouts.length > 0) {
            for (var i = 0; i < data.workouts.length; i++) {
                let thisWorkoutID = data.workouts[i];
                if (thisWorkoutID !== "") {
                    let thisWorkoutData: any = await getWorkoutInfo(thisWorkoutID);
                    thisWorkoutData["id"] = thisWorkoutID;
                    workoutListDataTemp.push(thisWorkoutData);
                } else {
                    workoutListDataTemp.push(initWorkout);
                }
                
            }
        }

        parsePersonalWorkouts(workoutListDataTemp);
    }

    async function getWorkoutInfo(workoutID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();

            let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
            thisWorkoutRef.on('value', function(snapshotWorkout) {
                if (snapshotWorkout.exists() === true) {
                    let thisWorkout = snapshotWorkout.val();
                    resolve(thisWorkout);
                }
            });
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    async function parsePersonalWorkouts(workoutList: any) {
        // console.log("--- WORKOUT LIST:", workoutList, Object.keys(workoutList));

        var temp: Workout_t[] = [];
        for (const key of Object.keys(workoutList)) {
            if (workoutList[key].id === "") {
                let newWorkout: Workout_t = {
                    title: "Rest Day",
                    description: '',
                    id: "",
                    createdBy: {name: "", id: ""},
                    exercises: [],
                    exerciseSeries: [],
                    duration: 0,
                    imageName: "",
                    targetGender: "",
                    totalSets: 0,
                    totalReps: 0,
                    difficulty: 0,
                    goals: [],
                    downloads: 0,
                    timesCompleted: 0
                };
                temp.push(newWorkout);
            } else {
                let thisWorkout = workoutList[key];

                //let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

                // Configure Exercise Series
                var seriesList_temp: ExerciseSeries_t[] = [];
                let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
                if (containsExerciseSeries === true) {
                    for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                        let thisSeriesObj = thisWorkout.exercise_series[i];
                        let thisSetLetter = getSetLetter(i);

                        var exerciseList_temp: Exercise_t[] = [];
                        if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                            for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                                let thisExerciseObj = thisSeriesObj.exercises[j];
                                let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                                exerciseList_temp.push(exTemp);
                            }
                        }

                        var seriesTemp: ExerciseSeries_t = {
                            title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                            index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                            type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                            exercises: exerciseList_temp
                        };

                        seriesList_temp.push(seriesTemp);
                    }
                } else {
                    // Workout was created "pre-series". Create a new placeholder series for each existing exercise
                    if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                        for (var i = 0; i < thisWorkout.exercises.length; i++) {
                            let thisExerciseTemp: any = thisWorkout.exercises[i];
                            let thisSetLetter = getSetLetter(i);
                            var seriesTemp: ExerciseSeries_t = {
                                title: thisSetLetter,
                                index: i,
                                type: 0,
                                exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                            };
                            seriesList_temp.push(seriesTemp);
                        }
                    }
                }

                var exerciseList: Exercise_t[] = [];
                if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

                    var reps_avg = 0.0;
                    var reps_avg_count = 0;
                    var exerciseReps: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var a: number[] = [];
                        if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                                let thisRep = thisWorkout.exercises[i].reps[j];
                                a.push(thisRep);
                                reps_avg += thisRep;
                                reps_avg_count += 1;
                            }
                        }
                        exerciseReps[thisWorkout.exercises[i].key] = a;

                        if (i === 0) {
                            setRepScheme(a);
                        }
                    }

                    setAvgReps(reps_avg / reps_avg_count);

                    var exerciseTempos: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                        if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                                let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                                let thisTempo: Tempo_t = {
                                    concentric: thisTempoObj.concentric,
                                    eccentric: thisTempoObj.eccentric,
                                    first_pause: thisTempoObj.first_pause,
                                    second_pause: thisTempoObj.second_pause
                                };
                                b.push(thisTempo);
                            }
                        }
                        exerciseTempos[thisWorkout.exercises[i].key] = b;
                    }

                    var exercisePWMs: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var c: number[] = [];
                        if (thisWorkout.exercises[i].percent_wms !== null && thisWorkout.exercises[i].percent_wms !== undefined && thisWorkout.exercises[i].percent_wms.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].percent_wms.length; j++) {
                                let thisPWM = thisWorkout.exercises[i].percent_wms[j];
                                c.push(thisPWM);
                            }
                        }
                        exercisePWMs[thisWorkout.exercises[i].key] = c;
                    }

                    var exerciseRests: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var d: number[] = [];
                        if (thisWorkout.exercises[i].rest_times !== null && thisWorkout.exercises[i].rest_times !== undefined && thisWorkout.exercises[i].rest_times.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].rest_times.length; j++) {
                                let thisRest = thisWorkout.exercises[i].rest_times[j];
                                d.push(thisRest);
                            }
                        }
                        exerciseRests[thisWorkout.exercises[i].key] = d;
                    }

                    let e: Exercise_t[] = await getExercises(thisWorkout.exercises, exerciseRests, exercisePWMs, exerciseReps, exerciseTempos);
                    exerciseList = e;


                    // push exercises into according slots in seriesList_temp
                    if (exerciseList.length > 0) {
                        for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                            let thisExercise: Exercise_t = exerciseList[ex_index];
                            let thisExercise_ID = thisExercise.id;

                            // Loop through seriesList_temp to find thisExercise's matching location
                            if (seriesList_temp.length > 0) {
                                for (var i = 0; i < seriesList_temp.length; i++) {
                                    let thisSeries = seriesList_temp[i];
                                    if (thisSeries.exercises.length > 0) {
                                        for (var j = 0; j < thisSeries.exercises.length; j++) {
                                            let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                            let thisSeriesExercise_ID = thisSeriesExercise.id;
                                            if (thisSeriesExercise_ID === thisExercise_ID) {
                                                // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                                // for the case of multiple of the same exercises in a workout
                                                if (thisSeriesExercise.title === '') {
                                                    seriesList_temp[i].exercises[j] = thisExercise;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
                let newWorkout: Workout_t = {
                    title: thisWorkout.title,
                    description: thisWorkout.description !== undefined ? thisWorkout.description: '',
                    id: thisWorkout.id,
                    createdBy: createdBy,
                    exercises: exerciseList,
                    exerciseSeries: seriesList_temp,
                    duration: Number(thisWorkout.duration),
                    imageName: thisWorkout.image_name,
                    targetGender: thisWorkout.target_gender,
                    totalSets: Number(thisWorkout.total_sets),
                    totalReps: Number(thisWorkout.total_reps),
                    difficulty: Number(thisWorkout.difficulty),
                    goals: thisWorkout.goals,
                    downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
                    timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
                };
                temp.push(newWorkout);
            }
            
        }

        var totalVol = 0;
        var totalVolSets = 0;
        for (var i = 0; i < temp.length; i++) {
            totalVol += temp[i].totalReps;
            totalVolSets += temp[i].totalSets;
        }

        setVolume(totalVol);
        setVolume_sets(totalVolSets);

        setAvgReps(totalVol / totalVolSets);
        //setWorkouts(workouts.filter(item => false));
        //setWorkouts(workouts.concat(temp));

        // console.log("SETTING WORKOUTS:", temp);

        setWorkouts(temp);
        configMusclesList(temp);
        //setAllWorkouts(allWorkouts.concat(temp));
        setIsLoading(false);
    }

    async function getExercises(exercises: any, restTimes: any[], percentWMs: any[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseRest = restTimes[thisExerciseKey];
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];
                let thisExercisePWMs = percentWMs[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            //let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            var restTimeArray: number[] = [];
                            for (var j = 0; j < thisExerciseReps.length; j++) {
                                restTimeArray.push(thisExerciseRest[j] !== undefined && thisExerciseRest[j] !== null ? Number(thisExerciseRest[j]) : -1);
                            }

                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: restTimeArray, //[thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos,
                                percent_wms: thisExercisePWMs
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }

    function configMusclesList(withWorkouts: Workout_t[]) {
        var tempList: Muscles_t[] = [];
        for (var i = 0; i < withWorkouts.length; i++) {
            let thisWorkout: Workout_t = withWorkouts[i];
            if (thisWorkout.exercises !== undefined && thisWorkout.exercises.length > 0) {
                var returnString = '';
                for (var j = 0; j < thisWorkout.exercises.length; j++) {
                    let thisExercise = thisWorkout.exercises[j];

                    if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                        for (var k = 0; k < thisExercise.muscles.length; k++) {
                            let thisMuscle = thisExercise.muscles[k];
                            if (!tempList.includes(thisMuscle)) {
                                tempList.push(thisMuscle);
                            }
                            // let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
                            // if (!returnString.includes(thisMuscleGroupString)) {
                            //     let finalString = i + j === 0 ? thisMuscleGroupString : (i === workout.exercises.length - 1 && j === thisExercise.muscles.length - 1 ? ` and ${thisMuscleGroupString}` : `, ${thisMuscleGroupString}`);
                            //     returnString += finalString;
                            // }
                        }
                    }

                }
            }
        }

        setMuscleList(tempList);

    }

    function getMusclesString(workout: Workout_t) {
        if (workout.id === "") { return ''; }

        if (workout.exercises !== undefined && workout.exercises.length > 0) {
            var returnString = '';
            for (var i = 0; i < workout.exercises.length; i++) {
                let thisExercise = workout.exercises[i];
                if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                    for (var j = 0; j < thisExercise.muscles.length; j++) {
                        let thisMuscleGroupIndex= thisExercise.muscles[j].group;
                        let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
                        if (!returnString.includes(thisMuscleGroupString)) {
                            let finalString = i + j === 0 ? thisMuscleGroupString : (i === workout.exercises.length - 1 && j === thisExercise.muscles.length - 1 ? ` and ${thisMuscleGroupString}` : `, ${thisMuscleGroupString}`);
                            returnString += finalString;
                        }
                    }
                }
            }
            return returnString;
        } else {
            return `No muscles defined`
        }
    }

    function getMuscleGroupList() {
        var groups: String[] = [];

        var returnString = '';
        for (var workout_i = 0; workout_i < workouts.length; workout_i++) {
            let workout: Workout_t = workouts[workout_i];
            if (workout.exercises !== undefined && workout.exercises.length > 0) {
                for (var i = 0; i < workout.exercises.length; i++) {
                    let thisExercise = workout.exercises[i];
                    if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                        for (var j = 0; j < thisExercise.muscles.length; j++) {
                            let thisMuscleGroupIndex= thisExercise.muscles[j].group;
                            let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
                            if (!returnString.includes(thisMuscleGroupString)) {
                                groups.push(thisMuscleGroupString);
                                let finalString = `${thisMuscleGroupString}, `;
                                returnString += finalString;
                            }
                        }
                    }
                }
            }
        }

        return groups;
    }

    function planPressed(p: Folder_t) {
        if (p.plan_id === undefined || p.plan_id === "") {
            props.addPlan(p);
        } else {
            props.planSelected(p);
        }
    }


    function getPeriodizationString() {

        if (plan.periodization_string !== undefined && plan.periodization_string !== "") {
            return plan.periodization_string;
        }

        if (avgReps === 0) { return 'None specified' }

        if (avgReps <= 8) {
            return 'Intensification';
        } else {
            return 'Accumulation';
        }
    }

    function getDifficultyString(workout: Workout_t) {
        let difficulties = ["Very easy", "Low intensity", "Mildly intense", "Moderatly intense", "Highly intense", "Extremely intense"]
        let difficultyString = difficulties[workout.difficulty];

        return difficultyString;
    }

    function getDurationString(workout: Workout_t) {
        if (workout.id === "") { return ''; }
        return `${workout.duration} mins`
    }

    function getNumberSets(workout: Workout_t) {
        if (workout.exercises === undefined) { return 0; }

        var numSets = 0;
        for (var i = 0; i < workout.exercises.length; i++) {
            let reps = workout.exercises[i].reps;
            if (reps !== undefined) {
                numSets += reps.length
            }
        }

        return numSets;
    }

    function assignToClient() {
        Mixpanel.track("Assign to client pressed",{location: "workout_plan_list_plan"});
        props.assignClientToPlan(plan);
    }

    function toggleMenuShow() {
        setShowMenu(!showMenu);
    }

    function deletePlanPressed() {
        setShowMenu(false);
        props.deletePlanPressed();
    }

    function editPlanPressed() {
        Mixpanel.track("Edit Plan pressed");
        setShowMenu(false);
        var planTemp = plan;
        planTemp.workoutObjects = workouts;
        props.editPlanPressed(planTemp);
    }

    function editFolderNamePressed() {
        setShowMenu(false);
        setEdittingPhaseName(true);
    }

    function inputChanged(folderName: string) {
        setNewPhaseName(folderName);
    }

    function saveFolderName() {
        setThisPlanFolder({...thisPlanFolder, title: newPhaseName});
        //props.updateFolderName(newFolderName);
        setEdittingPhaseName(false);

        // 1. Update name in phase referrence
        if (!props.planFolder || !props.planFolder.plan_id || props.planFolder.plan_id === "") return;
        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${props.planFolder.plan_id}/title`); // Change to database path
        ref.set(newPhaseName);

        // 2. Update name in program referrence
        if (!props.planFolder.path || props.planFolder.path === "") return;

        let thisFolderPath = props.planFolder.path;
        let thisFolderPathSplit = thisFolderPath.split("/");
        if (!thisFolderPathSplit || thisFolderPathSplit.length === 0) return;
        let thisFolderPathRecomposed = "";
        for (var i = 0; i < thisFolderPathSplit.length; i++) {
            let thisPathSplitElement = thisFolderPathSplit[i];
            if (thisPathSplitElement !== "") {
                thisFolderPathRecomposed += `/${thisPathSplitElement}`;
                thisFolderPathRecomposed += i === thisFolderPathSplit.length - 1 ? '' : '/children'
            }
            
        }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;
        if (trainerUID === undefined || trainerUID === "") return;

        let finalPath = `workout_plans/trainer_plans/${trainerUID}/folders_root${thisFolderPathRecomposed}/name`
        let trainerFoldersRef = database.ref(finalPath);
        trainerFoldersRef.set(newPhaseName)
    }

    function duplicatePlanPressed() {
        setShowMenu(false);
        var planTemp = plan;
        planTemp.workoutObjects = workouts;
        props.duplicatePlanPressed(planTemp);
    }

    function workoutSelected(w: Workout_t, i: number) {
        setEdittingPhaseName(false);
        if (w.id === "") {
            // Rest day -- needs to be built
            props.editRestDayPressed(plan, i);
        } else {
            //props.workoutSelected(w);
            setShowingWorkout(true);
            setSelectedWorkout(w);
            setSelectedWorkoutDay(i);
        }
        
    }

    function addNewWorkout() {
        var planTemp = plan;
        planTemp.workoutObjects = workouts;
        props.addNewWorkoutToPlan(planTemp);
    }

    function removeWorkout(item: Workout_t, index: number) {
        // console.log("REQUESTING TO REMOVE AT INDEX: ", index, item);
        setRemoveAtIndex(index);
        props.getUserConfirmation({
            title: `Are you sure you want to remove '${item.title}'?`,
            text: "This action cannot be undone",
            isRed: true,
            callback: confirmRemoveAtIndex,
            ctaText: "Remove",
            callbackParam: index
        });
    }

    function removeLastWorkout() {
        if (workouts.length < 1) return;
        setRemoveAtIndex(workouts.length - 1);
        props.getUserConfirmation({
            title: `Are you sure you want to remove the last workout of this Phase?`,
            text: "This action cannot be undone",
            isRed: true,
            callback: confirmRemoveAtIndex,
            ctaText: "Remove",
            callbackParam: workouts.length - 1
        });
    }
    

    function confirmRemoveAtIndex(num: number) {

        if (num < 0) { return; }

        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${props.planFolder.plan_id}/workouts`); // Change to database path
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data: (string | null)[] = snapshot.val();
                // console.log("REMOVING AT INDEX: ", num, data);
                
                if (data.length > 7) {
                    data.splice(num, 1);
                } else {
                    data[num] = "";
                }
                //data[num] = data.length > 7 ? null : "";
                // console.log("ADJUSTED LIUST: ", data);
                ref.set(data);
                setRemoveAtIndex(-1);
            }
        });
    }

    function getPath() {
        if (!props.programFolder || !props.programFolder.title || props.programFolder.title === "") return "My Workout Programs /";

        return `${ props.programFolder.title } /`
    }

    window.addEventListener('click', function(e){   
        if (document === null || document.getElementById('plan-menu') === null || document.getElementById('plan-menu-button') === null || e.target === null) { return; }
        
        if (document.getElementById('plan-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('plan-menu-button')!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    });

    window.addEventListener('keypress', function(e) {   
        // // console.log("PRESSED:", e.keyCode);
        // if ( e.keyCode === 97 ) {
        //     // 'a'
        //     assignToClient();
        // }
        
    });

    function deleteWorkoutPressed(workout: Workout_t, index: number) {
        props.getUserConfirmation({
            title: `Are you sure you want to delete '${workout.title}'?`,
            text: "Deleting a workout will permanently remove it from this Phase, but you will still have access to the workout in 'My Workouts'.",
            isRed: true,
            callback: confirmDeleteWorkout,
            ctaText: "Remove",
            callbackParam: index
        });
    }

    async function confirmDeleteWorkout(atIndex: number) {
        // console.log("DELETING: ", atIndex, workouts[atIndex]);
        setWorkouts(workouts.map((item: Workout_t, index: number) => {
            if (atIndex === index) {
                return initWorkout
            } else {
                return item;
            }
        }))

        if (!plan || !plan.id || plan.id === "" || atIndex < 0) return;

        let database = firebase.database();
        let loc = `workout_plans/plans/${plan.id}/workouts/${atIndex}`
        // console.log("UPDATE TO:", loc, atIndex)
        let ref = database.ref(loc);
        await ref.set("")
    }

    function copyWorkoutPressed(workout: Workout_t, index: number) {

        props.workoutCopied(workout);

        if (!workout || !workout.id || workout.id === "") return;

        // // console.log("COPYING:", workout.id);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let sendData = {
            workout_id: workout.id,
            copier_uid: trainerUID
        };

        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/duplicateWorkout`,
            sendData,
            { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log("COPIED:", response.data);
            let data = response.data;
        })
        .catch(e => {
            // console.log(e);
        })
    }

    async function pasteWorkout(workout: Workout_t, atIndex: number) {

        // if (props.copiedWorkout === undefined) return;
        if (!props.copiedWorkout || !props.copiedWorkout.id || props.copiedWorkout.id === "") return;
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        // 1. Create a copy
        let sendData = {
            workout_id: props.copiedWorkout.id,
            copier_uid: trainerUID
        };

        try {

            // props.updateLoadingState(true);
            let resp = await axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/duplicateWorkout`, sendData, { headers: {'Content-Type': 'application/json'} });
            if (resp && resp.data && resp.data.error === -1 && resp.data.new_workout_id !== undefined && resp.data.new_workout_id !== "") {

                let newWorkoutID = resp.data.new_workout_id;
                let newWorkoutObj = JSON.parse(JSON.stringify(props.copiedWorkout));
                newWorkoutObj.id = newWorkoutID;

                // 2. Update workouts in UI
                setWorkouts(workouts.map((item: Workout_t, index: number) => {
                    if (atIndex === index) {
                        return newWorkoutObj
                    } else {
                        return item;
                    }
                }))

                // 3. Save changes to Phase
                if (!plan || !plan.id || plan.id === "" || atIndex < 0) return;

                let database = firebase.database();
                let loc = `workout_plans/plans/${plan.id}/workouts/${atIndex}`
                // console.log("UPDATE PHASE TO:", loc, atIndex)
                let ref = database.ref(loc);
                await ref.set(newWorkoutID)

                // props.updateLoadingState(false);

            } else {
                // console.log("ERROR COPYING WORKOUT!", resp);
                // props.updateLoadingState(false);
            }
        } catch (err) {
            // console.log("ERROR Pasting WORKOUT!", err);
            // props.updateLoadingState(false);
        }

        
    }

    async function cellDropped(workout: Workout_t, index: number) {
        
        if (index !== workoutIndex_purposed) {
            // 1. If the workout is dropped in a new location, update the array
            let newArr = array_move_open(workouts, index, workoutIndex_purposed);
            setWorkouts(newArr)

            // 2. Save changes to firebase
            // 2.1 Create workout ID list
            var workoutIDListTemp: string[] = [];
            for (var i = 0; i < newArr.length; i++) {
                let thisWo = newArr[i];
                workoutIDListTemp.push(thisWo.id ?? "");
            }

            // 2.2 Push to firebase

            if (!plan || !plan.id || plan.id === "") return;
            let database = firebase.database();
            let loc = `workout_plans/plans/${plan.id}/workouts`
            // console.log("UPDATE TO:", loc, workoutIDListTemp)
            let ref = database.ref(loc);
            await ref.set(workoutIDListTemp)
        }

        setWorkoutIndex_purposed(-1);
    }

    function array_move_open(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    function cellPicked(workout: Workout_t, index: number) {

    }

    function updateDraggingPosition(workout: Workout_t, index: number, x: number, y: number) {
        if (y === 0) return;
        let phasesContainer = document.getElementById('workout-plan-content-add-container-workouts')
        if (phasesContainer !== null && phasesContainer !== undefined) {
            let upperMargin = 20;
            let workoutCardHeight = 102 + 8;

            let phasesContainer_bounds = phasesContainer.getBoundingClientRect();
            let phasesWidth = (phasesContainer_bounds.width - 36) / 3;
            let x_adj = x - phasesContainer_bounds.left + (phasesWidth / 2);
            let y_adj = y - phasesContainer_bounds.top - upperMargin;
           
            var thisWorkoutIndex = Math.floor(y_adj / workoutCardHeight);
            thisWorkoutIndex = thisWorkoutIndex < 0 ? 0 : (thisWorkoutIndex > workouts.length - 1 ? workouts.length - 1 : thisWorkoutIndex);

            setWorkoutIndex_purposed(thisWorkoutIndex);

        }
    }

    function workoutUpdated(newSeries: ExerciseSeries_t[]) {
        if (!selectedWorkout || !selectedWorkout.exerciseSeries) return;

        setWorkouts(workouts.map((item: Workout_t, index: number) => {
            if (item.id === selectedWorkout.id) {
                return { ...item, exerciseSeries: newSeries };
            } else {
                return item;
            }
        }))

    }


	return (
		<div className="workout-plan-list-plan">
            <div hidden={thisPlanFolder.plan_id !== undefined} className="workout-plan-list-plan-empty-container">
                <div className="workout-plan-list-plan-header-card-header"> 
                    <div className="workout-plan-list-cycle-overview-header-card-icon-container workout-plan-list-plan-header-card-icon-container-plan">
                        <DynamicFeed className="workout-plan-list-cycle-overview-header-card-icon workout-plan-list-plan-header-card-icon-plan"/>
                    </div>    
                    <div className="workout-plan-list-plan-header-card-text-container">
                        <h1>{thisPlanFolder.title}</h1>
                    </div> 
                </div>
                <div hidden={thisPlanFolder.plan_id !== undefined} className="workout-plan-list-plan-empty-card">
                    <div className="workout-plan-list-plan-empty-card-icon-container">
                        <img className="workout-plan-list-plan-empty-card-icon-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/building.svg`}/>
                    </div>
                    <h4>{thisPlanFolder.title} has not been built yet</h4>
                    <p>Placeholder Phases are created when you add a new Cycle, and remain as 'unbuilt' Phases until you build them. To build {thisPlanFolder.title}, click below and work through the Phase builder.</p>
                    <div onClick={() => props.addPlan(thisPlanFolder)} className="workout-plan-list-plan-empty-card-button">
                        <p>Build this Phase</p>
                    </div>
                </div>
            </div>
            
    		<div hidden={thisPlanFolder.plan_id === undefined} className="workout-plan-list-plan-header-card">
                <div className="workout-plan-list-plan-header-card-header-wrapper">
                    <div className={ `workout-plan-list-plan-header-card-header-back-container ${ showingWorkout ? 'workout-plan-list-plan-header-card-header-back-container-workout' : ''}` } onClick={ () => showingWorkout ? setShowingWorkout(false) : props.backPressed() }> 
                        <div className={ `workout-plan-list-plan-header-card-header-back-icon-container ${ showingWorkout ? 'workout-plan-list-plan-header-card-header-back-icon-container-workout' : ''}` }> 
                            <ArrowBackIos className="workout-plan-list-plan-header-card-header-back-icon"/>
                            <p>{ showingWorkout ? "Phase" : "Program" }</p>
                        </div>
                    </div>
                    <div className="workout-plan-list-plan-header-card-header"> 
                        <div className={`workout-plan-list-cycle-overview-header-card-icon-container workout-plan-list-plan-header-card-icon-container-plan ${showingWorkout ? 'workout-plan-list-plan-overview-header-card-icon-container-workout' : ''}`}>
                            {    
                                showingWorkout ?
                                <div className={ "workout-plan-list-plan-overview-header-card-icon-workout" }/>
                                :
                                <img className="workout-plan-list-cycle-overview-header-card-icon workout-plan-list-plan-header-card-icon-plan" src={`${process.env.PUBLIC_URL}/assets/images/icons/phase.png`}/>
                            }
                        </div>    
                        <div className={ `workout-plan-list-plan-header-card-text-container ${ showingWorkout ? 'workout-plan-list-plan-header-card-text-container-workout' : ''}` }>
                            <div className="workout-plan-list-plan-header-card-text-folder-name-container">
                                <p>{ getPath() }{ showingWorkout ? <span> {thisPlanFolder.title}</span> : '' }</p>
                            </div>
                            <h2>{showingWorkout ? `Day ${ selectedWorkoutDay + 1}` : `${thisPlanFolder.phaseName ?? ""}` }</h2>
                            {
                                edittingPhaseName ?
                                <div className="workout-plan-list-cycle-overview-header-card-text-container workout-plan-list-cycle-overview-header-card-text-container-editing">
                                    <FormTextInput 
                                        value={newPhaseName}
                                        placeholder="Folder name " 
                                        alert={false} 
                                        alertText=""
                                        isSearch={true}
                                        inputChanged={(e: string) => inputChanged(e)}/>
                                    <div onClick={() => saveFolderName()} className="workout-plan-list-cycle-overview-header-card-text-container-save-button">
                                        <Check className="workout-plan-list-cycle-overview-header-card-text-container-save-button-icon"/>
                                        <p>Save</p>
                                    </div>
                                </div>
                                :
                                <div className={ "workout-plan-list-plan-header-card-text-container-title" } onClick={ () => editFolderNamePressed() }>
                                    <h1>{showingWorkout ? (selectedWorkout ? selectedWorkout.title : "Unnamed Workout") : thisPlanFolder.title}</h1>
                                </div>
                            }
                            
                            
                            
                        </div> 

                        <div hidden={showingWorkout || (props.isClient !== undefined && props.isClient === true) } onClick={() => assignToClient()} className="workout-plan-list-header-button workout-plan-list-header-button-blue">
                            <AssignmentInd className="workout-plan-list-header-upper-content-container-buttons-icon"/>
                            <p>Assign Phase</p>
                        </div>
                        <div hidden={showingWorkout || (props.isClient === undefined || props.isClient === false)} onClick={() => assignToClient()} className="workout-plan-list-header-button workout-plan-list-header-button-blue">
                            <AssignmentInd className="workout-plan-list-header-upper-content-container-buttons-icon"/>
                            <p>Set as Current Program</p>
                            <div className="workout-plan-list-header-upper-content-container-buttons-hotkey-icon">A</div>
                        </div>
                        <div hidden={ showingWorkout } id="plan-menu-button" onClick={() => toggleMenuShow()} className="workout-plan-list-cycle-overview-header-card-menu-button-container workout-plan-header-card-menu-button-container">
                            <p>Options</p>
                            <MoreHoriz className="workout-plan-list-cycle-overview-header-card-menu-button-icons"/>
                        </div> 
                        <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                            <div id="plan-menu" className="workout-plan-list-cycle-overview-header-card-menu-container">
                                <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                                    <p>Phase Options</p>
                                </div>
                                <div className={ "workout-plan-list-cycle-overview-header-card-menu-header-row-divider" }></div>
                                <div onClick={() => editFolderNamePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                        <Edit className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                    </div>
                                    <p>Edit Phase Name</p>
                                </div>
                                <div onClick={() => duplicatePlanPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                        <DynamicFeed className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                    </div>
                                    <p>Duplicate Phase</p>
                                </div>
                                <div onClick={() => deletePlanPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                        <DeleteOutline className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                    </div>
                                    <p>Delete</p>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
                <div className="workout-plan-list-cycle-overview-header-card-line"/>
            </div>
            <CSSTransition in={!showingWorkout} timeout={200} classNames="slide" unmountOnExit>
                <div hidden={thisPlanFolder.plan_id === undefined} className="workout-plan-list-plan-content">
                    <div className="workout-plan-list-plan-header-card-body">
                        <div className="workout-plan-list-plan-header-card-section-title-container">
                            <h4>Phase Details</h4>
                            <p>{plan.description}</p>
                        </div>
                        <div className="workout-plan-list-plan-header-card-body-row-container">
                            <div className="workout-plan-list-plan-header-card-body-row">
                                <div className="workout-plan-list-plan-header-card-body-col">
                                    <p>Workout Count</p>
                                    <h4>{workouts.length} Workout{workouts.length === 1 ? '' : 's'}</h4>
                                </div>
                                <div className="workout-plan-list-plan-header-card-body-col">
                                    <p>Workout Volume</p>
                                    <h4>{volume} reps, {volume_sets} sets</h4>
                                </div>
                            </div>
                            <div className="workout-plan-list-plan-header-card-body-row">
                                <div className="workout-plan-list-plan-header-card-body-col">
                                    <p>Periodization</p>
                                    <h4>{getPeriodizationString()}</h4>
                                </div>
                                <div className="workout-plan-list-plan-header-card-body-col">
                                    <p>Rep Scheme</p>
                                    <div className="workout-plan-list-plan-header-card-bod-rep-scheme-container">
                                        {repScheme.map((item: number, index: number) => (
                                            <h4>{item}</h4>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ "workout-plan-list-plan-header-card-menu-container" }>
                            {
                                pageOptionTitles.map((item: string, index: number) => (
                                    <div className={ `workout-plan-list-plan-header-card-menu-item ${ pageIndex === index ? "workout-plan-list-plan-header-card-menu-item-selected" : ""}` } onClick={ () => setPageIndex(index) } key={`phase-menu-option-${item}`}>
                                        <p>{ item }</p>
                                        <div hidden={ pageIndex !== index } className={ "workout-plan-list-plan-header-card-menu-selected-bar" }></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div hidden={thisPlanFolder.plan_id === undefined || pageIndex !== 0 } className="workout-plan-list-plan-body-card">
                         
                        {/*<div className="workout-plan-list-plan-header-card-header workout-plan-list-plan-body-card-header">  
                            <div className="workout-plan-list-plan-body-card-header-text">
                                <h4>Workout Composition</h4>
                                <p>({workouts.length})</p>
                            </div>
                        </div>*/}
                        <div className="workout-plan-list-plan-body-card-content">
                            <div className="workout-plan-list-plan-body-card-header">
                                <div className={ "workout-plan-list-plan-body-card-header-icon-wrapper" }>
                                    <div className="workout-plan-list-plan-body-card-header-icon-container">
                                        <img className="workout-plan-list-plan-body-card-header-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/phase.png`}/>
                                    </div>
                                </div>
                                <div className={ "workout-plan-list-plan-body-card-header-content-wrapper" }>
                                    <div className="workout-plan-list-plan-body-card-header-text-container">
                                        <h4><span>{thisPlanFolder.phaseName ?? "Phase"}</span> Workout Composition</h4>
                                    </div>
                                    <div className={ "workout-plan-list-plan-body-card-header-phase-count-text-container" }>
                                        <p>{workouts.length} Workout{ workouts.length === 1 ? '' : 's' }</p>
                                    </div>
                                    {/*<div className={ "workout-plan-list-plan-body-card-header-buttons-container" }>
                                        <div className={ "workout-plan-list-plan-body-card-header-button workout-plan-list-plan-body-card-header-button-delete" } onClick={ () => null }>
                                            <DeleteOutline className={ "workout-plan-list-plan-body-card-header-button-icon" }/>
                                        </div>
                                        <div className={ "workout-plan-list-plan-body-card-header-button" } onClick={ () => null }>
                                            <FileCopy className={ "workout-plan-list-plan-body-card-header-button-icon" }/>
                                        </div>
                                        <div className={ "workout-plan-list-plan-body-card-header-button" } onClick={ () => null }>
                                            <Add className={ "workout-plan-list-plan-body-card-header-button-icon" }/>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="workout-plan-list-plan-body-card-content-workouts" id={ 'workout-plan-content-add-container-workouts' }>
                                { workouts.map((item: Workout_t, index: number) => (
                                    <div className="workout-plan-list-plan-body-card-row">
                                        <div style={{ zIndex: workouts.length - 1 - index }} className={ "workout-plan-list-plan-body-card-row-branch-container" }>
                                            <div className={ `workout-plan-list-plan-body-card-row-branch ${ !item || !item.id || item.id === "" ? "workout-plan-list-plan-body-card-row-branch-unselected" : "" }` }/>
                                        </div>
                                        <div style={{ zIndex: workouts.length }} className={ "workout-plan-list-plan-body-card-row-card-container" }>
                                            <WorkoutPlanListPlanWorkoutCard 
                                                workout={ item }
                                                allowDrag={ true }
                                                phase={item}
                                                index={index}
                                                maxIndex={workouts.length - 1}
                                                copiedWorkout={ props.copiedWorkout }
                                                pasteWorkout={ () => pasteWorkout(item, index) }
                                                deletePressed={ () => deleteWorkoutPressed(item, index) }
                                                copyPressed={ () => copyWorkoutPressed(item, index) }
                                                buildWorkoutPressed={ () => workoutSelected(item, index) }
                                                editWorkoutPressed={ () => workoutSelected(item, index) }
                                                purposedIndex={ workoutIndex_purposed }
                                                cellDropped={() => cellDropped(item,index)}
                                                cellPicked={() => cellPicked(item,index)}
                                                dragPosUpdated={(x: number, y: number) => updateDraggingPosition(item,index,x,y)}/>
                                        </div>
                                        
                                    </div>
                                ))}
                                <div className="assign-modal-body-add-button-container">
                                    <div onClick={() => addNewWorkout()} className="assign-modal-body-add-button">
                                        <p>+ Add another Workout</p>
                                    </div>
                                    <div hidden={workouts.length <= 7} onClick={() => removeLastWorkout()} className="assign-modal-body-add-button">
                                        <p>- Remove last Workout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div hidden={thisPlanFolder.plan_id === undefined  || pageIndex !== 1 } className="workout-plan-list-plan-body-card">
                        <div className="workout-plan-list-plan-header-card-header workout-plan-list-plan-body-card-header-noborder">  
                            <div className="workout-plan-list-plan-body-card-header-text">
                                <h4>Working Muscles</h4>
                                <p>({muscleList.length})</p>
                            </div>
                        </div>
                        <div className="workout-plan-list-plan-body-card-header-tag-container">
                            <h4>Groups</h4>
                                {
                                    getMuscleGroupList().map((item: String, index: number) => (
                                        <div className="workout-plan-list-plan-body-card-header-tag">
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        <div className="workout-plan-list-plan-body-card-content-body">
                            <div className="workout-plan-list-plan-muscle-selector-muscle-images-container">
                                {
                                    muscleList.map((item: Muscles_t, index: number) => (
                                        <div className="workout-plan-list-plan-muscle-selector-body-img-container">
                                            <img className="workout-plan-list-plan-muscle-selector-body-img" src={`${process.env.PUBLIC_URL}/assets/images/body_muscles/body-${item.group}-${item.muscle}.png`}/>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="workout-plan-list-plan-muscle-selector-empty-body-container">
                                <img className="workout-plan-list-plan-muscle-selector-empty-body-img" src={`${process.env.PUBLIC_URL}/assets/images/body_muscles/body-blank.png`}/>
                            </div>
                        </div>
                    </div>
                    <div className="workout-plan-list-plan-card-spacer"/>
                </div>
            </CSSTransition>
            <CSSTransition in={showingWorkout} timeout={200} classNames="slide" unmountOnExit>
                <div hidden={thisPlanFolder.plan_id === undefined || !showingWorkout} className="workout-plan-list-plan-content">
                    <WorkoutPlanListPlanWorkout 
                        workout={selectedWorkout}
                        day={selectedWorkoutDay}
                        backPressed={() => setShowingWorkout(false)}
                        workoutUpdated={ (es: ExerciseSeries_t[]) => workoutUpdated(es) }
                        addNewExerciseToWorkoutModalPressed={(d: any) => props.addNewExerciseToWorkoutModalPressed(d)}/>
                </div>
            </CSSTransition>
		</div>
	)
}

{ /*
    { workouts.map((item: Workout_t, index: number) => (
                                <div className="workout-plan-list-plan-body-card-row">
                                    <div onClick={() => workoutSelected(item, index)} className="workout-plan-list-plan-body-card-row-inner">
                                        <div className="workout-plan-list-plan-body-card-col workout-plan-list-plan-body-card-col-xs">
                                            <h4>DAY {index + 1 < 10 ? '0' : ''}{index + 1}</h4>
                                        </div>
                                        <div className="workout-plan-list-plan-body-card-col workout-plan-list-plan-body-card-col-large">
                                            <h4>{item.title}</h4>
                                        </div>
                                        <div className="workout-plan-list-plan-body-card-col">
                                            <h4>{getMusclesString(item)}</h4>
                                        </div>
                                        <div className="workout-plan-list-plan-body-card-col workout-plan-list-plan-body-card-col-sm">
                                            <h4>{getDurationString(item)}</h4>
                                        </div>
                                    </div>
                                    <div hidden={item.id === ""} onClick={() => removeWorkout(item, index)} className="workout-plan-list-plan-body-card-arrow-icon-container">
                                        <Delete className="workout-plan-list-plan-body-card-arrow-icon"/>
                                    </div>
                                    <div onClick={() => workoutSelected(item, index)} hidden={item.id !== ""} className="workout-plan-list-plan-body-card-arrow-icon-container workout-plan-list-plan-body-card-arrow-icon-container-add">
                                        <Add className="workout-plan-list-plan-body-card-arrow-icon"/>
                                        <p>Add a workout</p>
                                    </div>
                                </div>
                            ))}
*/}

export default WorkoutPlanListPlan;