import React, { useState, useEffect } from 'react';
import './BodyMeasurementChart.css';

import BodyMeasurement_t from '../../Interfaces/BodyMeasurement_t';

import Selector from '../Base/Selector';
import FormTextInput from '../Base/FormTextInput';

import '@material-ui/core';
import DonutLarge from '@material-ui/icons/DonutLarge';
import Accessibility from '@material-ui/icons/Accessibility';
import DirectionsRun from '@material-ui/icons/DirectionsRun';


function BodyMeasurementChart(props: any) {

    let measurementTypes = ["bodyWeight", "bodyFatPercentage", "leanMass", "height","neck", "shoulders", "chest", "arm_L", "arm_R", "forearm_L", "forearm_R", "waistAtMinimum", "waistAtBellyButton", "glutes", "thighAtHamTie_L", "thighAtHamTie_R", "thighMid_L", "thighMid_R", "calf_L", "calf_R","legLength", "torsoLength", "reachLength"];
    let measurementDescs = ["", "Body Weight", "Body Fat Percentage", "Lean Body Mass", "Height", "Neck Circumference", "Shoulders", "Chest", "Left Arm at Bicep", "Right Arm at Bicep", "Left Forearm", "Right Forearm", "Waist at Minimum", "Waist at Bellybutton", "Glutes/Hips", "Left Thigh at Glute-Ham Tie", "Right Thigh at Glute-Ham Tie", "Left Mid-thigh", "Right Mid-thigh", "Left Calf", "Right Calf",  "Leg Length", "Torso Length", "Reach Length"];
    let measurementUnits = ["_w_","%","_w_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_","_l_"];
    let measurementHelpers = [
        "Ask your Client to weigh themselves using a scale.",
        "You can use an impedance-based scale or calipers to determine body fat %.",
        "Lean mass is total mass, minus body fat mass. This is calcualted if you provided the previous measurements.",
        "Measure your Client's height from the base of their feet to the top of their head.",
        "Measure around your Client's neck, half way between their chin and the top of their trapezius muscles.",
        "Measure around your Client's shoulders as their hands are rested by their sides.",
        "Measure under your Client's armpits, around their chest at their nipples.",
        "Measure your Client's left arm, unflexed, at the belly of their bicep.",
        "Measure your Client's right arm, unflexed, at the belly of their bicep.",
        "Measure your Client's left forearm, unflexed, at the belly of their flexor muscles.",
        "Measure your Client's right forearm, unflexed, at the belly of their flexor muscles.",
        "Measure around your Client's abdomin in the region with the smallest circumference.",
        "Measure around your Client's abdomin at the point of their bellybutton.",
        "Measure around your Client's glutes/hips at the largest point in their glutes.",
        "Measure around your Client's upper left thigh, just below the bottom of their glutes.",
        "Measure around your Client's upper right thigh, just below the bottom of their glutes.",
        "Measure around your Client's mid left thigh at the belly of their quadraceps.",
        "Measure around your Client's mid right thigh at the belly of their quadraceps.",
        "Measure around your Client's mid left calf at the belly of their gastrocnemius.",
        "Measure around your Client's mid right calf at the belly of their gastrocnemius.",
        "Measure leg length from the base of your Client's feet to their hip.",
        "Measure torso length from your Client's hip to their shoudlers.",
        "Measure reach length from your Client's shoulder to the palm of their hand.",
    ]

    const [bodyMeasurement, setBodyMeasurement] = useState<BodyMeasurement_t>({});
    const [useMetric, setUseMetric] = useState(false);
    const [measurementIndex, setMeasurementIndex] = useState(0);
    const [initSet, setInitSet] = useState(false);

    const [heightError, setHeightError] = useState(false);
    const [weightError, setWeightError] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.isMetric !== undefined) {
            setUseMetric(props.isMetric);
        }

        if (props.isEditable !== undefined && props.isEditable === false) {
            setMeasurementIndex(-1);
        }

        if (props.forceMeasurement !== undefined) {
            setBodyMeasurement(props.forceMeasurement);
        }
    }

    useEffect(() => {
        if (props.isMetric !== undefined) {
            setUseMetric(props.isMetric);
        }
    }, [props.isMetric]);

    useEffect(() => {
        if (props.forceMeasurement !== undefined) {
            setBodyMeasurement(props.forceMeasurement);
        }
    }, [props.forceMeasurement]);

    useEffect(() => {
        if (props.heightError !== undefined) {
            setHeightError(props.heightError);
        }
    }, [props.heightError]);

    useEffect(() => {
        if (props.weightError !== undefined) {
            setWeightError(props.weightError);
        }
    }, [props.weightError]);

    useEffect(() => {
        if (props.measurementUpdated !== undefined) {
            props.measurementUpdated(bodyMeasurement);
        }
        
    }, [bodyMeasurement])



    function inputChanged(toString: any, forInput: string) {
        switch (forInput) {
            case 'location':
                let thisIndex = measurementDescs.indexOf(toString);
                if (thisIndex > 0 && thisIndex < measurementTypes.length) {
                    setMeasurementIndex(thisIndex - 1);
                }
                break;
            case 'measurement' :
                if (measurementIndex > -1 && measurementIndex < measurementTypes.length) {
                    let thisType = measurementTypes[measurementIndex];
                    let thisNumber = getNumber(toString);
                    if (thisNumber === -1) { return; }

                    var bmTemp: BodyMeasurement_t = {...bodyMeasurement, [thisType]: thisNumber};

                    if (measurementIndex === 0) {
                        // body weight
                        if (bodyMeasurement.bodyFatPercentage !== undefined) {
                            let leanMass = Number(thisNumber) * (1 - (Number(bodyMeasurement.bodyFatPercentage) / 100));
                            bmTemp.leanMass = Math.round(leanMass * 10) / 10;
                        } else if (bodyMeasurement.leanMass !== undefined) {
                            let bfp = (1 - (Number(bodyMeasurement.leanMass) / Number(thisNumber))) * 100;
                            bmTemp.bodyFatPercentage = Math.round(bfp * 10) / 10;
                        }
                    } else if (measurementIndex === 1) {
                        // body fat %
                        if (bodyMeasurement.bodyWeight !== undefined) {
                            let leanMass = Number(bodyMeasurement.bodyWeight) * (1 - (Number(thisNumber) / 100));
                            bmTemp.leanMass = Math.round(leanMass * 10) / 10;
                        }
                    } else if (measurementIndex === 2) {
                        // Lean mass
                        if (bodyMeasurement.bodyWeight !== undefined) {
                            let bfp = (1 - (Number(thisNumber) / Number(bodyMeasurement.bodyWeight ))) * 100;
                            bmTemp.bodyFatPercentage = Math.round(bfp * 10) / 10;
                        }
                    }

                    setBodyMeasurement(bmTemp);
                    //setBodyMeasurement({...bodyMeasurement, [thisType]: thisNumber});
                    
                }
                break;
            default:
                break;
        }
    }

    function getUnits(forIndex: number) {
        let thisUnitType = measurementUnits[forIndex];
        var returnUnit = "";

        switch (thisUnitType) {
            case '_w_':
                returnUnit = useMetric ? 'KGs' : 'LBS';
                break;
            case '_l_':
                returnUnit = useMetric ? "cm" : "in";
                break;
            default:
                returnUnit = thisUnitType;
                break;
        }
        return returnUnit;
    }

    function nextMeasurementPressed() {
        if (measurementIndex < measurementTypes.length - 1) {

            // check if last entry is a decimal - remove if it is
            let thisMeasurement = String(getMeasurement(measurementTypes[measurementIndex]));
            let measureSplit = thisMeasurement.split('');
            var endingInDecimal = false;
            if (measureSplit.length > 0) {
                let lastEntry = measureSplit[measureSplit.length - 1];
                if (lastEntry === '.') {
                    endingInDecimal = true;
                }
            }

            if (endingInDecimal === true) {
                var temp = "";
                for (var i = 0; i < measureSplit.length - 1; i++) {
                    temp += measureSplit[i];
                }

                setBodyMeasurement({...bodyMeasurement, [measurementTypes[measurementIndex]]: Number(temp)});
            }

            setMeasurementIndex(measurementIndex + 1);
        }
    }

    function getMeasurementExits(item: any) {
        let measurement: any = bodyMeasurement[item as ("bodyWeight"|"bodyFatPercentage"|"leanMass"|"height"|"legLength"|"torsoLength"|"reachLength"|"neck"|"shoulders"|"chest"|"arm_L"|"arm_R"|"forearm_L"|"forearm_R"|"waistAtMinimum"|"waistAtBellyButton"|"glutes"|"hips"|"thighAtHamTie_L"|"thighAtHamTie_R"|"thighMid_L"|"thighMid_R"|"calf_L"|"calf_R")];
        return measurement !== undefined && measurement !== -1 && measurement !== "";
    }

    function getMeasurement(item: any) {
        let measurement: any = bodyMeasurement[item as ("bodyWeight"|"bodyFatPercentage"|"leanMass"|"height"|"legLength"|"torsoLength"|"reachLength"|"neck"|"shoulders"|"chest"|"arm_L"|"arm_R"|"forearm_L"|"forearm_R"|"waistAtMinimum"|"waistAtBellyButton"|"glutes"|"hips"|"thighAtHamTie_L"|"thighAtHamTie_R"|"thighMid_L"|"thighMid_R"|"calf_L"|"calf_R")];
        return measurement === undefined || measurement === -1 ? `` : measurement;
    }

    function measurementLocationPressed(index: number) {
        if (props.isEditable !== undefined && props.isEditable === false) { return; }
        setMeasurementIndex(index);
    }

    function getNumber(str: string) {
        if (str === "") {
            return str;
        } else if(str.match(/^-?\d+$/)){
            //valid integer (positive or negative)
            return str;
        } else if (str.match(/^\d+\.+$/)) {
            return str;
        } else if(str.match(/^\d+\.\d+$/)){
            //valid float
            return str;
        }else{
            //not valid number
            return -1;
        }
    }

    function inputUpdated(e: any) {
        //// console.log(e);
        if (e === "Enter") {
            nextMeasurementPressed();
        }
    }

	return (
		<div className={props.isEditable !== undefined && props.isEditable === false ? 'body-measurement-chart-no-style' : 'body-measurement-chart'}>
			<div hidden={props.isEditable !== undefined && props.isEditable === false} className="body-measurement-chart-content-container">
                <div className="body-measurement-chart-content-header-container">
                    <h4>Record Measurements</h4>
                    <p>Select a measurement location from the drop-down below, or click on one in the image to the right.</p>
                </div>
                <div className="body-measurement-chart-content-selector-container">
                    <p>Select Measurement Location</p>
                    <Selector
                        placeholder="Select Measurement Location"
                        showTitle={true} 
                        options={measurementDescs}
                        forceSelected={measurementIndex + 1}
                        inputChanged={(e: number) => inputChanged(e, 'location')}/>
                    <h5>{measurementHelpers[measurementIndex]}</h5>
                </div>
                <div className="body-measurement-chart-content-input-outter-container">
                    <div className="body-measurement-chart-content-input-container">
                        <p>Enter a Measurement...</p>
                        <FormTextInput 
                            value={getMeasurement(measurementTypes[measurementIndex])}
                            placeholder="Measurement" 
                            alert={false} 
                            alertText=""
                            isSearch={true}
                            inputChanged={(e: string) => inputChanged(e, 'measurement')}
                            onKeyPress={(e: any) => inputUpdated(e.key)}/>
                        <div className="body-measurement-chart-content-input-units-container">
                            <p>{getUnits(measurementIndex)}</p>
                        </div>
                    </div>
                    <div onClick={() => nextMeasurementPressed()} hidden={measurementIndex >= measurementTypes.length - 1} className="body-measurement-chart-content-input-button">
                        <p>Next measurement</p>
                    </div>
                </div>
                <div hidden={!heightError && !weightError} className="body-measurement-chart-content-error-container">
                    <p>Please provide your Client's {`${weightError ? 'body weight' : ''}${weightError && heightError ? ' and ' : ''}${heightError ? 'height' : ''}`} before proceeding to the next step.</p>
                </div>
            </div>
            <div className="body-measurement-chart-body-container">
                <div className="body-measurement-chart-body-top-container">
                    <div onClick={() => measurementLocationPressed(0)} className={`body-measurement-chart-body-top-col ${measurementIndex === 0 ? 'body-measurement-chart-body-top-col-selected' : ''} ${weightError === true ? 'body-measurement-chart-body-top-col-error' : ''}`}>
                        <div className="body-measurement-chart-body-top-col-icon-container">
                            <Accessibility className="body-measurement-chart-body-top-col-icon"/>
                        </div>
                        <div className="body-measurement-chart-body-top-col-text-container">
                            <p>Body Weight</p>
                            <h4>{getMeasurement('bodyWeight') === '' ? `--.- ${getUnits(0)}` : `${getMeasurement('bodyWeight')} ${getUnits(0)}`}</h4>
                        </div>
                    </div>
                    <div onClick={() => measurementLocationPressed(1)} className={`body-measurement-chart-body-top-col ${measurementIndex === 1 ? 'body-measurement-chart-body-top-col-selected' : ''}`}>
                        <div className="body-measurement-chart-body-top-col-icon-container">
                            <DonutLarge className="body-measurement-chart-body-top-col-icon"/>
                        </div>
                        <div className="body-measurement-chart-body-top-col-text-container">
                            <p>Body Fat %</p>
                            <h4>{getMeasurement('bodyFatPercentage') === '' ? `--.- ${getUnits(1)}` : `${getMeasurement('bodyFatPercentage')} ${getUnits(1)}`}</h4>
                        </div>
                    </div>
                    <div onClick={() => measurementLocationPressed(2)} className={`body-measurement-chart-body-top-col ${measurementIndex === 2 ? 'body-measurement-chart-body-top-col-selected' : ''}`}>
                        <div className="body-measurement-chart-body-top-col-icon-container">
                            <DirectionsRun className="body-measurement-chart-body-top-col-icon"/>
                        </div>
                        <div className="body-measurement-chart-body-top-col-text-container">
                            <p>Lean Mass</p>
                            <h4>{getMeasurement('leanMass') === '' ? `--.- ${getUnits(2)}` : `${getMeasurement('leanMass')} ${getUnits(2)}`}</h4>
                        </div>
                    </div>
                </div>
                <div className="body-measurement-chart-body-container-wrapper">
                    <div className="body-measurement-chart-body-overlay-container">
                        <div className="body-measurement-chart-body-overlay-container-inner">
                            {
                                measurementTypes.map((item: string, index: number) => (
                                    <div onClick={() => measurementLocationPressed(index)} className={`body-measurement-chart-body-overlay-outter body-measurement-chart-body-overlay-outter-${item}`}>
                                        <div className={`body-measurement-chart-body-overlay-inner body-measurement-chart-body-overlay-inner-${item} ${getMeasurementExits(item) ? 'body-measurement-chart-body-overlay-inner-completed' : ''} ${index === measurementIndex ? 'body-measurement-chart-body-overlay-inner-selected' : ''} ${index === 3 && heightError === true ? 'body-measurement-chart-body-overlay-inner-error' : ''}`}>
                                            <div className={`body-measurement-chart-body-overlay-line-long body-measurement-chart-body-overlay-line-long-${item}`}/>
                                            <div className={`body-measurement-chart-body-overlay-line-a body-measurement-chart-body-overlay-line-a-${item}`}/>
                                            <div className={`body-measurement-chart-body-overlay-line-b body-measurement-chart-body-overlay-line-b-${item}`}/>
                                            <div className={`body-measurement-chart-body-overlay-measurement body-measurement-chart-body-overlay-measurement-${item}`}>
                                                <p>{getMeasurement(item) === '' ? `--.- ${getUnits(index)}` : `${getMeasurement(item)} ${getUnits(index)}`}</p>
                                            </div>
                                        </div>    
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="body-measurement-chart-body-image-container">
                        <img className="body-measurement-chart-body-image" src={`${process.env.PUBLIC_URL}/assets/images/body/empty.png`}/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default BodyMeasurementChart;