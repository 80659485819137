import React, { useState, useEffect } from 'react';
import {Line, Scatter} from 'react-chartjs-2';
import './CurrentExerciseModalChart.css';

import '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';


/*
    props = {
        updateStatsToDays(toSelectedDaysIndex: number),
        selectedDayOptions: number[],
        units: string[],
        statNames: string[],
        chartType: string,  // can be 'line', 'scatter'
        stats: number[][]
    }
*/


function CurrentExerciseModalChart(props: any) {

    //let selectedDaysNumbers = [7,14,30,180,500];
    let initDataSets = {
        labels:['1','2','3','4','5','6','7','8','9','10'],
        datasets: [
            {
                label: 'Stats',
                backgroundColor: 'rgba(73,89,193,0.15)',
                borderColor: `rgba(73,89,193,1.0)`,
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: [120,100,80,120,140,60,70,110,120,120]
            },
            {
                label: 'Stats_2',
                backgroundColor: 'rgba(217,46,46,0.15)',
                borderColor: `rgba(217,46,46,1.0)`,
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: [140,120,120,60,70,120,100,80,120,100]
            },
            {
                label: 'Stats_3',
                backgroundColor: 'rgba(217,46,217,0.15)',
                borderColor: `rgba(217,46,217,1.0)`,
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: [140,120,120,60,70,120,100,80,120,100]
            }
        ]
    };
    var chartOptions_line: any = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0, // general animation time
        },
        "tooltips": {
            "enabled": true,
            "mode": "index",
            "intersect": true,
            "bodyFontSize": 10
        },
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:10
        },layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        scales:{
            yAxes: [{
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#6C6C6C',
                    fontSize:10,
                    beginAtZero: true,
                    // max: 1.25,
                    // min: -1.25,
                    stepSize: props.stepSize !== undefined ? props.stepSize : 20,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` ${props.units[0]}`;
                    }
                }
            }],
            xAxes: [{
                display: false,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#9DA0AD',
                    fontSize:8,
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false
        }
    };

    var chartOptions_scatter: any = {
        maintainAspectRatio: false,
        responsive: true,
        "tooltips": {
            "enabled": true,
            "mode": "index",
            "intersect": true,
            "bodyFontSize": 14
        },
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },layout: {
            padding: {
                left: 0,
                right: 12,
                top: 0,
                bottom: 4
            }
        },
        scales:{
            yAxes: [{
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    fontSize: 8,
                    beginAtZero: true,
                    stepSize: 20,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` LBS`;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    fontSize: 8,
                    beginAtZero: true,
                    stepSize: 2
                }
            }]
        },
        legend:{
            display:false
        }
    };

    const [initSet, setInitSet] = useState(false);
    const [dataSets, setDataSets] = useState(initDataSets);
    const [selectedDaysIndex, setSelectedDaysIndex] = useState(0);
    const [numStats, setNumStats] = useState(1);
    const [averageStat_a, setAverageStat_a] = useState(0);
    const [averageStat_b, setAverageStat_b] = useState(0);
    const [loading, setLoading] = useState(true);

    const [readCount, setReadCount] = useState(0);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.stats !== undefined) {
            // console.log('STATS:', props.stats);
            setNumStats(props.stats.length);

            if (props.stats.length > 0) {
                if (props.chartType === 'line') {
                    processLineChartData()
                } else if (props.chartType === 'scatter') {
                    processScatterChartData();
                }
            }
        }
        if (props.readCount !== undefined) {
            setReadCount(props.readCount);
        }
    }, [props.stats, props.readCount]);

    useEffect(() => {
        if (props.loading !== undefined) {
            setLoading(props.loading);
        }
    }, [props.loading]);

    // useEffect(() => {
    //     if (props.selectedDaysIndex !== undefined) {
    //         setSelectedDaysIndex(props.selectedDaysIndex);
    //     }
    // }, [props.selectedDaysIndex]);


    function processScatterChartData() {

        if (props.stats === undefined || props.stats.length === 0 || props.stats[0] === undefined || props.stats[0].length === 0) {
            setNumStats(0);
            return;
        }

        var dataSetsTemp = [];
        var labelsTemp = [];

        let thisDataSet_A = {
                label: `Stats_scatter`,
                backgroundColor: `rgba(73,89,193,0.15)`,
                borderColor: `rgba(73,89,193,1.0)`,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'scatter',
                data: props.stats[0] === undefined ? [] : props.stats[0]
            };
        let thisDataSet_B = {
                label: `Stats_scatter`,
                backgroundColor: `rgba(73,89,193,0.15)`,
                borderColor: `rgba(73,89,193,1.0)`,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'scatter',
                data: props.stats[1] === undefined ? [] : props.stats[1]
            };
        let thisDataSet_C = {
                label: `Stats_scatter`,
                backgroundColor: `rgba(73,89,193,0.15)`,
                borderColor: `rgba(73,89,193,1.0)`,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'scatter',
                data: props.stats[8] === undefined ? [] : props.stats[8]
            };
        //// console.log(thisDataSet);

        dataSetsTemp.push(thisDataSet_A);
        setNumStats(1);
        setDataSets({datasets: [thisDataSet_A], labels: []});

        // calculate avg weight / rep
        var avgWeight = 0.0;
        var avgReps = 0;
        for (var i = 0; i < props.stats[0].length; i++) {
            let thisStat = props.stats[0][i];
            avgWeight += thisStat.y;
            avgReps += thisStat.x;
        }

        avgWeight /= props.stats[0].length === 0 ? 1 : props.stats[0].length;
        avgReps /= props.stats[0].length === 0 ? 1 : props.stats[0].length;

        let avgWeightPerRep = avgWeight / avgReps;
        setAverageStat_a(avgWeightPerRep);
    }


    function processLineChartData_old() {
        let chartLineColours = [`73,89,193`,`217,46,46`,`46,217,46`,`217,46,217`,`46,217,46`];
        let statsMaxIndex = props.stats.length > 5 ? 5 : props.stats.length;    // Restrict max number of data sets to 5

        var maxStatsLength = 0;
        var dataSetsTemp = [];
        var labelsTemp = [];

        for (var i = 0; i < statsMaxIndex; i++) {
            var thisStat = props.stats[i];

            if (props.units[i] === '%') {
                var statTemp = [];
                for (var j = 0; j < thisStat.length; j++) {
                    statTemp.push(thisStat[j] * 100);
                }
                thisStat = statTemp;
            }
            

            let thisDataSet = {
                label: `Stats_${i}`,
                backgroundColor: `rgba(${chartLineColours[i]},0.15)`,
                borderColor: `rgba(${chartLineColours[i]},1.0)`,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: thisStat
            }

            dataSetsTemp.push(thisDataSet);


            // Calculate Average
            if (i < 2) {
                var avgStat = 0;

                var maxIndex = thisStat.length;
                for (var j = 0; j < maxIndex; j++) {
                    let statVal = thisStat[j] !== undefined ? thisStat[j] : 0.0;

                    avgStat += Number(statVal);
                }

                avgStat /= maxIndex === 0 ? 1 : maxIndex;

                if (i === 0) {
                    setAverageStat_a(avgStat);
                } else if (i === 1) {
                    setAverageStat_b(avgStat);
                }
            }

            if (thisStat.length > maxStatsLength) {
                maxStatsLength = thisStat.length;
            }
        }

        for (var i = 0; i < maxStatsLength; i++) {
            labelsTemp.push(`${i}`);
        }

        if (maxStatsLength === 0) {
            setNumStats(0);
        }

        let finalDataSet = {
            labels: labelsTemp,
            datasets: dataSetsTemp
        };
        setDataSets(finalDataSet);
    }
    
    function processLineChartData() {
        let chartLineColours = [`73,89,193`,`217,46,46`,`46,217,46`,`217,46,217`,`46,217,46`,`217,46,46`,`46,217,46`,`217,46,217`,`46,217,46`];
        let statsMaxIndex = props.stats.length > 9 ? 9 : props.stats.length;    // Restrict max number of data sets to 9
        //// console.log("processLineChartData: ", props.stats);
        var maxStatsLength = 0;
        var dataSetsTemp = [];
        var labelsTemp = [];

        for (var i = 0; i < statsMaxIndex; i++) {
            var thisStat = props.stats[i];

            if (props.units[i] === '%') {
                var statTemp = [];
                for (var j = 0; j < thisStat.length; j++) {
                    statTemp.push(thisStat[j] * 100);
                }
                thisStat = statTemp;
            }
            

            let thisDataSet = {
                label: `Stats_${i}`,
                backgroundColor: `rgba(${chartLineColours[i]},0.15)`,
                borderColor: `rgba(${chartLineColours[i]},1.0)`,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: thisStat
            }

            dataSetsTemp.push(thisDataSet);


            // Calculate Average
            if (i < 2) {
                var avgStat = 0;

                var maxIndex = thisStat.length;
                for (var j = 0; j < maxIndex; j++) {
                    let statVal = thisStat[j] !== undefined ? thisStat[j] : 0.0;

                    avgStat += Number(statVal);
                }

                avgStat /= maxIndex === 0 ? 1 : maxIndex;

                if (i === 0) {
                    setAverageStat_a(avgStat);
                } else if (i === 1) {
                    setAverageStat_b(avgStat);
                }
            }

            if (thisStat.length > maxStatsLength) {
                maxStatsLength = thisStat.length;
            }
        }

        for (var i = 0; i < maxStatsLength; i++) {
            labelsTemp.push(`${i}`);
        }

        if (maxStatsLength === 0) {
            setNumStats(0);
        }

        let finalDataSet = {
            labels: labelsTemp,
            datasets: dataSetsTemp
        };
        setDataSets(finalDataSet);
    }

    function updateShowingDaysIndex(toIndex: number) {
        setSelectedDaysIndex(toIndex);
        props.updateStatsToDays(toIndex);
    }

    function getStringFormattedInt(num: number) {
        let flooredNum = Math.floor(num);
        let flooredNum_thousand = Math.floor(flooredNum % 1000);
        return flooredNum < 10 ? `0${flooredNum}` : (flooredNum > 1000 ? `${Math.floor(flooredNum / 1000)},${flooredNum_thousand < 100 ? `0${flooredNum_thousand}` : (flooredNum_thousand < 10 ? `00${flooredNum_thousand}` : flooredNum_thousand)}` : flooredNum);
    }

	return (
		<div className="current-exercise-modal-chart-graph">
            <div hidden={loading} className="current-exercise-modal-chart-content-container">
                <div hidden={numStats === 0} className="current-exercise-modal-chart-content-header-container">
                    <div className="current-exercise-modal-chart-content-header-content">
                        <div className="current-exercise-modal-chart-content-header-stat-container">
                            <div className="current-exercise-modal-chart-content-header-stat-circle-blue"/>
                            <h2>{getStringFormattedInt(averageStat_a)}</h2>
                            <p>{props.units !== undefined && props.units.length >= 1 ? props.units[0] : ''}</p>
                        </div>
                        <p>{props.statNames !== undefined && props.statNames.length >= 1 ? props.statNames[0] : 'Undefined stat'}</p>
                    </div>
                    <div hidden={numStats < 2} className="current-exercise-modal-chart-content-header-content">
                        <div className="current-exercise-modal-chart-content-header-stat-container">
                            <div className="current-exercise-modal-chart-content-header-stat-circle-red"/>
                            <h2>{getStringFormattedInt(averageStat_b)}</h2>
                            <p>{props.units !== undefined && props.units.length >= 2 ? props.units[1] : ''}</p>
                        </div>
                        <p>{props.statNames !== undefined && props.statNames.length >= 2 ? props.statNames[1] : 'Undefined stat'}</p>
                    </div>
                </div>
                <div hidden={numStats !== 0} className="current-exercise-modal-chart-graph-chart-no-data-container">
                    <h3>Not enough data</h3>
                    <p>Complete at least 1 rep to view data here</p>
                </div>
                <div hidden={numStats === 0 || (props.chartType !== undefined && props.chartType !== 'line')} className="current-exercise-modal-chart-graph-chart-container">
                    <Line
                        data={dataSets}
                        options={chartOptions_line}
                    />
                </div>
                <div hidden={numStats === 0 || (props.chartType !== undefined && props.chartType !== 'scatter')} className="current-exercise-modal-chart-graph-chart-container">
                    <Scatter
                        data={dataSets}
                        options={chartOptions_scatter}
                    />
                </div>
            </div>
            <div hidden={!loading} className="current-exercise-modal-chart-content-container">
                <div className="current-exercise-modal-chart-graph-chart-no-data-container">
                    <h3>Loading...</h3>
                    <p>Retrieving your data. This should only take a second.</p>
                </div>
            </div>
        </div>
	)
}

/*
<div hidden={true} className="current-exercise-modal-chart-header-container-b">
                { props.selectedDayOptions.map((item: number, index: number) => (
                    <div onClick={() => updateShowingDaysIndex(index)} className={`current-exercise-modal-chart-header-button-container${selectedDaysIndex === index ? '-selected' : ''}`}>
                        <p>{item} day</p>
                    </div>
                 ))}
            </div>
*/

export default CurrentExerciseModalChart;
