import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import './LogIn.css';
import firebase from '../Firebase';
import Mixpanel from '../Mixpanel';
import TextInput from '../components/Base/TextInput';
import StandardButton from '../components/Base/StandardButton';




function LogIn(props: any) {

  let history = useHistory();
  let location = useLocation();
  // var email = '';
  // var password = '';

  const [initSet, setInitSet] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorName, setErrorName] = useState("");
  const [error_email, setError_email] = useState(false);
  const [error_password, setError_password] = useState(false);

  const [showLogin, setShowLogin] = useState(true);
  const [willRegister, setWillRegister] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [willResetPassword, setWillResetPassword] = useState(false);
  const [resetPWEmailSent, setResetPWEmailSent] = useState(false);

  // firebase.auth().signOut().then(function() {
  //   // Sign-out successful.
  //   // console.log('User successfully logged out');
  // }).catch(function(error) {
  //   // An error happened.
  //   // console.log(`Logout error occured: ${error}`)
  // });


  if (!initSet) {
    firebase.auth().onAuthStateChanged(function(user: any) {

        // console.log(`HERE: ${location.pathname}`);
        //if (willRegister === true) { return; }

        if (user) {
            // User is signed in.
            var displayName = user.displayName;
            var email = user.email;
            var emailVerified = user.emailVerified;
            var photoURL = user.photoURL;
            var isAnonymous = user.isAnonymous;
            var uid = user.uid;
            var providerData = user.providerData;

            if (displayName === null && willRegister === true) { 
                // console.log('USER has no display name - will exit');
                return;
            }

            // Add user's UID to mixpanel for user-specific analytics
            Mixpanel.identify(uid);
            Mixpanel.people.set({'Most recent login': new Date()});

            // console.log(`User ${displayName} Signed in with email ${email}.`)
            history.push('/dashboard');
            } else {
            // User is signed out.
            setShowLogin(true);
            // console.log('User has not signed in, or recently signed out.');
            //history.push('/');
        }
    });
    setInitSet(true);
  }

  

    function registerPressed() {
        setWillRegister(true);
        history.push('register/name');
    }

    function loginPressed(buttonName: string) {

        let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        let passedEmailRegEx = emailRegEx.test(email);

        if (!passedEmailRegEx) {
            setError_email(true);
            setErrorName("Please enter a valid email address");
            return;
        }

        setErrorName("")
        // console.log(`LOGING IN: ${0}`);
        setWillRegister(false);
        setIsLoading(true);



        firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // console.log(`ERROR LOGGIN IN ${errorCode}: ${errorMessage}`);
            setIsLoading(false);
            setErrorName(errorMessage);
        });
  }

  function textInputChanged(text: string, inputIndex: number) {
    if (inputIndex === 0) {
      setEmail(text);
    } else if (inputIndex === 1) {
      setPassword(text);
    }
    
  }

  function getPasswordValidationState(forType: string) {

        let letters = password.split('');

        var chars_valid = letters.length >= 6;
        var num_valid = /\d/.test(password);
        var cap_valid = /[A-Z]/.test(password);

        switch (forType) {
            case 'chars':
                return chars_valid;
                break;
            case 'num':
                return num_valid;
                break;
            case 'cap':
                return cap_valid;
                break;
            case "all":
                return chars_valid &&  num_valid && cap_valid;
                break;
            default:
                break;
        }
    }

    function resetPasswordBegin() {
        setWillResetPassword(true);
    }

    function resetPasswordPressed() {
        let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        let passedEmailRegEx = emailRegEx.test(email);

        if (!passedEmailRegEx) {
            setError_email(true);
            setErrorName("Please enter a valid email address");
            return;
        }

        setIsLoading(true);

        firebase.auth().sendPasswordResetEmail(email)
        .then((response) => {
            
        })

        setTimeout(() => {
            setWillResetPassword(false);
            setIsLoading(false);
            setResetPWEmailSent(true);
        }, 800);
    }

    function resetPWDone() {
        setEmail("");
        setResetPWEmailSent(false);
        setIsLoading(false);
    }

    return (
    <div className="login-container">
        <div className="login-background-container">
            <img src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/sign_in_bg.jpg`} className="login-background-image"/>
        </div>
        <div className="login-logo-container">
            <p>eigen<span>fitness</span></p>
        </div>
        <div className="login-info-block" hidden={!showLogin}>
            <div className="login-info-block-header">
                <h1>Eigen Coaching Partner Sign In</h1>
                <p>Enter your Eigen Coaching Partner account information below to sign in. If you haven't created an account yet, press "Become a Coaching Partner" to sign up today.</p>
            </div>
            <div hidden={isLoading || willResetPassword || resetPWEmailSent} className="login-info-block-input-container">
                <TextInput placeholder="Email Address" input="" inputChanged={(e: string) => textInputChanged(e, 0)}></TextInput>
                <TextInput placeholder="Password" input="" hideText={true} inputChanged={(e: string) => textInputChanged(e, 1)}></TextInput>
                <div className="login-error-container" hidden={errorName == ""}>
                    <p>{errorName}</p>
                </div>
                <StandardButton callBack={(e: string) => loginPressed(e)} buttonText="Sign In"></StandardButton>
            </div>
            <div hidden={isLoading || !willResetPassword || resetPWEmailSent} className="login-info-block-input-container">
                <TextInput placeholder="Email Address" input="" inputChanged={(e: string) => textInputChanged(e, 0)}></TextInput>
                <div className="login-error-container" hidden={errorName == ""}>
                    <p>{errorName}</p>
                </div>
                <StandardButton callBack={(e: string) => resetPasswordPressed()} buttonText="Send Reset Link"></StandardButton>
            </div>
            <div hidden={isLoading || willResetPassword || !resetPWEmailSent} className="login-info-block-input-container">
                <div className="login-info-block-input-container login-info-block-input-container-lg">
                    <h4>Email Sent!</h4>
                    <p>Check your inbox for a reset password link. Please note that the sender is <span>noreply@movement-tracker-457bc.firebaseapp.com</span>.<br/><br/>If you don't see an email in your inbox, be sure to check your spam! You can also reach out to us at <a href="mailto: hey@eigenfitness.com">hey@eigenfitness.com</a>.</p>
                </div>
                <StandardButton callBack={(e: string) => resetPWDone()} buttonText="Done"></StandardButton>
            </div>
            <div hidden={!isLoading} className="login-info-block-input-container">
                <div className="login-loading-spinner-container">
                    <div className="login-loading-spinner"/>
                    <p>Loading...</p>
                </div>
            </div>
            <div hidden={isLoading || willResetPassword || resetPWEmailSent} className="register-container">
                <p>Need an account? <a onClick={e => registerPressed()}>Become a Coaching Partner today</a></p>
            </div>
            <div hidden={isLoading || willResetPassword || resetPWEmailSent} className="register-container reset-password-button-container">
                <a onClick={e => resetPasswordBegin()}>I forgot my password</a>
            </div>
        </div>
        <div className="login-copyright-container">
            <p>Built in Vancouver, BC Canada. Copyright ©2021 Eigen Fitness, all rights reserved</p>
        </div>
    </div>
    )
}

export default LogIn;