import axios from 'axios';
import firebase from 'firebase';

const API_ENDPOINT = "getChatPreview";

const getChatPreview = async (trainerUID: string | undefined)=> {
    return new Promise<any>( async (resolve, reject) => {
        try {
            console.log("getChatPreview | trainerUID:", trainerUID)
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { reject(); return; }
            let trainerUIDNew = currentUser.uid;

            const params = {
                trainer_id: trainerUID ?? trainerUIDNew,
            }

            let response = await axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/${API_ENDPOINT}`,
                                            params, 
                                            { headers: {'Content-Type': 'application/json'} })
                
            console.log("RESP:", response.data);
            resolve(response.data)
        }
        catch (err){
            console.log("getChatPreview: ERROR!", err);
            reject(err)
        }
    })
}

export default getChatPreview
