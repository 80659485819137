
import React, { useState, useEffect } from 'react';
import './ExerciseSummaryCard.css';

import ScoringMetricRow from './ScoringMetricRow';
import ExerciseSummaryRow from './ExerciseSummaryRow';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function ExerciseSummaryCard(props: any) {

    const [exerciseList, setExerciseList] = useState<any[]>([]);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.exerciseList) setExerciseList(props.exerciseList);
    }, [props.exerciseList])

    const initialize = () => {
        
    }

    const getVolumePercentage = () => {
    	if (!props.totalVolume || props.totalVolume === 0 || !props.exerciseList || props.exerciseList.length === 0) return 0;

    	var thisVol = 0;
    	for (var i = 0; i < props.exerciseList.length; i++) {
    		let thisExercise = props.exerciseList[i];
    		if (thisExercise && thisExercise.sets && thisExercise.sets.length > 0) {
    			for (var j = 0; j < thisExercise.sets.length; j++) {
    				let thisSet = thisExercise.sets[j];
    				if (thisSet && thisSet.is_complete !== undefined && thisSet.is_complete === true) {
    					thisVol += thisSet.reps_completed ?? 0;
    				}
    			}
    		}
    	}

    	return Math.floor(thisVol / props.totalVolume * 1000) / 10
    }

	return (
		<div className={ "exercise-summary-card" }>
			<div className={ "exercise-summary-card-header" }>
				<div className={ "scoring-metric-card-header-icon-container" }>
                    <GraphicEq className={ "scoring-metric-card-header-icon" }/>
                </div>
                <h4>Exercise Summary</h4>
                <div className={ "goal-card-header-more-button" } onClick={ () => props.openSetsPressed() } hidden={ props.hideShowMore !== undefined && props.hideShowMore === true }>
                    <p>See more</p>
                </div>
            </div>
            {
            	exerciseList.length === 0 ?
            	<div className={ "exercise-summary-card-body-empty-container" }>
            		<p>No exercises completed</p>
            	</div>
            	:
            	<div className={ "exercise-summary-card-body-container" }>
	                <div className={ "exercise-summary-card-body-header" }>
	                    <div className={ "exercise-summary-card-body-col-header-col exercise-summary-card-body-col exercise-summary-card-body-col-name" }>
	                        <p>Exercise Name</p>
	                    </div>
	                    <div className={ "exercise-summary-card-body-col-header-col exercise-summary-card-body-col exercise-summary-card-body-col-measurement" }>
	                        <p>One Rep Max</p>
	                    </div>
	                    <div className={ "exercise-summary-card-body-col-header-col exercise-summary-card-body-col exercise-summary-card-body-col-datapoints" }>
	                        <p>Completed Volume</p>
	                    </div>
	                    <div className={ "exercise-summary-card-body-col-header-col exercise-summary-card-body-col exercise-summary-card-body-col-score" }>
	                        <p>Set Rest Time</p>
	                    </div>
	                </div>
	                <div className={ "exercise-summary-card-body-content" }>
	                    {
	                        exerciseList.map((item: any, index: number) => (
	                            <ExerciseSummaryRow 
	                            	exercise={ item }/>
	                        ))
	                    }
	                </div>
	            </div>
            }
            
            <div className={ "exercise-summary-card-selector-container" }>
                <div className={ "exercise-summary-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default ExerciseSummaryCard;