import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';
import './NewAppointmentModal.css';
import './NewWorkoutModal.css';
import './ConfirmModal.css';
import './NewElementModal.css';

import Client_t from '../../Interfaces/Client_t';
import ScheduleDay_t from '../../Interfaces/ScheduleDay_t';

import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';
import AssignModalClientCell from '../Clients/AssignModalClientCell';
import CalendarDayTimeline from '../Calendar/CalendarDayTimeline';
import Selector from '../Base/Selector';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

function NewAppointmentModal(props: any) {

    let durationStepSize = 15;

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let wkDayStrings = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];
    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingClientPlan, setIsLoadingClientPlan] = useState(false);
    const [isLoadingTrainerSchedule, setIsLoadingTrainerSchedule] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [calendarDateTimestamp, setCalendarDateTimestamp] = useState(-1);

    const [searchString, setSearchString] = useState("");
    const [showClientList, setShowClientList] = useState(false);
    const [clientNameFocus, setClientNameFocus] = useState(false);
    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
    const [imgUrl, setImgUrl] = useState('');
    const [clients, setClients] = useState<Client_t[]>([]);
    const [allClients, setAllClients] = useState<Client_t[]>([]);

    const [day_startTime, setDay_startTime] = useState(480);
    const [day_endTime, setDay_endTime] = useState(1080);

    const [selectedDuration, setSelectedDuration] = useState<number>(durationStepSize);
    const [selectedStartTime, setSelectedStartTime] = useState<number>(0);

    const [avaliableDurations, setAvaliableDurations] = useState<string[]>([]);
    const [avaliableStartingTimes, setAvaliableStartingTimes] = useState<string[]>([]);
    const [avaliableEndingTimes, setAvaliableEndingTimes] = useState<string[]>([]);

    const [scheduledEvent, setScheduledEvent] = useState<ScheduleDay_t | undefined>();
    const [trainerSchedule, setTrainerSchedule] = useState<ScheduleDay_t[]>([]);
    
    const [schedulingConflict, setSchedulingConflict] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getPersonalClients();

        // Configure default durations from <durationStepSize> mins to 120 mins in <durationStepSize> min intervals
        var durations_temp: string[] = [];
        for (var i = 1; i <= 8; i++) {
            durations_temp.push(`${i * durationStepSize} mins`);
        }
        setAvaliableDurations(durations_temp);

        // Configure starting times
        let deltaHours = (day_endTime - day_startTime) / 60;
        let deltaSegments = Math.ceil((day_endTime - day_startTime) / durationStepSize) + 1;

        var hr = Math.floor(day_startTime / 60);
        var min = day_startTime % 60;
        var startTimes_temp: string[] = [];
        for (var i = 0; i < deltaSegments; i++) {
            startTimes_temp.push(`${hr % 12 === 0 ? 12 : hr % 12}:${min < 10 ? '0' : ''}${min} ${hr >= 12 ? 'pm' : 'am'}`);
            min += durationStepSize;
            hr = min >= 60 ? hr + 1 : hr;
            min = min >= 60 ? min - 60 : min;
        }
        setAvaliableStartingTimes(startTimes_temp);
    }

    useEffect(() => {
        if (props.information !== undefined) {
            if (props.information.timestamp !== undefined) {
                setCalendarDateTimestamp(props.information.timestamp);
                pullTrainerSchedule(props.information.timestamp);
            }

            if (props.information.client !== undefined) {
                clientSelected(props.information.client, props.information.timestamp);
            } else if (props.information.client_id !== undefined) {
                let database = firebase.database();
                let thisClientRef = database.ref(`user_profile/${props.information.client_id}`)
                thisClientRef.once('value', function(snapshotClient) {
                    if (snapshotClient.exists() === true) {
                        let thisUser = snapshotClient.val();
                        let newClient: Client_t = {
                            first_name:thisUser.first_name, 
                            last_name:thisUser.last_name, 
                            full_name:thisUser.full_name, 
                            id: props.information.client_id, 
                            gender: thisUser.gender,
                            email: thisUser.email,
                            weight: thisUser.weight,
                            height: thisUser.height,
                            isMetric: thisUser.units_kg,
                            birthdate: thisUser.birth_date,
                            tags: [goalDescriptors[0], "Gain Muscle"],
                            isSelected: false
                        };
                        clientSelected(newClient, props.information.timestamp);
                        setIsLoading(false);
                    }
                });
            }
        }
    }, [props.information])

    function closePressed() {
        props.closeModal();
    }


    function pullTrainerSchedule(withTimestamp: number) {

        setIsLoadingTrainerSchedule(true);

        // Compose date components
        let thisDate = new Date(withTimestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo = thisDate.getMonth();
        let thisDate_yr = thisDate.getFullYear();

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_schedule/${trainerUID}/schedule/${thisDate_yr}/${thisDate_mo}/${thisDate_day}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let thisObj = snapshot.val();
                parseTrainerSchedule(thisObj, thisDate);
            } else {
                setIsLoadingTrainerSchedule(false);
            }
        });
    }

    async function parseTrainerSchedule(scheduleObj: any[], thisDate: Date) {
        // scheduleObj is array holding objects of the type {id: string;}

        var schedule_temp: ScheduleDay_t[] = [];
        let schedule_temp_keys: any[] = Object.keys(scheduleObj);
        for (var i = 0; i < schedule_temp_keys.length; i++) {
            let thisObj: any = scheduleObj[schedule_temp_keys[i]];
            // console.log("XYZ: ", schedule_temp_keys[i], i, schedule_temp_keys, thisObj)
            if (thisObj !== undefined && thisObj.id !== undefined) {
                let thisScheduleEvent = await getScheduleEventForClient(thisObj.id, thisDate);
                if (thisScheduleEvent !== undefined && thisScheduleEvent !== null) {
                    schedule_temp.push(thisScheduleEvent);
                }
            }
        }
        setTrainerSchedule(schedule_temp);
        setIsLoadingTrainerSchedule(false);
    }

    async function getScheduleEventForClient(clientID: string, thisDate: Date) {
        return new Promise<ScheduleDay_t>((resolve, reject) => {
            let database = firebase.database();
            let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/schedule/${thisDate.getFullYear()}/${thisDate.getMonth()}/${thisDate.getDate()}`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let thisObj = snapshot.val();
                    let newScheduleObj: ScheduleDay_t = {
                        timestamp: thisObj.timestamp === undefined ? Date.now() : thisObj.timestamp,
                        day_number: thisDate.getFullYear(),
                        mo_number: thisDate.getMonth(),
                        year_number: thisDate.getDate(),
                        workout_id: thisObj.workout_id === undefined ? "" : thisObj.workout_id,
                        client_id: clientID,
                        path: thisObj.path === undefined ? "" : thisObj.path,
                        phase: thisObj.phase === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.phase,
                        cycle: thisObj.cycle === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.cycle,
                        scheduled: thisObj.scheduled
                    }
                    resolve(newScheduleObj);
                } else {
                    reject();
                }
            });

        });
    }


    async function submit() {
        setIsLoading(true);

        // 1. Add client ID to trainer's appointment list for the day
        let thisDate = new Date(calendarDateTimestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo = thisDate.getMonth();
        let thisDate_yr = thisDate.getFullYear();

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_schedule/${trainerUID}/schedule/${thisDate_yr}/${thisDate_mo}/${thisDate_day}`);
        ref.once('value', function(snapshot) {
            var thisObjList: any[] = []
            if (snapshot.exists() === true) {
                thisObjList = snapshot.val();
            } 
            var userExists = false;
            let thisObjList_keys: any[] = Object.keys(thisObjList);
            for (var i = 0; i < thisObjList_keys.length; i++) {
                if (thisObjList[thisObjList_keys[i]] !== undefined && thisObjList[thisObjList_keys[i]].id !== undefined && thisObjList[thisObjList_keys[i]].id === selectedClient.id) {
                    userExists = true;
                }
            }
            if (!userExists) {
                thisObjList.push({id: selectedClient.id});
                ref.set(thisObjList);
            }
        });

        // 2. Add scheduled object to ScheduleDay_t object in client's schedule
        let thisStartTime = day_startTime + selectedStartTime;
        let hr = Math.floor(thisStartTime / 60);
        let min = thisStartTime % 60;
        let newStartingDate = new Date(thisDate_yr, thisDate_mo, thisDate_day, hr, min, 0, 0);
        let newStartingTimestamp = newStartingDate.getTime();
        let newEndingTimestamp = newStartingTimestamp + (selectedDuration * 60 * 1000);
        let locationID = await getLocationID();
        let scheduleObject: any = {
            starting_timestamp: newStartingTimestamp,
            ending_timestamp: newEndingTimestamp,
            trainer_ids: [trainerUID],
            client_ids: [selectedClient.id],
            location_id: locationID === null || locationID === undefined ? "" : locationID
        };

        let ref_client = database.ref(`workout_plans/client_assigned_plans/${selectedClient.id}/schedule/${thisDate_yr}/${thisDate_mo}/${thisDate_day}/scheduled`);
        ref_client.set(scheduleObject);

        setTimeout(() => {
            setIsLoading(false);
            closePressed();
        }, 600);
    }

    async function getLocationID() {
        return new Promise<string>((resolve, reject) => {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { reject(); return; }
            let trainerUID = currentUser.uid;

            let database = firebase.database();
            let ref = database.ref(`personal_trainer_profile/${trainerUID}/studio_id`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists()) {
                    resolve(snapshot.val())
                } else {
                    reject();
                }
            });
        });
    }

    function getCalendarDateString() {
        let thisDate = new Date(props.information.timestamp);

        return `${moStrings_full[thisDate.getMonth()]} ${thisDate.getDate()}, ${thisDate.getFullYear()}`;
    }

    function getPersonalClients() {
        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }


        /*************************************************/
        //setIsLoading(true);    // TODO; uncomment

        let testClient: Client_t = {
            first_name:"Connor", 
            last_name:"Holowachuk", 
            full_name:"Connor Holowachuk", 
            id: "abc123", 
            gender: "m",
            email: "connor_chuk@yahoo.com",
            weight: 155,
            height: 72,
            isMetric: false,
            birthdate: "00-00-0000",
            tags: [goalDescriptors[0], "Gain Muscle"],
            isSelected: true
        };

        //setSelectedClient(testClient);



        /*************************************************/



        // console.log("CLIENT LIST | getPersonalClients()");

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_clients/${thisUser.uid}/clients`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let clientsList = snapshot.val();
                let clientListLength = Object.keys(clientsList).length;
                let temp: Client_t[] = [];
                for (const key of Object.keys(clientsList)) {
                    let thisClient = clientsList[key];

                    let thisClientRef = database.ref(`user_profile/${key}`)
                    thisClientRef.on('value', function(snapshotClient) {
                        if (snapshotClient.exists() === true) {
                            let thisUser = snapshotClient.val();
                            let newClient: Client_t = {
                                first_name:thisUser.first_name, 
                                last_name:thisUser.last_name, 
                                full_name:thisUser.full_name, 
                                id: key, 
                                gender: thisUser.gender,
                                email: thisUser.email,
                                weight: thisUser.weight,
                                height: thisUser.height,
                                isMetric: thisUser.units_kg,
                                birthdate: thisUser.birth_date,
                                tags: [goalDescriptors[0], "Gain Muscle"],
                                isSelected: false
                            };
                            temp.push(newClient);

                            if (temp.length === clientListLength) {
                                // all workouts have been pulled - set the state vars to temp
                                setClients(clients.concat(temp));
                                setAllClients(allClients.concat(temp));
                                setIsLoading(false);

                            }
                        }
                    });

                }

            } else {
                //setClientsBlank(true);
                setIsLoading(false);
            }
        });
    }

    function clientSelected(c: Client_t, ts?: number) {
        setShowClientList(false);
        setSelectedClient(c);
        getImage(c.id);
        setSearchString("");
        //pullClientFolders(c.id);

        pullClientScheduleMonth(c.id, ts === undefined ? calendarDateTimestamp : ts);
    }

    function removeClient() {
        setSelectedClient(initClient);
    }

    function pullClientFolders(clientID: string) {
        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/folders`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let dataKeys = Object.keys(data);


                // console.log("FOLDERS:", data, "KEYS:", dataKeys);

                // var folderListTemp: Folder_t[] = [];
                // for (var i = 0; i < dataKeys.length; i++) {
                //     let thisKey = dataKeys[i];
                //     let thisFolderObj = data[thisKey];
                //     // console.log("this folder object:", thisFolderObj, thisKey);
                //     let newFolder: Folder_t | null = parseFolderObj(thisFolderObj, thisKey);
                //     if (newFolder !== null) {
                //         folderListTemp.push(newFolder);
                //     }


                // }

                // // console.log("FOLDER LIST:", folderListTemp)
                // setClientFolders(folderListTemp);
            }
        });
    }

    function pullClientScheduleMonth(forID: string, forTimestamp: number) {

        // console.log("Calendar: pullClientScheduleMonth ->", forID);

        setIsLoadingClientPlan(true);

        let thisDate = new Date(forTimestamp);
        let forYear = thisDate.getFullYear();
        let forMonth = thisDate.getMonth();
        let forDate = thisDate.getDate();

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${forID}/schedule/${forYear}/${forMonth}/${forDate}`);
        ref.once('value', function(snapshot) {

            setIsLoadingClientPlan(false);

            
            if (snapshot.exists() === true) {
                let thisObj = snapshot.val();
                
                let newScheduleObj: ScheduleDay_t = {
                    timestamp: thisObj.timestamp === undefined ? Date.now() : thisObj.timestamp,
                    day_number: forDate,
                    mo_number: forMonth,
                    year_number: forYear,
                    workout_id: thisObj.workout_id === undefined ? "" : thisObj.workout_id,
                    path: thisObj.path === undefined ? "" : thisObj.path,
                    phase: thisObj.phase === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.phase,
                    cycle: thisObj.cycle === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.cycle,
                }

                // console.log("++++++++ SCHEDULED EVENT:", newScheduleObj);

                //setScheduledEvents(scheduledEvents.filter((item: ScheduleDay_t) => {return false;}));
                setScheduledEvent(newScheduleObj);
            } else {
                // console.log("++++ NUTHIN")
            }


        });
    }


    function getImage(forID: string) {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function inputChanged(toString: any, forInput: string) {
        switch (forInput) {
            case 'client-name':
                setSearchString(toString);
                var temp = [];
                for (var i = 0; i < allClients.length; i++) {
                    let searchString = toString.toUpperCase();
                    let nameString = allClients[i].full_name.toUpperCase();
                    let positiveSearch_name = nameString.includes(searchString);

                    var positiveSearch_email = false;
                    let emailString = allClients[i].email;
                    if (emailString != undefined && emailString != null) {

                    }

                    if (positiveSearch_name === true) {
                        temp.push(allClients[i]);
                    }
                }

                setClients(temp);
                if (toString.split('').length > 2) {
                    if (showClientList === false) {
                        setShowClientList(true);
                    }
                }
                break;
            case 'selected-duration':
                let selectedDurationIndex = avaliableDurations.indexOf(toString);
                // console.log("Changing selected-duration to:", selectedDurationIndex, toString);
                setSelectedDuration((selectedDurationIndex + 1) * durationStepSize);
                break;
            case 'start-time':
                let selectedStartingIndex = avaliableStartingTimes.indexOf(toString);
                // console.log("Changing selected-duration to:", selectedStartingIndex, toString);
                setSelectedStartTime(selectedStartingIndex * durationStepSize);

                break;
            default:
                break;
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            searchButtonPressed()
        }
    }

     function searchButtonPressed() {

    }

    function conflictUpdated(state: boolean) {
        setSchedulingConflict(state);
    }

	return (
		<div className="modal-background new-appointment-modal-background">
			<div className="new-appointment-modal">
                <div className="new-appointment-modal-header">
                    <p>Create a new Appointment for</p>
                    <h2>{getCalendarDateString()}</h2>
                    <div onClick={() => closePressed()} className="new-appointment-modal-header-floating-close"><Close className="new-appointment-modal-header-floating-close-icon" style={{ fontSize: 20 }}/></div>
                </div>   
                <div hidden={isLoading || workflowState > 0} className="new-appointment-modal-content">
                    <div className="new-appointment-modal-content-body">
                        <div className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>Client</h4>
                            </div>
                            <div className="assign-modal-body-row-input-container">
                                <div hidden={selectedClient.first_name !== ""} className={`assign-modal-body-client-name-input-container ${clientNameFocus ? "assign-modal-body-client-name-input-container-focus" : ""}`}>
                                    <IonInput value={searchString} 
                                        id="assign-to-client-input"
                                        autofocus={true}
                                        placeholder="Search for a Client by name..."
                                        inputmode="text"
                                        clearOnEdit={true}
                                        type="text"
                                        onKeyPress={e => inputUpdated(e.key)}
                                        onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                                        onFocus={(e) => setClientNameFocus(true)}
                                        onBlur={(e) => setClientNameFocus(false)}/>
                                    <div hidden={showClientList} onClick={() => setShowClientList(true)} className="assign-modal-body-client-name-input-arrow-icon-container">
                                        <ExpandMore className="assign-modal-body-client-name-input-arrow-icon"/>
                                    </div>
                                    <div hidden={!showClientList} onClick={() => setShowClientList(false)} className="assign-modal-body-client-name-input-arrow-icon-container">
                                        <ExpandLess className="assign-modal-body-client-name-input-arrow-icon"/>
                                    </div>
                                </div>
                                <div hidden={selectedClient.first_name === ""} className="assign-modal-body-client-name-input-container assign-modal-body-client-name-input-container-selected-client">
                                    <div className="client-card-image-container">
                                        <img  
                                            className="client-card-image" 
                                            hidden={imgUrl === ''}
                                            src={imgUrl} 
                                            alt={`Image for ${selectedClient.first_name}`} />
                                    </div>
                                    <div className="assign-modal-body-client-name-input-container-selected-client-text-container">
                                        <h4>{selectedClient.full_name}</h4>
                                    </div>
                                    <div onClick={() => removeClient()} className="assign-modal-body-client-name-input-container-selected-client-remove-container">
                                        <Close className="assign-modal-body-client-name-input-container-selected-client-remove-icon"/>
                                    </div>    
                                </div>
                            </div>
                            <CSSTransition in={showClientList} timeout={200} classNames="menu" unmountOnExit>
                                <div className="assign-modal-body-client-name-menu-container">
                                    {
                                        clients.map((item: Client_t, index: number) => (
                                            <AssignModalClientCell 
                                                client={item} 
                                                index={index} 
                                                clientSelected={() => clientSelected(item)}/>
                                        ))
                                    }
                                </div>
                            </CSSTransition>
                        </div>
                        <div hidden={selectedClient.id !== ''} className="assign-modal-body-curtain new-appointment-modal-curtain"/>
                        <div className="assign-modal-body-row assign-modal-body-row-col-container">
                            <div className="assign-modal-body-col-2">
                                <div className="assign-modal-body-row-label-container">
                                    <h4>Duration</h4>
                                </div>
                                <div className="">
                                    <Selector
                                        placeholder="Duration"
                                        // forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.machine}
                                        showTitle={false} 
                                        options={avaliableDurations}
                                        inputChanged={(e: number) => inputChanged(e, 'selected-duration')}/>
                                </div>
                            </div>
                            <div className="assign-modal-body-col-2">
                                <div className="assign-modal-body-row-label-container">
                                    <h4>Start time</h4>
                                </div>
                                <div className="">
                                    <Selector
                                        placeholder="Starting"
                                        // forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.machine}
                                        showTitle={false} 
                                        options={avaliableStartingTimes}
                                        inputChanged={(e: number) => inputChanged(e, 'start-time')}/>
                                </div>
                            </div>
                            <div hidden={true} className="assign-modal-body-col-3">
                                <div className="assign-modal-body-row-label-container">
                                    <h4>End time</h4>
                                </div>
                                <div className="">
                                    <Selector
                                        placeholder="Ending"
                                        // forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.machine}
                                        showTitle={false} 
                                        options={avaliableDurations}
                                        inputChanged={(e: number) => inputChanged(e, 'end-time')}/>
                                </div>
                            </div>
                        </div>
                        <div className="assign-modal-body-row">
                            <div className="assign-modal-body-row-label-container">
                                <h4>My Schedule</h4>
                            </div>
                            <div hidden={isLoadingTrainerSchedule} className="assign-modal-body-row-timeline-container">
                                <CalendarDayTimeline 
                                    dayStartTime={day_startTime}
                                    dayEndTime={day_endTime}
                                    selectedClient={selectedClient}
                                    scheduledEvent={scheduledEvent}
                                    selectedDuration={selectedDuration}
                                    selectedStartTime={selectedStartTime}
                                    trainerSchedule={trainerSchedule}
                                    conflictUpdated={(s: boolean) => conflictUpdated(s)}/>
                            </div>
                            <div hidden={!isLoadingTrainerSchedule} className="assign-modal-body-row-timeline-container">
                                <div className="create-workout-exercise-configure-loading-spinner new-appointment-modal-content-loading-spinner new-appointment-modal-content-loading-spinner-trainer-schedule"/>
                                <p>Loading schedule...</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="new-appointment-modal-content-button-container">
                        <div onClick={() => selectedClient.id === '' || schedulingConflict === true ? null : submit()} className={`new-appointment-modal-content-button ${selectedClient.id === '' || schedulingConflict === true ? 'new-appointment-modal-content-button-unselected' : ''}`}>
                            <p>Create Appointment</p>
                            <ArrowForwardIos className="new-appointment-modal-content-button-icon"/>
                        </div> 
                    </div> 
                </div>
                <div hidden={!isLoading} className="new-appointment-modal-content new-appointment-modal-content-loading">
                    <div className="create-workout-exercise-configure-loading-spinner new-appointment-modal-content-loading-spinner"/>
                    <p>Creating Appointment...</p>
                </div>
            </div>
		</div>
	)
}

export default NewAppointmentModal;