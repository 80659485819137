import React, { useState } from 'react';
import './ClientList.css';
import '../DashboardPageCommon.css';
import '../../../components/PageElements/BlankPage.css';
import firebase from '../../../Firebase';

import Search from '../../../components/Base/Search';
import TextInput from '../../../components/Base/TextInput';
import StandardButton from '../../../components/Base/StandardButton';
import ClientCell from '../../../components/Clients/ClientCell';
import Client_t from '../../../Interfaces/Client_t';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';

function ClientList(props: any) {

	let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];

	const [clientsBlank, setClientsBlank] = useState(false);
	const [clientsPulled, setClientsPulled] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [clients, setClients] = useState<Client_t[]>([]);
	const [allClients, setAllClients] = useState<Client_t[]>([]);
	const [selectedClient, setSelectedClient] = useState<Client_t>({first_name: '',last_name: '',full_name: '',id: '',isSelected: false});
	const [initSet, setInitSet] = useState(false);

	if (initSet === false) {
		setInitSet(true);
		getTrainerClients();
		// console.log("CLIENT LIST | CALLING getTrainerClients() from init");
	}	

	function getTrainerClients() {
		// TODO: uncomment when ref updated
		//let currentUser = firebase.auth().currentUser;
		//if (currentUser === null) { return; }

		if (clientsPulled === true) { return; }
		setClientsPulled(true);

		if (props.personalClients === true) {
			// console.log("CLIENT LIST | CALLING getPersonalClients() from getTrainerClients()");
			getPersonalClients();
			return;
		}

		let database = firebase.database();
		let ref = database.ref('user_profile') // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
		ref.once('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let userList = snapshot.val();
				//// console.log(snapshot.val());
				

				var temp: Client_t[] = [];
				for (const key of Object.keys(userList)) {
					let thisUser = userList[key];
					let newClient: Client_t = {
						first_name:thisUser.first_name, 
						last_name:thisUser.last_name, 
						full_name:thisUser.full_name, 
						id: key, 
						gender: thisUser.gender,
						email: thisUser.email,
						weight: thisUser.weight,
						height: thisUser.height,
						isMetric: thisUser.units_kg,
						birthdate: thisUser.birth_date,
						tags: [goalDescriptors[0], "Gain Muscle"],
						isSelected: false
					};
					temp.push(newClient);
				}
				setClients(clients.concat(temp));
				setAllClients(allClients.concat(temp));
				setIsLoading(false);
			} else {
				setClientsBlank(true);
				setIsLoading(false);
			}
		});
	}

	function getPersonalClients() {
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		// console.log("CLIENT LIST | getPersonalClients()");

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_clients/${thisUser.uid}/clients`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let clientsList = snapshot.val();
				let clientListLength = Object.keys(clientsList).length;
				let temp: Client_t[] = [];
				for (const key of Object.keys(clientsList)) {
					let thisClient = clientsList[key];

					let thisClientRef = database.ref(`user_profile/${key}`)
					thisClientRef.on('value', function(snapshotClient) {
						if (snapshotClient.exists() === true) {
							let thisUser = snapshotClient.val();
							let newClient: Client_t = {
								first_name:thisUser.first_name, 
								last_name:thisUser.last_name, 
								full_name:thisUser.full_name, 
								id: key, 
								gender: thisUser.gender,
								email: thisUser.email,
								weight: thisUser.weight,
								height: thisUser.height,
								isMetric: thisUser.units_kg,
								birthdate: thisUser.birth_date,
								tags: [goalDescriptors[0], "Gain Muscle"],
								isSelected: false
							};
							temp.push(newClient);

							if (temp.length === clientListLength) {
								// all workouts have been pulled - set the state vars to temp
								setClients(clients.concat(temp));
								setAllClients(allClients.concat(temp));
								setIsLoading(false);
							}
						}
					});

				}

			} else {
				setClientsBlank(true);
				setIsLoading(false);
			}
		});
	}


	function textInputChanged(toString: string) {
		var temp = [];
		for (var i = 0; i < allClients.length; i++) {
			let searchString = toString.toUpperCase();
			let nameString = allClients[i].full_name.toUpperCase();
			let positiveSearch_name = nameString.includes(searchString);

			var positiveSearch_email = false;
			let emailString = allClients[i].email;
			if (emailString != undefined && emailString != null) {

			}

			if (positiveSearch_name === true) {
				temp.push(allClients[i]);
			}
		}

		setClients(temp);
	}

	function clientSelected(client: Client_t) {
		setClients(
			clients.map(item => {
				if (item.id === client.id) {
					setSelectedClient(client);
					props.clientSelected(client);
					return {...item, isSelected: true};
				} else {
					return {...item, isSelected: false};;
				}
			})
		);
	}

	function moreInfoSelected(client: Client_t) {
		props.moreInfoSelected(client);
	}

	function addNewClientPressed() {
		props.addNewClientPressed();
	}
	//page-full-height-width
	return (
		<div className="client-list-container-outter">
			<div hidden={clientsBlank === true} className={props.isInModal !== undefined && props.isInModal === true ? "client-container-modal" : (props.isNewWorkout ? "client-container-new-workout" : "client-container")}>
				<div className="page-title-header-container">
                    <h1>{props.isNewWorkout === undefined || props.isNewWorkout === false ? "My Clients" : "Select a Client to workout with"}</h1>
                </div>
				<div hidden={props.showTitle === undefined || props.showTitle === false}><h3>Select a Client</h3></div>
				<div className="client-list-upper-content">
					<div className={`client-list-upper-content-search-container ${props.showTitle !== undefined && props.showTitle === true ? 'client-list-upper-content-search-container-full' : ''} ${props.isNewWorkout === undefined || props.isNewWorkout === false ? '' : 'client-list-upper-content-search-container-right'}`}>
						<Search 
							id="client-list-search"
							placeholder="Search by name or email..." 
							inputChanged={(e: string) => textInputChanged(e)}/>
					</div>
					<div hidden={(props.showTitle !== undefined && props.showTitle === true) || (props.isNewWorkout !== undefined && props.isNewWorkout === true)} className="client-list-upper-content-showing-number-container">
						<p>Showing {clients.length} of {allClients.length}</p>
					</div>
					<div hidden={(props.showTitle !== undefined && props.showTitle === true) || (props.isNewWorkout !== undefined && props.isNewWorkout === true)} className="client-list-upper-content-add-button-container">
						<div hidden={props.hideAddNewClient !== undefined && props.hideAddNewClient === true} onClick={() => addNewClientPressed()} className="client-list-upper-content-add-button">
							<Add className="client-list-upper-content-add-button-icon"/>
							<p>New client</p>
						</div>
					</div>
				</div>
				<div className="client-list-list-headding">
					<div className="client-list-list-headding-row client-list-list-headding-row-first">
						<p>NAME</p>
					</div>
					<div className="client-list-list-headding-row">
						<p>NEXT WORKOUT</p>
					</div>
					<div className="client-list-list-headding-row">
						<p>LAST TRAINED</p>
					</div>
					<div className="client-list-list-headding-row">
						<p>GOAL PROGRESS</p>
					</div>
				</div>
				<div hidden={isLoading === true} className={props.isInModal !== undefined && props.isInModal === true ? "cell-container-modal" : "cell-container"}>
					
					{clients.map(item => (
						<ClientCell 
							key={item.id} 
							client={item} 
							isInModal={props.isInModal === undefined ? false : props.isInModal}
							cellSelected={(e: Client_t) => clientSelected(e)} 
							moreInfoSelected={(e: Client_t) => moreInfoSelected(e)}/>
					))}
				</div>
				<div hidden={clientsBlank === false || isLoading === true} className="client-container">
					<div className="blank-page-container">
						<div className="blank-page-content">
			                <h4>No clients added</h4>
			                <p>It looks like you haven't added any clients yet.<br/>Not to worry, just press 'Add new' and select 'Client' to get started!</p>
			            </div>
					</div>
				</div>
				<div hidden={isLoading === false} className="client-container">
					<div className="client-list-loading-container">
                        <div className="client-list-container-loading-spinner"/>
                        <p>Loading Clients...</p>
                    </div>
				</div>
			</div>
			<div hidden={clientsBlank === false} className="client-list-container-blank">
				<div className="client-list-container-blank-img-container">
					<img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/add-client.svg`}/>
				</div>
				<div className="client-list-container-blank-text-container">
					<h4>My Athlete Roster is Empty</h4>
					<p>Athlete profiles give you the power to track your Athlete's progress, assign new plans, explore previous plans, and so much more.</p>
				</div>
				<div onClick={() => addNewClientPressed()} className="client-list-container-blank-button">
					<Add className="client-list-container-blank-button-icon"/>
					<p>Add your first Athlete</p>
				</div>
			</div>
		</div>
	)

}

export default ClientList;