import React, { useState, useEffect } from 'react';
import './FullAnalysisComposition.css';

import SetStats_t from '../../Interfaces/SetStats_t';

import TonnageChart from './TonnageChart';
import PowerChart from './PowerChart';
import ROMChart from './ROMChart';
import RestTimeChart from './RestTimeChart';
import FormAccuracyChart from './FormAccuracyChart';

function FullAnalysisComposition(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [setStats, setSetStats] = useState<SetStats_t[]>();

    let options = ["Tonnage","Power Output","Average ROM","Rest Time","Form Correctness"];
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function setPage(index: number) {
        setSelectedIndex(index);
    }

    useEffect(() => {
        setSetStats(props.setStats);
    }, [props]);

	return (
		<div className="full-analysis-composition-container">
			<div className="full-analysis-composition-selector-container">
                {options.map((item: string, index: number) => (
                    <div 
                        onClick={() => setPage(index)}
                        className={"full-analysis-composition-selector-option " + (selectedIndex === index ? "full-analysis-composition-selector-option-selected" : "full-analysis-composition-selector-option-unselected")}>
                            {item}
                            <div hidden={selectedIndex !== index} className="full-analysis-composition-selector-option-line"></div>
                    </div>
                ))}
            </div>
            <div className="full-analysis-composition-chart-container">
                <div className="full-analysis-composition-chart-content" hidden={selectedIndex !== 0}>
                    <TonnageChart setStats={setStats}/>
                </div>
                <div className="full-analysis-composition-chart-content" hidden={selectedIndex !== 1}>
                    <PowerChart setStats={setStats}/>
                </div>
                <div className="full-analysis-composition-chart-content" hidden={selectedIndex !== 2}>
                    <ROMChart setStats={setStats}/>
                </div>
                <div className="full-analysis-composition-chart-content" hidden={selectedIndex !== 3}>
                    <RestTimeChart setStats={setStats}/>
                </div>
                <div className="full-analysis-composition-chart-content" hidden={selectedIndex !== 4}>
                    <FormAccuracyChart setStats={setStats}/>
                </div>
            </div>
		</div>
	)
}

export default FullAnalysisComposition;