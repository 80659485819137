import React, { useState } from 'react';
import './Name.css';
import TextInput from '../../components/Base/TextInput';
import { IonReactRouter } from '@ionic/react-router';


function Email(props: any) {


  function textInputChanged(text: string) {
    let regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    let passedRegEx = regEx.test(text);

    let isValid = passedRegEx;
    props.validation(isValid);

    if (isValid === true) {
      props.resultUpdated(text);
    }
  }

  return (
    <div className="header-container">
      <h1>Hey there, {props.firstName}!</h1>
      <h1>Let's start setting up your login with Eigen Fitness.</h1>
      <p>Go ahead and enter your email address below to get started.</p>
      <div>
        <TextInput placeholder="My email address" input="" inputChanged={(e: string) => textInputChanged(e)}></TextInput>
        <p hidden={!props.showError} className="error-text">{ props.errorDesc }</p>
      </div>
   </div>
  )
}

export default Email;