import React, { useState, useEffect, useRef } from 'react';
import { IonInput } from '@ionic/react';
import { CSVLink } from "react-csv";
import firebase from '../../../Firebase';
import axios from 'axios';
import { FileDrop } from 'react-file-drop';

import './NodeStreamAnalyze.css';


import NodeStreamAnalyzeChart from './NodeStreamAnalyzeChart';
import NodeStreamAnalyzeAverageRep from './NodeStreamAnalyzeAverageRep';
// import NodeStreamAnalyze3D from './NodeStreamAnalyze3D';
import NodeStreamAnalyzeStream from './NodeStreamAnalyzeStream';
import RepStats_t from '../../../Interfaces/RepStats_t';
import MotionData_t from '../../../Interfaces/MotionData_t';
import Injury_t from '../../../Interfaces/Injury_t';

import '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Image from '@material-ui/icons/Image';

interface RepWindow_t {
    start_index: number;
    end_index: number;
    is_rep: boolean;
    injury_id?: string;
}

interface RecordedSession_t {
    id: string;
    timestamp: number;
    recorded_by_id: string;
    analysis_complete: boolean;
}

interface RecordedExercise_t {
    id: string;
    title: string;
    sessions: RecordedSession_t[];
    hasTargetData?: boolean;
    hasMotionInfo?: boolean;
    trainedTimestamp?: number;
}


function NodeStreamAnalyze(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let wkDayStrings = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const validLocationIndecies = [0,1,2,3,4,5,6,7,8];

    const defaultTargetData = {
        rom: { min: 40, max: 50 },
        velocity: { min: 0.9, max: 1.3 },
        eccentric: { min: 0.4, max: 2 },
        power_normalized: { min: 3, max: 6 }
    }

    const videoRef = useRef<any>(null);

    const [videoPresent, setVideoPresent] = useState(false);

    const [initSet, setInitSet] = useState(false);
    const [imgUrl, setImgUrl] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [percentLoaded, setPercentLoaded] = useState(0);
    const [isLoadingTargetData, setIsLoadingTargetData] = useState(false);

    const [exerciseApproved, setExerciseApproved] = useState(false); 
    const [exerciseCompatibleWithNodes, setExerciseCompatibleWithNodes] = useState(true); 

    const [showSaveTargetMetricsButton, setShowSaveTargetMetricsButton] = useState(false);

    const [injuryData, setInjuryData] = useState<Injury_t[]>([]);
    const [selectedInjuryType, setSelectedInjuryType] = useState<Injury_t | undefined>(undefined);
    const [showInjuryTypeDropdown, setShowInjuryTypeDropdown] = useState(false);

    //const [selectionState, setSelectionState] = useState(0);
    const [thisExerciseTitle, setThisExerciseTitle] = useState("");
    const [thisExerciseTitleEdited, setThisExerciseTitleEdited] = useState(false);
    const [thisExerciseMotionInfoEdited, setThisExerciseMotionInfoEdited] = useState(false);

    const [allExerciseSessions, setAllExerciseSessions] = useState<RecordedExercise_t[]>([]);
    const [selectedExerciseSession, setSelectedExerciseSession] = useState<RecordedExercise_t | undefined>();
    const [recentFilter, setRecentFilter] = useState(true);
    const [showParams, setShowParams] = useState(false);

    const [thisSessionID, setThisSessionID] = useState(""); // -N80_l0wls5cLXuVvGSh
    const [pointOffset, setPointOffset] = useState(0);

    const [targetMetrics, setTargetMetrics] = useState<any>(defaultTargetData)

    const [exerciseMotionData, setExerciseMotionData] = useState<any>({})

    const [currentSetRepStats, setCurrentSetRepStats] = useState<RepStats_t[]>([]);
    const [currentSetMotionData, setCurrentSetMotionData] = useState<MotionData_t[][]>([[],[],[],[],[],[],[],[],[]]);

    const [recordingTimeString, setRecordingTimeString] = useState("Loading...");

    const [videoProgress, setVideoProgress] = useState(0);

    const [viewingLocationIndex, setViewingLocationIndex] = useState(0);

    const [numPts, setNumPts] = useState(1);

    const [isTrainingModel, setIsTrainingModel] = useState(false);
    const [modelTrained, setModelTrained] = useState(false);
    const [modelTrainIndex, setModelTrainIndex] = useState(0);
    const [modelTrainedError, setModelTrainedError] = useState(false);
    //const [hoverPoint, setHoverPoint] = useState(0);

    const [videoUrl, setVideoUrl] = useState("");
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedVideoUrl, setUploadedVideoUrl] = useState<string | null>(null);
    const [videoFile, setVideoFile] = useState<FileList | null>(null);
    const [isUploadingVideo, setIsUploadingVideo] = useState(false);
    const [isCustomVideo, setIsCustomVideo] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');

    const [serverOK, setServerOK] = useState(true);

    const [latestMotionData, setLatestMotionData] = useState<MotionData_t>({
        quaternion: {w: 1, x: 0, y: 0, z: 0},
        acceleration: {x: 0, y: 0, z: 0},
        timestamp: 0
    })

    const [detectedRepWindows, setDetectedRepWindows] = useState<RepWindow_t[]>([])

    const [qStream, setQStream] = useState<{w: number, x: number, y: number, z: number}[]>([]);

    const [addingRepWindows, setAddingRepWindows] = useState(false);
    const [windowsAreReps, setWindowsAreReps] = useState(true);
    const [dataForCSV, setDataForCSV] = useState<any[]>([]);

    const [savingProgress, setSavingProgress] = useState(-1);
    
    if (initSet === false) {
        setInitSet(true);
        
    }

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet]);

    function initialize() {
        testPullingIDs(false);
        setSelectedExerciseSession(undefined);
        setThisExerciseTitle("");
        setThisExerciseTitleEdited(false);
        setThisExerciseMotionInfoEdited(false);
        checkServerStatus();
        //clearAllNodeStreamData();

        //pullDataStream(thisSessionID);
        //pullStreamVideoURL(thisSessionID);


        window.addEventListener('keypress', function(e) {   
            // console.log("PRESSED:", e.keyCode);

            if (e.keyCode === 114) {    // r
                setWindowsAreReps(true);
            }

            if (e.keyCode === 115) {    // s
                setWindowsAreReps(false);
            }

            if (e.keyCode === 97) {    // a
                setAddingRepWindows(true);
            }
            if (e.keyCode === 100) {    // d
                setAddingRepWindows(false);
            }

        });

    }

    useEffect(() => {
        if (selectedExerciseSession && selectedExerciseSession.id !== "") {
            updateVideo();
        }
    }, [selectedExerciseSession])

    useEffect(() => {
        setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }))
        setDataForCSV(dataForCSV.filter((item, index) => { return false; }));
        setWindowsAreReps(false);
    }, [viewingLocationIndex])

    useEffect(() => {
        setSavingProgress(-1);
    }, [dataForCSV])

    useEffect(() => {
        testPullingIDs(false);
    }, [recentFilter])

    function checkServerStatus() {
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/checkMLServer`,
            { },
            { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            //console.log(response.data);
            let data = response.data;
            let serverOK_temp = data !== undefined && data.server_active !== undefined ? data.server_active : false;
            setServerOK(serverOK_temp);
        })
        .catch(e => {
            //console.log(e);
            setServerOK(false);
        })
    }

    function testPullingIDs(fromDeleted: boolean) {

        setThisSessionID("");
        
        setIsLoading(true);
        setPercentLoaded(0);
        // console.log("-- testPullingIDs");

        let database = firebase.database();
        let ref = database.ref(`node_stream_data/exercises`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                parseRecordedExercises_new(data, fromDeleted);
            } else {
                setIsLoading(false);
            }
            
        });
    }

    async function parseRecordedExercises_new(exerciseSessionList: any, fromDeleted: boolean) {
        if (exerciseSessionList === undefined) { return; }

        let startTime = Date.now();
        var listKeys = Object.keys(exerciseSessionList);
        var promiseList: any[] = [];
        for (var i = 0; i < listKeys.length; i++) {
            let thisKey = listKeys[i];
            let thisExerciseObj = exerciseSessionList[thisKey];
            promiseList.push(getExerciseStuff(thisKey, thisExerciseObj));
        }

        let res = await Promise.allSettled(promiseList);

        var allExerciseSessions_temp: RecordedExercise_t[] = [];
        for (var i = 0; i < res.length; i++) {
            let thisRes = res[i];
            if (thisRes.status === "fulfilled") {
                let thisObj = thisRes.value;
                let thisKey = thisObj.id ?? "";
                let sessionList = thisObj.sessions ?? [];
                if (sessionList.length > 0) {
                    allExerciseSessions_temp.push(thisObj);
                }

                if (fromDeleted && selectedExerciseSession !== undefined && thisKey === selectedExerciseSession.id) {
                    setSelectedExerciseSession({...selectedExerciseSession, sessions: sessionList})
                }
            }
        }

        let endTime = Date.now();
        let dt = endTime - startTime;
        // console.log("RES:", Math.floor(dt / 1000));
        // console.log("---", res);
        allExerciseSessions_temp.sort((a, b) => a.title.localeCompare(b.title))
        setAllExerciseSessions(allExerciseSessions_temp);
        setIsLoading(false);

    }

    async function getExerciseStuff(thisKey: string, thisExerciseObj: any) {
        return new Promise<any>(async (resolve, reject) => {
            
            var sessionList: RecordedSession_t[] = [];

            if (thisExerciseObj.session_ids !== undefined) {
                let sessionKeys = Object.keys(thisExerciseObj.session_ids);
                for (var j = 0; j < sessionKeys.length; j++) {
                    let thisSessionKey = sessionKeys[j];
                    let thisSessionObj: any = thisExerciseObj.session_ids[thisSessionKey];
                    if (thisSessionObj !== undefined) {
                        let newSession: RecordedSession_t = {
                            id: thisSessionKey,
                            timestamp: thisSessionObj.timestamp === undefined ? -1 : thisSessionObj.timestamp,
                            recorded_by_id: thisSessionObj.logged_by === undefined ? "" : thisSessionObj.logged_by,
                            analysis_complete: thisSessionObj.analysis_complete === undefined ? false : thisSessionObj.analysis_complete,
                        }

                        if (!recentFilter || (recentFilter && newSession.timestamp > 1694768416000)) sessionList.push(newSession);
                    }
                    
                }
            }

            let thisExerciseTitle = await pullExerciseTitle(thisKey);
            let thisMotionInfo = await pullExerciseTargetData(thisKey);
            let thisTrainedInfo = await pullExerciseTrainedData(thisKey)

            sessionList.sort((a, b) => b.timestamp - a.timestamp);
            let newRecordedExercise: RecordedExercise_t = {
                id: thisKey,
                title: thisExerciseTitle,
                sessions: sessionList,
                hasMotionInfo: thisMotionInfo !== undefined,
                hasTargetData: thisMotionInfo !== undefined && thisMotionInfo.target !== undefined,
                trainedTimestamp: thisTrainedInfo ?? -1
            }

            resolve(newRecordedExercise);
            // if (sessionList.length > 0) allExerciseSessions_temp.push(newRecordedExercise);

            // if (fromDeleted && selectedExerciseSession !== undefined && thisKey === selectedExerciseSession.id) {
            //     setSelectedExerciseSession({...selectedExerciseSession, sessions: sessionList})
            // }
            // setPercentLoaded(Math.floor((i + 1) / listKeys.length * 1000) / 10);
        })
    }

    async function parseRecordedExercises(exerciseSessionList: any, fromDeleted: boolean) {
        if (exerciseSessionList === undefined) { return; }

        var allExerciseSessions_temp: RecordedExercise_t[] = [];

        var listKeys = Object.keys(exerciseSessionList);
        for (var i = 0; i < listKeys.length; i++) {
            let thisKey = listKeys[i];
            let thisExerciseObj = exerciseSessionList[thisKey];
            var sessionList: RecordedSession_t[] = [];

            if (thisExerciseObj.session_ids !== undefined) {
                let sessionKeys = Object.keys(thisExerciseObj.session_ids);
                for (var j = 0; j < sessionKeys.length; j++) {
                    let thisSessionKey = sessionKeys[j];
                    let thisSessionObj: any = thisExerciseObj.session_ids[thisSessionKey];
                    if (thisSessionObj !== undefined) {
                        let newSession: RecordedSession_t = {
                            id: thisSessionKey,
                            timestamp: thisSessionObj.timestamp === undefined ? -1 : thisSessionObj.timestamp,
                            recorded_by_id: thisSessionObj.logged_by === undefined ? "" : thisSessionObj.logged_by,
                            analysis_complete: thisSessionObj.analysis_complete === undefined ? false : thisSessionObj.analysis_complete,
                        }

                        if (!recentFilter || (recentFilter && newSession.timestamp > 1694768416000)) sessionList.push(newSession);
                    }
                    
                }
            }

            let thisExerciseTitle = await pullExerciseTitle(thisKey);
            let thisMotionInfo = await pullExerciseTargetData(thisKey);

            sessionList.sort((a, b) => b.timestamp - a.timestamp);
            let newRecordedExercise: RecordedExercise_t = {
                id: thisKey,
                title: thisExerciseTitle,
                sessions: sessionList,
                hasMotionInfo: thisMotionInfo !== undefined,
                hasTargetData: thisMotionInfo !== undefined && thisMotionInfo.target !== undefined
            }
            if (sessionList.length > 0) allExerciseSessions_temp.push(newRecordedExercise);

            if (fromDeleted && selectedExerciseSession !== undefined && thisKey === selectedExerciseSession.id) {
                setSelectedExerciseSession({...selectedExerciseSession, sessions: sessionList})
            }
            setPercentLoaded(Math.floor((i + 1) / listKeys.length * 1000) / 10);
        }

        allExerciseSessions_temp.sort((a, b) => a.title.localeCompare(b.title))
        

        setAllExerciseSessions(allExerciseSessions_temp);
        setIsLoading(false);

    }

    async function pullExerciseTitle(forID: string) {
        return new Promise<string>((resolve, reject) => {
            let database = firebase.database();
            let ref = database.ref(`exercises/${forID}/title`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    resolve(data);
                } else {
                    resolve("Unknown Exercise");
                }
            });
        })
    }

    async function pullExerciseTargetData(forID: string) {
        return new Promise<any>((resolve, reject) => {
            let database = firebase.database();
            let ref = database.ref(`exercises/${forID}/motion_info`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    resolve(data);
                } else {
                    resolve(undefined);
                }
            });
        })
    }

    async function pullExerciseTrainedData(forID: string) {
        return new Promise<any>((resolve, reject) => {
            let database = firebase.database();
            let ref = database.ref(`ml_data/results/${forID}/trained_on`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let data = snapshot.val();
                    resolve(data);
                } else {
                    resolve(undefined);
                }
            });
        })
    }

    async function exerciseSelected(e: RecordedExercise_t) {
        setSelectedExerciseSession(e);
        setThisExerciseTitle(e.title);
        setThisExerciseTitleEdited(false);
        setThisExerciseMotionInfoEdited(false);
        setModelTrained(false);
        setModelTrainedError(false);

        if (props.mlCore !== undefined) {
            props.mlCore.loadModel({id: e.id, title: e.title}, 0);
        }

        // Attempt to pull injury data
        pullInjuryData(e.id);

        // Attempt to pull target data
        pullTargetData(e.id, e)

        // Attempt to pull motion info
        pullMotionInfoData(e.id, e);

        let resp = await pullExerciseApproval(e.id);
        setExerciseApproved(resp);

        let respComp = await pullExerciseCompatiblity(e.id);
        setExerciseCompatibleWithNodes(respComp);


    }

    function pullTargetData(forID: string, e: RecordedExercise_t) {

        if (!e.hasTargetData) {
            setTargetMetrics(defaultTargetData);
            setShowSaveTargetMetricsButton(true);
            return;
        }

        setIsLoadingTargetData(true);
        let database = firebase.database();
        let ref = database.ref(`exercises/${forID}/motion_info/target`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setTargetMetrics(data);
                setShowSaveTargetMetricsButton(false);
            } else {
                setTargetMetrics(defaultTargetData);
                setShowSaveTargetMetricsButton(true)
            }
            setIsLoadingTargetData(false);
        });
    }

    function pullMotionInfoData(forID: string, e: RecordedExercise_t) {

        let database = firebase.database();
        let ref = database.ref(`exercises/${forID}/motion_info`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setExerciseMotionData(data);
            } else {
                setExerciseMotionData({});
            }
            setIsLoadingTargetData(false);
        });
    }

    function pullInjuryData(forID: string) {

        setInjuryData(injuryData.filter((item, index) => { return false; }));

        let database = firebase.database();
        let ref = database.ref(`injury/${forID}/types`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                parseInjuryData(data);

            } else {
                setInjuryData(injuryData.filter((item, index) => { return false; }));
            }
        });

    }

    function parseInjuryData(data: any) {
        // Parse injury data for this exercise

        var injuryDataObjList_temp: Injury_t[] = [];

        let injuryKeys = Object.keys(data);
        for (var i = 0; i < injuryKeys.length; i++) {
            let thisKey = injuryKeys[i];
            let thisInjuryData = data[thisKey];

            let newInjuryObj: Injury_t = thisInjuryData;
            newInjuryObj.id = thisKey;
            injuryDataObjList_temp.push(newInjuryObj);
        }

        setInjuryData(injuryDataObjList_temp);
    }

    function updateSelectedInjuryType(toType: Injury_t | undefined) {
        setSelectedInjuryType(toType);
        setShowInjuryTypeDropdown(false);
    }

    function updateViewingLocationIndex(toIndex: number) {
        setViewingLocationIndex(toIndex);
        if (props.mlCore !== undefined) {
            props.mlCore.loadModel({
                    id: selectedExerciseSession === undefined ? "" : selectedExerciseSession.id, 
                    title: selectedExerciseSession === undefined ? "" : selectedExerciseSession.title
                }, 
                toIndex
            );
        }
    }

    function sessionSelected(e: RecordedSession_t) {
        pullDataStream(e.id);
        pullStreamVideoURL(e.id);
        setThisSessionID(e.id);
    }

    function pullStreamVideoURL(forSessionID: string) {

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        const storage = firebase.storage();
        //// console.log(props.client.id);

        //gs://movement-tracker-457bc.appspot.com/node_stream_recordings/yjBmF1yXxOVDZOYCkA9Qkl2QOY13/-N80NNqSjtgL3twb5Jzn/recording--N80NNqSjtgL3twb5Jzn
        storage.ref(`node_stream_recordings/${trainerUID}/${forSessionID}/recording-${forSessionID}`).getDownloadURL()
        .then(fireBaseUrl => {
            setImgUrl(fireBaseUrl);
            setVideoPresent(true);
        })
        .catch(error => {
            // console.log('No video found');
            // console.log(error);
            setVideoPresent(false);
        })
    }

    async function pullExerciseApproval(forID: string) {
        return new Promise<boolean>( async (resolve, reject) => {
            try {
                let thisPath = `exercises/${forID}/approved`
                let database = firebase.database();
                let ref = database.ref(thisPath); 
                let res = await ref.once('value');

                if (res.exists()) {
                    resolve(res.val() ?? false);
                } else {
                    resolve(false);
                }
            } catch (err) {
                resolve(false);
            }
        })
    }

    async function pullExerciseCompatiblity(forID: string) {
        return new Promise<boolean>( async (resolve, reject) => {
            try {
                let thisPath = `exercises/${forID}/node_compatible`
                let database = firebase.database();
                let ref = database.ref(thisPath); 
                let res = await ref.once('value');

                if (res.exists()) {
                    resolve(res.val() ?? true);
                } else {
                    resolve(true);
                }
            } catch (err) {
                resolve(true);
            }
        })
    }


    const [thisDataStreamTS, setThisDataStreamTS] = useState(-1);

    function pullDataStream(forSessionID: string) {

        var pullPath = '';
        if (selectedExerciseSession === undefined) {
            pullPath = `node_stream_data/abcd/recordings/${forSessionID}`;
        } else {
            pullPath = `node_stream_data/logs/${selectedExerciseSession.id}/sessions/${forSessionID}`
        }

        let database = firebase.database();
        let ref = database.ref(pullPath);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                //console.log(data);
                let ts = data.timestamp;
                if (ts !== undefined) {
                    let thisDate = new Date(ts);
                    setThisDataStreamTS(ts);
                    let timeString = `${thisDate.getHours()}:${thisDate.getMinutes()}:${thisDate.getSeconds()}`
                    setRecordingTimeString(`${wkDayStrings[thisDate.getDay()]} ${moStrings[thisDate.getMonth()]} ${thisDate.getDate()} at ${timeString}`)
                }


                if (data.data_stream !== undefined) {
                    var motionData_temp: MotionData_t[][] = [];
                    let repData = data.data_stream;
                    for (var i = 0; i < 9; i++) {
                        let thisLocationData = repData[`${i}`];
                        if (thisLocationData === undefined) {
                            motionData_temp.push([]);
                        } else {
                            motionData_temp.push(thisLocationData);
                        }
                    }

                    // console.log("data.data_stream:", repData, motionData_temp);
                    setCurrentSetMotionData(motionData_temp);

                } else {
                    var repStats_temp: RepStats_t[] = [];
                    let repData = data.reps;
                    if (repData !== undefined) {
                        let repKeys = Object.keys(repData);
                        for (var i = 0; i < repKeys.length; i++) {
                            let thisRepKey = repKeys[i];
                            let thisRepData: RepStats_t = repData[thisRepKey].data;
                            repStats_temp.push(thisRepData);
                        }
                    }
                    //// console.log("setCurrentSetRepStats: ", repStats_temp)
                    setCurrentSetRepStats(repStats_temp);
                }
                

                /*
                
                */
            }
        });
    }

    function getVideoDuration() {
        if (!videoPresent || videoRef === null || videoRef.current === null) { return 0; }
        //// console.log("DURATION:", videoRef.current)


        return `${Math.floor(videoRef.current.duration * 100) / 100}s`;
    }

    function handleOnTimeUpdate() {
        const progress = (videoRef.current.currentTime / videoRef.current.duration);
        setVideoProgress(progress);
        //// console.log("VIDEO PROGRESS:", progress);
    }

    function updateVideoProgressByMouse(p: number) {
        if (videoPresent) {
            let totalDuration = videoRef.current.duration;
            let thisTime = p * totalDuration;
            videoRef.current.currentTime = thisTime;
        } else {
            //// console.log("setVideoProgress", p)
            setVideoProgress(p);
        }
        
    }

    function getVideoCompletion() {

        if (!videoRef || !videoRef.current || !videoRef.current.currentTime) { return '0' }

        return `${Math.floor(videoRef.current.currentTime * 10) / 10}s (${Math.floor(videoRef.current.currentTime * 1000 / videoRef.current.duration) / 10})%`
    }

    function getCurrentPointHover() {

        if (!videoPresent) {
            return Math.floor(videoProgress * numPts);
        }

        if (!videoRef || !videoRef.current || !videoRef.current.currentTime) { return '0' }
        const progress = (videoRef.current.currentTime / videoRef.current.duration);

        //setHoverPoint(progress * numPts);

        return Math.floor(progress * numPts);
    }

    function updatedMotionData(m: MotionData_t) {
        setLatestMotionData(m);
    }

    function getCurrentRepStateString() {
        var stateStrings = ["Initialzation" , "First Pause", "Concentric", "Second Pause", "Eccentric"];
        return `${latestMotionData.acceleration.z} | ${stateStrings[latestMotionData.acceleration.z]}`;
    }

    
    function getSessions() {
        return selectedExerciseSession === undefined ? [] : selectedExerciseSession.sessions;
    }    

    function getTimestampString(forTS: number) {
        let newDate = new Date(forTS);

        let mins = `${newDate.getMinutes() < 10 ? '0' : ''}${newDate.getMinutes()}`;

        let hrs = newDate.getHours();
        let hrsAdj = hrs % 12;

        let dayString = `${wkDayStrings[newDate.getDay()]} ${moStrings[newDate.getMonth()]} ${newDate.getDate()}`
        let timeString = `${hrsAdj}:${mins}${hrs >= 12 ? 'PM' : 'AM'}`
        return `${dayString}, ${timeString}`;
    }

    function clearAllNodeStreamData() {
        /*
        let database = firebase.database();
        let ref = database.ref(`node_stream_data/exercises`);
        ref.set(null);

        let ref_b = database.ref(`node_stream_data/logs`);
        ref_b.set(null);
        */
    }

    function saveCSVData() {
        if (selectedExerciseSession === undefined) { return; }



        let thisExerciseID = selectedExerciseSession.id; //"-N69Fi-NMz0-urrzbSNl" //




        // 1. Pull existing data length for this exercise

        let database = firebase.database();
        //let ref = database.ref(`ml_data/exercises/${thisExerciseID}/length`);
        let ref = database.ref(`ml_data/exercises/${thisExerciseID}/locations/${`${viewingLocationIndex}`}/length`);
        
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                // Data exists already. Just need to append this data onto existing data
                let data = snapshot.val();
                saveDataWithLength(data, thisExerciseID);
            } else {
                // No data exists yet. Need to create from scratch
                saveDataWithLength(0, thisExerciseID);
            }
        });
    }

    async function saveDataWithLength(l: number, e_id: string) {
        let thisCSVDataLength = dataForCSV.length - 1;    // Don't include the header row in calculations
        var startingIndex = l;
        var endingIndex = l + thisCSVDataLength;

        let database = firebase.database();
        let ref = database.ref(`ml_data/exercises/${e_id}/locations/${`${viewingLocationIndex}`}`);

        // console.log("---- WILL BEGIN PUSHING DATA ----", e_id, viewingLocationIndex);

        for (var i = 1; i < dataForCSV.length; i++) {
            let thisDataRow: any = dataForCSV[i];
            let dbIndex = (i - 1) + l;
            await ref.child(`data/${dbIndex}`).set(thisDataRow);
            let completion = Math.floor((i / dataForCSV.length) * 1000) / 10;
            setSavingProgress(completion);
            // console.log(`\t\tSaving ${i} of ${dataForCSV.length} at index ${dbIndex}\t\t${completion}`);
        }

        setSavingProgress(100);

        ref.child('length').set(endingIndex);
        ref.child('last_updated').set(Date.now());


        let ref_ca = database.ref(`node_stream_data/exercises/${e_id}/session_ids/${thisSessionID}/analysis_complete`);
        ref_ca.set(true);

        // Move this session to "completed_analysis" in database
        /*
        let ref_ex = database.ref(`node_stream_data/exercises/${e_id}/session_ids/${thisSessionID}`);
        ref_ex.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                let ref_ca = database.ref(`node_stream_data/completed_analysis/${e_id}/session_ids/${thisSessionID}`);
                ref_ca.set(data);

                ref_ex.set(null);

                
                testPullingIDs();
            }
        });
        */
    }

    function openExerciseInFirebase() {
        if (selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "" || targetMetrics === undefined) { return; }
        let thisExerciseID = selectedExerciseSession.id;

        window.open(`https://console.firebase.google.com/u/0/project/movement-tracker-457bc/database/movement-tracker-457bc/data/~2Fexercises~2F${thisExerciseID}`);
    }

    function saveTargetData() {
        if (selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "" || targetMetrics === undefined) { return; }
        let thisExerciseID = selectedExerciseSession.id;

        // console.log("WILL SAVE TO:", `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/motion_info/target`);
        setIsLoadingTargetData(true);
        let database = firebase.database();
        let ref_ca = database.ref(`exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/motion_info/target`);
        ref_ca.set(targetMetrics);
        setTimeout(() => { 
            setIsLoadingTargetData(false); 
            setShowSaveTargetMetricsButton(false);
        }, 500); 
    }

    function saveExerciseInfoData() {
        if (selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "" || thisExerciseTitle === undefined || thisExerciseTitle.split("").length < 3) { return; }
        let thisExerciseID = selectedExerciseSession.id;

        let thisTitle = thisExerciseTitle.trim();
        //// console.log("WILL SAVE TO:", `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/motion_info/target`);
        setIsLoadingTargetData(true);
        setThisExerciseTitleEdited(false);

        let database = firebase.database();
        let loc = `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/title`;
        let ref_title = database.ref(loc);
        // console.log("SAVING TO:", loc, thisExerciseID, thisTitle);
        ref_title.set(thisExerciseTitle);

        let loc_approval = `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/approved`;
        let ref_approval = database.ref(loc_approval);
        ref_approval.set(exerciseApproved);

        let loc_compatible = `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/node_compatible`;
        let ref_compatible = database.ref(loc_compatible);
        ref_compatible.set(exerciseCompatibleWithNodes);

        setTimeout(() => { 
            setIsLoadingTargetData(false); 
        }, 500);  
    }

    async function saveMotionInfo() {
        if (exerciseMotionData === undefined || selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "" || thisExerciseTitle === undefined || thisExerciseTitle.split("").length < 3) { return; }
        let thisExerciseID = selectedExerciseSession.id;

        let thisTitle = thisExerciseTitle.trim();
        //// console.log("WILL SAVE TO:", `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/motion_info/target`);
        setIsLoadingTargetData(true);
        setThisExerciseMotionInfoEdited(false);

        let database = firebase.database();
        let loc_root = `exercises/${thisExerciseID === "" ? "_ERROR_" : thisExerciseID}/motion_info`;
        let ref_root = database.ref(loc_root);

        // console.log("saveMotionInfo SAVING TO:", loc_root, thisTitle)
        
        await ref_root.child('counting').set({
            type: exerciseMotionData.counting !== undefined && exerciseMotionData.counting.type !== undefined && (exerciseMotionData.counting.type === "mirrored" || exerciseMotionData.counting.type === "alternating" || exerciseMotionData.counting.type === "single_side") ? exerciseMotionData.counting.type : "mirrored",
            minimum_monitor_locations: exerciseMotionData.counting !== undefined && exerciseMotionData.counting.minimum_monitor_locations !== undefined ? exerciseMotionData.counting.minimum_monitor_locations : [],
            auxiliary_monitor_locations: exerciseMotionData.counting !== undefined && exerciseMotionData.counting.auxiliary_monitor_locations !== undefined ? exerciseMotionData.counting.auxiliary_monitor_locations : [],
        })

        await ref_root.child('body_weight_by_default').set(exerciseMotionData.body_weight_by_default !== undefined ? exerciseMotionData.body_weight_by_default : false)
        await ref_root.child('leading_concentric').set(exerciseMotionData.leading_concentric !== undefined ? exerciseMotionData.leading_concentric : false)
        
        setTimeout(() => { 
            setIsLoadingTargetData(false); 
        }, 500);  
    }

    function goBackPressed() {
        //setSelectedExerciseSession(undefined) 
        setRecordingTimeString("Loading...");
        setVideoPresent(false);
        setImgUrl('');
        setThisSessionID('');
        setPointOffset(0)
        setCurrentSetRepStats(currentSetRepStats.filter((item, index) => { return false; }));
        setCurrentSetMotionData(currentSetMotionData.filter((item, index) => { return false; }));
        setVideoProgress(0);
        setNumPts(1);
        setLatestMotionData({
            quaternion: {w: 1, x: 0, y: 0, z: 0},
            acceleration: {x: 0, y: 0, z: 0},
            timestamp: Date.now()
        });
        setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }))
        setQStream(qStream.filter((item, index) => { return false; }))
        setAddingRepWindows(false);
        setWindowsAreReps(false);
        setDataForCSV(dataForCSV.filter((item, index) => { return false; }))
    }

    function deleteSessionPressed(item: RecordedSession_t, index: number) {
        let selectedSessionID = item.id;

        // console.log("Deleting session at:",selectedExerciseSession, selectedSessionID)

        if (selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "") { return; }

        let database = firebase.database();
        let ref_exercises = database.ref(`node_stream_data/exercises/${selectedExerciseSession.id}/session_ids/${selectedSessionID}`);
        ref_exercises.set(null);

        let ref_logs = database.ref(`node_stream_data/logs/${selectedExerciseSession.id}/sessions/${selectedSessionID}`);
        ref_logs.set(null);

        // console.log("Deleted session at:",selectedExerciseSession, selectedSessionID)

        testPullingIDs(true);

    }

    function updateMotionInfo(parameter: string, value: any) {
        switch(parameter) {
            case "counting_type":
                setExerciseMotionData({ ...exerciseMotionData, counting: { ...exerciseMotionData.counting, type: value } })
                break;

            case "leading_concentric": 
                setExerciseMotionData({ ...exerciseMotionData, leading_concentric: value })
                break;

            case "body_weight_by_default": 
                setExerciseMotionData({ ...exerciseMotionData, body_weight_by_default: value })
                break;
            case "mandatory_location":
                let existingList = exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.minimum_monitor_locations ? exerciseMotionData.counting.minimum_monitor_locations : [];
                let currentIndex = existingList.indexOf(value);
                var newList = existingList;
                if (currentIndex >= 0) {
                    // Already included, turn this location off
                    newList.splice(currentIndex, 1);
                } else {
                    // Not included, add this location
                    newList.push(value);
                }

                setExerciseMotionData({ ...exerciseMotionData, counting: { ...exerciseMotionData.counting, minimum_monitor_locations: newList } })
                break;

            case "auxiliary_location":
                let existingListAux = exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.auxiliary_monitor_locations ? exerciseMotionData.counting.auxiliary_monitor_locations : [];
                let currentIndexAux = existingListAux.indexOf(value);
                var newListAux = existingListAux;
                if (currentIndexAux >= 0) {
                    // Already included, turn this location off
                    newListAux.splice(currentIndexAux, 1);
                } else {
                    // Not included, add this location
                    newListAux.push(value);
                }

                setExerciseMotionData({ ...exerciseMotionData, counting: { ...exerciseMotionData.counting, auxiliary_monitor_locations: newListAux } })

                break;
            
        }

        setThisExerciseMotionInfoEdited(true);
    }

    function configureMLParams() {

        if (selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "") { return; }
        let thisExerciseID = selectedExerciseSession.id;

        let params: any = {
            "leading_concentric": false,
            "analysis_vector": {"x": 0, "y": 1, "z": 0},
            "analyze_vector": true,
            "body_weight_by_default": true,
            "weight_scaler": 0.5,
            "lever": {
                "segment_id": "full_arm",
                "default_length": 0.6,
            },
            "counting": {
                "type": "mirrored",                        // Options: "alternating", "mirrored", "single"
                "minimum_monitor_locations": [ 2, 3 ],        // Locations needed to count reps
                "auxiliary_monitor_locations": [ 0, 1, 9 ]       // Additional locations if enough Nodes available
            }
        }

        let database = firebase.database();
        //let ref = database.ref(`ml_data/params/${thisExerciseID}`);
        let ref = database.ref(`exercises/${thisExerciseID}/motion_info`);
        ref.set(params);
    }

    function getNumCompletedSessions(ex: RecordedExercise_t) {

        var numComplete = 0;
        if (ex && ex.sessions) {
            for (var i = 0; i < ex.sessions.length; i++) {
                if (ex.sessions[i] && ex.sessions[i].analysis_complete) numComplete += 1
            }
        }

        return numComplete;
    }

    function updateExerciseApproval(toState: boolean) {
        setExerciseApproved(toState);
        setThisExerciseTitleEdited(true);
    }

    function updateExerciseCompatibleWithNodes(toState: boolean) {
        setExerciseCompatibleWithNodes(toState);
        setThisExerciseTitleEdited(true);
    }

    

    function inputChanged(newValTemp: string, statName: "rom" | "velocity" | "eccentric" | "power_normalized" | "title", param?: "min" | "max") {

        let newVal = Number(newValTemp)
        // console.log("Input changed: newVal", newValTemp, newVal, statName, param);
        if (isNaN(newVal) && statName !== "title") return;

        switch (statName) {
            case "rom":
                setTargetMetrics({ ...targetMetrics, rom: { min: param === "min" ? newVal : targetMetrics.rom.min, max: param === "max" ? newVal : targetMetrics.rom.max } })
                break;
            case "velocity":
                setTargetMetrics({ ...targetMetrics, velocity: { min: param === "min" ? newVal : targetMetrics.velocity.min, max: param === "max" ? newVal : targetMetrics.velocity.max } })
                break;
            case "eccentric":
                setTargetMetrics({ ...targetMetrics, eccentric: { min: param === "min" ? newVal : targetMetrics.eccentric.min, max: param === "max" ? newVal : targetMetrics.eccentric.max } })
                break;
            case "power_normalized":
                setTargetMetrics({ ...targetMetrics, power_normalized: { min: param === "min" ? newVal : targetMetrics.power_normalized.min, max: param === "max" ? newVal : targetMetrics.power_normalized.max } })
                break;
            case "title":
                setThisExerciseTitle(newValTemp);
                setThisExerciseTitleEdited(true);
                break;
            default:
                break;
        }

        setShowSaveTargetMetricsButton(true);
    }


    async function trainModel() {

        /*
            value incorrect for index 552, 588. Fixing to 448.
            value incorrect for index 557, 468. Fixing to 448.
            value incorrect for index 780, 464. Fixing to 448.
            value incorrect for index 786, 496. Fixing to 448.
            value incorrect for index 846, 700. Fixing to 448.
            value incorrect for index 851, 560. Fixing to 448.
        
        let newData = []
        
        let incorrectIndecies = [552,557,780,786,846,851]
        let location = '/ml_data/exercises/-Mu9gFRssbfLvS5q_rPS/locations/0/data';
        let database = firebase.database();
        for (var i = 0; i < incorrectIndecies.length; i++) {
            let thisIndex = incorrectIndecies[i]
            let thisLoc = `${location}/${thisIndex}`;
            // console.log(thisLoc)
            let ref = database.ref(thisLoc);
            await ref.set(newData);
        }
        */

        
        
        if (modelTrained === true || isTrainingModel === true || selectedExerciseSession === undefined || selectedExerciseSession.id === undefined || selectedExerciseSession.id === "") { return; }
        let thisExerciseID = selectedExerciseSession.id;

        setIsTrainingModel(true);

        var locationResults: any[] = [];

        for (var i = 0; i < 9; i++) {
            setModelTrainIndex(i);
            let sendData = {
                "exercise_id": selectedExerciseSession.id,
                "location_id": `${i}`,
                "epochs": 250
            };

            let rootURL = `https://www.eigenfitnessapi.com:5000/train_model` // `http://ec2-18-236-132-162.us-west-2.compute.amazonaws.com:5000/train_model`
            try {
                let resp = await axios.post(
                    rootURL, 
                    sendData,
                    { headers: {'Content-Type': 'application/json'} });

                if (resp && resp.data) {
                    if (resp.data.status === "complete") {
                        // console.log(`Complete ${ i + 1 } / 9! Trained location ${ i } for selectedExerciseSession.id`, resp.data)
                        locationResults.push({
                            location: i,
                            status: "complete",
                            training: {
                                accuracy: resp.data && resp.data.training && resp.data.training.accuracy ? resp.data.training.accuracy : -1,
                                loss: resp.data && resp.data.training && resp.data.training.loss ? resp.data.training.loss : -1,
                                epochs: resp.data && resp.data.training && resp.data.training.epochs ? resp.data.training.epochs : 250,
                                duration: resp.data && resp.data.training && resp.data.training.duration ? resp.data.training.duration : -1,
                            },
                            num_entries: {
                                motion: resp.data && resp.data.num_entries && resp.data.num_entries.motion ? resp.data.num_entries.motion : 0,
                                stationary: resp.data && resp.data.num_entries && resp.data.num_entries.stationary ? resp.data.num_entries.stationary : 0,
                                total: resp.data && resp.data.num_entries && resp.data.num_entries.total ? resp.data.num_entries.total : 0,
                            }
                        });
                        
                    } else {
                        // console.log(`Complete ${ i + 1 } / 9! Failed to find data at location ${ i } for selectedExerciseSession.id`, resp.data);
                        locationResults.push({
                            location: i,
                            status: "failed",
                            num_entries: {
                                motion: 0,
                                stationary: 0,
                                total: 0,
                            }
                        })
                    }
                }

            } catch (err) {
                // console.log(`Error occured trying to train model:`, err)
                setModelTrainedError(true);
                i = 8;
                setModelTrainIndex(i);
                locationResults.push({
                    location: i,
                    status: "failed",
                    num_entries: {
                        motion: 0,
                        stationary: 0,
                        total: 0,
                    }
                })
            }

        }
        setModelTrained(true);
        setIsTrainingModel(false);
        saveModelTrainInfo(selectedExerciseSession.id, locationResults)
        
    }

    async function saveModelTrainInfo(exercise_id: string, location_results: any[]) {

        if (!exercise_id || exercise_id === "") return; 

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let saveLocation = `/ml_data/results/${exercise_id}`;
        let saveData = {
            trained_by: trainerUID,
            trained_on: Date.now(),
            results: location_results
        }

        let database = firebase.database();
        let ref = database.ref(saveLocation);
        ref.set(saveData);
    }

    async function pause(duration: number) {
        return new Promise<null>((resolve, reject) => {
            setTimeout(() => {
                resolve(null)
            }, duration)
        })
    }

    function getTrainedString(ts: number) {
        let thisDate = new Date(ts);
        let this_mo = thisDate.getMonth();
        let this_dy = thisDate.getDate();
        let this_yr = thisDate.getFullYear();

        let this_hr = thisDate.getHours();
        let this_hr_adj = this_hr % 12;
        this_hr_adj = this_hr_adj === 0 ? 12 : this_hr_adj;
        let this_min = thisDate.getMinutes();

        return `${this_mo < 10 ? '0' : ''}${this_mo}-${this_dy < 10 ? '0' : ''}${this_dy}-${this_yr}, ${this_hr_adj < 10 ? '0' : ''}${this_hr_adj}:${this_min < 10 ? '0' : ''}${this_min}${this_hr < 12 ? 'am' : 'pm'}`
    }

    const handleFileDropped = (files: FileList | null, event: any) => {
        // console.log(">>handleFileDropped:", files ? files[0] : "NADA");

        if (!selectedExerciseSession || selectedExerciseSession.id === "") return;

        setVideoFile(files ? files : null);

        // Upload to temp
        if (files === null) return;
        
        if (!selectedExerciseSession || selectedExerciseSession.id === "") return;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        
        const file = files[0];
        setUploadStatus(`File ${files[0].name} uploaded successfully`);
        setUploadProgress(0);
        const storageRef = firebase.storage().ref(`videos/exercise/${selectedExerciseSession.id}/video.mov`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                // Handle upload progress
                setIsUploadingVideo(true);
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(Math.floor(progress));
                // console.log(`Upload progress: ${progress}%`);
            },
            (error) => {
                // Handle upload error
                setIsUploadingVideo(false);
                // console.log('Upload error: ', error);
                setUploadProgress(0);
            },
            async () => {
                // Handle successful upload
                // console.log('Upload completed');
                // Get the URL of the uploaded video from Firebase Storage
                
                
                const uploadedVideoUrl = await storageRef.getDownloadURL();
                setUploadedVideoUrl(uploadedVideoUrl);
                setVideoUrl(uploadedVideoUrl)
                
                setTimeout(() => {
                    setIsUploadingVideo(false);
                    setUploadProgress(0);
                    setIsCustomVideo(false);
                }, 1000)
                // console.log('Here is the video link', uploadedVideoUrl);
            }
        );
    }

    async function removeCustomVideo() {
        if (!selectedExerciseSession || selectedExerciseSession.id === "") return;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setIsCustomVideo(false);
        setLoadingVideo(true);
        setUploadedVideoUrl("");
        const storageRef = firebase.storage().ref(`videos/exercise/${selectedExerciseSession.id}/video.mov`);
        try {
            let res = await storageRef.delete();
            setTimeout(() => {
                setLoadingVideo(false);
                updateVideo();
            }, 1000);
        } catch (err) {
            setLoadingVideo(false);
            updateVideo();
        }
        
    }

    function updateVideo() {
        if (selectedExerciseSession && selectedExerciseSession.id !== "") {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { return; }
            let trainerUID = currentUser.uid;
            setIsCustomVideo(false);
            setLoadingVideo(true);

            let storage = firebase.storage();
            let storageRef_trainer = storage.ref(`videos/exercise/${selectedExerciseSession.id}/video.mov`);
            storageRef_trainer.getDownloadURL()
            .then(fireBaseUrl_trainer => {
                setVideoUrl(fireBaseUrl_trainer);
                setLoadingVideo(false);
                setIsCustomVideo(false);
            })
            .catch(error => {
                // No video found
                setIsCustomVideo(false);
                setVideoUrl("");
                setLoadingVideo(false);
           })
        }
    }

	return (
		<div className="node-stream-analysis">
            <div hidden={!isLoading} className="home-container-clients-column-container-right-loading-curtain">
                <div className="create-workout-exercise-configure-loading-spinner"/>
                <p>Loading ML Logs</p>
                <p>{ percentLoaded }{ percentLoaded % 1 === 0 ? '.0' : '' }%</p>
            </div>
            <div hidden={thisSessionID !== "" || isLoading} className="node-stream-analysis-upper-container node-stream-analysis-upper-container-select-session">
                <div className="node-stream-analysis-upper-container-select-session-header">
                    <div hidden={selectedExerciseSession === undefined} onClick={() => setSelectedExerciseSession(undefined)} className="node-stream-analysis-upper-container-select-session-header-back-container">
                        <div className="node-stream-analysis-upper-container-select-session-header-back">
                            <ArrowBackIos className="node-stream-analysis-upper-container-select-session-header-back-icon"/>
                        </div>
                        <p>Back to all exercises</p>
                    </div>
                    <div className="node-stream-analysis-upper-container-select-session-header-content-container">
                        <h4>Select {selectedExerciseSession === undefined ? 'an Exercise' : `a Session from ${selectedExerciseSession === undefined ? '' : selectedExerciseSession.title} | ${selectedExerciseSession.id}`}</h4>
                       {/* <div hidden={selectedExerciseSession === undefined} onClick={() => configureMLParams()} className="node-stream-analysis-upper-container-select-session-header-button">
                            <p>Configure ML params</p>
                        </div>*/}
                        <div hidden={selectedExerciseSession !== undefined} onClick={() => setRecentFilter(!recentFilter)} className="node-stream-analysis-upper-container-select-session-header-button">
                            <p>{ recentFilter ? "Show All" : "Show Recent" }</p>
                        </div>
                        <div hidden={selectedExerciseSession === undefined} onClick={() => setShowParams(!showParams)} className="node-stream-analysis-upper-container-select-session-header-button">
                            <p>{ showParams ? "Hide Parameters" : "Show Parameters" }</p>
                        </div>
                        <div hidden={selectedExerciseSession === undefined} onClick={() => navigator.clipboard.writeText(selectedExerciseSession ? selectedExerciseSession.id : "")} className="node-stream-analysis-upper-container-select-session-header-button">
                            <p>Copy ID</p>
                        </div>
                        {/*<div hidden={selectedExerciseSession === undefined} onClick={() => navigator.clipboard.writeText(selectedExerciseSession ? `${selectedExerciseSession.title} | ${selectedExerciseSession.id}` : "")} className="node-stream-analysis-upper-container-select-session-header-button">
                            <p>Copy Name + ID</p>
                        </div>*/}
                        <div hidden={selectedExerciseSession === undefined} onClick={() => trainModel()} className={`node-stream-analysis-upper-container-select-session-header-button node-stream-analysis-upper-container-select-session-header-button-blue ${ modelTrainedError ? 'node-stream-analysis-upper-container-select-session-header-button-red' : ''}`}>
                            {
                                isTrainingModel ?
                                <p>{ modelTrainedError ? "ERROR! " : "" }Training { modelTrainIndex }...</p>
                                :
                                <p>{ modelTrainedError ? "ERROR! " : modelTrained ? "TRAINED!" : "TRAIN MODEL" }</p>
                            }
                        </div>
                        <div hidden={selectedExerciseSession !== undefined} className={ "node-stream-analysis-upper-container-system-status-container" }>
                            <div className={ `node-stream-analysis-upper-container-system-status-icon ${ serverOK ? "node-stream-analysis-upper-container-system-status-icon-green" : "node-stream-analysis-upper-container-system-status-icon-red" }` }></div>
                            <div className={ "node-stream-analysis-upper-container-system-status-text-container" }>
                                <p>{ serverOK ? "Server is Live" : "Server Error" }</p>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    showParams && selectedExerciseSession !== undefined &&
                    <div className={ "node-stream-analysis-upper-params-container" }>
                       <div className={ "node-stream-analysis-upper-params-header" }>
                            <h4>General Exercise Info for { selectedExerciseSession === undefined ? 'this Exercise' : selectedExerciseSession.title }. <span onClick={ () => openExerciseInFirebase() }>View in Firebase</span></h4>
                            <p>Enter all general exercise info here</p>
                            {
                                thisExerciseTitleEdited &&
                                <div onClick={ () => saveExerciseInfoData() } className={ "node-stream-analysis-upper-params-header-save-button" }>
                                    <p>Save Changes</p>
                                </div>
                            }
                        </div>
                        {
                            isLoadingTargetData ?
                            <div className={ "node-stream-analysis-upper-params-body node-stream-analysis-upper-params-body-loading" }>
                                <div className="create-workout-exercise-configure-loading-spinner"/>
                                <p>Saving Exercise Info...</p>
                            </div>
                            :
                            <div className={ "node-stream-analysis-upper-params-body" }>
                                <div className={ "node-stream-analysis-upper-params-body-header" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>PARAMETER NAME</p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <p>VALUE</p>
                                    </div>
                                </div> 
                                
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Exercise Name<span>string</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container node-stream-analysis-upper-params-body-input-container-lg" }>
                                            <IonInput 
                                                value={ thisExerciseTitle } 
                                                placeholder="Exercise Title..."
                                                inputmode="text"
                                                clearOnEdit={true}
                                                autofocus={false}
                                                type="text"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "title")}/>
                                        </div>
                                    </div> 
                                </div>  
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Approved for Coaches <span>boolean</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div onClick={ () => updateExerciseApproval(true) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseApproved ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                            <p>Approved</p>
                                        </div>
                                        <div onClick={ () => updateExerciseApproval(false) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ !exerciseApproved ? 'node-stream-analysis-upper-params-body-col-input-selector-selected-dark' : '' }` }>
                                            <p>Unapproved</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Compatible with Nodes <span>boolean</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div onClick={ () => updateExerciseCompatibleWithNodes(true) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseCompatibleWithNodes ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                            <p>Compatible</p>
                                        </div>
                                        <div onClick={ () => updateExerciseCompatibleWithNodes(false) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ !exerciseCompatibleWithNodes ? 'node-stream-analysis-upper-params-body-col-input-selector-selected-dark' : '' }` }>
                                            <p>Not Compatible</p>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        }
                    </div>
                }
                {
                    showParams && selectedExerciseSession !== undefined &&
                    <div className={ "node-stream-analysis-upper-params-container" }>
                       <div className={ "node-stream-analysis-upper-params-header" }>
                            <h4>Configure Target Metrics for { selectedExerciseSession === undefined ? 'this Exercise' : selectedExerciseSession.title }</h4>
                            <p>These metrics are used to calculate the real-time performance score.</p>
                            <div onClick={ () => saveTargetData() } hidden={ isLoadingTargetData || !showSaveTargetMetricsButton } className={ "node-stream-analysis-upper-params-header-save-button" }>
                                <p>Save Metrics</p>
                            </div>
                        </div> 
                        {
                            isLoadingTargetData ?
                            <div className={ "node-stream-analysis-upper-params-body node-stream-analysis-upper-params-body-loading" }>
                                <div className="create-workout-exercise-configure-loading-spinner"/>
                                <p>Loading Target Data...</p>
                            </div>
                            :
                            <div className={ "node-stream-analysis-upper-params-body" }>
                                <div className={ "node-stream-analysis-upper-params-body-header" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>METRIC NAME</p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <p>MAXIMUM</p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                        <p>MINIMUM</p>
                                    </div> 
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Range of Motion <span>[º]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.rom.min } 
                                                placeholder="40..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "rom", "min")}/>
                                        </div>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.rom.max } 
                                                placeholder="50..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "rom", "max")}/>
                                        </div>
                                    </div> 
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Concentric Velocity <span>[m/s]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.velocity.min } 
                                                placeholder="40..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "velocity", "min")}/>
                                        </div>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.velocity.max } 
                                                placeholder="50..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "velocity", "max")}/>
                                        </div>
                                    </div> 
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Eccentric Time <span>[s]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.eccentric.min } 
                                                placeholder="40..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "eccentric", "min")}/>
                                        </div>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.eccentric.max } 
                                                placeholder="50..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "eccentric", "max")}/>
                                        </div>
                                    </div> 
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Norm. Power <span>[W/kg]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.power_normalized.min } 
                                                placeholder="40..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "power_normalized", "min")}/>
                                        </div>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <IonInput 
                                                value={ targetMetrics.power_normalized.max } 
                                                placeholder="50..."
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                autofocus={true}
                                                type="number"
                                                onKeyPress={e => null}
                                                onIonChange={e => inputChanged(e.detail.value!, "power_normalized", "max")}/>
                                        </div>
                                    </div> 
                                </div> 
                            </div> 
                        }
                    </div>
                }
                {
                    showParams && selectedExerciseSession !== undefined && exerciseMotionData !== undefined && exerciseMotionData.counting !== undefined &&
                    <div className={ "node-stream-analysis-upper-params-container" }>
                       <div className={ "node-stream-analysis-upper-params-header" }>
                            <h4>Motion Info for { selectedExerciseSession === undefined ? 'this Exercise' : selectedExerciseSession.title }</h4>
                            <p>This object is used to when calculating real-time performance stats.</p>
                            <div hidden={ !thisExerciseMotionInfoEdited } onClick={ () => saveMotionInfo() } className={ "node-stream-analysis-upper-params-header-save-button" }>
                                <p>Save Changes</p>
                            </div>
                        </div>
                        {    
                            isLoadingTargetData ?
                            <div className={ "node-stream-analysis-upper-params-body node-stream-analysis-upper-params-body-loading" }>
                                <div className="create-workout-exercise-configure-loading-spinner"/>
                                <p>Saving Motion Info...</p>
                            </div>
                            :
                            <div className={ "node-stream-analysis-upper-params-body" }>
                                <div className={ "node-stream-analysis-upper-params-body-header" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>PARAMETER NAME</p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col-header node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <p>VALUE</p>
                                    </div>
                                </div> 
                                
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        {    
                                            exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.minimum_monitor_locations && exerciseMotionData.counting.minimum_monitor_locations.length > 2 ?
                                            <h4>LIMIT TO 2!</h4>
                                            :
                                            <p>Mandatory Locations <span>[number[]]</span></p>
                                        }
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        {
                                            validLocationIndecies.map((item: number, index: number) => (
                                                <div onClick={ () => updateMotionInfo("mandatory_location", item) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.minimum_monitor_locations && exerciseMotionData.counting.minimum_monitor_locations.includes(item) ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                    <p>{ item }</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {/*<div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <h4>{exerciseMotionData && exerciseMotionData.counting !== undefined && exerciseMotionData.counting.minimum_monitor_locations !== undefined ? JSON.stringify(exerciseMotionData.counting.minimum_monitor_locations) : "No data"}</h4>
                                        </div>
                                    </div>*/}
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Optional Locations <span>[number[]]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        {
                                            validLocationIndecies.map((item: number, index: number) => (
                                                <div onClick={ () => updateMotionInfo("auxiliary_location", item) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.auxiliary_monitor_locations && exerciseMotionData.counting.auxiliary_monitor_locations.includes(item) ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                    <p>{ item }</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Counting Type <span>[string]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                            <div onClick={ () => updateMotionInfo("counting_type", "mirrored") } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.type === "mirrored" ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                <p>Mirrored</p>
                                            </div>
                                            <div onClick={ () => updateMotionInfo("counting_type", "alternating") } className={ `node-stream-analysis-upper-params-body-col-input-selector ${  exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.type === "alternating" ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                <p>Alternating</p>
                                            </div>
                                            <div onClick={ () => updateMotionInfo("counting_type", "single_side") } className={ `node-stream-analysis-upper-params-body-col-input-selector ${  exerciseMotionData && exerciseMotionData.counting && exerciseMotionData.counting.type === "single_side" ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                <p>Unilateral</p>
                                            </div>
                                        </div>
                                        {/*<div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <h4>{exerciseMotionData && exerciseMotionData.counting !== undefined && exerciseMotionData.counting.type !== undefined ? exerciseMotionData.counting.type : "No data"}</h4>
                                        </div>*/}
                                    </div>
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Leading Phase <span>[boolean]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                            <div onClick={ () => updateMotionInfo("leading_concentric", true) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.leading_concentric === true ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                <p>Concentric</p>
                                            </div>
                                            <div onClick={ () => updateMotionInfo("leading_concentric", false) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.leading_concentric === false ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                                <p>Eccentric</p>
                                            </div>
                                        </div>
                                        {/*<div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <h4>{exerciseMotionData && exerciseMotionData.leading_concentric !== undefined ? (exerciseMotionData.leading_concentric ? "true" : "false") : "No data"}</h4>
                                        </div>*/}
                                    </div>
                                </div> 
                                <div className={ "node-stream-analysis-upper-params-body-row" }>
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                        <p>Default Weight Type<span>[boolean]</span></p>
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-max" }>
                                        <div onClick={ () => updateMotionInfo("body_weight_by_default", false) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.body_weight_by_default === false ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                            <p>Weighted</p>
                                        </div>
                                        <div onClick={ () => updateMotionInfo("body_weight_by_default", true) } className={ `node-stream-analysis-upper-params-body-col-input-selector ${ exerciseMotionData && exerciseMotionData.body_weight_by_default === true ? 'node-stream-analysis-upper-params-body-col-input-selector-selected' : '' }` }>
                                            <p>Bodyweight</p>
                                        </div>
                                        {/*<div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                            <h4>{exerciseMotionData && exerciseMotionData.body_weight_by_default !== undefined ? (exerciseMotionData.body_weight_by_default  ? "true" : "false") : "No data"}</h4>
                                        </div>*/}
                                    </div>
                                </div> 
                            </div> 
                        }
                    </div>
                }
                {
                    showParams && selectedExerciseSession !== undefined &&
                    <div className={ "node-stream-analysis-upper-params-container" }>
                       <div className={ "node-stream-analysis-upper-params-header" }>
                            <h4>Workout Video for { selectedExerciseSession === undefined ? 'this Exercise' : selectedExerciseSession.title }.</h4>
                            <p>Click and drag to upload a video. Be sure the video is muted.</p>
                        </div>
                        {
                            isLoadingTargetData ?
                            <div className={ "node-stream-analysis-upper-params-body node-stream-analysis-upper-params-body-loading" }>
                                <div className="create-workout-exercise-configure-loading-spinner"/>
                                <p>Saving Exercise Info...</p>
                            </div>
                            :
                            <div className={ "node-stream-analysis-upper-params-body node-stream-analysis-upper-params-body-flex" }>
                                <div className={ "create-workout-exercise-configure-video-container" }>
                                {    
                                    isUploadingVideo ?
                                    <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-loading" }>
                                        <p>Uploading Video...</p>
                                    </div>
                                    :
                                    loadingVideo ?
                                    <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-loading" }>
                                        <p>Loading Video...</p>
                                    </div>
                                    :
                                    videoUrl === "" ?
                                    <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-loading" }>
                                        <p>No video for this exercise</p>
                                    </div>
                                    :
                                    <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-video-container" }>
                                        <video controls={false} loop autoPlay muted>
                                            <source src={ uploadedVideoUrl && uploadedVideoUrl !== "" ? uploadedVideoUrl : videoUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                }
                            </div>

                            <div className={ "create-workout-exercise-configure-drop-area" }>
                                <FileDrop
                                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                    onDragOver={(event) => console.log('onDragOver', event)}
                                    onDragLeave={(event) => console.log('onDragLeave', event)}
                                    onDrop={(files, event) => handleFileDropped(files, event)}>
                                    <div className="create-workout-exercise-configure-drop-area-inner-container">
                                        <div className="create-workout-exercise-configure-drop-area-icon-container">
                                            <Image className="create-workout-exercise-configure-drop-area-icon" />
                                        </div>
                                        <h4>{isUploadingVideo ? `${uploadProgress}% Uploaded...` : "Drop your own video here to upload"}</h4>
                                        <p>Files less than 20MB are recommended, in .mp4 .mov formats.</p>
                                    </div>
                                </FileDrop>
                            </div>
                            </div> 
                        }
                    </div>
                }
                <div className="node-stream-analysis-upper-container-select-session-body">
                    <div hidden={selectedExerciseSession !== undefined} className="node-stream-analysis-upper-container-select-session-body-inner">
                        {
                            allExerciseSessions.map((item: RecordedExercise_t, index: number) => (
                                <div onClick={() => exerciseSelected(item)} className="node-stream-analysis-upper-container-select-session-body-list-cell node-stream-analysis-upper-container-select-session-body-list-cell-exercise">
                                    <div className={`node-stream-analysis-upper-container-select-session-body-list-cell-inner ${getNumCompletedSessions(item) === item.sessions.length ? 'node-stream-analysis-upper-container-select-session-body-list-cell-inner-complete' : ''}`}>
                                        <h4>{item.title} <span>{item.id}</span></h4>
                                        <p>{ item.sessions.length === 0 ? 0 : Math.floor(getNumCompletedSessions(item) / item.sessions.length * 100) }% { getNumCompletedSessions(item) < 10 ? '0' : ''}{ getNumCompletedSessions(item) } / {item.sessions.length} sessions</p>
                                        <div className={ "node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-container" }>
                                            {/*<div className={ `node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col ${item.hasMotionInfo ? "node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col-valid" : ""}` }>
                                                <p>{ item.hasMotionInfo ? "Has Motion Info" : "No Motion Info"}</p>
                                            </div>*/}
                                            <div className={ `node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col ${item.hasTargetData ? "node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col-valid" : ""}` }>
                                                <p>{ item.hasTargetData ? "Has Target Data" : "No Target Data"}</p>
                                            </div>
                                            <div className={ `node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col ${item.trainedTimestamp && item.trainedTimestamp > 0 ? "node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col-valid" : ""}` }>
                                                <p>{ item.trainedTimestamp && item.trainedTimestamp > 0 ? `Trained ${getTrainedString(item.trainedTimestamp)}` : "Not Trained"}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                    <div hidden={selectedExerciseSession === undefined} className="node-stream-analysis-upper-container-select-session-body-inner">
                        {
                           selectedExerciseSession === undefined ? null : selectedExerciseSession.sessions.map((item: RecordedSession_t, index: number) => (
                                <div className="node-stream-analysis-upper-container-select-session-body-list-cell node-stream-analysis-upper-container-select-session-body-list-cell-session">
                                    <div onClick={() => deleteSessionPressed(item, index)} className="node-stream-analysis-upper-container-select-session-body-list-cell-inner-delete-button">
                                        <p>DELETE</p>
                                    </div>
                                    <div onClick={() => sessionSelected(item)} className={`node-stream-analysis-upper-container-select-session-body-list-cell-inner ${item.analysis_complete === true ? 'node-stream-analysis-upper-container-select-session-body-list-cell-inner-complete' : ''}`}>
                                        <h4>{getTimestampString(item.timestamp)} <span>{item.id}</span></h4>
                                        <p>{item.recorded_by_id === "IDMl0c4MQbacldKQPN7D4Vq0eNz1" ? "CONNOR HOLOWACHUK" : item.recorded_by_id === "Xb4DcyyQJyTXwuUP9nZkrPq7k673" ? "CONNOR HOLOWACHUK (OLD ACCT)" : item.recorded_by_id === "nU93EH0guoMYKqg2qc4Wiooe1A73" ? "ZACH HIGGINS" : item.recorded_by_id}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div hidden={thisSessionID === "" || isLoading} className="node-stream-analysis-upper-container">
    			<div className="node-stream-analysis-video-container">
                    <NodeStreamAnalyzeAverageRep
                        locationIndex={ viewingLocationIndex }
                        selectedExerciseSession={ selectedExerciseSession }/>
                    {/*<video 
                        ref={videoRef} 
                        src={imgUrl} 
                        onTimeUpdate={handleOnTimeUpdate}
                        autoPlay 
                        loop 
                        controls/>*/}
                </div>
                
                {/*<div className="node-stream-analysis-3d-container">
                    <NodeStreamAnalyze3D 
                        latestMotionData={latestMotionData}/>
                </div>*/}
                <div className="node-stream-analysis-stream-container">
                    <NodeStreamAnalyzeStream 
                        qStream={qStream}
                        viewingLocationIndex={viewingLocationIndex}
                        numPts={numPts}
                        videoProgress={videoProgress}
                        bufferLength={112}
                        pointOffset={pointOffset}
                        mlCore={props.mlCore}
                        updateDetectedRepWindows={(w: RepWindow_t[]) => setDetectedRepWindows(w)}/>
                </div>
                <div className="node-stream-analysis-info-container">
                    <div onClick={() => goBackPressed()} className="node-stream-analysis-upper-container-select-session-header-back-container">
                        <div className="node-stream-analysis-upper-container-select-session-header-back">
                            <ArrowBackIos className="node-stream-analysis-upper-container-select-session-header-back-icon"/>
                        </div>
                        <p>Back to all exercises</p>
                    </div>
                    <h2>Node Set Analysis</h2>
                    <h4>{recordingTimeString}</h4>
                    <h4>{currentSetRepStats.length} reps</h4>
                    <h4>{getVideoCompletion()} of {getVideoDuration()}</h4>
                    <h4>{getCurrentPointHover()} | {numPts} total points</h4>
                    <h4>{getCurrentRepStateString()}</h4>
                    <div className="node-stream-analysis-info-button-container">
                        <div onClick={() => setAddingRepWindows(!addingRepWindows)} className={`node-stream-analysis-info-button ${addingRepWindows ? 'node-stream-analysis-info-button-red' : 'node-stream-analysis-info-button-blue'}`}>
                            <p>{addingRepWindows ? 'Done Adding Windows' : 'Add Windows'}</p>
                        </div>
                        <div onClick={() => setWindowsAreReps(!windowsAreReps)} hidden={!addingRepWindows} className={`node-stream-analysis-info-button ${!windowsAreReps ? 'node-stream-analysis-info-button-red' : 'node-stream-analysis-info-button-blue'}`}>
                            <p>{windowsAreReps ? 'Reps' : 'Stationary'}</p>
                        </div>
                        <div hidden={!addingRepWindows || !windowsAreReps || injuryData.length === 0} className={`node-stream-analysis-info-button-dropdown-wrapper`}>
                            <div onClick={() => setShowInjuryTypeDropdown(!showInjuryTypeDropdown)} className="node-stream-analysis-info-button">
                                <p>{selectedInjuryType === undefined ? "No Injury Selected" : selectedInjuryType.title}</p>
                            </div>
                            <div hidden={showInjuryTypeDropdown === false} className="node-stream-analysis-info-button-dropdown-container">
                                <div onClick={() => updateSelectedInjuryType(undefined)} className="node-stream-analysis-info-button-dropdown-row">
                                    <h4>Remove Injury</h4>
                                </div>
                                {    
                                    injuryData.map((item: Injury_t, index: number) => (
                                        <div onClick={() => updateSelectedInjuryType(item)} className="node-stream-analysis-info-button-dropdown-row">
                                            <h4>{item.title}</h4>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div 
                        hidden={dataForCSV.length === 0}
                        onClick={() => saveCSVData()} 
                        className="node-stream-analysis-info-button node-stream-analysis-info-button-blue">
                        <p>{savingProgress < 0 ? 'Save CSV to Database' : (savingProgress === 100 ? 'Done Saving' : `${savingProgress}%`)}</p>
                    </div>
                    <CSVLink     
                        hidden={dataForCSV.length === 0}
                        data={dataForCSV} 
                        filename={`node-data-${thisSessionID}-location-${viewingLocationIndex}.csv`}
                        className="node-stream-analysis-info-download-button">Download CSV</CSVLink>
                </div>
                
            </div>
            
            <div hidden={thisSessionID === ""} className="node-stream-analysis-chart-wrapper">
                <NodeStreamAnalyzeChart 
                    videoPresent={videoPresent}
                    videoProgress={videoProgress}
                    currentSetRepStats={currentSetRepStats}
                    currentSetMotionData={currentSetMotionData}
                    pointOffset={pointOffset}
                    thisSessionID={thisSessionID}
                    detectedRepWindows={detectedRepWindows}
                    addingRepWindows={addingRepWindows}
                    windowsAreReps={windowsAreReps}
                    selectedInjuryType={selectedInjuryType}
                    thisDataStreamTS={ thisDataStreamTS }
                    viewingLocationIndexUpdated={(e: number) => updateViewingLocationIndex(e)}
                    qStreamUpdated={(q: {w: number, x: number, y: number, z: number}[]) => setQStream(q)}
                    mousProgressUpdated={(p: number) => updateVideoProgressByMouse(p)}
                    updatedNumPts={(n: number) => setNumPts(n)}
                    updateCurrentMotionData={(m: MotionData_t) => updatedMotionData(m)}
                    updateCSVChartData={(d: any[]) => setDataForCSV(d)}/>
            </div>
		</div>
	)
}

export default NodeStreamAnalyze;