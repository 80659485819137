import React, { useState, useEffect } from 'react';
import './NewWorkout_Workout.css';
import firebase from '../../../Firebase';

import Workout_t from '../../../Interfaces/Workout_t';
import Plan_t from '../../../Interfaces/Plan_t';
import ScheduleDay_t from '../../../Interfaces/ScheduleDay_t';
import WorkoutLogBrief_t from '../../../Interfaces/WorkoutLogBrief_t';

import WorkoutList from '../WorkoutWorkflow/WorkoutList'
import NewWorkoutClientCard from './NewWorkoutClientCard';
import WorkoutPlanListWorkoutCell from '../WorkoutWorkflow/WorkoutPlanListWorkoutCell';
import NewWorkout_Workout_Day from './NewWorkout_Workout_Day';

import '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

interface CalendarDateCell_t {
    timestamp: number;
    workoutState: "none" | "not_complete" | "completed" | "today";
};

function NewWorkoutWorkoutPage(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let wkDayStrings = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const [initSet, setInitSet] = useState(false);
    const [isLoadingPlan, setIsLoadingPlan] = useState(false);
    const [isLoadingWorkout, setIsLoadingWorkout] = useState(false);
    const [currentPlan, setCurrentPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
    const [currentPlanPathNames, setCurrentPlanPathNames] = useState("");

    const [currentDayIndex, setCurrentDayIndex] = useState(0);

    const [selectedScheduleDay, setSelectedScheduleDay] = useState<ScheduleDay_t | undefined>();

    const [allDays, setAllDays] = useState<CalendarDateCell_t[]>([]);
    const [scheduledDays, setScheduledDays] = useState<ScheduleDay_t[]>([]);

    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [thisMoMonthIndex, setThisMoMonthIndex] = useState(0);
    const [thisMoYear, setThisMoYear] = useState(0);

    const [currentCycleIndex, setCurrentCycleIndex] = useState(0);
    const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
    const [currentWorkoutIndex, setCurrentWorkoutIndex] = useState(0);

    const [selectedCycleIndex, setSelectedCycleIndex] = useState(0);
    const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(0);
    const [selectedWorkoutIndex, setSelectedWorkoutIndex] = useState(0);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.client !== undefined && props.client.id !== '') {
            // Look for client plan in database
            setIsLoadingPlan(true);
            //getPlan();

            let todayDate = new Date();
            setSelectedTimestamp(todayDate.getTime());
            pullMonthlySchedule(todayDate.getMonth(), todayDate.getFullYear());
            configureAllDays(todayDate.getMonth(), todayDate.getFullYear());

        }
    }, [props.client])

    function getPlan() {
        if (props.client === undefined) { return; }
        let clientID = props.client.id;

        // console.log("getPlan", clientID);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/plans`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planList = snapshot.val();
                let planListkeys = Object.keys(planList);
                // console.log("planList:", planList)
                if (planListkeys.length > 0) {
                    var planObjs_temp: string[] = [];
                    
                    for (var i = 0; i < planListkeys.length; i++) {
                        let thisPlanObj = planList[planListkeys[i]];
                        // console.log(i, "thisPlanObj:", thisPlanObj)
                        let thisPlanID = thisPlanObj.template_plan_id;
                        planObjs_temp.push(thisPlanObj);

                        if (i === planList.length - 1) {
                            // Current plan
                            let startTime = thisPlanObj.starting_timestamp;
                            let endTime = thisPlanObj.ending_timestamp;
                            let currentTime = Date.now();
                            let endTime_adj = endTime - startTime;
                            let currentTime_adj = currentTime - startTime;
                            let dt = endTime_adj - currentTime_adj;
                            let percentComplete = Math.floor(100 * currentTime_adj / endTime_adj);

                            // set current day index
                            if (thisPlanObj.day_index !== undefined && thisPlanObj.day_index !== null && thisPlanObj.day_index >= 0) {
                                setCurrentDayIndex(thisPlanObj.day_index);
                            }

                            let numDaysRemaining = Math.ceil(dt / 1000 / 60 / 60 / 24);
                            getPlanDetails(thisPlanID);
                        }
                    }
                }
            } else {
                setIsLoadingPlan(false);
            }
        });
    }

    function getPlanDetails(forID: string) {

        // console.log("getPlanDetails", forID);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${forID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planData = snapshot.val();

                let newPlan: Plan_t = {
                    title: planData.title !== null ? planData.title : "", 
                    description: planData.description !== null ? planData.description : "", 
                    path: planData.location_path !== null ? planData.location_path : "",
                    id: forID, 
                    createdBy_ID: planData.create_by !== null ? planData.create_by : "", 
                    created_timestamp: planData.created_timestamp !== null ? planData.created_timestamp : 0, 
                    workout_ids: planData.workouts !== null ? planData.workouts : [],
                    availability: planData.availability !== null ? planData.availability : 0
                };

                setCurrentPlan(newPlan);
                if (newPlan.path !== undefined) {
                    getPathNames(newPlan.path);
                }
            }

            setIsLoadingPlan(false);
        });
    }

    function getPathNames(pathIDs: string) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let splitPath = pathIDs.split('/');
        let firstFolderID = splitPath[1];

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root/${firstFolderID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists()) {
                let foldersObj = snapshot.val();
                var pathNames = foldersObj.name + " / ";

                var parentObj = foldersObj;
                for (var i = 2; i < splitPath.length - 1; i++) {
                    let thisChildID = splitPath[i];
                    let parentsChildObj = parentObj.children[thisChildID];
                    pathNames += parentsChildObj.name + " / ";
                    parentObj = parentsChildObj;
                }

                setCurrentPlanPathNames(pathNames);
            }
        });
    }


    function pullMonthlySchedule(forMo: number, forYr: number) {
        //setIsLoadingProgram(true);
        let userUID = props.client.id;

        let refLocation = `/workout_plans/client_assigned_plans/${userUID}/schedule/${forYr}/${forMo}`;

        setCurrentPlan({title: "", id: "abc123", createdBy_ID: "", created_timestamp: 0, workout_ids: []})

        let todayDateTS = Date.now();
        let todayDate = new Date(todayDateTS);
        let todayDay = todayDate.getDate();
        let todayMo = todayDate.getMonth();
        let todayYr = todayDate.getFullYear();

        let database = firebase.database();
        let ref = database.ref(refLocation);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                var scheduledDaysList: ScheduleDay_t[] = [];
                let dayKeys = Object.keys(data);
                for (var i = 0; i < dayKeys.length; i++) {
                    let thisDayNum = dayKeys[i];
                    let thisDayData = data[thisDayNum];
                    if (thisDayData !== undefined) {
                        let thisDate = new Date(forYr, forMo, Number(thisDayNum));

                        let newScheduleDay: ScheduleDay_t = {
                            timestamp: thisDate.getTime(),
                            day_number: thisDate.getDate(),
                            mo_number: thisDate.getMonth(),
                            year_number: thisDate.getFullYear(),
                            workout_id: thisDayData.workout_id === undefined ? "" : thisDayData.workout_id,
                            path: thisDayData.path === undefined ? "" : thisDayData.path,
                            phase: thisDayData.phase === undefined ? {id: "", index: -1, name: "", path: ""} : thisDayData.phase,
                            cycle: thisDayData.cycle === undefined ? {id: "", index: -1, name: "", path: ""} : thisDayData.cycle,
                        };

                        if (thisDayData.log !== undefined) {
                            newScheduleDay.workout_log = {
                                log_id: thisDayData.log.log_id === undefined ? "" : thisDayData.log.log_id,
                                workout_id: thisDayData.log.workout_id === undefined ? "" : thisDayData.log.workout_id,
                                timestamp: thisDayData.log.timestamp === undefined ? 0 : thisDayData.log.timestamp
                            }
                        }

                        scheduledDaysList.push(newScheduleDay);

                        if (thisDate.getFullYear() === todayYr && thisDate.getMonth() === todayMo && thisDate.getDate() === todayDay) {
                            // this schedule day is today - let parent know that this date is selected
                            //props.scheduledDaySelected(newScheduleDay);

                            setSelectedScheduleDay(newScheduleDay);
                        } else if (thisDate.getFullYear() !== todayYr && thisDate.getMonth() !== todayMo && thisDate.getDate() === 1) {
                            // this schedule day is today - let parent know that this date is selected
                            //props.scheduledDaySelected(newScheduleDay);

                            setSelectedScheduleDay(newScheduleDay);
                        } 
                        
                    }
                }
                setIsLoadingPlan(false);
                setScheduledDays(scheduledDaysList);
            } else {
                setIsLoadingPlan(false);
            }
        });
    }

    function configureAllDays(mo: number, yr: number) {
        // Set all days for current month
        let allDaysTemp = getAllDaysForMoYr(mo, yr);
        setAllDays(allDaysTemp);
        setThisMoMonthIndex(mo);
        setThisMoYear(yr);
    }

    function getAllDaysForMoYr(mo: number, yr: number) {
        let numberOfShowingDays = getDaysInMonth(mo, yr);
        let startingDateIndex = new Date(yr, mo, 1).getDay();

        var tempDayBuffer: CalendarDateCell_t[] = [];

        if (startingDateIndex > 0) {
            for (var i = 0; i < startingDateIndex; i++) {
                tempDayBuffer.push({
                    timestamp: -1,
                    workoutState: "none"
                });
            }
        }
        for (var i = 0; i < numberOfShowingDays; i++) {
            let thisDayNum = i + 1;
            let newDate = new Date(yr, mo, thisDayNum);
            tempDayBuffer.push({
                timestamp: newDate.getTime(),
                workoutState: "none"
            });
        }

        return tempDayBuffer;
    }

    function getDaysInMonth(mo: number, yr: number) {
        return new Date(yr, mo + 1, 0).getDate();
    }

    function resetToToday() {
        let newTS = Date.now();
        let newDate = new Date(newTS);

        let newYear = newDate.getFullYear();
        let newMonth = newDate.getMonth();

        if (newMonth !== thisMoMonthIndex || newYear !== thisMoYear) {
            pullMonthlySchedule(newMonth, newYear);
        }

        setThisMoMonthIndex(newMonth);
        setThisMoYear(newYear);

        configureAllDays(newMonth, newYear);
        //configureWeeklyCal(newTS);

        setSelectedTimestamp(newTS);
    }

    function previousMonth() {

        let newYear = thisMoMonthIndex === 0 ? thisMoYear - 1 : thisMoYear;
        let newMonth = thisMoMonthIndex === 0 ? 11 : thisMoMonthIndex - 1;

        setThisMoMonthIndex(newMonth);
        setThisMoYear(newYear);

        configureAllDays(newMonth, newYear);

        let todayTS = Date.now();
        let todayDate = new Date(todayTS);
        let todayDateMonth = todayDate.getMonth();
        let todayDateYear = todayDate.getFullYear();
        let todayDateDay = todayDate.getDate();

        let newDate = new Date(newYear, newMonth, todayDateYear === todayDateYear && todayDateMonth === newMonth ? todayDateDay : 1);
        setSelectedTimestamp(newDate.getTime());

        pullMonthlySchedule(newMonth, newYear);
    }

    function nextMonth() {
        let newYear = thisMoMonthIndex === 11 ? thisMoYear + 1 : thisMoYear;
        let newMonth = thisMoMonthIndex === 11 ? 0 : thisMoMonthIndex + 1

        setThisMoMonthIndex(newMonth);
        setThisMoYear(newYear);

        configureAllDays(newMonth, newYear);

        let todayTS = Date.now();
        let todayDate = new Date(todayTS);
        let todayDateMonth = todayDate.getMonth();
        let todayDateYear = todayDate.getFullYear();
        let todayDateDay = todayDate.getDate();

        let newDate = new Date(newYear, newMonth, todayDateYear === todayDateYear && todayDateMonth === newMonth ? todayDateDay : 1);
        setSelectedTimestamp(newDate.getTime());

        pullMonthlySchedule(newMonth, newYear);
    }

    function getMoYrString() {
        let thisDate = new Date(selectedTimestamp);
        let thisMoIndex = thisDate.getMonth();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisYear}`
    }

    function workoutSelected(workout: Workout_t) {
        props.workoutSelected(workout);
    }

    function dateSelected(forDateCell: CalendarDateCell_t) {


        setSelectedTimestamp(forDateCell.timestamp);
        // props.newDateSelected(forDateCell.timestamp);

        // get workout
        let newDate = new Date(forDateCell.timestamp);
        let newDateDay = newDate.getDate();
        var selectedScheduleDayTemp: ScheduleDay_t | undefined = undefined;
        for (var i = 0; i < scheduledDays.length; i++) {
            let thisScheduledDay = scheduledDays[i];
            if (thisScheduledDay.day_number === newDateDay && thisScheduledDay.mo_number === newDate.getMonth() && thisScheduledDay.year_number === newDate.getFullYear()) {
                selectedScheduleDayTemp = thisScheduledDay;
            }
        }

        
        //props.scheduledDaySelected(selectedScheduleDay);

        if (selectedScheduleDayTemp === undefined) { return; }

        setSelectedScheduleDay(selectedScheduleDayTemp);

        if (selectedScheduleDayTemp.workout_id !== "") {
            
            //setIsLoadingWorkout(true);
            //setSelectedWorkoutID(selectedScheduleDay.workout_id);

            // let database = firebase.database();
            // let ref = database.ref(`workouts/${selectedScheduleDay.workout_id}`);
            // ref.once('value', function(snapshot) {
            //     if (snapshot.exists() === true) {
            //         let data = snapshot.val();
            //         // console.log("WORKOUT:", data.title, data);

                    
            //     }
            // });
        }
    }

    function checkForCompletedWorkout(ts: number) {
        let newDate = new Date(ts);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        for (var i = 0; i < scheduledDays.length; i++) {
            let thisScheduledDay: ScheduleDay_t = scheduledDays[i];
            let endDate = new Date(thisScheduledDay.timestamp);
            let endDateMonth = endDate.getMonth();
            let endDateYear = endDate.getFullYear();
            let endDateDay = endDate.getDate();

            if (newDateDay === endDateDay && newDateMonth === endDateMonth && newDateYear === endDateYear) {
                if (thisScheduledDay.workout_log !== undefined) {
                    return true;
                }
            }
        }

        return false;
    }

    function getCalDateClass(calDate: CalendarDateCell_t) {
        let todayTS = Date.now();
        let newDate = new Date(todayTS);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        let selectedDate = new Date(calDate.timestamp);
        let selectedDateMonth = selectedDate.getMonth();
        let selectedDateYear = selectedDate.getFullYear();
        let selectedDateDay = selectedDate.getDate();

        if (newDateDay === selectedDateDay && newDateMonth === selectedDateMonth && newDateYear === selectedDateYear) {
            return 'new-workout-workout-plan-container-schedule-day-content-inner-today'
        }

        // if (todayTS < calDate.timestamp) {
        //     return 'new-workout-workout-plan-container-schedule-day-content-inner-dark';
        // };



        let workoutComplete = checkForCompletedWorkout(calDate.timestamp);
        if (workoutComplete) {
            return 'new-workout-workout-plan-container-schedule-day-content-inner-complete'
        }

        if (calDate.timestamp < props.programStartTimestamp || calDate.timestamp > props.programEndTimestamp) {
            return 'new-workout-workout-plan-container-schedule-day-content-inner-dark';
        }
    }

    function getDayString(forDateCell: CalendarDateCell_t) {
        let thisDate = new Date(forDateCell.timestamp);
        return thisDate.getDate();
    }

    function getDateSelected(calDate: CalendarDateCell_t) {
        let newDate = new Date(calDate.timestamp);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        let selectedDate = new Date(selectedTimestamp);
        let selectedDateMonth = selectedDate.getMonth();
        let selectedDateYear = selectedDate.getFullYear();
        let selectedDateDay = selectedDate.getDate();

        return newDateDay === selectedDateDay && newDateMonth === selectedDateMonth && newDateYear === selectedDateYear;
    }

	return (
		<div className="new-workout-workout">
            <div className="new-workout-workout-header">
                <h1>Select a Workout to complete</h1>
            </div>
	        <NewWorkoutClientCard
                client={props.client}/>
            <div hidden={isLoadingPlan === true || currentPlan.id !== ""} className="new-workout-workout-list-container">
                <WorkoutList 
                    showTitle={true}
                    moreInfoSelected={(e: Workout_t) => workoutSelected(e)}
                    workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
            </div>
            <div hidden={!isLoadingPlan} className="new-workout-workout-loading-plan-container">
                <div className="client-list-container-loading-spinner"/>
                <p>Loading {props.client !== undefined ? `${props.client.first_name}\'s` : 'your client\'s'} workout plan...</p>
            </div>
            <div hidden={isLoadingPlan === true || currentPlan.id === ""} className="new-workout-workout-plan-container">
                <div hidden={props.client === undefined} className="new-workout-workout-plan-container-details">
                    <p>Select a workout from {props.client !== undefined ? `${props.client.first_name}\'s` : 'your client\'s'} Workout Plan to complete</p>
                </div>
                <div className="new-workout-workout-plan-container-schedule-container">
                    <div className="new-workout-workout-plan-container-schedule">
                        <div className="new-workout-workout-plan-container-schedule-header">
                            <div className="new-workout-workout-plan-container-schedule-header-title">
                                <h2>{getMoYrString()}</h2>
                            </div>
                            <div className="new-workout-workout-plan-container-schedule-header-button-container">
                                <div onClick={() => resetToToday()} className="new-workout-workout-plan-container-schedule-header-button new-workout-workout-plan-container-schedule-header-button-today">
                                    <p>Today</p>
                                </div>
                                <div onClick={() => previousMonth()} className="new-workout-workout-plan-container-schedule-header-button new-workout-workout-plan-container-schedule-header-button-prev">
                                    <ArrowBackIos className="new-workout-workout-plan-container-schedule-header-button-icon"/>
                                    <p>Prev</p>
                                </div>
                                <div onClick={() => nextMonth()} className="new-workout-workout-plan-container-schedule-header-button">
                                    <p>Next</p>
                                    <ArrowForwardIos className="new-workout-workout-plan-container-schedule-header-button-icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="new-workout-workout-plan-container-schedule-days-container-outter">
                            <div className="new-workout-workout-plan-container-schedule-weekdays-container">
                                {
                                    wkDayStrings.map((item: string, index: number) => (
                                        <div className="new-workout-workout-plan-container-schedule-weekdays">
                                            <p>{item}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="new-workout-workout-plan-container-schedule-days-container">
                                {
                                    allDays.map((item: CalendarDateCell_t, index: number) => (
                                        <div onClick={() => dateSelected(item)} className={`new-workout-workout-plan-container-schedule-day-content ${item.timestamp === -1 ? 'new-workout-workout-plan-container-schedule-day-content-invisible' : ''}`}>
                                            <div hidden={item.timestamp === -1} className={`new-workout-workout-plan-container-schedule-day-content-inner ${getCalDateClass(item)} ${getDateSelected(item) ? 'new-workout-workout-plan-container-schedule-day-content-inner-selected' : '' }`}>
                                                <p>{getDayString(item)}</p>
                                            </div>
                                            <div hidden={item.timestamp !== -1} className="new-workout-workout-plan-container-schedule-day-content-inner-invisible">
                                                <p>x</p>
                                            </div>
                                            {/*<div hidden={item.timestamp === -1 || (item.timestamp <= props.programStartTimestamp || item.timestamp >= props.programEndTimestamp)} className="new-workout-workout-plan-container-schedule-day-content-line-container">
                                                <div className={`new-workout-workout-plan-container-schedule-day-content-line-phase ${getIsEndOfPhase(item) ? 'new-workout-workout-plan-container-schedule-day-content-line-short' : ''}`}/>
                                                <div className={`new-workout-workout-plan-container-schedule-day-content-line-cycle ${getIsEndOfCycle(item) ? 'new-workout-workout-plan-container-schedule-day-content-line-short' : ''}`}/>
                                            </div>*/}
                                            
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                    <div className="new-workout-workout-plan-container-day-panel-outter">
                        <NewWorkout_Workout_Day 
                            selectedTimestamp={selectedTimestamp}
                            selectedScheduleDay={selectedScheduleDay}
                            currentPlan={currentPlan}
                            currentPlanPathNames={currentPlanPathNames}
                            workoutSelected={(e: Workout_t) => props.workoutSelected(e)}/>
                    </div>
                    {/*<div className="new-workout-workout-plan-container-day-panel">

                        <div hidden={isLoadingWorkout} className="new-workout-workout-plan-container-day-panel-inner">
                            <div className="new-workout-workout-plan-container-day-panel-header">
                                <h4>Apr 12, 2022</h4>
                                <h2>Chest Day</h2>
                                <div className="new-workout-workout-plan-container-day-panel-day-floater">
                                    <p>Today's Workout</p>
                                </div>
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body">
                                <div className="new-workout-workout-plan-container-day-panel-body-section">
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                            <p>Containing Folder</p>
                                        </div>
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                            <h4>{currentPlanPathNames}<span>{currentPlan.title}</span></h4>
                                        </div>
                                    </div>
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                            <p>Cycle</p>
                                        </div>
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                            <h4>Phase 2, Cycle 3</h4>
                                        </div>
                                    </div>
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                            <p>Muscle Groups</p>
                                        </div>
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                            <h4>Chest, Arms, Abs</h4>
                                        </div>
                                    </div>
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                            <p>Volume</p>
                                        </div>
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                            <h4>5 exercises, 25 sets</h4>
                                        </div>
                                    </div>
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-row">
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-title">
                                            <p>Duration</p>
                                        </div>
                                        <div className="new-workout-workout-plan-container-day-panel-body-section-row-content">
                                            <h4>62 minutes</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-workout-workout-plan-container-day-panel-body-section">
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-header">
                                        <h4>Notes from previous workout</h4>
                                        <p>1 day ago</p>
                                    </div>
                                    <div className="new-workout-workout-plan-container-day-panel-body-section-note">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="new-workout-workout-plan-container-day-panel-body-section-faded-curtain"/>
                            <div className="new-workout-workout-plan-container-day-panel-button">
                                <p>Continue with this Workout</p>
                                <ArrowForwardIos className="new-workout-workout-plan-container-day-panel-button-icon"/>
                            </div>
                        </div>
                    </div>*/}
                </div>
                {/*<div className="new-workout-workout-plan-container-header">
                    <p>{props.client !== undefined ? `${props.client.first_name}\'s` : 'your client\'s'} Workout Plan</p>
                    
                </div>
                <div className="workout-plan-plan-content-list-container">
                    {
                        (currentPlan.workout_ids.map((item: string, index: number) => (
                            <WorkoutPlanListWorkoutCell
                                id={item}
                                index={index}
                                highlighted={currentDayIndex === index}
                                workoutSelected={(e: Workout_t) => props.workoutSelected(e)}/>
                        )))
                    }
                </div>*/}
            </div>
            {/*<div hidden={isLoadingPlan === true || currentPlan.id === ""} className="new-workout-workout-plan-container">
                <div hidden={props.client === undefined} className="new-workout-workout-plan-container-details">
                    <p>Select a workout from {props.client !== undefined ? `${props.client.first_name}\'s` : 'your client\'s'} Workout Plan to complete</p>
                </div>
                <div className="new-workout-workout-plan-container-header">
                    <p>{props.client !== undefined ? `${props.client.first_name}\'s` : 'your client\'s'} Workout Plan</p>
                    <h4>{currentPlanPathNames}<span>{currentPlan.title}</span></h4>
                </div>
                <div className="workout-plan-plan-content-list-container">
                    {
                        (currentPlan.workout_ids.map((item: string, index: number) => (
                            <WorkoutPlanListWorkoutCell
                                id={item}
                                index={index}
                                highlighted={currentDayIndex === index}
                                workoutSelected={(e: Workout_t) => props.workoutSelected(e)}/>
                        )))
                    }
                </div>
            </div>*/}
		</div>
	)
}

export default NewWorkoutWorkoutPage;