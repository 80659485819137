import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import './DateSelector.css';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

function DateSelector(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let wkDayStrings = ["S", "M", "T", "W", "T", "F", "S"];

    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
    const [selectedYear, setSelectedYer] = useState(2022);
    const [selectedDay, setSelectedDay] = useState(1);

    const [allDays, setAllDays] = useState<number[]>([]);

    const [showingCalendar, setShowingCalendar] = useState(false);
    const [initSet, setInitSet] = useState(false);

    
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setSelectedTimestamp(Date.now());

        props.dateSelected(Date.now());

        let thisDate = new Date();

        setSelectedMonthIndex(thisDate.getMonth());
        setSelectedYer(thisDate.getFullYear());
        setSelectedDay(thisDate.getDate());

        configureAllDays(thisDate.getMonth(),thisDate.getFullYear());
    }

    useEffect(() => {
        if (props.defaultTimestamp !== undefined && props.defaultTimestamp > 0) {
            setSelectedTimestamp(props.defaultTimestamp);
            let thisDate = new Date(props.defaultTimestamp);

            setSelectedMonthIndex(thisDate.getMonth());
            setSelectedYer(thisDate.getFullYear());
            setSelectedDay(thisDate.getDate());

            configureAllDays(thisDate.getMonth(),thisDate.getFullYear());

            props.dateSelected(props.defaultTimestamp);
        }
    }, [props.defaultTimestamp])

    function configureAllDays(mo: number, yr: number) {
        let numberOfShowingDays = getDaysInMonth(mo, yr);
        let startingDateIndex = new Date(yr, mo, 1).getDay();

        var tempDayBuffer: number[] = [];

        if (startingDateIndex > 0) {
            for (var i = 0; i < startingDateIndex; i++) {
                tempDayBuffer.push(-1);
            }
        }
        for (var i = 0; i < numberOfShowingDays; i++) {
            tempDayBuffer.push(i + 1);
        }

        setAllDays(tempDayBuffer);
    }

    function getDaysInMonth(mo: number, yr: number) {
        return new Date(yr, mo + 1, 0).getDate();
    }

    function getDateString() {
        let thisDate = new Date(selectedTimestamp);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
    }

    function toggleShowingCalendar() {
        setShowingCalendar(!showingCalendar);
    }

    function previousMonth() {
        let newYear = selectedMonthIndex === 0 ? selectedYear - 1 : selectedYear;
        let newMonth = selectedMonthIndex === 0 ? 11 : selectedMonthIndex - 1;

        setSelectedYer(newYear);
        setSelectedMonthIndex(newMonth);

        configureAllDays(newMonth, newYear);
        updateTimestamp(1, newMonth, newYear);
    }

    function nextMonth() {
        let newYear = selectedMonthIndex === 11 ? selectedYear + 1 : selectedYear;
        let newMonth = selectedMonthIndex === 11 ? 0 : selectedMonthIndex + 1

        setSelectedYer(newYear);
        setSelectedMonthIndex(newMonth);

        configureAllDays(newMonth, newYear);

        updateTimestamp(1, newMonth, newYear);
    }

    function daySelected(dayNumber: number) {
        if (dayNumber < 0) { return; }
        setSelectedDay(dayNumber);
        updateTimestamp(dayNumber, selectedMonthIndex, selectedYear);

        setShowingCalendar(false);
    }

    function updateTimestamp(d: number, m: number, y: number) {
        let newDate = new Date(y,m,d);
        setSelectedTimestamp(newDate.getTime());

        props.dateSelected(newDate.getTime());
    }

	return (
		<div className="date-selector">
			<div hidden={ props.hideInput !== undefined && props.hideInput === true } onClick={() => toggleShowingCalendar()} className={`date-selector-input-container ${showingCalendar ? 'date-selector-input-container-selected' : ''}`}>
                <h4>{getDateString()}</h4>
            </div>
            <CSSTransition in={ (props.isOpen !== undefined && props.isOpen === true) || (props.hideInput !== undefined && props.hideInput === false) || showingCalendar} timeout={200} classNames="menu" unmountOnExit appear>
                <div className={`date-selector-calendar ${props.isLeft !== undefined && props.isLeft === true ? 'date-selector-calendar-left' : ''} ${props.isAbove !== undefined && props.isAbove === true ? 'date-selector-calendar-above' : ''}`}>
                    <div className="date-selector-calendar-header">
                        <div className="date-selector-calendar-header-text-container">
                            <h4>{props.title !== undefined ? props.title : "Select a date"}</h4>
                        </div>
                        <div onClick={() => setShowingCalendar(false)} className="date-selector-calendar-header-close-icon-container">
                            <Close className="date-selector-calendar-header-close-icon"/>
                        </div>
                    </div>
                    <div className="date-selector-calendar-body">
                        <div className="date-selector-calendar-month-row">
                            <div className="date-selector-calendar-month-row-text-container">
                                <h4>{moStrings_full[selectedMonthIndex]} {selectedYear}</h4>
                            </div>
                            <div className="date-selector-calendar-month-row-buttons-container">
                                <div onClick={() => previousMonth()} className="date-selector-calendar-month-row-button">
                                    <ArrowBackIos className="date-selector-calendar-month-row-button-icon"/>
                                </div>
                                <div onClick={() => nextMonth()} className="date-selector-calendar-month-row-button">
                                    <ArrowForwardIos className="date-selector-calendar-month-row-button-icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="date-selector-calendar-weekday-row">
                            { wkDayStrings.map((item: string, index: number) => (
                                <div className="date-selector-calendar-weekday-container">
                                    <p>{item}</p>
                                </div>
                            ))}
                        </div>
                        <div className="date-selector-calendar-days-container">
                            {
                                allDays.map((item: number, index: number) => (
                                    <div onClick={() => daySelected(item)} className={`date-selector-calendar-day-content ${selectedDay === item ? 'date-selector-calendar-day-content-selected' : ''} ${item === -1 ? 'date-selector-calendar-day-content-invisible' : ''}`}>
                                        <div hidden={item === -1} className="date-selector-calendar-day-content-inner">
                                            <p>{item}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </CSSTransition>
		</div>
	)
}

export default DateSelector;