import React, { useState } from 'react';
import firebase from '../../../Firebase';
import './HomeMain.css';

import Client_t from '../../../Interfaces/Client_t';
import Workout_t from '../../../Interfaces/Workout_t';
import ScheduleDay_t from '../../../Interfaces/ScheduleDay_t';

import TopClientCell from './TopClientCell';
import MyWorkoutCell from './MyWorkoutCell';
import DashboardSummaryChart from '../../../components/Charts/DashboardSummaryChart';
import TimingChart from '../../../components/Charts/TimingChart';
import InfoHover from '../../../components/Base/InfoHover';
import CalendarDayTimeline from '../../../components/Calendar/CalendarDayTimeline';

import Calendar from '../../../components/Calendar/Calendar';

import ActivityFeed from '../../../components/Home/ActivityFeed';

import sendPushNotification from '../../../api/notifications/push/sendPushNotification'

import '@material-ui/core';
import Timeline from '@material-ui/icons/Timeline';
import Star from '@material-ui/icons/Star';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import Add from '@material-ui/icons/Add';

interface TopClientCard_t {
    name: string;
    id: string;
    last_workout_date: string;
}

interface TopPerformer_t {
    name: string;
    color: number;
    improvement: number;
    workouts: number;
    duration: string;
    tonnage: number;
}

function HomeMain(props: any) {

    const [loadingTopClients, setLoadingTopClients] = useState(true);
    const [loadingMostRecent, setLoadingMostRecent] = useState(true);

    const [mostRecentClients, setMostRecentClients] = useState<TopClientCard_t[]>([]);
    
    const [loadingWorkouts, setLoadingWorkouts] = useState(true);
    const [myWorkoutIDs, setMyWorkoutIDs] = useState<string[]>([]);
    const [initSet, setInitSet] = useState(false);

    const [userHasClients, setUserHasClients] = useState(true);
    const [userHasFolders, setUserHasFolders] = useState(false);
    const [userHasExercises, setUserHasExercises] = useState(false);
    const [userHasCoaches, setUserHasCoaches] = useState(false);
    const [userHasNodes, setUserHasNodes] = useState(false);

    const [showGettingStarted, setShowGettingStarted] = useState(false);

    const [trainerSchedule, setTrainerSchedule] = useState<ScheduleDay_t[]>([]);
    const [isLoadingTrainerSchedule, setIsLoadingTrainerSchedule] = useState(false);

    let topClients: TopPerformer_t[] = [
        {
            name: "John Jones",
            color: 0,
            improvement: 16,
            workouts: 6,
            duration: "06:02:32",
            tonnage: 154231
        },
        {
            name: "Sue Smith",
            color: 2,
            improvement: 7,
            workouts: 5,
            duration: "03:52:52",
            tonnage: 98231
        },
        {
            name: "Connor Holowachuk",
            color: 1,
            improvement: -2,
            workouts: 6,
            duration: "04:23:12",
            tonnage: 102202
        },
    ];


    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullTrainerSchedule(Date.now());
        configureGettingStarted();
        // try {
        //     sendPushNotification("IDMl0c4MQbacldKQPN7D4Vq0eNz1", true, false, "Hello there 👋", "Yo yo what's good", { dinky: "dong" });
        // } catch (err) {
        //     console.log("ERR:", err)
        // }
        
    }

    function pullTrainerSchedule(withTimestamp: number) {

        setIsLoadingTrainerSchedule(true);

        // Compose date components
        let thisDate = new Date(withTimestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo = thisDate.getMonth();
        let thisDate_yr = thisDate.getFullYear();

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_schedule/${trainerUID}/schedule/${thisDate_yr}/${thisDate_mo}/${thisDate_day}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let thisObj = snapshot.val();
                parseTrainerSchedule(thisObj, thisDate);
            } else {
                setIsLoadingTrainerSchedule(false);
            }
        });
    }

    async function parseTrainerSchedule(scheduleObj: any[], thisDate: Date) {
        // scheduleObj is array holding objects of the type {id: string;}

        var schedule_temp: ScheduleDay_t[] = [];
        let schedule_temp_keys: any[] = Object.keys(scheduleObj);
        for (var i = 0; i < schedule_temp_keys.length; i++) {
            let thisObj: any = scheduleObj[schedule_temp_keys[i]];
            // console.log("XYZ: ", schedule_temp_keys[i], i, schedule_temp_keys, thisObj)
            if (thisObj !== undefined && thisObj.id !== undefined) {
                let thisScheduleEvent = await getScheduleEventForClient(thisObj.id, thisDate);
                if (thisScheduleEvent !== undefined && thisScheduleEvent !== null) {
                    schedule_temp.push(thisScheduleEvent);
                }
            }
        }
        setTrainerSchedule(schedule_temp);
        setIsLoadingTrainerSchedule(false);
    }

    async function getScheduleEventForClient(clientID: string, thisDate: Date) {
        return new Promise<ScheduleDay_t>((resolve, reject) => {
            let database = firebase.database();
            let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/schedule/${thisDate.getFullYear()}/${thisDate.getMonth()}/${thisDate.getDate()}`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let thisObj = snapshot.val();
                    let newScheduleObj: ScheduleDay_t = {
                        timestamp: thisObj.timestamp === undefined ? Date.now() : thisObj.timestamp,
                        day_number: thisDate.getFullYear(),
                        mo_number: thisDate.getMonth(),
                        year_number: thisDate.getDate(),
                        workout_id: thisObj.workout_id === undefined ? "" : thisObj.workout_id,
                        client_id: clientID,
                        path: thisObj.path === undefined ? "" : thisObj.path,
                        phase: thisObj.phase === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.phase,
                        cycle: thisObj.cycle === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.cycle,
                        scheduled: thisObj.scheduled
                    }
                    resolve(newScheduleObj);
                } else {
                    reject();
                }
            });

        });
    }

    function getMyWorkouts() {
        let currentUser = firebase.auth().currentUser;
        if (currentUser === null) { 
            setLoadingWorkouts(false);
            return; 
        }

        let trainerUID = currentUser.uid;
        let db = firebase.database();
        let ref = db.ref(`personal_trainer_workouts/${trainerUID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data: string[] = snapshot.val();
                let maxLength = 3;
                let length = data.length <= maxLength ? data.length : maxLength;
                var temp: string[] = [];
                for (var i = 0; i < length; i++) {
                    let thisID = data[i];
                    temp.push(thisID);
                }
                setMyWorkoutIDs(temp);
                setLoadingWorkouts(false);
            } else {
                setLoadingWorkouts(false);
            }
        });
    }

    function getTopClients() {
        let currentUser = firebase.auth().currentUser;
        if (currentUser === null) { 
            setLoadingTopClients(false);
            return; 
        }

        let trainerUID = currentUser.uid;
        let db = firebase.database();
        let ref = db.ref(`personal_trainer_clients/${trainerUID}/clients`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                
                let userKeys = Object.keys(data);
                var temp: TopClientCard_t[] = [];
                for (var i = 0; i < userKeys.length; i++) {
                    let thisUserID = userKeys[i];
                    let userData = data[thisUserID];

                    if (userData.workouts_together > 0) {
                        let newObj: TopClientCard_t = {
                            name: userData.full_name,
                            id: thisUserID,
                            last_workout_date: userData.last_workout_date
                        }
                        //if (temp.length < 3) {
                            temp.push(newObj);
                        //}
                    }
                }
                sortTopClients(temp);
                //setTopClients(temp);
                
            } else {
                // No clients exist - start configuring getting started
                //configureGettingStarted();
                setLoadingTopClients(false);
                setLoadingMostRecent(false);
            }
        });
    }

    async function configureGettingStarted() {

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        // 1. Check if clients exist
        let clientsExist = await checkForClients(trainerUID);
        

        // 2. Check if folders exists for this trainer
        let foldersExist = await checkForFolders(trainerUID);
        //setUserHasFolders(foldersExist);

        // 3. Check if user has created a custom exercise
        let exercisesExist = await checkForCustomExercises(trainerUID);
        //setUserHasExercises(exercisesExist);

        // 4. Check if user has added coaches to their studio
        let coachesExist = await checkForCoaches(trainerUID);

        let showGettingStarted_temp = !(clientsExist && foldersExist && exercisesExist && coachesExist);
        setShowGettingStarted(showGettingStarted_temp);

        if (!showGettingStarted_temp) {
            getTopClients();
            getMyWorkouts();
        } else {
            setLoadingTopClients(false);
            setLoadingMostRecent(false);
        }
        
    }

    async function checkForClients(trainerUID: string) {
        return new Promise<boolean>((resolve) => {
            let database = firebase.database();
            let ref = database.ref(`personal_trainer_clients/${trainerUID}/clients`);
            ref.on('value', function(snapshot) {
                let clientsExist = snapshot.exists();
                setUserHasClients(clientsExist);
                if (!clientsExist) {
                    setShowGettingStarted(true);
                }
                resolve(clientsExist);
            });
        })
    }

    async function checkForFolders(trainerUID: string) {
        return new Promise<boolean>((resolve) => {
            let database = firebase.database();
            let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root`);
            ref.on('value', function(snapshot) {
                let foldersExist = snapshot.exists();
                setUserHasFolders(foldersExist);
                if (!foldersExist) {
                    setShowGettingStarted(true);
                }
                resolve(foldersExist);
            });
        })
    }

    async function checkForCustomExercises(trainerUID: string) {
        return new Promise<boolean>((resolve) => {
            let database = firebase.database();
            let ref = database.ref(`trainer_exercises/${trainerUID}`);
            ref.on('value', function(snapshot) {
                let exercisesExist = snapshot.exists();
                setUserHasExercises(exercisesExist);
                if (!exercisesExist) {
                    setShowGettingStarted(true);
                }
                resolve(exercisesExist);
            });
        })
    }

    async function checkForCoaches(trainerUID: string) {
        return new Promise<boolean>((resolve) => {
            let database = firebase.database();
            let ref = database.ref(`personal_trainer_profile/${trainerUID}/studio_id`);
            ref.once('value', function(snapshot) {
                let studioExists = snapshot.exists();

                if (studioExists) {
                    let studioID = snapshot.val();
                    if (studioID !== "") {
                        let ref_studio = database.ref(`training_studios/${studioID}/trainers`);
                        ref_studio.on('value', function(snapshot_studio) {
                            let coachesExist = snapshot_studio.exists();

                            setUserHasCoaches(coachesExist);

                            resolve(coachesExist);
                        });
                    }
                } else {
                    resolve(false);
                }
            });
        })
    }


    function sortTopClients(list: TopClientCard_t[]) {

        

        list.sort((a,b) => {

            return getDaysSinceWorkout(a.last_workout_date) - getDaysSinceWorkout(b.last_workout_date);

        })

        var temp: TopClientCard_t[] = [];
        let maxCards = list.length >= 3 ? 3 : list.length;
        for (var i = 0; i < maxCards; i++) {
            temp.push(list[i]);
        }

        setMostRecentClients(temp);
        setLoadingTopClients(false);
        setLoadingMostRecent(false);
    }

    function getDaysSinceWorkout(dateString: string) {

        if (dateString === undefined || dateString === null) { return -1; }

        let today = new Date();

        let prevWorkoutParsed = dateString.split('-');

        let woDate = new Date(Number(prevWorkoutParsed[0]),Number(prevWorkoutParsed[1]),Number(prevWorkoutParsed[2]));

        let timeDiff = today.getTime() - woDate.getTime();
        let daysDiff = timeDiff / (1000 * 3600 * 24);
        let filteredDay = Math.ceil(daysDiff) - 1;

        return filteredDay;
    }

    function getColourName(forIndex: number) {
        let colourNames = ["blue", "red", "purple"];
        return colourNames[forIndex];
    }

    function getStartTimeString() {
        if (trainerSchedule.length > 0) {
            let initTimestamp = 999999999999999999999999;
            var minTimestamp = initTimestamp;
            for (var i = 0; i < trainerSchedule.length; i++) {
                let thisScheduleEvent: ScheduleDay_t = trainerSchedule[i];
                if (thisScheduleEvent.scheduled !== undefined) {
                    if (thisScheduleEvent.scheduled.starting_timestamp < minTimestamp) {
                        minTimestamp = thisScheduleEvent.scheduled.starting_timestamp;
                    }
                }
            }

            if (minTimestamp === initTimestamp) {
                // Error
                return 'unknown'
            } else {
                let thisDate = new Date(minTimestamp);
                let thisDate_hr = thisDate.getHours();
                let thisDate_min = thisDate.getMinutes();
                var hr_adj = thisDate_hr % 12;
                hr_adj = hr_adj === 0 ? 12 : hr_adj;
                return `${hr_adj}:${thisDate_min < 10 ? '0' : ''}${thisDate_min}${thisDate_hr < 12 ? 'am' : 'pm'}`
            }
        }

        return ''
    }

    function getEndTimeString() {
        if (trainerSchedule.length > 0) {
            let initTimestamp = 0;
            var minTimestamp = initTimestamp;
            for (var i = 0; i < trainerSchedule.length; i++) {
                let thisScheduleEvent: ScheduleDay_t = trainerSchedule[i];
                if (thisScheduleEvent.scheduled !== undefined) {
                    if (thisScheduleEvent.scheduled.ending_timestamp > minTimestamp) {
                        minTimestamp = thisScheduleEvent.scheduled.ending_timestamp;
                    }
                }
            }

            if (minTimestamp === initTimestamp) {
                // Error
                return 'unknown'
            } else {
                let thisDate = new Date(minTimestamp);
                let thisDate_hr = thisDate.getHours();
                let thisDate_min = thisDate.getMinutes();
                var hr_adj = thisDate_hr % 12;
                hr_adj = hr_adj === 0 ? 12 : hr_adj;
                return `${hr_adj}:${thisDate_min < 10 ? '0' : ''}${thisDate_min}${thisDate_hr < 12 ? 'am' : 'pm'}`
            }
        }

        return ''
    }

    function addNewAppointmentPressed() {
        props.addNewAppointmentPressed({
            timestamp: Date.now()
        });
    }

    function openVideo() {
        // "https://youtu.be/ISF5rtH0lRU"
        window.open("https://youtu.be/ISF5rtH0lRU", '_blank', 'noopener,noreferrer');
    }

	return (
		<div className="home-container">
            <div hidden={true}className="home-testing-container">
                <Calendar 
                    isBookable={true}/>
            </div>
			<div hidden={true || loadingMostRecent === true || showGettingStarted} className="home-container-clients-column-container home-container-clients-column-container-left">
                <div className="page-title-header-container">
                    <h1>Training Dashboard</h1>
                </div>
                <div className="home-container-clients-top-performers-container home-container-row-upper">
                    <div className="home-container-clients-top-performers-content">
                        <div className="home-container-clients-top-performers-chart-container">
                            <DashboardSummaryChart/>
                        </div>
                        <div className="home-container-clients-top-performers-list-container">
                            <div className="home-container-clients-top-performers-list-header-container">
                                <div className="home-container-clients-top-performers-list-cell-name-container">
                                    <p>Client</p>
                                    
                                </div>
                                <div className="home-container-clients-top-performers-list-cell-col-container">
                                    <p>Improvement</p>
                                </div>
                                <div className="home-container-clients-top-performers-list-cell-col-container">
                                    <p>Workouts</p>
                                </div>
                                <div className="home-container-clients-top-performers-list-cell-col-container">
                                    <p>Duration</p>
                                </div>
                                <div className="home-container-clients-top-performers-list-cell-col-container">
                                    <p>Tonnage</p>
                                </div>
                            </div>
                            {topClients.map((item: TopPerformer_t, index: number) => (
                                <div className="home-container-clients-top-performers-list-cell">
                                    <div className={`home-container-clients-top-performers-list-cell-colour-block home-container-clients-top-performers-list-cell-colour-block-${getColourName(item.color)}`}/>
                                    <div className="home-container-clients-top-performers-list-cell-name-container">
                                        <h4>{item.name}</h4>
                                    </div>
                                    <div className="home-container-clients-top-performers-list-cell-col-container">
                                        <h4>{item.improvement}%</h4>
                                    </div>
                                    <div className="home-container-clients-top-performers-list-cell-col-container">
                                        <h4>{item.workouts}</h4>
                                    </div>
                                    <div className="home-container-clients-top-performers-list-cell-col-container">
                                        <h4>{item.duration}</h4>
                                    </div>
                                    <div className="home-container-clients-top-performers-list-cell-col-container">
                                        <h4>{item.tonnage}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="home-container-clients-most-recent-container home-container-row-lower">
                    <div className="home-section-header-container">
                        <h4>Most Recent Workouts</h4>
                        <p>Below are 3 of your clients that have worked out most recently</p>
                    </div>
                    <div className="home-container-clients-most-recent-list-header-container">
                        <div className="home-container-clients-most-recent-list-header-container-col home-container-clients-most-recent-list-header-container-col-name">
                            <p>CLIENT</p>
                        </div>
                        <div className="home-container-clients-most-recent-list-header-container-col">
                            <p>DATE</p>
                        </div>
                        <div className="home-container-clients-most-recent-list-header-container-col">
                            <p>TONNAGE</p>
                        </div>
                        <div className="home-container-clients-most-recent-list-header-container-col home-container-clients-most-recent-list-header-container-col-muscles">
                            <p>MUSCLE GROUPS</p>
                        </div>
                    </div>
                    <div hidden={loadingMostRecent || mostRecentClients.length === 0} className="home-container-clients-most-recent-content">
                        {mostRecentClients.map((item: TopClientCard_t, index: number) => (
                            <TopClientCell
                                key={item.id}
                                name={item.name}
                                id={item.id}
                                last_workout_date={item.last_workout_date}
                                clientPressed={(e: string) => props.clientPressed(e)}/>
                        ))}
                    </div>
                    <div hidden={!loadingMostRecent} className="home-loading-container">
                        <p>Loading most recent workouts...</p>
                    </div>
                    <div hidden={loadingMostRecent || mostRecentClients.length > 0} className="home-loading-container">
                        <p>No recent workouts found</p>
                    </div>
                </div>
            </div>
            <div hidden={loadingMostRecent === true || showGettingStarted} className="home-container-clients-column-container home-container-clients-column-container-left home-container-clients-column-container-left-welcome">
                <div className="home-container-clients-column-container-left-welcome-inner">
                    <div className="home-container-clients-column-container-left-welcome-header">
                        <div className="home-container-clients-column-container-left-welcome-header-content">
                            <h1>Welcome back to<br/>Eigen{props.profile !== undefined && props.profile.first_name !== "" ? `, ${props.profile.first_name}` : ''}!</h1>
                            <h4>Find the latest of what your clients are up to below. For more details, open up their DynamicTrain Profiles, or open DynamicTrain directly below.</h4>
                            <div onClick={() => props.openClientInDynamicTrain("")} className="home-container-clients-column-container-left-welcome-video-button">
                                <p>Enter DynamicTrain</p>
                                <ArrowForward className="home-container-clients-column-container-left-welcome-video-button-icon"/>
                            </div>
                        </div>
                        {/*<div className="home-container-clients-column-container-left-welcome-img-container">
                            <img className="-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/welcome-bg.png`}/>
                        </div>*/}
                    </div>
                    <div className="home-container-clients-column-container-left-welcome-start-container">
                        <div className="home-container-clients-column-container-left-welcome-start-header-container">
                            <p>Check out what's new</p>
                            <h2>Your Athlete Activity Feed</h2>
                        </div>
                        <div className="home-container-clients-column-container-left-activity-feed-container">
                            <ActivityFeed 
                                openClientProfile={(c: string) => props.clientPressed(c)}
                                openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}/>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div hidden={loadingMostRecent === true || !showGettingStarted} className="home-container-clients-column-container home-container-clients-column-container-left home-container-clients-column-container-left-welcome">
                <div className="home-container-clients-column-container-left-welcome-inner">
                    <div className="home-container-clients-column-container-left-welcome-header">
                        <div className="home-container-clients-column-container-left-welcome-header-content">
                            <h1>Welcome to<br/>EigenTrainer{props.profile !== undefined && props.profile.first_name !== "" ? `, ${props.profile.first_name}` : ''}!</h1>
                            <h4>We’re happy to welcome to the team. Let’s get started creating Programs, Athletes and your Studio profile.</h4>
                            <div onClick={() => openVideo()} className="home-container-clients-column-container-left-welcome-video-button">
                                <p>Watch the video tutorial</p>
                                <ArrowForward className="home-container-clients-column-container-left-welcome-video-button-icon"/>
                            </div>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-img-container">
                            <img className="-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/welcome-bg.png`}/>
                        </div>
                    </div>
                    <div className="home-container-clients-column-container-left-welcome-start-container">
                        <div className="home-container-clients-column-container-left-welcome-start-header-container">
                            <p>Not sure where to start?</p>
                            <h2>Get started with EigenTrainer</h2>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-start-add-container">
                            <div className="home-container-clients-column-container-left-welcome-start-add-colour-container">
                                <div className="home-container-clients-column-container-left-welcome-start-add-colour-block home-container-clients-column-container-left-welcome-start-add-colour-block-client"/> 
                            </div>
                            <div className={`home-container-clients-column-container-left-welcome-start-add-text-container ${userHasClients ? 'home-container-clients-column-container-left-welcome-start-add-text-container-completed' : ''}`}>
                                <h4>Add an Athlete</h4>
                                <p>Athlete profiles give you the power to track your Athlete's progress, assign new plans, explore previous plans, and so much more.</p>
                                <div hidden={userHasClients} onClick={() => props.addNewClient()} className="home-container-clients-column-container-left-welcome-start-add-text-button">
                                    <p>Add your first Athlete</p>
                                    <ArrowForward className="home-container-clients-column-container-left-welcome-start-add-text-button-icon"/>
                                </div>
                                <div hidden={!userHasClients} className="home-container-clients-column-container-left-welcome-start-add-text-complete">
                                    <Check className="home-container-clients-column-container-left-welcome-start-add-text-complete-icon"/>
                                    <p>Complete</p>
                                </div>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-start-add-img-container">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/add-client.svg`}/>
                            </div>
                        </div>
                        
                        <div className="home-container-clients-column-container-left-welcome-start-add-container">
                            <div className="home-container-clients-column-container-left-welcome-start-add-colour-container">
                                <div className="home-container-clients-column-container-left-welcome-start-add-colour-block home-container-clients-column-container-left-welcome-start-add-colour-block-folder"/> 
                            </div>
                            <div className={`home-container-clients-column-container-left-welcome-start-add-text-container ${userHasFolders ? 'home-container-clients-column-container-left-welcome-start-add-text-container-completed' : ''}`}>
                                <h4>Create & organize your Programs</h4>
                                <p>Organizing your Program templates is key to saving time when assigning them to your Clients. Get started with Folders, Cycles, and Phases to save time creating on your next Program.</p>
                                <div hidden={userHasFolders} onClick={() => props.openPrograms()} className="home-container-clients-column-container-left-welcome-start-add-text-button">
                                    <p>Create your first Folder</p>
                                    <ArrowForward className="home-container-clients-column-container-left-welcome-start-add-text-button-icon"/>
                                </div>
                                <div hidden={!userHasFolders} className="home-container-clients-column-container-left-welcome-start-add-text-complete">
                                    <Check className="home-container-clients-column-container-left-welcome-start-add-text-complete-icon"/>
                                    <p>Complete</p>
                                </div>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-start-add-img-container">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/add-folder.svg`}/>
                            </div>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-start-add-container">
                            <div className="home-container-clients-column-container-left-welcome-start-add-colour-container">
                                <div className="home-container-clients-column-container-left-welcome-start-add-colour-block home-container-clients-column-container-left-welcome-start-add-colour-block-exercise"/> 
                            </div>
                            <div className={`home-container-clients-column-container-left-welcome-start-add-text-container ${userHasExercises ? 'home-container-clients-column-container-left-welcome-start-add-text-container-completed' : ''}`}>
                                <h4>Add to your Exercise Library</h4>
                                <p>EigenTrainer comes pre-loaded with hundreds of exercises, with multiple variations of each. But we know you have your tried and true techniques — get started building your custom exercise library for your next workout.</p>
                                <div hidden={userHasExercises} onClick={() => props.addNewExercise()} className="home-container-clients-column-container-left-welcome-start-add-text-button">
                                    <p>Add a custom Exercise</p>
                                    <ArrowForward className="home-container-clients-column-container-left-welcome-start-add-text-button-icon"/>
                                </div>
                                <div hidden={!userHasExercises} className="home-container-clients-column-container-left-welcome-start-add-text-complete">
                                    <Check className="home-container-clients-column-container-left-welcome-start-add-text-complete-icon"/>
                                    <p>Complete</p>
                                </div>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-start-add-img-container">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/add-program.svg`}/>
                            </div>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-start-add-container">
                            <div className="home-container-clients-column-container-left-welcome-start-add-colour-container">
                                <div className="home-container-clients-column-container-left-welcome-start-add-colour-block"/> 
                            </div>
                            <div className={`home-container-clients-column-container-left-welcome-start-add-text-container ${userHasClients ? 'home-container-clients-column-container-left-welcome-start-add-text-container-completed' : ''}`}>
                                <h4>Add Coaches to your Studio</h4>
                                <p>Build your studio by inviting fellow coaches to become Eigen Coaching Partners. You'll be able to share programs, clients, and exercises within your studio to make program building even faster.</p>
                                <div hidden={!userHasCoaches} onClick={() => props.inviteCoach()} className="home-container-clients-column-container-left-welcome-start-add-text-button">
                                    <p>Inivte a Coach</p>
                                    <ArrowForward className="home-container-clients-column-container-left-welcome-start-add-text-button-icon"/>
                                </div>
                                <div hidden={userHasCoaches} className="home-container-clients-column-container-left-welcome-start-add-text-complete">
                                    <Check className="home-container-clients-column-container-left-welcome-start-add-text-complete-icon"/>
                                    <p>Complete</p>
                                </div>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-start-add-img-container">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/add-coach.svg`}/>
                            </div>
                        </div>
                    </div>
                    <div className="home-container-clients-column-container-left-welcome-start-header-container">
                        <p><br/><br/>Check out what's new</p>
                        <h2>Your Athlete Activity Feed</h2>
                    </div>
                    <div className="home-container-clients-column-container-left-activity-feed-container">
                        <ActivityFeed 
                            openClientProfile={(c: string) => props.clientPressed(c)}
                            openClientInDynamicTrain={(c: string) => props.openClientInDynamicTrain(c)}/>
                    </div>
                    <div hidden={true} className="home-container-clients-column-container-left-welcome-start-container home-container-clients-column-container-left-welcome-start-container-nodes">
                        <div className="home-container-clients-column-container-left-welcome-start-header-container">
                            <p>Ready to take your training to the next level?</p>
                            <h2>Offer an unparralleled experience with Nodes</h2>
                            <h4>Nodes are the only wearables made for lifting — they've been engineered to track every rep, detect injury risks, and provide real-time stats like velocity, power, ROM, tempo, and more.</h4>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-nodes-img-container">
                            <img className="" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/welcome-bg-nodes.png`}/>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-multiple-nodes-img-container">
                            <div className="home-container-clients-column-container-left-welcome-multiple-nodes-img home-container-clients-column-container-left-welcome-multiple-nodes-img-1">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/devices/node-1.png`}/>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-multiple-nodes-img home-container-clients-column-container-left-welcome-multiple-nodes-img-2">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/devices/node-2.png`}/>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-multiple-nodes-img home-container-clients-column-container-left-welcome-multiple-nodes-img-3">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/devices/node-3.png`}/>
                            </div>
                        </div>
                        <div className="home-container-clients-column-container-left-welcome-nodes-content-container">
                            <div className="home-container-clients-column-container-left-welcome-nodes-content-row">
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card">
                                    
                                    <div className="home-container-clients-column-container-left-welcome-nodes-content-text-container">
                                        <h4>Offload your training time to Nodes.</h4>
                                        <p>Save training time when your Athletes workout on their own time with Nodes.</p>
                                    </div>
                                </div>
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card">
                                    
                                    <div className="home-container-clients-column-container-left-welcome-nodes-content-text-container">
                                        <h4>Effortlessly track stats that matter most.</h4>
                                        <p>Automatically record stats like power, velocity, ROM, tempo and much more.</p>
                                    </div>
                                </div>
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card">
                                    
                                    <div className="home-container-clients-column-container-left-welcome-nodes-content-text-container">
                                        <h4>Industry-standard injury prevention.</h4>
                                        <p>Allow your Athletes to workout on their own time without worrying about injury.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-nodes-content-row">
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card">
                                    
                                    <div className="home-container-clients-column-container-left-welcome-nodes-content-text-container">
                                        <h4>Go ahead — let your Athletes brag.</h4>
                                        <p>Level-up your studio with industry-leading fitness technology that will get your Athletes talking.</p>
                                    </div>
                                </div>
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card">
                                    
                                    <div className="home-container-clients-column-container-left-welcome-nodes-content-text-container">
                                        <h4>Offload your training time to Nodes.</h4>
                                        <p>Save training time when your Athletes workout on their own time with Nodes.</p>
                                    </div>
                                </div>
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card">
                                    
                                    <div className="home-container-clients-column-container-left-welcome-nodes-content-text-container">
                                        <h4>Offload your training time to Nodes.</h4>
                                        <p>Save training time when your Athletes workout on their own time with Nodes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="home-container-clients-column-container-left-welcome-nodes-content-row">
                                <div className="home-container-clients-column-container-left-welcome-nodes-content-card home-container-clients-column-container-left-welcome-nodes-content-card-full">
                                    <h2>Upgrade your training<br/>potential today.</h2>
                                    <p>Save time, money, and effort on tracking your Athletes, and allow Nodes to help you focus on what matters most — leading your Athletes toward results.</p>
                                    <div onClick={() => props.orderNodes()} className="home-container-clients-column-container-left-welcome-start-add-text-button">
                                        <p>Get my Nodes</p>
                                        <ArrowForward className="home-container-clients-column-container-left-welcome-start-add-text-button-icon"/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={loadingMostRecent === false} className="home-container-clients-column-container home-container-clients-column-container-left home-container-clients-column-container-left-loading">
                <div className="create-workout-exercise-configure-loading-spinner"/>
                <p>Loading dashboard</p>
            </div>
            <div className="home-container-stats-column-container home-container-clients-column-container-right">
                <div hidden={!isLoadingTrainerSchedule} className="home-container-clients-column-container-right-loading-curtain">
                    <div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Loading schedule</p>
                </div>
                <div className="home-container-clients-column-container-right-header-container">
                    <h2>Today's Schedule</h2>
                    <h4>You have <span>{trainerSchedule.length === 0 ? "no" : trainerSchedule.length} appointment{trainerSchedule.length === 1 ? '' : 's'}</span> booked today <div hidden={trainerSchedule.length === 0}><span>starting at {getStartTimeString()}</span> and <span>ending at {getEndTimeString()}</span></div></h4>
                    <div onClick={() => props.openSchedule()} className="home-container-clients-column-container-left-welcome-start-add-text-button">
                        <p>View my full schedule</p>
                        <ArrowForward className="home-container-clients-column-container-left-welcome-start-add-text-button-icon"/>
                    </div>
                    <div onClick={() => addNewAppointmentPressed()} className="home-container-clients-column-container-right-header-button-container">
                        <Add className="home-container-clients-column-container-right-header-button-icon"/>
                    </div>
                </div>
                
                <div className="home-container-clients-column-container-right-section-container">
                    <div className="home-container-clients-column-container-right-section-calendar-container">
                        <CalendarDayTimeline 
                            dayStartTime={480}
                            dayEndTime={1080}
                            selectedClient={undefined}
                            scheduledEvent={undefined}
                            selectedDuration={undefined}
                            selectedStartTime={undefined}
                            trainerSchedule={trainerSchedule}
                            conflictUpdated={(s: boolean) => null}/>
                    </div>
                </div>
                {/*<div className="home-container-exiring-plans-container home-container-row-upper">
                    <div className="home-section-header-container">
                        <h4>Expiring Plans</h4>
                        <p>These clients have working plans that are expiring this week</p>
                    </div>
                    <div className="home-container-exiring-plans-content">
                        <div className="home-loading-container">
                            <h4>All clear!</h4>
                            <p>No plans expiring this week</p>
                        </div>
                    </div>
                </div>
                <div hidden={true} className="home-container-stats-container home-container-row-lower">
                    <div className="home-section-header-container">
                        <h4>Stats Summary</h4>
                        <p>A brief overview of your recent training performance</p>
                    </div>
                    <div className="home-container-stats-content">
                        <div className="home-container-stats-card">
                            <div className="home-container-stats-header-icon-container home-container-stats-header-icon-container-blue">
                                <FitnessCenter className="home-container-stats-header-icon home-container-stats-header-icon-blue"/>
                            </div>
                            <h4>Total Workouts This Week</h4>
                            <h3>12</h3>
                            <p>+ 12.2%</p>
                        </div>
                        <div className="home-container-stats-card">
                            <div className="home-container-stats-header-icon-container home-container-stats-header-icon-container-green">
                                <Star className="home-container-stats-header-icon home-container-stats-header-icon-green"/>
                            </div>
                            <h4>Improving Client Count</h4>
                            <h3>16 / 20</h3>
                            <p>+ 8.0%</p>
                        </div>
                        <div className="home-container-stats-card">
                            <div className="home-container-stats-header-icon-container home-container-stats-header-icon-container-purple">
                                <Timeline className="home-container-stats-header-icon home-container-stats-header-icon-purple"/>
                            </div>
                            <h4>Total Weekly Training Time</h4>
                            <h3>54h</h3>
                            <p>- 4.7%</p>
                        </div>
                    </div>    
                </div>*/}
            </div>
		</div>
	)
}
/*
                    <div className="home-section-header-container">
                        <h4>Top Performing Clients</h4>
                        <p>Your star athletes that have shown the most progress over the last month</p>
                    </div>
*/

export default HomeMain;