import React, { useState, useEffect } from 'react';
import './VelocityCard.css';
import './common.css';

import StatGraph from './StatGraph';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function VelocityCard(props: any) {

    const [exerciseList, setExerciseList] = useState<any[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<any | undefined>(undefined);
    const [selectedSetIndex, setSelectedSetIndex] = useState(-1);
    const [initSet, setInitSet] = useState(false);

    const [heroStat, setHeroStat] = useState(0);
    const [units, setUnits] = useState("m/s");

    const [graphMetrics, setGraphMetrics] = useState<number[]>([]);
 
    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.exerciseList) {
        	setExerciseList(props.exerciseList);
        	setSelectedExercise(props.exerciseList[0] ?? undefined);
        }
    }, [props.exerciseList])

    useEffect(() => {
    	setSelectedExercise(props.selectedExercise);
	}, [props.selectedExercise])

	useEffect(() => {
    	setSelectedSetIndex(props.selectedSetIndex);
	}, [props.selectedSetIndex])

	useEffect(() => {
		configureStats()
	}, [selectedSetIndex, selectedExercise])

    const initialize = () => {
        
    }

    const configureStats = () => {
    	// console.log("POWER STATS:", selectedExercise)
    	if (!selectedExercise || !selectedExercise.sets || selectedExercise.sets.length < 1) return;

    	if (selectedSetIndex === -1) {
    		configureStats_sets();
    	} else {
    		configureStats_reps();
    	}

    }

    const configureStats_sets = () => {
    	var setAvgs: number[] = [];
    	for (var i = 0; i < selectedExercise.sets.length; i++) {
    		let thisSet = selectedExercise.sets[i];
    		if (thisSet && thisSet.rep_stats && thisSet.rep_stats.length > 0) {
    			var thisSetStatSum = 0;
    			var thisSetStatCnt = 0;
    			for (var j = 0; j < thisSet.rep_stats.length; j++) {
    				let thisRepStat = thisSet.rep_stats[j];
    				let thisVel = thisRepStat.peakVelocity ?? 0;
    				thisSetStatSum += thisVel;
    				thisSetStatCnt += 1;
    			}
    			let thisSetStatAvg = thisSetStatCnt === 0 ? 0 : thisSetStatSum / thisSetStatCnt;
    			setAvgs.push(thisSetStatAvg);

    		}
    	}
    	setGraphMetrics(setAvgs);
    }

    const configureStats_reps = () => {
    	var repAvgs: number[] = [];
    	let thisSet = selectedExercise.sets[selectedSetIndex];
		if (thisSet && thisSet.rep_stats && thisSet.rep_stats.length > 0) {

			for (var j = 0; j < thisSet.rep_stats.length; j++) {
				let thisRepStat = thisSet.rep_stats[j];
				let thisVel = thisRepStat.peakVelocity ?? 0;
				repAvgs.push(thisVel);
			}
		}
		// console.log("repAvgs:", repAvgs)
		setGraphMetrics(repAvgs);
    }

    const getAvg = () => {
    	var avgSum = 0;
    	var avgCnt = 0;
    	for (var i = 0; i < graphMetrics.length; i++) {
    		avgSum += graphMetrics[i];
    		avgCnt += 1;
    	}

    	return avgCnt === 0 ? 0 : Math.floor(avgSum / avgCnt * 100) / 100;
    }

    const getMax = () => {
    	return graphMetrics.length < 1 ? 0 : Math.max(...graphMetrics);
    }

    const getTargetMax = () => {
        if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.velocity) return -1;
    	let velocity_max = selectedExercise.data.motion_info.target.velocity.max ?? -1;

    	return velocity_max;
    }

    const getTargetMin= () => {
    	if (!selectedExercise || !selectedExercise.data || !selectedExercise.data.motion_info || !selectedExercise.data.motion_info.target || !selectedExercise.data.motion_info.target.velocity) return -1;
    	let velocity_min = selectedExercise.data.motion_info.target.velocity.min ?? -1;

    	return velocity_min;
    }

	return (
		<div className={ "performance-stat-card" }>
			<div className={ "performance-stat-card-header" }>
                <h4>Peak Velocity</h4>
            </div>
            {
            	exerciseList.length === 0 ?
            	<div className={ "performance-stat-card-body-empty-container" }>
            		<p>No exercises completed</p>
            	</div>
            	:
            	<div className={ "performance-stat-card-body-container" }>
	                <div className={ "performance-stat-card-body-content" }>
	                     <div className={ "performance-stat-card-body-hero-container" }>
	                     	<div className={ "performance-stat-card-body-hero-header-container performance-stat-card-body-hero-header-velocity" }>
			                    <h1>{ getAvg() } { units }</h1>
			                    <p>Average Peak Velocity</p>
			                    {
			                    	getTargetMin() > 0 && getTargetMax() > 0 && 
			                    	<h4>Target { Math.round(getTargetMin() * 100) / 100 } { units } – { Math.round(getTargetMax() * 100) / 100 } { units }</h4>
			                    }
		                    </div>
		                </div>
		                <div className={ "performance-stat-card-body-chart-container" }>
		                	<StatGraph
		                		data={ graphMetrics }
		                		units={ units }
		                		color={ "#F2B772" }
		                		statAvg={ getAvg() }
		                		statMax={ getTargetMax() }
		                		statMin={ getTargetMin() }/>
		                </div>
	                </div>
	            </div>
            }
            
            <div className={ "performance-stat-card-selector-container" }>
                <div className={ "performance-stat-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default VelocityCard;