import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';

import './ClientProfileGoals.css';
import './ClientProfileNotes.css';
import './ClientProfileCommon.css';

import Client_t from '../../Interfaces/Client_t';
import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

import FormTextInput from '../../components/Base/FormTextInput';
import ClientGoalProgressCard from '../../components/Clients/ClientGoalProgressCard';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';
import SwapHoriz from '@material-ui/icons/SwapHoriz';

function ClientProfileGoals(props: any) {

    const [initSet, setInitSet] = useState(false);

    const [allGoals, setAllGoals] = useState<Goal_t[]>([]);
    const [activeGoals, setActiveGoals] = useState<Goal_t[]>([]);

    const [goalTitle, setGoalTitle] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [goalCompletion, setGoalCompletion] = useState('--.-');
    const [remainingInGoal, setReaminingInGoal] = useState("--");
    const [goalDescString, setGoalDescString] = useState("");
    const [goalDNE, setGoalDNE] = useState(false);

    const [showingNewEntry, setShowingNewEntry] = useState(false);
    const [newEntryStage, setNewEntryStage] = useState(0);
    const [newEntryMetric, setNewEntryMetric] = useState(0);

    const [latestGoalIndex, setLatestGoalIndex] = useState(0);
    const [latestLogIndex, setLatestLogIndex] = useState(0);

    const [predictedDate, setPredictedDate] = useState("");
    const [scheduleRelative, setScheduleRelative] = useState(0);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getGoals();  
    }

    useEffect(() => {
        getGoals();
    }, [props.client]);


    function getGoals() {

        if (props.client === undefined) { return; }

        setIsLoading(true);

        let database = firebase.database();
        let ref = database.ref(`goals/${props.client.id}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                setGoalDNE(false);

                // Get ref data from .goals
                let goalsData: any[] = data.goals;

                let goalKeys = Object.keys(goalsData);

                var goalsList: Goal_t[] = [];
                var activeGoalsList: Goal_t[] = [];

                for (var i = 0; i < goalKeys.length; i++) {
                    let thisKey: any = goalKeys[i];
                    let thisGoalData: any = goalsData[thisKey];

                    //// console.log("--- RAW GOAL DATA FOR: ", thisKey, i, thisGoalData);

                    var actionList: GoalActions_t[] = [];

                    if (thisGoalData.actions !== undefined) {
                        let goalActionsData: any = thisGoalData.actions;
                        let goalActionsKeys: string[] = Object.keys(goalActionsData);
                        for (var j = 0; j < goalActionsKeys.length; j++) {
                            let thisActionData: any = goalActionsData[goalActionsKeys[j]];

                            var subActionList: GoalActions_t[] = [];

                            if (thisActionData.subActions !== undefined) {
                                let goalSubActionData: any = thisActionData.subActions;
                                let goalSubActionsKeys: string[] = Object.keys(goalSubActionData);
                                for (var k = 0; k < goalSubActionsKeys.length; k++) {
                                    let thisSubActionData: any = goalSubActionData[goalSubActionsKeys[k]];

                                    let thisSubAction: GoalActions_t = {
                                        index: k,
                                        goalDesc: thisSubActionData.goalDesc !== undefined ? thisSubActionData.goalDesc : "",
                                        durationDays: thisSubActionData.durationDays !== undefined ? thisSubActionData.durationDays : 0,
                                        action: thisSubActionData.action !== undefined ? thisSubActionData.action : "",
                                        metric: thisSubActionData.metric !== undefined ? thisSubActionData.metric : -1,
                                        metricUnit: thisSubActionData.metricUnit !== undefined ? thisSubActionData.metricUnit : "",
                                        timeframe: thisSubActionData.timeframe !== undefined ? thisSubActionData.timeframe : "per day",
                                    }
                                    subActionList.push(thisSubAction);
                                }
                            }

                            let thisAction: GoalActions_t = {
                                index: j,
                                goalDesc: thisActionData.goalDesc !== undefined ? thisActionData.goalDesc : "",
                                durationDays: thisActionData.durationDays !== undefined ? thisActionData.durationDays : 0,
                                subActions: subActionList
                            }
                            actionList.push(thisAction);
                        }
                    }


                    let thisGoal: Goal_t = {
                        type: (thisGoalData.type as ("custom" | "get-fit" | "loose-weight" | "gain-weight" | "muscle-toning" | "body-building" | "power-lifting" | "cardio-endurance")) ? thisGoalData.type : "custom",
                        customType: thisGoalData.custom_type !== undefined ? thisGoalData.custom_type : "",
                        targetMetric: thisGoalData.target_metric !== undefined ? thisGoalData.target_metric : 0,
                        targetUnitsKG: thisGoalData.target_units_kg !== undefined ? thisGoalData.target_units_kg : false,
                        targetTimestamp: thisGoalData.target_timestamp !== undefined ? thisGoalData.target_timestamp : 0,
                        startingTimestamp: thisGoalData.starting_timestamp !== undefined ? thisGoalData.starting_timestamp : 0,
                        actions: actionList,
                        isEditing: false,
                        id: thisKey,
                        isComplete:  thisGoalData.complete !== undefined ? thisGoalData.complete : false
                    };

                    //// console.log("--- PARSED GOAL DATA FOR: ", thisKey, i, thisGoalData);

                    goalsList.push(thisGoal);
                    if (thisGoal.isComplete === undefined || thisGoal.isComplete === false) {
                        activeGoalsList.push(thisGoal);
                    }
                }

                setAllGoals(goalsList);
                setActiveGoals(activeGoalsList);
            }
            setIsLoading(false);
        });
    }

    // function getGoals() {

    //     if (props.client === undefined) { return; }

    //     setIsLoading(true);

    //     let database = firebase.database();
    //     let ref = database.ref(`goals/${props.client.id}`);
    //     ref.on('value', function(snapshot) {
    //         if (snapshot.exists() === true) {
    //             let data = snapshot.val();

    //             setGoalDNE(false);

    //             // Get ref data from .goals
    //             let goalsData: any[] = data.goals;

    //             let mostRecentGoalData = goalsData[goalsData.length - 1];
    //             setLatestGoalIndex(goalsData.length - 1);

    //             // Get most recent data from .goal_logs
    //             let goalLogsData: any[] = data.goal_logs;
    //             if (goalLogsData === undefined) { return; }

    //             let mostRecentLogSet = goalLogsData[goalLogsData.length - 1];
    //             let mostRecentLogs = mostRecentLogSet.logs;
    //             let lastLog = mostRecentLogs[mostRecentLogs.length - 1];
    //             setLatestLogIndex(mostRecentLogs.length - 1);

    //             // console.log("---- GOALS -----");
    //             // console.log(mostRecentGoalData);
    //             // console.log(lastLog);

    //             let startingMetric = mostRecentGoalData.starting_metric;
    //             let startingTS = mostRecentGoalData.starting_timestamp;
    //             let targetMetric = mostRecentGoalData.target_metric;
    //             let targetTS = mostRecentGoalData.target_timestamp;
    //             let deltaMetric_target = targetMetric - startingMetric;

    //             let mostRecentMetricLog = lastLog.metric;
    //             let mostRecentTS = lastLog.timestamp;
    //             let deltaMetric_mostRecent = targetMetric - mostRecentMetricLog;//mostRecentMetricLog - startingMetric;
    //             //     let workoutGoals = [{title: 'Get Fit', desc:'Improve overall health'}, {title: 'Loose Weight', desc:'Reach a lower target bodyweight'}, {title: 'Gain Muscle', desc:'Reach a higher target bodyweight'}, {title: 'Muscle Toning', desc:'Lower body fat percentage'}, {title: 'Body Building', desc:'Gain muscle and lower BPF'}, {title: 'Power Lifting', desc:'Increase lifting capacity'}];
    //             let workoutGoalMetrics = [{title: 'Target workout days per week', units: 'days / week'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target body fat percentage', units: '% BF'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target max. lift', units: '_weight_'}];
    //             let thisGoalMetrics = workoutGoalMetrics[mostRecentGoalData.index];
    //             var thisGoalsUnits = thisGoalMetrics.units;
    //             if (thisGoalsUnits === '_weight_') {
    //                 thisGoalsUnits = props.client.isMetric ? "KGs" : "LBS";
    //             }

    //             let remainingRounded = Math.abs(Math.round(deltaMetric_mostRecent * 10) / 10);
    //             let remainingString = `${remainingRounded < 10 ? '0' : ''}${remainingRounded}${remainingRounded % 1 === 0 ? '.0' : ''} ${thisGoalsUnits}`
    //             setReaminingInGoal(remainingString)

    //             let completion = 1 - Math.abs(deltaMetric_mostRecent / deltaMetric_target);
    //             let completionRounded = Math.round(completion * 1000) / 10;
    //             let completionString = `${completionRounded < 10 ? '0' : ''}${completionRounded}${completionRounded % 1 === 0 ? '.0' : ''}`
    //             setGoalCompletion(completionString);

    //             // Goal description string
    //             let workoutGoalTitles = ["Workout vol", "Cutting", "Bulking", "Cutting", "Changing", "Lifting"];
    //             let goalDescString_temp = `${workoutGoalTitles[mostRecentGoalData.index]} from ${Math.round(startingMetric)}${thisGoalsUnits} to ${Math.round(targetMetric)}${thisGoalsUnits}`;
    //             //setGoalDescString(goalDescString_temp);
    //             setGoalTitle(goalDescString_temp);

    //             // Determind ahead or behind schedule
    //             let m_target = Math.abs(targetMetric - startingMetric) / (targetTS - startingTS);
    //             let m_actual = Math.abs(mostRecentMetricLog - startingMetric) / (mostRecentTS - startingTS);

    //             if (Math.abs(m_target) > Math.abs(m_actual)) {
    //                 setScheduleRelative(-1);
    //             } else {
    //                 setScheduleRelative(1);
    //             }

    //             // console.log("m_target = ", m_target);
    //             // console.log("m_actual = ", m_actual);
    //             // console.log("mostRecentMetricLog = ", mostRecentMetricLog);
    //             // console.log("targetMetric = ", targetMetric);
    //             // console.log("startingMetric = ", startingMetric);
    //             // console.log("mostRecentTS = ", mostRecentTS);
    //             // console.log("targetTS = ", targetTS);
    //             // console.log("startingTS = ", startingTS);

    //             let predictedTS = ((targetMetric - startingMetric) / m_actual) + startingTS; //((targetMetric - startingMetric) / m_actual) + startingTS;
    //             // console.log("PREDICTED TS: ", predictedTS);

    //             let predDate = new Date(predictedTS);

    //             let monthStrings = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    //             let predictedDate_temp = `${monthStrings[predDate.getMonth()]} ${predDate.getDate()}, ${predDate.getFullYear()}`
    //             setPredictedDate(predictedDate_temp);
    //         } else {
    //             setGoalDNE(true);
    //         }

    //         setIsLoading(false);
    //     });
    // }

    function addGoalPressed() {
        if (props.addGoalPressed !== undefined) {
            props.addGoalPressed();
        }
    }

    function toggleShowEntry() {
        setShowingNewEntry(!showingNewEntry)
    }

    function inputChanged(metric: string) {
        setNewEntryMetric(Number(metric));
    }

    function logEntry() {
        if (props.client === undefined || newEntryMetric === 0) { return; }

        setNewEntryStage(1);
        let database = firebase.database();
        let ref = database.ref(`goals/${props.client.id}/goal_logs/${latestGoalIndex}/logs/${latestLogIndex + 1}`);
        ref.set({metric: newEntryMetric, timestamp: Date.now()});

        setTimeout(() => {
            setNewEntryStage(2);
            setNewEntryMetric(0);
            setTimeout(() => {
                setNewEntryStage(0);
                setShowingNewEntry(false);
            }, 1200);
        }, 1200);
    }

    function deleteGoal(goal: Goal_t, goalIndex: number) {
        let clientID = props.client.id;

        let database = firebase.database();
        let ref = database.ref(`goals/${clientID}/goals/${goal.id}`); 
        ref.set(null)
        //setActiveGoals(activeGoals.filter((item: Goal_t, index: number) => index !== goalIndex));
    }

    function editGoal(goal: Goal_t, goalIndex: number) {
        props.editGoal(goal);
    }

    window.addEventListener('click', function(e){   

        let buttonID = `client-${props.client !== undefined ? props.client.id : 'abc'}-goal-button`;
        let menuID = `client-${props.client !== undefined ? props.client.id : 'abc'}-goal-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showingNewEntry === true) {
                    setShowingNewEntry(false);
                }
            }
        }
    });

	return (
		<div className="client-profile-page-container">
            <div hidden={allGoals.length !== 0 || isLoading} className="client-profile-page-blank">
                <h2>No goals added</h2>
                <p>It looks like there aren't any goals for {props.client !== undefined ? props.client.first_name : "your client"} yet.</p>
                <div onClick={() => props.newGoalPressed()} className="client-profile-page-add-first-note-button">
                    <Add className="client-profile-page-add-first-note-button-icon"/>
                    <p>Create a goal</p>
                </div>
            </div>
			<div hidden={allGoals.length === 0 || isLoading} className="client-profile-page-goals-container">
                <div hidden={true} className="client-profile-page-goals-header-container">
                    <div className="client-profile-page-goals-header-title-container">
                        <h4>{goalTitle}</h4>
                    </div>
                    
                </div>
                <div className="client-profile-page-goals-content-container">
                    <div className="client-profile-general-full-card client-profile-general-full-card-no-line">
                        <div className="client-profile-general-card-header">
                            <h4>Goal Progress Summary</h4>
                            <div className="client-profile-page-goals-header-buttons-container">
                                <div hidden={true} onClick={() => props.newGoalPressed()} className="client-profile-page-goals-header-button client-profile-page-goals-header-button-dark">
                                    <SwapHoriz className="client-profile-page-goals-header-button-icon"/>
                                    <p>Change goal</p>
                                </div>
                                <div onClick={() => props.logMeasurementPressed()} className="client-profile-page-goals-header-button client-profile-page-goals-header-button-dark">
                                    <Add className="client-profile-page-goals-header-button-icon"/>
                                    <p>New Log</p>
                                </div>
                                <div id={`client-${props.client !== undefined ? props.client.id : 'abc'}-goal-button`} onClick={() => props.newGoalPressed()} className="client-profile-page-goals-header-button client-profile-page-goals-header-button-blue">
                                    <Add className="client-profile-page-goals-header-button-icon"/>
                                    <p>Add Goal</p>
                                </div>
                                <CSSTransition in={showingNewEntry} timeout={200} classNames="menu" unmountOnExit>
                                    <div id={`client-${props.client !== undefined ? props.client.id : 'abc'}-goal-menu`} className="workout-plan-list-header-add-menu-container client-profile-page-goals-header-add-menu-container">
                                        <div hidden={newEntryStage !== 0} className="workout-plan-list-header-add-menu-content">
                                            <div className="workout-plan-list-header-add-menu-triangle"/>
                                            <div className="workout-plan-list-header-add-menu-content-header">
                                                <h4>Log new entry</h4>
                                                <p>Enter the latest metric to log a new entry</p>
                                            </div>
                                            <div className="workout-plan-list-header-add-menu-content-input-container">
                                                <FormTextInput 
                                                    placeholder="Entry metric" 
                                                    alert={false} 
                                                    alertText=""
                                                    isSearch={true}
                                                    inputChanged={(e: string) => inputChanged(e)}/>
                                            </div>
                                            <div onClick={() => logEntry()} className={`client-profile-page-goals-header-add-menu-content-input-button client-profile-page-goals-header-add-menu-content-input-button-${newEntryMetric === 0 ? 'dark' : 'blue'}`}>
                                                Log entry
                                            </div>
                                        </div>
                                        <div hidden={newEntryStage !== 1} className="workout-plan-list-header-add-menu-content workout-plan-list-header-add-menu-content-loading">
                                            <div className="workout-plan-list-header-add-menu-triangle"/>
                                            <p>Logging entry...</p>
                                        </div>
                                        <div hidden={newEntryStage !== 2} className="workout-plan-list-header-add-menu-content workout-plan-list-header-add-menu-content-complete">
                                            <div className="workout-plan-list-header-add-menu-triangle"/>
                                            <h4>Logged!</h4>
                                            <p>Your entry was successfully logged!</p>
                                        </div>
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                        <div className="client-profile-page-goal-card-content client-profile-page-goal-card-content-summary">
                            <p>{props.client !== undefined ? props.client.first_name : "Your client"} currently has {activeGoals.length === 0 ? 'no' : activeGoals.length} active goal{activeGoals.length === 1 ? '' : 's'}, and a total of {allGoals.length} goal{activeGoals.length === 1 ? '' : 's'} in thier history as your client.</p>
                        </div>
                    </div>
                    <div className="client-profile-goals-goal-card-container">
                        {
                            activeGoals.map((item: Goal_t, index: number) => (
                                <ClientGoalProgressCard 
                                        goal={item}
                                        index={index}
                                        client={props.client}
                                        client_name={props.client !== undefined && props.client.first_name !== undefined ? props.client.first_name : "Your Client"}
                                        getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
                                        deleteGoal={() => deleteGoal(item, index)}
                                        editGoal={() => editGoal(item, index)}
                                        logMeasurementPressed={() => props.logMeasurementPressed()}/>
                            ))
                        }
                    </div>
                </div>
                <div className="client-profile-goals-bottom-spacer"/>
            </div>
            <div hidden={!isLoading} className="client-profile-page-blank">
                <h2>   </h2>
                <p>Loading goals...</p>
            </div>
		</div>
	)
}

export default ClientProfileGoals;