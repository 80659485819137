import React, { useState, useEffect } from 'react';
import './ClientCell.css';
import firebase from '../../Firebase';

import Plan_t from '../../Interfaces/Plan_t';

function ClientCell(props: any) {

	const [initSet, setInitSet] = useState(false);
	const [imgUrl, setImgUrl] = useState('');
	const [daysSinceWorkout, setDaysSinceWorkout] = useState(-1);
	const [lastWorkoutDayString, setLastWorkoutDayString] = useState("");
	const [daysSinceWorkoutSet, setDaysSinceWorkoutSet] = useState(false);


	const [isLoadingPlan, setIsLoadingPlan] = useState(false);
	const [nextWorkoutName, setNextWorkoutName] = useState("Unknown workout");
	const [currentPlan, setCurrentPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
    const [currentPlanPathNames, setCurrentPlanPathNames] = useState("");


	if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getImage();
        setDaysSinceWorkoutSet(true);
		getLastWorkout();
		getPlan();
    }
	

	// if (daysSinceWorkoutSet === false) {
	// 	setDaysSinceWorkoutSet(true);
	// 	getLastWorkout();
	// }

	useEffect(() => {
		if (imgUrl === '') {
			getImage();
		}
	}, [props.client])

	function getImage() {
		if (props.client === undefined || props.client.id === '') { return; }
		const storage = firebase.storage();
		//// console.log(props.client.id);
		storage.ref('profileImages').child(props.client.id).child('profileImage/image.jpg').getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}

	function getLastWorkout() {
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		let database = firebase.database();
		let ref = database.ref(`workout_logs/${props.client.id}`);

		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let workoutList: any[] = snapshot.val();
				let keys = Object.keys(workoutList);
				let lastKey: any = keys[keys.length - 1];
				//// console.log(`LAST KEY: ${lastKey}`);
				let lastWorkout: any = workoutList[lastKey];
				let lastWorkoutTimestamp = lastWorkout.completed_date;

				let today = new Date();
				let timeStampSplitA = lastWorkoutTimestamp.split(" "); // split appart the data and time
		        let prevWorkoutParsed = timeStampSplitA[0].split('-'); // split the date appart at dashes

		        let woDate = new Date(prevWorkoutParsed[0],prevWorkoutParsed[1],prevWorkoutParsed[2]);

		        let timeDiff = today.getTime() - woDate.getTime();
		        let daysDiff = timeDiff / (1000 * 3600 * 24);
		        let filteredDay = Math.ceil(daysDiff) - 1;


		        // Parse time
		        let times = timeStampSplitA[1].split(':');
		        let h = parseInt(times[0]) % 12;
		        let m = parseInt(times[1]);
		        let isPM = parseInt(times[0]) > 12;

		        let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
		       	let lastWorkoutDayString_temp = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}${isPM ? 'pm' : 'am'}, ${moStrings[woDate.getMonth() - 1]} ${woDate.getDate()}`

		        setLastWorkoutDayString(lastWorkoutDayString_temp);

				setDaysSinceWorkout(Math.ceil(filteredDay));
			} 
		});
	}

	function cellSelected() {
		props.cellSelected(props.client);
	}

	function moreInfoSelected() {
		props.moreInfoSelected(props.client);
	}

	function getDaysSinceWorkoutString() {
		if (daysSinceWorkout < 0) {
			return "No workouts found";
		} else {
			return daysSinceWorkout === 1 ? 'Worked out today' : `${daysSinceWorkout - 1} day${daysSinceWorkout === 2 ? '' : 's'} ago`;
		}
	}


	function getPlan() {
        if (props.client === undefined) { return; }
        let clientID = props.client.id;

        // // console.log("Client Cell | getPlan", clientID);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/plans`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planList = snapshot.val();
                let planListkeys = Object.keys(planList);
                // // console.log("planList:", planList)
                if (planListkeys.length > 0) {
                    var planObjs_temp: string[] = [];
                    
                    for (var i = 0; i < planListkeys.length; i++) {
                        let thisPlanObj = planList[planListkeys[i]];
                        // // console.log(i, "thisPlanObj:", thisPlanObj)
                        let thisPlanID = thisPlanObj.template_plan_id;
                        planObjs_temp.push(thisPlanObj);

                        if (i === planList.length - 1) {
                            // Current plan
                            let startTime = thisPlanObj.starting_timestamp;
                            let endTime = thisPlanObj.ending_timestamp;
                            let currentTime = Date.now();
                            let endTime_adj = endTime - startTime;
                            let currentTime_adj = currentTime - startTime;
                            let dt = endTime_adj - currentTime_adj;
                            let percentComplete = Math.floor(100 * currentTime_adj / endTime_adj);

                            // set current day index
                            var planIndex = 0;
                            if (thisPlanObj.day_index !== undefined && thisPlanObj.day_index !== null && thisPlanObj.day_index >= 0) {
                                planIndex = thisPlanObj.day_index;
                            }

                            let numDaysRemaining = Math.ceil(dt / 1000 / 60 / 60 / 24);
                            getPlanDetails(thisPlanID, planIndex);
                        }
                    }
                }
            } else {
                setIsLoadingPlan(false);
            }
        });
    }

    function getPlanDetails(forID: string, planIndex: number) {

        // // console.log("getPlanDetails", forID);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${forID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planData = snapshot.val();

                let newPlan: Plan_t = {
                    title: planData.title !== null ? planData.title : "", 
                    description: planData.description !== null ? planData.description : "", 
                    path: planData.location_path !== null ? planData.location_path : "",
                    id: forID, 
                    createdBy_ID: planData.create_by !== null ? planData.create_by : "", 
                    created_timestamp: planData.created_timestamp !== null ? planData.created_timestamp : 0, 
                    workout_ids: planData.workouts !== null ? planData.workouts : [],
                    availability: planData.availability !== null ? planData.availability : 0
                };

                setCurrentPlan(newPlan);
                if (newPlan.path !== undefined) {
                    getPathNames(newPlan.path);
                }

                if (planIndex < newPlan.workout_ids.length && planIndex >= 0) {
                	getNextWorkoutName(newPlan.workout_ids[planIndex]);
                } else if (newPlan.workout_ids.length > 0) {
                	getNextWorkoutName(newPlan.workout_ids[0]);
                }
            }

            setIsLoadingPlan(false);
        });
    }

    function getNextWorkoutName(forID: string) {
    	let database = firebase.database();
        let ref = database.ref(`workouts/${forID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let planData = snapshot.val();
                setNextWorkoutName(planData.title);
            }
        });
    }

    function getPathNames(pathIDs: string) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let splitPath = pathIDs.split('/');
        let firstFolderID = splitPath[1];

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root/${firstFolderID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists()) {
                // let foldersObj = snapshot.val();
                // var pathNames = foldersObj.name + "/";

                // var parentObj = foldersObj;
                // for (var i = 2; i < splitPath.length; i++) {
                //     let thisChildID = splitPath[i];
                //     let parentsChildObj = parentObj.children[thisChildID];
                //     pathNames += parentsChildObj.name + "/";
                //     parentObj = parentsChildObj;
                // }

                // setCurrentPlanPathNames(pathNames);
            }
        });
    }



	return (
		<div onClick={() => cellSelected()} className={"client-cell-container " + (props.client.isSelected ? "selected" : "")}>
			<div className="client-cell-content">
				<div className="client-card-image-container">
					<img  
						className="client-card-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${props.client.first_name}`} />
				</div>
				<div className="client-card-col-container">
					<div className="client-card-col client-card-info-container">
						<h4>{props.client.first_name} {props.client.last_name}</h4>
					</div>
					<div hidden={currentPlan.title === ""} className="client-card-col client-card-info-container">
						<h4>{nextWorkoutName}</h4>
					</div>
					<div hidden={currentPlan.title !== ""} className="client-card-col client-card-info-container client-card-info-container-no-workouts">
						<h4>No plan</h4>
						<div className="client-card-info-container-no-workouts-alert"><p>!</p></div>
					</div>
					<div hidden={lastWorkoutDayString === ""} className="client-card-col client-card-info-container">
						<h4>{lastWorkoutDayString}</h4>
					</div>
					<div hidden={lastWorkoutDayString !== ""} className="client-card-col client-card-info-container client-card-info-container-no-workouts">
						<h4>No workouts found</h4>
					</div>
					<div className="client-card-col client-cell-goal-container">
						<div className="client-cell-goal-text-container">
							<h4>85.4%</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

/*
<div onClick={() => cellSelected()} className={"client-cell-container " + (props.client.isSelected ? "selected" : "")}>
			<div className="client-cell-content">
				<div className="client-card-image-container">
					<img  
						className="client-card-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${props.client.first_name}`} />
				</div>
				<div className="client-card-col-container">
					<div className="client-card-col client-card-info-container">
						<h4>{props.client.first_name} {props.client.last_name}</h4>
						<p>{props.client.email}</p>
					</div>
					<div hidden={currentPlan.title === ""} className="client-card-col client-card-info-container">
						<h4>{nextWorkoutName}</h4>
						<p>{currentPlanPathNames}<span>{currentPlan.title}</span></p>
					</div>
					<div hidden={currentPlan.title !== ""} className="client-card-col client-card-info-container client-card-info-container-no-workouts">
						<h4>No plan</h4>
						<div className="client-card-info-trained-with-me-container">
							<p>{props.client.first_name} has no assigned plan</p>
						</div>
						<div className="client-card-info-container-no-workouts-alert"><p>!</p></div>
					</div>
					<div hidden={lastWorkoutDayString === ""} className="client-card-col client-card-info-container">
						<h4>{lastWorkoutDayString}</h4>
						<div className="client-card-info-trained-with-me-container">
							<div className="client-card-info-trained-with-me-icon"/>
							<p>Trained with me</p>
						</div>
					</div>
					<div hidden={lastWorkoutDayString !== ""} className="client-card-col client-card-info-container client-card-info-container-no-workouts">
						<h4>No workouts found</h4>
						<div className="client-card-info-trained-with-me-container">
							<p>{props.client.first_name} has not logged any workouts</p>
						</div>
					</div>
					<div className="client-card-col client-cell-goal-container">
						<div className="client-cell-goal">Bodybuilding</div>
						<div className="client-cell-goal-text-container">
							<h4>85.4%</h4>
							<p>8.5 / 10LBS</p>
						</div>
					</div>
				</div>
			</div>
		</div>
*/

export default ClientCell; 