import React, { useState, useEffect } from 'react';
import './WorkoutPlanListFolder.css';

import Workout_t from '../../../Interfaces/Workout_t'
import Folder_t from '../../../Interfaces/Folder_t';
import Plan_t from '../../../Interfaces/Plan_t';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import Assignment from '@material-ui/icons/Assignment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import DragIndicator from '@material-ui/icons/DragIndicator';
import PlayArrow from '@material-ui/icons/PlayArrow';


function WorkoutPlanListFolder(props: any) {

    let initFolder = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
            isOpen: false
        };

    const [initSet, setInitSet] = useState(false);
    const [thisFolder, setThisFolder] = useState<Folder_t>(initFolder);

    const [activePath, setActivePath] = useState("");
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.folder !== undefined) {
            setThisFolder(props.folder);
        }
    }, [props.folder])

    useEffect(() => {
        if (props.activePath !== undefined) {
            setActivePath(props.activePath);
        }
    }, [props.activePath])

    function initialize() {
        if (props.folder !== undefined) {
            var folderTemp = props.folder;
            folderTemp.isOpen = false;
            setThisFolder(folderTemp);
        }
    }

    function toggleDisplay() {
        if (getChildType() !== "folder") return
        if (props.folder.type !== "plan") {
            props.toggleDisplay(props.folder);
        }
        //setThisFolder({...thisFolder, isOpen: thisFolder.isOpen === undefined ? false : !thisFolder.isOpen});
    }

    function folderSelected(folder: Folder_t) {
        // if (folder.id === thisFolder.id) {
        //     //setThisFolder({...thisFolder, isOpen: true});
        // }



        props.folderSelected(folder);

        if (getChildType(folder) !== "folder") return;

        if (folder.type !== "plan") {
            props.turnOnDisplay(props.folder);
        }
    }

    function getChildType(forFolder?: Folder_t) {

        let thisFolderTemp = forFolder ?? thisFolder;
        var selectedTypeName = ""
        if (thisFolderTemp.children !== undefined && thisFolderTemp.children.length > 0) {
            selectedTypeName = thisFolderTemp.children[0].type;
        }

        return selectedTypeName;
    }

    function getChildTypeString() {
        var selectedTypeName = ""
        if (thisFolder.children !== undefined && thisFolder.children.length > 0) {
            selectedTypeName = thisFolder.children[0].type;
        }

        switch(selectedTypeName) {
            case "plan":
                return `${thisFolder.children.length} Phase${thisFolder.children.length === 1 ? '' : 's'}`
                break;
            case "cycle":
                return `${thisFolder.children.length} Cycle${thisFolder.children.length === 1 ? '' : 's'}`
                break;
            case "folder":
                return `${thisFolder.children.length} Folder${thisFolder.children.length === 1 ? '' : 's'}`
                break;
            default:
                return "Empty"
                break;
        }
    }

    function getChildTypeColourClass() {
        var selectedTypeName = ""
        if (thisFolder.children !== undefined && thisFolder.children.length > 0) {
            selectedTypeName = thisFolder.children[0].type;
        }

        return `workout-plan-list-card-summary-circle-colour-${selectedTypeName}`;
    }

    function getIsActive() {
        if (activePath === "") { return false; }

        return activePath.includes(thisFolder.path);

    }


	return (
        <div className="workout-plan-list-folder" hidden={ thisFolder.type === "cycle" }>
            <div className={ "workout-plan-list-folder-branch-container" } hidden={ !props.showBranch }>
                <div className={ "workout-plan-list-folder-branch-container-inner" }>
                    <div className={ `workout-plan-list-folder-branch ${getChildType() === "folder" ? "workout-plan-list-folder-branch-folder" : getChildType() === "plan" || getChildType() === "cycle" ? "workout-plan-list-folder-branch-plan" : ""}` }></div>
                    <div className={ `workout-plan-list-folder-branch-dot ${getChildType() === "folder" ? "workout-plan-list-folder-branch-folder" : getChildType() === "plan" ? "workout-plan-list-folder-branch-plan" : ""}` }></div>
                </div>
            </div>
    		<div className={`workout-plan-list-folder-container ${props.currentFolder.id === thisFolder.id ? 'workout-plan-list-folder-container-selected' : ''}`}>
                <div className={ "workout-plan-list-folder-container-header" }>
                    <div onClick={() => folderSelected(thisFolder)} hidden={thisFolder.type !== "folder" || getChildType() === "cycle" || getChildType() === "plan"} className="workout-plan-list-card-icon-container workout-plan-list-card-icon-container-folder">
                        <Folder className={`workout-plan-list-card-icon workout-plan-list-card-icon-folder`}/>
                    </div>
                    <div onClick={() => folderSelected(thisFolder)} hidden={thisFolder.type !== "folder" || getChildType() !== "cycle"} className="workout-plan-list-card-icon-container workout-plan-list-card-icon-container-plan">
                        <PlayArrow className={`workout-plan-list-card-icon workout-plan-list-card-icon-plan`}/>
                    </div>
                    <div onClick={() => folderSelected(thisFolder)} hidden={thisFolder.type !== "folder" || getChildType() !== "plan"} className="workout-plan-list-card-icon-container workout-plan-list-card-icon-container-plan">
                        <PlayArrow className={`workout-plan-list-card-icon workout-plan-list-card-icon-plan`}/>
                    </div>
                    <div hidden={thisFolder.type !== "plan"} className="workout-plan-list-card-icon-container workout-plan-list-card-icon-container-plan">
                        <Assignment className={`workout-plan-list-card-icon workout-plan-list-card-icon-plan`}/>
                    </div>
                    <div hidden={thisFolder.type !== "cycle"} className="workout-plan-list-card-icon-container workout-plan-list-card-icon-container-cycle">
                        <List className={`workout-plan-list-card-icon workout-plan-list-card-icon-cycle`}/>
                    </div>
                </div>
                <div className={ "workout-plan-list-folder-container-body" }>
                    <div className={ "workout-plan-list-folder-container-body-drag-icon-container" }>
                        <DragIndicator className="workout-plan-list-folder-container-body-drag-icon"/>
                    </div>
                    <div onClick={() => folderSelected(thisFolder)} className={`workout-plan-list-folder-card-body-list-container-folder workout-plan-list-folder-card-body-list-title-container ${thisFolder.type === 'plan' ? 'workout-plan-list-folder-card-body-list-title-container-phase' : ''}`}>
                        <h4>{thisFolder.type === 'plan' && thisFolder.phaseName !== undefined ? `${thisFolder.phaseName } | ` : ''}{thisFolder.title}</h4>
                        <div hidden={!getIsActive()} className="workout-plan-list-card-summary-active-circle">
                            <p>ACTIVE</p>
                        </div>
                    </div>
                    <div onClick={() => folderSelected(thisFolder)} hidden={getChildType() === "cycle" || getChildType() === "plan" || thisFolder.type === "plan"} className="workout-plan-list-card-summary-container">
                        {/*<div className={`workout-plan-list-card-summary-circle ${getChildTypeColourClass()}`}/>*/}
                        <div className="workout-plan-list-card-summary-text-container">
                            <p>{getChildTypeString()}</p>
                        </div>
                    </div>
                    <div onClick={() => folderSelected(thisFolder)} hidden={getChildType() !== "cycle" && getChildType() !== "plan"} className="workout-plan-list-card-summary-container">
                        {/*<div className={`workout-plan-list-card-summary-circle ${getChildTypeColourClass()}`}/>*/}
                        <div className="workout-plan-list-card-summary-text-container workout-plan-list-card-summary-text-container-program">
                            <p>Program</p>
                        </div>
                    </div>
                    <div hidden={ getChildType() === "cycle" || getChildType() === "plan" || thisFolder.type === "plan"} onClick={() => toggleDisplay()} className="workout-plan-list-folder-expand-container">
                        <div hidden={thisFolder.isOpen === undefined || thisFolder.isOpen === true} className="workout-plan-list-folder-expand-icon-container">
                            <ExpandMore className="workout-plan-list-folder-expand-icon"/>
                        </div>
                        <div hidden={thisFolder.isOpen !== undefined && thisFolder.isOpen === false} className="workout-plan-list-folder-expand-icon-container">
                            <ExpandLess className="workout-plan-list-folder-expand-icon"/>
                        </div>
                    </div>
                </div>
                
                
            </div>
            <div hidden={thisFolder.children.length === 0 || (thisFolder.isOpen !== undefined && thisFolder.isOpen === false)} className="workout-plan-list-folder-sub-folder-container">
                { thisFolder.children.map((item: Folder_t, index: number) => (
                    <WorkoutPlanListFolder 
                        folder={item}
                        currentFolder={props.currentFolder}
                        activePath={activePath}
                        showBranch={ true }
                        folderSelected={(f: Folder_t) => folderSelected(f)}
                        toggleDisplay={(f: Folder_t) => props.toggleDisplay(f)}
                        turnOnDisplay={(f: Folder_t) => props.turnOnDisplay(f)}/>
                ))}
            </div>
            <div hidden={thisFolder.children.length > 0 || (thisFolder.isOpen !== undefined && thisFolder.isOpen === false)} className="workout-plan-list-folder-sub-folder-container workout-plan-list-folder-sub-folder-container-empty">
                <p>No content in this folder</p>
            </div>
        </div>
	)
}

export default WorkoutPlanListFolder;