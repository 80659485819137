import React, { useState, useEffect } from 'react';
import './GoalLogCard.css';

import GoalLogRow from './GoalLogRow';

import '@material-ui/core';
import ClearAll from '@material-ui/icons/ClearAll';

function GoalLogCard(props: any) {

    const [goalLogs, setGoalLogs] = useState<any[]>([]);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.goalData && props.goalData.logs) setGoalLogs(props.goalData.logs);
    }, [props.goalData])

    const initialize = () => {
        
    }

    function morePressed() {
        props.openPerformancePressed()
    }

	return (
		<div className={ "goal-log-card" }>
			<div className={ "goal-log-card-header" }>
                <div className={ "goal-log-card-header-icon-container" }>
                    <ClearAll className={ "goal-log-card-header-icon" }/>
                </div>
                <h4>Goal Logs</h4>
            </div>
            <div className={ "goal-log-card-body-container" }>
                <div className={ "goal-log-card-body-header" }>
                    <div className={ "goal-log-card-body-col-header-col goal-log-card-body-col goal-log-card-body-col-name" }>
                        <p>Goal Date</p>
                    </div>
                    <div className={ "goal-log-card-body-col-header-col goal-log-card-body-col goal-log-card-body-col-measurement" }>
                        <p>Measurement</p>
                    </div>
                    <div className={ "goal-log-card-body-col-header-col goal-log-card-body-col goal-log-card-body-col-datapoints" }>
                        <p>Completion</p>
                    </div>
                    <div className={ "goal-log-card-body-col-header-col goal-log-card-body-col goal-log-card-body-col-score" }>
                        <p>Mood</p>
                    </div>
                </div>
                <div className={ "goal-log-card-body-content" }>
                    {
                        goalLogs.map((item: any, index: number) => (
                            <GoalLogRow 
                            	key={`goal-log-row-${index}`} 
                            	log={ item }
                            	firstLog={ props.goalData.logs ? props.goalData.logs[0] : undefined }
                            	goal={ props.goalData.goal }/>
                        ))
                    }
                </div>
            </div>
            <div className={ "goal-log-card-selector-container" }>
                <div className={ "goal-log-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default GoalLogCard;