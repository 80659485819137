import React, { useState, useEffect } from 'react';
import './DashboardSummaryChart.css';
import './ChartCommon.css';

import SetStats_t from '../../Interfaces/SetStats_t';

import {Line, Bar} from 'react-chartjs-2';


function DashboardSummaryChart(props: any) {

    let initDataSets = {
        labels: [1,4,7,10,13,16],
        datasets: [
            {
                label: 'Martin',
                backgroundColor: 'rgba(73,89,193,0.0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1.5,
                type: 'line',
                data: [50,45,51,54,78,92]
            },
            // {
            //     label: 'Yong',
            //     backgroundColor: 'rgba(89,193,73,0.0)',
            //     hoverBackgroundColor: `rgba(89,193,73,0.9)`,
            //     borderColor: 'rgba(89,193,73,1)',
            //     hoverBorderColor: `rgba(89,193,73,1)`,
            //     borderWidth: 3,
            //     type: 'line',
            //     data: [54,62,60,71,74,80]
            // },
            {
                label: 'April',
                backgroundColor: 'rgba(193,73,89,0.0)',
                hoverBackgroundColor: `rgba(193,73,89,0.9)`,
                borderColor: 'rgba(193,73,89,1)',
                hoverBorderColor: `rgba(193,73,89,1)`,
                borderWidth: 1.5,
                type: 'line',
                data: [62,64,50,43,57,60]
            },
            {
                label: 'Connor',
                backgroundColor: 'rgba(140,40,193,0.0)',
                hoverBackgroundColor: `rgba(140,40,193,0.9)`,
                borderColor: 'rgba(140,40,193,1)',
                hoverBorderColor: `rgba(140,40,193,1)`,
                borderWidth: 1.5,
                type: 'line',
                data: [10,20,30,5,15,30]
            },
        ]
    }

    const [dataSets, setDataSets] = useState(initDataSets);

    var chartOptions: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: true,
                    color: '#272937',
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#6C6C6C',
                    fontSize: 10,
                    beginAtZero: false,
                    stepSize: 20,
                    callback: function(value: any, index: any, values: any) {
                        return value + `%`;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                // ticks: {
                //     fontColor: '#6C6C6C',
                //     fontSize: 8,
                //     beginAtZero: true,
                //     stepSize: 1
                // }
            }]
        },
        legend:{
            display:false,
            position: 'bottom',
            fontColor: '#5F6C76'
        }
      }

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="chart-container">
			<Bar
                data={dataSets}
                options={chartOptions}
            />
		</div>
	)
}

export default DashboardSummaryChart;