import React, { useState } from 'react';
import './Name.css';
import TextInput from '../../components/Base/TextInput';
import { IonReactRouter } from '@ionic/react-router';


function City(props: any) {

  const [isValid, setIsValid] = useState(false);
  const [trainsAtGym, setTrainsAtGym] = useState(true);

  function textInputChanged(text: string) {
    let valid_temp = text.split("").length >= 3;
    setIsValid(valid_temp);
    props.validation(valid_temp);

    if (valid_temp === true) {
      props.resultUpdated(text);
    }
  }

  return (
    <div className="header-container">
      <h1>Great!</h1>
      <h1>What city is {props.gym} based out of?</h1>
      <p>We'll use your city to help you find athletes in need of coaching in your area.</p>
      <div>
        <div hidden={!trainsAtGym}>
          <TextInput placeholder="My city" input="" inputChanged={(e: string) => textInputChanged(e)}/>
        </div>
      </div>
   </div>
  )
}

export default City;