import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { IonReactRouter } from '@ionic/react-router';

import axios from 'axios';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import TextInput from '../../components/Base/TextInput';
import StandardButton from '../../components/Base/StandardButton';
import Name from './Name';
import Email from './Email';
import Password from './Password';
import Company from './Company';
import City from './City';
import Experience from './Experience';

import './RegisterPage.css';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function RegisterPage(props: any) {

  let pageName: {page: string} = useParams();

  let history = useHistory();
  var userHasBeenDeleted = false;

  const [willRegister, setWillRegister] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageValidated, setPageValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorDesc, setErrorDesc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fName, setFName] = useState('');
  const [setupPosition, setSetupPosition] = useState(0);

  const pageNames = ['name','email','password','company','city','experience']

  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    full_name: '',
    email:'',
    password: '',
    company_name: '',
    works_for_org: false,
    city: '',
    'years_experience': 0
  });



  firebase.auth().onAuthStateChanged(function(user: any) {


    
    // console.log(`REGISTER PAGE HERE: ${willRegister}`);
    if (willRegister === false) { return; }
    if (user) {
      // User is signed in.
      var displayName = user.displayName;
      var email = user.email;

      user.updateProfile({
        displayName: userInfo.full_name
      }).then(function() {
        // Update successful.
        // console.log(`REGISTER PAGE: User ${user.uid} Signed in with email ${email}.`)
        history.push('/dashboard');
      }).catch(function(error: any) {
        // An error happened.
        // console.log('ERROR occured');
      });

      
    } else {
      // User is signed out.

      // console.log('REGISTER PAGE: User has not signed in, or recently signed out.');
    }
  });



  function goToLoginButtonPressed() {
    history.push('/login');
  }

  function configureUser() {
    firebase.auth().createUserWithEmailAndPassword(userInfo.email, userInfo.password)
    .then(function(user) {
      let userRef = user.user;
      if (!userRef) { 
        // console.log("Error: User object was null");
        return; 
      }
      let profileObj = {
        email: userInfo.email,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        full_name: userInfo.full_name,
        company_name: userInfo.company_name,
        works_for_org: userInfo.works_for_org,
        city: userInfo.city,
        joined_timestamp: Date.now(),
        years_experience: userInfo.years_experience
      }
      var db = firebase.database();
      db.ref(`personal_trainer_profile/${userRef.uid}`).set(profileObj);
      setSetupPosition(2);

      Mixpanel.identify(userRef.uid);
      Mixpanel.people.set_once({
        'Register Date': new Date(),
        $name: profileObj.full_name.toUpperCase(),
        'Company': profileObj.company_name.toUpperCase(),
        'Location': profileObj.city.toUpperCase(),
        $email: profileObj.email.toUpperCase()
      });
      Mixpanel.people.set({'Most recent login': new Date()});
      subscribeToEmails();

    })
    .catch(function(error) {
      // Handle errors
      var errorCode = error.code;
      var errorMessage = error.message;

      setErrorDesc(errorMessage);
      setShowError(true);
      setIsLoading(false);
    });
  }

  function subscribeToEmails() {
    axios.get(`https://eigenfitness.com/api/save-to-mailchimp-list.php?firstName=${userInfo.first_name}&lastName=${userInfo.last_name}&email=${userInfo.email}&phoneNumber=0000000000&userTag=TRAINER`,
        { headers: {'Content-Type': 'application/json'} })
    .then(response => {
        // console.log(response);
        // console.log(response.data);
        let data = response.data;
    })
    .catch(e => {
        // console.log(e);
    })

    axios.get(`https://eigenfitness.com/email/welcome_trainer.php?trainer_name=${userInfo.first_name}&email=${userInfo.email}`,
        { headers: {'Content-Type': 'application/json'} })
    .then(response => {
        // console.log(response);
        // console.log(response.data);
        let data = response.data;
    })
    .catch(e => {
        // console.log(e);
    })


  }

  function checkEmailValidity() {
    /**
     *  Verifies that the provided email address is acceptable by Firebase's Auth API.
     *  Creates a dummy account with the given email, using password "Abc123". If account
     *  is accepted, it's then deleted. Otherwise, the Firebase error message is displayed
     *  below the email input.
     */

    firebase.auth().createUserWithEmailAndPassword(userInfo.email, "Abc123")
    .then(function(user) {
      if (!user.user) { return; }
      user.user.delete()
      .then(function() {
        moveToNextPage();
      })
      .catch(function(error2: any) {
        // console.log(error2);
      });
    })
    .catch(function(error) {
      // Handle errors
      var errorCode = error.code;
      var errorMessage = error.message;

      setErrorDesc(errorMessage);
      setShowError(true);
      setIsLoading(false);
    });
  }

  function checkPasswordValidity() {
    /**
     *  Verifies that the provided email address is acceptable by Firebase's Auth API.
     *  Creates a dummy account with the given email, using given password. If account
     *  is accepted, it's then deleted. Otherwise, the Firebase error message is displayed
     *  below the password input.
     */
    // console.log(`Checking with email: ${userInfo.email}, pw: ${userInfo.password}`)
    firebase.auth().createUserWithEmailAndPassword(userInfo.email, userInfo.password)
    .then(function(user) {
      if (!user.user) { return; }
      user.user.delete()
      .then(function() {
        moveToNextPage();
      })
      .catch(function(error2: any) {
        // console.log(error2);
      });
    })
    .catch(function(error) {
      // Handle errors
      var errorCode = error.code;
      var errorMessage = error.message;

      setErrorDesc(errorMessage);
      setShowError(true);
      setIsLoading(false);
    });
  }

  function beingButtonPressed() {
    setWillRegister(true);
    firebase.auth().signInWithEmailAndPassword(userInfo.email, userInfo.password).catch(function(error) {
       // console.log(error)
      //history.push('/dashboard');
    });
    
  }

  function nextButtonPressed() {

    if (pageIndex === 1) {
      // Check if email is acceptable by Firebase Auth
      setIsLoading(true);
      checkEmailValidity();
      return;
    } else if (pageIndex === 2) {
      // Check if password is acceptable by Firebase Auth
      setIsLoading(true);
      checkPasswordValidity();
      return;
    } else if (pageIndex === 5) {
      setSetupPosition(1);
      configureUser();
      return;
    }

    if (!pageValidated) {
      setShowError(true);
    } else {
      moveToNextPage();
    }
  }

  function moveToNextPage() {
    // console.log(`MOVING TO NEXT PAGE`);

    let a = pageIndex + 1;
    setPageIndex(a);
    history.push(`/register/${pageNames[a]}`);
    validationStateChanged(false);
    setShowError(false);
    setIsLoading(false);
    // console.log(`This page Index: ${pageIndex}, ${a}`);
  }

  function validationStateChanged(toState: boolean) {
    if (toState === true) {
      setShowError(false);
    } else if (pageValidated === true && toState === false) {
      setShowError(true);
    }
    setPageValidated(toState);

  }

  function pageResultUpdated(toValue: any) {
    let userInfo_temp = userInfo;

    switch (pageIndex) {
      case 0:
        let nameString = String(toValue);
        let namesArray = nameString.split(" ");
        userInfo_temp.first_name = (namesArray[0]).trim();
        userInfo_temp.last_name = (namesArray[namesArray.length - 1]).trim();
        userInfo_temp.full_name = (nameString).trim();
        setUserInfo(userInfo_temp);
        setFName(userInfo.first_name);
        break;
      case 1:
        userInfo_temp.email = String(toValue);
        setUserInfo(userInfo_temp);

        break;
      case 2:
        userInfo_temp.password = String(toValue);
        setUserInfo(userInfo_temp);
        break;
      case 3:
        let usingGym = String(toValue) != ""
        userInfo_temp.company_name = usingGym ? String(toValue) : "Home";
        userInfo_temp.works_for_org = usingGym
        setUserInfo(userInfo_temp);
        break;
      case 4:
        userInfo_temp.city = String(toValue);
        setUserInfo(userInfo_temp);
        break;
      case 5:
        userInfo_temp.years_experience = Number(toValue);
        setUserInfo(userInfo_temp);
        break;
      default:
        break;
    }
  }

  let { path, url } = useRouteMatch();

  return (

    <div className="register-container">
      <div className="register-background-container">
        <img src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/sign_in_bg.jpg`} className="register-background-image"/>
      </div>
      <div className="login-logo-container">
        <p>eigen<span>fit</span></p>
      </div>
      
      <div hidden={setupPosition != 0} className="register-info-block">
        <div className="register-header-container">
          <h1>Register as Coaching Partner</h1>
          <div className="register-header-close-button-container">
            <div onClick={() => goToLoginButtonPressed()} className="register-header-close-button">
              <Close className="register-header-close-button-close-icon"/>
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path={`/register/name`}>
            <Name resultUpdated={(e: string) => pageResultUpdated(e)} validation={(e: boolean) => validationStateChanged(e)} showError={showError}/>
          </Route>
          <Route exact path={`/register/email`}>
            <Email firstName={fName} resultUpdated={(e: string) => pageResultUpdated(e)} validation={(e: boolean) => validationStateChanged(e)} showError={showError} errorDesc={errorDesc}/>
          </Route>
          <Route exact path={`/register/password`}>
            <Password resultUpdated={(e: string) => pageResultUpdated(e)} validation={(e: boolean) => validationStateChanged(e)} showError={showError} errorDesc={errorDesc}/>
          </Route>
          <Route exact path={`/register/company`}>
            <Company resultUpdated={(e: string) => pageResultUpdated(e)} validation={(e: boolean) => validationStateChanged(e)}/>
          </Route>
          <Route exact path={`/register/city`}>
            <City gym={userInfo.works_for_org ? userInfo.company_name : "your home gym"} resultUpdated={(e: string) => pageResultUpdated(e)} validation={(e: boolean) => validationStateChanged(e)}/>
          </Route>
          <Route exact path={`/register/experience`}>
            <Experience firstName={fName} resultUpdated={(e: string) => pageResultUpdated(e)} validation={(e: boolean) => validationStateChanged(e)}/>
          </Route>
        </Switch>
        <div className="button-container">
          <div hidden={isLoading}>
            <StandardButton 
                  buttonText="Next" 
                  isValid={pageValidated}
                  callBack={() => nextButtonPressed()} />
          </div> 
          <div hidden={!isLoading} className="loading-container">
            <p>Loading...</p>
          </div>        
        </div>
     </div>
     <div hidden={setupPosition != 1} className="register-info-block">
       <div className="configuring-account-container">
         <h1>Setting up your Eigen Trainer account...</h1>
         <p>Hold tight, this should only take a few seconds!</p>
       </div>
     </div>
     <div hidden={setupPosition != 2} className="register-info-block">
       <div className="configuring-account-container">
         <h1>Welcome to Eigen Fitness!</h1>
         <p>We're excited to welcome you to the Eigen team as a Coaching Partner, {userInfo.first_name}!. Press "Get Started" below to head over to your account and connect with your clients.</p>
       </div>
       <div className="button-container">
         <StandardButton 
                  buttonText="Get Started" 
                  callBack={() => beingButtonPressed()} />
       </div>
     </div>
     <div className="register-copyright-container">
       <p>Built in Vancouver, BC Canada. Copyright ©2021 Eigen Fitness, all rights reserved</p>
     </div>
   </div>
  )
}

/*
<p onClick={() => goToLoginButtonPressed()}>&larr; Back to LogIn</p>
*/

export default RegisterPage;
