import React, { useState, useEffect } from 'react';
import './NewWorkoutModal.css';
import './ConfirmModal.css';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

// modal data:
//	{ title: "", text: "", isRed: boolean, callback: any, ctaText: "" }
//
//
//


function ConfirmModal(props: any) {


	function ctaPressed() {
		if (props.information.callback !== undefined) {
			if (props.information.callbackParam !== undefined) {
				props.information.callback(props.information.callbackParam);
			} else {
				props.information.callback();
			}
			
			props.hideModalPressed();
		}
	}	

	function closePressed() {
		props.hideModalPressed();
	}

	return (
		<div className="modal-background confirm-modal-background">
			<div className="confirm-modal-container">
				<div onClick={() => closePressed()} className="confirm-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
				<div className="confirm-modal-top">
					<h3>{props.information.title !== undefined ? props.information.title : "Confirm action?"}</h3>
					<p>{props.information.text !== undefined ? props.information.text : "Are you sure that you want to confirm action?"}</p>
				</div>
				
				<div className="confirm-modal-bottom">
					<div onClick={() => closePressed()} className="confirm-modal-close-button">Cancel</div>
					<div onClick={() => ctaPressed()} className={props.information.isRed ? "confirm-modal-cta-button-red" : "confirm-modal-cta-button"}>{props.information.ctaText !== undefined ? props.information.ctaText : "Confirm"}</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmModal;

