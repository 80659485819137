import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";

import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import { CSSTransition } from 'react-transition-group';
import PageHeader_t from '../../Interfaces/PageHeader_t';
import './PageHeader.css'

import '@material-ui/core';
import Add from '@material-ui/icons/Add';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Close from '@material-ui/icons/Close';
import MenuRounded from '@material-ui/icons/MenuRounded';

import FitnessCenter from '@material-ui/icons/FitnessCenter';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import PersonOutline from '@material-ui/icons/PersonOutline';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ExitToApp from '@material-ui/icons/ExitToApp';
import {AppContext} from "../../AppContext";

const adminIDs = ["CLCA6mbza8TNpJcdaWAnM2Ea5jS2", "nU93EH0guoMYKqg2qc4Wiooe1A73"]

function PageHeader(props: any) {

	let history = useHistory();

	const [selectedPageIndex, setSelectedPageIndex] = useState(0);
	const [pageOptions, setPageOptions] = useState<PageHeader_t[]>([]);
	const [initStateSet, setInitStateSet] = useState(false);
	const [showAddMenu, setShowAddMenu] = useState(false);
	const [showProfileMenu, setShowProfileMenu] = useState(false);

	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	const { unreadMessagesCount } = useContext(AppContext);

	if (!initStateSet) {
		setInitStateSet(true);
		setInitState();
	}

	window.addEventListener('click', function(e){
		if (document === null || document.getElementById('clickbox') === null || document.getElementById('clickboxButton') === null || e.target === null) {

		} else {
			// Check for click outside "Create" menu
		    if (document.getElementById('clickbox')!.contains(e.target as Node)){
		    // Clicked in box
		    } else {
		    	if (!(document.getElementById('clickboxButton')!.contains(e.target as Node))) {
		    		if (showAddMenu) {
			    		setShowAddMenu(false);
			    	}
		    	}
		    }
		}


	    if (document === null || document.getElementById('clickbox-profile') === null || document.getElementById('clickboxButton-profile') === null || e.target === null) {

	    } else {
	    	// Check for click outside "Profile" menu
		    if (document.getElementById('clickbox-profile')!.contains(e.target as Node)){
		    // Clicked in box
		    } else {
		    	if (!(document.getElementById('clickboxButton-profile')!.contains(e.target as Node))) {
		    		if (showProfileMenu) {
			    		setShowProfileMenu(false);
			    	}
		    	}
		    }
	    }
	});

	useEffect(() => {
		if (props.selectedPageIndex !== undefined) {
			setSelectedPageIndex(props.selectedPageIndex);
		}
	}, [props.selectedPageIndex]);

	useEffect(() => {
		if (props.pageOptions !== undefined) {
			setPageOptions(props.pageOptions);
		}
	}, [props.pageOptions]);

	function setInitState() {
		setPageOptions(props.pageOptions);
		setSelectedPageIndex(0);

		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;
		if (adminIDs.includes(trainerUID)) {
			setIsAdmin(true);
		}
	}

	function pageSelected(pageName: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === pageName) {
				setSelectedPageIndex(i);
			}
		}

		props.pageSelected(pageName);
	}

	function backButtonPressed() {
		props.backButtonPressed();
	}

	function getOptionClass(atIndex: number) {
		//item.name === (props.selectedPage !== undefined ? pageOptions[0] : selectedPage) ? "page-header-selected-subpage" : "page-header-unselected-subpage"
		var className = 'page-header-unselected-subpage';
		if (props.selectedPage !== undefined) {
			if (atIndex === props.selectedPage) {
				className = "page-header-selected-subpage";
			}
		} else if (atIndex === selectedPageIndex) {
			className = "page-header-selected-subpage";
		}
		return className;
	}

	function toggleShowMenuState() {
		if (showAddMenu) {
			// Turning menu off
			Mixpanel.track("Add button closed");
		} else {
			// Opening menu
			Mixpanel.track("Add button opened");
		}
		setShowAddMenu(!showAddMenu);
	}

	function toggleShowProfileMenu() {
		if (showProfileMenu) {
			// Turning menu off
			Mixpanel.track("Profile menu closed");
		} else {
			// Opening menu
			Mixpanel.track("Profile menu opened");
		}
		setShowProfileMenu(!showProfileMenu);
	}

	function addNewSelected(withIndex: number) {
		setShowAddMenu(false);
		switch (withIndex) {
			case 0:
				Mixpanel.track("Add new button pressed", {"add_type":"Workout"});
				if (props.addNewWorkout !== undefined) {
					props.addNewWorkout();
				}
				break;
			case 1:
				Mixpanel.track("Add new button pressed", {"add_type":"Workout Plan"});
				if (props.addNewWorkoutPlan !== undefined) {
					props.addNewWorkoutPlan();
				}
				break;
			case 2:
				Mixpanel.track("Add new button pressed", {"add_type":"Client"});
				if (props.addNewClient !== undefined) {
					props.addNewClient();
				}
				break;
			case 3:
				Mixpanel.track("Add new button pressed", {"add_type":"Exercise"});
				if (props.addNewExercise !== undefined) {
					props.addNewExercise();
				}
				break;
			default:
				break;
		}
	}

	function logoutPressed() {
		firebase.auth().signOut().then(function() {
            // Sign-out successful.
            // console.log('User successfully logged out');
            history.push('/');
        }).catch(function(error) {
            // An error happened.
            // console.log(`Logout error occured: ${error}`)
        });
	}

	function closePressed() {
		if (props.closePressed !== undefined) {
			props.closePressed();
		}
	}

	function openMLTrain() {
		if (props.openMLTrainCallback) {
			props.openMLTrainCallback(true)
		}
		setShowMobileMenu(false);
	}

	function pageSelectedFromMenu(pageName: string) {
		setShowMobileMenu(false);

		switch (pageName) {
			case "Dashboard":
				history.push('/dashboard/home')
				if (props.openMLTrainCallback) props.openMLTrainCallback(false);
				break;
			case "Messages":
				history.push('/dashboard/messages')
				break;
			case "DynamicTrain":
				if (props.workoutButtonPressed !== undefined) props.workoutButtonPressed()
				break;
			default:
				break;
		}
	}

	return (
		<div className="page-header-container">
			<div className="page-header-upper-content-container">
				<div hidden={props.showClose === undefined || props.showClose === false} className="page-header-close-button-container-outter">
					<div className="page-header-close-button-container-inner">
						<div onClick={() => closePressed()} className="page-header-close-button">
							<Close className="page-header-close-button-icon"/>
						</div>
					</div>
				</div>
				<div className="page-header-upper-content-container-text">
					<p hidden={!props.showBack} className="page-header-back-text" onClick={() => backButtonPressed()}>&larr; {props.backMessage !== undefined ? props.backMessage : 'Go back'}</p>
					<p hidden={props.pageSubtitle === undefined || props.pageSubtitle === ""} className="page-header-back-text">{props.pageSubtitle}</p>
					<h1>{props.pageName}</h1>
					<div className="age-header-upper-content-container-text-arrow">
						<ArrowForwardIos className="age-header-upper-content-container-text-arrow-icon"/>
					</div>
					<div hidden={pageOptions.length === 0} className={props.isPartialMenu !== undefined && props.isPartialMenu === true ? "page-header-subpage-container page-header-subpage-container-partial" : "page-header-subpage-container"}>
						{pageOptions.map((item: PageHeader_t, index: number) => (
							<div
								key={index}
								onClick={() => pageSelected(item.name)}
								className={getOptionClass(index)}>
								{item.name}
								<div hidden={!item.alert} className="page-header-alert-circle"/>
								<div hidden={selectedPageIndex !== index} className="page-header-subpage-selected-bar"/>
							</div>
						))}
					</div>
				</div>
				<div hidden={(props.showButtons !== undefined && props.showButtons === false) || (props.isIntraWorkout !== undefined && props.isIntraWorkout === true)} className="page-header-upper-content-container-buttons">
					<div id="clickboxButton-messages" onClick={() => {history.push(`/dashboard/messages`)}} className="page-header-button page-header-button-dark">
						<div className="alert-unread-messages">
							<p>{unreadMessagesCount}</p>
						</div>
						<p>Messages</p>
					</div>
					<div id="clickboxButton" onClick={() => toggleShowMenuState()} className="page-header-button page-header-button-dark">
						<Add className="page-header-upper-content-container-buttons-icon"/>
						<p>Create</p>
					</div>
					<div onClick={() => props.workoutButtonPressed !== undefined ? props.workoutButtonPressed() : null} className="page-header-button page-header-button-blue">
						<p>DynamicTrain</p>
					</div>
					<div id="clickboxButton-profile" onClick={() => toggleShowProfileMenu()} className="page-header-upper-content-container-account-icon-container">
						<div className="page-header-upper-content-container-account-icon">

						</div>
						<div className="page-header-upper-content-container-account-arrow-icon-container">
							<KeyboardArrowDown className="page-header-upper-content-container-account-arrow-icon"/>
						</div>
					</div>
				</div>
				<div hidden={(props.showButtons !== undefined && props.showButtons === false) || props.isIntraWorkout === undefined || props.isIntraWorkout === false} className="page-header-upper-content-container-buttons">
					<div onClick={() => props.endWorkoutPressed !== undefined ? props.endWorkoutPressed() : null} className="page-header-button page-header-button-red">
						<p>End Workout</p>
					</div>
					<div onClick={() => props.addNotePressed !== undefined ? props.addNotePressed() : null} className="page-header-button page-header-button-dark">
						<p>Add Note</p>
						<Add className="page-header-upper-content-container-buttons-icon"/>
					</div>
					<div onClick={() => props.recordSetPressed !== undefined ? props.recordSetPressed() : null} className="page-header-button page-header-button-blue">
						<p>RECORD NEXT SET</p>
						<PlayArrow className="page-header-upper-content-container-buttons-icon"/>
					</div>
				</div>
				<CSSTransition in={showAddMenu} timeout={200} classNames="menu" unmountOnExit appear>
					<div id="clickbox" className="page-header-add-menu-container">
							<div className="page-header-add-menu-content">
								<div className="page-header-add-menu-triangle"/>
								<div className="page-header-add-menu-header">
									<h4>Create new...</h4>
								</div>
								<div onClick={() => addNewSelected(2)} className="page-header-add-menu-row-container page-header-add-menu-row-divide">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-client">
										<PersonOutline className="page-header-add-menu-row-icon page-header-add-menu-row-icon-client"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Athlete</h4>
										<p>Add a your practice</p>
									</div>
									<div className="page-header-add-menu-row-hotkey-container">
										<div className="page-header-add-menu-row-hotkey">
											<p>ctrl</p>
										</div>
										<div className="page-header-add-menu-row-hotkey">
											<p>a</p>
										</div>
									</div>
								</div>
								<div onClick={() => addNewSelected(1)} className="page-header-add-menu-row-container">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-plan">
										<DynamicFeed className="page-header-add-menu-row-icon page-header-add-menu-row-icon-plan"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Phase</h4>
										<p>Compose a set of workouts</p>
									</div>
									<div className="page-header-add-menu-row-hotkey-container">
										<div className="page-header-add-menu-row-hotkey">
											<p>ctrl</p>
										</div>
										<div className="page-header-add-menu-row-hotkey">
											<p>p</p>
										</div>
									</div>
								</div>
								<div onClick={() => addNewSelected(0)} className="page-header-add-menu-row-container ">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-workout">
										<FitnessCenter className="page-header-add-menu-row-icon page-header-add-menu-row-icon-workout"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Workout</h4>
										<p>Compose a set of exercises</p>
									</div>
									<div className="page-header-add-menu-row-hotkey-container">
										<div className="page-header-add-menu-row-hotkey">
											<p>ctrl</p>
										</div>
										<div className="page-header-add-menu-row-hotkey">
											<p>w</p>
										</div>
									</div>
								</div>
								<div onClick={() => addNewSelected(3)} className="page-header-add-menu-row-container">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-exercise">
										<DirectionsRun className="page-header-add-menu-row-icon page-header-add-menu-row-icon-exercise"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Exercise</h4>
										<p>Build your custom library</p>
									</div>
									<div className="page-header-add-menu-row-hotkey-container">
										<div className="page-header-add-menu-row-hotkey">
											<p>ctrl</p>
										</div>
										<div className="page-header-add-menu-row-hotkey">
											<p>e</p>
										</div>
									</div>
								</div>
							</div>
						</div>
				</CSSTransition>
				<CSSTransition in={showProfileMenu} timeout={200} classNames="menu" unmountOnExit appear>
					<div id="clickbox-profile" className="page-header-add-menu-container page-header-profile-menu-container">
							<div className="page-header-add-menu-content">
								<div className="page-header-add-menu-triangle"/>
								<div className="page-header-add-menu-header">
									<h4>Profile</h4>
								</div>
								<div onClick={() => logoutPressed()} className="page-header-add-menu-row-container page-header-add-menu-row-divide">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-logout">
										<ExitToApp className="page-header-add-menu-row-icon-show page-header-add-menu-row-icon-logout"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Log Out</h4>
									</div>
								</div>
							</div>
						</div>
				</CSSTransition>
			</div>
			<div className={ "page-header-mobile-container" }>
				<div className={ "page-header-mobile-brand-container" }>
					<h4>Eigen</h4>
				</div>
				<div className={ "page-header-mobile-menu-button-container" }>
					<div className={ "page-header-mobile-menu-button" } onClick={ () => setShowMobileMenu(!showMobileMenu) }>
						{
							showMobileMenu ?
							<Close className={ "page-header-mobile-menu-button-icon" }/>
							:
							<MenuRounded className={ "page-header-mobile-menu-button-icon" }/>
						}
					</div>
				</div>
				<CSSTransition in={showMobileMenu} timeout={200} classNames="menu" unmountOnExit appear>
					<div className="page-header-mobile-menu-menu">
						<div className="page-header-mobile-menu-menu-inner">
							<div className="page-header-mobile-menu-menu-header">
								<div className={ "page-header-mobile-menu-button" } onClick={ () => setShowMobileMenu(!showMobileMenu) }>
									<Close className={ "page-header-mobile-menu-button-icon" }/>
								</div>
							</div>
							<div className="page-header-mobile-menu-menu-content">
								<div className="page-header-mobile-menu-menu-content-row" onClick={ () => pageSelectedFromMenu("Dashboard") }>
									<h4>Dashboard</h4>
								</div>
								{
									isAdmin && props.openMLTrainCallback !== undefined &&
									<div className="page-header-mobile-menu-menu-content-row" onClick={ () => openMLTrain() }>
										<h4>ML Train</h4>
									</div>
								}
								<div className="page-header-mobile-menu-menu-content-row" onClick={ () => pageSelectedFromMenu("DynamicTrain") }>
									<h4>DynamicTrain</h4>
								</div>
								<div className="page-header-mobile-menu-menu-content-row" onClick={ () => pageSelectedFromMenu("Messages") }>
									<h4>Messages</h4>
								</div>
							</div>
						</div>
					</div>
				</CSSTransition>
			</div>
		</div>
	)
}

export default PageHeader;

/*
<div className="page-header-container">
			<div className="page-header-upper-content-container">
				<div className="page-header-upper-content-container-text">
					<p hidden={!props.showBack} className="page-header-back-text" onClick={() => backButtonPressed()}>&larr; {props.backMessage !== undefined ? props.backMessage : 'Go back'}</p>
					<p hidden={props.pageSubtitle === undefined || props.pageSubtitle === ""} className="page-header-back-text">{props.pageSubtitle}</p>
					<h1>{props.pageName}</h1>
				</div>
				<div hidden={(props.showButtons !== undefined && props.showButtons === false) || (props.isIntraWorkout !== undefined && props.isIntraWorkout === true)} className="page-header-upper-content-container-buttons">
					<div id="clickboxButton" onClick={() => toggleShowMenuState()} className="page-header-button page-header-button-dark">
						<p>Add new</p>
						<Add className="page-header-upper-content-container-buttons-icon"/>
					</div>
					<div onClick={() => props.workoutButtonPressed !== undefined ? props.workoutButtonPressed() : null} className="page-header-button page-header-button-blue">
						<p>Workout</p>
						<PlayArrow className="page-header-upper-content-container-buttons-icon"/>
					</div>
				</div>
				<div hidden={(props.showButtons !== undefined && props.showButtons === false) || props.isIntraWorkout === undefined || props.isIntraWorkout === false} className="page-header-upper-content-container-buttons">
					<div onClick={() => props.endWorkoutPressed !== undefined ? props.endWorkoutPressed() : null} className="page-header-button page-header-button-red">
						<p>End Workout</p>
					</div>
					<div onClick={() => props.addNotePressed !== undefined ? props.addNotePressed() : null} className="page-header-button page-header-button-dark">
						<p>Add Note</p>
						<Add className="page-header-upper-content-container-buttons-icon"/>
					</div>
					<div onClick={() => props.recordSetPressed !== undefined ? props.recordSetPressed() : null} className="page-header-button page-header-button-blue">
						<p>RECORD NEXT SET</p>
						<PlayArrow className="page-header-upper-content-container-buttons-icon"/>
					</div>
				</div>
				<CSSTransition in={showAddMenu} timeout={200} classNames="menu" unmountOnExit appear>
					<div id="clickbox" className="page-header-add-menu-container">
							<div className="page-header-add-menu-content">
								<div className="page-header-add-menu-triangle"/>
								<div className="page-header-add-menu-header">
									<h4>Add new</h4>
								</div>
								<div onClick={() => addNewSelected(2)} className="page-header-add-menu-row-container page-header-add-menu-row-divide">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-client">
										<PersonOutline className="page-header-add-menu-row-icon page-header-add-menu-row-icon-client"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Client</h4>
										<p>Add a your practice</p>
									</div>
								</div>
								<div onClick={() => addNewSelected(1)} className="page-header-add-menu-row-container page-header-add-menu-row-divide">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-plan">
										<DynamicFeed className="page-header-add-menu-row-icon page-header-add-menu-row-icon-plan"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Workout plan</h4>
										<p>Compose a set of workouts</p>
									</div>
								</div>
								<div onClick={() => addNewSelected(0)} className="page-header-add-menu-row-container page-header-add-menu-row-divide">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-workout">
										<FitnessCenter className="page-header-add-menu-row-icon page-header-add-menu-row-icon-workout"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Workout</h4>
										<p>Compose a set of exercises</p>
									</div>
								</div>
								<div onClick={() => addNewSelected(3)} className="page-header-add-menu-row-container">
									<div className="page-header-add-menu-row-icon-container page-header-add-menu-row-icon-container-exercise">
										<DirectionsRun className="page-header-add-menu-row-icon page-header-add-menu-row-icon-exercise"/>
									</div>
									<div className="page-header-add-menu-row-text-container">
										<h4>Exercise</h4>
										<p>Build your custom library</p>
									</div>
								</div>


							</div>
						</div>
				</CSSTransition>
			</div>
			<div hidden={pageOptions.length === 0} className={props.isPartialMenu !== undefined && props.isPartialMenu === true ? "page-header-subpage-container page-header-subpage-container-partial" : "page-header-subpage-container"}>
				{pageOptions.map((item: PageHeader_t, index: number) => (
					<div
						key={index}
						onClick={() => pageSelected(item.name)}
						className={getOptionClass(index)}>
						{item.name}
						<div hidden={item.alert === false} className="page-header-alert-circle"/>
						<div hidden={selectedPageIndex !== index} className="page-header-subpage-selected-bar"/>
					</div>
				))}
			</div>
		</div>
*/

