import React, { useState } from 'react';
import './MyCoachingProfile.css';

import Trainer_t from '../../../Interfaces/Trainer_t';
import Client_t from '../../../Interfaces/Client_t';
import PageHeader_t from '../../../Interfaces/PageHeader_t';

import PageHeader from '../../../components/Base/PageHeader';
import MyProfile from './CoachingProfilePages/MyProfile';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';

function MyCoachingProfile(props: any) {

    let pageOptions: PageHeader_t[] = [{ name:"My Profile", alert:false }, { name:"Programs for Sale", alert:false }];

    const [pageNumber, setPageNumber] = useState(0);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

	return (
		<div className="trainers-page">
            <div className="home-header">
                <PageHeader 
                    pageName="Coaching Profile" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
                    addNewWorkoutPlan={() => props.createNewWorkoutPlan !== undefined ? props.createNewWorkoutPlan() : null}
                    addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
            <div className="my-coaching-profile-page-container">
                <div className="my-coaching-profile-page-container-inner">
                    <MyProfile/>
                </div>
            </div>
		</div>
	)
}

export default MyCoachingProfile;