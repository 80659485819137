import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import './FormDateInput.css';



function FormDateInput(props: any) {

    const [day, setDay] = useState(-1);
    const [month, setMonth] = useState(-1);
    const [year, setYear] = useState(-1);

    const [dayFocused, setDayFocused] = useState(false);
    const [monthFocused, setMonthFocused] = useState(false);
    const [yearFocused, setYearFocused] = useState(false);

    function changeDate(to: string, forType: string) {
        let toNumber = Number(to);
        switch (forType) {
            case 'day' :
                setDay(toNumber);
                break;
            case 'month' :
                setMonth(toNumber);
                break;
            case 'year' :
                setYear(toNumber);
                break;
            default:
                break;
        }

        let dateString = `${day}-${month}-${year}`;
        props.inputChanged(dateString);
    }

    function updateFocused(toFocus: boolean, forInput: string) {
        switch(forInput) {
            case 'day':
                setDayFocused(toFocus);
                break;
            case 'month':
                setMonthFocused(toFocus);
                break;
            case 'year':
                setYearFocused(toFocus);
                break;
            default:
                break;
        }
    }

    return (
        <div className="">
            <div className="form-date-header-container">
                <p className="form-date-input-label">{props.headerTitle}</p>
                <p hidden={!props.alert} className="form-date-input-alert-label">{props.alertText}</p>
            </div>
            <div className="form-date-container">
                <div className={props.alert? "form-width-3-alert" : `form-width-3 ${dayFocused === true ? 'form-width-3-focused' : ''}`}>
                    <p className="form-date-inner-text">Day</p>
                    <IonInput value={day < 0 ? null : day} 
                        placeholder="DD"
                        inputmode="numeric"
                        type="number"
                        onIonChange={e => changeDate(e.detail.value!, 'day')}
                        onFocus={(e) => updateFocused(true, 'day')}
                        onBlur={(e) => updateFocused(false, 'day')}/>
                </div>
                <div className={props.alert? "form-width-3-alert" : `form-width-3 ${monthFocused === true ? 'form-width-3-focused' : ''}`}>
                    <p className="form-date-inner-text">Mo</p>
                    <IonInput value={month < 0 ? null : month} 
                        placeholder="MO"
                        inputmode="numeric"
                        type="number"
                        onIonChange={e => changeDate(e.detail.value!, 'month')}
                        onFocus={(e) => updateFocused(true, 'month')}
                        onBlur={(e) => updateFocused(false, 'month')}/>
                </div>
                <div className={props.alert? "form-width-3-alert" : `form-width-3 ${yearFocused === true ? 'form-width-3-focused' : ''}`}>
                    <p className="form-date-inner-text">Year</p>
                    <IonInput value={year < 0 ? null : year} 
                        placeholder="YYYY"
                        inputmode="numeric"
                        type="number"
                        onIonChange={e => changeDate(e.detail.value!, 'year')}
                        onFocus={(e) => updateFocused(true, 'year')}
                        onBlur={(e) => updateFocused(false, 'year')}/>
                </div>
            </div>
        </div>
    )
}

export default FormDateInput;
