import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './Calendar.css';

import Workout_t from '../../Interfaces/Workout_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import Plan_t from '../../Interfaces/Plan_t';
import ScheduleDay_t from '../../Interfaces/ScheduleDay_t';
import WorkoutLogBrief_t from '../../Interfaces/WorkoutLogBrief_t';
import Client_t from '../../Interfaces/Client_t';
import CalendarDateCell_t from '../../Interfaces/CalendarDateCell_t';

import CalendarMonth from './CalendarMonth';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';


function Calendar(props: any) {

    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};
    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let wkDayStrings = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedYear, setSelectedYear] = useState(0);

    const [initSet, setInitSet] = useState(false);
    const [calType, setCalType] = useState<"month" | "week" | "day">("month");

    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);

    const [scheduledEvents, setScheduledEvents] = useState<ScheduleDay_t[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isCreatingProgram, setIsCreatingProgram] = useState(false);
    const [workoutsInPhase, setWorkoutsInPhase] = useState<CalendarDateCell_t[]>([]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
        // Set selected date params to today
        let todayDate = new Date();
        setSelectedTimestamp(todayDate.getTime());
        setSelectedDay(todayDate.getDate());
        setSelectedMonth(todayDate.getMonth());
        setSelectedYear(todayDate.getFullYear());
        //pullMonthlySchedule(todayDate.getMonth(), todayDate.getFullYear());
        if (props.client !== undefined) {
            setSelectedClient(props.client);
            if (props.client.id !== "") {
                pullClientScheduleMonth(props.client.id, selectedMonth, selectedYear);
            }
            
        } else {

        }
    }

    useEffect(() => {
        //// console.log("Calendar useEffect fired for props.client")
        if (props.client !== undefined) {
            setSelectedClient(props.client);
            setScheduledEvents(scheduledEvents.filter((item: ScheduleDay_t) => {return false;}));
            if (props.client.id !== "") {
                pullClientScheduleMonth(props.client.id, selectedMonth, selectedYear);
            } else {
                pullTrainerScheduleMonth(selectedMonth, selectedYear);
            }
        } else {

        }

    }, [props.client])

    useEffect(() => {
        if (props.isCreatingProgramUpdated !== undefined) {
            props.isCreatingProgramUpdated(isCreatingProgram);
        }
    }, [isCreatingProgram])

    function pullTrainerScheduleMonth(forMonth: number, forYear: number) {

        setIsLoading(true);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_schedule/${trainerUID}/schedule/${forYear}/${forMonth}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                parseTrainerSchedule(data, forMonth, forYear);
            } else {
                setIsLoading(false);
            }
        });
    }

    async function parseTrainerSchedule(scheduleObj: any[], forMonth: number, forYear: number) {
        // scheduleObj is array holding objects of the type {id: string;}
        // console.log(scheduleObj);
        var schedule_temp: ScheduleDay_t[] = [];
        let schedule_temp_keys: any[] = Object.keys(scheduleObj);
        for (var i = 0; i < schedule_temp_keys.length; i++) {
            let thisDayKey = schedule_temp_keys[i];
            let thisUserList: any[] = scheduleObj[thisDayKey];
            let thisUserList_keys: any[] = Object.keys(thisUserList);

            for (var j = 0; j < thisUserList_keys.length; j++) {
                let thisUserKey = thisUserList_keys[j];
                let thisObj: any = thisUserList[thisUserKey];
                // console.log("XYZ: ", thisUserKey, thisDayKey, thisObj.id, thisObj);

                if (thisObj !== undefined && thisObj.id !== undefined) {
                    let thisScheduleEvent = await getScheduleEventForClient(thisObj.id, thisDayKey, forMonth, forYear);
                    if (thisScheduleEvent !== undefined && thisScheduleEvent !== null) {
                        schedule_temp.push(thisScheduleEvent);
                    }
                }
            } 
        }

        setScheduledEvents(scheduledEvents.filter((item: ScheduleDay_t) => {return false;}));
        setScheduledEvents(schedule_temp);
        setIsLoading(false);
    }

    async function getScheduleEventForClient(clientID: string, forDay: number, forMonth: number, forYear: number) {
        return new Promise<ScheduleDay_t>((resolve, reject) => {
            let database = firebase.database();
            // console.log("getScheduleEventForClient: ", clientID, forDay);
            let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/schedule/${forYear}/${forMonth}/${forDay}`);
            ref.once('value', function(snapshot) {
                if (snapshot.exists() === true) {
                    let thisObj = snapshot.val();
                    let newScheduleObj: ScheduleDay_t = {
                        timestamp: thisObj.timestamp === undefined ? -1 : thisObj.timestamp,
                        day_number: forDay,
                        mo_number: forMonth,
                        year_number: forYear,
                        workout_id: thisObj.workout_id === undefined ? "" : thisObj.workout_id,
                        client_id: clientID,
                        path: thisObj.path === undefined ? "" : thisObj.path,
                        phase: thisObj.phase === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.phase,
                        cycle: thisObj.cycle === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.cycle,
                        scheduled: thisObj.scheduled
                    }
                    // console.log("getScheduleEventForClient | done: ", clientID, newScheduleObj);
                    resolve(newScheduleObj);
                } else {
                    reject();
                }
            });

        });
    }



    function pullClientScheduleMonth(forID: string, forMonth: number, forYear: number) {

        setIsLoading(true);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${forID}/schedule/${forYear}/${forMonth}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                // console.log("!!!! XYZ: got plan for CLient", forID, forMonth, forYear, snapshot.val());
                let data = snapshot.val();
                let dataKeys: string[] = Object.keys(data);

                var scheduleListTemp: ScheduleDay_t[] = [];
                for (var i = 0; i < dataKeys.length; i++) {
                    let thisKey = dataKeys[i];
                    let thisObj: any = data[thisKey];

                    let newScheduleObj: ScheduleDay_t = {
                        timestamp: thisObj.timestamp === undefined ? -1 : thisObj.timestamp,
                        day_number: Number(thisKey),
                        mo_number: selectedMonth,
                        year_number: selectedYear,
                        workout_id: thisObj.workout_id === undefined ? "" : thisObj.workout_id,
                        client_id: forID,
                        path: thisObj.path === undefined ? "" : thisObj.path,
                        phase: thisObj.phase === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.phase,
                        cycle: thisObj.cycle === undefined ? {id: "", name: "", index: 0, path: ""} : thisObj.cycle,
                        scheduled: thisObj.scheduled
                    }

                    scheduleListTemp.push(newScheduleObj);
                }

                setScheduledEvents(scheduledEvents.filter((item: ScheduleDay_t) => {return false;}));
                setScheduledEvents(scheduleListTemp);
                
            }
            setIsLoading(false);
        });
    }

    function addNewAppointmentPressed(timestamp: number) {
        if (props.addNewAppointmentPressed !== undefined) {
            props.addNewAppointmentPressed({
                timestamp: timestamp
            });
        }
    }

    function addNewAppointmentPressedForClient(timestamp: number) {
        if (props.addNewAppointmentPressed !== undefined) {
            props.addNewAppointmentPressed({
                timestamp: timestamp,
                client: selectedClient
            });
        }
    }

    function editAppointmentPressedForClient(timestamp: number, clientID: string) {
        if (props.addNewAppointmentPressed !== undefined) {
            props.addNewAppointmentPressed({
                timestamp: timestamp,
                client_id: clientID
            });
        }
    }

    function resetToToday() {
        let newTS = Date.now();
        let newDate = new Date(newTS);

        let newYear = newDate.getFullYear();
        let newMonth = newDate.getMonth();

        if (newMonth !== selectedMonth || newYear !== selectedYear) {
            //pullMonthlySchedule(newMonth, newYear);
        }

        setSelectedMonth(newMonth);
        setSelectedYear(newYear);

        if (selectedClient.id !== "") {
            pullClientScheduleMonth(selectedClient.id, newMonth, newYear);
        }

        setSelectedTimestamp(newTS);
    }

    function previousMonth() {

        let newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
        let newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;

        setSelectedMonth(newMonth);
        setSelectedYear(newYear);


        let todayTS = Date.now();
        let todayDate = new Date(todayTS);
        let todayDateMonth = todayDate.getMonth();
        let todayDateYear = todayDate.getFullYear();
        let todayDateDay = todayDate.getDate();

        let newDate = new Date(newYear, newMonth, todayDateYear === todayDateYear && todayDateMonth === newMonth ? todayDateDay : 1);
        setSelectedTimestamp(newDate.getTime());

        if (selectedClient.id !== "") {
            pullClientScheduleMonth(selectedClient.id, newMonth, newYear);
        }
        
    }

    function nextMonth() {
        let newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
        let newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1

        setSelectedMonth(newMonth);
        setSelectedYear(newYear);

        //configureAllDays(newMonth, newYear);

        let todayTS = Date.now();
        let todayDate = new Date(todayTS);
        let todayDateMonth = todayDate.getMonth();
        let todayDateYear = todayDate.getFullYear();
        let todayDateDay = todayDate.getDate();

        let newDate = new Date(newYear, newMonth, todayDateYear === todayDateYear && todayDateMonth === newMonth ? todayDateDay : 1);
        setSelectedTimestamp(newDate.getTime());

        if (selectedClient.id !== "") {
            pullClientScheduleMonth(selectedClient.id, newMonth, newYear);
        }
        //pullMonthlySchedule(newMonth, newYear);
    }

    function addToProgramPressed(thisEvent: CalendarDateCell_t) {
        setIsCreatingProgram(true);
        setWorkoutsInPhase(workoutsInPhase.concat(thisEvent));
    }

    function removeFromProgramPressed(thisEvent: CalendarDateCell_t) {
        setWorkoutsInPhase(workoutsInPhase.filter((item: CalendarDateCell_t, index: number) => {
            return item.timestamp !== thisEvent.timestamp;
        }));
    }

    async function createPhasePressed() {
        if (workoutsInPhase.length === 0) { return; }

        setIsLoading(true);

        let daysList_temp: CalendarDateCell_t[] = JSON.parse(JSON.stringify(workoutsInPhase));
        daysList_temp.sort(function(a,b) {return a.timestamp - b.timestamp});    // Sort by ascending timestamp
        // console.log(daysList_temp);

        //var workoutIDList: string[] = [];
        var workoutListDataTemp: any[] = [];
        for (var i = 0; i < daysList_temp.length; i++) {
            let thisCalDate: CalendarDateCell_t = daysList_temp[i];
            if (thisCalDate.scheduleDay !== undefined && thisCalDate.scheduleDay.workout_id !== undefined) {
                //workoutIDList.push(thisCalDate.scheduleDay.workout_id);
                let thisWorkoutID = thisCalDate.scheduleDay.workout_id;
                let thisWorkoutData: any = await getWorkoutInfo(thisWorkoutID);
                thisWorkoutData["id"] = thisWorkoutID;
                workoutListDataTemp.push(thisWorkoutData);
            }
        }

        parsePersonalWorkouts(workoutListDataTemp);

        //setIsLoading(false);
        //props.createPhasePressedWithWorkouts(workoutIDList);
    }

    async function getWorkoutInfo(workoutID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();

            let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
            thisWorkoutRef.on('value', function(snapshotWorkout) {
                if (snapshotWorkout.exists() === true) {
                    let thisWorkout = snapshotWorkout.val();
                    resolve(thisWorkout);
                }
            });
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    async function parsePersonalWorkouts(workoutList: any) {

        var temp: Workout_t[] = [];
        for (const key of Object.keys(workoutList)) {
            let thisWorkout = workoutList[key];

            //let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

            // Configure Exercise Series
            var seriesList_temp: ExerciseSeries_t[] = [];
            let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
            if (containsExerciseSeries === true) {
                for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                    let thisSeriesObj = thisWorkout.exercise_series[i];
                    let thisSetLetter = getSetLetter(i);

                    var exerciseList_temp: Exercise_t[] = [];
                    if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                        for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                            let thisExerciseObj = thisSeriesObj.exercises[j];
                            let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                            exerciseList_temp.push(exTemp);
                        }
                    }

                    var seriesTemp: ExerciseSeries_t = {
                        title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                        index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                        type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                        exercises: exerciseList_temp
                    };

                    seriesList_temp.push(seriesTemp);
                }
            } else {
                // Workout was created "pre-series". Create a new placeholder series for each existing exercise
                if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        let thisExerciseTemp: any = thisWorkout.exercises[i];
                        let thisSetLetter = getSetLetter(i);
                        var seriesTemp: ExerciseSeries_t = {
                            title: thisSetLetter,
                            index: i,
                            type: 0,
                            exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                        };
                        seriesList_temp.push(seriesTemp);
                    }
                }
            }

            var exerciseList: Exercise_t[] = [];
            if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

                var reps_avg = 0.0;
                var reps_avg_count = 0;
                var exerciseReps: any = {};
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    var a: number[] = [];
                    if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                        for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                            let thisRep = thisWorkout.exercises[i].reps[j];
                            a.push(thisRep);
                            reps_avg += thisRep;
                            reps_avg_count += 1;
                        }
                    }
                    exerciseReps[thisWorkout.exercises[i].key] = a;

                    if (i === 0) {
                        //setRepScheme(a);
                    }
                }

                //setAvgReps(reps_avg / reps_avg_count);

                var exerciseTempos: any = {};
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                    if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                        for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                            let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                            let thisTempo: Tempo_t = {
                                concentric: thisTempoObj.concentric,
                                eccentric: thisTempoObj.eccentric,
                                first_pause: thisTempoObj.first_pause,
                                second_pause: thisTempoObj.second_pause
                            };
                            b.push(thisTempo);
                        }
                    }
                    exerciseTempos[thisWorkout.exercises[i].key] = b;
                }

                var exercisePWMs: any = {};
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    var c: number[] = [];
                    if (thisWorkout.exercises[i].percent_wms !== null && thisWorkout.exercises[i].percent_wms !== undefined && thisWorkout.exercises[i].percent_wms.length > 0) {
                        for (var j = 0; j < thisWorkout.exercises[i].percent_wms.length; j++) {
                            let thisPWM = thisWorkout.exercises[i].percent_wms[j];
                            c.push(thisPWM);
                        }
                    }
                    exercisePWMs[thisWorkout.exercises[i].key] = c;
                }

                var exerciseRests: any = {};
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    var d: number[] = [];
                    if (thisWorkout.exercises[i].rest_times !== null && thisWorkout.exercises[i].rest_times !== undefined && thisWorkout.exercises[i].rest_times.length > 0) {
                        for (var j = 0; j < thisWorkout.exercises[i].rest_times.length; j++) {
                            let thisRest = thisWorkout.exercises[i].rest_times[j];
                            d.push(thisRest);
                        }
                    }
                    exerciseRests[thisWorkout.exercises[i].key] = d;
                }

                let e: Exercise_t[] = await getExercises(thisWorkout.exercises, exerciseRests, exercisePWMs, exerciseReps, exerciseTempos);
                exerciseList = e;


                // push exercises into according slots in seriesList_temp
                if (exerciseList.length > 0) {
                    for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                        let thisExercise: Exercise_t = exerciseList[ex_index];
                        let thisExercise_ID = thisExercise.id;

                        // Loop through seriesList_temp to find thisExercise's matching location
                        if (seriesList_temp.length > 0) {
                            for (var i = 0; i < seriesList_temp.length; i++) {
                                let thisSeries = seriesList_temp[i];
                                if (thisSeries.exercises.length > 0) {
                                    for (var j = 0; j < thisSeries.exercises.length; j++) {
                                        let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                        let thisSeriesExercise_ID = thisSeriesExercise.id;
                                        if (thisSeriesExercise_ID === thisExercise_ID) {
                                            // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                            // for the case of multiple of the same exercises in a workout
                                            if (thisSeriesExercise.title === '') {
                                                seriesList_temp[i].exercises[j] = thisExercise;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
            let newWorkout: Workout_t = {
                title: thisWorkout.title,
                description: thisWorkout.description !== undefined ? thisWorkout.description: '',
                id: thisWorkout.id,
                createdBy: createdBy,
                exercises: exerciseList,
                exerciseSeries: seriesList_temp,
                duration: Number(thisWorkout.duration),
                imageName: thisWorkout.image_name,
                targetGender: thisWorkout.target_gender,
                totalSets: Number(thisWorkout.total_sets),
                totalReps: Number(thisWorkout.total_reps),
                difficulty: Number(thisWorkout.difficulty),
                goals: thisWorkout.goals,
                downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
                timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
            };
            temp.push(newWorkout);
        }

        var totalVol = 0;
        var totalVolSets = 0;
        for (var i = 0; i < temp.length; i++) {
            totalVol += temp[i].totalReps;
            totalVolSets += temp[i].totalSets;
        }

        //setVolume(totalVol);
        //setVolume_sets(totalVolSets);

        //setAvgReps(totalVol / totalVolSets);
        //setWorkouts(workouts.filter(item => false));
        //setWorkouts(workouts.concat(temp));
        //setWorkouts(temp);
        //configMusclesList(temp);
        //setAllWorkouts(allWorkouts.concat(temp));


        // console.log("WORKOUTS:", temp);
        props.createPhasePressedWithWorkouts(temp);
        setTimeout(() => {
            setIsLoading(false);
            setIsCreatingProgram(false);
            setWorkoutsInPhase(workoutsInPhase.filter(item => { return false; }))
        }, 500);
        
    }

    async function getExercises(exercises: any, restTimes: any[], percentWMs: any[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseRest = restTimes[thisExerciseKey];
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];
                let thisExercisePWMs = percentWMs[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            //let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            var restTimeArray: number[] = [];
                            for (var j = 0; j < thisExerciseReps.length; j++) {
                                restTimeArray.push(thisExerciseRest[j] !== undefined && thisExerciseRest[j] !== null ? Number(thisExerciseRest[j]) : -1);
                            }

                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: restTimeArray, //[thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos,
                                percent_wms: thisExercisePWMs
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }

	return (
		<div className="calendar">
			<div className="calendar-header-container">
                <div className="calendar-header-container-title">
                    <h4>{moStrings_full[selectedMonth]} {selectedYear}</h4>
                </div>
                <div className="calendar-header-container-buttons">
                    <div onClick={() => previousMonth()} className="calendar-header-container-button calendar-header-container-button-left">
                        <ArrowBackIos className="calendar-header-container-button-icon calendar-header-container-button-icon-left"/>
                        <p>{moStrings[selectedMonth === 0 ? 11 : selectedMonth - 1]}</p>
                    </div>
                    <div onClick={() => nextMonth()} className="calendar-header-container-button calendar-header-container-button-right">
                        <p>{moStrings[selectedMonth === 11 ? 0 : selectedMonth + 1]}</p>
                        <ArrowForwardIos className="calendar-header-container-button-icon calendar-header-container-button-icon-right"/>
                    </div>
                </div>
                <div hidden={!isCreatingProgram} className="calendar-header-container-phase-builder-container">
                    <div hidden={isLoading} className="calendar-header-container-phase-builder-text-container">
                        <h4>Creating a Phase with <span>{workoutsInPhase.length} Workout{workoutsInPhase.length === 1 ? '' : 's'}</span> selected</h4>
                    </div>
                    <div hidden={!isLoading} className="calendar-header-container-phase-builder-text-container">
                        <h4>Loading Phase Builder...</h4>
                    </div>
                    <div className="calendar-header-container-phase-builder-button-container">
                        <div hidden={isLoading} onClick={() => setIsCreatingProgram(false)} className="calendar-header-container-phase-builder-button-dark">
                            <p>Cancel</p>
                        </div>
                        <div hidden={isLoading}  onClick={() => createPhasePressed()} className={`calendar-header-container-phase-builder-button-blue ${workoutsInPhase.length === 0 ? 'calendar-header-container-phase-builder-button-blue-unselected' : ''}`}>
                            <p>Create Phase</p>
                        </div>
                        <div hidden={!isLoading} className={`calendar-header-container-phase-builder-button-blue calendar-header-container-phase-builder-button-blue-loading`}>
                            <div className="create-workout-exercise-configure-loading-spinner calendar-header-container-phase-builder-button-blue-loading-spinner"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="calendar-content-container">
                <div hidden={!isLoading} className="calendar-content-loading-container">
                    <div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Loading schedule</p>
                </div>
                <div hidden={calType !== "month"} className="calendar-content">
                    <CalendarMonth 
                        client={props.client}
                        scheduledEvents={scheduledEvents}
                        selectedTimestamp={selectedTimestamp}
                        selectedDay={selectedDay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        clientID={selectedClient.id === "" ? undefined : selectedClient.id}
                        isBookable={props.isBookable}
                        isCreatingProgram={isCreatingProgram}
                        workoutsInPhase={workoutsInPhase}
                        addToProgramPressed={(c: CalendarDateCell_t) => addToProgramPressed(c)}
                        removeFromProgramPressed={(c: CalendarDateCell_t) => removeFromProgramPressed(c)}
                        addNewAppointmentPressed={(ts: number) => addNewAppointmentPressed(ts)}
                        createNewWorkout={(e: any) => props.createNewWorkout(e)}
                        addNewAppointmentPressedForClient={(ts: number) => addNewAppointmentPressedForClient(ts)}
                        editAppointmentPressedForClient={(ts: number, clientID: string) => editAppointmentPressedForClient(ts, clientID)}
                        editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => props.editWorkoutForDay(workout, calendarDay, client)}/>
                </div>
            </div>
		</div>
	)
}

export default Calendar;