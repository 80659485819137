import React, { useState, useEffect } from 'react';
import './DiscoverNodes.css';
import './MyNodes.css';

import NodeSet_t from '../../../Interfaces/NodeSet_t';
import NodeInstance from '../../../NodeInstance';
import PageHeader_t from '../../../Interfaces/PageHeader_t';

import PageHeader from '../../../components/Base/PageHeader';
import DiscoveredNodeSetCell from '../../../components/Nodes/DiscoveredNodeSetCell';
import InfoBlock from '../../../components/Base/InfoBlock';

import '@material-ui/core';
import SignalCellularAltRounded from '@material-ui/icons/SignalCellularAltRounded';
import BluetoothSearchingRounded from '@material-ui/icons/BluetoothSearchingRounded';
import AddCircleOutlineRounded from '@material-ui/icons/AddCircleOutlineRounded';

function DiscoverNodes(props: any) {

    let pageOptions: PageHeader_t[] = [{ name:"Discover Nodes", alert:false }];

    const [pageNumber, setPageNumber] = useState(0);
    const [initSet, setInitSet] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [shouldRunAnimation, setShouldRunAnimation] = useState(true);
    const [circleCSS, setCircleCSS] = useState("");
    //const [nodeSets, setNodeSets] = useState<NodeSet_t[]>([]);
    const [nodeSets, setNodeSets] = useState<NodeSet_t[]>([
        {
            setSIN: "000001",
            UUIDs: ["BBA111","BBA112","ABC113","AAA114","AAA115","AAA116","AAA117","AAA118","AAA119"],
            setSize: 9
        },
        {
            setSIN: "00000A",
            UUIDs: ["AAA111","AAA112","AAA113","AAA114","AAA115","AAA116","AAA117","AAA118"],
            setSize: 9
        },
        {
            setSIN: "0000FF",
            UUIDs: ["AAA111","AAA112","AAA113","AAA114","AAA115"],
            setSize: 9
        },
        {
            setSIN: "00004B",
            UUIDs: ["AAA111","AAA112","AAA113","AAA114","AAA115","AAA116","AAA117"],
            setSize: 9
        }
    ]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

     function initialize() {
    //     props.nodeManager.setDiscoveredNodesCallback(discoveredNodesUpdated);
         //scan();
     }

    useEffect(() => {
        var tempNodeSets: NodeSet_t[] = [];

        if (props.discoveredNodesList.length > 0) {
            for (var i = 0; i < props.discoveredNodesList.length; i++) {
                let thisNode: NodeInstance = props.discoveredNodesList[i];
                let thisSetSIN = thisNode.getSetSIN();

                var tempSetSINIndex = -1;
                if (tempNodeSets.length > 0) {
                    for (var j = 0; j < tempNodeSets.length; j++) {
                        let thisSet = tempNodeSets[j];
                        if (thisSet.setSIN === thisSetSIN) {
                            tempSetSINIndex = j;
                        }
                    }
                }

                if (tempSetSINIndex === -1) {
                    let newSet: NodeSet_t = {setSIN: thisSetSIN, UUIDs: [thisNode.getUUID()], setSize: 9}
                    tempNodeSets.push(newSet);
                } else {
                    tempNodeSets[tempSetSINIndex].UUIDs.push(thisNode.getUUID());
                }
            }
        }


        setNodeSets(tempNodeSets);
    }, [props.discoveredNodesList]);

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    // function discoveredNodesUpdated(discoveredNodes: NodeInstance[]) {
    //     // console.log("UPDATED!!!!!")
    // }

    function scan() {
        if (isSearching === true) { return; }

        if (props.nodeManager === undefined) { return; }

        props.nodeManager.scanForNodes();

        //props.scan();

        setShouldRunAnimation(true);
        setIsSearching(true);
        runSearchAnimation(false);

        setTimeout(() => {
            setIsSearching(false);
            setShouldRunAnimation(false);
        }, 8000);
    }

    function runSearchAnimation(isBlue: boolean) {
        
        setCircleCSS(isBlue ? "discover-nodes-searching-circle-dark" : "discover-nodes-searching-circle-blue")
        setTimeout(() => {
            if (shouldRunAnimation === false) {return;}
            runSearchAnimation(!isBlue);
            
        }, 600);
    }

    function addSet(setSIN: string) {
        //props.addSet(setSIN);
        // console.log(`> NODES.tsx: Adding set ${setSIN} to my list`);
        if (props.nodeManager !== undefined) {
            props.nodeManager.addSetToMyList(setSIN);
        }
    }


	return (
		<div className="discover-nodes-container">
            <div className="home-header">
                <PageHeader 
                    pageName="My Nodes" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
                    addNewWorkoutPlan={() => props.createNewWorkoutPlan !== undefined ? props.createNewWorkoutPlan() : null}
                    addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
            <div className="page-title-header-container">
                <h1>Discover Nodes</h1>
            </div>
			<div hidden={!isSearching} className="discover-nodes-searching-container">
                <div className={`discover-nodes-searching-circle ${circleCSS}`}>
                    <BluetoothSearchingRounded className="discover-nodes-ble-icon" style={{ fontSize: 28 }}/>
                </div>
                <h3>Discovering nearby Nodes</h3>
            </div>
            <div hidden={nodeSets.length === 0 && isSearching === false} className="discover-nodes-content-container">
                <InfoBlock infoText="Tap on a Node set below to add to your account. They'll be saved and ready for you next time you log in."/>
                {
                    nodeSets.map((item: NodeSet_t, index: number) => (
                        <DiscoveredNodeSetCell 
                            key={index}
                            nodeSet={item}
                            addSet={() => addSet(item.setSIN)}/>
                    ))
                }
                
                <div hidden={nodeSets.length === 0} onClick={() => scan()} className="discover-nodes-cta-button">Search for more</div>
            </div>
            <div hidden={nodeSets.length > 0} className="my-nodes-empty-container discover-nodes-empty-container">
                <div className="discover-nodes-empty-image-container">
                    <img className="discover-nodes-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/devices/nodes-x3-01.svg`}/>
                </div>
                <h2>No Nodes Sets found</h2>
                <p>Make sure that your Nodes are charged and the power switch is in the 'ON' position</p>
                <div onClick={() => scan()} className="my-nodes-cta-button">Search again <AddCircleOutlineRounded style={{ fontSize: 14 }} className="my-nodes-add-icon"/></div>
            </div>
		</div>
	)
}

export default DiscoverNodes;