import React, { useState } from 'react';
import { IonInput, IonTextarea } from '@ionic/react';
import { FileDrop } from 'react-file-drop';
import firebase from '../../../../Firebase';

import './MyProfile.css';

import ColorScheme_t from '../../../../Interfaces/CoachingProfile/ColorScheme_t';
import InformationSection_t from '../../../../Interfaces/CoachingProfile/InformationSection_t';
import MyProfileBackground_t from '../../../../Interfaces/CoachingProfile/MyProfileBackground_t';

import InformationSection from './InformationSection';
import MyProfileBackgroundCell from './MyProfileBackgroundCell';
import MyProfilePhonePreview from './MyProfilePhonePreview';
import MyProfileCardPreview from './MyProfileCardPreview';
import CheckBox from '../../../../components/Base/CheckBox';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Image from '@material-ui/icons/Image';
import Add from '@material-ui/icons/Add';
import Bookmark from '@material-ui/icons/Bookmark';
import School from '@material-ui/icons/School';
import EmojiEvents from '@material-ui/icons/EmojiEvents';





interface ProfileInfoSection_t {
    title: string;
    content: string;
    showButton: boolean;
    imageURL: string;
}

function MyProfile(props: any) {

    let defaultSections: InformationSection_t[] = [
        {
            title: "My Training Philosophy",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            hasImage: true,
            hasButton: true,
            buttonText: "Connect with me",
            imageURL: "https://images.unsplash.com/photo-1623200216581-969d9479cf7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fHdvcmtvdXR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60"
        },
        {
            title: "Let's make the most of your next session",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            hasImage: true,
            hasButton: false,
            buttonText: "Connect with me",
            imageURL: "https://images.unsplash.com/photo-1629993471098-9cc8de61aece?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fHdvcmtvdXR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60"
        }
    ]
    const [colorSchemeOptions, setColorSchemeOptions] = useState<ColorScheme_t[]>([
        {
            title: "Classic",
            background: '#1F1F21',
            primary: '#5B62FF',
            heading_text: '#FFFFFF',
            body_text: '#ffffff',
            button_text: '#FFFFFF'
        },
        {
            title: "Black n' Yellow",
            background: '#000000',
            primary: '#FFEC00',
            heading_text: '#FFFFFF',
            body_text: '#ffffff',
            button_text: '#28292C'
        },
        {
            title: "Muted Pink",
            background: '#DABDB6',
            primary: '#ffffff',
            heading_text: '#FFFFFF',
            body_text: '#723A37',
            button_text: '#723A37'
        },
        {
            title: "Dark Luxe",
            background: '#000000',
            primary: '#E8BD70',
            heading_text: '#EDD185',
            body_text: '#97969B',
            button_text: '#000000'
        },
        {
            title: "Gentle Orange",
            background: '#BDBDBD',
            primary: '#FFC121',
            heading_text: '#FFFFFF',
            body_text: '#28292C',
            button_text: '#28292C'
        },
        {
            title: "Bold Red",
            background: '#F5F6F7',
            primary: '#DD2325',
            heading_text: '#484A52',
            body_text: '#484A52',
            button_text: '#ffffff'
        },
        {
            title: "Sunset",
            background: '#242424',
            primary: '#F7ED6C',
            heading_text: '#FFFFFF',
            body_text: '#FFFFFF',
            button_text: '#242424'
        },
        {
            title: "Bright Amethyst",
            background: '#EEF0F0',
            primary: '#5E45B4',
            heading_text: '#28292C',
            body_text: '#28292C',
            button_text: '#FFFFFF'
        },
        {
            title: "Sky Blue",
            background: '#F0F3FF',
            primary: '#ADC6E4',
            heading_text: '#2D2F35',
            body_text: '#28292C',
            button_text: '#ffffff'
        },
        {
            title: "Black and White",
            background: '#000000',
            primary: '#ffffff',
            heading_text: '#ffffff',
            body_text: '#ffffff',
            button_text: '#000000'
        },
        {
            title: "Lavinder",
            background: '#A580D4',
            primary: '#ffffff',
            heading_text: '#ffffff',
            body_text: '#EEE7F5',
            button_text: '#363A4C'
        },
        {
            title: "Electric",
            background: '#A6A8B2',
            primary: '#C1F50E',
            heading_text: '#000000',
            body_text: '#000000',
            button_text: '#000000'
        }
        
    ])

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingHeaderImage, setIsLoadingHeaderImage] = useState(false);
    const [showingProfile, setShowingProfile] = useState(true);

    const [trainerName_f, setTrainerName_f] = useState("");
    const [trainerName_l, setTrainerName_l] = useState("");
    const [gymName, setGymName] = useState("");
    const [trainingSinceString, setTrainingSinceString] = useState("");
    const [myCredentials, setMyCredentials] = useState("Certified Personal Trainer");
    const [acceptingClients, setAcceptingClients] = useState(false);

    const [uploadProgress_headerImage, setUploadProgress_headerImage] = useState(0);
    const [uploadWorkflowState_headerImage, setUploadWorkflowState_headerImage] = useState(0);
    const [headerImageURL, setHeaderImageURL] = useState("");

    const [studioImageURL, setStudioImageURL] = useState("");

    const [infoSections, setInfoSections] = useState<InformationSection_t[]>([]);

    const [educations, setEducations] = useState<MyProfileBackground_t[]>([]);
    const [awards, setAwards] = useState<MyProfileBackground_t[]>([]);
    const [achievements, setAchievements] = useState<MyProfileBackground_t[]>([]);

    const [isEditted, setIsEditted] = useState(false);

    const [colorScheme, setColorScheme] = useState<ColorScheme_t>({
        title: '',
        background: '#000000',
        primary: '#FFC121',
        heading_text: '#FFFFFF',
        body_text: '#28292C',
        button_text: '#28292C'
    })
    const [schemeForcedByStudio, setSchemeForcedByStudio] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        configureDefaults();
        initialize();
    }

    function initialize() {
        setIsLoading(true);

        // 1. Get user's profile
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setTrainerName_f(data.first_name);
                setTrainerName_l(data.last_name);
                //setTrainerName(data.full_name);


                if (data.coaching_profile !== undefined) {
                    if (data.coaching_profile.background !== undefined) {
                        if (data.coaching_profile.background.coaching_since_string !== undefined) {
                            setTrainingSinceString(data.coaching_profile.background.coaching_since_string);
                        }
                        if (data.coaching_profile.background.educations !== undefined) {
                            setEducations(data.coaching_profile.background.educations);
                        }
                        if (data.coaching_profile.background.awards !== undefined) {
                            setAwards(data.coaching_profile.background.awards);
                        }
                        if (data.coaching_profile.background.achievements !== undefined) {
                            setAchievements(data.coaching_profile.background.achievements);
                        }
                    }
                    if (data.coaching_profile.credentials !== undefined) {
                        setMyCredentials(data.coaching_profile.credentials);
                    }
                    if (data.coaching_profile.information_sections !== undefined) {
                        setInfoSections(data.coaching_profile.information_sections)
                    }
                    if (data.coaching_profile.theme !== undefined) {
                        setColorScheme(data.coaching_profile.theme)
                    }
                    if (data.coaching_profile.accepting_clients !== undefined) {
                        setAcceptingClients(data.coaching_profile.accepting_clients)
                    }
                }


                if (data.studio_id !== undefined && data.studio_id !== "") {
                    getStudioProfile(data.studio_id);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        });

        // 3. Load header image
        setIsLoadingHeaderImage(true);

        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref(`images/coaching_profile/${trainerUID}/header_image.png`).getDownloadURL()
           .then(fireBaseUrl => {
             setHeaderImageURL(fireBaseUrl);
             setTimeout(() => {
                 setIsLoadingHeaderImage(false);
             }, 800);
             
           })
           .catch(error => {
               setHeaderImageURL("");
               setIsLoadingHeaderImage(false);
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function getStudioProfile(forStudioID: string) {

        getStudioProfileImage(forStudioID);

        let database = firebase.database();
        let ref = database.ref(`training_studios/${forStudioID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setGymName(data.name);
                setIsLoading(false);

                if (data.force_theme_to_trainers !== undefined && data.force_theme_to_trainers === true) {
                    setSchemeForcedByStudio(true);
                    if (data.theme !== undefined) {
                        setColorScheme(data.theme);
                    }
                }
            } else {
                setIsLoading(false);
            }
        });
    }

    function getStudioProfileImage(forStudioID: string) {

        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref(`images/studio_profile/${forStudioID}/profile_image.png`).getDownloadURL()
       .then(fireBaseUrl => {
         setStudioImageURL(fireBaseUrl);
         setTimeout(() => {
             setIsLoadingHeaderImage(false);
         }, 800);
         
       })
       .catch(error => {
           setStudioImageURL("");
           setIsLoadingHeaderImage(false);
           //// console.log('No image found');
           //// console.log(error);
       })
    }

    function configureDefaults() {
        // Config default theme
        setColorScheme(colorSchemeOptions[0]);

        // Config default sections
        setInfoSections(defaultSections);
    }

    function getColorForType(theme: ColorScheme_t, type: 'background' | 'primary' | 'heading_text' | 'body_text' | 'button_text', rgb?: boolean, a?: number) {
        return rgb === undefined || rgb === false ? theme[type] : hexToRgb(theme[type], a === undefined ? 1 : a);
    }

    function hexToRgb(hex: string, a: number) {
        
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let rgbString = result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : `rgba(0, 0, 0, ${a})`

        // console.log("RESULT:",rgbString, result, hex, a);
        

        return rgbString;
    }

    function updateSection(toSection: InformationSection_t, atIndex: number) {
        setIsEditted(true);
        setInfoSections(infoSections.map((item: InformationSection_t, index: number) => {
            if (index === atIndex) {
                return toSection;
            } else {
                return item;
            }
        }));
    }

    function addInfoSection() {
        setIsEditted(true);
        setInfoSections(infoSections.concat({
            title: "New Section",
            content: "Add some content here...",
            hasImage: false,
            hasButton: false,
            buttonText: "Connect with me",
            imageURL: ""
        }))
    }

    function removeInfoSection(atIndex: number) {
        setIsEditted(true);
        setInfoSections(infoSections.filter((item: InformationSection_t, index: number) => { return atIndex !== index}));
    }

    function addMoreBackground(type: 'education' | 'award' | 'achievement') {
        setIsEditted(true);
        let newBackground: MyProfileBackground_t = {
                                                        title: "",
                                                        date_string: "",
                                                        type: type
                                                    };

        switch (type) {
            case 'education' :
                setEducations(educations.concat(newBackground));
                break;
            case 'award':
                setAwards(awards.concat(newBackground));
                break;
            case 'achievement':
                setAchievements(achievements.concat(newBackground));
                break
            default:
                break;
        }
    }

    function removeBackgroundPressed(type: 'education' | 'award' | 'achievement', atIndex: number) {
        setIsEditted(true);
        switch (type) {
            case 'education' :
                setEducations(educations.filter((item: MyProfileBackground_t, index: number) => { return index !== atIndex }));
                break;
            case 'award':
                setAwards(awards.filter((item: MyProfileBackground_t, index: number) => { return index !== atIndex }));
                break;
            case 'achievement':
                setAchievements(achievements.filter((item: MyProfileBackground_t, index: number) => { return index !== atIndex }));
                break
            default:
                break;
        }
    }

    function backgroundUpdated(toBackground: MyProfileBackground_t, type: 'education' | 'award' | 'achievement', atIndex: number) {
        setIsEditted(true);
        switch (type) {
            case 'education' :
                setEducations(educations.map((item: MyProfileBackground_t, index: number) => { 
                    if (index === atIndex) {
                        return toBackground;
                    } else {
                        return item;
                    }
                }));
                break;
            case 'award':
                setAwards(awards.map((item: MyProfileBackground_t, index: number) => { 
                    if (index === atIndex) {
                        return toBackground;
                    } else {
                        return item;
                    }
                }));
                break;
            case 'achievement':
                setAchievements(achievements.map((item: MyProfileBackground_t, index: number) => { 
                    if (index === atIndex) {
                        return toBackground;
                    } else {
                        return item;
                    }
                }));
                break
            default:
                break;
        }
    }

    function updateTrainingSince(toString: string) {
        setIsEditted(true);
        setTrainingSinceString(toString);
    }

    function updateCredentials(toString: string) {
        setIsEditted(true);
        setMyCredentials(toString);
    }

    function uploadImage(files: FileList | null, event: any) {
        if (files === null) { return; }
        //if (props.information === undefined || props.information.path === undefined) { return; }
        // console.log('onDrop!', files, event);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setUploadWorkflowState_headerImage(1);

        let storage = firebase.storage();
        let uploadTask = storage.ref(`images/coaching_profile/${trainerUID}/header_image.png`).put(files[0]);

        uploadTask.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress_headerImage(progress);
        }, (error) => {
            // error
            setUploadWorkflowState_headerImage(0);
        }, () => {
            // completed
            uploadTask.snapshot.ref.getDownloadURL()
            .then(fireBaseUrl => {
                setUploadWorkflowState_headerImage(0);
                setHeaderImageURL(fireBaseUrl);
            })
            .catch(error => {
                //// console.log('No image found');
                //// console.log(error);
                setUploadWorkflowState_headerImage(0);
            })

        });

        // storage.ref(props.information.path).put(files[0]).then((resp: any) => {
        //     setTimeout(() => {
        //         if (props.information.completedCallback !== undefined) {
        //             props.information.completedCallback();
        //         }
        //     }, 500);
            
        // }).catch((e: any) => {

        // })

    }

    function updateTheme(toTheme: ColorScheme_t) {
        setIsEditted(true);
        setColorScheme(toTheme);
    }

    function updateAcceptingClients(toState: boolean) {
        setIsEditted(true);
        setAcceptingClients(toState);
    }

    function saveChanges() {

        setIsLoading(true);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let profileObject = {
            information_sections: infoSections,
            background: {
                coaching_since_string: trainingSinceString,
                educations: educations,
                awards: awards,
                achievements: achievements
            },
            theme: colorScheme,
            credentials: myCredentials,
            accepting_clients: acceptingClients
        }

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}/coaching_profile`);
        ref.set(profileObject);

        setTimeout(() => {
            setIsLoading(false);
            setIsEditted(false);
        }, 800);
        

    } 

	return (
		<div className="my-coaching-profile-page">
            <div hidden={!isLoading && !isLoadingHeaderImage} className="my-coaching-profile-curtain">
                <div className="workout-plan-content-loading-spinner"/>
                <p>{isEditted ? "Saving" : "Loading"} your coaching profile...</p>
            </div>
			<div className="my-coaching-profile-page-pane my-coaching-profile-page-pane-left">
                <div className="my-coaching-profile-page-pane-left-header-container">
                    <div className="my-coaching-profile-page-pane-left-header-title">
                        <h1>My Coaching Profile</h1>
                        <p>Promote yourself, sell programs, and more with your custom coaching profile.</p>
                    </div>
                    <div className="my-coaching-profile-page-pane-left-header-button-container">
                        <div onClick={() => saveChanges()} hidden={!isEditted} className="my-coaching-profile-page-pane-left-header-button">
                            <p>Save Changes</p>
                        </div>
                    </div>
                </div>
                <div className="my-coaching-profile-page-content">
                    <div className="my-coaching-profile-page-content-section">
                        <div className="my-coaching-profile-page-content-section-header">
                            <h4>Profile Header Section</h4>
                            <p>This is the first thing that Athletes will see on your Coaching Profile. Include an image of yourself and show off your coaching credentials.</p>
                        </div>
                        <div className="my-coaching-profile-page-content-section-content">
                            <div className="my-coaching-profile-drop-area">
                                <FileDrop
                                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                    onDragOver={(event) => console.log('onDragOver', event)}
                                    onDragLeave={(event) => console.log('onDragLeave', event)}
                                    onDrop={(files, event) => uploadImage(files, event)}>
                                        <div hidden={headerImageURL !== ""} className="my-coaching-profile-drop-area-inner-container">
                                            <div className="my-coaching-profile-drop-area-icon-container">
                                                <Image className="my-coaching-profile-drop-area-icon"/>
                                            </div>
                                            <h4>Drop an image here to upload</h4>
                                            <p>Files less than 10MB are recommended, in .png, .jpg, or .gif formats.</p>
                                        </div>
                                        <div hidden={headerImageURL === ""} className="my-coaching-profile-drop-area-inner-container my-coaching-profile-drop-area-inner-container-has-image">
                                            <div className="my-coaching-profile-drop-area-image-container">
                                                <img className="" src={headerImageURL}/>
                                            </div>
                                            <div className="my-coaching-profile-drop-area-text-container">
                                                <h4>Header section image</h4>
                                                <div onClick={() => setHeaderImageURL("")} className="my-profile-information-section-card-body-image-content-remove-button">
                                                    <Close className="my-profile-information-section-card-body-image-content-remove-button-icon"/>
                                                    <p>Remove image</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div hidden={uploadWorkflowState_headerImage !== 1} className="upload-image-modal-loading-bar-container">
                                            <div className="upload-image-modal-loading-bar">
                                                <div className="upload-image-modal-loading-bar-inner" style={{width: `${uploadProgress_headerImage}%`}}/>
                                            </div>
                                            <p>{Math.round(uploadProgress_headerImage)}%</p>
                                        </div>
                                        
                                </FileDrop>
                                
                            </div>
                            <div className="my-coaching-profile-page-content-section-background-training-since-container my-coaching-profile-credentials-input-container">
                                <div className="my-coaching-profile-page-content-section-background-training-since-text">
                                    <h4>I am a </h4>
                                </div>
                                <div className="my-profile-background-cell-row-input-container-inner my-profile-information-section-card-header-text-editable-inner">
                                    <IonTextarea value={myCredentials} 
                                        class=""
                                        placeholder={`Enter your credentials...`}
                                        inputmode="text"
                                        clearOnEdit={false}
                                        autoGrow={true}
                                        rows={1}
                                        maxlength={500}
                                        onKeyPress={e => null}
                                        onIonChange={e => updateCredentials(e.detail.value!)}/>
                                </div>
                            </div>
                            <div className="my-coaching-profile-page-content-section-checkbox-container">
                                <div className="my-coaching-profile-page-content-section-checkbox-content">
                                    <div className="my-coaching-profile-page-content-section-checkbox">
                                        <CheckBox 
                                            forceState={acceptingClients}
                                            stateChanged={(s: boolean) => updateAcceptingClients(s)}/>
                                    </div>
                                    <div className="my-coaching-profile-page-content-section-checkbox-text-container">
                                        <h4>Accepting new clients</h4>
                                        <p>Allow athletes to connect with you and inquire about becoming a client.</p>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-coaching-profile-page-content-section">
                        <div className="my-coaching-profile-page-content-section-header">
                            <h4>Information Sections</h4>
                            <p>Show Athletes what you’re made of. Add up to 6 information sections to covery training philosophy, goals, motivation, and more.</p>
                        </div>
                        <div className="my-coaching-profile-page-content-section-content my-coaching-profile-page-content-section-content-sections">
                            <div className="my-coaching-profile-page-content-section-content-sections-inner">
                                {
                                    infoSections.map((item: InformationSection_t, index: number) => (
                                        <InformationSection 
                                            infoSection={item}
                                            sectionIndex={index}
                                            acceptingClients={acceptingClients}
                                            updateSection={(s: InformationSection_t) => updateSection(s, index)}
                                            removeInfoSection={() => removeInfoSection(index)}/>
                                    ))
                                }
                            </div>
                            <div hidden={infoSections.length >= 6} onClick={() => addInfoSection()} className="my-coaching-profile-page-content-section-content-sections-add-button">
                                <Add className="my-coaching-profile-page-content-section-content-sections-add-button-icon"/>
                                <p>Add another information section</p>
                            </div>
                        </div>
                    </div>
                    <div className="my-coaching-profile-page-content-section">
                        <div className="my-coaching-profile-page-content-section-header">
                            <h4>My Coaching Background</h4>
                            <p>Let Athletes know all you've learnt and accomplished over the years by adding education, awards, and achievements.</p>
                        </div>
                        <div className="my-coaching-profile-page-content-section-background-training-since-container">
                            <div className="my-coaching-profile-page-content-section-background-training-since-text">
                                <h4>I have been coaching since</h4>
                            </div>
                            <div className="my-profile-background-cell-row-input-container-inner my-profile-information-section-card-header-text-editable-inner">
                                <IonTextarea value={trainingSinceString} 
                                    class=""
                                    placeholder={`January 2012`}
                                    inputmode="text"
                                    clearOnEdit={false}
                                    autoGrow={true}
                                    rows={1}
                                    maxlength={500}
                                    onKeyPress={e => null}
                                    onIonChange={e => updateTrainingSince(e.detail.value!)}/>
                            </div>
                        </div>
                        <div className="my-coaching-profile-page-content-section-content my-coaching-profile-page-content-section-content-background">
                            <div className="my-coaching-profile-page-content-section-content-background-section">
                                <div className="my-coaching-profile-page-content-section-content-background-section-header">
                                    <School className="my-coaching-profile-page-content-section-content-background-section-header-icon"/>
                                    <h4>Education & Certifications</h4>
                                </div>
                                <div className="my-coaching-profile-page-content-section-content-background-section-body">
                                    <div hidden={educations.length === 0} className="my-coaching-profile-page-content-section-content-background-section-body-list-container">
                                        {
                                            educations.map((item: MyProfileBackground_t, index: number) => (
                                                <MyProfileBackgroundCell 
                                                    background={item}
                                                    backgroundIndex={index}
                                                    backgroundUpdated={(b: MyProfileBackground_t) => backgroundUpdated(b, 'education', index)}
                                                    removePressed={() => removeBackgroundPressed('education', index)}/>
                                            ))
                                        }
                                    </div>
                                    <div hidden={educations.length !== 0} className="my-coaching-profile-page-content-section-content-background-section-body-empty-container">
                                        <p>No Education & Certifications added</p>
                                    </div>
                                    <div hidden={educations.length >= 10} onClick={() => addMoreBackground('education')} className="my-coaching-profile-page-content-section-content-sections-add-button">
                                        <Add className="my-coaching-profile-page-content-section-content-sections-add-button-icon"/>
                                        <p>Add more Education or Certifications</p>
                                    </div>
                                </div>
                            </div>
                            <div className="my-coaching-profile-page-content-section-content-background-section">
                                <div className="my-coaching-profile-page-content-section-content-background-section-header">
                                    <EmojiEvents className="my-coaching-profile-page-content-section-content-background-section-header-icon"/>
                                    <h4>Awards</h4>
                                </div>
                                <div className="my-coaching-profile-page-content-section-content-background-section-body">
                                    <div hidden={awards.length === 0} className="my-coaching-profile-page-content-section-content-background-section-body-list-container">
                                        {
                                            awards.map((item: MyProfileBackground_t, index: number) => (
                                                <MyProfileBackgroundCell 
                                                    background={item}
                                                    backgroundIndex={index}
                                                    backgroundUpdated={(b: MyProfileBackground_t) => backgroundUpdated(b, 'award', index)}
                                                    removePressed={() => removeBackgroundPressed('award', index)}/>
                                            ))
                                        }
                                    </div>
                                    <div hidden={awards.length !== 0} className="my-coaching-profile-page-content-section-content-background-section-body-empty-container">
                                        <p>No Awards added</p>
                                    </div>
                                    <div hidden={awards.length >= 10} onClick={() => addMoreBackground('award')} className="my-coaching-profile-page-content-section-content-sections-add-button">
                                        <Add className="my-coaching-profile-page-content-section-content-sections-add-button-icon"/>
                                        <p>Add more Awards</p>
                                    </div>
                                </div>
                            </div>
                            <div className="my-coaching-profile-page-content-section-content-background-section">
                                <div className="my-coaching-profile-page-content-section-content-background-section-header">
                                    <Bookmark className="my-coaching-profile-page-content-section-content-background-section-header-icon"/>
                                    <h4>Achievements</h4>
                                </div>
                                <div className="my-coaching-profile-page-content-section-content-background-section-body">
                                    <div hidden={achievements.length === 0} className="my-coaching-profile-page-content-section-content-background-section-body-list-container">
                                        {
                                            achievements.map((item: MyProfileBackground_t, index: number) => (
                                                <MyProfileBackgroundCell 
                                                    background={item}
                                                    backgroundIndex={index}
                                                    backgroundUpdated={(b: MyProfileBackground_t) => backgroundUpdated(b, 'achievement', index)}
                                                    removePressed={() => removeBackgroundPressed('achievement', index)}/>
                                            ))
                                        }
                                    </div>
                                    <div hidden={achievements.length !== 0} className="my-coaching-profile-page-content-section-content-background-section-body-empty-container">
                                        <p>No Achievements added</p>
                                    </div>
                                    <div hidden={achievements.length >= 10} onClick={() => addMoreBackground('achievement')} className="my-coaching-profile-page-content-section-content-sections-add-button">
                                        <Add className="my-coaching-profile-page-content-section-content-sections-add-button-icon"/>
                                        <p>Add more Achievements</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div hidden={schemeForcedByStudio} className="my-coaching-profile-page-content-section">
                        <div className="my-coaching-profile-page-content-section-header">
                            <h4>Customize Your Profile's Theme</h4>
                            <p>Show off your gym's branding by designing your coaching profile's theme.</p>
                        </div>
                        <div className="my-coaching-profile-page-content-section-content my-coaching-profile-page-content-section-content-themes">
                            {
                                colorSchemeOptions.map((item: ColorScheme_t, index: number) => (
                                    <div onClick={() => updateTheme(item)} className="my-coaching-profile-page-theme-card">
                                        <div className="my-coaching-profile-page-theme-card-theme" style={{background: getColorForType(item, 'background')}}>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-header-primary" style={{background: getColorForType(item, 'primary')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-header" style={{background: getColorForType(item, 'heading_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-body my-coaching-profile-page-theme-card-theme-body-top" style={{background: getColorForType(item, 'body_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-body" style={{background: getColorForType(item, 'body_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-body my-coaching-profile-page-theme-card-theme-body-bottom" style={{background: getColorForType(item, 'body_text')}}></div>
                                            <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-button" style={{background: getColorForType(item, 'primary')}}>
                                                <div className="my-coaching-profile-page-theme-card-theme-item my-coaching-profile-page-theme-card-theme-button-text" style={{background: getColorForType(item, 'button_text')}}></div>
                                            </div>


                                        </div>
                                        <div className="my-coaching-profile-page-theme-card-title">
                                            <CheckBox 
                                                forceState={item.title === colorScheme.title}
                                                stateChanged={(s: boolean) => updateTheme(item)}/>
                                            <h4>{item.title}</h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-coaching-profile-page-pane my-coaching-profile-page-pane-right">
                <div className="my-coaching-profile-page-pane-right-header-container">
                    <div className="my-coaching-profile-page-pane-right-header-title">
                        <h1>Preview</h1>
                    </div>
                    <div className="my-coaching-profile-page-pane-right-header-view-selector">
                        <div onClick={() => setShowingProfile(true)} className={`my-coaching-profile-page-pane-right-header-view-selector-option my-coaching-profile-page-pane-right-header-view-selector-option-left ${showingProfile ? 'my-coaching-profile-page-pane-right-header-view-selector-option-highlighted' : ''}`}>
                            <p>Profile</p>
                        </div>
                        <div onClick={() => setShowingProfile(false)} className={`my-coaching-profile-page-pane-right-header-view-selector-option my-coaching-profile-page-pane-right-header-view-selector-option-right ${!showingProfile ? 'my-coaching-profile-page-pane-right-header-view-selector-option-highlighted' : ''}`}>
                            <p>Card</p>
                        </div>
                    </div>
                </div>
                <div hidden={!showingProfile} className="my-coaching-profile-page-pane-right-content-container">
                    <MyProfilePhonePreview 
                        trainerName_f={trainerName_f}
                        trainerName_l={trainerName_l}
                        myCredentials={myCredentials}
                        gymName={gymName}
                        headerImageURL={headerImageURL}
                        studioImageURL={studioImageURL}
                        infoSections={infoSections}
                        colorScheme={colorScheme}
                        trainingSinceString={trainingSinceString}
                        acceptingClients={acceptingClients}
                        educations={educations}
                        awards={awards}
                        achievements={achievements}/>
                    <div className="my-coaching-profile-page-pane-right-content-bottom-text">
                        <p>This how your profile will appear to Athletes when<br/>viewing your profile in the <a href="https://apps.apple.com/ie/app/eigen-fit/id1498333582">Eigen Fitness app</a></p>
                    </div>
                    <div className="my-coaching-profile-page-pane-right-content-scroll-floater">
                        <p>Scroll</p>
                        <KeyboardArrowDown className="my-coaching-profile-page-pane-right-content-scroll-floater-icon"/>
                    </div>
                </div>
                <div hidden={showingProfile} className="my-coaching-profile-page-pane-right-content-container">
                    <MyProfileCardPreview
                        trainerName_f={trainerName_f}
                        trainerName_l={trainerName_l}
                        myCredentials={myCredentials}
                        gymName={gymName}
                        headerImageURL={headerImageURL}
                        studioImageURL={studioImageURL}
                        infoSections={infoSections}
                        colorScheme={colorScheme}
                        trainingSinceString={trainingSinceString}
                        acceptingClients={acceptingClients}
                        educations={educations}
                        awards={awards}
                        achievements={achievements}/>
                    <div className="my-coaching-profile-page-pane-right-content-bottom-text">
                        <p>This how your profile will appear to Athletes when<br/>see your profile card in the <a href="https://apps.apple.com/ie/app/eigen-fit/id1498333582">Eigen Fitness app</a></p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default MyProfile;