import React, { useState, useEffect } from 'react';
import './NewWorkout_Client.css';

import Workout_t from '../../../Interfaces/Workout_t';
import Client_t from '../../../Interfaces/Client_t';

import NewWorkoutWorkoutCard from './NewWorkoutWorkoutCard';
import ClientList from '../ClientWorkflow/ClientList'

function NewWorkoutClientPage(props: any) {

    let initWorkout: Workout_t = {
                    title: '',
                    id: '',
                    createdBy: {name: '', id: ''},
                    duration: 0,
                    imageName: '',
                    targetGender: '',
                    totalSets: 0,
                    totalReps: 0,
                    difficulty: 0
                };

    const [workout, setWorkout] = useState<Workout_t>(initWorkout);

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setWorkout(props.workout);
    }

    useEffect(() => {
        setWorkout(props.workout);
    }, [props.workout]);


    function clientSelected(client: Client_t) {
        props.clientSelected(client);
    }

	return (
		<div className="">
            <div hidden={props.workout.title === '' && props.workout.id === ''}>
			    <NewWorkoutWorkoutCard
                    workout={workout}/>
            </div>
            <div className="new-workout-workout-list-container">
                <ClientList 
                    personalClients={true}
                    showTitle={false}
                    isNewWorkout={true}
                    moreInfoSelected={(e: Client_t) => clientSelected(e)}
                    clientSelected={(e: Client_t) => clientSelected(e)}/>
            </div>
		</div>
	)
}

export default NewWorkoutClientPage;