import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './FilterSingle.css';

import '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';
import Filter from '@material-ui/icons/FilterList';


function FilterSingle(props: any) {

    const [options, setOptions] = useState([]);
    const [optionsSet, setOptionsSet] = useState(false);
    const [showList, setShowList] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);


    useEffect(() => {
        if (props.forceOpenState !== undefined) {
            setShowList(props.forceOpenState);
        }
    }, [props.forceOpenState]) 

    useEffect(() => {
        if (props.options !== undefined) {
            setOptions(props.options);
        }
    }, [props.options]) 

    
    if (optionsSet === false) {
        setOptionsSet(true);
        setOptions(props.options);
    }

    function toggleShowList() {
        setShowList(!showList);
    }

    function optionSelected(toOption: string) {
        for (var i = 0; i < options.length; i++) {
            if (toOption === options[i]) {
                setSelectedItemIndex(i);
                props.inputChanged(options[i]);
            }
        }

        setShowList(false);
    }

    function clearPressed() {
        if (selectedItemIndex !== 0) {
            optionSelected(props.options[0]);
        }
    }

    window.addEventListener('click', function(e){  
        if (document === null || document.getElementById(`${props.id}`) === null || document.getElementById(`${props.id}-button`) === null || e.target === null) { return; }

        if (document.getElementById(`${props.id}`)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(`${props.id}-button`)!.contains(e.target as Node))) {
                if (showList === true) {
                    setShowList(false);
                }
            }
        }
    });

    return (
        <div className="filter-single-input-container">
            <div id={`${props.id}-button`} onClick={() => toggleShowList()} className={props.alert === undefined || props.alert === false ? "filter-single-text-input-container" : "filter-single-text-input-container-alert"}>
                <div className="filter-single-menu-icon-container">
                    <Filter className="filter-single-menu-icon"/>
                </div>
                <div className="filter-single-text">
                    <h4>{props.title === undefined ? 'Filter' : props.title}</h4>
                </div>
                <div hidden={selectedItemIndex !== 0} className="filter-single-menu-count filter-single-menu-count-unhighlighted">
                    <p>0</p>
                </div>
                <div hidden={selectedItemIndex === 0} className="filter-single-menu-count filter-single-menu-count-highlighted">
                    <p>1</p>
                </div>
            </div>
            <CSSTransition in={showList} timeout={200} classNames="menu" unmountOnExit>
                <div id={`${props.id}`} className="filter-single-menu-container">
                    <div className="filter-single-menu-header-container">
                        <div onClick={() => clearPressed()} className="filter-single-menu-header-button filter-single-menu-header-button-dark">
                            <p>Clear</p>
                        </div>
                        <h4>{props.filterTitle !== undefined ? props.filterTitle : "Filter"}</h4>
                        <div onClick={() => toggleShowList()} className="filter-single-menu-header-button filter-single-menu-header-button-blue">
                            <p>Done</p>
                        </div>
                    </div>
                    <div className="filter-single-menu-body-container">
                        {options.map((item: any, index: number) => (
                            <div 
                                hidden={index === 0}
                                onClick={() => optionSelected(item)}
                                key={item} 
                                className={item === options[selectedItemIndex] ? "filter-single-menu-option-selected" : "filter-single-menu-option"}>
                                <div className={`filter-single-menu-option-box ${item === options[selectedItemIndex] ? 'filter-single-menu-option-box-selected' : 'filter-single-menu-option-box-unselected'}`}>

                                </div>
                                <p>{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}


export default FilterSingle;
