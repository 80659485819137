import React, { useState, useEffect } from 'react';
import './MyProfilePhonePreview.css';
import './MyProfileCardPreview.css';

import ColorScheme_t from '../../../../Interfaces/CoachingProfile/ColorScheme_t';
import InformationSection_t from '../../../../Interfaces/CoachingProfile/InformationSection_t';
import MyProfileBackground_t from '../../../../Interfaces/CoachingProfile/MyProfileBackground_t';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Battery30 from '@material-ui/icons/Battery30';
import SignalCellularAlt from '@material-ui/icons/SignalCellularAlt';
import SignalWifi3Bar from '@material-ui/icons/SignalWifi3Bar';
import Bookmark from '@material-ui/icons/Bookmark';
import School from '@material-ui/icons/School';
import EmojiEvents from '@material-ui/icons/EmojiEvents';


function MyProfilePhonePreview(props: any) {

    let defaultImageURL = "https://images.unsplash.com/photo-1620188467120-5042ed1eb5da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDJ8fHdvcmtvdXR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60";

    const [initSet, setInitSet] = useState(false);

    const [infoSections, setInfoSections] = useState<InformationSection_t[]>([]);
    const [headerImageURL, setHeaderImageURL] = useState("");
    const [studioImageURL, setStudioImageURL] = useState("");
    const [myCredentials, setMyCredentials] = useState("");
    const [acceptingClients, setAcceptingClients] = useState(false);

    const [colorScheme, setColorScheme] = useState<ColorScheme_t>({
        title: '',
        background: '#000000',
        primary: '#5B62FF',
        heading_text: '#000000',
        body_text: '#000000',
        button_text: '#000000'
    })
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.colorScheme !== undefined) {
            setColorScheme(props.colorScheme);
        }
    }, [props.colorScheme])

    useEffect(() => {
        if (props.infoSections !== undefined) {
            setInfoSections(props.infoSections);
        }
    }, [props.infoSections])

    useEffect(() => {
        if (props.myCredentials !== undefined) {
            setMyCredentials(props.myCredentials);
        }
    }, [props.myCredentials])

    useEffect(() => {
        if (props.headerImageURL !== undefined) {
            setHeaderImageURL(props.headerImageURL);
        }
    }, [props.headerImageURL])

    useEffect(() => {
        if (props.studioImageURL !== undefined) {
            setStudioImageURL(props.studioImageURL);
        }
    }, [props.studioImageURL])

    useEffect(() => {
        if (props.acceptingClients !== undefined) {
            setAcceptingClients(props.acceptingClients);
        }
    }, [props.acceptingClients])

    

    function getColorForType(type: 'background' | 'primary' | 'heading_text' | 'body_text' | 'button_text', rgb?: boolean, a?: number) {
        return rgb === undefined || rgb === false ? colorScheme[type] : hexToRgb(colorScheme[type], a === undefined ? 1 : a);
    }

    function hexToRgb(hex: string, a: number) {
        
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let rgbString = result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : `rgba(0, 0, 0, ${a})`

        // console.log("RESULT:",rgbString, result, hex, a);
        

        return rgbString;
    }

	return (
		<div style={{background: getColorForType('background'), borderColor: getColorForType('background')}} className="my-coaching-profile-preview-phone my-profile-card-preview">
            <div className="my-coaching-profile-preview-phone-content">
                <div className="my-coaching-profile-preview-phone-content-section my-coaching-profile-preview-phone-content-section-header">
                    <div hidden={studioImageURL === ""} className="my-coaching-profile-preview-phone-content-section-header-studio-img-container">
                        <img className="" src={studioImageURL}/>
                    </div>
                    <div hidden={!acceptingClients} style={{background: getColorForType('background', true, 0.4)}} className="my-coaching-profile-preview-card-content-section-header-studio-accepting-clients-tag my-coaching-profile-preview-phone-content-section-header-studio-accepting-clients-tag my-coaching-profile-preview-phone-content-section-header-text-title-container">
                        <Check style={{color: getColorForType('primary')}} className="my-coaching-profile-preview-phone-content-section-header-studio-accepting-clients-tag-icon"/>
                        <p style={{color: getColorForType('primary')}}>Accepting new Athletes</p>
                    </div>
                    <div className="my-coaching-profile-preview-phone-content-section-header-img-container">
                        <div style={{background: `linear-gradient(180deg, ${getColorForType('background', true, 0)} 20%, ${getColorForType('background', true, 1)} 70%)`}} className="my-coaching-profile-preview-phone-content-section-header-img-overlay"></div>
                        <img className="" src={headerImageURL === "" ? defaultImageURL : headerImageURL}/>
                    </div>
                    <div className="my-coaching-profile-preview-phone-content-section-header-text-container">
                        <h4 style={{color: getColorForType('primary')}}>{props.gymName}</h4>
                        <h1 style={{color: getColorForType('heading_text')}}>{props.trainerName_f}<br/>{props.trainerName_l}</h1>
                        <div style={{background: getColorForType('heading_text', true, 0.2)}} className="my-coaching-profile-preview-phone-content-section-header-text-title-container">
                            <p style={{color: getColorForType('heading_text')}}>{myCredentials === "" ? "Certified Personal Trainer" : myCredentials}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default MyProfilePhonePreview;