import React, { useState } from 'react';
import firebase from '../../../../Firebase';
import './WorkoutsMoreLoadingPatterns.css';

import LoadingPattern_t from '../../../../Interfaces/LoadingPattern_t';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';

function WorkoutsMoreLoadingPatterns(props: any) {

    let poliquin_LUT: number[] = [100, 100, 94.3, 90.6, 90.6, 88.1, 85.6, 83.1, 80.7, 78.6, 76.5, 74.4, 72.3, 70.3, 68.8, 67.5, 66.2, 65, 63.8, 62.7, 61.6, 60.6];
    let standard_LUT: number[] = [100, 100, 95, 93, 90, 87, 85, 83, 80, 77, 75, 70, 67, 66, 65, 65, 60]

    const [initSet, setInitSet] = useState(false);
    const [loadingPatterns, setLoadingPatterns] = useState<LoadingPattern_t[]>([]);
    const [loadingPatternTypes, setLoadingPatternTypes] = useState<string[]>(["All", 
                                                                              "Relative Strength", 
                                                                              "Absolute Strength", 
                                                                              "Functional Hypertrophy", 
                                                                              "Hypertrophy"]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullLoadingPatterns();
    }

    function pullLoadingPatterns() {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`loading_patterns/${trainerUID}`);
        // ref.on('value', function(snapshot) {
        //     if (snapshot.exists() === true) {
        //         let data: any = snapshot.val();
        //         let keys = Object.keys(data);
        //         for (var i = 0; i < keys.length; i++) {
        //             let thisKey = keys[i];
        //             let thisObj: any = data[thisKey];

        //         }
        //     }
        // });

        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data: any = snapshot.val();
                // console.log("DATA:", data);
                let keys: string[] = Object.keys(data);

                // 1. Compose category list
                var categoryList: string[] = ["All",
                                              "Relative Strength", 
                                              "Absolute Strength", 
                                              "Functional Hypertrophy", 
                                              "Hypertrophy"];

                for (var i = 0; i < keys.length; i++) {
                    let thisKey: string = keys[i];
                    let thisLPObj: any = data[thisKey];
                    
                    if (thisLPObj !== undefined && thisLPObj.category !== undefined) {
                        if (categoryList.indexOf(thisLPObj.category) < 0) {
                            categoryList.push(thisLPObj.category);
                        }
                    }                    
                }

                setLoadingPatternTypes(categoryList);
                
                // 2. Compose loading pattern List
                var loadingPatterns_temp: LoadingPattern_t[] = [];
                for (var i = 0; i < keys.length; i++) {
                    let thisKey: string = keys[i];
                    let thisLPObj: any = data[thisKey];
                    
                    var categoryIndex = 0;
                    if (thisLPObj !== undefined && thisLPObj.category !== undefined) {
                        categoryIndex = categoryList.indexOf(thisLPObj.category) - 1;
                    }      

                    // Compose set list
                    var setList: any[] = [];
                    if (thisLPObj !== undefined && thisLPObj.reps !== undefined) {
                        for (var j = 0; j < thisLPObj.reps.length; j++) {
                            let thisRep: number = thisLPObj.reps[j];
                            var thisRepPWM = 0;
                            if (thisLPObj.pwms !== undefined && thisLPObj.pwms[j] !== undefined) {
                                thisRepPWM = thisLPObj.pwms[j]
                            } else {
                                thisRepPWM = thisRep < standard_LUT.length && thisRep >= 0 ? standard_LUT[thisRep] : 50;
                            }
                            setList.push({
                                reps: thisRep,
                                percentWM: thisRepPWM
                            })
                        }
                    }

                    let thisLoadingPattern: LoadingPattern_t = {
                        title: thisLPObj.title !== undefined ? thisLPObj.title : "Custom loading pattern",
                        type: categoryIndex,
                        sets: setList,
                        id: thisKey
                    }

                    loadingPatterns_temp.push(thisLoadingPattern);

                }

                setLoadingPatterns(loadingPatterns_temp);

                //// console.log("List:", categoryList);
                //setCategories(categoryList);
                //setIsLoading(false);
            } else {
                //setIsLoading(false);
            }
        });

    }

    function getVolumeString(forPattern: LoadingPattern_t) {
        if (forPattern.sets === undefined || forPattern.sets.length === 0) {
            return `0 sets, 0 reps`;
        }

        var vol_reps = 0;
        var vol_sets = 0;
        for (var i = 0; i < forPattern.sets.length; i++) {
            vol_sets += 1;
            vol_reps += forPattern.sets[i].reps === undefined ? 0 : forPattern.sets[i].reps;
        }

        return `${vol_sets} sets, ${vol_reps} reps`
    }

    function requestDeletePattern(item: LoadingPattern_t) {
        //setLoadingPatternToDelete(item.id === undefined ? "" : item.id);
        props.getUserConfirmation({
            title: `Are you sure you want to remove your loading pattern, '${item.title}'?`,
            text: "This action cannot be undone",
            isRed: true,
            callback: deleteLoadingPatternConfirmed,
            ctaText: "Remove",
            callbackParam: item.id === undefined ? "" : item.id
        });
    }

    function deleteLoadingPatternConfirmed(id: string) {
        // console.log("deleteLoadingPatternConfirmed:", id);
        if (id === undefined || id === "") { return; }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`loading_patterns/${trainerUID}/${id}`);
        ref.set(null);

        //setLoadingPatternToDelete("");
    }

	return (
		<div className="workouts-more-loading-patterns">
            <div onClick={() => props.createNewLoadingPattern()} className="workouts-more-loading-patterns-button">
                <Add className="workouts-more-loading-patterns-button-icon"/>
                <p>New loading pattern</p>
            </div>
			<div className="workouts-more-loading-patterns-header-row">
                <h4>Saved Loading Patterns</h4>
                <p>({loadingPatterns.length})</p>
            </div>
            <div className="workouts-more-loading-patterns-content">
                {
                    loadingPatterns.map((item: LoadingPattern_t, index: number) => (
                        <div className="workouts-more-loading-patterns-content-row">
                            <div className="workouts-more-loading-patterns-content-col workouts-more-loading-patterns-content-col-name">
                                <h4>{item.title}</h4>
                            </div>
                            <div className="workouts-more-loading-patterns-content-col workouts-more-loading-patterns-content-col-type">
                                <p>{loadingPatternTypes[item.type + 1] !== undefined ? loadingPatternTypes[item.type + 1] : "Unspecified"}</p>
                            </div>
                            <div className="workouts-more-loading-patterns-content-col workouts-more-loading-patterns-content-col-reps">
                                <div className="workouts-more-loading-patterns-content-col-reps-container">
                                    {
                                        item.sets === undefined ? null : item.sets.map((item_set: any, index_set: number) => (
                                            <div className="workouts-more-loading-patterns-content-col-reps-container-inner">
                                                <p>{item_set.reps === undefined ? "" : item_set.reps}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="workouts-more-loading-patterns-content-col-reps-volume-container">
                                    <p>({getVolumeString(item)})</p>
                                </div>
                            </div>
                            <div onClick={() => requestDeletePattern(item)} className="workouts-more-loading-patterns-content-col workouts-more-loading-patterns-content-col-close">
                                <Close className="workouts-more-loading-patterns-content-col-close-icon"/>
                            </div>
                        </div>
                    ))
                }
            </div>
		</div>
	)
}

export default WorkoutsMoreLoadingPatterns;