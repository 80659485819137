import React, { useState, useEffect } from 'react';
import './Progress.css';
import './common.css';

import ExecutiveSummaryCard from '../components/Cards/ExecutiveSummaryCard';
import GoalCard from '../components/Cards/GoalCard';
import GoalLogCard from '../components/Cards/GoalLogCard';

function Progress(props: any) {

    
    const [scoringMetrics, setScoringMetrics] = useState<any[]>([]);
    const [logData, setLogData] = useState<any>();
    const [goalData, setGoalData] = useState<any>();
    const [totalScore, setTotalScore] = useState(0);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize()
    }, [initSet])

    useEffect(() => {
        generateScoringMetrics();
    }, [props.logData, props.goalData])
    

    const initialize = () => {

        generateScoringMetrics();



        setScoringMetrics([
                {
                    title: "Eccentric Control",
                    status: "good",
                    measurement_string: "1.24x Concentric Velocity",
                    target_range_string: "Above 1.2x",
                    score: 8.5
                },
                {
                    title: "Concentric Velocity",
                    status: "poor",
                    measurement_string: "1.04 m/s Average",
                    target_range_string: "1.1m/s – 1.4m/s",
                    score: 4.0
                }
            ])
    }

    const generateScoringMetrics = () => {
        let workoutCompletion = getWorkoutCompletion();
        let workoutsPerWeek = getWorkoutsPerWeek();
        let muscleDiversity = getMuscleDiversity();
        let avgROM = getROM();
        let tempoRatio = getTempoRatio();
        let goalProgressData: { completion: number, targetCompletion: number } | undefined = getGoalProgress();
        let concentricVelocity = getConcentricVelocity();

        let workoutCompletion_score = getFormattedScore(workoutCompletion);
        let workoutsPerWeek_score = getFormattedScore(workoutsPerWeek / 4);
        let muscleDiversity_score = getFormattedScore(1 - (2 * muscleDiversity));
        let avgROM_score = getFormattedScore(avgROM);
        let tempoRatio_score = getFormattedScore(tempoRatio / 1.2);
        let concentricVelocity_score = getFormattedScore(concentricVelocity < 1.1 ? (concentricVelocity / 1.1) : concentricVelocity > 1.4 ? (1.4 - (concentricVelocity)) : 1);

        let goalProgressPercent = 0;
        let goalProgressTarget = 0;
        if (goalProgressData) {
            goalProgressPercent = goalProgressData.completion;
            goalProgressTarget = goalProgressData.targetCompletion;
        }
        let goalCompletion_score = getFormattedScore(goalProgressTarget === 0 ? 0 : goalProgressPercent / goalProgressTarget)

        let scoringMetricList = [
                {
                    title: "Goal Completion",
                    status: getStatusString(goalCompletion_score),
                    measurement_string: `${goalProgressPercent}% Complete`,
                    target_range_string: `${goalProgressTarget}% Completion`,
                    score: goalCompletion_score,
                    weight: 10
                },
                {
                    title: "Workout Completion",
                    status: getStatusString(workoutCompletion_score),
                    measurement_string: `${workoutCompletion_score * 10}% Complete`,
                    target_range_string: "100% Completion",
                    score: workoutCompletion_score,
                    weight: 10
                },
                {
                    title: "Workouts per Week",
                    status: getStatusString(workoutsPerWeek_score),
                    measurement_string: `${Math.round(workoutsPerWeek)} per week`,
                    target_range_string: "Above 4 / week",
                    score: workoutsPerWeek_score,
                    weight: 8
                },
                {
                    title: "Muscle Diversity",
                    status: getStatusString(muscleDiversity_score),
                    measurement_string: `${Math.floor(muscleDiversity * 100)}% Deviation`,
                    target_range_string: "Below 10%",
                    score: muscleDiversity_score,
                    weight: 10
                },
                {
                    title: "Range of Motion",
                    status: getStatusString(avgROM_score),
                    measurement_string: `${Math.floor(avgROM * 100)}% ROM`,
                    target_range_string: "100% ROM",
                    score: avgROM_score,
                    weight: 10
                },
                {
                    title: "Eccentric Control",
                    status: getStatusString(tempoRatio_score),
                    measurement_string: `${Math.floor(tempoRatio * 100) / 100}x Concentric Velocity`,
                    target_range_string: "Above 1.2x",
                    score: tempoRatio_score,
                    weight: 6
                },
                {
                    title: "Concentric Velocity",
                    status: getStatusString(concentricVelocity_score),
                    measurement_string: `${Math.floor(concentricVelocity * 100) / 100} m/s Average`,
                    target_range_string: "1.1m/s – 1.4m/s",
                    score: concentricVelocity_score,
                    weight: 6
                }
            ]
        setScoringMetrics(scoringMetricList);

        let scoreWeightedTotalSum = 0
        for (var i = 0; i < scoringMetricList.length; i++) scoreWeightedTotalSum += scoringMetricList[i].weight;

        let scoreWeightedSum = 0;
        let maxScore = 10 * scoringMetricList.length;
        for (var i = 0; i < scoringMetricList.length; i++) {
            let thisMetric = scoringMetricList[i];
            //let thisWeightRelative = thisMetric.weight / scoreWeightedTotalSum;
            let thisWeightedScore = thisMetric.score * thisMetric.weight;
            scoreWeightedSum += thisWeightedScore;
        }

        // console.log("SCORE:", scoreWeightedTotalSum, maxScore, scoreWeightedSum)

        setTotalScore(Math.floor( scoreWeightedSum / scoreWeightedTotalSum * 10 ) / 10);
    }

    const getStatusString = (score: number) => {
        return score >= 9.5 ? "perfect" : score > 8 ? "good" : score > 6 ? "okay" : "poor"
    }

    const getFormattedScore = (val: number) => {
        let valTemp = Math.floor(val * 20) / 2;
        return valTemp > 10 ? 10 : valTemp < 0 ? 0 : valTemp;
    }

    const getWorkoutCompletion = () => {
        if (!props.logData) return 0;

        var totalVolSum = 0;
        var completedVolSum = 0;

        for (var i = 0; i < props.logData.length; i++) {
            let thisLogObj = props.logData[i];
            if (thisLogObj && thisLogObj.exercise_series) {
                for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                    let thisSeries = thisLogObj.exercise_series[j];
                    if (thisSeries && thisSeries.exercises) {
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise = thisSeries.exercises[k];

                            var benchmarkROM = 70;
                            if (thisExercise && thisExercise.data && thisExercise.data.benchmark && thisExercise.data.benchmark.rom) {
                                benchmarkROM = thisExercise.data.benchmark.rom
                            } 

                            if (thisExercise && thisExercise.sets) {
                                for (var s = 0; s < thisExercise.sets.length; s++) {
                                    let thisSetObj = thisExercise.sets[s];
                                    if (thisSetObj) {
                                        totalVolSum += 1;
                                        if (thisSetObj.is_complete !== undefined && thisSetObj.is_complete === true) {
                                            completedVolSum += 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        
        return totalVolSum === 0 ? 0 : completedVolSum / totalVolSum;
    }

    const getWorkoutCompletion_old = () => {
        if (!props.logData) return 0;

        var numWorkouts = 0;
        var numCompletedWorkouts = 0;

        for (var i = 0; i < props.logData.length; i++) {
            let thisDayLog = props.logData[i];
            if (thisDayLog) {

                numWorkouts += 1;
                numCompletedWorkouts += thisDayLog.ending_timestamp !== undefined && thisDayLog.ending_timestamp > 0 ? 1 : 0;
            }
        }

        let res = numWorkouts === 0 ? 0 : numCompletedWorkouts / numWorkouts;
        return res;
    }

    const getWorkoutsPerWeek = () => {
        if (!props.logData) return 0;

        var numWorkouts = 0;

        for (var i = 0; i < props.logData.length; i++) {
            let thisDayLog = props.logData[i];
            if (thisDayLog) {
                if (thisDayLog && thisDayLog.starting_timestamp) numWorkouts += 1;
            }
        }

        let totalDays = props.logData.length;
        let res = totalDays === 0 ? 0 : numWorkouts / (Math.floor(totalDays / 7));
        return res;
    }

    const getMuscleDiversity = () => {
        if (!props.logData) return 0;

        var volumeBucketsSets: number[] = [0,0,0,0,0,0,0];
        var volumeBucketsReps: number[] = [0,0,0,0,0,0,0];

        for (var i = 0; i < props.logData.length; i++) {
            let thisLogObj = props.logData[i];
            if (thisLogObj && thisLogObj.exercise_series) {
                for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                    let thisSeries = thisLogObj.exercise_series[j];
                    if (thisSeries && thisSeries.exercises) {
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise = thisSeries.exercises[k];

                            // 1. Generate set and rep volume
                            var volumeSets = 0;
                            var volumeReps = 0;
                            if (thisExercise && thisExercise.sets) {
                                for (var s = 0; s < thisExercise.sets.length; s++) {
                                    let thisSetObj = thisExercise.sets[s];
                                    if (thisSetObj) {
                                        if (thisSetObj.is_complete !== undefined && thisSetObj.is_complete === true) {
                                            volumeSets += 1;
                                            volumeReps += thisSetObj.reps_completed ?? 0
                                        }
                                    }
                                }
                            }

                            if (thisExercise && thisExercise.data && thisExercise.data.muscles) {
                                for (var m = 0; m < thisExercise.data.muscles.length; m++) {
                                    let thisMuscle = thisExercise.data.muscles[m];
                                    if (thisMuscle && thisMuscle.group && thisMuscle.group > 0 && thisMuscle.group < volumeBucketsReps.length) {
                                        volumeBucketsSets[thisMuscle.group] = volumeBucketsSets[thisMuscle.group] + volumeSets;
                                        volumeBucketsReps[thisMuscle.group] = volumeBucketsReps[thisMuscle.group] + volumeReps;
                                    }
                                }
                            } 
                        }
                    }
                }
            }
        }

        // Generate deviation from set volume
        var setVolSum = 0;
        for (var i = 0; i < volumeBucketsSets.length; i++) setVolSum += volumeBucketsSets[i];

        var percentOfFullArray: number[] = [];
        for (var i = 0; i < volumeBucketsSets.length; i++) {
            percentOfFullArray.push(setVolSum === 0 ? 0 : volumeBucketsSets[i] / setVolSum);
        }

        let maxPercent = Math.max(...percentOfFullArray);
        let minPercent = Math.min(...percentOfFullArray);
        let diff = maxPercent - minPercent;

        //// console.log("VOLUME:", volumeBucketsSets, volumeBucketsReps, maxPercent, minPercent, diff);
        return maxPercent === 0 ? 1 : diff;
    }

    const getROM = () => {
        if (!props.logData) return 0;

        var romSum = 0;
        var romCnt = 0;

        for (var i = 0; i < props.logData.length; i++) {
            let thisLogObj = props.logData[i];
            if (thisLogObj && thisLogObj.exercise_series) {
                for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                    let thisSeries = thisLogObj.exercise_series[j];
                    if (thisSeries && thisSeries.exercises) {
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise = thisSeries.exercises[k];

                            var benchmarkROM = 70;
                            if (thisExercise && thisExercise.data && thisExercise.data.benchmark && thisExercise.data.benchmark.rom) {
                                benchmarkROM = thisExercise.data.benchmark.rom
                            } 

                            if (thisExercise && thisExercise.sets) {
                                for (var s = 0; s < thisExercise.sets.length; s++) {
                                    let thisSetObj = thisExercise.sets[s];
                                    if (thisSetObj) {
                                        if (thisSetObj.is_complete !== undefined && thisSetObj.is_complete === true) {
                                            let thisROM = thisSetObj.ROM ?? 0;
                                            if (thisROM > 0) {
                                                let percentROM = thisROM / (benchmarkROM === 0 ? 70 : benchmarkROM)
                                                romSum += percentROM;
                                                romCnt += 1
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        
        return romCnt === 0 ? 0 : romSum / romCnt;
    }

    const getTempoRatio = () => {
        if (!props.logData) return 0;

        var concentricSum = 0;
        var eccentrySum = 0;
        //var tempoCnt = 0;

        for (var i = 0; i < props.logData.length; i++) {
            let thisLogObj = props.logData[i];
            if (thisLogObj && thisLogObj.exercise_series) {
                for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                    let thisSeries = thisLogObj.exercise_series[j];
                    if (thisSeries && thisSeries.exercises) {
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise = thisSeries.exercises[k];

                            var benchmarkROM = 70;
                            if (thisExercise && thisExercise.data && thisExercise.data.benchmark && thisExercise.data.benchmark.rom) {
                                benchmarkROM = thisExercise.data.benchmark.rom
                            } 

                            if (thisExercise && thisExercise.sets) {
                                for (var s = 0; s < thisExercise.sets.length; s++) {
                                    let thisSetObj = thisExercise.sets[s];
                                    if (thisSetObj) {
                                        if (thisSetObj.is_complete !== undefined && thisSetObj.is_complete === true) {
                                            let thisTempo = thisSetObj.tempo;
                                            if (thisTempo && thisTempo.concentric && thisTempo.eccentric && thisTempo.concentric > 0 && thisTempo.eccentric > 0) {
                                                concentricSum += thisTempo.concentric;
                                                eccentrySum += thisTempo.eccentric;

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        
        return concentricSum === 0 ? 0 : eccentrySum / concentricSum;
    }

    const getConcentricVelocity = () => {
        if (!props.logData) return 0;

        var velSum = 0;
        var velCnt = 0;

        for (var i = 0; i < props.logData.length; i++) {
            let thisLogObj = props.logData[i];
            if (thisLogObj && thisLogObj.exercise_series) {
                for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                    let thisSeries = thisLogObj.exercise_series[j];
                    if (thisSeries && thisSeries.exercises) {
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise = thisSeries.exercises[k];

                            var benchmarkROM = 70;
                            if (thisExercise && thisExercise.data && thisExercise.data.benchmark && thisExercise.data.benchmark.rom) {
                                benchmarkROM = thisExercise.data.benchmark.rom
                            } 

                            if (thisExercise && thisExercise.sets) {
                                for (var s = 0; s < thisExercise.sets.length; s++) {
                                    let thisSetObj = thisExercise.sets[s];
                                    if (thisSetObj && thisSetObj.is_complete !== undefined && thisSetObj.is_complete === true && thisSetObj.rep_stats && thisSetObj.rep_stats.length > 0) {
                                        for (var r = 0; r < thisSetObj.rep_stats.length; r++) {
                                            let thisRepStat = thisSetObj.rep_stats[r];
                                            if (thisRepStat && thisRepStat.peakVelocity && thisRepStat.peakVelocity > 0) {
                                                velSum += thisRepStat.peakVelocity;
                                                velCnt += 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        
        return velCnt === 0 ? 0 : velSum / velCnt;
    }

    const getGoalProgress = () => {
        if (!props.goalData) return undefined;

        let goalOverviewData = props.goalData.goal;
        let goalLogData = props.goalData.logs;

        if (!goalOverviewData || !goalLogData || goalLogData.length < 1) return undefined;

        let metricTarget = goalOverviewData.target_metric ?? 0;
        let timeStart = goalOverviewData.starting_timestamp;
        let timeEnd = goalOverviewData.target_timestamp;
        let dt = timeEnd - timeStart;
        let m = 1 / dt;

        // Get first and latest logs
        let firstLog = goalLogData[0]
        let latestLog = goalLogData[goalLogData.length - 1];

        let firstLogMetric = firstLog ? firstLog.metric : 0;
        let lateststLogMetric = latestLog ? latestLog.metric : 0;

        let d_metric_target = Math.abs(metricTarget - firstLogMetric);
        let d_metric_achieved = Math.abs(metricTarget - lateststLogMetric);
        let percentCompleteTemp = d_metric_achieved / d_metric_target;
        

        // Check if above or behind schedule
        let progressBuffer = 0.02;
        let latestLogTime = latestLog.timestamp;
        let dt_latest = latestLogTime - timeStart;
        let targetProgress = m * dt_latest;
        let targetProgress_min = targetProgress - progressBuffer;
        let targetProgress_max = targetProgress + progressBuffer;
        // console.log("TARGET:", targetProgress)

        return { completion: Math.round(percentCompleteTemp * 100), targetCompletion: Math.round(targetProgress * 100)  }
    }



	return (
		<div className={ "performance-analysis-page" }>
			<div className={ "performance-analysis-data-content-body-row" }>

                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                    <GoalCard  
                        logData={ props.logData }
                        goalData={ props.goalData }
                        clientName={ props.clientName }
                        hideShowMore={ true }
                        openGoalsPressed={ () => props.openGoalsPressed() }/>
                </div>
            </div>
            <div className={ "performance-analysis-data-content-body-row" }>
                <div className={ "performance-analysis-data-content-card-container performance-analysis-data-content-card-container-lg" }>
                    <GoalLogCard  
                        scoringMetrics={ scoringMetrics }
                        logData={ props.logData }
                        goalData={ props.goalData }
                        clientName={ props.clientName }
                        openPerformancePressed={ () => props.openPerformancePressed() }/>
                </div>
            </div>

		</div>
	)
}

export default Progress;