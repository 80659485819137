import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';

import './activityFeedRow.css';

import ActivityFeedItem_t from '../../Interfaces/ActivityFeedItem_t';

import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';


function ActivityFeedRow(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [item, setItem] = useState<ActivityFeedItem_t | undefined>(undefined);
    const [imgUrl, setImgUrl] = useState('');

    const [clientName, setClientName] = useState("");
    const [clientInitials, setClientInitials] = useState("");

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            initialize();
        }
    }, [initSet])

    const initialize = () => {
        setItem(props.item);
    }

    useEffect(() => {
        if (item) {
            getImage(item.client_id);
            configClientName(item.client_id);
        }
    }, [item])

    const getImage = (forID: string) => {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    const configClientName = async (client_id: string) => {
        if (!client_id || client_id === "") return;
        try {
            let database = firebase.database();
            let snapshot_log = await database.ref(`user_profile/${client_id}/full_name`).once('value');
            let data_log = snapshot_log.exists() ? snapshot_log.val() : "";
            
            setClientName(data_log);

            if (data_log && data_log !== "") {
                let names = data_log.split(" ");
                if (names && names.length > 1) {
                    let fName = names[0];
                    let lName = names[names.length - 1];

                    let fName_letters = fName.split("");
                    let fName_fLetter = fName_letters && fName_letters.length > 0 ? fName_letters[0] : "";

                    let lName_letters = lName.split("");
                    let lName_fLetter = lName_letters && lName_letters.length > 0 ? lName_letters[0] : "";

                    let initials = `${fName_fLetter}${lName_fLetter}`
                    setClientInitials(initials);
                }
            }

        } catch (err) {
        
        }
    }

    const getGoalMetric = () => {
        if (!item || item.type !== "goal_log" || !item.data || !item.data.logs || item.data.logs.length === 0) return 0;
        let logs = item.data.logs;
        let logKeys = Object.keys(logs);
        let lastLogKey = logKeys[logKeys.length - 1];
        let lastLog = logs[lastLogKey]
        return lastLog && lastLog.metric ? lastLog.metric : 0
    }

    const getTimeString = () => {

        let ts = item && item.timestamp && item.timestamp > 0 ? item.timestamp : Date.now();
        let thisDate = new Date(ts);
        let thisHr = thisDate.getHours();
        let thisHrAdj = thisHr % 12;
        thisHrAdj = thisHrAdj === 0 ? 12 : thisHrAdj;
        let thisMin = thisDate.getMinutes();

        return `${thisHrAdj < 10 ? '0' : ''}${thisHrAdj}:${thisMin < 10 ? '0' : ''}${thisMin}${thisHr >= 12 ? 'pm' : 'am'}`
    }

    const getWorkoutComplete = () => {
        return item && item.data && item.data.ending_timestamp && item.data.ending_timestamp > 0
    }

    const openInDynamicTrain = () => {
        if (!item || !item.client_id) return;
        props.openClientInDynamicTrain(item.client_id)
    }

    const openClientProfile = () => {
        if (!item || !item.client_id) return;
        props.openClientProfile(item.client_id)
    }

    useEffect(() => {
        setItem(props.item);
    }, [props.item])

	return (
		<div className={ "activity-feed-row" }>
			<div className={ "activity-feed-row-left" }>
                <div className={ "activity-feed-row-left-image-container" }>
                    { imgUrl !== "" && <img className="activity-feed-row-left-image" src={ imgUrl }/> }
                    <h4>{clientInitials}</h4>
                </div>
                <div className={ "activity-feed-row-left-line" }/>
            </div>
            <div className={ "activity-feed-row-right" }>
                <div className={ "activity-feed-row-right-upper" }>
                    {
                        item && item.type === "workout" ?
                            <h4><span className={ "activity-feed-row-name-text" } onClick={ () => openClientProfile() }>{ clientName }</span> has { getWorkoutComplete() ? "completed" : "started" } their <span>{ item && item.data && item.data.title ? item.data.title : "unnamed"  }</span> workout. { getWorkoutComplete() ? "🎉" : "" }</h4>
                        : item && item.type === "workout_missed" ?
                            <h4><span className={ "activity-feed-row-name-text" } onClick={ () => openClientProfile() }>{ clientName }</span> has missed their <span>{ item && item.data && item.data.title ? item.data.title : "unnamed"  }</span> workout.</h4>
                        : item && item.type === "goal_log" ?
                            <h4><span className={ "activity-feed-row-name-text" } onClick={ () => openClientProfile() }>{ clientName }</span> has updated their goal log to <span>{ getGoalMetric() }LBS</span></h4>
                        : 
                            <h4></h4>
                    }
                </div>
                <div className={ "activity-feed-row-right-lower" }>
                    <div className={ "activity-feed-row-right-lower-left" }>
                        {
                            item && item.type === "workout" ?
                                <div className={ "activity-feed-row-status activity-feed-row-status-workout" }>
                                    <Check className={ "activity-feed-row-status-icon activity-feed-row-status-icon-workout" }/>
                                    <p>{ getWorkoutComplete() ? "Workout Complete" : "Workout Started" }</p>
                                </div>
                            : item && item.type === "workout_missed" ?
                                <div className={ "activity-feed-row-status activity-feed-row-status-workout-missed" }>
                                    <Close className={ "activity-feed-row-status-icon activity-feed-row-status-icon-workout-missed" }/>
                                    <p>Workout Missed</p>
                                </div>
                            : item && item.type === "goal_log" ?
                                <div className={ "activity-feed-row-status activity-feed-row-status-log" }>
                                    <Add className={ "activity-feed-row-status-icon activity-feed-row-status-icon-log" }/>
                                    <p>New Log</p>
                                </div>
                            : 
                                <div/>
                        }
                        <div className={ "activity-feed-row-right-lower-left-time-container" }>
                            <p>{ item && item.type === "workout_missed" ? "" : getTimeString() }</p>
                        </div>
                    </div>
                    <div className={ "activity-feed-row-right-lower-btn-container" }>
                       <div className={ "activity-feed-row-right-lower-btn" } onClick={ () => openInDynamicTrain() }>
                            <p>Open in DynamicTrain</p>
                            <ArrowForward className={ "activity-feed-row-right-lower-btn-icon" }/>
                        </div> 
                    </div>
                </div>
            </div>
		</div>
	)
}

export default ActivityFeedRow;