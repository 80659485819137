import React, { useState } from 'react';
import firebase from '../../Firebase';
import axios from 'axios';

import Client_t from '../../Interfaces/Client_t';
import Note_t from '../../Interfaces/Note_t';

import './ClientProfileStats.css';
import './ClientProfileCommon.css'

import ClientProfileStatsCard from './ClientProfileStatsCard';

import '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

function ClientProfileStats(props: any) {

    const useDemoStats = true;
    let demoStats_lift = [12234,13405,12100,16789,12343,11092,12322]
    let demoStats_power = [224,300,180,145,212,198,260]
    let demoStats_form = [45,60,55,50,45,47,46]
    let demoStats_rest = [45,60,55,50,45,47,46]
    let demoStats_rom = [0.97,1.02,0.92,0.94,0.96,0.95,0.65]
    let demoStats_repweightdist = [{x: 12, y: 30},{x: 8, y: 35},{x: 6, y: 40},{x: 6, y: 50},{x: 16, y: 135},{x: 12, y: 185},{x: 3, y: 225},{x: 6, y: 205},{x: 16, y: 155},{x: 20, y: 20},{x: 16, y: 35},{x: 12, y: 40}]

	let noteTypes = ['General','Form Improvement', 'Stretch', 'Injury Risk', 'Other'];
	let noteTypeColours = ['193,73,193','73,89,193','73,193,89','193,73,89','255,255,255'];

	let testNote = {
		title: "My first note",
		id: "abc-123",
		createdBy: {name: "Johnny Appleseed", "id": "dnfjdnjsf"},
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		time_stamp: 1630489554,
		client_id: "LRAYWIcH4IOUb7zzLWIWA6G1VdE2",
		type: 0,
		trainer_id: "3fCdlRTmfWSuozlTNHFoou8c1LU2",
		// tags: {},
		remind_me_next_time: true,
		seen_by: ["LRAYWIcH4IOUb7zzLWIWA6G1VdE2","3fCdlRTmfWSuozlTNHFoou8c1LU2"]
	};

	const [notesList, setNotesList] = useState<Note_t[]>([]);
	const [initSet, setInitSet] = useState(false);

	const [loadingData, setLoadingData] = useState(true);
    const [weeklyWorkouts, setWeeklyWorkouts] = useState(0);
    const [weeklyTotalLift, setWeeklyTotalLift] = useState(0);
    const [timeSpentLifting, setTimeSpentLifting] = useState(0);
    const [weeklyAvgROM, setWeeklyAvgROM] = useState(0);
    const [selectedDaysIndex, setSelectedDaysIndex] = useState(0);
    
    const [chartLiftData, setChartLiftData] = useState<number[]>([]);
    const [chartPowerData, setChartPowerData] = useState<number[]>([]);
    const [chartRestData, setChartRestData] = useState<number[]>([]);
    const [chartROMData, setChartROMData] = useState<number[]>([]);
    const [chartFormData, setChartFormData] = useState<number[]>([]);
    const [chartMaxWeightData, setChartMaxWeightData] = useState<number[]>([]);
    const [chartRepWeightDist, setChartRepWeightDist] = useState<any[]>([]);

    let selectedDaysNumbers = [7,14,30,180,365];
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.client === null || props.client === undefined) { return; }
    	getSummaryStats(7);
    }

	function addNotePressed() {
		props.addNotePressed();
	}

	function getSummaryStats(withDaysToShow: number) {

        if (useDemoStats) {
            setChartLiftData(demoStats_lift);
            setChartPowerData(demoStats_power);
            setChartMaxWeightData(demoStats_lift);
            setChartFormData(demoStats_form);
            setChartRestData(demoStats_rest);
            setChartROMData(demoStats_rom);
            setChartRepWeightDist(demoStats_repweightdist);
            setTimeSpentLifting(0.45);

            setTimeout(() => {
                setLoadingData(false);
            }, 500);
            return;
        }

        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }    // exit if no user ID is found

        let sendData = {
            user_id: props.client.id,
            days_to_show: withDaysToShow
        };

        // console.log(`--> USER ID: ${thisUser.uid}`);

        setLoadingData(true);
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getAllStats`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            let data = response.data;
            if (data !== undefined && data.error === 0) {
                let results = data.results;
                // console.log(`GET ALL STATS RESULTS:`);
                // console.log(results);
                setWeeklyWorkouts(results.weekly_workouts);
                setWeeklyTotalLift(results.weekly_lift);

                let liftStats = results.lift_stats !== undefined ? results.lift_stats : [];
                let powerStats = results.power_stats !== undefined ? results.power_stats : [];
                let maxWeightStats = results.maximum_weight !== undefined ? results.maximum_weight : [];
                let formStats = results.form_accuracy_stats !== undefined ? results.form_accuracy_stats : [];
                var restStats = results.rest_time_stats !== undefined ? results.rest_time_stats : [];
                let ROMStats = results.ROM_stats !== undefined ? results.ROM_stats : [];
                let repWeightDistStats = results.rep_weight_distribution !== undefined ? results.rep_weight_distribution : [];
                let totalLiftingTimeStats = results.total_lift_time_stats !== undefined ? results.total_lift_time_stats : [];

                // Filter data
                if (restStats.length > 0) {
                    for (var i = 0; i < restStats.length; i++) {
                        restStats[i] = restStats[i] < 0 ? 0 : (restStats[i] > 1000000 ? 0 : restStats[i]);
                    }
                }

                if (withDaysToShow === 7) {
                    var avgROM = 0;
                    if (ROMStats.length > 0) {
                        for (var i = 0; i < ROMStats.length; i++) {
                            avgROM += ROMStats[i];
                        }
                        avgROM /= ROMStats.length;
                    }
                    setWeeklyAvgROM(avgROM);
                }

                // calculate avg time spent lifting
                var avgTimeLifting = 0.0;
                var avgRestTime = 0.0;
                let numIndecies = totalLiftingTimeStats.length > restStats.length ? restStats.length : totalLiftingTimeStats.length;    // determine min. length of 2 arrays to avoid accessing nulls
                if (numIndecies > 0) {
                    for (var i = 0; i < numIndecies; i++) {
                        avgTimeLifting += totalLiftingTimeStats[i];
                        avgRestTime += restStats[i];
                    }
                }
                avgTimeLifting /= numIndecies;
                avgRestTime /= numIndecies;

                let avgTimeSpentLiftingPercentage = (avgTimeLifting / (avgTimeLifting + avgRestTime)) * 100;

                setChartLiftData(liftStats);
                setChartPowerData(powerStats);
                setChartMaxWeightData(maxWeightStats);
                setChartFormData(formStats);
                setChartRestData(restStats);
                setChartROMData(ROMStats);
                setChartRepWeightDist(repWeightDistStats);
                setTimeSpentLifting(avgTimeSpentLiftingPercentage);

                //// console.log(repWeightDistStats);

                setLoadingData(false);
            }
        })
        .catch(e => {
            // console.log(e);
        })
    }

	function getDateString(timestampData: number) {

        let monthStrings = ['January','February','March','April','May','June','July','August','September','October','November','December'];

        let timestamp = new Date(timestampData);
        let mo = monthStrings[timestamp.getMonth()];
        let da = timestamp.getDate();
        let yr = timestamp.getFullYear();
        let hh = timestamp.getHours();
        let mm = timestamp.getMinutes();


        return ` Note created on ${mo} ${da}, ${yr} at ${hh % 12}:${mm < 10 ? `0${mm}` : mm} ${hh > 12 ? 'PM' : 'AM'}`
    }

    function getNoteTypeColourStyle(type: number) {
    	return { background: `rgba(${noteTypeColours[type]},0.2)`, color: `rgba(${noteTypeColours[type]})` };
    }

    function updateShowingDaysIndex(toIndex: number) {
        setSelectedDaysIndex(toIndex);
        getSummaryStats(selectedDaysNumbers[toIndex]);
    }

	return (
		<div className="client-profile-page-container">
			<div className="client-profile-stats">
	            <div className="home-stats-card-container">
	                <ClientProfileStatsCard
                            title={"Total Lift & Power Output"}
                            subTitle={"Explore how your client's total workout lift and power output correlate and have varried over time."}
	                        updateStatsToDays={(e: number) => updateShowingDaysIndex(e)}
	                        selectedDayOptions={selectedDaysNumbers}
	                        selectedDaysIndex={selectedDaysIndex}
	                        units={['LBS','W']}
                            stepSize={2500}
	                        statNames={['Average Total Lift', 'Average Power Output']}
	                        chartType='line'
	                        loading={loadingData}
	                        stats={[chartLiftData,chartPowerData]}/>
	            </div>
	            <div className="home-stats-card-container">
	                <ClientProfileStatsCard
                            title={"Rep vs. Weight Distribution"}
                            subTitle={"Understand your client's net strength. Rep vs. weight distribution reveals your client's ability to lift heavy for a number of reps."}
	                        updateStatsToDays={(e: number) => updateShowingDaysIndex(e)}
	                        selectedDayOptions={selectedDaysNumbers}
	                        selectedDaysIndex={selectedDaysIndex}
	                        units={['LBS/rep']}
                            stepSize={20}
	                        statNames={['Average weight per rep']}
	                        chartType='scatter'
	                        loading={loadingData}
	                        stats={[chartRepWeightDist]}/>
	            </div>
	            <div className="home-stats-card-container">
	                <ClientProfileStatsCard
                            title={"Rest Time Between Sets"}
                            subTitle={"View the ammount of time taken to rest between sets. Generally speaking, if your client is improving this should be decreasing."}
	                        updateStatsToDays={(e: number) => updateShowingDaysIndex(e)}
	                        selectedDayOptions={selectedDaysNumbers}
	                        selectedDaysIndex={selectedDaysIndex}
	                        units={['S']}
                            stepSize={30}
	                        statNames={['Rest Time']}
	                        chartType='line'
	                        loading={loadingData}
	                        stats={[chartRestData]}/>
	            </div>
	            <div className="home-stats-card-container">
	                <ClientProfileStatsCard
                            title={"Average Range of Motion (ROM)"}
                            subTitle={"This is a percentage representation of a full ROM - shown as 100%. If your client is doing well, their ROM should be around 100%."}
	                        updateStatsToDays={(e: number) => updateShowingDaysIndex(e)}
	                        selectedDayOptions={selectedDaysNumbers}
	                        selectedDaysIndex={selectedDaysIndex}
	                        units={['%']}
                            stepSize={50}
	                        statNames={['Average ROM']}
	                        chartType='line'
	                        loading={loadingData}
	                        stats={[chartROMData]}/>
	            </div>
	            <div className="home-stats-card-container">
	                <ClientProfileStatsCard
                            title={"Maximum Weight"}
                            subTitle={"View your client's maximum weight per workout over time. If they're improving, this should increase over time."}
	                        updateStatsToDays={(e: number) => updateShowingDaysIndex(e)}
	                        selectedDayOptions={selectedDaysNumbers}
	                        selectedDaysIndex={selectedDaysIndex}
	                        units={['LBS']}
                            stepSize={25}
	                        statNames={['Avg. Maximum Weight']}
	                        chartType='line'
	                        loading={loadingData}
	                        stats={[chartMaxWeightData]}/>
	            </div>
			</div>
		</div>
	)
}

export default ClientProfileStats;