import React, { useState } from 'react';
import './ClientPrograms.css';

import Folder_t from '../../Interfaces/Folder_t';
import Plan_t from '../../Interfaces/Plan_t';
import Client_t from '../../Interfaces/Client_t';

import ClientProgramsFolders from './ClientProgramsPages/ClientProgramsFolders';
import ClientSchedule from './ClientProgramsPages/ClientSchedule';

function ClientPrograms(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="client-programs">
			<div className="client-programs-header">
                <h1>{props.client === undefined || props.client.full_name === undefined ? "Your Client" : props.client.full_name}'s Programs</h1>
                <p>Explore your Client’s Programs structure below. The Folders below are unique to this client only.</p>
            </div>
            <div className="client-programs-menu">
                {/*<div onClick={() => setPageIndex(0)} className={`client-programs-menu-item ${pageIndex === 0 ? 'client-programs-menu-item-selected' : ''}`}>
                    <p>Schedule</p>
                    <div hidden={pageIndex !== 0} className="client-programs-menu-item-bar"/>
                </div>*/}
                <div onClick={() => setPageIndex(1)} className={`client-programs-menu-item ${pageIndex === 1 ? 'client-programs-menu-item-selected' : ''}`}>
                    <p>Folders</p>
                    <div hidden={pageIndex !== 1} className="client-programs-menu-item-bar"/>
                </div>
                {/*<div onClick={() => setPageIndex(2)} className={`client-programs-menu-item ${pageIndex === 2 ? 'client-programs-menu-item-selected' : ''}`}>
                    <p>Current Program</p>
                    <div hidden={pageIndex !== 2} className="client-programs-menu-item-bar"/>
                </div>
                <div onClick={() => setPageIndex(3)} className={`client-programs-menu-item ${pageIndex === 3 ? 'client-programs-menu-item-selected' : ''}`}>
                    <p>Program History</p>
                    <div hidden={pageIndex !== 3} className="client-programs-menu-item-bar"/>
                </div>*/}
            </div>
            <div className="client-programs-page-container">
                <div hidden={pageIndex !== 0} className="client-programs-page-content">
                    <div className="client-programs-empty-container">
                        <div className="client-programs-empty-img-container">
                            <img className="-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/building.svg`}/>
                        </div>
                        <h4>We're working on it!</h4>
                        <p>Client schedules are coming soon. Check back shortly as new features are added every day!</p>
                    </div>
                </div>
                <div hidden={pageIndex !== 1} className="client-programs-page-content">
                    <ClientProgramsFolders 
                        client={props.client}
                        assignClientToPlan={(plan: Plan_t, client: Client_t) => props.assignClientToPlan(plan, client)}
                        assignClientToFolder={(folder: Folder_t, client: Client_t) => props.assignClientToFolder(folder, client)}
                        addElementPressed={(e: any) => props.addElementPressed(e)}
                        getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
                        newPlanPressed={(folder: Folder_t, client_id: string) => props.newPlanPressed(folder, client_id)}
                        editRestDayPressed={(p: Plan_t, i: number) => props.editRestDayPressed(p, i, props.client)}
                        editPlanPressed={(p: Plan_t) => props.editPlanPressed(p, props.client && props.client.id ? props.client.id : "_NO_CLIENT_ID_")}
                        addNewWorkoutToPlan={(p: Plan_t) => props.addNewWorkoutToPlan(p)}
                        deleteCyclePressed={(a: any) => props.deleteCyclePressed(a)}
                        copyFolderPressed={(e: any) => props.copyFolderPressed(e)}/>
                </div>
                <div hidden={pageIndex !== 2} className="client-programs-page-content">
                    <div className="client-programs-empty-container">
                        <div className="client-programs-empty-img-container">
                            <img className="-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/building.svg`}/>
                        </div>
                        <h4>We're working on it!</h4>
                        <p>Current Program view is coming soon. Check back shortly as new features are added every day!</p>
                    </div>
                </div>
                <div hidden={pageIndex !== 3} className="client-programs-page-content">
                    <div className="client-programs-empty-container">
                        <div className="client-programs-empty-img-container">
                            <img className="-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/building.svg`}/>
                        </div>
                        <h4>We're working on it!</h4>
                        <p>Program History view is coming soon. Check back shortly as new features are added every day!</p>
                    </div>
                </div>
            </div>

		</div>
	)
}

export default ClientPrograms;