import React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation 
} from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import { History, LocationState } from "history";
import LogIn from './pages/LogIn';
import RegisterPage from './pages/Register/RegisterPage';
import ReferralRegister from './pages/Register/Referral/ReferralRegister';
import Dashboard from './pages/Dashboard/Dashboard';
import IntraWorkout from './pages/IntraWorkout';
import Home from './pages/Dashboard/Home';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Locale data for Safari to use JS Date() */
import 'intl';
import 'intl/locale-data/jsonp/en.js';

/* Firebase and backend */
import firebase from './Firebase';

/* Mixpanel */
import mixpanel from './Mixpanel';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';



const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Route path="/login" component={LogIn} exact={true} />
      <Route path="/workout" component={IntraWorkout} exact={true}/>
      <Route path="/register/:page" component={RegisterPage} exact={true} />
      <Route path="/register-referral" component={ReferralRegister} exact={true} />
      <Route path="/dashboard/:page" component={Dashboard} exact={true} />
      <Route path="/" render={() => <Redirect to="/login" />} exact={true} />
      <Route path="/dashboard" render={() => <Redirect to="/dashboard/home" />} exact={true} />
    </IonReactRouter>
  </IonApp>
);

export default App;
