import React, { useState, useEffect } from 'react';
import './NewClientModal.css';

import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import Client_t from '../../Interfaces/Client_t';
import Reminder_t from '../../Interfaces/Reminder_t';
import BodyMeasurement_t from '../../Interfaces/BodyMeasurement_t';
import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

import GoalCard from '../Goals/GoalCard';
import MultiSelect from '../Base/MultiSelect';
import DualSelector from '../Base/DualSelector';
import FormTextInput from '../Base/FormTextInput';
import FormDateInput from '../Base/FormDateInput';
import Selector from '../Base/Selector';
import DateSelector from '../Base/DateSelector';
import CheckBox from '../Base/CheckBox';
import FormBodyMeasurementInput from '../Base/FormBodyMeasurementInput';
import BodyMeasurementChart from '../Clients/BodyMeasurementChart';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Person from '@material-ui/icons/Person';
import Check from '@material-ui/icons/Check';

function NewClientModal(props: any) {

	//const clientTypeOptions: string[] = ["In-person training", "Online training", "In-person & online training"];
	const clientTypeOptions: any[] = [{title: "In-person training", desc: ""},{title: "Online training", desc: ""},{title: "In-person & online training", desc: ""}];

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

	let pageNames = ["Personal Info", "Body Measurements", "Activity Level", "Goals and Actions", "Configure Reminders", "Account Details"];
	let pageDescs = ["Begin by entering basic information describing your new Athlete.", "Start keeping track of how your Client's body measurements change over time by entering their current state.", "Make note of your Client's current activity level and their preferred means of exerise.", "Set Goals with your Client and put together an actionable plan to keep them on track.", "Set up notifications for you, and your new Client, so that you never forget to record an important milestone again.", "Last step! Create an Eigen Fitness account for your client so that they have access to their fitness data.", "", ""]

	let reminderTypes: ("" | "plan-expiring" | "review-goals" | "measure-fullbody" | "measure-weight" | "measure-bodyfat" | "measure-neck" | "measure-arms" | "measure-chest" | "measure-shoulders" | "measure-waistatbellybutton" | "measure-waistatminimum" | "measure-glutes" | "measure-legsathamtiein" | "measure-midquad" | "measure-calf")[] = ["review-goals", "measure-weight", "measure-bodyfat", "measure-fullbody", "measure-neck", "measure-arms", "measure-chest", "measure-shoulders", "measure-waistatbellybutton", "measure-waistatminimum", "measure-glutes", "measure-legsathamtiein", "measure-midquad", "measure-calf"];
	let reminderTypeStrings = ["Review Goals", "Measure bodyweight", "Measure Body Fat %", "Full Body Scan", "Measure Neck Circumference", "Measure Arm Circumference", "Measure Chest Circumference", "Measure Shoulder Circumference", "Measure Waist at Bellybutton", "Measure Waist at Minimum", "Measure Glute Circumference", "Measure Legs at Hamstring Tie-in", "Measure Legs at Mid-quad", "Measure Calf Circumference"]
	let reminderRecurringTypes: ("none" | "daily" | "weekly" | "bi-weekly" | "monthly" | "yearly")[] = ["none", "daily", "weekly", "bi-weekly", "monthly", "yearly"];
	let reminderRecurringTypeStrings = ["Once", "Daily", "Weekly", "Bi-weekly", "Monthly", "Yearly"];

	let initReminder: Reminder_t = {type: "", notifyTimestamp: 0, notificationSent: false, clientID: "", notificationCreatedTimestamp: 0, recurring: "none"};

	let initClient: Client_t = {first_name:'Jimbo',last_name:'',full_name:'',id:'', isMetric: true, gender: 'f', birthdate: '00-00-0000', height: 0, weight: 0, type: clientTypeOptions[0].title};
	let initBodyMeasurement: BodyMeasurement_t = {bodyWeight: -1, height: -1, bodyFatPercentage: -1, legLength: -1, torsoLength: -1, reachLength: -1};
	let testTrainers = ['Johnny Appleseed'];
	let workoutLevels = [{title: 'Sedentary', desc:'0 days/week'}, {title: 'Been Before', desc: '1-2 days/week'}, {title:'Moderate', desc: '3-4 days/week'}, {title:'Intense', desc:'5-6 days/week'}, {title: 'Insane', desc: 'every day'}];
	let workoutGoals = [{title: 'Get Fit', desc:'Improve overall health'}, {title: 'Loose Weight', desc:'Reach a lower target bodyweight'}, {title: 'Gain Muscle', desc:'Reach a higher target bodyweight'}, {title: 'Muscle Toning', desc:'Lower body fat percentage'}, {title: 'Body Building', desc:'Gain muscle and lower BPF'}, {title: 'Power Lifting', desc:'Increase lifting capacity'}];
	let workoutGoalMetrics = [{title: 'Target workout days per week', units: 'days / week'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target body fat percentage', units: '%'},{title: 'Target bodyweight', units: '_weight_'},{title: 'Target max. lift', units: '_weight_'}]

	let preferredExerciseTypes = [{title: 'Cardio', desc:'Running, biking, swimming, etc.'},{title: 'Weights', desc:'Lifting, crossfit, calisthenics, etc.'},{title: 'Sports', desc: 'Soccer, hockey, tennis, etc.'}]

	const [emailErrorText, setEmailErrorText] = useState('Please enter a valid email address');
	const [passwordErrorText, setPasswordErrorText] = useState('Please enter a valid email password');

	const [showingOptionIndex, setShowingOptionIndex] = useState(0);
	const [emailString, setEmailString] = useState('');
	const [passwordString, setPasswordString] = useState('');
	const [encyrptedPassword, setEncyrptedPassword] = useState('');
	const [encyrptedEmail, setEncyrptedEmail] = useState('');
	const [workoutLevelIndex, setWorkoutLevelIndex] = useState(0);
	const [workoutGoalIndex, setWorkoutGoalIndex] = useState(0);
	const [goalMetric, setGoalMetric] = useState(0);
	const [goalMetricTimeline, setGoalMetricTimeline] = useState(0);


	const [workflowState, setWorkflowState] = useState(0);
	const [client, setClient] = useState<Client_t>(initClient);
	const [bodyMeasurement, setBodyMeasurement] = useState<BodyMeasurement_t>(initBodyMeasurement);

	const [goals, setGoals] = useState<Goal_t[]>([]);

	const [reminders, setReminders] = useState<Reminder_t[]>([]);
	const [newReminder, setNewReminder] = useState<Reminder_t>(initReminder);
	const [reminderDefaultDate, setReminderDefaultDate] = useState<number>(0);
	const [selectedTrainer, setSelectedTrainer] = useState('');
	const [alerts, setAlerts] = useState<boolean[]>([false,false,false,false,false,false,false,false,false]);

	const [initSet, setInitSet] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [emailLoaded, setEmailLoaded] = useState(false);
	const [verifiedUserUID, setVerifiedUserUID] = useState("");
	const [isLoadingEmail, setIsLoadingEmail] = useState(false);

	if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        // Start mixpanel timing event
        //props.updateModalSize(true);
        Mixpanel.time_event("New client created");
        setReminderDefaultDate(Date.now());
    }

	function getWorkflowClassName(state: number) {
		return workflowState === state ? "new-client-selected-workflow-state" : (state < workflowState ? "new-client-completed-workflow-state" : "new-client-unselected-workflow-state");
	}

	function nextButtonPressed() {
		// console.log("NEXT BUTTON PRESSED");
	}

	function continuePressed() {

		if (workflowState === 7) {
			props.closeModal();
			return;
		}

		if (validatePage() === true) {
			if (workflowState === 5) {
				configureNewClient();
			}
			setWorkflowState(workflowState + 1);
		}
		
	}

	function backPressed() {
		setWorkflowState(workflowState - 1);
	}

	function jumpToPagePressed(atIndex: number) {
        if (atIndex <= workflowState) {
            // moving backwards - all good in all cases
            Mixpanel.track("New plan jump page pressed",{
                from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                to_page: atIndex < pageNames.length ? pageNames[atIndex] : 'unknown',
                move_valid: true
            });
            setWorkflowState(atIndex);
        } else {
            // attempting to move forwards. Validate all pages from (including) the current page to the page before the moving-to page
            var moveValid = true;
            var furthestValidPageIndex = workflowState - 1;
            for (var i = workflowState; i < atIndex; i++) {
                let thisPageValid = validatePage(i);
                if (!thisPageValid) {
                    moveValid = false;
                } else {
                    if (moveValid === true) {
                        furthestValidPageIndex = i;
                    }
                    
                }
            }
            if (furthestValidPageIndex >= 0) {
                Mixpanel.track("New plan jump page pressed",{
                    from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                    to_page: furthestValidPageIndex + 1 < pageNames.length ? pageNames[furthestValidPageIndex + 1] : 'unknown',
                    move_valid: moveValid
                });
                setWorkflowState(furthestValidPageIndex + 1);
            }
        }
    }


	function closeModal() {
		if (workflowState >= 1 || client.full_name.split('').length >= 1) {
			props.getUserConfirmation({
				title: "Are you sure you want to exit?",
				text: `Exiting the Athlete builder will erase your progress creating ${client.first_name}'s profile.`,
				isRed: false,
				callback: confirmCloseModal,
				ctaText: "Close"
			});

			Mixpanel.track("Close new client modal requested",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
		} else {
			confirmCloseModal()
		}
	}

	function confirmCloseModal() {
		Mixpanel.track("Close new client modal confirmed",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
		props.closeModal();
		
	}

	function unitsChanged(index: number) {
		let isMetric = index === 0;
		setClient({...client,isMetric: isMetric});
	}

	function genderSelected(index: number) {
		let genderString = index === 0 ? 'f' : 'm';
		setClient({...client,gender: genderString});
	}

	function typeSelected(index: number) {
		setClient({...client,type: clientTypeOptions[index].title});
	}

	function inputChanged(toString: any, fromInput: string) {
		// console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)
		switch (fromInput) {
			case 'client-name':
				let names = toString.split(' ');
				let fname = names.length >= 1 ? names[0] : "";
				let lname = names.length >= 2 ? names[1] : "";
				setClient({...client,first_name:fname, last_name:lname, full_name:toString});
				if (alerts[0] === true && names.length >= 2) {
					setAlerts(alerts.map((item, index) => { return index === 0 ? false : item }));
				} 
				break;
			case 'dob':
				let days = toString.split('-');
				let DOBValid = Number(days[0]) < 32 && Number(days[0]) > 0 && Number(days[1]) > 0 && Number(days[2]) > 0 && Number(days[2]) < 2020
				setClient({...client,birthdate:toString});
				if (alerts[1] === true && DOBValid) {
					setAlerts(alerts.map((item, index) => { return index === 1 ? false : item }));
				} 
				break;
			case 'trainer-name':
				setSelectedTrainer(toString);
				break;
			case 'units':
				setClient({...client,isMetric: toString === "Metric"});
				break;
			case 'weight':
				setClient({...client,weight:Number(toString)});
				setBodyMeasurement({...bodyMeasurement,bodyWeight:Number(toString)});
				break;
			case 'bfp': //body fat %
				setBodyMeasurement({...bodyMeasurement,bodyFatPercentage:Number(toString)});
				break;
			case 'leg-length': //body fat %
				setBodyMeasurement({...bodyMeasurement,legLength:Number(toString)});
				break;
			case 'torso-length': //body fat %
				setBodyMeasurement({...bodyMeasurement,torsoLength:Number(toString)});
				break;
			case 'reach-length': //body fat %
				setBodyMeasurement({...bodyMeasurement,reachLength:Number(toString)});
				break;
			case 'height':
				setClient({...client,height:Number(toString)});
				setBodyMeasurement({...bodyMeasurement,height:Number(toString)});
				break;
			case 'workout-levels' :
				let selectedLevelIndex = Number(toString); //workoutLevels.indexOf(toString);
				setWorkoutLevelIndex(selectedLevelIndex);
				break;
			case 'workout-goals':
				let selectedGoalIndex = Number(toString);//workoutGoals.indexOf(toString);
				setWorkoutGoalIndex(selectedGoalIndex);
				break;
			case 'goal-metric':
				let selectedGoalMetric = Number(toString);
				setGoalMetric(selectedGoalMetric);
				break;

			case 'goal-timeline': 
				let selectedGoalMetricTimeline = Number(toString);
				setGoalMetricTimeline(selectedGoalMetricTimeline);
				break;
			case 'reminder-type':
				let selectedReminderTypeIndex = reminderTypeStrings.indexOf(toString);
				let thisReminderType: "" | "plan-expiring" | "review-goals" | "measure-bodyfat" | "measure-weight" | "measure-fullbody" | "measure-neck" | "measure-arms" | "measure-chest" | "measure-shoulders" | "measure-waistatbellybutton" | "measure-waistatminimum" | "measure-glutes" | "measure-legsathamtiein" | "measure-midquad" | "measure-calf" = selectedReminderTypeIndex >= 0 && selectedReminderTypeIndex < reminderTypes.length ? reminderTypes[selectedReminderTypeIndex] : "review-goals";
				setNewReminder({...newReminder, type: thisReminderType});

				break;
			case 'reminder-frequency':
				let selectedReminderFrequencyIndex = reminderRecurringTypeStrings.indexOf(toString);
				let thisReminderFrequency: "none" | "daily" | "weekly" | "bi-weekly" | "monthly" | "yearly" = reminderRecurringTypes[selectedReminderFrequencyIndex];
				setNewReminder({...newReminder, recurring: thisReminderFrequency});

				let thisTS = Date.now();
				var tsOffset = 0;
				switch(thisReminderFrequency) {
					case "none":

						break; 
					case "daily":
						tsOffset = 24 * 60 * 60 * 1000;
						break; 
					case "weekly":
						tsOffset = 7 * 24 * 60 * 60 * 1000;
						break; 
					case "bi-weekly":
						tsOffset = 14 * 24 * 60 * 60 * 1000;
						break; 
					case "monthly":
						tsOffset = 30 * 24 * 60 * 60 * 1000;
						break; 
					case "yearly":
						tsOffset = 365 * 24 * 60 * 60 * 1000;
						break;
					default:
						break;
				}

				let newTS = thisTS + tsOffset;
				setReminderDefaultDate(newTS);

				break;
			default:
				// console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
				break;
		}
	}

	function validatePage(forPage?: number) {
		var okayToProceed = true;

		switch (forPage === undefined ? workflowState : forPage) {
			case 0: 			// User information page
				setAlerts(alerts.map((item, index) => { 
					// console.log(`(${item} , ${index})`);
					switch (index) {
						case 0: 	// full name
							let names = client.full_name.split(' ');
							okayToProceed = names.length >= 2;
							return names.length < 2;
							break;
						case 1: 	// date of birth
							let currentDOB = client.birthdate === undefined ? "" : client.birthdate;
							let days = currentDOB.split('-');
							// console.log(days);
							let DOBValid = Number(days[0]) < 32 && Number(days[0]) > 0 && Number(days[1]) > 0 && Number(days[2]) > 0 && Number(days[2]) < 2020
							okayToProceed = DOBValid;
							return !DOBValid;
							break;
						default:
							return false;
							break;
					}
				}))

				break;
			case 1: 			// Body information page
				setAlerts(alerts.map((item, index) => { 
					// console.log(`(${item} , ${index})`);
					switch (index) {
						case 3: 	// full name
							let weight = Number(bodyMeasurement.bodyWeight === undefined || bodyMeasurement.bodyWeight === null || bodyMeasurement.bodyWeight === NaN ? 0 : bodyMeasurement.bodyWeight);
							let weightValid = weight > 30 && weight < 600;
							okayToProceed = weightValid;
							return !weightValid;
							break;
						case 4: 	// date of birth
							let height = Number(bodyMeasurement.height === undefined || bodyMeasurement.height === null || bodyMeasurement.height === NaN ? 0 : bodyMeasurement.height);
							let heightValid = height > 30 && height < 1000;
							okayToProceed = heightValid;
							return !heightValid;
							break;
						default:
							return false;
							break;
					}
				}))
				break;
			case 2: 			// Activity level page

				break;
			case 3: 			// Goal metrics page
				// setAlerts(alerts.map((item, index) => { 
				// 	// console.log(`(${item} , ${index})`);
				// 	switch (index) {
				// 		case 5: 	// full name
				// 			let goalMetricValid = goalMetric > 0;
				// 			okayToProceed = goalMetricValid;
				// 			return !goalMetricValid;
				// 			break;
				// 		case 6: 	// date of birth
				// 			let goalMetricTimelineValid = goalMetricTimeline > 0;
				// 			okayToProceed = goalMetricTimelineValid;
				// 			return !goalMetricTimelineValid;
				// 			break;
				// 		default:
				// 			return false;
				// 			break;
				// 	}
				// }))
				break;
			case 5: 			// Account information page
				let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    			let passedEmailRegEx = emailRegEx.test(emailString);

    			let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/;
    			let passedPasswordRegEx = passwordRegEx.test(passwordString);

    			okayToProceed = passedEmailRegEx && passedPasswordRegEx;

    			setAlerts(alerts.map((item, index) => { 
    				if (index === 7) {
    					return !passedEmailRegEx;
    				} else if (index === 8) {
    					return !passedPasswordRegEx;
    				} else {
    					return false;
    				}
    			}));
				break;
			default:
				break;
		}

		for (var i = 0; i < alerts.length; i++) {
			if (alerts[i] === true) {
				// console.log(alerts[i]);
				okayToProceed = false;
			}
		}
		return okayToProceed;
	}

	function setPassword(pw: string) {
		setPasswordString(pw);

		var CryptoJS = require("crypto-js");

		var ciphertext_password = CryptoJS.AES.encrypt(pw, 'password_i8pa0ARrtSF');
		setEncyrptedPassword(ciphertext_password.toString());
		// console.log("encrypted password:", ciphertext_password.toString());

		// var bytes  = CryptoJS.AES.decrypt(ciphertext_password.toString(), 'password_i8pa0ARrtSF');
		// var plaintext = bytes.toString(CryptoJS.enc.Utf8);
		// // console.log("decrypted password", plaintext);
	}

	function setEmail(email: string) {

		setEmailString(email);

		var CryptoJS = require("crypto-js");

		var ciphertext_email = CryptoJS.AES.encrypt(email, 'email_i8pa0ARrtSF');
		setEncyrptedEmail(ciphertext_email.toString());
		// console.log("encrypted email:", ciphertext_email.toString());

		if (emailLoaded === true) {
			setEmailLoaded(false);
			setIsLoadingEmail(false);
			props.updateModalSize(true);
		}

		// var bytes  = CryptoJS.AES.decrypt(ciphertext_password.toString(), 'email_i8pa0ARrtSF');
		// var plaintext = bytes.toString(CryptoJS.enc.Utf8);
		// // console.log("decrypted email", plaintext);
	}

	function configureExistingUserAsClient() {

		let clientUID = verifiedUserUID;
		if (clientUID === "") { return; }

		setIsLoading(true);

		let currentUser = firebase.auth().currentUser;
		let trainerUID = currentUser === null ? "open" : currentUser.uid;
		let clientObjectForAPI = {
			client_uid: 		clientUID,
			first_name: 		client.first_name,
			last_name: 			client.last_name,
			full_name: 			client.full_name,
			birthdate: 			client.birthdate === undefined ? "01-01-2000" : client.birthdate,
			gender: 			client.gender,
			body_weight: 		bodyMeasurement.bodyWeight !== undefined ? bodyMeasurement.bodyWeight : -1,
			body_height: 		bodyMeasurement.height !== undefined ? bodyMeasurement.height : -1,
			type:				client.type,
			workout_goal_index: workoutGoalIndex,
			workout_level_index: workoutLevelIndex,
			trainer_uid: trainerUID
		};
		// console.log(clientObjectForAPI);

		sendWelcomeEmail();

		Mixpanel.track("New client created", {is_metric: client.isMetric});

		Mixpanel.people.increment("New clients");

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/addExistingClient`,
		clientObjectForAPI,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
			// console.log(response);
		 	// console.log(response.data);
		 	if (response.data.user != null) {
		 		setWorkflowState(7);
		 		// console.log(`Existing user added as client: ${clientUID}, ${trainerUID}`);
		 		setBodyMeasurementInDB(clientUID);
		 		//setGoalInDB(response.data.user.uid);
		 		configureGoalsInDB(clientUID);
		 		configureRemindersInDB(clientUID);

		 		setTimeout(() => {
		 			setIsLoading(false);
		 			confirmCloseModal();
		 		}, 1000);
		 		
		 	} else {
		 		//setWorkflowState(5);
		 		setIsLoading(false);
		 		// console.log(response.data);
		 		// console.log(`Error in creating user: ${response.data.error.message}`);

		 		if (response.data.error.code != null) {
		 			switch (response.data.error.code) {
		 				case 'auth/email-already-exists':
		 					setEmailErrorText('User with this email already exists');
		 					setAlerts(alerts.map((item, index) => { return index === 7 }));
		 					break;
		 				case 'auth/invalid-email':
		 					setEmailErrorText('Please enter a valid email address');
		 					setAlerts(alerts.map((item, index) => { return index === 7 }));
		 					break;
		 				case 'auth/invalid-password':
		 					setPasswordErrorText('Please enter a valid password');
		 					setAlerts(alerts.map((item, index) => { return index === 8 }));
		 					break;
		 				default:
		 					setEmailErrorText('Unknown error occured. Check email and try again');
		 					setPasswordErrorText('Unknown error occured. Check password and try again');
		 					setAlerts(alerts.map((item, index) => { return index >= 7 }));
		 					break;
		 			}
		 		}
		 	}
		})
		.catch(e => {
			// console.log(e);
		})
	}

	function configureNewClient() {

		setIsLoading(true);

		let pw_temp = "i8pa0ARrtSF";
		var CryptoJS = require("crypto-js");
		var ciphertext_password = CryptoJS.AES.encrypt(pw_temp, 'password_i8pa0ARrtSF');
		let encyrptedPassword_temp = ciphertext_password.toString();


		let currentUser = firebase.auth().currentUser;
		let trainerUID = currentUser === null ? "open" : currentUser.uid;
		let clientObjectForAPI = {
			first_name: 		client.first_name,
			last_name: 			client.last_name,
			full_name: 			client.full_name,
			birthdate: 			client.birthdate === undefined ? "01-01-2000" : client.birthdate,
			gender: 			client.gender,
			body_weight: 		bodyMeasurement.bodyWeight !== undefined ? bodyMeasurement.bodyWeight : -1,
			body_height: 		bodyMeasurement.height !== undefined ? bodyMeasurement.height : -1,
			isMetric: 			client.isMetric,
			encyrpted_email: 	encyrptedEmail,
			type:				client.type,
			encyrpted_password: encyrptedPassword_temp,
			workout_goal_index: workoutGoalIndex,
			workout_level_index: workoutLevelIndex,
			trainer_uid: trainerUID,
			is_configured: false
		};
		// console.log(clientObjectForAPI);

		subscribeUserToMailchimp();
		sendWelcomeEmail();

		Mixpanel.track("New client created", {is_metric: client.isMetric});

		Mixpanel.people.increment("New clients");

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/createClient`,
		clientObjectForAPI,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
			// console.log(response);
		 	// console.log(response.data);
		 	if (response.data.user != null) {
		 		setWorkflowState(7);
		 		// console.log(`User created: ${response.data.user.uid}`);
		 		setBodyMeasurementInDB(response.data.user.uid);
		 		configureClientTrainersInDB(response.data.user.uid, trainerUID);
		 		//setGoalInDB(response.data.user.uid);
		 		configureGoalsInDB(response.data.user.uid);
		 		configureRemindersInDB(response.data.user.uid);

		 		setTimeout(() => {
		 			setIsLoading(false);
		 			confirmCloseModal();
		 		}, 1000);
		 		
		 	} else {
		 		//setWorkflowState(5);
		 		setIsLoading(false);
		 		// console.log(response.data);
		 		// console.log(`Error in creating user: ${response.data.error.message}`);

		 		if (response.data.error.code != null) {
		 			switch (response.data.error.code) {
		 				case 'auth/email-already-exists':
		 					setEmailErrorText('User with this email already exists');
		 					setAlerts(alerts.map((item, index) => { return index === 7 }));
		 					break;
		 				case 'auth/invalid-email':
		 					setEmailErrorText('Please enter a valid email address');
		 					setAlerts(alerts.map((item, index) => { return index === 7 }));
		 					break;
		 				case 'auth/invalid-password':
		 					setPasswordErrorText('Please enter a valid password');
		 					setAlerts(alerts.map((item, index) => { return index === 8 }));
		 					break;
		 				default:
		 					setEmailErrorText('Unknown error occured. Check email and try again');
		 					setPasswordErrorText('Unknown error occured. Check password and try again');
		 					setAlerts(alerts.map((item, index) => { return index >= 7 }));
		 					break;
		 			}
		 		}
		 	}
		})
		.catch(e => {
			// console.log(e);
		})

		// axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/createClient`, { clientObjectForAPI })
		// .then(res => {
		// 	// console.log(res);
		// 	// console.log(res.data);
		// })
	}

	function configureClientTrainersInDB(userID: string, trainerID: string) {
		if (!trainerID || trainerID === "" || !userID || userID === "") return;
		let database = firebase.database();
		let ref = database.ref(`client_trainers/${userID}/0`);
		ref.set(trainerID)
	}

	function configureGoalsInDB(userID: string) {

		if (goals.length === 0) { return; }

		let database = firebase.database();
		let ref = database.ref(`goals/${userID}/goals`);

		for (var i = 0; i < goals.length; i++) { 
			let thisGoal: Goal_t = goals[i];

			let thisGoalObj: any = {
				type: thisGoal.type,
				target_metric: thisGoal.targetMetric,
				target_units_kg: thisGoal.targetUnitsKG,
				target_timestamp: thisGoal.targetTimestamp,
				starting_timestamp: Date.now()
			};

			if (thisGoal.customType !== undefined) {
				thisGoalObj['custom_type'] = thisGoal.customType;
			}

			// console.log("thisGoal.actions: ", thisGoal.actions);
			if (thisGoal.actions !== undefined) {
				var actionsList: any[] = [];
				for (var j = 0; j < thisGoal.actions.length; j++) {
					let thisAction = thisGoal.actions[j];
					let thisActionObj: any = {
						index: thisAction.index,
					    goal_desc: thisAction.goalDesc,
					    duration_days: thisAction.durationDays
					};
					if (thisAction.subActions !== undefined) {
						var subActionsList: any[] = [];
						for (var k = 0; k < thisAction.subActions.length; k++) {
							let thisSubAction = thisAction.subActions[k];
							let thisSubActionObj: any = {
								index: thisSubAction.index,
							    goal_desc: thisSubAction.goalDesc,
							    duration_days: thisSubAction.durationDays,
							    action: thisSubAction.action === undefined ? "" : thisSubAction.action,
							    metric: thisSubAction.metric === undefined ? 1 : thisSubAction.metric,
							    metric_unit: thisSubAction.metricUnit === undefined ? "" : thisSubAction.metricUnit,
							    timeframe: thisSubAction.timeframe === undefined ? "" : thisSubAction.timeframe,
							};
							subActionsList.push(thisSubActionObj);
						}
						thisActionObj['sub_actions'] = subActionsList;
					}
					actionsList.push(thisAction);
				}

				thisGoalObj['actions'] = actionsList;
			}
			// console.log("thisGoalObj:", thisGoalObj);
			ref.push(thisGoalObj);
		}


	}

	function configureRemindersInDB(userID: string) {
		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

		let database = firebase.database();
		let ref = database.ref(`notification_queue/user_queue/${trainerUID}`);

		if (reminders.length > 0) {
			for (var i = 0; i < reminders.length; i++) {
				let thisReminder: Reminder_t = reminders[i];
				let thisReminderObj: any = {
					type: thisReminder.type,
					notify_timestamp: thisReminder.notifyTimestamp,
					notification_sent: false,
					info: {
						client_id: userID,
						notification_created: Date.now()
					},
					recurring: {
						type: thisReminder.recurring,
						count: 0
					}
				}
				ref.push(thisReminderObj);
			}
		}
	}

	function subscribeUserToMailchimp() {
		axios.get(`https://eigenfitness.com/api/save-to-mailchimp-list.php?firstName=${client.first_name}&lastName=${client.last_name}&email=${emailString}&phoneNumber=0000000000&userTag=CLIENT`,
        	{ headers: {'Content-Type': 'application/json'} })
	    .then(response => {
	        // console.log(response);
	        // console.log(response.data);
	        let data = response.data;
	    })
	    .catch(e => {
	        // console.log(e);
	    })
	}

	function sendWelcomeEmail() {
		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_profile/${trainerUID}`); // Change to database path
		ref.on('value', function(snapshot) {
			var trainerName = ""
		    if (snapshot.exists() === true) {
		        let data = snapshot.val();
		        trainerName = data.first_name;
		    }

		    axios.get(`https://eigenfitness.com/email/welcome_client.php?trainer_name=${trainerName}&client_name=${client.first_name}&email=${emailString}`,
	        { headers: {'Content-Type': 'application/json'} })
		    .then(response => {
		        // console.log(response);
		        // console.log(response.data);
		        let data = response.data;
		    })
		    .catch(e => {
		        // console.log(e);
    		})
		});
		
	}

	function setBodyMeasurementInDB(userID: string) {

		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

		// console.log(`Setting body measurement log in DB for user: ${userID}`);

		let t = Date.now();

		// let bodyMeasurementLogObj =  {
		// 	timestamp: t,
		// 	logged_by: trainerUID,
		// 	body_weight: client.weight,
		// 	body_fat_percentage: bodyMeasurement.bodyFatPercentage,
		// 	height: bodyMeasurement.height,
		// 	torso_length: bodyMeasurement.torsoLength,
		// 	leg_length: bodyMeasurement.legLength,
		// 	reach_length: bodyMeasurement.reachLength
		// }


		let bodyMeasurementLogObj = {
			timestamp: t,
			logged_by: trainerUID,
			isKG: client.isMetric,
			body_weight: bodyMeasurement.bodyWeight !== undefined ? bodyMeasurement.bodyWeight : -1,
			body_fat_percentage: bodyMeasurement.bodyFatPercentage !== undefined ? bodyMeasurement.bodyFatPercentage : -1,
			lean_mass: bodyMeasurement.leanMass !== undefined ? bodyMeasurement.leanMass : -1,
			height: bodyMeasurement.height !== undefined ? bodyMeasurement.height : -1,
			torso_length: bodyMeasurement.torsoLength !== undefined ? bodyMeasurement.torsoLength : -1,
			leg_length: bodyMeasurement.legLength !== undefined ? bodyMeasurement.legLength : -1,
			reach_length: bodyMeasurement.reachLength !== undefined ? bodyMeasurement.reachLength : -1,

			neck: bodyMeasurement.neck !== undefined ? bodyMeasurement.neck : -1,
			shoulders: bodyMeasurement.shoulders !== undefined ? bodyMeasurement.shoulders : -1,
			chest: bodyMeasurement.chest !== undefined ? bodyMeasurement.chest : -1,
			arm_L: bodyMeasurement.arm_L !== undefined ? bodyMeasurement.arm_L : -1,
			arm_R: bodyMeasurement.arm_R !== undefined ? bodyMeasurement.arm_R : -1,
			forearm_L: bodyMeasurement.forearm_L !== undefined ? bodyMeasurement.forearm_L : -1,
			forearm_R: bodyMeasurement.forearm_R !== undefined ? bodyMeasurement.forearm_R : -1,
			waistAtMinimum: bodyMeasurement.waistAtMinimum !== undefined ? bodyMeasurement.waistAtMinimum : -1,
			waistAtBellyButton: bodyMeasurement.waistAtBellyButton !== undefined ? bodyMeasurement.waistAtBellyButton : -1,
			glutes: bodyMeasurement.glutes !== undefined ? bodyMeasurement.glutes : -1,
			hips: bodyMeasurement.hips !== undefined ? bodyMeasurement.hips : -1,
			thighAtHamTie_L: bodyMeasurement.thighAtHamTie_L !== undefined ? bodyMeasurement.thighAtHamTie_L : -1,
			thighAtHamTie_R: bodyMeasurement.thighAtHamTie_R !== undefined ? bodyMeasurement.thighAtHamTie_R : -1,
			thighMid_L: bodyMeasurement.thighMid_L !== undefined ? bodyMeasurement.thighMid_L : -1,
			thighMid_R: bodyMeasurement.thighMid_R !== undefined ? bodyMeasurement.thighMid_R : -1,
			calf_L: bodyMeasurement.calf_L !== undefined ? bodyMeasurement.calf_L : -1,
			calf_R: bodyMeasurement.calf_R !== undefined ? bodyMeasurement.calf_R : -1,
		}

		let bodyMeasurementLogArray = [bodyMeasurementLogObj];

		let finalObj = {
			logs: bodyMeasurementLogArray
		}

		// console.log(finalObj);

		let database = firebase.database();
		let ref = database.ref(`body_measurements/${userID}`);
		ref.set(finalObj);

	}

	// function setGoalInDB(userID: string) {

	// 	// console.log(`Setting goal log in DB for user: ${userID}`);

	// 	let t = Date.now();
	// 	let daysToMs = goalMetricTimeline * 24 * 60 * 60 * 1000;
	// 	//	let workoutGoals = [{title: 'Get Fit', desc:'Improve overall health'}, {title: 'Loose Weight', desc:'Reach a lower target bodyweight'}, {title: 'Gain Muscle', desc:'Reach a higher target bodyweight'}, {title: 'Muscle Toning', desc:'Lower body fat percentage'}, {title: 'Body Building', desc:'Gain muscle and lower BPF'}, {title: 'Power Lifting', desc:'Increase lifting capacity'}];

	// 	var startingMetric = 0;
	// 	switch (workoutGoalIndex) {
	// 		case 0: // Get fit - number of days
	// 			var numDays = [0,2,4,6,7];
	// 			startingMetric = numDays[workoutLevelIndex];
	// 			break;
	// 		case 1: // Loose weight - current weight
	// 			startingMetric = client.weight !== undefined ? client.weight : 0;
	// 			break;
	// 		case 2: // Gain weight - current weight
	// 			startingMetric = client.weight !== undefined ? client.weight : 0;
	// 			break;
	// 		case 3: // Toning - current bfp
	// 			startingMetric = bodyMeasurement.bodyFatPercentage !== undefined ? bodyMeasurement.bodyFatPercentage : 10;
	// 			break;
	// 		case 4: // Bodybuilding - current weight
	// 			startingMetric = client.weight !== undefined ? client.weight : 0;
	// 			break;
	// 		case 5: // Powerlifting - current weight
	// 			startingMetric = 0;
	// 			break;
	// 		default:
	// 			break;
	// 	}

	// 	let goalObject = {
	// 		index: workoutGoalIndex,
	// 		starting_timestamp: t,
	// 		target_timestamp: t + daysToMs,
	// 		starting_metric: startingMetric,
	// 		target_metric:goalMetric
	// 	}
	// 	let goalArray = [goalObject];

	// 	let goalLogObj = {
	// 		logs: [
	// 			{
	// 				timestamp: t,
	// 				metric: startingMetric
	// 			}
	// 		]
	// 	}

	// 	let goalLobArray = [goalLogObj];

	// 	let finalObj = {
	// 		goals: goalArray,
	// 		goal_logs: goalLobArray
	// 	}

	// 	// console.log(finalObj);

	// 	let database = firebase.database();
	// 	let ref = database.ref(`goals/${userID}`);
	// 	ref.set(finalObj);
	// }

	function addNewReminderPressed() {
		setNewReminder({...newReminder, type: "review-goals", notificationCreatedTimestamp: Date.now()});

	}

	function saveNewReminder() {
		setReminders(reminders.concat(newReminder));
		setNewReminder(initReminder);
	}

	function reminderDateUpdated(ts: number) {
		setNewReminder({...newReminder, notifyTimestamp: ts})
	}

	function cancelNewReminder() {
		setNewReminder({...newReminder, type: ""});
	}

	function getReminderTitle(forReminder: Reminder_t) {
		let indexOfType = reminderTypes.indexOf(forReminder.type);
		if (forReminder.type === "" || indexOfType === -1) { return "Undefined reminder"; }
		return reminderTypeStrings[indexOfType];
	}

	function getReminderRecurringTitle(forReminder: Reminder_t) {
		let indexOfType = reminderRecurringTypes.indexOf(forReminder.recurring);
		if (forReminder.type === "" || indexOfType === -1) { return "Undefined reminder"; }
		return reminderRecurringTypeStrings[indexOfType];
	}

	function getReminderStartDate(forReminder: Reminder_t) {
		let thisDate = new Date(forReminder.notifyTimestamp);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
	}

	function removeReminder(atIndex: number) {
		setReminders(reminders.filter((item: Reminder_t, index: number) => index !== atIndex));
	}

	function addNewGoalPressed() {
		let newGoal: Goal_t = {
			type: "get-fit",
			targetMetric: 5,
			targetUnitsKG: client.isMetric !== undefined ? client.isMetric : true,
			targetTimestamp: -1,
			startingTimestamp: Date.now(),
			isEditing: true
		}

		setGoals(goals.concat(newGoal));
	}

	function editGoalPressed(atIndex: number) {
		setGoals(goals.map((item: Goal_t, index: number) => {
			if (index !== atIndex) {
				let goalTemp = item;
				goalTemp.isEditing = false;
				return goalTemp;
			} else {
				return item;
			}

		}))
	}

	function updateGoal(atIndex: number, toGoal: Goal_t) {
		// console.log("New client modal | updateGoal", atIndex, toGoal);

		let goalTemp = toGoal;
		goalTemp.isEditing = false;

		setGoals(goals.map((item: Goal_t, index: number) => {
			if (index !== atIndex) {
				return item;
			} else {
				return goalTemp;
			}
		}))
	}

	function showAddGoal() {
		if (goals.length > 0) {
			var editing = false;
			for (var i = 0; i < goals.length; i++) {
				let thisGoal = goals[i];
				if (thisGoal.isEditing === true) {
					editing = true;
				}
			}

			return !editing;
		} else {
			return false;
		}
	}

	function removeGoalPressed(atIndex: number) {
		setGoals(goals.filter((item: Goal_t, index: number) => index !== atIndex));
	}

	function updateBodyMeasurement(measurement: BodyMeasurement_t) {
		setBodyMeasurement(measurement);

		if (alerts[3] === true || alerts[4] === true) {
			validatePage(workflowState);
		}
	}

	function getPageValidation() {
		let names = client.full_name.split(' ');
		let passedNames = names.length >= 2;

		let currentDOB = client.birthdate === undefined ? "" : client.birthdate;
		let days = currentDOB.split('-');
		let passedDOB = true; //Number(days[0]) < 32 && Number(days[0]) > 0 && Number(days[1]) > 0 && Number(days[2]) > 0 && Number(days[2]) < 2020

		let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    	let passedEmailRegEx = emailRegEx.test(emailString);

    	return passedNames && passedDOB && passedEmailRegEx && emailLoaded;
	} 

	function getEmailValidation() {
		let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    	let passedEmailRegEx = emailRegEx.test(emailString);
    	return passedEmailRegEx;
	}

	function verifyEmailPressed() {
		if (isLoadingEmail === true || getEmailValidation() === false) { return; }
		setIsLoadingEmail(true);

		let verifyEmailObj = {
			encyrpted_email: encyrptedEmail
		}

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/checkForExistingAccount`,
		verifyEmailObj,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
			// console.log(response);
		 	// console.log(response.data);

		 	setEmailLoaded(true);
			setIsLoadingEmail(false);
			props.updateModalSize(false);

		 	if (response.data.user_found !== null && response.data.user_found !== null && response.data.user_found === true) {
		 		if (response.data.user_data !== null && response.data.user_data !== undefined) {
		 			if (response.data.user_data.uid !== null && response.data.user_data.uid !== undefined && response.data.user_data.uid !== "") {
		 				// User found successfully!
		 				// console.log("USER IS FOUND!", response.data.user_data.uid);
		 				setVerifiedUserUID(response.data.user_data.uid);
			 		}
			 		if (response.data.user_data.profile !== null && response.data.user_data.profile !== undefined) {
			 			setClient({
			 				...client,
			 				first_name: response.data.user_data.profile.first_name !== null && response.data.user_data.profile.first_name !== undefined ? response.data.user_data.profile.first_name : "",
			 				last_name: response.data.user_data.profile.last_name !== null && response.data.user_data.profile.last_name !== undefined ? response.data.user_data.profile.last_name : "",
			 				full_name: response.data.user_data.profile.full_name !== null && response.data.user_data.profile.full_name !== undefined ? response.data.user_data.profile.full_name : "",
			 				gender: response.data.user_data.profile.gender !== null && response.data.user_data.profile.gender !== undefined ? response.data.user_data.profile.gender : "f",
			 			})
			 		}

			 		if (response.data.user_data.display_name !== null && response.data.user_data.display_name !== undefined && response.data.user_data.display_name !== "") {
			 			setClient({... client, full_name: response.data.user_data.display_name})
			 		}
			 		return;
		 		}
		 	} 

		 	setClient(initClient);

		 	setVerifiedUserUID("");
		 	return;

		 });

		setTimeout(() => {
			
		}, 2000);
	}

	function saveChanges() {
		if (verifiedUserUID === "") {
			// Will create a new client
			configureNewClient();
		} else {
			// Will add existing athlete to trainer
			configureExistingUserAsClient();
		}
		
	}

	return (
		<div className="new-client-container">
			<div className="new-workout-modal-header-container">
                <div className="new-workout-modal-header-close-container">
                    <div onClick={() => closeModal()} className="new-workout-modal-header-close-button">
                        <Close className="new-workout-modal-header-close-button-icon"/>
                    </div>
                </div>
                <div className="new-workout-modal-header-content-container">
                    <div className="new-workout-modal-header-content-icon-container">
                        <Person className="new-workout-modal-header-content-icon"/>
                    </div>
                    <div className="new-workout-modal-header-content-text-container">
                        <h4>Create a new Athlete</h4>
                    </div>
                </div>
                <div className="new-workout-modal-header-button-container">
					<div onClick={() => getPageValidation() ? saveChanges() : null} className={`new-workout-modal-header-button-cta ${getPageValidation() ? '' : 'new-workout-modal-header-button-cta-unselected'}`}>
						<p>Finish & create Athlete</p>
					</div>
				</div>
            </div>
            <div hidden={isLoading} className="new-client-content-container-wrapper">
                <div className={`new-client-content-container new-client-content-container-lean ${emailLoaded ? '' :'new-client-content-container-full'}`}>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>Get started setting up your new Athlete</h2>
						<p>Begin creating a new Athlete with their basic info. Let's start with their email address.</p>
					</div>
					<div hidden={emailLoaded} className="new-client-content-page-section-curtain"></div>
					<div className="new-client-content-page-input-container">
						<div className="new-client-content-page-input-container-with-button">
							<FormTextInput 
								placeholder="Email Address" 
								alert={alerts[7]} 
								noHide={true}
								autofocus={true}
								alertText={emailErrorText}
								inputChanged={(e: string) => setEmail(e)}/>
							<div hidden={emailLoaded} onClick={() => verifyEmailPressed()} className={`new-client-content-page-input-button ${getEmailValidation() ? '' : 'new-client-content-page-input-button-unselected'}`}>
								<div hidden={isLoadingEmail} className="new-client-content-page-input-button-inner new-client-content-page-input-button-inner-text-container">
									<p>Verify Email</p>
								</div>
								<div hidden={!isLoadingEmail} className="new-client-content-page-input-button-inner new-client-content-page-input-button-inner-spinner-container">
									<div className="create-workout-exercise-configure-loading-spinner new-client-content-page-input-button-spinner"/>
								</div>
							</div>
							<div hidden={!emailLoaded} className="new-client-content-page-input-verified-container">
								<Check className="new-client-content-page-input-verified-icon"/>
								<p>Verified</p>
							</div>
						</div>
						<div hidden={emailLoaded} className="new-client-content-page-info-container new-client-content-page-info-container-email-verify">
							<div className="new-client-content-page-info-icon-container">
								<div className="new-client-content-page-info-icon"/>
							</div>
							<p>Speed up adding an athlete by verifying their email to check if they already have an Eigen account</p>
						</div>
						<div hidden={!emailLoaded} className="new-client-content-page-info-container new-client-content-page-info-container-user">
							<div hidden={verifiedUserUID === ""} className="new-client-content-page-info-container-user-inner">
								<Check className="new-client-content-page-info-container-user-icon"/>
								<p>{client.first_name} has an Athlete account set up with Eigen!</p>
							</div>
							<div hidden={verifiedUserUID !== ""} className="new-client-content-page-info-container-user-inner">
								<Person className="new-client-content-page-info-container-user-icon"/>
								<p>You're creating a new Athlete account for your client.</p>
							</div>
						</div>
						<div className="new-client-content-page-input-container-with-spacing">
							<FormTextInput 
								value={client.full_name}
								placeholder="Athlete name" 
								alert={alerts[0]} 
								noHide={true}
								alertText="Please enter your Athlete's first and last name"
								inputChanged={(e: string) => inputChanged(e, 'client-name')}/>
						</div>
						<div className="">
							<FormDateInput 
								headerTitle="Date of Birth" 
								isSearch={true}
								alert={alerts[1]} 
								alertText="Please enter a valid DOB (DD MM YYYY)"
								inputChanged={(e: string) => inputChanged(e, 'dob')}/>
						</div>
						<div className="new-client-content-page-section-header new-client-content-page-section-header-noline new-client-content-page-section-header-extra-margin">
							<h4>Athlete's sex</h4>
							<p>This is important for some stats, such as daily required caloric intake.</p>
						</div>
						<MultiSelect
							options={[{title: "Female", desc: ""}, {title: "Male", desc: ""}]}
							optionChanged={(e: number) => genderSelected(e)}/>
						<div className="new-client-content-page-section-header new-client-content-page-section-header-noline new-client-content-page-section-header-extra-margin">
							<h4>Training location</h4>
							<p>Keep track of where your new Athlete primarily trains.</p>
						</div>
						<MultiSelect
							options={clientTypeOptions}
							optionChanged={(e: number) => typeSelected(e)}/>
					</div>					
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>Athlete's activity level</h2>
						<p>Select your Athlete's current activity level to keep track of their fitness progress over time.</p>
					</div>
					<MultiSelect
						options={workoutLevels}
						optionChanged={(e: number) => inputChanged(e, 'workout-levels')}/>
					
					<div className="new-client-content-page-section-spacer-5vh"/>
					


			        <div hidden={true}>
						<MultiSelect
							options={workoutGoals}
							optionChanged={(e: number) => inputChanged(e, 'workout-goals')}/>
						<div className="new-client-content-page-section-header new-client-content-page-section-header-line">
							<h4>{workoutGoalMetrics[workoutGoalIndex].title}</h4>
							<p>Help your Athlete reach their goal by setting their target metric and the desired number of days to reach that goal below.</p>
						</div>
						<div className="new-client-content-page-row new-client-content-page-row-no-padding">
							<div className="new-client-content-page-row-col">
								<FormBodyMeasurementInput
									placeholder="Target metric"
									units={workoutGoalMetrics[workoutGoalIndex].units === "_weight_" ? (client.isMetric === true ? "Kgs" : "LBS") : workoutGoalMetrics[workoutGoalIndex].units }
									alertText="Enter a valid target metric"
									alert={alerts[5]} 
									inputChanged={(e: string) => inputChanged(e, 'goal-metric')}/>
							</div>
							<div className="new-client-content-page-row-col">
								<FormBodyMeasurementInput
									placeholder="Timeline"
									units="days"
									alertText="Enter a valid timeline"
									alert={alerts[6]} 
									inputChanged={(e: string) => inputChanged(e, 'goal-timeline')}/>
							</div>
						</div>
						<div className="new-client-content-page-section-spacer-5vh"/>
					</div>

					<div hidden={true}>
						<div className="new-client-content-page-section-spacer-5vh"/>
						<div className="new-client-content-page-section-spacer-5vh"/>
						<div className="new-client-content-page-section-header">
							<h2>Set up {client.first_name}'s Eigen Fitness account</h2>
							<p>To finish setting up your Athlete's account, provide your tablet to them so that they can enter their email and password. This will be their login information on the EigenFit app.</p>
						</div>
						<p className="new-client-header-warning-text">
							<b>For security:</b>{` Please pass tablet to ${client.first_name} for them to enter their email and password.`}
						</p>
						<div className="new-client-content-page-row-account-info">
							<FormTextInput 
								placeholder="Email Address" 
								alert={alerts[7]} 
								alertText={emailErrorText}
								inputChanged={(e: string) => setEmail(e)}/>
						</div>
						<div className="new-client-content-page-row-account-info">
							<FormTextInput 
								placeholder="Create a password" 
								alert={alerts[8]} 
								hideText={true}
								alertText={passwordErrorText}
								inputChanged={(e: string) => setPassword(e)}/>
						</div>
						<div className={`new-client-content-page-account-password-hint-container ${alerts[8] === true ? 'new-client-content-page-account-password-hint-container-error' : ''}`}>
							<h4>For security, your password should include:</h4>
							<p>• At least 8 characters</p>
							<p>• At least one capital letter</p>
							<p>• At least one number</p>
							<p>• At least one special character (!, ?, &)</p>
						</div>
						<div className="new-workout-next-button-container new-workout-next-button-container-page-0">
							<div onClick={() => backPressed()} className="modal-back-button">Back</div>
							<div onClick={() => continuePressed()} className="modal-cta-button">Create Account</div>
						</div>
					</div>
				</div>

            	<div hidden={!emailLoaded} className="new-client-right-pane-content-container">
            		<div className="new-client-right-pane-content-header-container">
            			<div onClick={() => setShowingOptionIndex(0)} className={`new-client-right-pane-content-header-option ${showingOptionIndex === 0 ? 'new-client-right-pane-content-header-option-selected' : ''}`}>
            				<p>Goals and Actions</p>
            				<div hidden={showingOptionIndex !== 0} className="new-client-right-pane-content-header-selected-option-bar"/>
            			</div>
            			<div onClick={() => setShowingOptionIndex(1)} className={`new-client-right-pane-content-header-option ${showingOptionIndex === 1 ? 'new-client-right-pane-content-header-option-selected' : ''}`}>
            				<p>Body Measurements</p>
            				<div hidden={showingOptionIndex !== 1} className="new-client-right-pane-content-header-selected-option-bar"/>
            			</div>
            			<div onClick={() => setShowingOptionIndex(2)} className={`new-client-right-pane-content-header-option ${showingOptionIndex === 2 ? 'new-client-right-pane-content-header-option-selected' : ''}`}>
            				<p>Reminders</p>
            				<div hidden={showingOptionIndex !== 2} className="new-client-right-pane-content-header-selected-option-bar"/>
            			</div>
            		</div>
            		<div hidden={showingOptionIndex !== 0} className="new-client-right-pane-content-body-container">

						<div hidden={goals.length > 0} className="create-workout-exercise-list-empty-container">
				            <div className="create-workout-exercise-list-empty-image-container">
				                <img className="create-workout-exercise-list-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/workout-block-empty.png`}/>
				            </div>
				            <div className="create-workout-exercise-list-empty-content-container">
				                <h4>Athlete Goals & Actions</h4>
				                <p>Every Athlete has a unique goal, which has pushed them to seek training advice from experts like you. Goals and Actions can help you keep them focused on achieving their goal in a timeline that best suits their needs.<br/><br/>Tap 'Create a Goal' to begin adding a new Goal. You can also toggle Actions 'ON' to break their goal down into small, easily achievable, incrimental Actions that help them stay motivated and on track.</p>
				                <div onClick={() => addNewGoalPressed()} className="create-workout-exercise-list-empty-button-container create-client-reminder-empty-button-container">
				                    <p>Create a Goal</p>
				                </div>
				            </div>
				        </div>
				        <div hidden={goals.length === 0} className="new-client-cotent-goals-list-container">
				        	{
				        		goals.map((item: Goal_t, index: number) => (
				        			<GoalCard 
				        				goal={item} 
				        				index={index}
				        				client={client}
				        				updateGoal={(e: Goal_t) => updateGoal(index, e)}
				        				getUserConfirmation={(e:any) => props.getUserConfirmation(e)}
				        				editPressed={() => editGoalPressed(index)}
				        				removePressed={() => removeGoalPressed(index)}/>
				        		))
				        	}
				        </div>
				        <div onClick={() => addNewGoalPressed()} hidden={!showAddGoal()} className="new-client-cotent-goals-new-goal-container">
				        	<p>+ Add another Goal</p>
				        </div>
            		</div>
					<div hidden={showingOptionIndex !== 1} className="new-client-right-pane-content-body-container">
						<div className="new-client-container-body-measurement-container">
							<div className="new-client-container-unit-select-container">
								<div className="new-client-container-unit-select-text-container">
									<h4>Set Measurement Units</h4>
									<p>Select your Athlete's preferred units of measure</p>
								</div>
								<div hidden={true} className="new-client-container-unit-select-selector-container">
									<Selector
										placeholder="Reminder Type..."
										showTitle={false} 
										options={["Metric", "Imperial"]}
										inputChanged={(e: number) => inputChanged(e, 'units')}/>
								</div>
								<div className="new-client-container-unit-select-options-container">
									<div className="new-client-container-unit-select-option">
										<CheckBox 
											stateChanged={(e: boolean) => inputChanged('Metric', 'units')}
											forceState={client.isMetric}/>
										<div onClick={() => inputChanged('Metric', 'units')} className="new-client-container-unit-select-option-text-container">
											<p>Metric</p>
										</div>
									</div>
									<div className="new-client-container-unit-select-option">
										<CheckBox 
											stateChanged={(e: boolean) => inputChanged('Imperial', 'units')}
											forceState={!client.isMetric}/>
										<div onClick={() => inputChanged('Imperial', 'units')} className="new-client-container-unit-select-option-text-container">
											<p>Imperial</p>
										</div>
									</div>
								</div>
							</div>
							<BodyMeasurementChart 
								isMetric={client.isMetric}
								heightError={alerts[4]}
								weightError={alerts[3]}
								measurementUpdated={(e: BodyMeasurement_t) => updateBodyMeasurement(e)}/>
						</div>
					</div>
					<div hidden={showingOptionIndex !== 2} className="new-client-right-pane-content-body-container">
						<div hidden={newReminder.type !== "" || reminders.length > 0} className="create-workout-exercise-list-empty-container">
				            <div className="create-workout-exercise-list-empty-image-container">
				                <img className="create-workout-exercise-list-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/workout-block-empty.png`}/>
				            </div>
				            <div className="create-workout-exercise-list-empty-content-container">
				                <h4>Athlete Reminders</h4>
				                <p>Athlete Reminders are used to keep track of important dates for your new Athlete. You can view reminders in your dashboard, and if you have emails enabled in your notification settings you will recieve an email on the reminder date.<br/><br/>Tap 'Create a Reminder' to add a new one, selecting the reminder type, frequency, and the date you wish to start recieving reminders.</p>
				                <div onClick={() => addNewReminderPressed()} className="create-workout-exercise-list-empty-button-container create-client-reminder-empty-button-container">
				                    <p>Create a Reminder</p>
				                </div>
				            </div>
				        </div>
				        <div hidden={newReminder.type === "" && reminders.length === 0} className="create-client-reminders-list">
				        	<div className="create-client-reminders-list-header">
				        		<div className="create-client-reminders-list-header-col create-client-reminders-list-header-col-type">
				        			<p>REMINDER TYPE</p>
					        	</div>
					        	<div className="create-client-reminders-list-header-col create-client-reminders-list-header-col-body">
				        			<p>FREQUENCY</p>
					        	</div>
					        	<div className="create-client-reminders-list-header-col create-client-reminders-list-header-col-body">
				        			<p>START DATE</p>
					        	</div>
					        	<div className="create-client-reminders-list-header-col create-client-reminders-list-header-col-options">
				        			<p>OPTIONS</p>
					        	</div>
				        	</div>
				        	{
				        		reminders.map((item: Reminder_t, index: number) => (
				        			<div className="create-client-reminders-list-cell">
				        				<div className="create-client-reminders-list-cell-col create-client-reminders-list-cell-col-header">
					        				<h4>{getReminderTitle(item)}</h4>
						        		</div>
						        		<div className="create-client-reminders-list-cell-col create-client-reminders-list-cell-col-body">
					        				<h4>{getReminderRecurringTitle(item)}</h4>
						        		</div>
						        		<div className="create-client-reminders-list-cell-col create-client-reminders-list-cell-col-body">
					        				<h4>{getReminderStartDate(item)}</h4>
						        		</div>
						        		<div className="create-client-reminders-list-cell-col create-client-reminders-list-cell-col-options">
							        		<div onClick={() => removeReminder(index)} className="create-client-reminders-list-cell-col-options-button create-client-reminders-list-cell-col-options-button-remove">
							        			<Close className="create-client-reminders-list-cell-col-options-button-icon"/>
							        		</div>
						        		</div>
					        		</div>
				        		))
				        	}
				        	<div hidden={newReminder.type === ""} className="create-client-reminders-list-cell create-client-reminders-list-cell-add-new">
				        		<div className="create-client-reminders-list-cell-col-header">
				        			<Selector
										placeholder="Reminder Type..."
										showTitle={false} 
										options={reminderTypeStrings}
										inputChanged={(e: number) => inputChanged(e, 'reminder-type')}/>
				        		</div>
				        		<div className="create-client-reminders-list-cell-col-body">
				        			<Selector
										placeholder="Recurring..."
										showTitle={false} 
										options={reminderRecurringTypeStrings}
										inputChanged={(e: number) => inputChanged(e, 'reminder-frequency')}/>
				        		</div>
				        		<div className="create-client-reminders-list-cell-col-body">
			        				<DateSelector 
			        					dateSelected={(e: number) => reminderDateUpdated(e)}
			        					defaultTimestamp={reminderDefaultDate}/>
				        		</div>
				        		<div className="create-client-reminders-list-cell-col-body create-client-reminders-list-cell-col-buttons">
			        				<div onClick={() => cancelNewReminder()} className="create-client-reminders-list-new-reminder-button create-client-reminders-list-new-reminder-button-cancel">
			        					<p>Cancel</p>
					        		</div>
					        		<div onClick={() => saveNewReminder()} className="create-client-reminders-list-new-reminder-button create-client-reminders-list-new-reminder-button-save">
			        					<p>Save</p>
					        		</div>
				        		</div>
				        	</div>
				        	<div hidden={newReminder.type !== ""} onClick={() => addNewReminderPressed()} className="create-client-reminders-add-new-container">
				        		<p>+ Add another reminder</p>
				        	</div>
				        </div>
					</div>
            	</div>
            </div>
            <div hidden={!isLoading} className="new-client-content-container-wrapper new-client-content-container-wrapper-loading">
				<div className="new-client-content-container-wrapper-loading-container">
					<div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Creating Athlete profile...</p>
				</div>
			</div>
			
		</div>
	)
}

export default NewClientModal;