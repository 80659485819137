import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './ClientProgramsFolders.css';
import firebase from '../../../Firebase';
import axios from 'axios';

import Workout_t from '../../../Interfaces/Workout_t'
import Folder_t from '../../../Interfaces/Folder_t';
import Plan_t from '../../../Interfaces/Plan_t';

import WorkoutPlanListFolder from '../../Dashboard/WorkoutWorkflow/WorkoutPlanListFolder';
import WorkoutPlanListCycleOverview from '../../Dashboard/WorkoutWorkflow/WorkoutPlanListPages/WorkoutPlanListCycleOverview';
import WorkoutPlanListPlanOverview from '../../Dashboard/WorkoutWorkflow/WorkoutPlanListPages/WorkoutPlanListPlanOverview';
import WorkoutPlanListPlan from '../../Dashboard/WorkoutWorkflow/WorkoutPlanListPages/WorkoutPlanListPlan';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';

import PlayArrow from '@material-ui/icons/PlayArrow';

import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Info from '@material-ui/icons/Info';
import Check from '@material-ui/icons/Check';
import FileCopy from '@material-ui/icons/FileCopy';

function ClientProgramsFolders(props: any) {

    let initFolder = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
        };

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [path,setPath] = useState("My workout plans"); // /Bodybuilding/3-day split
    const [path_ids,setPath_ids] = useState("");
    const [activePath,setActivePath] = useState("");
    const [folders, setFolders] = useState<Folder_t[]>([]);
    const [showingFolders, setShowingFolders] = useState<Folder_t[]>([]);
    const [currentFolder, setCurrentFolder] = useState<Folder_t>(initFolder);
    const [rootFolder, setRootolder] = useState<Folder_t>(initFolder);
    const [plan, setPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
    const [showingPlan, setShowingPlan] = useState(false);

    const [creatingFolderState, setCreatingFolderState] = useState(0);

    const [newFolderName, setNewFolderName] = useState("");
    const [editedFolderName, setEditedFolderName] = useState("");
    const [editingFolderName, setEditingFolderName] = useState(false);

    const [addingState, setAddingState] = useState(-1);
    const [showMenu, setShowMenu] = useState(false);

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullFolders();
        pullCurrentPath();
    }

    function pullCurrentPath() {
        if (props.client === undefined || props.client.id === undefined) { return; }

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${props.client.id}/active_program`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setActivePath(data.current_path === undefined ? "" : data.current_path);
            }
        });
    }

    useEffect(() => {
        // console.log("-+-+-+ ClientProgramsFolders | FOLDERS UPDATED TO:", folders);
        //pullFolders();
        //attemptOpenStatusRecovery(folders)
    }, [folders])

    function pullFolders() {

        if (props.client === undefined || props.client.id === undefined) { return; }

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${props.client.id}/folders`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {

                let data = snapshot.val();
                // console.log("------------------------------- UPDATED ----------------------------", data);
                
                let foldersRoot = data;
                let foldersInRootKeys = Object.keys(foldersRoot);
                let numFoldersInRoot = foldersInRootKeys.length;
                // console.log(`${numFoldersInRoot} folders in root`);

                var foldersRootList: Folder_t[] = [];
                for (var i = 0; i < numFoldersInRoot; i++) {
                    let thisFolderObj = foldersRoot[foldersInRootKeys[i]];
                    let resultFolder: Folder_t = parseFolder(thisFolderObj, foldersInRootKeys[i]);
                    foldersRootList.push(resultFolder);
                }

                // console.log("PATH: ", path);
                // console.log("PATH IDs: ", path_ids);

                // Keep isOpen property
                attemptOpenStatusRecovery(foldersRootList)

            } else {
                // No plans exist
                //setIsLoading(false);
            }
        });
    }

    function getPlan(withID?: string) {
        // console.log("xxxx GET PLAN:", withID);
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return 'No workouts in plan'; }
        let trainerUID = currentUser.uid;

        if (withID === undefined) { console.log(`Undefined | No workout found with ID: ${withID}`);}

        let database = firebase.database();
        //let ref = database.ref(`workout_plans/${trainerUID}/plans/${withID}`);
        let ref = database.ref(`workout_plans/plans/${withID}`);

        setIsLoading(true);
        setShowingPlan(true);
        
        ref.on('value', function(snapshot) {
            if (snapshot.exists()) {
                let data = snapshot.val();
                // console.log(data);

                let planWorkouts: string[] = data.workouts

                let plan_temp: Plan_t = {
                    title: data.title,
                    id: withID !== undefined ? withID : "",
                    createdBy_ID: data.created_by,
                    created_timestamp: data.created_timestamp,
                    workout_ids: planWorkouts
                }

                setPlan(plan_temp);
                setIsLoading(false);
            } else {
                // console.log(`No workout found with ID: ${withID}`);
                setIsLoading(false);
            }
        });
    }

    function configureOpenStatus(forFolder: Folder_t, refFolder: Folder_t) {

        let f = forFolder;
        if (refFolder === undefined) { return f; }
        f.isOpen = refFolder.isOpen;
        //// console.log(`Obj ${f.title}: ${f.isOpen ? 'IS OPEN' : 'IS CLOSED'}\t\tRef ${refFolder.title}: ${refFolder.isOpen ? 'IS OPEN' : 'IS CLOSED'}`);

        var fChildTemp: Folder_t[] = [];

        if (f.children !== undefined && f.children.length > 0) {
            for (var i = 0; i < f.children.length; i++) {
                let thisChild: Folder_t = f.children[i];
                if (i < forFolder.children.length) {
                    let thisRefChild = forFolder.children[i];
                    let childResult = configureOpenStatus(thisChild, thisRefChild);
                    fChildTemp.push(childResult);
                } else {
                    thisChild.isOpen = false;
                }
            }
        }

        if (f.id === currentFolder.id) { // && currentFolder.type !== 'cycle') {
            f.isOpen = true; //currentFolder.isOpen;
            //setCurrentFolder(f);
            folderPressed(f);
            // console.log("CURRENT FOLDER PATH SET TO: ", f.path, f);
        } else if (path_ids.includes(f.id)){
            f.isOpen = true;
        }


        f.children = fChildTemp;
        //// console.log(`Obj ${f.title}: WILL BE ${f.isOpen ? 'OPEN' : 'CLOSED'}`);
        return f;
    }

    function setStaticPath(toPath: string) {
        setStaticPath_recursion(toPath, folders);
    }

    function setStaticPath_recursion(toPath: string, forFolders: Folder_t[]) {
        if (forFolders.length === 0) { return; }
        for (var i = 0; i < forFolders.length; i++) {
            let thisFolder: Folder_t = forFolders[i];
            //// console.log(`${toPath}`, `${thisFolder.path}`);
            if (`${toPath}` === `${thisFolder.path}`) {
                folderPressed(thisFolder);
                //// console.log("setStaticPath_recursion: FOLDER AT PATH FOUND - ", toPath, thisFolder);
                return;
            } else {
                setStaticPath_recursion(toPath, thisFolder.children !== undefined ? thisFolder.children : []);
            }
        }
    }

    function attemptOpenStatusRecovery(withList: Folder_t[]) {
        // console.log("attemptOpenStatusRecovery: ", withList, folders, currentFolder);

        if (folders.length > 0) {
            let folderResult: Folder_t[] = [];

            for (var i = 0; i < withList.length; i++) {
                let thisResult: Folder_t = configureOpenStatus(withList[i], folders[i]);
                folderResult.push(thisResult);
            }

            // console.log("attemptOpenStatusRecovery | setting folders: ", folderResult);

            setFolders(folders.filter(item => false));
            setFolders(folderResult);
        } else {
            setFolders(withList);
            updateShowingFolders(withList);
        }
    }

    function updateShowingFolders(newFolders: Folder_t[]) {
        let splitPath = currentFolder.path.split("/");
        //// console.log("splitPath: ", splitPath);
        var parentFolder: Folder_t = initFolder;
        parentFolder.children = newFolders;
        for (var i = 0; i < splitPath.length; i++) {
            let thisPathID = splitPath[i];
            for (var j = 0; j < parentFolder.children.length; j++) {
                let thisChild =  parentFolder.children[j];
                //// console.log(`i: ${i}`, `j: ${j}`, thisPathID, thisChild.id);
                if (thisChild.id === thisPathID) {
                    parentFolder = thisChild;
                }
            }
        }
        //// console.log("updateShowingFolders: ", parentFolder);
        setShowingFolders(parentFolder.children);
    }

    function parseFolder(folderObj: any, folderKey: string) {

        let folderObjType = folderObj.type;
        let isFolder = folderObjType === "folder";

//        if (isFolder) {
            var children: Folder_t[] = [];

            let thisObjChildren = folderObj.children;
            if (thisObjChildren !== undefined) {
                let childFolderKeys = Object.keys(thisObjChildren);
                let numChildFolders = childFolderKeys.length;
                for (var i = 0; i < numChildFolders; i++) {
                    let thisFolderObj = thisObjChildren[childFolderKeys[i]];
                    let resultFolder: Folder_t = parseFolder(thisFolderObj, childFolderKeys[i]);
                    children.push(resultFolder);
                }
            }
            

            var returnFolder: Folder_t = {
                title: folderObj.name,
                id: folderKey,
                type: folderObjType,
                path: folderObj.path,
                isPlan: folderObjType === "plan",
                children: children,
                isOpen: false
            }

            if (folderObjType === "plan") {
                returnFolder.plan_id = folderObj.template_id === undefined ? folderObj.plan_id : folderObj.template_id;
                returnFolder.phaseName = folderObj.phase_name;
            }

            return returnFolder;
        // } else {

        //     // Plan found at end of folder chain
        //     let planFolder: Folder_t = {
        //         title: folderObj.name,
        //         isPlan: true,
        //         type: folderObjType,
        //         path: folderObj.path,
        //         plan_id: folderObj.plan_id,
        //         children: [],
        //         id: folderKey
        //     }

        //     return planFolder;
        // }
        
    }

    function folderPressed(folder: Folder_t) {
        let thisFolderPath = folder.path;

        var newFolderArray: Folder_t[] = [];
        if (!folder.isPlan) {
            // A folder was selected.
            setShowingFolders(folder.children);
            setPath(path + `/${folder.title}`);
            setPath_ids(folder.path);
            // console.log("Setting path IDs to: ", folder.path);

            setCurrentFolder(folder);

        } else {
            // console.log("CLICKED PLAN - SEE folderPressed()");
            getPlan(folder.plan_id);
        }
    }

    function updateFolderDisplay(folder: Folder_t, isToggle: boolean) {

        // console.log(`WorkoutPlanList | updateFolderDisplay: `, folder, 'isToggle: ', isToggle);

        var folderObjTemp: Folder_t[] = [];

        for (var i = 0; i < folders.length; i++) {
            var thisFolderTemp = folders[i];
            let thisFolderID = folders[i].id;
            if (thisFolderID === folder.id) {
                thisFolderTemp.isOpen = isToggle ? !thisFolderTemp.isOpen : true;
            }
            thisFolderTemp = updateFolderDisplay_recursive(folder, thisFolderTemp, isToggle);
            folderObjTemp.push(thisFolderTemp);
        }

        setFolders(folderObjTemp);
    }

    function updateFolderDisplay_recursive(folder: Folder_t, checkingFolder: Folder_t, isToggle: boolean) {
        
        var folderTemp: Folder_t = checkingFolder;
        var folderChilrenTemp: Folder_t[] = [];

        if (folderTemp.children !== undefined && folderTemp.children.length > 0) {
            for (var i = 0; i < folderTemp.children.length; i++) {
                var thisFolderTemp = folderTemp.children[i];
                let thisFolderID = thisFolderTemp.id;

                if (thisFolderID === folder.id) {
                    thisFolderTemp.isOpen = isToggle ? !thisFolderTemp.isOpen : true;
                }
                thisFolderTemp = updateFolderDisplay_recursive(folder, thisFolderTemp, isToggle);
                folderChilrenTemp.push(thisFolderTemp);
            }
        }

        folderTemp.children = folderChilrenTemp;
        return folderTemp;
    }

    function folderSelected(folder: Folder_t) {

        // console.log(`WorkoutPlanList | folderSelected: `, folder, folder.path);

        setCurrentFolder(folder);
        setAddingState(-1);

        setEditedFolderName(folder.title);

        var folderDepth = 0;
        if (folder.path !== "") {
            let folderPathDivided = folder.path.split('/');
            folderDepth = folderPathDivided.length;
        }
        //Mixpanel.track("Folder pressed",{folder_type: folder.type, title: folder.title, depth: folderDepth});

        let thisFolderPath = folder.path;

        var newFolderArray: Folder_t[] = [];
        if (!folder.isPlan) {
            // A folder was selected.
            setShowingFolders(folder.children);
            setPath(path + `/${folder.title}`);
            setPath_ids(folder.path);
            // console.log("Setting path IDs to: ", folder.path);

            setCurrentFolder(folder);

        } else {
            // console.log("CLICKED PLAN - SEE folderPressed()");
            getPlan(folder.plan_id);
        }
    }

    function addFolderToRootPressed() {
        
        props.addElementPressed({type: 'folder', parent: rootFolder, callback: addFolderAtRootCallback});
    }

    function addFolderAtRootCallback(name: string) {
        createFolderAtRoot(name);
    }

    function addFolderPressed() {
        // Mixpanel.track("Add Folder pressed", {to_state: addingState === 0 ? 'closing' : 'opening'});
        props.addElementPressed({type: 'folder', parent: currentFolder, callback: addFolderCallback});
    }



    function addFolderCallback(name: string) {
        createFolder(name);
    }

    function createFolder(withName?: string) {
        if (newFolderName === "" && withName === undefined) { return; }
        // console.log("createFolder", props.client);

        if (props.client === undefined || props.client.id === undefined) { return; }
        let userUID = props.client.id;

        let splitPath_ids = path_ids.split('/');
        let depth = splitPath_ids.length;

        // console.log(`Creating folder at ${path_ids}`);

        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += "/";
            composedPath_ids += splitPath_ids[i];
            composedPath_ids += "/children";
        }
        // console.log(`Final FB location at ${composedPath_ids}`);


        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${userUID}/folders${composedPath_ids}`);
        //let key = ref.push().key;


        let newFolderObj = {
            type: "folder",
            name: withName !== undefined ? withName : newFolderName,
            path: path_ids,
            children: []
        }  


        // console.log(newFolderObj);
        let pushRef = ref.push(newFolderObj);
        let key = pushRef.key;

        let newRef = ref.child(`${key}/path`);
        newRef.set(`${path_ids}/${key}`);

        setCreatingFolderState(1);
        //setIsLoading(true);

        let newFolder: Folder_t = {
            id: key === null ? '' : key,
            type: "folder",
            title: withName !== undefined ? withName : newFolderName,
            children: [],
            path: `${path_ids}/${key === null ? '' : key}`,
            isPlan: false,
            isOpen: false
        }

        setTimeout(() => {
            setCreatingFolderState(2);
            pullFolders();

            folderPressed(newFolder);
            setIsLoading(false);

            // setTimeout(() => {
            //     clearNewFolder();
                
            // }, 5000);
        }, 300);
    }

    function createFolderAtRoot(withName?: string) {
        if (newFolderName === "" && withName === undefined) { return; }

        if (props.client === undefined || props.client.id === undefined) { return; }
        let userUID = props.client.id;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let splitPath_ids = "".split('/');
        let depth = splitPath_ids.length;

        // console.log(`Creating folder at ${path_ids}`);

        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += "/";
            composedPath_ids += splitPath_ids[i];
            composedPath_ids += "/children";
        }
        // console.log(`Final FB location at ${composedPath_ids}`);


        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${userUID}/folders${composedPath_ids}`);
        //let key = ref.push().key;


        let newFolderObj = {
            type: "folder",
            name: withName !== undefined ? withName : newFolderName,
            path: "",
            children: []
        }  


        // console.log(newFolderObj);
        let pushRef = ref.push(newFolderObj);
        let key = pushRef.key;

        let newRef = ref.child(`${key}/path`);
        newRef.set(`/${key}`);

        setCreatingFolderState(1);
        //setIsLoading(true);

        let newFolder: Folder_t = {
            id: key === null ? '' : key,
            type: "folder",
            title: withName !== undefined ? withName : newFolderName,
            children: [],
            path: `/${key === null ? '' : key}`,
            isPlan: false,
            isOpen: false
        }

        setTimeout(() => {
            setCreatingFolderState(2);
            pullFolders();

            folderPressed(newFolder);
            setIsLoading(false);

            // setTimeout(() => {
            //     clearNewFolder();
                
            // }, 5000);
        }, 300);
    }

    function addCyclePressed() {
        //Mixpanel.track("Add Cycle pressed", {to_state: addingState === 1 ? 'closing' : 'opening', location: "workout_plan_list"});

        props.addElementPressed({type: 'cycle', parent: currentFolder, callback: addCycleCallback});
    }

    function addCycleCallback(numPhases: number) {
        createCycle(numPhases);
    }

    function newPlanPressed() {
        //setShowAddCycleMenu(false);
        //setShowAddFolderMenu(false);
        if (props.client === undefined || props.client.id === undefined) { return; }
        let userUID = props.client.id;
        props.newPlanPressed(currentFolder, userUID);
    }

    function addPlanPressed() {
        //Mixpanel.track("Add Plan pressed", {location: "empty folder"});
        setAddingState(-1);
        newPlanPressed();
    }

    function addPlanPressedInCycle(planFolder: Folder_t, cycleFolder: Folder_t) {
        // Mixpanel.track("Add Plan pressed", {location: "cycle"});
        // setShowAddCycleMenu(false);
        // setShowAddFolderMenu(false);
        if (props.client === undefined || props.client.id === undefined) { return; }
        let userUID = props.client.id;
        // console.log("GGGGG --- addPlanPressedInCycle", userUID, planFolder);
        props.newPlanPressed(planFolder, userUID);
    }

    function addPlanPressedInPlan(planFolder: Folder_t) {
        // Mixpanel.track("Add Plan pressed", {location: "plan"});
        // setShowAddCycleMenu(false);
        // setShowAddFolderMenu(false);



        if (props.client === undefined || props.client.id === undefined) { return; }
        let userUID = props.client.id;
        // console.log("GGGGG --- addPlanPressedInPlan", userUID, planFolder);
        props.newPlanPressed(planFolder, userUID);
    }

    function createCycle(numberPhasesRequested?: number) {
        if (props.client === undefined || props.client.id === undefined) { return; }
        let userUID = props.client.id;

        // if (numberPhasesRequested === undefined) {
        //     Mixpanel.track("Cycle added", {num_phases: cyclesToAdd, location: "workout_plan_list"});
        // }

        //setIsLoading(true);

        let splitPath_ids = path_ids.split('/');
        let depth = splitPath_ids.length;

        // console.log(`Creating folder at ${path_ids}`);

        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += "/";
            composedPath_ids += splitPath_ids[i];
            composedPath_ids += "/children";
        }
        // console.log(`Final FB location at ${composedPath_ids}`);


        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${userUID}/folders${composedPath_ids}`);
        //let key = ref.push().key;

        // Calculate this cycle number
        var cycleNum = 1;
        var numPhases = 0;
        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {
            var cycleExists = false;
            for (var i = 0; i < currentFolder.children.length; i++) {
                if (currentFolder.children[i].type === "cycle") { 
                    cycleExists = true; 
                    let thisCycleFolder = currentFolder.children[i];
                    if (thisCycleFolder.children !== undefined && thisCycleFolder.children.length > 0) {
                        for (var j = 0; j < thisCycleFolder.children.length; j++) {
                            if (thisCycleFolder.children[j].type === "plan") {
                                numPhases += 1;
                            }
                        }
                    }
                }
            }

            if (cycleExists) {
                cycleNum = currentFolder.children.length + 1;
            }
        }

        let newFolderObj = {
            type: "cycle",
            name: `Cycle ${cycleNum}`,
            path: path_ids,
            children: []
        }   

        

        // console.log(newFolderObj);
        let pushRef = ref.push(newFolderObj);
        let key = pushRef.key;

        let newRef = ref.child(`${key}/path`);
        newRef.set(`${path_ids}/${key}`);

        let newCycle: Folder_t = {
            id: key === null ? '' : key,
            type: "cycle",
            title: `Cycle ${cycleNum}`,
            children: [],
            path: `${path_ids}/${key === null ? '' : key}`,
            isPlan: false,
            isOpen: true
        }

        let numPhasesToAdd = numberPhasesRequested === undefined ? 3 : numberPhasesRequested;
        for (var i = 0; i < numPhasesToAdd; i++) {
            if (key !== null) {
                addChildToCycle(key, numPhases + i, ref);
            }
            
        }

        //setCreatingCycleState(1);

        setTimeout(() => {
            //setCreatingCycleState(2);
            pullFolders();
            setIsLoading(false);
            //folderPressed(currentFolder);
            setTimeout(() => {
                // setShowAddFolderMenu(false);
                // setCreatingCycleState(0);
                setAddingState(-1);
            }, 5000);
        }, 600);
    }

    function addChildToCycle(cycleID: string, index: number, ref: firebase.database.Reference) {
        let thisPhaseObj = {
            type: "plan",
            name: `Phase ${index + 1}`,
            path: `${path_ids}/${cycleID}`
        };

        let pushRef = ref.child(`${cycleID}/children`).push(thisPhaseObj);
        let key = pushRef.key;

        let newRef = ref.child(`${cycleID}/children/${key}/path`);
        newRef.set(`${path_ids}/${cycleID}/${key}`);
    }

    function jumpToParentFolder(ofFolder: Folder_t) {
        let pathSplit = ofFolder.path.split('/');
        if (pathSplit === undefined || pathSplit === null || pathSplit.length <= 1) { return; }

        var recomposedPath = '/';
        for (var i = 1; i < pathSplit.length - 1; i++) {
            recomposedPath += pathSplit[i];
            recomposedPath += i === pathSplit.length - 2 ? '' : '/';
        }

        // console.log("JUMP TO PARENT: ", recomposedPath);
        if (recomposedPath === '/') {
            folderSelected(initFolder);
        } else {
            setStaticPath(recomposedPath);
        }
        
    }

    function checkShouldObliteratePlan() {
        // console.log("checkShouldObliteratePlan: ", currentFolder, folders);

        
        let splitPath: string[] = currentFolder.path.split('/');
        //if (splitPath.length <= 1) { return true; }                // Remove all plans with one or zero keys in path (also root level)

        var refPath = "";
        for (var i = 1; i < splitPath.length - 1; i++) {
            refPath += `/${splitPath[i]}`;
        }

        if (refPath === "") { return true; }            // Remove all plans at root level
        // console.log("Checking path v. plan path:", refPath, currentFolder.path);

        let obliterate = checkShouldObliteratePlan_childFolder(folders, refPath);
        // console.log("SHOULD obliterate:", obliterate);
        return obliterate;
    }

    function checkShouldObliteratePlan_childFolder(thisFolderList: Folder_t[], refPath: string): boolean {

        var thingToReturn = false;
        for (var i = 0; i < thisFolderList.length; i++) {
            let thisFolder: Folder_t = thisFolderList[i];
            if (thisFolder.path === refPath) {
                // console.log("MATCHING PATHS!!!", thisFolder, thisFolder.path, refPath, thisFolder.type);
                thingToReturn = thisFolder.type === "folder";
            } else if (thisFolder.children !== undefined && thisFolder.children.length > 0) {
                if (thingToReturn === false) {
                    thingToReturn = checkShouldObliteratePlan_childFolder(thisFolder.children, refPath);
                }
            }
        }

        return thingToReturn;
    }


    function confirmDeleteFolder() {

        if (props.client === undefined || props.client.id === undefined || props.client.id === "") { return; }

        let folderPathSplit = currentFolder.path.split('/');
        var folderPathDB = `workout_plans/client_assigned_plans/${props.client.id}/folders`;
        for (var i = 1; i < folderPathSplit.length; i++) {
            let thisPathID = folderPathSplit[i];
            folderPathDB += `/${folderPathSplit[i]}`;
            folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        }

        setIsLoading(true);
        
        let deletedCycle: Folder_t = currentFolder;
        jumpToParentFolder(deletedCycle);

        let database = firebase.database();
        let ref = database.ref(folderPathDB);

        ref.set(null).then((response: any) => {
            setTimeout(() => {
                // console.log("Successfully deleted folder", response);
                setIsLoading(false);
                pullFolders();
            }, 600);
            
        })
        .catch((err: any) => {
            setTimeout(() => {
                // console.log("ERROR! Could not delete folder", err);
                setIsLoading(false);
                pullFolders();
            }, 600);
        })
    }

    function confirmDeleteCycle() {

        if (props.client === undefined || props.client.id === undefined || props.client.id === "") { return; }

        setIsLoading(true);
        let sendData = {
            user_uid: props.client.id,
            cycle_id: currentFolder.id,
            cycle_path: currentFolder.path,
            is_client_path: true
        };

        let deletedCycle: Folder_t = currentFolder;
        jumpToParentFolder(deletedCycle);

        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/deleteCycle`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response);
            // console.log(response.data);
            let data = response.data;

            setIsLoading(false);
            pullFolders();
        })
        .catch(e => {
            // console.log(e);
            setIsLoading(false);
        })
    }

    function confirmDeletePlan() {

        // console.log("confirmDeletePlan", props.client, currentFolder);
        if (props.client === undefined || props.client.id === undefined || props.client.id === "") { return; }

        setIsLoading(true);

        let sendData = {
            user_uid: props.client.id,
            phase_id: currentFolder.id,
            phase_path: currentFolder.path,
            is_client_path: true,
            obliterate: checkShouldObliteratePlan()
        };

        let deletedPlan: Folder_t = currentFolder;

        // console.log("DELETING: ", currentFolder);
        jumpToParentFolder(deletedPlan);

        
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/deletePhase`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response);
            // console.log(response.data);
            // console.log(response.data.phase_path_db)
            let data = response.data;

            setIsLoading(false);
            pullFolders();
        })
        .catch(e => {
            // console.log(e);
            setIsLoading(false);
        })
    }

    function deleteFolderPressed() {
        //Mixpanel.track("Delete Folder requested");
        //setShowMenu(false);
        let info = {
            callback: confirmDeleteFolder,
            title: "Are you sure you want to delete this Folder?",
            text: `Deleting a your Folder, ${currentFolder.title}, will remove all Cycles, Phases, and other Folders inside. This action cannot be undone.`,
            isRed: true,
            ctaText: "Delete Folder"
        }
        props.deleteCyclePressed(info);
    }

    function deleteCyclePressed() {
       // Mixpanel.track("Delete Cycle requested");
        let info = {
            callback: confirmDeleteCycle,
            title: "Are you sure you want to delete this Cycle?",
            text: `Deleting a cycle, ${currentFolder.title}, will remove all phases that you have created inside of it. If you choose to delete the cycle, this action cannot be undone.`,
            isRed: true,
            ctaText: "Delete Cycle"
        }
        props.deleteCyclePressed(info);
    }

    function deletePlanPressed() {
        //Mixpanel.track("Delete Plan requested");
        let info = {
            callback: confirmDeletePlan,
            title: "Are you sure you want to delete this Phase?",
            text: `Deleting a your Phase, ${currentFolder.title}, will remove all information and workouts created specifically for the Phase. This action cannot be undone.`,
            isRed: true,
            ctaText: "Delete Phase"
        }
        props.deleteCyclePressed(info);
    }

    function copyFolder() {
        props.copyFolderPressed({parent: currentFolder, client: props.client, callback: confirmCopyFolder});
    }

    function confirmCopyFolder() {

    }

    function updateFolderName(toFolderName: string) {
        // let currentUser = firebase.auth().currentUser;
        // if (!currentUser) { return; }
        // let trainerUID = currentUser.uid;

        // setIsLoading(true);


        // let folderPathSplit = currentFolder.path.split('/');
        // var folderPathDB = `workout_plans/trainer_plans/${trainerUID}/folders_root`;
        // for (var i = 1; i < folderPathSplit.length; i++) {
        //     let thisPathID = folderPathSplit[i];
        //     folderPathDB += `/${folderPathSplit[i]}`;
        //     folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        // }

        // let database = firebase.database();
        // let ref = database.ref(folderPathDB);

        // ref.child('name').set(toFolderName === "" ? "Unnamed" : toFolderName).then((response: any) => {
        //     setTimeout(() => {
        //         // console.log("Successfully deleted folder", response);
        //         setIsLoading(false);
        //         getPlans();
        //     }, 300);
            
        // })
        // .catch((err: any) => {
        //     setTimeout(() => {
        //         // console.log("ERROR! Could not delete folder", err);
        //         setIsLoading(false);
        //         getPlans();
        //     }, 300);
        // })


    }

    function assignClientToFolder(forFolder: Folder_t) {
        if (props.client === undefined || props.client.id === undefined) { return; }

        props.assignClientToFolder(forFolder, props.client);
    }

    function assignClientToPlan(forPlan: Plan_t) {
        if (props.client === undefined || props.client.id === undefined) { return; }

        props.assignClientToPlan(forPlan, props.client);
    }

    function toggleMenuShow() {
        setShowMenu(!showMenu);
    }

    window.addEventListener('click', function(e){ 
        if (document === null || document.getElementById('folder-overview-menu') === null || document.getElementById('folder-overview-button') === null || e.target === null) { return; }

        if (document.getElementById('folder-overview-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('folder-overview-button')!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    });

    function getShowType() {
        // Showing type: 
        //    0 = show add screen - Currently in a folder with child folders, or an empty folder
        //    1 = show cycle layout - currently in a folder with only cycles
        //    2 = show plan layout - currently in cycle
        //    3 = show plan info - currently in plan

        if (currentFolder.title === "") {
            // Root folder - show add screen. Which buttons to show is adjusted via showAddCyclesAndPlans()
            return 0;
        }

        if (currentFolder.type === "cycle") {
            return 2;
        }

        if (currentFolder.type === "plan") {
            return 3;
        }

        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {

            for (var i = 0; i < currentFolder.children.length; i++) {
                let thisChild = currentFolder.children[i];
                if (thisChild.type === "cycle") {
                    // In folder with cycles
                    return 1;
                } else if (thisChild.type === "plan") {
                    // In folder with plans
                    return 1;
                }
            }
        }
        // Empty folder, or folder with only sub folders - add away!
        return 0;

    }

    function copyToMyFoldersPressed() {

    }

    function editFolderNamePressed() {

    }

	return (
		<div className="client-programs-folders">
			<div className="client-programs-folders-list-container">
                <div className="workout-plan-list-folder-card-row-header">
                    <div className="workout-plan-list-folder-card-row-header-text-container">
                        <h4>Program Folders</h4>
                        <p>({folders.length})</p>
                    </div>
                    <div onClick={() => addFolderToRootPressed()} className="workout-plan-list-folder-card-row-header-add-button">
                        <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                    </div>
                </div>
                <div hidden={isLoading} className="workout-plan-list-folder-card-body client-programs-folders-list-container-inner">
                    { folders.map((item: Folder_t, index: number) => (
                        <WorkoutPlanListFolder 
                            folder={item}
                            currentFolder={currentFolder}
                            activePath={activePath}
                            folderSelected={(f: Folder_t) => folderSelected(f)}
                            toggleDisplay={(f: Folder_t) => updateFolderDisplay(f, true)}
                            turnOnDisplay={(f: Folder_t) => updateFolderDisplay(f, false)}/>
                    ))}
                </div>
                <div hidden={!isLoading} className="workout-plan-list-folder-card-body workout-plan-list-folder-card-body-loading">
                    <p>Loading Workout Plans...</p>
                </div>         
            </div>
            <div className="client-programs-folders-content-container">
                <div hidden={!isLoading} className="workout-plan-content-loading-container">
                    <div className="workout-plan-content-loading-spinner"/>
                    <p>Loading plans...</p>
                </div>
                <div hidden={getShowType() !== 0} className="workout-plan-content workout-plan-content-add-container">
                    <div className="workout-plan-list-cycle-overview-header-card">
                        <div className="workout-plan-list-cycle-overview-header-card-icon-container">
                            <Folder className="workout-plan-list-cycle-overview-header-card-icon"/>
                        </div>    
                        <div hidden={editingFolderName} className="workout-plan-list-cycle-overview-header-card-text-container">
                            <h1>{currentFolder.title === "" ? 'All My Programs' : currentFolder.title}</h1>
                        </div>
                        {/*<div hidden={!editingFolderName} className="workout-plan-list-cycle-overview-header-card-text-container workout-plan-list-cycle-overview-header-card-text-container-editing">
                            <FormTextInput 
                                value={editedFolderName}
                                placeholder="Folder name " 
                                alert={false} 
                                alertText=""
                                isSearch={true}
                                inputChanged={(e: string) => inputChanged_editFolder(e)}/>
                            <div onClick={() => saveFolderName()} className="workout-plan-list-cycle-overview-header-card-text-container-save-button">
                                <Check className="workout-plan-list-cycle-overview-header-card-text-container-save-button-icon"/>
                                <p>Save</p>
                            </div>
                        </div>*/}
                        <div hidden={currentFolder.title === ""} id="folder-overview-button" onClick={() => toggleMenuShow()} className="workout-plan-list-cycle-overview-header-card-menu-button-container">
                            <p>Options</p>
                            <MoreHoriz className="workout-plan-list-cycle-overview-header-card-menu-button-icons"/>
                        </div> 
                        <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                            <div id="folder-overview-menu" className="workout-plan-list-cycle-overview-header-card-menu-container">
                                <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                                    <p>Folder Options</p>
                                </div>
                                <div onClick={() => editFolderNamePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                        <Edit className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                    </div>
                                    <p>Edit Folder Name</p>
                                </div>
                                <div onClick={() => copyFolder()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                        <FileCopy className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                    </div>
                                    <p>Copy to My Folders</p>
                                </div>
                                <div onClick={() => deleteFolderPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                    <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                        <DeleteOutline className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                    </div>
                                    <p>Delete Folder</p>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                    <div hidden={currentFolder.children.length === 0} className="workout-plan-add-header-container">
                        <div className="workout-plan-add-header-image-container">
                            <img className="workout-plan-add-header-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/bi_folder.png`}/>
                        </div>
                        <h3>Folder is not empty</h3>
                        <p>Folders that contain other folders cannot be used to add new Cycles or Phases.<br/><br/>Go ahead and create another Folder to add Cycles and Phases, or use the Program Folders navigation panel on the left to view and editing existing content.</p>
                        <div onClick={() => addFolderPressed()} className="workout-plan-add-folder-button">
                            <p>Add a Folder</p>
                        </div>
                    </div>
                    <div hidden={currentFolder.children.length > 0} className="workout-plan-add-header-container">
                        <div className="workout-plan-add-header-image-container">
                            <img className="workout-plan-add-header-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/bi_folder.png`}/>
                        </div>
                        <h3>Add to this empty Folder</h3>
                        <p>Use empty Folders to house other Folders, or add Cycles and Phases to build your Program structure.</p>
                        <div className="workout-plan-add-element-container">
                            <div onClick={() => addFolderPressed()} className="workout-plan-add-element-row-container workout-plan-add-element-row-line">
                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-folder">
                                    <Folder className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-folder"/>
                                </div>
                                <div className="workout-plan-add-element-row-text-container">
                                    <h4>Add a Folder</h4>
                                    <p>Use Folders to further organize your Programs</p>
                                </div>
                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                </div>
                            </div>
                            <div onClick={() => addCyclePressed()} className="workout-plan-add-element-row-container workout-plan-add-element-row-line">
                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-list">
                                    <List className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-list"/>
                                </div>
                                <div className="workout-plan-add-element-row-text-container">
                                    <h4>Add a Cycle</h4>
                                    <p>Use cycles to organize Phased workout Programs</p>
                                </div>
                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                </div>
                            </div>  
                            <div onClick={() => addPlanPressed()} className="workout-plan-add-element-row-container">
                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-phase">
                                    <DynamicFeed className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-phase"/>
                                </div>
                                <div className="workout-plan-add-element-row-text-container">
                                    <h4>Add a Phase</h4>
                                    <p>Configure your Programs without using Cycles</p>
                                </div>
                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div hidden={getShowType() !== 1} className="workout-plan-content workout-plan-content-cycle-overview-container">
                    <WorkoutPlanListCycleOverview
                        addPlanPressed={(p: Folder_t, c: Folder_t) => addPlanPressedInCycle(p, c)} 
                        addPlanPressedInFolder={() => addPlanPressed()}
                        folder={currentFolder}
                        assignClientToFolder={() => assignClientToFolder(currentFolder)}
                        addCyclePressed={() => addCyclePressed()}
                        cycleSelected={(c: Folder_t) => folderSelected(c)}
                        turnOnDisplay={(c: Folder_t) => updateFolderDisplay(c, false)}
                        createNewCycle={(n: number) => createCycle(n)}
                        updateFolderName={(n: string) => updateFolderName(n)}
                        editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                        deleteFolderPressed={() => deleteFolderPressed()}
                        copyFolder={() => copyFolder()}
                        isClient={true}/>
                </div>
                <div hidden={getShowType() !== 2} className="workout-plan-content workout-plan-content-cycle-overview-container">
                    <WorkoutPlanListPlanOverview 
                        cycle={currentFolder}
                        addPlan={(p: Folder_t, c: Folder_t) => addPlanPressedInCycle(p, c)} 
                        planSelected={(p: Folder_t) => folderSelected(p)}
                        editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                        deleteCyclePressed={() => deleteCyclePressed()}
                        isClient={true}/>
                </div>
                <div hidden={getShowType() !== 3} className="workout-plan-content workout-plan-content-cycle-overview-container">
                    <WorkoutPlanListPlan 
                        planFolder={currentFolder}
                        assignClientToFolder={() => assignClientToFolder(currentFolder)}
                        addPlan={(p: Folder_t) => addPlanPressedInPlan(p)}
                        assignClientToPlan={(p: Plan_t) => assignClientToPlan(p)} 
                        planSelected={(p: Folder_t) => folderSelected(p)}
                        editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                        workoutSelected={(w: Workout_t) => props.workoutSelected(w)}
                        editRestDayPressed={(p: Plan_t, i: number) => props.editRestDayPressed(p, i)}
                        deletePlanPressed={() => deletePlanPressed()}
                        addNewWorkoutToPlan={(p: Plan_t) => props.addNewWorkoutToPlan(p)}
                        getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
                        addNewExerciseToWorkoutModalPressed={(d: any) => props.addNewExerciseToWorkoutModalPressed(d)}
                        isClient={true}/>
                </div>
            </div>
		</div>
	)
}

export default ClientProgramsFolders;