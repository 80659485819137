
import React, { useState, useEffect } from 'react';
import './SetBreakdown.css';
import './common.css';

import SetBreakdownCard from '../components/Cards/SetBreakdownCard';

function SetBreakdown(props: any) {
    
    const [logData, setLogData] = useState<any[]>([]);
    const [totalVolume, setTotalVolume] = useState(0);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize()
    }, [initSet])

    useEffect(() => {
        generateExerciseData();
    }, [props.logData])
    

    const initialize = () => {
        generateExerciseData();
    }

    const generateExerciseData = () => {

    	if (!props.logData || !props.logData.log) return;

    	// console.log("DEEENK:", props.logData);


    	// 1. Compose list of all exercise sets
    	var totalVolSum = 0;
    	var exerciseList: any[] = [];
		let thisLog = props.logData.log;
		if (thisLog && thisLog.exercise_series) {
			for (var j = 0; j < thisLog.exercise_series.length; j++) {
				let thisSeries = thisLog.exercise_series[j];
				if (thisSeries && thisSeries.exercises) {
					for (var k = 0; k < thisSeries.exercises.length; k++) {
						let thisExerciseObj = thisSeries.exercises[k];
						let thisExerciseID = thisExerciseObj.id;
						if (thisExerciseID && thisExerciseID !== "") {
							exerciseList.push(thisExerciseObj)

							// Calculate total rep volume
							let thisSetList = thisExerciseObj.sets ?? [];
							for (var m = 0; m < thisSetList.length; m++) {
								let thisSet = thisSetList[m];
								if (thisSet && thisSet.reps_completed) totalVolSum += thisSet.reps_completed;
							}
						}
					}
				}
			}
		}
    	setTotalVolume(totalVolSum);

    	
		// let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    	// 2. Organize by muscle
    	// var muscleExerciseBuckets: any[] = [{title: "Abdominals", exercises: []},{title: "Arms", exercises: []},{title: "Back", exercises: []},{title: "Chest", exercises: []},{title: "Shoulders", exercises: []},{title: "Legs", exercises: []},{title: "Butt", exercises: []}];
    	// let exerciseKeys = Object.keys(exerciseList);
    	// for (var i = 0; i < exerciseKeys.length; i++) {
    	// 	let thisID = exerciseKeys[i];
    	// 	let thisExerciseObj = exerciseList[thisID];
    	// 	if (thisExerciseObj && thisExerciseObj.exercise_data && thisExerciseObj.exercise_data.muscles && thisExerciseObj.exercise_data.muscles.length > 0) {
    	// 		for (var j = 0; j < thisExerciseObj.exercise_data.muscles.length; j++) {
    	// 			let thisMuscle = thisExerciseObj.exercise_data.muscles[j];
    	// 			if (thisMuscle && thisMuscle.group && thisMuscle.group > 0 && thisMuscle.group < muscleExerciseBuckets.length) {
    	// 				let thisGroup = thisMuscle.group;
    	// 				// Check if exercise is already added to this group
    	// 				if (muscleExerciseBuckets[thisGroup].length === 0) {
    	// 					muscleExerciseBuckets[thisMuscle.group].exercises.push(thisExerciseObj);
    	// 				} else {
    	// 					var okayToAdd = true;
    	// 					for (var k = 0; k < muscleExerciseBuckets[thisGroup].exercises.length; k++) {
    	// 						let thisExistingExercise = muscleExerciseBuckets[thisGroup].exercises[k];
    	// 						let thisExistingExerciseID = thisExistingExercise.exercise_data ? thisExistingExercise.exercise_data.id : "";
    	// 						if (thisExistingExerciseID === thisID) okayToAdd = false;
    	// 					}
    	// 					if (okayToAdd) muscleExerciseBuckets[thisMuscle.group].exercises.push(thisExerciseObj);
    	// 				}
    					
    	// 			}
    	// 		}
    	// 	}
    	// }

    	// setLogData(muscleExerciseBuckets);
    	setLogData(exerciseList);
    	// console.log("thisExerciseObj:", exerciseList);

    }


	return (
		<div className={ "performance-analysis-page" }>
            {
            	logData.map((item: any, index: number) => (
            		<div className={ "performance-analysis-data-content-body-row" }>
                		<SetBreakdownCard 
                			title={ `${item.series_id} | ${item.title}` ?? "Unknown Muscle Group" }
                			totalVolume={ totalVolume }
                			setList={ item.sets ?? [] }/>
                	</div>
            	))
            }
		</div>
	)
}

export default SetBreakdown;