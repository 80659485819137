import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import './NewWorkoutModal.css';
import './ConfirmModal.css';
import './NewElementModal.css';

import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

// modal data:
//	{ title: "", text: "", isRed: boolean, callback: any, ctaText: "" }
//
//
//


function NewElementModal(props: any) {

	const [note, setNote] = useState("");
	const [workflowState, setWorkflowState] = useState(0);
	const [inputAlert, setInputAlert] = useState(false);
	
	const [numPhases, setNumPhases] = useState(3);

	function closePressed() {
		props.closeModal();
	}

	function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'note':
                setNote(toString);
                if (inputAlert === true) {
                	if (toString.split("").length >= 5) {
                		setInputAlert(false);
                	}
                }
                break;
            case 'num-phases':
            	if (Number(toString) > 0) {
            		setNumPhases(Number(toString));
            	}
            	
            default:
                break;
        }
    }

    function submit() {
    	if (props.information.type === 'folder' && note.split("").length >= 3) {
    		setWorkflowState(1);

    		if (props.information !== undefined && props.information.callback !== undefined) {
    			props.information.callback(note);
    		}

    		setTimeout(() => {
    			closePressed();
	        	setWorkflowState(2);
	        }, 900);
    	} else if (props.information.type === 'cycle') {
    		setWorkflowState(1);

    		if (props.information !== undefined && props.information.callback !== undefined) {
    			props.information.callback(numPhases);
    		}

    		setTimeout(() => {
    			closePressed();
	        	setWorkflowState(2);
	        }, 900);
    	}
    }

    function sendAdminEmail(data: any, key: any) {

    	let dbLocation = `https://console.firebase.google.com/u/1/project/movement-tracker-457bc/database/movement-tracker-457bc/data/issues/${data.user.user_id}/${key !== null ? key : ''}`
    	axios.get(`https://eigenfitness.com/email/user_feedback_submitted.php?submitted_on=${data.human_timestamp}&email=${data.user.email}&trainer_name=${data.user.full_name}&trainer_first_name=${data.user.first_name}&website=${dbLocation}&gym_name=${data.user.company}&city=${data.user.city}&note=${data.note}`,
        { headers: {'Content-Type': 'application/json'} })
	    .then(response => {
	        // console.log(response);
	        // console.log(response.data);
	        let data = response.data;
	    })
	    .catch(e => {
	        // console.log(e);
		})
    }

    function updateNumPhases(delta: number) {
    	if (numPhases + delta > 0) {
    		setNumPhases(numPhases + delta);
    	}
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            submit()
        }
    }

	return (
		<div className="modal-background new-element-modal-background">
			<div className="new-element-modal-container">
				<div onClick={() => closePressed()} className="new-element-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
				<div hidden={workflowState !== 0} className="new-element-modal-top">
					<h3>Create a {props.information.type === 'folder' ? "Folder" : `Cycle with ${numPhases} Phase${numPhases === 1 ? '' : 's'}`}</h3>
					<p>
						{props.information.type === 'folder' ? `Enter a name for your new Folder in ${props.information !== undefined && props.information.parent !== undefined ? (props.information.parent.title === "" ? "Programs Root Folder" : props.information.parent.title) : "unnamed folder"} to house more Folders, Cycles, or Phases.` 
						: `Select the number of Phases to add to your new Cycle in ${props.information !== undefined && props.information.parent !== undefined ? (props.information.parent.title === "" ? "Programs Root Folder" : props.information.parent.title) : "unnamed folder"}.`}
					</p>
				</div>
				<div hidden={workflowState !== 0 || props.information.type !== 'folder'} className="new-element-modal-body-container">
					<div className="new-element-modal-input-container">
						<FormTextInput 
                            value={note}
                            placeholder="Enter a Folder name..." 
                            alert={inputAlert} 
                            autofocus={props.information.type === 'folder'}
                            alertText=""
                            isSearch={true}
                            isOptional={false}
                            onKeyPress={(e: any) => inputUpdated(e.key)}
                            inputChanged={(e: string) => inputChanged(e, 'note')}/>
                    </div>
				</div>
				<div hidden={workflowState !== 0 || props.information.type !== 'cycle'} className="new-element-modal-body-container new-element-modal-body-container-phases">
					<div onClick={() => updateNumPhases(-1)} className="new-element-modal-input-button-container">
						<p>-</p>
					</div>
					<div className="new-element-modal-input-container">
						<FormTextInput 
                            value={numPhases}
                            placeholder="3"
                            alert={inputAlert} 
                            alertText=""
                            isSearch={true}
                            isOptional={false}
                            inputChanged={(e: string) => inputChanged(e, 'num-phases')}/>
                    </div>
                    <div onClick={() => updateNumPhases(1)} className="new-element-modal-input-button-container">
						<p>+</p>
					</div>
				</div>
				<div hidden={workflowState !== 0} className="new-element-modal-bottom">
					<div onClick={() => closePressed()} className="new-element-modal-close-button">Cancel</div>
					<div onClick={() => submit()} className="new-element-modal-cta-button">Create {props.information.type === 'folder' ? "Folder" : "Cycle"}</div>
				</div>
				<div hidden={workflowState !== 1} className="new-element-modal-body-container new-element-modal-body-container-loading">
					<div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Creating your new {props.information.type === 'folder' ? "Folder" : "Cycle"}...</p>
				</div>
				<div hidden={workflowState !== 2} className="new-element-modal-body-container new-element-modal-body-container-done">
					<h4>{props.information.type === 'folder' ? "Folder" : "Cycle"} Created!</h4>
                    <p>Your new {props.information.type === 'folder' ? "Folder" : "Cycle"} was created in <span>{props.information !== undefined && props.information.parent !== undefined ? (props.information.parent.title === "" ? "Programs Root Folder" : props.information.parent.title) : "unnamed folder"}</span></p>
                    <div onClick={() => closePressed()} className="new-element-modal-cta-button new-element-modal-cta-button-done">Done</div>
				</div>
			</div>
		</div>
	)
}

export default NewElementModal;

