import React, { useState, useEffect } from 'react';
import './NewWorkoutWorkoutCard.css';
import firebase from '../../../Firebase';
import axios from 'axios';

import Workout_t from '../../../Interfaces/Workout_t';

import '@material-ui/core';
import TrendingUp from '@material-ui/icons/TrendingUp';
import Timer from '@material-ui/icons/Timer';
import Accessibility from '@material-ui/icons/Accessibility';

function NewWorkoutClientCard(props: any) {

	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};

	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	const [imgUrl, setImgUrl] = useState('');
	const [initSet, setInitSet] = useState(false);
	const [userGoalIndex, setUserGoalIndex] = useState(0);
	const [prevWorkoutName, setPrevWorkoutName] = useState('No workouts logged')
	const [muscleTags, setMuscleTags] = useState<string[]>([]);
	const [exerciseList, setExerciseList] = useState<string>("");
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

    	if (props.workout != undefined) {
    		setWorkout(props.workout);
    		getImage();
        	getMuscles();
    	}
        
    }

    useEffect(() => {
        setWorkout(props.workout);
        initialize();
    }, [props.workout]);

	function getImage() {
		if (props.workout.imageName === undefined || props.workout.imageName === '') { return; }
		const storage = firebase.storage();
		
		storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}

	function getMuscles() {
		if (props.workout.id === undefined || props.workout.id === '') { return; }
		let database = firebase.database();
		let ref = database.ref(`workouts/${props.workout.id}`);
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let workoutObj = snapshot.val();

				// Determine previous workout muscle groups
				let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
				var muscleGroupTags_temp: string[] = [];
				var exerciseTags_temp: string[] = [];
				let exercises = workoutObj.exercises;
				let exerciseKeys = Object.keys(exercises);
				for (var i = 0; i < exerciseKeys.length; i++) {
					let thisKey = exerciseKeys[i];
					let thisExercise = exercises[thisKey];

					let exerciseTitle = String(thisExercise.title);
					if (!exerciseTags_temp.includes(exerciseTitle)) {
						exerciseTags_temp.push(exerciseTitle);
					}
					

					let thisExerciseMuscles = thisExercise.muscles;
					let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

					for (var j = 0; j < thisExerciseMusclesKeys.length; j++) {
						let thisMuscleKey = thisExerciseMusclesKeys[j];
						let thisMuscle = thisExerciseMuscles[thisMuscleKey];
						let thisMuscleGroup = Number(thisMuscle.group);

						let thisMuscleString = muscle_groups[thisMuscleGroup];
						if (!muscleGroupTags_temp.includes(thisMuscleString)) {
							muscleGroupTags_temp.push(thisMuscleString);
						}
					}
				}
				
				setMuscleTags(muscleTags.concat(muscleGroupTags_temp));

				let exerciseString_temp = "";
				for (var i = 0; i < exerciseTags_temp.length; i++) {
					exerciseString_temp += i != 0 ? `, ${exerciseTags_temp[i]}` : exerciseTags_temp[i];
				}
				setExerciseList(exerciseString_temp);
			}
		});
	}


	function getWorkoutGoalString(goalIndex: number) {
		let workoutGoals = ['Get Fit', 'Loose Weight', 'Gain Muscle', 'Muscle Toning', 'Body Building', 'Power Lifting']
		return workoutGoals[goalIndex];
	}

	function getDifficultyString() {
		let difficulties = ["Very easy", "Low intensity", "Mildly intense", "Moderatly intense", "Highly intense", "Extremely intense"]
		let difficultyString = difficulties[props.workout.difficulty];

		return `${difficultyString}`
	}

	function getMusclesString() {
		if (muscleTags.length === 0) {
			return 'No muscles specified';
		}

		var temp = '';
		for (var i = 0; i < muscleTags.length; i++) {
			temp += muscleTags[i];
			temp += i === muscleTags.length - 1 ? '' : ', ';
		}

		return temp;
	}

	return (
		<div className="new-workout-client-card-container">
			<div className="new-workout-client-card-image-container">
				<div className="new-workout-client-profile-general-profile-circle">
					<img  
						className="new-workout-client-profile-general-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${workout.title}`} />
				</div>
			</div>

			<div className="new-workout-client-name-container">
				<p>Selected Workout</p>
				<h2>{props.workout.title}</h2>
			</div>
			<div className="new-workout-client-info-container">
				<p>Workout Details</p>
				<h2>{getDifficultyString()} {props.workout.duration} mins</h2>
			</div>
			<div className="new-workout-client-info-container">
				<p>Exercise Count</p>
				<h2>{workout.exercises !== undefined ? workout.exercises!.length : 0} Exercises</h2>
			</div>
			<div className="new-workout-client-info-container">
				<p>Workout Details</p>
				<h2>{getMusclesString()}</h2>
			</div>
			
		</div>
	)
}

/*

<div className="new-workout-client-card-image-container">
				<div className="new-workout-client-profile-general-profile-circle">
					<img  
						className="new-workout-client-profile-general-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${workout.title}`} />
				</div>
			</div>
			<div className="new-workout-workout-name-container">
				<h2>{props.workout.title}</h2>
				<div className="new-workout-workout-details-container">
					<div className="new-workout-workout-details-content">
						<TrendingUp className="new-workout-client-name-tag-icon" style={{ fontSize: 14 }}/>
						<p>{getDifficultyString()}</p>
					</div>
					<div className="new-workout-workout-details-content">
						<Timer className="new-workout-client-name-tag-icon" style={{ fontSize: 14 }}/>
						<p>{props.workout.duration} mins</p>
					</div>
				</div>
				<div className="new-workout-client-name-tag-container">
					<Accessibility className="new-workout-client-info-tag-icon" style={{ fontSize: 14 }}/>
					{muscleTags.map((item, index) => (
						<div 
							key={`tag-${index}`}
							className="new-workout-client-name-tag-content">{item}</div>
					))}
				</div>
			</div>
			<div className="new-workout-client-info-container">
				<p>6 exercises in this workout</p>
				<div className="new-workout-workout-exercise-tag-container">
					{exerciseList}
				</div>
			</div>

{exerciseList.map((item, index) => (
						<div className="new-workout-workout-exercise-tag-content">{item}</div>
					))}
*/

export default NewWorkoutClientCard;