import React, { useState } from 'react';
import './SettingsPageHeader.css';


function SettingsPageHeader(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="settings-page-header">
			<h4>{props.title}</h4>
            <div className="settings-page-header-subtitle-container" hidden={props.subTitle === undefined}>
                <p>{props.subTitle === undefined ? '' : props.subTitle}</p>
            </div>
		</div>
	)
}

export default SettingsPageHeader;