import React, { useState } from 'react';
import './AlertBlock.css';

import '@material-ui/core';
import Warning from '@material-ui/icons/Warning';


function AlertBlock(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="alert-block-container">
			<div className="alert-block-icon-container">
                <Warning style={{ fontSize: 14 }}/>
            </div>
            <div className="alert-block-text-container">
                <p>{props.alertText !== undefined ? props.alertText : ""}</p>
            </div>
		</div>
	)
}

export default AlertBlock;