import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';

import './ClientPlanCell.css';
import Plan_t from '../../Interfaces/Plan_t';
import Client_t from '../../Interfaces/Client_t';


function ClientPlanCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [currentPlanPathNames, setCurrentPlanPathNames] = useState("");
    const [planName, setPlanName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.planObj !== undefined) {
            getPlanData();
        }
    }

    useEffect(() => {
        if (props.planObj !== undefined) {
            getPlanData();
        }
    }, [props.planObj]);

    function getPlanData() {
        //if (props.client === undefined || props.client.id === "") { return; }

        setIsLoading(true);

        let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

        let startTS = new Date(props.planObj.starting_timestamp);
        let start_date = startTS.getDate();
        let start_mo = moStrings[startTS.getMonth()];
        let start_yr = startTS.getFullYear();
        let startString = `${start_mo} ${start_date}, ${start_yr}`;
        setStartDate(startString);

        let endTS = new Date(props.planObj.ending_timestamp);
        let end_date = endTS.getDate();
        let end_mo = moStrings[endTS.getMonth()];
        let end_yr = endTS.getFullYear();
        let endString = `${end_mo} ${end_date}, ${end_yr}`;
        setEndDate(endString);

        setIsActive(props.planObj.is_active);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${props.planObj.template_plan_id}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                let planTitle = data.title;
                setPlanName(planTitle);
                
                let thisPath = data.path !== undefined && data.path !== null ? data.path : (data.location_path !== undefined && data.location_path !== null ? data.location_path : '');
                getPathNames(thisPath);
            } else {

            }
        });
    }

    function getPathNames(pathIDs: string) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let splitPath = pathIDs.split('/');
        let firstFolderID = splitPath[1];

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root/${firstFolderID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists()) {
                let foldersObj = snapshot.val();
                var pathNames = foldersObj.name + " / ";

                var parentObj = foldersObj;
                for (var i = 2; i < splitPath.length - 1; i++) {
                    let thisChildID = splitPath[i];
                    let parentsChildObj = parentObj.children[thisChildID];
                    pathNames += parentsChildObj.name + " / ";
                    parentObj = parentsChildObj;
                }

                setCurrentPlanPathNames(pathNames);
            }

            setIsLoading(false);
        });
    }

	return (
		<div className="client-plan-cell">
            <div hidden={isLoading} className="client-plan-cell-content">
    			<div className="client-plan-cell-col client-plan-cell-col-head">
                    <p>{currentPlanPathNames}</p>
                    <h4>{planName}</h4>
                </div>
                <div className="client-plan-cell-col">
                    <p>Started</p>
                    <h4>{startDate}</h4>
                </div>
                <div className="client-plan-cell-col">
                    <p>{isActive ? 'Ending' : 'Ended'}</p>
                    <h4>{endDate}</h4>
                </div>
                <div hidden={!isActive} className="client-plan-cell-current-plan-circle"/>
            </div>
            <div hidden={!isLoading} className="client-plan-cell-content client-plan-cell-content-loading">
                <p>Loading...</p>
            </div>
		</div>
	)
}

export default ClientPlanCell;