import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import './DateRangeSelector.css';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import CalendarToday from '@material-ui/icons/CalendarToday';

function DateRangeSelector(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let wkDayStrings = ["S", "M", "T", "W", "T", "F", "S"];

    const [selectionStage, setSelectionStage] = useState(0);        // 0 = both dates selected. Next selection is first date | 1 = first date selected, next selection is ending date

    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
    const [selectedYear, setSelectedYer] = useState(2022);
    const [selectedDay, setSelectedDay] = useState(1);

    const [selectedTimestamp_ending, setSelectedTimestamp_ending] = useState(0);
    const [selectedMonthIndex_ending, setSelectedMonthIndex_ending] = useState(0);
    const [selectedYear_ending, setSelectedYer_ending] = useState(2022);
    const [selectedDay_ending, setSelectedDay_ending] = useState(1);

    const [allDays, setAllDays] = useState<number[]>([]);
    const [allDaysNextMo, setAllDaysNextMo] = useState<number[]>([]);

    const [thisMoMonthIndex, setThisMoMonthIndex] = useState(0);
    const [thisMoYear, setThisMoYear] = useState(0);

    const [nextMoMonthIndex, setNextMoMonthIndex] = useState(0);
    const [nextMoYear, setNextMoYear] = useState(0);

    const [showingCalendar, setShowingCalendar] = useState(false);
    const [initSet, setInitSet] = useState(false);

    
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setSelectedTimestamp(Date.now());

        let durationTime = props.defaultDurationDays === undefined ? 2592000000 : (props.defaultDurationDays * 1000 * 60 * 60); 
        setSelectedTimestamp_ending(Date.now() + durationTime);

        props.dateSelected(Date.now());

        let thisDate = new Date();

        setSelectedMonthIndex(thisDate.getMonth());
        setSelectedYer(thisDate.getFullYear());
        setSelectedDay(thisDate.getDate());

        configureAllDays(thisDate.getMonth(),thisDate.getFullYear());
    }

    useEffect(() => {
        //if (props.defaultTimestamp !== undefined && props.defaultTimestamp > 0) {
            // Configure starting timestamp and date consts
            // console.log("DATE RANGE UPDATED!");
            setSelectedTimestamp(props.startingTimestamp);
            let thisDate = new Date(props.startingTimestamp);

            setSelectedMonthIndex(thisDate.getMonth());
            setSelectedYer(thisDate.getFullYear());
            setSelectedDay(thisDate.getDate());

            // Configure ending timestamp and date consts
            let durationTime = props.defaultDurationDays === undefined ? 2592000000 : (props.defaultDurationDays * 1000 * 60 * 60 * 24); 
            let endingDateTimestamp = props.endingTimestamp; //thisDate.getTime() + durationTime;
            let endingDate = new Date(endingDateTimestamp);
            setSelectedTimestamp_ending(endingDateTimestamp);

            setSelectedMonthIndex_ending(endingDate.getMonth());
            setSelectedYer_ending(endingDate.getFullYear());
            setSelectedDay_ending(endingDate.getDate());


            configureAllDays(thisDate.getMonth(),thisDate.getFullYear());

            //props.dateSelected(props.defaultTimestamp);
        //}
    }, [props.defaultTimestamp])

    useEffect(() => {
        // console.log("DATE RANGE UPDATED!");
        setSelectedTimestamp(props.startingTimestamp);
        let thisDate = new Date(props.startingTimestamp);

        setSelectedMonthIndex(thisDate.getMonth());
        setSelectedYer(thisDate.getFullYear());
        setSelectedDay(thisDate.getDate());

        // Configure ending timestamp and date consts
        let durationTime = props.defaultDurationDays === undefined ? 2592000000 : (props.defaultDurationDays * 1000 * 60 * 60 * 24); 
        let endingDateTimestamp = props.endingTimestamp; //thisDate.getTime() + durationTime;
        let endingDate = new Date(endingDateTimestamp);
        setSelectedTimestamp_ending(endingDateTimestamp);

        setSelectedMonthIndex_ending(endingDate.getMonth());
        setSelectedYer_ending(endingDate.getFullYear());
        setSelectedDay_ending(endingDate.getDate());

        configureAllDays(thisDate.getMonth(),thisDate.getFullYear());
    }, [props])

    function configureAllDays(mo: number, yr: number) {
        // Set all days for current month
        setAllDays(getAllDaysForMoYr(mo, yr));
        setThisMoMonthIndex(mo);
        setThisMoYear(yr);

        // Set all days for next month
        let nextMoMo = mo >= 11 ? 0 : mo + 1;
        let nextMoYr = mo >= 11 ? yr + 1 : yr;

        setAllDaysNextMo(getAllDaysForMoYr(nextMoMo, nextMoYr));
        setNextMoMonthIndex(nextMoMo);
        setNextMoYear(nextMoYr);
    }

    function getAllDaysForMoYr(mo: number, yr: number) {
        let numberOfShowingDays = getDaysInMonth(mo, yr);
        let startingDateIndex = new Date(yr, mo, 1).getDay();

        var tempDayBuffer: number[] = [];

        if (startingDateIndex > 0) {
            for (var i = 0; i < startingDateIndex; i++) {
                tempDayBuffer.push(-1);
            }
        }
        for (var i = 0; i < numberOfShowingDays; i++) {
            tempDayBuffer.push(i + 1);
        }

        return tempDayBuffer;
    }

    function getDaysInMonth(mo: number, yr: number) {
        return new Date(yr, mo + 1, 0).getDate();
    }

    function getDateString() {
        let thisDate = new Date(selectedTimestamp);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        let endingDate = new Date(selectedTimestamp_ending);
        let endingMoIndex = endingDate.getMonth();
        let endingDay = endingDate.getDate();
        let endingYear = endingDate.getFullYear();

        return selectionStage === 0 ? `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear} - ${moStrings[endingMoIndex]} ${endingDay}, ${endingYear}` : `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear} - `;
    }

    function toggleShowingCalendar() {
        setShowingCalendar(!showingCalendar);
    }

    function previousMonth() {
        let newYear = thisMoMonthIndex === 0 ? thisMoYear - 1 : thisMoYear;
        let newMonth = thisMoMonthIndex === 0 ? 11 : thisMoMonthIndex - 1;

        setThisMoMonthIndex(newMonth);
        setThisMoYear(newYear);

        configureAllDays(newMonth, newYear);
        //updateTimestamp(1, newMonth, newYear);
    }

    function nextMonth() {
        let newYear = thisMoMonthIndex === 11 ? thisMoYear + 1 : thisMoYear;
        let newMonth = thisMoMonthIndex === 11 ? 0 : thisMoMonthIndex + 1

        setThisMoMonthIndex(newMonth);
        setThisMoYear(newYear);

        configureAllDays(newMonth, newYear);

        //updateTimestamp(1, newMonth, newYear);
    }

    function daySelected(dayNumber: number, cal: number) {
        if (dayNumber < 0) { return; }

        let moNum = cal === 0 ? thisMoMonthIndex : nextMoMonthIndex;
        let yrNum = cal === 0 ? thisMoYear : nextMoYear;

        updateTimestamp(dayNumber, moNum, yrNum);
    }

    function updateTimestamp(d: number, m: number, y: number) {
        let newDate = new Date(y,m,d);

        switch (selectionStage) {
            case 0:                    // Set starting date
                setSelectedTimestamp(newDate.getTime());
                setSelectedDay(d);
                setSelectedMonthIndex(m);
                setSelectedYer(y);

                setSelectedTimestamp_ending(-1);
                setSelectedDay_ending(-1);
                setSelectedMonthIndex_ending(-1);
                setSelectedYer_ending(-1);

                setSelectionStage(1);

                break;
            case 1:                    // Set ending date
                // Check if date is before selected starting date - reject if true
                let thisDate = new Date(y, m, d);
                let thisDateTS = thisDate.getTime();
                if (thisDateTS < selectedTimestamp) { return; }

                setSelectedTimestamp_ending(newDate.getTime());
                setSelectedDay_ending(d);
                setSelectedMonthIndex_ending(m);
                setSelectedYer_ending(y);

                setSelectionStage(0);

                //setShowingCalendar(false);
                break;
            default:
                break;
        }
        

        //props.dateSelected(newDate.getTime());
    }

    function getDurationDays() {
        let dt = selectedTimestamp_ending - selectedTimestamp;
        let dt_days = dt / (1000 * 60 * 60 * 24);
        return Math.floor(dt_days);
    }

    function getCellHighlightClass(day: number, cal: number) {
        // check if full highlight applicable
        if (day === selectedDay) {
            // correct day number to highlight. Filter by cal number
            if (cal === 0) {
                if (selectedMonthIndex === thisMoMonthIndex) {
                    return 'date-range-selector-calendar-day-content-selected'
                }
            }
            if (cal === 1) {
                if (selectedMonthIndex === nextMoMonthIndex) {
                    return 'date-range-selector-calendar-day-content-selected'
                }
            }
        }
        if (day === selectedDay_ending) {
            // correct day number to highlight. Filter by cal number
            if (cal === 0) {
                if (selectedMonthIndex_ending === thisMoMonthIndex) {
                    return 'date-range-selector-calendar-day-content-selected'
                }
            }
            if (cal === 1) {
                if (selectedMonthIndex_ending === nextMoMonthIndex) {
                    return 'date-range-selector-calendar-day-content-selected'
                }
            }
        }

        // Check if this cell is bewtween selected dates
        if (selectionStage === 0) {
            let moNum = cal === 0 ? thisMoMonthIndex : nextMoMonthIndex;
            let yrNum = cal === 0 ? thisMoYear : nextMoYear;
            let thisDate = new Date(yrNum, moNum, day);
            let thisDateTS = thisDate.getTime();

            if (thisDateTS > selectedTimestamp && thisDateTS <= selectedTimestamp_ending) {
                return 'date-range-selector-calendar-day-content-selected-minor'
            }
        }


        // Check if date is before first selected date
        if (selectionStage === 1) {
            let moNum = cal === 0 ? thisMoMonthIndex : nextMoMonthIndex;
            let yrNum = cal === 0 ? thisMoYear : nextMoYear;
            let thisDate = new Date(yrNum, moNum, day);
            let thisDateTS = thisDate.getTime();

            if (thisDateTS < selectedTimestamp) {
                return 'date-range-selector-calendar-day-content-no-select'
            }
        }
    }

    function donePressed() {
        if (selectionStage === 1) { return; }

        setShowingCalendar(false);

        props.updateDateRange(selectedTimestamp, selectedTimestamp_ending);
    }

    function cancelPressed() {
        setSelectionStage(0);
        setShowingCalendar(false);

        setSelectedTimestamp(props.startingTimestamp);
        let thisDate = new Date(props.startingTimestamp);

        setSelectedMonthIndex(thisDate.getMonth());
        setSelectedYer(thisDate.getFullYear());
        setSelectedDay(thisDate.getDate());

        // Configure ending timestamp and date consts
        let endingDateTimestamp = props.endingTimestamp; //thisDate.getTime() + durationTime;
        let endingDate = new Date(endingDateTimestamp);
        setSelectedTimestamp_ending(endingDateTimestamp);

        setSelectedMonthIndex_ending(endingDate.getMonth());
        setSelectedYer_ending(endingDate.getFullYear());
        setSelectedDay_ending(endingDate.getDate());

    }

	return (
		<div className="date-range-selector">
			<div onClick={() => toggleShowingCalendar()} className={`date-range-selector-input-container ${showingCalendar ? 'date-range-selector-input-container-selected' : ''}`}>
                <div className="date-range-selector-input-container-date-icon-container">
                    <CalendarToday className="date-range-selector-input-container-date-icon"/>
                </div>
                <h4>{getDateString()}</h4>
            </div>
            <CSSTransition in={showingCalendar} timeout={200} classNames="menu" unmountOnExit appear>
                <div className={`date-range-selector-calendar ${props.isLeft !== undefined && props.isLeft === true ? 'date-range-selector-calendar-left' : ''} ${props.isAbove !== undefined && props.isAbove === true ? 'date-range-selector-calendar-above' : ''}`}>
                    <div hidden={true} className="date-range-selector-calendar-header">
                        <div className="date-range-selector-calendar-header-text-container">
                            <h4>{props.title !== undefined ? props.title : "Select a date range"}</h4>
                        </div>
                        <div onClick={() => setShowingCalendar(false)} className="date-range-selector-calendar-header-close-icon-container">
                            <Close className="date-range-selector-calendar-header-close-icon"/>
                        </div>
                    </div>
                    <div className="date-range-selector-calendar-body">
                        <div className="date-range-selector-calendar-month-row">
                            <div onClick={() => previousMonth()} className="date-range-selector-calendar-month-row-button">
                                <ArrowBackIos className="date-range-selector-calendar-month-row-button-icon"/>
                            </div>
                            <div className="date-range-selector-calendar-month-row-text-container">
                                <h4>{moStrings_full[thisMoMonthIndex]} {selectedYear}</h4>
                            </div>
                            <div className="date-range-selector-calendar-month-row-text-container">
                                <h4>{moStrings_full[nextMoMonthIndex]} {nextMoYear}</h4>
                            </div>
                            <div onClick={() => nextMonth()} className="date-range-selector-calendar-month-row-button">
                                <ArrowForwardIos className="date-range-selector-calendar-month-row-button-icon"/>
                            </div>
                        </div>
                        <div className="date-range-selector-calendar-weekday-row-outter">
                            <div className="date-range-selector-calendar-weekday-row">
                                { wkDayStrings.map((item: string, index: number) => (
                                    <div className="date-range-selector-calendar-weekday-container">
                                        <p>{item}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="date-range-selector-calendar-weekday-row">
                                { wkDayStrings.map((item: string, index: number) => (
                                    <div className="date-range-selector-calendar-weekday-container">
                                        <p>{item}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="date-range-selector-calendar-days-container-outter">
                            <div className="date-range-selector-calendar-days-container">
                                {
                                    allDays.map((item: number, index: number) => (
                                        <div onClick={() => daySelected(item, 0)} className={`date-range-selector-calendar-day-content ${getCellHighlightClass(item, 0)} ${item === -1 ? 'date-range-selector-calendar-day-content-invisible' : ''}`}>
                                            <div hidden={item === -1} className="date-range-selector-calendar-day-content-inner">
                                                <p>{item}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="date-range-selector-calendar-days-container">
                                {
                                    allDaysNextMo.map((item: number, index: number) => (
                                        <div onClick={() => daySelected(item, 1)} className={`date-range-selector-calendar-day-content ${getCellHighlightClass(item, 1)} ${item === -1 ? 'date-range-selector-calendar-day-content-invisible' : ''}`}>
                                            <div hidden={item === -1} className="date-range-selector-calendar-day-content-inner">
                                                <p>{item}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        
                    </div>
                    <div className="date-range-selector-calendar-footer">
                        <div hidden={selectionStage === 1} className="date-range-selector-calendar-header-text-container">
                            <h4>{getDurationDays()}</h4>
                            <p>days</p>
                        </div>
                        <div hidden={selectionStage === 0} className="date-range-selector-calendar-header-text-container">
                            <p>Select ending date</p>
                        </div>
                        <div className="date-range-selector-calendar-footer-buttons-container">
                            <div onClick={() => cancelPressed()} className="date-range-selector-calendar-footer-button date-range-selector-calendar-footer-button-dark">
                                <p>Cancel</p>
                            </div>   
                            <div onClick={() => donePressed()} className={`date-range-selector-calendar-footer-button date-range-selector-calendar-footer-button-blue ${selectionStage === 1 ? 'date-range-selector-calendar-footer-button-blue-unselected' : ''}`}>
                                <p>Done</p>
                            </div>    
                        </div>
                    </div>
                </div>
            </CSSTransition>
		</div>
	)
}

export default DateRangeSelector;


