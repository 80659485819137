import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './IntraWorkoutOverviewSummaryStats.css';

import CurrentWorkout_t from '../../../Interfaces/CurrentWorkout_t';
import Tempo_t from '../../../Interfaces/Tempo_t';
import SetStats_t from '../../../Interfaces/SetStats_t';

import FormTempoInput from '../../../components/Base/FormTempoInput';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function IntraWorkoutOverviewSummaryStats(props: any) {

    const [initSet, setInitSet] = useState(false);

    // Input value states
    const [inputVal_reps, setInputVal_reps] = useState(0);
    const [inputVal_weight, setInputVal_weight] = useState(0);
    const [inputVal_tempo, setInputVal_tempo] = useState<Tempo_t>({eccentric: -1, concentric: -1, first_pause: -1, second_pause: -1});
    const [inputVal_rest, setInputVal_rest] = useState(0);

    // Input focus states
    const [isFocused_reps, setIsFocused_reps] = useState(false);
    const [isFocused_weight, setIsFocused_weight] = useState(false);
    const [isFocused_tempo, setIsFocused_tempo] = useState(false);
    const [isFocused_rest, setIsFocused_rest] = useState(false);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        updateValues();
    }

    useEffect(() => {
        updateValues();
    }, [props.selectedSetIndex, props.selectedExerciseSeriesIndex, props.selectedExerciseIndex]);

    function updateValues() {

        // console.log("IntraWorkoutOverviewSummaryStats: UPDATE VALUES", props.selectedSetIndex, props.selectedExerciseSeriesIndex, props.selectedExerciseIndex, props.currentWorkout);

        if (props.currentWorkout !== undefined && props.selectedSetIndex !== undefined && props.selectedExerciseSeriesIndex !== undefined && props.selectedExerciseIndex !== undefined) {
            if (props.selectedSetIndex === -1) {

            } else {
                if (props.selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                        props.selectedSetIndex < props.currentWorkout.setStats[props.selectedExerciseSeriesIndex].length && 
                        props.selectedExerciseIndex < props.currentWorkout.setStats[props.selectedExerciseSeriesIndex][props.selectedSetIndex].length) {

                    let thisSetStat: SetStats_t = props.currentWorkout.setStats[props.selectedExerciseSeriesIndex][props.selectedSetIndex][props.selectedExerciseIndex];
                    // console.log(thisSetStat);

                    setInputVal_reps(thisSetStat.anticipatedReps);
                    setInputVal_weight(thisSetStat.weight);
                    setInputVal_rest(thisSetStat.restTime);
                    
                    if (thisSetStat.tempo !== undefined) {
                        setInputVal_tempo(thisSetStat.tempo);
                    }

                    return;
                }
            }
        }

        setInputVal_reps(-1);
        setInputVal_weight(-1);
        setInputVal_tempo({eccentric: -1, concentric: -1, first_pause: -1, second_pause: -1});
        setInputVal_rest(-1);
    }

    function updateInputFocusedState(toState: boolean, forInput: 'rep' | 'weight' | 'tempo' | 'rest') {
        switch (forInput) {
            case 'rep':
                setIsFocused_reps(toState);
                break;
            case 'weight':
                setIsFocused_weight(toState);
                break;
            case 'tempo':
                setIsFocused_tempo(toState);
                break;
            case 'rest':
                setIsFocused_rest(toState);
                break;
            default:
                break;
        }
    }

    function inputChanged(forInput: 'rep' | 'weight' | 'tempo' | 'rest', toString: any) {
        switch (forInput) {
            case 'rep':
                let numReps = Number(toString);
                if (!isNaN(numReps)) {
                    //setInputVal_reps(numReps);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.changeReps(numReps);
                }
                
                break;
            case 'weight':
                let numWeight = Number(toString);
                if (!isNaN(numWeight)) {
                    //setInputVal_weight(numWeight);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.changeWeight(numWeight);
                }
                
                break;
            case 'tempo':
                let tempoRaw = toString as Tempo_t;
                //setInputVal_tempo(tempoRaw);
                //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                props.changeTempo(tempoRaw);
                
                break;
            case 'rest':
                let numRest = Number(toString);
                if (!isNaN(numRest)) {
                    //setInputVal_rest(numRest);
                    //// console.log("CreateWorkoutExerciseListCell | inputChanged: number of reps: ", numReps, toString);
                    props.changeRest(numRest);
                }
                
                break;
            default:
                break;
        }
    }

    function getStat(type: 'rep' | 'weight' | 'tempo' | 'rest') {
        if (props.currentWorkout !== undefined && props.selectedSetIndex !== undefined && props.selectedExerciseSeriesIndex !== undefined && props.selectedExerciseIndex !== undefined) {
            if (props.selectedSetIndex === -1) {

            } else {
                if (props.selectedExerciseSeriesIndex < props.currentWorkout.setStats.length && 
                        props.selectedSetIndex < props.currentWorkout.setStats[props.selectedExerciseSeriesIndex].length && 
                        props.selectedExerciseIndex < props.currentWorkout.setStats[props.selectedExerciseSeriesIndex][props.selectedSetIndex].length) {

                    let thisSetStat: SetStats_t = props.currentWorkout.setStats[props.selectedExerciseSeriesIndex][props.selectedSetIndex][props.selectedExerciseIndex];

                    switch (type) {
                        case 'rep':
                            return thisSetStat.manualEntryReps;
                            
                            break;
                        case 'weight':
                            return thisSetStat.weight;

                            break;
                        case 'tempo':
                            return thisSetStat.tempo === undefined ? {eccentric: -1, concentric: -1, first_pause: -1, second_pause: -1} : thisSetStat.tempo;
                            
                            break;
                        case 'rest':
                            return thisSetStat.restTime;
                            
                            break;
                        default:
                            return -1;
                            break;
                    }
                    
                }
            }
        }

        return -1;
    }

	return (
		<div className="intra-workout-overview-summary-stats">
			<div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-sm">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>REPS</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_reps ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <IonInput
                        value={getStat('rep') <= 0 ? "" : `${getStat('rep')}`}
                        className="intra-workout-overview-summary-stats-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'rep')}
                        onBlur={() => updateInputFocusedState(false, 'rep')}
                        onIonChange={e => inputChanged('rep', e.detail.value!)}/>
                </div>
            </div>
            <div className="intra-workout-overview-summary-stats-x-icon-container">
                <Close className="intra-workout-overview-summary-stats-x-icon"/>
            </div>
            <div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-md">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>WEIGHT</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_weight ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <IonInput
                        value={getStat('weight') <= 0 ? "" : `${getStat('weight')}`}
                        className="intra-workout-overview-summary-stats-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'weight')}
                        onBlur={() => updateInputFocusedState(false, 'weight')}
                        onIonChange={e => inputChanged('weight', e.detail.value!)}/>
                    <div className="intra-workout-overview-summary-stats-input-units-container">
                        <p>{props.currentWorkout !== undefined && props.currentWorkout.isKG === true ? "KG" :  "LBS"}</p>
                    </div>
                </div>
            </div>
            <div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-lg">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>AVERAGE TEMPO</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_tempo ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <FormTempoInput
                        thisTempo={getStat('tempo')}
                        noBorder={true}
                        focusChanged={(e: boolean) => updateInputFocusedState(e, 'tempo')}
                        tempoUpdated={(t: Tempo_t) => inputChanged('tempo', t)}/>
                </div>
            </div>
            <div className="intra-workout-overview-summary-stats-input-container-outter intra-workout-overview-summary-stats-input-container-outter-md">
                <div className="intra-workout-overview-summary-stats-input-header">
                    <p>REST</p>
                </div>
                <div className={`intra-workout-overview-summary-stats-input-container ${isFocused_rest ? 'intra-workout-overview-summary-stats-input-container-focused' : ''}`}>
                    <IonInput
                        value={getStat('rest') <= 0 ? "" : `${getStat('rest')}`}
                        className="intra-workout-overview-summary-stats-input"
                        placeholder={`--.-`}
                        inputmode="numeric"
                        clearOnEdit={true}
                        type="number"
                        onFocus={() => updateInputFocusedState(true, 'rest')}
                        onBlur={() => updateInputFocusedState(false, 'rest')}
                        onIonChange={e => inputChanged('rest', e.detail.value!)}/>
                    <div className="intra-workout-overview-summary-stats-input-units-container">
                        <p>s</p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default IntraWorkoutOverviewSummaryStats;