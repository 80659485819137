import React, { useState, useEffect } from 'react';
import Mixpanel from '../../Mixpanel';
import { IonInput } from '@ionic/react';

import './NewExerciseModal.css';
import './NewClientModal.css';

import firebase from '../../Firebase';
import axios from 'axios';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import Muscles_t from '../../Interfaces/Muscles_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import Tempo_t from '../../Interfaces/Tempo_t';


import ExerciseBuilder from '../Exercises/ExerciseBuilder';
import ExerciseCell from '../Workouts/ExerciseCell';
import MuscleSelector from '../Base/MuscleSelector';
import MultiSelect from '../Base/MultiSelect';
import DualSelector from '../Base/DualSelector';
import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';
import FormDateInput from '../Base/FormDateInput';
import Selector from '../Base/Selector';
import SelectorWithPills from '../Base/SelectorWithPills';
import FormBodyMeasurementInput from '../Base/FormBodyMeasurementInput';
import CreateWorkoutExerciseList from '../Workouts/WorkoutBuilder/CreateWorkoutExerciseList';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import Public from '@material-ui/icons/Public';
import Home from '@material-ui/icons/Home';
import Group from '@material-ui/icons/Group';
import Lock from '@material-ui/icons/Lock';


function NewExerciseModal(props: any) {

    let pageNames = ["Exercise Info", "Muscles", "Equipment", "Setup", "Availability"];
    let pageDescs = ["", "Define all of the muscles that your Exercise will target.", "", "", "", "", ""]
	const workoutDifficulties = [{title: 'Beginner', desc: 'Easy for those new to lifting'}, {title: 'Light', desc: 'For those who have been to the gym'}, {title: 'Moderate', desc: 'Slightly intense for most'}, {title: 'Difficult', desc: 'Challenging to most gym goers'}, {title: 'Extremely Challenging', desc: 'Challenging, even to gym frequenters'}];

	let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

	let muscles: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]
        ]

    let equipment = {
        machines: [
          "None",
          "Single Cables",
          "Double Cables",
          "Weighted Ab Crunch",
          "Weighted Trunk Roatator",
          "Parallel Bars",
          "Incline Chest Press",
          "Chest Press",
          "Decline Chest Press",
          "Pec Fly",
          "Shoulder Press",
          "Lateral Shoulder Raise",
          "Rear Delt Extensor",
          "Shrug Machine",
          "Deadlift Machine",
          "Spinal Errector",
          "Cable Pulldown",
          "Pulldown",
          "Low Pull Back",
          "High Pull Back",
          "Straight Pull Cable",
          "Leg Press",
          "Quad Extensor",
          "Lying Hamstring Curls",
          "Seated Hamstring Curls",
          "Standing Calves Raises",
          "Seated Calves Raises",
          "Hip Abductor",
          "Hip Adductor",
          "Squat Rack",
          "Bench Rack",
          "Shoudler Rack",
          "Double Arm Curl",
          "Single Arm Curl",
          "Tricep Extensor",
          "Overhead Tricep Extensor",
          "Machine Dips",
          "Custom"
        ],
        benches: [
          "None",
          "Flat",
          "Large Incline",
          "Slight Incline",
          "Large Decline",
          "Slight Decline",
          "L-seat",
          "Bench Press",
          "Shoulder Press",
          "Custom"
        ],
        bars: [
          "None",
          "Full Bar",
          "Short Bar",
          "Curl Bar",
          "Swiss Bar",
          "Tricep Bar",
          "Fixed Weight Barbell",
          "Custom"
        ],
        cable_attachments: [
          "None",
          "Double Ropes",
          "Single Rope",
          "Flat Bar",
          "Curl Bar",
          "Double-D Handles",
          "Signle-D Handle",
          "Short Parallel Bar",
          "Pulldown Bar",
          "Side Grip Lat Bar",
          "Tricep V-Bar",
          "Head Harness",
          "Foot Harness",
          "Custom"
        ]
      }

	let initExercise: Exercise_t = {
		title:'',
		id:'',
		type: 0,
		muscles:[],
		equipment: {
			machine: -1,
			bench: -1,
			bar: -1,
			cable_attachment: -1
		},
        setup: ""
	}


    // ----------------
    const [isValid, setIsValid] = useState(false);
    const [saveCallback, setSaveCallback] = useState<any | undefined>();

    // ----------------
	
	const [exercise, setExercise] = useState<Exercise_t>(initExercise);
    const [finalExercise, setFinalExercise] = useState<Exercise_t>(initExercise);

	const [workflowState, setWorkflowState] = useState(0);
    const [searchState, setSearchState] = useState(0); // 0 = no search, 1 = loading, 2 = display results, 3 = no results found
	const [showingSearchResults, setShowingSearchResults] = useState(true);
	const [searchResults, setSearchResults] = useState<Exercise_t[]>([]);
	const [allExercises, setAllExercises] = useState<Exercise_t[]>([]);

	const [selectedAvailabilityState, setSlectedAvailabilityState] = useState(1);
	const [selectedMuscles, setSelectedMuscles] = useState<Muscles_t[]>([]);
	const [steps, setSteps] = useState<string[]>([]);
	const [currentStep, setCurrentStep] = useState<string>("");
	const [videoLink, setVideoLink] = useState<string>("");

	const [customBarName, setCustomBarName] = useState("");
	const [customBenchName, setCustomBenchName] = useState("");
	const [customAttachmentName, setCustomAttachmentName] = useState("");
	const [customMachineName, setCustomMachineName] = useState("");

	const [alerts, setAlerts] = useState<boolean[]>([false,false,false,false,false,false,false]);
	const [initSet, setInitSet] = useState(false);

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
    	let thisUser = firebase.auth().currentUser;

    	let createdByName: string = thisUser !== null && thisUser.displayName !== null ? thisUser.displayName! : 'Eigen Fitness';
    	let createdById: string = thisUser !== null  && thisUser.uid !== null ? thisUser.uid : 'eigen_fit'
        let createdByObj: CreatedBy_t = {
        	name: createdByName, 
        	id: createdById
        };
        setExercise({...exercise, createdBy:createdByObj});
        pullAllExercises();

        // Start mixpanel timing event
        Mixpanel.time_event("New exercise created");
    }

    useEffect(() => {
        if (props.onTheFlyExerciseAdding !== undefined && props.onTheFlyExerciseAdding === true && props.defaultTitleString !== undefined && props.defaultTitleString !== "") {
            setExercise({...exercise, title: props.defaultTitleString})
        }
    }, [props.defaultTitleString, props.onTheFlyExerciseAdding]);

    function pullAllExercises() {
    	let database = firebase.database();
		let ref = database.ref('exercises'); // Change to database path
		ref.on('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        //let data = snapshot.val();
		        
		        // Parse and use data here
		        let responseData = snapshot.val();
	            let responseExerciseArray: any = responseData;
	            let responseKeys = Object.keys(responseData);

                var tempExerciseArray: Exercise_t[] = [];
                for (var i = 0; i < responseKeys.length; i++) {
                    let thisExerciseObj = responseExerciseArray[responseKeys[i]];
                    let thisExerciseMuscles: Muscles_t[] = [];

                    if (thisExerciseObj.muscles !== null && thisExerciseObj.muscles !== undefined) {

                        for (var j = 0; j < thisExerciseObj.muscles.length; j++) {
                            let thisMuscle = thisExerciseObj.muscles[j];
                            let newMuscle: Muscles_t = {
                                group: Number(thisMuscle.group),
                                muscle: Number(thisMuscle.muscle)
                            }
                            thisExerciseMuscles.push(newMuscle);
                        }
                    }

                    let newExercise: Exercise_t = {
                        title: thisExerciseObj.title,
                        id: responseKeys[i],
                        createdBy: {name: thisExerciseObj.created_by.name, id: thisExerciseObj.created_by.id},
                        muscles: thisExerciseMuscles,
                        type: thisExerciseObj.type,
                        equipment: thisExerciseObj.equipment,
                        tags: thisExerciseObj.tags,
                        setup: thisExerciseObj.setup,
                        steps: thisExerciseObj.steps,
                        videoLink: thisExerciseObj.video_link
                    }
                    tempExerciseArray.push(newExercise);
                }


                var tempExerciseArray_adj = [];
                for (var i = 0; i < tempExerciseArray.length; i++) {
                    let i_adj = tempExerciseArray.length - 1 - i;
                    tempExerciseArray_adj.push(tempExerciseArray[i_adj]);
                }
                // console.log("tempExerciseArray_adj");
                // console.log(tempExerciseArray_adj);
                setAllExercises(tempExerciseArray_adj);
		    }
		});
    }

	function getWorkflowClassName(state: number) {
		return workflowState === state ? "new-client-selected-workflow-state" : (state < workflowState ? "new-client-completed-workflow-state" : "new-client-unselected-workflow-state");
	}

	function nextButtonPressed() {
		// console.log("NEXT BUTTON PRESSED");
		
	}

    function clearAll() {
        setExercise(initExercise);
        setFinalExercise(initExercise);
        setSteps(steps.filter(item => false));
        setVideoLink("");
        setSelectedMuscles(selectedMuscles.filter(item => false));
        setCurrentStep("");
        setCustomBarName("");
        setCustomBenchName("");
        setCustomMachineName("");
        setCustomAttachmentName("");
        setSlectedAvailabilityState(1);

        setAlerts([false,false,false,false,false,false,false]);
        setWorkflowState(0);
    }

	function continuePressed() {

		if (workflowState === 6) {

            if (props.onTheFlyExerciseAdding !== undefined && props.onTheFlyExerciseAdding === true) {
                props.closeModal(finalExercise);
                clearAll();
            } else {
                props.closeModal();
                clearAll();
            }

			return;
		}

		if (validatePage() === true) {
			// if (workflowState === 0 && searchState === 0) {
			// 	searchExercises();
			// 	return;
			// }

			if (workflowState === 4) {
				configureNewExercise();
			}
			setWorkflowState(workflowState + 1);
		}
		
	}

	function backPressed() {
		// if (workflowState === 0) {
		// 	setSearchState(0);
		// 	setSearchResults(searchResults.filter(item => false));
		// 	return;
		// }

        Mixpanel.track("New exercise back pressed",{
            from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'
        });

		if (workflowState === 1) {
			// setSearchState(0);
			// setSearchResults(searchResults.filter(item => false));
		}
		setWorkflowState(workflowState - 1);
	}

    function closeModal() {

        if (workflowState >= 1 || exercise.title.split('').length >= 2) {
            props.getUserConfirmation({
                title: "Are you sure you want to exit?",
                text: `Exiting the Exercise builder will erase your progress creating '${exercise.title}'.`,
                isRed: false,
                callback: confirmCloseModal,
                ctaText: "Close"
            });

            Mixpanel.track("Close new exercise modal requested",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
        } else {
            confirmCloseModal();
        }
    }

	function confirmCloseModal() {
        Mixpanel.track("Close new exercise modal confirmed",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
        clearAll();
        props.closeModal();
	}

	function toggleShowingSearchResults() {
		setShowingSearchResults(!showingSearchResults);
	}

	function searchExercises(withString: string) {

		var results: Exercise_t[] = [];

		for (var i = 0; i < allExercises.length; i++) {
			let thisObj: Exercise_t = allExercises[i];
			let thisObjTitle = thisObj.title.toUpperCase();
			let uppercaseInputString = withString.toUpperCase();
			let searchTextMatchesTitle = uppercaseInputString.includes(thisObjTitle); //thisObjTitle.includes(uppercaseInputString);

      if (searchTextMatchesTitle == true) {
        results.push(thisObj);
      }

			// var searchTextMatchesTag = false;
			// if (thisObj.tags !== undefined && thisObj.tags !== null) {
			// 	for (var j = 0; j < thisObj.tags.length; j++) {
			// 		let thisTag = thisObj.tags[j];
			// 		let thisTagUpperCase = thisTag.toUpperCase();
			// 		if (thisTagUpperCase.includes(exercise.title.toUpperCase())) {
			// 			searchTextMatchesTag = true;
			// 		}
			// 	}
			// }

			// if (searchTextMatchesTitle === true || searchTextMatchesTag === true) {
   //              results.push(thisObj);
   //          } else {
   //              // Try breaking each string into different words. If word match > 2, add to results
   //              let testStringWords = thisObjTitle.split(' ');
   //              let inputStringWords = uppercaseInputString.split(' ');
   //              if (testStringWords !== null && testStringWords !== undefined && testStringWords.length > 0
   //                  && inputStringWords !== null && inputStringWords !== undefined && inputStringWords.length > 0) {

   //                  var matchingWordCount = 0;
   //                  for (var a = 0; a < testStringWords.length; a++) {
   //                      let thisTestWord = testStringWords[a];
   //                      for (var b = 0; b < inputStringWords.length; b++) {
   //                          let thisInputWord = inputStringWords[b];
   //                          let thisInputWordLetters = thisInputWord.split('');
   //                          if (thisInputWordLetters.length >= 2) {
   //                          	let wordsMatch = thisTestWord.includes(thisInputWord);
	  //                           if (wordsMatch === true) {
	  //                               matchingWordCount += 1;
	  //                           }
   //                          }
   //                      }
   //                  }
   //                  if (matchingWordCount >= 2) {
   //                      results.push(thisObj);
   //                  }
   //              }
   //          }

            
		}

		setSearchResults(results);

		/*

		setSearchState(1);

        let sendData = {
            text: exercise.title,
            muscle_group: 0
        };
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/searchAllExercises`,
            sendData,
            { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response.data);
            setSearchState(2);
            let responseData = response.data;
            let responseExerciseArray: any[] = responseData.resp;
            if (responseExerciseArray.length === 0) {
                // No exercises found for search. Present no results found page (state 3)
                setWorkflowState(workflowState + 1);
            } else {
                var tempExerciseArray: Exercise_t[] = [];
                for (var i = 0; i < responseExerciseArray.length; i++) {
                    let thisExerciseObj = responseExerciseArray[i];
                    let thisExerciseMuscles: Muscles_t[] = [];

                    if (thisExerciseObj.muscles !== null && thisExerciseObj.muscles !== undefined) {

                        for (var j = 0; j < thisExerciseObj.muscles.length; j++) {
                            let thisMuscle = thisExerciseObj.muscles[j];
                            let newMuscle: Muscles_t = {
                                group: Number(thisMuscle.group),
                                muscle: Number(thisMuscle.muscle)
                            }
                            thisExerciseMuscles.push(newMuscle);
                        }
                    }

                    let newExercise: Exercise_t = {
                        title: thisExerciseObj.title,
                        id: thisExerciseObj.id,
                        createdBy: {name: thisExerciseObj.created_by.name, id: thisExerciseObj.created_by.id},
                        muscles: thisExerciseMuscles,
                        type: thisExerciseObj.type,
                        equipment: thisExerciseObj.equipment,
                        tags: thisExerciseObj.tags,
                        setup: thisExerciseObj.setup,
                        steps: thisExerciseObj.steps,
                        videoLink: thisExerciseObj.video_link
                    }
                    tempExerciseArray.push(newExercise);
                }


                var tempExerciseArray_adj = [];
                for (var i = 0; i < tempExerciseArray.length; i++) {
                    let i_adj = tempExerciseArray.length - 1 - i;
                    tempExerciseArray_adj.push(tempExerciseArray[i_adj]);
                }

                setSearchResults(tempExerciseArray_adj);
            }
        })
        .catch(e => {
            // console.log(e);
            setWorkflowState(workflowState + 1);
        })

        */
    }

    function getShowTitleFeedback() {
    	let titleLetters = exercise.title.split('');
    	return getShowTitleFeedback.length >= 5;
    }

    function inputUpdated(e: any) {
        // console.log(e);
        if (e === "Enter") {
            addStepPressed();
        }
    }


	function inputChanged(toString: any, fromInput: string) {
		// console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)
		
		switch (fromInput) {
			case 'exercise-title':
				let letters = toString.split('');
				setExercise({...exercise,title: toString});
				if (letters.length > 5) {
					searchExercises(toString);
				} else {
					if (searchResults.length > 0) {
						setSearchResults(searchResults.filter(item => false));
					}
				}
				if (alerts[0] === true && letters.length >= 5) {
					setAlerts(alerts.map((item, index) => { return index === 0 ? false : item }));
				}
				break;
			case 'video-link':
				setVideoLink(toString);
				break;
			case 'search-tags':
				let tags: string[] = toString.split(',');
				setExercise({...exercise,tags: tags});
				setAlerts(alerts.map((item, index) => { return index === 1 ? false : item }));
				break;
			case 'exercise-type': 
				setExercise({...exercise,type: Number(toString)});
				break
			case 'exercise-setup':
				let letters_setup = toString.split('');
				// if (alerts[3] === true && letters_setup.length >= 4) {
				// 	setAlerts(alerts.map((item, index) => { return index === 3 ? false : item }));
				// }
				setExercise({...exercise,setup: toString});
				break;
			case 'machine-selected': 
				let equipment_temp_m = exercise.equipment!;
				let index_m = equipment.machines.indexOf(toString);
				equipment_temp_m.machine = index_m;
				setExercise({...exercise, equipment: equipment_temp_m});
				break;
			case 'bench-selected': 
				let equipment_temp_be = exercise.equipment!;
				let index_be = equipment.benches.indexOf(toString);
				equipment_temp_be.bench = index_be;
				setExercise({...exercise, equipment: equipment_temp_be});
				break;
			case 'bar-selected': 
				let equipment_temp_b = exercise.equipment!;
				let index_b = equipment.bars.indexOf(toString);
				equipment_temp_b.bar = index_b;
				setExercise({...exercise, equipment: equipment_temp_b});
				break;
			case 'attachment-selected': 
				let equipment_temp_a = exercise.equipment!;
				let index_a = equipment.cable_attachments.indexOf(toString);
				equipment_temp_a.cable_attachment = index_a;
				setExercise({...exercise, equipment: equipment_temp_a});
				break;
			case 'custom-bar-name':
				setCustomBarName(toString);
				break;
			case 'custom-bench-name':
				setCustomBenchName(toString);
				break;
			case 'custom-attachment-name':
				setCustomAttachmentName(toString);
				break;
			case 'custom-machine-name':
				setCustomMachineName(toString);
				break;
			case 'exercise-setup':
				setExercise({...exercise, setup: toString});
				break;
			case 'exercise-step':
				setCurrentStep(toString);
				break;
			default:
				// console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
				break;
		}
	}

	function validatePage(forPage?: number) {
		var okayToProceed = true;

		//return true;

		switch (forPage === undefined ? workflowState : forPage) {
			case 0: 			// Exercise information page
				setAlerts(alerts.map((item, index) => { 
					switch (index) {
						case 0: 	// full name
							// console.log(exercise.title);
							let letters_title = exercise.title.split('');
							okayToProceed = letters_title.length >= 5;
                            //// console.log(`validatePage | index: ${0}, alert index: ${index} | okayToProceed = ${okayToProceed}, ${exercise.title }`)
							return letters_title.length < 5;
							break;
						case 1: 	// workout description
							//okayToProceed = true;
							return false;
							break;
						default:
							return false;
							break;
					}
				}))

				break;
			case 1:
				setAlerts(alerts.map((item, index) => { 
					switch (index) {
						case 2: 	// full name
							okayToProceed = selectedMuscles.length > 0
							return selectedMuscles.length === 0;
							break;
						default:
							return item;
							break;
					}
				}));
				break;
			case 2:
				// setAlerts(alerts.map((item, index) => { 
				// 	// console.log(`(${item} , ${index})`);
				// 	switch (index) {
				// 		case 3: 	// full name
				// 			okayToProceed = selectedImageIndex >= 0;
				// 			return selectedImageIndex < 0;
				// 			break;
				// 		default:
				// 			return false;
				// 			break;
				// 	}
				// }))
				break;
			case 3:
				setAlerts(alerts.map((item, index) => { 
					switch (index) {
						case 4: 	// full name
							//let letters_setup = exercise.setup === undefined ? [] : exercise.setup.split('');
							//okayToProceed = letters_setup.length > 2
							return false;// letters_setup.length < 2;
							break;
						default:
							return item;
							break;
					}
				}));
				break;
			case 4:
				okayToProceed = true;
			default:
                okayToProceed = true;
				break;
		}

        //if (forPage === undefined) {
            // for (var i = 0; i < alerts.length; i++) {
            //     if (alerts[i] === true) {
            //         // console.log(alerts[i]);
            //         okayToProceed = false;
            //     }
            // }
        //}
		

		return okayToProceed;
	}

	function configureNewExercise() {

        // console.log("Calling callback: ", saveCallback);
        if (saveCallback !== undefined) {
            saveCallback();
        }
        return;

        /*
		var tags_temp = [exercise.title];

		for (var i = 0; i < selectedMuscles.length; i++) {
			let thisMuscle: Muscles_t = selectedMuscles[i];
			let thisGroupName = thisMuscle.group < muscle_groups.length ? muscle_groups[thisMuscle.group] : "";
			if (tags_temp.indexOf(thisGroupName) < 0) {
				tags_temp.push(thisGroupName);
			}
		}

		var equipmentObj: any = {bar: -1, bench: -1, cable_attachment: -1, machine: -1};
		if (exercise.equipment !== undefined) {
			equipmentObj.bar = exercise.equipment!.bar === equipment.bars.length - 1 ? -2 : exercise.equipment!.bar;
			equipmentObj.bench = exercise.equipment!.bench === equipment.benches.length - 1 ? -2 : exercise.equipment!.bench;
			equipmentObj.cable_attachment = exercise.equipment!.cable_attachment === equipment.cable_attachments.length - 1 ? -2 : exercise.equipment!.cable_attachment;
			equipmentObj.machine = exercise.equipment!.machine === equipment.machines.length - 1 ? -2 : exercise.equipment!.machine;

			if (equipmentObj.bar === -2) {
				equipmentObj["custom_bar_name"] = customBarName;
			}
			if (equipmentObj.bench === -2) {
				equipmentObj["custom_bench_name"] = customBenchName;
			}
			if (equipmentObj.cable_attachment === -2) {
				equipmentObj["custom_cable_attachment_name"] = customAttachmentName;
			}
			if (equipmentObj.machine === -2) {
				equipmentObj["custom_machine_name"] = customMachineName;
			}
		}
		

		let exerciseJSON = {
			created_by: {
				id: "eigen_fit",
				name: "Eigen Fitness"
			},
			equipment: equipmentObj,//exercise.equipment,
			muscles: selectedMuscles,
			setup: exercise.setup === undefined ? "" : exercise.setup,
			steps: steps,
			title: exercise.title,
			type: exercise.type,
			tags: tags_temp,
			video_link: videoLink,
			availability: selectedAvailabilityState
		};

		// console.log("exerciseJSON:", exerciseJSON);

		let db = firebase.database();
		let dbLogRef = db.ref(`exercises`);
		let pushRef = dbLogRef.push(exerciseJSON);

        let id = pushRef.key;

        let finalE: Exercise_t = {
            id: id === null ? '' : id,
            title: exercise.title,
            type: exercise.type,
            tags: tags_temp,
            videoLink: videoLink,
            availability: selectedAvailabilityState,
            createdBy: {id: "eigen_fit", name: "Eigen Fitness"},
            muscles: selectedMuscles,
            setup: exercise.setup === undefined ? "" : exercise.setup,
            steps: steps,
            equipment: equipmentObj
        }

        setFinalExercise(finalE);


        // Track in Mixpanel
        let availabilityStrings = ["Public","Gym-only","Clients-only","Private"];
        Mixpanel.track("New exercise created",{
            num_similar_titles: searchResults.length, 
            num_muscles: muscles.length,
            num_steps: steps.length,
            muscle_group: selectedMuscles.length > 0 ? muscle_groups[selectedMuscles[0].group] : 'unknown',
            availability: selectedAvailabilityState < availabilityStrings.length ? availabilityStrings[selectedAvailabilityState] : 'unknown'
        });

        Mixpanel.people.increment("New exercises");

		setTimeout(() => {
			setWorkflowState(6);
		}, 1200);

        */
	}

	function searchForSimilarExercises() {

	}

	function availabilitySelected(a: number) {
		setSlectedAvailabilityState(a);
	}

	function muscleListUpdated(muscles: Muscles_t[]) {
		setSelectedMuscles(selectedMuscles.filter(item => false));
		setSelectedMuscles(muscles);
	}

	function addStepPressed() {
		setSteps(steps.concat(currentStep));
		setCurrentStep("");
	}

	function removeStepPressed(atIndex: number) {
		setSteps(steps.filter((item: string, index: number) => index !== atIndex))
	}

	function goToLibrary() {
		props.goToLibrary();
	}

	function getMusclesString(forExercise: Exercise_t) {

    	if (forExercise !== undefined && forExercise.muscles !== undefined && forExercise.muscles.length > 0) {
    		var muscleString = "";
    		for (var i = 0; i < forExercise.muscles.length; i++) {
    			let thisMuscle: Muscles_t = forExercise.muscles[i];
    			let thisMuscleString = muscles[thisMuscle.group][thisMuscle.muscle];

    			muscleString += thisMuscleString;
    			muscleString += i === forExercise.muscles.length - 1 ? '' : ', ';
    		}

    		return muscleString;
    	} else {
    		return 'No muscles defined'
    	}
    	return '';
    }

	function getEquipmentRequired(forExercise: Exercise_t) {
		if (forExercise.equipment !== undefined) {
			return forExercise.equipment.machine !== -1 || forExercise.equipment.cable_attachment !== -1 || forExercise.equipment.bar !== -1 || forExercise.equipment.bench !== -1;
		} else {
			return false;
		}
	}

	function getEquipmentString(forExercise: Exercise_t) {
		if (!getEquipmentRequired(forExercise)) {
			return 'No equipment specified for this exercise';
		}

		if (forExercise !== undefined && forExercise.equipment !== undefined) {
			var equipmentString = "";
			var numAdded = 0;

			if (forExercise.equipment.machine >= 0) {
				equipmentString += forExercise.equipment.machine + 1 < equipment.machines.length ? equipment.machines[forExercise.equipment.machine + 1] : "Custom machine";
				numAdded += 1;
			} else if (forExercise.equipment.machine === -2) {
                equipmentString += forExercise.equipment.custom_machine_name !== undefined ? forExercise.equipment.custom_machine_name : "Custom machine";
                numAdded += 1;
            }

			if (forExercise.equipment.bench >= 0) {
				equipmentString += numAdded > 0 ? ", " : "";
				equipmentString += forExercise.equipment.bench + 1 < equipment.benches.length ? equipment.benches[forExercise.equipment.bench + 1] : "Custom bench";
				numAdded += 1;
			} else if (forExercise.equipment.bench === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.custom_bench_name !== undefined ? forExercise.equipment.custom_bench_name : "Custom bench";
                numAdded += 1;
            }

			if (forExercise.equipment.cable_attachment >= 0) {
				equipmentString += numAdded > 0 ? ", " : "";
				equipmentString += forExercise.equipment.cable_attachment + 1 < equipment.cable_attachments.length ? equipment.cable_attachments[forExercise.equipment.cable_attachment + 1] : "Custom attachment";
				numAdded += 1;
			} else if (forExercise.equipment.cable_attachment === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.custom_cable_attachment_name !== undefined ? forExercise.equipment.custom_cable_attachment_name : "Custom attachment";
                numAdded += 1;
            }

			if (forExercise.equipment.bar >= 0) {
				equipmentString += numAdded > 0 ? ", " : "";
				equipmentString += forExercise.equipment.bar + 1 < equipment.bars.length ? equipment.bars[forExercise.equipment.bar + 1] : "Custom bar";
			} else if (forExercise.equipment.bar === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.custom_bar_name !== undefined ? forExercise.equipment.custom_bar_name : "Custom bar";
            }

			return equipmentString;
		} else {
			return "No equipment specified for this exercise"
		}
	}

    function jumpToPagePressed(atIndex: number) {
        if (atIndex <= workflowState) {
            // moving backwards - all good in all cases
            Mixpanel.track("New exercise jump page pressed",{
                from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                to_page: atIndex < pageNames.length ? pageNames[atIndex] : 'unknown',
                move_valid: true
            });
            setWorkflowState(atIndex);
        } else {
            // attempting to move forwards. Validate all pages from (including) the current page to the page before the moving-to page
            var moveValid = true;
            var furthestValidPageIndex = workflowState - 1;
            for (var i = workflowState; i < atIndex; i++) {
                let thisPageValid = validatePage(i);
                if (!thisPageValid) {
                    moveValid = false;
                } else {
                    if (moveValid === true) {
                        furthestValidPageIndex = i;
                    }
                    
                }
            }
            if (furthestValidPageIndex >= 0) {
                Mixpanel.track("New exercise jump page pressed",{
                    from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                    to_page: furthestValidPageIndex + 1 < pageNames.length ? pageNames[furthestValidPageIndex + 1] : 'unknown',
                    move_valid: moveValid
                });
                setWorkflowState(furthestValidPageIndex + 1);
            }
        }
    }

	return (
		<div className="new-client-container new-exercise-modal-container">
            <div className="new-workout-modal-header-container">
                <div className="new-workout-modal-header-close-container">
                    <div onClick={() => closeModal()} className="new-workout-modal-header-close-button">
                        <Close className="new-workout-modal-header-close-button-icon"/>
                    </div>
                </div>
                <div className="new-workout-modal-header-content-container">
                    <div className="new-workout-modal-header-content-icon-container">
                        <DirectionsRun className="new-workout-modal-header-content-icon"/>
                    </div>
                    <div className="new-workout-modal-header-content-text-container">
                        <h4>Create a new Exercise</h4>
                    </div>
                </div>
                
            </div>
            <div className="new-client-content-container new-exercise-content-container">
                <ExerciseBuilder 
                    exerciseUpdated={(e: Exercise_t) => setExercise(e)}
                    validationUpdated={(e: boolean) => setIsValid(e)}
                    updateSaveCallback={(f: any) => setSaveCallback(f)}
                    closeModal={(e?: Exercise_t) => props.closeModal(e)}/>
            </div>
            <div hidden={true} className={`new-client-header-container new-client-header-container-first-screen new-exercise-header-container${workflowState === 0 ? '-coloured' : ''}`}>
                <div hidden={workflowState !== 0}>
                    <div className="new-exercise-header-icon-container new-exercise-header-icon-container-first-screen">
                        <DirectionsRun className="new-exercise-header-icon"/>
                    </div>
                    <div className="new-client-header-text-container">
                        <h1>Create a new<br/>Exercise</h1>
                        <p>Exercises are the building blocks of your Workouts, and here is where you can add your own to the default Eigen Fitness Exercise Library.</p>
                    </div>
                    <div className="new-client-header-dots-container">
                        <div className="new-client-header-dot-long"/>
                        <div className="new-client-header-dot-short"/>
                        <div className="new-client-header-dot-short"/>
                    </div>
                </div>
                <div hidden={workflowState === 0}>
                    <div className="new-client-header-upper-content">
                        <div className="new-exercise-header-icon-container">
                            <DirectionsRun className="new-exercise-header-icon"/>
                        </div>
                        <h2>Create a new Exercise</h2>
                    </div>
                    <div className="new-client-header-body-container">
                        <div className="new-client-header-body-header">
                            <h1>Step {workflowState + 1}</h1>
                            <p>{pageDescs[workflowState]}</p>
                        </div>
                        <div className="new-client-header-body-content">
                            {pageNames.map((item: string, index: number) => (
                                <div onClick={() => jumpToPagePressed(index)} className={`new-client-header-body-row-container ${workflowState === index ? 'new-client-header-body-row-container-selected' : ''}`}>
                                    <div className="new-client-header-body-row-circle">
                                        <h4>{index + 1}</h4>
                                    </div>
                                    <div className="new-client-header-body-row-text-container">
                                        <h4>{item}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
			<div hidden={true} className="new-client-content-container">
				<div className="new-client-header-close-button-container">
                    <div onClick={() => closeModal()} className="new-client-header-close-button">
                        <Close className="new-client-header-close-button-close-icon"/>
                    </div>
                </div>
				<div className="new-workout-modal-page-full-height" hidden={workflowState !== 0 || searchState !== 0}>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-header">
                        <h2>Get started creating a new Exercise</h2>
                        <p>Choose a name for your new Exercise. This will appear in your Workout Library, your Plans, and to Clients that use this Exercise.</p>
                    </div>
					<div className="new-client-content-page-section-spacer"/>
					<FormTextInput 
                        value={exercise.title}
						placeholder="Exercise Title" 
						alert={alerts[0]} 
						alertText="Please enter an exercise name"
						inputChanged={(e: string) => inputChanged(e, 'exercise-title')}/>
					<div className="new-exercise-modal-exercise-search-result-container">
						
						<div hidden={searchResults.length > 0 || getShowTitleFeedback()} className="new-exercise-modal-exercise-search-result-container-results-empty">
							<div className="new-exercise-modal-exercise-search-result-container-results-empty-icon-container">
								<Check className="new-exercise-modal-exercise-search-result-container-results-empty-icon"/>
							</div>
							<p>No exercises with matching titles were found</p>
						</div>
						<div hidden={searchResults.length === 0} className="new-exercise-modal-searching-exercises-cell-container">
							<div hidden={searchResults.length === 0 || getShowTitleFeedback()} className="new-exercise-modal-exercise-search-result-container-results">
								<div className="new-exercise-modal-exercise-search-result-container-results-results-icon-container">
									<h4 className="new-exercise-modal-exercise-search-result-container-results-results-icon">!</h4>
								</div>
								<p>{searchResults.length} exercises with similar titles were found</p>
								<div onClick={() => toggleShowingSearchResults()} className="new-exercise-modal-exercise-search-result-container-results-expand-results-container">
									<p>{showingSearchResults ? 'Hide similar exercises' : 'Show similar exercises'}</p>
									<div hidden={showingSearchResults} className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon-container">
										<ExpandMore className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon"/>
									</div>
									<div hidden={!showingSearchResults} className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon-container">
										<ExpandLess className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon"/>
									</div>
								</div>
							</div>
							<div hidden={!showingSearchResults} className="new-exercise-modal-searching-exercises-cell-content-container">
								{searchResults.map((item: Exercise_t, index: number) => (
									<div className="new-exercise-modal-searching-exercises-cell">
										<div className="new-exercise-modal-searching-exercises-cell-col new-exercise-modal-searching-exercises-cell-col-large">
											<h4>{item.title}</h4>
		                    				<p>{getMusclesString(item)}</p>
										</div>
										<div className="new-exercise-modal-searching-exercises-cell-col new-exercise-modal-searching-exercises-cell-col-large">
											<h4>{getEquipmentRequired(item) ? "Equipment required" : "No equipment"}</h4>
		                    				<p>{getEquipmentString(item)}</p>
										</div>
										<div className="new-exercise-modal-searching-exercises-cell-col">
											<h4>Eigen Library</h4>
						                    <div className="exercise-cell-info-card-info-trained-with-me-container">
						                        <div className="exercise-cell-info-card-info-trained-with-me-icon"/>
						                        <p>Created by Eigen Fitness</p>
						                    </div>
										</div>
									</div>
								))}
								<div onClick={() => goToLibrary()} className="new-exercise-modal-searching-exercises-to-library-button">
									<p>Go to Library</p>
								</div>
							</div>
						</div>
					</div>
					<div className="new-client-content-page-section-spacer"/>
					<div className="new-client-content-page-section-spacer"/>
					<FormTextInput 
                        value={videoLink}
						placeholder="Youtube video link" 
						alert={false} 
                        isOptional={true}
						alertText=""
						inputChanged={(e: string) => inputChanged(e, 'video-link')}/>
					<div className="new-client-content-page-section-header new-client-content-page-section-header-line">
						<h4>Select the type of exercise</h4>
						<p>Choose the type of workout you're creating from the list of options below</p>
					</div>
					<MultiSelect
						options={[{title: "Isotonic", desc: "Repetition based exercise"}, {title: "Isometric", desc: "Timer-based exercise (tempo >9s)"}, {title: "Cardio", desc: "Long duration timer-based exercise"}]}
						optionChanged={(e: number) => inputChanged(e, 'exercise-type')}/>
                    <div className="new-workout-next-button-container new-workout-next-button-container-page-0">
                        <div onClick={() => continuePressed()} hidden={ exercise.title.split('').length < 5 } className="modal-cta-button">Select muscles</div>
                    </div>
				</div>
				<div className="new-workout-modal-page-full-height" hidden={workflowState != 1}>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>Select the Working Muscles</h2>
						<p>Use the body below to tap on the muscles that are used in this exercise </p>
					</div>
                    <div className="new-client-content-page-section-spacer"/>
					<div className="new-exercise-modal-muscle-selector-container">
						<MuscleSelector 
                            muscleList={selectedMuscles}
							muscleListUpdated={(e: Muscles_t[]) => muscleListUpdated(e)}/>
					</div>
					<div hidden={selectedMuscles.length === 0} className="new-exercise-modal-selected-muscles-container">
						{
							selectedMuscles.map((item: Muscles_t, index: number) => (
								<div className="new-exercise-modal-selected-muscle-content-container">
									{muscles[item.group][item.muscle]}
								</div>
							))
						}
					</div>
					<div hidden={selectedMuscles.length !== 0} className="new-exercise-modal-selected-muscles-container">
						<div hidden={alerts[2] === true} className="new-exercise-modal-selected-muscle-content-container-unselected">
							<p>No muscles selected</p>
						</div>
						<div hidden={alerts[2] === false} className="new-exercise-modal-selected-muscle-content-container-unselected new-exercise-modal-selected-muscle-content-container-unselected-alert">
							<p>No muscles selected</p>
						</div>
					</div>
                    <div className="new-workout-next-button-container new-workout-next-button-container-page-0">
                        <div onClick={() => backPressed()} className="modal-back-button">Back</div>
                        <div onClick={() => continuePressed()} hidden={ selectedMuscles.length === 0 } className="modal-cta-button">Next step</div>
                    </div>
				</div>
				<div hidden={workflowState != 2} className="new-workout-modal-page-full-height">
					<div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-header">
						<h2>Select required machines and benches</h2>
						<p>Choose the machine and/or bench required for this exercise</p>
					</div>
					<div className="new-exercise-modal-select-row">
						<div className="new-exercise-modal-select-container">
							<h5>Machine</h5>
							<Selector
								placeholder="Machine"
                                forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.machine}
								showTitle={false} 
								options={equipment.machines}
								inputChanged={(e: number) => inputChanged(e, 'machine-selected')}/>
							<div className="new-exercise-modal-select-custom-input-container">
								<div className="new-exercise-modal-select-custom-input-container-inner" hidden={exercise.equipment === undefined || exercise.equipment.machine !== equipment.machines.length - 1}>
									<FormTextInput 
                                        value={customMachineName}
										placeholder="Custom machine name" 
										alert={alerts[0]} 
										alertText="Please enter a machine name"
										inputChanged={(e: string) => inputChanged(e, 'custom-machine-name')}/>
								</div>
							</div>
						</div>
						<div className="new-exercise-modal-select-container">
							<h5>Bench</h5>
							<Selector
								placeholder="Bench"
                                forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.bench}
								showTitle={false} 
								options={equipment.benches}
								inputChanged={(e: number) => inputChanged(e, 'bench-selected')}/>
							<div className="new-exercise-modal-select-custom-input-container">
								<div className="new-exercise-modal-select-custom-input-container-inner" hidden={exercise.equipment === undefined || exercise.equipment.bench !== equipment.benches.length - 1}>
									<FormTextInput 
                                        value={customBenchName}
										placeholder="Custom bench name" 
										alert={alerts[0]} 
										alertText="Please enter a bench name"
										inputChanged={(e: string) => inputChanged(e, 'custom-bench-name')}/>
								</div>
							</div>
						</div>
					</div>
					<div className="new-client-content-page-section-header">
						<h2>Select required bars and cable attachments</h2>
						<p>Choose the bars and/or cable attachments required for this exercise</p>
					</div>
					<div className="new-exercise-modal-select-row">
						<div className="new-exercise-modal-select-container">
							<h5>Bar</h5>
							<Selector
								placeholder="Bar"
                                forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.bar}
								showTitle={false} 
								options={equipment.bars}
								inputChanged={(e: number) => inputChanged(e, 'bar-selected')}/>
							<div className="new-exercise-modal-select-custom-input-container">
								<div className="new-exercise-modal-select-custom-input-container-inner" hidden={exercise.equipment === undefined || exercise.equipment.bar !== equipment.bars.length - 1}>
									<FormTextInput 
                                        value={customBarName}
										placeholder="Custom bar name" 
										alert={alerts[0]} 
										alertText="Please enter a bar name"
										inputChanged={(e: string) => inputChanged(e, 'custom-bar-name')}/>
								</div>
							</div>
						</div>
						<div className="new-exercise-modal-select-container">
							<h5>Cable Attachment</h5>
							<Selector
								placeholder="Cable Attachment"
                                forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.cable_attachment}
								showTitle={false} 
								options={equipment.cable_attachments}
								inputChanged={(e: number) => inputChanged(e, 'attachment-selected')}/>
							<div className="new-exercise-modal-select-custom-input-container">
								<div className="new-exercise-modal-select-custom-input-container-inner" hidden={exercise.equipment === undefined || exercise.equipment.cable_attachment !== equipment.cable_attachments.length - 1}>
									<FormTextInput 
                                        value={customAttachmentName}
										placeholder="Custom attachment name" 
										alert={alerts[0]} 
										alertText="Please enter an attchment name"
										inputChanged={(e: string) => inputChanged(e, 'custom-attachment-name')}/>
								</div>
							</div>
						</div>
					</div>
                    <div className="new-workout-next-button-container new-workout-next-button-container-page-0">
                        <div onClick={() => backPressed()} className="modal-back-button">Back</div>
                        <div onClick={() => continuePressed()} hidden={ selectedMuscles.length === 0 } className="modal-cta-button">Next step</div>
                    </div>
				</div>
				<div hidden={workflowState != 3}>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>Share this exercise's setup</h2>
						<p>Add a brief description on how to set this exercise up</p>
					</div>
					
                    <div className="new-exercise-modal-setup-input-container">
                        <FormLongTextInput 
                            value={exercise.setup}
                            placeholder="Exercise Setup" 
                            alert={alerts[4]} 
                            alertText="Please enter a brief exercise setup"
                            isSearch={true}
                            isOptional={true}
                            inputChanged={(e: string) => inputChanged(e, 'exercise-setup')}/>
                    </div>
					<div className="new-client-content-page-section-header">
						<h2>Include pointers on this exercise</h2>
						<p>Add helpful points, or steps, to complete this exercise. Be sure to include points on reducing the risk of injury.</p>
					</div>
					<div hidden={steps.length === 0} className="new-exercise-steps-container">
						{
							steps.map((item: string, index: number) => (
								<div className="new-exercise-steps-content">
									<div className="new-exercise-steps-content-number-container">
										<h4>{index + 1}</h4>
									</div>
									<div className="new-exercise-steps-content-text-container">
										<p>{item}</p>
									</div>
									<div onClick={() => removeStepPressed(index)} className="new-exercise-steps-content-remove-container">
										<Close className="new-exercise-steps-content-remove-icon"/>
									</div>
								</div>
							))
						}
					</div>	
					<div className="new-exercise-add-step-container">
						<div className="new-exercise-add-step-input-container">
							<IonInput value={currentStep} 
                                placeholder={steps.length > 0 ? "Enter Another Step / Pointer" : "Exercise Step / Pointer"}
                                inputmode="text"
                                clearOnEdit={true}
                                type="text"
                                onKeyPress={e => inputUpdated(e.key)}
                                onIonChange={e => inputChanged(e.detail.value!, 'exercise-step')}/>
						</div>
						<div hidden={currentStep === ""} onClick={() => addStepPressed()} className="new-exercise-add-step-input-button">
							<p>Add Pointer</p>
						</div>
						<div hidden={currentStep !== ""} className="new-exercise-add-step-input-button new-exercise-add-step-input-button-unselected">
							<p>Add Pointer</p>
						</div>
					</div>
					
					<div hidden={steps.length !== 0} className="new-exercise-steps-container-empty">
						<p>No steps added</p>
					</div>
                    <div className="new-workout-next-button-container new-workout-next-button-container-page-0">
                        <div onClick={() => backPressed()} className="modal-back-button">Back</div>
                        <div onClick={() => continuePressed()} className="modal-cta-button">Set Availability</div>
                    </div>

				</div>


				<div className="new-workout-modal-availability-container" hidden={workflowState != 4}>
                    <div className="new-client-content-page-section-spacer-5vh"/>
                    <div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
                        <h2>Choose who can use your Exercise</h2>
                        <p>Eigen Fitness allows your to share Exercises with all EigenFit users, or to keep your Exercises local within your gym. Select the Exercise availability option of your choosing below.</p>
                    </div>
                    <div className="new-workout-modal-availability-row">
                        <div onClick={() => availabilitySelected(0)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 0 ? "new-workout-modal-availability-cell-selected" : "")}>
                            <div className="new-workout-modal-availability-cell-header">
                                <Public className="new-workout-modal-availability-cell-header-icon"/>
                                <h3>Public</h3>
                            </div>
                            <p>This Exercise will be available for download by all EigenFit users. Recommended to help boost your downloads and Trainer Rating.</p>
                        </div>
                        <div onClick={() => availabilitySelected(1)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 1 ? "new-workout-modal-availability-cell-selected" : "")}>
                            <div className="new-workout-modal-availability-cell-floater">Recommended</div>
                            <div className="new-workout-modal-availability-cell-header">
                                <Home className="new-workout-modal-availability-cell-header-icon"/>
                                <h3>Gym only</h3>
                            </div>
                            <p>This Exercise will be available for download and use by members of my gym. Perfect for sharing your tried & true Exercises with trainers you know.</p>
                        </div>
                    </div>
                    <div className="new-workout-modal-availability-row">
                        <div onClick={() => availabilitySelected(2)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 2 ? "new-workout-modal-availability-cell-selected" : "")}>
                            <div className="new-workout-modal-availability-cell-header">
                                <Group className="new-workout-modal-availability-cell-header-icon"/>
                                <h3>Clients only</h3>
                            </div>
                            <p>Only my clients will have access to use and download this Exercise. Great for keeping your Exercises personal and solidifying your training style.</p>
                        </div>
                        <div onClick={() => availabilitySelected(3)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 3 ? "new-workout-modal-availability-cell-selected" : "")}>
                            <div className="new-workout-modal-availability-cell-header">
                                <Lock className="new-workout-modal-availability-cell-header-icon"/>
                                <h3>Private</h3>
                            </div>
                            <p>This Exercise is only available during one-on-one sessions with my clients. Best for Exercises that require a close eye and lots of communication.</p>
                        </div>
                    </div>
                    <div className="new-workout-next-button-container new-workout-next-button-container-page-0">
                        <div onClick={() => backPressed()} className="modal-back-button">Back</div>
                        <div onClick={() => continuePressed()} className="modal-cta-button">Finish & Create Exercise</div>
                    </div>
				</div>
				<div className="new-client-centered-container" hidden={workflowState != 5}>
					<p className="new-client-centerd-loading-text">Creating Exercise...</p>
				</div>
				<div className="new-client-centered-container" hidden={workflowState != 6}>
					<h2>{exercise.title} created!</h2>
					<p>You've successfully created the new Exercise, {exercise.title}. You can now view this Exercise in your library add it to new Workouts</p>
					
					<div hidden={props.onTheFlyExerciseAdding !== undefined && props.onTheFlyExerciseAdding === true} onClick={() => goToLibrary()} className="new-exercise-button new-exercise-to-library-button">
						<p>Go to Library</p>
					</div>
                    <div className="new-workout-next-button-container new-workout-next-button-container-page-6">
                        <div onClick={() => continuePressed()} className="modal-cta-button">{props.onTheFlyExerciseAdding !== undefined && props.onTheFlyExerciseAdding === true ? 'Finish and Add to Workout' : 'Done'}</div>
                    </div>
				</div>
			</div>
		</div>
	)
}

export default NewExerciseModal;

