import React, { useState, useEffect } from 'react';
import './WorkoutSummaryCard.css';

import '@material-ui/core';
import FitnessCenter from '@material-ui/icons/FitnessCenter';



function WorkoutSummaryCard(props: any) {

    const [score, setScore] = useState(0)
    
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            
        }
    }, [initSet])

    useEffect(() => {
        setScore(props.score ?? 0);
    }, [props.score])

    const getIsComplete = () => {
        if (!props.logData || !props.logData.log || !props.logData.log.ending_timestamp || props.logData.log.ending_timestamp < 1) return false;
        return true;
    }

    const getCompletion = () => {
    	if (!props.logData || !props.logData.workout || !props.logData.workout || !props.logData.log) return 0;

    	// Generate Volume and Tonnage
        var vol_complete = 0;
        var vol_complete_reps = 0;
        var vol_total = 0;
        var tonnageSum = 0;
        var tonnageUnits = 'LBS'

        if (props.logData.log.exercise_series) {
            for (var i = 0; i < props.logData.log.exercise_series.length; i++) {
                let thisSeries = props.logData.log.exercise_series[i];
                if (thisSeries && thisSeries.exercises) {
                    for (var j = 0; j < thisSeries.exercises.length; j++) {
                        let thisExercise = thisSeries.exercises[j];
                        if (thisExercise && thisExercise.sets) {
                            for (var k = 0; k < thisExercise.sets.length; k++) {
                                let thisSet = thisExercise.sets[k];
                                if (thisSet) {
                                    vol_complete += thisSet.is_complete !== undefined && thisSet.is_complete === true ? 1 : 0;
                                    vol_total += 1;
                                    let w = thisSet.weight ?? -1;
                                    let repsCompleted = thisSet.reps_completed ?? 0;
                                    let thisTonnage = w * repsCompleted;
                                    if (thisTonnage > 0 && !isNaN(thisTonnage)) tonnageSum += thisTonnage;
                                    if (thisSet.weight_units && thisSet.weight_units !== "") tonnageUnits = thisSet.weight_units
                                	vol_complete_reps += repsCompleted;
                                }
                            }
                        }
                    }
                }
            }
        }

        return vol_total === 0 ? 0 : vol_complete / vol_total;

    }

    const getTitleString = () => {
    	let comp = getCompletion();
    	return `${!props.logData || !props.logData.workout || !props.logData.workout.title ? "Workout" : props.logData.workout.title} ${ getIsComplete() ? "Complete" : "Incomplete"}`

        return score >= 9.5 ? "Perfect" : score > 8 ? "Good" : score > 6 ? "Okay" : "Poor"
    }

    const getDurationString = () => {
    	if (!props.logData || !props.logData.log || !props.logData.log.starting_timestamp || !props.logData.log.ending_timestamp) return "00m 00s";

    	let startingTS = props.logData.log.starting_timestamp ?? -1;
        let endingTS = props.logData.log.ending_timestamp ?? -1;
        let lastSetTS = props.logData.log.last_set_timestamp ?? -1;

        let duration_ms = (endingTS > 0 ? endingTS : lastSetTS > 0 ? lastSetTS : 0) - startingTS;
        duration_ms = duration_ms < 0 ? 0 : duration_ms;
        let durationStringTemp = getFormattedTime(Math.floor(duration_ms / 1000));

        return durationStringTemp
    }

    const getTonnageString = () => {
    	if (!props.logData || !props.logData.workout || !props.logData.workout || !props.logData.log) return 0;

    	// Generate Volume and Tonnage
        var vol_complete = 0;
        var vol_complete_reps = 0;
        var vol_total = 0;
        var tonnageSum = 0;
        var tonnageUnits = 'LBS'

        if (props.logData.log.exercise_series) {
            for (var i = 0; i < props.logData.log.exercise_series.length; i++) {
                let thisSeries = props.logData.log.exercise_series[i];
                if (thisSeries && thisSeries.exercises) {
                    for (var j = 0; j < thisSeries.exercises.length; j++) {
                        let thisExercise = thisSeries.exercises[j];
                        if (thisExercise && thisExercise.sets) {
                            for (var k = 0; k < thisExercise.sets.length; k++) {
                                let thisSet = thisExercise.sets[k];
                                if (thisSet) {
                                    vol_complete += thisSet.is_complete !== undefined && thisSet.is_complete === true ? 1 : 0;
                                    vol_total += 1;
                                    let w = thisSet.weight ?? -1;
                                    let repsCompleted = thisSet.reps_completed ?? 0;
                                    let thisTonnage = w * repsCompleted;
                                    if (thisTonnage > 0 && !isNaN(thisTonnage)) tonnageSum += thisTonnage;
                                    if (thisSet.weight_units && thisSet.weight_units !== "") tonnageUnits = thisSet.weight_units
                                	vol_complete_reps += repsCompleted;
                                }
                            }
                        }
                    }
                }
            }
        }

        return `${getStringFormattedInt(tonnageSum)} ${tonnageUnits}`;
    }

    function getStringFormattedInt(num: number) {
        let flooredNum = Math.floor(num);
        let flooredNum_thousand = Math.floor(flooredNum % 1000);
        return flooredNum < 10 ? `0${flooredNum}` : (flooredNum > 1000 ? `${Math.floor(flooredNum / 1000)},${ flooredNum_thousand < 1 ? '000' : flooredNum_thousand < 100 ? `0${flooredNum_thousand}` : (flooredNum_thousand < 10 ? `00${flooredNum_thousand}` : flooredNum_thousand)}` : `${flooredNum}`);
    }

    function getFormattedTime(time: number) {
        let time_seconds = Math.floor(time % 60);
        let time_mins = Math.floor(time / 60);

        return `${time_mins < 10 ? '0' : ''}${time_mins}m ${time_seconds < 10 ? '0' : ''}${time_seconds}s`

    }

    const getVolumeString = () => {
    	if (!props.logData || !props.logData.workout || !props.logData.workout || !props.logData.log) return 0;

    	// Generate Volume and Tonnage
        var vol_complete = 0;
        var vol_complete_reps = 0;
        var vol_total = 0;
        var tonnageSum = 0;
        var tonnageUnits = 'LBS'

        if (props.logData.log.exercise_series) {
            for (var i = 0; i < props.logData.log.exercise_series.length; i++) {
                let thisSeries = props.logData.log.exercise_series[i];
                if (thisSeries && thisSeries.exercises) {
                    for (var j = 0; j < thisSeries.exercises.length; j++) {
                        let thisExercise = thisSeries.exercises[j];
                        if (thisExercise && thisExercise.sets) {
                            for (var k = 0; k < thisExercise.sets.length; k++) {
                                let thisSet = thisExercise.sets[k];
                                if (thisSet) {
                                    vol_complete += thisSet.is_complete !== undefined && thisSet.is_complete === true ? 1 : 0;
                                    vol_total += 1;
                                    let w = thisSet.weight ?? -1;
                                    let repsCompleted = thisSet.reps_completed ?? 0;
                                    let thisTonnage = w * repsCompleted;
                                    if (thisTonnage > 0 && !isNaN(thisTonnage)) tonnageSum += thisTonnage;
                                    if (thisSet.weight_units && thisSet.weight_units !== "") tonnageUnits = thisSet.weight_units
                                	vol_complete_reps += repsCompleted;
                                }
                            }
                        }
                    }
                }
            }
        }

        return `${vol_complete_reps < 10 ? '0' : ''}${vol_complete_reps} rep${vol_complete_reps === 1 ? '' : 's'}, ${vol_complete < 10 ? '0' : ''}${vol_complete} set${vol_complete === 1 ? '' : 's'}`;
    }

    const getAdviceString = () => {
        return score >= 9.5 ? "All metrics are where they should be." : score > 8 ? "Most metrics are where they should be" : score > 6 ? "You may want to review their metrics with them." : "It is strongly recommended to review their metrics with them."
    }

    const getScoreImageString = () => {
    	let comp = getIsComplete();
        return comp ? "-green" : "-red"
    }

	return (
		<div className={ "workout-summary-card " }>
			<div className={ "workout-summary-card-header" }>
                <div className={ "workout-summary-card-header-icon-container" }>
                    <FitnessCenter className={ "workout-summary-card-header-icon" }/>
                </div>
                <h4>Workout Summary</h4>
            </div>
            <div className={ "workout-summary-card-body-container" }>
                <div className={ "workout-summary-card-body-status-container" }>
                    <h1>{ getTitleString() }</h1>
                    <h4>{ Math.floor(getCompletion() * 100) }% of assigned volume completed</h4>
                </div>
                <div className={ "workout-summary-card-body-status-info-container workout-summary-card-body-status-info-col-container" }>
                    <div className={ "workout-summary-card-body-status-info-col" }>
	                    <h2>{ getDurationString() }</h2>
	                    <p>Workout Duration</p>
	                </div>
	                <div className={ "workout-summary-card-body-status-info-col" }>
	                    <h2>{ getTonnageString() }</h2>
	                    <p>Tonnage</p>
	                </div>
	                <div className={ "workout-summary-card-body-status-info-col" }>
	                    <h2>{ getVolumeString() }</h2>
	                    <p>Completed Volume</p>
	                </div>
                </div>
            </div>
            <div className={ "workout-summary-card-selector-container" }>
                <div className={ "workout-summary-card-selector-demo" }></div>
            </div>
            <div className={ "workout-summary-card-bg-container" }>
                <img className="workout-summary-card-bg-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/summary-card${getScoreImageString()}-bg.png`}/>
            </div>
		</div>
	)
}

export default WorkoutSummaryCard;