import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './ClientProfileSchedule.css';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';

import Calendar from '../../components/Calendar/Calendar';

function ClientProfileSchedule(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="client-profile-schedule">
			<div className="client-profile-schedule-header">
                
            </div>
            <div className="client-profile-schedule-content">
                <Calendar 
                    isBookable={false}
                    client={props.client}
                    createNewWorkout={(e: any) => props.createNewWorkout(e)}
                    addNewAppointmentPressed={(e: any) => props.addNewAppointmentPressed(e)}
                    isCreatingProgramUpdated={(e: boolean) => null}
                    createPhasePressedWithWorkouts={(w: Workout_t[]) => props.createPhasePressedWithWorkouts(w)}
                    editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => props.editWorkoutForDay(workout, calendarDay, client)}/>
            </div>
		</div>
	)
}

export default ClientProfileSchedule;