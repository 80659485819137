import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import './NewWorkoutModal.css';
import './ConfirmModal.css';
import './AddTrainerModal.css';

import Location_t from '../../Interfaces/Locations/Location_t';

import FormTextInput from '../Base/FormTextInput';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';


// modal data:
//	{ title: "", text: "", isRed: boolean, callback: any, ctaText: "" }
//
//
//

interface AddingTrainer_t {
	email: string;
	permission: number;
	isShowing: boolean;
	location?: Location_t;
	isShowingLocation: boolean;
}

function AddTrainerModal(props: any) {

	let permissionTitleStrings: string[] = ["Gym Admin", "Location Admin", "Standard Coach", "Observe Only"];

	const [email, setEmail] = useState("");
	const [workflowState, setWorkflowState] = useState(0);
	const [inputAlert, setInputAlert] = useState(false);
	const [emails, setEmails] = useState<AddingTrainer_t[]>([]);
	const [initSet, setInitSet] = useState(false);

	const [locations, setLocations] = useState<Location_t[]>([]);

	if (!initSet) {
		initialize();
		setInitSet(true);
	}

	function initialize() {
		pullStudioLocations();
	}

	function pullStudioLocations() {
		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

		// console.log("pullStudioLocations trainerUID: ", trainerUID)

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_profile/${trainerUID}/studio_id`);
		ref.once('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        let studioID = snapshot.val();

		        // console.log("STUDIO ID: ", studioID)

		        if (studioID !== undefined && studioID !== null && studioID !== "") {
		        	let studioRef = database.ref(`training_studios/${studioID}`);
		        	studioRef.once('value', function(snapshot_studio) {
					    if (snapshot_studio.exists() === true) {
					        let studioObj = snapshot_studio.val();
					        if (studioObj !== undefined && studioObj.locations !== undefined) {
					        	let locationsListTemp = parseLocations(studioObj.locations);
					        	setLocations(locationsListTemp);
					        }
					    }
					});
		        }
		    }
		});
	}

	function parseLocations(locationsObj: any) {

		let locationKeys: string[] = Object.keys(locationsObj);

		var locationListTemp: Location_t[] = [];
		for (var i = 0; i < locationKeys.length; i++) {
			let thisLocationKey: string = locationKeys[i];
			let thisLocationObj: any = locationsObj[thisLocationKey];

			let newLocation: Location_t = {
				id: thisLocationKey,
				title: thisLocationObj.name !== undefined ? thisLocationObj.name : 'My Location',
				owner_id: thisLocationObj.owner_id,
				location: thisLocationObj.location !== undefined ? thisLocationObj.location : {
					address_string: "",
					unit_string: "",
					google_place_id: "",
					coordinates: {lat: 0.0, lng: 0.0},
					isHQ: false
				},
				times: thisLocationObj.times !== undefined ? thisLocationObj.times : {
					monday: 	{open: 420, close: 1080, isOpen: true},
					tuesday: 	{open: 420, close: 1080, isOpen: true},
					wednesday: 	{open: 420, close: 1080, isOpen: true},
					thursday: 	{open: 420, close: 1080, isOpen: true},
					friday: 	{open: 420, close: 1080, isOpen: true},
					saturday: 	{open: 420, close: 1080, isOpen: true},
					sunday: 	{open: 420, close: 1080, isOpen: true},
				}
			}

			locationListTemp.push(newLocation);
		}

		return locationListTemp;

	}



	function closePressed() {
		props.hideModalPressed();
	}

	function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'email':
                setEmail(toString);
                if (inputAlert === true) {
                	if (toString.split("").length >= 5) {
                		setInputAlert(false);
                	}
                }
                break;
            default:
                break;
        }
    }

    function validateEmailInput() {
    	if (email.split("").length >= 5) {
    		let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    		let passedEmailRegEx = emailRegEx.test(email);

    		return passedEmailRegEx;
    	} else {
    		return false;
    	}
    }

    function addEmail() {
    	let newAddTrainer: AddingTrainer_t = {email: email, permission: 2, isShowing: false, isShowingLocation: false, location: locations.length > 0 ? locations[0] : undefined};
    	setEmails(emails.concat(newAddTrainer));
    	setEmail("");
    }

    function removeTrainer(atIndex: number) {
    	setEmails(emails.filter((item: AddingTrainer_t, index: number) => {
    		return index !== atIndex;
    	}));
    }

    function toggleShowPermissions(atIndex: number) {
    	setEmails(emails.map((item: AddingTrainer_t, index: number) => {
    		if (index === atIndex) {
    			var itemTemp = item;
    			itemTemp.isShowing = !itemTemp.isShowing;
    			return itemTemp;
    		} else {
    			return item;
    		}
    	}))
    }

    function toggleShowLocations(atIndex: number) {
    	setEmails(emails.map((item: AddingTrainer_t, index: number) => {
    		if (index === atIndex) {
    			var itemTemp = item;
    			itemTemp.isShowingLocation = !itemTemp.isShowingLocation;
    			return itemTemp;
    		} else {
    			return item;
    		}
    	}))
    }

    function updateUserPermissions(atIndex: number, toPermission: number) {
    	setEmails(emails.map((item: AddingTrainer_t, index: number) => {
    		if (index === atIndex) {
    			var itemTemp = item;
    			itemTemp.permission = toPermission;
    			itemTemp.isShowing = false;
    			itemTemp.isShowingLocation = false;
    			return itemTemp;
    		} else {
    			item.isShowing = false;
    			item.isShowingLocation = false;
    			return item;
    		}
    	}))
    }

    function updateUserLocation(atIndex: number, toLocation: Location_t) {
    	setEmails(emails.map((item: AddingTrainer_t, index: number) => {
    		if (index === atIndex) {
    			var itemTemp = item;
    			itemTemp.location = toLocation;
    			itemTemp.isShowing = false;
    			itemTemp.isShowingLocation = false;
    			return itemTemp;
    		} else {
    			item.isShowing = false;
    			item.isShowingLocation = false;
    			return item;
    		}
    	}))
    }

    function submit() {
    	if (emails.length >= 1) {
    		setWorkflowState(1);
    		Mixpanel.track("Adding trainers", {num_trainers: emails.length});
    		Mixpanel.people.increment("Added trainers");

    		
    		// 1. Check if Studio exists in DB
	    	let currentUser = firebase.auth().currentUser;
			if (!currentUser) { return; }
			let trainerUID = currentUser.uid;

			let database = firebase.database();
			let ref = database.ref(`personal_trainer_profile/${trainerUID}`); // Change to database path
			ref.once('value', function(snapshot) {
			    if (snapshot.exists() === true) {
			        let data = snapshot.val();

			        if (data.studio_id === undefined) {
			        	// Studio has not been configured - start config
			        	configureStudio(data, trainerUID);
			        } else {
			        	let studioID = data.studio_id;
			        	addTrainers(studioID, data);
			        }
			        
			    }
			});
    	}
    }

    async function configureStudio(userObj: any, trainerUID: string) {

    	let database = firebase.database();

    	// Configure Studio
		let refStudio = database.ref(`training_studios`).push({
			name: userObj.company_name !== undefined ? userObj.company_name : "My Studio",
			city: userObj.city !== undefined ? userObj.city : "Unknown City",
			owner_id: trainerUID,
			owner_email: userObj.email,
			created: Date.now(),
			trainers: [{id: trainerUID, permission: 0}]
		});

		let studioID = refStudio.key;

		// Add studio ID to user's profile
		database.ref(`personal_trainer_profile/${trainerUID}/studio_id`).set(studioID);

		if (studioID !== undefined) {
			addTrainers(studioID!, refStudio);
		}
    }


    async function addTrainers(withStudioID: string, studioObj: any) {
    	// 1. Compose list of added trainers
    	var trainerList: any[] = [];
    	var failedTrainers: any[] = [];

    	for (var i = 0; i < emails.length; i++) {
    		let thisTrainerObj: any = {email: emails[i].email, 
    								  permission: emails[i].permission, 
    								  location_id: emails[i].location === undefined ? "" : emails[i].location!.id,
    								  id: ""};

    		try {
    			let thisTrainerID = await addTrainer(thisTrainerObj, withStudioID, studioObj);

    			// If trainer account is created successfully, add to trainerList
    			// console.log("Promise resolved with ID: ", thisTrainerID, "for email: ", thisTrainerObj.email);
    			thisTrainerObj["id"] = thisTrainerID;
    			trainerList.push(thisTrainerObj);
    		} catch {
    			// If attempt to create trainer account fails, add to failedTrainers
    			failedTrainers.push(thisTrainerObj);
    			// console.log("Promise failed for email: ", thisTrainerObj.email);
    		}
       	}

       	if (trainerList.length > 0) {
       		// List is composed. If trainerList is not empty, add trainer objects to studio trainer list
       		// Get current trainer list
       		let database = firebase.database();
			let ref = database.ref(`training_studios/${withStudioID}`);
			ref.once('value', function(snapshot) {

				var currentTrainersLength = 0;
				var currentTrainerList: any[] = [];
			    if (snapshot.exists() === true) {
			        let data = snapshot.val();
			        let currentTrainers: any[] = data.trainers;
			        currentTrainerList = currentTrainers;
			        currentTrainersLength = currentTrainers.length;
			    }
			    // Add all to list
		        for (var i = 0; i < trainerList.length; i++) {
		        	let thisTrainerObj = trainerList[i];
		        	currentTrainerList.push(thisTrainerObj);
		        	//ref.child('trainers').child(`${currentTrainersLength + 1}`).set({id: thisTrainerObj.id, permission: thisTrainerObj.permission})
		        }

		        ref.child('trainers').set(currentTrainerList);

		        addTrainersToLocation(trainerList, withStudioID);
				
		        
			});



       	} else {
       		setWorkflowState(2);
       	}

    }

    async function addTrainersToLocation(trainerList: any[], withStudioID: string) {
    	// Add new trainers to locations, if locations exist
        if (locations.length > 0) {
        	// 1. compose list of training location IDs
			var locationTrainerLists: any = {};
			for (var i = 0; i < locations.length; i++) {
				locationTrainerLists[locations[i].id] = [];
			}
			// console.log("Adding trainers, step 1: ", locationTrainerLists)

			// 2. Add trainer to respective lists
			for (var i = 0; i < trainerList.length; i++) {
				let thisTrainerLocationID = trainerList[i].location_id;
				if (thisTrainerLocationID !== undefined && thisTrainerLocationID !== "") {
					locationTrainerLists[thisTrainerLocationID].push(trainerList[i].id);
				}
			}

			// console.log("Adding trainers, step 2: ", locationTrainerLists)

			// 3. Update db lists
			let locationKeys = Object.keys(locationTrainerLists);
			// console.log("Adding trainers, step 3 | keys: ", locationKeys);
			for (var i = 0; i < locationKeys.length; i++) {
				let thisLocationKey = locationKeys[i];
				let thisList: any[] = locationTrainerLists[thisLocationKey];
				let isCompleted = await ammendTrainerList(thisList, thisLocationKey, withStudioID);
			}
        }

        setTimeout(() => {
        	setWorkflowState(2);
        }, 500);
    }

    async function ammendTrainerList(thisList: any[], thisLocationKey: string, withStudioID: string) {
    	// console.log("ammendTrainerList", thisList, thisLocationKey, withStudioID);
    	return new Promise<Boolean>((resolve, reject) => {
    		let database = firebase.database();
			let ref_location = database.ref(`training_studios/${withStudioID}/locations/${thisLocationKey}/trainers`);
			ref_location.once('value', function(snapshot_location) {
				if (snapshot_location.exists()) {
					var currentList: any[] = JSON.parse(JSON.stringify(snapshot_location.val()));
					for (var j = 0; j < thisList.length; j++) {
						currentList.push(thisList[j])
					}
					ref_location.set(currentList);
				} else {
					var currentList: any[] = [];
					for (var j = 0; j < thisList.length; j++) {
						currentList.push(thisList[j])
					}
					ref_location.set(currentList);
				}
				resolve(true);
			});
    	})
    }

    async function addTrainer(trainerObj: any, withStudioID: string, studioObj: any) {
	   	return new Promise<string>((resolve, reject) => {

			let currentUser = firebase.auth().currentUser;
			if (!currentUser) { return; }
			let trainerUID = currentUser.uid;

			let sendData = {
			    "trainer_email": trainerObj.email,
			    "trainerPermission": trainerObj.permission,
			    "referrer_id": trainerUID,
			    "location_id": trainerObj.location_id,
			    "studio_id": withStudioID,
			    "studio_city": studioObj.city,
			    "studio_name": studioObj.company_name !== undefined ? studioObj.company_name : "My Studio"
			}

			axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/addTrainerToStudio`,
				sendData,
				{ headers: {'Content-Type': 'application/json'} })
			.then(response => {
			    // console.log("RESPONSE:", response.data);
			    let data = response.data;
			    resolve(data.user.uid);
			})
			.catch(e => {
			    // console.log(e);
			    reject();
			})
		});
		
    }

    function sendAdminEmail(data: any, key: any) {

    	let dbLocation = `https://console.firebase.google.com/u/1/project/movement-tracker-457bc/database/movement-tracker-457bc/data/issues/${data.user.user_id}/${key !== null ? key : ''}`
    	axios.get(`https://eigenfitness.com/email/user_feedback_submitted.php?submitted_on=${data.human_timestamp}&email=${data.user.email}&trainer_name=${data.user.full_name}&trainer_first_name=${data.user.first_name}&website=${dbLocation}&gym_name=${data.user.company}&city=${data.user.city}&note=${data.note}`,
        { headers: {'Content-Type': 'application/json'} })
	    .then(response => {
	        // console.log(response);
	        // console.log(response.data);
	        let data = response.data;
	    })
	    .catch(e => {
	        // console.log(e);
		})
    }

	return (
		<div className="modal-background feedback-modal-background">
			<div className="add-trainer-modal-container">
				<div onClick={() => closePressed()} className="feedback-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
				<div hidden={workflowState !== 0} className="feedback-modal-top">
					<h3>Add Coaches to your Studio</h3>
					<p>Enter your fellow Coach's email address below and hit "Add" to add them to your studio. They will recieve an email invitation to register with Eigen.</p>
				</div>
				<div hidden={workflowState !== 0} className="new-trainer-modal-body-container">
					<div className="new-trainer-modal-input-container-outter">
						<div className="new-trainer-modal-input-container">
							<FormTextInput 
	                            value={email}
	                            placeholder="Enter your Coach's email address..." 
	                            alert={inputAlert} 
	                            alertText=""
	                            isSearch={true}
	                            isOptional={false}
	                            inputChanged={(e: string) => inputChanged(e, 'email')}/>

	                    </div>
	                    <div onClick={() => validateEmailInput() ? addEmail() : null} className={`add-trainer-modal-add-button ${validateEmailInput() ? 'add-trainer-modal-add-button-active' : ''}`}>
                            <Add className="add-trainer-modal-add-button-icon"/>
	                    	<p>Add</p>
	                    </div>
                    </div>
                    <div hidden={emails.length === 0} className="new-trainer-modal-trainers-header-container">
                    	<div className="new-trainer-modal-trainers-header-col new-trainer-modal-trainers-cell-email-container">
                    		<p>Email Address</p>
                    	</div>
                    	<div className="new-trainer-modal-trainers-header-col new-trainer-modal-trainers-cell-permissions-container-outter">
                    		<p>Permissions</p>
                    	</div>
                    	<div className="new-trainer-modal-trainers-header-col new-trainer-modal-trainers-cell-permissions-container-outter">
                    		<p>Coaching Location</p>
                    	</div>
                    </div>
                    <div className="new-trainer-modal-trainers-container">
                    	{
                    		emails.map((item: AddingTrainer_t, index: number) => (
                    			<div className="new-trainer-modal-trainers-cell">
                    				<div className="new-trainer-modal-trainers-cell-email-container">
	                    				<h4>{item.email}</h4>
	                    			</div>
	                    			<div className="new-trainer-modal-trainers-cell-permissions-container-outter">
		                    			<div onClick={() => toggleShowPermissions(index)} className="new-trainer-modal-trainers-cell-permissions-container">
		                    				<h4>
		                    					{permissionTitleStrings[item.permission]}
		                    				</h4>
		                    				<div hidden={!item.isShowing} className="new-trainer-modal-trainers-cell-permissions-arrow-icon-container">
		                    					<ExpandLess className="new-trainer-modal-trainers-cell-permissions-arrow-icon"/>
		                    				</div>
		                    				<div hidden={item.isShowing} className="new-trainer-modal-trainers-cell-permissions-arrow-icon-container">
		                    					<ExpandMore className="new-trainer-modal-trainers-cell-permissions-arrow-icon"/>
		                    				</div>
		                    				
		                    			</div>
		                    			<CSSTransition in={item.isShowing} timeout={200} classNames="menu" unmountOnExit>
	                    					<div className="new-trainer-modal-trainers-cell-permissions-menu-container">
	                    						<div className="new-trainer-modal-trainers-cell-permissions-menu-header-container">
	                    							<h4>User Permissions</h4>
		                    					</div>
		                    					<div className="new-trainer-modal-trainers-cell-permissions-menu-body-container">
			                    					<div onClick={() => updateUserPermissions(index, 0)} className={`new-trainer-modal-trainers-cell-permissions-menu-row-container ${item.permission === 0 ? 'new-trainer-modal-trainers-cell-permissions-menu-row-container-selected' : ''}`}>
		                    							<h4>Gym Administrator</h4>
		                    							<p>All access granted to view, add, and edit Programs, Athletes, Coaches, and settings for all gym locations.</p>
			                    					</div>
			                    					<div onClick={() => updateUserPermissions(index, 1)} className={`new-trainer-modal-trainers-cell-permissions-menu-row-container ${item.permission === 0 ? 'new-trainer-modal-trainers-cell-permissions-menu-row-container-selected' : ''}`}>
		                    							<h4>Location Administrator</h4>
		                    							<p>All access granted to view, add, and edit Programs, Athletes, Coaches, and settings for their location only.</p>
			                    					</div>
			                    					<div onClick={() => updateUserPermissions(index, 2)} className={`new-trainer-modal-trainers-cell-permissions-menu-row-container ${item.permission === 1 ? 'new-trainer-modal-trainers-cell-permissions-menu-row-container-selected' : ''}`}>
		                    							<h4>Standard Coach</h4>
		                    							<p>Able to view, add, and edit their own Programs and Athletes, and schedule appointments.</p>
			                    					</div>
			                    					<div onClick={() => updateUserPermissions(index, 3)} className={`new-trainer-modal-trainers-cell-permissions-menu-row-container ${item.permission === 2 ? 'new-trainer-modal-trainers-cell-permissions-menu-row-container-selected' : ''}`}>
		                    							<h4>Observe Only</h4>
		                    							<p>Able to view, but not add or edit, Studio-wide Programs, Athletes, and Coaches, and setup appointments.</p>
			                    					</div>
		                    					</div>
	                    					</div>
	                    				</CSSTransition>
	                    			</div>
	                    			<div hidden={locations.length !== 0} className="new-trainer-modal-trainers-cell-permissions-container-outter new-trainer-modal-trainers-cell-location-container-outter">
	                    				<div className="new-trainer-modal-trainers-cell-permissions-container new-trainer-modal-trainers-cell-permissions-container-blank">
		                    				<h4>None added</h4>
	                    				</div>
	                    			</div>
	                    			<div hidden={locations.length === 0} className="new-trainer-modal-trainers-cell-permissions-container-outter new-trainer-modal-trainers-cell-location-container-outter">
		                    			<div onClick={() => toggleShowLocations(index)} className="new-trainer-modal-trainers-cell-permissions-container">
		                    				<h4>
		                    					{item.location !== undefined ? item.location.title : (locations[0] !== undefined ? locations[0].title : "My Studio")}
		                    				</h4>
		                    				<div hidden={!item.isShowingLocation} className="new-trainer-modal-trainers-cell-permissions-arrow-icon-container">
		                    					<ExpandLess className="new-trainer-modal-trainers-cell-permissions-arrow-icon"/>
		                    				</div>
		                    				<div hidden={item.isShowingLocation} className="new-trainer-modal-trainers-cell-permissions-arrow-icon-container">
		                    					<ExpandMore className="new-trainer-modal-trainers-cell-permissions-arrow-icon"/>
		                    				</div>
		                    				
		                    			</div>
		                    			<CSSTransition in={item.isShowingLocation} timeout={200} classNames="menu" unmountOnExit>
	                    					<div className="new-trainer-modal-trainers-cell-permissions-menu-container">
	                    						<div className="new-trainer-modal-trainers-cell-permissions-menu-header-container">
	                    							<h4>Coaching Location</h4>
		                    					</div>
		                    					<div className="new-trainer-modal-trainers-cell-permissions-menu-body-container">
		                    						{
		                    							locations.map((item_location: Location_t, index_location: number) => (
		                    								<div onClick={() => updateUserLocation(index, item_location)} className={`new-trainer-modal-trainers-cell-permissions-menu-row-container ${item.permission === 0 ? 'new-trainer-modal-trainers-cell-permissions-menu-row-container-selected' : ''}`}>
				                    							<h4>{item_location.title}</h4>
				                    							<p>{item_location.location.address_string}</p>
					                    					</div>
		                    							))
		                    						}
		                    					</div>
	                    					</div>
	                    				</CSSTransition>
	                    			</div>
	                    			<div onClick={() => removeTrainer(index)} className="new-trainer-modal-trainers-cell-close-container">
	                    				<Close className="new-trainer-modal-trainers-cell-close-icon"/>
	                    			</div>
                    			</div>
                    		))
                    	}
                    </div>
				</div>
				<div hidden={workflowState !== 0} className="feedback-modal-bottom">
					<div onClick={() => closePressed()} className="feedback-modal-close-button">Cancel</div>
					<div onClick={() => submit()} className="feedback-modal-cta-button">Send Invitation</div>
				</div>
				<div hidden={workflowState !== 1} className="feedback-modal-body-container new-trainer-modal-body-container-loading">
					<div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Adding Coaches...</p>
				</div>
				<div hidden={workflowState !== 2} className="feedback-modal-body-container new-trainer-modal-body-container-done">
					<h4>Invitations sent!</h4>
                    <p>Your Coaches have been added to your Studio, and invitations were sent our to the provided emails. You will recieve a notification when they set up their Eigen accounts.</p>
                    <div onClick={() => closePressed()} className="feedback-modal-cta-button feedback-modal-cta-button-done">Done</div>
				</div>
			</div>
		</div>
	)
}

export default AddTrainerModal;

