import React, { useState, useEffect } from 'react';
import './PerformanceAnalysis.css';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../../Firebase';

import WeekDayTimes_t from '../../../Interfaces/Locations/WeekDayTimes_t';
import Amenity_t from '../../../Interfaces/Locations/Amenity_t';

import CheckBox from '../../Base/CheckBox';
import PerformanceAnalysisLogCard from './components/PerformanceAnalysisLogCard';
import ClientPicker from './components/ClientPicker';
import PerformanceAnalysisSummary from './PerformanceAnalysisSummary';
import LogSummary from './LogSummary';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';



function PerformanceAnalysis(props: any) {

    const [showEdit, setShowEdit] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);

    const [clientID, setClientID] = useState(""); //IDMl0c4MQbacldKQPN7D4Vq0eNz1
    const [clientName, setClientName] = useState("your client");
    const [logID, setLogID] = useState("");
    const [logTitle, setLogTitle] = useState("");
    const [logTimestampList, setLogTimestampList] = useState<number[]>([]);
    const [logData, setLogData] = useState<any | undefined>();

    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            var arrTemp = [];
            for (var i = 0; i < 28; i++) {
                arrTemp.push( Date.now() - (24 * 3600 * 1000 * i) );
            }
            setLogTimestampList(arrTemp);
        }
    }, [initSet])

    useEffect(() => {
        getClientName();
    }, [clientID])

    useEffect(() => {
        if (props.information && props.information.client_id) {
            setClientID(props.information.client_id)
        }
    }, [props.information])

    const getClientName = async () => {
        if (clientID === "") return;
        let location = `user_profile/${clientID}/first_name`;
        let resp = await getDBDirect(location);
        setClientName(!resp || resp === "" ? "your client" : resp);
    }

    const configureWorkoutLogList = () => {

    }

    const clientUpdated = (toID: string) => {
        if (toID !== "") setClientID(toID);
    }

    const getDayTimestamp = (index: number) => {
        return Date.now() - (index * 24 * 3600 * 1000);
    }

    const getDataPageTitle = () => {
        return "4 Week Summary"
    }


    const logSelected = (id: string, title: string) => {
        if (id === "") return
        setLogID(id);
        setLogTitle(title === "" ? "Unnamed Workout" : title);
    }

    const clearLogSelection = () => {
        setLogID("");
        setLogTitle("");
    }

    const getDBDirect = async (location: string) => {
        return new Promise<any>(async (resolve, reject) => {
            if (location === "") reject();
            try {
                let database = firebase.database();
                let ref = database.ref(location);
                ref.once('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        resolve(snapshot.val());
                    } else {
                        reject();
                    }
                })
                .catch((err: any) => {
                    reject(err);
                })
            } catch (err) {
                reject(err);
            }
        })
        
    }


	return (
		<div className="modal-background">
            <div className={`performance-analysis`}>
                <div className="performance-analysis-upper">
                    <div className={ "performance-analysis-upper-content" }>
                        <div onClick={() => props.hideModalPressed()} className="performance-analysis-upper-close-button-container">
                            <Close className="performance-analysis-upper-close-button-icon"/>
                        </div>
                        <h4>DynamicTrain Analysis</h4>
                    </div>
                    {
                        clientID !== "" &&
                        <div className={ "performance-analysis-upper-client-picker-container" }>
                            <ClientPicker
                                forceClientID={ clientID }
                                clientUpdated={ (e: string) => clientUpdated(e) }/>
                        </div>
                    }
                    
                </div>
                {
                    clientID === "" ?
                    <div className={ "performance-analysis-body performance-analysis-body-empty" }>
                        <div className={ "performance-analysis-body-empty-content" }>
                            <div className={ "performance-analysis-body-empty-content-header" }>
                                <h4>Select a client to view their DynamicTrain Analysis</h4>
                            </div>
                            <div className={ "performance-analysis-body-empty-content-picker-container" }>
                                <ClientPicker
                                    clientUpdated={ (e: string) => clientUpdated(e) }/>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="performance-analysis-body">
                        <div className={"performance-analysis-list-wrapper"}>
                            <div className={ "performance-analysis-list-header" }>
                                <div className={ "performance-analysis-list-header-showing-row" }>
                                    <div className={ "performance-analysis-list-header-showing-title-container" }>
                                        <h4>Showing</h4>
                                    </div>
                                    <div className={ "performance-analysis-list-header-showing-pill" } onClick={ () => clearLogSelection() }>
                                        <h4>{ logID === "" ? "4 Week Summary" : logTitle }</h4>
                                        <div className={ "performance-analysis-list-header-showing-pill-close-button" } hidden={ logID === "" }>
                                            <Close className="performance-analysis-list-header-showing-pill-close-button-icon"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"performance-analysis-list-container"} hidden={ isLoadingList }>
                                <div className={"performance-analysis-list-content"} >
                                    {
                                        logTimestampList.map((item: number, index: number) => (
                                            <PerformanceAnalysisLogCard
                                                key={`perf-analysis-card-${index}`}
                                                clientID={ clientID }
                                                selectedLogID={ logID }
                                                logSelected={ (id: string, title: string) => logSelected(id, title) }
                                                timestamp={ item }/>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={"performance-analysis-list-content performance-analysis-list-content-loading"} hidden={ !isLoadingList }>
                                <div className="client-list-container-loading-spinner"/>
                                <p>Loading Workouts...</p>
                            </div>
                        </div>
                        <div className={"performance-analysis-data-container"}>

                            {
                                logID === "" ?
                                <PerformanceAnalysisSummary
                                    clientID={ clientID }
                                    clientName={ clientName }/>
                                :
                                <LogSummary
                                    clientID={ clientID }
                                    clientName={ clientName }
                                    logID={ logID }/>
                            }
                            
                        </div>
                    </div>  
                }
                   
            </div>
		</div>
	)
}

export default PerformanceAnalysis;