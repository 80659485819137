import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import firebase from '../../../Firebase';
import Mixpanel from '../../../Mixpanel';

import './WorkoutPlanList.css';

import FormTextInput from '../../../components/Base/FormTextInput';
import WorkoutPlanListWorkoutCell from './WorkoutPlanListWorkoutCell';
import WorkoutPlanListFolder from './WorkoutPlanListFolder';

import WorkoutPlanListCycleOverview from './WorkoutPlanListPages/WorkoutPlanListCycleOverview';
import WorkoutPlanListPlanOverview from './WorkoutPlanListPages/WorkoutPlanListPlanOverview';
import WorkoutPlanListPlan from './WorkoutPlanListPages/WorkoutPlanListPlan';

import InfoCard from '../../../components/Base/InfoCard';

import Workout_t from '../../../Interfaces/Workout_t'
import Folder_t from '../../../Interfaces/Folder_t';
import Plan_t from '../../../Interfaces/Plan_t';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';

import PlayArrow from '@material-ui/icons/PlayArrow';

import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Info from '@material-ui/icons/Info';
import Check from '@material-ui/icons/Check';
import FileCopy from '@material-ui/icons/FileCopy';

function WorkoutPlanList(props: any) {

    let initFolder = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
        };

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [path,setPath] = useState("My workout plans"); // /Bodybuilding/3-day split
    const [path_ids,setPath_ids] = useState("");
    const [folders, setFolders] = useState<Folder_t[]>([]);
    const [showingFolders, setShowingFolders] = useState<Folder_t[]>([]);
    const [currentFolder, setCurrentFolder] = useState<Folder_t>(initFolder);
    const [programFolder, setProgramFolder] = useState<Folder_t>(initFolder);
    const [rootFolder, setRootolder] = useState<Folder_t>(initFolder);
    const [plan, setPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
    const [showingPlan, setShowingPlan] = useState(false);

    const [copiedWorkout, setCopiedWorkout] = useState<Workout_t | undefined>(undefined);
    const [copiedPhase, setCopiedPhase] = useState<Plan_t | undefined>(undefined);
    const [copiedCycle, setCopiedCycle] = useState<Folder_t | undefined>(undefined);

    const [newFolderName, setNewFolderName] = useState("");
    const [editedFolderName, setEditedFolderName] = useState("");
    const [editingFolderName, setEditingFolderName] = useState(false);

    const [creatingFolderState, setCreatingFolderState] = useState(0);
    const [showAddFolderMenu, setShowAddFolderMenu] = useState(false);

    const [creatingCycleState, setCreatingCycleState] = useState(0);
    const [showAddCycleMenu, setShowAddCycleMenu] = useState(false);
    const [cyclesToAdd, setCyclesToAdd] = useState(3);
    const [showMenu, setShowMenu] = useState(false);

    const [addingState, setAddingState] = useState(-1);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getPlans();
    }

    useEffect(() => {
        // console.log("--- Workout Plan List: currentFolder updated to: ", currentFolder);
    }, [currentFolder])

    function getPlans() {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid; // "oJzZJ5QZEHaop5JV3pO3DRklhyT2"

        //setIsLoading(true);

        var firstTime = true;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let foldersRoot = data.folders_root;
                let foldersInRootKeys = Object.keys(foldersRoot);
                let numFoldersInRoot = foldersInRootKeys.length;
                // console.log(`${numFoldersInRoot} folders in root`);

                var foldersRootList: Folder_t[] = [];
                for (var i = 0; i < numFoldersInRoot; i++) {
                    let thisFolderObj = foldersRoot[foldersInRootKeys[i]];
                    let resultFolder: Folder_t = parseFolder(thisFolderObj, foldersInRootKeys[i]);
                    foldersRootList.push(resultFolder);
                }

                // console.log("PATH: ", path);
                // console.log("PATH IDs: ", path_ids);

                // Keep isOpen property
                attemptOpenStatusRecovery(foldersRootList)

            } else {
                // No plans exist
                //setIsLoading(false);

                setFolders(folders.filter((item: Folder_t, index: number) => {return false;}))
            }
        });
    }

    function attemptOpenStatusRecovery(withList: Folder_t[]) {
        if (folders.length > 0) {
            let folderResult: Folder_t[] = [];

            for (var i = 0; i < withList.length; i++) {
                let thisResult: Folder_t = configureOpenStatus(withList[i], folders[i]);
                folderResult.push(thisResult);
            }

            //setFolders(folders.filter(item => false));
            setFolders(folderResult);
        } else {
            setFolders(withList);
            updateShowingFolders(withList);
        }
    }

    function configureOpenStatus(forFolder: Folder_t, refFolder: Folder_t) {

        let f = forFolder;
        if (refFolder === undefined) { return f; }
        f.isOpen = refFolder.isOpen;
        //// console.log(`Obj ${f.title}: ${f.isOpen ? 'IS OPEN' : 'IS CLOSED'}\t\tRef ${refFolder.title}: ${refFolder.isOpen ? 'IS OPEN' : 'IS CLOSED'}`);

        var fChildTemp: Folder_t[] = [];

        if (f.children !== undefined && f.children.length > 0) {
            for (var i = 0; i < f.children.length; i++) {
                let thisChild: Folder_t = f.children[i];
                if (i < forFolder.children.length) {
                    let thisRefChild = forFolder.children[i];
                    let childResult = configureOpenStatus(thisChild, thisRefChild);
                    fChildTemp.push(childResult);
                } else {
                    thisChild.isOpen = false;
                }
            }
        }

        if (f.id === currentFolder.id) { // && currentFolder.type !== 'cycle') {
            f.isOpen = true; //currentFolder.isOpen;
            //setCurrentFolder(f);
            folderPressed(f);
            // console.log("CURRENT FOLDER PATH SET TO: ", f.path, f);
        } else if (path_ids.includes(f.id)){
            f.isOpen = true;
        }


        f.children = fChildTemp;
        //// console.log(`Obj ${f.title}: WILL BE ${f.isOpen ? 'OPEN' : 'CLOSED'}`);
        return f;
    }

    function updateShowingFolders(newFolders: Folder_t[]) {
        let splitPath = currentFolder.path.split("/");
        //// console.log("splitPath: ", splitPath);
        var parentFolder: Folder_t = initFolder;
        parentFolder.children = newFolders;
        for (var i = 0; i < splitPath.length; i++) {
            let thisPathID = splitPath[i];
            for (var j = 0; j < parentFolder.children.length; j++) {
                let thisChild =  parentFolder.children[j];
                //// console.log(`i: ${i}`, `j: ${j}`, thisPathID, thisChild.id);
                if (thisChild.id === thisPathID) {
                    parentFolder = thisChild;
                }
            }
        }
        //// console.log("updateShowingFolders: ", parentFolder);
        setShowingFolders(parentFolder.children);
    }

    function parseFolder(folderObj: any, folderKey: string) {

        let folderObjType = folderObj.type;
        let isFolder = folderObjType === "folder";

//        if (isFolder) {
            var children: Folder_t[] = [];

            let thisObjChildren = folderObj.children;
            if (thisObjChildren !== undefined) {
                let childFolderKeys = Object.keys(thisObjChildren);
                let numChildFolders = childFolderKeys.length;
                for (var i = 0; i < numChildFolders; i++) {
                    let thisFolderObj = thisObjChildren[childFolderKeys[i]];
                    let resultFolder: Folder_t = parseFolder(thisFolderObj, childFolderKeys[i]);
                    children.push(resultFolder);
                }
            }
            

            var returnFolder: Folder_t = {
                title: folderObj.name,
                id: folderKey,
                type: folderObjType,
                path: folderObj.path,
                isPlan: folderObjType === "plan",
                children: children,
                isOpen: false
            }

            if (folderObjType === "plan") {
                returnFolder.plan_id = folderObj.plan_id
                returnFolder.phaseName = folderObj.phase_name;
            }

            return returnFolder;
        // } else {

        //     // Plan found at end of folder chain
        //     let planFolder: Folder_t = {
        //         title: folderObj.name,
        //         isPlan: true,
        //         type: folderObjType,
        //         path: folderObj.path,
        //         plan_id: folderObj.plan_id,
        //         children: [],
        //         id: folderKey
        //     }

        //     return planFolder;
        // }
        
    }

    function getPathDivided() {
        let splitPath = path.split('/');
        return splitPath;
    }

    function pathContentPressed(atIndex: number) {
        let splitPath = path.split('/');
        var composedPath = "";
        for (var i = 0; i <= atIndex; i++) {
            composedPath += splitPath[i];
            composedPath += i < atIndex ? "/" : "";
        }

        setPath(composedPath);
        setShowingPlan(false);

        // Change path_ids
        let newSplitPath = composedPath.split('/');
        let depth = newSplitPath.length;

        // console.log("path IDs pre: ", path_ids);
        let splitPath_ids = path_ids.split('/');
        // console.log(splitPath_ids);
        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += '/';
            composedPath_ids += splitPath_ids[i];
        }

        // console.log("path IDs post: ", composedPath_ids);
        setPath_ids(composedPath_ids);

        // Change showingFolders
        
        var s: Folder_t[] = folders;
        var subFolder_temp: Folder_t = folders[0];
        // console.log(`Nesting depth : ${depth}`);
        for (var i = 1; i < depth; i++) {
            let thisSubPath = newSplitPath[i];
            for (var j = 0; j < s.length; j++) {
                let thisFolder = s[j];
                // console.log(thisFolder.title,thisSubPath);
                if (thisFolder.title === thisSubPath) {
                    subFolder_temp = thisFolder;
                }
            }
        }

        setShowingFolders(depth === 1 ? folders : subFolder_temp.children);

    }

    function folderPressed(folder: Folder_t) {
        let thisFolderPath = folder.path;

        var newFolderArray: Folder_t[] = [];
        if (!folder.isPlan) {
            // A folder was selected.
            setShowingFolders(folder.children);
            setPath(path + `/${folder.title}`);
            setPath_ids(folder.path);
            // console.log("Setting path IDs to: ", folder.path);
            // console.log("setCurrentFolder A")
            setCurrentFolder(folder);

        } else {
            // console.log("CLICKED PLAN - SEE folderPressed()");
            getPlan(folder.plan_id);
        }

    }

    function getPlan(withID?: string) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return 'No workouts in plan'; }
        let trainerUID = currentUser.uid;

        if (withID === undefined) { console.log(`Undefined | No workout found with ID: ${withID}`);}

        let database = firebase.database();
        //let ref = database.ref(`workout_plans/${trainerUID}/plans/${withID}`);
        let ref = database.ref(`workout_plans/plans/${withID}`);

        setIsLoading(true);
        setShowingPlan(true);
        
        ref.once('value')
        .then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                // console.log(data);

                let planWorkouts: string[] = data.workouts

                let plan_temp: Plan_t = {
                    title: data.title,
                    id: withID !== undefined ? withID : "",
                    createdBy_ID: data.created_by,
                    created_timestamp: data.created_timestamp,
                    workout_ids: planWorkouts
                }

                setPlan(plan_temp);
                setIsLoading(false);
            } else {
                // console.log(`No workout found with ID: ${withID}`);
                setIsLoading(false);
            }
        });
    }

    function getNumberOfPlans(folder: Folder_t) {
        return 12;
    }

    function getNumberOfFoldersInDirectory() {
        var numFolders = 0;
        for (var i = 0; i < showingFolders.length; i++) {
            let thisFolder = showingFolders[i];
            if (thisFolder.isPlan === false) {
                numFolders += 1;
            }
        }

        return numFolders;
    }

    function getNumberOfPlansInDirectory() {
        var numPlans = 0;
        for (var i = 0; i < showingFolders.length; i++) {
            let thisFolder = showingFolders[i];
            if (thisFolder.isPlan === true) {
                numPlans += 1;
            }
        }

        return numPlans;
    }

    function getNumPlansInFolder(folder: Folder_t) {
        if (folder.isPlan === true) { return 1; }
        var numPlans = 0;
        for (var i = 0; i < folder.children.length; i++) {
            let thisChild = folder.children[i];
            let thisChildNumChildren = getNumPlansInFolder(thisChild);
            numPlans += thisChildNumChildren;
        }

        return numPlans;
    }

    function assignToClient() {
        props.assignClientToPlan(plan);
    }

    function editPlan() {

    }

    function toggleShowAddFolderMenu() {
        setCreatingFolderState(0);
        setShowAddFolderMenu(!showAddFolderMenu);
        setShowAddCycleMenu(false);
    }

    function toggleShowAddCycleMenu() {
        setCreatingCycleState(0);
        setShowAddCycleMenu(!showAddCycleMenu);
        setShowAddFolderMenu(false);
    }

    function inputChanged(folderName: string) {
        setNewFolderName(folderName);
    }

    function inputChanged_editFolder(folderName: string) {
        setEditedFolderName(folderName);
    }

    function createFolder(withName?: string, followOnCycle?: number) {
        if (newFolderName === "" && withName === undefined) { return; }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let splitPath_ids = path_ids.split('/');
        let depth = splitPath_ids.length;

        console.log(`Creating folder at ${path_ids}`);

        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += "/";
            composedPath_ids += splitPath_ids[i];
            composedPath_ids += "/children";
        }
        // console.log(`Final FB location at ${composedPath_ids}`);


        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root${composedPath_ids}`);
        //let key = ref.push().key;


        let newFolderObj = {
            type: "folder",
            name: withName !== undefined ? withName : newFolderName,
            path: path_ids,
            children: []
        }  


        // console.log(newFolderObj);
        let pushRef = ref.push(newFolderObj);
        let key = pushRef.key;

        let newRef = ref.child(`${key}/path`);
        newRef.set(`${path_ids}/${key}`);

        setCreatingFolderState(1);
        //setIsLoading(true);

        let newFolder: Folder_t = {
            id: key === null ? '' : key,
            type: "folder",
            title: withName !== undefined ? withName : newFolderName,
            children: [],
            path: `${path_ids}/${key === null ? '' : key}`,
            isPlan: false,
            isOpen: false
        }

        setTimeout(() => {
            setCreatingFolderState(2);
            getPlans();

            folderPressed(newFolder);
            setIsLoading(false);

            if (followOnCycle !== undefined && followOnCycle > 0) {
                let newPath = `${path_ids}/${key === null ? '' : key}`;
                createCycle(followOnCycle, newPath)
            }

            // setTimeout(() => {
            //     clearNewFolder();
                
            // }, 5000);
        }, 300);
    }

    function createFolderAtRoot(withName?: string) {
        if (newFolderName === "" && withName === undefined) { return; }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let splitPath_ids = "".split('/');
        let depth = splitPath_ids.length;

        // console.log(`Creating folder at ${path_ids}`);

        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += "/";
            composedPath_ids += splitPath_ids[i];
            composedPath_ids += "/children";
        }
        // console.log(`Final FB location at ${composedPath_ids}`);


        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root${composedPath_ids}`);
        //let key = ref.push().key;


        let newFolderObj = {
            type: "folder",
            name: withName !== undefined ? withName : newFolderName,
            path: "",
            children: []
        }  


        // console.log(newFolderObj);
        let pushRef = ref.push(newFolderObj);
        let key = pushRef.key;

        let newRef = ref.child(`${key}/path`);
        newRef.set(`/${key}`);

        setCreatingFolderState(1);
        //setIsLoading(true);

        let newFolder: Folder_t = {
            id: key === null ? '' : key,
            type: "folder",
            title: withName !== undefined ? withName : newFolderName,
            children: [],
            path: `/${key === null ? '' : key}`,
            isPlan: false,
            isOpen: false
        }

        setTimeout(() => {
            setCreatingFolderState(2);
            getPlans();

            folderPressed(newFolder);
            setIsLoading(false);

            // setTimeout(() => {
            //     clearNewFolder();
                
            // }, 5000);
        }, 300);
    }

    function clearNewFolder() {
        setShowAddFolderMenu(false);
        setCreatingFolderState(0);
        setAddingState(-1);
    }

    function createCycle(numberPhasesRequested?: number, forcePath?: string) {

        console.log("Create cycle at", forcePath, numberPhasesRequested, path_ids)

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        if (numberPhasesRequested === undefined) {
            Mixpanel.track("Cycle added", {num_phases: cyclesToAdd, location: "workout_plan_list"});
        }

        //setIsLoading(true);
        let refPathIds = forcePath !== undefined && forcePath !== "" && (forcePath.split('/')).length > 1 ? forcePath : path_ids;
        let splitPath_ids = refPathIds.split('/');
        let depth = splitPath_ids.length;

        // console.log(`Creating folder at ${path_ids}`);

        var composedPath_ids = "";
        for (var i = 1; i < depth; i++) {
            composedPath_ids += "/";
            composedPath_ids += splitPath_ids[i];
            composedPath_ids += "/children";
        }
        // console.log(`Final FB location at ${composedPath_ids}`);


        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root${composedPath_ids}`);
        //let key = ref.push().key;

        // Calculate this cycle number
        var cycleNum = 1;
        var numPhases = 0;
        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {
            var cycleExists = false;
            for (var i = 0; i < currentFolder.children.length; i++) {
                if (currentFolder.children[i].type === "cycle") { 
                    cycleExists = true; 
                    let thisCycleFolder = currentFolder.children[i];
                    if (thisCycleFolder.children !== undefined && thisCycleFolder.children.length > 0) {
                        for (var j = 0; j < thisCycleFolder.children.length; j++) {
                            if (thisCycleFolder.children[j].type === "plan") {
                                numPhases += 1;
                            }
                        }
                    }
                }
            }

            if (cycleExists) {
                cycleNum = currentFolder.children.length + 1;
            }
        }

        let newFolderObj = {
            type: "cycle",
            name: `Cycle ${cycleNum}`,
            path: refPathIds,
            children: []
        }   

        

        // console.log(newFolderObj);
        let pushRef = ref.push(newFolderObj);
        let key = pushRef.key;

        let newRef = ref.child(`${key}/path`);
        newRef.set(`${refPathIds}/${key}`);

        let newCycle: Folder_t = {
            id: key === null ? '' : key,
            type: "cycle",
            title: `Cycle ${cycleNum}`,
            children: [],
            path: `${refPathIds}/${key === null ? '' : key}`,
            isPlan: false,
            isOpen: true
        }

        let numPhasesToAdd = numberPhasesRequested === undefined ? cyclesToAdd : numberPhasesRequested;
        for (var i = 0; i < numPhasesToAdd; i++) {
            if (key !== null) {
                addChildToCycle(key, numPhases + i, ref, forcePath);
            }
            
        }

        setCreatingCycleState(1);

        setTimeout(() => {
            setCreatingCycleState(2);
            getPlans();
            setIsLoading(false);
            //folderPressed(currentFolder);
            setTimeout(() => {
                setShowAddFolderMenu(false);
                setCreatingCycleState(0);
                setAddingState(-1);
            }, 5000);
        }, 600);
    }

    function addChildToCycle(cycleID: string, index: number, ref: firebase.database.Reference, forcePath?: string) {
        
        let refPathIds = forcePath !== undefined && forcePath !== "" && (forcePath.split('/')).length > 1 ? forcePath : path_ids;

        let thisPhaseObj = {
            type: "plan",
            name: `Phase ${index + 1}`,
            path: `${refPathIds}/${cycleID}`
        };

        let pushRef = ref.child(`${cycleID}/children`).push(thisPhaseObj);
        let key = pushRef.key;

        let newRef = ref.child(`${cycleID}/children/${key}/path`);
        newRef.set(`${refPathIds}/${cycleID}/${key}`);
    }

    function navigateToCurrentFolder() {

    }

    function getFolderID(thisPathName: string, children: Folder_t[]) {

    }

    function newProgramPressed() {
        props.addProgramPressed(currentFolder);
        props.addProgramPressed({parent: currentFolder, callback: addProgramCallback});
    }

    function addProgramCallback(resp: any) {
        let thisProgramName = resp && resp.program_title ? resp.program_title : "Unnamed Program"
        let numPhases = resp && resp.num_phases ? resp.num_phases : 3
        setIsLoading(true);

        createFolder(thisProgramName, numPhases)
    }

    function newPlanPressed() {
        setShowAddCycleMenu(false);
        setShowAddFolderMenu(false);
        props.newPlanPressed(currentFolder)
    }

    function newPlanCallback(planObj: any) {
        // console.log("!!!!!----!!!! CALLBACK: ", planObj);
        getPlans();
    }

    function adjustNumberOfPhasesInCycle(adj: number) {
        if (cyclesToAdd + adj > 0) {
            setCyclesToAdd(cyclesToAdd + adj);
        }
    }

    function folderSelected(folder: Folder_t) {

        // console.log(`WorkoutPlanList | folderSelected: `, folder, folder.path);

        // console.log("setCurrentFolder B")
        setCurrentFolder(folder);
        setAddingState(-1);

        setEditedFolderName(folder.title);

        var folderDepth = 0;
        if (folder.path === undefined) { return; }
        if (folder.path !== "") {
            let folderPathDivided = folder.path.split('/');
            folderDepth = folderPathDivided.length;
        }
        Mixpanel.track("Folder pressed",{folder_type: folder.type, title: folder.title, depth: folderDepth});

        let thisFolderPath = folder.path;

        var newFolderArray: Folder_t[] = [];
        if (!folder.isPlan) {
            // A folder was selected.
            setShowingFolders(folder.children);
            setPath(path + `/${folder.title}`);
            setPath_ids(folder.path);
            // console.log("Setting path IDs to: ", folder.path);

            // console.log("setCurrentFolder C")
            setCurrentFolder(folder);
            setProgramFolder(folder);
        } else {
            // console.log("CLICKED PLAN - SEE folderPressed()");
            getPlan(folder.plan_id);
        }
    }

    function addFolderToRootPressed() {
        setPath_ids("");
        Mixpanel.track("Add Folder pressed", {to_state: addingState === 0 ? 'closing' : 'opening'});
        //setAddingState(addingState === 0 ? -1 : 0);
        props.addElementPressed({type: 'folder', parent: rootFolder, callback: addFolderAtRootCallback});
    }

    function addFolderAtRootCallback(name: string) {
        createFolderAtRoot(name);
    }

    function addFolderPressed() {
        Mixpanel.track("Add Folder pressed", {to_state: addingState === 0 ? 'closing' : 'opening'});
        //setAddingState(addingState === 0 ? -1 : 0);
        props.addElementPressed({type: 'folder', parent: currentFolder, callback: addFolderCallback});
    }

    function addFolderCallback(name: string) {
        createFolder(name);
    }



    function addCyclePressed() {
        Mixpanel.track("Add Cycle pressed", {to_state: addingState === 1 ? 'closing' : 'opening', location: "workout_plan_list"});
        //setAddingState(addingState === 1 ? -1 : 1);
        props.addElementPressed({type: 'cycle', parent: currentFolder, callback: addCycleCallback});
    }

    function addCycleCallback(numPhases: number) {
        createCycle(numPhases);
    }

    function addPlanPressed() {
        Mixpanel.track("Add Plan pressed", {location: "empty folder"});
        setAddingState(-1);
        newPlanPressed();
    }

    function addPlanPressedInCycle(planFolder: Folder_t, cycleFolder: Folder_t) {
        Mixpanel.track("Add Plan pressed", {location: "cycle"});
        setShowAddCycleMenu(false);
        setShowAddFolderMenu(false);
        props.newPlanPressed(planFolder, newPlanCallback)
    }

    function addPlanPressedInPlan(planFolder: Folder_t) {
        Mixpanel.track("Add Plan pressed", {location: "plan"});
        setShowAddCycleMenu(false);
        setShowAddFolderMenu(false);
        props.newPlanPressed(currentFolder)
    }

    function showAddCyclesAndPlans() {
        if (currentFolder.title === "") { return false;}
        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {
            var allowShow = true;
            for (var i = 0; i < currentFolder.children.length; i++) {
                if (currentFolder.children[i].type === "folder") {
                    allowShow = false;
                }
            }

            return allowShow;
        }

        return true;
    }

    function getShowType() {
        // Showing type: 
        //    0 = show add screen - Currently in a folder with child folders, or an empty folder
        //    1 = show cycle layout - currently in a folder with only cycles
        //    2 = show plan layout - currently in cycle
        //    3 = show plan info - currently in plan

        if (currentFolder.title === "") {
            // Root folder - show add screen. Which buttons to show is adjusted via showAddCyclesAndPlans()
            return 0;
        }

        if (currentFolder.type === "cycle") {
            return 2;
        }

        if (currentFolder.type === "plan") {
            return 3;
        }

        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {

            for (var i = 0; i < currentFolder.children.length; i++) {
                let thisChild = currentFolder.children[i];
                if (thisChild.type === "cycle") {
                    // In folder with cycles
                    return 1;
                } else if (thisChild.type === "plan") {
                    // In folder with plans
                    return 1;
                }
            }
        }
        // Empty folder, or folder with only sub folders - add away!
        return 0;

    }

    function updateFolderDisplay(folder: Folder_t, isToggle: boolean) {

        // console.log(`WorkoutPlanList | updateFolderDisplay: `, folder, 'isToggle: ', isToggle);

        var folderObjTemp: Folder_t[] = [];

        for (var i = 0; i < folders.length; i++) {
            var thisFolderTemp = folders[i];
            let thisFolderID = folders[i].id;
            if (thisFolderID === folder.id) {
                thisFolderTemp.isOpen = isToggle ? !thisFolderTemp.isOpen : true;
            }
            thisFolderTemp = updateFolderDisplay_recursive(folder, thisFolderTemp, isToggle);
            folderObjTemp.push(thisFolderTemp);
        }

        setFolders(folderObjTemp);
    }

    function updateFolderDisplay_recursive(folder: Folder_t, checkingFolder: Folder_t, isToggle: boolean) {
        
        var folderTemp: Folder_t = checkingFolder;
        var folderChilrenTemp: Folder_t[] = [];

        if (folderTemp.children !== undefined && folderTemp.children.length > 0) {
            for (var i = 0; i < folderTemp.children.length; i++) {
                var thisFolderTemp = folderTemp.children[i];
                let thisFolderID = thisFolderTemp.id;

                if (thisFolderID === folder.id) {
                    thisFolderTemp.isOpen = isToggle ? !thisFolderTemp.isOpen : true;
                }
                thisFolderTemp = updateFolderDisplay_recursive(folder, thisFolderTemp, isToggle);
                folderChilrenTemp.push(thisFolderTemp);
            }
        }

        folderTemp.children = folderChilrenTemp;
        return folderTemp;
    }

    function infoCardClosePressed() {
        Mixpanel.track("Close Info Card pressed", {location: "workout_plan_list"});
    }

    function infoCardButtonPressed() {
        Mixpanel.track("Add Folder pressed from Info Card");
        addFolderPressed();
    }

    function jumpToParentFolder(ofFolder: Folder_t) {
        if (ofFolder === undefined || ofFolder.path === undefined) { return; }
        let pathSplit = ofFolder.path.split('/');
        if (pathSplit === undefined || pathSplit === null || pathSplit.length <= 1) { return; }

        var recomposedPath = '/';
        for (var i = 1; i < pathSplit.length - 1; i++) {
            recomposedPath += pathSplit[i];
            recomposedPath += i === pathSplit.length - 2 ? '' : '/';
        }

        // console.log("JUMP TO PARENT: ", recomposedPath);
        if (recomposedPath === '/') {
            folderSelected(initFolder);
        } else {
            setStaticPath(recomposedPath);
        }
        
    }

    function setStaticPath(toPath: string) {
        setStaticPath_recursion(toPath, folders);
    }

    function setStaticPath_recursion(toPath: string, forFolders: Folder_t[]) {
        if (forFolders.length === 0) { return; }
        for (var i = 0; i < forFolders.length; i++) {
            let thisFolder: Folder_t = forFolders[i];
            //// console.log(`${toPath}`, `${thisFolder.path}`);
            if (`${toPath}` === `${thisFolder.path}`) {
                folderPressed(thisFolder);
                //// console.log("setStaticPath_recursion: FOLDER AT PATH FOUND - ", toPath, thisFolder);
                return;
            } else {
                setStaticPath_recursion(toPath, thisFolder.children !== undefined ? thisFolder.children : []);
            }
        }
    }

    async function addPhaseToCycle(c: Folder_t) {
        if (!c || !c.path || c.path === "") return;

        Mixpanel.track("Adding Phase To Cycle");

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        //if (!currentFolder || !currentFolder.path || currentFolder.path === "") return;

        let folderPathSplit = c.path.split('/');
        var folderPathDB = `workout_plans/trainer_plans/${trainerUID}/folders_root`;
        for (var i = 1; i < folderPathSplit.length; i++) {
            let thisPathID = folderPathSplit[i];
            folderPathDB += `/${folderPathSplit[i]}`;
            folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        }

        setIsLoading(true);
        
        let deletedCycle: Folder_t = c;
        jumpToParentFolder(deletedCycle);
        
        let database = firebase.database();
        let ref = database.ref(folderPathDB);

        let newBlankPhase = {
            name: `Phase ${ c.children && c.children.length > 0 ? c.children.length + 1 : 'x'}`,
            path: c.path,
            type: "plan"
        }

        console.log("ADDING PHASE TO CYCLE:", folderPathDB, newBlankPhase, c)

        let pushRef = await ref.child('children').push(newBlankPhase);

        let newKey = pushRef ? pushRef.key : "";

        if (newKey && newKey !== "") {
            ref.child(`children/${newKey}/path`).set(`${c.path}/${newKey}`);
        }

        setTimeout(() => {
            // console.log("Successfully deleted folder", response);
            setIsLoading(false);
            getPlans();
        }, 300);


        // ref.set(newBlankPhase).then((response: any) => {
        //     setTimeout(() => {
        //         // console.log("Successfully deleted folder", response);
        //         setIsLoading(false);
        //         getPlans();
        //     }, 600);
            
        // })
        // .catch((err: any) => {
        //     setTimeout(() => {
        //         // console.log("ERROR! Could not delete folder", err);
        //         setIsLoading(false);
        //         getPlans();
        //     }, 600);
        // })
    }

    function confirmDeleteFolder() {

        Mixpanel.track("Delete Folder confirmed");

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        if (!currentFolder || !currentFolder.path || currentFolder.path === "") return;

        let folderPathSplit = currentFolder.path.split('/');
        var folderPathDB = `workout_plans/trainer_plans/${trainerUID}/folders_root`;
        for (var i = 1; i < folderPathSplit.length; i++) {
            let thisPathID = folderPathSplit[i];
            folderPathDB += `/${folderPathSplit[i]}`;
            folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        }

        setIsLoading(true);
        
        let deletedCycle: Folder_t = currentFolder;
        jumpToParentFolder(deletedCycle);

        console.log("Workout Plan List: confirmDeleteFolder | ", folderPathDB, currentFolder)
        
        let database = firebase.database();
        let ref = database.ref(folderPathDB);


        
        ref.set(null).then((response: any) => {
            setTimeout(() => {
                // console.log("Successfully deleted folder", response);
                setIsLoading(false);
                getPlans();
            }, 600);
            
        })
        .catch((err: any) => {
            setTimeout(() => {
                // console.log("ERROR! Could not delete folder", err);
                setIsLoading(false);
                getPlans();
            }, 600);
        })        
    }

    function confirmDeleteCycle(c: Folder_t) {

        if (!c || !c.path || c.path === "") return;

        Mixpanel.track("Delete Cycle confirmed");

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        //if (!currentFolder || !currentFolder.path || currentFolder.path === "") return;

        let folderPathSplit = c.path.split('/');
        var folderPathDB = `workout_plans/trainer_plans/${trainerUID}/folders_root`;
        for (var i = 1; i < folderPathSplit.length; i++) {
            let thisPathID = folderPathSplit[i];
            folderPathDB += `/${folderPathSplit[i]}`;
            folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        }

        setIsLoading(true);
        
        let deletedCycle: Folder_t = c;
        jumpToParentFolder(deletedCycle);

        console.log("Workout Plan List: confirmDeleteCycle | ", folderPathDB, c)
        
        let database = firebase.database();
        let ref = database.ref(folderPathDB);

        ref.set(null).then((response: any) => {
            setTimeout(() => {
                // console.log("Successfully deleted folder", response);
                setIsLoading(false);
                getPlans();
            }, 600);
            
        })
        .catch((err: any) => {
            setTimeout(() => {
                // console.log("ERROR! Could not delete folder", err);
                setIsLoading(false);
                getPlans();
            }, 600);
        })

        /*
        setIsLoading(true);
        let sendData = {
            user_uid: trainerUID,
            cycle_id: currentFolder.id,
            cycle_path: currentFolder.path
        };

        let deletedCycle: Folder_t = currentFolder;
        jumpToParentFolder(deletedCycle);

        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/deleteCycle`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response);
            // console.log(response.data);
            let data = response.data;

            setIsLoading(false);
            getPlans();
        })
        .catch(e => {
            // console.log(e);
            setIsLoading(false);
        })
        */
    }

    function checkShouldObliteratePlan() {
        // console.log("checkShouldObliteratePlan: ", currentFolder, folders);

        
        let splitPath: string[] = currentFolder.path.split('/');
        //if (splitPath.length <= 1) { return true; }                // Remove all plans with one or zero keys in path (also root level)

        var refPath = "";
        for (var i = 1; i < splitPath.length - 1; i++) {
            refPath += `/${splitPath[i]}`;
        }

        if (refPath === "") { return true; }            // Remove all plans at root level
        // console.log("Checking path v. plan path:", refPath, currentFolder.path);

        let obliterate = checkShouldObliteratePlan_childFolder(folders, refPath);
        // console.log("SHOULD obliterate:", obliterate);
        return obliterate;
    }

    function checkShouldObliteratePlan_childFolder(thisFolderList: Folder_t[], refPath: string): boolean {

        var thingToReturn = false;
        for (var i = 0; i < thisFolderList.length; i++) {
            let thisFolder: Folder_t = thisFolderList[i];
            if (thisFolder.path === refPath) {
                // console.log("MATCHING PATHS!!!", thisFolder, thisFolder.path, refPath, thisFolder.type);
                thingToReturn = thisFolder.type === "folder";
            } else if (thisFolder.children !== undefined && thisFolder.children.length > 0) {
                if (thingToReturn === false) {
                    thingToReturn = checkShouldObliteratePlan_childFolder(thisFolder.children, refPath);
                }
            }
        }

        return thingToReturn;
    }

    function confirmDeletePlan(p?: any) {

        if (!p || !p.path || p.path === "") return;

        console.log("---> confirmDeletePlan", p)

        Mixpanel.track("Delete Plan confirmed", p);

        //checkShouldObliteratePlan();

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let folderPathSplit = p.path.split('/');
        var folderPathDB = `workout_plans/trainer_plans/${trainerUID}/folders_root`;
        for (var i = 1; i < folderPathSplit.length; i++) {
            let thisPathID = folderPathSplit[i];
            folderPathDB += `/${folderPathSplit[i]}`;
            folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        }

        setIsLoading(true);
        
        let deletedCycle: Folder_t = p;
        jumpToParentFolder(deletedCycle);

        console.log("Workout Plan List: confirmDeletPhase | ", folderPathDB, p)
        
        let database = firebase.database();
        let ref = database.ref(folderPathDB);

        let newBlankPhase = {
            name: p.phaseName ?? "Phase",
            path: p.path,
            type: "plan"
        }

        ref.set(p.plan_id ? newBlankPhase : null).then((response: any) => {
            setTimeout(() => {
                // console.log("Successfully deleted folder", response);
                setIsLoading(false);
                getPlans();
            }, 600);
            
        })
        .catch((err: any) => {
            setTimeout(() => {
                // console.log("ERROR! Could not delete folder", err);
                setIsLoading(false);
                getPlans();
            }, 600);
        })




        /*
        let deletingPlan: Folder_t = p ?? currentFolder;

        let sendData = {
            user_uid: trainerUID,
            phase_id: currentFolder.id,
            phase_path: `path${currentFolder.path}`,
            obliterate: checkShouldObliteratePlan()
        };

        // console.log(sendData.phase_path.split('/'))

        console.log("DELETING: ", currentFolder);
        console.log("DELETING // will send: ", sendData);
        jumpToParentFolder(deletingPlan);
        */
        /*
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/deletePhase`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response);
            // console.log(response.data);
            // console.log(response.data.phase_path_db)
            let data = response.data;

            setIsLoading(false);
            getPlans();
        })
        .catch(e => {
            // console.log(e);
            setIsLoading(false);
        })

        */
    }

    function deleteFolderPressed() {
        Mixpanel.track("Delete Folder requested");
        setShowMenu(false);
        let info = {
            callback: confirmDeleteFolder,
            title: "Are you sure you want to delete this Item?",
            text: `Deleting this item, ${currentFolder.title}, will remove all Cycles, Phases, and other Folders inside. This action cannot be undone.`,
            isRed: true,
            ctaText: "Delete Item"
        }
        props.deleteCyclePressed(info);
    }

    function deleteCyclePressed(c?: Folder_t) {
        if (!c) return;
        Mixpanel.track("Delete Cycle requested");
        let info = {
            callback: confirmDeleteCycle,
            title: "Are you sure you want to delete this Cycle?",
            text: `Deleting ${c.title} will remove all phases that you have created inside of it. If you choose to delete the cycle, this action cannot be undone.`,
            isRed: true,
            ctaText: "Delete Cycle",
            callbackParam: c ? c : currentFolder
        }
        props.deleteCyclePressed(info);
    }

    function deletePlanPressed(p?: any) {
        console.log("--> deletePlanPressed,", p)
        Mixpanel.track("Delete Plan requested");
        let info: any = {
            callback: confirmDeletePlan,
            title: `Are you sure you want to ${ p && p.plan_id ? "clear" : "delete"} this Phase?`,
            text: p && p.plan_id ? `Clearing this Phase, ${p ? p.title : currentFolder.title}, will remove all information and workouts created specifically for the Phase. This action cannot be undone.`
                                 : `Deleting this Phase will permanently remove it from the cycle. It can be added back later if you wish to do so.`,
            isRed: true,
            ctaText: p && p.plan_id ? "Clear Phase" : "Delete Phase"
        }
        if (p) info["callbackParam"] = p;
        props.deleteCyclePressed(info);
    }

    function toggleMenuShow() {
        setShowMenu(!showMenu);
    }

    function updateFolderName(toFolderName: string) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setIsLoading(true);


        let folderPathSplit = currentFolder.path.split('/');
        var folderPathDB = `workout_plans/trainer_plans/${trainerUID}/folders_root`;
        for (var i = 1; i < folderPathSplit.length; i++) {
            let thisPathID = folderPathSplit[i];
            folderPathDB += `/${folderPathSplit[i]}`;
            folderPathDB += i === folderPathSplit.length - 1 ? '' : '/children';
        }

        let database = firebase.database();
        let ref = database.ref(folderPathDB);

        ref.child('name').set(toFolderName === "" ? "Unnamed" : toFolderName).then((response: any) => {
            setTimeout(() => {
                // console.log("Successfully deleted folder", response);
                setIsLoading(false);
                getPlans();
            }, 300);
            
        })
        .catch((err: any) => {
            setTimeout(() => {
                // console.log("ERROR! Could not delete folder", err);
                setIsLoading(false);
                getPlans();
            }, 300);
        })


    }

    window.addEventListener('click', function(e){ 
        if (document === null || document.getElementById('folder-overview-menu') === null || document.getElementById('folder-overview-button') === null || e.target === null) { return; }

        if (document.getElementById('folder-overview-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('folder-overview-button')!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    });

    function editFolderNamePressed() {
        setEditingFolderName(true);
        setShowMenu(false);
    }

    function saveFolderName() {
        // console.log("setCurrentFolder D")
        setCurrentFolder({...currentFolder, title: editedFolderName});
        updateFolderName(editedFolderName);
        setEditingFolderName(false);
    }

    function getChildTypeString() {
        var selectedTypeName = ""
        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {
            selectedTypeName = currentFolder.children[0].type;
        }

        switch(selectedTypeName) {
            case "plan":
                return `${currentFolder.children.length} Phase${currentFolder.children.length === 1 ? '' : 's'}`
                break;
            case "cycle":
                return `${currentFolder.children.length} Cycle${currentFolder.children.length === 1 ? '' : 's'}`
                break;
            case "folder":
                return `${currentFolder.children.length} Folder${currentFolder.children.length === 1 ? '' : 's'}`
                break;
            default:
                return "Empty"
                break;
        }
    }

    function getChildType() {
        var selectedTypeName = ""
        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {
            selectedTypeName = currentFolder.children[0].type;
        }

        return selectedTypeName;
    }

    function getChildTypeColourClass() {
        var selectedTypeName = ""
        if (currentFolder.children !== undefined && currentFolder.children.length > 0) {
            selectedTypeName = currentFolder.children[0].type;
        }

        return `workout-plan-list-card-summary-circle-colour-${selectedTypeName}`;
    }


    //********* DRAGGING FUNCTIONS ************//
    function updateDraggingPosition_phase(item: Folder_t, index: number, x: number, y: number) {
        
    }

    function copyToClientsFolderPressed() {
        setShowMenu(false);
        if (props.copyFolderToClientPressed !== undefined) {
            props.copyFolderToClientPressed({parent: currentFolder, callback: confirmopyToClientsFolder});
        }
    }

    function confirmopyToClientsFolder() {

    }
    

	return (
		<div className="workout-plan-list">
            <div className={ "workout-plan-list-background" }>
                <img className="workout-plan-list-background-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/program-designer-bg.png`}/>
            </div>
            <div className="workout-plan-list-folder-card-container">
                <div className="workout-plan-list-folder-card">
                    <div onClick={() => folderSelected(initFolder)} className="workout-plan-list-folder-card-header">
                        <h1>Program Designer</h1>
                    </div>
                    {/*<div className="workout-plan-list-folder-card-row-header">
                        <div className="workout-plan-list-folder-card-row-header-text-container">
                            <h4>Program Folders</h4>
                            <p>({folders.length})</p>
                        </div>
                        <div onClick={() => addFolderToRootPressed()} className="workout-plan-list-folder-card-row-header-add-button">
                            <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                        </div>
                    </div>*/}
                    <div hidden={isLoading || folders.length === 0} className="workout-plan-list-folder-card-body">
                        { folders.map((item: Folder_t, index: number) => (
                            <WorkoutPlanListFolder 
                                folder={item}
                                currentFolder={currentFolder}
                                showBranch={ false }
                                folderSelected={(f: Folder_t) => folderSelected(f)}
                                toggleDisplay={(f: Folder_t) => updateFolderDisplay(f, true)}
                                turnOnDisplay={(f: Folder_t) => updateFolderDisplay(f, false)}/>
                        ))}
                    </div>
                    <div hidden={isLoading || folders.length !== 0} className="workout-plan-list-folder-card-body workout-plan-list-folder-card-body-empty">
                        <div className="workout-plan-list-folder-card-body-empty-inner">
                            <div className="workout-plan-list-folder-card-body-empty-img-container">
                                <img className="" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/add-folder.svg`}/>
                            </div>
                            <div className="workout-plan-list-folder-card-body-empty-text-container">
                                <h4>My Programs is Empty</h4>
                                <p>Folders give you the power to organize your program templates into Cycles and Phases. To get started, create your first Folder.</p>
                            </div>
                            <div onClick={() => addFolderToRootPressed()} className="workout-plan-list-folder-card-body-empty-button-container">
                                <Add className="workout-plan-list-folder-card-body-empty-button-icon"/>
                                <p>Add your first Folder</p>
                            </div>
                        </div>
                    </div>
                    <div hidden={!isLoading} className="workout-plan-list-folder-card-body workout-plan-list-folder-card-body-loading">
                        <p>Loading Workout Plans...</p>
                    </div>
                </div>
            </div>
            <div className="workout-plan-content-wrapper">
                <CSSTransition in={copiedWorkout !== undefined} timeout={200} classNames="menu" unmountOnExit>
                    <div className={ "workout-plan-content-copied-container" }>
                        <div className={ "workout-plan-content-copied-inner" }>
                            <div className={ "workout-plan-content-copied-text-container" }>
                                <p><span>{ !copiedWorkout || copiedWorkout.title === "" ? "Unnamed Workout" : copiedWorkout.title }</span> is copied to your clipboard.</p>
                            </div>
                            <div className={ "workout-plan-content-copied-close-container" } onClick={ () => setCopiedWorkout(undefined) }>
                                <Close className={ "workout-plan-content-copied-close-icon" }/>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                 <CSSTransition in={copiedPhase !== undefined} timeout={200} classNames="menu" unmountOnExit>
                    <div className={ "workout-plan-content-copied-container workout-plan-content-copied-container-phase" }>
                        <div className={ "workout-plan-content-copied-inner" }>
                            <div className={ "workout-plan-content-copied-text-container" }>
                                <p><span>{ !copiedPhase || copiedPhase.title === "" ? "Unnamed Phase" : copiedPhase.title }</span> is copied to your clipboard.</p>
                            </div>
                            <div className={ "workout-plan-content-copied-close-container" } onClick={ () => setCopiedPhase(undefined) }>
                                <Close className={ "workout-plan-content-copied-close-icon" }/>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                <div className="workout-plan-content-container">
                    <div hidden={!isLoading} className="workout-plan-content-loading-container">
                        <div className="workout-plan-content-loading-spinner"/>
                        <p>Loading plans...</p>
                    </div>
                    
                    <div hidden={getShowType() !== 0 || folders.length === 0} className="workout-plan-content workout-plan-content-add-container">
                        <div>
                            <div className="workout-plan-list-cycle-overview-header-card">
                                <div className="workout-plan-list-cycle-overview-header-card-icon-container">
                                    <Folder className="workout-plan-list-cycle-overview-header-card-icon"/>
                                </div>    
                                <div hidden={editingFolderName} className="workout-plan-list-cycle-overview-header-card-text-container">
                                    <h1>{currentFolder.title === "" ? 'All My Programs' : currentFolder.title}</h1>
                                </div>
                                <div hidden={!editingFolderName} className="workout-plan-list-cycle-overview-header-card-text-container workout-plan-list-cycle-overview-header-card-text-container-editing">
                                    <FormTextInput 
                                        value={editedFolderName}
                                        placeholder="Folder name " 
                                        alert={false} 
                                        alertText=""
                                        isSearch={true}
                                        inputChanged={(e: string) => inputChanged_editFolder(e)}/>
                                    <div onClick={() => saveFolderName()} className="workout-plan-list-cycle-overview-header-card-text-container-save-button">
                                        <Check className="workout-plan-list-cycle-overview-header-card-text-container-save-button-icon"/>
                                        <p>Save</p>
                                    </div>
                                </div>
                                <div hidden={currentFolder.title === ""} id="folder-overview-button" onClick={() => toggleMenuShow()} className="workout-plan-list-cycle-overview-header-card-menu-button-container">
                                    <p>Options</p>
                                    <MoreHoriz className="workout-plan-list-cycle-overview-header-card-menu-button-icons"/>
                                </div> 
                                <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                                    <div id="folder-overview-menu" className="workout-plan-list-cycle-overview-header-card-menu-container">
                                        <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                                            <p>Folder Options</p>
                                        </div>
                                        <div onClick={() => editFolderNamePressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                                <Edit className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                            </div>
                                            <p>Edit Folder Name</p>
                                        </div>
                                        <div onClick={() => copyToClientsFolderPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                                <FileCopy className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                            </div>
                                            <p>Copy to Client's Folder</p>
                                        </div>
                                        <div onClick={() => deleteFolderPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                                <DeleteOutline className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                                            </div>
                                            <p>Delete Folder</p>
                                        </div>
                                    </div>
                                </CSSTransition>
                            </div>
                            <div className="workout-plan-list-cycle-overview-header-card-line"/>
                            <CSSTransition in={addingState === -1} timeout={200} classNames="slide" unmountOnExit>
                                <div>
                                    <div className="workout-plan-list-folder-card-row-header">
                                        <div className="workout-plan-list-folder-card-row-header-text-container">
                                            <h4>{currentFolder.children.length === 0 ? "Folder is empty" : "Folder contains subfolders"}</h4>
                                        </div>
                                        <div hidden={currentFolder.type === "plan"} className="workout-plan-list-header-summary-container workout-plan-list-card-summary-container">
                                            <div className={`workout-plan-list-card-summary-circle ${getChildTypeColourClass()}`}/>
                                            <div className="workout-plan-list-card-summary-text-container">
                                                <p>{getChildTypeString()}</p>
                                            </div>
                                        </div>
                                        <div onClick={() => addFolderPressed()} className="workout-plan-list-folder-card-row-header-add-button">
                                            <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                            <div className="workout-plan-list-folder-card-row-header-add-button-tooltip">
                                                <p>Add a folder</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div hidden={currentFolder.path !== "My workout plans" && currentFolder.path !== ""} className="workout-plan-add-header-container">
                                        <div className="workout-plan-add-header-image-container">
                                            <img className="workout-plan-add-header-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/bi_folder.png`}/>
                                        </div>
                                        <h3>Folder is not empty</h3>
                                        <p>Folders that contain other folders cannot be used to add new Cycles or Phases.<br/><br/>Go ahead and create another Folder to add Cycles and Phases, or use the Program Folders navigation panel on the left to view and editing existing content.</p>
                                        <div onClick={() => addFolderPressed()} className="workout-plan-add-folder-button">
                                            <p>Add a Folder</p>
                                        </div>
                                    </div>
                                    <div hidden={currentFolder.path === "My workout plans" || currentFolder.path === ""} className="workout-plan-add-header-container">
                                        <div className="workout-plan-add-header-image-container">
                                            <img className="workout-plan-add-header-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/bi_folder.png`}/>
                                        </div>
                                        <h3>Add to this empty Folder</h3>
                                        <p>Use empty Folders to house other Folders, or add Cycles and Phases to build your Program structure.</p>
                                        <div className="workout-plan-add-element-container">
                                            <div onClick={() => addFolderPressed()} className="workout-plan-add-element-row-container workout-plan-add-element-row-line">
                                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-folder">
                                                    <Folder className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-folder"/>
                                                </div>
                                                <div className="workout-plan-add-element-row-text-container">
                                                    <h4>Add a Folder</h4>
                                                    <p>Use Folders to further organize your Programs</p>
                                                </div>
                                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                                </div>
                                            </div>
                                            <div onClick={() => newProgramPressed()} className="workout-plan-add-element-row-container">
                                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-phase">
                                                    <DynamicFeed className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-phase"/>
                                                </div>
                                                <div className="workout-plan-add-element-row-text-container">
                                                    <h4>Add a Program</h4>
                                                    <p>Configure a new program inside of "{ currentFolder.title }"</p>
                                                </div>
                                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                                </div>
                                            </div> 
                                            {/*<div onClick={() => addCyclePressed()} className="workout-plan-add-element-row-container workout-plan-add-element-row-line">
                                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-list">
                                                    <List className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-list"/>
                                                </div>
                                                <div className="workout-plan-add-element-row-text-container">
                                                    <h4>Add a Cycle</h4>
                                                    <p>Use cycles to organize Phased workout Programs</p>
                                                </div>
                                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                                </div>
                                            </div>  
                                            <div onClick={() => addPlanPressed()} className="workout-plan-add-element-row-container">
                                                <div className="workout-plan-add-element-row-icon-container workout-plan-add-element-row-icon-container-phase">
                                                    <DynamicFeed className="workout-plan-add-element-row-icon workout-plan-add-element-row-icon-phase"/>
                                                </div>
                                                <div className="workout-plan-add-element-row-text-container">
                                                    <h4>Add a Phase</h4>
                                                    <p>Configure your Programs without using Cycles</p>
                                                </div>
                                                <div className="workout-plan-list-folder-card-row-header-add-button workout-plan-add-element-row-add-button-container">
                                                    <Add className="workout-plan-list-folder-card-row-header-add-button-icon"/>
                                                </div>
                                            </div> */}   
                                        </div>
                                    </div>
                                    
                                </div>
                            </CSSTransition>
                            <CSSTransition in={addingState === 0} timeout={200} classNames="slide" unmountOnExit>
                                <div>
                                    <div className="workout-plan-add-header-container workout-plan-add-header-container-left">
                                        <div className="workout-plan-add-header-folder-container">
                                            <div className="workout-plan-add-header-folder-icon-container">
                                                <Folder className="workout-plan-add-header-folder-icon"/>
                                            </div>
                                            <h4>{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}</h4>
                                        </div>
                                        <h3>Adding a new Folder to '{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}'</h3>
                                        <p>Folders are great tools to help organize your workout plans. Enter the name of the new Folder you would like to create inside of '{currentFolder.title === "" ? "root" : currentFolder.title}'</p>
                                    </div>
                                    <div hidden={creatingFolderState !== 0} className="workout-plan-add-button-details-container-content">
                                        <div className="workout-plan-list-header-add-menu-content-input-container">
                                                <FormTextInput 
                                                    placeholder="Folder name " 
                                                    alert={false} 
                                                    alertText=""
                                                    isSearch={true}
                                                    inputChanged={(e: string) => inputChanged(e)}/>
                                        </div>
                                        <div onClick={() => createFolder()} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-${newFolderName === "" ? 'dark' : 'blue'}`}>
                                            Create Folder
                                        </div>
                                    </div>
                                    <div hidden={creatingFolderState !== 1} className="workout-plan-add-button-details-container-content workout-plan-add-button-details-container-content-loading">
                                        <p>Creating '{newFolderName}' in '{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}'...</p>
                                    </div>
                                    <div hidden={creatingFolderState !== 2} className="workout-plan-add-button-details-container-content workout-plan-add-button-details-container-content-done">
                                        <h4>Success!</h4>
                                        <p>You have created '{newFolderName}' in your Folder '{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}'</p>
                                    </div>
                                    <div hidden={creatingFolderState > 0} onClick={() => setAddingState(-1)} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-cancel workout-plan-list-header-add-menu-content-input-button-dark`}>
                                        Cancel
                                    </div>
                                    <div hidden={creatingFolderState !== 2} onClick={() => clearNewFolder()} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-done workout-plan-list-header-add-menu-content-input-button-dark`}>
                                        Done
                                    </div>
                                </div>
                                </CSSTransition>
                                <CSSTransition in={addingState === 1} timeout={200} classNames="slide" unmountOnExit>
                                <div>
                                    <div className="workout-plan-add-header-container workout-plan-add-header-container-left">
                                        <div className="workout-plan-add-header-folder-container">
                                            <div className="workout-plan-add-header-folder-icon-container workout-plan-add-header-cycle-icon-container">
                                                <List className="workout-plan-add-header-folder-icon workout-plan-add-header-cycle-icon"/>
                                            </div>
                                            <h4>+ Cycle in {currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}</h4>
                                        </div>
                                        <h3>Adding a new Cycle to '{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}'</h3>
                                        <p>Cycles allow you to easily managed phase-based workout programming. Select the number of phases to create in your new Cycle within the '{currentFolder.title === "" ? "root" : currentFolder.title}' Folder</p>
                                    </div>
                                    <div hidden={creatingFolderState !== 0} className="workout-plan-add-button-details-container-content">
                                        <div className="workout-plan-list-header-add-menu-content-input-container workout-plan-list-header-add-menu-content-input-container-cycles workout-plan-list-header-add-menu-content-input-container-cycles-spacing">
                                            <div onClick={() => adjustNumberOfPhasesInCycle(-1)} className="workout-plan-list-header-add-menu-content-input-content-button">
                                                <h3>-</h3>
                                            </div>
                                            <div className="workout-plan-list-header-add-menu-content-input-content">
                                                <h4>{cyclesToAdd}</h4>
                                            </div>
                                            <div onClick={() => adjustNumberOfPhasesInCycle(1)} className="workout-plan-list-header-add-menu-content-input-content-button">
                                                <h3>+</h3>
                                            </div>
                                        </div>
                                        <div onClick={() => createCycle()} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-blue`}>
                                            Create Cycle
                                        </div>
                                    </div>
                                    <div hidden={creatingFolderState !== 1} className="workout-plan-add-button-details-container-content workout-plan-add-button-details-container-content-loading">
                                        <p>Creating '{newFolderName}' in '{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}'...</p>
                                    </div>
                                    <div hidden={creatingFolderState !== 2} className="workout-plan-add-button-details-container-content workout-plan-add-button-details-container-content-done">
                                        <h4>Success!</h4>
                                        <p>You have created '{newFolderName}' in the Folder '{currentFolder.title === "" ? "My Workout Plans root" : currentFolder.title}'</p>
                                    </div>
                                    <div hidden={creatingFolderState > 0} onClick={() => setAddingState(-1)} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-cancel workout-plan-list-header-add-menu-content-input-button-dark`}>
                                        Cancel
                                    </div>
                                    <div hidden={creatingFolderState !== 2} onClick={() => setAddingState(-1)} className={`workout-plan-list-header-add-menu-content-input-button workout-plan-list-header-add-menu-content-input-button-done workout-plan-list-header-add-menu-content-input-button-dark`}>
                                        Done
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>                    
                    </div>
                    <div hidden={getShowType() !== 0 || folders.length !== 0} className="workout-plan-content workout-plan-content-add-container workout-plan-content-getting-started-container">
                        <div className="workout-plan-content-getting-started-header">
                            <p>Not sure where to start?</p>
                            <h1>Get started with Folders, Cycles, and Phases</h1>
                            <h4>Folders, Cycles, and Phases allow you to easily organize and assign a large number of complex programs. Learn how to get started by watching the video walkthrough.</h4>
                            <div className="workout-plan-content-getting-started-header-button">
                                <p>Watch the walkthrough</p>
                                <ArrowForward className="workout-plan-content-getting-started-header-button-icon"/>
                            </div>
                        </div>
                        <div className="workout-plan-content-getting-started-content">
                            <div className="workout-plan-content-getting-started-content-card">
                                <div className="workout-plan-content-getting-started-content-card-colour-container">
                                     <div className="workout-plan-content-getting-started-content-card-colour-block workout-plan-content-getting-started-content-card-colour-block-folder"/>
                                </div>
                                <div className="workout-plan-content-getting-started-content-card-content-container">
                                    <h2>What are Folders?</h2>
                                    <p>Folders allow you to create, organize, and assign re-usable program templates, making programming quick and efficient. </p>
                                    <h4>Folders are used to contain other Folders, Cycles, and Phases.</h4>
                                </div>
                            </div>
                            <div className="workout-plan-content-getting-started-content-card">
                                <div className="workout-plan-content-getting-started-content-card-colour-container">
                                     <div className="workout-plan-content-getting-started-content-card-colour-block workout-plan-content-getting-started-content-card-colour-block-cycle"/>
                                </div>
                                <div className="workout-plan-content-getting-started-content-card-content-container">
                                    <h2>What are Cycles?</h2>
                                    <p>When assigning a program to your Athlete, Cycles are used to automatically progress Athletes on to the next Phase when the current one expires.</p>
                                    <h4>Cycles are used to contain Phases – Typically 3 Phases are used per Cycle.</h4>
                                </div>
                            </div>
                            <div className="workout-plan-content-getting-started-content-card">
                                <div className="workout-plan-content-getting-started-content-card-colour-container">
                                     <div className="workout-plan-content-getting-started-content-card-colour-block workout-plan-content-getting-started-content-card-colour-block-phase"/>
                                </div>
                                <div className="workout-plan-content-getting-started-content-card-content-container">
                                    <h2>What are Phases?</h2>
                                    <p>Phases are workout plans! They're used to contain workouts, however you choose to organize them – by week day, muscle group, or with your own tried-and-true program building technique.</p>
                                    <h4>Phases contain workouts</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div hidden={getShowType() !== 1} className="workout-plan-content workout-plan-content-cycle-overview-container">
                        <WorkoutPlanListCycleOverview
                            addPlanPressed={(p: Folder_t, c: Folder_t) => addPlanPressedInCycle(p, c)} 
                            addPlanPressedInFolder={() => addPlanPressed()}
                            folder={currentFolder}
                            assignClientToFolder={() => props.assignClientToFolder(currentFolder)}
                            assignClientToPlan={(p: Plan_t) => props.assignClientToPlan(p)} 
                            duplicatePlanPressed={(p: Plan_t) => setCopiedPhase(p)}
                            copyFolderToClientPressed={(e: any) => props.copyFolderToClientPressed(e)}
                            deletePlanPressed={(p: Plan_t) => deletePlanPressed(p)}
                            addCyclePressed={() => addCyclePressed()}
                            cycleSelected={(c: Folder_t) => folderSelected(c)}
                            turnOnDisplay={(c: Folder_t) => updateFolderDisplay(c, false)}
                            createNewCycle={(n: number) => createCycle(n)}
                            updateFolderName={(n: string) => updateFolderName(n)}
                            editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                            copyPhasePressed={(p: Plan_t) => setCopiedPhase(p)}
                            copiedPhase={ copiedPhase }
                            updatePlans={ () => getPlans() }
                            deleteFolderPressed={() => deleteFolderPressed()}
                            deleteCyclePressed={(c: Folder_t) => deleteCyclePressed(c)}
                            addPhaseToCycle={ (c: Folder_t) => addPhaseToCycle(c) }
                            updateDraggingPosition_phase={(item: Folder_t, index: number, x: number, y: number) => updateDraggingPosition_phase(item, index, x, y)}
                            />
                    </div>
                    <div hidden={getShowType() !== 2} className="workout-plan-content workout-plan-content-cycle-overview-container">
                        <WorkoutPlanListPlanOverview 
                            cycle={currentFolder}
                            copyFolderToClientPressed={(e: any) => props.copyFolderToClientPressed(e)}
                            addPlan={(p: Folder_t, c: Folder_t) => addPlanPressedInCycle(p, c)} 
                            planSelected={(p: Folder_t) => folderSelected(p)}
                            editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                            deleteCyclePressed={() => deleteCyclePressed()}/>
                    </div>
                    <div hidden={getShowType() !== 3} className="workout-plan-content workout-plan-content-cycle-overview-container">
                        <WorkoutPlanListPlan 
                            planFolder={currentFolder}
                            programFolder={programFolder}
                            copiedWorkout={ copiedWorkout }
                            workoutCopied={ (w: Workout_t) => setCopiedWorkout(w) }
                            workoutPasted={ () => null }
                            updateLoadingState={ (s: boolean) => setIsLoading(s) }
                            backPressed={ () => folderSelected(programFolder ?? initFolder) }
                            addPlan={(p: Folder_t) => addPlanPressedInPlan(p)}
                            copyFolderToClientPressed={(e: any) => props.copyFolderToClientPressed(e)}
                            assignClientToPlan={(p: Plan_t) => props.assignClientToPlan(p)} 
                            planSelected={(p: Folder_t) => folderSelected(p)}
                            editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                            duplicatePlanPressed={(p: Plan_t) => props.duplicatePlanPressed(p)}
                            editRestDayPressed={(p: Plan_t, i: number) => props.editRestDayPressed(p, i)}
                            workoutSelected={(w: Workout_t) => props.workoutSelected(w)}
                            deletePlanPressed={(p?: Plan_t) => deletePlanPressed(p)}
                            addNewWorkoutToPlan={(p: Plan_t) => props.addNewWorkoutToPlan(p)}
                            getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
                            addNewExerciseToWorkoutModalPressed={(d: any) => props.addNewExerciseToWorkoutModalPressed(d)}/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default WorkoutPlanList;