import React, { useState } from 'react';
import firebase from '../../../Firebase';

import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';

import './CreatePlanWorkoutConfigureCell.css';

import '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';

function CreatePlanWorkoutConfigureCell(props: any) {

    const [imgUrl, setImgUrl] = useState('');
    const [daysSinceWorkout, setDaysSinceWorkout] = useState(-1);
    const [daysSinceWorkoutSet, setDaysSinceWorkoutSet] = useState(false);
    const [musclesString, setMusclesString] = useState('');
    const [expanded, setExpanded] = useState(false);
    
    if (daysSinceWorkoutSet === false) {
        setDaysSinceWorkoutSet(true);
        getImage();
        //getMusclesString();
    }


    function getImage() {
        if (props.workout.id === "") { return; }

        const storage = firebase.storage();        
        storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function getMusclesString() {

        if (props.workout === undefined || props.workout.id === "" ) { return ""; }
        let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
        

        var muscleGroupTags_temp: string[] = [];

        let exercises: Exercise_t[] = props.workout.exercises !== undefined ? props.workout.exercises : [];
        for (var i = 0; i < exercises.length; i++) {
            let thisExercise: Exercise_t = exercises[i];
            let muscles: Muscles_t[] = thisExercise.muscles !== undefined ? thisExercise.muscles : [];
            for (var j = 0; j < muscles.length; j++) {
                let thisMuscle: Muscles_t = muscles[j];
                let thisMuscleString = muscle_groups[thisMuscle.group];
                if (!muscleGroupTags_temp.includes(thisMuscleString)) {
                    muscleGroupTags_temp.push(thisMuscleString);
                }
            }
        }

        var string_temp = "";
        for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
            string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
        }
        return string_temp;
        //setMusclesString(string_temp);



        // let database = firebase.database();
        // let ref = database.ref(`workouts/${props.workout.id}`);

        //// console.log(props.workout.exercises);

        // ref.on('value', function(snapshot) {
        //     if (snapshot.exists() === true) {
        //         let workoutObj = snapshot.val();

        //         // Determine previous workout muscle groups
        //         let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
        //         var muscleGroupTags_temp: string[] = [];
        //         let exercises = workoutObj.exercises;
        //         let exerciseKeys = Object.keys(exercises);
        //         for (var i = 0; i < exerciseKeys.length; i++) {
        //             let thisKey = exerciseKeys[i];
        //             let thisExercise = exercises[thisKey];

        //             let thisExerciseMuscles = thisExercise.muscles;
        //             let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

        //             for (var j = 0; j < thisExerciseMusclesKeys.length; j++) {
        //                 let thisMuscleKey = thisExerciseMusclesKeys[j];
        //                 let thisMuscle = thisExerciseMuscles[thisMuscleKey];
        //                 let thisMuscleGroup = Number(thisMuscle.group);

        //                 let thisMuscleString = muscle_groups[thisMuscleGroup];
        //                 if (!muscleGroupTags_temp.includes(thisMuscleString)) {
        //                     muscleGroupTags_temp.push(thisMuscleString);
        //                 }
        //             }
        //         }
        //         var string_temp = "";
        //         for (var i = 0; i < muscleGroupTags_temp.length; i ++) {
        //             string_temp += `${i != 0 ? ', ' : ''}${muscleGroupTags_temp[i]}`
        //         }
        //         setMusclesString(string_temp);
        //     }
        // });
    }

    function getDifficultyString() {

        if (props.workout.id === "") { return ""; }

        let difficulties = ["Very easy", "Low intensity", "Mildly intense", "Moderatly intense", "Highly intense", "Extremely intense"]
        let difficultyString = difficulties[props.workout.difficulty];

        return difficultyString;
    }

    function getDurationString() {
        if (props.workout.id === "") { return ""; }
        return `${props.workout.duration} minutes`
    }

    function getNumberSets() {
        if (props.workout.exercises === undefined || props.workout.id === "") { return 0; }

        var numSets = 0;
        for (var i = 0; i < props.workout.exercises.length; i++) {
            let reps = props.workout.exercises[i].reps;
            if (reps !== undefined) {
                numSets += reps.length
            }
        }

        return numSets;
    }

    function toggleExpanded() {
        setExpanded(!expanded);
    }

	return (
		<div className="create-plan-workout-configure-cell">
            <div className="create-plan-workout-configure-cell-content">
                <div onClick={() => toggleExpanded()} className="create-plan-workout-configure-cell-expand-container">
                    <div className="create-plan-workout-configure-cell-expand-icon-container">
                        <ExpandMore className={`create-plan-workout-configure-cell-expand-icon ${expanded ? 'create-plan-workout-configure-cell-expand-icon-expanded' : 'create-plan-workout-configure-cell-expand-icon-contracted'}`}/>
                    </div>
                </div>
    			<div className="create-plan-workout-configure-cell-info-card-col-container">
                    <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container create-plan-workout-configure-cell-info-card-info-container-title">
                        <h4>{props.workout.title}</h4>
                    </div>
                    <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container create-plan-workout-configure-cell-info-card-info-container-title">
                        <h4>{getMusclesString()}</h4>
                    </div>
                    <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container">
                        <h4>{getDurationString()}</h4>
                    </div>
                    <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container">
                        <h4>{props.workout.exercises !== undefined ? props.workout.exercises.length : 0} exercises</h4>
                    </div>
                </div>
                <div onClick={() => props.workoutSelected(props.workout)} className="create-plan-workout-configure-cell-add-button">
                    <Add className="create-plan-workout-configure-cell-add-button-icon"/>
                    <p>Add</p>
                </div>
            </div>
            <div hidden={!expanded} className="create-plan-workout-configure-cell-expanded-content">
                {props.workout.exercises.map((item: Exercise_t, index: number) => (
                    <div className="create-plan-workout-configure-cell-expanded-content-row">
                        <h4>{item.title}</h4>
                    </div>
                ))}
            </div>
		</div>
	)
}

export default CreatePlanWorkoutConfigureCell;

/*
<div onClick={() => props.workoutSelected(props.workout)} className="create-plan-workout-configure-cell">
            <div className="create-plan-workout-configure-cell-image-container">
                <img  
                    className="create-plan-workout-configure-cell-image" 
                    hidden={imgUrl === ''}
                    src={imgUrl} 
                    alt={`Image for ${props.workout.title}`} />
            </div>
            <div className="create-plan-workout-configure-cell-info-card-col-container">
                <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container create-plan-workout-configure-cell-info-card-info-container-title">
                    <h4>{props.workout.title}</h4>
                    <p>{musclesString}</p>
                </div>
                <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container">
                    <h4>{getDurationString()}</h4>
                    <div className="create-plan-workout-configure-cell-info-card-info-trained-with-me-container">
                        <div className="create-plan-workout-configure-cell-info-card-info-trained-with-me-icon"/>
                        <p>{getDifficultyString()}</p>
                    </div>
                </div>
                <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container">
                    <h4>{props.workout.exercises !== undefined ? props.workout.exercises.length : 0} exercises</h4>
                    <p>{getNumberSets()} sets total</p>
                </div>
                <div className="create-plan-workout-configure-cell-info-card-col create-plan-workout-configure-cell-info-card-info-container">
                    <h4>{props.workout.timesCompleted} times used</h4>
                    <p>{props.workout.downloads} user downloads</p>
                </div>
            </div>
        </div>
*/