import React, { useState, useEffect } from 'react';
import './NewWorkoutModal.css';
import './ConfirmModal.css';
import './ContactModal.css'

import TextInput from '../Base/TextInput';

import firebase from '../../Firebase';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

// modal data:
//	{ title: "", text: "", isRed: boolean, callback: any, ctaText: "" }
//
//
//


function ContactModal(props: any) {

	const [currentState, setCurrentState] = useState(0);
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");

	function ctaPressed() {
		if (currentState === 1) { return; }
		if (currentState === 2) { closePressed(); }
		setCurrentState(1);

		let currentUser = firebase.auth().currentUser;
        if (currentUser === null) { return; }
		let trainerUID = currentUser.uid;

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_profile/${trainerUID}`); // Change to database path
		ref.on('value', function(snapshot) {
		    if (snapshot.exists() === true) {
		        let data = snapshot.val();
		        
		        let email = data.email;

		        let obj = {
		        	email: email,
		        	message_title: title,
		        	message_desc: message,
		        	name: data.full_name,
		        	user_id: trainerUID
		        }

		        database.ref('issues').push(obj);

		        setTimeout(() => {
					setCurrentState(2);
				}, 2000)
		        		        
		    }
		});

		
	}	

	function closePressed() {
		props.hideModalPressed();
	}

	function inputChanged(e: string, index: number) {
		// console.log(e);
		if (index === 0) {
			setTitle(e);
		} else {
			setMessage(e);
		}
	}

	return (
		<div className="modal-background">
			<div className="contact-modal-container">
				<div onClick={() => closePressed()} className="confirm-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
				<div hidden={currentState !== 0} className="contact-modal-top">
					<h3>How can we help?</h3>
					<p>We're always ready to help. Enter your inquiry below, press send, and someone from EigenFit will get back to you with answers via email shortly.</p>
					
					<div className="contact-modal-input-container">
						<TextInput 
							placeholder="Subject"
							input=""
							isSearch={true}
							inputChanged={(e: string) => inputChanged(e, 0)}/>
						<TextInput 
							placeholder="Inquiry Description"
							input=""
							isSearch={true}
							inputChanged={(e: string) => inputChanged(e, 1)}/>
					</div>

				</div>
				<div hidden={currentState !== 1} className="contact-modal-top contact-modal-sending">
					<h4>Sending Inquiry...</h4>
				</div>
				<div hidden={currentState !== 2} className="contact-modal-top">
					<h3>Inquiry Sent!</h3>
					<p>Someone will get back to you within the next 48 hours! Keep your eye open for an email from one of our EigenFit team members.</p>
				

				</div>
				<div className="confirm-modal-bottom">
					<div onClick={() => closePressed()} className="confirm-modal-close-button">Cancel</div>
					<div onClick={() => ctaPressed()} className="confirm-modal-cta-button">{currentState === 2 ? "Done" : "Send"}</div>
				</div>
			</div>
		</div>
	)
}

export default ContactModal;

