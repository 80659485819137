import React, { useState, useEffect } from 'react';
import './CheckBox.css';

import '@material-ui/core';
import Check from '@material-ui/icons/Check';

function CheckBox(props: any) {

    const [isSelected, setIsSelected] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.forceState !== undefined) {
            setIsSelected(props.forceState);
        }
    }

    useEffect(() => {
        // console.log("disabled updated to:", props.isDisabled)
        if (props.isDisabled !== undefined) {
            setDisabled(props.isDisabled)
        }
    }, [props.isDisabled])

    useEffect(() => {
        // console.log("Checkbox updating to state:", props.forceState);
        if (props.forceState !== undefined) {
            setIsSelected(props.forceState);
        }
    }, [props.forceState])

    function toggleCheck() {
        if (disabled) { return; }
        props.stateChanged(!isSelected);
        setIsSelected(!isSelected);
    }

	return (
		<div onClick={() => toggleCheck()} className={`check-box ${isSelected ? 'check-box-selected' : ''} ${disabled ? 'check-box-disabled' : ''}`}>
			<div hidden={!isSelected} className="check-box-icon-container">
                <Check className="check-box-icon"/>
            </div>
		</div>
	)
}

export default CheckBox;