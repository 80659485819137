import React, { useState, useEffect } from 'react';
import './CreatePlanWorkoutList.css';
import '../../Workouts/WorkoutBuilder/CreateWorkoutExerciseList.css';
//import CreateWorkoutExerciseListCell from '../../Workouts/WorkoutBuilder/CreateWorkoutExerciseListCell';
import CreatePlanWorkoutListCell from './CreatePlanWorkoutListCell';
import Workout_t from '../../../Interfaces/Workout_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';

import InfoCard from '../../Base/InfoCard';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ArrowForward from '@material-ui/icons/ArrowForward';

function CreatePlanWorkoutList(props: any) {

    let initWorkout: Workout_t = {
                        title: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        duration: 0,
                        imageName: '',
                        targetGender: '',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0
                    };

    let testMuscle: Muscles_t = {group: 0, muscle: 0};

    const [initSet, setInitSet] = useState(false);
    const [workouts, setWorkouts] = useState<Workout_t[]>([]);

    const [replacingWorkout, setReplacingWorkout] = useState<Workout_t>(initWorkout);
    const [replacingWorkoutIndex, setReplacingWorkoutIndex] = useState(-1);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        //setConnected(props.isConnected);
        // console.log("Create plan workout list: useEffect for props.selectedWorkout | props.replacingWorkout, props.selectedWorkout ", props.replacingWorkout, props.selectedWorkout)
        if (props.replacingWorkout !== undefined && props.replacingWorkout.id !== "" || replacingWorkoutIndex !== -1) { return; }

        if (props.selectedWorkout !== undefined) {
            if (props.selectedWorkout.title !== '') {

                var temp: Workout_t[] = workouts;
                if (props.selectedWorkout.oldID !== undefined) {
                    temp = temp.filter(item => item.id !== props.selectedWorkout.oldID);
                }
                temp = temp.concat(props.selectedWorkout);

                props.workoutListUpdated(temp);
                setWorkouts(temp);

                // if (props.selectedWorkout.oldID !== undefined) {
                //     props.workoutListUpdated();
                //     setWorkouts(workouts.filter(item => item.id !== props.selectedWorkout.oldID));
                // }

                // props.workoutListUpdated(workouts.concat(props.selectedWorkout));
                // setWorkouts(workouts.concat(props.selectedWorkout));
                
            }
        }
    }, [props.selectedWorkout]);

    useEffect(() => {
        if (props.workoutList !== undefined) {
            setWorkouts(props.workoutList)
            setReplacingWorkoutIndex(-1);
        }
    }, [props.workoutList]);


    function initialize() {

    }

    function addAnotherWorkout() {
        props.addAnotherWorkout();
    }

    function moveWorkoutUp(atIndex: number) {
        let newArray = array_move(atIndex, atIndex - 1);
        setWorkouts(newArray);
    }

    function moveWorkoutDown(atIndex: number) {
        let newArray = array_move(atIndex, atIndex + 1);
        setWorkouts(newArray);
    }

    function array_move(old_index: number, new_index: number) {
        var arr: Workout_t[] = [];
        for (var i = 0; i < workouts.length; i ++) {
            arr.push(workouts[i]);
        }

        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(initWorkout);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    function removeWorkout(atIndex: number) {
        //props.workoutListUpdated(workouts.filter((item, index) => atIndex !== index));
        //setWorkouts(workouts.filter((item, index) => atIndex !== index));
        setWorkouts(workouts.map((item: Workout_t, index: number) => {
            if (index === atIndex) {
                return initWorkout;
            } else {
                return item;
            }
        }));
        props.workoutListUpdated(workouts.map((item: Workout_t, index: number) => {
            if (index === atIndex) {
                return initWorkout;
            } else {
                return item;
            }
        }));
    }

    function infoCardButtonPressed() {

    }

    function infoCardClosePressed() {

    }

    function editWorkout(withWorkout: Workout_t) {
        setReplacingWorkout(withWorkout);
        props.editWorkout(withWorkout);
    }

    function addToEmptyWorkout(withWorkout: Workout_t, index: number) {
        setReplacingWorkout(withWorkout);
        setReplacingWorkoutIndex(index);
        props.editWorkout(withWorkout, index);
    }

	return (
		<div className="new-workout-modal-page-full-height">
            <div hidden={true} className="new-workout-modal-page-header-text-container">
                <p>Workout list for plan</p>
                <p className="new-workout-modal-page-header-text-right">To add another, press "Add Workout"</p>
            </div>
            <div hidden={workouts.length === 0} className="create-workout-exercise-list-container">
    			{workouts.map((item, index) => (
                    <CreatePlanWorkoutListCell
                        key={`${item.title}-${index}`}
                        workout={item}
                        workoutIndex={index}
                        maxWorkoutIndex={workouts.length - 1}
                        moveWorkoutUp={(e: number) => moveWorkoutUp(e)}
                        moveWorkoutDown={(e: number) => moveWorkoutDown(e)}
                        removeWorkout={() => removeWorkout(index)}
                        editWorkout={() => editWorkout(item)}
                        addToEmptyWorkout={() => addToEmptyWorkout(item, index)}
                    />
                ))}
                <div hidden={true} className="create-plan-workout-list-placeholder-day-container">
                    <div className="create-plan-workout-list-placeholder-day-container-header">
                        <h4>Day {workouts.length + 1}</h4>
                        <p>No Workout assigned</p>
                    </div>
                    <div className="create-plan-workout-list-placeholder-day-container-content">
                        <div onClick={() => props.createNewWorkoutFromPlan()} className="create-plan-workout-list-placeholder-day-container-content-col create-plan-workout-list-placeholder-day-container-content-col-no-boarder">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-add">
                                <Add className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-add"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Create a new Workout</h4>
                                <p>Build a Workout tailored to this Plan</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                        <div onClick={() => addAnotherWorkout()} className="create-plan-workout-list-placeholder-day-container-content-col">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-cloud">
                                <CloudDownload className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Add a saved Workout</h4>
                                <p>Load from your Workout library</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={true} className="create-workout-exercise-list-empty-content-button-row-container">
                    <div onClick={() => props.createNewWorkoutFromPlan()} className="create-workout-exercise-list-empty-button-container create-plan-workout-list-empty-button-container">
                        <Add className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                        <p>Create a new Workout</p>
                    </div>
                    <div onClick={() => addAnotherWorkout()} className="create-workout-exercise-list-empty-button-container create-plan-workout-list-empty-button-container">
                        <CloudDownload className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                        <p>Add a saved Workout</p>
                    </div>
                </div>
            </div>
            <div hidden={workouts.length > 0} className="create-workout-exercise-list-empty-container">
                <div className="create-workout-exercise-list-empty-image-container">
                    <img className="create-workout-exercise-list-empty-image" src={`${process.env.PUBLIC_URL}/assets/images/empty_images/workout-block-empty.png`}/>
                </div>
                <div className="create-workout-exercise-list-empty-content-container">
                    <h4>Add Workouts</h4>
                    <p>Build new Days into your new Plan by adding Workouts - each Workout represents a Day of your Plan. Once you add Workouts, you can rearrange the order as you see fit.<br/><br/>Select 'Add a saved Workout' to add a Workout in your current Workout Library, or select 'Create a new Workout' to design a Workout specifcly for thi Plan.</p>
                    <div className="create-workout-exercise-list-empty-content-button-row-container">
                        <div onClick={() => props.createNewWorkoutFromPlan()} className="create-workout-exercise-list-empty-button-container create-plan-workout-list-empty-button-container">
                            <Add className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                            <p>Create a new Workout</p>
                        </div>
                        <div onClick={() => addAnotherWorkout()} className="create-workout-exercise-list-empty-button-container create-plan-workout-list-empty-button-container">
                            <CloudDownload className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                            <p>Add a saved Workout</p>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={true} className="create-workout-exercise-list-empty-container">
                <div className="create-plan-workout-list-placeholder-day-container">
                    <div className="create-plan-workout-list-placeholder-day-container-header">
                        <h4>Day {workouts.length + 1}</h4>
                        <p>No Workout assigned</p>
                    </div>
                    <div className="create-plan-workout-list-placeholder-day-container-content">
                        <div onClick={() => props.createNewWorkoutFromPlan()} className="create-plan-workout-list-placeholder-day-container-content-col create-plan-workout-list-placeholder-day-container-content-col-no-boarder">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-add">
                                <Add className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-add"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Create a new Workout</h4>
                                <p>Build a Workout tailored to this Plan</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                        <div onClick={() => addAnotherWorkout()} className="create-plan-workout-list-placeholder-day-container-content-col">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-cloud">
                                <CloudDownload className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Add a saved Workout</h4>
                                <p>Load from your Workout library</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

/*
<div className="new-workout-modal-page-full-height">
            <div className="new-workout-modal-page-header-text-container">
                <p>Workout list for plan</p>
                <p className="new-workout-modal-page-header-text-right">To add another, press "Add Workout"</p>
            </div>
            <div hidden={workouts.length === 0} className="create-workout-exercise-list-container">
                {workouts.map((item, index) => (
                    <CreatePlanWorkoutListCell
                        key={`${item.title}-${index}`}
                        workout={item}
                        workoutIndex={index}
                        maxWorkoutIndex={workouts.length - 1}
                        moveWorkoutUp={(e: number) => moveWorkoutUp(e)}
                        moveWorkoutDown={(e: number) => moveWorkoutDown(e)}
                        removeWorkout={() => removeWorkout(index)}
                        editWorkout={() => editWorkout(item)}
                    />
                ))}
                <div className="create-plan-workout-list-placeholder-day-container">
                    <div className="create-plan-workout-list-placeholder-day-container-header">
                        <h4>Day {workouts.length + 1}</h4>
                        <p>No Workout assigned</p>
                    </div>
                    <div className="create-plan-workout-list-placeholder-day-container-content">
                        <div onClick={() => props.createNewWorkoutFromPlan()} className="create-plan-workout-list-placeholder-day-container-content-col create-plan-workout-list-placeholder-day-container-content-col-no-boarder">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-add">
                                <Add className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-add"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Create a new Workout</h4>
                                <p>Build a Workout tailored to this Plan</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                        <div onClick={() => addAnotherWorkout()} className="create-plan-workout-list-placeholder-day-container-content-col">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-cloud">
                                <CloudDownload className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Add a saved Workout</h4>
                                <p>Load from your Workout library</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={workouts.length > 0} className="create-workout-exercise-list-empty-container">
                <div className="create-plan-workout-list-placeholder-day-container">
                    <div className="create-plan-workout-list-placeholder-day-container-header">
                        <h4>Day {workouts.length + 1}</h4>
                        <p>No Workout assigned</p>
                    </div>
                    <div className="create-plan-workout-list-placeholder-day-container-content">
                        <div onClick={() => props.createNewWorkoutFromPlan()} className="create-plan-workout-list-placeholder-day-container-content-col create-plan-workout-list-placeholder-day-container-content-col-no-boarder">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-add">
                                <Add className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-add"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Create a new Workout</h4>
                                <p>Build a Workout tailored to this Plan</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                        <div onClick={() => addAnotherWorkout()} className="create-plan-workout-list-placeholder-day-container-content-col">
                            <div className="create-plan-workout-list-placeholder-day-container-content-icon-container create-plan-workout-list-placeholder-day-container-content-icon-container-cloud">
                                <CloudDownload className="create-plan-workout-list-placeholder-day-container-content-icon create-plan-workout-list-placeholder-day-container-content-icon-cloud"/>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-text-container">
                                <h4>Add a saved Workout</h4>
                                <p>Load from your Workout library</p>
                            </div>
                            <div className="create-plan-workout-list-placeholder-day-container-content-arrow-icon-container">
                                <ArrowForward className="create-plan-workout-list-placeholder-day-container-content-arrow-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={props.isForcedPage === undefined || props.isForcedPage === false} className="create-plan-modal-forced-page-spacer"/>
            <div hidden={workouts.length > 0 || (props.isForcedPage !== undefined && props.isForcedPage === true)} className="create-plan-modal-info-card-container">
                <InfoCard 
                    title="Which way should I add a Workout to this Plan?"
                    description="Choose from above to add a saved workout, or a start from scratch by creating a new one. Each Workout is a Day of this Plan."
                    buttonText="+ New Workout"
                    buttonPressed={() => infoCardButtonPressed()}
                    closePressed={() => infoCardClosePressed()}
                    showCard={!(workouts.length > 0)}/>
            </div>
        </div>
*/

export default CreatePlanWorkoutList;