import React, { useState } from 'react';
import './SettingsMain.css';

// Page Imports
import Account from './pages/Account';
import Notifications from './pages/Notifications';

import '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import AccountBox from '@material-ui/icons/AccountBox';
import Business from '@material-ui/icons/Business';

function SettingsMain(props: any) {

    let pages: any = [
        {
            title: "Account",
            icon: <AccountBox className="settings-main-menu-content-row-header-icon"/>,
            subPages: [ "Profile", "Preferences", "Notifications" ]
        },
        {
            title: "My Gym",
            icon: <Business className="settings-main-menu-content-row-header-icon"/>,
            subPages: [ "Overview", "Trainers", "Plans", "Billing" ]
        }
    ]

    const [pageIndex, setPageIndex] = useState(0);
    const [subPageIndex, setSubPageIndex] = useState(0);

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function pageSelected(p: number, sp: number) {
        setPageIndex(p);
        setSubPageIndex(sp);
    }

    function logoutPressed() {
        let modalData = {
            title: "Are you sure you want to log out? ",
            text: "If you haven't finished a workout, all progress will be lost. Otherwise, all data will be saved for your next login.",
            isRed: true,
            callback: logoutConfirmed,
            ctaText: "Logout"
        };
        props.getUserConfirmation(modalData);
    }
    function logoutConfirmed() {
        props.logout();
    }

	return (
		<div className="settings-main">
			<div className="settings-main-menu">
                <div className="settings-main-menu-header">
                    <div onClick={() => props.backFromSettings()} className="settings-main-menu-header-back-icon-container">
                        <ArrowBackIos className="settings-main-menu-header-back-icon"/>
                    </div>
                    <h4>Settings</h4>
                </div>
                <div className="settings-main-menu-content">
                    {
                        pages.map((item: any, index: number) => (
                            <div>
                                <div className="settings-main-menu-content-row-header">
                                    {item.icon}
                                    <h4>{item.title}</h4>
                                </div>
                                <div>
                                    {
                                        item.subPages.map((item_page: any, index_page: number) => (
                                            <div onClick={() => pageSelected(index, index_page)} className={`settings-main-menu-content-row ${index_page === subPageIndex && index === pageIndex ? 'settings-main-menu-content-row-selected' : ''}`}>
                                                <h4>{item_page}</h4>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
            </div>
            <div className="settings-main-content">
                <div hidden={pageIndex !== 0 || subPageIndex !== 0} className="settings-main-content-inner">
                    <Account logoutPressed={() => logoutPressed()}/>
                </div>
                <div hidden={pageIndex !== 0 || subPageIndex !== 2} className="settings-main-content-inner">
                    <Notifications />
                </div>
            </div>
		</div>
	)
}

export default SettingsMain;