import React from 'react';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './MultiSelect.css';

import '@material-ui/core';
import Check from '@material-ui/icons/Check';

interface MultiSelectProps {
	options: any[];
	optionChanged: any;
}

interface MultiSelectState {
	currentPosition: number;
}

class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {

	static defaultProps = {
		buttonText: "Next",
		callBack: null,
		isValid: true
	}
	constructor(props: any) {
		super(props);
		this.state = {currentPosition: 0};
	}
	buttonPressed(pressed: number) {
		if (pressed === this.state.currentPosition) { return; }
		this.setState({currentPosition: pressed});
		this.props.optionChanged(pressed);
	}
	render() {
		return (
			<div className="multi-select-container">
				{ this.props.options.map((item: {title: string, desc: string}, index: number) => (
					<div onClick={() => this.buttonPressed(index)} className={this.state.currentPosition === index ? "multi-select-container-selected" : "multi-select-container-unselected"}>
						<div className={`multi-select-container-title ${item.desc === "" ? 'multi-select-container-title-full' : ''}`}><h3>{item.title}</h3></div>
						<div className="multi-select-container-desc"><p>{item.desc}</p></div>
						<div className={`multi-select-check-container ${this.state.currentPosition === index ? 'multi-select-check-container-selected' : 'multi-select-check-container-unselected'}`}>
							<div hidden={this.state.currentPosition !== index} className="multi-select-check-container-icon-container">
								<Check className="multi-select-check-container-icon"/>
							</div>
						</div>
					</div>
				))}
			</div>
		)
	}
}

export default MultiSelect;