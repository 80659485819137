import React from 'react';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './StandardButton.css';

interface StandardButtonProps {
	buttonText: string;
	callBack: any;
	isValid?: boolean;
	isLarge?: boolean;
}

class StandardButton extends React.Component<StandardButtonProps> {

	static defaultProps = {
		buttonText: "Next",
		callBack: null,
		isValid: true,
		isLarge: false
	}
	constructor(props: any) {
		super(props);
	}
	buttonPressed() {
		this.props.callBack(this.props.buttonText);
	}
	getClassName() {
		return this.props.isValid ? ( this.props.isLarge ? "standard-button-container standard-button-container-large" : "standard-button-container") : "standard-button-container-unselected";
	}
	render() {
		return (
			<div onClick={e => this.buttonPressed()} className={this.getClassName()}>
				<p>{this.props.buttonText}</p>
			</div>
		)
	}
}

export default StandardButton;
