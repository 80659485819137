import React, { useState, useEffect } from 'react';
import './MuscleSelector.css';
import Muscles_t from '../../Interfaces/Muscles_t';


function MuscleSelector(props: any) {

    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    let muscles: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]
        ]

    const [selectedMuscles, setSelectedMuscles] = useState<Muscles_t[]>([]);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        // Force to sync with parent
        if (props.muscleList !== undefined && props.muscleList.length !== selectedMuscles.length) {
            setSelectedMuscles(props.muscleList);
        }
    }, [props.muscleList])

    useEffect(() => {
        if (props.muscleListUpdated !== undefined) {
            props.muscleListUpdated(selectedMuscles);
        }
    }, [selectedMuscles])

    function muscleSelected(groupIndex: number, muscleIndex: number) {
        let newMuscle: Muscles_t = {group: groupIndex, muscle: muscleIndex};

        // console.log(`----- TAPPED ${groupIndex}, ${muscleIndex} -----`);
        // console.log(selectedMuscles);

        var muscleExists = false;
        if (selectedMuscles.length > 0) {
            for (var i = 0; i < selectedMuscles.length; i++) {
                let thisMuscle = selectedMuscles[i];
                if (thisMuscle.group === groupIndex && thisMuscle.muscle === muscleIndex) {
                    muscleExists = true;

                }
            }
        }

        if (muscleExists === true) {
            // console.log("MUSCLE EXISTS")
            setSelectedMuscles(selectedMuscles.filter(item => {
                if (item.group === groupIndex && item.muscle === muscleIndex) {
                    // console.log(`Will remove muscle at group ${item.group}, muscle ${item.muscle} (pressed ${groupIndex}, ${muscleIndex})`);
                    return false;
                } else {
                    // console.log(`Will keep muscle at group ${item.group}, muscle ${item.muscle} (pressed ${groupIndex}, ${muscleIndex})`);
                    return true;
                }
            }));
        } else {
            // console.log("MUSCLE DNE")
            setSelectedMuscles(selectedMuscles.concat(newMuscle));
        }

    }

	return (
		<div className="muscle-selector">
            <div className="muscle-selector-content">
                <div className="muscle-selector-muscle-buttons-container">
                    <div className="muscle-selector-muscle-buttons-inner-container">
                        <div onClick={() => muscleSelected(0,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Rectus-Abdominis"/>
                        <div onClick={() => muscleSelected(0,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-External-Obliques-1"/>
                        <div onClick={() => muscleSelected(0,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-External-Obliques-2"/>
                        <div onClick={() => muscleSelected(0,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Internal-Obliques-1"/>
                        <div onClick={() => muscleSelected(0,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Internal-Obliques-2"/>
                        <div onClick={() => muscleSelected(1,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Biceps-Short-Head-1"/>
                        <div onClick={() => muscleSelected(1,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Biceps-Short-Head-2"/>
                        <div onClick={() => muscleSelected(1,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Biceps-Long-Head-1"/>
                        <div onClick={() => muscleSelected(1,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Biceps-Long-Head-2"/>
                        <div onClick={() => muscleSelected(1,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Triceps-Long-Head-1"/>
                        <div onClick={() => muscleSelected(1,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Triceps-Long-Head-2"/>
                        <div onClick={() => muscleSelected(1,3)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Triceps-Lateral-Head-1"/>
                        <div onClick={() => muscleSelected(1,3)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Triceps-Lateral-Head-2"/>
                        <div onClick={() => muscleSelected(1,4)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Triceps-Medial-Head-1"/>
                        <div onClick={() => muscleSelected(1,4)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Triceps-Medial-Head-2"/>
                        <div onClick={() => muscleSelected(1,5)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Brachioradialis-1"/>
                        <div onClick={() => muscleSelected(1,5)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Brachioradialis-2"/>
                        <div onClick={() => muscleSelected(1,5)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Brachioradialis-3"/>
                        <div onClick={() => muscleSelected(1,5)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Brachioradialis-4"/>

                        <div onClick={() => muscleSelected(1,6)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Flexor-Carpi-1"/>
                        <div onClick={() => muscleSelected(1,6)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Flexor-Carpi-2"/>

                        <div onClick={() => muscleSelected(2,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Trapezius-1"/>
                        <div onClick={() => muscleSelected(2,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Trapezius-2"/>
                        <div onClick={() => muscleSelected(2,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Rhomboid-Major-1"/>
                        <div onClick={() => muscleSelected(2,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Rhomboid-Major-2"/>
                        <div onClick={() => muscleSelected(2,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Latissimus-Dorsi-1"/>
                        <div onClick={() => muscleSelected(2,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Latissimus-Dorsi-2"/>
                        <div onClick={() => muscleSelected(2,3)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Teres-Major-1"/>
                        <div onClick={() => muscleSelected(2,3)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Teres-Major-2"/>
                        <div onClick={() => muscleSelected(2,4)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Erector-Spinae"/>

                        <div onClick={() => muscleSelected(3,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Pectoralis-Clavicular-Head"/>
                        <div onClick={() => muscleSelected(3,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Pectoralis-Sternocosial-Head"/>

                        <div onClick={() => muscleSelected(4,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Deltoid-Anterior-Head-1"/>
                        <div onClick={() => muscleSelected(4,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Deltoid-Anterior-Head-2"/>
                        <div onClick={() => muscleSelected(4,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Deltoid-Posterior-Head-1"/>
                        <div onClick={() => muscleSelected(4,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Deltoid-Posterior-Head-2"/>
                        <div onClick={() => muscleSelected(4,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Deltoid-Lateral-Head-1"/>
                        <div onClick={() => muscleSelected(4,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Deltoid-Lateral-Head-2"/>

                        <div onClick={() => muscleSelected(5,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gastrocnemius-Lateral-1"/>
                        <div onClick={() => muscleSelected(5,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gastrocnemius-Lateral-2"/>
                        <div onClick={() => muscleSelected(5,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gastrocnemius-Medial-1"/>
                        <div onClick={() => muscleSelected(5,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gastrocnemius-Medial-2"/>
                        <div onClick={() => muscleSelected(5,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gastrocnemius-Medial-3"/>
                        <div onClick={() => muscleSelected(5,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gastrocnemius-Medial-4"/>
                        <div onClick={() => muscleSelected(5,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Soleus-1"/>
                        <div onClick={() => muscleSelected(5,2)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Soleus-2"/>
                        <div onClick={() => muscleSelected(5,3)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Tibialis-Anterior-1"/>
                        <div onClick={() => muscleSelected(5,3)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Tibialis-Anterior-2"/>


                        <div onClick={() => muscleSelected(5,4)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Rectus-Femoris-1"/>
                        <div onClick={() => muscleSelected(5,4)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Rectus-Femoris-2"/>

                        <div onClick={() => muscleSelected(5,5)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Vastus-Lateralis-1"/>
                        <div onClick={() => muscleSelected(5,5)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Vastus-Lateralis-2"/>

                        <div onClick={() => muscleSelected(5,6)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Vastus-Medialis-1"/>
                        <div onClick={() => muscleSelected(5,6)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Vastus-Medialis-2"/>

                        <div onClick={() => muscleSelected(5,7)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Adductor-Longus-1"/>
                        <div onClick={() => muscleSelected(5,7)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Adductor-Longus-2"/>

                        <div onClick={() => muscleSelected(5,8)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Adductor-Mangus-1"/>
                        <div onClick={() => muscleSelected(5,8)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Adductor-Mangus-2"/>

                        <div onClick={() => muscleSelected(5,9)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Bicep-Femoris-1"/>
                        <div onClick={() => muscleSelected(5,9)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Bicep-Femoris-2"/>

                        <div onClick={() => muscleSelected(5,10)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Semitendinosus-1"/>
                        <div onClick={() => muscleSelected(5,10)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Semitendinosus-2"/>


                        <div onClick={() => muscleSelected(6,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gluteus-Medius-1"/>
                        <div onClick={() => muscleSelected(6,0)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gluteus-Medius-2"/>
                        <div onClick={() => muscleSelected(6,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gluteus-Maximus-1"/>
                        <div onClick={() => muscleSelected(6,1)} className="muscle-selector-muscle-buttons muscle-selector-muscle-buttons-Gluteus-Maximus-2"/>
                    </div>
                </div>
                <div className="muscle-selector-muscle-images-container">
                    {
                        selectedMuscles.map((item: Muscles_t, index: number) => (
                            <div className="muscle-selector-body-img-container">
                                <img className="muscle-selector-body-img" src={`${process.env.PUBLIC_URL}/assets/images/body_muscles/body-${item.group}-${item.muscle}.png`}/>
                            </div>
                        ))
                    }
                </div>
    			<div className="muscle-selector-empty-body-container">
                    <img className="muscle-selector-empty-body-img" src={`${process.env.PUBLIC_URL}/assets/images/body_muscles/body-blank.png`}/>
                </div>
            </div>
		</div>
	)
}

export default MuscleSelector;