import React, { useState, useEffect } from 'react';
import { IonInput, IonTextarea } from '@ionic/react';

import './MyProfileBackgroundCell.css';

import MyProfileBackground_t from '../../../../Interfaces/CoachingProfile/MyProfileBackground_t';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function MyProfileBackgroundCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [thisBackground, setThisBackground] = useState<MyProfileBackground_t>({
        title: "",
        date_string: "",
        type: 'education'
    });
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.background !== undefined) {
            setThisBackground(props.background);
        }
    }, [props.background])

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'title':
                let newBackground: MyProfileBackground_t = { ...thisBackground, title: toString };
                setThisBackground(newBackground)
                props.backgroundUpdated(newBackground);
                break;
            case 'date':
                let newBackground_date: MyProfileBackground_t = { ...thisBackground, date_string: toString };
                setThisBackground(newBackground_date)
                props.backgroundUpdated(newBackground_date);
                break;
            default:
                break; 
        }
    }

	return (
		<div className="my-profile-background-cell">
            <div onClick={() => props.removePressed()} className="my-profile-background-cell-close-button">
                <Close className="my-profile-background-cell-close-icon"/>
            </div>
			<div className="my-profile-background-cell-row">
                <div className="my-profile-background-cell-row-title">
                    <p>Title</p>
                </div>
                <div className="my-profile-background-cell-row-input-container">
                    <div className="my-profile-background-cell-row-input-container-inner my-profile-information-section-card-header-text-editable-inner">
                        <IonTextarea value={thisBackground.title} 
                            class=""
                            placeholder={`Enter a title...`}
                            inputmode="text"
                            clearOnEdit={false}
                            autoGrow={true}
                            rows={1}
                            maxlength={500}
                            onKeyPress={e => null}
                            onIonChange={e => inputChanged(e.detail.value!, 'title')}/>
                    </div>
                </div>
            </div>
            <div className="my-profile-background-cell-row">
                <div className="my-profile-background-cell-row-title">
                    <p>Date</p>
                </div>
                <div className="my-profile-background-cell-row-input-container">
                    <div className="my-profile-background-cell-row-input-container-inner my-profile-information-section-card-header-text-editable-inner">
                        <IonTextarea value={thisBackground.date_string} 
                            class=""
                            placeholder={`Enter a date...`}
                            inputmode="text"
                            clearOnEdit={false}
                            autoGrow={true}
                            rows={1}
                            maxlength={500}
                            onKeyPress={e => null}
                            onIonChange={e => inputChanged(e.detail.value!, 'date')}/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default MyProfileBackgroundCell;