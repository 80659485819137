import React, { useState, useEffect } from 'react';
import './NewPlanModal.css';
import './NewClientModal.css';

import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';
import Plan_t from '../../Interfaces/Plan_t';
import Folder_t from '../../Interfaces/Folder_t';

import CreatePlanWorkoutConfigure from '../Plans/PlanBuilder/CreatePlanWorkoutConfigure';
import CreatePlanWorkoutList from '../Plans/PlanBuilder/CreatePlanWorkoutList';
import PlanFolderSelector from '../Base/PlanFolderSelector';
import MultiSelect from '../Base/MultiSelect';
import DualSelector from '../Base/DualSelector';
import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';
import FormDateInput from '../Base/FormDateInput';
import Selector from '../Base/Selector';
import SelectorWithPills from '../Base/SelectorWithPills';
import ToggleSwitch from '../Base/ToggleSwitch';
import FormBodyMeasurementInput from '../Base/FormBodyMeasurementInput';

import InfoCard from '../Base/InfoCard';


import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Folder from '@material-ui/icons/Folder';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import Public from '@material-ui/icons/Public';
import Home from '@material-ui/icons/Home';
import Group from '@material-ui/icons/Group';
import Lock from '@material-ui/icons/Lock';


function NewPlanModal(props: any) {

	let pageNames = ["Plan Info", "Workouts", "Availability"];
	let pageDescs = ["", "Configure your new Plan by adding Workouts, or days, and specifying the order of each.", "Select an image for your workout, which will be visible in your Library and to your clients.", "Define who you're creating this workout for by their goals and/or gender.", "Specify who has access to this Workout, from only you to the entire Eigen Fitness community.", ""]
	let periodizationOptions = [{title: "Accumulation", desc: ""}, 
								{title: "Intensification", desc: ""}];

	const workoutDifficulties = [{title: 'Beginner ', desc: 'Easy for those new to lifting'}, {title: 'Light', desc: 'For those who have been to the gym'}, {title: 'Moderate', desc: 'Slightly intense for most'}, {title: 'Difficult', desc: 'Challenging to most gym goers'}, {title: 'Extremely Challenging', desc: 'Challenging, even to gym frequenters'}];
	let initFolder = {
	        title: "",
	        id: "",
	        type: "folder",
	        path: "",
	        children: [],
	        isPlan: false,
	    };
    let initWorkout: Workout_t = {
            title: '',
            id: '',
            description: '',
            createdBy: {name: '', id: ''},
            duration: 0,
            imageName: '',
            targetGender: '',
            totalSets: 0,
            totalReps: 0,
            difficulty: 0
        };

	const [workflowState, setWorkflowState] = useState(0);
    const [plan, setPlan] = useState<Plan_t>({title: "", description: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});

    const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>(initWorkout);
    const [workoutList, setWorkoutList] = useState<Workout_t[]>([]);

    const [clientID, setClientID] = useState("");

	const [selectedAvailabilityState, setSlectedAvailabilityState] = useState(1);
	const [selectedFolder, setSelecteFolder] = useState<Folder_t>(initFolder);
	const [incomingSelectedFolder, setIncomingSelectedFolder] = useState<Folder_t>(initFolder);

	const [alerts, setAlerts] = useState<boolean[]>([false,false,false,false,false,false,false]);
	const [initSet, setInitSet] = useState(false);
	const [showWorkoutSelector, setShowWorkoutSelector] = useState(false);

	const [replacingWorkout, setReplacingWorkout] = useState<Workout_t>(initWorkout);
	const [replacingWorkoutIndex, setReplacingWorkoutIndex] = useState(-1);
	const [periodizationString, setPeriodizationString] = useState(periodizationOptions[0].title);

	const [isLoading, setIsLoading] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
    	if (props.forcedPage !== undefined) { return; }
    	let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

        setPlan({...plan, createdBy_ID: trainerUID});

        if (props.folder !== undefined) {
        	setIncomingSelectedFolder(props.folder);
	        setSelecteFolder(props.folder);
        }

        if (props.preselectedWorkouts !== undefined && props.preselectedWorkouts.length > 0) {
        	// console.log("--A")
        	setWorkoutList(props.preselectedWorkouts);
        } else {
        	var initWorkoutList: Workout_t[] = [];
        	for (var i = 0; i < 7; i++) {
        		initWorkoutList.push(initWorkout);
        	}
        	setWorkoutList(initWorkoutList);
        }
    }

    useEffect(() => {
    	if (props.preselectedWorkouts !== undefined && props.preselectedWorkouts.length > 0) {
    		// console.log("--B")
        	setWorkoutList(props.preselectedWorkouts);
        }
        
    }, [props.preselectedWorkouts]);

    useEffect(() => {
    	// console.log("Workout List: ", workoutList);
        if (replacingWorkout.id !== "") {
        	setReplacingWorkout(initWorkout);
        }
    }, [workoutList]);

    useEffect(() => {
    	if (props.forcedPage !== undefined) { return; }
        // console.log(`CHANGING SELECTED WORKOUT!`)
        // console.log(props.selectedWorkout);
        if (props.selectedWorkout !== undefined) {
            setSelectedWorkout(props.selectedWorkout);
        }

        if (showWorkoutSelector === true) {
        	setShowWorkoutSelector(false);
        }

        // console.log("----- props.selectedWorkout, replacingWorkout, workoutList, replacingWorkoutIndex:", props.selectedWorkout, replacingWorkout, workoutList, replacingWorkoutIndex);
        if (replacingWorkout !== undefined && replacingWorkout.id !== "") {
        	// console.log("--C")
        	setWorkoutList(workoutList.map((item: Workout_t, index: number) => {
        		if (item.id === replacingWorkout.id) {
        			return props.selectedWorkout
        		} else {
        			return item;
        		}
        	}))


        } else if (replacingWorkoutIndex >= 0) {
        	// console.log("--C.A")
        	setWorkoutList(workoutList.map((item: Workout_t, index: number) => {
        		if (index === replacingWorkoutIndex) {
        			return props.selectedWorkout
        		} else {
        			return item;
        		}
        	}))

        	setReplacingWorkoutIndex(-1);
        }
        
    }, [props.selectedWorkout]);

    useEffect(() => {
    	if (props.forcedPage !== undefined) { return; }
    	if (props.folder === undefined) { return; }
    	//// console.log(`----------------- New Plan Modal | Setting folder to:`, props.folder);
    	if (props.folder.path === undefined || props.folder.path === "") {
    		//// console.log("----- HA GOTCHA BITCH"!)
    	} else {
    		//// console.log("----- Setting folder with location: ", props.folder.path);
    		setIncomingSelectedFolder(props.folder);
	        setSelecteFolder(props.folder);
    	}
        
    }, [props.folder]);

    useEffect(() => {
    	if (props.forcedPage !== undefined) {
    		setWorkflowState(props.forcedPage);
    	}
    }, [props.forcedPage]);

    useEffect(() => {
    	if (props.forcedPage !== undefined) { return; }
    	// console.log("PLAN TO EDIT:", props.planToEdit)
    	if (props.planToEdit !== undefined && props.planToEdit.id !== "") {
    		var planTemp: Plan_t = props.planToEdit;
    		if (planTemp.description === "No description of this plan was provided") {
    			planTemp.description = "";
    		}
    		if (planTemp.workoutObjects !== undefined) {
    			// console.log("--D")
    			setWorkoutList(planTemp.workoutObjects)
    		}

    		if (planTemp.periodization_string !== undefined && planTemp.periodization_string !== "") {
    			setPeriodizationString(planTemp.periodization_string);
    		}
    		
    		setWorkflowState(1);

    		setPlan(props.planToEdit);
    	}
    }, [props.planToEdit])

    useEffect(() => {
    	if (props.clientID === undefined) return;
    	setClientID(props.clientID);
	}, [props.clientID])


	function getWorkflowClassName(state: number) {
		return workflowState === state ? "new-client-selected-workflow-state" : (state < workflowState ? "new-client-completed-workflow-state" : "new-client-unselected-workflow-state");
	}

	function continuePressed() {

		if (workflowState === 4) {
			props.closeModal();
			return;
		}

		if (validatePage() === true) {
			if (workflowState === 2) {
				configureNewWorkout();
			}
			setWorkflowState(workflowState + 1);
		}
		
	}

	function saveChanges() {
		setWorkflowState(3);
		configureNewWorkout();
	}

	function backPressed() {
		setWorkflowState(workflowState - 1);
	}

	function closeModal() {
		if (workflowState >= 1 || plan.title.split('').length >= 1) {
			props.getUserConfirmation({
				title: "Are you sure you want to exit?",
				text: `Exiting the Plan builder will erase your progress creating '${plan.title}'.`,
				isRed: false,
				callback: confirmCloseModal,
				ctaText: "Close"
			});

			Mixpanel.track("Close new plan modal requested",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
		} else {
			confirmCloseModal()
		}
	}

	function confirmCloseModal() {
		Mixpanel.track("Close new plan modal confirmed",{on_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown'});
		props.closeModal();
		
	}

	function backToWorkoutList(workout?: Workout_t) {
		//// console.log(`Back to workout list with: ${workout !== null ? workout!.title : 'nada'}`)
		if (workout !== null && workout !== undefined) {
			// console.log('CHAINGIN')
			setSelectedWorkout(workout!);
		}
		setShowWorkoutSelector(false);
		setWorkflowState(1);
	}

	function jumpToPagePressed(atIndex: number) {
        if (atIndex <= workflowState) {
            // moving backwards - all good in all cases
            Mixpanel.track("New plan jump page pressed",{
                from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                to_page: atIndex < pageNames.length ? pageNames[atIndex] : 'unknown',
                move_valid: true
            });
            setWorkflowState(atIndex);
        } else {
            // attempting to move forwards. Validate all pages from (including) the current page to the page before the moving-to page
            var moveValid = true;
            var furthestValidPageIndex = workflowState - 1;
            for (var i = workflowState; i < atIndex; i++) {
                let thisPageValid = validatePage(i);
                if (!thisPageValid) {
                    moveValid = false;
                } else {
                    if (moveValid === true) {
                        furthestValidPageIndex = i;
                    }
                    
                }
            }
            if (furthestValidPageIndex >= 0) {
                Mixpanel.track("New plan jump page pressed",{
                    from_page: workflowState < pageNames.length ? pageNames[workflowState] : 'unknown',
                    to_page: furthestValidPageIndex + 1 < pageNames.length ? pageNames[furthestValidPageIndex + 1] : 'unknown',
                    move_valid: moveValid
                });
                setWorkflowState(furthestValidPageIndex + 1);
            }
        }
    }


	function inputChanged(toString: any, fromInput: string) {
		// console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)
		
		switch (fromInput) {
			case 'plan-name':
				let letters = toString.split('');
				setPlan({...plan, title: toString});
				if (alerts[0] === true && letters.length >= 2) {
					setAlerts(alerts.map((item, index) => { return index === 0 ? false : item }));
				}
				break;
			case 'plan-description':
				let letters_desc = toString.split('');
				setPlan({...plan, description: toString});
				// if (alerts[0] === true && letters_desc.length >= 2) {
				// 	setAlerts(alerts.map((item, index) => { return index === 1 ? false : item }));
				// }
				break;
			case 'plan-durration' :
				let genderKeys = ['b','f','m'];
				//let genderKey = toString === 'Primarily Female' ? 'f' : (toString === 'Primarily Male' ? 'm' : 'b');
				//setWorkout({...workout,targetGender: genderKeys[Number(toString)]});
				break;
			case 'workout-difficulty' :
				//let workoutDifficultyIndex = workoutDifficulties.indexOf(toString);
				//// console.log(`difficulty: ${toString}, ${workoutDifficulties[Number(toString)]}`);
				//setWorkout({...workout,difficulty: Number(toString)});
				break;
			case 'periodization':
				let selectedObj = periodizationOptions[Number(toString)];
				// console.log("Periodization: ", selectedObj.title)
				setPeriodizationString(selectedObj.title);
				break;
			default:
				// console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
				break;
		}
	}

	function validatePage(forPage?: number) {
		var okayToProceed = true;

		switch (forPage === undefined ? workflowState : forPage) {
			case 0: 			// Workout information page
				setAlerts(alerts.map((item, index) => { 
					// console.log(`(${item} , ${index})`);
					switch (index) {
						case 0: 	// full name
							let letters_title = plan.title.split('');
							okayToProceed = letters_title.length >= 2;
							return letters_title.length < 2;
							break;
						// case 1: 	// workout description
						// 	let letters_desc = plan.description === undefined ? [''] : plan.description.split('');
						// 	okayToProceed = letters_desc.length >= 2;
						// 	return letters_desc.length < 2;
						// 	break;
						default:
							return false;
							break;
					}
				}))

				break;
			case 1:
				okayToProceed = workoutList.length > 0;
				break;
			case 2:
				okayToProceed = true;
				break;
			case 3:
				// okayToProceed = workout.goals !== undefined && workout.goals.length > 0;
				// setAlerts(alerts.map((item, index) => { return index !== 4 ? item : !okayToProceed }));
				break;
			case 4:
				okayToProceed = true;
			default:
				break;
		}

		for (var i = 0; i < alerts.length; i++) {
			if (alerts[i] === true) {
				// console.log(alerts[i]);
				okayToProceed = false;
			}
		}
		return okayToProceed;
	}

	function configureNewWorkout() {

		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }
		let trainerUID = thisUser.uid

		//setWorkflowState(3);
		setIsLoading(true);


		var workoutIDList: string[] = [];
		// console.log("WORKOUT LIST: ", workoutList);
		for (var i = 0; i < workoutList.length; i++) {
			let thisWorkoutID = workoutList[i].id;
			workoutIDList.push(thisWorkoutID);
		}

		// console.log("WORKOUT ID LIST: ", workoutIDList);
		
		let newPlanObj = {
			title: plan.title,
			description: plan.description,
			created_by: trainerUID,
			availability: selectedAvailabilityState,
			location_path: selectedFolder.path,				// Temporary - will update with plan ID after push key retrieved
			created_timestamp: Date.now(),
			periodization_string: periodizationString,
			workouts: workoutIDList
		};

		// console.log("newPlanObj: ", newPlanObj);
		// console.log("newPlanObj.location_path: ", newPlanObj.location_path);


		let database = firebase.database();

		if (props.planToEdit !== undefined && props.planToEdit.id !== "") {
			newPlanObj.location_path = props.planToEdit.path

			// console.log("PLAN TO EDIT | Will update plan with ID: ", props.planToEdit.id, " and path: ", props.planToEdit.path);
			// console.log(newPlanObj);

			// Push to db workout_plans
			
			let ref = database.ref(`workout_plans/plans/${props.planToEdit.id}`);

			ref.set(newPlanObj);

			let splitpath = newPlanObj.location_path.split('/');
			var composedPath = "";
			for (var i = 1; i < splitpath.length; i++) {
				composedPath += "/";
	            composedPath += splitpath[i];
	            composedPath += i === splitpath.length - 1 ? "" : "/children";
			};

			// console.log("composedPath: ", composedPath);

			// console.log("NEW PLAN MODAL, CLIENT ID: ", clientID);

			let refLocation = clientID === undefined || clientID === "" ? `workout_plans/trainer_plans/${trainerUID}/folders_root${composedPath}` : `workout_plans/client_assigned_plans/${clientID}/folders${composedPath}`;
			// console.log("refLocationB = ", refLocation, props.information);
			let new_ref = database.ref(refLocation);

			let updatedFolderObj: any = {
	            type: "plan",
	            phase_name: props.planToEdit.phase_name !== undefined ? props.planToEdit.phase_name : '',
	            plan_id: props.planToEdit.id,
	            name: plan.title,
	            path: newPlanObj.location_path
	        } 

	        new_ref.set(updatedFolderObj);
		} else {
			// CREATE NEW PHASE

			// Push to db workout_plans
			let database = firebase.database();
			let ref = database.ref(`workout_plans/plans`);

			var key = '';
			// console.log("configuring key: ", incomingSelectedFolder, selectedFolder);
			if (incomingSelectedFolder.type === "plan") {
				ref.child(incomingSelectedFolder.id).set(newPlanObj);
				key = incomingSelectedFolder.id;
			} else if (selectedFolder.isPlan === true) {
				ref.child(selectedFolder.id).set(newPlanObj);
				key = selectedFolder.id;
			} else {
				let pushRef = ref.push(newPlanObj);
			    key = pushRef.key === null ? '' : pushRef.key;

			    let newRef = ref.child(`${key}/location_path`);
				let newPath = `${newPlanObj.location_path}/${key}`; 
				newRef.set(newPath);
				newPlanObj.location_path = newPath;
			}
			// console.log("Key is: ", key);

			// Update path with key
			if (incomingSelectedFolder.type === "") {

			}
			

			// Add plan to folders
			let splitpath = newPlanObj.location_path.split('/');
			var composedPath = "";
			// console.log("newPlanObj: ", newPlanObj, splitpath);
			if (selectedFolder.isPlan === undefined || selectedFolder.isPlan === false) {
				for (var i = 1; i < splitpath.length - 1; i++) {
					composedPath += "/";
		            composedPath += splitpath[i];
		            composedPath += incomingSelectedFolder.type === "plan" && i === splitpath.length - 1 ? "" : "/children";
				};
			} else {
				for (var i = 1; i < splitpath.length; i++) {
					composedPath += "/";
		            composedPath += splitpath[i];
		            composedPath += i === splitpath.length - 1 ? "" : "/children";
				};
			}
			

			// console.log("composedPath: ", composedPath);

			let adj_key = incomingSelectedFolder.type === "plan" || selectedFolder.isPlan === true ? '' : key;

			// console.log("NEW PLAN MODAL B, CLIENT ID: ", clientID);

			let refLocationB = clientID === undefined || clientID === "" ? `workout_plans/trainer_plans/${trainerUID}/folders_root${composedPath}/${adj_key}` : `workout_plans/client_assigned_plans/${clientID}/folders${composedPath}/${adj_key}`;
			// console.log("refLocationB = ", refLocationB, props.information);
			let ref_b = database.ref(refLocationB);
	        //let key = ref.push().key;

	        let newFolderObj: any = {
	            type: "plan",
	            plan_id: key,
	            name: plan.title,
	            path: newPlanObj.location_path,
	            phase_name: selectedFolder.isPlan === undefined || selectedFolder.isPlan === false ? null : selectedFolder.title
	        } 

	        if (incomingSelectedFolder.type === "plan") {
	        	newFolderObj["phase_name"] = incomingSelectedFolder.title;
	        }

	        ref_b.set(newFolderObj);

	        // console.log("---- FINISHING CREATING PLAN: ", props.information);
	        if (props.completedCallback !== undefined) {
	        	props.completedCallback(newFolderObj);
	        }
		}



        setTimeout(() => {
        	setIsLoading(false);
        	confirmCloseModal();
        }, 1000);
	}

	function availabilitySelected(a: number) {
		setSlectedAvailabilityState(a);
	}

	function folderSelected(folder: Folder_t) {
		setSelecteFolder(folder);
		// console.log(`>: NewPlanModal: folderSelected | folder _${folder.title}_ selected with path: ${folder.path}`);
	}

	function addAnotherWorkout() {
		//props.addAnotherWorkout();
		setShowWorkoutSelector(true);
	}

	function workoutListUpdated(list: Workout_t[]) {
		// console.log("--E")
		setWorkoutList(list);
	}

	function createNewWorkoutFromPlan() {
		props.editWorkout(initWorkout);
		props.createNewWorkoutFromPlan();
	}

	function editWorkout(w: Workout_t, i?: number) {
		// console.log("PPPPPPPPPPPPP edit workout with params: ", w, i)
		setReplacingWorkoutIndex(i === undefined ? -1 : i);
		setReplacingWorkout(w);
		props.editWorkout(w);
		
	}

	function getPageValidation() {
		let workoutsValid = true; //workoutList.length > 0;

		let letters_title = plan.title.split('');
		let nameValid = letters_title.length >= 2;

		return workoutsValid && nameValid;
	}

	function getSelectedBuildLocationString() {

		if (selectedFolder.type === "plan") {
			return `Building this phase as ${selectedFolder.phaseName !== undefined && selectedFolder.phaseName !== "" ? selectedFolder.phaseName : selectedFolder.title}`;
		} else if (selectedFolder.type === "folder") {
			return `Adding this Phase to your '${selectedFolder.title}' Folder`
		}

		return `Adding this Phase to your 'My workout plans' Folder`;
	}



	return (
		<div className="new-client-container">
			<div className="new-workout-modal-header-container">
				<div className="new-workout-modal-header-close-container">
					<div onClick={() => closeModal()} className="new-workout-modal-header-close-button">
						<Close className="new-workout-modal-header-close-button-icon"/>
					</div>
				</div>
				<div className="new-workout-modal-header-content-container">
					<div className="new-workout-modal-header-content-icon-container">
						<DynamicFeed className="new-workout-modal-header-content-icon"/>
					</div>
					<div className="new-workout-modal-header-content-text-container">
						<h4>{incomingSelectedFolder.type === "plan" ? (props.planToEdit !== undefined ? 'Edit your Phase' : '') : 'Build a new Phase'}</h4>
					</div>
				</div>
				<div className="new-workout-modal-header-button-container">
					<div onClick={() => getPageValidation() ? saveChanges() : null} className={`new-workout-modal-header-button-cta ${getPageValidation() ? '' : 'new-workout-modal-header-button-cta-unselected'}`}>
						<p>Finish & {incomingSelectedFolder.type === "plan" ? (props.planToEdit !== undefined ? 'save Phase' : '') : 'save Phase'}</p>
					</div>
				</div>
			</div>
			<div hidden={isLoading} className="new-client-content-container-wrapper">
				<div className="new-client-content-container">
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>General {incomingSelectedFolder.type === "plan" ? "Phase" : "Phase"} details</h2>
					</div>
					<div className="new-client-content-page-section-spacer"/>
					<div className="new-client-content-page-input-container">
						<FormTextInput 
							value={plan.title}
							placeholder={`${incomingSelectedFolder.type === "plan" ? "Phase" : "Phase"} Name`}
							alert={alerts[0]} 
							autofocus={true}
							noHide={true}
							alertText="Please enter a plan name"
							inputChanged={(e: string) => inputChanged(e, 'plan-name')}/>
						<div className="new-client-content-page-info-container">
							<div className="new-client-content-page-info-icon-container">
								<div className="new-client-content-page-info-icon"/>
							</div>
							<p>{incomingSelectedFolder.type === "plan" ? "Phase" : "Phase"} names typically inlude loading types, such as "Wave Loading" or "Heavy/Light".</p>
						</div>
					</div>
					<div className="new-client-content-page-section-spacer"/>
					<div className="new-client-content-page-input-container">	
						<FormLongTextInput 
							value={plan.description}
							placeholder="Brief Description" 
							alert={alerts[1]} 
							isOptional={true}
							alertText="Please enter a short description of the plan"
							isSearch={false}
							inputChanged={(e: string) => inputChanged(e, 'plan-description')}/>
					</div>
					<div className="new-phase-periodization-header">
						<h4>Phase Periodization</h4>
					</div>
					<MultiSelect
						options={[{title: "Accumulation", desc: ""}, {title: "Intensification", desc: ""}]}
						optionChanged={(e: number) => inputChanged(e, 'periodization')}/>
					<div hidden={incomingSelectedFolder.type === "plan" || (props.planToEdit !== undefined && props.planToEdit.id !== "")}>
						<div className="new-client-content-page-section-spacer-5vh"/>
						<div className="new-client-content-page-section-header new-client-content-page-section-header-noline">
							<h4>Select a folder for your plan</h4>
							<p>Use folders to organize your plans. Select a folder below, or head over to Manage Workouts & Plans to create new folders.</p>
							<div className="new-plan-modal-folder-selector-text-container">
								<h4>{getSelectedBuildLocationString()}</h4>
							</div>
						</div>
						
						<div className="new-plan-modal-folder-selector-container">
							<PlanFolderSelector 
								incomingSelectedFolder={incomingSelectedFolder}
								folderSelected={(e: Folder_t) => folderSelected(e)}/>
						</div>
					</div>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-header">
						<h2>{incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"} access permisions</h2>
					</div>
					<div className="new-workout-modal-availability-row">
						<div onClick={() => availabilitySelected(0)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 0 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-header">
								<Public className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Public</h3>
							</div>
							<p>This {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"} will be available for download by all EigenFit users. Recommended to help boost your downloads and Trainer Rating.</p>
						</div>
						<div onClick={() => availabilitySelected(1)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 1 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-floater">Recommended</div>
							<div className="new-workout-modal-availability-cell-header">
								<Home className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Gym only</h3>
							</div>
							<p>This {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"} will be available for download and use by members of my gym. Perfect for sharing your tried & true {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"}s with trainers you know.</p>
						</div>
					</div>
					<div className="new-workout-modal-availability-row">
						<div onClick={() => availabilitySelected(2)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 2 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-header">
								<Group className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Clients only</h3>
							</div>
							<p>Only my clients will have access to use and download this {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"}. Great for keeping your {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"}s personal and solidifying your training style.</p>
						</div>
						<div onClick={() => availabilitySelected(3)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 3 ? "new-workout-modal-availability-cell-selected" : "")}>
							<div className="new-workout-modal-availability-cell-header">
								<Lock className="new-workout-modal-availability-cell-header-icon"/>
								<h3>Private</h3>
							</div>
							<p>This {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"} is only available during one-on-one sessions with my clients. Best for {incomingSelectedFolder.type === "plan" ? "Phase" : "Plan"}s that require a close eye and lots of communication.</p>
						</div>
					</div>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-spacer-5vh"/>
					<div className="new-client-content-page-section-spacer-5vh"/>
				</div>
				<div className="new-client-right-pane-content-container">
					<div className="new-client-content-page-section-header">
						<h2>Build your Phase</h2>
						<p>Design your Phase by adding & configuring Workouts, or days.</p>
					</div>
					<div hidden={showWorkoutSelector}>
						<CreatePlanWorkoutList
							backButtonPressed={() => setShowWorkoutSelector(false)}
							selectedWorkout={selectedWorkout}
							workoutList={workoutList}
							isForcedPage={props.forcedPage !== undefined}
							workoutListUpdated={(e: Workout_t[]) => workoutListUpdated(e)}
							addAnotherWorkout={() => addAnotherWorkout()}
							createNewWorkoutFromPlan={() => createNewWorkoutFromPlan()}
							replacingWorkout={replacingWorkout}
							editWorkout={(w: Workout_t, i?: number) => editWorkout(w, i)}/>
					</div>
					<div hidden={!showWorkoutSelector}>
						<CreatePlanWorkoutConfigure
							backToWorkoutList={(e?:Workout_t) => backToWorkoutList(e)}
							closeModal={() => closeModal()}
							createNewWorkoutFromPlan={() => createNewWorkoutFromPlan()}/>
					</div>
				</div>
			</div>
			<div hidden={!isLoading} className="new-client-content-container-wrapper new-client-content-container-wrapper-loading">
				<div className="new-client-content-container-wrapper-loading-container">
					<div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>{incomingSelectedFolder.type === "plan" ? (props.planToEdit !== undefined ? 'Creating Phase...' : '') : 'Creating Phase...'}</p>
				</div>
			</div>
		</div>
	)
}

export default NewPlanModal;


/*
			<div className={`new-client-header-container new-client-header-container-first-screen new-plan-header-container${workflowState === 0 ? '-coloured' : ''}`}>
				<div hidden={workflowState !== 0}>
					<div className="new-plan-header-icon-container new-plan-header-icon-container-first-screen">
						<DynamicFeed className="new-plan-header-icon"/>
					</div>
					<div hidden={incomingSelectedFolder.type !== "plan"} className="new-client-header-text-container">
						<h1>Build a new Phase<br/>for {incomingSelectedFolder.title}</h1>
						<p>Composed of a series of Workouts, Phases can be used to build cycles or be assigned to clients as stand-alone Plans.</p>
					</div>
					<div hidden={incomingSelectedFolder.type === "plan"}>
						<div hidden={props.planToEdit !== undefined} className="new-client-header-text-container">
							<h1>Edit your<br/>Workout Plan</h1>
							<p>Composed of a series of Workouts, Plans can be used to build cycles or be assigned to clients as stand-alone Plans.</p>
						</div>
						<div hidden={props.planToEdit === undefined} className="new-client-header-text-container">
							<h1>Create a new<br/>Workout Plan</h1>
							<p>Composed of a series of Workouts, Plans can be used to build cycles or be assigned to clients as stand-alone Plans.</p>
						</div>
					</div>
					<div className="new-client-header-dots-container">
						<div className="new-client-header-dot-long"/>
						<div className="new-client-header-dot-short"/>
						<div className="new-client-header-dot-short"/>
					</div>
				</div>
				<div hidden={workflowState === 0}>
					<div className="new-client-header-upper-content">
						<div className="new-plan-header-icon-container">
							<DynamicFeed className="new-plan-header-icon"/>
						</div>
						<h2>{incomingSelectedFolder.type === "plan" ? (props.planToEdit !== undefined ? 'Edit your Workout Plan' : '') : 'Build a new Phase'}</h2>
					</div>
					<div className="new-client-header-body-container">
						<div hidden={workflowState >= 5} className="new-client-header-body-header">
							<h1>Step {workflowState + 1}</h1>
							<p>{pageDescs[workflowState]}</p>
						</div>
						<div hidden={workflowState !== 5} className="new-client-header-body-header">
							<h1>Completing...</h1>
							<p>Hold tight as we create '{plan.title}' in your Workout Library</p>
						</div>
						<div hidden={workflowState !== 6} className="new-client-header-body-header">
							<h1>Complete!</h1>
							<p></p>
						</div>
						<div hidden={workflowState >= 5} className="new-client-header-body-content">
							{pageNames.map((item: string, index: number) => (
								<div onClick={() => jumpToPagePressed(index)} className={`new-client-header-body-row-container ${workflowState === index ? 'new-client-header-body-row-container-selected' : ''}`}>
									<div className="new-client-header-body-row-circle">
										<h4>{index + 1}</h4>
									</div>
									<div className="new-client-header-body-row-text-container">
										<h4>{item}</h4>
									</div>
									<div hidden={index === pageNames.length - 1} className="new-client-header-body-row-line"/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="modal-bottom">
				<div onClick={() => continuePressed()} hidden={ workflowState === 3 } className="modal-cta-button">{ workflowState === 4 ? 'Finish' : (workflowState === 0 ? (props.planToEdit !== undefined && props.planToEdit.id !== "" ? "Edit Workout List" : "Add Workouts to Plan") : 'Next')}</div>
				<div onClick={() => backPressed()} hidden={ workflowState <= 0 || workflowState >= 3 } className="modal-back-button">Back</div>
			</div>
*/
