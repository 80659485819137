import React, { useState, useEffect } from 'react';
import { IonInput, IonTextarea } from '@ionic/react';
import './GoalSkillColumn.css';

import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

import InfoHover from '../Base/InfoHover';
import GoalSkillColumnActionCard from './GoalSkillColumnActionCard';

function GoalSkillColumn(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    let initGoalAction: GoalActions_t = {
        index: 0,
        goalDesc: "",
        durationDays: 1,
        subActions: [
            {
                index: 0,
                goalDesc: "",
                durationDays: 1,
            }  
        ]
    }

    const [goalAction, setGoalAction] = useState<GoalActions_t>(initGoalAction);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.goalAction !== undefined) {
            setGoalAction(props.goalAction);
        }
    }

    useEffect(() => {
        // if (props.goalAction !== undefined) {
        //     setGoalAction(props.goalAction);
        // }
        if (props.forceEdit !== undefined && props.forceEdit === true) {
            setGoalAction(props.goalAction);
            return;
        } else {
            if (props.goalAction !== undefined && props.goalAction.durationDays !== goalAction.durationDays) {
                setGoalAction({...goalAction, durationDays: props.goalAction});
            }
        }
        
    }, [props.goalAction])

    useEffect(() => {
        props.goalActionUpdated(goalAction);
    }, [goalAction])

    function getEndingDateString() {

        let currentTS = Date.now();
        let numDays = goalAction.durationDays * (props.index !== undefined ? (props.index + 1) : 1);
        let endingTS = currentTS + (numDays * 24 * 60 * 60 * 1000);

        let thisDate = new Date(endingTS);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
    }

    function inputUpdated(key: any) {

    }

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'skill': 
                setGoalAction({...goalAction, goalDesc: toString});
                break;
            default:
                break;
        }
    }

    function addAnotherAction() {

        if (goalAction.subActions === undefined) {
            let newGoalAction: GoalActions_t = {
                index: 0,
                goalDesc: "",
                durationDays: goalAction.durationDays,
            }  

             setGoalAction({...goalAction, subActions: [newGoalAction]});

        } else {
            let newGoalAction: GoalActions_t = {
                index: goalAction.subActions.length,
                goalDesc: "",
                durationDays: goalAction.durationDays / goalAction.subActions.length,
            }  
            setGoalAction({...goalAction, subActions: goalAction.subActions.concat(newGoalAction)});          
        }
    }

    function goalActionUpdated(thisGoalAction: GoalActions_t, atIndex: number) {
        if (goalAction.subActions === undefined) { return; }
        setGoalAction({...goalAction, subActions: goalAction.subActions.map((item: GoalActions_t, index: number) => {
            if (atIndex === index) {
                return thisGoalAction;
            } else {
                return item;
            }
        })})
    }

	return (
		<div className="goal-skill-column">
			<div className="goal-skill-column-header">
                <div className="goal-skill-column-header-number-container">
                    <p>SKILL</p>
                    <h4>{props.index + 1 < 10 ? '0' : ''}{props.index + 1}</h4>
                </div>
                <div className="goal-skill-column-header-skill-desc-container">
                    
                    <div className="goal-skill-column-header-input-header-container">
                        <p>Skill Description</p>
                        <InfoHover isBottom={true} text="Enter a short description of the skill your client should develop"/>
                    </div>
                    <div className="goal-skill-column-header-input-container">
                        <IonTextarea value={goalAction.goalDesc} 
                            class="ion-no-padding"
                            placeholder={`Enter skill...`}
                            inputmode="text"
                            clearOnEdit={false}
                            autoGrow={true}
                            rows={1}
                            maxlength={500}
                            onKeyPress={e => inputUpdated(e.key)}
                            onIonChange={e => inputChanged(e.detail.value!, 'skill')}/>
                    </div>
                </div>
                <div hidden={true} className="goal-skill-column-header-skill-date-container">
                    <div className="goal-skill-column-header-input-header-container">
                        <p>Ending on</p>
                        <InfoHover text="The end date of this skill is automatically calculated so that each skill consumes an equal number of days along the path to reaching your Client's Goal."/>
                    </div>
                    <div className="goal-skill-column-header-date-container">
                        <h4>{getEndingDateString()}</h4>
                    </div>
                </div>
                

            </div>
            <div className="goal-skill-column-body">
                <div className="goal-skill-column-body-header">
                    <h4>Develop this skill over {Number(goalAction.durationDays)} day{Number(goalAction.durationDays) === 1 ? '' : 's'} with the following actions</h4>
                </div>
                <div className="goal-skill-column-body-actions-container">
                    {
                        goalAction.subActions === undefined ? null : goalAction.subActions.map((item: GoalActions_t, index: number) => (
                            <GoalSkillColumnActionCard 
                                goalAction={item} 
                                index={index} 
                                skillIndex={props.index}
                                client={props.client}
                                forceEdit={props.forceEdit}
                                goalActionUpdated={(e: GoalActions_t) => goalActionUpdated(e, index)}/>
                        ))
                    }
                </div>
                <div hidden={true} onClick={() => addAnotherAction()} className="goal-skill-column-body-add-actions-container">
                    <p>+ Add another Action</p>
                </div>
            </div>
		</div>
	)
}

/*
<div className="goal-skill-column-header-skill-date-container">
                    <div className="goal-skill-column-header-input-header-container">
                        <p>Build Skill For</p>
                    </div>
                    <div className="goal-skill-column-header-date-container">
                        <h4>{goalAction.durationDays} day{goalAction.durationDays === 1 ? '' : 's'}</h4>
                    </div>
                </div>
<div className="goal-skill-column-body-actions-content">
                                <div className="goal-skill-column-body-actions-content-header">
                                    <h4>Action {index + 1}</h4>
                                </div>
                            </div>
*/

export default GoalSkillColumn;