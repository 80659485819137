import React, { useState } from 'react';
import './MyStudio.css';

import PageHeader_t from '../../../Interfaces/PageHeader_t';
import Location_t from '../../../Interfaces/Locations/Location_t';

import PageHeader from '../../../components/Base/PageHeader';
import MyStudioPage from './MyStudioPage';


function MyStudio(props: any) {

    let pageOptions: PageHeader_t[] = [{ name:"My Studio", alert:false }];

    const [pageNumber, setPageNumber] = useState(0);

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function pageSelected(newPage: string) {
        for (var i = 0; i < pageOptions.length; i++) {
            if (pageOptions[i].name === newPage) {
                setPageNumber(i);
            }
        }
    }

    return (
        <div className="my-studio">
            <div className="home-header">
                <PageHeader 
                    pageName="Studio Settings" 
                    pageOptions={pageOptions}
                    pageSelected={(e: string) => pageSelected(e)}
                    workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
                    addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
                    addNewWorkoutPlan={() => props.createNewWorkoutPlan !== undefined ? props.createNewWorkoutPlan() : null}
                    addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
                    addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
            </div>
            <div className="my-studio-content">
                <div className="my-coaching-profile-page-container">
                    <div className="my-coaching-profile-page-container-inner">
                        <MyStudioPage
                            addLocationPressed={() => props.addLocationPressed()}
                            editLocationPressed={(l: Location_t) => props.editLocationPressed(l)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyStudio;