import React, { useState } from 'react';
import firebase from '../../../../Firebase';

import './Notifications.css';
import '../SettingsCommon.css';

import SettingsPageHeader from '../SettingsPageHeader';
import ToggleSwitch from '../../../../components/Base/ToggleSwitch';
import CheckBox from '../../../../components/Base/CheckBox';

import '@material-ui/core';
import Email from '@material-ui/icons/Email';
import DonutLarge from '@material-ui/icons/DonutLarge';
import DesktopMac from '@material-ui/icons/DesktopMac';

function Notifications(props: any) {

    let initNotificationPrefs: any = {
        "email-all": true,
        "dashboard-all": true,
        "desktop-all": false,
        "clients": {
            "measurement": {"email": true, "dashboard": true, "desktop": false},
            "plan-expiring": {"email": true, "dashboard": true, "desktop": false},
            "review": {"email": true, "dashboard": true, "desktop": false},
            "weekly-summary": {"email": true, "dashboard": true, "desktop": false},
            "remote-workout-completed": {"email": true, "dashboard": true, "desktop": false},
            "reached-goal": {"email": true, "dashboard": true, "desktop": false},
            "logged-point": {"email": true, "dashboard": true, "desktop": false},
        },
        "gym": {
            "new-client-added": {"email": true, "dashboard": true, "desktop": false},
            "weekly-summary": {"email": true, "dashboard": true, "desktop": false},
            "new-trainer": {"email": true, "dashboard": true, "desktop": false},
            "gym-info-updated": {"email": true, "dashboard": true, "desktop": false}
        }      
    };

    const [notificationPrefs, setNotificationPrefs] = useState<any>(initNotificationPrefs);
    const [isLoading, setIsLoading] = useState(false);
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        pullCurrentSettings();   
    }

    function pullCurrentSettings() {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`user_pref/${trainerUID}/notifications`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                setNotificationPrefs(data);

            } else {
                // No settings exists - update user's settings
                ref.set(initNotificationPrefs);
            }
        });
    }

    function notificationChannelUpdated(newState: boolean, channel: string) {

        setNotificationPrefs({...notificationPrefs, [`${channel}-all`]: newState});
        //notificationUpdated(newState, channel, 'client');
        //notificationUpdated(newState, channel, 'gym');

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`user_pref/${trainerUID}/notifications`);

        switch (channel) {
            case 'email':
                ref.child('email-all').set(newState);
                
                break;
            case 'dashboard':
                ref.child('dashboard-all').set(newState);
                //setNotificationPrefs({...notificationPrefs, [notificationPrefs['dashboard-all']]: newState});
                break;
            case 'desktop':
                ref.child('desktop-all').set(newState);
                //setNotificationPrefs({...notificationPrefs, [notificationPrefs['desktop-all']]: newState});
                break;
            default:
                break;
        }
    }

    function notificationUpdated(newState: boolean, channel: string, heading: string, setting?: string) {
        if (setting === undefined) {
            // user selected the heading checkbox -- set all in the column to this value
            let notificationPrefs_temp: any = notificationPrefs;
            let notificatioPrefTempKeys: string[] = Object.keys(notificationPrefs_temp);

            let thisHeadingObj: any = notificationPrefs[heading];
            for (var i = 0; i < notificatioPrefTempKeys.length; i++) {
                let thisKey = notificatioPrefTempKeys[i];
                if (thisKey === heading) {
                    let thisHeadingKeys: string[] = Object.keys(notificationPrefs_temp[thisKey]);
                    for (var j = 0; j < thisHeadingKeys.length; j++) {
                        let thisSettingKey = thisHeadingKeys[j];
                        thisHeadingObj[thisSettingKey][channel] = newState;
                        updatePrefInDB(newState, channel, heading, thisSettingKey);
                    }
                }
            }
            // console.log(notificationPrefs_temp);
            setNotificationPrefs({...notificationPrefs, [notificationPrefs[heading]]: thisHeadingObj});
            //setNotificationPrefs(notificationPrefs_temp);
        } else {

            let notificationPrefs_temp: any = notificationPrefs;
            let notificatioPrefTempKeys: string[] = Object.keys(notificationPrefs_temp);

            let thisHeadingObj: any = notificationPrefs[heading];
            for (var i = 0; i < notificatioPrefTempKeys.length; i++) {
                let thisKey = notificatioPrefTempKeys[i];
                if (thisKey === heading) {
                    let thisHeadingKeys: string[] = Object.keys(notificationPrefs_temp[thisKey]);
                    for (var j = 0; j < thisHeadingKeys.length; j++) {
                        let thisSettingKey = thisHeadingKeys[j];
                        if (thisSettingKey === setting) {
                            thisHeadingObj[thisSettingKey][channel] = newState;
                        }
                    }
                }
            }
            // console.log(notificationPrefs_temp);
            setNotificationPrefs({...notificationPrefs, [notificationPrefs[heading]]: thisHeadingObj});

            updatePrefInDB(newState, channel, heading, setting);
        }
    }

    function getAllChannelsOff(channel: string, heading: string) {
        if (notificationPrefs === undefined) { return false };
        var allOff = true;
        let notificatioPrefKeys: string[] = Object.keys(notificationPrefs);
        for (var i = 0; i < notificatioPrefKeys.length; i++) {
            let thisKey: any = notificatioPrefKeys[i];
            if (thisKey === heading) {
                let thisHeadingKeys: string[] = Object.keys(notificationPrefs[thisKey]);
                for (var j = 0; j < thisHeadingKeys.length; j++) {
                    let thisSettingKey = thisHeadingKeys[j];
                    // console.log(thisKey, thisSettingKey, channel);
                    let thisSetting = notificationPrefs[thisKey][thisSettingKey][channel];
                    if (thisSetting === true) { allOff = false; }
                }
            }
        }

        return allOff;
    }

    function updatePrefInDB(newState: boolean, channel: string, heading: string, setting: string) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`user_pref/${trainerUID}/notifications/${heading}/${setting}/${channel}`);
        ref.set(newState);
    }

	return (
		<div className="settings-page-container">
			<SettingsPageHeader
                title="Notifications"
                subTitle="Configure your notification preferences to suit your needs"/>
            <div className="settings-page-section-container">
                <h2>Notification Channels</h2>
                <div className="notifications-channel-container notifications-channel-container-lined">
                    <div className="notifications-channel-icon-container">
                        <Email className="notifications-channel-icon"/>
                    </div>
                    <div className="notifications-channel-content">
                        <h4>Emails</h4>
                        <p>Recieve email notifications for events selected below.</p>
                    </div>
                    <div className="notifications-channel-switch-container">
                        <ToggleSwitch 
                            stateChanged={(e: boolean) => notificationChannelUpdated(e, 'email')}
                            forceState={notificationPrefs['email-all']} />
                    </div>
                </div>
                <div className="notifications-channel-container notifications-channel-container-lined">
                    <div className="notifications-channel-icon-container">
                        <DonutLarge className="notifications-channel-icon"/>
                    </div>
                    <div className="notifications-channel-content">
                        <h4>Dashbaord</h4>
                        <p>View selected notifications in your Eigen Trainer dashboard.</p>
                    </div>
                    <div className="notifications-channel-switch-container">
                        <ToggleSwitch 
                            stateChanged={(e: boolean) => notificationChannelUpdated(e, 'dashboard')} 
                            forceState={notificationPrefs['dashboard-all']}/>
                    </div>
                </div>
                <div className="notifications-channel-container">
                    <div className="notifications-channel-icon-container">
                        <DesktopMac className="notifications-channel-icon"/>
                    </div>
                    <div className="notifications-channel-content">
                        <h4>Desktop</h4>
                        <p>Recieve notifications from your browser for selected events.</p>
                    </div>
                    <div className="notifications-channel-switch-container">
                        <ToggleSwitch 
                            stateChanged={(e: boolean) => notificationChannelUpdated(e, 'desktop')} 
                            forceState={notificationPrefs['desktop-all']}/>
                    </div>
                </div>
            </div>
            <div className="notifications-channel-container notifications-channel-container-lined">
                <div className="settings-page-card">
                    <div className="settings-page-card-header">
                        <h4>Send me notifications for</h4>
                        <div className="notifications-card-channel-header-container">
                            <h5>Email</h5>
                            <h5>Dashbaord</h5>
                            <h5>Desktop</h5>
                        </div>
                    </div>
                    <div className="settings-page-card-content">
                        <div className="notifications-card-row-header">
                            <h4>Client Updates</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients')}
                                        forceState={!getAllChannelsOff('email','clients')}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients')}
                                        forceState={!getAllChannelsOff('dashboard','clients')}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients')}
                                        forceState={!getAllChannelsOff('desktop','clients')}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Assigned plan is expiring</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'plan-expiring')}
                                        forceState={notificationPrefs['clients']['plan-expiring']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'plan-expiring')}
                                        forceState={notificationPrefs['clients']['plan-expiring']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'plan-expiring')}
                                        forceState={notificationPrefs['clients']['plan-expiring']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Client-trainer review is due</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'review')}
                                        forceState={notificationPrefs['clients']['review']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'review')}
                                        forceState={notificationPrefs['clients']['review']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'review')}
                                        forceState={notificationPrefs['clients']['review']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Weekly summary of Client's progress</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'weekly-summary')}
                                        forceState={notificationPrefs['clients']['weekly-summary']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'weekly-summary')}
                                        forceState={notificationPrefs['clients']['weekly-summary']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'weekly-summary')}
                                        forceState={notificationPrefs['clients']['weekly-summary']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Your Client completed a remote workout</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'remote-workout-completed')}
                                        forceState={notificationPrefs['clients']['remote-workout-completed']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'remote-workout-completed')}
                                        forceState={notificationPrefs['clients']['remote-workout-completed']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'remote-workout-completed')}
                                        forceState={notificationPrefs['clients']['remote-workout-completed']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Your Client's goal was reached</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'reached-goal')}
                                        forceState={notificationPrefs['clients']['reached-goal']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'reached-goal')}
                                        forceState={notificationPrefs['clients']['reached-goal']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'reached-goal')}
                                        forceState={notificationPrefs['clients']['reached-goal']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Measurement upcoming reminder</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'measurement')}
                                        forceState={notificationPrefs['clients']['measurement']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'measurement')}
                                        forceState={notificationPrefs['clients']['measurement']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'measurement')}
                                        forceState={notificationPrefs['clients']['measurement']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Goal progress point was logged</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'clients', 'logged-point')}
                                        forceState={notificationPrefs['clients']['logged-point']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'clients', 'logged-point')}
                                        forceState={notificationPrefs['clients']['logged-point']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'clients', 'logged-point')}
                                        forceState={notificationPrefs['clients']['logged-point']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>


                        <div className="notifications-card-row-header notifications-card-row-header-lined">
                            <h4>Gym Notifications</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'gym')}
                                        forceState={!getAllChannelsOff('email','gym')}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'gym')}
                                        forceState={!getAllChannelsOff('dashboard','gym')}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'gym')}
                                        forceState={!getAllChannelsOff('desktop','gym')}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Your gym's weekly summary</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'gym', 'weekly-summary')}
                                        forceState={notificationPrefs['gym']['weekly-summary']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'gym', 'weekly-summary')}
                                        forceState={notificationPrefs['gym']['weekly-summary']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'gym', 'weekly-summary')}
                                        forceState={notificationPrefs['gym']['weekly-summary']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>New Client added to Gym</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'gym', 'new-client-added')}
                                        forceState={notificationPrefs['gym']['new-client-added']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'gym', 'new-client-added')}
                                        forceState={notificationPrefs['gym']['new-client-added']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'gym', 'new-client-added')}
                                        forceState={notificationPrefs['gym']['new-client-added']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>A new Trainer has joined your gym</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'gym', 'new-trainer')}
                                        forceState={notificationPrefs['gym']['new-trainer']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'gym', 'new-trainer')}
                                        forceState={notificationPrefs['gym']['new-trainer']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'gym', 'new-trainer')}
                                        forceState={notificationPrefs['gym']['new-trainer']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-card-row">
                            <h4>Your gym's information has been updated</h4>
                            <div className="notifications-card-row-checkbox-container">
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'email', 'gym', 'gym-info-updated')}
                                        forceState={notificationPrefs['gym']['gym-info-updated']['email']}
                                        isDisabled={notificationPrefs['email-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'dashboard', 'gym', 'gym-info-updated')}
                                        forceState={notificationPrefs['gym']['gym-info-updated']['dashboard']}
                                        isDisabled={notificationPrefs['dashboard-all'] === false}/>
                                </div>
                                <div className="notifications-card-row-checkbox-container-inner">
                                    <CheckBox 
                                        stateChanged={(e: boolean) => notificationUpdated(e, 'desktop', 'gym', 'gym-info-updated')}
                                        forceState={notificationPrefs['gym']['gym-info-updated']['desktop']}
                                        isDisabled={notificationPrefs['desktop-all'] === false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default Notifications;