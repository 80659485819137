import React, { useState } from 'react';
import { FileDrop } from 'react-file-drop';
import './UploadImageModal.css';

import firebase from '../../Firebase';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';


function UploadImageModal(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function uploadImage(files: FileList | null, event: any) {
        if (files === null) { return; }
        if (props.information === undefined || props.information.path === undefined) { return; }
        // console.log('onDrop!', files, event);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setWorkflowState(1);

        let storage = firebase.storage();
        let uploadTask = storage.ref(props.information.path).put(files[0]);

        uploadTask.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        }, (error) => {
            // error

        }, () => {
            // completed
            setTimeout(() => {
                setWorkflowState(2);
                if (props.information.completedCallback !== undefined) {
                    props.information.completedCallback();
                }
            }, 500);
        });

        // storage.ref(props.information.path).put(files[0]).then((resp: any) => {
        //     setTimeout(() => {
        //         if (props.information.completedCallback !== undefined) {
        //             props.information.completedCallback();
        //         }
        //     }, 500);
            
        // }).catch((e: any) => {

        // })

    }

	return (
        <div className="modal-background feedback-modal-background">
    		<div className="upload-image-modal">
    			<div className="upload-image-modal-header">
                    <h4>Upload a Profile Image</h4>
                    <div onClick={() => props.hideModalPressed()} className="confirm-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
                </div>
                <div className="upload-image-modal-content">
                    <FileDrop
                        onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                        onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                        onFrameDrop={(event) => console.log('onFrameDrop', event)}
                        onDragOver={(event) => console.log('onDragOver', event)}
                        onDragLeave={(event) => console.log('onDragLeave', event)}
                        onDrop={(files, event) => uploadImage(files, event)}>
                            <h4>Drag & Drop an <span>Image</span> to Upload</h4>
                            <p>PNG, JPG, and GIF file types are accepted</p>
                    </FileDrop>
                    <div hidden={workflowState !== 1} className="upload-image-modal-loading-bar-container">
                        <div className="upload-image-modal-loading-bar">
                            <div className="upload-image-modal-loading-bar-inner" style={{width: `${uploadProgress}%`}}/>
                        </div>
                        <p>{Math.round(uploadProgress)}%</p>
                    </div>
                    <div hidden={workflowState !== 2} className="upload-image-modal-loading-bar-container">
                       <h4>Upload complete!</h4>
                    </div>
                </div>
                <div hidden={workflowState !== 2} className="upload-image-modal-button-container">
                    <div onClick={() => props.hideModalPressed()} className="upload-image-modal-button">
                        <p>Done</p>
                    </div>
                </div>
    		</div>
        </div>
	)
}

export default UploadImageModal;