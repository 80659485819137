import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './Selector.css';

import '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';


function Selector (props: any) {

    const [options, setOptions] = useState([]);
    const [optionsSet, setOptionsSet] = useState(false);
    const [showList, setShowList] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    useEffect(() => {
        if (props.forceSelected !== undefined) {
            setSelectedItemIndex(props.forceSelected >= 0 ? props.forceSelected : 0);
        }
    }, [props.forceSelected])

    useEffect(() => {
        if (props.options !== undefined) {
            setOptions(props.options);
        }
    }, [props.options])

    if (optionsSet === false) {
        setOptionsSet(true);
        setOptions(props.options);
    }

    function toggleShowList() {
        setShowList(!showList);
    }

    function optionSelected(toOption: string) {
        for (var i = 0; i < options.length; i++) {
            if (toOption === options[i]) {
                setSelectedItemIndex(i);
                props.inputChanged(options[i]);
            }
        }

        setShowList(false);
    }

    return (
        <div className="selector-input-container">
            <div hidden={props.showTitle !== undefined || props.showTitle === false}><p className="selector-text-input-label">{props.placeholder}</p></div>
            <div onClick={() => toggleShowList()} className={props.alert === undefined || props.alert === false ? `selector-text-input-container ${showList ? 'selector-text-input-container-open' : ''}` : "selector-text-input-container-alert"}>
                <div className="selector-text">{props.options[selectedItemIndex]}</div>
                <div hidden={showList} className="selector-menu-icon-container"><ExpandMore className="selector-menu-icon"/></div>
                <div hidden={!showList} className="selector-menu-icon-container"><Close className="selector-menu-icon"/></div>
            </div>
            <CSSTransition in={showList} timeout={200} classNames="menu" unmountOnExit appear>
                <div className="selector-menu-container">
                    {options.map(item => (
                        <div 
                            onClick={() => optionSelected(item)}
                            key={item} 
                            className={item === options[selectedItemIndex] ? "selector-menu-option-selected" : "selector-menu-option"}>
                            {item}
                        </div>
                    ))}
                </div>
            </CSSTransition>
        </div>
    )
}


export default Selector;
