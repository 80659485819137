import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './NodeSetCell.css';

import NodeSet_t from '../../Interfaces/NodeSet_t';
import NodeInstance from '../../NodeInstance';

import '@material-ui/core';
import WifiTethering from '@material-ui/icons/WifiTethering';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

function NodeSetCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [cardAdditionalCSSClass, setCardAdditionalCSSClass] = useState("");
    const [connectedNodesUUIDs, setConnectedNodesUUIDs] = useState<string[]>([]);
    const [showMenu, setShowMenu] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.pingState !== undefined && props.pingState === true) {
            blinkCardGreen();
        }
    }, [props.pingState]);

    useEffect(() => {
        if (props.connectedNodesUUIDs !== undefined) {
            setConnectedNodesUUIDs(props.connectedNodesUUIDs);
        }
    }, [props.connectedNodesUUIDs])

    function getConnectedCircles() {
        var composedSet = [];
        for (var i = 0; i < props.totalNodes; i++) {
            composedSet.push(<div className={`node-set-cell-info-node-connected-cirlce ${i < props.connectedNodes ? "node-set-cell-info-node-connected-cirlce-connected" : "node-set-cell-info-node-connected-cirlce-notconnected"}`}/>);
        }

        return composedSet;
    }

    function setSelected() {
        props.setSelected(props.setSIN);
        blinkCardTransparency();
    }

    function pingAllPressed() {
        setShowMenu(false);
        props.pingAllPressed(props.setSIN);
        blinkCardGreen();
    }

    function forgetPressed() {
        setShowMenu(false);
        props.forgetPressed(props.setSIN);
        
    }

    function blinkCardTransparency() {
        setCardAdditionalCSSClass("node-set-cell-container-low-opacity");
        setTimeout(() => {
            setCardAdditionalCSSClass("");
        }, 200);
    }

    function blinkCardGreen() {
        let blinkTime = 400;
        setCardAdditionalCSSClass("node-set-cell-container-green");
        setTimeout(() => {
            setCardAdditionalCSSClass("");
            setTimeout(() => {
                setCardAdditionalCSSClass("node-set-cell-container-green");
                setTimeout(() => {
                    setCardAdditionalCSSClass("");
                }, blinkTime);
            }, blinkTime);
        }, blinkTime);
    }

    function getNodeName(uuid: string) {
        if (props.nodeManager === undefined) { return 'Unknown Node'; }

        let thisNode: NodeInstance | null = props.nodeManager.getNodeInstanceByUUID(uuid);
        if (thisNode === null) { return 'Unknown Node'; }

        let thisSIN = thisNode.getDeviceSIN();

        return thisSIN;
    }

    function toggleMenu() {
        setShowMenu(!showMenu);
    }

    window.addEventListener('click', function(e){   
        checkOptionsMenuHide(e);
        
    });

    function checkOptionsMenuHide(e: any) {
        if (document === null || document.getElementById('node-set-cell-menu') === null || document.getElementById('node-set-cell-button') === null || e.target === null) { return; }

        if (document.getElementById('node-set-cell-menu')!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById('node-set-cell-button')!.contains(e.target as Node))) {
                if (showMenu === true) {
                    setShowMenu(false);
                }
            }
        }
    }

	return (
        <div className="node-set-cell">
            <div className={`node-set-cell-container ${cardAdditionalCSSClass} ${props.isSelected ? "node-set-cell-container-selected" : "node-set-cell-container-unselected"}`}>
                <div onClick={() => setSelected()} className="node-set-cell-info-container">
                    <div className="node-set-cell-info-title-container">
                        <h2>{`Set SIN ${props.setSIN !== undefined ? props.setSIN : "unspecified"}`}</h2>
                    </div>
                    <div className="node-set-cell-info-node-connected-container">
                        <p>({props.connectedNodes} Node{props.connectedNodes > 1 ? "s" : ""})</p>
                        <div className="node-set-cell-info-node-connected-cirlces-container">
                            {
                                getConnectedCircles()
                            }
                        </div>
                    </div>
                </div>
                <div id="node-set-cell-button" onClick={() => toggleMenu()} className="node-set-cell-button">
                    <MoreHoriz className="node-set-cell-button-icon"/>
                </div>
                <CSSTransition in={showMenu} timeout={200} classNames="menu" unmountOnExit>
                    <div id="node-set-cell-menu" className="workout-plan-list-cycle-overview-header-card-menu-container node-set-cell-menu">
                        <div className="workout-plan-list-cycle-overview-header-card-menu-header-row">
                            <p>Node Set Options</p>
                        </div>
                        <div onClick={() => pingAllPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <WifiTethering className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Ping all Nodes</p>
                        </div>
                        <div onClick={() => forgetPressed()} className="workout-plan-list-cycle-overview-header-card-menu-row">
                            <div className="workout-plan-list-cycle-overview-header-card-menu-row-icon-container">
                                <DeleteOutline className="workout-plan-list-cycle-overview-header-card-menu-row-icon"/>
                            </div>
                            <p>Forget Set</p>
                        </div>
                    </div>
                </CSSTransition>

                <div hidden={true} className="node-set-cell-buttons-container">
                    <div onClick={() => pingAllPressed()} className="node-set-cell-button node-set-cell-button-blue">
                        <p>Ping All</p>
                    </div>
                    <div onClick={() => forgetPressed()} className="node-set-cell-button node-set-cell-button-red">
                        <p>Forget Set</p>
                    </div>
                </div>
            </div>
            <div className="node-set-cell-nodes-list-container">
                {
                    connectedNodesUUIDs.map((item: string, index: number) => (
                        <div className="node-set-cell-nodes-cell">
                            <div className="node-set-cell-nodes-cell-circle"/>
                            <div className="node-set-cell-nodes-cell-name">
                                <h4>Node 0{getNodeName(item)}</h4>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
		
	)
}

export default NodeSetCell;