import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import './Workouts.css';
import './DashboardPageCommon.css';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';

import Workout_t from '../../Interfaces/Workout_t';
import Plan_t from '../../Interfaces/Plan_t';
import Folder_t from '../../Interfaces/Folder_t';

import PageHeader_t from '../../Interfaces/PageHeader_t';
import StandardButton from '../../components/Base/StandardButton';
import PageHeader from '../../components/Base/PageHeader';
import WorkoutList from './WorkoutWorkflow/WorkoutList'
import ExerciseList from './WorkoutWorkflow/ExerciseList';
import WorkoutPlanList from './WorkoutWorkflow/WorkoutPlanList';
import WorkoutsMore from './WorkoutWorkflow/WorkoutsMore';
import BlankPage from '../../components/PageElements/BlankPage'

import '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


function Workout(props: any) {

	let pageOptions: PageHeader_t[] = [{ name:"My Programs", alert:false }, 
					   				   { name:"All Workouts", alert:false },
					   				   //{ name:"Shared Workouts", alert:false },
					   				   { name:"Exercise Library", alert:false },
					   				   { name:"More", alert:false }]

	const [pageNumber, setPageNumber] = useState(0);
	function pageSelected(newPage: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setPageNumber(i);
				Mixpanel.track("Workouts sub-page selected",{page: pageOptions[i].name, has_alert:pageOptions[i].alert});
			}
		}
	}

	useEffect(() => {
		if (props.subPageIndex !== undefined) {
			pageSelected(pageOptions[props.subPageIndex].name)
		}
	}, [props.subPageIndex])

	function workoutSelected(workout: Workout_t) {
		//props.startWorkoutWithWorkout(workout);
		props.showWorkoutProfile(workout);
	}

	function createNewWorkout() {
		props.createNewWorkout();
		Mixpanel.track("Add new workout from workout page");
	}

	return (
		<div className="page-container">
			<div className="workout-header">
				<PageHeader 
					pageName="Programs"
					pageOptions={pageOptions}
					pageSelected={(e: string) => pageSelected(e)}
					workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
					addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
			</div>
			<div className="page-container-no-padding">
				<CSSTransition in={pageNumber === 0} timeout={200} classNames="slide" unmountOnExit>
					<WorkoutPlanList 
						workoutSelected={(e: Workout_t) => workoutSelected(e)}
						assignClientToPlan={(e: Plan_t) => props.assignClientToPlan(e)}
						assignClientToFolder={(e: Folder_t) => props.assignClientToFolder(e)}
						newPlanPressed={(folder: Folder_t, callback: any) => props.createNewPlanWithFolderAndCallback(folder, callback)}
						duplicatePlanPressed={(p: Plan_t) => props.duplicatePlanPressed(p)}
						editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
						editRestDayPressed={(p: Plan_t, i: number) => props.editRestDayPressed(p, i)}
						deleteCyclePressed={(a: any) => props.deleteCyclePressed(a)}
						addElementPressed={(a: any) => props.addElementPressed(a)}
						addProgramPressed={(a: any) => props.addProgramPressed(a)}
						addNewWorkoutToPlan={(p: Plan_t) => props.addNewWorkoutToPlan(p)}
						getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
						copyFolderToClientPressed={(e: any) => props.copyFolderToClientPressed(e)}
						addNewExerciseToWorkoutModalPressed={(d: any) => props.addNewExerciseToWorkoutModalPressed(d)}/>
				</CSSTransition>
				<CSSTransition in={pageNumber === 1} timeout={200} classNames="slide" unmountOnExit>
					<WorkoutList 
						personalWorkouts={true}
	                    showTitle={false}
	                    moreInfoSelected={(e: Workout_t) => workoutSelected(e)}
	                    workoutSelected={(e: Workout_t) => workoutSelected(e)}
	                    addNewWorkoutPressed={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}/>
	            </CSSTransition>
	            <CSSTransition in={pageNumber === 2} timeout={200} classNames="slide" unmountOnExit>
					<ExerciseList 
						personalWorkouts={false}
	                    showTitle={false}
	                    addNewExercisePressed={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}
	                    moreInfoSelected={(e: Workout_t) => workoutSelected(e)}
	                    workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
				</CSSTransition>
				<CSSTransition in={pageNumber === 3} timeout={200} classNames="slide" unmountOnExit>
					<WorkoutsMore 
						getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
						createNewLoadingPattern={() => props.createNewLoadingPattern()}/>
				</CSSTransition>
			</div>
		</div>
	)

}


/*
<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 0}>
				<WorkoutPlanList 
					workoutSelected={(e: Workout_t) => workoutSelected(e)}
					assignClientToPlan={(e: Plan_t) => props.assignClientToPlan(e)}
					newPlanPressed={(folder: Folder_t) => props.addNewWorkoutPlan(folder)}/>

			</div>
			<CSSTransition in={pageNumber === 1} timeout={200} classNames="slide" unmountOnExit appear>
				<WorkoutList 
					personalWorkouts={true}
                    showTitle={false}
                    moreInfoSelected={(e: Workout_t) => workoutSelected(e)}
                    workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
            </CSSTransition>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 2}>
				<WorkoutList 
					personalWorkouts={false}
                    showTitle={false}
                    moreInfoSelected={(e: Workout_t) => workoutSelected(e)}
                    workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
			</div>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 3}>
				<ExerciseList 
					personalWorkouts={false}
                    showTitle={false}
                    addNewExercisePressed={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}
                    moreInfoSelected={(e: Workout_t) => workoutSelected(e)}
                    workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
			</div>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 1}>

*/
export default Workout;