import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';

import './CopyFolderToClient.css';
import './DuplicatePhase.css'
import './CopyFolderModal.css';
import './AssignModal.css';

import Client_t from '../../Interfaces/Client_t';
import Folder_t from '../../Interfaces/Folder_t';
import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';

import PlanFolderSelector from '../Base/PlanFolderSelector';
import AssignModalClientCell from '../Clients/AssignModalClientCell';
import AssignModalClientFolders from './AssignComponents/AssignModalClientFolders';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';

function DuplicatePhase(props: any) {

    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};
    let goalDescriptors = ["Get Fit", "Loose Weight", "Gain Muscle", "Muscle Toning", "Body Building", "Power Lifting"];

    const [initSet, setInitSet] = useState(false);
    const [copyingFolder, setCopyingFolder] = useState<Folder_t | undefined>();
    const [selectedFolder, setSelectedFolder] = useState<Folder_t | undefined>();
    const [errorString, setErrorString] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [clients, setClients] = useState<Client_t[]>([]);
    const [allClients, setAllClients] = useState<Client_t[]>([]);
    const [searchString, setSearchString] = useState("");
    const [showClientList, setShowClientList] = useState(false);
    const [clientNameFocus, setClientNameFocuse] = useState(false);
    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
    const [imgUrl, setImgUrl] = useState('');

    const [selectedClientFolder, setSelectedClientFolder] = useState<Folder_t | undefined>();

    const [clientFolders, setClientFolders] = useState<Folder_t[]>([]);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        //getPersonalClients();
        pullUserFolders();
        if (props.information !== undefined && props.information.plan !== undefined) {
            setCopyingFolder({ ...props.information.plan, type: "phase", isPlan: true } as Folder_t);
        }
    }

    useEffect(() => {
        if (props.information !== undefined && props.information.plan !== undefined) {
            setCopyingFolder({ ...props.information.plan, type: "phase", isPlan: true } as Folder_t);
        }
    }, [props.information])

    function getPersonalClients() {
        let thisUser = firebase.auth().currentUser;
        if (!thisUser) { return; }


        /*************************************************/
        //setIsLoading(true);    // TODO; uncomment

        let testClient: Client_t = {
            first_name:"Connor", 
            last_name:"Holowachuk", 
            full_name:"Connor Holowachuk", 
            id: "abc123", 
            gender: "m",
            email: "connor_chuk@yahoo.com",
            weight: 155,
            height: 72,
            isMetric: false,
            birthdate: "00-00-0000",
            tags: [goalDescriptors[0], "Gain Muscle"],
            isSelected: true
        };

        //setSelectedClient(testClient);



        /*************************************************/



        // console.log("CLIENT LIST | getPersonalClients()");

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_clients/${thisUser.uid}/clients`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let clientsList = snapshot.val();
                let clientListLength = Object.keys(clientsList).length;
                let temp: Client_t[] = [];
                for (const key of Object.keys(clientsList)) {
                    let thisClient = clientsList[key];

                    let thisClientRef = database.ref(`user_profile/${key}`)
                    thisClientRef.on('value', function(snapshotClient) {
                        if (snapshotClient.exists() === true) {
                            let thisUser = snapshotClient.val();
                            let newClient: Client_t = {
                                first_name:thisUser.first_name, 
                                last_name:thisUser.last_name, 
                                full_name:thisUser.full_name, 
                                id: key, 
                                gender: thisUser.gender,
                                email: thisUser.email,
                                weight: thisUser.weight,
                                height: thisUser.height,
                                isMetric: thisUser.units_kg,
                                birthdate: thisUser.birth_date,
                                tags: [goalDescriptors[0], "Gain Muscle"],
                                isSelected: false
                            };
                            temp.push(newClient);

                            if (temp.length === clientListLength) {
                                // all workouts have been pulled - set the state vars to temp
                                setClients(clients.concat(temp));
                                setAllClients(allClients.concat(temp));
                                setIsLoading(false);

                            }
                        }
                    });

                }

            } else {
                //setClientsBlank(true);
                setIsLoading(false);
            }
        });
    }

    function inputChanged(toString: any, forInput: string) {
        switch (forInput) {
            case 'client-name':
                setSearchString(toString);
                var temp = [];
                for (var i = 0; i < allClients.length; i++) {
                    let searchString = toString.toUpperCase();
                    let nameString = allClients[i].full_name.toUpperCase();
                    let positiveSearch_name = nameString.includes(searchString);

                    var positiveSearch_email = false;
                    let emailString = allClients[i].email;
                    if (emailString != undefined && emailString != null) {

                    }

                    if (positiveSearch_name === true) {
                        temp.push(allClients[i]);
                    }
                }

                setErrorString("");

                setClients(temp);
                if (toString.split('').length > 2) {
                    if (showClientList === false) {
                        setShowClientList(true);
                    }
                }
                break;
            default:
                break;
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            searchButtonPressed()
        }
    }

    function searchButtonPressed() {

    }

    function clientSelected(c: Client_t) {
        setShowClientList(false);
        setSelectedClient(c);
        getImage(c.id);
        setSearchString("");
        setErrorString("");
        // pullClientFolders(c.id);
    }

    function removeClient() {
        setSelectedClientFolder(undefined);
        setSelectedClient(initClient);
        setErrorString("");
    }

    function pullUserFolders() {

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid; //"IDMl0c4MQbacldKQPN7D4Vq0eNz1"//
        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let dataKeys = Object.keys(data);


                // console.log("FOLDERS:", data, "KEYS:", dataKeys);

                var folderListTemp: Folder_t[] = [];
                for (var i = 0; i < dataKeys.length; i++) {
                    let thisKey = dataKeys[i];
                    let thisFolderObj = data[thisKey];
                    // console.log("this folder object:", thisFolderObj, thisKey);
                    let newFolder: Folder_t | null = parseFolderObj(thisFolderObj, thisKey);
                    if (newFolder !== null) {
                        folderListTemp.push(newFolder);
                    }


                }

                // console.log("FOLDER LIST:", folderListTemp)
                setClientFolders(folderListTemp);
            }
        });
    }

    function parseFolderObj(folderObj: any, folderID: string) {

        //if (folderObj.type === undefined || folderObj.type !== "folder") { return null; }

        var childrenList: Folder_t[] = [];

        var childIsCycle = false;
        if (folderObj.children !== undefined) {
            let childrenObjList = folderObj.children;
            let dataKeys = Object.keys(childrenObjList);
            for (var i = 0; i < dataKeys.length; i++) {
                let thisKey = dataKeys[i];
                let thisFolderObj = childrenObjList[thisKey];
                let thisChildFolder: Folder_t | null = parseFolderObj(thisFolderObj, thisKey);
                if (thisChildFolder !== null) {
                    childrenList.push(thisChildFolder);
                } else {
                    childIsCycle = true;
                }
                
            }
        }

        let newFolder: Folder_t = {
            id: folderID,
            title: folderObj.name === undefined ? "" : folderObj.name,
            type: folderObj.type === undefined ? "" : folderObj.type,
            path: folderObj.path === undefined ? "" : folderObj.path,
            children: childrenList,
            isPlan: folderObj.isPlan ?? false
        }

        return newFolder;
    }

    function folderSelected(f: Folder_t) {
        setSelectedFolder(f);
        setErrorString("");
        // console.log("WILL COPY TO:", f.path, f, );
    }

    function getImage(forID: string) {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function closePressed() {
        props.closeModal();
    }

    async function submit() {

        setIsLoading(true);

        // console.log(selectedFolder, copyingFolder);
        if (!copyingFolder || !selectedFolder) return;

        let copyingFolderTemp: any = JSON.parse(JSON.stringify(copyingFolder));

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        // 1. Create new instance of the copying folder as a phase and retrieve the phase ID

        let thisPhaseID = selectedFolder.id;

        let seperatedPath: string[] = selectedFolder.path.split("/");
        if (selectedFolder.path === "" || seperatedPath.length === 0) return;
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}${ i < seperatedPath.length - 1 ? '/children' : ''}`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);



        // let seperatedPath_target: string[] = selectedFolder.path.split("/");
        // var shortenedPath = "/";
        // let newLength = seperatedPath.length - 1;
        // for (var i = 0; i < newLength; i++) {
        //     let thisID = seperatedPath[i];
        //     if (thisID && thisID !== "") {
        //         shortenedPath += thisID;
        //         shortenedPath += i === newLength - 1 ? '' : '/';
        //     }
        // }

        let phaseObj = {
            "availability": 1,
            "created_by": trainerUID,
            "created_timestamp": Date.now(),
            "description": copyingFolderTemp.description ?? "No description of this plan was provided",
            "location_path": selectedFolder.path,
            "periodization_string": copyingFolderTemp.periodization_string ?? "Accumulation",
            "title": selectedFolder.title,
            "workouts": copyingFolderTemp.workout_ids ?? []
        }

        // console.log(`Will put this under phases with ID ${thisPhaseID}:`, phaseObj);


        let database = firebase.database();
        let thisRootPath = `workout_plans/trainer_plans/${trainerUID}/folders_root${composedMasterPath_DB}`
        let ref_planID = database.ref(`${thisRootPath}/plan_id`);
        let ref_phaseName = database.ref(`${thisRootPath}/phase_name`);
        let ref_name = database.ref(`${thisRootPath}/name`);

        await ref_planID.set(thisPhaseID);
        await ref_phaseName.set(selectedFolder.title);
        await ref_name.set(`${copyingFolderTemp.title && copyingFolderTemp.title !== "" ? copyingFolderTemp.title : selectedFolder.title ?? ""} (copy)`);

        let refPhases = database.ref(`workout_plans/plans/${thisPhaseID}`);
        await refPhases.set(phaseObj);


        setTimeout(() => {
            setIsLoading(false);
            closePressed();
        }, 600);

        // 2. Add the phaseName and plan_id to the existing object at copyingFolder's path





        /*
        if ( selectedFolder === undefined || selectedFolder.id === "" || copyingFolder === undefined || copyingFolder.id === "" ) { return; }
        setIsLoading(true);


        let newMasterPath = selectedFolder.path;
        let folderExists = false;//await checkIfLocationExists(newMasterPath, copyingFolder.id, selectedClient.id);

        // if (folderExists === true) {
        //     setIsLoading(false)
        //     setErrorString("This folder has already been copied here! Please choose another location.");
        //     return;
        // }

        let seperatedPath: string[] = copyingFolder.path.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);
        
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root${composedMasterPath_DB}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                // console.log("DATA:", data);

                let newMasterPath_withKey = `${newMasterPath}/${copyingFolder.id}`;

                let newChildList: any[] = getFolderChildArray(data, newMasterPath_withKey);

                // console.log("NEW LIST:", newChildList);

                let masterFolder: any = {
                    name: copyingFolder.title,
                    path: newMasterPath_withKey,
                    type: "folder",
                    children: newChildList
                };

                addFormattedFoldersToDB(masterFolder, newMasterPath, copyingFolder.id, selectedClient.id);
            } else {
                // console.log("NO FOLDERS!")
            }
        });
        */
    }

    async function submit_old() {
        if (selectedClient === undefined || selectedClient.id === undefined || selectedClient.id === "" || selectedClientFolder === undefined || copyingFolder === undefined) { return; }
        setIsLoading(true);

        /*
        // 1. Place folder contents in Client's folder structure
        // 1.a) Generate path in Client's folder structure
        let addingToFolderLocation = selectedClientFolder === undefined ? "/" : selectedClientFolder.path;

        let seperatedPath: string[] = addingToFolderLocation.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);

        // 1.b) Add object to Client's folder structure
        let masterFolderDBPath = `workout_plans/client_assigned_plans/${selectedClient.id}/folders${composedMasterPath_DB}`;
        let database = firebase.database();
        let masterFolderRef = database.ref(masterFolderDBPath);

        let masterFolderRefObj = await masterFolderRef.push({
            name: selectedClientFolder.title,
            template_id: selectedClientFolder.id,
            template_path: selectedClientFolder.path,
            type: "folder"
        });

        let masterFolderKey = masterFolderRefObj.key;

        if (masterFolderKey === null) {
            return;
        }

        // console.log("FOLDER PUSH KEY: ", masterFolderKey);

        await masterFolderRef.child(masterFolderKey).child("path").set(`${addingToFolderLocation}/${masterFolderKey}`);
        */

        let newMasterPath = selectedClientFolder.path;
        let folderExists = await checkIfLocationExists(newMasterPath, copyingFolder.id, selectedClient.id);

        if (folderExists === true) {
            setIsLoading(false)
            setErrorString("This folder has already been copied here! Please choose another location.");
            return;
        }

        let seperatedPath: string[] = copyingFolder.path.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);
        
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root${composedMasterPath_DB}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                // console.log("DATA:", data);

                let newMasterPath_withKey = `${newMasterPath}/${copyingFolder.id}`;

                let newChildList: any[] = getFolderChildArray(data, newMasterPath_withKey);

                // console.log("NEW LIST:", newChildList);

                let masterFolder: any = {
                    name: copyingFolder.title,
                    path: newMasterPath_withKey,
                    type: "folder",
                    children: newChildList
                };

                addFormattedFoldersToDB(masterFolder, newMasterPath, copyingFolder.id, selectedClient.id);
            } else {
                // console.log("NO FOLDERS!")
            }
        });

    }

    function getFolderChildArray(data: any, thisParentPath: string) {
        let dataKeys: any[] = Object.keys(data);

        var fullChildList: any = {};
        for (var i = 0; i < dataKeys.length; i++) {
            let thisKey = dataKeys[i];
            let thisObj = data[thisKey];

            let thisPath = `${thisParentPath}/${thisKey}`; //`${thisParentPath}${thisObj.path}`;
            //let thisPathPropogation = `${thisParentPath}/${thisKey}`;

            var childrenList: any = [];
            if (thisObj.children !== undefined) {
                childrenList = getFolderChildArray(thisObj.children, thisPath);
            }

            let newObj: any = JSON.parse(JSON.stringify(thisObj));
            newObj["path"] = thisPath;
            newObj["children"] = childrenList;

            if (newObj.template_id !== undefined) {
                newObj["plan_id"] = newObj.template_id;
            }

            fullChildList[thisKey] = newObj;
        }

        return fullChildList;
    }

    async function checkIfLocationExists(withPath: string, folderID: string, clientUID: string) {
        return new Promise<boolean>(resolve => {

            let seperatedPath: string[] = withPath.split("/");
            // console.log("seperatedPath", seperatedPath);

            var composedMasterPath_DB = "";
            var composedMasterPath_raw = "";
            for (var i = 0; i < seperatedPath.length; i++) {
                let thisElement: string = seperatedPath[i];
                if (thisElement !== undefined && thisElement !== "") {
                    composedMasterPath_DB += `/${thisElement}/children`;
                    composedMasterPath_raw += `/${thisElement}`;
                }
            }

            composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
            composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
            // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);

            let finalPath = `workout_plans/client_assigned_plans/${clientUID}/folders${composedMasterPath_DB}/${folderID}`;
            let database = firebase.database();
            let ref = database.ref(finalPath);
            ref.once('value', function(snapshot) {
                resolve(snapshot.exists())
            });
        })
    }

    async function addFormattedFoldersToDB(data: any, withPath: string, folderID: string, clientUID: string) {

        // console.log("addFormattedFoldersToDB", data, withPath, folderID);

        let seperatedPath: string[] = withPath.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);

        let finalPath = `workout_plans/client_assigned_plans/${clientUID}/folders${composedMasterPath_DB}/${folderID}`;
        // console.log("FINAL PATH:", finalPath);

        let database = firebase.database();
        let ref = database.ref(finalPath);
        await ref.set(data);

        setTimeout(() => {
            setIsLoading(false);
            closePressed();
        }, 600);
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

	return (
		<div className="modal-background copy-folder-modal-background copy-folder-to-client-modal-background">
            <div className="new-element-modal-container copy-folder-modal-container copy-folder-to-client-modal-container">
                <div onClick={() => closePressed()} className="new-element-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
                <div className="new-element-modal-top copy-folder-modal-top">
                    <h3>Duplicate "{copyingFolder === undefined ? "this Phase" : copyingFolder.title}" to an empty Phase</h3>
                    <p>Select an empty Phase below where you would like to duplicate "{copyingFolder === undefined ? "this Phase" : copyingFolder.title}" to.</p>
                    <div hidden={errorString === ""} className="copy-folder-modal-top-error">
                        <p>{errorString}</p>
                    </div>
                </div>
                <div hidden={isLoading} className="copy-folder-to-client-modal-body">
                    <div className="assign-modal-body-row">
                        <div className="assign-modal-body-row-label-container">
                            <h4>Select a location to paste "{copyingFolder === undefined ? "this Folder" : copyingFolder.title}"</h4>
                        </div>
                        <div className="copy-folder-to-client-modal-folder-container">
                            <PlanFolderSelector 
                                isClientFolder={false}
                                noCycleSelect={false}
                                incomingSelectedFolder={clientFolders}
                                folderSelected={(f: Folder_t) => folderSelected(f)}/>
                            {/*<AssignModalClientFolders 
                                client={selectedClient}
                                clientFolders={clientFolders}
                                folderSelected={(f: Folder_t) => { setSelectedClientFolder(f); setErrorString("") }}
                                hideTitle={true}
                                backButtonPressed={() => null}/>*/}
                        </div>
                    </div>
                </div>
                <div hidden={!isLoading} className="copy-folder-to-client-modal-body copy-folder-to-client-modal-body-loading">
                    <div className="client-list-container-loading-spinner"/>
                    <p>Duplicating Phase...</p>
                </div>
                <div className="copy-folder-modal-button-container">
                    <div hidden={isLoading} onClick={() => submit()} className={`copy-folder-modal-button ${selectedFolder === undefined || selectedFolder.id === "" ? 'copy-folder-modal-button-unselected' : ''}`}>
                        <p>Duplicate Phase</p>
                    </div>
                </div>

            </div>
			
		</div>
	)
}

export default DuplicatePhase;