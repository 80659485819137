import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import '../../Modals/CreateWorkoutExerciseConfigure.css';
import './CreatePlanWorkoutConfigure.css'
//import axios from 'axios';
import firebase from '../../../Firebase';

import Workout_t from '../../../Interfaces/Workout_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Tempo_t from '../../../Interfaces/Tempo_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';
import Muscles_t from '../../../Interfaces/Muscles_t';
import Equipment_t from '../../../Interfaces/Equipment_t';

import CreatePlanWorkoutConfigureCell from './CreatePlanWorkoutConfigureCell';
import Selector from '../../Base/Selector';
import FilterSingle from '../../Base/FilterSingle';
import FormTextInput from '../../Base/FormTextInput';
import CreateWorkoutSearchListCell from '../../Workouts/WorkoutBuilder/CreateWorkoutSearchListCell';
import StandardButton from '../../Base/StandardButton';

import '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import DynamicFeed from '@material-ui/icons/DynamicFeed';

function CreatePlanWorkoutConfigure(props: any) {

    let muscle_groups = ["All","Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [searchStringError, setSearchStringError] = useState(false);
    const [searchState, setSearchState] = useState(0); // 0 = no search, 1 = loading, 2 = display results, 3 = no results found
    const [searchString, setSearchString] = useState('');
    const [searchGroup, setSearchGroup] = useState(0);
    //const [searchResults, setSearchResults] = useState<Exercise_t[]>([]);
    //const [selectedExercise, setSelectedExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
    const [allWorkouts, setAllWorkouts] = useState<Workout_t[]>([]);
    const [showingWorkouts, setShowingWorkouts] = useState<Workout_t[]>([]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getPersonalWorkouts();
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

  //   function pullAllWorkouts() {
  //   	// Step 1: pull list of trainer's workout IDs
  //   	let currentUser = firebase.auth().currentUser;
		// if (!currentUser) { return; }
		// let trainerUID = currentUser.uid;

		// setIsLoading(true);

		// let database = firebase.database();
		// let ref = database.ref(`personal_trainer_workouts/${trainerUID}`);
		// ref.once('value', function(snapshot: any) {
		//     if (snapshot.exists() === true) {
		//         let data = snapshot.val();
		        
		//         if (data.length > 0) {
		//         	for (var i = 0; i < data.length; i++) {
		// 	        	pullWorkoutWithID(data[i]);
		// 	        }
		//         } else {
		//         	let dataKeys = Object.keys(data);
		//         	for (var i = 0; i < dataKeys.length; i++) {
		// 	        	pullWorkoutWithID(data[dataKeys[i]]);
		// 	        }
		//         }
		        
		        
		//     } else {
		//     	setIsLoading(false);
		//     }
		// });
  //   }

  //   function pullWorkoutWithID(id: string) {
  //   	let database = firebase.database();
		// let ref = database.ref(`workouts/${id}`);
		// ref.once('value', function(snapshot: any) {
		//     if (snapshot.exists() === true) {
		//         let data = snapshot.val();
		//         // console.log(data[0]);
		        
		        
		//     } else {
		//     	setIsLoading(false);
		//     }
		// });
  //   }

  	function getPersonalWorkouts() {
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_workouts/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				// Currently logged in trainer has previously created workouts
				let workoutList: string[] = snapshot.val();

				let workoutListLength = workoutList.length;
				//var temp: Workout_t[] = [];
				var temp: any = {};
				for (var i = 0; i < workoutListLength; i++) {
					let workoutID = workoutList[i];
					let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
					thisWorkoutRef.on('value', function(snapshotWorkout) {
						if (snapshotWorkout.exists() === true) {
							let thisWorkout = snapshotWorkout.val();

							temp[workoutID] = thisWorkout;
							let keys = Object.keys(temp);
							if (keys.length === workoutListLength) {
								// all workouts have been pulled - set the state vars to temp
								parsePersonalWorkouts(temp);
							}
						}
					});
				}
			} else {
				// No workouts have been created for the currently logged in user
				//setWorkoutsBlank(true);
				setIsLoading(false);
			}
		});
	}

 //    async function parsePersonalWorkouts(workoutList: any) {

	// 	var temp: Workout_t[] = [];
	// 	for (const key of Object.keys(workoutList)) {
	// 		let thisWorkout = workoutList[key];

	// 		let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

	// 		var exerciseList: Exercise_t[] = [];
	// 		if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

	// 			var exerciseReps: any = {};
	// 			for (var i = 0; i < thisWorkout.exercises.length; i++) {
	// 				var a: number[] = [];
	// 				if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
	// 					for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
	// 						let thisRep = thisWorkout.exercises[i].reps[j];
	// 						a.push(thisRep);
							
	// 					}
	// 				}
	// 				exerciseReps[thisWorkout.exercises[i].key] = a;
	// 			}

	// 			let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps);
	// 			exerciseList = e;
	// 		}

	// 		let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
	// 		let newWorkout: Workout_t = {
	// 			title: thisWorkout.title,
	// 			description: thisWorkout.description !== undefined ? thisWorkout.description: '',
	// 			id: key,
	// 			createdBy: createdBy,
	// 			exercises: exerciseList,
	// 			duration: Number(thisWorkout.duration),
	// 			imageName: thisWorkout.image_name,
	// 			targetGender: thisWorkout.target_gender,
	// 			totalSets: Number(thisWorkout.total_sets),
	// 			totalReps: Number(thisWorkout.total_reps),
	// 			difficulty: Number(thisWorkout.difficulty),
	// 			goals: thisWorkout.goals,
	// 			downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
	// 			timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
	// 		};
	// 		temp.push(newWorkout);
	// 	}

	// 	setShowingWorkouts(showingWorkouts.concat(temp));
	// 	setAllWorkouts(allWorkouts.concat(temp));
	// 	setIsLoading(false);
	// }

    async function parsePersonalWorkouts(workoutList: any) {

        var temp: Workout_t[] = [];
        for (const key of Object.keys(workoutList)) {
            let thisWorkout = workoutList[key];

            let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

            // Configure Exercise Series
            var seriesList_temp: ExerciseSeries_t[] = [];
            let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
            if (containsExerciseSeries === true) {
                for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                    let thisSeriesObj = thisWorkout.exercise_series[i];
                    let thisSetLetter = getSetLetter(i);

                    var exerciseList_temp: Exercise_t[] = [];
                    if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                        for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                            let thisExerciseObj = thisSeriesObj.exercises[j];
                            let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                            exerciseList_temp.push(exTemp);
                        }
                    }

                    var seriesTemp: ExerciseSeries_t = {
                        title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                        index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                        type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                        exercises: exerciseList_temp
                    };

                    seriesList_temp.push(seriesTemp);
                }
            } else {
                // Workout was created "pre-series". Create a new placeholder series for each existing exercise
                if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        let thisExerciseTemp: any = thisWorkout.exercises[i];
                        let thisSetLetter = getSetLetter(i);
                        var seriesTemp: ExerciseSeries_t = {
                            title: thisSetLetter,
                            index: i,
                            type: 0,
                            exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                        };
                        seriesList_temp.push(seriesTemp);
                    }
                }
            }

            var exerciseList: Exercise_t[] = [];
            if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

                var exerciseReps: any = {};
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    var a: number[] = [];
                    if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                        for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                            let thisRep = thisWorkout.exercises[i].reps[j];
                            a.push(thisRep);
                            
                        }
                    }
                    exerciseReps[thisWorkout.exercises[i].key] = a;
                }

                var exerciseTempos: any = {};
                for (var i = 0; i < thisWorkout.exercises.length; i++) {
                    var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                    if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                        for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                            let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                            let thisTempo: Tempo_t = {
                                concentric: thisTempoObj.concentric,
                                eccentric: thisTempoObj.eccentric,
                                first_pause: thisTempoObj.first_pause,
                                second_pause: thisTempoObj.second_pause
                            };
                            b.push(thisTempo);
                        }
                    }
                    exerciseTempos[thisWorkout.exercises[i].key] = b;
                }

                let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
                exerciseList = e;


                // push exercises into according slots in seriesList_temp
                if (exerciseList.length > 0) {
                    for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                        let thisExercise: Exercise_t = exerciseList[ex_index];
                        let thisExercise_ID = thisExercise.id;

                        // Loop through seriesList_temp to find thisExercise's matching location
                        if (seriesList_temp.length > 0) {
                            for (var i = 0; i < seriesList_temp.length; i++) {
                                let thisSeries = seriesList_temp[i];
                                if (thisSeries.exercises.length > 0) {
                                    for (var j = 0; j < thisSeries.exercises.length; j++) {
                                        let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                        let thisSeriesExercise_ID = thisSeriesExercise.id;
                                        if (thisSeriesExercise_ID === thisExercise_ID) {
                                            // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                            // for the case of multiple of the same exercises in a workout
                                            if (thisSeriesExercise.title === '') {
                                                seriesList_temp[i].exercises[j] = thisExercise;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
            let newWorkout: Workout_t = {
                title: thisWorkout.title,
                description: thisWorkout.description !== undefined ? thisWorkout.description: '',
                id: key,
                createdBy: createdBy,
                exercises: exerciseList,
                exerciseSeries: seriesList_temp,
                duration: Number(thisWorkout.duration),
                imageName: thisWorkout.image_name,
                targetGender: thisWorkout.target_gender,
                totalSets: Number(thisWorkout.total_sets),
                totalReps: Number(thisWorkout.total_reps),
                difficulty: Number(thisWorkout.difficulty),
                goals: thisWorkout.goals,
                downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
                timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
            };
            temp.push(newWorkout);
        }

        // setWorkouts(workouts.concat(temp));
        // setAllWorkouts(allWorkouts.concat(temp));
        // setIsLoading(false);

        setShowingWorkouts(showingWorkouts.concat(temp));
        setAllWorkouts(allWorkouts.concat(temp));
        setIsLoading(false);
    }

	// async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[]) {
	// 	return new Promise<Exercise_t[]>(resolve => {
	// 		let database = firebase.database();
	// 		var completedIDs = [];
	// 		var exerciseList: Exercise_t[] = [];

	// 		for (var i = 0; i < exercises.length; i++) {
	// 			let thisExercise = exercises[i];
	// 			let thisExerciseKey = thisExercise.key;
	// 			let thisExerciseReps = repBreakdown[thisExerciseKey];

	// 			let ref = database.ref(`exercises/${thisExerciseKey}`) 
	// 			ref.on('value', function(snapshot) {
	// 				if (snapshot.exists() === true) {
	// 					let key = snapshot.key;
	// 					let thisExercise = snapshot.val();
	// 					completedIDs.push(thisExercise.title);
						
	// 					if (thisExercise !== null) {

	// 						let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
	// 						let newExercise: Exercise_t = {
	// 							title:thisExercise.title,
	// 							id: key !== null && key !== undefined ? key : "",
	// 							type: Number(thisExercise.type),
	// 							muscles:thisExercise.muscles,
	// 							equipment: thisExercise.equipment,
	// 							setup: thisExercise.setup,
	// 							steps: thisExercise.steps,
	// 							createdBy: thisExercise.created_by,
	// 							restTime: [thisRestTime],
	// 							reps: thisExerciseReps
	// 						}

	// 						if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
	// 							// Motion info data exists - assign to thisMotionInfo

	// 							var thisMotionInfo = thisExercise.motion_info;
	// 							newExercise.motionInfo = thisMotionInfo;
	// 						}

	// 						exerciseList.push(newExercise);
	// 					}

	// 					if (completedIDs.length >= exercises.length) {
	// 						resolve(exerciseList);
	// 					}
	// 				}
	// 			});
	// 		}
	// 	});
	// }

    async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: [thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }



    function backButtonPressed() {
        if (workflowState === 0) {
            props.backToWorkoutList(null);
            setSearchStringError(false);
            //setSearchResults([]);
            setSearchState(0);
        } else if (workflowState === 1) {
            setWorkflowState(0);
        }
    }

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'muscle-group':
                let indexOfGroup = muscle_groups.indexOf(toString);
                if (indexOfGroup >= 0) {
                    setSearchGroup(indexOfGroup);
                    searchExercise(searchString, indexOfGroup);
                }
                break;
            case 'exercise-name':
                setSearchString(toString);
                if (searchStringError === true && toString.split('').length > 2) {
                    setSearchStringError(false);
                    
                }
                searchExercise(toString, searchGroup);
                break;
            default:
                break;
        }
    }

    function searchExercise(withString: string, muscleGroup: number) {
        setShowingWorkouts(allWorkouts.filter((item: Workout_t, index: number) => {

            var muscleMatch = false;

            if (muscleGroup === 0) {
                muscleMatch = true;
            } else {
                let exercises: Exercise_t[] = item.exercises !== undefined ? item.exercises : [];
                for (var i = 0; i < exercises.length; i++) {
                    let thisExercise: Exercise_t = exercises[i];
                    let muscles: Muscles_t[] = thisExercise.muscles !== undefined ? thisExercise.muscles : [];
                    for (var j = 0; j < muscles.length; j++) {
                        let thisMuscle: Muscles_t = muscles[j];
                        if (thisMuscle.group === muscleGroup - 1) { muscleMatch = true; }
                    }
                }
            }
            

            var stringMatch = item.title.includes(withString);
            stringMatch = searchString.split('').length <= 2 ? true : stringMatch;

            return stringMatch && muscleMatch;
            
        }))
    }

    function searchButtonPressed() {
        searchExercise(searchString, searchGroup);
        // // console.log(`Searching in ${muscle_groups[searchGroup]} for ${searchString}`);
        // let searchCriteriaPassed = searchString.split('').length > 2;
        // setSearchStringError(!searchCriteriaPassed);
        // if (searchCriteriaPassed === true) {
        //     // Hit search API
        //     setSearchState(1);
        //     //searchExercises();
        // }
    }

    function workoutSelected(workout: Workout_t) {
    	if (props.backToWorkoutList !== undefined) {
    		props.backToWorkoutList(workout);
    	}
    }

    // function searchExercises() {
    //     let sendData = {
    //         text: searchString,
    //         muscle_group: searchGroup
    //     };
    //     axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/searchExercises`,
    //         sendData,
    //         { headers: {'Content-Type': 'application/json'} })
    //     .then(response => {
    //         // console.log(response.data);
    //         setSearchState(2);
    //         let responseData = response.data;
    //         let responseExerciseArray: any[] = responseData.resp;
    //         if (responseExerciseArray.length === 0) {
    //             // No exercises found for search. Present no results found page (state 3)
    //             setSearchState(3);
    //         } else {
    //             var tempExerciseArray: Exercise_t[] = [];
    //             for (var i = 0; i < responseExerciseArray.length; i++) {
    //                 let thisExerciseObj = responseExerciseArray[i];
    //                 let thisExerciseMuscles: Muscles_t[] = [];

    //                 if (thisExerciseObj.muscles !== null && thisExerciseObj.muscles !== undefined) {

    //                     for (var j = 0; j < thisExerciseObj.muscles.length; j++) {
    //                         let thisMuscle = thisExerciseObj.muscles[j];
    //                         let newMuscle: Muscles_t = {
    //                             group: Number(thisMuscle.group),
    //                             muscle: Number(thisMuscle.muscle)
    //                         }
    //                         thisExerciseMuscles.push(newMuscle);
    //                     }
    //                 }

    //                 let newExercise: Exercise_t = {
    //                     title: thisExerciseObj.title,
    //                     id: thisExerciseObj.id,
    //                     createdBy: {name: thisExerciseObj.created_by.name, id: thisExerciseObj.created_by.id},
    //                     muscles: thisExerciseMuscles,
    //                     type: thisExerciseObj.type,
    //                     equipment: thisExerciseObj.equipment,
    //                     tags: thisExerciseObj.tags,
    //                     setup: thisExerciseObj.setup,
    //                     steps: thisExerciseObj.steps,
    //                 }
    //                 tempExerciseArray.push(newExercise);
    //             }
    //             setSearchResults(tempExerciseArray);
    //         }
    //     })
    //     .catch(e => {
    //         // console.log(e);
    //         setSearchState(3);
    //     })
    // }

    // function exerciseSelected(e: Exercise_t) {
    //     // console.log(`${e.title} selected`);
    //     setSelectedExercise(e);
    //     setWorkflowState(1);
    // }

    // function toggleUseRestTime() {
    //     setUseDefaultRestTime(!useDefaultRestTime);
    // }

    // function addAnotherSet() {
    //     let addReps = reps.length > 0 ? reps[reps.length - 1] : 16;
    //     let addRest = rest.length > 0 ? rest[rest.length - 1] : defaultRestTime;
    //     setReps(reps.concat(addReps));
    //     setRest(rest.concat(addRest));
    // }

    // function setRestTime(toTime: any) {
    //     // console.log(`Setting rest time to: ${toTime}s`);
    //     setDefaultRestTime(Number(toTime));
    // }

    // function changeSets(toSets: any, atIndex: number) {
    //     // console.log(`Changing set ${atIndex + 1} to ${toSets} sets`);
    //     setReps(
    //         reps.map((item, index) => {
    //             return index === atIndex ? Number(toSets) : item;
    //         })
    //     );
    // }

    // function changeRest(toRest: any, atIndex: number) {
    //     // console.log(`Changing rest ${atIndex + 1} to ${toRest}s`);
    //     setRest(
    //         rest.map((item, index) => {
    //             return index === atIndex ? Number(toRest) : item;
    //         })
    //     );
    // }

    // function removeSet(atIndex: number) {
    //     setReps(reps.filter((item, index) => index !== atIndex));
    //     setRest(rest.filter((item, index) => index !== atIndex));
    // }

    // function finishPressed() {
    //     var restTimes: number[] = [];
    //     for (var i = 0; i < rest.length; i++) {
    //         restTimes.push(useDefaultRestTime ? rest[i] : -1);
    //     }
    //     var finalExercise: Exercise_t = selectedExercise;
    //     finalExercise.reps = reps;
    //     finalExercise.restTime = restTimes;

    //     // console.log(finalExercise);
    //     props.backToWorkoutList(finalExercise);

    //     setSearchStringError(false);
    //     setSearchResults([]);
    //     setSearchState(0);
    //     setWorkflowState(0);
    //     setRest([30]);
    //     setReps([16]);
    //     setDefaultRestTime(30);
    //     setSelectedExercise({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
    // }

	return (
		<div className="create-workout-exercise-configure-container">
            <div className="create-workout-exercise-configure-header-container">
                <p className="create-workout-exercise-configure-header-back-text" onClick={() => backButtonPressed()}>&larr; Back to added Workouts</p>
                <h1>Add a saved Workout</h1>
            </div>

			<div hidden={true} className="create-workout-exercise-configure-header-container">
                <div hidden={true} className="new-plan-header-icon-container">
                    <DynamicFeed className="new-plan-header-icon"/>
                </div>
                <div hidden={true} className="create-workout-exercise-configure-header-content">
                    <p className="create-workout-exercise-configure-header-back-text" onClick={() => backButtonPressed()}>&larr; {workflowState === 0 ? "Back to workout builder" : "Back to exercise selector"}</p>
                    <h1>Add a saved Workout</h1>
                </div>
                <div hidden={true} className="new-client-header-close-button-container">
                    <div onClick={() => props.closeModal()} className="new-client-header-close-button">
                        <Close className="new-client-header-close-button-close-icon"/>
                    </div>
                </div>
            </div>
            <div className="create-workout-exercise-configure-content-container">
                <div className="create-workout-exercise-configure-search-container">
                    <div className="create-workout-exercise-configure-search-header-container">
                        <p>Select a saved Workout below to add to your Plan. To find a specific Workout, search by name and filter by muscle group</p>
                    </div>
                    
                    <div className="create-workout-exercise-configure-muscle-search-content create-plan-workout-configure-muscle-search-content">
                        <FormTextInput 
                            placeholder="Workout Name" 
                            alert={searchStringError} 
                            isSearch={true}
                            alertText="Please enter an exercise name"
                            inputChanged={(e: string) => inputChanged(e, 'exercise-name')}/>
                    </div>
                    <div className="create-workout-exercise-configure-muscle-seletor-content create-plan-workout-configure-muscle-seletor-content">
                        <FilterSingle 
                            placeholder="Muscle Group" 
                            alert={false} 
                            options={muscle_groups}
                            showTitle={false} 
                            id="create-plan-workout-configure"
                            inputChanged={(e: string) => inputChanged(e, 'muscle-group')}/>
                    </div>
                    <div onClick={() => searchButtonPressed()} className="create-workout-exercise-configure-search-button create-plan-workout-configure-search-button">
                        <Search className="create-workout-exercise-configure-search-icon" style={{ fontSize: 18 }}/>
                        <p>Search</p>
                    </div>
                </div>
                <div hidden={!isLoading} className="create-workout-exercise-configure-results-container">
                    <div className="create-workout-exercise-configure-results-blank-content">Loading your workouts...</div>
                </div>
                <div hidden={isLoading || showingWorkouts.length === 0} className="create-plan-workout-configure-results-container">
                    <div className="create-workout-exercise-configure-results-header-container">
                        <p className="create-workout-exercise-configure-results-header-text-light">Select an exercise below to configure reps and rest time</p>
                        <p className="create-workout-exercise-configure-results-header-text-dark">Tap an exercise to select</p>
                    </div>
                    <div className="create-workout-exercise-configure-results-header-row-container">
                        <div className="create-workout-exercise-configure-results-header-col-container create-workout-exercise-configure-results-header-col-container-image-spacer">
                    
                        </div>
                        <div className="create-workout-exercise-configure-results-header-col-container create-workout-exercise-configure-results-header-col-container-large">
                            <p>NAME</p>
                        </div>
                        <div className="create-workout-exercise-configure-results-header-col-container create-workout-exercise-configure-results-header-col-container-large">
                            <p>MUSCLE GROUPS</p>
                        </div>
                        <div className="create-workout-exercise-configure-results-header-col-container create-workout-exercise-configure-results-header-col-container-small">
                            <p>DURATION</p>
                        </div>
                        <div className="create-workout-exercise-configure-results-header-col-container create-workout-exercise-configure-results-header-col-container-small">
                            <p>EXECISES</p>
                        </div>
                    </div>
                    <div className="create-plan-workout-configure-results-scroller-container">
                        {showingWorkouts.map((item, index) => (
                            <CreatePlanWorkoutConfigureCell 
                            	workout={item}
                            	index={index}
                            	workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
                        ))}

                        <div onClick={() => props.createNewWorkoutFromPlan()} className="create-workout-exercise-configure-results-add-workout-button-container">
                            <div className="create-workout-exercise-configure-results-add-workout-button-text-container">
                                <h4>Can't find what you're looking for?</h4>
                                <p>Tap here to create a new Workout on the fly to add to your new Plan</p>
                            </div>
                            <div className="create-workout-exercise-configure-results-add-workout-button-add-icon-container">
                                <h5>+</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={isLoading || allWorkouts.length > 0} className="create-plan-workout-configure-results-container create-plan-workout-configure-results-empty-container">
                    <h4>No Workouts were found in your Workout Library</h4>
                    <p>It looks like you haven't created any workouts yet. Workout Plans are composed of Workouts, so before you continue you'll need to create some.<br/>Tap 'Create My First Workout' below to get started.</p>
                    <div onClick={() => props.createNewWorkoutFromPlan()} className="create-workout-exercise-list-empty-button-container create-plan-workout-configure-empty-button-container">
                        <p>Create my First Workout</p>
                    </div>
                </div>
                <div hidden={isLoading || showingWorkouts.length > 0 || allWorkouts.length === 0} className="create-plan-workout-configure-results-container create-plan-workout-configure-results-empty-container">
                    <h4>No Workouts matching '{searchString}' found in your Workout Library</h4>
                    <p>We couldn't find any workouts with names similar to '{searchString}'. If you're sure this Workout exists, double check the spelling and muscle filter selection.<br/>Tap 'Create' below to start building a new Workout.</p>
                    <div onClick={() => props.createNewWorkoutFromPlan()} className="create-workout-exercise-list-empty-button-container create-plan-workout-configure-empty-button-container">
                        <p>Create '{searchString}'</p>
                    </div>
                </div>
                <div hidden={searchState !== 3} className="create-workout-exercise-configure-results-container">
                    <div className="create-workout-exercise-configure-results-blank-content-light">No exercises match your search.</div>
                </div>
            </div>
            
		</div>
	)
}

/*

<CreateWorkoutSearchListCell
                            key={`${item.title}-${index}`}
                            exercise={item}
                            cellPressed={(e: Exercise_t) => exerciseSelected(e)}/>

<div hidden={workflowState !== 1} className="create-workout-exercise-configure-content-container">
                <div className="create-workout-exercise-configure-rest-select-container">
                    <div 
                        onClick={() => toggleUseRestTime()}
                        className={useDefaultRestTime ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}/>
                    <div className="create-workout-exercise-configure-rest-select-text-container">
                        <h4>Set rest time between sets</h4>
                        <p>Check to setup a mandatory rest time between sets</p>
                    </div>
                    <div hidden={!useDefaultRestTime} className="create-workout-exercise-configure-rest-input-container">
                        <IonInput value={defaultRestTime} 
                            placeholder="no"
                            inputmode="numeric"
                            clearOnEdit={false}
                            type="number"
                            onIonChange={e => setRestTime(e.detail.value!)}/>
                        <p>seconds</p>
                    </div>
                </div>
                <div>
                    <div className="create-workout-exercise-configure-results-header-container">
                        <p className="create-workout-exercise-configure-results-header-text-light">Set breakdown for this exercise</p>
                        <p className="create-workout-exercise-configure-results-header-text-dark">Press "Add new set" to configure another set</p>
                    </div>
                </div>
                <div className="create-workout-exercise-configure-set-list-container">
                    {reps.map((item, index) => (
                        <div key={index} className="create-workout-exercise-configure-set-cell">
                            <div className="create-workout-exercise-configure-set-cell-name-container">Set {index + 1}</div>
                            <div className="create-workout-exercise-configure-set-cell-sets-container">
                                <div className="create-workout-exercise-configure-set-cell-input-container">
                                    <IonInput value={item} 
                                        className="create-workout-exercise-configure-set-cell-input"
                                        placeholder="0"
                                        inputmode="numeric"
                                        clearOnEdit={false}
                                        type="number"
                                        onIonChange={e => changeSets(e.detail.value!, index)}/>
                                    <p>reps</p>
                                </div>
                            </div>
                            <div className="create-workout-exercise-configure-set-cell-sets-container">
                                <div hidden={!useDefaultRestTime} className="create-workout-exercise-configure-set-cell-input-container">
                                    <IonInput value={rest[index]} 
                                        className="create-workout-exercise-configure-set-cell-input"
                                        placeholder="0"
                                        inputmode="numeric"
                                        clearOnEdit={false}
                                        type="number"
                                        onIonChange={e => changeRest(e.detail.value!, index)}/>
                                    <p>s rest</p>
                                </div>
                            </div>
                            <div onClick={() => removeSet(index)} className="create-workout-exercise-configure-set-cell-remove-container">
                                <Close className="create-workout-exercise-configure-set-cell-remove-icon" style={{ fontSize: 18 }}/>
                            </div>
                        </div>
                    ))}
                    <div onClick={() => addAnotherSet()} className="create-workout-exercise-configure-new-exercise-container">
                        <p>+ Add New Set</p>
                    </div>
                </div>
                <div hidden={reps.length === 0} className="create-workout-exercise-configure-finish-button-container">
                    <div onClick={() => finishPressed()} className="create-workout-exercise-configure-finish-button">Finish and add exercise</div>
                </div>
            </div>
*/

export default CreatePlanWorkoutConfigure;