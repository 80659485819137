import React, { useState } from 'react';
import './Notifications.css';

import AnimatedModel from '../IntraWorkout/IntraWorkoutElements/AnimatedModel';

function Notifications(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="notifications-container">
			<div className="notifications-blank-page-content">
                <h4>No notifications</h4>
                <p>Looks like there's no notifications for you at the moment.<br/>Check back to view updates on your workouts, clients and conversations.</p>
            </div>
            <div className="notifications-blank-page-content">
                
            </div>
		</div>
	)
}

export default Notifications;