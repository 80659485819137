import firebase from './Firebase';
import { BLE } from '@ionic-native/ble';
import Peripheral_t from './Interfaces/Peripheral_t';
import MotionData_t from './Interfaces/MotionData_t';
import RepStats_t from './Interfaces/RepStats_t';
import MotionInfo_t from './Interfaces/MotionInfo_t';

import NodeManager from './NodeManager';

// enum SendNodeType {
//     setColorPurple = 0x01,
// 	setColorCustom = 0x02,
// 	setColorYellow = 0x03,
// 	getTemperature = 0x04,
// 	getAccel = 0x05,
// 	getGyro = 0x06,
// 	alertSet_quick = 0x08,
// 	alertSet = 0x09,
// 	setColorGreen = 0x0A,
// 	getIntegratedAngle = 0x0B,
// 	getQuaternion = 0x0C,
// 	getQuaternionAndAccel = 0x0D,
// 	getUnitVectors = 0x10,
// 	startTracking = 0x11,
// 	endTracking = 0x12,
// 	tap_allow = 0x13,
// 	tap_end = 0x14,
// 	request_battery = 0x20,
// 	request_battery_mv = 0x21,
// 	gyro_test = 0x22,
// 	set_gyro_offset = 0x23
// }



enum SendNodeType {
    // *--------------- Commands from user device to Node ---------------* //
    // General device information
    EF_NODE_UART_REQUEST_ID_DEVICE_SIN                        = 0x00,
    EF_NODE_UART_REQUEST_ID_HW_VERSION                        = 0x01,
    EF_NODE_UART_REQUEST_ID_FW_VERSION                        = 0x02,
    EF_NODE_UART_REQUEST_ID_STATUS                            = 0x03,
    EF_NODE_UART_REQUEST_ID_BATTERY                           = 0x04,
    EF_NODE_UART_REQUEST_ID_GENERAL_TEST                      = 0x05,
    EF_NODE_UART_REQUEST_ID_CHARGING_STATE					  = 0x06,

    // Device commands (void)
    EF_NODE_UART_REQUEST_ID_SET_TRACK_MOTION_STATE            = 0x10,
    EF_NODE_UART_REQUEST_ID_SET_ALERT_STATUS                  = 0x11,
    EF_NODE_UART_REQUEST_ID_SET_LED_OFF                       = 0x12,
    EF_NODE_UART_REQUEST_ID_SET_LED_COLOUR                    = 0x13,
    EF_NODE_UART_REQUEST_ID_SET_TAP_STATUS                    = 0x14,
    EF_NODE_UART_REQUEST_ID_SET_SLEEP_STATUS                  = 0x15,            // SEE: ef_node_sleep_status_t
    EF_NODE_UART_REQUEST_ID_RUN_CALIBRATION                   = 0x16,
    EF_NODE_UART_REQUEST_ID_RUN_LED_SEQUENCE                  = 0x17,

    // Device configurations
    EF_NODE_UART_REQUEST_ID_CFG_IMU_PRESETS                   = 0x20,
    EF_NODE_UART_REQUEST_ID_CFG_MAX_LED_BRIGHTNESS            = 0X21,
    EF_NODE_UART_REQUEST_ID_CFG_EXERCISE                      = 0x22,
    EF_NODE_UART_REQUEST_ID_CFG_INJURY_LIMITS                 = 0X23,

    // Data requests (returns non-void)
    EF_NODE_UART_REQUEST_ID_GET_TEMPERATURE                   = 0x30,
    EF_NODE_UART_REQUEST_ID_GET_ACCELEROMETER                 = 0x31,
    EF_NODE_UART_REQUEST_ID_GET_GYROSCOPE                     = 0X32,
    EF_NODE_UART_REQUEST_ID_GET_MAGNOMETER                    = 0X33,
    EF_NODE_UART_REQUEST_ID_GET_QUATERNION                    = 0x34,
    EF_NODE_UART_REQUEST_ID_GET_INTEGRATED_ANGLE              = 0X35,
    EF_NODE_UART_REQUEST_ID_GET_ALL_MOTION                    = 0X36,
    EF_NODE_UART_REQUEST_ID_GET_MOTION_DATA_DUMP              = 0X37,

    // *--------- Unidirectional notices (Node -> user device) ----------* //
    EF_NODE_UART_REQUEST_ID_UPDATE_SYS_ALERT                  = 0X80,         // SEE: ef_system_status_t
    EF_NODE_UART_REQUEST_ID_UPDATE_INJURY_ALERT               = 0X81,
    EF_NODE_UART_REQUEST_ID_UPDATE_REP_STAGE                  = 0XAA,
    EF_NODE_UART_REQUEST_ID_DOWNLOAD_REP_MOTION               = 0XAB,
    EF_NODE_UART_REQUEST_ID_UPDATE_TAP_STATE                  = 0X83,
};

enum SendNodeLEDSequenceType {
	EF_NODE_RUN_LED_SEQUENCE_ID_START						  = 0x00,
	EF_NODE_RUN_LED_SEQUENCE_ID_PING						  = 0x01,
	EF_NODE_RUN_LED_SEQUENCE_ID_CONNECTED					  = 0x02,
	EF_NODE_RUN_LED_SEQUENCE_ID_DISCONNECTED				  = 0x03,
	EF_NODE_RUN_LED_SEQUENCE_ID_CALIBRATE				      = 0x04,
	EF_NODE_RUN_LED_SEQUENCE_ID_CALIBRATE_SUCCESS			  = 0x05,
	EF_NODE_RUN_LED_SEQUENCE_ID_CALIBRATE_FAILED			  = 0x06,
}

/**
 *	NodeInstance
 *	----------------------------------------------------------------------------------------------------
 *	@desc A class that contains all information pretaining to an individual Node, and handles all 
 *		  low-level Node tasks such as BLE connection, data retrieval and BLE disconnection.
 * 		  Create a NodeInstance object for every Node that is discovered and to be connected to.
 *
 */
class NodeInstance {
	private peripheral: Peripheral_t = {
		name: '',
		uuid: '',
		signal: 0,
		battery: 0,
		isConnected: false
	};

	private Nordic_UART_Service_UUID = "6E400001-B5A3-F393-E0A9-E50E24DCCA9E";
	private Nordic_UART_RX_Characteristic_UUID = "6E400003-B5A3-F393-E0A9-E50E24DCCA9E";
	private Nordic_UART_TX_Characteristic_UUID = "6E400002-B5A3-F393-E0A9-E50E24DCCA9E";
	private UART_data_retrieval_interval = 50;	// UART data interval in ms

	private Set_SIN = "00000";
	private Device_SIN = "0";

	private batteryLUT: number[] = [4010,3960,3930,3910,3870,3850,3840,3820,3800,3780,3770,3760,3750,3740,3730,3720,3710,3700,3690,3610,3300];
								// [4200, 4150, 4110, 4080, 4020, 3980, 3950, 3910, 3870, 3850, 3840, 3820, 3800, 3790, 3770, 3750, 3730, 3710, 3690, 3610, 3270];
	private lastBatteryCheckTime = 0;
	private deviceLocationIndex = -1;
	private shouldHoldGreen = false;

	//private lastRequestType: number = SendNodeType.getQuaternionAndAccel;
	private isStreaming = false;
	private isReconnecting = false;
	private isTapEnabled = false;
	private hasSubscribedToNotifications = false;

	private isTapped = false;
	private isHeld = false;
	private tapAndHoldTimer: any = null;

	private calibrationResults = {x: 0.0, y: 0.0, z: 0.0};
	private gyroCalibrationComplete: boolean = false;
	private lastCalibratedTime = 0;
	private MPUCalibrationComplete: boolean = false;
	private foundMPUError: boolean = false;

	private isCharging = false;

	private NodeManagerInstance: NodeManager;

	private requestTime_ms = 0;
	private responseTime_ms = 0;

	private signalStrengthCallback: any = null;
	private batteryCallback: any = null;
	private connectionCallback: any = null;
	private chargingCallback: any = null;
	private calibrationCallback: any = null;
	private nodeDumpCallback: any = null;
	private singleTapCallback: any = null;

	private isBatteryCheckTimerSet: boolean = false;
	private isSignalCheckTimerSet: boolean = false;

	private previousQuaternion: any = {w: 1.0, x: 0.0, y:0.0, z: 0.0};

	private motionInfo: MotionInfo_t = {
								leading_concentric: true,
							    analysis_vector: {x: 0, y: 1, z: 0},
							    analyze_vector: true,
							    body_weight_by_default: false,
							    weight_scaler: 1.0,
							    lever: {
							        segment_id: "forearm",
							        default_length: 0.35,
							    },
							    counting: {
							        type: "mirrored",
							        minimum_monitor_locations: [0, 1],
							        auxiliary_monitor_locations: [2, 3]
							    }
							};

	private currentRep: RepStats_t = {
		startTime: 0,
		power: 0,
		ROM: 0,
		ROM_neg: 0,
		ROM_pos: 0,
		formAccuracy: 0,
		tempo: {concentric: -1, first_pause: -1, eccentric: -1, second_pause: -1},
		stats_all_nodes: [],
		weight: 0,
		isKG: false,
		velocityStream: [],
		velocityStream_3d: [],
		peakVelocity: 0
	}

	private accelBuffer: any[] = [];

	constructor(props: Peripheral_t, managerInstance: NodeManager) {
		this.peripheral = props;
		//this.motionData_callback = motionData_callback;
		this.NodeManagerInstance = managerInstance;

		if (props.name !== undefined) {
			let splitName = props.name.split(':');
			let fullSINString = splitName[1];
			let splitSIN = fullSINString.split('.');
			let setSIN = splitSIN[0];
			let deviceSIN = splitSIN[1];

			this.Set_SIN = setSIN;
			this.Device_SIN = deviceSIN;

		}
		
	}

	public connect() {
		// console.log(`WILL CONNECT TO NODE ${this.Set_SIN}.${this.Device_SIN}`);
		BLE.connect(this.peripheral.uuid).subscribe(
			peripheral => this.connectedPeripheral("peripheral.id"),
			peripheral => this.disconnectedPeripheral("peripheral.id")
		);
		//BLE.autoConnect(this.peripheral.uuid, this.connectedPeripheral(this.peripheral.uuid), this.disconnectedPeripheral(this.peripheral.uuid));
	}

	public disconnect() {
		BLE.disconnect(this.peripheral.uuid)
		.then((resp: any) => {
			// console.log(`> NODE INSTANCE: DISCONNECTED FROM ${this.peripheral.uuid}.`, resp);
		});
		this.peripheral.isConnected = false;
	}

	private connectedPeripheral(id: string) {
		// console.log(`> NODE INSTANCE: CONNECTED to ${this.Set_SIN}.${this.Device_SIN} ${id} | ${this.peripheral.uuid}`)
		this.peripheral.isConnected = true;
		this.notifyNodeManagerOfUpdate();

		// // console.log(`CAL TEST: NODE INSTANCE: Node connected. ${this.foundMPUError}, ${this.MPUCalibrationComplete}`)
		// if (this.foundMPUError === true && this.MPUCalibrationComplete === true) {
		// 	// This Node has been power cycled - alert NodeManager
		// 	this.NodeManagerInstance.nodePowerCycledForRecalibration(this);

		// 	// console.log(`CAL TEST: NODE INSTANCE: Node was power cycled with previous failure`)

		// 	if (this.calibrationCallback !== undefined) {
		// 		// console.log(`CAL TEST: NODE INSTANCE: Node will set timeout for re-calibration`)
		// 		setTimeout(() => {
		// 			// console.log(`CAL TEST: NODE INSTANCE: Node WILL CALL TO RUN MPU TEST`)
		// 			this.runMPUTest();
		//     	}, 1000);
		// 	}
		// } else if (this.foundMPUError === false && this.MPUCalibrationComplete === true) {
		// 	// console.log(`CAL TEST: NODE INSTANCE: Node was power cycled with previous pass`)

		// 	if (this.calibrationCallback !== undefined) {
		// 		// console.log(`CAL TEST: NODE INSTANCE: Node will set timeout for re-calibration`)
		// 		setTimeout(() => {
		// 			// console.log(`CAL TEST: NODE INSTANCE: Node WILL CALL TO RUN MPU TEST`);
		// 			this.setLEDColour(false,true,true);
		// 			this.runMPUTest();
		//     	}, 1000);
		// 	}
		// }

		this.isSignalCheckTimerSet = false;
		this.isBatteryCheckTimerSet = false;
		this.hasSubscribedToNotifications = false;
		this.MPUCalibrationComplete = false;
		this.foundMPUError = false;
		this.shouldRunGyroTest = true;
		//this.gyroCalibrationComplete = false;

		if (this.connectionCallback !== null) {
			this.connectionCallback(true);
		}

		if (this.gyroCalibrationComplete === true) {
			this.setGyroOffsets();
		}

		this.NodeManagerInstance.nodeInstanceConnectionChanged(true);
		
		setTimeout(() => {    		
			this.subscribeToNodeNotifications();

			setTimeout(() => {
				this.requestBatteryPercentage();
				if (this.isStreaming === true) {
					this.isStreaming = false;
					this.startMotionDataStream();
				}
	    	}, 600);
    	}, 600);
	}

	private disconnectedPeripheral(id: string) {
		// console.log(`> NODE INSTANCE: DISOCNNECTED from ${this.Set_SIN}.${this.Device_SIN} ${id} | ${this.peripheral.uuid}`);
		this.notifyNodeManagerOfUpdate();

		this.peripheral.isConnected = false;
		this.isSignalCheckTimerSet = false;
		this.isBatteryCheckTimerSet = false;
		this.hasSubscribedToNotifications = false;

		if (this.connectionCallback !== null) {
			this.connectionCallback(false);
		}

		this.NodeManagerInstance.nodeInstanceConnectionChanged(false);
		setTimeout(() => {
			this.NodeManagerInstance.scanForNodes();
		}, 1500);	
	}

	public getUUID() {
		return this.peripheral.uuid;
	}

	public getPeripheral() {
		return this.peripheral;
	}

	public getIsConnected() {
		return this.peripheral.isConnected;
	}

	public getSetSIN() {
		return this.Set_SIN;
	}

	public getDeviceSIN() {
		return this.Device_SIN;
	}

	public getIsTapEnabled() {
		return this.isTapEnabled;
	}

	public getLocationIndex() {
		return this.deviceLocationIndex;
	}

	public getCalibrationState() {
		return this.gyroCalibrationComplete; //&& !this.foundMPUError && this.MPUCalibrationComplete;
	}

	public getPassedIMUTestState() {
		return !this.foundMPUError;
	}

	public getLocationString() {
		if (this.deviceLocationIndex === -1) { return "Unassigned Node"; }
		let nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];
		return nodeLocationNames[this.deviceLocationIndex];
	}

	public setLocationIndex(toLocationIndex: number) {
		// Set location
		// console.log("> NODE INSTANCE: setLocationIndex() | Setting to", toLocationIndex);
		this.deviceLocationIndex = toLocationIndex;
		this.shouldHoldGreen = true;
	}

	public setMotionInfo(mI: MotionInfo_t) {
		this.motionInfo = mI;
	}

	public setSignalStrengthCallback(tocallback: any) {
		this.signalStrengthCallback = tocallback;
	}

	public setBatteryCallback(tocallback: any) {
		this.batteryCallback = tocallback;
	}

	public setChargingCallback(tocallback: any) {
		this.chargingCallback = tocallback;
		this.chargingCallback(this.isCharging);
	}

	public setConnectionCallback(tocallback: any) {
		tocallback(this.peripheral.isConnected);
		this.connectionCallback = tocallback;
	}

	public setCalibrationCallback(tocallback: any) {
		//// console.log(`CAL TEST: NODE INSTANCE: Calibration Callback Set`)
		this.calibrationCallback = tocallback;
	}

	public setNodeDumpCallback(tocallback: any) {
		this.nodeDumpCallback = tocallback;
	}

	public requestBatteryPercentage() {
		//// console.log(`> NODE INSTANCE: Will request battery for ${this.Set_SIN}.${this.Device_SIN}`);

		if (this.batteryCallback !== null) {
			this.batteryCallback(this.peripheral.uuid, this.peripheral.battery);
		}

		let batteryUpdateDt = 10000;
		let thisTime = Date.now();

		if (thisTime - this.lastBatteryCheckTime >= batteryUpdateDt - 1) {
			setTimeout(() => {
				this.requestBatteryPercentage();
			}, 10000);
		} else {
			return;
		}

		this.lastBatteryCheckTime = thisTime;

		//if (this.isStreaming === true || this.isBatteryCheckTimerSet === true) { return; }
		if (this.peripheral.isConnected === false || this.isStreaming) { return; }
		if (this.hasSubscribedToNotifications === true) { this.isBatteryCheckTimerSet = true; }

		//// console.log(`REQUESTING BATTERY`)

		var data = new Uint8Array(1);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_BATTERY; //request_battery_mv;
		//this.lastRequestType = data[0];

		// console.log("NODE INSTANCE | requestBatteryPercentage");
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			
			//// console.log(`> NODE INSTANCE: Battery requested for ${this.Set_SIN}.${this.Device_SIN}`);
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR: ");
			//// console.log(error);
		})

		//if (this.getIsConnected()) {
			
		//}
	}

	private requestBatteryPercentage_loop() {
		//// console.log(`> NODE INSTANCE: In battery loop for ${this.Set_SIN}.${this.Device_SIN}`);
		var data = new Uint8Array(1);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_BATTERY; //request_battery_mv;
		//this.lastRequestType = data[0];

		// console.log("NODE INSTANCE | requestBatteryPercentage_loop");
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			//// console.log(`> NODE INSTANCE: Battery requested for ${this.Set_SIN}.${this.Device_SIN}`);
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})
	}

	private convertBatteryVoltage(mV: number) {
		var LUT_index = 0;
		for (var i = 0; i < this.batteryLUT.length - 1; i++) {
			let upperBracket = this.batteryLUT[i];
			let lowerBracket = this.batteryLUT[i + 1];
			if (mV <= upperBracket && mV >= lowerBracket) {
				LUT_index = i;
			}
		}
		let batteryPercentage = (20 - LUT_index) * 5;

		return batteryPercentage;
	}

	public requestSignalStrength() {

		if (this.isSignalCheckTimerSet === true) { return; }
		this.isSignalCheckTimerSet = true;

		BLE.readRSSI(this.peripheral.uuid)
		.then((rssi: any) => {
            if (this.signalStrengthCallback !== null) {
            	this.signalStrengthCallback(this.peripheral.uuid, rssi);
            	this.peripheral.signal = rssi;

            	setTimeout(() => {
					this.requestSignalStrength_loop();
				}, 10000);
            }
        }).catch((err: any) => {
        })
	}

	private requestSignalStrength_loop() {
		BLE.readRSSI(this.peripheral.uuid)
		.then((rssi: any) => {
            if (this.signalStrengthCallback !== null) {
            	this.signalStrengthCallback(this.peripheral.uuid, rssi);
            	this.peripheral.signal = rssi;
            	this.notifyNodeManagerOfUpdate();

            	setTimeout(() => {
					this.requestSignalStrength_loop();
				}, 10000);
            }
        }).catch((err: any) => {
        })
	}

	public allowTap() {
		// Check if isReconnecting flag is set to true - if true, exit function
		if (this.isReconnecting) { return; }

		// Set data byte array to length 1, and set send data type (see SendNodeType enum for all)
		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_SET_TAP_STATUS; //tap_allow;
		data[1] = 0x01;	// Enable tap byte

		// console.log("> NODE INSTANCE: entering touch mode");

		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			this.isTapEnabled = true;
			//this.setLEDColour(true,false,true);
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})
	}

	public endTap() {
		// Check if isReconnecting flag is set to true - if true, exit function
		if (this.isReconnecting) { return; }

		// Set data byte array to length 1, and set send data type (see SendNodeType enum for all)
		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_SET_TAP_STATUS; //tap_end;
		data[1] = 0x00;	// Disable tap byte

		// console.log("> NODE INSTANCE: exeting touch mode & entering power mode");

		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			this.isTapEnabled = false;
			//this.setDefaultColour();
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})
	}

	private setDefaultColour() {
		// console.log('> NODE INSTANCE: ERROR - setDefaultColour() called')
		// var data = new Uint8Array(1);
		// data[0] = SendNodeType.setColorPurple;

		// BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		// .then((response: any) => {
			
		// })
		// .catch((error: any) => {
		// 	//// console.log("> NODE INSTANCE: UART ERROR:");
		// 	//// console.log(error);
		// })
	}

	public setSyncedColour() {
		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_SET_LED_COLOUR;
		data[1] = 0x01;	// Steady green

		// console.log("NODE INSTANCE | setSyncedColour");
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})
	}

	private tapChangedFromNode(tappedState: boolean) {
		// console.log(`> NODE INSTANCE: TAP STATE CHANGED TO ${tappedState ? 'TAPPED' : 'RELEASED'} for ${this.Set_SIN}.${this.Device_SIN}`);

		// If the local instance of NodeManager exists, call the tapOccured method to indicate that a change
		// in the tap state of this Node has occured.
		
		if (this.NodeManagerInstance !== null && tappedState === true) {
			this.NodeManagerInstance.tapOccured(tappedState, this);
		}

		/*
		if (this.isHeld === true && tappedState === false) {
			// Tap and hold has ended. Check if should hold LEDs green
			if (this.shouldHoldGreen === true) {
				setTimeout(() => {
					this.isHeld = false;
					// Turn node green
					var data = new Uint8Array(1);
					data[0] = SendNodeType.setColorGreen;

					BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
					.then((response: any) => {
						
					})
					.catch((error: any) => {
						//// console.log("> NODE INSTANCE: UART ERROR:");
						//// console.log(error);
					})
		    	}, 5);
				
			}
		}

		// Run the timeout sequence to determine if 
		if (tappedState && !this.isTapped) {
			this.isTapped = true;

			this.tapAndHoldTimer = setTimeout(() => {
				if (this.isTapped) {
					// console.log(`> NODE INSTANCE: Tap and hold occured for Node ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`);
					this.isHeld = true;
					if (this.NodeManagerInstance !== null) {
						this.NodeManagerInstance.holdOccured(this);
					}
				}
	    	}, 1500);
		} else if (!tappedState) {
			this.isTapped = false;
			if (this.tapAndHoldTimer !== null) {
				clearTimeout(this.tapAndHoldTimer);
			}

			// Turn node back to purple
			var data = new Uint8Array(1);
			data[0] = SendNodeType.setColorPurple;

			BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
			.then((response: any) => {
				
			})
			.catch((error: any) => {
				//// console.log("> NODE INSTANCE: UART ERROR:");
				//// console.log(error);
			})
		}
		*/
	}

	public pingNode() {

		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_LED_SEQUENCE;
		data[1] = SendNodeLEDSequenceType.EF_NODE_RUN_LED_SEQUENCE_ID_PING;

		// console.log("NODE INSTANCE | ping");
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})
	}

	private setNodeColour(sendType: SendNodeType) {
		// var data = new Uint8Array(1);
		// data[0] = sendType;

		// BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		// .then((response: any) => {
			
		// })
		// .catch((error: any) => {
		// 	//// console.log("> NODE INSTANCE: UART ERROR:");
		// 	//// console.log(error);
		// })
	}



	private setRepWindows(roc_window: number, progression_window: number, min_rom_cutoff_window: number) {

		// console.log(`> NODE INSTANCE: Will set window parameters to r=${roc_window}, p=${progression_window}, m=${min_rom_cutoff_window} for Node ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`);

		let roc_window_temp = roc_window * 8192;
		let progression_window_temp = progression_window * 8192;
		let min_rom_cutoff_window_temp = min_rom_cutoff_window * 8192;

		let r = Math.floor(roc_window_temp);
		let p = Math.floor(progression_window_temp);
		let m = Math.floor(min_rom_cutoff_window_temp);

		var data = new Uint8Array(7);

		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_CFG_EXERCISE;
		data[1] = (r >> 8) & 0x00FF;
		data[2] = r & 0x00FF;
		data[3] = (p >> 8) & 0x00FF;
		data[4] = p & 0x00FF;
		data[5] = (m >> 8) & 0x00FF;
		data[6] = m & 0x00FF;

		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			//// console.log(`> NODE INSTANCE: Finished setting window parameters for Node ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`);
			this.beingMotionStreamAfterWindowSet();
		})
		.catch((error: any) => {
			this.beingMotionStreamAfterWindowSet();
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})

	}


	/**
	 *	startMotionDataStream
	 *  ----------------------------------------------------------------------------------------------------
	 *	
	 */
	public startMotionDataStream() {

		//this.setRepWindows(0.05, 0.3);
		//let theseFilterParams = this.motionInfo.filter_params;
		//this.setRepWindows(theseFilterParams.roc_window_size, theseFilterParams.progression_window_size, theseFilterParams.min_rom_window_size);
		this.setRepWindows(0.012, 0.008, 0.002);

		setTimeout(() => {
		    // Check if start streaming motion was called
		    if (this.isStreaming === true) {

		    } else {
		    	// console.log(`> NODE INSTANCE: ERROR FOR NODE ${this.Set_SIN}.${this.Device_SIN} | After 1s timeout, Node has not accepted window params. Will begin stream anyway`);
		    	this.beingMotionStreamAfterWindowSet();
		    }
		}, 1000);
		
	}

	private beingMotionStreamAfterWindowSet() {
		// console.log(`> NODE INSTANCE: Will begin stream for Node ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`);
		
		if (this.isStreaming === true) {
			// console.log(`> NODE INSTANCE: ERROR FOR NODE ${this.Set_SIN}.${this.Device_SIN} | device has already begun streaming motion.`);
			return;
		}
		var d = new Date();
  		var n = d.getTime();

		this.currentRep.startTime = n;
		this.isStreaming = true;
		this.sendMotionDataRequest();

		this.previousQuaternion = {w: 1.0, x: 0.0, y: 0.0, z: 0.0};

		if (this.NodeManagerInstance !== null) {
			let q = {w: 1.0,x: 0.0,y: 0.0,z: 0.0};
			let a = {x: 0.0,y: 0.0,z: 1.0};
			let motionData: MotionData_t = {quaternion: q, acceleration: a, timestamp: Date.now()};
			this.NodeManagerInstance.NodeMotionDataUpdated(motionData, this.peripheral, this.deviceLocationIndex);
		}
	}

	/**
	 *	endMotionDataStream
	 *  ----------------------------------------------------------------------------------------------------
	 *	
	 */
	public endMotionDataStream() {
		// console.log(`> NODE INSTANCE: Will end stream for Node ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`);
		this.isStreaming = false;

		if (this.deviceLocationIndex === 0) {
			// Print accel data to put into spreadsheet
			let x_list: any[] = [];
			let y_list: any[] = [];
			let z_list: any[] = [];

			for (var i = 0; i < this.accelBuffer.length; i++) {

				let thisBufferVector = this.accelBuffer[i];

				x_list.push(thisBufferVector.x);
				y_list.push(thisBufferVector.y);
				z_list.push(thisBufferVector.z);

			}
			// // console.log("_________________ ACCELEROMETER DATA __________________");
			// // console.log(x_list);
			// // console.log(y_list);
			// // console.log(z_list);
			// // console.log("______________ ACCELEROMETER DATA DONE ________________");

			
		}

		this.accelBuffer = [];
		

		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_SET_TRACK_MOTION_STATE; //SendNodeType.endTracking;
		data[1] = 0x00;

		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			//// console.log(`UART request sent:`, data[0]);			
			this.isStreaming = false;
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
			if (error === 'cordova_not_available') {
				this.isStreaming = false;
			}
		});

		setTimeout(() => {
			this.endQuaternionStream();
			this.requestBatteryPercentage();
		}, 500);
	}

	/**
	 *  sendMotionDataRequest
	 *  ----------------------------------------------------------------------------------------------------
	 *	Sends motion data request byte (getQuaternionAndAccel) to Node. Call once to begin motion data stream.
	 *  See 'subscribeToNodeNotifications' for handling the UART response from Node.
	 *	
	 *	This function is recursive (calls itself every UART_data_retrieval_interval ms); to end the loop,
	 *	set the isStreaming flag to false.
	 *
	 */
	private sendMotionDataRequest() {
		// Check if isStreaming flag is set to false - if false, exit function
		if (!this.isStreaming || this.isReconnecting) { return; }

		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_SET_TRACK_MOTION_STATE; //SendNodeType.startTracking;
		data[1] = 0x01;

		// console.log("NODE INSTANCE | sendMotionDataRequest");
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			//// console.log(`UART request sent:`, data[0]);
			var d = new Date();
  			var n = d.getTime();
  			this.requestTime_ms = n;
		})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
			if (error === 'cordova_not_available') {
				this.isStreaming = false;
			}
		})
	}







	private mpuTests = 0;
	private mpuFails = 0;
	private gyroTests = 0;

	private shouldRunGyroTest = true;

	public runGyroTest() {

		//// console.log(`> NODE INSTANCE  ${this.getDeviceSIN()}: RUN GYRO TEST. gyroCalibrationComplete=${this.gyroCalibrationComplete}`);

		// if (this.gyroCalibrationComplete === true) { 
		// 	this.MPUCalibrationComplete = false;
		// 	this.setLEDColour(false,true,true);
		// 	this.runMPUTest();
		// 	//this.NodeManagerInstance.gyroCalibrationCompleted(this.peripheral.uuid);
		// 	return; 
		// }

		// if (this.gyroTests === 0) {
		// 	this.setLEDColour(false,true,true);
		// }

		// this.lastRequestType = SendNodeType.gyro_test;

		// var data = new Uint8Array(1);
		// data[0] = SendNodeType.gyro_test; //SendNodeType.getQuaternionAndAccel;
		// BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		// .then((response: any) => {})
		// .catch((error: any) => {
		// 	//// console.log("> NODE INSTANCE: UART ERROR:");
		// 	//// console.log(error);
		// })

		//this.shouldRunGyroTest = true;
		
		// console.log("> NODE INSTANCE: runGyroTest");

		var dataB = new Uint8Array(1);
		dataB[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_CALIBRATION; //SendNodeType.getQuaternionAndAccel;
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, dataB.buffer)
		.then((response: any) => {})
		.catch((error: any) => {})
			
		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_LED_SEQUENCE; //SendNodeType.getQuaternionAndAccel;
		data[1] = SendNodeLEDSequenceType.EF_NODE_RUN_LED_SEQUENCE_ID_CALIBRATE;
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {
			

		})
		.catch((error: any) => {
			// console.log(`NODE INSTANCE: runGyroTest | ERROR: ${error}`);
			// console.log(error);
		})
	}

	public endGyroTest() { 
		// console.log(`> NODE INSTANCE: ENDING GYRO TEST for SIN  ${this.getDeviceSIN()}`);
		// this.gyroTests = this.peripheral.isConnected ? this.gyroTests : 0;
		this.shouldRunGyroTest = false;
		// this.gyroCalibrationComplete = this.peripheral.isConnected ? this.gyroCalibrationComplete : false;
		// this.MPUCalibrationComplete = this.peripheral.isConnected ? this.MPUCalibrationComplete : false;
		// this.setLEDColour(true,false,true);
	}

	public updateCalibrationResultsFromSaved(results: any) {
		// console.log(`> NODE INSTANCE: Updating Gyro Calibration data for ${this.getDeviceSIN()}`);
		let resultsTemp = {x: results.x, y: results.y, z: results.z};
		this.calibrationResults = resultsTemp;
		this.lastCalibratedTime = results.last_calibrated_time;

		this.gyroCalibrationComplete = true;

		// console.log(`> NODE INSTANCE: Updated Gyro Calibration data with | x: ${results.x}\t\ty: ${results.y}\t\tz: ${results.z}`);
	}

	public getLastCalibratedTime() {
		return this.lastCalibratedTime;
	}

	private saveGyroTestResults() {
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_nodes/${thisUser.uid}`);
		let thisUUID = this.peripheral.uuid;

		var d = new Date();
		var n = d.getTime();
		var time = Math.floor(n / 1000);

		let calData = {last_calibrated_time:time, x: this.calibrationResults.x, y: this.calibrationResults.y, z: this.calibrationResults.z};
		
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let savedUserNodes: any = snapshot.val();
				let listKeys: any[] = Object.keys(savedUserNodes);
				var associatedKey = '';
				for (var i = 0; i < listKeys.length; i++) {
					let thisKey = listKeys[i];
					if (savedUserNodes[thisKey].uuid === thisUUID) {
						associatedKey = thisKey;
					}
				}

				if (associatedKey !== '') {
					let thisUser = firebase.auth().currentUser;
					if (!thisUser) { return; }
					let refB = database.ref(`personal_trainer_nodes/${thisUser.uid}/${associatedKey}/calibration/gyro`);


					refB.set(calData);
				}
			}
		});
	}

	private runMPUTest() {

		// // console.log(`> NODE INSTANCE  ${this.getDeviceSIN()}: RUN MPU TEST. MPUCalibrationComplete=${this.MPUCalibrationComplete}, foundMPUError=${this.foundMPUError}`);

		// if (this.MPUCalibrationComplete === true && this.foundMPUError === false) {
		// 	this.completeCalibration();
		// }

		// this.MPUCalibrationComplete = false;
		// this.startMotionDataStream();
	}

	private processMPUTestResult(q: any) {

		// if (this.MPUCalibrationComplete === true) { 
		// 	this.completeCalibration();
		// 	return; 
		// }

		// let totalTests = 10;
		// this.mpuTests += 1;

		// let q_mag = Math.sqrt((q.w*q.w)+(q.x*q.x)+(q.y*q.y)+(q.z*q.z));

		// // console.log(`MPU TEST RESULT: location ${this.getLocationIndex()} has q_mag |${q_mag}| `);

		// if (q_mag < 0.8 || q_mag > 1.2) {
		// 	this.mpuFails += 1;
		// 	//// console.log(`> DATA ERROR #${this.mpuFails}!!! Magnitude for ${this.Device_SIN} is ${q_mag}`);
		// }

		// if (this.mpuTests >= totalTests) {
		// 	// Testing is complete - end data stream and determine pass or fail
		// 	this.endMotionDataStream();
		// 	this.MPUCalibrationComplete = true;

		// 	if (this.mpuFails >= 3) {
		// 		// Failed MPU test. Device must be reset
				
		// 		this.calibrationFailed();
		// 	} else {
		// 		// Passed MPU test! Complete calibration.
		// 		this.foundMPUError = false;
		// 		// console.log(`> NODE INSTANCE: TEST _PASSED_ FOR ${this.Set_SIN}.${this.Device_SIN} WITH ${this.mpuFails}/${this.mpuTests} FAILED IMU ATTEMPTS.`);
		// 		this.completeCalibration();
		// 	}
		// }

	}

	private processGyroTestData(data: Uint8Array) {

		//// console.log(`> NODE INSTANCE: processing gyro test data for ${this.getDeviceSIN()}`);

		let signedData = new Int16Array(8);
		let signedOffsetData = new Int16Array(8);


		signedData[0] = data[1] << 8;
  		signedData[1] = data[2];
  		signedData[2] = data[3] << 8;
  		signedData[3] = data[4];
  		signedData[4] = data[5] << 8;
  		signedData[5] = data[6];

  		signedOffsetData[0] = data[7] << 8;
  		signedOffsetData[1] = data[8];
  		signedOffsetData[2] = data[9] << 8;
  		signedOffsetData[3] = data[10];
  		signedOffsetData[4] = data[11] << 8;
  		signedOffsetData[5] = data[12];		

		let v = {
			x: (Number(signedData[0] | signedData[1]) / 16384.0),
			y: (Number(signedData[2] | signedData[3]) / 16384.0),
			z: (Number(signedData[4] | signedData[5]) / 16384.0)
		};

		let offsets = {
			x: (Number(signedOffsetData[0] | signedOffsetData[1]) / 16384.0),
			y: (Number(signedOffsetData[2] | signedOffsetData[3]) / 16384.0),
			z: (Number(signedOffsetData[4] | signedOffsetData[5]) / 16384.0)
		}

		// console.log(`> NODE INSTANCE ${this.Device_SIN}: Gyro test ${this.gyroTests} | x: ${v.x}\t\ty: ${v.y}\t\tz: ${v.z}`);

		this.calibrationResults = offsets;

		let maxMag = 0.04;
		if (Math.abs(v.x) < maxMag && Math.abs(v.y) < maxMag && Math.abs(v.z) < maxMag && this.gyroTests > 30) {
			
			this.completeCalibration();

		} else {
			// console.log(`> NODE INSTANCE ${this.Device_SIN}: SHOULD RUN GYRO TEST ${this.shouldRunGyroTest} `);
			if (this.gyroTests < 80 && this.shouldRunGyroTest === true) {
				setTimeout(() => {
				    if (this.shouldRunGyroTest === true) {
				    	this.runGyroTest();
				    } else {
				    	this.shouldRunGyroTest = true;
				    	this.runGyroTest();
				    }
				}, 400);
			} else {
				// console.log(`> NODE INSTANCE ${this.Device_SIN}: CALIBRATION FAILED | SHOULD RUN GYRO TEST ${this.shouldRunGyroTest} `);
				this.calibrationFailed();
			}
		}

		this.gyroTests += 1;
		//// console.log(`> NODE INSTANCE: GYRO OFFSET for  ${this.Set_SIN}.${this.Device_SIN}`);
		//// console.log(v);
		//// console.log(offsets);
	}

	private setGyroOffsets() {

		// let x_scaled = new Uint16Array(1);
		// let y_scaled = new Uint16Array(1);
		// let z_scaled = new Uint16Array(1);
		let x_scaled = new Int16Array(1);
		let y_scaled = new Int16Array(1);
		let z_scaled = new Int16Array(1);
		x_scaled[0] = this.calibrationResults.x * 16384.0;
		y_scaled[0] = this.calibrationResults.y * 16384.0;
		z_scaled[0] = this.calibrationResults.z * 16384.0;

		var data = new Int8Array(7);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_CFG_IMU_PRESETS;// set_gyro_offset; //SendNodeType.getQuaternionAndAccel;
		data[1] = x_scaled[0] >> 8;
		data[2] = x_scaled[0];
		data[3] = y_scaled[0] >> 8;
		data[4] = y_scaled[0];
		data[5] = z_scaled[0] >> 8;
		data[6] = z_scaled[0];

		//// console.log("SENDING THE FOLLOWING GYRO OFFSET DATA: ")
		// // console.log(data);
		// // console.log(x_scaled);
		// // console.log(y_scaled);
		// // console.log(z_scaled);
		// // console.log(this.calibrationResults);

		// console.log("NODE INSTANCE | setGyroOffsets");
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {})
		.catch((error: any) => {
			//// console.log("> NODE INSTANCE: UART ERROR:");
			//// console.log(error);
		})
	}

	private completeCalibration() {
		this.gyroTests = 0;
		this.mpuFails = 0;
		this.mpuTests = 0;
		
		this.gyroCalibrationComplete = true;
		// console.log(`> NODE INSTANCE: Gyro Test Complete for ${this.getDeviceSIN()} after ${this.gyroTests} tests.`);
		// console.log(`> NODE INSTANCE: Gyro test completed with offsets | x: ${this.calibrationResults.x}\t\ty: ${this.calibrationResults.y}\t\tz: ${this.calibrationResults.z}`);

		this.setGyroOffsets();
		this.saveGyroTestResults();

		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_LED_SEQUENCE; //SendNodeType.getQuaternionAndAccel;
		data[1] = SendNodeLEDSequenceType.EF_NODE_RUN_LED_SEQUENCE_ID_CALIBRATE_SUCCESS;
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {})
		.catch((error: any) => {})


		// console.log(`> NODE INSTANCE: CALIBRATION COMPLETE FOR ${this.getDeviceSIN()}`);
		this.NodeManagerInstance.gyroCalibrationCompleted(this.peripheral.uuid);

		if (this.calibrationCallback !== undefined && this.calibrationCallback !== null) {
			this.calibrationCallback(true, this.peripheral.uuid);
		}
	}

	private calibrationFailed() {
		// console.log(`> TEST _FAILED_ FOR ${this.Set_SIN}.${this.Device_SIN} WITH ${this.mpuFails}/${this.mpuTests} FAILED IMU ATTEMPTS.`);
		this.foundMPUError = true;
		this.mpuFails = 0;
		this.mpuTests = 0;
		this.gyroTests = 0;
		this.shouldRunGyroTest = true;
		
		var data = new Uint8Array(2);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_LED_SEQUENCE; //SendNodeType.getQuaternionAndAccel;
		data[1] = SendNodeLEDSequenceType.EF_NODE_RUN_LED_SEQUENCE_ID_CALIBRATE_FAILED;
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {})
		.catch((error: any) => {})

		this.NodeManagerInstance.gyroCalibrationFailed(this);

		if (this.calibrationCallback !== undefined && this.calibrationCallback !== null) {
			this.calibrationCallback(false, this.peripheral.uuid);
		}
	}





	private setLEDColour(red: boolean, green: boolean, blue: boolean) {
		// var data = new Uint8Array(4);
		// data[0] = SendNodeType.setColorCustom; //SendNodeType.getQuaternionAndAccel;
		// data[1] = red ? 0x01 : 0x00;
		// data[2] = green ? 0x01 : 0x00;
		// data[3] = blue ? 0x01 : 0x00;

		// BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		// .then((response: any) => {})
		// .catch((error: any) => {
		// 	//// console.log("> NODE INSTANCE: UART ERROR:");
		// 	//// console.log(error);
		// })
	}

	/**
	 *	processMotionData
	 *  ----------------------------------------------------------------------------------------------------
	 *	Handles incoming motion data from Node. Will parse the Uint8Array of bytes from Node and compose
	 *	quaternion and acceleration vectors for further processing.
	 *	@param data {Uint8Array}	byte array of Node motion data
	 */
	private processMotionData(data: Uint8Array) {

		// var d = new Date();
  // 		var n = d.getTime();
  // 		this.responseTime_ms = n;

  // 		let request_dt = this.responseTime_ms - this.requestTime_ms;

  // 		if (request_dt >= 30) {
  // 			//this.reconnectToNode();
  // 		}

  		//// console.log(`> NODE INSTANCE: Request time: ${request_dt} ms`);


  		let signedData = new Int16Array(15);

  		// signedData[0] = data[0] << 8;
  		// signedData[1] = data[1];
  		// signedData[2] = data[2] << 8;
  		// signedData[3] = data[3];
  		// signedData[4] = data[4] << 8;
  		// signedData[5] = data[5];
  		// signedData[6] = data[6] << 8;
  		// signedData[7] = data[7];

  		// signedData[8] = data[8] << 8;
  		// signedData[9] = data[9];
  		// signedData[10] = data[10] << 8;
  		// signedData[11] = data[11];
  		// signedData[12] = data[12] << 8;
  		// signedData[13] = data[13];

  		signedData[0] = data[1] << 8;
  		signedData[1] = data[2];
  		signedData[2] = data[3] << 8;
  		signedData[3] = data[4];
  		signedData[4] = data[5] << 8;
  		signedData[5] = data[6];
  		signedData[6] = data[7] << 8;
  		signedData[7] = data[8];

  		signedData[8] = data[9] << 8;
  		signedData[9] = data[10];
  		signedData[10] = data[11] << 8;
  		signedData[11] = data[12];
  		signedData[12] = data[13] << 8;
  		signedData[13] = data[14];

		let q = {
			w: (Number(signedData[0] | signedData[1])) / 16384.0,
			x: (Number(signedData[2] | signedData[3])) / 16384.0,
			y: (Number(signedData[4] | signedData[5])) / 16384.0,
			z: (Number(signedData[6] | signedData[7])) / 16384.0
		};

		// // console.log(`> _____ Data for ${this.Device_SIN} _____`);
		// // console.log(q);
		// // console.log(data);
		

		let filteredQ = {
			w: (q.w + this.previousQuaternion.w) / 2,
			x: (q.x + this.previousQuaternion.x) / 2,
			y: (q.y + this.previousQuaternion.y) / 2,
			z: (q.z + this.previousQuaternion.z) / 2
		}

		//// console.log(filteredQ);
		
		//w: ((signedData[0] << 8) | (signedData[1])) / 16384.0,
		//x: ((signedData[2] << 8) | (signedData[3])) / 16384.0,
		//y: ((signedData[4] << 8) | (signedData[5])) / 16384.0,
		//z: ((signedData[6] << 8) | (signedData[7])) / 16384.0
		let a = {
			x: (Number(signedData[8] | signedData[9])) / 16384.0, 
			y: (Number(signedData[10] | signedData[12])) / 16384.0,
			z: (Number(signedData[12] | signedData[13])) / 16384.0,
		};

		this.accelBuffer.push(a);
		let motionData: MotionData_t = {quaternion: filteredQ, acceleration: a, timestamp: Date.now()};
		
		this.NodeManagerInstance.NodeMotionDataUpdated(motionData, this.peripheral, this.deviceLocationIndex);
		//// console.log(motionData);

		this.previousQuaternion = filteredQ;

		/*

		if (this.MPUCalibrationComplete === true && this.foundMPUError === false) {
			// Data received is for further processing - MPU test has already been completed
			this.previousQuaternion = filteredQ;

			// If callback is not null, call it to pass motion data and this peripheral instance to NodeManager
			if (this.NodeManagerInstance !== null) {
				//// console.log(`------ NEW DATA FOR ${this.getDeviceSIN()} at location index ${this.deviceLocationIndex} ------- `);
				this.NodeManagerInstance.NodeMotionDataUpdated(motionData, this.peripheral, this.deviceLocationIndex);
			}
		} else if (this.MPUCalibrationComplete === false) {
			// 
			this.processMPUTestResult(q);
			
		}
		*/

		
	}








	
	private reconnectToNode() {
		this.isReconnecting = true;
		// console.log(`> NODE INSTANCE: Running reconnect sequence for ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`);
		BLE.disconnect(this.peripheral.uuid)
		.then((resp: any) => {
			BLE.autoConnect(this.peripheral.uuid, this.reconnectedToPeripheral(), {});
		});
	}

	private reconnectedToPeripheral() {
		// console.log(`> NODE INSTANCE: Reconnected to ${this.Set_SIN}.${this.Device_SIN} | ${this.peripheral.uuid}`)
		setTimeout(() => {
    		this.subscribeToNodeNotifications();
			this.isReconnecting = false;
			this.sendMotionDataRequest();
    	}, 1000);
		
	}
	
	private notifyNodeManagerOfUpdate() {
		//// console.log(`NODE INSTANCE: notifyNodeManagerOfUpdate`);
		if (this.NodeManagerInstance !== undefined) {
			//// console.log(`NODE INSTANCE: will call Node Manager fireNodeStatusHasUpdatedCallback`);
			this.NodeManagerInstance.fireNodeStatusHasUpdatedCallback();
		}
	}

	private downloadingNumPoints = 0;
	private readingsRetrieved = 0;

	private retrievedData: any[] = [];
	private dataPrinted: boolean = false;

	private processRepData(data: Uint8Array) {

		let unsignedData = new Uint16Array(1);

  		unsignedData[0] = data[14];
  		this.downloadingNumPoints = unsignedData[0];
  		this.readingsRetrieved = 0;
  		this.retrievedData = [];
  		this.dataPrinted = false;

  		var date = new Date();
  		var num = date.getTime();

		this.currentRep.startTime = num;
	}

	private processRepData_old(data: Uint8Array) {

		let signedData = new Uint16Array(8);
		let unsignedData = new Uint16Array(5);

		//// console.log(data);

		// first 2 bytes of data[] are 0xAA to indicate rep data is being sent
		signedData[0] = data[2];
  		signedData[1] = data[3];
  		signedData[2] = data[4];
  		signedData[3] = data[5];
  		signedData[4] = data[6];
  		signedData[5] = data[7];
  		signedData[6] = data[8];
  		signedData[7] = data[9];

  		// ROM positive
  		unsignedData[0] = data[10];
  		unsignedData[1] = data[11];
  		// ROM negative
  		unsignedData[2] = data[12];
  		unsignedData[3] = data[13];

  		// Number of stored data points that will be dumped
  		unsignedData[4] = data[14];
  		this.downloadingNumPoints = unsignedData[4];
  		this.readingsRetrieved = 0;
  		this.retrievedData = [];
  		this.dataPrinted = false;
  		// // console.log(`--------- NUM DOWNLOADING POINTS (from rep meta data): ${this.downloadingNumPoints} | ${unsignedData[4]}`)

  		let p = (((signedData[0] << 8) | signedData[1]) / 1000) / 0.4;
  		let u = (((signedData[2] << 8) | signedData[3]) / 1000) / 0.4;
  		let n = (((signedData[4] << 8) | signedData[5]) / 1000) / 0.4;
  		let d = (((signedData[6] << 8) | signedData[7]) / 1000) / 0.4;

  		let rom_pos = ((unsignedData[0] << 8) | unsignedData[1]) / 90;
  		let rom_neg = ((unsignedData[2] << 8) | unsignedData[3]) / 90;
  		let rom = rom_pos;// //(rom_pos + rom_neg) / 2; //

  		//this.currentRep.timing = {static_up: u, static_down: d, motion_rise: u, motion_fall: n };
  		this.currentRep.tempo = { first_pause: u, second_pause: d, concentric: p, eccentric: n };
  		this.currentRep.ROM = rom;
  		this.currentRep.ROM_neg = rom_neg;
  		this.currentRep.ROM_pos = rom_pos;

  		//// console.log(`------ REP COMPLETED FOR ${this.Set_SIN}.${this.Device_SIN} | ROM: ${Math.floor(rom * 10) / 10}º / ${Math.floor(rom / 70 * 100)}%\t|\tUP time: ${p}\t|\tU time: ${u}\t|\tN time: ${n}\t|\tD time: ${d}`);
  		// // console.log(`ROM POS: ${Math.floor(rom_pos * 100) / 100}º\t|\tROM NEG: ${Math.floor(rom_neg * 100) / 100}º\t|\tROM AVG: ${Math.floor(rom * 100) / 100}`)
  		//this.NodeManagerInstance.NodeRepDataUpdated(this.currentRep, this);
  		
  		var date = new Date();
  		var num = date.getTime();

		this.currentRep.startTime = num;

		//let p = (Double(((Int16)(data[3]) << 8) | (Int16)(data[2])) / 1000000) / 0.04
        //let u = (Double(((Int16)(data[5]) << 8) | (Int16)(data[4])) / 1000000) / 0.04
        //let n = (Double(((Int16)(data[7]) << 8) | (Int16)(data[6])) / 1000000) / 0.04
        //let d = (Double(((Int16)(data[9]) << 8) | (Int16)(data[8])) / 1000000) / 0.04

        //let rom = (Double(((Int16)(data[11]) << 8) | (Int16)(data[10])) / 90)
	}

	public requestDataDump() {
		// console.log("NODE INSTANCE | requestDataDump");
		var data = new Uint8Array(1);
		data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_GET_MOTION_DATA_DUMP; //SendNodeType.getQuaternionAndAccel;
		BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		.then((response: any) => {})
		.catch((error: any) => {})
	}

	private simulationStreamCount = 0;
	private dataBuffer: number[] = [];
	private dataBuffer_prev: number[] = [];
	private dataBuffer_toSend_length = 100;
	private dataBuffer_toSend: number[] = [];


	private q_normalize(q: {w: number, x: number, y: number, z: number}) 
	{
		var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};
	    const quaternionMagSq = Math.sqrt((q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z));
	    result.w = q.w / quaternionMagSq;
	    result.x = q.x / quaternionMagSq;
	    result.y = q.y / quaternionMagSq;
	    result.z = q.z / quaternionMagSq;

	    return result;
	}

	private q_invert(q: {w: number, x: number, y: number, z: number})
	{
	    var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};
	    const quaternionMagSq = (q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z);
	    result.w = q.w / quaternionMagSq;
	    result.x = -1 * q.x / quaternionMagSq;
	    result.y = -1 * q.y / quaternionMagSq;
	    result.z = -1 * q.z / quaternionMagSq;

	    return result;
	}

	private q_multiply(q: {w: number, x: number, y: number, z: number}, p: {w: number, x: number, y: number, z: number})
	{
	    var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};

	    result.w = q.w*p.w - q.x*p.x - q.y*p.y - q.z*p.z;
	    result.x = q.x*p.w + q.w*p.x - q.z*p.y + q.y*p.z;
	    result.y = q.y*p.w + q.z*p.x + q.w*p.y - q.x*p.z;
	    result.z = q.z*p.w - q.y*p.x + q.x*p.y + q.w*p.z;

	    return result;
	}

	private q_rotate(q: {w: number, x: number, y: number, z: number}, w: {w: number, x: number, y: number, z: number}) 
	{
	    var a_body: {w: number, x: number, y: number, z: number} = q;
	    var w_inv: {w: number, x: number, y: number, z: number} = this.q_invert(w);

	    var w_a: {w: number, x: number, y: number, z: number} = this.q_multiply(w, a_body);
	    var a_world: {w: number, x: number, y: number, z: number} = this.q_multiply(w_a, w_inv);

	    return a_world;
	}


	private q_inverse_rotate(q: {w: number, x: number, y: number, z: number}, w: {w: number, x: number, y: number, z: number})
	{
	    var a_world: {w: number, x: number, y: number, z: number} = q;
	    var w_inv: {w: number, x: number, y: number, z: number} = this.q_invert(w);

	    var w_a: {w: number, x: number, y: number, z: number} = this.q_multiply(w_inv, a_world);
	    var a_body: {w: number, x: number, y: number, z: number} = this.q_multiply(w_a, w);

	    return a_body;
	}

	private angle_between_quaternions(q_a: {w: number, x: number, y: number, z: number}, q_b: {w: number, x: number, y: number, z: number}) {
		let q_inv = this.q_invert(q_a);
		q_inv.x = q_inv.x * -1;
		q_inv.y = q_inv.y * -1;
		q_inv.z = q_inv.z * -1;
  		let res = this.q_multiply(q_inv, q_b);

  		//res.w = res.w > 1.0 ? 1.0 : (res.w < -1.0 ? 1.0 : res.w);

  		//// console.log(res.w, Math.acos(res.w));
  		let vectorMag = Math.sqrt((res.x * res.x) + (res.y * res.y) + (res.z * res.z));
  		let d_theta = 2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

  		return d_theta;
	}



	private last_q = {w: 1.0, x: 0, y: 0, z: 0.0};

	private processDownloadedRepMotion_old(dataArray: Uint8Array) {


		let q_directional: {w: number, x: number, y: number, z: number} = {w: 0.0, x: 1.0, y: 0.0, z: 0.0};

		this.readingsRetrieved += 1;
		let numTotalUsedBytes = this.downloadingNumPoints * 7;
		let numSends = Math.ceil(numTotalUsedBytes / 19);
		//// console.log(`--------- NUM DOWNLOADING POINTS: ${this.downloadingNumPoints} | num used Bytes: ${this.readingsRetrieved * 19} / ${numTotalUsedBytes} | num sends = ${this.readingsRetrieved} / ${numSends} `);
		

		for (var i = 1; i < dataArray.length; i++) {
			let thisNum = dataArray[i];
			this.retrievedData.push(thisNum);
		}

		// // console.log(this.retrievedData.length);
		// // console.log(this.retrievedData);

		if ((this.readingsRetrieved * 19 / 7) >= this.downloadingNumPoints && !this.dataPrinted) {

			this.dataPrinted = true;

			var printString = "";
			var numReads = 0;

			var dataBufferTemp = [];
			var quaternionBuffer = [];

			for (var i = 0; i < this.downloadingNumPoints; i++) {
				let baseIndex = i * 7;
				// let q_w = (this.retrievedData[baseIndex] - 122) / 122;
				// let q_x = (this.retrievedData[baseIndex + 1] - 122) / 122;
				// let q_y = (this.retrievedData[baseIndex + 2] - 122) / 122;
				// let q_z = (this.retrievedData[baseIndex + 3] - 122) / 122;

				// let v_x = (this.retrievedData[baseIndex + 4] - 42) / 42;
				// let v_y = (this.retrievedData[baseIndex + 5] - 42) / 42;
				// let v_z = (this.retrievedData[baseIndex + 6] - 42) / 42;

				let q_w = this.retrievedData[baseIndex];
				let q_x = this.retrievedData[baseIndex + 1];
				let q_y = this.retrievedData[baseIndex + 2];
				let q_z = this.retrievedData[baseIndex + 3];

				let q = {w: (q_w - 128) / 122, x: (q_x - 128) / 122, y: (q_y - 128) / 122, z: (q_z - 128) / 122};
				quaternionBuffer.push(q);

				let v_x = this.retrievedData[baseIndex + 4];
				let v_y = this.retrievedData[baseIndex + 5];
				let v_z = this.retrievedData[baseIndex + 6];

				dataBufferTemp.push(q_w);
				dataBufferTemp.push(q_x);
				dataBufferTemp.push(q_y);
				dataBufferTemp.push(q_z);

				dataBufferTemp.push(v_x);
				dataBufferTemp.push(v_y);
				dataBufferTemp.push(v_z);				

				let printString_temp = `,${q_w},${q_x},${q_y},${q_z},${v_x},${v_y},${v_z}`;
				printString += printString_temp;

				numReads += 1;

				//// console.log(`Log num ${i} | Q: (${q_w}, ${q_x}, ${q_y}, ${q_z})\t\tV: (${v_x},${v_y},${v_z})`);
				//// console.log(`${i},${q_w},${q_x},${q_y},${q_z},${v_x},${v_y},${v_z}`);
			}

			if (this.downloadingNumPoints < 64) {
				let numPointsRemaining = 64 - this.downloadingNumPoints;
				let lastRecordedPointBaseIndex = (this.downloadingNumPoints - 1) * 7;

				let q_w = this.retrievedData[lastRecordedPointBaseIndex];
				let q_x = this.retrievedData[lastRecordedPointBaseIndex + 1];
				let q_y = this.retrievedData[lastRecordedPointBaseIndex + 2];
				let q_z = this.retrievedData[lastRecordedPointBaseIndex + 3];

				

				let v_x = this.retrievedData[lastRecordedPointBaseIndex + 4];
				let v_y = this.retrievedData[lastRecordedPointBaseIndex + 5];
				let v_z = this.retrievedData[lastRecordedPointBaseIndex + 6];

				for (var i = 0; i < numPointsRemaining; i++) {
					dataBufferTemp.push(q_w);
					dataBufferTemp.push(q_x);
					dataBufferTemp.push(q_y);
					dataBufferTemp.push(q_z);

					let q = {w: (q_w - 128) / 122, x: (q_x - 128) / 122, y: (q_y - 128) / 122, z: (q_z - 128) / 122};
					quaternionBuffer.push(q);

					dataBufferTemp.push(v_x);
					dataBufferTemp.push(v_y);
					dataBufferTemp.push(v_z);
					let printString_temp = `,${q_w},${q_x},${q_y},${q_z},${v_x},${v_y},${v_z}`;
					printString += printString_temp;
					numReads += 1;

					//// console.log(`${i + this.downloadingNumPoints},${q_w},${q_x},${q_y},${q_z},${v_x},${v_y},${v_z}`);
				}
			}

			// Try calculating ROM

			// let starting_q = this.last_q;
			// var integratedAngle = 0.0;

			// var integratedAngle_b = 0.0;

			// //console.table(quaternionBuffer);
			// var min = 10000000.0;
			// var max = -10000000.0;
			// for (var i = 1; i < quaternionBuffer.length; i++) {
			// 	let this_q = this.q_normalize(quaternionBuffer[i]);
			// 	let prev_q = this.q_normalize(quaternionBuffer[i - 1]);
			// 	let theta_ref_a = this.angle_between_quaternions(starting_q, prev_q);
			// 	let theta_ref_b = this.angle_between_quaternions(starting_q, this_q);

			// 	if (i > 8) {
			// 		let theta_ref_gen = this.angle_between_quaternions(this_q, prev_q);
			// 		integratedAngle_b += theta_ref_gen;
			// 	}
				

			// 	let d_theta = theta_ref_b - theta_ref_a;
			// 	//d_theta *= 0.05; // ~50mS per reading * 64 readings (max) = 3.2s (max)

			// 	integratedAngle += d_theta;

			// 	if (integratedAngle < min) {
			// 		min = integratedAngle;
			// 	}

			// 	if (integratedAngle > max) {
			// 		max = integratedAngle;
			// 	}

			// 	//{w: ${this_q.w}, x: ${this_q.x}, y: ${this_q.y}, z: ${this_q.z}} -> 
			// 	// console.log(i,Math.round(theta_ref_a),Math.round(theta_ref_b),Math.round(d_theta),`angle:\t${integratedAngle}º`)
			// 	//// console.log(d_theta,integratedAngle);
			// }

			// // console.log(`MIN: ${min}\t\tMAX: ${max}\t\tDELTA: ${max - min}\t|\ttotal movement: ${integratedAngle_b}`);

			// this.last_q = quaternionBuffer[10];


			// Calculate Tangential Velocity
			let starting_q = this.last_q;
			let d_t = 0.05; // ~50ms per reading
			var velBuff: number[] = [0.0];
			for (var i = 1; i < quaternionBuffer.length; i++) {
				let this_q = this.q_normalize(quaternionBuffer[i]);
			 	let prev_q = this.q_normalize(quaternionBuffer[i - 1]);
			 	let theta_ref_a = this.angle_between_quaternions(starting_q, prev_q);
			 	let theta_ref_b = this.angle_between_quaternions(starting_q, this_q);

			 	let d_theta = (theta_ref_b - theta_ref_a);
			 	let omega = d_theta / d_t;	// Angular velocity [deg/s]
			 	let omega_rad = omega / 57.2957795131;
			 	let v_tang = omega_rad * 0.2667; //0.304; // tangential velocity for 12" forearm [m/s]
			 	velBuff.push(v_tang);

			}

			// Calculate directional vector orientation

			var positionBuff: {x: number, y: number, z: number}[] = [];
			for (var i = 0; i < quaternionBuffer.length; i++) {
				let this_q = this.q_normalize(quaternionBuffer[i]);

				let q_directional_new = this.q_rotate(q_directional, this_q);

				let v_directional_new: {x: number, y: number, z: number} = {
					x: q_directional_new.x,
					y: q_directional_new.y,
					z: q_directional_new.z
				}
				positionBuff.push(v_directional_new);
			}


			
			var dataBufferTemp_amended = [];
			var velBuff_index = 0;
			var posBuff_index = 0;

			for (var i = 0; i < dataBufferTemp.length; i++) {
				if (i % 7 <= 3) {
					dataBufferTemp_amended.push(dataBufferTemp[i]);
				} else if (i % 7 === 4 ) {
					dataBufferTemp_amended.push(positionBuff[posBuff_index].x);
				} else if (i % 7 === 5 ) {
					dataBufferTemp_amended.push(positionBuff[posBuff_index].y);
				} else if (i % 7 === 6 ) {
					dataBufferTemp_amended.push(positionBuff[posBuff_index].z);
					posBuff_index += 1;
				}

				/*else if (i % 7 === 4 ) {
					dataBufferTemp_amended.push(velBuff[velBuff_index]);
					velBuff_index += 1;
				} else {
					dataBufferTemp_amended.push(0.0);
				}*/
			}


			this.simulationStreamCount = 0;
			this.dataBuffer = dataBufferTemp;

			if (this.nodeDumpCallback !== null) {
				//// console.log(dataBufferTemp);
				//this.nodeDumpCallback(this, dataBufferTemp);
				
				this.nodeDumpCallback(this, dataBufferTemp_amended);
				
				

				// if (this.dataBuffer_prev.length === 0) {
				// 	// First data load. Set dataBuffer_prev to empty values
				// 	for (var i = 0; i < 448; i++) {
				// 		this.dataBuffer_prev.push(i % 7 === 0 ? 1.0 : 0.0);
				// 	}
				// }

				// if (this.dataBuffer_toSend.length === 0) {
				// 	for (var i = 0; i < this.dataBuffer_toSend_length * 7; i++) {
				// 		this.dataBuffer_toSend.push(0);
				// 	}
				// }
				// setTimeout(() => {
				// 	this.simulateStreaming();
				// }, 60);


			}
			let ML_TYPE = 0;
			//// console.log(`${ML_TYPE}${printString}`);
			//// console.log(`${numReads} | ${Math.floor((numReads * 7) * 1000 / 448) / 10}%`);

		}
	}

	private processDownloadedRepMotion(dataArray: Uint8Array) {

		this.readingsRetrieved += 1;
		let numTotalUsedBytes = this.downloadingNumPoints * 7;
		let numSends = Math.ceil(numTotalUsedBytes / 19);
		//// console.log(`--------- NUM DOWNLOADING POINTS: ${this.downloadingNumPoints} | num used Bytes: ${this.readingsRetrieved * 19} / ${numTotalUsedBytes} | num sends = ${this.readingsRetrieved} / ${numSends} `);
		
		for (var i = 1; i < dataArray.length; i++) {
			let thisNum = dataArray[i];
			this.retrievedData.push(thisNum);
		}

		if ((this.readingsRetrieved * 19 / 7) >= this.downloadingNumPoints && !this.dataPrinted) {

			this.dataPrinted = true;

			var numReads = 0;

			var dataBufferTemp = [];
			var quaternionBuffer = [];
			var velocityBuffer = [];

			for (var i = 0; i < this.downloadingNumPoints; i++) {
				let baseIndex = i * 7;

				let q_w = this.retrievedData[baseIndex];
				let q_x = this.retrievedData[baseIndex + 1];
				let q_y = this.retrievedData[baseIndex + 2];
				let q_z = this.retrievedData[baseIndex + 3];

				let q = {w: (q_w - 128) / 122, x: (q_x - 128) / 122, y: (q_y - 128) / 122, z: (q_z - 128) / 122};
				quaternionBuffer.push(q);

				let v_x = this.retrievedData[baseIndex + 4];
				let v_y = this.retrievedData[baseIndex + 5];
				let v_z = this.retrievedData[baseIndex + 6];

				let v = {x: (v_x) / 64, y: (v_y - 128), z: (v_z)}
				velocityBuffer.push(v);

				dataBufferTemp.push(q_w);
				dataBufferTemp.push(q_x);
				dataBufferTemp.push(q_y);
				dataBufferTemp.push(q_z);

				dataBufferTemp.push(v_x);
				dataBufferTemp.push(v_y);
				dataBufferTemp.push(v_z);

				numReads += 1;
			}

			// numPts_threshold is the number of data points (quaternion + vectors) per data batch sent from a Node
			// NOTE: Each data point is about 32ms. So for numPts_threshold = 32, 32 * 32ms = 1.024 seconds per batch.
			// IMPORTANT: This must match the configuration on the Node. It is not enough to only change this setting here.
			const numPts_threshold = 32;		

			if (this.downloadingNumPoints < numPts_threshold) {
				let numPointsRemaining = numPts_threshold - this.downloadingNumPoints;
				let lastRecordedPointBaseIndex = (this.downloadingNumPoints - 1) * 7;

				let q_w = this.retrievedData[lastRecordedPointBaseIndex];
				let q_x = this.retrievedData[lastRecordedPointBaseIndex + 1];
				let q_y = this.retrievedData[lastRecordedPointBaseIndex + 2];
				let q_z = this.retrievedData[lastRecordedPointBaseIndex + 3];

				let v_x = this.retrievedData[lastRecordedPointBaseIndex + 4];
				let v_y = this.retrievedData[lastRecordedPointBaseIndex + 5];
				let v_z = this.retrievedData[lastRecordedPointBaseIndex + 6];

				for (var i = 0; i < numPointsRemaining; i++) {
					dataBufferTemp.push(q_w);
					dataBufferTemp.push(q_x);
					dataBufferTemp.push(q_y);
					dataBufferTemp.push(q_z);

					let q = {w: (q_w - 128) / 122, x: (q_x - 128) / 122, y: (q_y - 128) / 122, z: (q_z - 128) / 122};
					quaternionBuffer.push(q);

					dataBufferTemp.push(v_x);
					dataBufferTemp.push(v_y);
					dataBufferTemp.push(v_z);

					let v = {x: (v_x) / 64, y: (v_y - 128), z: (v_z)}
					velocityBuffer.push(v);

					numReads += 1;
				}
			}

			this.dataBuffer = dataBufferTemp;


			this.last_q = quaternionBuffer[quaternionBuffer.length - 1];
			

			var dataBufferTemp_amended = [];
			for (var i = 0; i < dataBufferTemp.length; i++) {
				dataBufferTemp_amended.push(dataBufferTemp[i]);
			}

			// Pull and parse velocities from data buffer
			var q_buff: {w: number[], x: number[], y: number[], z: number[]} = {w: [], x:[], y: [], z: []};
	        var v_buff: {x: number[], y: number[], z: number[]} = {x:[], y: [], z: []};


			for (var i = 0; i < dataBufferTemp_amended.length; i++) {
	            let thisByte = dataBufferTemp_amended[i];
	            let thisBytePosition = i % 7;

	            switch (thisBytePosition) {
	                case 0:
	                    q_buff.w.push((thisByte - 128) / 122);
	                    break;
	                case 1:
	                    q_buff.x.push((thisByte - 128) / 122);
	                    break;
	                case 2:
	                    q_buff.y.push((thisByte - 128) / 122);
	                    break;
	                case 3:
	                    q_buff.z.push((thisByte - 128) / 122);
	                    break;
	                case 4:
	                    v_buff.x.push(thisByte / 64); 			// Accel mag, in g's (0 - 4 g's)
	                    break;
	                case 5:
	                    v_buff.y.push(thisByte - 128); 			// Angular velocity mag, in deg/s (-125 - +125 deg/s)
	                    break;
	                case 6:
	                    v_buff.z.push(thisByte); 				// Time delta, in ms (0 - 256ms)
	                    break;
	            }
	        }

	        var m_buff: MotionData_t[] = [];

	        let startingTS = Date.now();

	        var lastTS = startingTS;

	        for (var i = 0; i < v_buff.x.length; i++) {

	        	var prevTimeSum = 0;
	        	for (var j = 0; j < v_buff.z.length - i; j++) {
	        		prevTimeSum += v_buff.z[j];
	        	}

	        	let thisTime = startingTS - prevTimeSum;
	        	let thisDate = new Date(thisTime);
	        	
	        	// console.log(`TIMESTAMP: ${this.getLocationIndex()}`, i, `${thisDate.getHours()}:${thisDate.getMinutes() < 10 ? '0' : ''}${thisDate.getMinutes()}:${thisDate.getSeconds() < 10 ? '0' : ''}${thisDate.getSeconds()}:${thisDate.getMilliseconds()}\t\t${thisTime - lastTS}`)
	        	lastTS = thisTime;
	            let m_new: MotionData_t = {
	            	acceleration : {
	            		x: v_buff.x[i],
	            		y: v_buff.y[i],
	            		z: v_buff.z[i]
	            	},
	            	quaternion: {
	            		w: q_buff.w[i],
	            		x: q_buff.x[i],
	            		y: q_buff.y[i],
	            		z: q_buff.z[i]
	            	},
	            	timestamp: thisTime
	            }
	            m_buff.push(m_new);
	        }

			this.NodeManagerInstance.NodeMotionStreamUpdated(m_buff, this);

			let ML_TYPE = 0;
		}
	}

	private pushPopDataBuffer(withVals: number[]) {
		for (var i = 0; i < this.dataBuffer_toSend_length - 1; i++) {
			for (var j = 0; j < 7; j++) {
				let thisIndex = (i * 7) + j;
				let replacementIndex = thisIndex + 7;
				this.dataBuffer_toSend[thisIndex] = this.dataBuffer_toSend[replacementIndex];
			}
		}

		for (var j = 0; j < 7; j++) {
			let thisIndex = ((this.dataBuffer_toSend_length - 1) * 7) + j;
			this.dataBuffer_toSend[thisIndex] = withVals[j];
		}
	}

	private simulateStreaming() {

		let numReadsBeforeRefresh = 60;

		if (this.simulationStreamCount <= numReadsBeforeRefresh) {

			var tempDataSet: number[] = [];
			let thisBaseIndex = this.simulationStreamCount;
			for (var i = 0; i < 7; i++) {
				tempDataSet.push(this.dataBuffer[thisBaseIndex + i]);
			}

			this.pushPopDataBuffer(tempDataSet);

			if (this.nodeDumpCallback !== null) {
				this.nodeDumpCallback(this, tempDataSet);
			}

			setTimeout(() => {
				this.simulateStreaming();
			}, 55);
		}

		if (this.simulationStreamCount == numReadsBeforeRefresh) {
			this.requestDataDump();
		}

		this.simulationStreamCount += 1;
	}

	private streamingQuaternions = false;
	private quaternionStreamBuffer: number[] = [];
	//private quaternionStreamLog: {w: number, x: number, y: number, z: number}[][] = [];
	private quaternionStreamLog: MotionData_t[][] = [];
	private quaternionStreamBufferReadingsLength = 50;
	private pointCount = 0;
	private quaternionLogCount = 0;

	public beginQuaternionStream() {
		this.streamingQuaternions = true;

		// Request quaternion data (starts request loop)
		this.sendQuaternionRequest();

		// Clear log
		this.quaternionStreamLog = [];
		this.quaternionLogCount = 0;

		// Clear buffer
		for (var i = 0; i < this.quaternionStreamBufferReadingsLength; i++) {
			for (var j = 0; j < 7; j++) {
				this.quaternionStreamBuffer.push(j === 0 ? 1.0 : 0.0);
			}
		}
		this.pointCount = 0;

		// Send cleared buffer to UI
		if (this.nodeDumpCallback !== null) {
			this.nodeDumpCallback(this, this.quaternionStreamBuffer);
		}
	}

	public endQuaternionStream() {
		this.streamingQuaternions = false;

	}

	private sendQuaternionRequest() {

		// if (!this.streamingQuaternions) { return; }
		// var data = new Uint8Array(1);
		// data[0] = SendNodeType.EF_NODE_UART_REQUEST_ID_GET_QUATERNION;

		// // console.log("NODE INSTANCE | sendQuaternionRequest");
		// BLE.write(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_TX_Characteristic_UUID, data.buffer)
		// .then((response: any) => {})
		// .catch((error: any) => {})
	}

	private processQuaternionData(data: Uint8Array) {
		let signedData = new Int16Array(8);

		// Ignore first byte of data, as it is request ID 0x34
  		signedData[0] = data[1] << 8;
  		signedData[1] = data[2];
  		signedData[2] = data[3] << 8;
  		signedData[3] = data[4];
  		signedData[4] = data[5] << 8;
  		signedData[5] = data[6];
  		signedData[6] = data[7] << 8;
  		signedData[7] = data[8];

		let q = {
			w: (Number(signedData[0] | signedData[1])) / 16384.0,
			x: (Number(signedData[2] | signedData[3])) / 16384.0,
			y: (Number(signedData[4] | signedData[5])) / 16384.0,
			z: (Number(signedData[6] | signedData[7])) / 16384.0
		};

		// Push pop data buffer
		var quaternionStreamBuffer_temp: number[] = [];
		quaternionStreamBuffer_temp[0] = (q.w * 122) + 128;
		quaternionStreamBuffer_temp[1] = (q.x * 122) + 128;
		quaternionStreamBuffer_temp[2] = (q.y * 122) + 128;
		quaternionStreamBuffer_temp[3] = (q.z * 122) + 128;
		quaternionStreamBuffer_temp[4] = 0.0;
		quaternionStreamBuffer_temp[5] = 0.0;
		quaternionStreamBuffer_temp[6] = 0.0;

		let thisMotionData: MotionData_t = {
			quaternion: {w : q.w, x : q.x, y: q.y, z: q.z},
			acceleration: {x: 0.0, y: 0.0, z: 0.0},
			timestamp: Date.now()
		};

		for (var i = 0; i < this.quaternionStreamBuffer.length - 7; i++) {
			quaternionStreamBuffer_temp.push(this.quaternionStreamBuffer[i]);
		}

		if (this.nodeDumpCallback !== null) {
			//this.nodeDumpCallback(this, quaternionStreamBuffer_temp);
		}

		if (this.NodeManagerInstance !== null && this.NodeManagerInstance !== undefined) {
			this.NodeManagerInstance.NodeStreamUpdated(thisMotionData, this);
		}

		this.pointCount += 1;
		//// console.log("QUAT STREAM count:", this.pointCount);

		this.quaternionStreamBuffer = quaternionStreamBuffer_temp;
		this.sendQuaternionRequest();
	}

	private breakQuaternionStream() {
		// Add current stream buffer to Log
		let quatList = this.getQuaternionPath();
		this.quaternionStreamLog.push(quatList);

		// Clear buffer
		this.quaternionStreamBuffer = [];
		for (var i = 0; i < this.quaternionStreamBufferReadingsLength; i++) {
			for (var j = 0; j < 7; j++) {
				this.quaternionStreamBuffer.push(j === 0 ? 1.0 : 0.0);
			}
		}

		// Track how many logs are recorded
		this.quaternionLogCount += 1;

	}

	public getQuaternionPath() {

		var quatList: MotionData_t[] = [];

		var latestQ: {w: number, x: number, y: number, z: number} = {w: 0.0, x: 0.0, y: 0.0, z: 0.0};
		for (var i = 0; i < this.quaternionStreamBuffer.length; i++) {
			if (i % 7 === 0) {
				latestQ.w = (this.quaternionStreamBuffer[i] - 128) / 122;
			} else if (i % 7 === 1) {
				latestQ.x = (this.quaternionStreamBuffer[i] - 128) / 122;
			} else if (i % 7 === 2) {
				latestQ.y = (this.quaternionStreamBuffer[i] - 128) / 122;
			} else if (i % 7 === 3) {
				latestQ.z = (this.quaternionStreamBuffer[i] - 128) / 122;

				let thisMotionData: MotionData_t = {
					quaternion: latestQ,
					acceleration: {x: 0.0, y: 0.0, z: 0.0},
					timestamp: Date.now()
				};
				quatList.push(thisMotionData);

				latestQ = {w: 0.0, x: 0.0, y: 0.0, z: 0.0};
			} 
		}

		// Filter out first 3 readings
		var quatList_final: MotionData_t[] = [];
		for (var i = 5; i < quatList.length; i++) {
			quatList_final.push(quatList[i]);
		}
		return quatList_final;
	}

	public getQuaternionLogs() {
		return this.quaternionStreamLog;
	}


	/**
	 *	subscribeToNodeNotifications
	 *  ----------------------------------------------------------------------------------------------------
	 *	Call to subscribe to UART RX data from Node
	 *
	 */
	private subscribeToNodeNotifications() {

		this.hasSubscribedToNotifications = true;

   		BLE.startNotification(this.peripheral.uuid, this.Nordic_UART_Service_UUID, this.Nordic_UART_RX_Characteristic_UUID).subscribe(
        	(buffer) => { 
        		let data = new Uint8Array(buffer); 
        		//// console.log(data);
        		//// console.log("---");
        		if (data === undefined || data === null || data.length === 0) { return; } // Validate data
        		//// console.log(`REQUEST RECIEVED: ${data[0]}`)

        		let responseID = Number(data[0]);

        		//// console.log("REPONSE")

        		switch (responseID) {
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_DEVICE_SIN :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_HW_VERSION :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_FW_VERSION :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_STATUS :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_BATTERY:
        				//// console.log("Battery response!");
        				let unsignedData_battery_mv = new Uint16Array(2);
        				unsignedData_battery_mv[0] = data[1] << 8;
        				unsignedData_battery_mv[1] = data[2];
        				//// console.log(data);
        				let mV = Number(unsignedData_battery_mv[0] | unsignedData_battery_mv[1]);

        				let batt = this.convertBatteryVoltage(mV);
        				if (batt < 15) {
        					//// console.log(`> NODE INSTANCE: LOW BATTERY ALERT! Battery for ${this.Set_SIN}.${this.Device_SIN} is ${batt}% (${mV}mV)`);
        				}
        				
        				this.peripheral.battery = batt;

        				if (this.batteryCallback !== null) {
        					this.batteryCallback(this.peripheral.uuid, batt);

        					
        					
        				}
        				this.notifyNodeManagerOfUpdate();

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GENERAL_TEST :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_CHARGING_STATE :
        				let chargingState = data[1] === 0x01;
        				// console.log(`> NODE INSTANCE: ${this.Set_SIN}.${this.Device_SIN} charging state updated to: ${chargingState}`);
        				if (this.chargingCallback !== null) {
        					// update listening UI elements on current charging state
        					this.chargingCallback(chargingState);
        				}
        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_SET_TRACK_MOTION_STATE :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_SET_ALERT_STATUS :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_SET_LED_OFF :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_SET_LED_COLOUR :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_SET_TAP_STATUS :
        				//this.tapChangedFromNode(true);
        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_SET_SLEEP_STATUS :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_CALIBRATION :
        				// console.log('EF_NODE_UART_REQUEST_ID_RUN_CALIBRATION pinged');
        				this.processGyroTestData(data);
        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_RUN_LED_SEQUENCE :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_CFG_IMU_PRESETS :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_CFG_MAX_LED_BRIGHTNESS :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_CFG_EXERCISE :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_CFG_INJURY_LIMITS :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_TEMPERATURE :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_ACCELEROMETER :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_GYROSCOPE :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_MAGNOMETER :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_QUATERNION :
        				if (this.streamingQuaternions) {
        					this.processQuaternionData(data);
        				}
        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_INTEGRATED_ANGLE :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_ALL_MOTION :
        				//this.processMotionData(data);
        				//this.sendQuaternionRequest();
        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_GET_MOTION_DATA_DUMP:
        				// Will return as EF_NODE_UART_REQUEST_ID_DOWNLOAD_REP_MOTION
        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_UPDATE_SYS_ALERT :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_UPDATE_INJURY_ALERT :

        				break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_UPDATE_REP_STAGE :
        				//// console.log(`--------- Recieved new batch of Node orientation data ---------`);
        				this.processRepData(data);


        				this.breakQuaternionStream();
	        			this.sendQuaternionRequest();


	        			break;
        			case SendNodeType.EF_NODE_UART_REQUEST_ID_DOWNLOAD_REP_MOTION :
        				this.processDownloadedRepMotion(data);
        				

        				//this.sendQuaternionRequest();


        				break;

        			case SendNodeType.EF_NODE_UART_REQUEST_ID_UPDATE_TAP_STATE: 
        				this.tapChangedFromNode(data[1] === undefined ? true : data[1] === 0x01)
        				break;
        			default:
        				break;
        		}


        		/*
        		if (data.length === 1) {
        			//// console.log(`RESPONSE: ${data[0]}`);
        			//// console.log(`> NODE INSTANCE: Battery response for ${this.Set_SIN}.${this.Device_SIN}: ${data[0]}`);
        			if (this.batteryCallback !== null) {
    					this.batteryCallback(this.peripheral.uuid, data[0]);
    					this.peripheral.battery = data[0];
    					
    					//clearTimeout(this.batteryCheckTimer);
    					
    				}

    				setTimeout(() => {
						this.requestBatteryPercentage_loop();
    				}, 12000);

        		} else if (data.length === 3) {
        			if (data[0] === 0x30 && data[1] === 0x30) {
        				//// console.log(`Button Pressed: ${data[2]}`);
        				this.tapChangedFromNode(data[2] === 0x01);
        			} else if (data[0] === 0x31 && data[1] === 0x31) {
        				let c = data[2] === 0x01;
        				this.isCharging = c;
        				if (this.chargingCallback !== null) {
        					this.chargingCallback(c);
        				}
        			} else if (data[0] === 0x21) {
        				let unsignedData_battery_mv = new Uint16Array(2);
        				unsignedData_battery_mv[0] = data[1] << 8;
        				unsignedData_battery_mv[1] = data[2];
        				//// console.log(data);
        				let mV = Number(unsignedData_battery_mv[0] | unsignedData_battery_mv[1]);

        				let batt = this.convertBatteryVoltage(mV);
        				//// console.log(`> NODE INSTANCE: Battery for ${this.Set_SIN}.${this.Device_SIN} is ${batt}% (${mV}mV)`);
        				this.peripheral.battery = batt;

        				if (this.batteryCallback !== null) {
        					this.batteryCallback(this.peripheral.uuid, batt);
        				}
    					
        			}
        		} else if (data.length === 12) {
        			this.processGyroTestData(data);
        		} else {
        			this.processMotionData(data);
        			
        			
        			//this.sendMotionDataRequest();
        		}

        		*/
        		
        	},
        	(error) => {
        		// console.log(error)
        	}
    	);
	}
}

export default NodeInstance;