import React, { useState, useEffect, useRef } from 'react';
import { IonLabel, IonInput, IonItem } from '@ionic/react';

import './FormTempoInput.css';

import Tempo_t from '../../Interfaces/Tempo_t';

function FormTempoInput(props: any) {

    const inputRef_tempo = useRef<any>(null);

    const [initSet, setInitSet] = useState(false);
    const [alert, setAlert] = useState(false);
    const [tempo, setTempo] = useState<Tempo_t>({eccentric: -1, first_pause: -1, concentric: -1, second_pause: -1});
    const [tempVal, setTempVal] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.thisTempo !== undefined) {
            setTempo(props.thisTempo);
        }
    }

    useEffect(() => {
        if (props.thisTempo !== undefined) {
            setTempo(props.thisTempo);
        }
        
    }, [props.thisTempo]);

    useEffect(() => {
        if (props.forceFocus !== undefined) {
            setIsFocused(props.forceFocus);
            if (!props.forceFocus) {
                inputRef_tempo.current.blur();
            } else {
                inputRef_tempo.current.setFocus();
            }
        }
        
    }, [props.forceFocus]);    

    function tempoUpdated(withNum: any) {
        // console.log(">: FormTempoInput | ENTERING VALUE: ", withNum);

        let arr = withNum.split("");
        let updatedTempo = {
            eccentric:     arr[0] === undefined ? -1 : arr[0] === "X" || arr[0] === 'x' ? -2 : (isNaN(arr[0]) ? 0 : Number(arr[0])), 
            first_pause:   arr[1] === undefined ? -1 : arr[1] === "X" || arr[1] === 'x' ? -2 : (isNaN(arr[1]) ? 0 : Number(arr[1])), 
            concentric:    arr[2] === undefined ? -1 : arr[2] === "X" || arr[2] === 'x' ? -2 : (isNaN(arr[2]) ? 0 : Number(arr[2])),
            second_pause:  arr[3] === undefined ? -1 : arr[3] === "X" || arr[3] === 'x' ? -2 : (isNaN(arr[3]) ? 0 : Number(arr[3])), 
        }
        setTempo(updatedTempo);

        props.tempoUpdated(updatedTempo);

        if (arr.length <= 4) {
            setTempVal(withNum);
        }
        
    }

    function getTempoString(index: number) {
        var tempos_buff = [tempo.eccentric, tempo.first_pause, tempo.concentric, tempo.second_pause];
        let thisTempoFromBuff = tempos_buff[index];
        return thisTempoFromBuff === -1 ? '-' : (thisTempoFromBuff === -2 ? 'X' : thisTempoFromBuff);
    }

    function focusChanged(toFocus: boolean) {
        setIsFocused(toFocus);
        if (props.focusChanged !== undefined) {
            props.focusChanged(toFocus);
        }
    }

    function showBlinker() {
        return tempo.concentric === -1 && tempo.eccentric === -1 && tempo.first_pause === -1 && tempo.second_pause === -1;
    }

    return (
        <div className="form-tempo-input">
            <p hidden={props.alert === undefined || props.alert === false} className="form-tempo-input-alert-label">{props.alertText !== undefined ? props.alertText : ""}</p>
            <div className={props.noBorder !== undefined && props.noBorder === true ? "form-tempo-input-container form-tempo-input-container-no-border" : (props.alert === undefined || props.alert === false ? `form-tempo-input-container ${isFocused === true ? 'form-tempo-input-container-focused' : ''}` : "form-tempo-input-container-alert")}>
                <div className="form-tempo-input-text-show-container">
                    <h5>
                        {getTempoString(0)}<span className="form-tempo-input-param-colon">:</span>
                        {getTempoString(1)}<span className="form-tempo-input-param-colon">:</span>
                        {getTempoString(2)}<span className="form-tempo-input-param-colon">:</span>
                        {getTempoString(3)}
                    </h5>
                </div>
                <div className="form-tempo-input-text-input-container">
                    <IonInput 
                        value={tempVal} 
                        placeholder="" 
                        ref={(ref) => inputRef_tempo.current = ref}
                        inputmode="text"
                        clearOnEdit={true}
                        type={"text"}
                        onIonChange={(e: any) => tempoUpdated(e.detail.value!)}
                        onFocus={(e) => focusChanged(true)}
                        onBlur={(e) => focusChanged(false)}/>
                    <div hidden={!isFocused || !showBlinker()} className="create-workout-set-rep-input-typing-blinker form-tempo-input-typing-blinker"/>
                </div>
            </div>
        </div>
    )
}

export default FormTempoInput;