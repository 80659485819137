import React from 'react';
import getDatabaseDirect from '../firebase/database/getDatabaseDirect';

const getClientName = async (
		user_uid: string
	) => {
    
    return new Promise<{ full_name: string, first_name: string, last_name: string, initials: string }>( async function(resolve, reject) {
    	
    	if (!user_uid || user_uid === "") reject(`API/getUserPushId: Provide a valid user UID string. <${user_uid}>`)

    	try {
    		// Get the logged in user
    		let location = `user_profile/${user_uid}`;
    		let resp = await getDatabaseDirect(location);

    		if (!resp || !resp.full_name || resp.full_name === "") {
    			reject(`No user profile found for given UID: ${user_uid}`);
    			return;
    		}

    		let nameSplit = resp.full_name.split(" ");
            let fName = nameSplit[0];
            let lName = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : "";

            let fName_fInitial = fName !== undefined ? fName.split("")[0] : "";
            let lName_fInitial = lName !== undefined ? lName.split("")[0] : "";

            let initials = `${fName_fInitial}${lName_fInitial}`;

    		resolve({
    			full_name: resp.full_name,
    			first_name: resp.first_name ?? "",
    			last_name: resp.last_name ?? "",
    			initials: initials
    		});
			
    	} catch (err) {
    		console.log("ERROR (API/getClientName)", err);
    		reject(err);
    	}
	});
}

export default getClientName;