import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './GoalCard.css';

import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';

import Selector from '../Base/Selector';
import FormTextInput from '../Base/FormTextInput';
import DateSelector from '../Base/DateSelector';
import ToggleSwitch from '../Base/ToggleSwitch';

import GoalSkillCard from './GoalSkillCard';

import GoalSkillColumn from './GoalSkillColumn';

import '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';


function GoalCard(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    let workoutGoals = ['Get Fit', 'Loose Weight', 'Gain Muscle', 'Muscle Toning', 'Body Building', 'Power Lifting', 'Cardio Endurance'];
    let goalDescs = ['workout', 'loose', 'bulk to', 'tone to', 'body build to', 'power lift', 'improve endurance to'];
    let goalUnits = ['days/wk', '_weight_', '_weight_', '% BF', '_weight_', '_weight_', 'mins']
    let goalMetricDecs = ['Workouts', 'Body Weight', 'Body Weight', '% Body Fat', 'Body Weight', 'Max Lift', 'Working Mins']
    let workoutGoalTypes: ("custom" | "get-fit" | "loose-weight" | "gain-weight" | "muscle-toning" | "body-building" | "power-lifting" | "cardio-endurance")[] = ["get-fit", "loose-weight", "gain-weight", "muscle-toning", "body-building", "power-lifting", "cardio-endurance"];

    let initGoal: Goal_t = {
            type: "get-fit",
            targetMetric: -1,
            targetUnitsKG: true,
            targetTimestamp: -1,
            startingTimestamp: 0,
            isEditing: false,
            actions: []
        }

    const [goal, setGoal] = useState<Goal_t>(initGoal);
    const [totalDurationDays, setTotalDurationDays] = useState(112);
    const [useActions, setUseActions] = useState(false);
    const [defaultDate, setDefaultDate] = useState(0);
    const [initSet, setInitSet] = useState(false);

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        let dDate = Date.now() + (112 * 24 * 60 * 60 * 1000);
        setDefaultDate(dDate);    // Set starting cal date to 3 mo's from today
        setGoal({...goal, targetTimestamp: dDate, targetMetric: 5, type: 'get-fit', startingTimestamp: Date.now()});
        if (props.goal !== undefined) {
            let goalTemp = props.goal;
            if (goalTemp.targetTimestamp <= 0) {
                goalTemp.targetTimestamp = defaultDate;
            }
            setGoal(goalTemp);
        }
    }

    useEffect(() => {
        if (props.goal !== undefined) {
            let goalTemp = props.goal;
            if (goalTemp.targetTimestamp <= 0) {
                goalTemp.targetTimestamp = defaultDate;
            }
            
            setGoal(goalTemp);
        }
    }, [props.goal])

    // useEffect(() => {
    //     props.updateGoal(goal);
    // }, [goal])

    function getActionNumber(actionIndex: number, actionSubIndex: number) {
        if (goal.actions === undefined || goal.actions.length === 0) { return 0; }
        var num = 0;
        for (var i = 0; i < goal.actions.length; i++) {
            let thisAction: GoalActions_t = goal.actions[i];
            if (i < actionIndex) {
                num += thisAction.subActions === undefined ? 0 : thisAction.subActions.length;
            }
        }

        num += actionSubIndex + 1;

        return num;
    }

    function getGoalName() {
        let goalStringIndex = workoutGoalTypes.indexOf(goal.type);
        let goalString = goalStringIndex >= 0 && goalStringIndex <= workoutGoals.length ? workoutGoals[goalStringIndex] : 'Get Fit';
        return goalString;
    }

    function getGoalUnits() {

        let goalStringIndex = workoutGoalTypes.indexOf(goal.type);
        let goalUnitsString = goalUnits[goalStringIndex];
        return goalUnitsString === '_weight_' ? (props.client !== undefined && props.client.isMetric !== undefined ? (props.client.isMetric === true ? 'KGs' : 'LBS') : 'KGs') : goalUnitsString; 
    }

    function getDateString() {
        let thisDate = new Date(goal.targetTimestamp);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
    }

    function abcd(e: boolean) {
        setUseActions(e);
        if (e === true) {
            // configure default actions
            configureActionLayer(4,2);            
        } else {
            setGoal({...goal, actions: []});
        }
    }

    function configureActionLayer(layerA: number, layerB: number) {

        let durationMs = goal.targetTimestamp - Date.now();
        let durationDays = durationMs / (1000 * 60 * 60 * 24);

        setTotalDurationDays(Math.round(durationDays));

        let layerADuration = Math.round(durationDays / layerA);
        let layerBDuration = Math.round(layerADuration / layerB);


        var actionLayersTemp: GoalActions_t[] = [];
        for (var i = 0; i < layerA; i++) {
            var subLayer: GoalActions_t[] = [];

            for (var j = 0; j < layerB; j++) {
                let newSubAction: GoalActions_t = {
                    index: j,
                    goalDesc: "",
                    durationDays: layerBDuration,
                    action: "",
                    metric: 1,
                    metricUnit: "",
                    timeframe: "per day"
                }
                subLayer.push(newSubAction);
            }

            let newAction: GoalActions_t = {
                index: i,
                goalDesc: "",
                durationDays: layerADuration,
                subActions: subLayer
            }

            actionLayersTemp.push(newAction);
        }

        //// console.log("actionLayersTemp", actionLayersTemp);

        setGoal({...goal, actions: actionLayersTemp});
    }

    function targetDateChanged(toTS: number) {
        setGoal({...goal, targetTimestamp: toTS});

        if (useActions === true && goal.actions !== undefined && goal.actions.length > 0) {
            // update timestamps
            let layerA = goal.actions.length;

            let durationMs = toTS - Date.now();
            let durationDays = durationMs / (1000 * 60 * 60 * 24);
            setTotalDurationDays(Math.round(durationDays));

            let layerADuration = Math.round(durationDays / layerA);
            
            var updatedActions: GoalActions_t[] = [];

            for (var i = 0; i < layerA; i++) {
                let thisAction: GoalActions_t = goal.actions[i];
                thisAction.durationDays = layerADuration;

                var updatedSubActions: GoalActions_t[] = [];
                if (thisAction.subActions !== undefined && thisAction.subActions.length > 0) {
                    let layerB = thisAction.subActions.length;
                    let layerBDuration = Math.round(layerADuration / layerB);

                    for (var j = 0; j < layerB; j++) {
                        let thisSubLayer = thisAction.subActions[j];
                        thisSubLayer.durationDays = layerBDuration;
                        updatedSubActions.push(thisSubLayer);
                    }
                }

                thisAction.subActions = updatedSubActions;
                updatedActions.push(thisAction);
            }

            setGoal({...goal, actions: updatedActions, targetTimestamp: toTS});
        }
    }

    function inputChanged(toString: any, fromInput: string) {

        switch (fromInput) {
            case 'goal-type':
                let goalStringIndex = workoutGoals.indexOf(toString);
                let type = goalStringIndex >= 0 && goalStringIndex <= workoutGoalTypes.length ? workoutGoalTypes[goalStringIndex] : 'get-fit';
                

                var defaultTarget = 0;
                switch (type) {
                    case "custom":

                        break;
                    case "get-fit":
                        defaultTarget = 5;
                        break;
                    case "loose-weight":
                        defaultTarget = props.client !== undefined && props.client.weight !== undefined && props.client.weight !== 0 ? props.client.weight - 10 : 100;
                        break;
                    case "gain-weight":
                        defaultTarget = props.client !== undefined && props.client.weight !== undefined && props.client.weight !== 0 ? props.client.weight + 10 : 100;
                        break;
                    case "muscle-toning":
                        defaultTarget = 10;
                        break;
                    case "body-building":
                        defaultTarget = props.client !== undefined && props.client.weight !== undefined && props.client.weight !== 0 ? props.client.weight + 10 : 100;
                        break;
                    case "power-lifting":
                        defaultTarget = 500;
                        break;
                    case "cardio-endurance":
                        defaultTarget = 30;
                        break;
                    default: 
                        break;
                }

                setGoal({...goal, type: type, targetMetric: defaultTarget});

                break;

            case 'target-metric':
                setGoal({...goal, targetMetric: Number(toString)});
                break;

            default:
                break;
        }

    }

    function cancelPressed() {
        props.getUserConfirmation({
            title: "Are you sure you want to remove this goal?",
            text: `Removing this goal will erase any progress you've made creating ${props.client !== undefined ? props.client.first_name : 'your client'}'s new goal.`,
            isRed: true,
            callback: cancelConfirmed,
            ctaText: "Remove"
        });
    }

    function cancelConfirmed() {
        props.removePressed();
    }

    function savePressed() {
        setGoal({...goal, isEditing: false});
        props.updateGoal(goal);
    }

    function editPressed() {
        setGoal({...goal, isEditing: true});
        props.editPressed();
    }

    function goalActionUpdated(thisGoalAction: GoalActions_t, atIndex: number) {
        //// console.log("Goal card | goalActionUpdated", atIndex, thisGoalAction);
        if (goal.actions === undefined) { return; }
        setGoal({...goal, actions: goal.actions.map((item: GoalActions_t, index: number) => {
            if (atIndex === index) {
                return thisGoalAction;
            } else {
                return item;
            }
        })})
    }

    return (
        <div className="goal-card">
            <div hidden={goal.isEditing === true} className="goal-card-static-container">
                <div className="goal-card-static-container-col-type">
                    <h4>Goal {props.index + 1} is to <span>{goalDescs[workoutGoalTypes.indexOf(goal.type)]} {goal.targetMetric} {getGoalUnits()}</span> by <span>{getDateString()}</span> ({Math.round(totalDurationDays)} days)</h4>
                </div>
                <div className="goal-card-static-container-col-options">
                    <div onClick={() => editPressed()} className="goal-card-static-container-options-button">
                        <Edit className="goal-card-static-container-options-button-icon"/>
                    </div>
                    <div onClick={() => cancelPressed()} className="goal-card-static-container-options-button goal-card-static-container-options-button-remove">
                        <Close className="goal-card-static-container-options-button-icon"/>
                    </div>
                </div>
            </div>
            <div hidden={goal.isEditing === false} className="goal-card-editing-container">
                <div className="goal-card-editing-header-container">
                    <h4>Create a new Goal</h4>
                </div>
                <div className="goal-card-editing-body-container">
                    <div className="goal-card-editing-body-upper-container">
                        <div className="goal-card-editing-body-upper-col-type">
                            <p>New Goal Type</p>
                            <Selector
                                placeholder="Recurring..."
                                showTitle={false} 
                                options={workoutGoals}
                                inputChanged={(e: number) => inputChanged(e, 'goal-type')}/>
                        </div>
                        <div className="goal-card-editing-body-upper-col-body goal-card-editing-body-upper-col-pullup">
                            <p>Target {goalMetricDecs[workoutGoalTypes.indexOf(goal.type)]}</p>
                            <FormTextInput 
                                value={goal.targetMetric}
                                placeholder="Target Metric" 
                                alert={false} 
                                alertText={""}
                                isSearch={true}
                                inputChanged={(e: string) => inputChanged(e, 'target-metric')}/>
                            <div className="goal-card-editing-body-upper-col-body-unit-container">
                                <p>{getGoalUnits()}</p>
                            </div>
                        </div>
                        <div className="goal-card-editing-body-upper-col-body">
                            <p>Goal Target Date</p>
                            <DateSelector 
                                dateSelected={(e: number) => targetDateChanged(e)}
                                isLeft={true}
                                defaultTimestamp={defaultDate}/>
                        </div>
                    </div>  
                    <div className="goal-card-editing-body-lower-container">
                        <div className="goal-card-editing-body-lower-toggle-container">
                            <ToggleSwitch 
                                stateChanged={(e: boolean) => abcd(e)}
                                forceState={useActions}/>
                            <p>Set Skills & Actions for this Goal</p>
                        </div>
                        <div hidden={useActions === true} className="goal-card-editing-body-lower-buttons-container">
                            <div onClick={() => cancelPressed()} className="modal-back-button goal-card-editing-body-lower-button-cancel">Remove</div>
                            <div onClick={() => savePressed()} className="modal-cta-button">Save</div>
                        </div>
                    </div>  
                </div>
                
                <CSSTransition in={useActions} timeout={200} classNames="menu" unmountOnExit appear>
                    <div>
                        <div className="goal-card-editing-body-actions-container">
                            <div className="goal-card-editing-body-actions-header-container">
                                <p>SKILLS & ACTIONS FOR THIS GOAL</p>
                            </div>
                            
                           
                            <div className="goal-card-editing-body-actions-list-columns">
                                <div className="goal-card-editing-body-actions-list-columns-inner">
                                    {
                                        goal.actions === undefined ? null : goal.actions.map((item: GoalActions_t, index: number) => (
                                            <GoalSkillColumn
                                                goalAction={item} 
                                                index={index}
                                                client={props.client}
                                                goalActionUpdated={(e: GoalActions_t) => goalActionUpdated(e, index)}/>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="goal-card-editing-bottom-container">
                            <div className="goal-card-editing-body-lower-buttons-container">
                                <div onClick={() => cancelPressed()} className="modal-back-button goal-card-editing-body-lower-button-cancel">Remove</div>
                                <div onClick={() => savePressed()} className="modal-cta-button">Save</div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

/*

 <div hidden={true} className="goal-card-editing-body-actions-list">
                                {
                                    goal.actions === undefined ? null : goal.actions.map((item: GoalActions_t, index: number) => (
                                        <GoalSkillCard 
                                            goalAction={item} 
                                            index={index}
                                            client={props.client}
                                            goalActionUpdated={(e: GoalActions_t) => goalActionUpdated(e, index)}/>
                                    ))
                                }
                            </div>

<div className="goal-card-editing-body-actions-goal-container">
                                <p>Jim's Goal is to workout 5 days per week</p>
                                <div className="goal-card-editing-body-actions-duration-container">
                                    <p>{totalDurationDays} days</p>
                                </div>
                            </div>
<div className="goal-card-editing-body-action-outter-container">
                                            <div className="goal-card-editing-body-action-route-line"/>
                                            <div className="goal-card-editing-body-action-card">
                                                <div className="goal-card-editing-body-action-card-header">
                                                    <h4>Skill to develop {index + 1}</h4>
                                                    <p>{item.durationDays} days</p>
                                                </div>
                                            </div>
                                            <div className="goal-card-editing-body-action-subaction-container">
                                                {
                                                    item.subActions === undefined ? null : item.subActions.map((sub_item: GoalActions_t, sub_index: number) => (
                                                        <div className="goal-card-editing-body-action-card">
                                                            <div className="goal-card-editing-body-action-card-header">
                                                                <h4>Actionable item {getActionNumber(index, sub_index)}</h4>
                                                                <p>{sub_item.durationDays} days</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
*/

export default GoalCard;