import React, { useState } from 'react';
import './DiscoveredNodeSetCell.css';
import './NodeListCell.css';

import '@material-ui/core';
import AddRounded from '@material-ui/icons/AddRounded';


function DiscoveredNodeSetCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

        
    }

    function addSet() {
        props.addSet();
    }

    function getCircles(selected: number, total: number) {
        var composedSet = [];
        for (var i = 0; i < total; i++) {
            composedSet.push(<div key={i} className={`node-set-cell-info-node-connected-cirlce ${i < selected ? "node-set-cell-info-node-connected-cirlce-connected" : "node-set-cell-info-node-connected-cirlce-notconnected"}`}/>);
        }

        return composedSet;
    }

    function getSignalCircles() {
        let signalPercentage = 75;
        let dividedSignal = Math.floor(signalPercentage / 10);
        let circles = getCircles(dividedSignal, 10);
        return circles;
    }

	return (
		<div onClick={() => addSet()} className="discovered-node-set-cell-container">
            <div className="discovered-node-set-cell-content-container">
    			<div className="node-cell-header-container">
                    <div className="node-cell-title">
                        <h2>Node Set SIN {props.nodeSet.setSIN}</h2>
                    </div>
                </div>
                <div className="node-cell-info-container">
                    <div className="node-cell-info-content">
                        <p>{props.nodeSet.UUIDs.length} Node{props.nodeSet.UUIDs.length > 1 ? "s" : ""} Found</p>
                        <div className="node-cell-info-content-circle-container">{getCircles(props.nodeSet.UUIDs.length, 9)}</div>
                    </div>
                    <div className="node-cell-info-content">
                        <p>Average Signal Strength</p>
                        <div className="node-cell-info-content-circle-container">{getSignalCircles()}</div>
                    </div>
                </div>
            </div>
            <div className="discovered-node-set-cell-button-container">
                <div className="discovered-node-set-cell-button"><AddRounded style={{ fontSize: 20 }}/></div>
            </div>
		</div>
	)
}

export default DiscoveredNodeSetCell;