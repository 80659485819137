import React, { useState, useEffect } from 'react';
import {Line, Pie} from 'react-chartjs-2';
import Utils from 'react-chartjs-2';
import './ClientGoalProgress.css';


import Goal_t from '../../Interfaces/Goal_t';
import GoalActions_t from '../../Interfaces/GoalActions_t';


function ClientGoalProgress(props: any) {
    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    const [completion, setCompletion] = useState(50);
    const [daysInGoal, setDaysInGoal] = useState(1);
    const [daysRemaining, setDaysRemaining] = useState(1);
    const [isUpwardGoal, setIsUpwardGoal] = useState(true);
    const [initSet, setInitSet] = useState(false);

    const [predictedDate, setPredictedDate] = useState("N/A");
    const [remainingInGoal, setReaminingInGoal] = useState("--.-");
    const [changePerDay, setChangePerDay] = useState("--.-");
    const [summary, setSummary] = useState("Loading...");
    const [myLogs, setMyLogs] = useState<any[]>([
            {
                metric: 20,
                timestamp: 1641084238000,
            },
            {
                metric: 60,
                timestamp: 1642639438000,
            },
            // {
            //     metric: 95,
            //     timestamp: 1642725838000,
            // },
            // {
            //     metric: 105,
            //     timestamp: 1643762638000,
            // },
            // {
            //     metric: 200,
            //     timestamp: 1644799438000,
            // }
        ]
        )

    let initDataSets_pie = {
        labels:['complete','incomplete'],
        datasets: [
            {
                label: 'completion',
                backgroundColor: ['rgba(91,98,255,1.0)', 'rgba(0,0,0,0.2)'],
                borderColor: [`rgba(91,98,255,0)`, 'rgba(0,0,0,0)'],
                borderWidth: 4,
                type: 'pie',
                data: [60,40]
            },
        ]
    };

    let initDataSets = {
        labels:['1','2','3','4','5','6','7','8','9','10'],
        datasets: [
            {
                label: 'Trajectory',
                backgroundColor: 'rgba(217,46,46,0)',
                borderColor: `rgba(217,46,46,1.0)`,
                borderWidth: 1.5,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: [140,120,120,60,70,120,100,80,120,100]
            },
            {
                label: 'Progress',
                backgroundColor: function(context: any) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;

                    if (!chartArea) {
                      // This case happens on initial chart load
                      return;
                    }
                    return getGradient(ctx, chartArea);
                },
                borderColor: `rgba(91,98,255,1.0)`,
                borderWidth: 1.5,
                pointRadius: 0,
                pointHoverRadius: 0,
                type: 'line',
                data: [120,100,80,120,140,60,70,110,120,120]
            }
        ]
    };
    var chartOptions_line: any = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0, // general animation time
        },
        "tooltips": {
            "enabled": true,
            "mode": "index",
            "intersect": true,
            "bodyFontSize": 10
        },
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:10
        },layout: {
            padding: {
                left: 3,
                right: 0,
                top: 8,
                bottom: 8
            }
        },
        scales:{
            yAxes: [{
                display: false,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#6C6C6C',
                    fontSize:10,
                    beginAtZero: false,
                    stepSize: 1,
                    display: false
                    // stepSize: props.stepSize !== undefined ? props.stepSize : 20,
                    // callback: function(value: any, index: any, values: any) {
                    //     return value + ` ${props.units[0]}`;
                    // }
                }
            }],
            xAxes: [{
                display: false,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#9DA0AD',
                    fontSize:8,
                    beginAtZero: true,
                    stepSize: 1,
                    display: false
                }
            }]
        },
        legend:{
            display:false
        }
    };
    var chartOptions_pie: any = {
        maintainAspectRatio: false,
        responsive: true,
        "tooltips": {
            "enabled": true,
            "mode": "index",
            "intersect": false,
            "bodyFontSize": 18
        },
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        scales:{
            yAxes: [{
                display: false,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 20,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` LBS`;
                    }
                }
            }],
            xAxes: [{
                display: false,
                gridLines: {
                    drawOnChartArea: true,
                    drawBorder: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false
        }
    }

    const [dataSets, setDataSets] = useState(initDataSets);
    const [dataSets_pie, setDataSets_pie] = useState(initDataSets_pie);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        configureChart();
    }

    useEffect(() => {
        if (props.myLogs !== undefined) {
            setMyLogs(props.myLogs);
            configureChart();
            getSchedulePrediction();
        }
    }, [props.myLogs]);

    useEffect(() => {
        //if (props.myLogs !== undefined) {
            configureChart();
            getSchedulePrediction();
        //}
    }, [props.goal]);

    useEffect(() => {
        configureChart();
        getSchedulePrediction();
    }, [myLogs]);

    function configureChart() {
        if (props.goal === undefined) { return; }

        let startingTimestamp = props.goal.startingTimestamp;
        let targetTimestamp = props.goal.targetTimestamp;
        let currentTimestamp = Date.now();

        let daysLeft = (targetTimestamp - currentTimestamp) / (24 * 60 * 60 * 1000)
        setDaysRemaining(Math.round(daysLeft));

        let dt_total = targetTimestamp - startingTimestamp;
        let dt_total_days = dt_total / (24 * 60 * 60 * 1000); // number of days between goal extents

        let targetMetric = props.goal.targetMetric;
        var startingMetric = myLogs.length === 0 ? 0 : myLogs[0].metric;
        if (startingMetric < 0) {
            // -1 valued starting metric -- re-adjust for newest non-NaN metric
            if (myLogs.length > 0) {
                for (var i = 0; i < myLogs.length; i++) {
                    if (startingMetric < 0) {
                        startingMetric = myLogs[i].metric;
                    }
                }
            }
        }
        let delta_metric = targetMetric - startingMetric;
        setIsUpwardGoal(delta_metric > 0);

        let m = delta_metric / dt_total_days; // linear slope of metric progression per day


        var previousTimestamp = startingTimestamp;
        // Compose data
        var myLogsPoints: any[] = [];
        myLogsPoints.push(startingMetric);

        for (var i = 0; i < myLogs.length; i++) {
            let thisLog = myLogs[i];
            let dtSincePrev = thisLog.timestamp - previousTimestamp;
            let daysToFill = (dtSincePrev / (24 * 60 * 60 * 1000));

            for (var j = 0; j < daysToFill - 1; j++) {
                myLogsPoints.push(NaN);
            }
            previousTimestamp = thisLog.timestamp;
            myLogsPoints.push(thisLog.metric < 0 ? NaN : thisLog.metric);
        }



        // Compose Trajectory Points
        var trajectoryPoints: number[] = [];
        trajectoryPoints.push(startingMetric);
        for (var i = 0; i < dt_total_days; i++) {
            //let thisTrajPoint = (m * i) + startingMetric;
            trajectoryPoints.push(NaN);
        }

        let numExtraDays = 6;
        trajectoryPoints.push(targetMetric);
        for (var i = 0; i < numExtraDays; i++) {
            trajectoryPoints.push(NaN);
        }
        trajectoryPoints.push(targetMetric);

        // Compose Labels 
        var labels: any[] = [];
        for (var i = 0; i < trajectoryPoints.length; i++) {
            labels.push(i);
        }


        
        


        const skipped = (ctx: any, value: any) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
        const down = (ctx: any, value: any) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

        let finalDataSets = {
            labels: labels,
            datasets: [
                {
                    label: 'Trajectory',
                    backgroundColor: 'rgba(217,46,46,0)',
                    borderColor: `#2B2B30`,
                    borderWidth: 1.5,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    type: 'line',
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    spanGaps: true,
                    data: trajectoryPoints
                },
                {
                    label: 'Progress',
                    backgroundColor: function(context: any) {
                        const chart = context.chart;
                        const {ctx, chartArea} = chart;

                        if (!chartArea) {
                          // This case happens on initial chart load
                          return;
                        }
                        return getGradient(ctx, chartArea);
                    },
                    borderColor: `rgba(73,89,193,1.0)`,
                    borderWidth: 1.5,
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    type: 'line',
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4,
                    spanGaps: true,
                    data: myLogsPoints,
                    segment: {
                        borderColor: (ctx: any) => { skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)')} ,
                        borderDash: (ctx: any) => { skipped(ctx, [6, 60])} ,
                    },
                }
            ]
        };

        setDataSets(finalDataSets);


        // compose pie chart data
        if (myLogs.length === 0 || props.goal === undefined) { return; }
        //let newestLog = myLogs[myLogs.length - 1];

        var newestLog = myLogs.length === 0 ? 0 : myLogs[myLogs.length - 1].metric;
        if (newestLog < 0) {
            // -1 valued starting metric -- re-adjust for newest non-NaN metric
            if (myLogs.length > 0) {
                for (var i = myLogs.length - 1; i >= 0; i--) {
                    if (newestLog < 0) {
                        newestLog = myLogs[i].metric;
                    }
                }
            }
        }

        let isLowering = targetMetric < startingMetric;

        var percentComplete = ((newestLog - startingMetric) / (targetMetric - startingMetric)) * 100;
        percentComplete = percentComplete < 0.0 ? 0.0 : percentComplete;
        setCompletion(Math.round(percentComplete));
        var tempDataSets_pie = JSON.parse(JSON.stringify(initDataSets_pie));
        tempDataSets_pie.datasets[0].data = [percentComplete,100 - percentComplete];
        setDataSets_pie(tempDataSets_pie);
    }


    function getGradient(ctx: any, chartArea: any) {
        // var gradient;
        // let width = 100;
        // let height = 200;
        // const chartWidth = chartArea.right - chartArea.left;
        // const chartHeight = chartArea.bottom - chartArea.top;
        // if (!gradient || width !== chartWidth || height !== chartHeight) {
        //     // Create the gradient because this is either the first render
        //     // or the size of the chart has changed
        //     width = chartWidth;
        //     height = chartHeight;
        //     gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        //     gradient.addColorStop(0, `rgba(255,0,0,1)`);
        //     gradient.addColorStop(0.5, `rgba(0,255,0,1)`);
        //     gradient.addColorStop(1, `rgba(0,0,255,1)`);
        // }

        var gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, 'rgba(91,98,255,0.6)');   
        gradient.addColorStop(1, 'rgba(91,98,255,0)');

        return gradient;
    }

    function getGoalName() {
        if (props.goal === undefined || props.goal.type === undefined) { return 'Goal'; }

        var resultString = "Goal";
        switch (props.goal.type) {
            case "custom": 
                resultString = "Custom Goal";
                break;
            case "get-fit": 
                resultString = "Custom Goal";
                break;
            case "loose-weight": 
                resultString = "Loose Weight";
                break;
            case "gain-weight": 
                resultString = "Gain Mass";
                break;
            case "muscle-toning": 
                resultString = "Lowering Body Fat";
                break;
            case "body-building": 
                resultString = "Bodybuilding";
                break;
            case "power-lifting": 
                resultString = "Power Lifting";
                break;
            case "cardio-endurance":
                resultString = "Cardio Endurance";
                break;
            default: 
                break;
        }

        return resultString;
    }

    function getGoalDesc() {
        if (props.goal === undefined || props.goal.type === undefined) { return `Your Client's goal has not been defined`; }

        var resultString = "Goal";
        var units = "";

        switch (props.goal.type) {
            case "custom": 
                resultString = "a custom goal";
                break;
            case "get-fit": 
                units = "days/wk"
                resultString = "a custom goal";
                break;
            case "loose-weight": 
                resultString = "to loose weight";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "gain-weight": 
                resultString = "to gain muscle mass";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "muscle-toning": 
                resultString = "to lower body fat";
                units = "%";
                break;
            case "body-building": 
                resultString = "to body build";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "power-lifting": 
                resultString = "to power lift";
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "cardio-endurance":
                resultString = "improve cardio endurance";
                units = "mins";
                break;
            default: 
                break;
        }



        let startingTimestamp = props.goal.startingTimestamp;
        let targetTimestamp = props.goal.targetTimestamp;

        let dt_total = targetTimestamp - startingTimestamp;
        let dt_total_days = Math.round(dt_total / (24 * 60 * 60 * 1000));

        var startingMetric = myLogs.length === 0 ? 0 : myLogs[0].metric;
        if (startingMetric < 0) {
            // -1 valued starting metric -- re-adjust for newest non-NaN metric
            if (myLogs.length > 0) {
                for (var i = 0; i < myLogs.length; i++) {
                    if (startingMetric < 0) {
                        startingMetric = myLogs[i].metric;
                    }
                }
            }
        }

        let returnString = `${props.client_name}'s goal is ${resultString}${startingMetric === -1 ? '' : ` from ${startingMetric}${units}`} to ${ props.goal.targetMetric}${units} in ${dt_total_days} days`

        return returnString;
    }

    function getTargetString() {
        if (props.goal === undefined || props.goal.type === undefined) { return `--.-`; }

        var units = ""
        switch (props.goal.type) {
            case "custom": 
                units = ""
                break;
            case "get-fit": 
                units = "days/wk"
                break;
            case "loose-weight": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "gain-weight": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "muscle-toning": 
                units = "%";
                break;
            case "body-building": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "power-lifting": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "cardio-endurance":
                units = "mins";
                break;
            default: 
                break;
        }

        return `${props.goal.targetMetric}${units}`
    }

    function getStartingString() {
        if (myLogs.length === 0 || props.goal === undefined || props.goal.type === undefined) { return `--.-`; }

        var units = getUnits();

        var startingMetric = myLogs.length === 0 ? 0 : myLogs[0].metric;
        if (startingMetric < 0) {
            // -1 valued starting metric -- re-adjust for newest non-NaN metric
            if (myLogs.length > 0) {
                for (var i = 0; i < myLogs.length; i++) {
                    if (startingMetric < 0) {
                        startingMetric = myLogs[i].metric;
                    }
                }
            }
        }

        let newestLog = myLogs[myLogs.length - 1];
        return `${Math.round(startingMetric)}${units}`
    }

    function getUnits() {
        if (myLogs.length === 0 || props.goal === undefined || props.goal.type === undefined) { return ""; }

        var units = ""
        switch (props.goal.type) {
            case "custom": 
                units = ""
                break;
            case "get-fit": 
                units = "days/wk"
                break;
            case "loose-weight": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "gain-weight": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "muscle-toning": 
                units = "%";
                break;
            case "body-building": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "power-lifting": 
                units = props.goal.targetUnitsKG ? "KGs" : "LBS";
                break;
            case "cardio-endurance":
                units = "mins";
                break;
            default: 
                break;
        }

        return units;
    }

    function getGraphDotBottom() {
        if (myLogs.length === 0 || props.goal === undefined) { return `20px`; }

        let newestLog = myLogs[myLogs.length - 1];

        let newestMetric = newestLog.metric;

        let maxMetric = props.goal.targetMetric;

        let chartYOffset = 8;
        let pxPerMax = (200 - chartYOffset) / maxMetric;

        return `${(pxPerMax * newestMetric) + chartYOffset - 10}px`;

    }

    function getGraphDotLeft() {
        if (myLogs.length === 0 || props.goal === undefined) { return `6px`; }
        let newestLog = myLogs[myLogs.length - 1];

        let startingTimestamp = props.goal.startingTimestamp;
        let targetTimestamp = props.goal.targetTimestamp;
        let dt_total = targetTimestamp - startingTimestamp;
        let dt_total_days = (dt_total / (24 * 60 * 60 * 1000)) + 7; // number of days between goal extents

        let newestTimestamp = newestLog.timestamp;

        let dt_newestTimestamp = newestTimestamp - startingTimestamp;
        let dt_days_newest = dt_newestTimestamp / (24 * 60 * 60 * 1000);

        //// console.log("GOAL PROGRESS CHART: total days to newest log: ", dt_days_newest);
        
        //let dt_targetTimestamp = targetTimestamp - startingTimestamp;
        let percentPerTimestamp = 100 / dt_total_days;

        //// console.log("GOAL PROGRESS CHART: total days to newest log: ", dt_days_newest, `${dt_days_newest * percentPerTimestamp}%`, dt_total_days);

        let chartXOffset = -9;

        return `calc(${((dt_days_newest) * percentPerTimestamp)}% + ${chartXOffset}px)`
    }

    function getLineLeft() {
        if (myLogs.length === 0 || props.goal === undefined) { return `calc(100% - 24px)`; }
        let newestLog = myLogs[myLogs.length - 1];

        let startingTimestamp = props.goal.startingTimestamp;
        let targetTimestamp = props.goal.targetTimestamp;
        let dt_total = targetTimestamp - startingTimestamp;
        let dt_total_days = (dt_total / (24 * 60 * 60 * 1000)) + 7; // number of days between goal extents

        let newestTimestamp = targetTimestamp;

        let dt_newestTimestamp = newestTimestamp - startingTimestamp;
        let dt_days_newest = dt_newestTimestamp / (24 * 60 * 60 * 1000);

        //// console.log("GOAL PROGRESS CHART: total days to newest log: ", dt_days_newest);
        
        //let dt_targetTimestamp = targetTimestamp - startingTimestamp;
        let percentPerTimestamp = 100 / dt_total_days;

        //// console.log("GOAL PROGRESS CHART: total days to newest log: ", dt_days_newest, `${dt_days_newest * percentPerTimestamp}%`, dt_total_days);

        let chartXOffset = -1;

        return `calc(${((dt_days_newest) * percentPerTimestamp)}% + ${chartXOffset}px)`
    }

    function getEndDate() {
        if (props.goal === undefined) { return; }

        let targetTimestamp = props.goal.targetTimestamp;
        let targetDate = new Date(targetTimestamp);

        return `${moStrings[targetDate.getMonth()]} ${targetDate.getDate()}`
    }

    function getStartDate() {
        if (props.goal === undefined) { return; }

        let startingTimestamp = props.goal.startingTimestamp;
        let startingDate = new Date(startingTimestamp);

        return `${moStrings[startingDate.getMonth()]} ${startingDate.getDate()}`
    }

    function getSchedulePrediction() {
        if (myLogs.length === 0 || props.goal === undefined) { 
            setSummary('No Logs'); 
            return;
        }



        var startingMetric = myLogs.length === 0 ? 0 : myLogs[0].metric;
        if (startingMetric < 0) {
            // -1 valued starting metric -- re-adjust for newest non-NaN metric
            if (myLogs.length > 0) {
                for (var i = 0; i < myLogs.length; i++) {
                    if (startingMetric < 0) {
                        startingMetric = myLogs[i].metric;
                    }
                }
            }
        }

        let startingTS = props.goal.startingTimestamp;
        let targetMetric = props.goal.targetMetric;
        let targetTS = props.goal.targetTimestamp;
        let deltaMetric_target = targetMetric - startingMetric;

        var mostRecentMetricLog = myLogs.length === 0 ? 0 : myLogs[myLogs.length - 1].metric;
        if (mostRecentMetricLog < 0) {
            // -1 valued starting metric -- re-adjust for newest non-NaN metric
            if (myLogs.length > 0) {
                for (var i = myLogs.length - 1; i >= 0; i--) {
                    if (mostRecentMetricLog < 0) {
                        mostRecentMetricLog = myLogs[i].metric;
                    }
                }
            }
        }

        //let mostRecentMetricLog = lastLog.metric;
        let mostRecentTS = myLogs.length === 0 ? Date.now() : myLogs[myLogs.length - 1].timestamp;
        let deltaMetric_mostRecent = targetMetric - mostRecentMetricLog;//mostRecentMetricLog - startingMetric;


        let remainingRounded = Math.abs(Math.round(deltaMetric_mostRecent * 10) / 10);
        let remainingString = `${remainingRounded < 10 ? '0' : ''}${remainingRounded}${remainingRounded % 1 === 0 ? '.0' : ''} ${getUnits()}`
        setReaminingInGoal(remainingString)

        let completion = 1 - Math.abs(deltaMetric_mostRecent / deltaMetric_target);
        let completionRounded = Math.round(completion * 1000) / 10;
        let completionString = `${completionRounded < 10 ? '0' : ''}${completionRounded}${completionRounded % 1 === 0 ? '.0' : ''}`
        //setGoalCompletion(completionString);

        // Goal description string
        //let workoutGoalTitles = ["Workout vol", "Cutting", "Bulking", "Cutting", "Changing", "Lifting"];
        //let goalDescString_temp = `${workoutGoalTitles[mostRecentGoalData.index]} from ${Math.round(startingMetric)}${thisGoalsUnits} to ${Math.round(targetMetric)}${thisGoalsUnits}`;
        ////setGoalDescString(goalDescString_temp);
        //setGoalTitle(goalDescString_temp);

        // Determind ahead or behind schedule
        let m_target = Math.abs(targetMetric - startingMetric) / (targetTS - startingTS);
        let m_actual = Math.abs(Number(mostRecentMetricLog) - Number(startingMetric)) / (mostRecentTS - startingTS);

        // m_actual = (180-160)/(1641441465380-1640997838000) = 0.00000004508288
        // m_target = 

        var returnString = '';
        if (Math.abs(m_target) > Math.abs(m_actual)) {
            //setScheduleRelative(-1);
            returnString = 'Behind Schedule'
        } else {
            //setScheduleRelative(1);
            returnString = 'Ahead of Schedule'
        }


        

        // // console.log("m_target = ", m_target);
        // // console.log("m_actual = ", m_actual);
        // // console.log("mostRecentMetricLog = ", mostRecentMetricLog);
        // // console.log("targetMetric = ", targetMetric);
        // // console.log("startingMetric = ", startingMetric);
        // // console.log("mostRecentTS = ", mostRecentTS);
        // // console.log("targetTS = ", targetTS);
        // // console.log("startingTS = ", startingTS);

        let predictedTS = (Math.abs(targetMetric - startingMetric) / m_actual) + startingTS; //((targetMetric - startingMetric) / m_actual) + startingTS;
        // // console.log("PREDICTED TS: ", predictedTS);

        let predDate = new Date(predictedTS);

        let monthStrings = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        let predictedDate_temp = `${monthStrings[predDate.getMonth()]} ${predDate.getDate()}, ${predDate.getFullYear()}`
        // // console.log("Predicted date = ", predictedDate_temp);
        setPredictedDate(myLogs.length < 3 ? 'Not enough data' : (isNaN(predDate.getMonth()) === true ? 'Predicition Unavailable' : predictedDate_temp));

        let deltaInDays = m_actual * (24 * 60 * 60 * 1000);
        setChangePerDay(`${Math.round(deltaInDays * 10) / 10} ${getUnits()}/day`)

        setSummary(returnString);
    }

    function getInfoString() {
        if (props.goal === undefined || props.goal.type === undefined) { return ""; }

        var response = ""
        switch (props.goal.type) {
            case "custom": 
                response = "Custom Goal types such as this are calculated using manual inputs. Press 'New Log' to add a new data point."
                break;
            case "get-fit": 
                response = `This Goal is calculated using ${props.client.first_name}'s workout information. Start a workout with them to add to this metric.`
                break;
            case "loose-weight": 
                response = `This Goal is calculated using ${props.client.first_name}'s body measurement data. Press 'New Log' to make note of their current body measurements.`
                break;
            case "gain-weight": 
                response = `This Goal is calculated using ${props.client.first_name}'s body measurement data. Press 'New Log' to make note of their current body measurements.`
                break;
            case "muscle-toning": 
                response = `This Goal is calculated using ${props.client.first_name}'s body measurement data. Press 'New Log' to make note of their current body measurements.`
                break;
            case "body-building": 
                response = `This Goal is calculated using ${props.client.first_name}'s body measurement data. Press 'New Log' to make note of their current body measurements.`
                break;
            case "power-lifting": 
                response = `This Goal is calculated using ${props.client.first_name}'s workout information. Press 'Start a workout with them to add to this metric.`
                break;
            case "cardio-endurance":
                response = `This Goal is calculated using ${props.client.first_name}'s workout data, in addition to manual inputs. Press 'New Log' to make note of new updates to their progress.`
                break;
            default: 
                break;
        }

        return response;
    }

	return (
		<div className={`client-goal-progress ${props.hideBottom !== undefined && props.hideBottom === true ? 'client-goal-progress-no-border' : ''}`}>
            <div className="client-goal-progress-content">
                <div className="client-goal-progress-content-upper">
                    <div className="client-goal-progress-content-upper-col client-goal-progress-content-upper-col-0">
                        <div className="client-goal-progress-content-upper-col-pie-container">
                            <div className="client-goal-progress-content-upper-col-pie-overlay"/>
                            <Pie data={dataSets_pie} options={chartOptions_pie}/>
                        </div>
                        <div className="client-goal-progress-content-upper-col-text-container">
                            <p>Completed</p>
                            <h4>{completion}%</h4>
                        </div>
                    </div>
                    <div className="client-goal-progress-content-upper-col client-goal-progress-content-upper-col-1">
                        <div className="client-goal-progress-content-upper-col-text-container">
                            <p>{daysRemaining < 0 ? 'Time since target' : 'Time remaining'}</p>
                            <h4>{Math.abs(daysRemaining)} days</h4>
                        </div>
                    </div>
                    <div className="client-goal-progress-content-upper-col client-goal-progress-content-upper-col-2">
                        <div className="client-goal-progress-content-upper-col-text-container">
                            <p>Summary</p>
                            <h4>{summary}</h4>
                        </div>
                    </div>
                </div>
                <div className="client-goal-progress-content-progress-graph-container">
                    <div hidden={true} className="client-goal-progress-content-progress-graph-dot" style={{bottom: getGraphDotBottom(), left: getGraphDotLeft()}}/>
                    <div className="client-goal-progress-content-progress-graph-end-line" style={{left: getLineLeft()}}>
                        <div className="client-goal-progress-content-progress-graph-end-line-inner">
                            <div className="client-goal-progress-content-progress-graph-end-line-tick"/>
                            <div className="client-goal-progress-content-progress-graph-end-line-text-container"><p>{getEndDate()}</p></div>
                        </div>
                    </div>
                    <div className={`client-goal-progress-content-progress-graph-target-container ${isUpwardGoal ? '' : 'client-goal-progress-content-progress-graph-target-container-downward'}`}><p>{getTargetString()}</p></div>
                    <div className={`client-goal-progress-content-progress-graph-starting-container ${isUpwardGoal ? '' : 'client-goal-progress-content-progress-graph-starting-container-downward'}`}><p>{getStartingString()}</p></div>
                    <div className="client-goal-progress-content-progress-graph-start-line-inner">
                        <div className="client-goal-progress-content-progress-graph-start-line-tick"/>
                        <div className="client-goal-progress-content-progress-graph-start-line-text-container"><p>{getStartDate()}</p></div>
                    </div>
                    <Line
                        data={dataSets}
                        options={chartOptions_line}/>
                </div>
                <div hidden={props.hideBottom !== undefined && props.hideBottom === true} className="client-goal-progress-content-lower">
                    <div className="client-goal-progress-content-lower-col client-goal-progress-content-lower-col-0">
                        <div className="client-goal-progress-content-lower-col-text-container">
                            <p>Remaining in Goal</p>
                            <h4>{remainingInGoal}</h4>
                        </div>
                    </div>
                    <div className="client-goal-progress-content-lower-col client-goal-progress-content-lower-col-1">
                        <div className="client-goal-progress-content-lower-col-text-container">
                            <p>Change per Day</p>
                            <h4>{changePerDay}</h4>
                        </div>
                    </div>
                    <div className="client-goal-progress-content-lower-col client-goal-progress-content-lower-col-2">
                        <div className="client-goal-progress-content-lower-col-text-container">
                            <p>Projected Completion Date</p>
                            <h4>{predictedDate}</h4>
                        </div>
                    </div>
                </div>
                <div hidden={props.hideBottom !== undefined && props.hideBottom === true} className="client-goal-progress-content-lower-info">
                    <p>{getInfoString()}</p>
                </div>
            </div>
		</div>
	)
}
// style={{top: getGraphDotBottom(), left: getGraphDotLeft()}}

export default ClientGoalProgress;