import React, { useState, useEffect } from 'react';
import {Line, Bar, Scatter} from 'react-chartjs-2';

import './WorkoutSummary.css';
import './IntraWorkoutMain.css';
import './IntraWorkoutStats.css';
import '../DashboardPageCommon.css';

import SetStats_t from '../../../Interfaces/SetStats_t';

import FullAnalysisComposition from '../../../components/Charts/FullAnalysisComposition';
import RepXWeightBreakdown from '../../../components/Charts/RepXWeightBreakdown';

function WorkoutSummary(props: any) {

    let initDataSets = {
        datasets: [
            {
                label: 'Reps vs Weight',
                backgroundColor: 'rgba(73,89,193,0.9)',
                hoverBackgroundColor: `rgba(73,89,193,1)`,
                data: []
            }
        ]
    };

    const [dataSets, setDataSets] = useState(initDataSets);

    var chartOptions: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },layout: {
            padding: {
                left: 0,
                right: 0,
                top: 24,
                bottom: 0
            }
        },
        scales:{
            yAxes: [{
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 20,
                    callback: function(value: any, index: any, values: any) {
                        return value + ` LBS`;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false
        }
    }

    const [initSet, setInitSet] = useState(false);
    const [setStats, setSetStats] = useState<SetStats_t[]>();
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        setSetStats(props.currentWorkout.setStats);

        if (props.currentWorkout.setStats === undefined || props.currentWorkout.setStats.length === 0) { return; }
        
        let stats: SetStats_t[][][] = props.currentWorkout.setStats;

        var points_temp: any[] = [];
        for (var i = 0; i < stats.length; i++) {
            let thisSeriesStats: SetStats_t[][] = stats[i];

            for (var j = 0; j < thisSeriesStats.length; j++) {
                let thisSetStats: SetStats_t[] = thisSeriesStats[j];

                for (var k = 0; k < thisSetStats.length; k++) {
                    let thisExerciseStat: SetStats_t = thisSetStats[k];
                    let numReps = thisExerciseStat.manualEntryReps;
                    points_temp.push({x:numReps, y:thisExerciseStat.weight});
                }
            }
        }

        var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        tempDataSets.datasets[0].data = points_temp;

        setDataSets(tempDataSets);




        // let stats: SetStats_t[][] = props.currentWorkout.setStats;

        // var points_temp: any[] = [];
        // for (var i = 0; i < stats.length; i++) {
        //     let thisExerciseStats = stats[i];
        //     for (var j = 0; j < thisExerciseStats.length; j++) {
        //         let thisSetStats = thisExerciseStats[j];
        //         let numReps = thisSetStats.repStats.length;
        //         points_temp.push({x:numReps, y:thisSetStats.weight});
        //     }
        // }

        // var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        // tempDataSets.datasets[0].data = points_temp;

        // setDataSets(tempDataSets);

    }, [props]);

	return (
		<div className="page-container">
            <div className="summary-client-header">
                <p>{props.currentWorkout !== undefined ? `Completed ${props.currentWorkout.workout.title}` : `Workout completed`}</p>
                <h4>{props.currentWorkout !== undefined ? `${props.currentWorkout.client.first_name}'s Workout Summary` : 'Your Client\'s Workout Summary'}</h4>
                <div onClick={() => props.done()} className="summary-done-button">Done</div>
            </div>
			<div className="summary-content-container">
                <div className="intra-workout-stats-card">
                    <div className="intra-workout-stats-card-header-container">
                        <p>Workout Stats Overview</p>
                        <h3>Set-by-set Analysis</h3>
                    </div>
                    <FullAnalysisComposition
                        setStats={setStats}/>
                </div>
                <div className="intra-workout-stats-card">
                    <div className="intra-workout-stats-card-header-container">
                        <p>In-depth analysis</p>
                        <h3>Reps vs Weight Distribution</h3>
                    </div>
                    <div className="intra-workout-stats-chart-container">
                        <Scatter
                            data={dataSets}
                            options={chartOptions}
                        />
                    </div>
                </div>
                <div className="intra-workout-stats-card">
                    <div className="intra-workout-stats-card-header-container">
                        <p>List View</p>
                        <h3>Rep x Weight Exercise Breakdown</h3>
                    </div>
                    <div className="intra-workout-stats-chart-container-large">
                        <RepXWeightBreakdown
                            workout={props.currentWorkout.workout}
                            setStats={setStats}/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default WorkoutSummary;