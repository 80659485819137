import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import './IntraWorkoutMain.css';
import '../DashboardPageCommon.css';
import firebase from '../../../Firebase';

import PageHeader_t from '../../../Interfaces/PageHeader_t';
import Client_t from '../../../Interfaces/Client_t';
import Workout_t from '../../../Interfaces/Workout_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import SetStats_t from '../../../Interfaces/SetStats_t';
import Tempo_t from '../../../Interfaces/Tempo_t';


import StandardButton from '../../../components/Base/StandardButton';
import PageHeader from '../../../components/Base/PageHeader';

import IntraWorkoutSeriesList from './IntraWorkoutSeriesList';
import IntraWorkoutOverview from './IntraWorkoutOverview';
import IntraWorkoutCurrentExercise from './IntraWorkoutCurrentExercise';
import IntraWorkoutStats from './IntraWorkoutStats';
import IntraWorkoutNodes from './IntraWorkoutNodes';
import IntraWorkoutOptions from './IntraWorkoutOptions';
import IntraWorkoutSummaryCard from './IntraWorkoutSummaryCard';

import AlertBlock from '../../../components/Base/AlertBlock';


import '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


function IntraWorkoutMain(props: any) {

	let pageOptions: PageHeader_t[] = [{ name:"Set Summary", alert:false }, 
					   				   { name:"Statistics", alert:false },
					   				   { name:"Notes", alert:false },
					   				   { name:"Options", alert: false}]

    let initExercise: Exercise_t = {title:'',id:'',type: 0,muscles:[]};
    let initExerciseSeries: ExerciseSeries_t = {title: '', index: 0, exercises: [], type: 0, isShowing: false};
	let initWorkout: Workout_t = {
						title: 'Quick Arms Assault',
						id: '',
						createdBy: {name: '', id: ''},
						exercises: [
							{title:'First Exercise',id:'',type: 0,muscles:[{group:0,muscle:1},{group:0,muscle:0}],reps:[16,12,8,12]},
							{title:'Second Exercise',id:'',type: 0,muscles:[{group:2,muscle:1},{group:2,muscle:0}],reps:[16,12,8,12]},
							{title:'Third Exercise',id:'',type: 0,muscles:[{group:2,muscle:1},{group:2,muscle:0}],reps:[24,24,24,24]},
							{title:'Fourth Exercise',id:'',type: 0,muscles:[{group:3,muscle:1},{group:3,muscle:0}],reps:[16,12,8,12]}
						],
						duration: 80,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};
	let initClient: Client_t = {first_name:'',
								last_name:'',
								full_name:'',
								id:'', 
								isMetric: true, 
								gender: 'b', 
								birthdate: '00-00-0000',
								height: 0, 
								weight: 0,
								goalIndex: 0,
								experienceIndex: 0};

	const [pageNumber, setPageNumber] = useState(0);
	const [workflowState, setWorkflowState] = useState(0);
	const [totalSecondsCount, setTotalSecondsCount] = useState(0);
	const [pageTitle, setPageTitle] = useState("Workout session");
	const [pageSubTitle, setPageSubTitle] = useState("Workout session");

	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	const [client, setClient] = useState<Client_t>(initClient);

	const [setStats, setSetStats] = useState<SetStats_t[]>();

	//const [selectedSeries, setSelectedSeries] = useState<ExerciseSeries_t>(initExerciseSeries);
	//const [selectedExercise, setSelectedExercise] = useState<Exercise_t>(initExercise);
	const [selectedSetIndex, setSelectedSetIndex] = useState(0);
	const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
	const [selectedExerciseSeriesIndex, setSelectedExerciseSeriesIndex] = useState(0);

	const [showAlert, setShowAlert] = useState(false);

	const [initWMRequested, setInitWMRequested] = useState(false);

	//const [currentExerciseIndex, setCurrentExerciseIndex] = useState(1);

	const [initSet, setInitSet] = useState(false);
	const [isActive, setIsActive] = useState(false);
	var testCount = 0;
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
    	if (props.currentWorkout !== undefined) {
    		if (props.currentWorkout.client !== undefined) {
	    		setClient(props.currentWorkout.client);
	    	}
	    	if (props.currentWorkout.workout !== undefined) {
	    		setWorkout(props.currentWorkout.workout);
	    	}
        	
    	}

    	//props.startSet();
    	configureTitle();
    	fireSecondsTimer();
    }

    useEffect(() => {
        if (props.currentWorkout !== undefined) {
        	setSetStats(props.currentWorkout.setStats);

			if (initWMRequested === false) {
				setInitWMRequested(true);
				let currentExercise: Exercise_t = props.currentWorkout.workout.exerciseSeries[props.currentWorkout.seriesIndex].exercises[props.currentWorkout.exerciseIndex];
	        	if (currentExercise.userWorkingMaxHistory === undefined) {
	        		props.configureWorkingMaxHistory(currentExercise, client);
	        	} else {
	        		// console.log("Working max history set for exercise!");
	        		// console.log(currentExercise.userWorkingMaxHistory);
	        	}
			} 
        	
        	
        }
        configureTitle();
    }, [props.currentWorkout]);

    

    function fireSecondsTimer() {
    	if (props.currentWorkout !== undefined) {
    		var d = new Date();
  			var n = d.getTime();
  			var time = Math.floor(n / 1000);
  			var dt = time - props.currentWorkout.startTime;
  			testCount = dt;
  			setTotalSecondsCount(testCount);
    	}
    	setTimeout(fireSecondsTimer, 100);
		return;
    }

    function configureTitle() {
    	setPageTitle(workout.title);
    	setPageSubTitle(`Current Workout with ${client.first_name}`);
    }

	function pageSelected(newPage: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setPageNumber(i);
			}
		}
	}

	function seeAllStats() {
		setPageNumber(2);
	}

	function nextSetPressed() {
		//// console.log(setStats);
		// console.log("NEXT SET PRESSED ");

		props.startSet();


		// if (isActive) {
		// 	props.completeSet();
		// 	if (props.nodeManager !== undefined) {
		// 		props.nodeManager.endDataStream();
		// 	}
		// } else {
		// 	props.startSet();
		// 	// if (pageNumber !== 1) {
		// 	// 	setPageNumber(1);
		// 	// }
			
		// 	if (props.nodeManager !== undefined) {
		// 		props.nodeManager.beginDataStream();
		// 	}
		// }
		
		setIsActive(!isActive);
	}

	function updateWeight(toWeight: number) {
		props.updateWeight(toWeight);
	}

	function updateWeightPressed() {
		setPageNumber(1);
	}

	function addNotePressed() {
		props.addNotePressed();
	}

	function alertPressed() {
	 	setPageNumber(3);
	}

	function getSetString() {

		var totalNumSets = 0;
		var currentSetCount = 0;

		let numExercises = workout.exercises !== undefined ? workout.exercises.length : 0;

		let currentExerciseIndex = props.currentWorkout !== undefined ? props.currentWorkout.exerciseIndex : 0;
		let currentSetIndex = props.currentWorkout !== undefined ? props.currentWorkout.setIndex : 0;

		if (numExercises > 0) {
			for (var i = 0; i < numExercises; i++) {
				let thisExercise: Exercise_t = workout.exercises !== undefined ? workout.exercises[i] : {title:'',id:'',type: 0,muscles:[]};
				totalNumSets += thisExercise.reps !== undefined ? thisExercise.reps.length : 0;
				currentSetCount += i < currentExerciseIndex ? (thisExercise.reps !== undefined ? thisExercise.reps.length : 0) : (i === currentExerciseIndex ? (currentSetIndex + 1) : 0);
			}
		}

		return `Set ${currentSetCount < 10 ? '0' : ''}${currentSetCount} / ${totalNumSets < 10 ? '0' : ''}${totalNumSets}`
	}

	function exerciseSelected(seriesIndex: number, exerciseIndex: number) {
		//setSelectedSeries(workout.exerciseSeries !== undefined ? workout.exerciseSeries[seriesIndex] : initExerciseSeries);
		//setSelectedExercise(workout.exerciseSeries !== undefined ? (workout.exerciseSeries[seriesIndex].exercises[exerciseIndex]) : initExercise);
		setSelectedExerciseSeriesIndex(seriesIndex);
		setSelectedExerciseIndex(exerciseIndex);
		setSelectedSetIndex(-1);
	}

	function setWasSelected(seriesIndex: number, exerciseIndex: number, setIndex: number) {
		//setSelectedSeries(workout.exerciseSeries !== undefined ? workout.exerciseSeries[seriesIndex] : initExerciseSeries);
		//setSelectedExercise(workout.exerciseSeries !== undefined ? (workout.exerciseSeries[seriesIndex].exercises[exerciseIndex]) : initExercise);
		setSelectedExerciseSeriesIndex(seriesIndex);
		setSelectedExerciseIndex(exerciseIndex);
		setSelectedSetIndex(setIndex);
	}

	function movedToNextSet() {
		props.startSet();
	}

	return (
		<div className="page-container intra-workout-container">
			
			<div className="client-header">
				<PageHeader 
					pageName={pageTitle}
					pageOptions={pageOptions}
					selectedPageIndex={pageNumber}
					pageSelected={(e: string) => pageSelected(e)}
					showBack={false}
					pageSubtitle={pageSubTitle}
					isIntraWorkout={true}
					recordSetPressed={() => nextSetPressed()}
					addNotePressed={() => addNotePressed()}
					endWorkoutPressed={() => props.endWorkout()}/>
			</div>
			<div className="intra-workout-content-container">
				<div className="intra-workout-overview-container">
					<div hidden={true} className="intra-workout-overview-container-header">
						<h4>Workout Overview</h4>
						<p>{getSetString()}</p>
					</div>
					<div className="intra-workout-overview-container-content-container">
						<IntraWorkoutSeriesList 
							nodeManager={props.nodeManager}
							totalSecondsCount={totalSecondsCount}
							currentWorkout={props.currentWorkout}
							alertPressed={() => props.openNodesPage()}
							exerciseSelected={(ser_i: number, ex_i: number) => exerciseSelected(ser_i, ex_i)}
							setWasSelected={(ser_i: number, ex_i: number, set_i: number) => setWasSelected(ser_i, ex_i, set_i)}
							changeWeight={(toWeight: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => props.changeWeight(toWeight, forSeriesIndex, forExerciseIndex, setIndex)}
							changeReps={(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => props.changeReps(toReps, forSeriesIndex, forExerciseIndex, setIndex)}
							movedToNextSet={() => movedToNextSet()}
							workout={workout}/>
					</div>
				</div>
				<div className="intra-workout-outer-page-container">
					<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 0}>
						<IntraWorkoutOverview
							currentWorkout={props.currentWorkout}
							workout={workout}
							selectedSetIndex={selectedSetIndex}
							selectedExerciseIndex={selectedExerciseIndex}
							selectedExerciseSeriesIndex={selectedExerciseSeriesIndex}
							seeAllStats={() => seeAllStats()}
							setStats={setStats}
							openNodesPressed={() => props.openNodesPage()}
							viewStats={() => pageSelected(pageOptions[1].name)}
							changeReps={(toReps: string) => props.changeReps(toReps, selectedExerciseSeriesIndex, selectedExerciseIndex, selectedSetIndex)}
							changeWeight={(toWeight: string) => props.changeWeight(toWeight, selectedExerciseSeriesIndex, selectedExerciseIndex, selectedSetIndex)}
							changeTempo={(toTempo: Tempo_t) => props.changeTempo(toTempo, selectedExerciseSeriesIndex, selectedExerciseIndex, selectedSetIndex)}
                            changeRest={(toRest: string) => props.changeRest(toRest, selectedExerciseSeriesIndex, selectedExerciseIndex, selectedSetIndex)}/>
					</div>
					<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 1}>
						<IntraWorkoutStats 
							selectedSetIndex={selectedSetIndex}
							selectedExerciseIndex={selectedExerciseIndex}
							selectedExerciseSeriesIndex={selectedExerciseSeriesIndex}
							currentWorkout={props.currentWorkout}/>

					</div>
				</div>
			</div>
		</div>
	)
}

/*

<div onClick={() => alertPressed()} hidden={!showAlert} className="intra-workout-alert-container">
				<AlertBlock 
					alertText="A Node has lost connection! Make sure you're in range and the Nodes are powered ON."/>
			</div>

<IntraWorkoutSummaryCard
				isActive={isActive}
				currentWorkout={props.currentWorkout}
				nextSetPressed={() => nextSetPressed()}
				updateWeightPressed={() => updateWeightPressed()}
				totalSecondsCount={totalSecondsCount}/>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 0}>
				<IntraWorkoutOverview
					currentExerciseIndex={props.currentWorkout !== undefined ? props.currentWorkout.exerciseIndex : 2}
					currentSetIndex={props.currentWorkout !== undefined ? props.currentWorkout.setIndex : 2}
					workout={workout}
					seeAllStats={() => seeAllStats()}
					setStats={setStats}/>
			</div>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 1}>
				<IntraWorkoutCurrentExercise
					nodeManager={props.nodeManager}
					updateWeight={(e: number) => updateWeight(e)}
					currentWorkout={props.currentWorkout}
					currentExerciseIndex={props.currentWorkout !== undefined ? props.currentWorkout.exerciseIndex : 2}
					currentSetIndex={props.currentWorkout !== undefined ? props.currentWorkout.setIndex : 2}
					workout={workout}/>
			</div>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 2}>
				<IntraWorkoutStats
					setStats={setStats}
					workout={workout}/>
			</div>
			
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 3}>
				<IntraWorkoutNodes
					nodeManager={props.nodeManager}/>
			</div>
			<div className="intraworkout-page-container-no-padding" hidden={pageNumber != 4}>
				<IntraWorkoutOptions
					currentWorkout={props.currentWorkout}
					updateUnits={(e: boolean) => props.updateUnits(e)}
					endWorkout={() => props.endWorkout()}/>
			</div>
*/

export default IntraWorkoutMain;