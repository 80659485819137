
import React, { useState, useEffect } from 'react';
import './StatGraph.css';


function StatGraph(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [finalData, setFinalData] = useState<number[]>([])

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            generateData();
        }
    }, [initSet])

    useEffect(() => {
        generateData();
    }, [props.data])

    const generateData = () => {
        if (!props.data) return;

        let dataTemp: any[] = JSON.parse(JSON.stringify(props.data))
        if (props.statMax) dataTemp.push(props.statMax);
        if (props.statMin) dataTemp.push(props.statMin);

        let maxVal = props.data.length === 0 ? 1 : Math.max(...dataTemp);

        var dataFiltered: number[] = [];
        for (var i = 0; i < props.data.length; i++) {
        	dataFiltered.push(maxVal === 0 ? 0 : props.data[i] / maxVal * 100);
        }
        
        // console.log("dataFiltered ->", dataFiltered)
        setFinalData(dataFiltered)

    }

    const formatData = (data: any[], startingTS: number) => {

        var finalData_temp: number[] = [];

        for (var i = 0; i < data.length; i++) {
            let thisPoint = data[i];
            let thisValue = thisPoint.metric;
            let thisTimestamp = thisPoint.timestamp;

            if (i > 0) {
                let lastPoint = data[i - 1];
                let lastValue = lastPoint.metric;
                let lastTimestamp = lastPoint.timestamp;

                let msDelta = thisTimestamp - lastTimestamp;
                let dayDelta = Math.floor(msDelta / (24 * 3600 * 1000));
                if (dayDelta > 1) {
                    for (var j = 0; j < dayDelta; j++) {
                        finalData_temp.push(lastValue)
                    }
                    finalData_temp.push(lastValue)
                } else if (dayDelta <= 1) {
                    finalData_temp.push(thisValue)
                }
            } else {
                let msDelta_start = thisTimestamp - startingTS;
                let dayDelta_start = Math.floor(msDelta_start / (24 * 3600 * 1000));
                if (dayDelta_start > 1) {
                    for (var j = 0; j < dayDelta_start; j++) {
                        finalData_temp.push(thisValue)
                    }
                }
                finalData_temp.push(thisValue)
            }
        }

        return finalData_temp;
    }

    const padDataWithZeros = (data: (number | null)[], desiredLength: number) => {
        const sanitizedData = data.map((value) => (value === null ? 0 : value));
        while (sanitizedData.length < desiredLength) {
            sanitizedData.push(0);
        }
        return sanitizedData;
    }

    const getTargetStyle = () => {

        let dataTemp: any[] = JSON.parse(JSON.stringify(props.data))
        if (props.statMax) dataTemp.push(props.statMax);
        if (props.statMin) dataTemp.push(props.statMin);
        
        let maxVal = props.data.length === 0 ? 1 : Math.max(...dataTemp);
        let thisTargetValMax = props.statMax ?? 0;
        let thisTargetValMin = props.statMin ?? 0;

        let heightDelta = Math.abs(thisTargetValMax - thisTargetValMin);
        let heightDeltaPercent = maxVal === 0 ? 0 : (heightDelta / maxVal) * 100;
        heightDeltaPercent = heightDeltaPercent <= 0 ? 0 : heightDeltaPercent;
        
        let bottomPosPercent = maxVal === 0 ? 0 : (thisTargetValMin / maxVal) * 100;
        bottomPosPercent = bottomPosPercent <= 0 ? 0 : bottomPosPercent;

        let color = props.color ?? "#5B62FF";

        let targetStyle = { height: `${heightDeltaPercent}%`, bottom: `${bottomPosPercent}%`, borderColor: color, opacity: heightDeltaPercent < 0 ? 0 : 1.0 };
        return targetStyle;
    }

    const getAverageStyle = () => {
        if (!props.statAvg || props.statAvg <= 0) return { height: `0%`, bottom: `0%`, opacity: 0 };
        let dataTemp: any[] = JSON.parse(JSON.stringify(props.data))
        
        if (props.statMax) dataTemp.push(props.statMax);
        if (props.statMin) dataTemp.push(props.statMin);
        
        let maxVal = props.data.length === 0 ? 1 : Math.max(...dataTemp);
        let thisAvgVal = props.statAvg ?? 0;
        let bottomPosPercent = maxVal === 0 ? 0 : (thisAvgVal / maxVal) * 100;
        bottomPosPercent = bottomPosPercent <= 0 ? 0 : bottomPosPercent;

        let thisStatMax = props.statMax ?? -1;
        let thisStatMin = props.statMin ?? -1;

        let isValid = thisStatMax <= 0 || thisStatMin <= 0 ? true : ( thisAvgVal <= thisStatMax && thisAvgVal >= thisStatMin )

        let color = isValid ? ( props.color ?? "#5B62FF" ) : "#6C6C6C";

        let targetStyle = { height: `2px`, bottom: `${bottomPosPercent}%`, borderColor: color, opacity: 1.0 };
        return targetStyle;
    }

    const getTargetMaxPercent = () => {
        if (!props.statMax) return 0;

        let dataTemp: any[] = JSON.parse(JSON.stringify(props.data))
        if (props.statMax) dataTemp.push(props.statMax);
        if (props.statMin) dataTemp.push(props.statMin);

        let maxVal = props.data.length === 0 ? 1 : Math.max(...dataTemp);
        let thisTargetValMax = props.statMax ?? 0;

        let maxValPercent = maxVal === 0 ? 0 : (thisTargetValMax / maxVal) * 100;
        maxValPercent = maxValPercent <= 0 ? 0 : maxValPercent;

        return maxValPercent;
    }

    const getTargetMinPercent = () => {
        if (!props.statMin) return 0;

        let dataTemp: any[] = JSON.parse(JSON.stringify(props.data))
        if (props.statMax) dataTemp.push(props.statMax);
        if (props.statMin) dataTemp.push(props.statMin);

        let maxVal = props.data.length === 0 ? 1 : Math.max(...dataTemp);
        let thisTargetValMin = props.statMin ?? 0;

        let minValPercent = maxVal === 0 ? 0 : (thisTargetValMin / maxVal) * 100;
        minValPercent = minValPercent <= 0 ? 0 : minValPercent;

        return minValPercent;
    }

    const getDataValid = (dataPoint: number) => {
        let thisMax = getTargetMaxPercent();
        let thisMin = getTargetMinPercent();

        if (!dataPoint || thisMax === thisMin || thisMin < 0 || thisMax < 0) return true;

        return dataPoint >= thisMin && dataPoint <= thisMax;
    }

    // const getTargetStyle = (targetType: "min" | "max") => {

    //     let dataTemp: any[] = JSON.parse(JSON.stringify(props.data))
    //     if (props.statMax) dataTemp.push(props.statMax);
    //     if (props.statMin) dataTemp.push(props.statMin);
        
    //     let maxVal = props.data.length === 0 ? 1 : Math.max(...dataTemp);
    //     let thisTargetVal = targetType === "min" ? props.statMin ?? -1 : props.statMax ?? -1;
    //     thisTargetVal = maxVal === 0 ? 0 : (thisTargetVal / maxVal) * 100;
    //     thisTargetVal = thisTargetVal <= 0 ? 0 : thisTargetVal;
        
    //     let color = props.color ?? "#5B62FF";

    //     let targetStyle = { height: `${thisTargetVal}%`, borderColor: color, opacity: thisTargetVal < 0 ? 0 : 1.0 };
    //     if (props.units === "W") // console.log("thisTargetVal", targetType, targetType === "min" ? props.statMin ?? -1 : props.statMax ?? -1, targetStyle)
    //     return targetStyle;
    // }

    const getBarStyle = (val: number, index: number) => {
        let lastVal = index === 0 ? 0 : finalData[index - 1];
        let pointValid = getDataValid(val);
        let color = pointValid ? props.color ?? "#5B62FF" : "#6C6C6C";
        return { height: `${val}%`, backgroundColor: `${color}60` };
    }

    const getBarTopStyle = (val: number, index: number) => {
        let pointValid = getDataValid(val);
        let color = pointValid ? props.color ?? "#5B62FF" : "#6C6C6C";
        return { backgroundColor: color };
    }

    

    const getStartingDateString = () => {
        if (!props.goalData || !props.goalData.goal) return "";
        let goalData = props.goalData.goal;
        let startingTS = goalData.starting_timestamp;
        if (startingTS < 1) return "";
        return getDateString(startingTS, false, false);
    }

    const getTargetDateString = () => {
        if (!props.goalData || !props.goalData.goal) return "";
        let goalData = props.goalData.goal;
        let endingTS = goalData.target_timestamp;
        if (endingTS < 1) return "";
        return getDateString(endingTS, false, false);
    }

    const getDateString = (timestamp: number, fullMonth: boolean, includeYear?: boolean) => {
        const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const moStringsFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        let thisDate = new Date(timestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo_index = thisDate.getMonth();
        let thisDate_mo = fullMonth ? moStringsFull[thisDate_mo_index] : moStrings[thisDate_mo_index];
        let thisDate_yr = thisDate.getFullYear();

        return `${thisDate_mo} ${thisDate_day}${includeYear ? `, ${thisDate_yr}` : ''}`;
    }

    const getStat = (forIndex: number) => {
    	if (!props.data || !props.data[forIndex]) return `-- ${props.units}`;
    	return `${Math.floor(props.data[forIndex] * 100) / 100} ${props.units}`
    }

	return (
		<div className={ "stat-graph" }>
            
			<div className={ "stat-graph-bar-container" }>
                {
                    finalData.map((item: number, index: number) => (
                        <div className={ "stat-graph-bar" } style={ getBarStyle(item, index) } key={ `goal-progress-bar-${index}` }> 
                        	<div className={ "stat-graph-bar-top" } style={ getBarTopStyle(item, index) }/>
                            <div className={ "stat-graph-bar-metric-container" }>
                        		<p>{ getStat(index) }</p>
                        	</div>
                        	<div className={ `stat-graph-bar-num-container ${getDataValid(item) ? "" : "stat-graph-bar-num-container-invalid"}`}>
                        		<p>Set { index + 1 < 10 ? '0' : '' }{ index + 1 }</p>
                        	</div>
                        </div>
                    ))
                }
                <div hidden={ finalData.length === 0 || !props.statAvg || props.statAvg <= 0 } className={ "stat-graph-average-container" }>
                    <div className={ "stat-graph-average-container-inner" }>
                        <div className={ "stat-graph-average" } style={ getAverageStyle() }/>
                    </div>
                </div>
                <div hidden={ finalData.length === 0 || !props.statMax || !props.statMin || props.statMax === props.statMin } className={ "stat-graph-target-container" }>
                    <div className={ "stat-graph-target-container-inner" }>
                        <div className={ "stat-graph-target" } style={ getTargetStyle() }/>
                    </div>
                </div>
                {/*<div hidden={ finalData.length === 0 } className={ "stat-graph-target-container" }>
                    <div className={ "stat-graph-target-container-inner" }>
                        <div className={ "stat-graph-target" } style={ getTargetStyle("max") }/>
                        <div className={ "stat-graph-target" } style={ getTargetStyle("min") }/>
                    </div>
                </div>*/}
            </div>
            {/*<div className={ "stat-graph-target-gradient-container" }>
                <img className="stat-graph-target-gradient-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/goal-chart-overlay.png`}/>
            </div>*/}
            <div className={ "stat-graph-date-container" }>
                <div className={ "stat-graph-date stat-graph-date-starting" }>
                    <p>{ getStartingDateString() }</p>
                </div>
                <div className={ "stat-graph-date stat-graph-date-ending" }>
                    <p>{ getTargetDateString() }</p>
                </div>
            </div>
		</div>
	)
}

export default StatGraph;