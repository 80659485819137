import React, { useState, useEffect } from 'react';
import './LogSummary.css';
import './PerformanceAnalysis.css';

import firebase from '../../../Firebase';

import ExecutiveSummaryCard from './components/Cards/ExecutiveSummaryCard';

// Import pages
import OverviewWorkout from './pages/OverviewWorkout';
import SetBreakdown from './pages/SetBreakdown';
import Performance from './pages/Performance';

const pageOptions = [
        {
            title: "Overview",
            id: 'overview'
        },
        {
            title: "Set Breakdown",
            id: 'set_breakdown'
        },
        {
            title: "Performance",
            id: 'performance'
        }
    ]

function LogSummary(props: any) {

    const [dayCount, setDayCout] = useState(28);
    const [selectedPageOption, setSelectedPageOption] = useState(pageOptions[0].id);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [clientID, setClientID] = useState("");
    const [initSet, setInitSet] = useState(false);

    const [pageTitle, setPageTitle] = useState("Workout Summary");

    const [logID, setLogID] = useState("");
    const [logData, setLogData] = useState<any>();
    const [goalData, setGoalData] = useState<any>();

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            initialize();
        }
    }, [initSet])

    useEffect(() => {
        if (props.clientID) setClientID(props.clientID);
    }, [props.clientID])

    useEffect(() => {
        if (props.logID) setLogID(props.logID);
    }, [props.logID])

    useEffect(() => {
        getLogData();
    }, [clientID, logID])

    const initialize = () => {
        
    }

    const getLogData = async () => {

        if (!clientID || clientID === "" || !logID || logID === "") return;

        setIsLoadingData(true);

        // 1. Get log data
        
        let nowTS = Date.now();
        let results = await pullLogData(logID);

        // 1.2 Pull workout data
        let woData: any | undefined;
        if (results.workout_id && results.workout_id !== "") {
            woData = await pullWorkoutData(results.workout_id);
            if (woData && woData.title) setPageTitle(`${woData.title} Summary`)
        }
        
        
        // 2. Get exercise data for each log
        // 2.1 Create an array of each exercise ID found in the logs
        
        var exerciseIDList: string[] = [];
        if (results) {
            let thisLogObj = results;
            if (thisLogObj.exercise_series) {
                for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                    let thisSeries = thisLogObj.exercise_series[j];
                    if (thisSeries && thisSeries.exercises) {
                        for (var k = 0; k < thisSeries.exercises.length; k++) {
                            let thisExercise = thisSeries.exercises[k];
                            if (thisExercise && thisExercise.id && thisExercise.id !== "") {
                                if (!exerciseIDList.includes(thisExercise.id)) exerciseIDList.push(thisExercise.id);
                            } 
                        }
                    }
                }
            }
        }

        // console.log("Exercise IDs:", exerciseIDList)
        var promiseListExercises: any[] = [];
        for (var i = 0; i < exerciseIDList.length; i++) {
            let pr = pullExerciseData(exerciseIDList[i]);
            promiseListExercises.push(pr);
        }

        let exerciseResults = await Promise.allSettled(promiseListExercises);
        

        // 3. Add exercise objects to respective logs
        // 3.1 Create list of exercise objects
        var exerciseObjects: any = {};
        if (exerciseResults) {
            for (var i = 0; i < exerciseResults.length; i++) {
                let thisResult = exerciseResults[i] as {status: string, value: any};
                if (thisResult && thisResult.value) {
                    let thisExerciseObj: any = JSON.parse(JSON.stringify(thisResult.value));
                    exerciseObjects[thisExerciseObj.id ?? "unknown"] = thisExerciseObj;
                }
            }
        }

        

        // 3.2 Organize
        let thisLogObj: any = JSON.parse(JSON.stringify(results));
                    
        if (thisLogObj.exercise_series) {
            for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                let thisSeries = thisLogObj.exercise_series[j];
                if (thisSeries && thisSeries.exercises) {
                    for (var k = 0; k < thisSeries.exercises.length; k++) {
                        let thisExercise = thisSeries.exercises[k];
                        if (thisExercise && thisExercise.id && thisExercise.id !== "") {

                            if (exerciseObjects[thisExercise.id]) {
                                thisExercise["data"] = exerciseObjects[thisExercise.id]
                            }
                        } 
                    }
                }
            }
        }

        let finalLogObj = { log: thisLogObj, workout: woData };
        // console.log(`----- Done pulling LOG DATA in ${Date.now() - nowTS}mS -----`);
        // console.log("LOG:", finalLogObj);
        setLogData(finalLogObj);

        setIsLoadingData(false);
    }

    const pullLogData = (logID: string) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let logData = await getDBDirect(`workout_logs/${clientID}/${logID}`);
                resolve(logData);
            } catch (err) {
                resolve({ });
            }
        })
    }

    const pullWorkoutData = (id: string) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let exerciseData = await getDBDirect(`workouts/${id}`);
                exerciseData = { ...exerciseData, id: id };
                resolve(exerciseData);
            } catch (err) {
                resolve({ });
            }
        })
    }

    const pullExerciseData = (id: string) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let exerciseData = await getDBDirect(`exercises/${id}`);
                exerciseData = { ...exerciseData, id: id };
                resolve(exerciseData);
            } catch (err) {
                resolve({ });
            }
        })
    }

    const getDBDirect = async (location: string) => {
        return new Promise<any>(async (resolve, reject) => {
            if (location === "") reject();
            try {
                let database = firebase.database();
                let ref = database.ref(location);
                ref.once('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        resolve(snapshot.val());
                    } else {
                        reject();
                    }
                })
                .catch((err: any) => {
                    reject(err);
                })
            } catch (err) {
                reject(err);
            }
        })
        
    }

    const pageOptionSelected = (pageOption: any) => {
        if (pageOption.id) setSelectedPageOption(pageOption.id);
    }

    const getDateString = (timestamp: number, fullMonth: boolean, includeYear?: boolean) => {
        const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const moStringsFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        let thisDate = new Date(timestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo_index = thisDate.getMonth();
        let thisDate_mo = fullMonth ? moStringsFull[thisDate_mo_index] : moStrings[thisDate_mo_index];
        let thisDate_yr = thisDate.getFullYear();

        let thisDate_hr = thisDate.getHours();
        let thisDate_min = thisDate.getMinutes();

        return `${thisDate_mo} ${thisDate_day}${includeYear ? `, ${thisDate_yr}` : ''}, ${thisDate_hr % 12 < 10 ? '0' : ''}${thisDate_hr % 12}:${thisDate_min < 10 ? 0 : ''}${thisDate_min}${thisDate_hr < 12 ? 'am' : 'pm'}`;
    }

    const getPageDateString = () => {
        if (!logData || !logData.log || !logData.log.starting_timestamp || logData.log.starting_timestamp < 1) return "Unknown Date";

        let ts = logData.log.starting_timestamp;
        return getDateString(ts, true, false);
    }

	return (
		<div className={ "" }>
            <div className={"performance-analysis-data-content"} hidden={ isLoadingData }>
                <div className={ "performance-analysis-data-content-header" }>
                    <div className={ "performance-analysis-data-content-header-title-container" }>
                        <h4>{ pageTitle } <span>| { getPageDateString() }</span></h4>
                    </div>
                    <div className={ "performance-analysis-data-content-header-page-options-container" }>
                        {
                            pageOptions.map((item: any, index: number) => (
                                 <div onClick={ () => pageOptionSelected(item) } className={ `performance-analysis-data-content-header-page-option ${item.id === selectedPageOption ? "performance-analysis-data-content-header-page-option-selected" : ""}` }>
                                    <p>{ item.title }</p>
                                    <div className={ "performance-analysis-data-content-header-page-option-selected-bar" } hidden={ item.id !== selectedPageOption }/>
                                </div>
                            ))
                        }
                    </div>
                </div>     
                <div className={ "performance-analysis-data-content-body" }>
                    {
                        selectedPageOption === "overview" ?
                        <OverviewWorkout 
                            logData={ logData }
                            clientName={ props.clientName }
                            openSetsPressed={ () => setSelectedPageOption(pageOptions[1].id)}/>
                        : selectedPageOption === "set_breakdown" ? 
                        <SetBreakdown
                            logData={ logData }
                            clientName={ props.clientName }/>
                        : selectedPageOption === "performance" ?
                        <Performance 
                            logData={ logData }
                            goalData={ goalData }
                            clientName={ props.clientName }/>
                        :
                        null
                    }
                    
                </div>   
            </div>
            <div className={"performance-analysis-data-content performance-analysis-data-content-loading"} hidden={ !isLoadingData }>
                <div className="client-list-container-loading-spinner"/>
                <p>Loading DynamicTrain Data...</p>
            </div>

		</div>
	)
}

export default LogSummary;