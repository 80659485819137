import React, { useState, useEffect } from 'react';
import firebase from '../../../Firebase';
import './AssignModalWorkoutCell.css';

import Workout_t from '../../../Interfaces/Workout_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';
import Tempo_t from '../../../Interfaces/Tempo_t';

import '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

function AssignModalWorkoutCell(props: any) {

    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

    let initWorkout: Workout_t = {
                        title: '',
                        id: '',
                        createdBy: {name: '', id: ''},
                        exercises: [],
                        duration: 80,
                        imageName: '',
                        targetGender: '',
                        totalSets: 0,
                        totalReps: 0,
                        difficulty: 0
                    };

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [muscleList, setMuscleList] = useState<Muscles_t[]>([]);
    const [volume, setVolume] = useState(0);
    const [volume_sets, setVolume_sets] = useState(0);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        getWorkoutData();
    }

    useEffect(() => {
        if (workout.id === '') {
            //getWorkoutData();
        }
        
    }, [props.id]);

    useEffect(() => {
        if (workout !== undefined) {
            props.workoutDataDownloaded(workout);
        }
    }, [workout]);

    async function getWorkoutData() {
        if (props.id === undefined) { return; }
        if (props.workoutData !== undefined && props.workoutData.exercises !== undefined && props.workoutData.exercises.length !== 0) { 
            setIsLoading(false);
            setWorkout(props.workoutData);

            // Calculate volumes
            var volTemp = 0;
            var volSetsTemp = 0;
            if (props.workoutData.exerciseSeries !== undefined) {
                for (var i = 0; i < props.workoutData.exerciseSeries.length; i++) {
                    let thisExerciseSeries = props.workoutData.exerciseSeries[i];
                    if (thisExerciseSeries.exercises !== undefined) {
                        for (var j = 0; j < thisExerciseSeries.exercises.length; j++) {
                            let thisExercise = thisExerciseSeries.exercises[j];
                            if (thisExercise.reps !== undefined) {
                                volSetsTemp += thisExercise.reps.length;
                                for (var k = 0; k < thisExercise.reps.length; k++) {
                                    volTemp += thisExercise.reps[k];
                                }
                            }
                        }
                    }
                }
            }

            setVolume(volTemp);
            setVolume_sets(volSetsTemp);

            return; 
        }

        let thisWorkoutData: any = await getWorkoutInfo(props.id);
        thisWorkoutData["id"] = props.id;

        parsePersonalWorkouts([thisWorkoutData]);
    }

    async function getWorkoutInfo(workoutID: string) {
        return new Promise<any>(resolve => {
            let database = firebase.database();

            let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
            thisWorkoutRef.on('value', function(snapshotWorkout) {
                if (snapshotWorkout.exists() === true) {
                    let thisWorkout = snapshotWorkout.val();
                    resolve(thisWorkout);
                }
            });
        });
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    async function parsePersonalWorkouts(workoutList: any) {

        var temp: Workout_t[] = [];
        for (const key of Object.keys(workoutList)) {

            if (workoutList[key].id === "") {
                let newWorkout: Workout_t = {
                    title: "Rest Day",
                    description: '',
                    id: "",
                    createdBy: {name: "", id: ""},
                    exercises: [],
                    exerciseSeries: [],
                    duration: 0,
                    imageName: "",
                    targetGender: "",
                    totalSets: 0,
                    totalReps: 0,
                    difficulty: 0,
                    goals: [],
                    downloads: 0,
                    timesCompleted: 0
                };
                temp.push(newWorkout);
            } else {
                let thisWorkout = workoutList[key];

                let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

                // Configure Exercise Series
                var seriesList_temp: ExerciseSeries_t[] = [];
                let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
                if (containsExerciseSeries === true) {
                    for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
                        let thisSeriesObj = thisWorkout.exercise_series[i];
                        let thisSetLetter = getSetLetter(i);

                        var exerciseList_temp: Exercise_t[] = [];
                        if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
                            for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
                                let thisExerciseObj = thisSeriesObj.exercises[j];
                                let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

                                exerciseList_temp.push(exTemp);
                            }
                        }

                        var seriesTemp: ExerciseSeries_t = {
                            title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
                            index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
                            type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
                            exercises: exerciseList_temp
                        };

                        seriesList_temp.push(seriesTemp);
                    }
                } else {
                    // Workout was created "pre-series". Create a new placeholder series for each existing exercise
                    if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
                        for (var i = 0; i < thisWorkout.exercises.length; i++) {
                            let thisExerciseTemp: any = thisWorkout.exercises[i];
                            let thisSetLetter = getSetLetter(i);
                            var seriesTemp: ExerciseSeries_t = {
                                title: thisSetLetter,
                                index: i,
                                type: 0,
                                exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
                            };
                            seriesList_temp.push(seriesTemp);
                        }
                    }
                }

                var exerciseList: Exercise_t[] = [];
                if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

                    var reps_avg = 0.0;
                    var reps_avg_count = 0;
                    var exerciseReps: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var a: number[] = [];
                        if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
                                let thisRep = thisWorkout.exercises[i].reps[j];
                                a.push(thisRep);
                                reps_avg += thisRep;
                                reps_avg_count += 1;
                            }
                        }
                        exerciseReps[thisWorkout.exercises[i].key] = a;

                        if (i === 0) {
                            //setRepScheme(a);
                        }
                    }

                    //setAvgReps(reps_avg / reps_avg_count);

                    var exerciseTempos: any = {};
                    for (var i = 0; i < thisWorkout.exercises.length; i++) {
                        var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
                        if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
                            for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
                                let thisTempoObj = thisWorkout.exercises[i].tempos[j];
                                let thisTempo: Tempo_t = {
                                    concentric: thisTempoObj.concentric,
                                    eccentric: thisTempoObj.eccentric,
                                    first_pause: thisTempoObj.first_pause,
                                    second_pause: thisTempoObj.second_pause
                                };
                                b.push(thisTempo);
                            }
                        }
                        exerciseTempos[thisWorkout.exercises[i].key] = b;
                    }

                    let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
                    exerciseList = e;


                    // push exercises into according slots in seriesList_temp
                    if (exerciseList.length > 0) {
                        for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
                            let thisExercise: Exercise_t = exerciseList[ex_index];
                            let thisExercise_ID = thisExercise.id;

                            // Loop through seriesList_temp to find thisExercise's matching location
                            if (seriesList_temp.length > 0) {
                                for (var i = 0; i < seriesList_temp.length; i++) {
                                    let thisSeries = seriesList_temp[i];
                                    if (thisSeries.exercises.length > 0) {
                                        for (var j = 0; j < thisSeries.exercises.length; j++) {
                                            let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
                                            let thisSeriesExercise_ID = thisSeriesExercise.id;
                                            if (thisSeriesExercise_ID === thisExercise_ID) {
                                                // ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
                                                // for the case of multiple of the same exercises in a workout
                                                if (thisSeriesExercise.title === '') {
                                                    seriesList_temp[i].exercises[j] = thisExercise;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
                let newWorkout: Workout_t = {
                    title: thisWorkout.title,
                    description: thisWorkout.description !== undefined ? thisWorkout.description: '',
                    id: thisWorkout.id,
                    createdBy: createdBy,
                    exercises: exerciseList,
                    exerciseSeries: seriesList_temp,
                    duration: Number(thisWorkout.duration),
                    imageName: thisWorkout.image_name,
                    targetGender: thisWorkout.target_gender,
                    totalSets: Number(thisWorkout.total_sets),
                    totalReps: Number(thisWorkout.total_reps),
                    difficulty: Number(thisWorkout.difficulty),
                    goals: thisWorkout.goals,
                    downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
                    timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
                };
                temp.push(newWorkout);
            }
        }

        var totalVol = 0;
        var totalVolSets = 0;
        for (var i = 0; i < temp.length; i++) {
            totalVol += temp[i].totalReps > 0 ? temp[i].totalReps : 0;
            totalVolSets += temp[i].totalSets;
        }

        setVolume(totalVol);
        setVolume_sets(totalVolSets);

        // setAvgReps(totalVol / totalVolSets);

        // setWorkouts(temp);
        setWorkout(temp[0]);
        configMusclesList(temp);
        setIsLoading(false);
    }

    async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
        return new Promise<Exercise_t[]>(resolve => {
            let database = firebase.database();
            var completedIDs = [];
            var exerciseList: Exercise_t[] = [];

            for (var i = 0; i < exercises.length; i++) {
                let thisExercise = exercises[i];
                let thisExerciseKey = thisExercise.key;
                let thisExerciseReps = repBreakdown[thisExerciseKey];
                let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

                let ref = database.ref(`exercises/${thisExerciseKey}`) 
                ref.on('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        let key = snapshot.key;
                        let thisExercise = snapshot.val();
                        completedIDs.push(thisExercise.title);
                        
                        if (thisExercise !== null) {

                            let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
                            let newExercise: Exercise_t = {
                                title:thisExercise.title,
                                id: key !== null && key !== undefined ? key : "",
                                type: Number(thisExercise.type),
                                muscles:thisExercise.muscles,
                                equipment: thisExercise.equipment,
                                setup: thisExercise.setup,
                                steps: thisExercise.steps,
                                createdBy: thisExercise.created_by,
                                restTime: [thisRestTime],
                                reps: thisExerciseReps,
                                tempos: thisExerciseTempos
                            }

                            if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
                                // Motion info data exists - assign to thisMotionInfo

                                var thisMotionInfo = thisExercise.motion_info;
                                newExercise.motionInfo = thisMotionInfo;
                            }

                            exerciseList.push(newExercise);
                        }

                        if (completedIDs.length >= exercises.length) {
                            resolve(exerciseList);
                        }
                    }
                });
            }
        });
    }

    function configMusclesList(withWorkouts: Workout_t[]) {
        var tempList: Muscles_t[] = [];
        for (var i = 0; i < withWorkouts.length; i++) {
            let thisWorkout: Workout_t = withWorkouts[i];
            if (thisWorkout.exercises !== undefined && thisWorkout.exercises.length > 0) {
                var returnString = '';
                for (var j = 0; j < thisWorkout.exercises.length; j++) {
                    let thisExercise = thisWorkout.exercises[j];

                    if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                        for (var k = 0; k < thisExercise.muscles.length; k++) {
                            let thisMuscle = thisExercise.muscles[k];
                            if (!tempList.includes(thisMuscle)) {
                                tempList.push(thisMuscle);
                            }
                            // let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
                            // if (!returnString.includes(thisMuscleGroupString)) {
                            //     let finalString = i + j === 0 ? thisMuscleGroupString : (i === workout.exercises.length - 1 && j === thisExercise.muscles.length - 1 ? ` and ${thisMuscleGroupString}` : `, ${thisMuscleGroupString}`);
                            //     returnString += finalString;
                            // }
                        }
                    }

                }
            }
        }

        setMuscleList(tempList);

    }

    function getMusclesString(workout: Workout_t) {
        if (workout.exercises !== undefined && workout.exercises.length > 0) {
            var returnString = '';
            for (var i = 0; i < workout.exercises.length; i++) {
                let thisExercise = workout.exercises[i];
                if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
                    for (var j = 0; j < thisExercise.muscles.length; j++) {
                        let thisMuscleGroupIndex= thisExercise.muscles[j].group;
                        let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
                        if (!returnString.includes(thisMuscleGroupString)) {
                            let finalString = i + j === 0 ? thisMuscleGroupString : (i === workout.exercises.length - 1 && j === thisExercise.muscles.length - 1 ? ` and ${thisMuscleGroupString}` : `, ${thisMuscleGroupString}`);
                            returnString += finalString;
                        }
                    }
                }
            }
            return returnString;
        } else {
            return `No muscles defined`
        }
    }

	return (
		<div className="assign-modal-workout-cell">
			<div onClick={() => props.workoutSelected(workout)} hidden={isLoading} className="assign-modal-workout-cell-content">
                <div className="assign-modal-workout-cell-content-col assign-modal-workout-cell-content-col-xs">
                    <p>DAY {(props.index + 1) < 10 ? '0':''}{(props.index + 1)}</p>
                </div>
                <div className="assign-modal-workout-cell-content-col assign-modal-workout-cell-content-col-lg">
                    <h4>{workout.title}</h4>
                </div>
                <div className="assign-modal-workout-cell-content-col">
                    <h4>{getMusclesString(workout)}</h4>
                </div>
                <div className="assign-modal-workout-cell-content-col">
                    <h4>{volume_sets} sets, {volume} reps</h4>
                </div>
                <div className="assign-modal-workout-cell-content-col-arrow">
                    <ArrowForwardIos className="assign-modal-workout-cell-content-col-arrow-icon"/>
                </div>
            </div>
            <div hidden={!isLoading} className="assign-modal-workout-cell-loading">
                <p>Loading Workout...</p>
            </div>
		</div>
	)
}

export default AssignModalWorkoutCell;