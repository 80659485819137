import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import firebase from '../../../Firebase';
import Mixpanel from '../../../Mixpanel';
import FormTextInput from '../../../components/Base/FormTextInput';
import Selector from '../../../components/Base/Selector';
import CheckBox from '../../../components/Base/CheckBox';

import './ReferralRegister.css';


// example URL:
// http://localhost:8100/register-referral?id=3vfq1lXvRgTkuhgLy9ZODEq2XzH2&notif_id=-Mw2qmZzDx0NV8heYROl&studio_id=-Mw-1RLuBwDTF_oKJqIr&referrer_id=3fCdlRTmfWSuozlTNHFoou8c1LU2&ts=1645037168965

function ReferralRegister(props: any) {

    let history = useHistory();

    let experienceOptions = ["Less than 1 year", "Between 1-2 years", "Between 2-5 years", "Between 5-10 years", "More than 10 years"];

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [workflowState, setWorkflowState] = useState(0);
    const [studioName, setStudioName] = useState("Jim's Gym");
    const [referrerName, setReferrerName] = useState("Jim Jones");
    const [userEmail, setUserEmail] = useState("myemail@mail.com");

    const [name, setName] = useState("");
    const [pw, setPW] = useState("");
    const [pw_confirm, setPw_confirm] = useState("");
    const [yrExp, setYrExp] = useState(1);

    const [agreedToTOS, setAgreedToTOS] = useState(false);

    const [alerts, setAlerts] = useState<boolean[]>([false,false,false,false]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        // console.log("user ID = ", GetURLParameter('id'));
        setIsLoading(true);

        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            // console.log('User successfully logged out');
            pullData();
        }).catch(function(error) {
            // An error happened.
            // console.log(`Logout error occured: ${error}`)
            pullData();
        });
        
    }

    function pullData() {

        let userID = GetURLParameter('id');
        let referrerID = GetURLParameter('referrer_id');
        let studioID = GetURLParameter('studio_id');

        // 1. Pull current user account
        let database = firebase.database();
        let userRef = database.ref(`personal_trainer_profile/${userID}`);
        userRef.once('value', function(user_snapshot) {
            if (user_snapshot.exists() === true) {
                let data = user_snapshot.val();
                let userEmailTemp = data.email !== undefined ? data.email : "No email provided";
                setUserEmail(userEmailTemp);

                // 2. Pull referrer info and get name
                let referrerRef = database.ref(`personal_trainer_profile/${referrerID}`);
                referrerRef.once('value', function(referrer_snapshot) {
                    if (referrer_snapshot.exists() === true) {
                        let data = referrer_snapshot.val();
                        let referrerNameTemp = data.full_name;
                        setReferrerName(referrerNameTemp);

                        // 3. Pull studio info and get name
                        let studioRef = database.ref(`training_studios/${studioID}`);
                        studioRef.once('value', function(studio_snapshot) {
                            if (studio_snapshot.exists() === true) {
                                let data = studio_snapshot.val();
                                let studioNameTemp = data.name;
                                setStudioName(studioNameTemp);
                                setIsLoading(false);
                            }
                        });
                    }
                });
            }
        });
    }

    function GetURLParameter(sParam: string) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    }

    function checkPressed(withState: boolean) {
        setAgreedToTOS(withState);
        validatePage(true, 'y', 'tos-agree');
    }

    function inputChanged(toString: any, fromInput: string) {
        // console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)
        switch (fromInput) {
            case 'name':
                setName(toString);
                break;
            case 'pw':
                setPW(toString);
                break;
            case 'pw-confirm':
                setPw_confirm(toString);
                break;
            case 'experience':
                let yrExpIndex = experienceOptions.indexOf(toString);
                let yrOptions = [1,2,5,8,10];
                setYrExp(yrExpIndex < 0 ? 0 : (yrExpIndex > 4 ? yrOptions[4] : yrOptions[yrExpIndex]));
                break;
            default:
                // console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
                break;
        }

        // Check if alerts need to be cleared
        var clearRequired = false;
        for (var i = 0; i < alerts.length; i++) {
            if (alerts[i] === true) {
                clearRequired = true;
            }
        }

        if (clearRequired) {
            validatePage(true, toString, fromInput);
        }
    }

    function validatePage(presentAlert?: boolean, newInput?: string, newInputType?: string) {

        let displayValidationAlerts = presentAlert !== undefined ? presentAlert : false;

        // Confirm name
        var valid_name = false;

        let nameTemp = newInputType !== undefined && newInputType === 'name' ? (newInput !== undefined ? newInput : name) : name;
        let names = nameTemp.split(' ');
        let fname = names.length >= 1 ? names[0] : "";
        let lname = names.length >= 2 ? names[1] : "";

        valid_name = names.length >= 2 && lname !== "";

        // if (displayValidationAlerts === true) {
        //     setAlerts(alerts.map((item, index) => { return index === 0 ? valid_name : item }));
        // }

        // Confirm password
        let pwTemp = newInputType !== undefined && newInputType === 'pw' ? (newInput !== undefined ? newInput : pw) : pw;
        let regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/;
        let valid_pw = regEx.test(pwTemp);

        // Confirm confirm password
        let pwConfirmTemp = newInputType !== undefined && newInputType === 'pw-confirm' ? (newInput !== undefined ? newInput : pw_confirm) : pw_confirm;
        let valid_pw_confirm = (pwTemp === pwConfirmTemp) && valid_pw;

        // Confirm pressed TOS agreement
        let currentTosAgreeString = agreedToTOS ? 'y' : 'n';
        let tosAgreeConfirmTemp = newInputType !== undefined && newInputType === 'tos-agree' ? (newInput !== undefined ? newInput : currentTosAgreeString) : currentTosAgreeString;
        let currentTosAgreeBool = tosAgreeConfirmTemp === 'y';

        if (displayValidationAlerts) {
            setAlerts([!valid_name, !valid_pw, !valid_pw_confirm, !currentTosAgreeBool]);
        }

        var errorFound = false;
        for (var i = 0; i < alerts.length; i++) {
            if (alerts[i] === true) {
                errorFound = true;
            }
        }

        return !errorFound;
    }

    function continuePressed() {
        let allGood = validatePage(true);
        if (allGood) {
            updateTrainerData();
        }
    }

    function updateTrainerData() {

        setWorkflowState(1);

        let defaultPW = "i8pa0ARrtSF";
        firebase.auth().signInWithEmailAndPassword(userEmail, defaultPW).then((userCred: any) => {
            // console.log("SIGNED IN", userCred);

            // Update user profile
            let names = name.split(' ');
            let fname = names.length >= 1 ? names[0] : "";
            let lname = names.length >= 2 ? names[1] : "";

            let database = firebase.database();
            let ref = database.ref(`personal_trainer_profile/${userCred.user.uid}`);
            ref.child('first_name').set(fname);
            ref.child('last_name').set(lname);
            ref.child('full_name').set(name);
            ref.child('is_configured').set(true);

            ref.child('years_experience').set(yrExp);

            let user = firebase.auth().currentUser;
            if (user !== null) {
                user.updatePassword(pw).then(() => {
                  // Update successful.
                  setWorkflowState(2);
                }, (error) => {
                  // An error happened.
                  setWorkflowState(2);
                });
            }
            


        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // console.log(`ERROR LOGGIN IN ${errorCode}: ${errorMessage}`);
            // ...
        });
    }

    function getFName() {
        let names = name.split(' ');
        let fname = names.length >= 1 ? names[0] : "";
        return fname;
    }

    function goToDashboard() {

        let userID = GetURLParameter('id');
        Mixpanel.identify(userID);
        Mixpanel.people.set({'Most recent login': new Date()});

        history.push('/dashboard');
    }

	return (
		<div className="referral-register">
			<div className="referral-register-left-pane">
                <div className="referral-register-left-pane-background-container">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/register-referral-bg.png`}/>
                </div>
                <div className="referral-register-left-pane-icon-container">
                    <div className="referral-register-eigen-icon">
                        <div className="referral-register-eigen-icon-circle"/>
                        <div className="referral-register-eigen-icon-line"/>
                    </div>
                </div>
                <div className="referral-register-left-pane-text-container">
                    <h2>Expand your practice with connected training.</h2>
                    <h5>View your clients' performance metrics in real-time, whether you are working out together or remotely.</h5>
                </div>
                <div className="referral-register-left-pane-name-container">
                    <h4>Eigen Fitness</h4>
                </div>
            </div>
            <div hidden={workflowState !== 0} className="referral-register-right-pane">
                <div hidden={!isLoading} className="referral-register-right-pane-loading-curtain">
                    <div className="referral-register-right-pane-loading-container">
                        <div className="client-list-container-loading-spinner"/>
                        <p>Loading Info...</p>
                    </div>
                </div>
                <div className="referral-register-right-pane-content">
                    <div className="referral-register-right-pane-content-header">
                        <h2>Set up your Eigen Trainer Account.</h2>
                        <p>You have been invited by <span>{referrerName}</span> to join the <span>{studioName}</span> studio on Eigen Trainer.</p>
                    </div>
                    <div className="referral-register-right-pane-content-body">
                        <div className="referral-register-right-pane-content-body-text-inputs">
                            <FormTextInput 
                                placeholder="Full Name" 
                                alert={alerts[0]} 
                                isSearch={true}
                                alertText="Please enter your first and last name"
                                inputChanged={(e: string) => inputChanged(e, 'name')}/>
                            <div className="referral-register-right-pane-content-body-email-container">
                                <p>{userEmail}</p>
                            </div>
                            <FormTextInput 
                                placeholder="Password" 
                                alert={alerts[1]} 
                                isSearch={true}
                                hideText={true}
                                alertText="Please enter a valid password"
                                inputChanged={(e: string) => inputChanged(e, 'pw')}/>
                            <FormTextInput 
                                placeholder="Confirm Password" 
                                alert={alerts[2]} 
                                isSearch={true}
                                hideText={true}
                                alertText="Please enter a valid password"
                                inputChanged={(e: string) => inputChanged(e, 'pw-confirm')}/>
                            <div className="referral-register-right-pane-content-body-experience-header">
                                <h4>How many years have you been personal training for?</h4>
                            </div>
                            <Selector
                                placeholder="Level of experience"
                                showTitle={false} 
                                options={experienceOptions}
                                inputChanged={(e: number) => inputChanged(e, 'experience')}/>
                        </div>
                        <div className="referral-register-right-pane-content-body-agree-container">
                            <CheckBox stateChanged={(s: boolean) => checkPressed(s)}/>
                            <div className="referral-register-right-pane-content-body-agree-text">
                                <p>I agree with the Eigen Fitness <a href="https://eigenfitness.com/terms">Terms</a> and <a href="https://eigenfitness.com/privacy">Privacy Policy</a></p>
                            </div>
                            <div hidden={!alerts[3]} className="referral-register-right-pane-content-body-agree-alert-container">
                                <div className="referral-register-right-pane-content-body-agree-alert-inner"/>
                            </div>
                        </div>
                        <div className="referral-register-right-pane-content-body-continue-container">
                            <div onClick={() => continuePressed()} className={`referral-register-right-pane-content-body-continue-button ${!validatePage() ? 'referral-register-right-pane-content-body-continue-button-inactive' : ''}`}>
                                <p>Continue</p>
                            </div>
                        </div>
                        <div className="referral-register-right-pane-content-body-signin-container">
                            <h4>Already have an account?</h4>
                            <div onClick={() => history.push('/login')}><h5>Sign in &rarr;</h5></div>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={workflowState !== 1} className="referral-register-right-pane">
                <div className="referral-register-right-pane-loading-curtain">
                    <div className="referral-register-right-pane-loading-container">
                        <div className="client-list-container-loading-spinner"/>
                        <p>Creating Account...</p>
                    </div>
                </div>
            </div>
            <div hidden={workflowState !== 2} className="referral-register-right-pane">
                <div className="referral-register-right-pane-complete-container">
                    <h2>Welcome to Eigen Trainer</h2>
                    <p>We're glad you've joined the team, {getFName()}! Press "Go to Dashboard" below to get started with connected training.</p>
                    <div onClick={() => goToDashboard()} className="referral-register-right-pane-content-body-continue-button referral-register-right-pane-content-body-dashboard-button">
                        <p>Go to Dashboard</p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default ReferralRegister;