import React, { useState, useEffect, useRef } from 'react';
import './NodeStreamAnalyzeChart.css';

import {Line, Bar} from 'react-chartjs-2';

import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import SetStats_t from '../../../Interfaces/SetStats_t';
import Tempo_t from '../../../Interfaces/Tempo_t';
import MotionData_t from '../../../Interfaces/MotionData_t';
import Peripheral_t from '../../../Interfaces/Peripheral_t';
import RepStats_t from '../../../Interfaces/RepStats_t';
import Injury_t from '../../../Interfaces/Injury_t';

interface RepWindow_t {
    start_index: number;
    end_index: number;
    is_rep: boolean;
    injury_id?: string;
}

function NodeStreamAnalyzeChart(props: any) {

    let nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];

    const allRepWindows: {session_id: string, windows: RepWindow_t[]}[] = [
        {
            session_id: "-N80_l0wls5cLXuVvGSh",
            windows: [
                {
                    start_index: 16,
                    end_index: 98,
                    is_rep: true
                },
                {
                    start_index: 99,
                    end_index: 157,
                    is_rep: true
                },
                {
                    start_index: 158,
                    end_index: 223,
                    is_rep: true
                },
                {
                    start_index: 224,
                    end_index: 281,
                    is_rep: true
                },
                {
                    start_index: 282,
                    end_index: 347,
                    is_rep: true
                },
                {
                    start_index: 348,
                    end_index: 432,
                    is_rep: false
                }
            ]
        },
        {
            session_id: "-N80ZOBQf5l83TenZjjj",
            windows: [
                {
                    start_index: 30,
                    end_index: 120,
                    is_rep: true
                },
                {
                    start_index: 121,
                    end_index: 186,
                    is_rep: true
                },
                {
                    start_index: 187,
                    end_index: 256,
                    is_rep: true
                },
                {
                    start_index: 257,
                    end_index: 316,
                    is_rep: true
                },
                {
                    start_index: 317,
                    end_index: 390,
                    is_rep: true
                },
                {
                    start_index: 391,
                    end_index: 448,
                    is_rep: false
                },
            ]
        },
        {
            session_id: "-N80Y7Jyp_fyuoERn56o",
            windows: [
                {
                    start_index: 30,
                    end_index: 86,
                    is_rep: true
                },

                {
                    start_index: 364,
                    end_index: 460,
                    is_rep: false
                },
            ]
        },
        {
            session_id: "-N80XLqJ7rKj__sevq9D",
            windows: [
                {
                    start_index: 15,
                    end_index: 71,
                    is_rep: false
                },
                {
                    start_index: 72,
                    end_index: 131,
                    is_rep: true
                },
                {
                    start_index: 132,
                    end_index: 204,
                    is_rep: true
                },
                {
                    start_index: 205,
                    end_index: 273,
                    is_rep: true
                },
                {
                    start_index: 274,
                    end_index: 326,
                    is_rep: true
                },
                {
                    start_index: 327,
                    end_index: 382,
                    is_rep: true
                },
                {
                    start_index: 383,
                    end_index: 460,
                    is_rep: true
                },
                // {
                //     start_index: 205,
                //     end_index: 204,
                //     is_rep: true
                // },
            ]
        }
    ]

    const showAutomaticReps = false;    // Show red marking lines to indicate where Nodes automatically detected reps

    const quaternionSmoothLength = 0;

    // Vector plotting
    let initDataSets_v = {
        labels: [],
        datasets: [
            {
                label: 'v_x', // Blue
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            },
            {
                label: 'v_y',    // Red
                backgroundColor: 'rgba(193,89,73,0)',
                hoverBackgroundColor: `rgba(193,89,73,0.9)`,
                borderColor: 'rgba(193,89,73,1)',
                hoverBorderColor: `rgba(193,89,73,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            },
            {
                label: 'v_z', // Green
                backgroundColor: 'rgba(73,193,89,0)',
                hoverBackgroundColor: `rgba(73,193,89,0.9)`,
                borderColor: 'rgba(73,193,89,1)',
                hoverBorderColor: `rgba(73,193,89,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            }
        ]
    }
    var chartOptions_v: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 1,
                    callback: function(value: any, index: any, values: any) {
                        return Math.floor(value) + ``;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
    }

    // Quaternion plotting
    let initDataSets_q = {
        labels: [],
        datasets: [
            {
                label: 'q_w',
                backgroundColor: 'rgba(255,255,255,0)',
                hoverBackgroundColor: `rgba(255,255,255,0.9)`,
                borderColor: 'rgba(255,255,255,1)',
                hoverBorderColor: `rgba(255,255,255,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_x', // Blue
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_y',    // Red
                backgroundColor: 'rgba(193,89,73,0)',
                hoverBackgroundColor: `rgba(193,89,73,0.9)`,
                borderColor: 'rgba(193,89,73,1)',
                hoverBorderColor: `rgba(193,89,73,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_z', // Green
                backgroundColor: 'rgba(73,193,89,0)',
                hoverBackgroundColor: `rgba(73,193,89,0.9)`,
                borderColor: 'rgba(73,193,89,1)',
                hoverBorderColor: `rgba(73,193,89,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            }
        ]
    }
    var chartOptions_q: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: false,
                    stepSize: 1,
                    max: 1,
                    min: -1,
                    callback: function(value: any, index: any, values: any) {
                        return Math.floor(value) + ``;
                    }
                },
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
    }

    // Integrated angle plotting
    let initDataSets_q_int = {
        labels: [],
        datasets: [
            {
                label: 'q_x', // Blue
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_y',    // Red
                backgroundColor: 'rgba(193,89,73,0)',
                hoverBackgroundColor: `rgba(193,89,73,0.9)`,
                borderColor: 'rgba(193,89,73,1)',
                hoverBorderColor: `rgba(193,89,73,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_z', // Green
                backgroundColor: 'rgba(73,193,89,0)',
                hoverBackgroundColor: `rgba(73,193,89,0.9)`,
                borderColor: 'rgba(73,193,89,1)',
                hoverBorderColor: `rgba(73,193,89,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            }
        ]
    }
    var chartOptions_q_int: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 0.1,
                    callback: function(value: any, index: any, values: any) {
                        return -1//Math.floor(value) + ``;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 10
                }
            }]
        },
        legend:{
            display:false,
        }
    }

    const containerRef = useRef<HTMLDivElement>(null);

    const [videoPresent, setVideoPresent] = useState(false);

    const [pointOffset, setPointOffset] = useState(-1);


    const [dataSets_v, setDataSets_v] = useState(initDataSets_v);
    const [dataSets_q, setDataSets_q] = useState(initDataSets_q);
    const [dataSets_q_int, setDataSets_q_int] = useState(initDataSets_q_int);
    const [chartSettings_v, setChartSettings_v] = useState(chartOptions_v);
    const [chartSettings_q, setChartSettings_q] = useState(chartOptions_q);
    const [chartSettings_q_int, setChartSettings_q_int] = useState(chartOptions_q_int);

    const [thisDataStreamTS, setThisDataStreamTS] = useState(-1);
    const [analysisAntiquated, setAnalysisAntiquated] = useState(false);

    const [initSet, setInitSet] = useState(false);

    const [numPts, setNumPts] = useState(1);

    const [simIndex, setSimIndex] = useState(-1);

    const [endingIndex, setEndingIndex] = useState(-1);
    const [endingIndecies, setEndingIndecies] = useState<number[]>([]);

    const [hoverPosX, setHoverPosX] = useState(0);

    const [videoProgress, setVideoProgress] = useState(0);

    const [manualRepWindows, setManualRepWindows] = useState<RepWindow_t[]>([])
    const [detectedRepWindows, setDetectedRepWindows] = useState<RepWindow_t[]>([])

    const [testing_risingWindows, setTesting_risingWindows] = useState<RepWindow_t[]>([])
    const [testing_holdWindows, setTesting_holdWindows] = useState<RepWindow_t[]>([])
    const [testing_pauseWindows, setTesting_pauseWindows] = useState<RepWindow_t[]>([])

    const [allMotionData, setAllMotionData] = useState<MotionData_t[][]>([]);
    const [viewingLocationIndex, setViewingLocationIndex] = useState(0);
    const [invertData, setInvertData] = useState(false);

    const [addMore, setAddMore] = useState(true);
    const [addingRepWindow, setAddingRepWindow] = useState<RepWindow_t | undefined>();
    const [addingRepWindows, setAddingRepWindows] = useState(false);
    const [windowsAreReps, setWindowsAreReps] = useState(false);

    const [selectedInjuryType, setSelectedInjuryType] = useState<Injury_t | undefined>(undefined);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

        setInvertData(false);

        if (props.detectedRepWindows !== undefined) {
            updateRepWindowDisplay(props.detectedRepWindows);
        }

        
        if (props.thisSessionID !== undefined) {
            for (var i = 0; i < allRepWindows.length; i++) {
                let thisThing = allRepWindows[i];
                if (props.thisSessionID === thisThing.session_id) {
                    setManualRepWindows(thisThing.windows);
                }
            }
        }

    }

    useEffect(() => {
        if (props.thisSessionID === undefined || props.thisSessionID === "") {
            // console.log("-------------------- CLEARING ALL WINDOWS IN NodeStreamAnalyzeChart by props.thisSessionID", props.thisSessionID);
            setDataSets_v(initDataSets_v);
            setDataSets_q(initDataSets_q);
            setDataSets_q_int(initDataSets_q_int);
            setChartSettings_v(chartOptions_v);
            setChartSettings_q(chartOptions_q);
            setChartSettings_q_int(chartOptions_q_int);
            setNumPts(1);
            setSimIndex(-1);
            setEndingIndex(-1);
            setEndingIndecies(endingIndecies.filter((item, index) => { return false; }));
            setHoverPosX(0);
            setVideoProgress(0);
            setManualRepWindows(manualRepWindows.filter((item, index) => { return false; }));
            setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }));
            setTesting_risingWindows(testing_risingWindows.filter((item, index) => { return false; }));
            setTesting_holdWindows(testing_holdWindows.filter((item, index) => { return false; }));
            setTesting_pauseWindows(testing_pauseWindows.filter((item, index) => { return false; }))
            setAllMotionData(allMotionData.filter((item, index) => { return false; }));
            setViewingLocationIndex(0);
            setAddMore(true);
            setAddingRepWindow(undefined);
            setAddingRepWindows(false);
            setWindowsAreReps(false);
        }
    }, [props.thisSessionID]);

    useEffect(() => {
        if (props.thisDataStreamTS) { 
            setThisDataStreamTS(props.thisDataStreamTS);
            if (props.thisDataStreamTS > 0) {
                setAnalysisAntiquated(props.thisDataStreamTS > 1689665877000 && props.thisDataStreamTS < 1696200129100); // October 1, 20223
            }
        }
    }, [props.thisDataStreamTS])

    useEffect(() => {
        setSelectedInjuryType(props.selectedInjuryType);
    }, [props.selectedInjuryType])


    useEffect(() => {
        if (props.currentSetRepStats !== undefined) {
            updateAllRepStream_old(props.currentSetRepStats);
            //repUpdatedCallback(props.currentSetRepStats[0]);
            // if (props.currentSetRepStats[0] !== undefined && props.currentSetRepStats[0].motionData !== undefined) {
            //     determineEndingIndex(props.currentSetRepStats[0].motionData);
            // }
        }
    }, [props.currentSetRepStats])

    useEffect(() => {
        if (props.currentSetMotionData !== undefined) {
            updateAllRepStream(props.currentSetMotionData);
            //repUpdatedCallback(props.currentSetRepStats[0]);
            // if (props.currentSetRepStats[0] !== undefined && props.currentSetRepStats[0].motionData !== undefined) {
            //     determineEndingIndex(props.currentSetRepStats[0].motionData);
            // }
        }
    }, [props.currentSetMotionData])

    

    useEffect(() => {
        if (props.videoProgress !== undefined) {
            setVideoProgress(props.videoProgress);
            updateCurrentMotionData(props.videoProgress);
        }
    }, [props.videoProgress])

    useEffect(() => {
        if (props.pointOffset !== undefined) {
            setPointOffset(props.pointOffset);
        }
    }, [props.pointOffset])

    useEffect(() => {
        props.updatedNumPts(numPts);
    }, [numPts])

    useEffect(() => {
        if (props.detectedRepWindows !== undefined) {
            updateRepWindowDisplay_new(props.detectedRepWindows);
        }
    }, [props.detectedRepWindows])

    useEffect(() => {
        if (props.videoPresent !== undefined) {
            setVideoPresent(props.videoPresent)
        }
    }, [props.videoPresent])

    useEffect(() => {
        // console.log("NodeStreamAnalyzeChart | props.addingRepWindows updated", props.addingRepWindows)
        if (props.addingRepWindows !== undefined) {
            setAddingRepWindow(undefined)
            setAddingRepWindows(props.addingRepWindows);

            // console.log("NodeStreamAnalyzeChart | props.addingRepWindows windows:", manualRepWindows, props.currentSetRepStats)

            if (props.addingRepWindows === false && manualRepWindows.length > 0) {
                if (props.currentSetRepStats !== undefined && props.currentSetRepStats.length !== 0) {
                    updateAllRepStream_old(props.currentSetRepStats);
                } else if (props.currentSetMotionData !== undefined && props.currentSetMotionData.length !== 0) {
                    updateAllRepStream(props.currentSetMotionData)
                }
            }
        }
    }, [props.addingRepWindows])

    useEffect(() => {
        if (props.windowsAreReps !== undefined) {
            setWindowsAreReps(props.windowsAreReps)
        }
    }, [props.windowsAreReps])

    function beginSim() {

        if (simIndex < 0) {
            setTimeout(() => {
                setSimIndex(simIndex + 1);
                props.mousProgressUpdated((simIndex + 1) / numPts);
            }, 32);
        } else {
            stopSim()
        }
        
    }

    function stopSim() {
        setSimIndex(-1);
        setTimeout(() => {
            setSimIndex(-1);
            props.mousProgressUpdated(0);
        }, 32);
    }

    useEffect(() => {
        if (simIndex !== -1) {
            if (simIndex < numPts - 1) {
                setTimeout(() => {
                    setSimIndex(simIndex + 1);
                    props.mousProgressUpdated((simIndex + 1) / numPts);
                }, 32);
            } else {
                setTimeout(() => {
                    setSimIndex(-1);
                    props.mousProgressUpdated(0);
                }, 32);
                
            }
        }
        
        
    }, [simIndex])

    useEffect(() => {
        // console.log("NODE STREAM ANALYZE CHART: updating viewingLocationIndex", viewingLocationIndex, allMotionData);
        if (allMotionData[viewingLocationIndex] !== undefined) {
            updateAllRepStream(allMotionData);
        }
        props.viewingLocationIndexUpdated(viewingLocationIndex);

        // console.log("-------------------- CLEARING ALL WINDOWS IN NodeStreamAnalyzeChart by props.thisSessionID", props.thisSessionID);

        setSimIndex(-1);
        setEndingIndex(-1);
        setEndingIndecies(endingIndecies.filter((item, index) => { return false; }));
        setHoverPosX(0);
        setVideoProgress(0);
        setManualRepWindows(manualRepWindows.filter((item, index) => { return false; }));
        setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }));
        setTesting_risingWindows(testing_risingWindows.filter((item, index) => { return false; }));
        setTesting_holdWindows(testing_holdWindows.filter((item, index) => { return false; }));
        setTesting_pauseWindows(testing_pauseWindows.filter((item, index) => { return false; }))
        //setAllMotionData(allMotionData.filter((item, index) => { return false; }));
        //setViewingLocationIndex(0);
        setAddMore(true);
        setAddingRepWindow(undefined);
        setAddingRepWindows(false);
        setWindowsAreReps(false);
        setInvertData(false);
        // setTesting_risingWindows(testing_risingWindows.filter((item, index) => { return false; }))
        // setTesting_holdWindows(testing_holdWindows.filter((item, index) => { return false; }))
        // setTesting_pauseWindows(testing_pauseWindows.filter((item, index) => { return false; }))
        // setDetectedRepWindows(detectedRepWindows.filter((item, index) => { return false; }))
        // setManualRepWindows(manualRepWindows.filter((item, index) => { return false; }))
        // setEndingIndecies(endingIndecies.filter((item, index) => { return false; }));

    }, [viewingLocationIndex])

    useEffect(() => {
        if (allMotionData[viewingLocationIndex] !== undefined) {
            updateAllRepStream(allMotionData);
        }
    }, [invertData])

    function toggleInversionState() {
        //let newInversionState = !invertData;

        //updateAllRepStream(props.currentSetMotionData);
        props.qStreamUpdated([]);
        setInvertData(!invertData);
    }


    function updateViewingIndex(toIndex: number) {
        if (toIndex < 0 || toIndex >= allMotionData.length) { return; }

        if (allMotionData[toIndex] === undefined) { return; }

        setViewingLocationIndex(toIndex);
        
    }

    function moveSim() {

    }


    function updateRepWindowDisplay_new(forWindows: RepWindow_t[]) {
        setDetectedRepWindows(forWindows);

        let updateRepWindowDisplayVerbose = true;

        // Sort through windows and calculate stats
        // 1. Retrieve 1d stat stream from chart data
        // if (dataSets_q_int === undefined || dataSets_q_int.datasets === undefined || dataSets_q_int.datasets[0] === undefined || dataSets_q_int.datasets[0].data === undefined) {
        //     // console.log("NodeStreamAnalyzeChart | updateRepWindowDisplay NEW: No data found in dataSets_q_int", dataSets_q_int);
        //     return;
        // }

        if (dataSets_q === undefined || dataSets_q.datasets === undefined || dataSets_q.datasets[0] === undefined || dataSets_q.datasets[0].data === undefined) {
            // console.log("NodeStreamAnalyzeChart | updateRepWindowDisplay NEW: No data found in dataSets_q", dataSets_q);
            return;
        }

        //let streamData_w: number[] = dataSets_q.datasets[0].data;
        let streamData_x: number[] = dataSets_q.datasets[1].data;
        let streamData_y: number[] = dataSets_q.datasets[2].data;
        let streamData_z: number[] = dataSets_q.datasets[3].data;

        if (!streamData_x || !streamData_y || !streamData_z) {
            // console.log("NodeStreamAnalyzeChart | updateRepWindowDisplay NEW: No data found in streamData_x/y/z",streamData_x,streamData_y,streamData_z);
            return;
        }

        var risingWindows: RepWindow_t[] = []
        var holdWindows: RepWindow_t[] = []
        var pauseWindows: RepWindow_t[] = [];

        var lastRepEndIndex = 0;


        let refV: {w: number, x: number, y: number, z: number} = {w: 0.0, x: 0.0, y: 1.0, z: 0.0};
        // 2. Parse stream data into windows
        for (var i = 0; i < forWindows.length; i++) {
            //// console.log("--- REP: ", i);
            let thisWindow: RepWindow_t = forWindows[i];

            if (updateRepWindowDisplayVerbose) console.log(`--------------------- REP: ${i + 1} -----------------------`)
            if (updateRepWindowDisplayVerbose) console.log(`\t\tWindow:`, thisWindow.start_index, thisWindow.end_index, Math.abs(thisWindow.start_index - thisWindow.end_index));
            let startingQ = {
                w: 0,
                x: streamData_x[thisWindow.start_index] ?? 0,
                y: streamData_y[thisWindow.start_index] ?? 0,
                z: streamData_z[thisWindow.start_index] ?? 0,
            }

            //// console.log("\t\tthisWindow =", thisWindow);
            //// console.log("\t\tstartingQ =", startingQ);

            // 2.a Form this window's data stream
            var thisWindowDataStream: number[] = [];
            for (var j = thisWindow.start_index; j < thisWindow.end_index + 5; j++) {

                let thisQ = {
                    w: 0,
                    x: streamData_x[j] ?? 0,
                    y: streamData_y[j] ?? 0,
                    z: streamData_z[j] ?? 0,
                }

                let ang = angle_between_vector_quaternions(startingQ, thisQ);
                ang = isNaN(ang) || !isFinite(ang) || ang < 0.01 ? 0 : ang
                thisWindowDataStream.push(ang)
            }

            //thisWindowDataStream[thisWindowDataStream.length - 1] = 0.0;
            thisWindowDataStream.push(0.0);

            var thisWindowDataStream_string = '';
            for (var n = 0; n < thisWindowDataStream.length; n++) {
                thisWindowDataStream_string += i === 0 ? '' : ', ';
                thisWindowDataStream_string += `${thisWindowDataStream[n]}`
            }
            thisWindowDataStream_string += '\r\n';
            // // console.log("thisWindowDataStream_string: ", thisWindowDataStream_string);

            if (updateRepWindowDisplayVerbose) console.log("\t\tthisWindowDataStream =", thisWindowDataStream);


            // 3. !!MEAT+POTATOES!! Analyze data

            var slopes_pos: {val: number, index: number}[] = [];
            var slopes_neg: {val: number, index: number}[] = [];
            let slopeTrehsold = 0.2;//0.0005;

            // 1. Compose lists of positive and negative slopes
            for (var j = 1; j < thisWindowDataStream.length - 1; j++) {
                let thisAngle = thisWindowDataStream[j];
                let lastAngle = thisWindowDataStream[j - 1];
                let dAngle = thisAngle - lastAngle;

                if (dAngle > slopeTrehsold) {
                    slopes_pos.push({val: dAngle, index: j});
                } else if (dAngle < -1 * slopeTrehsold) {
                    slopes_neg.push({val: dAngle, index: j});
                }
            }


            // 2.A Compose lists of pos / neg slope chains
            var slopeChains_pos: number[][] = [];
            var lastSlopeIndexInChain_pos = -2;
            for (var j = 0; j < slopes_pos.length; j++) {
                let thisSlopeObj = slopes_pos[j];

                if (thisSlopeObj.index === lastSlopeIndexInChain_pos + 1 || thisSlopeObj.index === lastSlopeIndexInChain_pos + 2) {
                    // This is part of an existing chain!
                    slopeChains_pos[slopeChains_pos.length - 1].push(thisSlopeObj.index);
                } else {
                    // Create a new chain
                    slopeChains_pos.push([thisSlopeObj.index]);
                }
                lastSlopeIndexInChain_pos = thisSlopeObj.index;
            }

            var slopeChains_neg: number[][] = [];
            var lastSlopeIndexInChain_neg = -2;
            for (var j = 0; j < slopes_neg.length; j++) {
                let thisSlopeObj = slopes_neg[j];

                if (thisSlopeObj.index === lastSlopeIndexInChain_neg + 1 || thisSlopeObj.index === lastSlopeIndexInChain_neg + 2) {
                    // This is part of an existing chain!
                    slopeChains_neg[slopeChains_neg.length - 1].push(thisSlopeObj.index);
                } else {
                    // Create a new chain
                    slopeChains_neg.push([thisSlopeObj.index]);
                }
                lastSlopeIndexInChain_neg = thisSlopeObj.index;
            }

            // // console.log("pos slopes:", slopeChains_pos);
            // // console.log("neg slopes:", slopeChains_neg);

            // 2.B Find longest chain
            var longestChain_pos: number[] = [];
            var longestChain_neg: number[] = [];

            const getChainDelta = (chain: number[]) => {
                if (chain.length === 0 || !chain[0] || !chain[chain.length - 1]) return 0;
                let maxIndex = chain[chain.length - 1];
                let minIndex = chain[0];
                let maxVal = thisWindowDataStream[maxIndex] ?? 0;
                let minVal = thisWindowDataStream[minIndex] ?? 0;

                return Math.abs(maxVal - minVal);
            }

            for (var j = 0; j < slopeChains_pos.length; j++) {
                if (getChainDelta(slopeChains_pos[j]) > getChainDelta(longestChain_pos)) { //if (slopeChains_pos[j].length > longestChain_pos.length
                    longestChain_pos = slopeChains_pos[j];
                }
            }

            for (var j = 0; j < slopeChains_neg.length; j++) {
                if (getChainDelta(slopeChains_neg[j]) > getChainDelta(longestChain_neg)) { //slopeChains_neg[j].length > longestChain_neg.length) {
                    longestChain_neg = slopeChains_neg[j];
                }
            }


            let risingWindow_pos: RepWindow_t = {
                start_index: longestChain_pos[0] + thisWindow.start_index,
                end_index: longestChain_pos[longestChain_pos.length - 1] + thisWindow.start_index,
                is_rep: false
            }
            risingWindows.push(risingWindow_pos);

            let risingWindow_neg: RepWindow_t = {
                start_index: longestChain_neg[0] + thisWindow.start_index,
                end_index: longestChain_neg[longestChain_neg.length - 1] + thisWindow.start_index,
                is_rep: false
            }
            risingWindows.push(risingWindow_neg);


            // 3. Determine if angles are inverted (decreasing angle first)
            var anglesInverted = false;

            if (risingWindow_pos.start_index > risingWindow_neg.start_index) {
                // If positive slope chain comes after negative slope chain, the angles for this rep are inverted
                anglesInverted = true;
            }





            // 4. Get ROM from concentric window
            let leadingConcentric = true;

            // 4.A Generate concentric window
            let concentricWindow: RepWindow_t = risingWindow_pos;    
            let eccentricWindow: RepWindow_t = risingWindow_pos;    
            if (leadingConcentric) {
                if (anglesInverted) {
                    concentricWindow = risingWindow_neg;
                    eccentricWindow = risingWindow_pos;
                } else {
                    concentricWindow = risingWindow_pos;
                    eccentricWindow = risingWindow_neg;
                }
            } else {
                if (anglesInverted) {
                    concentricWindow = risingWindow_pos;
                    eccentricWindow = risingWindow_neg;
                } else {
                    concentricWindow = risingWindow_neg;
                    eccentricWindow = risingWindow_pos;
                }
            }

            let concentricWindow_local: RepWindow_t = {
                start_index: concentricWindow.start_index - thisWindow.start_index,
                end_index: concentricWindow.end_index - thisWindow.start_index,
                is_rep: concentricWindow.is_rep
            }

            let eccentricWindow_local: RepWindow_t = {
                start_index: eccentricWindow.start_index - thisWindow.start_index,
                end_index: eccentricWindow.end_index - thisWindow.start_index,
                is_rep: eccentricWindow.is_rep
            }

            let concentricWindowLength = concentricWindow_local.end_index - concentricWindow_local.start_index;
            let concentricTime = concentricWindowLength * 0.041;

            // 4.B Calc angualar diff. betwen window start and end
            let angle_start = thisWindowDataStream[concentricWindow_local.start_index];
            let angle_end = thisWindowDataStream[concentricWindow_local.end_index];
            let dAngle_concentric = Math.abs(angle_end - angle_start);

            let angle_start_ecc = thisWindowDataStream[eccentricWindow_local.start_index];
            let angle_end_ecc = thisWindowDataStream[eccentricWindow_local.end_index];
            let dAngle_ecccentric = Math.abs(angle_end_ecc - angle_start_ecc);
            dAngle_ecccentric = isNaN(dAngle_ecccentric) || !isFinite(dAngle_ecccentric) || dAngle_ecccentric <= 0 ? 0 : dAngle_ecccentric;

            let dAngleAvg = (dAngle_concentric + dAngle_ecccentric) / 2

            if (updateRepWindowDisplayVerbose) console.log(`\t\tROM: ${Math.floor(dAngle_concentric * 100) / 100}º\t|\tROM_n: ${Math.floor(dAngle_ecccentric * 100) / 100}º\t|\tROM_avg: ${Math.floor(dAngleAvg * 100) / 100}º`);


            // 5. Power and velocity
            let reachLength = 0.60;     // in meters
            let bodyWeight = 155;       // in pounds
            let bodyWeight_metric = bodyWeight / 2.21;
            let g_mag = 9.81;           // m/s^2

            let power = (0.5 * bodyWeight_metric * g_mag * reachLength) / concentricTime;

            if (updateRepWindowDisplayVerbose) console.log(`\t\tPOWER: ${Math.floor(power * 100) / 100}W`)

            // AVERAGE CONCENTRIC SPEED
            //let velocity = reachLength / eccentricTime; // FOR PUSHUP
            let velocity = (reachLength * (dAngle_concentric / 57.2957795131)) / concentricTime;
            if (updateRepWindowDisplayVerbose) console.log(`\t\tVELOCITY: ${Math.floor(velocity * 100) / 100}m/s`)


            // TEMPO CALCULATION
            let eccentricWindowLength = Math.abs(eccentricWindow.end_index - eccentricWindow.start_index);
            let eccentricTime = eccentricWindowLength * 0.041;
            let firstPauseTime = Math.abs(concentricWindow.start_index - thisWindow.start_index) * 0.041;
            let secondPauseTime = Math.abs(concentricWindow.end_index - eccentricWindow.start_index) * 0.041;

            let tempoString = `${Math.floor(firstPauseTime * 100) / 100} : ${Math.floor(concentricTime * 100) / 100} : ${Math.floor(secondPauseTime * 100) / 100} : ${Math.floor(eccentricTime * 100) / 100}`

            if (updateRepWindowDisplayVerbose) console.log(`\t\tTEMPO:`)
            if (updateRepWindowDisplayVerbose) console.log(`\t\t\t\tFP : ${Math.floor(firstPauseTime * 100) / 100}`)
            if (updateRepWindowDisplayVerbose) console.log(`\t\t\t\tCON: ${Math.floor(concentricTime * 100) / 100}`)
            if (updateRepWindowDisplayVerbose) console.log(`\t\t\t\tSP : ${Math.floor(secondPauseTime * 100) / 100}`)
            if (updateRepWindowDisplayVerbose) console.log(`\t\t\t\tECC: ${Math.floor(eccentricTime * 100) / 100}`)
            if (updateRepWindowDisplayVerbose) console.log(`\t\t------------------------------------`)

        }

        if (updateRepWindowDisplayVerbose) console.log(`----------------------------------------`)

        

        setTesting_risingWindows(testing_risingWindows.filter((item, index) => {return false;}))
        setTesting_risingWindows(risingWindows);

        setTesting_pauseWindows(testing_pauseWindows.filter((item, index) => {return false;}))
        setTesting_pauseWindows(pauseWindows);

        setTesting_holdWindows(testing_holdWindows.filter((item, index) => {return false;}))
        setTesting_holdWindows(holdWindows);

    }





    function updateRepWindowDisplay_old(forWindows: RepWindow_t[]) {
        setDetectedRepWindows(forWindows);

        // Sort through windows and calculate stats
        // 1. Retrieve 1d stat stream from chart data
        if (dataSets_q_int === undefined || dataSets_q_int.datasets === undefined || dataSets_q_int.datasets[0] === undefined || dataSets_q_int.datasets[0].data === undefined) {
            // console.log("NodeStreamAnalyzeChart | updateRepWindowDisplay: No data found in dataSets_q_int", dataSets_q_int);
            return;
        }

        let streamData: number[] = dataSets_q_int.datasets[0].data;

        var risingWindows: RepWindow_t[] = []
        var holdWindows: RepWindow_t[] = []
        var pauseWindows: RepWindow_t[] = [];

        var lastRepEndIndex = 0;

        // 2. Parse stream data into windows
        for (var i = 0; i < forWindows.length; i++) {
            //// console.log("--- REP: ", i);
            let thisWindow: RepWindow_t = forWindows[i];

            // 2.a Form this window's data stream
            var thisWindowDataStream: number[] = [];
            for (var j = thisWindow.start_index; j < thisWindow.end_index; j++) {
                if (streamData[j] !== undefined) {
                    thisWindowDataStream.push(streamData[j])
                }
            }

            thisWindowDataStream[thisWindowDataStream.length - 1] = 0.0;

            var thisWindowDataStream_string = '';
            for (var n = 0; n < thisWindowDataStream.length; n++) {
                thisWindowDataStream_string += i === 0 ? '' : ', ';
                thisWindowDataStream_string += `${thisWindowDataStream[n]}`
            }
            thisWindowDataStream_string += '\r\n';
            // // console.log("thisWindowDataStream_string: ", thisWindowDataStream_string);

            // 3. !!MEAT+POTATOES!! Analyze data

            var risingIndecides: number[] = [];
            var risingBegins_index = thisWindow.start_index;

            var firstPeak_index = 0;
            var firstPeak_val = 0;

            var inflectionPoints_pos_to_neg: {val: number, index: number}[] = [];

            var deltas: number[] = [];
            var deltaApprovedCount = 0;

            for (var j = 1; j < thisWindowDataStream.length - 12; j++) {
                let globalIndex = j + thisWindow.start_index;

                let thisPoint: number = thisWindowDataStream[j];
                let lastPoint: number = thisWindowDataStream[j - 1];
                let delta = thisPoint - lastPoint;
                let delta_is_positive = delta > 0;
                let delta_is_positive_or_zero = delta >= 0;


                // A [rising detection]. Add to rising deltas
                if (delta_is_positive) {
                    risingIndecides.push(globalIndex - 1);
                }

                // B [first peak detection]. Check if recorded peak is max peak
                if (thisPoint > firstPeak_val) {
                    firstPeak_val = thisPoint;
                    firstPeak_index = globalIndex;
                }

                // C Find inflection points
                
                if (j < thisWindowDataStream.length - 1) {
                    let nextPoint: number = thisWindowDataStream[j + 1];
                    let delta_next = nextPoint - thisPoint;
                    deltas.push(delta_next)
                    let delta_next_is_positive = delta_next > 0;

                    if (delta_is_positive_or_zero === true && delta_next_is_positive === false) {
                        inflectionPoints_pos_to_neg.push({index: globalIndex - 1, val: thisPoint});
                    } else {
                        deltaApprovedCount = 0;
                    }
                }
                
            }

            //// console.log("deltas: ", deltas);
            //// console.log("thisWindowDataStream:", thisWindowDataStream);

            // // A [rising detection]. Check for consecutiveRisingCount_threshold indecies in a row
            // let consecutiveRisingCount_threshold = 3;
            // var consecutiveRisingCount = 0;
            // var selectedIndex = risingIndecides[0];
            // var continueIndexSearch = true;

            // let prevIndex = risingIndecides[0];
            // for (var j = 1; j < risingIndecides.length; j++) {
            //     if (continueIndexSearch === true) {
            //         if (risingIndecides[j] === prevIndex + 1) {
            //             consecutiveRisingCount += 1;
            //             if (consecutiveRisingCount === consecutiveRisingCount_threshold) {
            //                 selectedIndex = risingIndecides[j] - consecutiveRisingCount_threshold;
            //                 continueIndexSearch = false;
            //             }
            //         } else {
            //             consecutiveRisingCount = 0;
            //         }

            //         prevIndex = risingIndecides[j];
            //     }
            // }

            // risingBegins_index = selectedIndex; //risingIndecides[0] === undefined ? risingBegins_index : risingIndecides[0];

            // C inflection points
            if (inflectionPoints_pos_to_neg.length > 0) {
                var maxInflection_a: {val: number, index: number} = {index: 0, val: 0};
                var maxInflection_a_index = 0;

                for (var j = 0; j < inflectionPoints_pos_to_neg.length; j++) {
                    let thisInflection: {val: number, index: number} = inflectionPoints_pos_to_neg[j];
                    if (thisInflection.val > maxInflection_a.val) {
                        maxInflection_a = thisInflection;
                        maxInflection_a_index = j;
                    }
                }

                //// console.log("inflectionPoints_pos_to_neg", inflectionPoints_pos_to_neg)

                // Run again to get second largest inflection point
                inflectionPoints_pos_to_neg.splice(maxInflection_a_index, 1);
                var maxInflection_b: {val: number, index: number} = {index: 0, val: 0};
                var maxInflection_b_index = 0;

                if (inflectionPoints_pos_to_neg.length > 0) {
                    for (var j = 0; j < inflectionPoints_pos_to_neg.length; j++) {
                        let thisInflection: {val: number, index: number} = inflectionPoints_pos_to_neg[j];
                        if (thisInflection.val > maxInflection_b.val && Math.abs(thisInflection.index - maxInflection_a.index) > 6) {
                            maxInflection_b = thisInflection;
                            maxInflection_b_index = j;
                        }
                    }
                }
                if (maxInflection_b.index === 0 && maxInflection_b.val === 0) {
                    maxInflection_b = {index: maxInflection_a.index + 7, val: maxInflection_a.val * 0.8};
                }

                var firstInflectionPoint = maxInflection_a;
                var lastInflectionPoint = maxInflection_b;

                //// console.log("firstInflectionPoint:", firstInflectionPoint)
                //// console.log("lastInflectionPoint:", lastInflectionPoint)

                if (firstInflectionPoint.index > lastInflectionPoint.index) {
                    var copiedPoint = JSON.parse(JSON.stringify(firstInflectionPoint));
                    firstInflectionPoint = maxInflection_b;
                    lastInflectionPoint = copiedPoint;
                }

                let holdWindowWidth = lastInflectionPoint.index - firstInflectionPoint.index;
                if (holdWindowWidth < 14) {
                    lastInflectionPoint.index = firstInflectionPoint.index + 14;
                }

                let thisHoldWindow: RepWindow_t = {
                    start_index: firstInflectionPoint.index,
                    end_index: lastInflectionPoint.index,
                    is_rep: false
                }
                holdWindows.push(thisHoldWindow);



                // A [rising detection]. Look before this window for beginning rise location
                let thisAdj_startIndex = firstInflectionPoint.index - thisWindow.start_index;
                let thisAdj_endIndex = lastInflectionPoint.index - thisWindow.start_index;

                let posSlopeCount = 0;
                let negSlopeCount = 0;
                let posSlopeCount_threshold = 2;
                let slopeMinInflectionIndex_local = 0;
                let slopeMinInflectionIndex_local_temp = 0;

                var startIsSet = false;

                for (var j = 1; j < thisAdj_startIndex; j++) {
                    let thisPoint = thisWindowDataStream[j];
                    let lastPoint = thisWindowDataStream[j - 1];
                    let slopePos = thisPoint > lastPoint;
                    if (slopePos === true) {
                        posSlopeCount += 1;
                        if (posSlopeCount >= posSlopeCount_threshold && lastPoint !== 0 && startIsSet === false) {
                            slopeMinInflectionIndex_local = slopeMinInflectionIndex_local_temp;
                            startIsSet = true;
                        }
                        if (posSlopeCount === 1) {
                            slopeMinInflectionIndex_local_temp = j - posSlopeCount_threshold;
                        }
                        negSlopeCount = 0;
                    } else {
                        negSlopeCount += 1;
                        if (negSlopeCount >= 3) {
                            posSlopeCount = 0;
                        }
                        
                    }
                }

                // for (var j = thisAdj_startIndex; j > 1; j--) {
                //     let thisPoint = thisWindowDataStream[j];
                //     let lastPoint = thisWindowDataStream[j - 1];
                //     let slopePos = thisPoint > lastPoint;
                //     if (slopePos === true) {
                //         posSlopeCount += 1;
                //         if (posSlopeCount >= posSlopeCount_threshold && lastPoint !== 0) {
                //             slopeMinInflectionIndex_local = j - posSlopeCount_threshold;
                //         }
                //     } else {
                //         posSlopeCount = 0;
                //     }
                // }

                slopeMinInflectionIndex_local = slopeMinInflectionIndex_local < 0 ? 0 : slopeMinInflectionIndex_local;
                let slopeMinInflectionIndex_global = slopeMinInflectionIndex_local + thisWindow.start_index;

                
                // D look inside of this window for min. value
                
                //// console.log("Adjusted indecies: ", firstInflectionPoint.index, lastInflectionPoint.index, thisAdj_startIndex, thisAdj_endIndex, thisAdj_endIndex - thisAdj_startIndex);
                
                var windowIntegration = 0;

                var minPoint = 1000;
                var minPoint_index = thisAdj_startIndex;
                for (var j = thisAdj_startIndex; j < thisAdj_endIndex; j++) {
                    let thisPoint = thisWindowDataStream[j];
                    if (thisPoint < minPoint) {
                        minPoint = thisPoint;
                        minPoint_index = j;
                    }
                    windowIntegration += thisPoint;
                }

                let risingWindow: RepWindow_t = {
                    start_index: slopeMinInflectionIndex_global,
                    end_index: minPoint_index + thisWindow.start_index,
                    is_rep: false
                }
                //// console.log("RISING WINDOW:", risingWindow)
                risingWindows.push(risingWindow);

                let eccentricTime = (risingWindow.end_index - risingWindow.start_index) * 0.032;


                // E. Look for first pause inside of purple window, after rising window's end
                

                let firstPause_startIndex = risingWindow.end_index; // global index
                var firstPause_endIndex = risingWindow.end_index - thisWindow.start_index; // temporarily local index

                let firstPauseIndexStart: number = risingWindow.end_index - thisWindow.start_index;
                for (var j = firstPauseIndexStart; j < thisHoldWindow.end_index - thisWindow.start_index; j++) {
                    if (thisWindowDataStream[j] <= 0.02) {
                        firstPause_endIndex = j;
                    }
                }

                let firstPauseWindow: RepWindow_t = {
                    start_index: firstPause_startIndex,
                    end_index: firstPause_endIndex + thisWindow.start_index,
                    is_rep: false
                }
                pauseWindows.push(firstPauseWindow);

                let firstPauseFrames = firstPauseWindow.end_index - firstPauseWindow.start_index;
                let firstPauseTime = firstPauseFrames * 0.032;


                // F. Look after hold (purple) window for end to eccentric phase
                //// console.log("thisWindowDataStream:", , thisWindowDataStream)

                var minStreamPoint = 100;
                var minStreamPointIndex = firstPauseWindow.end_index - thisWindow.start_index;
                for (var j = thisHoldWindow.end_index - thisWindow.start_index; j < thisWindowDataStream.length; j++) {
                    if (thisWindowDataStream[j] < minStreamPoint) {
                        minStreamPoint = thisWindowDataStream[j];
                        minStreamPointIndex = j;
                    }
                }

                let conWindow: RepWindow_t = {
                    start_index: firstPauseWindow.end_index,
                    end_index: minStreamPointIndex <= thisHoldWindow.end_index - thisWindow.start_index ? minStreamPointIndex + thisWindow.start_index + 5 : minStreamPointIndex + thisWindow.start_index - 1,
                    is_rep: false
                }
                risingWindows.push(conWindow);

                let concentricFrames = conWindow.end_index - conWindow.start_index;
                let concentricTime = concentricFrames * 0.032;



                // G. Get second pause
                var secondPauseTime = 0.0;
                if (lastRepEndIndex > 0) {
                    let thisRepStartIndex = risingWindow.start_index;
                    let secondPauseFrames = thisRepStartIndex - lastRepEndIndex;
                    secondPauseTime = secondPauseFrames * 0.032;

                    let secondPauseWindow: RepWindow_t = {
                        start_index: lastRepEndIndex,
                        end_index: thisRepStartIndex,
                        is_rep: false
                    }
                    pauseWindows.push(secondPauseWindow);

                }

                lastRepEndIndex = conWindow.end_index;


                // H. Look inside of rising window for ROM

                var integratedAngle = 0.0;


                if (dataSets_q.datasets.length === 4) {
                    let q_ws = dataSets_q.datasets[0].data === undefined ? [] : dataSets_q.datasets[0].data;
                    let q_xs = dataSets_q.datasets[1].data === undefined ? [] : dataSets_q.datasets[1].data;
                    let q_ys = dataSets_q.datasets[2].data === undefined ? [] : dataSets_q.datasets[2].data;
                    let q_zs = dataSets_q.datasets[3].data === undefined ? [] : dataSets_q.datasets[3].data;
                    for (var j = risingWindow.start_index + 1; j < risingWindow.end_index; j++) {
                        let thisQ: {w: number, x: number, y: number, z: number} = {
                            w: q_ws[j] === undefined ? 0 : q_ws[j],
                            x: q_xs[j] === undefined ? 0 : q_xs[j],
                            y: q_ys[j] === undefined ? 0 : q_ys[j],
                            z: q_zs[j] === undefined ? 0 : q_zs[j]
                        };

                        let lastQ: {w: number, x: number, y: number, z: number} = {
                            w: q_ws[j - 1] === undefined ? 0 : q_ws[j - 1],
                            x: q_xs[j - 1] === undefined ? 0 : q_xs[j - 1],
                            y: q_ys[j - 1] === undefined ? 0 : q_ys[j - 1],
                            z: q_zs[j - 1] === undefined ? 0 : q_zs[j - 1]
                        };

                        let deltaAngle = angle_between_vector_quaternions(thisQ, lastQ);

                        integratedAngle += deltaAngle;
                    }

                }
                

                // // console.log("\t\tREP CONTAINING WINDOW:",thisWindow.start_index, thisWindow.end_index, thisWindow.end_index - thisWindow.start_index)
                // // console.log("\t\tWINDOWS:");
                // // console.log("\t\t\t\trisingWindow:", risingWindow.start_index - thisWindow.start_index, risingWindow.end_index - thisWindow.start_index, risingWindow.end_index - risingWindow.start_index);
                // // console.log("\t\t\t\tholdWindow:", thisHoldWindow.start_index - thisWindow.start_index, thisHoldWindow.end_index - thisWindow.start_index, thisHoldWindow.end_index - thisHoldWindow.start_index);
                // // console.log("\t\t\t\tconWindow:", conWindow.start_index - thisWindow.start_index, conWindow.end_index - thisWindow.start_index, conWindow.end_index - conWindow.start_index);
                // // console.log("\t\t\t\tfirstPauseWindow:", firstPauseWindow.start_index - thisWindow.start_index, firstPauseWindow.end_index - thisWindow.start_index, firstPauseWindow.end_index - firstPauseWindow.start_index);


                //// console.log("\t\tTIMING:", Math.floor(eccentricTime * 1000) / 1000, Math.floor(firstPauseTime * 1000) / 1000, Math.floor(concentricTime * 1000) / 1000, Math.floor(secondPauseTime * 1000) / 1000)

                // POWER
                let reachLength = 0.60;     // in meters
                let bodyWeight = 155;       // in pounds
                let bodyWeight_metric = bodyWeight / 2.21;
                let g_mag = 9.81;           // m/s^2

                let power = (0.5 * bodyWeight_metric * g_mag * reachLength) / eccentricTime;

                //// console.log(`\t\tPOWER: ${Math.floor(power * 100) / 100}W`)

                // AVERAGE CONCENTRIC SPEED
                //let velocity = reachLength / eccentricTime; // FOR PUSHUP
                let velocity = (reachLength * (integratedAngle / 57.2957795131)) / eccentricTime;
                //// console.log(`\t\tVELOCITY: ${Math.floor(velocity * 100) / 100}m/s`)

                //// console.log(`\t\tROM: ${Math.floor(integratedAngle * 100) / 100}º`)
                
                //let concentricTime = (risingWindow.end_index - risingWindow.start_index) * maxInflection_a.val * 0.032;
                //let testConTime = Math.floor((1 / concentricTime) * 100) / 100;


                //// console.log("Concentric time: ", testConTime, `${Math.floor(testConTime * 14.75 * 10) / 10} frames`, `${testConTime * 14.75 * 0.032} seconds`, `${windowIntegration} integrated`);
                // // console.log("Concentric time: ",
                //                     risingWindow.end_index - risingWindow.start_index,
                //                     concentricTime,
                //                     maxInflection_a.val,
                //                     (risingWindow.end_index - risingWindow.start_index) / maxInflection_a.val,
                //                     maxInflection_a.val / (risingWindow.end_index - risingWindow.start_index),
                //                     Math.floor((1 / concentricTime) * 100) / 100);

            }


            // X. Compose testing windows for display
            // let risingWindow: RepWindow_t = {
            //     start_index: risingBegins_index,
            //     end_index: firstPeak_index,
            //     is_rep: false
            // }
            // risingWindows.push(risingWindow);

            //// console.log("WINDOW:", thisWindow, risingWindow, risingIndecides, risingBegins_index, firstPeak_index, firstPeak_val)
        }


        

        setTesting_risingWindows(testing_risingWindows.filter((item, index) => {return false;}))
        setTesting_risingWindows(risingWindows);

        setTesting_pauseWindows(testing_pauseWindows.filter((item, index) => {return false;}))
        setTesting_pauseWindows(pauseWindows);

        setTesting_holdWindows(testing_holdWindows.filter((item, index) => {return false;}))
        setTesting_holdWindows(holdWindows);

    }

    function updateRepWindowDisplay(forWindows: RepWindow_t[]) {
        setDetectedRepWindows(forWindows);

        // Sort through windows and calculate stats
        // 1. Retrieve 1d stat stream from chart data
        if (dataSets_q_int === undefined || dataSets_q_int.datasets === undefined || dataSets_q_int.datasets[0] === undefined || dataSets_q_int.datasets[0].data === undefined) {
            // console.log("NodeStreamAnalyzeChart | updateRepWindowDisplay: No data found in dataSets_q_int", dataSets_q_int);
            return;
        }

        let streamData: number[] = dataSets_q_int.datasets[0].data;

        var risingWindows: RepWindow_t[] = []
        var holdWindows: RepWindow_t[] = []
        var pauseWindows: RepWindow_t[] = [];

        var lastRepEndIndex = 0;

        // 2. Parse stream data into windows
        for (var i = 0; i < forWindows.length; i++) {
            //// console.log("--- REP: ", i);
            let thisWindow: RepWindow_t = forWindows[i];

            // console.log(`\t\t------------- REP: ${i + 1} ---------------`)

            // 2.a Form this window's data stream
            var thisWindowDataStream: number[] = [];
            for (var j = thisWindow.start_index; j < thisWindow.end_index; j++) {
                if (streamData[j] !== undefined) {
                    thisWindowDataStream.push(streamData[j])
                }
            }

            thisWindowDataStream[thisWindowDataStream.length - 1] = 0.0;

            var thisWindowDataStream_string = '';
            for (var n = 0; n < thisWindowDataStream.length; n++) {
                thisWindowDataStream_string += i === 0 ? '' : ', ';
                thisWindowDataStream_string += `${thisWindowDataStream[n]}`
            }
            thisWindowDataStream_string += '\r\n';
            // // console.log("thisWindowDataStream_string: ", thisWindowDataStream_string);

            // 3. !!MEAT+POTATOES!! Analyze data


            var slopes_pos: {val: number, index: number}[] = [];
            var slopes_neg: {val: number, index: number}[] = [];
            let slopeTrehsold = 0.0005;

            // 1. Compose lists of positive and negative slopes
            for (var j = 1; j < thisWindowDataStream.length - 1; j++) {
                let thisAngle = thisWindowDataStream[j];
                let lastAngle = thisWindowDataStream[j - 1];
                let dAngle = thisAngle - lastAngle;

                if (dAngle > slopeTrehsold) {
                    slopes_pos.push({val: dAngle, index: j});
                } else if (dAngle < -1 * slopeTrehsold) {
                    slopes_neg.push({val: dAngle, index: j});
                }
            }


            // 2.A Compose lists of pos / neg slope chains
            var slopeChains_pos: number[][] = [];
            var lastSlopeIndexInChain_pos = -2;
            for (var j = 0; j < slopes_pos.length; j++) {
                let thisSlopeObj = slopes_pos[j];

                if (thisSlopeObj.index === lastSlopeIndexInChain_pos + 1 || thisSlopeObj.index === lastSlopeIndexInChain_pos + 2) {
                    // This is part of an existing chain!
                    slopeChains_pos[slopeChains_pos.length - 1].push(thisSlopeObj.index);
                } else {
                    // Create a new chain
                    slopeChains_pos.push([thisSlopeObj.index]);
                }
                lastSlopeIndexInChain_pos = thisSlopeObj.index;
            }

            var slopeChains_neg: number[][] = [];
            var lastSlopeIndexInChain_neg = -2;
            for (var j = 0; j < slopes_neg.length; j++) {
                let thisSlopeObj = slopes_neg[j];

                if (thisSlopeObj.index === lastSlopeIndexInChain_neg + 1 || thisSlopeObj.index === lastSlopeIndexInChain_neg + 2) {
                    // This is part of an existing chain!
                    slopeChains_neg[slopeChains_neg.length - 1].push(thisSlopeObj.index);
                } else {
                    // Create a new chain
                    slopeChains_neg.push([thisSlopeObj.index]);
                }
                lastSlopeIndexInChain_neg = thisSlopeObj.index;
            }

            //// console.log("pos slopes:", slopeChains_pos);
            //// console.log("neg slopes:", slopeChains_neg);

            // 2.B Find longest chain
            var longestChain_pos: number[] = [];
            var longestChain_neg: number[] = [];

            const getChainDelta = (chain: number[]) => {
                if (chain.length === 0 || !chain[0] || !chain[chain.length - 1]) return 0;
                let maxIndex = chain[chain.length - 1];
                let minIndex = chain[0];
                let maxVal = thisWindowDataStream[maxIndex] ?? 0;
                let minVal = thisWindowDataStream[minIndex] ?? 0;

                return Math.abs(maxVal - minVal);
            }

            for (var j = 0; j < slopeChains_pos.length; j++) {
                if (getChainDelta(slopeChains_pos[j]) > getChainDelta(longestChain_pos)) { //if (slopeChains_pos[j].length > longestChain_pos.length
                    longestChain_pos = slopeChains_pos[j];
                }
            }

            for (var j = 0; j < slopeChains_neg.length; j++) {
                if (getChainDelta(slopeChains_neg[j]) > getChainDelta(longestChain_neg)) { //slopeChains_neg[j].length > longestChain_neg.length) {
                    longestChain_neg = slopeChains_neg[j];
                }
            }


            let risingWindow_pos: RepWindow_t = {
                start_index: longestChain_pos[0] + thisWindow.start_index,
                end_index: longestChain_pos[longestChain_pos.length - 1] + thisWindow.start_index,
                is_rep: false
            }
            risingWindows.push(risingWindow_pos);

            let risingWindow_neg: RepWindow_t = {
                start_index: longestChain_neg[0] + thisWindow.start_index,
                end_index: longestChain_neg[longestChain_neg.length - 1] + thisWindow.start_index,
                is_rep: false
            }
            risingWindows.push(risingWindow_neg);


            // 3. Determine if angles are inverted (decreasing angle first)
            var anglesInverted = false;

            if (risingWindow_pos.start_index > risingWindow_neg.start_index) {
                // If positive slope chain comes after negative slope chain, the angles for this rep are inverted
                anglesInverted = true;
            }


            // 4. Get ROM from concentric window
            let leadingConcentric = true;

            // 4.A Generate concentric window
            let concentricWindow: RepWindow_t = risingWindow_pos;    
            let eccentricWindow: RepWindow_t = risingWindow_pos;    
            if (leadingConcentric) {
                if (anglesInverted) {
                    concentricWindow = risingWindow_neg;
                    eccentricWindow = risingWindow_pos;
                } else {
                    concentricWindow = risingWindow_pos;
                    eccentricWindow = risingWindow_neg;
                }
            } else {
                if (anglesInverted) {
                    concentricWindow = risingWindow_pos;
                    eccentricWindow = risingWindow_neg;
                } else {
                    concentricWindow = risingWindow_neg;
                    eccentricWindow = risingWindow_pos;
                }
            }

            let concentricWindow_local: RepWindow_t = {
                start_index: concentricWindow.start_index - thisWindow.start_index,
                end_index: concentricWindow.end_index - thisWindow.start_index,
                is_rep: concentricWindow.is_rep
            }

            let concentricWindowLength = concentricWindow_local.end_index - concentricWindow_local.start_index;
            let concentricTime = concentricWindowLength * 0.041;

            // 4.B Calc angualar diff. betwen window start and end
            let angle_start = thisWindowDataStream[concentricWindow_local.start_index];
            let angle_end = thisWindowDataStream[concentricWindow_local.end_index];
            let dAngle_concentric = Math.abs(angle_end - angle_start);

            // console.log(`\t\tROM: ${Math.floor(dAngle_concentric * 0.4) / 0.4}º`);


            // 5. Power and velocity
            let reachLength = 0.60;     // in meters
            let bodyWeight = 155;       // in pounds
            let bodyWeight_metric = bodyWeight / 2.21;
            let g_mag = 9.81;           // m/s^2

            let power = (0.5 * bodyWeight_metric * g_mag * reachLength) / concentricTime;

            // console.log(`\t\tPOWER: ${Math.floor(power * 100) / 100}W`)

            // AVERAGE CONCENTRIC SPEED
            //let velocity = reachLength / eccentricTime; // FOR PUSHUP
            let velocity = (reachLength * (dAngle_concentric / 57.2957795131)) / concentricTime;
            // console.log(`\t\tVELOCITY: ${Math.floor(velocity * 100) / 100}m/s`)


            // TEMPO CALCULATION
            let eccentricWindowLength = Math.abs(eccentricWindow.end_index - eccentricWindow.start_index);
            let eccentricTime = eccentricWindowLength * 0.041;
            let firstPauseTime = Math.abs(concentricWindow.start_index - thisWindow.start_index) * 0.041;
            let secondPauseTime = Math.abs(concentricWindow.end_index - eccentricWindow.start_index) * 0.041;

            let tempoString = `${Math.floor(firstPauseTime * 100) / 100} : ${Math.floor(concentricTime * 100) / 100} : ${Math.floor(secondPauseTime * 100) / 100} : ${Math.floor(eccentricTime * 100) / 100}`

            // console.log(`\t\tTEMPO:`)
            // console.log(`\t\t\t\tFP : ${Math.floor(firstPauseTime * 100) / 100}`)
            // console.log(`\t\t\t\tCON: ${Math.floor(concentricTime * 100) / 100}`)
            // console.log(`\t\t\t\tSP : ${Math.floor(secondPauseTime * 100) / 100}`)
            // console.log(`\t\t\t\tECC: ${Math.floor(eccentricTime * 100) / 100}`)
            // console.log(`\t\t------------------------------------`)

        }

        // console.log(`----------------------------------------`)

        

        setTesting_risingWindows(testing_risingWindows.filter((item, index) => {return false;}))
        setTesting_risingWindows(risingWindows);

        setTesting_pauseWindows(testing_pauseWindows.filter((item, index) => {return false;}))
        setTesting_pauseWindows(pauseWindows);

        setTesting_holdWindows(testing_holdWindows.filter((item, index) => {return false;}))
        setTesting_holdWindows(holdWindows);

    }

    function updateAllRepStream_old(forData: RepStats_t[]) {

        if (forData === undefined || forData.length === 0) { return; }

        var v_stream: {x: number, y: number, z: number}[] = [];
        var q_stream: {w: number, x: number, y: number, z: number}[] = [];

        let absLeadingQ: {w: number, x: number, y: number, z: number} = forData !== undefined && forData[0] !== undefined && forData[0].motionData !== undefined && forData[0].motionData[0] !== undefined && forData[0].motionData[0].quaternion !== undefined ? forData[0].motionData[0].quaternion : {w: 1, x: 0, y: 0, z: 0};

        //let absLeadingQ: {w: number, x: number, y: number, z: number} = forData === undefined || forData[0] === undefined || forData[0].motionData[0] === undefined || forData[0].motionData[0].quaternion === undefined ? {w: 1, x: 0, y: 0, z: 0} : forData[0].motionData[0].quaternion;

        for (var i = 0; i < pointOffset; i++) {
            q_stream.push(absLeadingQ);
            v_stream.push({x: 0, y: 0, z: 0});
        }

        var endingIndecies_temp: number[] = [];

        for (var i = 0; i < forData.length; i++) {
            let thisRepStat: RepStats_t = forData[i];
            if (thisRepStat !== undefined && thisRepStat.motionData !== undefined) {
                let thisEndingIndex = determineEndingIndex(thisRepStat.motionData);
                endingIndecies_temp.push(thisEndingIndex);
                let thisStartingIndex = 0;
                for (var j = thisStartingIndex; j <= thisEndingIndex; j++) {
                    //v_stream.push(thisRepStat.motionData[j].acceleration);


                    // Smooth quaternions
                    if (q_stream.length <= quaternionSmoothLength) {
                        q_stream.push(thisRepStat.motionData[j].quaternion);
                    } else {
                        var avgQ: {w: number, x: number, y: number, z: number} = thisRepStat.motionData[j].quaternion;
                        for (var k = 0; k < quaternionSmoothLength; k++) {
                            avgQ.w += q_stream[q_stream.length - 1 - k].w;
                            avgQ.x += q_stream[q_stream.length - 1 - k].x;
                            avgQ.y += q_stream[q_stream.length - 1 - k].y;
                            avgQ.z += q_stream[q_stream.length - 1 - k].z;
                        }

                        avgQ.w /= quaternionSmoothLength + 1;
                        avgQ.x /= quaternionSmoothLength + 1;
                        avgQ.y /= quaternionSmoothLength + 1;
                        avgQ.z /= quaternionSmoothLength + 1;

                        // if ( j % 64 > 10 ) q_stream.push(avgQ);
                        q_stream.push(avgQ)
                    }


                    v_stream.push(thisRepStat.motionData[j].acceleration);
                    


                    
                    // if (thisRepStat.velocityStream_3d !== undefined && thisRepStat.velocityStream_3d[j] !== undefined) {
                    //     v_stream.push(thisRepStat.velocityStream_3d[j]);
                    // }
                }

            }
        }

        // console.log("endingIndecies_temp", endingIndecies_temp);
        setEndingIndecies(endingIndecies_temp);




        // Parse streams and set states
        // V stream
        var points_temp_v_x: number[] = [];
        var points_temp_v_y: number[] = [];
        var points_temp_v_z: number[] = [];
        var labels_temp_v: string[] = [];

        var timeSum = 0;

        for (var i = 0; i < v_stream.length; i++) {
            let thisVEntry = v_stream[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry.x);
            points_temp_v_y.push(thisVEntry.y / 5000);
            points_temp_v_z.push(0); //thisVEntry.z);

            timeSum += thisVEntry.z;
        }

        setNumPts(labels_temp_v.length);

        var tempDataSets_v = JSON.parse(JSON.stringify(initDataSets_v));
        tempDataSets_v.labels = labels_temp_v;
        tempDataSets_v.datasets[0].data = points_temp_v_x;
        tempDataSets_v.datasets[1].data = points_temp_v_y;
        tempDataSets_v.datasets[2].data = points_temp_v_z;

        // console.log(`KJNKNJNJNJNJNJNJNJNJNJNJN: ${timeSum}ms `, v_stream);

        setDataSets_v(tempDataSets_v)

        // Q stream
        /*
        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];
        for (var i = 0; i < q_stream.length; i++) {
            let thisQEntry = q_stream[i];
            labels_temp_q.push(`${i}`);

            points_temp_q_w.push(thisQEntry.w);
            points_temp_q_x.push(thisQEntry.x);
            points_temp_q_y.push(thisQEntry.y);
            points_temp_q_z.push(thisQEntry.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)
        */

        // TEST

        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];

        let qToRotate = {w: 0, x: 0, y: 1, z: 0};

        for (var i = 0; i < q_stream.length; i++) {
            let thisQEntry = q_stream[i];
            labels_temp_q.push(`${i}`);

            let rotatedQ: {w: number, x: number, y: number, z: number} = q_rotate(qToRotate, thisQEntry);


            points_temp_q_w.push(-1);
            points_temp_q_x.push(rotatedQ.x);
            points_temp_q_y.push(rotatedQ.y);
            points_temp_q_z.push(rotatedQ.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)



        // END TEST



        // Generate integrated angle chart data
        var points_temp_q_int: number[] = [];
        var points_temp_q_int_x: number[] = [];
        var points_temp_q_int_y: number[] = [];
        var points_temp_q_int_z: number[] = [];
        var labels_temp_q_int: string[] = [];

        var thisNetAngle = 0.0;
        let n_x = {w: 0, x: 1.0,y: 0.0,z: 0.0};
        let n_y = {w: 0, x: 0.0,y: 1.0,z: 0.0};
        let n_z = {w: 0, x: 0.0,y: 0.0,z: 1.0};

        let minCutoff = 0.04;
        let sumWindowSize = 4;

        var deltaMags: number[] = [];

        // Uncomment for windowed delta summation
        // for (var i = 1; i < q_stream.length; i++) {
        //     let thisQEntry = q_stream[i];
        //     let lastQEntry = q_stream[i - 1]; //{w: 0, x: 0.57735027, y: 0.57735027, z: 0.57735027};//

        //     //let angle = angle_between_quaternions(lastQEntry, thisQEntry);

        //     var r_x = q_inverse_rotate(n_x, thisQEntry);
        //     var r_x_prev = q_inverse_rotate(n_x, lastQEntry);

        //     let rx_delta = {
        //         x: r_x.x - r_x_prev.x,
        //         y: r_x.y - r_x_prev.y,
        //         z: r_x.z - r_x_prev.z
        //     }
        //     let rx_delta_mag = Math.sqrt((rx_delta.x * rx_delta.x) + (rx_delta.y * rx_delta.y) + (rx_delta.z * rx_delta.z));
        //     rx_delta_mag = rx_delta_mag < minCutoff ? 0.0 : rx_delta_mag;
        //     points_temp_q_int_x.push(rx_delta_mag );



        //     var r_y = q_inverse_rotate(n_y, thisQEntry);
        //     var r_y_prev = q_inverse_rotate(n_y, lastQEntry);

        //     let ry_delta = {
        //         x: r_y.x - r_y_prev.x,
        //         y: r_y.y - r_y_prev.y,
        //         z: r_y.z - r_y_prev.z
        //     }
        //     let ry_delta_mag = Math.sqrt((ry_delta.x * ry_delta.x) + (ry_delta.y * ry_delta.y) + (ry_delta.z * ry_delta.z));
        //     ry_delta_mag = ry_delta_mag < minCutoff ? 0.0 : ry_delta_mag;
        //     points_temp_q_int_y.push(ry_delta_mag);



        //     var r_z = q_inverse_rotate(n_z, thisQEntry);
        //     var r_z_prev = q_inverse_rotate(n_z, lastQEntry);

        //     let rz_delta = {
        //         x: r_z.x - r_z_prev.x,
        //         y: r_z.y - r_z_prev.y,
        //         z: r_z.z - r_z_prev.z
        //     }
        //     let rz_delta_mag = Math.sqrt((rz_delta.x * rz_delta.x) + (rz_delta.y * rz_delta.y) + (rz_delta.z * rz_delta.z));
        //     rz_delta_mag = rz_delta_mag < minCutoff ? 0.0 : rz_delta_mag;
        //     points_temp_q_int_z.push(rz_delta_mag);


        //     //points_temp_q_int_x.push(r_x.x - r_x_prev.x);
        //     //points_temp_q_int_y.push(r_x.y - r_x_prev.y);
        //     //points_temp_q_int_z.push(r_x.z - r_x_prev.z);

        //     let r_delta_mag = Math.sqrt((rx_delta_mag * rx_delta_mag) + (ry_delta_mag * ry_delta_mag) + (rz_delta_mag * rz_delta_mag));
            

        //     var r_delta_mag_sum = r_delta_mag;
        //     for (var w = 0; w < sumWindowSize; w++) {
        //         let adjIndex = i - w;
        //         if (deltaMags[adjIndex]) {
        //             r_delta_mag_sum += deltaMags[adjIndex];
        //         }
        //     }

        //     r_delta_mag_sum = i < 32 && r_delta_mag_sum > 0.25 ? 0.25 : r_delta_mag_sum;

        //     deltaMags.push(r_delta_mag);

        //     labels_temp_q_int.push(`${i}`);
        //     points_temp_q_int.push(r_delta_mag_sum);
        // }

        // Velocity test
        // for (var i = 1; i < q_stream.length; i++) {
        //     let thisQEntry = q_stream[i];
        //     let lastQEntry = q_stream[i - 1];

        //     var r_y = q_inverse_rotate(n_y, thisQEntry);
        //     var r_y_prev = q_inverse_rotate(n_y, lastQEntry);

        //     let r_y_angle = angle_between_vector_quaternions(r_y, r_y_prev);

        //     points_temp_q_int.push(r_y_angle);
        //     labels_temp_q_int.push(`${i}`);

        // }

        if (q_stream.length === 0) { return; }
        let starting_q = q_stream[0];
        let starting_v = q_rotate(n_y, starting_q);

        for (var i = 1; i < q_stream.length; i++) {
            let thisQEntry = q_stream[i];
            let thisV = q_rotate(n_y, thisQEntry);

            let angle = angle_between_vector_quaternions(starting_v, thisV);

            labels_temp_q_int.push(`${i}`);
            points_temp_q_int.push(angle);

        }
        
        var tempDataSets_q_int = JSON.parse(JSON.stringify(initDataSets_q_int));
        tempDataSets_q_int.labels = labels_temp_q_int;
        tempDataSets_q_int.datasets[0].borderColor = "#ff0000";
        tempDataSets_q_int.datasets[0].backgroundColor = "#ff0000";
        tempDataSets_q_int.datasets[0].data = points_temp_q_int;
        // tempDataSets_q_int.datasets[0].data = points_temp_q_int_x;
        // tempDataSets_q_int.datasets[1].data = points_temp_q_int_y;
        // tempDataSets_q_int.datasets[2].data = points_temp_q_int_z;

        setDataSets_q_int(tempDataSets_q_int)

        //// console.log("QSTREAM:", q_stream);

        //runMLTest(q_stream);
        composeCSVData(q_stream);

        props.qStreamUpdated(q_stream);

    }

    function updateAllRepStream(forData: MotionData_t[][]) {

        if (forData === undefined || forData.length === 0) { return; }

        setAllMotionData(forData);

        let thisLocationIndex = viewingLocationIndex;

        var v_stream: {x: number, y: number, z: number}[] = [];
        var q_stream: {w: number, x: number, y: number, z: number}[] = [];

        let absLeadingQ: {w: number, x: number, y: number, z: number} = forData !== undefined && forData[thisLocationIndex] !== undefined && forData[thisLocationIndex][0] !== undefined && forData[thisLocationIndex][0].quaternion !== undefined ? forData[thisLocationIndex][0].quaternion : {w: 1, x: 0, y: 0, z: 0};

        //let absLeadingQ: {w: number, x: number, y: number, z: number} = forData === undefined || forData[0] === undefined || forData[0].motionData[0] === undefined || forData[0].motionData[0].quaternion === undefined ? {w: 1, x: 0, y: 0, z: 0} : forData[0].motionData[0].quaternion;

        for (var i = 0; i < pointOffset; i++) {
            q_stream.push(absLeadingQ);
            v_stream.push({x: 0, y: 0, z: 0});
        }

        var endingIndecies_temp: number[] = [];

        //for (var i = 0; i < forData.length; i++) {
            let thisRepStat: MotionData_t[] = forData[thisLocationIndex];
            if (thisRepStat !== undefined) {
                let thisEndingIndex = determineEndingIndex(thisRepStat);
                endingIndecies_temp.push(thisEndingIndex);
                let thisStartingIndex = 0;
                for (var j = thisStartingIndex; j <= thisEndingIndex; j++) {
                    //v_stream.push(thisRepStat.motionData[j].acceleration);


                    // Smooth quaternions
                    if (q_stream.length <= quaternionSmoothLength) {
                        q_stream.push(thisRepStat[j].quaternion);
                    } else {
                        var avgQ: {w: number, x: number, y: number, z: number} = thisRepStat[j].quaternion;
                        for (var k = 0; k < quaternionSmoothLength; k++) {
                            avgQ.w += q_stream[q_stream.length - 1 - k].w;
                            avgQ.x += q_stream[q_stream.length - 1 - k].x;
                            avgQ.y += q_stream[q_stream.length - 1 - k].y;
                            avgQ.z += q_stream[q_stream.length - 1 - k].z;
                        }

                        avgQ.w /= quaternionSmoothLength + 1;
                        avgQ.x /= quaternionSmoothLength + 1;
                        avgQ.y /= quaternionSmoothLength + 1;
                        avgQ.z /= quaternionSmoothLength + 1;


                        if (invertData) {
                            const originalQuaternion = avgQ; //{ w: original_w, x: original_x, y: original_y, z: original_z };
                            const angleInRadians = Math.PI; // 180 degrees in radians
                            const axisX = { x: 1, y: 0, z: 0 };
                            const halfAngle = angleInRadians / 2;
                            const sinHalfAngle = Math.sin(halfAngle);
                            const flippingQuaternion = {
                                w: Math.cos(halfAngle),
                                x: axisX.x * sinHalfAngle,
                                y: axisX.y * sinHalfAngle,
                                z: axisX.z * sinHalfAngle,
                            };

                            // Multiply the original quaternion by the flipping quaternion
                            const newQuaternion = {
                                w: originalQuaternion.w * flippingQuaternion.w - originalQuaternion.x * flippingQuaternion.x - originalQuaternion.y * flippingQuaternion.y - originalQuaternion.z * flippingQuaternion.z,
                                x: originalQuaternion.w * flippingQuaternion.x + originalQuaternion.x * flippingQuaternion.w + originalQuaternion.y * flippingQuaternion.z - originalQuaternion.z * flippingQuaternion.y,
                                y: originalQuaternion.w * flippingQuaternion.y - originalQuaternion.x * flippingQuaternion.z + originalQuaternion.y * flippingQuaternion.w + originalQuaternion.z * flippingQuaternion.x,
                                z: originalQuaternion.w * flippingQuaternion.z + originalQuaternion.x * flippingQuaternion.y - originalQuaternion.y * flippingQuaternion.x + originalQuaternion.z * flippingQuaternion.w,
                            };

                            avgQ = newQuaternion;
                        }

                        //avgQ.w *= 
                        //avgQ.x /= invertData ? -1 : 1;
                        //avgQ.y /= invertData ? -1 : 1;
                       // avgQ.z /= invertData ? -1 : 1;

                        if (analysisAntiquated) {
                            if ( j % 64 > 9 ) q_stream.push(avgQ);
                        } else {
                            q_stream.push(avgQ);
                        }

                        //q_stream.push(avgQ);
                        
                        //
                    }


                    v_stream.push(thisRepStat[j].acceleration);



                    
                    // if (thisRepStat.velocityStream_3d !== undefined && thisRepStat.velocityStream_3d[j] !== undefined) {
                    //     v_stream.push(thisRepStat.velocityStream_3d[j]);
                    // }
                }

            }
        //}

        // console.log("endingIndecies_temp", endingIndecies_temp);
        setEndingIndecies(endingIndecies_temp);




        // Parse streams and set states
        // V stream
        var points_temp_v_x: number[] = [];
        var points_temp_v_y: number[] = [];
        var points_temp_v_z: number[] = [];
        var labels_temp_v: string[] = [];

        var timeSum = 0;

        for (var i = 0; i < v_stream.length; i++) {
            let thisVEntry = v_stream[i];
            labels_temp_v.push(`${i}`);

            points_temp_v_x.push(thisVEntry.x);
            points_temp_v_y.push(thisVEntry.y);
            points_temp_v_z.push(thisVEntry.z);

            timeSum += thisVEntry.z;
        }

        setNumPts(analysisAntiquated ? q_stream.length : labels_temp_v.length);

        var tempDataSets_v = JSON.parse(JSON.stringify(initDataSets_v));
        tempDataSets_v.labels = labels_temp_v;
        tempDataSets_v.datasets[0].data = points_temp_v_x;
        tempDataSets_v.datasets[1].data = points_temp_v_y;
        tempDataSets_v.datasets[2].data = points_temp_v_z;

        // console.log(`KJNKNJNJNJNJNJNJNJNJNJNJN: ${timeSum}ms `, v_stream);

        setDataSets_v(tempDataSets_v)

        // Q stream
        /*
        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];
        for (var i = 0; i < q_stream.length; i++) {
            let thisQEntry = q_stream[i];
            labels_temp_q.push(`${i}`);

            points_temp_q_w.push(thisQEntry.w);
            points_temp_q_x.push(thisQEntry.x);
            points_temp_q_y.push(thisQEntry.y);
            points_temp_q_z.push(thisQEntry.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)
        */

        // TEST

        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];

        let qToRotate = {w: 0, x: 0, y: 1, z: 0};

        for (var i = 0; i < q_stream.length; i++) {
            let thisQEntry = q_stream[i];
            labels_temp_q.push(`${i}`);

            let rotatedQ: {w: number, x: number, y: number, z: number} = q_rotate(qToRotate, thisQEntry);

            // rotatedQ.x *= invertData ? -1 : 1;
            // rotatedQ.y *= invertData ? -1 : 1;
            // rotatedQ.z *= invertData ? -1 : 1;

            points_temp_q_w.push(-1);
            points_temp_q_x.push(rotatedQ.x);
            points_temp_q_y.push(rotatedQ.y);
            points_temp_q_z.push(rotatedQ.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)



        // END TEST



        // Generate integrated angle chart data
        var points_temp_q_int: number[] = [];
        var points_temp_q_int_x: number[] = [];
        var points_temp_q_int_y: number[] = [];
        var points_temp_q_int_z: number[] = [];
        var labels_temp_q_int: string[] = [];

        var thisNetAngle = 0.0;
        let n_x = {w: 0, x: 1.0,y: 0.0,z: 0.0};
        let n_y = {w: 0, x: 0.0,y: 1.0,z: 0.0};
        let n_z = {w: 0, x: 0.0,y: 0.0,z: 1.0};

        let minCutoff = 0.022;
        let sumWindowSize = 4;

        var deltaMags: number[] = [];


        if (q_stream[0] === undefined) { return; }

        let starting_q = q_stream[0]//q_stream[5];
        // let starting_q_cnt = 1;
        // for (var i = 0; i < 30; i++) {
        //     let thisQ = q_stream[i];
        //     if (thisQ) {
        //         starting_q.w += thisQ.w;
        //         starting_q.x += thisQ.x;
        //         starting_q.y += thisQ.y;
        //         starting_q.z += thisQ.z;

        //         starting_q_cnt += 1;
        //     }
        // }

        // starting_q.w /= starting_q_cnt;
        // starting_q.x /= starting_q_cnt;
        // starting_q.y /= starting_q_cnt;
        // starting_q.z /= starting_q_cnt;

        // starting_q = q_normalize(starting_q);

        let starting_v = q_rotate(n_y, starting_q);




        for (var i = 1; i < q_stream.length; i++) {
            let thisQEntry = q_stream[i];
            let thisV = q_rotate(n_y, thisQEntry);

            let angle = angle_between_vector_quaternions(starting_v, thisV);

            labels_temp_q_int.push(`${i}`);
            points_temp_q_int.push(angle);

        }



        // for (var i = 1; i < q_stream.length; i++) {
        //     let thisQEntry = q_stream[i];
        //     let lastQEntry = q_stream[i - 1]; //{w: 0, x: 0.57735027, y: 0.57735027, z: 0.57735027};//

        //     //let angle = angle_between_quaternions(lastQEntry, thisQEntry);

        //     var r_x = q_inverse_rotate(n_x, thisQEntry);
        //     var r_x_prev = q_inverse_rotate(n_x, lastQEntry);

        //     let rx_delta = {
        //         x: r_x.x - r_x_prev.x,
        //         y: r_x.y - r_x_prev.y,
        //         z: r_x.z - r_x_prev.z
        //     }
        //     let rx_delta_mag = Math.sqrt((rx_delta.x * rx_delta.x) + (rx_delta.y * rx_delta.y) + (rx_delta.z * rx_delta.z));
        //     rx_delta_mag = rx_delta_mag < minCutoff ? 0.0 : rx_delta_mag;
        //     points_temp_q_int_x.push(rx_delta_mag );



        //     var r_y = q_inverse_rotate(n_y, thisQEntry);
        //     var r_y_prev = q_inverse_rotate(n_y, lastQEntry);

        //     let ry_delta = {
        //         x: r_y.x - r_y_prev.x,
        //         y: r_y.y - r_y_prev.y,
        //         z: r_y.z - r_y_prev.z
        //     }
        //     let ry_delta_mag = Math.sqrt((ry_delta.x * ry_delta.x) + (ry_delta.y * ry_delta.y) + (ry_delta.z * ry_delta.z));
        //     ry_delta_mag = ry_delta_mag < minCutoff ? 0.0 : ry_delta_mag;
        //     points_temp_q_int_y.push(ry_delta_mag);



        //     var r_z = q_inverse_rotate(n_z, thisQEntry);
        //     var r_z_prev = q_inverse_rotate(n_z, lastQEntry);

        //     let rz_delta = {
        //         x: r_z.x - r_z_prev.x,
        //         y: r_z.y - r_z_prev.y,
        //         z: r_z.z - r_z_prev.z
        //     }
        //     let rz_delta_mag = Math.sqrt((rz_delta.x * rz_delta.x) + (rz_delta.y * rz_delta.y) + (rz_delta.z * rz_delta.z));
        //     rz_delta_mag = rz_delta_mag < minCutoff ? 0.0 : rz_delta_mag;
        //     points_temp_q_int_z.push(rz_delta_mag);


        //     //points_temp_q_int_x.push(r_x.x - r_x_prev.x);
        //     //points_temp_q_int_y.push(r_x.y - r_x_prev.y);
        //     //points_temp_q_int_z.push(r_x.z - r_x_prev.z);

        //     let r_delta_mag = Math.sqrt((rx_delta_mag * rx_delta_mag) + (ry_delta_mag * ry_delta_mag) + (rz_delta_mag * rz_delta_mag));
            

        //     var r_delta_mag_sum = r_delta_mag;
        //     for (var w = 0; w < sumWindowSize; w++) {
        //         let adjIndex = i - w;
        //         if (deltaMags[adjIndex]) {
        //             r_delta_mag_sum += deltaMags[adjIndex];
        //         }
        //     }

        //     r_delta_mag_sum = i < 32 && r_delta_mag_sum > 0.25 ? 0.25 : r_delta_mag_sum;

        //     deltaMags.push(r_delta_mag);

        //     labels_temp_q_int.push(`${i}`);
        //     points_temp_q_int.push(r_delta_mag_sum);
        // }

        // Velocity test
        // for (var i = 1; i < q_stream.length; i++) {
        //     let thisQEntry = q_stream[i];
        //     let lastQEntry = q_stream[i - 1];

        //     var r_y = q_inverse_rotate(n_y, thisQEntry);
        //     var r_y_prev = q_inverse_rotate(n_y, lastQEntry);

        //     var r_y_angle = angle_between_vector_quaternions(r_y, r_y_prev);
        //     if (i % 32 === 0) {
        //         r_y_angle /= 2;
        //     }

        //     points_temp_q_int.push(r_y_angle);
        //     labels_temp_q_int.push(`${i}`);

        // }
        
        var tempDataSets_q_int = JSON.parse(JSON.stringify(initDataSets_q_int));
        tempDataSets_q_int.labels = labels_temp_q_int;
        tempDataSets_q_int.datasets[0].borderColor = "#ff0000";
        tempDataSets_q_int.datasets[0].backgroundColor = "#ff0000";
        tempDataSets_q_int.datasets[0].data = points_temp_q_int;
        // tempDataSets_q_int.datasets[0].data = points_temp_q_int_x;
        // tempDataSets_q_int.datasets[1].data = points_temp_q_int_y;
        // tempDataSets_q_int.datasets[2].data = points_temp_q_int_z;

        setDataSets_q_int(tempDataSets_q_int)

        //runMLTest(q_stream);
        composeCSVData(q_stream);

        //// console.log("QSTREAM:", q_stream);

        props.qStreamUpdated(q_stream);

    }

    function runMLTest(q_stream: {w: number, x: number, y: number, z: number}[]) {

        // console.log("runMLTest", q_stream);

        if (q_stream === undefined || q_stream.length === 0) { return; }

        const quatListBufferLength = 112;        // 448 / 4 = 112 (448 is current tensor size, 4 is number of components in each quaternion)
        var currentRepIndex = -1;

        let manualRepCount = manualRepWindows.length;

        if (manualRepCount === 0) { return; }

        var list_quaternions: {w: number, x: number, y: number, z: number}[][] = [];
        var list_final: number[][] = [];


        // 1. Configure empty arrays for each manual rep
        for (var i = 0; i < manualRepCount; i++) {
            list_final.push([]);
            list_quaternions.push([]);
        }

        // 2. Compose quaternion data lists for each rep. No leading 0's yet
        for (var i = 0 ; i < q_stream.length; i++) {
            // Get current rep index that i is within by examining window extents
            for (var j = 0; j < manualRepCount; j++) {
                let thisManualRep = manualRepWindows[j];
                if (i >= thisManualRep.start_index && i < thisManualRep.end_index) {
                    currentRepIndex = j;
                }
            }

            if (currentRepIndex >= 0) {
                let thisQEntry = q_stream[i];
                list_quaternions[currentRepIndex].push(thisQEntry);
            }
            currentRepIndex = -1;
        }

        // console.log("runMLTest | list_quaternions:", list_quaternions);

        // 3. Add leading 0 buffers
        var list_quaternions_final: {w: number, x: number, y: number, z: number}[][] = [];
        for (var i = 0; i < list_quaternions.length; i++) {
            var thisQuatList: {w: number, x: number, y: number, z: number}[] = list_quaternions[i];
            let thisComposedQuatList: {w: number, x: number, y: number, z: number}[] = getQListWithLeadingZeros(thisQuatList, quatListBufferLength);

            list_quaternions_final.push(thisComposedQuatList);
        }

        // 4. Compose byte buffers
        var byteBuffer: number[][] = [];
        for (var i = 0; i < list_quaternions_final.length; i++) {
            let thisListQuaternion_temp: {w: number, x: number, y: number, z: number}[] = list_quaternions_final[i];
            let byteBuffer_temp = getByteBuffer_noLabel(thisListQuaternion_temp);
            byteBuffer.push(byteBuffer_temp);
        }


        if (props.mlCore === undefined) {
            // console.log("NodeStreamAnalyzeChart | ML Core cannot be found");
            return;
        }

        const TESTING_INDEX = 1;

        let thisQTestBuffer = getQListWithLeadingZeros([], 112);

        let thisByteBuffer = byteBuffer[TESTING_INDEX];//getByteBuffer_noLabel(thisQTestBuffer);

        let res = props.mlCore.predict(thisByteBuffer);

        let resultStrings: string[] = ["Stationary", "Rep Complete", "Unspecified"];
        // console.log("ML TEST RESULT:", resultStrings[res.predicted_index], res, manualRepWindows[TESTING_INDEX]);
    }


    function composeCSVData(q_stream: {w: number, x: number, y: number, z: number}[]) {

        // console.log("composeCSVData | A", q_stream.length);

        if (q_stream === undefined || q_stream.length === 0) { return; }

        // console.log("composeCSVData | B", manualRepWindows.length)

        const quatListBufferLength = 112;        // 448 / 4 = 112 (448 is current tensor size, 4 is number of components in each quaternion)
        var currentRepIndex = -1;

        let manualRepCount = manualRepWindows.length;

        if (manualRepCount === 0) { return; }

        var list_quaternions: {w: number, x: number, y: number, z: number}[][] = [];
        var list_final: number[][] = [];


        // 1. Configure empty arrays for each manual rep
        for (var i = 0; i < manualRepCount; i++) {
            list_final.push([]);
            list_quaternions.push([]);
        }

        // 2. Compose quaternion data lists for each rep. No leading 0's yet
        for (var i = 0 ; i < q_stream.length; i++) {
            // Get current rep index that i is within by examining window extents
            for (var j = 0; j < manualRepCount; j++) {
                let thisManualRep = manualRepWindows[j];
                if (i >= thisManualRep.start_index && i < thisManualRep.end_index) {
                    currentRepIndex = j;
                }
            }

            if (currentRepIndex >= 0) {
                let thisQEntry = q_stream[i];


                // -->> INERJECTION: rotate a Q vector by thisListQuaternion, then get byte buffer of rotated result
                let qToRotate = {w: 0, x: 0, y: 1, z: 0};
                var rotatedQ: {w: number, x: number, y: number, z: number} = q_rotate(qToRotate, thisQEntry);
                rotatedQ.w = -1;

                list_quaternions[currentRepIndex].push(rotatedQ);
            }
            currentRepIndex = -1;
        }

        // 3. Add leading 0 buffers
        var list_quaternions_final: {w: number, x: number, y: number, z: number}[][] = [];
        for (var i = 0; i < list_quaternions.length; i++) {
            var thisQuatList: {w: number, x: number, y: number, z: number}[] = list_quaternions[i];
            let thisComposedQuatList: {w: number, x: number, y: number, z: number}[] = getQListWithLeadingZeros(thisQuatList, quatListBufferLength);

            list_quaternions_final.push(thisComposedQuatList);
        }

        // 4. Compose byte buffers
        var byteBuffer: number[][] = [];
        for (var i = 0; i < list_quaternions_final.length; i++) {
            let thisListQuaternion_temp: {w: number, x: number, y: number, z: number}[] = list_quaternions_final[i];
            let thisListQuaternion_allPermutations: {w: number, x: number, y: number, z: number}[][] = getAllQStreamPermutations(thisListQuaternion_temp);
            //// console.log("thisListQuaternion_allPermutations", i, thisListQuaternion_allPermutations)

            for (var perm_index = 0; perm_index < thisListQuaternion_allPermutations.length; perm_index++) {
                let thisListQuaternion = thisListQuaternion_allPermutations[perm_index];

                // 4.a - add heading columns to this row
                let thisRepWindow: RepWindow_t = manualRepWindows[i];
                var thisLabel: number = 0;
                if (thisRepWindow) {
                    thisLabel = thisRepWindow.is_rep ? (thisRepWindow.injury_id === undefined ? 1 : (isNaN(Number(thisRepWindow.injury_id)) ? 1 : Number(thisRepWindow.injury_id) + 2)) : 0;
                } else {
                    thisLabel = 0;
                }


                let byteBuffer_temp = getByteBuffer(thisListQuaternion, thisLabel);
                byteBuffer.push(byteBuffer_temp);


                // 4.2. Add sub-100% fills with label: "0" to indicate no rep detected
                // For each permutation of the rep's q stream, we need to teach the model that the first
                // bunch of data points in the steam don't constitute a rep. For this, we create an array 
                // of %'s that indicate how many recorded points are filling the buffer; the remaining
                // points are 0's

                const percentageFills = [5, 20, 40, 60, 80];
                for (var p_fill_index = 0; p_fill_index < percentageFills.length; p_fill_index++) {
                    let thisPercentageFill = percentageFills[p_fill_index] / 100;
                    let OGlength = list_quaternions[i].length;
                    let newLength = Math.floor(thisPercentageFill * OGlength);
                    let thisListQuaternion_shortened_temp: {w: number, x: number, y: number, z: number}[] = [];
                    for (var p = 0; p < newLength; p++) {
                        thisListQuaternion_shortened_temp.push(list_quaternions[i][p]);
                    }
                    let thisComposedQuatList_shortened: {w: number, x: number, y: number, z: number}[] = getQListWithLeadingZeros(thisListQuaternion_shortened_temp, quatListBufferLength);
                    let thisByteBuffer_shortened: number[] = getByteBuffer(thisComposedQuatList_shortened, 0);

                    byteBuffer.push(thisByteBuffer_shortened);
                }
            }
        }

        // 5. finalize CSV buffer by adding a label row
        var csvDataFinal: any[][] = [];

        // 5.a - compose header row
        var headerRow: string[] = ["label"];
        for (var i = 0; i < byteBuffer[0].length - 1; i++) {    // size of buffer, minus one for the label column
            headerRow.push(`${i}`);
        }
        csvDataFinal.push(headerRow);

        // 5.b - add data rows
        for (var i = 0; i < byteBuffer.length; i++) {
            csvDataFinal.push(byteBuffer[i]);
        }

        // console.log("FINAL CSV DATA:", csvDataFinal);

        props.updateCSVChartData(csvDataFinal);
    }

    function getByteBuffer(thisListQuaternion: {w: number, x: number, y: number, z: number}[], label: number) {
        // 1. Add full permutation q stream with label:"1" to indicate a rep detected
        var byteBuffer_temp: number[] = [];

        // 2. add heading columns to this row
        byteBuffer_temp.push(label);

        // 3. loop through each quaternion of this rep
        for (var j = 0; j < thisListQuaternion.length; j++) {
            let thisQuat: {w: number, x: number, y: number, z: number} = thisListQuaternion[j];

            // 4.c - loop through each component, make a byte, and push to buffer
            for (var k = 0; k < 4; k++) {
                switch (k) {
                    case 0 :
                        byteBuffer_temp.push(Math.floor(thisQuat.w * 128) + 128);
                        break;
                    case 1 :
                        byteBuffer_temp.push(Math.floor(thisQuat.x * 128) + 128);
                        break;
                    case 2 :
                        byteBuffer_temp.push(Math.floor(thisQuat.y * 128) + 128);
                        break;
                    case 3 :
                        byteBuffer_temp.push(Math.floor(thisQuat.z * 128) + 128);
                        break;
                    default:
                        // console.log("ERROR: check your 'k' loop in 4.c while composing CSV");
                        break;

                }
            }
        }

        return byteBuffer_temp;
    }

    function getByteBuffer_noLabel(thisListQuaternion: {w: number, x: number, y: number, z: number}[]) {
        // 1. Add full permutation q stream with label:"1" to indicate a rep detected
        var byteBuffer_temp: number[] = [];

        // 2. loop through each quaternion of this rep
        for (var j = 0; j < thisListQuaternion.length; j++) {
            let thisQuat: {w: number, x: number, y: number, z: number} = thisListQuaternion[j];

            // 4.c - loop through each component, make a byte, and push to buffer
            for (var k = 0; k < 4; k++) {
                switch (k) {
                    case 0 :
                        byteBuffer_temp.push(Math.floor(thisQuat.w * 128) + 128);
                        break;
                    case 1 :
                        byteBuffer_temp.push(Math.floor(thisQuat.x * 128) + 128);
                        break;
                    case 2 :
                        byteBuffer_temp.push(Math.floor(thisQuat.y * 128) + 128);
                        break;
                    case 3 :
                        byteBuffer_temp.push(Math.floor(thisQuat.z * 128) + 128);
                        break;
                    default:
                        // console.log("ERROR: check your 'k' loop in 4.c while composing CSV");
                        break;

                }
            }
        }

        return byteBuffer_temp;
    }

    function getQListWithLeadingZeros(thisQuatList: {w: number, x: number, y: number, z: number}[], quatListBufferLength: number) {
        let thisQuatListLength = thisQuatList.length;
        let numZeroEntries = quatListBufferLength - thisQuatListLength;

        var thisComposedQuatList: {w: number, x: number, y: number, z: number}[] = [];
        // 1 - add leading 0 entries
        let leadingQ = thisQuatList[0] === undefined ? {w: 0, x: 0, y: 0, z: 0} : thisQuatList[0];
        for (var j = 0; j < numZeroEntries; j++) {
            thisComposedQuatList.push(leadingQ);
        }

        // 2 - add remaining recorded entries
        for (var j = 0; j < thisQuatListLength; j++) {
            thisComposedQuatList.push(thisQuatList[j]);
        }

        return thisComposedQuatList
    }

    function getAllQStreamPermutations(q_stream:  {w: number, x: number, y: number, z: number}[]) {
        var allPermsList:  {w: number, x: number, y: number, z: number}[][] = [];

        const numPermutations = 1; // 6

        for (var n_i = 0; n_i < 1; n_i++) {    // Flip +/- w
            for (var i = 0; i < numPermutations; i++) {
                var thisPermList: {w: number, x: number, y: number, z: number}[] = [];
                for (var j = 0; j < q_stream.length; j++) {
                    let thisQEntry: {w: number, x: number, y: number, z: number} = q_stream[j];

                    switch (i) {
                        case 0:        // {w,x,y,z}
                            thisPermList.push({w: n_i === 0 ? thisQEntry.w : (thisQEntry.w === 0 ? 0 : -1 * thisQEntry.w), x: thisQEntry.x, y: thisQEntry.y, z: thisQEntry.z});
                            break;
                        case 1:        // {w,x,z,y}
                            thisPermList.push({w: n_i === 0 ? thisQEntry.w : (thisQEntry.w === 0 ? 0 : -1 * thisQEntry.w), x: thisQEntry.x, y: thisQEntry.z, z: thisQEntry.y});
                            break;
                        case 2:        // {w,y,z,x}
                            thisPermList.push({w: n_i === 0 ? thisQEntry.w : (thisQEntry.w === 0 ? 0 : -1 * thisQEntry.w), x: thisQEntry.y, y: thisQEntry.z, z: thisQEntry.x});
                            break;
                        case 3:        // {w,y,x,z}
                            thisPermList.push({w: n_i === 0 ? thisQEntry.w : (thisQEntry.w === 0 ? 0 : -1 * thisQEntry.w), x: thisQEntry.y, y: thisQEntry.x, z: thisQEntry.z});
                            break;
                        case 4:        // {w,z,x,y}
                            thisPermList.push({w: n_i === 0 ? thisQEntry.w : (thisQEntry.w === 0 ? 0 : -1 * thisQEntry.w), x: thisQEntry.z, y: thisQEntry.x, z: thisQEntry.y});
                            break;
                        case 5:        // {w,z,y,x}
                            thisPermList.push({w: n_i === 0 ? thisQEntry.w : (thisQEntry.w === 0 ? 0 : -1 * thisQEntry.w), x: thisQEntry.z, y: thisQEntry.y, z: thisQEntry.x});
                            break;
                        default:
                            // console.log("ERROR in NodeStreamAnalyzeChart | getAllQStreamPermutations - trying permutation index that DNE. (index, max)", j, numPermutations);
                            break;
                    }
                }
                allPermsList.push(thisPermList);
            }
        }

        return allPermsList;
    }

    function composeEmptyQStreamArray(ofLength: number) {
        var tempList: number[] = [];

        for (var i = 0; i < ofLength; i++) {
            // Add to temp list for every ofLength
            for (var j = 0; j < 4; j++) {
                // Add one entry for every quaternion component
                tempList.push(0);
            }
        }

        return tempList;
    }

    function repUpdatedCallback(toReps: RepStats_t) {
        
        if (toReps === undefined) { return; }

        var points_temp: number[] = [];
        var labels_temp: string[] = [];

        var unitString = 'm/s';

        let thisRepStat = toReps;

        // console.log("AAAAA ", thisRepStat)
        if (thisRepStat === undefined || thisRepStat.velocityStream === undefined) { return; }

        // Velocity shit

        for (var i = 0; i < thisRepStat.velocityStream.length; i++) {
            let thisVel: number = thisRepStat.velocityStream[i];

            labels_temp.push(`${i + 1}`);
            points_temp.push(thisVel);
        }

        // console.log("row chart repUpdatedCallback:", points_temp.length, points_temp);

        // var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        // tempDataSets.labels = labels_temp;
        // tempDataSets.datasets[0].data = points_temp;

        // setDataSets(tempDataSets);


        // Vector shit
        if (thisRepStat.velocityStream_3d !== undefined) {
            var points_temp_v_x: number[] = [];
            var points_temp_v_y: number[] = [];
            var points_temp_v_z: number[] = [];
            var labels_temp_v: string[] = [];

            for (var i = 0; i < thisRepStat.velocityStream_3d.length; i++) {
                let thisVectorData: {x: number, y: number, z: number} = thisRepStat.velocityStream_3d[i];

                labels_temp_v.push(`${i}`);

                points_temp_v_x.push(thisVectorData.x);
                points_temp_v_y.push(thisVectorData.y);
                points_temp_v_z.push(thisVectorData.z);
            }

            var tempDataSets_v = JSON.parse(JSON.stringify(initDataSets_v));
            tempDataSets_v.labels = labels_temp_v;
            tempDataSets_v.datasets[0].data = points_temp_v_x;
            tempDataSets_v.datasets[1].data = points_temp_v_y;
            tempDataSets_v.datasets[2].data = points_temp_v_z;

            setDataSets_v(tempDataSets_v)
        }


        // Quaternion shit
        if (thisRepStat.motionData === undefined) { return; }

        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];

        for (var i = 0; i < thisRepStat.motionData.length; i++) {
            let thisMotionData: MotionData_t = thisRepStat.motionData[i];

            labels_temp_q.push(`${i}`);

            points_temp_q_w.push(thisMotionData.quaternion.w);
            points_temp_q_x.push(thisMotionData.quaternion.x);
            points_temp_q_y.push(thisMotionData.quaternion.y);
            points_temp_q_z.push(thisMotionData.quaternion.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)
    }

    function determineEndingIndex(forMotionData: MotionData_t[]) {

        var endingIndex_temp = forMotionData.length - 1;

        var matchingCount = 0;
        var maxMatchingCount = 700//12;
        var matchFound = false;

        for (var i = 1; i < forMotionData.length; i++) {
            let lastMotionData: MotionData_t = forMotionData[i - 1];
            let last_q: {w: number, x: number, y: number, z: number} = lastMotionData.quaternion;

            let thisMotionData: MotionData_t = forMotionData[i];
            let this_q: {w: number, x: number, y: number, z: number} = thisMotionData.quaternion;

            if (last_q.w === this_q.w && last_q.x === this_q.x && last_q.y === this_q.y && last_q.z === this_q.z) {
                matchingCount += 1;
                if (matchingCount === maxMatchingCount && !matchFound) {
                    endingIndex_temp = i - maxMatchingCount + 1;
                    matchFound = true;
                }
            } else {
                matchingCount = 0;
            }
        }

        return endingIndex_temp;

        //setEndingIndex(endingIndex_temp)
    }

    function getThisRepChatIndex(thisIndex: number) {
        var thisPosIndex = 0;

        for (var i = 0; i < thisIndex; i++) {
            thisPosIndex += endingIndecies[i];
        }

        return thisPosIndex;
    }

    function getRepMarkerPosition(thisEndingIndex: number, thisIndex: number) {
        //if (dataSets_q.datasets[0] === undefined) { return {}; }
        let leftMarginSize = 0;
        var totalIndex = numPts;//dataSets_q.datasets[0].data.length;
        var thisPosIndex = getThisRepChatIndex(thisIndex);



        var ratio = thisPosIndex / totalIndex;
        var widthPx = containerRef.current ? containerRef.current.offsetWidth : 0;
        var thisXPos = ratio * (widthPx - leftMarginSize);


        //// console.log("ENDING INDEX: ", thisIndex, thisPosIndex, thisXPos, widthPx);

        return {left: `${thisXPos + leftMarginSize}px`};

    }

    function getManualRepMarkerWindowStyle(item: RepWindow_t, index: number) {
        var totalIndex = numPts;//dataSets_q.datasets[0].data.length;
        var thisStatingPosIndex = item.start_index//getThisRepChatIndex(thisIndex);
        var thisWidthIndecies = item.end_index - item.start_index;

        var ratio_left = thisStatingPosIndex / totalIndex;
        var ratio_width = thisWidthIndecies / totalIndex;

        var divWidthPx = containerRef.current ? containerRef.current.offsetWidth : 0;

        var thisXPos = ratio_left * divWidthPx;
        var thisWidth = ratio_width * divWidthPx;


        return {left: `${thisXPos}px`, width: `${thisWidth}px`, borderColor: item.is_rep ? (item.injury_id === undefined ? '#5BFF62' : '#FFFF12') : '#FF4D4D', backgroundColor: item.is_rep ? (item.injury_id === undefined ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,150,0.2)') : 'rgba(255,100,100,0.1)'};
    }

    function getAddingRepMarkerWindowStyle() {
        if (addingRepWindow === undefined) {
            return {display: 'none'};
        }

        var totalIndex = numPts;//dataSets_q.datasets[0].data.length;
        var thisStatingPosIndex = addingRepWindow.start_index//getThisRepChatIndex(thisIndex);
        var thisWidthIndecies = addingRepWindow.end_index - addingRepWindow.start_index;

        var ratio_left = thisStatingPosIndex / totalIndex;
        var ratio_width = thisWidthIndecies / totalIndex;

        var divWidthPx = containerRef.current ? containerRef.current.offsetWidth : 0;

        var thisXPos = ratio_left * divWidthPx;
        var thisWidth = ratio_width * divWidthPx;


        return {left: `${thisXPos}px`, width: `${thisWidth}px`, borderColor: addingRepWindow.is_rep ? '#5BFF62' : '#FF4D4D', backgroundColor: addingRepWindow.is_rep ? 'rgba(255,255,255,0.1)' : 'rgba(255,100,100,0.1)'};
    }

    function mouseMoveDiv(event: any) {
        //// console.log(event.clientX - 220);
        if (simIndex >= 0) { return; }
        let posX = event.clientX - 220 - 26;
        setHoverPosX(posX);

        var widthPx = containerRef.current ? containerRef.current.offsetWidth : 0;
        let progressPercent = posX / (widthPx < 1 ? 10 : widthPx);
        props.mousProgressUpdated(progressPercent);

        updateCurrentMotionData(progressPercent);

        if (addingRepWindow !== undefined) {
            let hoverPoint = Math.floor(progressPercent * numPts);
            setAddingRepWindow({...addingRepWindow, end_index: hoverPoint});
        }
    }

    function chartClicked(event: any) {

        if (addingRepWindows === false) { return; }

        let posX = event.clientX - 220 - 26;
        var widthPx = containerRef.current ? containerRef.current.offsetWidth : 0;
        let progressPercent = posX / (widthPx < 1 ? 10 : widthPx);

        let hoverPoint = Math.floor(progressPercent * numPts);

        if (hoverPoint < 1) { return; }

        if (addingRepWindow === undefined) {
            // Adding new rep window
            setAddingRepWindow({
                is_rep: windowsAreReps,
                start_index: hoverPoint,
                end_index: hoverPoint
            });

        } else {
            // Finish adding current rep window

            if (hoverPoint - addingRepWindow.start_index >= 110) { return; }

            if (addMore) {
                setAddingRepWindow({
                    is_rep: windowsAreReps,
                    start_index: hoverPoint,
                    end_index: hoverPoint
                });
            } else {
                setAddingRepWindow(undefined);
            }


            
            setManualRepWindows(manualRepWindows.concat({
                is_rep: windowsAreReps,
                start_index: addingRepWindow.start_index,
                end_index: hoverPoint,
                injury_id: selectedInjuryType === undefined ? undefined : selectedInjuryType.id
            }));

        }

    }

    function updateCurrentMotionData(forProgress: number) {
        let thisPoint = Math.floor(forProgress * numPts) + 1;

        var thisQ = {
            w: dataSets_q && dataSets_q.datasets && dataSets_q.datasets[0] && dataSets_q.datasets[0].data[thisPoint] ? dataSets_q.datasets[0].data[thisPoint] : -1,
            x: dataSets_q && dataSets_q.datasets && dataSets_q.datasets[1] && dataSets_q.datasets[1].data[thisPoint] ? dataSets_q.datasets[1].data[thisPoint] : -1,
            y: dataSets_q && dataSets_q.datasets && dataSets_q.datasets[2] && dataSets_q.datasets[2].data[thisPoint] ? dataSets_q.datasets[2].data[thisPoint] : -1,
            z: dataSets_q && dataSets_q.datasets && dataSets_q.datasets[3] && dataSets_q.datasets[3].data[thisPoint] ? dataSets_q.datasets[3].data[thisPoint] : -1,
        }

        var thisV = {
            x: dataSets_v && dataSets_v.datasets && dataSets_v.datasets[0] && dataSets_v.datasets[0].data[thisPoint] ? dataSets_v.datasets[0].data[thisPoint] : -1,
            y: dataSets_v && dataSets_v.datasets && dataSets_v.datasets[1] && dataSets_v.datasets[1].data[thisPoint] ? dataSets_v.datasets[1].data[thisPoint] : -1,
            z: dataSets_v && dataSets_v.datasets && dataSets_v.datasets[2] && dataSets_v.datasets[2].data[thisPoint] ? dataSets_v.datasets[2].data[thisPoint] : -1
        }

        let newMotionData: MotionData_t = {
            acceleration: thisV,
            quaternion: thisQ,
            timestamp: Date.now()
        };

        props.updateCurrentMotionData(newMotionData);

    }

    function getProgressXPos() {
        var widthPx = containerRef.current ? containerRef.current.offsetWidth : 0;
        var thisXPos = videoProgress * (widthPx);

        return {left: `${thisXPos}px`};
    }


    // QUATERNION FUNCTIONS

    function q_normalize(q: {w: number, x: number, y: number, z: number}) 
    {
        var result: {w: number, x: number, y: number, z: number} = {w:1.0,x:0.0,y:0.0,z:0.0};
        const quaternionMagSq = Math.sqrt((q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z));
        result.w = q.w / quaternionMagSq;
        result.x = q.x / quaternionMagSq;
        result.y = q.y / quaternionMagSq;
        result.z = q.z / quaternionMagSq;

        return result;
    }

    function q_multiply(q: any, p: any)
    {
        var result = {w: 1, x: 0, y: 0, z:0};

        result.w = q.w*p.w - q.x*p.x - q.y*p.y - q.z*p.z;
        result.x = q.x*p.w + q.w*p.x - q.z*p.y + q.y*p.z;
        result.y = q.y*p.w + q.z*p.x + q.w*p.y - q.x*p.z;
        result.z = q.z*p.w - q.y*p.x + q.x*p.y + q.w*p.z;

        return result;

    }

    function q_invert(q: any)
    {
        var result = {w: 1, x: 0, y: 0, z:0};
        
        const quaternionMagSq = (q.w*q.w) + (q.x*q.x) + (q.y*q.y) + (q.z*q.z);
        result.w = q.w / quaternionMagSq;
        result.x = -1 * q.x / quaternionMagSq;
        result.y = -1 * q.y / quaternionMagSq;
        result.z = -1 * q.z / quaternionMagSq;

        return result;
    }

    function angle_between_vector_quaternions(v_a: {w: number, x: number, y: number, z: number}, v_b: {w: number, x: number, y: number, z: number}) {
        
        let dp = ((v_a.x * v_b.x) + (v_a.y * v_b.y) + (v_a.z * v_b.z));
        let vectorMag_a = Math.sqrt((v_a.x * v_a.x) + (v_a.y * v_a.y) + (v_a.z * v_a.z));
        let vectorMag_b = Math.sqrt((v_b.x * v_b.x) + (v_b.y * v_b.y) + (v_b.z * v_b.z));

        let prod = dp / (vectorMag_a * vectorMag_b)

        let d_theta = Math.acos(prod) * 57.2957795131;//2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

        return d_theta;
    }

    function angle_between_quaternions(q_a: {w: number, x: number, y: number, z: number}, q_b: {w: number, x: number, y: number, z: number}) {
        let q_inv = q_invert(q_a);
        q_inv.x = q_inv.x * -1;
        q_inv.y = q_inv.y * -1;
        q_inv.z = q_inv.z * -1;
        let res = q_multiply(q_inv, q_b);

        //res.w = res.w > 1.0 ? 1.0 : (res.w < -1.0 ? 1.0 : res.w);

        //// console.log(res.w, Math.acos(res.w));
        let vectorMag = Math.sqrt((res.x * res.x) + (res.y * res.y) + (res.z * res.z));
        let d_theta = 2.0 * Math.acos(res.w) * 57.2957795131;//2.0 * Math.atan2(vectorMag, res.w) * 57.2957795131; //2.0 * Math.acos(res.w) * 57.2957795131;

        return d_theta;
    }

    function q_rotate(q: any, w: any)
    {
        var w_inv = {w: 1, x: 0, y: 0, z:0};
        var a_world = {w: 1, x: 0, y: 0, z:0};
        var w_a = {w: 1, x: 0, y: 0, z:0};
        var a_body = {w: 1, x: 0, y: 0, z:0};

        a_body    = q_normalize(q);
        w_inv     = q_invert(q_normalize(w));

        w_a       = q_multiply(w, a_body);
        a_world   = q_multiply(w_a, w_inv);

        return a_world;
    }

    function q_inverse_rotate(q: {w: number, x: number, y: number, z: number}, w: {w: number, x: number, y: number, z: number})
    {
        var a_world: {w: number, x: number, y: number, z: number} = q;
        var w_inv: {w: number, x: number, y: number, z: number} = q_invert(w);

        var w_a: {w: number, x: number, y: number, z: number} = q_multiply(w_inv, a_world);
        var a_body: {w: number, x: number, y: number, z: number} = q_multiply(w_a, w);

        return a_body;
    }


    // END QUATERNION FUNCTIONS



	return (
		<div ref={containerRef} 
             onMouseMove={(e: any) => mouseMoveDiv(e)} 
             onClick={(e: any) => chartClicked(e)}
             className="node-stream-analysis-chart">

            <div onClick={() => beginSim()} className="node-stream-analysis-chart-simulate-button">{simIndex < 0 ? "SIMULATE" : "END SIM"}</div>
            <div onClick={() => updateViewingIndex(viewingLocationIndex - 1)} className="node-stream-analysis-chart-update-location-button node-stream-analysis-chart-update-location-button-minus"><p>Prev Loc</p></div>
            <div onClick={() => updateViewingIndex(viewingLocationIndex + 1)} className="node-stream-analysis-chart-update-location-button node-stream-analysis-chart-update-location-button-plus"><p>Next Loc</p></div>
            <div className="node-stream-analysis-chart-update-location-button node-stream-analysis-chart-update-location-button-viewing"><p>Viewing {nodeLocationNames[viewingLocationIndex]} ({viewingLocationIndex})</p></div>
            {
                analysisAntiquated && <div className="node-stream-analysis-chart-update-location-button node-stream-analysis-chart-update-location-button-antiquated">ANTIQUATED | FIXED</div>
            }
            {
                <div className="node-stream-analysis-chart-update-location-button node-stream-analysis-chart-update-location-button-stream" onClick={ () => toggleInversionState() }>{ invertData ? "Uninvert Stream" : "Invert Stream" }</div>
            }
            <div className="node-stream-analysis-chart-rep-marker-container">
                {
                    endingIndecies.map((item: number, index: number) => (
                        <div hidden={!showAutomaticReps} className="node-stream-analysis-chart-rep-marker" style={getRepMarkerPosition(item, index)}>
                            <div className="node-stream-analysis-chart-rep-marker-inner">
                                <div className="node-stream-analysis-chart-rep-marker-text">
                                    <p>{index + 1}</p>
                                    <p>{getThisRepChatIndex(index)}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    manualRepWindows.map((item: RepWindow_t, index: number) => (
                        <div hidden={showAutomaticReps} className="node-stream-analysis-chart-manual-rep-marker" style={getManualRepMarkerWindowStyle(item, index)}>
                            <div className="node-stream-analysis-chart-rep-marker-inner">
                                <div className="node-stream-analysis-chart-rep-marker-text">
                                    <p>{index + 1}</p>
                                    <p>{item.start_index} - {item.end_index} ({item.end_index - item.start_index})</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    detectedRepWindows.map((item: RepWindow_t, index: number) => (
                        <div hidden={showAutomaticReps} className="node-stream-analysis-chart-manual-rep-marker node-stream-analysis-chart-manual-rep-marker-detected" style={getManualRepMarkerWindowStyle(item, index)}>
                            <div className="node-stream-analysis-chart-rep-marker-inner">
                                <div className="node-stream-analysis-chart-rep-marker-text">
                                    <p>{index + 1}</p>
                                    <p>{item.start_index} - {item.end_index} ({item.end_index - item.start_index})</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    testing_risingWindows.map((item: RepWindow_t, index: number) => (
                        <div hidden={showAutomaticReps} className="node-stream-analysis-chart-manual-rep-marker node-stream-analysis-chart-manual-rep-marker-rising" style={getManualRepMarkerWindowStyle(item, index)}>
                            <div className="node-stream-analysis-chart-rep-marker-inner">
                                <div className="node-stream-analysis-chart-rep-marker-text">
                                    <p>{index + 1}</p>
                                    <p>{item.start_index} - {item.end_index} ({item.end_index - item.start_index})</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    testing_pauseWindows.map((item: RepWindow_t, index: number) => (
                        <div hidden={showAutomaticReps} className="node-stream-analysis-chart-manual-rep-marker node-stream-analysis-chart-manual-rep-marker-pause" style={getManualRepMarkerWindowStyle(item, index)}>
                            <div className="node-stream-analysis-chart-rep-marker-inner">
                                <div className="node-stream-analysis-chart-rep-marker-text">
                                    <p>{index + 1}</p>
                                    <p>{item.start_index} - {item.end_index} ({item.end_index - item.start_index})</p>
                                </div>
                            </div>
                        </div>
                    ))
                }

                
                {
                    testing_holdWindows.map((item: RepWindow_t, index: number) => (
                        <div hidden={showAutomaticReps} className="node-stream-analysis-chart-manual-rep-marker node-stream-analysis-chart-manual-rep-marker-hold" style={getManualRepMarkerWindowStyle(item, index)}>
                            <div className="node-stream-analysis-chart-rep-marker-inner">
                                <div className="node-stream-analysis-chart-rep-marker-text">
                                    <p>{index + 1}</p>
                                    <p>{item.start_index} - {item.end_index} ({item.end_index - item.start_index})</p>
                                </div>
                            </div>
                        </div>
                    ))
                }

                
                <div hidden={addingRepWindow === undefined} className="node-stream-analysis-chart-manual-rep-marker node-stream-analysis-chart-manual-rep-marker-adding" style={getAddingRepMarkerWindowStyle()}>
                    <div className="node-stream-analysis-chart-rep-marker-inner">
                        <div className="node-stream-analysis-chart-rep-marker-text">
                            <p>{manualRepWindows.length + 1}</p>
                            <p>{addingRepWindow === undefined ? '' : addingRepWindow.start_index} - {addingRepWindow === undefined ? '' : addingRepWindow.end_index} ({(addingRepWindow === undefined ? 0 : addingRepWindow.end_index) - (addingRepWindow === undefined ? 0 : addingRepWindow.start_index)})</p>
                        </div>
                    </div>
                </div>
                <div className="node-stream-analysis-chart-rep-marker node-stream-analysis-chart-rep-marker-hover" style={{left: `${hoverPosX}px`}}>
                    
                </div>
                <div className="node-stream-analysis-chart-rep-marker node-stream-analysis-chart-rep-marker-video" style={getProgressXPos()}>
                    
                </div>
            </div>
			
            <div className="node-stream-analyze-chart-container">
                <div className="node-stream-analyze-chart-container-header">
                </div>
                <div className={ "node-stream-analyze-chart-container-mid-line" }></div>
                <Line
                    data={dataSets_q}
                    options={chartSettings_q}
                />
            </div>

            <div className="node-stream-analyze-chart-container">
                <div className="node-stream-analyze-chart-container-header">
                </div>
                <Line
                    data={dataSets_q_int}
                    options={chartSettings_q_int}
                />
            </div>
            <div hidden={false} className="node-stream-analyze-chart-container node-stream-analyze-chart-container-velocity">
                <div className="node-stream-analyze-chart-container-header">
                    <h4>Magnetometer Data</h4>
                </div>
                <div className={ "node-stream-analyze-chart-container-mid-line" }></div>
                <Line
                    data={dataSets_v}
                    options={chartSettings_v}
                />
            </div>
		</div>
	)
}

export default NodeStreamAnalyzeChart;