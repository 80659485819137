import React, { useState, useEffect } from 'react';
import { IonInput, IonTextarea } from '@ionic/react';
import { FileDrop } from 'react-file-drop';
import firebase from '../../../../Firebase';
import './InformationSection.css';

import InformationSection_t from '../../../../Interfaces/CoachingProfile/InformationSection_t';

import CheckBox from '../../../../components/Base/CheckBox';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Image from '@material-ui/icons/Image';

function InformationSection(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [sectionIndex, setSectionIndex] = useState(0);

    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadWorkflowState, setUploadWorkflowState] = useState(0);

    const [acceptingClients, setAcceptingClients] = useState(false);

    const [infoSection, setInfoSection] = useState<InformationSection_t>({
        title: "",
        content: "",
        hasImage: false,
        hasButton: false,
        buttonText: "Connect with me",
        imageURL: "https://images.unsplash.com/photo-1623200216581-969d9479cf7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fHdvcmtvdXR8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60"
    });

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.infoSection !== undefined) {
            setInfoSection(props.infoSection);
        }
    }, [props.infoSection])

    useEffect(() => {
        if (props.sectionIndex !== undefined) {
            setSectionIndex(props.sectionIndex);
        }
    }, [props.sectionIndex])

    useEffect(() => {
        if (props.acceptingClients !== undefined) {
            setAcceptingClients(props.acceptingClients);
        }
    }, [props.acceptingClients])

    function inputUpdated(e: any) {

    }

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'title':
                let newInfoSection: InformationSection_t = { ...infoSection, title: toString };
                setInfoSection(newInfoSection)
                props.updateSection(newInfoSection);
                break;
            case 'content':
                let newInfoSection_content: InformationSection_t = { ...infoSection, content: toString };
                setInfoSection(newInfoSection_content)
                props.updateSection(newInfoSection_content);
                break;
            default:
                break; 
        }
    }

    function toggleIsOpen() {
        setIsOpen(!isOpen)
    }

    function updateShowButtonState(toState: boolean) {
        let newInfoSection: InformationSection_t = { ...infoSection, hasButton: toState };
        setInfoSection(newInfoSection)
        props.updateSection(newInfoSection);
    }

    function updateShowImageState(toState: boolean) {
        let newInfoSection: InformationSection_t = { ...infoSection, hasImage: toState };
        setInfoSection(newInfoSection)
        props.updateSection(newInfoSection);
    }

    function updateImageURL(toURL: string) {
        let newInfoSection: InformationSection_t = { ...infoSection, imageURL: toURL };
        setInfoSection(newInfoSection)
        props.updateSection(newInfoSection);
    }

    function uploadImage(files: FileList | null, event: any) {
        if (files === null) { return; }
        //if (props.information === undefined || props.information.path === undefined) { return; }
        // console.log('onDrop!', files, event);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setUploadWorkflowState(1);

        let storage = firebase.storage();
        let uploadTask = storage.ref(`images/coaching_profile/${trainerUID}/section_${sectionIndex}.png`).put(files[0]);

        uploadTask.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        }, (error) => {
            // error
            setUploadWorkflowState(0);
        }, () => {
            // completed
            uploadTask.snapshot.ref.getDownloadURL()
            .then(fireBaseUrl => {
                setUploadWorkflowState(0);
                updateImageURL(fireBaseUrl);
            })
            .catch(error => {
                //// console.log('No image found');
                //// console.log(error);
                setUploadWorkflowState(0);
            })

        });

        // storage.ref(props.information.path).put(files[0]).then((resp: any) => {
        //     setTimeout(() => {
        //         if (props.information.completedCallback !== undefined) {
        //             props.information.completedCallback();
        //         }
        //     }, 500);
            
        // }).catch((e: any) => {

        // })

    }

	return (
		<div className="my-profile-information-section">
			<div className="my-profile-information-section-number-container">
                <h2>{sectionIndex + 1 < 10 ? '0' : ''}{sectionIndex + 1}</h2>
            </div>
            <div className="my-profile-information-section-card">
                <div className="my-profile-information-section-card-header">
                    <div hidden={isOpen} onClick={() => toggleIsOpen()} className="my-profile-information-section-card-header-text my-profile-information-section-card-header-text-frozen">
                        <h2>{infoSection.title}</h2>
                    </div>
                    <div hidden={!isOpen} className="my-profile-information-section-card-header-text my-profile-information-section-card-header-text-editable">
                        <div className="my-profile-information-section-card-header-text-editable-inner">
                            <IonTextarea value={infoSection.title} 
                                class=""
                                placeholder={`Enter a section title...`}
                                inputmode="text"
                                clearOnEdit={false}
                                autoGrow={true}
                                rows={1}
                                maxlength={500}
                                onKeyPress={e => inputUpdated(e.key)}
                                onIonChange={e => inputChanged(e.detail.value!, 'title')}/>
                        </div>
                    </div>
                    <div onClick={() => toggleIsOpen()} hidden={!isOpen} className="my-profile-information-section-card-header-arrow-container">
                        <ExpandLess className="my-profile-information-section-card-header-arrow-icon"/>
                    </div>
                    <div onClick={() => toggleIsOpen()} hidden={isOpen} className="my-profile-information-section-card-header-arrow-container">
                        <ExpandMore className="my-profile-information-section-card-header-arrow-icon"/>
                    </div>
                </div>
                <div hidden={!isOpen} className="my-profile-information-section-card-body">
                    <div className="my-profile-information-section-card-body-content-container">
                        <p>CONTENT</p>
                        <div className="my-profile-information-section-card-header-text-editable-inner my-profile-information-section-card-body-text-editable-inner">
                            <IonTextarea value={infoSection.content} 
                                class=""
                                placeholder={`Enter content for this section...`}
                                inputmode="text"
                                clearOnEdit={false}
                                autoGrow={true}
                                rows={1}
                                maxlength={500}
                                onKeyPress={e => inputUpdated(e.key)}
                                onIonChange={e => inputChanged(e.detail.value!, 'content')}/>
                        </div>
                    </div>
                    <div className="my-profile-information-section-card-body-image-container">
                        <div className="my-profile-information-section-card-body-option-container">
                            <div className="my-profile-information-section-card-body-option-checkbox-container">
                                <CheckBox 
                                    forceState={infoSection.hasImage}
                                    stateChanged={(s: boolean) => updateShowImageState(s)}/>
                            </div>
                            <div className="my-profile-information-section-card-body-option-text-container">
                                <h4>Display Section Image</h4>
                                <p>Showcase an image at the bottom of this section, following the content above.</p>
                            </div>
                        </div>
                        <div hidden={infoSection.imageURL === undefined || infoSection.imageURL === ""} className="my-profile-information-section-card-body-image-content">
                            
                            <div className="my-profile-information-section-card-body-image-content-img-container">
                                <img className="" src={infoSection.imageURL === undefined ? '' : infoSection.imageURL}/>
                            </div>
                            
                            <div className="my-profile-information-section-card-body-image-content-text-container">
                                <h4>Section {sectionIndex + 1 < 10 ? '0' : ''}{sectionIndex + 1} Image</h4>
                                <div onClick={() => updateImageURL("")} className="my-profile-information-section-card-body-image-content-remove-button">
                                    <Close className="my-profile-information-section-card-body-image-content-remove-button-icon"/>
                                    <p>Remove image</p>
                                </div>
                            </div>
                        </div>
                        <div hidden={infoSection.imageURL !== ""} className="my-profile-information-section-card-body-image-content my-profile-information-section-card-body-image-content-upload">
                            <FileDrop
                                onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                onDragOver={(event) => console.log('onDragOver', event)}
                                onDragLeave={(event) => console.log('onDragLeave', event)}
                                onDrop={(files, event) => uploadImage(files, event)}>
                                    <div className="my-coaching-profile-drop-area-inner-container">
                                        <div className="my-coaching-profile-drop-area-icon-container">
                                            <Image className="my-coaching-profile-drop-area-icon"/>
                                        </div>
                                        <h4>Drop an image here to upload</h4>
                                        <p>Files less than 10MB are recommended, in .png, .jpg, or .gif formats.</p>
                                    </div>
                                    <div hidden={uploadWorkflowState !== 1} className="upload-image-modal-loading-bar-container">
                                        <div className="upload-image-modal-loading-bar">
                                            <div className="upload-image-modal-loading-bar-inner" style={{width: `${uploadProgress}%`}}/>
                                        </div>
                                        <p>{Math.round(uploadProgress)}%</p>
                                    </div>
                            </FileDrop>
                        </div>
                    </div>
                    <div hidden={!acceptingClients} className="my-profile-information-section-card-body-button-container">
                        <div className="my-profile-information-section-card-body-option-container">
                            <div className="my-profile-information-section-card-body-option-checkbox-container">
                                <CheckBox 
                                    forceState={infoSection.hasButton}
                                    stateChanged={(s: boolean) => updateShowButtonState(s)}/>
                            </div>
                            <div className="my-profile-information-section-card-body-option-text-container">
                                <h4>Connect with Me Button</h4>
                                <p>Include button that allows Athletes to connect directly with you. A "Connect with me" button will appear on the bottom of your profile regardless of it being in an information section.</p>
                            </div>
                        </div>
                    </div>
                    <div className="my-profile-information-section-card-body-remove-container">
                        <div onClick={() => props.removeInfoSection()} className="my-profile-information-section-card-body-remove-button">
                            <Close className="my-profile-information-section-card-body-remove-icon"/>
                            <p>Remove this information section</p>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default InformationSection;