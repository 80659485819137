import React, {useEffect, useState} from 'react';
import { ReactMediaRecorder } from "react-media-recorder";

import './ClientChat.css'

import ClientChatInput from './ClientChatInput';

import sendMessageIcon from '../../../theme/send-message-icon.png';
import ClientChatMessage from "./ClientChatMessage";
import firebase from "firebase";
import postCheckAndRetrieveChat from "./postCheckAndRetrieveChat";
import sendChatMessage from "./sendChatMessage";
import dateTimeParser from "./dateTimeParser";

import messageSentPushNotification from '../../../api/notifications/push/messageSentPushNotification';

const ClientChat = (props: any) => {

    const [messageText, setMessageText] = useState('');
    const [isFocused, setFocused] = useState(false);
    const [messages, setMessages] = useState<any[]>([])
    const [chatId, setChatId] = useState('');
    const [createdAt, setCreatedAt] = useState(0)
    const [initSet, setInitSet] = useState(false);

    const [showVideoRec, setShowVideoRec] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSend, setIsLoadingSend] = useState(false);

    const [imgUrl_trainer, setImgUrl_trainer] = useState("");
    const [imgUrl_client, setImgUrl_client] = useState("");

    const [clientInitials, setClientInitials] = useState("");
    const [trainerInitials, setTrainerInitials] = useState("Me");

    const currentUser = firebase.auth().currentUser;
    const trainerUID = currentUser?.uid;
    const clientID = props.client.id
    const client_first_name = props.client.first_name

    const transformChatMessage = (original_chat: any, currentUserID: string | undefined, clientUserName: string | undefined) => {
        const messageID = Object.keys(original_chat)[0];
        const {message, sent_by, timestamp, read_by} = original_chat[messageID];

        return {
            messageID,
            sent_by,
            message,
            sentByClient: sent_by != currentUserID,
            timestamp: timestamp,
            userName: sent_by == currentUserID ? 'Me' : clientUserName,
            read_by: read_by
        }
    }

    let transformedMessages: any[] = [];
    const chatMessagesToList = (chat_messages_obj: any, currentUserID: string | undefined, clientUserName: string | undefined) => {
        for (const messageId in chat_messages_obj) {
            const transformedMessage = transformChatMessage(
                {[messageId]: chat_messages_obj[messageId]},
                currentUserID,
                clientUserName
            );
            transformedMessages.push(transformedMessage);
        }
        return transformedMessages
    }

    const retrieveChatData = async () => {
        try {
            setIsLoading(true);
            const result = await postCheckAndRetrieveChat(clientID);
            if (result) {
                setChatId(result.chatID)
                setIsLoading(false);
            } else {
                configNewChat();
            }
        } catch (error) {
            // console.log('Error:', error);
            configNewChat();
        }
    };

    const configNewChat = async () => {
        let currentUserTemp = firebase.auth().currentUser;
        if (!currentUserTemp) { return; }
        let trainerUIDTemp = currentUserTemp.uid;

        let currentUserUIDTemp = props.client.id ?? clientID ?? "_NO_CLIENT_";

        // 1. Create a new chat and retrieve ID
        let chatObj = {
            created_at: Date.now(),
            members: [ trainerUIDTemp, currentUserUIDTemp ]
        }
        // console.log("NEW CHAT OBJECT: ", chatObj)
        let chatsRef = firebase.database().ref(`messages/chats`);
        let chatPushResp = await chatsRef.push(chatObj);

        let chatID = chatPushResp ? chatPushResp.key : "";
        // console.log("NEW CHAT ID: ", chatID)
        if (!chatID || chatID === "") return;

        // 2. Add this chat to the trainer's inbox
        let inboxTrainerRef = firebase.database().ref(`messages/inboxes/${trainerUIDTemp}/chat_ids/${chatID}`);
        inboxTrainerRef.set(chatID);

        // 3. Add this chat to the client's inbox
        let inboxClientRef = firebase.database().ref(`messages/inboxes/${currentUserUIDTemp}/chat_ids/${chatID}`);
        inboxClientRef.set(chatID);

        setTimeout(() => {
            retrieveChatData();
        }, 200);

    }

    const getCreatedAt = async (chat_id: string) => {
        try {
            const snapshot = await firebase
                .database()
                .ref(`messages/chats/${chat_id}/created_at`).once('value');
            const createdAtValue = snapshot.val();
            setCreatedAt(createdAtValue)

        } catch (error) {
            console.error('Error fetching value:', error);
        }
    }

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            retrieveChatData();

            
        }
    }, [initSet])


    useEffect( () => {
        getCreatedAt(chatId)
        if (chatId !== ''){
            // Real-time update listener
            const chatMessagesRef = firebase
                .database()
                .ref(`messages/chats/${chatId}/chat_messages`);

            const handleChatMessagesUpdate = async (snapshot: any) => {
                const updatedMessages = await snapshot.val();
                transformedMessages = [];
                if (updatedMessages) {
                    const processedMessages = chatMessagesToList(
                        updatedMessages,
                        trainerUID,
                        client_first_name
                    );
                    transformedMessages = [];
                    setMessages(processedMessages.reverse());
                }
            };
            chatMessagesRef.on('value', handleChatMessagesUpdate);

            return () => {
                chatMessagesRef.off('value', handleChatMessagesUpdate);
            };
        }
    }, [chatId]);

    useEffect(() => {
        console.log("-- props client chat!!", props.client)
        if (props.client && props.client.id) {
            if (props.client.id !== "") getImage(props.client.id, false);

            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { return; }
            let trainerUID = currentUser.uid;

            getImage(trainerUID, true);

        }
        if (props.client && props.client.first_name && props.client.first_name !== "") {
            let nameSplit = props.client.first_name.split(" ");
            let fName = nameSplit[0];
            let lName = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : "";

            let fName_fInitial = fName !== undefined ? fName.split("")[0] : "";
            let lName_fInitial = lName !== undefined ? lName.split("")[0] : "";
            setClientInitials(`${fName_fInitial}${lName_fInitial}`);
        }
    }, [props.client])

    const getImage = (withUID: string, isTrainer: boolean) => {
        if (!withUID || withUID === "") { return; }

        console.log("getImage!", withUID, isTrainer)

        const storage = firebase.storage();
        storage.ref('profileImages').child(withUID).child('profileImage/image.jpg').getDownloadURL()
        .then(fireBaseUrl => {
            console.log("URL!", withUID, isTrainer, fireBaseUrl)
            if (isTrainer) {
                setImgUrl_trainer(fireBaseUrl);
            } else {
                setImgUrl_client(fireBaseUrl);
            }
        })
        .catch(error => {
            
        })
    }

    const handleInputChange = (event: any) => {
        const message = event.target.value;
        setMessageText(message);
    };

    const handleSend = async () => {
        const newMessage = {
            sent_by: trainerUID,
            message: messageText,
            timestamp: Date.now()
        }

        setIsLoadingSend(true);
        await sendChatMessage(chatId, newMessage);
        await messageSentPushNotification(clientID, "text", messageText)
        setIsLoadingSend(false);
        setMessageText('')


    }

    const handleFocus = () => {
        setFocused(true)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default behavior of Enter key in textarea
            handleSend();
        }
    };

    return (
        <div className="chat-container">
            {
                isLoading ? 
                <div className={ "chat-container-inner chat-container-inner-loading" }>
                    <div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>Loading messages...</p>
                </div>
                :
                <div className={ "chat-container-inner" }>
                    <div className="chat-header-container">
                        <div className={ "chat-header-icons-container" }>
                            <div className={ "chat-header-icon chat-header-icon-trainer" }>
                                {
                                    imgUrl_trainer === "" ?
                                    <h4>{ trainerInitials }</h4>
                                    :
                                    <img className="chat-header-icon-image" src={imgUrl_trainer}/>
                                }
                            </div>
                            <div className={ "chat-header-icon chat-header-icon-client" }>
                                {
                                    imgUrl_client === "" ?
                                    <h4>{ clientInitials }</h4>
                                    :
                                    <img className="chat-header-icon-image" src={imgUrl_client}/>
                                }
                            </div>
                        </div>
                        <div className={ "chat-header-top-container" }>
                            <h1>{client_first_name ? `Your conversation with ${client_first_name.split(" ")[0]}` : ``}</h1>
                        </div>
                        {/*<div className={ "chat-header-bottom-container" }>
                            <h2>{createdAt ? `Created ${dateTimeParser(createdAt)}` : ``}</h2>
                        </div>*/}
                        <div className={ "chat-header-scheduled-button" } onClick={ () => props.client && props.client.id ? props.openScheduleMessages(props.client.id) : null }>
                            <p>Schedule Messages</p>
                        </div>
                    </div>

                    <div className="chat-board-container">
                        <div className="chat-messages-container">
                        { messages.map (( message ) => (
                            <ClientChatMessage
                                key={message.messageID}
                                messageID={message.messageID}
                                chatID={chatId}
                                userUID={message.sent_by}
                                currentUserID={trainerUID}
                                sentByClient={message.sentByClient}
                                message={message.message}
                                timestamp={message.timestamp}
                                userName={message.userName}
                                read_by={message.read_by}
                                renderedThroughMessages={!!props.renderedThroughMessages}
                            />
                        ))}
                        </div>
                    </div>
                    <div className={ "chat-message-input-wrapper" }>
                        <ClientChatInput 
                            client={ props.client }
                            chatId={ chatId }/>
                    </div>

                    {/*<div className="chat-message-test">
                        <ReactMediaRecorder
                            video
                            render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
                            <div>
                                <p>{status}</p>
                                <button onClick={startRecording}>Start Recording</button>
                                <button onClick={stopRecording}>Stop Recording</button>
                                <video src={mediaBlobUrl} controls autoPlay loop />
                            </div>
                            )}
                        />
                    </div>*/}
                    
                    
                </div>
            }
        </div>
    )
}
export default ClientChat
