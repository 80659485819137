import React, { useState, useEffect } from 'react';
import './PerformanceAnalysisSummary.css';
import './PerformanceAnalysis.css';

import firebase from '../../../Firebase';

import ExecutiveSummaryCard from './components/Cards/ExecutiveSummaryCard';

// Import pages
import Overview from './pages/Overview';
import ExerciseBreakdown from './pages/ExerciseBreakdown';
import Progress from './pages/Progress';

const pageOptions = [
        {
            title: "Overview",
            id: 'overview'
        },
        {
            title: "Exercise Breakdown",
            id: 'set_breakdown'
        },
        // {
        //     title: "Performance",
        //     id: 'performance'
        // },
        {
            title: "Progress",
            id: 'progress'
        }
    ]

function PerformanceAnalysisSummary(props: any) {

    const [dayCount, setDayCout] = useState(28);
    const [selectedPageOption, setSelectedPageOption] = useState(pageOptions[0].id);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [clientID, setClientID] = useState("");
    const [initSet, setInitSet] = useState(false);

    const [logData, setLogData] = useState<any>();
    const [goalData, setGoalData] = useState<any>();

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            initialize();
        }
    }, [initSet])

    useEffect(() => {
        if (props.clientID) setClientID(props.clientID);
    }, [props.clientID])

    useEffect(() => {
        getLogData();
        getGoalData();
    }, [clientID])

    const initialize = () => {
        //getLogData();
        //getLogData();
    }

    const getGoalData = async () => {

        // 1. Get goal log ID
        let locationGoals = `goals/${clientID}/goals`;
        let goalsResp = await getDBDirect(locationGoals);
        let goalID = "";
        if (goalsResp) {
            let goalKeys = Object.keys(goalsResp);
            goalID = goalKeys[goalKeys.length - 1];
        }

        if (goalID === "") return;

        // 2. Get goal entries
        let locationGoalLogs = `goals/${clientID}/goal_logs/${goalID}/logs`;
        let goalLogsResp = await getDBDirect(locationGoalLogs);

        let goalObj = { goal: goalsResp[goalID], logs: goalLogsResp };
        // console.log("GOALS:", goalObj);

        setGoalData(goalObj);

    }

    const getLogData = async () => {

        if (!clientID || clientID === "") return;

        setIsLoadingData(true);

        // 1. Get log data
        var promiseList: any[] = [];
        let nowTS = Date.now();
        for (var i = 0; i < dayCount; i++) {
            let thisTs = nowTS - (24 * 3600 * 1000 * i);
            let pr = pullDataForDate(thisTs);
            promiseList.push(pr);
        }

        let results = await Promise.allSettled(promiseList);
        

        // 2. Get exercise data for each log
        // 2.1 Create an array of each exercise ID found in the logs
        
        var exerciseIDList: string[] = [];
        if (results) {
            for (var i = 0; i < results.length; i++) {
                let thisResult = results[i] as {status: string, value: any};
                if (thisResult && thisResult.value) {
                    let thisLogObj = thisResult.value;
                    if (thisLogObj.exercise_series) {
                        for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                            let thisSeries = thisLogObj.exercise_series[j];
                            if (thisSeries && thisSeries.exercises) {
                                for (var k = 0; k < thisSeries.exercises.length; k++) {
                                    let thisExercise = thisSeries.exercises[k];
                                    if (thisExercise && thisExercise.id && thisExercise.id !== "") {
                                        if (!exerciseIDList.includes(thisExercise.id)) exerciseIDList.push(thisExercise.id);
                                    } 
                                }
                            }
                        }
                    }
                }
            }
        }

        // console.log("Exercise IDs:", exerciseIDList)
        var promiseListExercises: any[] = [];
        for (var i = 0; i < exerciseIDList.length; i++) {
            let pr = pullExerciseData(exerciseIDList[i]);
            promiseListExercises.push(pr);
        }

        let exerciseResults = await Promise.allSettled(promiseListExercises);
        

        // 3. Add exercise objects to respective logs
        // 3.1 Create list of exercise objects
        var exerciseObjects: any = {};
        if (exerciseResults) {
            for (var i = 0; i < exerciseResults.length; i++) {
                let thisResult = exerciseResults[i] as {status: string, value: any};
                if (thisResult && thisResult.value) {
                    let thisExerciseObj: any = JSON.parse(JSON.stringify(thisResult.value));
                    exerciseObjects[thisExerciseObj.id ?? "unknown"] = thisExerciseObj;
                }
            }
        }

        

        // 3.2 Organize
        var workoutLogObjects: any[] = [];
        if (results) {
            for (var i = 0; i < results.length; i++) {
                let thisResult = results[i] as {status: string, value: any};
                if (thisResult && thisResult.value) {
                    let thisLogObj: any = JSON.parse(JSON.stringify(thisResult.value));
                    
                    if (thisLogObj.exercise_series) {
                        for (var j = 0; j < thisLogObj.exercise_series.length; j++) {
                            let thisSeries = thisLogObj.exercise_series[j];
                            if (thisSeries && thisSeries.exercises) {
                                for (var k = 0; k < thisSeries.exercises.length; k++) {
                                    let thisExercise = thisSeries.exercises[k];
                                    if (thisExercise && thisExercise.id && thisExercise.id !== "") {

                                        if (exerciseObjects[thisExercise.id]) {
                                            thisExercise["data"] = exerciseObjects[thisExercise.id]
                                        }
                                    } 
                                }
                            }
                        }
                    }
                    workoutLogObjects.push(thisLogObj);
                }
            }
        }

        // console.log(`----- Done in ${Date.now() - nowTS}mS -----`);
        // console.log(workoutLogObjects);
        setLogData(workoutLogObjects);
        setIsLoadingData(false);
    }

    const pullDataForDate = (ts: number) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let thisDate = new Date(ts);
                let d_d = thisDate.getDate();
                let d_m = thisDate.getMonth();
                let d_y = thisDate.getFullYear();
                let logID = await getDBDirect(`workout_plans/client_assigned_plans/${clientID}/schedule/${d_y}/${d_m}/${d_d}/log/log_id`);
                let logData = await getDBDirect(`workout_logs/${clientID}/${logID}`);
                resolve(logData);
            } catch (err) {
                resolve({ });
            }
        })
    }

    const pullExerciseData = (id: string) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let exerciseData = await getDBDirect(`exercises/${id}`);
                exerciseData = { ...exerciseData, id: id };
                resolve(exerciseData);
            } catch (err) {
                resolve({ });
            }
        })
    }

    const getDBDirect = async (location: string) => {
        return new Promise<any>(async (resolve, reject) => {
            if (location === "") reject();
            try {
                let database = firebase.database();
                let ref = database.ref(location);
                ref.once('value', function(snapshot) {
                    if (snapshot.exists() === true) {
                        resolve(snapshot.val());
                    } else {
                        reject();
                    }
                })
                .catch((err: any) => {
                    reject(err);
                })
            } catch (err) {
                reject(err);
            }
        })
        
    }

    const pageOptionSelected = (pageOption: any) => {
        if (pageOption.id) setSelectedPageOption(pageOption.id);
    }

	return (
		<div className={ "" }>
            <div className={"performance-analysis-data-content"} hidden={ isLoadingData }>
                <div className={ "performance-analysis-data-content-header" }>
                    <div className={ "performance-analysis-data-content-header-title-container" }>
                        <h4>4 Week Summary</h4>
                    </div>
                    <div className={ "performance-analysis-data-content-header-page-options-container" }>
                        {
                            pageOptions.map((item: any, index: number) => (
                                 <div onClick={ () => pageOptionSelected(item) } className={ `performance-analysis-data-content-header-page-option ${item.id === selectedPageOption ? "performance-analysis-data-content-header-page-option-selected" : ""}` }>
                                    <p>{ item.title }</p>
                                    <div className={ "performance-analysis-data-content-header-page-option-selected-bar" } hidden={ item.id !== selectedPageOption }/>
                                </div>
                            ))
                        }
                    </div>
                </div>     
                <div className={ "performance-analysis-data-content-body" }>
                    {
                        selectedPageOption === "overview" ?
                        <Overview 
                            logData={ logData }
                            goalData={ goalData }
                            clientName={ props.clientName }
                            openGoalsPressed={ () => pageOptionSelected(pageOptions[2]) }
                            openPerformancePressed={ () => pageOptionSelected(pageOptions[1]) }/>

                        : selectedPageOption === "set_breakdown" ? 
                        <ExerciseBreakdown
                            logData={ logData }
                            clientName={ props.clientName }/>
                        : selectedPageOption === "progress" ?
                        <Progress 
                            logData={ logData }
                            goalData={ goalData }
                            clientName={ props.clientName }/>
                        :
                        null
                    }
                    
                </div>   
            </div>
            <div className={"performance-analysis-data-content performance-analysis-data-content-loading"} hidden={ !isLoadingData }>
                <div className="client-list-container-loading-spinner"/>
                <p>Loading DynamicTrain Data...</p>
            </div>

		</div>
	)
}

export default PerformanceAnalysisSummary;