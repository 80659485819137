import React, { useState } from 'react';
import './Account.css';
import '../SettingsCommon.css';
import firebase from '../../../../Firebase';

import SettingsPageHeader from '../SettingsPageHeader';


const data: any = {
    "-MqIQwMicLvZNgSYFsHO": {    // Barbell hip thrusts
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    // "-MqGXNJJ-uJ9-GtJ7YQi": {    // BB Back squat
    //     "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
    //     "counting": {
    //         "auxiliary_monitor_locations": [ 4,5,8 ],
    //         "minimum_monitor_locations": [ 6,7 ],
    //         "type": "mirrored"
    //     },
    //     "lever": { "default_length": 0.35, "segment_id": "thigh" }
    // },
    "-NLcXpGQWsOeiq7UwPVU": {    // Bent over rows
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-M-gh7jw7YUrqALltV6E": {    // BW dips
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-NfDeVQh23tPnlbih4HS": {    // Box Jumps
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-M5JpNXHj9czYsyq1eg2": {    // Box Squats
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NLcXpGJLV3Ewmi9Wp90": {    // Box Squats
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "alternating"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NfDg8toGz0YIeaGvVOE": {    // Close Grip BB Benchpress
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-N7UALFIjm30eJYaOoPU": {    // DB Bulgarian Split Squat
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NLcXpGEaOlZxcDhSnxP": {    // Deadlift
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.75, "segment_id": "upper_body" }
    },
    "-NfDhVaUTSvXNadGO31k": {    // Diamond Pushup
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-Mpz-vNDHr6nMihJiRSu": {    // DB BenchPress
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-M2PhaZ3leDS9DvmcvT0": {    // DB bicep curls
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3,8 ],
            "minimum_monitor_locations": [ 0,1 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "forearm" }
    },
    "-M73UBhsQshBgVcA8gnZ": {    // DB lateral raises
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.55, "segment_id": "full_arm" }
    },
    "-NLcr2cPrhKGZiuFWS8m": {    // DB rows
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "alternating"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-NfDixfOdFo6MfU1wBOE": {    // Farmer's Walk
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "alternating"
        },
        "lever": { "default_length": 0.35, "segment_id": "calf" }
    },
    "-M5K4VePm_2JgX23Q9yi": {    // Glute Bridges
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.65, "segment_id": "upper_body" }
    },
    "-NY5KaHEta4cxm10Xs7n": {    // Goblet Squats
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NLcXpGl_OeY-1acv-CN": {    // Good Mornings
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 6,7,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.55, "segment_id": "upper_body" }
    },
    "-My4kNjIZXg-M2HgsFUU": {    // Hack Squat
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 0.5,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-M-g_JxYEkAlnxYbFoRW": {    // Incline Bench Press
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-Mt6_ySYklMzP3_SM723": {    // Leg Extensions
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 0.71,
        "counting": {
            "auxiliary_monitor_locations": [ 6,7,8 ],
            "minimum_monitor_locations": [ 4,5 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "calf" }
    },
    "-NLcXpGidZC5uup_MU5s": {    // Lundges
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "alternating"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NfDlYXtChvFmZzayCxv": {    // Lying Leg Curls
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 6,7,8 ],
            "minimum_monitor_locations": [ 4,5 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "calf" }
    },
    "-NfDm747DNxyqhWqjfdY": {    // Lying Reverse Flyes
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.55, "segment_id": "full_arm" }
    },
    "-MqNrJ_ZXKQpZCMOh8Fd": {    // Pistol Squat
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "alternating"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-M-gsazbgiVE-gJg0pXR": {    // Planks
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3 ],
            "minimum_monitor_locations": [ 8,6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 1, "segment_id": "full_body" }
    },
    "-M2PXn_rOAaXnw7zaiYO": {    // Pull ups
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-NY5KtBA7hj_0bxcRZaH": {    // RDLs
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.55, "segment_id": "upper_body" }
    },
    "-M2GaRIjwH98HJTAg5rg": {    // Rope facepulls
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-M-gpaHffKnTjsnL7HQG": {    // Russian twists
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 0.5,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "forearm" }
    },
    "-Mt6aWvvIiWO5LKOVY9k": {    // Seated calf raise
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1.4,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.15, "segment_id": "calf" }
    },
    "-NfDnU4N-rq3XHSBqjSz": {    // Seated leg curl
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 6,7,8 ],
            "minimum_monitor_locations": [ 4,5 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "calf" }
    },
    "-NfDo8zkrtSsenCjxGrd": {    // Sissy squats
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "calf" }
    },
    "-Mi-Ua79Bkha1lEpTIik": {    // Skull crushers
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3,8 ],
            "minimum_monitor_locations": [ 0,1 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.35, "segment_id": "forearm" }
    },
    "-N7n2WNZavoHaXfyU9ss": {    // Standing calf raise
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": false, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 6,7,8 ],
            "minimum_monitor_locations": [ 4,5 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.15, "segment_id": "calf" }
    },
    "-NfDp5XzP_wDgqM59DBH": {    // Step ups
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "alternating"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NfDqftZ4nyR-R9K8CSn": {    // Sumo deadlift w/ highpull
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 6,7,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.55, "segment_id": "upper_body" }
    },
    "-NfDqPCyPzbUaciRaQ6T": {    // Sumo deadlift 
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "mirrored"
        },
        "lever": { "default_length": 0.55, "segment_id": "upper_body" }
    },
    "-NLcXpGA1YxuaU0fnzYZ": {    // Upright Row
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 0,1,8 ],
            "minimum_monitor_locations": [ 2,3 ],
            "type": "single_side"
        },
        "lever": { "default_length": 0.35, "segment_id": "upper_arm" }
    },
    "-NfDrCIM6YnkJ8e94IVI": {    // Wall sit
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": true, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 4,5,8 ],
            "minimum_monitor_locations": [ 6,7 ],
            "type": "single_side"
        },
        "lever": { "default_length": 0.35, "segment_id": "thigh" }
    },
    "-NfDrkw63AF5IOGH-L7R": {    // Wrist curls
        "analysis_vector": { "x": 0,"y": 1,"z": 0 }, "analyze_vector": true, "body_weight_by_default": false, "leading_concentric": true, "weight_scaler": 1,
        "counting": {
            "auxiliary_monitor_locations": [ 2,3,8 ],
            "minimum_monitor_locations": [ 0,1 ],
            "type": "single_side"
        },
        "lever": { "default_length": 0.15, "segment_id": "forearm" }
    }
}

function Account(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    async function runDataThing() {
        // console.log("WILL BEGIN!");

        let keys = Object.keys(data);
        let database = firebase.database();

        for (var i = 0; i < keys.length; i++) {
            let thisKey = keys[i];
            if (thisKey && thisKey !== "") {
                let loc = `exercises/${thisKey}/motion_info`
                // console.log("PUSH TO:", loc, data[thisKey]);
                let ref = database.ref(loc);
                await ref.set(data[thisKey]);
            }
        }


    }

	return (
		<div className="settings-page-container">
			<SettingsPageHeader
                title="My Profile"
                subTitle="Setup your Trainer profile for your Clients to view"/>
            <div className="settings-page-section-container">
                <div onClick={() => runDataThing()} className="account-page-logout-button">
                    <p>Run Data Thing</p>
                </div>
                <div onClick={() => props.logoutPressed()} className="account-page-logout-button">
                    <p>Log Out</p>
                </div>
            </div>
		</div>
	)
}

export default Account;