import React, { useState, useEffect } from 'react';
import './NewWorkoutNodeCard.css';
import './NewWorkoutWorkoutCard.css';

import Workout_t from '../../../Interfaces/Workout_t';

import '@material-ui/core';
import BluetoothAudioRounded from '@material-ui/icons/BluetoothAudioRounded';
import Timer from '@material-ui/icons/Timer';
import Accessibility from '@material-ui/icons/Accessibility';

function NewWorkoutNodeCard(props: any) {

	let nodeOptions = [
	        {
	            name: "3 Nodes",
	            nodes: [0,1,8]
	        },
	        {
	            name: "5 Nodes",
	            nodes: [0,1,4,5,8]
	        },
	        {
	            name: "8 Nodes",
	            nodes: [0,1,2,3,4,5,6,7]
	        },
	        {
	            name: "9 Nodes",
	            nodes: [0,1,2,3,4,5,6,7,8]
	        }
	    ];
	let nodeLocationNames = ["Right Forearm", "Left Forearm", "Right Bicep", "Left Bicep", "Right Shin", "Left Shin", "Right Thigh", "Left Thigh", "Waistband"];

	const [initSet, setInitSet] = useState(false);
	const [numberOfNodes, setNumberOfNodes] = useState(0);
	const [estiamtedTime, setEstimatedTime] = useState(0);
	const [syncedSINs, setSyncedSINs] = useState<string[]>([]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
    	getNumberOfNodes();
        getTimeEstimate();
        getSINs();
    }

    useEffect(() => {
        initialize();
    }, [props.nodesAssigned]);

	function getImage() {
		
	}

	function getNumberOfNodes() {
		var num = 0;
		if (props.nodeManager !== undefined) {
			num = props.nodeManager.getNumberOfSyncedNodes();
		}

		setNumberOfNodes(num);
	}

	function getNodeImages() {

        var composed = [];

        let numberOfNodesIndex = -1;
        for (var i = 0; i < nodeOptions.length; i++) {
        	if (nodeOptions[i].nodes.length === numberOfNodes) {
        		numberOfNodesIndex = i;
        	}
        }

        if (numberOfNodesIndex === -1) { return; }

        for (var i = 0; i < 9; i++) {
            let includeNodes: number[] = nodeOptions[numberOfNodesIndex].nodes;
            for (var j = 0; j < includeNodes.length; j++) {
                let thisNodeIndex = includeNodes[j];
                let colour = "green";
                let imageTag = <img className="new-workout-node-body-image" src={`${process.env.PUBLIC_URL}/assets/images/body/nodes/${thisNodeIndex}/${colour}.png`}/>;
                composed.push(imageTag);
            }
        }

        return composed;
    }

    function getTimeEstimate() {
    	if (props.nodeManager !== undefined) {
    		let time = props.nodeManager.getEstimatedBatteryTime();
    		setEstimatedTime(time);
    	}
    }

    function getSINs() {
    	
    	if (props.nodeManager !== undefined) {
    		let SINs: string[] = props.nodeManager.getSyncedSINs();
    		setSyncedSINs(syncedSINs.concat(SINs));
    	}
    }

    function getLocationStrings() {

    	var locationStringList = '';

    	let numberOfNodesIndex = -1;
        for (var i = 0; i < nodeOptions.length; i++) {
        	if (nodeOptions[i].nodes.length === numberOfNodes) {
        		numberOfNodesIndex = i;
        	}
        }

        if (numberOfNodesIndex === -1) { return 'No Nodes Connected'; }

        for (var i = 0; i < nodeOptions[numberOfNodesIndex].nodes.length; i++) {
        	let thisNodeIndex = nodeOptions[numberOfNodesIndex].nodes[i];
        	let thisNodeString = nodeLocationNames[thisNodeIndex];
        	let st = i === 0 ? thisNodeString : `, ${thisNodeString}`;
        	locationStringList += st;
        }

        return locationStringList;

    }


	return (
		<div className="new-workout-client-card-container">
			<div className="new-workout-client-card-image-container">
				<div className="new-workout-node-image-container">
					<img className="new-workout-node-body-image" src={`${process.env.PUBLIC_URL}/assets/images/body/empty.png`}/>
					{ getNodeImages() }
				</div>
			</div>
			<div className="new-workout-client-name-container">
				<p>Connected Nodes</p>
				<h2>{numberOfNodes === 0 ? "No" : numberOfNodes} Nodes Connected</h2>
			</div>
			<div className="new-workout-client-info-container">
				<p>Estimated Battery life</p>
				<h2>{numberOfNodes === 0 ? 'No Nodes Connected' : `${estiamtedTime} mins`}</h2>
			</div>
			<div className="new-workout-client-info-container">
				<p>Connected Set</p>
				{syncedSINs.map((item, index) => (
					<h2 
						key={`tag-${index}`}
						className="new-workout-client-name-tag-content">SIN:{item}{index === syncedSINs.length - 1 ? '' : ', '}</h2>
				))}
			</div>
			<div className="new-workout-client-info-container">
				<p>Node Placement</p>
				<h2>{getLocationStrings()}</h2>
			</div>
			
			
		</div>
	)
}

/*
<div className="new-workout-workout-name-container">
				<h2>{numberOfNodes} Nodes Connected</h2>
				<div className="new-workout-workout-details-container">
					<div className="new-workout-workout-details-content">
						<Timer className="new-workout-client-name-tag-icon" style={{ fontSize: 14 }}/>
						<p>{estiamtedTime} mins of battery life</p>
					</div>
				</div>
				<div className="new-workout-client-name-tag-container">
					<BluetoothAudioRounded className="new-workout-client-info-tag-icon" style={{ fontSize: 14 }}/>
					{syncedSINs.map((item, index) => (
						<div 
							key={`tag-${index}`}
							className="new-workout-client-name-tag-content">SIN:{item}</div>
					))}
				</div>
			</div>
			<div className="new-workout-client-info-container">
				<p><Accessibility className="new-workout-client-info-tag-icon" style={{ fontSize: 14 }}/> Node placements on client</p>
				<div className="new-workout-workout-exercise-tag-container">
					{getLocationStrings()}
				</div>
			</div>
*/

export default NewWorkoutNodeCard;