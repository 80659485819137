import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import { IonInput } from '@ionic/react'; import './ExerciseBuilder.css';


import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import Muscles_t from '../../Interfaces/Muscles_t';
import CreatedBy_t from '../../Interfaces/CreatedBy_t';

import MuscleSelector from '../Base/MuscleSelector';
import MultiSelect from '../Base/MultiSelect';
import DualSelector from '../Base/DualSelector';
import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';
import FormDateInput from '../Base/FormDateInput';
import Selector from '../Base/Selector';


import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import Public from '@material-ui/icons/Public';
import Home from '@material-ui/icons/Home';
import Group from '@material-ui/icons/Group';
import Lock from '@material-ui/icons/Lock';
import Image from '@material-ui/icons/Image';
import { FileDrop } from 'react-file-drop';


function ExerciseBuilder(props: any) {

    let pageNames = ["Exercise Info", "Muscles", "Equipment", "Setup", "Availability"];
    let pageDescs = ["", "Define all of the muscles that your Exercise will target.", "", "", "", "", ""]
    const workoutDifficulties = [{ title: 'Beginner', desc: 'Easy for those new to lifting' }, { title: 'Light', desc: 'For those who have been to the gym' }, { title: 'Moderate', desc: 'Slightly intense for most' }, { title: 'Difficult', desc: 'Challenging to most gym goers' }, { title: 'Extremely Challenging', desc: 'Challenging, even to gym frequenters' }];
    const [uploadStatus, setUploadStatus] = useState('');

    let muscle_groups: string[] = ["Abs", "Arms", "Back", "Chest", "Shoulders", "Legs", "Butt"];

    let muscles: string[][] = [
        [ // Abs
            "Rectus Abdominis",
            "External Obliques",
            "Internal Obliques"
        ],
        [ // Arms
            "Biceps Short Head",
            "Biceps Long Head",
            "Triceps Long Head",
            "Triceps Lateral Head",
            "Triceps Medial Head",
            "Brachioradialis",
            "Flexor Carpi"
        ],
        [ // Back
            "Trapezius",
            "Rhomboid Major",
            "Latissimus Dorsi",
            "Teres Major",
            "Erector Spinae"
        ],
        [ // Chest
            "Pectoralis Minor", //Clavicular Head
            "Pectoralis Major" //Sternocosial Head
        ],
        [ // Shoulders
            "Deltoid Anterior Head",
            "Deltoid Posterior Head",
            "Deltoid Lateral Head"
        ],
        [ // Legs
            "Gastrocnemius Lateral Head",
            "Gastrocnemius Medial Head",
            "Soleus",
            "Tibialis Anterior",
            "Rectus Femoris",
            "Vastus Lateralis",
            "Vastus Medialis",
            "Adductor Longus",
            "Adductor Mangus",
            "Bicep Femoris",
            "Semitendinosus"
        ],
        [ // Butt
            "Gluteus Medius",
            "Gluteus Maximus"
        ]
    ]

    let equipment = {
        machines: [
            "None",
            "Single Cables",
            "Double Cables",
            "Weighted Ab Crunch",
            "Weighted Trunk Roatator",
            "Parallel Bars",
            "Incline Chest Press",
            "Chest Press",
            "Decline Chest Press",
            "Pec Fly",
            "Shoulder Press",
            "Lateral Shoulder Raise",
            "Rear Delt Extensor",
            "Shrug Machine",
            "Deadlift Machine",
            "Spinal Errector",
            "Cable Pulldown",
            "Pulldown",
            "Low Pull Back",
            "High Pull Back",
            "Straight Pull Cable",
            "Leg Press",
            "Quad Extensor",
            "Lying Hamstring Curls",
            "Seated Hamstring Curls",
            "Standing Calves Raises",
            "Seated Calves Raises",
            "Hip Abductor",
            "Hip Adductor",
            "Squat Rack",
            "Bench Rack",
            "Shoudler Rack",
            "Double Arm Curl",
            "Single Arm Curl",
            "Tricep Extensor",
            "Overhead Tricep Extensor",
            "Machine Dips",
            "Custom"
        ],
        benches: [
            "None",
            "Flat",
            "Large Incline",
            "Slight Incline",
            "Large Decline",
            "Slight Decline",
            "L-seat",
            "Bench Press",
            "Shoulder Press",
            "Custom"
        ],
        bars: [
            "None",
            "Full Bar",
            "Short Bar",
            "Curl Bar",
            "Swiss Bar",
            "Tricep Bar",
            "Fixed Weight Barbell",
            "Custom"
        ],
        cable_attachments: [
            "None",
            "Double Ropes",
            "Single Rope",
            "Flat Bar",
            "Curl Bar",
            "Double-D Handles",
            "Signle-D Handle",
            "Short Parallel Bar",
            "Pulldown Bar",
            "Side Grip Lat Bar",
            "Tricep V-Bar",
            "Head Harness",
            "Foot Harness",
            "Custom"
        ]
    }

    let defaultMotionInfo = {
      "analysis_vector": {"x": 0,"y": 1,"z": 0},
      "analyze_vector": true,
      "body_weight_by_default": false,
      "counting": {
        "auxiliary_monitor_locations": [0,1,8],
        "minimum_monitor_locations": [2,3],
        "type": "mirrored"
      },
      "leading_concentric": true,
      "lever": {
        "default_length": 0.35,
        "segment_id": "upper_arm"
      },
      "target": {
        "eccentric": {
          "max": 2.5,
          "min": 0.4
        },
        "power_normalized": {
          "max": 12,
          "min": 3
        },
        "rom": {
          "max": 100,
          "min": 35.5
        },
        "velocity": {
          "max": 3.5,
          "min": 0.7
        }
      },
      "weight_scaler": 1
    }

    let initExercise: Exercise_t = {
        title: '',
        id: '',
        type: 0,
        muscles: [],
        equipment: {
            machine: -1,
            bench: -1,
            bar: -1,
            cable_attachment: -1
        },
        setup: ""
    }

    const [initSet, setInitSet] = useState(false);

    const [exercise, setExercise] = useState<Exercise_t>(initExercise);
    const [finalExercise, setFinalExercise] = useState<Exercise_t>(initExercise);

    const [showingSearchResults, setShowingSearchResults] = useState(true);
    const [searchResults, setSearchResults] = useState<Exercise_t[]>([]);
    const [allExercises, setAllExercises] = useState<Exercise_t[]>([]);

    const [selectedAvailabilityState, setSlectedAvailabilityState] = useState(1);
    const [selectedMuscles, setSelectedMuscles] = useState<Muscles_t[]>([]);
    const [steps, setSteps] = useState<string[]>([]);
    const [currentStep, setCurrentStep] = useState<string>("");
    const [videoLink, setVideoLink] = useState<string>("");

    const [customBarName, setCustomBarName] = useState("");
    const [customBenchName, setCustomBenchName] = useState("");
    const [customAttachmentName, setCustomAttachmentName] = useState("");
    const [customMachineName, setCustomMachineName] = useState("");

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        let thisUser = firebase.auth().currentUser;

        let createdByName: string = thisUser !== null && thisUser.displayName !== null ? thisUser.displayName! : 'Eigen Fitness';
        let createdById: string = thisUser !== null && thisUser.uid !== null ? thisUser.uid : 'eigen_fit'
        let createdByObj: CreatedBy_t = {
            name: createdByName,
            id: createdById
        };
        setExercise({ ...exercise, createdBy: createdByObj });
        pullAllExercises();

        // Start mixpanel timing event
        Mixpanel.time_event("New exercise created");
    }

    useEffect(() => {
        if (props.onTheFlyExerciseAdding !== undefined && props.onTheFlyExerciseAdding === true && props.defaultTitleString !== undefined && props.defaultTitleString !== "") {
            setExercise({ ...exercise, title: props.defaultTitleString })
        }
    }, [props.defaultTitleString, props.onTheFlyExerciseAdding]);

    useEffect(() => {
        if (props.exerciseUpdated !== undefined) {
            props.exerciseUpdated(exercise);
        }
    }, [exercise])

    function backPressed() {
        if (props.backPressed !== undefined) {
            props.backPressed();
        }
    }

    function clearAll() {
        setExercise(initExercise);
        setFinalExercise(initExercise);
        setSteps(steps.filter(item => false));
        setVideoLink("");
        setSelectedMuscles(selectedMuscles.filter(item => false));
        setCurrentStep("");
        setCustomBarName("");
        setCustomBenchName("");
        setCustomMachineName("");
        setCustomAttachmentName("");
        setSlectedAvailabilityState(1);
    }

    function pullAllExercises() {
        let database = firebase.database();
        let ref = database.ref('exercises'); // Change to database path
        ref.on('value', function (snapshot) {
            if (snapshot.exists() === true) {
                //let data = snapshot.val();

                // Parse and use data here
                let responseData = snapshot.val();
                let responseExerciseArray: any = responseData;
                let responseKeys = Object.keys(responseData);

                var tempExerciseArray: Exercise_t[] = [];
                for (var i = 0; i < responseKeys.length; i++) {
                    let thisExerciseObj = responseExerciseArray[responseKeys[i]];
                    let thisExerciseMuscles: Muscles_t[] = [];

                    if (thisExerciseObj.muscles !== null && thisExerciseObj.muscles !== undefined) {

                        for (var j = 0; j < thisExerciseObj.muscles.length; j++) {
                            let thisMuscle = thisExerciseObj.muscles[j];
                            let newMuscle: Muscles_t = {
                                group: Number(thisMuscle.group),
                                muscle: Number(thisMuscle.muscle)
                            }
                            thisExerciseMuscles.push(newMuscle);
                        }
                    }

                    let newExercise: Exercise_t = {
                        title: thisExerciseObj.title,
                        id: responseKeys[i],
                        createdBy: { name: thisExerciseObj.created_by.name, id: thisExerciseObj.created_by.id },
                        muscles: thisExerciseMuscles,
                        type: thisExerciseObj.type,
                        equipment: thisExerciseObj.equipment,
                        tags: thisExerciseObj.tags,
                        setup: thisExerciseObj.setup,
                        steps: thisExerciseObj.steps,
                        videoLink: thisExerciseObj.video_link
                    }
                    tempExerciseArray.push(newExercise);
                }


                var tempExerciseArray_adj = [];
                for (var i = 0; i < tempExerciseArray.length; i++) {
                    let i_adj = tempExerciseArray.length - 1 - i;
                    tempExerciseArray_adj.push(tempExerciseArray[i_adj]);
                }
                // console.log("tempExerciseArray_adj");
                // console.log(tempExerciseArray_adj);
                setAllExercises(tempExerciseArray_adj);
            }
        });
    }

    function searchExercises(withString: string) {

        // console.log("searchExercises:", withString)

        var results: Exercise_t[] = [];

        for (var i = 0; i < allExercises.length; i++) {
            let thisObj: Exercise_t = allExercises[i];
            let thisObjTitle = thisObj.title.toUpperCase();
            let uppercaseInputString = withString.toUpperCase();
            let searchTextMatchesTitle = uppercaseInputString.includes(thisObjTitle); //thisObjTitle.includes(uppercaseInputString);

            if (searchTextMatchesTitle == true) {
                results.push(thisObj);
            }
        }
        setSearchResults(results);
    }

    function toggleShowingSearchResults() {
        setShowingSearchResults(!showingSearchResults);
    }

    function inputUpdated(e: any) {
        // console.log(e);
        if (e === "Enter") {
            addStepPressed();
        }
    }

    function inputChanged(toString: any, fromInput: string) {
        // console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)

        switch (fromInput) {
            case 'exercise-title':
                let letters = toString.split('');
                setExercise({ ...exercise, title: toString });
                if (letters.length > 5) {
                    searchExercises(toString);
                } else {
                    if (searchResults.length > 0) {
                        setSearchResults(searchResults.filter(item => false));
                    }
                }
                // if (alerts[0] === true && letters.length >= 5) {
                //     setAlerts(alerts.map((item, index) => { return index === 0 ? false : item }));
                // }
                break;
            case 'video-link':
                setVideoLink(toString);
                break;
            case 'search-tags':
                let tags: string[] = toString.split(',');
                setExercise({ ...exercise, tags: tags });
                //setAlerts(alerts.map((item, index) => { return index === 1 ? false : item }));
                break;
            case 'exercise-type':
                setExercise({ ...exercise, type: Number(toString) });
                break
            case 'exercise-setup':
                let letters_setup = toString.split('');
                // if (alerts[3] === true && letters_setup.length >= 4) {
                //     setAlerts(alerts.map((item, index) => { return index === 3 ? false : item }));
                // }
                setExercise({ ...exercise, setup: toString });
                break;
            case 'machine-selected':
                let equipment_temp_m = exercise.equipment!;
                let index_m = equipment.machines.indexOf(toString);
                equipment_temp_m.machine = index_m;
                setExercise({ ...exercise, equipment: equipment_temp_m });
                break;
            case 'bench-selected':
                let equipment_temp_be = exercise.equipment!;
                let index_be = equipment.benches.indexOf(toString);
                equipment_temp_be.bench = index_be;
                setExercise({ ...exercise, equipment: equipment_temp_be });
                break;
            case 'bar-selected':
                let equipment_temp_b = exercise.equipment!;
                let index_b = equipment.bars.indexOf(toString);
                equipment_temp_b.bar = index_b;
                setExercise({ ...exercise, equipment: equipment_temp_b });
                break;
            case 'attachment-selected':
                let equipment_temp_a = exercise.equipment!;
                let index_a = equipment.cable_attachments.indexOf(toString);
                equipment_temp_a.cable_attachment = index_a;
                setExercise({ ...exercise, equipment: equipment_temp_a });
                break;
            case 'custom-bar-name':
                setCustomBarName(toString);
                break;
            case 'custom-bench-name':
                setCustomBenchName(toString);
                break;
            case 'custom-attachment-name':
                setCustomAttachmentName(toString);
                break;
            case 'custom-machine-name':
                setCustomMachineName(toString);
                break;
            case 'exercise-setup':
                setExercise({ ...exercise, setup: toString });
                break;
            case 'exercise-step':
                setCurrentStep(toString);
                break;
            default:
                // console.log(`ERROR: ${fromInput} case has not been handled. See inputChanged() to fix.`);
                break;
        }
    }

    function getPageValidation() {

        let numMusclesPassed = selectedMuscles.length > 0;

        let letters_title = exercise.title.split('');
        let nameValid = letters_title.length >= 2;

        props.validationUpdated(numMusclesPassed && nameValid);
        return numMusclesPassed && nameValid;
    }

    function getShowTitleFeedback() {
        let titleLetters = exercise.title.split('');
        return getShowTitleFeedback.length >= 5;
    }

    function availabilitySelected(a: number) {
        setSlectedAvailabilityState(a);
    }

    function muscleListUpdated(muscles: Muscles_t[]) {
        setSelectedMuscles(selectedMuscles.filter(item => false));
        setSelectedMuscles(muscles);
    }

    function addStepPressed() {
        setSteps(steps.concat(currentStep));
        setCurrentStep("");
    }

    function removeStepPressed(atIndex: number) {
        setSteps(steps.filter((item: string, index: number) => index !== atIndex))
    }

    function goToLibrary() {
        props.goToLibrary();
    }

    function getMusclesString(forExercise: Exercise_t) {

        if (forExercise !== undefined && forExercise.muscles !== undefined && forExercise.muscles.length > 0) {
            var muscleString = "";
            for (var i = 0; i < forExercise.muscles.length; i++) {
                let thisMuscle: Muscles_t = forExercise.muscles[i];
                let thisMuscleString = muscles[thisMuscle.group][thisMuscle.muscle];

                muscleString += thisMuscleString;
                muscleString += i === forExercise.muscles.length - 1 ? '' : ', ';
            }

            return muscleString;
        } else {
            return 'No muscles defined'
        }
        return '';
    }

    function getEquipmentRequired(forExercise: Exercise_t) {
        if (forExercise.equipment !== undefined) {
            return forExercise.equipment.machine !== -1 || forExercise.equipment.cable_attachment !== -1 || forExercise.equipment.bar !== -1 || forExercise.equipment.bench !== -1;
        } else {
            return false;
        }
    }

    function getEquipmentString(forExercise: Exercise_t) {
        if (!getEquipmentRequired(forExercise)) {
            return 'No equipment specified for this exercise';
        }

        if (forExercise !== undefined && forExercise.equipment !== undefined) {
            var equipmentString = "";
            var numAdded = 0;

            if (forExercise.equipment.machine >= 0) {
                equipmentString += forExercise.equipment.machine + 1 < equipment.machines.length ? equipment.machines[forExercise.equipment.machine + 1] : "Custom machine";
                numAdded += 1;
            } else if (forExercise.equipment.machine === -2) {
                equipmentString += forExercise.equipment.custom_machine_name !== undefined ? forExercise.equipment.custom_machine_name : "Custom machine";
                numAdded += 1;
            }

            if (forExercise.equipment.bench >= 0) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.bench + 1 < equipment.benches.length ? equipment.benches[forExercise.equipment.bench + 1] : "Custom bench";
                numAdded += 1;
            } else if (forExercise.equipment.bench === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.custom_bench_name !== undefined ? forExercise.equipment.custom_bench_name : "Custom bench";
                numAdded += 1;
            }

            if (forExercise.equipment.cable_attachment >= 0) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.cable_attachment + 1 < equipment.cable_attachments.length ? equipment.cable_attachments[forExercise.equipment.cable_attachment + 1] : "Custom attachment";
                numAdded += 1;
            } else if (forExercise.equipment.cable_attachment === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.custom_cable_attachment_name !== undefined ? forExercise.equipment.custom_cable_attachment_name : "Custom attachment";
                numAdded += 1;
            }

            if (forExercise.equipment.bar >= 0) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.bar + 1 < equipment.bars.length ? equipment.bars[forExercise.equipment.bar + 1] : "Custom bar";
            } else if (forExercise.equipment.bar === -2) {
                equipmentString += numAdded > 0 ? ", " : "";
                equipmentString += forExercise.equipment.custom_bar_name !== undefined ? forExercise.equipment.custom_bar_name : "Custom bar";
            }

            return equipmentString;
        } else {
            return "No equipment specified for this exercise"
        }
    }

    const saveChanges = async () => {

        var tags_temp = [exercise.title];

        var muscleCount_legs = 0;
        var muscleCount_arms = 0;
        var muscleCount_upper = 0;

        //let muscle_groups: string[] = ["Abs", "Arms", "Back", "Chest", "Shoulders", "Legs", "Butt"];

        for (var i = 0; i < selectedMuscles.length; i++) {
            let thisMuscle: Muscles_t = selectedMuscles[i];
            let thisGroupName = thisMuscle.group < muscle_groups.length ? muscle_groups[thisMuscle.group] : "";
            if (tags_temp.indexOf(thisGroupName) < 0) {
                tags_temp.push(thisGroupName);
            }

            if (thisMuscle.group === 5 || thisMuscle.group === 6) {
                muscleCount_legs += 1;
            } else if (thisMuscle.group === 1) {
                muscleCount_arms += 1;
            } else if (thisMuscle.group === 0 || thisMuscle.group === 2 || thisMuscle.group === 3 || thisMuscle.group === 4) {
                muscleCount_upper += 1;
            } else {
                muscleCount_upper += 1;
            }
        }

        var equipmentObj: any = { bar: -1, bench: -1, cable_attachment: -1, machine: -1 };
        if (exercise.equipment !== undefined) {
            equipmentObj.bar = exercise.equipment!.bar === equipment.bars.length - 1 ? -2 : exercise.equipment!.bar;
            equipmentObj.bench = exercise.equipment!.bench === equipment.benches.length - 1 ? -2 : exercise.equipment!.bench;
            equipmentObj.cable_attachment = exercise.equipment!.cable_attachment === equipment.cable_attachments.length - 1 ? -2 : exercise.equipment!.cable_attachment;
            equipmentObj.machine = exercise.equipment!.machine === equipment.machines.length - 1 ? -2 : exercise.equipment!.machine;

            if (equipmentObj.bar === -2) {
                equipmentObj["custom_bar_name"] = customBarName;
            }
            if (equipmentObj.bench === -2) {
                equipmentObj["custom_bench_name"] = customBenchName;
            }
            if (equipmentObj.cable_attachment === -2) {
                equipmentObj["custom_cable_attachment_name"] = customAttachmentName;
            }
            if (equipmentObj.machine === -2) {
                equipmentObj["custom_machine_name"] = customMachineName;
            }
        }

        let thisUser = firebase.auth().currentUser;
        let createdByName: string = thisUser !== null && thisUser.displayName !== null ? thisUser.displayName! : 'Eigen Fitness';
        let createdById: string = thisUser !== null && thisUser.uid !== null ? thisUser.uid : 'eigen_fit'
        let createdByObj: CreatedBy_t = {
            name: createdByName,
            id: createdById
        };


        let isBWTemp = (exercise.title.toUpperCase()).includes("BODYWEIGHT");

        var minimum_monitor_locations_temp = [2,3];
        var auxiliary_monitor_locations_temp = [0,1,8];

        if (muscleCount_upper > muscleCount_legs && muscleCount_upper > muscleCount_arms) {
            minimum_monitor_locations_temp = [2,3];
            auxiliary_monitor_locations_temp = [0,1,8];
        } else if (muscleCount_legs > muscleCount_upper && muscleCount_legs > muscleCount_arms) {
            minimum_monitor_locations_temp = [6,7];
            auxiliary_monitor_locations_temp = [4,5,8];
        } else if (muscleCount_arms > muscleCount_upper && muscleCount_arms > muscleCount_legs) {
            minimum_monitor_locations_temp = [0,1];
            auxiliary_monitor_locations_temp = [2,3,8];
        } else {
            minimum_monitor_locations_temp = [2,3];
            auxiliary_monitor_locations_temp = [0,1,8];
        }

        let finalMotonInfo = JSON.parse(JSON.stringify(defaultMotionInfo))
        finalMotonInfo.counting.minimum_monitor_locations = minimum_monitor_locations_temp;
        finalMotonInfo.counting.auxiliary_monitor_locations = auxiliary_monitor_locations_temp;
        finalMotonInfo.body_weight_by_default = isBWTemp
        
        let exerciseJSON = {
            created_by: createdByObj,
            equipment: equipmentObj,//exercise.equipment,
            muscles: selectedMuscles,
            setup: exercise.setup === undefined ? "" : exercise.setup,
            steps: steps,
            title: exercise.title,
            type: exercise.type,
            tags: tags_temp,
            video_link: uploadedVideoUrl,
            // video_link: videoLink,
            availability: selectedAvailabilityState,
            approved: true,
            node_compatible: false,
            motion_info: finalMotonInfo
        };

        // console.log("exerciseJSON:", exerciseJSON);

        let db = firebase.database();
        let dbLogRef = db.ref(`exercises`);
        let pushRef = dbLogRef.push(exerciseJSON);

        let id = pushRef.key;

        let finalE: Exercise_t = {
            id: id === null ? '' : id,
            title: exercise.title,
            type: exercise.type,
            tags: tags_temp,
            videoLink: videoLink,
            availability: selectedAvailabilityState,
            createdBy: createdByObj,
            muscles: selectedMuscles,
            setup: exercise.setup === undefined ? "" : exercise.setup,
            steps: steps,
            equipment: equipmentObj
        }


        // Add this exercise to current user's custom exercise list
        let userExerciseListRef = db.ref(`trainer_exercises/${createdById}/${id === null ? 'abcd' : id}`);
        userExerciseListRef.set({
            id: id === null ? 'abcd' : id,
            title: exercise.title
        });

        if (videoFile !== null) {
            uploadImage(videoFile, id === null ? 'abcd' : id, finalE)
        } else {
            props.closeModal(finalE);
        }

        // Make available in ML Train
        let loc = `node_stream_data/exercises/${ id === null || id === undefined ? '' : id }/session_ids/_INIT_`;
        let bsData = {
            analysis_complete: true,
            logged_by: "CLCA6mbza8TNpJcdaWAnM2Ea5jS2",
            timestamp: Date.now()
        }

        let nodeStreamDataRef = db.ref(loc)
        nodeStreamDataRef.set(bsData)
        //setFinalExercise(finalE);
        

        // Track in Mixpanel
        let availabilityStrings = ["Public", "Gym-only", "Clients-only", "Private"];
        Mixpanel.track("New exercise created", {
            num_similar_titles: searchResults.length,
            num_muscles: muscles.length,
            num_steps: steps.length,
            muscle_group: selectedMuscles.length > 0 ? muscle_groups[selectedMuscles[0].group] : 'unknown',
            availability: selectedAvailabilityState < availabilityStrings.length ? availabilityStrings[selectedAvailabilityState] : 'unknown'
        });

        Mixpanel.people.increment("New exercises");

        setTimeout(() => {
            //setWorkflowState(6);

            clearAll();
        }, 200);

    }


    const [uploadedVideoUrl, setUploadedVideoUrl] = useState<string | null>(null);
    const [videoFile, setVideoFile] = useState<FileList | null>(null);
    const [isUploadingVideo, setIsUploadingVideo] = useState(false);
    // const [uploadStatus, setUploadStatus] = useState('');

    const handleFileDropped = (files: FileList | null, event: any) => {
        // console.log(">>handleFileDropped:", files ? files[0] : "NADA");
        setVideoFile(files ? files : null);

        // Upload to temp
        if (files === null) {
            return;
        }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        
        const file = files[0];
        setUploadStatus(`File ${files[0].name} uploaded successfully`);
        const storageRef = firebase.storage().ref(`videos/exercise/temp/${trainerUID ?? "abcd"}/video.mov`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                // Handle upload progress
                setIsUploadingVideo(true);
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log(`Upload progress: ${progress}%`);
            },
            (error) => {
                // Handle upload error
                setIsUploadingVideo(false);
                // console.log('Upload error: ', error);
            },
            async () => {
                // Handle successful upload
                // console.log('Upload completed');
                // Get the URL of the uploaded video from Firebase Storage
                setIsUploadingVideo(false);
                const uploadedVideoUrl = await storageRef.getDownloadURL();
                setUploadedVideoUrl(uploadedVideoUrl);
                // console.log('Here is the video link', uploadedVideoUrl);
            }
        );
    }

    const uploadImage = (files: FileList | null, id: string, finalE: Exercise_t) => {
        // console.log('uploadImage Called');

        if (files === null) {
            props.closeModal(finalE);
            return;
        }

        const file = files[0];
        setUploadStatus(`File ${files[0].name} uploaded successfully`);
        const storageRef = firebase.storage().ref(`videos/exercise/${id ?? "abcd"}/video.mov`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                // Handle upload progress
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log(`Upload progress: ${progress}%`);
            },
            (error) => {
                // Handle upload error
                props.closeModal(finalE);
                // console.log('Upload error: ', error);
            },
            async () => {
                // Handle successful upload
                // console.log('Upload completed');
                props.closeModal(finalE);
                // Get the URL of the uploaded video from Firebase Storage
                const uploadedVideoUrl = await storageRef.getDownloadURL();
                //setUploadedVideoUrl(uploadedVideoUrl);
                // console.log('hEre is the video link', uploadedVideoUrl);
            }
        );

    };


    const handleDelete = () => {
        // Here, you need to implement the logic to delete the uploaded video from Firebase Storage.
        if (uploadedVideoUrl) {
            const storageRef = firebase.storage().refFromURL(uploadedVideoUrl);
            storageRef
                .delete()
                .then(() => {
                    // console.log('File deleted successfully');
                    // Reset the state to remove the video display
                    setUploadedVideoUrl(null);
                    setUploadStatus('');
                })
                .catch((error) => {
                    // console.log('Error deleting file: ', error);
                });
        }
    };

    return (
        <div className="exercise-builder">

            <div className="new-workout-modal-header-button-container exercise-builder-floaty-button">
                <div onClick={() => getPageValidation() ? saveChanges() : null} className={`new-workout-modal-header-button-cta ${getPageValidation() ? '' : 'new-workout-modal-header-button-cta-unselected'}`}>
                    <p>Finish & create Exercise</p>
                </div>
            </div>
            <div className="exercise-builder-body">
                <div hidden={true} className="exercise-builder-header">
                    <div className="exercise-builder-header-back-container">
                        <div className="exercise-builder-header-back-button">
                            <ArrowLeft className="exercise-builder-header-back-button-icon" />
                        </div>
                    </div>
                    <div className="exercise-builder-header-text-container">
                        <h4>Create a new Exercise</h4>
                    </div>
                    <div>
                        <div onClick={() => getPageValidation() ? saveChanges() : null} className={`exercise-builder-header-button-cta ${getPageValidation() ? '' : 'exercise-builder-header-button-cta-unselected'}`}>
                            <p>Finish & create Exercise</p>
                        </div>
                    </div>
                </div>
                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-header">
                    <h2>General Exercise details</h2>
                </div>
                <div className="new-client-content-page-section-spacer" />
                <FormTextInput
                    value={exercise.title}
                    placeholder="Exercise Title"
                    alert={false}
                    autofocus={true}
                    noHide={true}
                    alertText="Please enter an exercise name"
                    inputChanged={(e: string) => inputChanged(e, 'exercise-title')} />
                <div className="new-exercise-modal-exercise-search-result-container">
                    <div hidden={searchResults.length > 0 || getShowTitleFeedback()} className="new-exercise-modal-exercise-search-result-container-results-empty">
                        <div className="new-exercise-modal-exercise-search-result-container-results-empty-icon-container">
                            <Check className="new-exercise-modal-exercise-search-result-container-results-empty-icon" />
                        </div>
                        <p>No exercises with matching titles were found</p>
                    </div>
                    <div hidden={searchResults.length === 0} className="new-exercise-modal-searching-exercises-cell-container">
                        <div hidden={searchResults.length === 0 || getShowTitleFeedback()} className="new-exercise-modal-exercise-search-result-container-results">
                            <div className="new-exercise-modal-exercise-search-result-container-results-results-icon-container">
                                <h4 className="new-exercise-modal-exercise-search-result-container-results-results-icon">!</h4>
                            </div>
                            <p>{searchResults.length} exercises with similar titles were found</p>
                            <div onClick={() => toggleShowingSearchResults()} className="new-exercise-modal-exercise-search-result-container-results-expand-results-container">
                                <p>{showingSearchResults ? 'Hide similar exercises' : 'Show similar exercises'}</p>
                                <div hidden={showingSearchResults} className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon-container">
                                    <ExpandMore className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon" />
                                </div>
                                <div hidden={!showingSearchResults} className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon-container">
                                    <ExpandLess className="new-exercise-modal-exercise-search-result-container-results-expand-results-icon" />
                                </div>
                            </div>
                        </div>
                        <div hidden={!showingSearchResults} className="new-exercise-modal-searching-exercises-cell-content-container">
                            {searchResults.map((item: Exercise_t, index: number) => (
                                <div className="new-exercise-modal-searching-exercises-cell">
                                    <div className="new-exercise-modal-searching-exercises-cell-col new-exercise-modal-searching-exercises-cell-col-large">
                                        <h4>{item.title}</h4>
                                        <p>{getMusclesString(item)}</p>
                                    </div>
                                    <div className="new-exercise-modal-searching-exercises-cell-col new-exercise-modal-searching-exercises-cell-col-large">
                                        <h4>{getEquipmentRequired(item) ? "Equipment required" : "No equipment"}</h4>
                                        <p>{getEquipmentString(item)}</p>
                                    </div>
                                    <div className="new-exercise-modal-searching-exercises-cell-col">
                                        <h4>Eigen Library</h4>
                                        <div className="exercise-cell-info-card-info-trained-with-me-container">
                                            <div className="exercise-cell-info-card-info-trained-with-me-icon" />
                                            <p>Created by Eigen Fitness</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div onClick={() => goToLibrary()} className="new-exercise-modal-searching-exercises-to-library-button">
                                <p>Go to Library</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-client-content-page-section-spacer" />
                <div className="new-client-content-page-section-spacer" />

                {!uploadedVideoUrl && <FormTextInput
                    value={videoLink}
                    placeholder="Youtube video link"
                    alert={false}
                    isOptional={true}
                    alertText=""
                    inputChanged={(e: string) => inputChanged(e, 'video-link')}
                />}

                <div className="my-coaching-profile-drop-area">
                    {
                        !uploadedVideoUrl ? 
                            (
                                <FileDrop
                                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                    onDragOver={(event) => console.log('onDragOver', event)}
                                    onDragLeave={(event) => console.log('onDragLeave', event)}
                                    onDrop={(files, event) => handleFileDropped(files, event)}>
                                    <div className="my-coaching-profile-drop-area-inner-container">
                                        <div className="my-coaching-profile-drop-area-icon-container">
                                            <Image className="my-coaching-profile-drop-area-icon" />
                                        </div>
                                        <h4>{isUploadingVideo ? "Uploading..." : "Drop a video here to upload"}</h4>
                                        <p>Files less than 10MB are recommended, in .mp4 .mov formats.</p>
                                    </div>
                                </FileDrop>
                            
                        ) : (
                            <div className="my-coaching-profile-drop-area-inner-container">
                                {/* Delete button */}
                                <button className="delete-button" onClick={handleDelete}>
                                    Delete
                                </button>
                                {/* Display the video here */}
                                <video controls>
                                    <source src={uploadedVideoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                {uploadStatus && <p>{uploadStatus}</p>}
                            </div>
                        )
                    }
                </div>


                <div className="new-client-content-page-subsection-header">
                    <h4>Select an Exercise type</h4>
                </div>
                <MultiSelect
                    options={[{ title: "Isotonic", desc: "Repetition based exercise" }, { title: "Isometric", desc: "Timer-based exercise (tempo >9s)" }, { title: "Cardio", desc: "Long duration timer-based exercise" }]}
                    optionChanged={(e: number) => inputChanged(e, 'exercise-type')} />
                <div className="">
                    <div className="new-client-content-page-section-spacer-5vh" />
                    <div className="new-client-content-page-section-spacer-5vh" />
                    <div className="new-client-content-page-section-spacer-5vh" />
                    <div className="new-client-content-page-section-header">
                        <h2>Select the Working Muscles</h2>
                        <p>Use the body below to tap on the muscles that are used in this exercise </p>
                    </div>
                    <div className="new-client-content-page-section-spacer" />
                    <div className="new-exercise-modal-muscle-selector-container">
                        <MuscleSelector
                            muscleList={selectedMuscles}
                            muscleListUpdated={(e: Muscles_t[]) => muscleListUpdated(e)} />
                    </div>
                    <div hidden={selectedMuscles.length === 0} className="new-exercise-modal-selected-muscles-container">
                        {
                            selectedMuscles.map((item: Muscles_t, index: number) => (
                                <div className="new-exercise-modal-selected-muscle-content-container">
                                    {muscles[item.group][item.muscle]}
                                </div>
                            ))
                        }
                    </div>
                    <div hidden={selectedMuscles.length !== 0} className="new-exercise-modal-selected-muscles-container">
                        <div className="new-exercise-modal-selected-muscle-content-container-unselected">
                            <p>No muscles selected</p>
                        </div>
                    </div>
                </div>

                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-header">
                    <h2>Setup & execution</h2>
                </div>
                <div className="new-exercise-modal-setup-input-container">
                    <FormLongTextInput
                        value={exercise.setup}
                        placeholder="Exercise Setup"
                        alert={false}
                        alertText="Please enter a brief exercise setup"
                        isSearch={true}
                        isOptional={true}
                        inputChanged={(e: string) => inputChanged(e, 'exercise-setup')} />
                </div>
                <div className="new-client-content-page-subsection-header">
                    <h4>Include steps and pointers</h4>
                </div>
                <div hidden={steps.length === 0} className="new-exercise-steps-container">
                    {
                        steps.map((item: string, index: number) => (
                            <div className="new-exercise-steps-content">
                                <div className="new-exercise-steps-content-number-container">
                                    <h4>{index + 1}</h4>
                                </div>
                                <div className="new-exercise-steps-content-text-container">
                                    <p>{item}</p>
                                </div>
                                <div onClick={() => removeStepPressed(index)} className="new-exercise-steps-content-remove-container">
                                    <Close className="new-exercise-steps-content-remove-icon" />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="new-exercise-add-step-container">
                    <div className="new-exercise-add-step-input-container">
                        <IonInput value={currentStep}
                            placeholder={steps.length > 0 ? "Enter Another Step / Pointer" : "Exercise Step / Pointer"}
                            inputmode="text"
                            clearOnEdit={true}
                            type="text"
                            onKeyPress={e => inputUpdated(e.key)}
                            onIonChange={e => inputChanged(e.detail.value!, 'exercise-step')} />
                    </div>
                    <div hidden={currentStep === ""} onClick={() => addStepPressed()} className="new-exercise-add-step-input-button">
                        <p>Add Pointer</p>
                    </div>
                    <div hidden={currentStep !== ""} className="new-exercise-add-step-input-button new-exercise-add-step-input-button-unselected">
                        <p>Add Pointer</p>
                    </div>
                </div>

                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-header">
                    <h2>Required equipment</h2>
                </div>
                <div className="new-exercise-modal-select-container">
                    <h5>Machine</h5>
                    <Selector
                        placeholder="Machine"
                        forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.machine}
                        showTitle={false}
                        options={equipment.machines}
                        inputChanged={(e: number) => inputChanged(e, 'machine-selected')} />
                    <div hidden={exercise.equipment === undefined || exercise.equipment.machine !== equipment.machines.length - 1} className="new-exercise-modal-select-custom-input-container">
                        <div className="new-exercise-modal-select-custom-input-container-inner" >
                            <FormTextInput
                                value={customMachineName}
                                placeholder="Custom machine name"
                                alert={false}
                                noHide={true}
                                alertText="Please enter a machine name"
                                inputChanged={(e: string) => inputChanged(e, 'custom-machine-name')} />
                        </div>
                    </div>
                </div>
                <div className="new-exercise-modal-select-container">
                    <h5>Bench</h5>
                    <Selector
                        placeholder="Bench"
                        forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.bench}
                        showTitle={false}
                        options={equipment.benches}
                        inputChanged={(e: number) => inputChanged(e, 'bench-selected')} />
                    <div hidden={exercise.equipment === undefined || exercise.equipment.bench !== equipment.benches.length - 1} className="new-exercise-modal-select-custom-input-container">
                        <div className="new-exercise-modal-select-custom-input-container-inner">
                            <FormTextInput
                                value={customBenchName}
                                placeholder="Custom bench name"
                                alert={false}
                                noHide={true}
                                alertText="Please enter a bench name"
                                inputChanged={(e: string) => inputChanged(e, 'custom-bench-name')} />
                        </div>
                    </div>
                </div>
                <div className="new-exercise-modal-select-container">
                    <h5>Bar</h5>
                    <Selector
                        placeholder="Bar"
                        forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.bar}
                        showTitle={false}
                        options={equipment.bars}
                        inputChanged={(e: number) => inputChanged(e, 'bar-selected')} />
                    <div hidden={exercise.equipment === undefined || exercise.equipment.bar !== equipment.bars.length - 1} className="new-exercise-modal-select-custom-input-container">
                        <div className="new-exercise-modal-select-custom-input-container-inner">
                            <FormTextInput
                                value={customBarName}
                                placeholder="Custom bar name"
                                alert={false}
                                noHide={true}
                                alertText="Please enter a bar name"
                                inputChanged={(e: string) => inputChanged(e, 'custom-bar-name')} />
                        </div>
                    </div>
                </div>
                <div className="new-exercise-modal-select-container">
                    <h5>Cable Attachment</h5>
                    <Selector
                        placeholder="Cable Attachment"
                        forceSelected={exercise.equipment === undefined ? 0 : exercise.equipment!.cable_attachment}
                        showTitle={false}
                        options={equipment.cable_attachments}
                        inputChanged={(e: number) => inputChanged(e, 'attachment-selected')} />
                    <div hidden={exercise.equipment === undefined || exercise.equipment.cable_attachment !== equipment.cable_attachments.length - 1} className="new-exercise-modal-select-custom-input-container">
                        <div className="new-exercise-modal-select-custom-input-container-inner">
                            <FormTextInput
                                value={customAttachmentName}
                                placeholder="Custom attachment name"
                                alert={false}
                                noHide={true}
                                alertText="Please enter an attchment name"
                                inputChanged={(e: string) => inputChanged(e, 'custom-attachment-name')} />
                        </div>
                    </div>
                </div>


                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-header">
                    <h2>Access permisions</h2>
                </div>
                <div className="new-workout-modal-availability-row">
                    <div onClick={() => availabilitySelected(0)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 0 ? "new-workout-modal-availability-cell-selected" : "")}>
                        <div className="new-workout-modal-availability-cell-header">
                            <Public className="new-workout-modal-availability-cell-header-icon" />
                            <h3>Public</h3>
                        </div>
                        <p>This Exercise will be available for download by all EigenFit users. Recommended to help boost your downloads and Trainer Rating.</p>
                    </div>
                    <div onClick={() => availabilitySelected(1)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 1 ? "new-workout-modal-availability-cell-selected" : "")}>
                        <div className="new-workout-modal-availability-cell-floater">Recommended</div>
                        <div className="new-workout-modal-availability-cell-header">
                            <Home className="new-workout-modal-availability-cell-header-icon" />
                            <h3>Gym only</h3>
                        </div>
                        <p>This Exercise will be available for download and use by members of my gym. Perfect for sharing your tried & true Exercises with trainers you know.</p>
                    </div>
                </div>
                <div className="new-workout-modal-availability-row">
                    <div onClick={() => availabilitySelected(2)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 2 ? "new-workout-modal-availability-cell-selected" : "")}>
                        <div className="new-workout-modal-availability-cell-header">
                            <Group className="new-workout-modal-availability-cell-header-icon" />
                            <h3>Clients only</h3>
                        </div>
                        <p>Only my clients will have access to use and download this Exercise. Great for keeping your Exercises personal and solidifying your training style.</p>
                    </div>
                    <div onClick={() => availabilitySelected(3)} className={"new-workout-modal-availability-cell " + (selectedAvailabilityState === 3 ? "new-workout-modal-availability-cell-selected" : "")}>
                        <div className="new-workout-modal-availability-cell-header">
                            <Lock className="new-workout-modal-availability-cell-header-icon" />
                            <h3>Private</h3>
                        </div>
                        <p>This Exercise is only available during one-on-one sessions with my clients. Best for Exercises that require a close eye and lots of communication.</p>
                    </div>
                </div>

                <div className="new-client-content-page-section-spacer-5vh" />
                <div className="new-client-content-page-section-spacer-5vh" />
            </div>
        </div>
    )
}

export default ExerciseBuilder;