import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';

import PageHeader_t from '../../Interfaces/PageHeader_t';
import Client_t from '../../Interfaces/Client_t';
import Plan_t from '../../Interfaces/Plan_t';
import Folder_t from '../../Interfaces/Folder_t';
import Workout_t from '../../Interfaces/Workout_t';

import PageHeader from '../../components/Base/PageHeader';

import ClientProfileGeneral from './ClientProfileGeneral'
import ClientPrograms from './ClientPrograms';
import ClientProfileSchedule from './ClientProfileSchedule';
import ClientProfileMeasurements from './ClientProfileMeasurements';
import ClientProfileGoals from './ClientProfileGoals'
import ClientProfileHistory from './ClientProfileHistory'
import ClientProfileStats from './ClientProfileStats'
import ClientProfileNotes from './ClientProfileNotes'
import ClientProfileConversations from './ClientProfileConversations'

import '@material-ui/core';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Mail from '@material-ui/icons/Mail';
import Chat from '@material-ui/icons/Chat';
import Phone from '@material-ui/icons/Phone';
import Edit from '@material-ui/icons/Edit';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import './ClientProfileMain.css';
import '../Dashboard/Clients.css';
import '../Dashboard/DashboardPageCommon.css';
import ClientChat from "./Chat/ClientChat";

function ClientProfileMain(props: any) {

	const clientTypeOptions: string[] = ["In-person training", "Online training", "In-person & online training"];

	let pageOptions: PageHeader_t[] = [{ name:"Overview", alert:false },
		                               { name: "Chat", alert: false},
									   { name:"Schedule", alert:false },
									   { name:"Programs", alert:false },
									   { name:"Goals", alert:false },
									   // { name:"Measurements", alert:false },
									   // { name:"Statistics", alert:false },
					   				   ]
//{ name:"Notes", alert:false }
	let initClient: Client_t = {first_name:'',
								last_name:'',
								full_name:'',
								id:'',
								isMetric: true,
								gender: 'b',
								birthdate: '00-00-0000',
								height: 0,
								weight: 0,
								goalIndex: 0,
								experienceIndex: 0,
								email: ''};



	let history = useHistory();

	const [pageNumber, setPageNumber] = useState(0);
	const [imgUrl, setImgUrl] = useState('');
	const [client, setClient] = useState<Client_t>(initClient);
	const [currentPlan, setCurrentPlan] = useState<Plan_t>({title: "", id: "", createdBy_ID: "", created_timestamp: 0, workout_ids: []});
	const [allPlanObjs, setAllPlanObjs] = useState<any[]>([]);
	const [currentPlanPathNames, setCurrentPlanPathNames] = useState("");

	const [planCompletion, setPlanCompletion] = useState(0);
	const [daysRemainingInPlan, setDaysRemainingInPlan] = useState(0);

	const [clientType, setClientType] = useState(clientTypeOptions[0]);
	const [clientEnabled, setClientEnabled] = useState(true);
	const [showClientTypeList, setShowClientTypeList] = useState(false);
	const [showClientStatusList, setShowClientStatusList] = useState(false);

	function pageSelected(newPage: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setPageNumber(i);
			}
		}
	}

	useEffect(() => {
		setClient(props.client);
		getClientType();
		getClientStatus();
		getImage();
		getPlan();
	}, [props.client])

	function getImage() {
		if (props.client === undefined) { return; }
		const storage = firebase.storage();
		//// console.log(props.client.id);
		storage.ref('profileImages').child(props.client.id).child('profileImage/image.jpg').getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}

	function getClientType() {
		if (props.client === undefined) { return; }
		if (props.client.type !== undefined) {
			setClientType(props.client.type);
			return;
		}

		let clientID = props.client.id;
		let database = firebase.database();
        let ref = database.ref(`user_profile/${clientID}/type`);
        ref.on('value', function(snapshot) {
        	if (snapshot.exists()) {
        		let typeTemp = snapshot.val();
        		setClientType(typeTemp);
        	} else {
        		ref.set(clientTypeOptions[0]);
        	}
        });
	}

	function getClientStatus() {
		if (props.client === undefined) { return; }

		let clientID = props.client.id;
		let database = firebase.database();
        let ref = database.ref(`user_profile/${clientID}/enabled`);
        ref.on('value', function(snapshot) {
        	if (snapshot.exists()) {
        		let enabledTemp = snapshot.val();
        		setClientEnabled(enabledTemp);
        	} else {
        		ref.set(true);
        	}
        });
	}

	function updateClientType(toType: string) {
		setShowClientTypeList(false);
		if (props.client === undefined || toType === "") { return; }

		let clientID = props.client.id;
		let database = firebase.database();
        let ref = database.ref(`user_profile/${clientID}/type`);
        ref.set(toType);
	}

	function updateClientStatus(toStatus: boolean) {
		setClientEnabled(toStatus);
		setShowClientStatusList(false);
		if (props.client === undefined) { return; }

		let clientID = props.client.id;
		let database = firebase.database();
        let ref = database.ref(`user_profile/${clientID}/enabled`);
        ref.set(toStatus);
	}

	function getPlan() {
		if (props.client === undefined) { return; }
		let clientID = props.client.id;

		// console.log("Client Profile Main | getPlan", clientID);

		let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/plans`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
            	let planList = snapshot.val();
            	let planListkeys = Object.keys(planList);
            	//// console.log("planList:", planList)
            	if (planListkeys.length > 0) {
            		var planObjs_temp: string[] = [];

            		for (var i = 0; i < planListkeys.length; i++) {


            			let thisPlanObj = planList[planListkeys[i]];
            			//// console.log(i, "thisPlanObj:", thisPlanObj)
            			let thisPlanID = thisPlanObj.template_plan_id;
            			planObjs_temp.push(thisPlanObj);

            			if (i === planList.length - 1) {
            				// Current plan
            				let startTime = thisPlanObj.starting_timestamp;
            				let endTime = thisPlanObj.ending_timestamp;
            				let currentTime = Date.now();
            				let endTime_adj = endTime - startTime;
            				let currentTime_adj = currentTime - startTime;
            				let dt = endTime_adj - currentTime_adj;
            				let percentComplete = Math.floor(100 * currentTime_adj / endTime_adj);

            				let numDaysRemaining = Math.ceil(dt / 1000 / 60 / 60 / 24);
            				setPlanCompletion(percentComplete);
            				getPlanDetails(thisPlanID);
            				setDaysRemainingInPlan(numDaysRemaining);
            			}
            		}

            		// // reverse direction of plan IDs
            		var planIDs_temp_rev = [];
            		for (var i = planObjs_temp.length - 1; i >= 0; i--) {
            			planIDs_temp_rev.push(planObjs_temp[i]);
            		}

            		setAllPlanObjs(planIDs_temp_rev);
            	}
            }
        });
	}

	function getPlanDetails(forID: string) {

		let database = firebase.database();
        let ref = database.ref(`workout_plans/plans/${forID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
            	let planData = snapshot.val();

            	let newPlan: Plan_t = {
            		title: planData.title !== null ? planData.title : "",
            		description: planData.description !== null ? planData.description : "",
            		path: planData.location_path !== null ? planData.location_path : "",
            		id: forID,
            		createdBy_ID: planData.create_by !== null ? planData.create_by : "",
            		created_timestamp: planData.created_timestamp !== null ? planData.created_timestamp : 0,
            		workout_ids: planData.workouts !== null ? planData.workouts : [],
            		availability: planData.availability !== null ? planData.availability : 0
            	};

            	setCurrentPlan(newPlan);
            	if (newPlan.path !== undefined) {
            		getPathNames(newPlan.path);
            	}
            }
        });
	}

	function getPathNames(pathIDs: string) {
		let currentUser = firebase.auth().currentUser;
		if (!currentUser) { return; }
		let trainerUID = currentUser.uid;

		let splitPath = pathIDs.split('/');
		let firstFolderID = splitPath[1];

		let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}/folders_root/${firstFolderID}`);
        ref.once('value', function(snapshot) {
        	if (snapshot.exists()) {
        		let foldersObj = snapshot.val();
        		var pathNames = foldersObj.name + " / ";

        		var parentObj = foldersObj;
        		for (var i = 2; i < splitPath.length; i++) {
        			let thisChildID = splitPath[i];
        			let parentsChildObj = parentObj.children[thisChildID];
        			pathNames += parentsChildObj.name + " / ";
        			parentObj = parentsChildObj;
        		}

        		setCurrentPlanPathNames(pathNames);
        	}
        });
	}

	function getDownloadBarWidth() {
        return {width: `${planCompletion < 3 ? 3 : (planCompletion > 100 ? 100 : planCompletion)}%`};
    }

	function backButtonPressed() {
		history.push('/dashboard/clients');
	}

	function startNewWorkout() {
		props.startWorkoutWithClient(props.client);
	}

	function goalsPressed() {
		setPageNumber(1);
	}

	function chatPressed() {
		setPageNumber(1);
	}

	function emailPressed() {
		window.location.href = `mailto: ${client.email}`
	}

	function phonePressed() {
		return;
		window.location.href = `tel: `
	}

	function addGoalPressed() {
        if (props.addGoalPressed !== undefined) {
            props.addGoalPressed();
        }
    }

    function logMeasurementPressed() {
    	if (props.logMeasurementPressed !== undefined) {
    		props.logMeasurementPressed();
    	}
    }

    function updateProfileImagePressed() {
    	if (client === undefined || client.id === undefined || client.id === "") { return; }
    	let uploadImageObj = {
    		path: `/profileImages/${client.id}/profileImage/image.jpg`,
    		fileTypes: ['image/png','image/jpg','image/gif'],
    		completedCallback: getImage
    	}
    	props.updateProfileImage(uploadImageObj);
    }

    function toggleShowClientTypeList() {
    	setShowClientTypeList(!showClientTypeList);
    }

	return (
		<div className="page-container">
			<div className="client-header">
				<PageHeader
					pageName="Athlete Profile"
					pageOptions={pageOptions}
					selectedPageIndex={pageNumber}
					pageSelected={(e: string) => pageSelected(e)}
					showBack={true}
					backMessage="Back to all Athletes"
					backButtonPressed={() => backButtonPressed()}
					isPartialMenu={false}
					workoutButtonPressed={() => props.openClientInDynamicTrain !== undefined ? props.openClientInDynamicTrain(client && client.id ? client.id : "") : null}
					addNewWorkout={() => props.createNewWorkout !== undefined ? props.createNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewClient={() => props.createNewClient !== undefined ? props.createNewClient() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
			</div>
			<div hidden={pageNumber === 2 || pageNumber === 3} className="client-profile-content-container">
				<div className="client-profile-summary-container">
					<div onClick={() => updateProfileImagePressed()} className="client-profile-summary-profile-image-container">
						<img
							className="client-profile-image"
							hidden={imgUrl === ''}
							src={imgUrl}
							alt={`Image for ${props.client === undefined ? "" : props.client.first_name}`} />
						<div hidden={imgUrl === ''} className="client-profile-summary-profile-image-edit">
							<div className="client-profile-summary-profile-image-edit-circle">
								<Edit className="client-profile-summary-profile-image-edit-icon"/>
							</div>
							<p>Edit Profile Image</p>
						</div>
						<div hidden={imgUrl !== ''} className="client-profile-summary-profile-image-edit-no-hide">
							<div className="client-profile-summary-profile-image-edit-circle">
								<Edit className="client-profile-summary-profile-image-edit-icon"/>
							</div>
							<p>Edit Profile Image</p>
						</div>
					</div>
					<div className="client-profile-summary-header">
						<h3>{client.first_name}</h3>
						<h3>{client.last_name}</h3>
						<p>{client.email}</p>
					</div>
					<div className="client-profile-summary-client-type-container">
						<div onClick={() => toggleShowClientTypeList()} className="client-profile-summary-client-type-content">
							<p>{clientType}</p>
							<div hidden={showClientTypeList} className="client-profile-summary-client-type-content-icon-container">
								<KeyboardArrowDown className="client-profile-summary-client-type-content-icon"/>
							</div>
							<div hidden={!showClientTypeList} className="client-profile-summary-client-type-content-icon-container">
								<KeyboardArrowUp className="client-profile-summary-client-type-content-icon"/>
							</div>
						</div>
						<CSSTransition in={showClientTypeList} timeout={200} classNames="menu" unmountOnExit>
							<div className="client-profile-summary-client-type-menu">
								<div className="client-profile-summary-client-type-menu-header">
									<h4>Select a training type</h4>
								</div>
								<div className="client-profile-summary-client-type-menu-content">
									{
										clientTypeOptions.map((item: string, index: number) => (
											<div onClick={() => updateClientType(item)} className={`client-profile-summary-client-type-menu-row ${index === clientTypeOptions.length - 1 ? 'client-profile-summary-client-type-menu-row-no-border' : ''}`}>
												<p>{item}</p>
											</div>
										))
									}
								</div>
							</div>
						</CSSTransition>
					</div>
					
					<div className="client-profile-summary-contact-container">
						<div onClick={() => chatPressed()} className="client-profile-summary-contact-icon-container client-profile-summary-contact-icon-container-chat">
							<Chat className="client-profile-summary-contact-icon client-profile-summary-contact-icon-chat"/>
						</div>
						<div onClick={() => emailPressed()} className="client-profile-summary-contact-icon-container client-profile-summary-contact-icon-container-email">
							<Mail className="client-profile-summary-contact-icon client-profile-summary-contact-icon-email"/>
						</div>
						<div onClick={() => phonePressed()} className="client-profile-summary-contact-icon-container client-profile-summary-contact-icon-container-phone-unavailable">
							<Phone className="client-profile-summary-contact-icon client-profile-summary-contact-icon-phone-unavailable"/>
						</div>
					</div>
					<div className="client-profile-summary-client-type-container">
						<div onClick={() => updateClientStatus(!clientEnabled)} className="client-profile-summary-client-type-content">
							<p>{ clientEnabled ? "Client Enabled" : "Client Disabled" }</p>
						</div>
					</div>
					<div hidden={true} className="client-profile-summary-plan-container">
						<div className="client-profile-summary-plan-inner-container">
							<div className="client-profile-summary-plan-header-container">
								<h4>Currently assigned plan</h4>
							</div>
							<div hidden={currentPlan.id === ""} className="client-profile-summary-plan-card-container">
								<div className="client-profile-summary-plan-card-header-container">
									<p>{currentPlanPathNames}</p>
									<h4>{currentPlan.title}</h4>
								</div>
								<div className="client-profile-summary-plan-card-completion-container">
									<div className="client-profile-summary-plan-card-completion-loading-bar-container">
					                    <div className="client-profile-summary-plan-card-completion-loading-bar-outer">
					                        <div className="client-profile-summary-plan-card-completion-loading-bar-inner" style={getDownloadBarWidth()}/>
					                    </div>
					                </div>
					                <div className="client-profile-summary-plan-card-completion-loading-text-container">
										<p>{daysRemainingInPlan} days remaining</p>
									</div>
								</div>
							</div>
							<div hidden={currentPlan.id !== ""} className="client-profile-summary-plan-card-container client-profile-summary-plan-card-container-no-plan">
								<p>No plan assigned</p>
							</div>
						</div>
					</div>
				</div>
				<div className="client-profile-outer-page-container">
					<div hidden={pageNumber != 0} className="client-profile-inner-page-container">
						<ClientProfileGeneral
							client={props.client}
							newGoalPressed={() => props.addGoalPressed()}
							allPlanObjs={allPlanObjs}
							goalsPressed={() => goalsPressed()}
							logMeasurementPressed={() => logMeasurementPressed()}/>
					</div>
					<div hidden={pageNumber != 1} className="client-profile-inner-page-container">
						<ClientChat
							client={props.client}/>
					</div>
					<div hidden={pageNumber != 4} className="client-profile-inner-page-container">
						<ClientProfileGoals
							client={props.client}
							newGoalPressed={() => props.addGoalPressed()}
							getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
							editGoal={(e: any) => props.editGoal(e)}
							logMeasurementPressed={() => logMeasurementPressed()}/>
					</div>
					<div hidden={pageNumber != 5} className="client-profile-inner-page-container">
						<ClientProfileMeasurements
							client={props.client}
							logMeasurementPressed={() => logMeasurementPressed()}/>
					</div>
					<div hidden={pageNumber != 6} className="client-profile-inner-page-container">
						<ClientProfileStats
							client={props.client}/>
					</div>
					<div hidden={pageNumber != 400} className="client-profile-inner-page-container">
						<ClientProfileHistory
							client={props.client}/>
					</div>

					<div hidden={pageNumber != 7} className="client-profile-inner-page-container">
						<ClientProfileNotes
							client={props.client}
							addNotePressed={() => props.addNotePressed()}/>
					</div>
					<div hidden={true} className="client-profile-inner-page-container">
						<ClientProfileConversations
							client={props.client}/>
					</div>
				</div>
			</div>
			<div hidden={pageNumber !== 2} className="client-profile-content-container">
				<ClientProfileSchedule
					client={props.client}
					addElementPressed={(e: any) => props.addElementPressed(e)}
					newPlanPressed={(folder: Folder_t, client_id: string) => props.newPlanPressed(folder, client_id)}
					copyFolderPressed={(e: any) => props.copyFolderPressed(e)}
					createNewWorkout={(e: any) => props.createNewWorkout(e)}
					createPhasePressedWithWorkouts={(w: Workout_t[]) => props.createPhasePressedWithWorkouts(w)}
                    addNewAppointmentPressed={(e: any) => props.addNewAppointmentPressed(e)}
                    editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => props.editWorkoutForDay(workout, calendarDay, client)}/>
			</div>
			<div hidden={pageNumber !== 3} className="client-profile-content-container">
				<ClientPrograms
					client={props.client}
					addElementPressed={(e: any) => props.addElementPressed(e)}
					getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
					assignClientToPlan={(plan: Plan_t, client: Client_t) => props.assignClientToPlan(plan, client)}
					assignClientToFolder={(folder: Folder_t, client: Client_t) => props.assignClientToFolder(folder, client)}
					newPlanPressed={(folder: Folder_t, client_id: string) => props.newPlanPressed(folder, client_id)}
					editRestDayPressed={(p: Plan_t, i: number, client: Client_t) => props.editRestDayPressed(p, i, client)}
                    editPlanPressed={(p: Plan_t, client_id: string) => props.editPlanPressed(p, client_id)}
					addNewWorkoutToPlan={(p: Plan_t) => props.addNewWorkoutToPlan(p)}
					deleteCyclePressed={(a: any) => props.deleteCyclePressed(a)}
					copyFolderPressed={(e: any) => props.copyFolderPressed(e)}/>
			</div>
		</div>
	)
}

export default ClientProfileMain;
