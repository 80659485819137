import React, { useState, useEffect } from 'react';
import './ExerciseBreakdownRow.css';
import './ExerciseBreakdownCard.css';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function SetBreakdownRow(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [title, setTitle] = useState("Unkown Exercise");
    const [setComplete, setSetComplete] = useState(false);
    const [reps, setReps] = useState(0);
    const [targetReps, setTargetReps] = useState(-1);
    const [weight, setWeight] = useState(0);
    const [units, setUnits] = useState("LBS");
    const [orm_delta, setOrm_delta] = useState("+ 0.0%");
    const [weeklyVol, setWeeklyVol] = useState("0 reps, 0 sets");
    const [restString, setRestString] = useState("00s");
    const [score, setScore] = useState(10);
    const [tempoString, setTempoString] = useState("- : - : - : -");
    const [status, setStatus] = useState<"perfect" | "good" | "okay" | "poor">("perfect");

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            configureRow();
        }
    }, [initSet])

    useEffect(() => {
    	configureRow();
	}, [props.set])

    const configureRow = () => {
    	if (!props.set) return;

    	let completed = props.set.is_complete !== undefined && props.set.is_complete === true;
    	setSetComplete(completed);
    	if (props.set.reps_completed && props.set.reps_completed > 0) setReps(props.set.reps_completed)
    		else if (props.set.reps_anticipated) setReps(props.set.reps_anticipated)

        if (props.set.reps_anticipated) setTargetReps(props.set.reps_anticipated)
            else setTargetReps(-1)

    	if (props.set.weight) setWeight(props.set.weight)

    	if (props.set.tempo) {
    		let con = props.set.tempo.concentric <= 0 ? '0' : Math.floor(props.set.tempo.concentric * 10) / 10;
    		let ecc = props.set.tempo.eccentric <= 0 ? '0' : Math.floor(props.set.tempo.eccentric * 10) / 10;
    		let fp = props.set.tempo.first_pause <= 0 ? '0' : Math.floor(props.set.tempo.first_pause * 10) / 10;
    		let sp = props.set.tempo.second_pause <= 0 ? '0' : Math.floor(props.set.tempo.second_pause * 10) / 10;
    		setTempoString(`${con} : ${fp} : ${ecc} : ${sp}`)
    	}

    	if (props.set.restTime) setRestString(`${props.set.restTime < 10 ? '0' : ''}${props.set.restTime < 0 ? 0 : Math.floor(props.set.restTime)}s`);

    	var scoreTemp = 10;
    	if (props.set.restTime && props.set.restTime > 60) scoreTemp -= 2;
    	if (props.set.tempo && props.set.tempo.concentric > 0 && props.set.tempo.eccentric > 0) {
    		if (props.set.tempo.eccentric / props.set.tempo.concentric < 1.2) scoreTemp -= 2;
    	}
    	if (!completed) scoreTemp = 0;

    	scoreTemp = scoreTemp < 0 ? 0 : scoreTemp > 10 ? 10 : scoreTemp;
    	setStatus(getStatusString(scoreTemp));
    	setScore(scoreTemp)
    }

    const getStatusString = (scoreTemp: number) => {
        return scoreTemp >= 9.5 ? "perfect" : scoreTemp >= 8 ? "good" : scoreTemp >= 6 ? "okay" : "poor"
    }

	return (
		<div className={ "exercise-breakdown-row" }>
			<div className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-setnum" }>
                <div className={ `exercise-breakdown-card-body-col-name-icon exercise-breakdown-card-body-col-name-icon-${ status === "perfect" ? "green" : status === "good" ? "blue" : status === "okay" ? "yellow" : "red"}` }></div>
                <p>{ `Set ${props.setNum}` }</p>
            </div>
            <div style={{ opacity: setComplete ? 1.0 : 0.5}} className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-reps" }>
                <p>{ reps <= 0 ? '--' : reps } { targetReps > 0 ? <span>/ { targetReps }</span> : '' }</p>
            </div>
            <div style={{ opacity: setComplete ? 1.0 : 0.5}} className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-cross" }>
                <Close className={ "exercise-breakdown-card-body-col-cross-icon" }/>
            </div>
            <div style={{ opacity: setComplete ? 1.0 : 0.5}} className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-weight" }>
                <p>{ weight <= 0 ? '--.-' : weight } { units }</p>
            </div>
            <div style={{ opacity: setComplete ? 1.0 : 0.5}} className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-tempo" }>
                <p>{ tempoString }</p>
            </div>
            <div style={{ opacity: setComplete ? 1.0 : 0.5}} className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-tempo" }>
                <p>{ restString }</p>
            </div>
            <div style={{ opacity: setComplete ? 1.0 : 0.5}} className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-score" }>
                <p>{ score } / 10</p>
            </div>
		</div>
	)
}

export default SetBreakdownRow;