import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import './NewWorkoutMain.css';
import '../DashboardPageCommon.css';

import PageHeader_t from '../../../Interfaces/PageHeader_t';
import Client_t from '../../../Interfaces/Client_t';
import Workout_t from '../../../Interfaces/Workout_t';

import PageHeader from '../../../components/Base/PageHeader';
import NewWorkoutClientPage from './NewWorkout_Client';
import NewWorkoutWorkoutPage from './NewWorkout_Workout';
import NewWorkoutConfirmPage from './NewWorkout_Confirm';
import NewWorkoutNodePage from './NewWorkout_Nodes';

function NewWorkoutMain(props: any) {

	let initPageOptions: PageHeader_t[] = [{ name:"Select Client", alert:false },
									   { name:"Select Workout", alert:false },
					   				   { name:"Connect Nodes", alert:false }];

	let initPageOptions_B: PageHeader_t[] = [{ name:"Select Workout", alert:false },
									   { name:"Select Client", alert:false },
					   				   { name:"Connect Nodes", alert:false }];

	let initClient: Client_t = {first_name:'',
								last_name:'',
								full_name:'',
								id:'', 
								isMetric: true, 
								gender: 'b', 
								birthdate: '00-00-0000',
								height: 0, 
								weight: 0,
								goalIndex: 0,
								experienceIndex: 0};

	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};
	const [client, setClient] = useState<Client_t>(initClient);
	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	const [nodesAreAssigned, setNodesAreAssigned] = useState(false);
	const [clientIsFirst, setClientIsFirst] = useState(true);
	const [workflowState, setWorkflowState] = useState(1);
	const [initSet, setInitSet] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);

	const [pageOptions, setPageOptions] = useState<PageHeader_t[]>(initPageOptions);

	const [usingNodes, setUsingNodes] = useState(false);

	if (initSet === false) {
		setInitSet(true);
		initialize();
	}
 
	function initialize() {
		if (props.workout != null) {
			setClientIsFirst(false);
			setPageOptions(initPageOptions_B);
		}

		if (props.workout != undefined) {
			setWorkout(props.workout);
		}

		if (props.client != undefined) {
			setClient(props.client);
		}

		if (props.client === undefined && props.workout === undefined) {
			setWorkflowState(0);
		}
	}

	function openNodesPage() {
		props.openNodesPage();
	}

	function getWorkflowClassName(state: number) {
		return workflowState === state ? "new-workout-selected-workflow-state" : (state < workflowState ? "new-workout-completed-workflow-state" : "new-workout-unselected-workflow-state");
	}

	function getWorkflowContainerClassName(state: number) {
		return workflowState === state ? "new-workout-selected-workflow-state-container" : (state < workflowState ? "new-workout-completed-workflow-state-container" : "new-workout-unselected-workflow-state-container");
	}

	function workoutSelected(workout: Workout_t) {
		setWorkout(workout);
		setWorkflowState(workflowState + 1);
	}

	function clientSelected(client: Client_t) {
		setClient(client);
		setWorkflowState(workflowState + 1);
	}

	function nodesAssigned() {
		setWorkflowState(workflowState + 1);
		setNodesAreAssigned(true);
		setUsingNodes(true);

		if (props.nodeManager !== undefined) {
			props.nodeManager.disableTap();
		}
	}

	function skipNodes() {
		setUsingNodes(false);
		setWorkflowState(workflowState + 1);
	}

	function beginWorkout() {
		props.beginWorkout(workout, client, usingNodes);
	}

	function pageSelected(newPage: string) {
		for (var i = 0; i < pageOptions.length; i++) {
			if (pageOptions[i].name === newPage) {
				setWorkflowState(i);
			}
		}
	}

	return (
		<div className="page-container">
			<div className="home-header">
				<PageHeader 
					pageName="Start new Workout" 
					pageOptions={pageOptions}
					showButtons={false}
					showClose={true}
					closePressed={() => props.backPressed()}
					pageSelected={(e: string) => pageSelected(e)}
					selectedPageIndex={workflowState}
					workoutButtonPressed={() => props.startNewWorkout !== undefined ? props.startNewWorkout() : null}
					addNewWorkout={() => props.addNewWorkout !== undefined ? props.addNewWorkout() : null}
					addNewWorkoutPlan={() => props.addNewWorkoutPlan !== undefined ? props.addNewWorkoutPlan() : null}
					addNewClient={() => props.addNewClient !== undefined ? props.addNewClient() : null}
					addNewExercise={() => props.addNewExercise !== undefined ? props.addNewExercise() : null}/>
			</div>
			<div hidden={true} className="new-workout-header-container">
				<div className="new-workout-progress-circle">{workflowState < 4 ? workflowState + 1 : 4}</div>
				<div className="new-workout-header">
					<h1>Start a new workout {clientIsFirst ? `with ${client.first_name}` : ''}</h1>
					<div className="new-workout-workflow-state-container">
						<div className={getWorkflowContainerClassName(0)}><p className={getWorkflowClassName(0)}>{ clientIsFirst ? "Client" : "Workout"}</p></div>
						<div className={getWorkflowContainerClassName(1)}><p className={getWorkflowClassName(1)}>{ clientIsFirst ? "Workout" : "Client"}</p></div>
						<div className={getWorkflowContainerClassName(2)}><p className={getWorkflowClassName(2)}>Sync Nodes</p></div>
						<div className={getWorkflowContainerClassName(3)}><p className={getWorkflowClassName(3)}>Confirm</p></div>
					</div>
				</div>
			</div>
			<div className="new-workout-inner-page">
				<div hidden={(workflowState != 0 && clientIsFirst === true) || (workflowState != 1 && clientIsFirst === false)} className="new-workout-inner-page-container">
					<NewWorkoutClientPage
						workout={workout}
						clientIsFirst={clientIsFirst}
						clientSelected={(e: Client_t) => clientSelected(e)}/>
				</div>
				<div hidden={(workflowState != 1 && clientIsFirst === true) || (workflowState != 0 && clientIsFirst === false)} className="new-workout-inner-page-container">
					<NewWorkoutWorkoutPage
						client={client}
						clientIsFirst={clientIsFirst}
						workoutSelected={(e: Workout_t) => workoutSelected(e)}/>
				</div>
				
				<div hidden={workflowState != 2} className="new-workout-inner-page-container">
					<NewWorkoutNodePage
						nodeManager={props.nodeManager}
						nodesAssigned={() => nodesAssigned()}
						skipNodes={() => skipNodes()}
						openNodesPage={() => openNodesPage()}/>
				</div>
				<div hidden={workflowState != 3} className="new-workout-inner-page-container">
					<NewWorkoutConfirmPage
						clientIsFirst={clientIsFirst}
						client={client}
						workout={workout}
						nodeManager={props.nodeManager}
						nodesAssigned={nodesAreAssigned}
						beginWorkout={() => beginWorkout()}/>
				</div>
			</div>
		</div>
	)
}

export default NewWorkoutMain;