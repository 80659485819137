import React, { useState } from 'react';
import firebase from '../../Firebase';

import {Line, Bar} from 'react-chartjs-2';

import Workout_t from '../../Interfaces/Workout_t';

import './WorkoutProfileGeneral.css';
import './WorkoutProfileCommon.css'

import '@material-ui/core';
import MailOutline from '@material-ui/icons/MailOutline';
import GpsFixed from '@material-ui/icons/GpsFixed';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Wc from '@material-ui/icons/Wc';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Public from '@material-ui/icons/Public';

function WorkoutProfileGeneral(props: any) {

	let target_goals: string[] = [
        "Get Fit",
        "Loose Weight",
        "Gain Muscle",
        "Health Bennifits",
        "Body Building",
        "Power Lifitng"
      ];

    let workoutDifficulties: string[] = ['For beginners', 'Light', 'Moderate', 'Difficult', 'Extreme'];
    let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];

	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0,
						downloads: 0,
						timesCompleted: 0,
						exercises: []
					};
    let initDataSets = {
        labels: [],
        datasets: [
            {
                label: 'Reps',
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                type: 'line',
                data: []
            },
            {
                label: 'Reps Discrete',
                backgroundColor: 'rgba(73,89,193,0.05)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                data: []
            }
        ]
    };

    var chartOptions: any = {
        maintainAspectRatio: false,
        responsive: true,
        title:{
            display:false,
            text:'Rep volume',
            fontSize:10
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'reps',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#ffffff',
                    beginAtZero: true,
                    stepSize: 2,
                    fontSize: 10,
                    callback: function(value: any, index: any, values: any) {
                        return value;
                    }
                }
            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#9DA0AD',
                    fontSize: 10,
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
    };

	const [dataSets, setDataSets] = useState(initDataSets);
	const [workout, setWorkout] = useState<Workout_t>(initWorkout);
	const [workoutSet, setWorkoutSet] = useState(false);
	const [imgUrl, setImgUrl] = useState('');

	if (workoutSet === false) {
		setWorkoutSet(true);
		setWorkout(props.workout ? props.workout : initWorkout);
		getImage();
		setupChartData();

		// console.log(props.workout);
	}
	function setupChartData() {
		if (props.workout === undefined || props.workout.exercises === undefined) { return; }
		var labels_temp: string[] = [];
        var points_temp: number[] = [];
        for (var i = 0; i < props.workout.exercises.length; i++) {
            let thisExercise = props.workout.exercises[i];
            for (var j = 0; j < thisExercise.reps!.length; j++) {
                let thisSet = thisExercise.reps![j];
                labels_temp.push(String(j + 1));
                points_temp.push(thisSet);
            }
        }

        var tempDataSets = JSON.parse(JSON.stringify(initDataSets));
        tempDataSets.labels = labels_temp;
        tempDataSets.datasets[0].data = points_temp;
        //tempDataSets.datasets[1].data = points_temp;

        setDataSets(tempDataSets);
	}

	function getImage() {

		if (props.workout === undefined) { return; } 

		const storage = firebase.storage();
		storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}

	function getIntensity() {
		if (workout.difficulty !== undefined) {
			return workoutDifficulties[workout.difficulty]
		} else {
			return 'Moderate'
		}
	}

	function getGoals() {
		if (workout.goals !== undefined && workout.goals.length > 0) {
			var returnString = 'For ';
			for (var i = 0; i < workout.goals.length; i++) {
				let thisGoalString = target_goals[workout.goals[i]];
				let finalString = i === 0 ? thisGoalString : (i === workout.goals.length - 1 ? ` and ${thisGoalString}` : `, ${thisGoalString}`);
				returnString += finalString;
			}
			return returnString;
		} else {
			return 'No goals specified'
		}
	}

	function getMusclesString() {
		if (workout.exercises !== undefined && workout.exercises.length > 0) {
			var returnString = '';
			for (var i = 0; i < workout.exercises.length; i++) {
				let thisExercise = workout.exercises[i];
				if (thisExercise.muscles !== undefined && thisExercise.muscles.length > 0) {
					for (var j = 0; j < thisExercise.muscles.length; j++) {
						let thisMuscleGroupIndex= thisExercise.muscles[j].group;
						let thisMuscleGroupString = muscle_groups[thisMuscleGroupIndex];
						if (!returnString.includes(thisMuscleGroupString)) {
							let finalString = i + j === 0 ? thisMuscleGroupString : (i === workout.exercises.length - 1 && j === thisExercise.muscles.length - 1 ? ` and ${thisMuscleGroupString}` : `, ${thisMuscleGroupString}`);
							returnString += finalString;
						}
					}
				}
			}
			return returnString;
		} else {
			return `No muscles defined`
		}
	}


	function getDurationString() {
		let totalActiveTimeSeconds = workout.totalReps * 3.5;
		let totalRestTimeSeconds = workout.totalSets * 50;
		let totalSetupTimeSeconds = workout.exercises !== undefined ? workout.exercises.length * 45 : 300;
		let totalTimeMins = Math.ceil((totalActiveTimeSeconds + totalRestTimeSeconds + totalSetupTimeSeconds) / 60);
		return `${totalTimeMins} minutes`;
	}

	function getGenderString() {		
		if (workout.targetGender === 'm') {
			return 'Male'
		} else if (workout.targetGender === 'f') {
			return 'Female'
		} else {
			return 'Not specified'
		}
	}

	function getTimesCompletedString() {
		if (workout.timesCompleted !== undefined && workout.timesCompleted > 0) {
			return `Completed ${workout.timesCompleted} times`
		} else {
			return 'No workouts yet'
		}
	}

	function getDownloadsString() {
		if (workout.downloads !== undefined && workout.downloads > 0) {
			return `${workout.downloads} downloads`
		} else {
			return 'No downloads yet'
		}
	}

	return (
		<div className="workout-profile-page-container">
			<div className="client-profile-general-full-card">
				<div className="client-profile-general-full-card-content">
					<div className="client-profile-general-full-card-col-2">
						<p>Targetted Muscle Groups</p>
						<h4>{getMusclesString()}</h4>
					</div>
					<div className="client-profile-general-full-card-col-4">
						<p>Estimated Duration</p>
						<h4>{getDurationString()}</h4>
					</div>
					<div className="client-profile-general-full-card-col-4">
						<p>Intensity</p>
						<h4>{getIntensity()}</h4>
					</div>
					
				</div>
			</div>
			<div className="workout-profile-general-full-card workout-profile-general-full-card-plans">
				<div className="workout-profile-general-card-header">
					<h2>Workout Volume</h2>
					<p>The chart below illustrates the rep variation of {workout.title} over the span of the workout.</p>
				</div>
				<div className="workout-profile-general-card-content-plans-container">
					<div className="workout-profile-chart-container">
						<Bar
			                data={dataSets}
			                options={chartOptions}
			            />
					</div>
				</div>
			</div>
			<div className="workout-profile-general-full-card">
				<div className="workout-profile-body-stats-container">
					<div className="workout-profile-card-header-container">
						<h2>Workout Usage</h2>
						<p>How has {workout.title} been used since creation? Below you will find information about the workout creator, how many times it has been usd, and how many users have downloaded the workout.</p>
					</div>
					<div className="workout-profile-body-stats-info-container">
						<div className="workout-profile-body-stats-info-content">
							<p>Creator</p>
							<h3>{workout.createdBy.name}</h3>
						</div>
						<div className="workout-profile-body-stats-info-content">
							<p>Times Completed</p>
							<h3>{getTimesCompletedString()}</h3>
						</div>
						<div className="workout-profile-body-stats-info-content">
							<p>User Downloads</p>
							<h3>{getDownloadsString()}</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

/*
<div className="workout-profile-general-full-card">
				<div className="workout-profile-general-personal">
					<div className="workout-profile-general-profile-image-container">
						<div className="workout-profile-general-profile-circle">
							<img  
								className="client-profile-general-image" 
								hidden={imgUrl === ''}
								src={imgUrl} 
								alt={`Image for ${props.workout.title}`} />
						</div>
					</div>
					<div className="workout-profile-general-profile-info-container">
						<div className="workout-profile-general-profile-info-header-container">
							<h2>{workout.title}</h2>
							<p><AccessibilityIcon style={{ fontSize: 14 }} className="workout-profile-inline-icon"/> {getMusclesString()}</p>
							<p><GpsFixed style={{ fontSize: 14 }} className="workout-profile-inline-icon"/> {getGoals()}</p>
							<div className={workout.difficulty > 2 ? "workout-profile-user-experience-pill-red" : "workout-profile-user-experience-pill-green"}>{getIntensity()}</div>
						</div>
						<div className="workout-profile-general-profile-info-content">
							<div className="workout-profile-info-container ">
								<p className="workout-profile-info-header-label">Estiamted Duration</p>
								<p className="workout-profile-info-label"><QueryBuilder style={{ fontSize: 14 }}/> {getDurationString()}</p>
							</div>
							<div className="workout-profile-info-container ">
								<p className="workout-profile-info-header-label">Target Gender</p>
								<p className="workout-profile-info-label"><Wc style={{ fontSize: 14 }}/> {getGenderString()}</p>
							</div>
							<div className="workout-profile-info-container ">
								<p className="workout-profile-info-header-label">Avaliability</p>
								<p className="workout-profile-info-label"><Public style={{ fontSize: 14 }}/> All user access</p>
						</div>
						</div>
					</div>
				</div>
			</div>
*/

export default WorkoutProfileGeneral;