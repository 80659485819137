import React, { useState } from 'react';
import { IonLabel, IonInput, IonItem, IonButton } from '@ionic/react';
import './IntraWorkout.css';
import firebase from '../Firebase';
import GLTFLoader from 'three-gltf-loader';


function IntraWorkout(props: any) {

	var THREE = require('three');

	// var scene = new THREE.Scene();

	let scene: any,
		renderer: any,
		camera: any,
		model, 								// Our character
		neck, 								// Reference to the neck bone in the skeleton
		waist, 								// Reference to the waist bone in the skeleton
		leftArm,
		rightArm,
		possibleAnims, 						// Animations found in our file
		mixer: any, 								// THREE.js animations mixer
		idle, 								// Idle, the default state our character returns to
		clock = new THREE.Clock(), 			// Used for anims, which run to a clock instead of frame rate 
		currentlyAnimating = false, 		// Used to check whether characters neck is being used in another anim
  		raycaster = new THREE.Raycaster(); 	// Used to detect the click on our character

  	//init();

 	function init() {

	    const MODEL_PATH = 'https://threejs.org/examples/js/controls/TransformControls.js' //'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy_lightweight.glb';
	    const canvas = document.querySelector('#c');
	    const backgroundColor = 0x202B35;//0xf1f1f1;

	    // Init the scene
	    scene = new THREE.Scene();
	    scene.background = new THREE.Color(backgroundColor);
	    //scene.fog = new THREE.Fog(backgroundColor, 60, 100);

	    // Init the renderer
	    renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
	    renderer.shadowMap.enabled = true;
	    renderer.setPixelRatio(window.devicePixelRatio);
	    document.body.appendChild(renderer.domElement);

	     // Add a camera
		camera = new THREE.PerspectiveCamera(
		50,
		window.innerWidth / window.innerHeight,
		0.1,
		1000);

		camera.position.z = -30;//30;
		camera.position.x = 0;
		camera.position.y = -3;

		camera.rotation.y = Math.PI;

		let stacy_txt = new THREE.TextureLoader().load('https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQrpqzXNss6YEiBdPLWNjW2Pb3UZgu5ti4h1_qpuDn0UZ8eWk6B&usqp=CAU')//('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy.jpg');
		stacy_txt.flipY = false;

		const stacy_mtl = new THREE.MeshPhongMaterial({
		  color: 0xeeeeee,
		  skinning: true });


		
		const loader = new GLTFLoader();
		let URL = `https://threejs.org/examples/models/gltf/Soldier.glb` //${process.env.PUBLIC_URL}/assets/
		loader.setRequestHeader({["Content-Type"]: "application/json"});
		// console.log(loader);
		
	    loader.load( URL, function ( gltf: any ) { //https://threejs.org/examples/models/gltf/Soldier.glb

	      model = gltf.scene;
	      let fileAnimations = gltf.animations;
	      /*
	      model.traverse((o: any) => {

	        // console.log(`${o.name}, ${o.isBone}`);
	        
	        if (o.isMesh) {
	          o.castShadow = true;
	          o.receiveShadow = true;
	          o.material = stacy_mtl;
	        }

	        if (o.isBone && o.name.includes('mixamorigLeftLeg')) {
	          o.rotateX(-30);

	        }        

	      });

	      model.getObjectByName( 'mixamorigSpine' ).rotateZ(25);
	      model.getObjectByName( 'mixamorigSpine' ).rotateY(25);
	      rightArm = model.getObjectByName( 'mixamorigRightArm' );
	      rightArm.rotateZ(0.8);
		*/
	      model.scale.set(7, 7, 7);
	      model.position.y = -11;

	      scene.add(model);

	      //loaderAnim.remove();
	      /*
	      mixer = new THREE.AnimationMixer(model);

	      let clips = fileAnimations.filter((val: any) => val.name !== 'idle');
	      possibleAnims = clips.map((val: any) => {
	        let clip = THREE.AnimationClip.findByName(clips, val.name);

	        clip.tracks.splice(3, 3);
	        clip.tracks.splice(9, 3);

	        clip = mixer.clipAction(clip);
	        return clip;
	      });
	      */
		})

		// Add lights
	    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
	    hemiLight.position.set(0, 50, 0);
	    // Add hemisphere light to scene
	    scene.add(hemiLight);

	    let d = 8.25;
	    let dirLight = new THREE.DirectionalLight(0xffffff, 0.74);
	    dirLight.position.set(-8, 12, -8);
	    dirLight.castShadow = true;
	    dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
	    dirLight.shadow.camera.near = 0.1;
	    dirLight.shadow.camera.far = 1500;
	    dirLight.shadow.camera.left = d * -1;
	    dirLight.shadow.camera.right = d;
	    dirLight.shadow.camera.top = d;
	    dirLight.shadow.camera.bottom = d * -1;
	    // Add directional Light to scene
	    scene.add(dirLight);


	    // Floor
	    let floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
	    let floorMaterial = new THREE.MeshPhongMaterial({
	      color: 0x202B35,
	      shininess: 0 });


	    let floor = new THREE.Mesh(floorGeometry, floorMaterial);
	    floor.rotation.x = -0.5 * Math.PI;
	    floor.receiveShadow = true;
	    floor.position.y = -11;
	    scene.add(floor);

	    update();
	}

	function resizeRendererToDisplaySize(renderer: any) {
		const canvas = renderer.domElement;
		let width = window.innerWidth;
		let height = window.innerHeight;
		let canvasPixelWidth = canvas.width / window.devicePixelRatio;
		let canvasPixelHeight = canvas.height / window.devicePixelRatio;

		const needResize =
		canvasPixelWidth !== width || canvasPixelHeight !== height;
		if (needResize) {
			renderer.setSize(width, height, false);
		}
		return needResize;
	}

	function update() {
		if (mixer) {
			mixer.update(clock.getDelta());
		}

		if (resizeRendererToDisplaySize(renderer)) {
			const canvas = renderer.domElement;
			camera.aspect = canvas.clientWidth / canvas.clientHeight;
			camera.updateProjectionMatrix();
		}

		renderer.render(scene, camera);
		requestAnimationFrame(update);
	}

  //update();

  	let mouseDown = false;
	var prevCoords: any = null;
	var cameraAngle = 0;

	// document.addEventListener('mousedown', function (e) {
	// 	var mousecoords = getMousePos(e);
	// 	mouseDown = true;
	// 	moveCamera(mousecoords);

	// });

	// document.addEventListener('mouseup', function (e) {
	// 	prevCoords = null;
	// 	mouseDown = false;
	// });

	// document.addEventListener('mousemove', function (e) {
	// 	var mousecoords = getMousePos(e);

	// 	if (mouseDown === true) {
	// 		moveCamera(mousecoords);
	// 	} else {

	// 	}
	// });


	function moveCamera(coords: any) {

		if (!prevCoords) {
			prevCoords = coords;
			return; 
		}

		let radius = 30.0;
		let xDiff = prevCoords.x - coords.x;
		let xScaler = 0.01;
		cameraAngle += xDiff * xScaler;

		camera.rotation.y = cameraAngle;
		camera.position.z = radius * Math.cos(cameraAngle);
		camera.position.x = radius * Math.sin(cameraAngle);

		update();

		//rightArm.rotateZ(THREE.Math.degToRad(cameraAngle * 0.1));
		/*
		if (rightArm) {
		rightArm.rotation.x = cameraAngle * 5;
		rightArm.rotation.y = cameraAngle * 5;
		rightArm.rotation.z = cameraAngle * 5;
		// console.log(cameraAngle * 5);
		}*/

		prevCoords = coords;
	}

	function getMousePos(e: any) {
    	return { x: e.clientX, y: e.clientY };
  	}

  	function getCoordinates(event: any) {
  		// console.log(`STUFFS: ${event.clientX}, ${// console.log(event.clientY)}`);
  		moveCamera({x: event.clientX, y: event.clientY});
  	}

	return (
		<div className="container">
			<div className="button" onClick={() => init()}>GO</div>
			<canvas className="container" id="c"></canvas>
		</div>
	)
}

export default IntraWorkout;