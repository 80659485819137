import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';

import './PlanFolderSelector.css';

import Workout_t from '../../Interfaces/Workout_t'
import Folder_t from '../../Interfaces/Folder_t';
import Plan_t from '../../Interfaces/Plan_t';

import '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import Assignment from '@material-ui/icons/Assignment';
import List from '@material-ui/icons/List';

function PlanFolderSelector(props: any) {

    let initFolder = {
        title: "",
        type: "folder",
        id: "",
        path: "",
        children: [],
        isPlan: false,
    }

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [folders, setFolders] = useState<Folder_t[]>([]);
    const [selectedFolder, setSelecteFolder] = useState<Folder_t>(initFolder);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        // console.log(`Plan Folder Selector | Setting folder to:`, props.incomingSelectedFolder);
        if (props.incomingSelectedFolder !== undefined) {
            setSelecteFolder(props.incomingSelectedFolder);
        }
        
        
    }, [props.incomingSelectedFolder]);

    useEffect(() => {
        if (props.clientID !== undefined) {
            getPlans();
        }
    }, [props.clientID])

    function initialize() {
        getPlans();
    }

    function getPlans() {

        if (props.isClientFolder !== undefined && props.isClientFolder === true && props.clientID !== undefined) {
            getClientFolders();
            return;
        }

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setIsLoading(true);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/trainer_plans/${trainerUID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let foldersRoot = data.folders_root;
                let foldersInRootKeys = Object.keys(foldersRoot);
                let numFoldersInRoot = foldersInRootKeys.length;
                // console.log(`${numFoldersInRoot} folders in root`);

                var foldersRootList: Folder_t[] = [];
                for (var i = 0; i < numFoldersInRoot; i++) {
                    let thisFolderObj = foldersRoot[foldersInRootKeys[i]];
                    let resultFolder: Folder_t = parseFolder(thisFolderObj, foldersInRootKeys[i]);
                    foldersRootList.push(resultFolder);
                }

                setFolders(foldersRootList);
                //setShowingFolders(foldersRootList);

                // setPath("My workout plans");
                // setPath_ids("");

                // console.log("DONE");
                setIsLoading(false);

                // Parse and use data here
                
            } else {
                // No plans exist
                setIsLoading(false);
            }
        });
    }

    function getClientFolders() {
        setIsLoading(true);

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${props.clientID}/folders`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                let foldersRoot = data;
                let foldersInRootKeys = Object.keys(foldersRoot);
                let numFoldersInRoot = foldersInRootKeys.length;
                // console.log(`${numFoldersInRoot} folders in root`);

                var foldersRootList: Folder_t[] = [];
                for (var i = 0; i < numFoldersInRoot; i++) {
                    let thisFolderObj = foldersRoot[foldersInRootKeys[i]];
                    let resultFolder: Folder_t = parseFolder(thisFolderObj, foldersInRootKeys[i]);
                    foldersRootList.push(resultFolder);
                }

                setFolders(foldersRootList);
                //setShowingFolders(foldersRootList);

                // setPath("My workout plans");
                // setPath_ids("");

                // console.log("DONE");
                // console.log("setFolders:", foldersRootList);
                setIsLoading(false);

                // Parse and use data here
                
            } else {
                // No plans exist
                setIsLoading(false);
            }
        });
    }

    function parseFolder(folderObj: any, folderKey: string) {

        let folderObjType = folderObj.type;
        let isFolder = folderObjType === "folder";

        //if (isFolder) {
        var children: Folder_t[] = [];

        let thisObjChildren = folderObj.children;
        if (thisObjChildren !== undefined) {
            let childFolderKeys = Object.keys(thisObjChildren);
            let numChildFolders = childFolderKeys.length;
            for (var i = 0; i < numChildFolders; i++) {
                let thisFolderObj = thisObjChildren[childFolderKeys[i]];
                let resultFolder: Folder_t = parseFolder(thisFolderObj, childFolderKeys[i]);
                children.push(resultFolder);
            }
        }
        

        var returnFolder: Folder_t = {
            title: folderObj.name,
            id: folderKey,
            type: folderObjType,
            path: folderObj.path,
            plan_id: folderObj.plan_id,
            phaseName: folderObj.phase_name,
            isPlan: folderObjType === "plan",
            children: children
        }

        return returnFolder;
        // } else {

        //     // Plan found at end of folder chain
        //     let planFolder: Folder_t = {
        //         title: folderObj.name,
        //         type: folderObjType,
        //         isPlan: true,
        //         path: folderObj.path,
        //         plan_id: folderObj.plan_id,
        //         children: [],
        //         id: folderKey
        //     }

        //     return planFolder;
        // }
    }

    function folderPressed(folder: Folder_t) {

        var okayToProceed = false;

        if ((!folder.isPlan && folder.type !== undefined && folder.type !== "cycle") || props.incomingSelectedFolder.type === "plan") {
            okayToProceed = true;
        }

        if (folder.isPlan && props.incomingSelectedFolder.type !== "plan" && folder.plan_id === undefined) {
            // Unbuilt phases
            okayToProceed = true;
        }

        if (okayToProceed) {
            // console.log("folderPressed: ", folder);
            setSelecteFolder(folder);
            props.folderSelected(folder);
        }
    }

    function allowSelect(folder: Folder_t) {
        if (props.noCycleSelect === undefined || props.noCycleSelect === false) { return true; }

        

        if (folder.type === undefined || folder.type !== "folder") {
            return false;
        }

        if (folder.children.length > 0) {
            for (var i = 0; i < folder.children.length; i++) {
                let thisChild: Folder_t = folder.children[i];
                if (thisChild.type !== "folder") {
                    return false;
                }
            }
        }

        return true;
    }

    function getFolderRow(folder: Folder_t, depth: number) {
        var arr: any[] = [];

        var hasCycleChildren = false;
        if (allowSelect(folder)) {
            let obj =   <div onClick={() => folderPressed(folder)} style={{width: `calc(100% - ${24 * depth}px)`, margin: `0px 0px 0px ${24 * depth}px`}} className={folder.id === selectedFolder.id ? "plan-folder-selector-row-container plan-folder-selector-row-container-selected" : "plan-folder-selector-row-container"}>
                        <div className="plan-folder-selector-vertical-line"/>
                        <div className="plan-folder-selector-horizontal-line"/>
                        <div hidden={folder.type !== "folder"} className="plan-folder-selector-row-icon-container plan-folder-selector-row-icon-container-folder">
                            <Folder className="plan-folder-selector-row-icon plan-folder-selector-row-icon-folder"/>
                        </div>
                        <div hidden={folder.type !== "plan" && folder.type !== "phase"} className="plan-folder-selector-row-icon-container plan-folder-selector-row-icon-container-plan">
                            <Assignment className="plan-folder-selector-row-icon plan-folder-selector-row-icon-plan"/>
                        </div>
                        <div hidden={folder.type !== "cycle"} className="plan-folder-selector-row-icon-container plan-folder-selector-row-icon-container-cycle">
                            <List className="plan-folder-selector-row-icon plan-folder-selector-row-icon-cycle"/>
                        </div>
                        <div className={`plan-folder-selector-row-text-container ${folder.isPlan && props.incomingSelectedFolder.type !== "plan" ? (folder.plan_id === undefined ? 'plan-folder-selector-row-text-container-plan-unbuilt' : 'plan-folder-selector-row-text-container-plan') : 'plan-folder-selector-row-text-container-folder'}`}>
                            <p>{folder.title} {folder.isPlan && props.incomingSelectedFolder.type !== "plan" && folder.plan_id === undefined ? "(Unbuilt)" : ""}</p>
                        </div>
                    </div>;
            
            arr.push(obj);
            

            if (folder.children.length > 0) {
                for (var i = 0; i < folder.children.length; i++) {
                    if (folder.children[i].type === "cycle") {
                        hasCycleChildren = true;
                    }
                    let thisSubFolder = getFolderRow(folder.children[i], depth + 1);
                    if (thisSubFolder !== null) {
                        arr.push(thisSubFolder);
                    }
                    
                }
            }
        }

        
        return props.removeCycleFolders !== undefined && props.removeCycleFolders === true && hasCycleChildren === true ? null : arr;
    }

    function getFoldersContent() {
        var arr: any[] = [];

        let rootFolder: Folder_t = {
            title: "My workout plans",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
        }

        let rootRow = getFolderRow(rootFolder, 0);
        arr.push(rootRow);

        for (var i = 0; i < folders.length; i++) {
            let row = getFolderRow(folders[i], 1);
            
                arr.push(row);
           
            
        }

        return arr;
    }

	return (
		<div className="plan-folder-selector">
			{getFoldersContent()}
		</div>
	)
}

export default PlanFolderSelector;