import React, { useState } from 'react';
import './WorkoutsMore.css';

import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Info from '@material-ui/icons/Info';
import Check from '@material-ui/icons/Check';

import WorkoutsMoreLoadingPatterns from './MorePages/WorkoutsMoreLoadingPatterns';

function WorkoutsMore(props: any) {

    const [pageTitles, setPageTitles] = useState<string[]>(["Custom Loading Patterns"])

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [pageIndex, setPageIndex] = useState(0);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function pageSelected(atIndex: number) {
        setPageIndex(atIndex);
    }

	return (
		<div className="workouts-more">
			<div className="workout-plan-list-folder-card-container">
                <div className="workout-plan-list-folder-card">
                    <div  className="workout-plan-list-folder-card-header">
                        <h1>More Content</h1>
                    </div>
  
                    <div hidden={isLoading} className="workout-plan-list-folder-card-body workouts-more-list-folder-card-body">
                        {
                            pageTitles.map((item: string, index: number) => (
                                <div onClick={() => pageSelected(index)} className={`workouts-more-selection-row ${index === pageIndex ? 'workouts-more-selection-row-selected' : ''}`}>
                                    <p>{item}</p>
                                    <ArrowForward className="workouts-more-selection-row-icon"/>
                                </div>
                            ))
                        }
                        
                    </div>
                    <div hidden={!isLoading} className="workout-plan-list-folder-card-body workout-plan-list-folder-card-body-loading">
                        <p>Loading Workout Plans...</p>
                    </div>
                </div>
            </div>
            <div className="workout-plan-content-container">
                <div className="workout-plan-content workout-plan-content-cycle-overview-container">
                    <div className="workouts-more-content-page-header">
                        <h1>{pageTitles[pageIndex]}</h1>
                    </div>
                    <div hidden={pageIndex !== 0} className="workouts-more-content-page-container">
                        <WorkoutsMoreLoadingPatterns 
                            getUserConfirmation={(e: any) => props.getUserConfirmation(e)}
                            createNewLoadingPattern={() => props.createNewLoadingPattern()}/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default WorkoutsMore;