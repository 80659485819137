import React, { useState, useEffect } from 'react';
import './ExerciseBreakdownRow.css';
import './ExerciseBreakdownCard.css';


function ExerciseBreakdownRow(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [title, setTitle] = useState("Unkown Exercise");
    const [orm, setOrm] = useState(0);
    const [units, setUnits] = useState("LBS");
    const [orm_delta, setOrm_delta] = useState("+ 0.0%");
    const [weeklyVol, setWeeklyVol] = useState("0 reps, 0 sets");
    const [score, setScore] = useState(10);
    const [status, setStatus] = useState<"perfect" | "good" | "okay" | "poor">("perfect");

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            configureRow();
        }
    }, [initSet])

    useEffect(() => {
    	configureRow();
	}, [props.exercise])

    const configureRow = () => {
    	if (!props.exercise || !props.exercise.exercise_data) return;

    	setTitle(props.exercise.exercise_data.title ?? "Unknown Exercise");


    	if (!props.exercise.sets || props.exercise.sets.length < 1) return;


    	var ormLatest = -1;
    	var ongoingOrm = -1;
    	var weightUnitsTemp = "LBS";
    	var vol_sets = 0;
    	var vol_reps = 0;

    	for (var i = 0; i < props.exercise.sets.length; i++) {
    		let thisSet = props.exercise.sets[i];

    		let isSetComplete = thisSet.is_complete !== undefined && thisSet.is_complete === true;

    		if (isSetComplete) {

    			let reps = thisSet.reps_completed;
    			let weight = thisSet.weight;
    			if (weight > 0 && reps > 0) {
    				let thisORM = weight / (1.0278 - (reps * 0.0278))
    				if (ormLatest === -1) ormLatest = thisORM;
		    			else ongoingOrm = thisORM;
    			}
    			
	    		if (thisSet.weight_units && thisSet.weight_units !== "") weightUnitsTemp = thisSet.weight_units

    			vol_sets += 1;
	    		vol_reps += reps ?? 0;
    		}
    	}

    	let numDays = props.numDays ?? 7;
    	let numWks = Math.floor(numDays / 7);
    	let vol_reps_weekly = Math.ceil(vol_reps / numWks);
    	let vol_sets_weekly = Math.ceil(vol_sets / numWks);
    	setWeeklyVol(`${ vol_reps_weekly} rep${vol_reps_weekly === 1 ? '' : 's'}, ${ vol_sets_weekly } set${vol_sets_weekly === 1 ? '' : 's'}`);

    	let deltaOrmTemp = ongoingOrm <= 0 || ormLatest <= 0 ? 0 : (ormLatest - ongoingOrm) / ongoingOrm;
    	let deltaOrmTempRounded = Math.abs(Math.floor(deltaOrmTemp * 1000) / 10);
    	setOrm_delta(`${deltaOrmTemp >= 0 ? '+' : '-'}${deltaOrmTempRounded}${deltaOrmTempRounded % 1 === 0 ? '.0' : ''}%`)

    	setOrm(Math.floor(ormLatest));
    	setUnits(weightUnitsTemp)


    	let scoreTemp = 10;
    	if (vol_reps_weekly === 0) {
    		scoreTemp -= 10;
    	} else if (vol_reps_weekly < 40 || vol_sets_weekly < 3) {
    		scoreTemp -= 2;
    	}

    	if (deltaOrmTemp < 0) {
    		scoreTemp -= 2;
    	}

    	scoreTemp = scoreTemp < 0 ? 0 : scoreTemp > 10 ? 10 : scoreTemp;
    	setStatus(getStatusString(scoreTemp));
    	setScore(scoreTemp);
    }

    const getStatusString = (scoreTemp: number) => {
        return scoreTemp >= 9.5 ? "perfect" : scoreTemp >= 8 ? "good" : scoreTemp >= 6 ? "okay" : "poor"
    }

	return (
		<div className={ "exercise-breakdown-row" }>
			<div className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-name" }>
                <div className={ `exercise-breakdown-card-body-col-name-icon exercise-breakdown-card-body-col-name-icon-${ status === "perfect" ? "green" : status === "good" ? "blue" : status === "okay" ? "yellow" : "red"}` }></div>
                <p>{ title }</p>
            </div>
            <div className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-measurement" }>
                <p>{ orm <= 0 ? '--.-' : orm } { orm <= 0 ? "" : units }</p>
                <h4>{ orm <= 0 ? "" : orm_delta }</h4>
            </div>
            <div className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-datapoints" }>
                <p>{ weeklyVol }</p>
            </div>
            <div className={ "exercise-breakdown-card-body-col-content-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-score" }>
                <p>{ score } / 10</p>
            </div>
		</div>
	)
}

export default ExerciseBreakdownRow;