 // Import Ionic Elements, React and Supporting Elements/Libraries
import React, {useState} from 'react';
import { Switch, Route, Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";
import Mixpanel from '../../Mixpanel';
import { IonReactRouter } from '@ionic/react-router';
import { CSSTransition } from 'react-transition-group';
import MetaTags from 'react-meta-tags';

// Import CSS Files
import './Dashboard.css';

// Import Libraries
import firebase from '../../Firebase';
import axios from 'axios';
import NodeManager from '../../NodeManager';
import NodeInstance from '../../NodeInstance';
import MLCore from '../../MLCore';

// Import Data Types
import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import Folder_t from '../../Interfaces/Folder_t';
import Plan_t from '../../Interfaces/Plan_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import CurrentWorkout_t from '../../Interfaces/CurrentWorkout_t';
import SetStats_t from '../../Interfaces/SetStats_t';
import RepStats_t from '../../Interfaces/RepStats_t';
import MotionData_t from '../../Interfaces/MotionData_t';
import Peripheral_t from '../../Interfaces/Peripheral_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import Goal_t from '../../Interfaces/Goal_t';
import Location_t from '../../Interfaces/Locations/Location_t';

// Import React Components
import TextInput from '../../components/Base/TextInput';
import StandardButton from '../../components/Base/StandardButton';
import Home from './Home';
import ScheduleMain from './Schedule/ScheduleMain';
import Workouts from './Workouts';
import WorkoutProfile from '../WorkoutProfile/WorkoutProfileMain';
import Clients from './Clients';
import ClientProfile from '../ClientProfile/ClientProfileMain';
import SelectWorkout from './ClientWorkflow/SelectWorkout';
import Nodes from './Nodes';
import MyNodes from './NodePages/MyNodes';
import DiscoverNodes from './NodePages/DiscoverNodes';
import OrderNodes from './NodePages/MyNodes';
import Trainers from './StudioPages/Trainers';
import MyStudio from './StudioPages/MyStudio';
import MyCoachingProfile from './StudioPages/MyCoachingProfile';
import NodeTest from './NodeTest';
import Profile from '../Profile';
import NewWorkoutWorkflow from './NewWorkoutWorkflow/NewWorkoutMain';
import IntraWorkout from './IntraWorkout/IntraWorkoutMain';
import WorkoutSummary from './IntraWorkout/WorkoutSummary';
import DashboardMenu from '../../components/DashboardMenu';
import HelpAndFeedback from './HelpAndFeedback';
import SettingsMain from './Settings/SettingsMain';

import Modal from '../../components/Modal';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import ContactModal from '../../components/Modals/ContactModal';
import FeedbackModal from '../../components/Modals/FeedbackModal';
import CurrentExerciseModal from '../../components/Modals/CurrentExerciseModal';
import UploadImageModal from '../../components/Modals/UploadImageModal';
import EditGoalModal from '../../components/Modals/EditGoalModal';
import NewElementModal from '../../components/Modals/NewElementModal';
import NewProgramModal from '../../components/Modals/NewProgramModal';
import AddTrainerModal from '../../components/Modals/AddTrainerModal';
import AssignModal from '../../components/Modals/AssignModal';
import AddExerciseToWorkoutModal from '../../components/Modals/AddExerciseToWorkoutModal';
import CopyFolderModal from '../../components/Modals/CopyFolderModal';
import RecordMessageModal from '../../components/Modals/RecordMessageModal';
import CopyFolderToClient from '../../components/Modals/CopyFolderToClient';
import DuplicatePhase from '../../components/Modals/DuplicatePhase';
import PairNodesModal from '../../components/Modals/PairNodesModal';
import NewAppointmentModal from '../../components/Modals/NewAppointmentModal';
import NewLoadingPatternModal from '../../components/Modals/NewLoadingPatternModal';
import NewLocationModal from '../../components/Modals/NewLocationModal';
import ScheduleMessagesModal from '../../components/Modals/ScheduleMessagesModal';
import PerformanceAnalysis from '../../components/Modals/PerformanceAnalysis/PerformanceAnalysis';
import Messages from "../Messages/Messages";


function Dashboard(props: any) {

	/**
	 *	Decleare variables and constants to be used throughout Dashboard, or initializing objects
	 *  ----------------------------------------------------------------------------------------------------
	 */

	let exampleProfile = {
		id:'',
		city: 'Toronto',
		company_name: 'Goodlife',
		email: 'johhny@appleseed.com',
		first_name: 'Johnny',
		last_name: 'Appleseed',
		full_name: 'Johnny Appleseed',
		works_for_org: true,
		years_experience: 5,
		joined_timestamp: -1
	}

	let history = useHistory();													// Instance of useHistory for path redirects

	/**
	 *	Decleare state variables and their setters
	 *  ----------------------------------------------------------------------------------------------------
	 */
	const [showModal, setShowModal] = useState(false);									// Flag indicating display status of modal
	//const [modalData, setModalData] = useState<any>({});										// Pass data from page to modal with this state
	const [modalType, setModalType] = useState<string>('client-profile');				// The type of modal to be displayed. See Modal.tsx
																						// for acceptable type strings.
    const [modalData, setModalData] = useState<any>({});

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [confirmModalData, setConfirmModalData] = useState({});

	const [showCurrentExerciseModal, setShowCurrentExerciseModal] = useState(false);

	const [showContactModal, setShowContactModal] = useState(false);
	const [showImageUploadModal, setShowImageUploadModal] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [showEditGoalModal, setShowEditGoalModal] = useState(false);
	const [showNewElementModal, setShowNewElementModal] = useState(false);
	const [showNewProgramModal, setShowNewProgramModal] = useState(false);
	const [showTrainerModal, setShowTrainerModal] = useState(false);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showAddExerciseToWorkoutModal, setShowAddExerciseToWorkoutModal] = useState(false);
	const [showCopyFolderModal, setShowCopyFolderModal] = useState(false);
	const [showCopyFolderToClientModal, setShowCopyFolderToClientModal] = useState(false);
	const [showDuplicatePhaseModal, setShowDuplicatePhaseModal] = useState(false);
	const [showPairModal, setShowPairModal] = useState(false);
	const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
	const [showNewLoadingPatternModal, setShowNewLoadingPatternModal] = useState(false);
	const [showNewLocationModal, setShowNewLocationModal] = useState(false);
	const [showPerformanceAnalysis, setShowPerformanceAnalysis] = useState(false);
	const [showRecordMessageModal, setShowRecordMessageModal] = useState(false);
	const [showScheduleMessagesModal, setShowScheduleMessagesModal] = useState(false);

	const [showingSettings, setShowingSettings] = useState(false);
	const [previousPathIndex, setPreviousPathIndex] = useState(0);

	const [pageIndex, setPageIndex] = useState(0);										// Holds current dashboard page index
	const [subPageIndex, setSubPageIndex] = useState(0);
	const [selectedClient, setSelectedClient] = useState<Client_t>();					//
	const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>();				//
	const [inWorkoutSession, setInWorkoutSession] = useState(false);					//
	const [currentWorkout, setCurrentWorkout] = useState<CurrentWorkout_t>();			//

	const [selectedWeight, setSelectedWeight] = useState(0);
	const [newSetStartTime, setNewSetStartTime] = useState(0);							//
	const [newSetWorkingTime, setNewSeWorkingTime] = useState(0);						//

	const [mlcore, setMlcore] = useState<MLCore>();

	const [nodeManager, setNodeManager] = useState<NodeManager>();						// NodeManager instance to be shared globally. Set only once
	const [nodeMotionData, setNodeMotionData] = useState<any[]>([]);					// List of motion data in format [{MotionData_t, Peripheral_t}]
	const [myNodesList, setMyNodesList] = useState<NodeInstance[]>([]);					//
	const [discoveredNodesList, setDiscoverdNodesList] = useState<NodeInstance[]>([]);	//

	const [profileLoaded, setProfileLoaded] = useState(false);							//
	const [profile, setProfile] = useState(exampleProfile);								// User (personal trainer) profile. Defaults to exampleProfile

	const [initSet, setInitSet] = useState(false);										// Initial Flag. Check if false and set true immediately after
																						// for a block that runs only on setup.

	/**
	 *	Initializing functions.
	 *  ----------------------------------------------------------------------------------------------------
	 */

    if (initSet === false) {
        setInitSet(true);

        

        // Create re-usable instance of MLCore
        let c = new MLCore({title:'',id:'',type: 0,muscles:[]});
        setMlcore(c);

        // Create re-usable instance of NodeManager
        let m = new NodeManager(nodesListUpdated, myNodesUpdated, dataUpdated, c);
        setNodeManager(m);

        window.addEventListener('keypress', function(e) {

	        switch (e.keyCode) {
	       		case 27:			 // 'esc'
	       			hideModalPressed();
	       			break;

	       		default:
	       			break;
	       	}

	        // Ignore any following hotkeys if modal is already open
	        if (showModal ||
	        	showingSettings ||
	        	showConfirmModal ||
	        	showCurrentExerciseModal ||
	        	showContactModal ||
	        	showImageUploadModal ||
	        	showFeedbackModal ||
	        	showEditGoalModal ||
	        	showNewElementModal ||
	        	showNewProgramModal ||
	        	showTrainerModal ||
	        	showAssignModal ||
	        	showAddExerciseToWorkoutModal ||
	        	showCopyFolderModal ||
	        	showCopyFolderToClientModal ||
	        	showPairModal ||
	        	showNewAppointmentModal) { return; }

	       	switch (e.keyCode) {
	       		case 23:			 // 'ctrl + w'
	       			createNewWorkout();
	       			break;
	       		case 1:			 // 'ctrl + c'
	       			createNewClient();
	       			break;
	       		case 16:			 // 'ctrl + p'
	       			createNewPlan();
	       			break;
	       		case 5:			 // 'ctrl + e'
	       			addNewExercise();
	       			break;
	       		default:
	       			break;
	       	}
	    });
    }


	let thisUser = firebase.auth().currentUser;
	if (!thisUser) {
		// console.log('DASHBOARD: No user logged in, will exit');
		history.push('/');
	}

	if (profileLoaded === false) { getUserProfile(); }

	/**
	 *	Page change handling methods
	 *  ----------------------------------------------------------------------------------------------------
	 */
	function pageChanged(toIndex: number) {
		let pages = ['home','schedule','workouts','clients','messages','my-nodes','discover-nodes','order-nodes','my-coaching-profile','trainers','my-studio','help','settings'];
		history.push(`/dashboard/${pages[toIndex]}`);
		setPageIndex(toIndex);

		let a = firebase.analytics();
		a.logEvent("search", {search_term: "core"});

		setSubPageIndex(0);

		if (toIndex === 12) {
			setShowingSettings(true);
		} else {
			setPreviousPathIndex(toIndex);
		}
	}

	function backFromSettings() {
		setShowingSettings(false);
		pageChanged(previousPathIndex);
	}

	function profilePressed() {
		setPageIndex(5);
		history.push(`/dashboard/profile`);
	}

	/**
	 *	Initializing functions.
	 *  ----------------------------------------------------------------------------------------------------
	 */

	 // Call to retrieve user (personal trainer) profile information from db.
	function getUserProfile() {
		setProfileLoaded(true);
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		// console.log("DASHBOARD: getUserProfile()");

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_profile/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (snapshot.exists()) {

				let tempProfile = snapshot.val();
				let thisUser = firebase.auth().currentUser;
				if (!thisUser) { return; }
				tempProfile.id = thisUser.uid;
				updateProfile(tempProfile);
			}

		});
	}

	// This app login requires Firebase Auth object to have 'displayName' parameter set.
	// Call updateProfile to check if it is already set, and if not, set to the user's first name.
	function updateProfile(withData: any) {

		// TODO: REMOVE
		// Mixpanel.people.set_once({
		// 	'Register Date': new Date(),
		// 	$name: withData.full_name.toUpperCase(),
		// 	'Company': withData.company_name.toUpperCase(),
		// 	'Location': withData.city.toUpperCase(),
		// 	$email: withData.email.toUpperCase()
		// });

		// Check if user has joined_timestamp
		if (withData.joined_timestamp === undefined) {
			let database = firebase.database();
			let ref = database.ref(`personal_trainer_profile/${withData.id}/joined_timestamp`);
			ref.set(Date.now());
			withData["joined_timestamp"] = Date.now();
		}

		setProfile(withData);
		// console.log("SETTING PROFILE TO:", withData);

		// Check if user has display name. Old users will not. Update it to their name if it is null.
		var user = firebase.auth().currentUser;

		if (user) {
			if (user.displayName === null || (user.displayName !== withData.full_name && withData.full_name !== "") ) {
				user.updateProfile({
					displayName: withData.full_name
				}).then(function() {
					// Update successful.
					//console.log(`> DASHBOARD: User has updated display name`);
				}).catch(function(error) {
					// An error happened.
					// console.log("> DAHSBOARD: ERROR OCCURED in setting user display name");
					// console.log(error);
				});
			} else {
				//console.log("Display name:", user.displayName)
			}
		}
	}

	/**
	 *	Node-relevant Methods
	 *  ----------------------------------------------------------------------------------------------------
	 *  Callbacks and void methods that handle connection to NodeManager. These functions serve as an
	 *	intermediary between dashboard pages and NodeManager - call the following through props of dashboard
	 *	pages to access NodeManager.
	 *
	 */

	// Callback for when NodeManager discovers new Nodes and updates its local list
	function nodesListUpdated(toList: NodeInstance[]) {
		setDiscoverdNodesList(toList);
	}

	function myNodesUpdated(toList: NodeInstance[]) {
		setMyNodesList(toList);
	}

	/* Callback for when NodeManager retrieves new motion data from Nodes.
	* @param data [{MotionData_t, Peripheral_t}] a list of motion data and the respective peripheral
	*/
	function dataUpdated(data: [any]) {
		setNodeMotionData(data);
	}

	// Call to begin streaming motion data from Nodes
	function beginDataStream() {
		if (nodeManager !== undefined) {
			nodeManager.beginDataStream();
		}
	}

	// Call to end streaming motion fata from Nodes
	function endDataStream() {
		if (nodeManager !== undefined) {
			nodeManager.endDataStream();
		}
	}

	function discoverNodesPressedFromMyNodes() {
		setShowPairModal(true);
		// history.push(`/dashboard/discover-nodes`);
	}


	function createNewClient() {
		setModalType('new-client');
		setShowModal(true);
	}

	function createNewWorkout(withInfo?: any) {
		setModalType('new-workout');
		setShowModal(true);
		// console.log("*****OOOOOOOOOOOO))))OOOO))))OOOO createNewWorkout: ", withInfo);
		if (withInfo !== undefined) {
			setModalData(withInfo);
		}
	}

	function addNewExercise() {
		setModalType('new-exercise');
		setShowModal(true);
	}

	function openNewNoteModal() {
		setModalType('new-note');
		setShowModal(true);
		setModalData(selectedClient);
	}

	function addNewExerciseToWorkoutModalPressed(info: any) {
		setModalData(info);
		setShowAddExerciseToWorkoutModal(true);
	}

	function copyFolderPressed(info: any) {
		setModalData(info);
		setShowCopyFolderModal(true)
	}

	function copyFolderToClientPressed(info: any) {
		setModalData(info);
		setShowCopyFolderToClientModal(true);
	}

	function addGoalPressed() {
		// setModalType('new-goal');
		// setShowModal(true);
		// setModalData(selectedClient);
		let dataForModal = {
			isEditing: false,
			client: selectedClient
		}
		setModalData(dataForModal);
		setShowEditGoalModal(true);
	}

	function logMeasurementPressed() {
		setModalType('log-measurement');
		setShowModal(true);
		setModalData(selectedClient);
	}

	function createNewPlan() {
		setModalType('new-plan');
		setShowModal(true);
	}

	function createPhasePressedWithWorkouts(w: Workout_t[]) {
		setModalType('new-plan');
		setModalData({preselected_workouts: w});
		setShowModal(true);
	}

	function assignClientToPlan(plan: Plan_t, client?: Client_t) {
		setModalData({plan: plan, client: client});
		//setModalType('assign-plan');
		//setShowModal(true);
		setShowAssignModal(true);
	}

	function assignClientToFolder(folder: Folder_t, client?: Client_t) {
		setModalData({folder: folder, client: client});
		setShowAssignModal(true);
	}

	function editPlanPressed(plan: Plan_t, client_id?: string) {
		setModalData(client_id ? {plan: plan, clientID: client_id} : {plan: plan});
		setModalType('edit-plan');
		setShowModal(true);
	}

	function duplicatePlanPressed(plan: Plan_t, client_id?: string) {
		setShowDuplicatePhaseModal(true);
		setModalData(client_id ? {plan: plan, clientID: client_id} : {plan: plan});
		// setModalType('edit-plan');
		// setShowModal(true);
	}

	function addNewWorkoutToPlan(plan: Plan_t) {
		setModalData({addToPlan: plan});
		setModalType('new-workout');
		setShowModal(true);
	}

	function editRestDayPressed(plan: Plan_t, dayIndex: number, client?: Client_t) {
		setModalData(client && client.id && client.id !== "" ? {addToPlan: plan, dayIndex: dayIndex, clientID: client.id} : {addToPlan: plan, dayIndex: dayIndex});
		setModalType('new-workout');
		setShowModal(true);
	}

	function addNewElementPressed(info: any) {
		setModalData(info);
		setShowNewElementModal(true);
	}

	function addNewProgramPressed(info: any) {
		setModalData(info);
		setShowNewProgramModal(true);
	}

	function addNewTrainerPressed(info: any) {
		setModalData(info);
		setShowTrainerModal(true);
	}

	function addNewAppointmentPressed(info: any) {
		// console.log("Dashboard - addNewAppointmentPressed", info)
		setModalData(info);
		setShowNewAppointmentModal(true);
	}

	function createNewPlanWithFolder(folder: Folder_t, clientID?: string) {
		setModalData({folder: folder, clientID: clientID});
		// console.log(`DASHBOARD | createNewPlanWithFolder: `, folder);
		setModalType('new-plan');
		setShowModal(true);
	}

	function createNewPlanWithFolderAndCallback(folder: Folder_t, callback?: any) {
		setModalData({folder: folder, plan_created_callback: callback});
		// console.log(`DASHBOARD | createNewPlanWithFolder: `, folder);
		setModalType('new-plan');
		setShowModal(true);
	}

	function addLocationPressed() {
		setModalData({});
		setShowNewLocationModal(true);
	}

	function editLocationPressed(l: Location_t) {
		setModalData({location: l});
		setShowNewLocationModal(true);
	}



	function editWorkoutFromSchedule(workout: Workout_t, calendarDay: any, client: Client_t) {
		setModalData({
			selectedClient: client,
			selectedTimestamp: calendarDay.timestamp,
			editingWorkout: workout
		});
		setModalType('new-workout');
		setShowModal(true);
	}

	function createNewLoadingPattern(withReps?: number[]) {
		setModalData({... modalData, withReps: withReps});
		setShowNewLoadingPatternModal(true);
	}


	function showClientProfile(client: Client_t) {
		setSelectedClient(client);
		history.push('/dashboard/client-profile');
	}

	function showWorkoutProfile(workout: Workout_t) {
		setSelectedWorkout(workout);
		history.push('/dashboard/workout-profile');
	}

	function moreInfoSelected(client: Client_t) {
		//setModalType('client-profile');
		//setModalData(client);
		//setShowModal(true);
	}

	function beginPairNodesPressed(info: any) {
		setModalData(info);
		setShowPairModal(true);
	}

	function hideConfirmModal() {
		setShowConfirmModal(false);
		setModalData({});
	}

	function hideModalPressed() {
		setShowModal(false);
		setShowConfirmModal(false);
		setShowContactModal(false);
		setShowFeedbackModal(false);
		setShowImageUploadModal(false);
		setShowEditGoalModal(false);
		setShowNewElementModal(false);
		setShowNewProgramModal(false);
		setShowTrainerModal(false);
		setShowAssignModal(false);
		setShowAddExerciseToWorkoutModal(false);
		setShowCopyFolderModal(false);
		setShowCopyFolderToClientModal(false);
		setShowDuplicatePhaseModal(false);
		setShowPairModal(false);
		setShowNewAppointmentModal(false);
		setShowNewLoadingPatternModal(false);
		setShowNewLocationModal(false);
		setShowPerformanceAnalysis(false);
		setShowRecordMessageModal(false);
		setShowScheduleMessagesModal(false);
		setModalData({});
	}

	function hideLoadingPatternModalPressed() {
		setShowNewLoadingPatternModal(false);
		//setModalData({});
	}

	function hideExerciseModalPressed() {
		setShowAddExerciseToWorkoutModal(false);
	}

	function getUserConfirmation(info: any) {
		setConfirmModalData(info);
		setShowConfirmModal(true);
	}

	function contactCardPressed() {
		setShowFeedbackModal(true);
	}

	function openClientInDynamicTrain(client_id: string) {
		setModalData({client_id : client_id});
		setShowPerformanceAnalysis(true);

	}

	function configureExerciseForAssign(callback: any, blockIndex: number, data?: any) {
		setModalData({ callback: callback, blockIndex: blockIndex, data: data });
		setShowAddExerciseToWorkoutModal(true);
	}

	function openVideoRecord(callback: any, forClient: Client_t) {
		setModalData({ callback: callback, client: forClient });
		setShowRecordMessageModal(true);
	}

	function openScheduleMessages(clientID: string) {
		setModalData({ client_id: clientID });
		setShowScheduleMessagesModal(true);
	}

	function startNewWorkout() {
		setShowPerformanceAnalysis(true);
		/*
		Mixpanel.track("Request begin new workout without parameters");
		setSelectedClient(undefined);
		setSelectedWorkout(undefined);
		//setShowingSettings(true);
		history.push('/dashboard/start-new-workout');
		*/
	}

	function startWorkoutWithClient(client?: Client_t) {
		Mixpanel.track("Request begin new workout with client");
		setSelectedClient(client);
		setSelectedWorkout(undefined);
		//setShowingSettings(true);
		history.push('/dashboard/start-new-workout');
	}

	function startWorkoutWithWorkout(workout?: Workout_t) {
		Mixpanel.track("Request begin new workout with workout");
		setSelectedClient(undefined);
		setSelectedWorkout(workout);
		//setShowingSettings(true);
		history.push('/dashboard/start-new-workout');
	}

	function backToMainFromStartNewWorkout() {
		setShowPerformanceAnalysis(true);
		/*
		Mixpanel.track("Exit from begin new workout");
		setSelectedClient(undefined);
		setSelectedWorkout(undefined);
		setShowingSettings(false);
		history.push('/dashboard');
		*/
	}

	function beginWorkout(workout: Workout_t, client: Client_t, usingNodes: boolean) {

		setShowPerformanceAnalysis(true);

		/*
		setSelectedClient(client);
		setSelectedWorkout(workout);

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);
		let newWorkoutObject: CurrentWorkout_t = {
			workout: workout,
			client: client,
			startTime: time,
			setStats: [],
			seriesIndex: 0,
			exerciseIndex: 0,
			setIndex: 0,
			selectedWeight: 0,
			isKG: false,
			usingNodes: usingNodes
		}
		setCurrentWorkout(newWorkoutObject);
		setNewSetStartTime(time);
		setInWorkoutSession(true);
		setPageIndex(6);
		history.push('/dashboard/intraworkout');

		Mixpanel.track("Begin new workout", {using_nodes: usingNodes});
		Mixpanel.people.increment("Workouts started");
		Mixpanel.time_event("Workout completed");

		// Set the current workout in NodeManager to pass to new MotionManager instance
		// for motion info object to be used by algorithm filters
		if (newWorkoutObject.workout !== undefined && newWorkoutObject.workout.exercises !== undefined && nodeManager !== undefined) {
			let currentExercise: Exercise_t = newWorkoutObject.exerciseIndex <= newWorkoutObject.workout.exercises.length - 1 ? newWorkoutObject.workout.exercises[newWorkoutObject.exerciseIndex] : newWorkoutObject.workout.exercises[newWorkoutObject.workout.exercises.length - 1];
			nodeManager.setCurrentExercise(currentExercise);
		}
		*/
	}

	function configureWorkingMaxHistory(forExercise: Exercise_t, forClient: Client_t) {
		// Will pull client's WM history and push it to CurrentWorkout
		if (currentWorkout === undefined) {return;}

		let sendData = {
		    client_id: forClient.id,
		    exercise_id: forExercise.id,
		    days_to_show: 120
		};

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getWorkingMaxHistory`,
		sendData,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
		    // console.log("getWorkingMaxHistory response: ");
		    // console.log(response.data);
		    let data = response.data;
		    // console.log("currentWorkout === undefined: ", currentWorkout === undefined);

		    if (data.error === 0) {
		    	var wTemp: Workout_t = currentWorkout!.workout;
			    if (wTemp.exerciseSeries !== undefined && wTemp.exerciseSeries.length > 0) {
			    	for (var i = 0; i < wTemp.exerciseSeries.length; i++) {
			    		if (wTemp.exerciseSeries[i].exercises.length > 0) {
			    			for (var j = 0; j < wTemp.exerciseSeries[i].exercises.length; j++) {
			    				if (forExercise.id === wTemp.exerciseSeries[i].exercises[j].id && wTemp.exerciseSeries[i].exercises[j].userWorkingMaxHistory === undefined) {
			    					wTemp.exerciseSeries[currentWorkout!.seriesIndex].exercises[currentWorkout!.exerciseIndex].userWorkingMaxHistory = data.results.working_max_list;
			    				}
			    			}
			    		}
			    	}
			    }

			    setCurrentWorkout({...currentWorkout, workout: wTemp});
		    }

		    //setCurrentWorkout
		})
		.catch(e => {
		    // console.log(e);
		})
	}

	function updateWeight(toWeight: number) {
		setSelectedWeight(toWeight);
		if (currentWorkout === undefined) { return; }

		setCurrentWorkout({...currentWorkout, selectedWeight: toWeight});

		if (nodeManager === undefined) { return; }
		let isKG = currentWorkout.isKG;
		nodeManager.setWeight(toWeight, isKG);
	}

	function changeReps(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
		let repNum = Number(toReps);
		if (currentWorkout !== undefined) {

			let statsTemp: SetStats_t[][][] = currentWorkout!.setStats;

			if (forSeriesIndex < statsTemp.length &&
	            setIndex < statsTemp[forSeriesIndex].length &&
	            forExerciseIndex < statsTemp[forSeriesIndex][setIndex].length) {

	            let thisSetStat: SetStats_t = statsTemp[forSeriesIndex][setIndex][forExerciseIndex];
	            thisSetStat.manualEntryReps = repNum;
	            statsTemp[forSeriesIndex][setIndex][forExerciseIndex] = thisSetStat;
	        }

	        setCurrentWorkout({...currentWorkout, setStats: statsTemp});
		}
	}

	function changeWeight(toWeight: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
		let weightNum = Number(toWeight);
		if (currentWorkout !== undefined) {

			let statsTemp: SetStats_t[][][] = currentWorkout!.setStats;

			if (forSeriesIndex < statsTemp.length &&
	            setIndex < statsTemp[forSeriesIndex].length &&
	            forExerciseIndex < statsTemp[forSeriesIndex][setIndex].length) {

	            let thisSetStat: SetStats_t = statsTemp[forSeriesIndex][setIndex][forExerciseIndex];
	            thisSetStat.weight = weightNum;
	            statsTemp[forSeriesIndex][setIndex][forExerciseIndex] = thisSetStat;
	        }

	        // Check if this change advances workout progress
	        if (forSeriesIndex === currentWorkout!.seriesIndex && forExerciseIndex === currentWorkout!.exerciseIndex && setIndex === currentWorkout!.setIndex) {
	        	// Weight changed for current set/exercise/series -- advance to next set
	        	nextSetFromWeightUpdate(weightNum, statsTemp);
	        	return;
	        }

	        setCurrentWorkout({...currentWorkout, setStats: statsTemp});
		}
	}

	function changeTempo(toTempo: Tempo_t, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
		if (currentWorkout !== undefined) {

			let statsTemp: SetStats_t[][][] = currentWorkout!.setStats;

			if (forSeriesIndex < statsTemp.length &&
	            setIndex < statsTemp[forSeriesIndex].length &&
	            forExerciseIndex < statsTemp[forSeriesIndex][setIndex].length) {

	            let thisSetStat: SetStats_t = statsTemp[forSeriesIndex][setIndex][forExerciseIndex];
	            thisSetStat.tempo = toTempo;
	            statsTemp[forSeriesIndex][setIndex][forExerciseIndex] = thisSetStat;
	        }

	        setCurrentWorkout({...currentWorkout, setStats: statsTemp});
		}
	}

	function changeRest(toRest: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) {
		let restNum = Number(toRest);
		if (currentWorkout !== undefined) {

			let statsTemp: SetStats_t[][][] = currentWorkout!.setStats;

			if (forSeriesIndex < statsTemp.length &&
	            setIndex < statsTemp[forSeriesIndex].length &&
	            forExerciseIndex < statsTemp[forSeriesIndex][setIndex].length) {

	            let thisSetStat: SetStats_t = statsTemp[forSeriesIndex][setIndex][forExerciseIndex];
	            thisSetStat.restTime = restNum;
	            statsTemp[forSeriesIndex][setIndex][forExerciseIndex] = thisSetStat;
	        }

	        setCurrentWorkout({...currentWorkout, setStats: statsTemp});
		}
	}

	/**
	 *	@brief	Call when weight is updated for current set and sys should progress to next set.
	 * 			This method of set progression should only be called when Nodes are not used for current workout.
	 *
	 * 	@param 	withWeight		number 		New weight entered by user
	 */
	function nextSetFromWeightUpdate(withWeight: number, statsTemp: SetStats_t[][][]) {

		if (currentWorkout === undefined) { return; }		// Exit if currentWorkout is not defined
		if (nodeManager === undefined) 	  { return; }		// Exit if NodeManager is not defined
		if (currentWorkout.usingNodes)    { return; }		// Exit if currentWorkout is using Nodes

		// Update current set stats
		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);

  		let workingTime = time - newSetWorkingTime;
		let restTime = newSetWorkingTime - newSetStartTime;

		var t: SetStats_t[][][] = statsTemp; //currentWorkout!.setStats;

		if (currentWorkout.seriesIndex < t.length &&
            currentWorkout.setIndex < t[currentWorkout.seriesIndex].length &&
            currentWorkout.exerciseIndex < t[currentWorkout.seriesIndex][currentWorkout.setIndex].length) {

            let thisSetStat: SetStats_t = t[currentWorkout.seriesIndex][currentWorkout.setIndex][currentWorkout.exerciseIndex];
        	thisSetStat.endUnixTime = time;
        	thisSetStat.restTime = restTime;

        	thisSetStat.weight = withWeight;

        	var temp: RepStats_t[] = nodeManager !== undefined ? nodeManager.getRepStats() : [];
			let numReps = temp.length;

			for (var i = 0; i < numReps; i++) {
				let newRepStats = temp[i];

				thisSetStat.averagePower += newRepStats.power / numReps;
				thisSetStat.averageROM += newRepStats.ROM / numReps;
				thisSetStat.averageFormAccuracy += newRepStats.formAccuracy / numReps;
			}

			thisSetStat.repStats = temp;

			t[currentWorkout.seriesIndex][currentWorkout.setIndex][currentWorkout.exerciseIndex] = thisSetStat;
	    }



	    // Add set stats for next set
	    // console.log("nextSetFromWeightUpdate | START SET");

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);

  		setNewSeWorkingTime(time);

  		if ( currentWorkout === undefined) { return; }
		let isKG = currentWorkout.isKG;
		nodeManager.setWeight(withWeight, isKG);


		let useExerciseIndex = currentWorkout.exerciseIndex;

		// Set the current workout in NodeManager to pass to new MotionManager instance
		// for motion info object to be used by algorithm filters

		if (currentWorkout.workout !== undefined && currentWorkout.workout.exercises !== undefined) {
			let currentExercise: Exercise_t = currentWorkout.exerciseIndex <= currentWorkout.workout.exercises.length - 1 ? currentWorkout.workout.exercises[currentWorkout.exerciseIndex] : currentWorkout.workout.exercises[currentWorkout.workout.exercises.length - 1];
			nodeManager.setCurrentExercise(currentExercise);
		}

		// Create a new set stat
		let targetRepCount = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].reps![currentWorkout.setIndex];


		// Check for tempo
		// console.log("Dashboard | nextSetFromWeightUpdate - THIS EXERCISE TEMPOS: ");
		// console.log(currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos);

		var thisSetTempo: Tempo_t = {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1};
		if (currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos !== undefined && currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos!.length > 0) {
			let tempoList: Tempo_t[] = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos!;
			thisSetTempo = currentWorkout.setIndex < tempoList.length ? tempoList[currentWorkout.setIndex] : {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1};
		}


		let newSetStats: SetStats_t = {
			startUnixTime: time,
			endUnixTime: 0,
			restTime: 0,
			averagePower: 0,
			averageROM: 0,
			averageFormAccuracy: 0,
			weight: withWeight, //currentWorkout.selectedWeight === 0 ? selectedWeight : currentWorkout.selectedWeight,
			isKG: currentWorkout.isKG,
			anticipatedReps: targetRepCount,
			manualEntryReps: targetRepCount,
			repStats: [],
			tempo: thisSetTempo,
			targetTempo: thisSetTempo
		}

		// var t: SetStats_t[][][] = currentWorkout!.setStats;
		if (currentWorkout.seriesIndex >= t.length) {
			// start a new series of setStats
			t.push([[newSetStats]]);
		} else {
			if (currentWorkout.setIndex >= t[currentWorkout.seriesIndex].length) {
				// start a new set of setStats
				t[currentWorkout.seriesIndex].push([newSetStats]);
			} else {
				// next exercise in set, push to existing set
				t[currentWorkout.seriesIndex][currentWorkout.setIndex].push(newSetStats);
			}
		}

		//// console.log("Dashboard | startSet - SET STATS:",t);


		//

		//// console.log("NEXT SET", currentWorkout);
		if (currentWorkout === undefined) { return; }

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);
  		setNewSetStartTime(time);

		// var nextExerciseIndex = currentWorkout.exerciseIndex;
		// var nextSetIndex = currentWorkout.setIndex + 1;
		// if (nextSetIndex > currentWorkout.workout.exercises![currentWorkout.exerciseIndex].reps!.length - 1) {
		// 	nextExerciseIndex = currentWorkout.exerciseIndex + 1;
		// 	nextSetIndex = 0;
		// 	if (nextExerciseIndex >= currentWorkout.workout.exercises!.length) {
		// 		endWorkout();
		// 	}
		// }
		//var nextSetIndex = currentWorkout.setIndex + 1;
		//setCurrentWorkout({...currentWorkout, exerciseIndex: nextExerciseIndex, setIndex: nextSetIndex});

		// 1. Check if there is another exercise in series (ie. are you in a superset?)
		if (currentWorkout.exerciseIndex + 1 < currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises!.length) {
			// Yes, in a superset with another exercise to go. Move up to next exercise in this set (no change to series # or set #).
			//setCurrentWorkout({...currentWorkout, exerciseIndex: currentWorkout.exerciseIndex + 1});
			startSet(currentWorkout.exerciseIndex + 1);

			if (nodeManager !== undefined) {
				nodeManager.endDataStream();
				setTimeout(() => {
					nodeManager.beginDataStream();
				}, 500);
			}



		} else {
			// Either not in a superset, or no more exercises remaining in super set.
			// 2. Check if sets are remaining in this series

			// Hide current workout modal if superset is complete
			setShowCurrentExerciseModal(false);

			if (nodeManager !== undefined) {
				nodeManager.endDataStream();
			}

			var maxSetIndex = 0;
			for (var i = 0; i < currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises!.length; i++) {
				let thisExercise = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![i];

				if (thisExercise.reps!.length > maxSetIndex) {
					maxSetIndex = thisExercise.reps!.length;
				}
			}

			if (currentWorkout.setIndex + 1 < maxSetIndex) {
				// Yes, there is another set in this series. Go ahead and move up a set, while returning exercise index to 0. No change to series #.
				setCurrentWorkout({...currentWorkout, setStats: t, exerciseIndex: 0, setIndex: currentWorkout.setIndex + 1, startTime: time});
			} else {
				// No more sets in this series! Move to next series or end exercise.
				// 3. Check if more series are available to do in workout. Otherwise end the workout.
				if (currentWorkout.seriesIndex + 1 < currentWorkout.workout.exerciseSeries!.length) {
					// Yes, another series exists. Move to it, while resetting exerciseIndex and setIndex to 0.
					setCurrentWorkout({...currentWorkout, setStats: t, exerciseIndex: 0, setIndex: 0, seriesIndex: currentWorkout.seriesIndex + 1, startTime: time});
				} else {
					// Workout is complete! Go ahead and end 'er.
					endWorkoutConfirmed();
				}
			}
		}

		// if (useExerciseIndex === undefined) {
		// 	setCurrentWorkout({...currentWorkout, setStats: t, startTime: time});
		// } else {
		// 	setCurrentWorkout({...currentWorkout, setStats: t, exerciseIndex: useExerciseIndex, startTime: time});
		// }
	}


	function startSet(withExerciseIndex?: number) {
		// console.log("startSet | START SET");

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);

  		setNewSeWorkingTime(time);
  		setShowCurrentExerciseModal(true);

  		if (nodeManager === undefined || currentWorkout === undefined) { return; }
		let weight = currentWorkout.selectedWeight;
		let isKG = currentWorkout.isKG;
		nodeManager.setWeight(weight, isKG);

		nodeManager.beginDataStream();

		let useExerciseIndex = withExerciseIndex === undefined ? currentWorkout.exerciseIndex : withExerciseIndex;

		// Set the current workout in NodeManager to pass to new MotionManager instance
		// for motion info object to be used by algorithm filters

		if (currentWorkout.workout !== undefined && currentWorkout.workout.exercises !== undefined) {
			let currentExercise: Exercise_t = currentWorkout.exerciseIndex <= currentWorkout.workout.exercises.length - 1 ? currentWorkout.workout.exercises[currentWorkout.exerciseIndex] : currentWorkout.workout.exercises[currentWorkout.workout.exercises.length - 1];
			nodeManager.setCurrentExercise(currentExercise);
		}

		// Create a new set stat
		if (currentWorkout === undefined) { return; }

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);

		let targetRepCount = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].reps![currentWorkout.setIndex];


		// Check for tempo
		// console.log("Dashboard | startSet - THIS EXERCISE TEMPOS: ");
		// console.log(currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos);
		var thisSetTempo: Tempo_t = {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1};
		if (currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos !== undefined && currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos!.length > 0) {
			let tempoList: Tempo_t[] = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![useExerciseIndex].tempos!;
			thisSetTempo = currentWorkout.setIndex < tempoList.length ? tempoList[currentWorkout.setIndex] : {concentric: -1, eccentric: -1, first_pause: -1, second_pause: -1};
		}


		let newSetStats: SetStats_t = {
			startUnixTime: time,
			endUnixTime: 0,
			restTime: 0,
			averagePower: 0,
			averageROM: 0,
			averageFormAccuracy: 0,
			weight: currentWorkout.selectedWeight === 0 ? selectedWeight : currentWorkout.selectedWeight,
			isKG: currentWorkout.isKG,
			anticipatedReps: targetRepCount,
			manualEntryReps: targetRepCount,
			repStats: [],
			tempo: thisSetTempo,
			targetTempo: thisSetTempo
		}

		var t: SetStats_t[][][] = currentWorkout!.setStats;
		if (currentWorkout.seriesIndex >= t.length) {
			// start a new series of setStats
			t.push([[newSetStats]]);
		} else {
			if (currentWorkout.setIndex >= t[currentWorkout.seriesIndex].length) {
				// start a new set of setStats
				t[currentWorkout.seriesIndex].push([newSetStats]);
			} else {
				// next exercise in set, push to existing set
				t[currentWorkout.seriesIndex][currentWorkout.setIndex].push(newSetStats);
			}
		}

		// console.log("Dashboard | startSet - SET STATS:",t);

		if (withExerciseIndex === undefined) {
			setCurrentWorkout({...currentWorkout, setStats: t, startTime: time});
		} else {
			setCurrentWorkout({...currentWorkout, setStats: t, exerciseIndex: withExerciseIndex, startTime: time});
		}



		//setShowCurrentExerciseModal(true);
	}

	function completeSet(withWeight?: number) {

		// console.log("Dashboard | completeSet - withWeight:", withWeight, currentWorkout);

		setShowCurrentExerciseModal(false);

		if (currentWorkout === undefined) { return; }

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);

  		let workingTime = time - newSetWorkingTime;
		let restTime = newSetWorkingTime - newSetStartTime;

		var t: SetStats_t[][][] = currentWorkout!.setStats;

		if (currentWorkout.seriesIndex < t.length &&
            currentWorkout.setIndex < t[currentWorkout.seriesIndex].length &&
            currentWorkout.exerciseIndex < t[currentWorkout.seriesIndex][currentWorkout.setIndex].length) {

            let thisSetStat: SetStats_t = t[currentWorkout.seriesIndex][currentWorkout.setIndex][currentWorkout.exerciseIndex];
        	thisSetStat.endUnixTime = time;
        	thisSetStat.restTime = restTime;

        	thisSetStat.weight = withWeight !== undefined ? withWeight : (currentWorkout.selectedWeight === 0 ? selectedWeight : currentWorkout.selectedWeight);

        	var temp: RepStats_t[] = nodeManager !== undefined ? nodeManager.getRepStats() : [];
			let numReps = temp.length;

			for (var i = 0; i < numReps; i++) {
				let newRepStats = temp[i];

				thisSetStat.averagePower += newRepStats.power / numReps;
				thisSetStat.averageROM += newRepStats.ROM / numReps;
				thisSetStat.averageFormAccuracy += newRepStats.formAccuracy / numReps;

			}

			thisSetStat.repStats = temp;

			t[currentWorkout.seriesIndex][currentWorkout.setIndex][currentWorkout.exerciseIndex] = thisSetStat;
	    }


/*
		let targetRepCount = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![currentWorkout.exerciseIndex].reps![currentWorkout.setIndex];

		let newSetStats: SetStats_t = {
			startUnixTime: newSetWorkingTime,
			endUnixTime: time,
			restTime: restTime,
			averagePower: 0,
			averageROM: 0,
			averageFormAccuracy: 0,
			weight: currentWorkout.selectedWeight,//Math.floor(50 + (Math.random() * 100)),
			isKG: currentWorkout.isKG,
			anticipatedReps: targetRepCount,
			manualEntryReps: targetRepCount,
			repStats: []
		}



		var temp: RepStats_t[] = nodeManager !== undefined ? nodeManager.getRepStats() : [];
		let numReps = temp.length; //currentWorkout!.workout!.exercises![currentWorkout!.exerciseIndex].reps![currentWorkout!.setIndex];


		for (var i = 0; i < numReps; i++) {
			// let newRepStats: RepStats_t = {
			// 	startTime: newSetStartTime,
			// 	power: Math.floor(Math.random() * 500),
			// 	ROM: Math.random(),
			// 	formAccuracy: Math.random(),
			// 	timing: {
			// 		static_up: 0.05,
			// 		static_down: 0.05,
			// 		motion_rise: 0.05,
			// 		motion_fall: 0.05
			// 	}
			// }
			let newRepStats = temp[i];

			newSetStats.averagePower += newRepStats.power / numReps;
			newSetStats.averageROM += newRepStats.ROM / numReps;
			newSetStats.averageFormAccuracy += newRepStats.formAccuracy / numReps;

			//temp.push(newRepStats);
		}

		newSetStats.repStats = temp;

		var t: SetStats_t[][][] = currentWorkout!.setStats;
		if (currentWorkout.seriesIndex >= t.length) {
			// start a new series of setStats
			t.push([[newSetStats]]);
		} else {
			if (currentWorkout.setIndex >= t[currentWorkout.seriesIndex].length) {
				// start a new set of setStats
				t[currentWorkout.seriesIndex].push([newSetStats]);
			} else {
				// next exercise in set, push to existing set
				t[currentWorkout.seriesIndex][currentWorkout.setIndex].push(newSetStats);
			}
		}

		// if (t.length <= currentWorkout.exerciseIndex) {
		// 	t.push([newSetStats]);
		// } else {
		// 	t[currentWorkout.exerciseIndex].push(newSetStats);
		// }

		// console.log("SET STATS:");
		// console.log(t);
*/
  		setCurrentWorkout({...currentWorkout, setStats: t});

		nextSet();

	}

	function nextSet() {

		//// console.log("NEXT SET", currentWorkout);
		if (currentWorkout === undefined) { return; }

		var d = new Date();
  		var n = d.getTime();
  		var time = Math.floor(n / 1000);
  		setNewSetStartTime(time);

		// var nextExerciseIndex = currentWorkout.exerciseIndex;
		// var nextSetIndex = currentWorkout.setIndex + 1;
		// if (nextSetIndex > currentWorkout.workout.exercises![currentWorkout.exerciseIndex].reps!.length - 1) {
		// 	nextExerciseIndex = currentWorkout.exerciseIndex + 1;
		// 	nextSetIndex = 0;
		// 	if (nextExerciseIndex >= currentWorkout.workout.exercises!.length) {
		// 		endWorkout();
		// 	}
		// }
		//var nextSetIndex = currentWorkout.setIndex + 1;
		//setCurrentWorkout({...currentWorkout, exerciseIndex: nextExerciseIndex, setIndex: nextSetIndex});

		// 1. Check if there is another exercise in series (ie. are you in a superset?)
		if (currentWorkout.exerciseIndex + 1 < currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises!.length) {
			// Yes, in a superset with another exercise to go. Move up to next exercise in this set (no change to series # or set #).
			//setCurrentWorkout({...currentWorkout, exerciseIndex: currentWorkout.exerciseIndex + 1});
			startSet(currentWorkout.exerciseIndex + 1);

			if (nodeManager !== undefined) {
				nodeManager.endDataStream();
				setTimeout(() => {
					nodeManager.beginDataStream();
				}, 500);
			}



		} else {
			// Either not in a superset, or no more exercises remaining in super set.
			// 2. Check if sets are remaining in this series

			// Hide current workout modal if superset is complete
			setShowCurrentExerciseModal(false);

			if (nodeManager !== undefined) {
				nodeManager.endDataStream();
			}

			var maxSetIndex = 0;
			for (var i = 0; i < currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises!.length; i++) {
				let thisExercise = currentWorkout.workout.exerciseSeries![currentWorkout.seriesIndex].exercises![i];

				if (thisExercise.reps!.length > maxSetIndex) {
					maxSetIndex = thisExercise.reps!.length;
				}
			}

			if (currentWorkout.setIndex + 1 < maxSetIndex) {
				// Yes, there is another set in this series. Go ahead and move up a set, while returning exercise index to 0. No change to series #.
				setCurrentWorkout({...currentWorkout, exerciseIndex: 0, setIndex: currentWorkout.setIndex + 1, startTime: time});
			} else {
				// No more sets in this series! Move to next series or end exercise.
				// 3. Check if more series are available to do in workout. Otherwise end the workout.
				if (currentWorkout.seriesIndex + 1 < currentWorkout.workout.exerciseSeries!.length) {
					// Yes, another series exists. Move to it, while resetting exerciseIndex and setIndex to 0.
					setCurrentWorkout({...currentWorkout, exerciseIndex: 0, setIndex: 0, seriesIndex: currentWorkout.seriesIndex + 1, startTime: time});
				} else {
					// Workout is complete! Go ahead and end 'er.
					endWorkoutConfirmed();
				}
			}
		}


	}

	function endWorkout() {

		if (currentWorkout === undefined) { return; }

		// var nextExerciseIndex = currentWorkout.exerciseIndex;
		// var nextSetIndex = currentWorkout.setIndex + 1;
		// if (nextSetIndex > currentWorkout.workout.exercises![currentWorkout.exerciseIndex].reps!.length - 1) {
		// 	nextExerciseIndex = currentWorkout.exerciseIndex + 1;
		// 	nextSetIndex = 0;
		// 	if (nextExerciseIndex >= currentWorkout.workout.exercises!.length) {
		// 		endWorkoutConfirmed();
		// 		return;
		// 	}
		// }

		Mixpanel.track("Workout end requested");

		getUserConfirmation({
			title: "Are you sure you want to end this workout?",
			text: "You won't be able to resume and complete the remaining sets if you end the workout now. ",
			isRed: true,
			callback: endWorkoutConfirmed,
			ctaText: "End Workout"
		});

	}

	function endWorkoutConfirmed() {
		logWorkout();
		setInWorkoutSession(false);
		history.push('/dashboard/workoutSummary');
	}

	function logWorkout() {
		if (currentWorkout === undefined) { return }
		// console.log("> DASHBOARD: LOGGING WORKOUT");
		// Post log object to user's workout logs
		let startDate = new Date(currentWorkout.startTime * 1000);
		let y_start = startDate.getFullYear();
		let mo_start = startDate.getMonth();
		let d_start = startDate.getDate();
		let h_start = startDate.getHours();
		let m_start = startDate.getMinutes();
		let s_start = startDate.getSeconds();
		let startDateString = `${y_start}-${mo_start + 1}-${d_start} ${h_start}:${m_start}:${s_start}`;

		let endDate = new Date();
		let y_end = endDate.getFullYear();
		let mo_end = endDate.getMonth();
		let d_end = endDate.getDate();
		let h_end = endDate.getHours();
		let m_end = endDate.getMinutes();
		let s_end = endDate.getSeconds();
		let endDateString = `${y_end}-${mo_end + 1}-${d_end} ${h_end}:${m_end}:${s_end}`;

		var statCount = 0;
		var exerciseSeriesTemp: any[] = [];

		for (var series_i = 0; series_i < currentWorkout.setStats.length; series_i++) {

			let thisSeries: ExerciseSeries_t | undefined = currentWorkout.workout.exerciseSeries !== undefined && series_i < currentWorkout.workout.exerciseSeries.length ? currentWorkout.workout.exerciseSeries[series_i] : undefined;
			var seriesTitle = "_";
			if (thisSeries !== undefined) {
				seriesTitle = thisSeries.title;
			}

			let thisSeriesStats: SetStats_t[][] = currentWorkout.setStats[series_i];
			var exercisesTemp: any[] = [];

			for (var i = 0; i < thisSeriesStats.length; i++) {
				let thisSetStats: SetStats_t[] = thisSeriesStats[i];
				//var thisExerciseID =  currentWorkout.workout.exercises![i] !== undefined ? currentWorkout.workout.exercises![i].id : "";

				var setStatsTemp: any[] = [];
				for (var j = 0; j < thisSetStats.length; j++) {
					// j = exercise index
					var thisExerciseID = "";
					var thisExerciseTitle = "";

					if (thisSeries !== undefined) {
						let thisExercise = thisSeries.exercises[j];
						thisExerciseID = thisExercise.id;
						thisExerciseTitle = thisExercise.title;
					}

					let thisExerciseSetStat: SetStats_t = thisSetStats[j];
					var repStatsTemp: RepStats_t[] = [];
					if (thisExerciseSetStat.repStats.length > 0) {
						for (var k = 0; k < thisExerciseSetStat.repStats.length; k++) {
							let thisRepStat = thisExerciseSetStat.repStats[k];
							let thisRepObj = thisRepStat;
							repStatsTemp.push(thisRepObj);
						}
					}
					let thisSetStatObj: any = {
						ROM: thisExerciseSetStat.averageROM,
						power: thisExerciseSetStat.averagePower,
						form_accuracy: thisExerciseSetStat.averageFormAccuracy,
						restTime: thisExerciseSetStat.restTime,
						weight: thisExerciseSetStat.weight,
						reps_anticipated: currentWorkout.workout.exercises![i] !== undefined ? currentWorkout.workout.exercises![i].reps![j] : thisExerciseSetStat.repStats.length,
						reps_completed: thisExerciseSetStat.manualEntryReps,
						weight_units: thisExerciseSetStat.isKG ? "KGs" : "LBS",
						rep_stats: repStatsTemp,
						start_time: thisExerciseSetStat.startUnixTime,
						end_time: thisExerciseSetStat.endUnixTime,
						exercise: {
							series_id: `${seriesTitle}${j + 1}`,
							id: thisExerciseID,
							title: thisExerciseTitle
						}
					}

					if (thisExerciseSetStat.tempo !== undefined) {
						thisSetStatObj['tempo'] = thisExerciseSetStat.tempo;
					}
					setStatsTemp.push(thisSetStatObj);
					statCount += 1;
				}


				let thisExerciseObj = {
					//id: thisExerciseID,
					set_number: i,
					exercise_set_stats: setStatsTemp
				}
			// // console.log(`EXERCISE ID: ${thisExerciseID}`);
			// // console.log(thisExerciseObj);
			// // console.log(currentWorkout.workout.exercises);
			// // console.log("_______________")
				exercisesTemp.push(thisExerciseObj);
			}

			var seriesObj = {
				title: seriesTitle,
				sets: exercisesTemp
			};

			exerciseSeriesTemp.push(seriesObj);
		}

		/*
		for (var i = 0; i < currentWorkout.setStats.length; i++) {
			let thisExerciseStats: SetStats_t[] = currentWorkout.setStats[i];
			var thisExerciseID =  currentWorkout.workout.exercises![i] !== undefined ? currentWorkout.workout.exercises![i].id : "";

			var setStatsTemp: any[] = [];
			for (var j = 0; j < thisExerciseStats.length; j++) {
				let thisSetStat: SetStats_t = thisExerciseStats[j];
				var repStatsTemp: RepStats_t[] = [];
				if (thisSetStat.repStats.length > 0) {
					for (var k = 0; k < thisSetStat.repStats.length; k++) {
						let thisRepStat = thisSetStat.repStats[k];
						let thisRepObj = thisRepStat;
						repStatsTemp.push(thisRepObj);
					}
				}
				let thisSetStatObj = {
					ROM: thisSetStat.averageROM,
					power: thisSetStat.averagePower,
					form_accuracy: thisSetStat.averageFormAccuracy,
					restTime: thisSetStat.restTime,
					weight: thisSetStat.weight,
					reps_anticipated: currentWorkout.workout.exercises![i] !== undefined ? currentWorkout.workout.exercises![i].reps![j] : thisSetStat.repStats.length,
					reps_completed: thisSetStat.repStats.length,
					weight_units: currentWorkout.isKG ? "KGs" : "LBS",
					rep_stats: repStatsTemp
				}
				setStatsTemp.push(thisSetStatObj);
			}


			let thisExerciseObj = {
				id: thisExerciseID,
				set_stats: setStatsTemp
			}
			// console.log(`EXERCISE ID: ${thisExerciseID}`);
			// console.log(thisExerciseObj);
			// console.log(currentWorkout.workout.exercises);
			// console.log("_______________")
			exercisesTemp.push(thisExerciseObj);
		}

		*/

		Mixpanel.track("Workout completed",{num_set_stats: statCount});
		Mixpanel.people.increment("Logged workouts");

		var user = firebase.auth().currentUser;
		let userID = user ? user.uid : 'anon';

		let logObject = {
			workout_id: currentWorkout.workout.id,
			start_date: startDateString,
			completed_date: endDateString,
			exercise_series: exerciseSeriesTemp,
			trainer: userID
		};
		// console.log(logObject);

		let db = firebase.database();
		let dbLogRef = db.ref(`workout_logs/${currentWorkout.client.id}`);
		dbLogRef.push(logObject)
		.then(res => {
			let postKey = res.key;
			// console.log(`Posted with Key: ${postKey}`);
			// Take log ID and post to list of trainer's logs
			let hasUpdatedTrainerLog = false;

			// console.log(`Configuring for trianer ${userID} and client ${currentWorkout.client.id}`)
			let trainerLogRef = db.ref(`personal_trainer_clients/${userID}/clients/${currentWorkout.client.id}`);
			trainerLogRef.on('value', function(snapshot) {
			    if (snapshot.exists() === true) {
			        let data = JSON.parse(JSON.stringify(snapshot.val()));
			        data.workouts_together = data.workouts_together + 1;
			        data.last_workout_date = `${y_end}-${mo_end}-${d_end}`;
			        let tempWorkouts = data.workout_history !== undefined && data.workout_history !== null ? data.workout_history : [];
			        tempWorkouts.push({
			        	workout_id: currentWorkout.workout.id,
			        	log_id: postKey,
			        	log_ref: `workout_logs/${currentWorkout.client.id}/${postKey}`,
			        	workout_name: currentWorkout.workout.title,
			        	start_date: startDateString,
			        	end_date: endDateString
			        });
			        data.workout_history = tempWorkouts;
			        // console.log(data);
			        if (hasUpdatedTrainerLog === false) {
			        	hasUpdatedTrainerLog = true;
			        	trainerLogRef.set(data);
			        }
			    } else {
			    	let obj = {
			    		date_added: `${y_end}-${m_end}-${d_end}`,
			    		full_name: currentWorkout.client.full_name,
			    		last_workout_date: `${y_end}-${m_end}-${d_end}`,
			    		workouts_together: 1,
			    		workout_history: {
			    			workout_id: currentWorkout.workout.id,
			        		log_id: postKey,
			        		log_ref: `workout_logs/${currentWorkout.client.id}/${postKey}`,
			        		workout_name: currentWorkout.workout.title,
			        		start_date: startDateString,
			        		end_date: endDateString
			    		}
			    	}
			    	trainerLogRef.set(obj);
			    }
			});

		})

		// incriment times_complete in workout db ref
		var workoutHasUpdated = false;
		let workoutRef = db.ref(`workouts/${currentWorkout.workout.id}`);
		workoutRef.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let data = JSON.parse(JSON.stringify(snapshot.val()));
				let timesComplete = data.times_complete !== null && data.times_complete !== undefined ? data.times_complete : 0;
				data.times_complete = timesComplete + 1;
				// console.log("WILL WRITE WORKOUT:");
				// console.log(data)
				if (workoutHasUpdated === false) {
					workoutHasUpdated = true;
					workoutRef.set(data);
				}
			}
		});
	}

	function finishedViewingStats() {
		setSelectedClient(undefined);
		setSelectedWorkout(undefined);

		setPageIndex(0);
		history.push('/dashboard/home');
	}

	function resumeWorkout() {
		setPageIndex(6);
		history.push('/dashboard/intraworkout');
	}

	function updateUnits(isKG: boolean) {
		if (currentWorkout === undefined) { return; }
		setCurrentWorkout({...currentWorkout, isKG: isKG});
	}

	function openNodesPage() {
		setPageIndex(3);
		pageChanged(3);
		setShowingSettings(false);
	}

	function goToLibrary() {
		setPageIndex(1);
		pageChanged(1);
		setSubPageIndex(3);
		hideModalPressed();
	}





	function showClientPressed(forID: string) {

		// console.log(`Dashboard: showClientPressed for ID: ${forID}`)

		let database = firebase.database();
		let ref = database.ref(`user_profile/${forID}`) // TODO: update ref to `personal_trainer_clients/${currentUser.uid}`
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let thisUser = snapshot.val();
				//// console.log(snapshot.val());
				let newClient: Client_t = {
					first_name:thisUser.first_name,
					last_name:thisUser.last_name,
					full_name:thisUser.full_name,
					id: forID,
					gender: thisUser.gender,
					email: thisUser.email,
					weight: thisUser.weight,
					height: thisUser.height,
					isMetric: thisUser.units_kg,
					birthdate: thisUser.birth_date,
					tags: ["Gain Muscle"],
					isSelected: false
				};

				setSelectedClient(newClient);
				history.push('/dashboard/client-profile');
			} else {
				// console.log(`NOTHING FOUND FOR ${forID}`);
			}
		});
	}

	function openFileUploadModal(e: any) {
		setModalData(e);
		setShowImageUploadModal(true);
	}

	function editGoalPressed(e: Goal_t) {
		let dataForModal = {
			isEditing: true,
			goal: e,
			client: selectedClient
		}
		setModalData(dataForModal);
		setShowEditGoalModal(true);
	}

	function logoutPressed() {
		firebase.auth().signOut().then(function() {
			// Sign-out successful.
			history.push('/');
		}).catch(function(error) {
			// An error happened.
			// console.log(error);
		});
	}


	return (
		<div className="dashboard">
			<MetaTags>
				<title>Eigen Fitness</title>
				<meta name="theme-color" content="#161719" media="(prefers-color-scheme: light)"/>
				<meta name="theme-color" content="#161719" media="(prefers-color-scheme: dark)"/>
			</MetaTags>
			<CSSTransition in={showModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<Modal
						profile={profile}
						reason={modalType}
						information={modalData}
						currentWorkout={currentWorkout}
						hideModalPressed={() => hideModalPressed()}
						goToLibrary={() => goToLibrary()}
						getUserConfirmation={(e: any) => getUserConfirmation(e)}
						createNewLoadingPattern={(e?: number[]) => createNewLoadingPattern(e)}
						addNewExerciseToWorkoutModalPressed={(data: any) => addNewExerciseToWorkoutModalPressed(data)}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showConfirmModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<ConfirmModal
						reason={modalType}
						information={confirmModalData}
						hideModalPressed={() => hideConfirmModal()}/>
				</div>
			</CSSTransition>

			<CSSTransition in={showFeedbackModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<FeedbackModal
						reason={modalType}
						information={confirmModalData}
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showNewElementModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<NewElementModal
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showNewProgramModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<NewProgramModal
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showTrainerModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<AddTrainerModal
						reason={modalType}
						information={confirmModalData}
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showCurrentExerciseModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<CurrentExerciseModal
						currentWorkout={currentWorkout}
						completeSet={() => completeSet()}
						hideModalPressed={() => hideModalPressed()}
						updateWeight={(e: number) => updateWeight(e)}
						nodeManager={nodeManager}
						mlCore={mlcore}
						changeReps={(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => changeReps(toReps, forSeriesIndex, forExerciseIndex, setIndex)}
						changeTempo={(toTempo: Tempo_t, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => changeTempo(toTempo, forSeriesIndex, forExerciseIndex, setIndex)}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showContactModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<ContactModal
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showImageUploadModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<UploadImageModal
						information={modalData}
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showEditGoalModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<EditGoalModal
						information={modalData}
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showAssignModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<AssignModal
						information={modalData}
						showExerciseModal={ (c: any, bi: number, d?: any) => configureExerciseForAssign(c, bi, d) }
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showAddExerciseToWorkoutModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<AddExerciseToWorkoutModal
						information={modalData}
						saveLoadingPatternPressed={(r: number[]) => createNewLoadingPattern(r)}
						hideModalPressed={() => hideExerciseModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showCopyFolderModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<CopyFolderModal
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showCopyFolderToClientModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<CopyFolderToClient
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showDuplicatePhaseModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<DuplicatePhase
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showRecordMessageModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<RecordMessageModal
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={ showScheduleMessagesModal } timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<ScheduleMessagesModal
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>

			<CSSTransition in={showNewAppointmentModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<NewAppointmentModal
						information={modalData}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showNewLoadingPatternModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<NewLoadingPatternModal
						information={modalData}
						closeModal={() => hideLoadingPatternModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showPairModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container dashboard-modal-container-confirm">
					<PairNodesModal
						information={modalData}
						nodeManager={nodeManager}
						discoveredNodesList={discoveredNodesList}
						myNodesList={myNodesList}
						closeModal={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showNewLocationModal} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<NewLocationModal
						information={modalData}
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>
			<CSSTransition in={showPerformanceAnalysis} timeout={200} classNames="fade" unmountOnExit>
				<div className="dashboard-modal-container">
					<PerformanceAnalysis
						information={modalData}
						hideModalPressed={() => hideModalPressed()}/>
				</div>
			</CSSTransition>

			


			<div hidden={showingSettings} className="dashboard-menu-container">
				<DashboardMenu
					showWorkoutSession={inWorkoutSession}
					workoutSessionName={selectedWorkout === undefined ? undefined : selectedWorkout.title}
					workoutSessionClient={selectedClient  === undefined ? undefined : selectedClient.first_name}
					profile={profile}
					pagesChanged={(e: number) => pageChanged(e)}
					selectedIndex={pageIndex}
					profilePressed={() => profilePressed()}
					resumeWorkout={() => resumeWorkout()}
					contactCardPressed={() => contactCardPressed()}/>
			</div>
			<div className={`dashboard-content-container ${inWorkoutSession ? "dashboard-content-container-dark" : ""} ${showingSettings === true ? 'dashboard-content-container-full-width' : ''}`}>
				<div className={`dashboard-content-container-back-highlight ${inWorkoutSession ? "dashboard-content-container-back-highlight-dark" : ""}`}/>
				<div className="switch-container">
					<Switch>
						<Route exact path={`/dashboard/settings`}>
							<SettingsMain
								backFromSettings={() => backFromSettings()}
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								profile={profile}
								logout={() => logoutPressed()}/>
						</Route>
						<Route exact path={`/dashboard/profile`}>
							<Profile
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								profile={profile}
								logout={() => logoutPressed()}/>
						</Route>
						<Route exact path={`/dashboard/schedule`}>
							<ScheduleMain
								startNewWorkout={() => openClientInDynamicTrain("")}
								profile={profile}
								clientPressed={(e: string) => showClientPressed(e)}
								workoutSelected={(e: Workout_t) => showWorkoutProfile(e)}
								addNewClient={() => createNewClient()}
								addNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								createNewWorkout={(e: any) => createNewWorkout(e)}
								addNewAppointmentPressed={(e: any) => addNewAppointmentPressed(e)}
								createPhasePressedWithWorkouts={(w: Workout_t[]) => createPhasePressedWithWorkouts(w)}
								editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => editWorkoutFromSchedule(workout, calendarDay, client)}/>
						</Route>
						<Route exact path={`/dashboard/home`}>
							<Home
								startNewWorkout={() => startNewWorkout()}
								profile={profile}
								clientPressed={(e: string) => showClientPressed(e)}
								workoutSelected={(e: Workout_t) => showWorkoutProfile(e)}
								nodeManager={nodeManager}
								mlCore={mlcore}
								inviteCoach={(e: any) => addNewTrainerPressed(e)}
								openPrograms={() => pageChanged(2)}
								orderNodes={() => pageChanged(6)}
								openSchedule={() => pageChanged(1)}
								addNewClient={() => createNewClient()}
								addNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								openClientInDynamicTrain={(c: string) => openClientInDynamicTrain(c)}
								addNewAppointmentPressed={(e: any) => addNewAppointmentPressed(e)}/>
						</Route>
						<Route exact path={`/dashboard/workouts`}>
							<Workouts
								subPageIndex={subPageIndex}
								addNewExerciseToWorkoutModalPressed={(data: any) => addNewExerciseToWorkoutModalPressed(data)}
								newPlanPressed={() => createNewPlan()}
								startNewWorkout={() => startNewWorkout()}
								showWorkoutProfile = {(e: Workout_t) => showWorkoutProfile(e)}
								createNewWorkout={() => createNewWorkout()}
								createNewClient = {() => createNewClient()}
								addNewExercise={() => addNewExercise()}
								assignClientToPlan={(e: Plan_t) => assignClientToPlan(e)}
								assignClientToFolder={(e: Folder_t) => assignClientToFolder(e)}
								copyFolderToClientPressed={(e: any) => copyFolderToClientPressed(e)}
								addNewWorkoutPlan={(folder: Folder_t) => createNewPlanWithFolder(folder)}
								createNewPlanWithFolderAndCallback={(folder: Folder_t, callback?: any) => createNewPlanWithFolderAndCallback(folder, callback)}
								duplicatePlanPressed={(p: Plan_t) => duplicatePlanPressed(p)}
								editPlanPressed={(p: Plan_t) => editPlanPressed(p)}
								editRestDayPressed={(p: Plan_t, i: number) => editRestDayPressed(p, i)}
								deleteCyclePressed={(a: any) => getUserConfirmation(a)}
								addElementPressed={(a: any) => addNewElementPressed(a)}
								addProgramPressed={(a: any) => addNewProgramPressed(a)}
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								addNewWorkoutToPlan={(p: Plan_t) => addNewWorkoutToPlan(p)}
								createNewLoadingPattern={() => createNewLoadingPattern()}/>
						</Route>
						<Route exact path={`/dashboard/workout-profile`}>
							<WorkoutProfile
								workout={selectedWorkout}
								startWorkoutWithWorkout={(e: Workout_t) => startWorkoutWithWorkout(e)}
								startNewWorkout={() => openClientInDynamicTrain("")}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewExercise={() => addNewExercise()}
								addNewWorkoutPlan={() => createNewPlan()}/>
						</Route>
						<Route exact path={`/dashboard/clients`}>
							<Clients
								showClientProfile = {(e: Client_t) => showClientProfile(e)}
								startNewWorkout={() => openClientInDynamicTrain("")}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								moreInfoSelected={(e: Client_t) => moreInfoSelected(e)}/>
						</Route>
						<Route exact path={`/dashboard/client-profile`}>
							<ClientProfile
								startWorkoutWithClient={(e: Client_t) => startWorkoutWithClient(e)}
								addNotePressed={() => openNewNoteModal()}
								client={selectedClient}
								openClientInDynamicTrain={(c: string) => openClientInDynamicTrain(c)}
								addGoalPressed={() => addGoalPressed()}
								logMeasurementPressed={() => logMeasurementPressed()}
								startNewWorkout={() => startWorkoutWithClient(selectedClient)}
								createNewClient = {() => createNewClient()}
								createNewWorkout={(e?: any) => createNewWorkout(e)}
								addNewExercise={() => addNewExercise()}
								addNewWorkoutPlan={() => createNewPlan()}
								updateProfileImage={(e: any) => openFileUploadModal(e)}
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								editGoal={(e: Goal_t) => editGoalPressed(e)}
								editRestDayPressed={(p: Plan_t, i: number, client: Client_t) => editRestDayPressed(p, i, client)}
								editPlanPressed={(p: Plan_t, client_id: string) => editPlanPressed(p, client_id)}
								addNewWorkoutToPlan={(p: Plan_t) => addNewWorkoutToPlan(p)}
								assignClientToPlan={(plan: Plan_t, client: Client_t) => assignClientToPlan(plan, client)}
								assignClientToFolder={(folder: Folder_t, client: Client_t) => assignClientToFolder(folder, client)}
								addElementPressed={(e: any) => addNewElementPressed(e)}
								newPlanPressed={(folder: Folder_t, client_id: string) => createNewPlanWithFolder(folder, client_id)}
								copyFolderPressed={(e: any) => copyFolderPressed(e)}
								deleteCyclePressed={(a: any) => getUserConfirmation(a)}
								createPhasePressedWithWorkouts={(w: Workout_t[]) => createPhasePressedWithWorkouts(w)}
                    			addNewAppointmentPressed={(e: any) => addNewAppointmentPressed(e)}
                    			editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => editWorkoutFromSchedule(workout, calendarDay, client)}/>
						</Route>
						<Route exact path={`/dashboard/messages`}>
							<Messages
								startNewWorkout={() => openClientInDynamicTrain("")}
								openVideoRecord={(cb: any, c: Client_t) => openVideoRecord(cb, c)}
								openClientInDynamicTrain={(c: string) => openClientInDynamicTrain(c)}
								openScheduleMessages={ (c: string) => openScheduleMessages(c) }
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}/>
						</Route>
						<Route exact path={`/dashboard/start-new-workout`}>
							<NewWorkoutWorkflow
								client={selectedClient}
								nodeManager={nodeManager}
								workout={selectedWorkout}
								backPressed={() => backToMainFromStartNewWorkout()}
								beginWorkout={(w: Workout_t, c: Client_t, u: boolean) => beginWorkout(w,c,u)}
								openNodesPage={() => openNodesPage()}
								addNewWorkoutPlan={() => createNewPlan()}/>
						</Route>
						<Route exact path={`/dashboard/selectWorkout`}>
							<SelectWorkout/>
						</Route>
						<Route exact path={`/dashboard/my-nodes`}>
							<MyNodes
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								nodeManager={nodeManager}
								dataList={nodeMotionData}
								myNodesList={myNodesList}
								discoveredNodesList={discoveredNodesList}
								beginDataStream={() => beginDataStream()}
								endDataStream={() => endDataStream()}
								startNewWorkout={() => startNewWorkout()}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}
								discoverNodesPressed={() => discoverNodesPressedFromMyNodes()}/>
						</Route>
						<Route exact path={`/dashboard/discover-nodes`}>
							<DiscoverNodes
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								nodeManager={nodeManager}
								dataList={nodeMotionData}
								myNodesList={myNodesList}
								discoveredNodesList={discoveredNodesList}
								beginDataStream={() => beginDataStream()}
								endDataStream={() => endDataStream()}
								startNewWorkout={() => startNewWorkout()}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}/>
						</Route>
						<Route exact path={`/dashboard/order-nodes`}>
							<OrderNodes
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								nodeManager={nodeManager}
								dataList={nodeMotionData}
								myNodesList={myNodesList}
								discoveredNodesList={discoveredNodesList}
								startNewWorkout={() => startNewWorkout()}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}/>
						</Route>
						<Route exact path={`/dashboard/trainers`}>
							<Trainers
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								addNewTrainerPressed={(e: any) => addNewTrainerPressed(e)}
								clientSelected={(e: Client_t) => showClientProfile(e)}
								profile={profile}
								startNewWorkout={() => openClientInDynamicTrain("")}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}/>
						</Route>
						<Route exact path={`/dashboard/my-studio`}>
							<MyStudio
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								profile={profile}
								addLocationPressed={() => addLocationPressed()}
	                            editLocationPressed={(l: Location_t) => editLocationPressed(l)}
								startNewWorkout={() => openClientInDynamicTrain("")}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}/>
						</Route>

						<Route exact path={`/dashboard/my-coaching-profile`}>
							<MyCoachingProfile
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								profile={profile}
								startNewWorkout={() => openClientInDynamicTrain("")}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}/>
						</Route>


						<Route exact path={`/dashboard/nodeTest`}>
							<NodeTest/>
						</Route>
						<Route exact path={`/dashboard/help`}>
							<HelpAndFeedback
								contactCardPressed={() => contactCardPressed()}
								profile={profile}
								startNewWorkout={() => openClientInDynamicTrain("")}
								createNewClient = {() => createNewClient()}
								createNewWorkout={() => createNewWorkout()}
								addNewWorkoutPlan={() => createNewPlan()}
								addNewExercise={() => addNewExercise()}
								logout={() => logoutPressed()}/>
						</Route>

						<Route exact path={`/dashboard/intraworkout`}>
							<IntraWorkout
								getUserConfirmation={(e: any) => getUserConfirmation(e)}
								updateUnits={(e: boolean) => updateUnits(e)}
								updateWeight={(e: number) => updateWeight(e)}
								completeSet={() => completeSet()}
								startSet={() => startSet()}
								nodeManager={nodeManager}
								mlCore={mlcore}
								currentWorkout={currentWorkout}
								client={selectedClient}
								workout={selectedWorkout}
								addNotePressed={() => openNewNoteModal()}
								openNodesPage={() => openNodesPage()}
								configureWorkingMaxHistory={(e: Exercise_t, c: Client_t) => configureWorkingMaxHistory(e,c)}
								changeReps={(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => changeReps(toReps, forSeriesIndex, forExerciseIndex, setIndex)}
								changeWeight={(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => changeWeight(toReps, forSeriesIndex, forExerciseIndex, setIndex)}
								changeTempo={(toReps: Tempo_t, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => changeTempo(toReps, forSeriesIndex, forExerciseIndex, setIndex)}
								changeRest={(toReps: string, forSeriesIndex: number, forExerciseIndex: number, setIndex: number) => changeRest(toReps, forSeriesIndex, forExerciseIndex, setIndex)}
								endWorkout={() => endWorkout()}/>
						</Route>
						<Route exact path={`/dashboard/workoutSummary`}>
							<WorkoutSummary
								currentWorkout={currentWorkout}
								done={() => finishedViewingStats()}/>
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	)
}

export default Dashboard;
