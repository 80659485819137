import React from 'react';
import firebase from '../../../Firebase';

const getDatabaseDirect = async (
		location: string
	) => {
    
    return new Promise<any>( async function(resolve, reject) {
    	
    	if (!location || location === "") reject(`API/getDatabaseDirect: Provide a valid location string. <${location}>`)

    	try {
    		// Get the logged in user
    		let database = firebase.database();
	        let ref = database.ref(location);
	        ref.once('value', function(snapshot) {
	            if (snapshot.exists() === true) {
	                let thisObj = snapshot.val();
	                resolve(thisObj);
	            } else {
	                reject(`API/getDatabaseDirect: No data found at the give location. <${location}>`)
	            }
	        });
			
    	} catch (err) {
    		console.log("ERROR (API/getDatabaseDirect)", err);
    		reject(err);
    	}
	});
}

export default getDatabaseDirect;