import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import Mixpanel from '../../Mixpanel';
import axios from 'axios';

import './NewWorkoutModal.css';
import './ConfirmModal.css';
import './ScheduleMessagesModal.css';

// Import components
import ScheduledMessagesRow from '../ScheduledMessages/ScheduledMessagesRow';


// Import API helpers
import getClientName from '../../api/client/getClientName';
import createScheduledMessage from '../../api/scheduledMessages/createScheduledMessage';

// UI icon imports
import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Autorenew from '@material-ui/icons/Autorenew';


function ScheduleMessagesModal(props: any) {

	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [selectedView, setSelectedView] = useState<"event" | "scheduled_single" | "scheduled_recurring">("scheduled_single")

	const [clientID, setClientID] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [clientInitials, setClientInitials] = useState("");
    const [clientName, setClientName] = useState("");

    const [scheduledMessages, setScheduledMessages] = useState<any[]>([])

	useEffect(() => {

		// TODO: remove
		if (props.information && props.information.client_id && props.information.client_id !== "") {
			setClientID(props.information.client_id);
		} else {
			setClientID("IDMl0c4MQbacldKQPN7D4Vq0eNz1");
		}
		
		return () => {

		}
	}, [])

	useEffect(() => {
		if (clientID && clientID !== "") {
			configureUser(clientID);
		}
	}, [clientID])

	const configureUser = async (withUID: string) => {

		setIsLoading(true);
		getImage(withUID);

		try {
            let nameData: { full_name: string, first_name: string, last_name: string, initials: string } = await getClientName(withUID);
            setClientName(nameData.full_name);
            setClientInitials(nameData.initials);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
	}

	const getImage = (withUID: string) => {
        if (!withUID || withUID === "") { return; }

        const storage = firebase.storage();
        storage.ref('profileImages').child(withUID).child('profileImage/image.jpg').getDownloadURL()
        .then(fireBaseUrl => {
            setImgUrl(fireBaseUrl);
        })
        .catch(error => {
            
        })
    }

    async function createMessage() {
    	if (!clientID || clientID === "") return;
    	try {
    		let targetTS = Date.now() + (7 * 24 * 3600 * 1000);
            let messageKey = await createScheduledMessage(clientID, selectedView, targetTS);
            console.log("KEY:", messageKey);
            setScheduledMessages(scheduledMessages.concat({ id: messageKey }));
        } catch (err) {
            
        }

    	
    }

	function closePressed() {
		props.closeModal();
	}

	function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'note':
                
                break;
            default:
                break;
        }
    }

    function submit() {
    	props.closeModal();
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            submit()
        }
    }

	return (
		<div className="modal-background schedule-messages-modal-background">
			<div className="schedule-messages-modal">
				{
					isLoading ? 
					<div className="schedule-messages-modal-wrapper schedule-messages-modal-wrapper-loading">
						<div className="create-workout-exercise-configure-loading-spinner"/>
						<p>Loading...</p>
					</div>
					:
					<div className="schedule-messages-modal-wrapper">
						<div className="schedule-messages-modal-header">
							<div className="schedule-messages-modal-header-title-container">
								<div className={ "schedule-messages-modal-header-title-automated-circle-container" }>
									<Autorenew className="schedule-messages-modal-header-title-automated-circle-icon"/>
								</div>
								<div className={ "schedule-messages-modal-header-title-automated-line" }></div>
				                <div className="schedule-messages-modal-header-title-image-container">
					                {
			                            imgUrl === "" ?
			                            <h4>{ clientInitials }</h4>
			                            :
			                            <img className="" src={imgUrl}/>
			                        }
					            </div>
					            <div className={ "schedule-messages-modal-header-title" }>
					            	<h4>Automate Messages for { clientName }</h4>
					            </div>
				            </div>
			                <div className="schedule-messages-modal-header-save-container">
				                <div className="schedule-messages-modal-header-save-button" onClick={ () => submit() }>
					                <p>Finish & Save</p>
					            </div>
				            </div>
			            </div>
			            <div className="schedule-messages-modal-body">
			            	<div className="schedule-messages-modal-body-info-container">
			            		<p>Save time by setting up Automated Messages to automatically send to your client in the future.</p>
			            	</div>
			            	<div className="schedule-messages-modal-body-options-container">
				            	<div className={ `schedule-messages-modal-body-option ${ selectedView === "scheduled_single" ? "schedule-messages-modal-body-option-selected" : "" }` }
				            	     onClick={ () => setSelectedView("scheduled_single") }>
					            	<p>Scheduled</p>
					            </div>
					            <div className={ `schedule-messages-modal-body-option ${ selectedView === "scheduled_recurring" ? "schedule-messages-modal-body-option-selected" : "" }` }
				            	     onClick={ () => setSelectedView("scheduled_recurring") }>
					            	<p>Recurring</p>
					            </div>
					            <div className={ `schedule-messages-modal-body-option ${ selectedView === "event" ? "schedule-messages-modal-body-option-selected" : "" }` }
				            	     onClick={ () => setSelectedView("event") }>
					            	<p>Event Triggered</p>
					            </div>
				            </div>
				            <div className={ "schedule-messages-modal-body-content" }>
				            	{
				            		scheduledMessages.length === 0 ?
				            		<div className={ "schedule-messages-modal-body-content-wrapper schedule-messages-modal-body-content-wrapper-empty" }>
				            			<div className={ "schedule-messages-modal-body-content-empty-header" }>
				            				<p>No Automated Messages</p>
				            			</div>
				            			<div className={ "schedule-messages-modal-body-content-empty-button-container" }>
				            				<div className={ "schedule-messages-modal-body-content-empty-button" } onClick={ () => createMessage() }>
				            					<p>Create Message</p>
				            				</div>
				            			</div>
				            		</div>
				            		:
				            		<div className={ "schedule-messages-modal-body-content-wrapper" }>
				            			{
				            				scheduledMessages.map((item: any, index: number) => (
				            					<ScheduledMessagesRow 
				            						id={ item.id ?? "" }
				            						clientID={ clientID }/>
				            				))
				            			}
				            		</div>
				            	}
				            </div>
			            </div>
					</div>
				}
				
				{/*<div onClick={() => closePressed()} className="new-element-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>*/}
				
			</div>
		</div>
	)
}

export default ScheduleMessagesModal;

