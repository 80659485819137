import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './CopyFolderModal.css';

import Folder_t from '../../Interfaces/Folder_t';

import PlanFolderSelector from '../Base/PlanFolderSelector';


import '@material-ui/core';
import Close from '@material-ui/icons/Close';

function CopyFolderModal(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [copyingFolder, setCopyingFolder] = useState<Folder_t | undefined>();
    const [selectedFolder, setSelectedFolder] = useState<Folder_t | undefined>();
    const [errorString, setErrorString] = useState("");

    let initFolder: Folder_t = {
            title: "My Folder",
            id: "folder1",
            path: "folder1",
            type: "folder",
            children: [],
            isPlan: false
        };

    const [rootFolder, setRootFolder] = useState<Folder_t>(initFolder);

    const [workflowState, setWorkflowState] = useState(0);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.information !== undefined && props.information.parent !== undefined) {
            setCopyingFolder(props.information.parent as Folder_t);
        }
    }

    useEffect(() => {
        if (props.information !== undefined && props.information.parent !== undefined) {
            setCopyingFolder(props.information.parent as Folder_t);
        }
    }, [props.information])

    function closePressed() {
        props.closeModal();
    }

    function folderSelected(f: Folder_t) {
        setSelectedFolder(f);
        setErrorString("");
        // console.log("WILL COPY TO:", f.path, f, );
    }

    async function submit() {

        
        // console.log(selectedFolder, copyingFolder, props.information.client.id);

        if (selectedFolder === undefined || copyingFolder === undefined || copyingFolder.path === undefined || props.information === undefined || props.information.client === undefined || props.information.client.id === undefined) { return; }
        let clientID = props.information.client.id;

        setWorkflowState(1);

        let newMasterPath = selectedFolder.path;
        let folderExists = await checkIfLocationExists(newMasterPath, copyingFolder.id);

        if (folderExists === true) {
            setWorkflowState(0);
            setErrorString("This folder has already been copied here! Please choose another location.");
            return;
        }

        let seperatedPath: string[] = copyingFolder.path.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);
        

        let database = firebase.database();
        let ref = database.ref(`workout_plans/client_assigned_plans/${clientID}/folders${composedMasterPath_DB}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                // console.log("DATA:", data);

                let newMasterPath_withKey = `${newMasterPath}/${copyingFolder.id}`;

                let newChildList: any[] = getFolderChildArray(data, newMasterPath_withKey);

                // console.log("NEW LIST:", newChildList);

                let masterFolder: any = {
                    name: copyingFolder.title,
                    path: newMasterPath_withKey,
                    type: "folder",
                    children: newChildList
                };

                addFormattedFoldersToDB(masterFolder, newMasterPath, copyingFolder.id);
            }
        });

    }

    function getFolderChildArray(data: any, thisParentPath: string) {
        let dataKeys: any[] = Object.keys(data);

        var fullChildList: any = {};
        for (var i = 0; i < dataKeys.length; i++) {
            let thisKey = dataKeys[i];
            let thisObj = data[thisKey];

            let thisPath = `${thisParentPath}/${thisKey}`; //`${thisParentPath}${thisObj.path}`;
            //let thisPathPropogation = `${thisParentPath}/${thisKey}`;

            var childrenList: any = [];
            if (thisObj.children !== undefined) {
                childrenList = getFolderChildArray(thisObj.children, thisPath);
            }

            let newObj: any = JSON.parse(JSON.stringify(thisObj));
            newObj["path"] = thisPath;
            newObj["children"] = childrenList;

            if (newObj.template_id !== undefined) {
                newObj["plan_id"] = newObj.template_id;
            }

            fullChildList[thisKey] = newObj;
        }

        return fullChildList;
    }

    async function checkIfLocationExists(withPath: string, folderID: string) {
        return new Promise<boolean>(resolve => {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { return; }
            let trainerUID = currentUser.uid;

            let seperatedPath: string[] = withPath.split("/");
            // console.log("seperatedPath", seperatedPath);

            var composedMasterPath_DB = "";
            var composedMasterPath_raw = "";
            for (var i = 0; i < seperatedPath.length; i++) {
                let thisElement: string = seperatedPath[i];
                if (thisElement !== undefined && thisElement !== "") {
                    composedMasterPath_DB += `/${thisElement}/children`;
                    composedMasterPath_raw += `/${thisElement}`;
                }
            }

            composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
            composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
            // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);

            let finalPath = `workout_plans/trainer_plans/${trainerUID}/folders_root${composedMasterPath_DB}/${folderID}`;
            let database = firebase.database();
            let ref = database.ref(finalPath);
            ref.once('value', function(snapshot) {
                resolve(snapshot.exists())
            });
        })
        
    }

    async function addFormattedFoldersToDB(data: any, withPath: string, folderID: string) {

        // console.log("addFormattedFoldersToDB", data, withPath, folderID);
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let seperatedPath: string[] = withPath.split("/");
        // console.log("seperatedPath", seperatedPath);

        var composedMasterPath_DB = "";
        var composedMasterPath_raw = "";
        for (var i = 0; i < seperatedPath.length; i++) {
            let thisElement: string = seperatedPath[i];
            if (thisElement !== undefined && thisElement !== "") {
                composedMasterPath_DB += `/${thisElement}/children`;
                composedMasterPath_raw += `/${thisElement}`;
            }
        }

        composedMasterPath_DB = composedMasterPath_DB === "" ? "/" : composedMasterPath_DB;
        composedMasterPath_raw = composedMasterPath_raw === "" ? "/" : composedMasterPath_raw;
        // console.log("composedMasterPath = ", composedMasterPath_DB, composedMasterPath_raw);

        let finalPath = `workout_plans/trainer_plans/${trainerUID}/folders_root${composedMasterPath_DB}/${folderID}`;
        // console.log("FINAL PATH:", finalPath);

        let database = firebase.database();
        let ref = database.ref(finalPath);
        await ref.set(data);

        setWorkflowState(2);
    }



	return (
		<div className="modal-background copy-folder-modal-background">
			<div className="new-element-modal-container copy-folder-modal-container">
                <div onClick={() => closePressed()} className="new-element-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
                <div className="new-element-modal-top copy-folder-modal-top">
                    <h3>Copy "{copyingFolder === undefined ? "this Folder" : copyingFolder.title}" to My Folders</h3>
                    <p>Select a location in My Folders for "{copyingFolder === undefined ? "this Folder" : copyingFolder.title}" to be copied into</p>
                    <div hidden={errorString === ""} className="copy-folder-modal-top-error">
                        <p>{errorString}</p>
                    </div>
                </div>
                <div hidden={workflowState !== 0} className="copy-folder-modal-folder-container">
                    <div className="copy-folder-modal-folder-container-inner">
                        <PlanFolderSelector 
                            isClientFolder={false}
                            removeCycleFolders={true}
                            clientID={props.client === undefined || props.client.id === undefined ? "" : props.client.id}
                            noCycleSelect={true}
                            incomingSelectedFolder={rootFolder}
                            folderSelected={(f: Folder_t) => folderSelected(f)}/>
                    </div>
                </div>
                <div hidden={workflowState !== 1} className="copy-folder-modal-folder-container">
                    <div className="copy-folder-modal-loading-container">
                        <div className="client-list-container-loading-spinner"/>
                        <p>Copying Folder...</p>
                    </div>
                </div>
                <div hidden={workflowState !== 2} className="copy-folder-modal-folder-container">
                    <div className="copy-folder-modal-complete-container">
                        <h4>Folder has been copied!</h4>
                        <p>{copyingFolder === undefined || copyingFolder.title === undefined || copyingFolder.title === "" ? "Your Client's Folder" : `"${copyingFolder.title}"`} has successfully been copied into your Folder structure.</p>
                        <div onClick={() => closePressed()} className={`copy-folder-modal-button copy-folder-modal-button-done`}>
                            <p>Done</p>
                        </div>
                    </div>
                </div>
                <div className="copy-folder-modal-button-container">
                    <div hidden={workflowState !== 0} onClick={() => submit()} className={`copy-folder-modal-button ${selectedFolder === undefined ? 'copy-folder-modal-button-unselected' : ''}`}>
                        <p>Copy Folder</p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default CopyFolderModal;