import React, { useState } from 'react';
import './BlankPage.css';


function BlankPage(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="blank-page-container">
			<div className="blank-page-content">
                <h4>{props.pageName} coming soon!</h4>
                <p>We're currently working on making the {props.pageName} page work seemlessly for you.<br/>Keep your eyes open for App Store updates and new version notification emails!</p>
            </div>
		</div>
	)
}

export default BlankPage;