import React, { useState } from 'react';
import './InfoHover.css';


function InfoHover(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="info-hover">
			<div className="info-hover-button">
                <p>i</p>
            </div>
            <div className={`info-hover-text-container ${props.isBottom !== undefined && props.isBottom === true ? 'info-hover-text-container-bottom' : ''}`}>
                <p>{props.text !== undefined ? props.text : ""}</p>
            </div>
		</div>
	)
}

export default InfoHover;