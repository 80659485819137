import React, { useState, useEffect, useRef } from 'react';
import firebase from '../../../Firebase';
import {Line, Bar} from 'react-chartjs-2';
import { IonInput } from '@ionic/react';

import './NodeStreamAnalyzeAverageRep.css';


function NodeStreamAnalyzeAverageRep(props: any) {

    const containerRef = useRef<HTMLDivElement>(null);

    // Quaternion plotting
    let initDataSets_q = {
        labels: [],
        datasets: [
            {
                label: 'q_w',
                backgroundColor: 'rgba(255,255,255,0)',
                hoverBackgroundColor: `rgba(255,255,255,0.9)`,
                borderColor: 'rgba(255,255,255,1)',
                hoverBorderColor: `rgba(255,255,255,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_x', // Blue
                backgroundColor: 'rgba(73,89,193,0)',
                hoverBackgroundColor: `rgba(73,89,193,0.9)`,
                borderColor: 'rgba(73,89,193,1)',
                hoverBorderColor: `rgba(73,89,193,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_y',    // Red
                backgroundColor: 'rgba(193,89,73,0)',
                hoverBackgroundColor: `rgba(193,89,73,0.9)`,
                borderColor: 'rgba(193,89,73,1)',
                hoverBorderColor: `rgba(193,89,73,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            },
            {
                label: 'q_z', // Green
                backgroundColor: 'rgba(73,193,89,0)',
                hoverBackgroundColor: `rgba(73,193,89,0.9)`,
                borderColor: 'rgba(73,193,89,1)',
                hoverBorderColor: `rgba(73,193,89,1)`,
                borderWidth: 1,
                pointRadius: 2,
                type: 'line',
                data: []
            }
        ]
    }
    var chartOptions_q: any = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        title:{
            display:false,
            text:'Average Rainfall per month',
            fontSize:20
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            width: 300
        },
        scales:{
            yAxes: [{
                id: 'weight',
                display: true,
                gridLines: {
                    display: false
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#5F6C76',
                    beginAtZero: true,
                    stepSize: 0.25,
                    callback: function(value: any, index: any, values: any) {
                        return Math.floor(value) + ``;
                    },
                    min: -1,
                    max: 1
                },

            }],
            xAxes: [{
                display: true,
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: true
                },
                fontFamily: "'Avenir', 'Helvetica', 'Arial', sans-serif",
                ticks: {
                    fontColor: '#C1C4CC',
                    beginAtZero: true,
                    stepSize: 1
                }
            }]
        },
        legend:{
            display:false,
        }
    }

    const [initSet, setInitSet] = useState(false);
    const [exerciseTitle, setExerciseTitle] = useState("This Exercise");
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [lastRecordedTimestamp, setLastRecordedTimestamp] = useState(-1);
    const [validLength, setValidLength] = useState(0);
    const [dataSets_q, setDataSets_q] = useState(initDataSets_q);
    const [chartSettings_q, setChartSettings_q] = useState(chartOptions_q);

    const [showRemovingPage, setShowRemovingPage] = useState(false);

    const [all_q_streams, set_all_q_streams] = useState<{w:number,x:number,y:number,z:number}[][]>([]);
    const [viewingLogIndex, setViewingLogIndex] = useState(0);

    const [STD_z, setSTD_z] = useState(0.0);

    const [copyID, setCopyID] = useState("");
    const [copyPassword, setCopyPassword] = useState("");

    const [removeIndex_start, setRemoveIndex_start] = useState(0);
    const [removeIndex_end, setRemoveIndex_end] = useState(0);
    const [removePassword, setRemovePassword] = useState("");

    const [prepDelete, setPrepDelete] = useState(false);
    const [prepCopy, setPrepCopy] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            if (props.selectedExerciseSession && props.selectedExerciseSession.id && props.selectedExerciseSession.id !== "") {
                generateData(props.selectedExerciseSession.id, 2);
            }
        }
    }, [initSet])

    useEffect(() => {
        setDataSets_q(initDataSets_q);
        set_all_q_streams(all_q_streams.filter((item, index) => { return false; }))
        if (props.selectedExerciseSession && props.selectedExerciseSession.id && props.selectedExerciseSession.id !== "") {
            generateData(props.selectedExerciseSession.id, props.locationIndex);
        }
    }, [props.selectedExerciseSession, props.locationIndex])

    useEffect(() => {
        setPrepDelete(false);
    }, [showRemovingPage])

    const generateData = (forExerciseID: string, locationIndex: number) => {
        setIsLoading(true);
        setPrepDelete(false);
        setRemovePassword("");
        setShowRemovingPage(false);
        setViewingLogIndex(0);
        setIsDeleting(false);

        let database = firebase.database();
        let ref = database.ref(`ml_data/exercises/${forExerciseID}/locations/${locationIndex}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                parseRecordedExercises(data);
            } else {
                setIsLoading(false);
            }
        });

    }

    const parseRecordedExercises = (data: any) => {

        
        if (data.last_updated) setLastRecordedTimestamp(data.last_updated);

        var validDataList: number[][] = [];
        if (data.data && data.data.length > 0) {
            let thisDataArr: number[][] = data.data;
            for (var i = 0; i < thisDataArr.length; i++) {
                let thisDataLog = thisDataArr[i];
                if (thisDataLog && thisDataLog.length > 0) {
                    let thisDataLogPredictionIndex = thisDataLog[0];
                    if (thisDataLogPredictionIndex === 1) {
                        validDataList.push(thisDataLog)
                    }
                }
            }
        }

        setValidLength(validDataList.length);
        setRemoveIndex_end(validDataList.length);
        setRemoveIndex_start(validDataList.length - 20 < 0 ? 0 : validDataList.length - 20);

        // console.log("> NodeStreamAnalyzeAverageRep // validDataList:", validDataList.length, validDataList);

        var allQStreams: {w:number,x:number,y:number,z:number}[][] = [];
        for (var i = 0; i < validDataList.length; i++) {
            let thisEntryBytes = validDataList[i];
            var thisEntryQStream: {w:number,x:number,y:number,z:number}[] = [];
            var thisQ: {w:number,x:number,y:number,z:number} = { w: 0.0, x: 0.0, y: 0.0, z: 0.0 };
            for (var j = 1; j < thisEntryBytes.length; j++) {
                let thisByte = thisEntryBytes[j];
                let j_adj = j - 1;
                let thisEntryIndex = j_adj % 4;

                let thisByteScaled = ((thisByte / 255) * 2) - 1;
                
                switch (thisEntryIndex) {
                    case 0:
                        thisQ.w = thisByteScaled;
                        break;
                    case 1:
                        thisQ.x = thisByteScaled;
                        break;
                    case 2:
                        thisQ.y = thisByteScaled;
                        break;
                    case 3:
                        thisQ.z = thisByteScaled;
                        thisEntryQStream.push(thisQ);
                        thisQ = { w: 0.0, x: 0.0, y: 0.0, z: 0.0};
                        break;
                    default:
                        // console.log("switch (thisEntryIndex) ERROR:", thisEntryIndex);
                        break;
                }
            }

            allQStreams.push(thisEntryQStream);
        }

        // console.log("> NodeStreamAnalyzeAverageRep // allQStreams:", allQStreams.length, allQStreams);
        set_all_q_streams(allQStreams);

        // generate average quaternion streams
        var avgQStream: {w:number,x:number,y:number,z:number}[] = [];
        var standardDeviations: {w:number,x:number,y:number,z:number}[] = [];
        let windowLength = 112;
        for (var i = 0; i < windowLength; i++) {
            var thisQ: {w:number,x:number,y:number,z:number} = { w: 0.0, x: 0.0, y: 0.0, z: 0.0 };
            for (var j = 0; j < allQStreams.length; j++) {
                let thisStream: {w:number,x:number,y:number,z:number}[] = allQStreams[j];
                let thisEntry: {w:number,x:number,y:number,z:number} = thisStream[i];
                if (thisEntry) {
                    thisQ.w = thisQ.w + (thisEntry.w / allQStreams.length);
                    thisQ.x = thisQ.x + (thisEntry.x / allQStreams.length);
                    thisQ.y = thisQ.y + (thisEntry.y / allQStreams.length);
                    thisQ.z = thisQ.z + (thisEntry.z / allQStreams.length);
                }
            }

            avgQStream.push(thisQ);

            var thisSTD: {w:number,x:number,y:number,z:number} = { w: 0.0, x: 0.0, y: 0.0, z: 0.0 };
            for (var j = 0; j < allQStreams.length; j++) {
                let thisStream: {w:number,x:number,y:number,z:number}[] = allQStreams[j];
                let thisEntry: {w:number,x:number,y:number,z:number} = thisStream[i];
                if (thisEntry) {
                    thisSTD.w += (thisQ.w - thisEntry.w) * (thisQ.w - thisEntry.w);
                    thisSTD.x += (thisQ.x - thisEntry.x) * (thisQ.w - thisEntry.x);
                    thisSTD.y += (thisQ.y - thisEntry.y) * (thisQ.w - thisEntry.y);
                    thisSTD.z += (thisQ.z - thisEntry.z) * (thisQ.w - thisEntry.z);
                }
            }

            thisSTD.w /= allQStreams.length;
            thisSTD.x /= allQStreams.length;
            thisSTD.y /= allQStreams.length;
            thisSTD.z /= allQStreams.length;

            thisSTD.w = Math.sqrt(thisSTD.w);
            thisSTD.x = Math.sqrt(thisSTD.x);
            thisSTD.y = Math.sqrt(thisSTD.y);
            thisSTD.z = Math.sqrt(thisSTD.z);

            standardDeviations.push(thisSTD);
        }

        var avgSTD_z = 0;
        for (var i = 0; i < standardDeviations.length; i++) {
            var thisQ: {w:number,x:number,y:number,z:number} = standardDeviations[i];
            avgSTD_z += thisQ.z / standardDeviations.length;
        }

        setSTD_z(avgSTD_z);

        configureChartDisplay(0, allQStreams);

        // console.log("> NodeStreamAnalyzeAverageRep // avgQStream:", avgQStream.length, avgQStream);
        // console.log("> NodeStreamAnalyzeAverageRep // standardDeviations:", standardDeviations.length, standardDeviations);

        /*
        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];

        let qToRotate = {w: 0, x: 0, y: 1, z: 0};

        for (var i = 0; i < avgQStream.length; i++) {
            let thisQEntry = avgQStream[i];
            labels_temp_q.push(`${i}`);

            points_temp_q_w.push(thisQEntry.w);
            points_temp_q_x.push(thisQEntry.x);
            points_temp_q_y.push(thisQEntry.y);
            points_temp_q_z.push(thisQEntry.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)
        */
        setIsLoading(false);
    }


    const configureChartDisplay = (forIndex: number, allQStreams?: {w:number,x:number,y:number,z:number}[][]) => {
        
        let thisAllQStreams = allQStreams ?? all_q_streams;
        let thisQStreamTemp: {w:number,x:number,y:number,z:number}[] | undefined = thisAllQStreams[forIndex];
        let thisQStream = thisQStreamTemp ?? thisAllQStreams[0];

        var points_temp_q_w: number[] = [];
        var points_temp_q_x: number[] = [];
        var points_temp_q_y: number[] = [];
        var points_temp_q_z: number[] = [];
        var labels_temp_q: string[] = [];

        let qToRotate = {w: 0, x: 0, y: 1, z: 0};

        for (var i = 0; i < thisQStream.length; i++) {
            let thisQEntry = thisQStream[i];
            labels_temp_q.push(`${i}`);

            points_temp_q_w.push(thisQEntry.w);
            points_temp_q_x.push(thisQEntry.x);
            points_temp_q_y.push(thisQEntry.y);
            points_temp_q_z.push(thisQEntry.z);
        }

        var tempDataSets_q = JSON.parse(JSON.stringify(initDataSets_q));
        tempDataSets_q.labels = labels_temp_q;
        tempDataSets_q.datasets[0].data = points_temp_q_w;
        tempDataSets_q.datasets[1].data = points_temp_q_x;
        tempDataSets_q.datasets[2].data = points_temp_q_y;
        tempDataSets_q.datasets[3].data = points_temp_q_z;

        setDataSets_q(tempDataSets_q)
    }


    const getLastRecordedTimestampString = () => {
        if (lastRecordedTimestamp <= 0) return "";
        let thisDate = new Date(lastRecordedTimestamp);
        let this_yr = thisDate.getFullYear();
        let this_mo = thisDate.getMonth();
        let this_dy = thisDate.getDate();

        let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

        return `${moStrings[this_mo]} ${this_dy}, ${this_yr}`;

    }

    function mouseMoveDiv(event: any) {
        //// console.log(event.clientX - 220);
        let posX = event.clientX - 220 - 42;
        //setHoverPosX(posX);

        var widthPx = containerRef.current ? containerRef.current.offsetWidth : 0;
        let progressPercent = posX / (widthPx < 1 ? 10 : widthPx);

        let progressIndex = Math.floor(progressPercent * validLength);
        progressIndex -= 1;
        progressIndex = progressIndex < 0 ? 0 : progressIndex > validLength - 1 ? validLength - 1 : progressIndex;
        setViewingLogIndex(progressIndex);
        setRemoveIndex_start(progressIndex + 1);

        if (progressIndex !== viewingLogIndex) {
            configureChartDisplay(progressIndex);
        }
    }

    function getLocationHasData() {
        return dataSets_q.labels.length > 0;
    }

    function inputChanged(newValTemp: string, valName: "start" | "end") {

        if (prepDelete === true) return;

        let newVal = Number(newValTemp)
        if (isNaN(newVal)) return;

        if (valName === "start") {
            setRemoveIndex_start(newVal);
        } else if (valName === "end") {
            setRemoveIndex_end(newVal);
        }
    }

    function confirmPassword(forInput: "remove" | "copy") {
        let thisPW = forInput === "remove" ? removePassword : forInput === "copy" ? copyPassword : "";
        return thisPW === "CONNOR" || thisPW === "ZACH";
    }

    async function confirmDelete() {
        

        let thisStartingIndex = removeIndex_start - 1;
        let thisEndingIndex = removeIndex_end - 1;
        let forExerciseID = props.selectedExerciseSession.id;
        let locationIndex = props.locationIndex;

        if (!forExerciseID || forExerciseID === "" || locationIndex === undefined || locationIndex < 0 || locationIndex > 11) { 
            // console.log("> NodeStreamAnalyzeAverageRep | confirmDelete(): ERROR in exercise ID or locationIndex", forExerciseID, locationIndex);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        setPrepDelete(false);
        setRemovePassword("");
        setShowRemovingPage(false);
        setIsDeleting(true);

        // Pull logs
        let database = firebase.database();
        let ref = database.ref(`ml_data/exercises/${forExerciseID}/locations/${locationIndex}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                // console.log("Data loaded")
                if (!data.data || data.data.length === 0) {
                    setIsLoading(false);
                    setIsDeleting(false);
                    return;
                } else {
                    parseDataToRemove(data, thisStartingIndex, thisEndingIndex, forExerciseID, locationIndex);
                }
            } else {
                // console.log("> NodeStreamAnalyzeAverageRep | confirmDelete(): ERROR in pulling log data. No data exists. Will exit.", forExerciseID, locationIndex);
                setIsLoading(false);
                setIsDeleting(false);
            }
        });
    }

    async function parseDataToRemove(data: any, thisStartingIndex: number, thisEndingIndex: number, forExerciseID: string, locationIndex: number) {
        // console.log("parseDataToRemove", thisStartingIndex, thisEndingIndex)
        let logData = data.data;
        var newDataArr: number[][] = [];
        var validIndex = 0;
        var removedEntries_rep = 0;
        var removedEntries_stationary = 0;
        for (var i = 0; i < logData.length; i++) {
            let thisLog: number[] = logData[i];
            let thisDataLogPredictionIndex = thisLog[0];

            if (thisDataLogPredictionIndex === 1) validIndex += 1;

            if (validIndex < thisStartingIndex || validIndex > thisEndingIndex) {
                newDataArr.push(thisLog);
            } else {
                if (thisDataLogPredictionIndex === 1) {
                    removedEntries_rep += 1;
                    
                } else {
                    removedEntries_stationary += 1;
                }
            }
        }

        let newLength = newDataArr.length;
        // console.log("OLD LIST:", logData.length, logData);
        // console.log("NEW LIST:", newLength, newDataArr);
        // console.log("Diff", logData.length - newLength, thisEndingIndex - thisStartingIndex)
        // console.log(`REMOVED: ${removedEntries_rep} reps\t ${removedEntries_stationary} stationary\t${removedEntries_rep + removedEntries_stationary} total entries`, )

        let newObject = {
            data: newDataArr,
            last_updated: Date.now(),
            length: newLength
        };

        // console.log("newObject:", newObject)

        if (!newObject || !newObject.data || !forExerciseID || forExerciseID === "" || locationIndex === undefined || locationIndex < 0 || locationIndex > 11) {
            setIsDeleting(false);
            setIsLoading(false);
            return;
        }

        let database = firebase.database();
        let ref = database.ref(`ml_data/exercises/${forExerciseID}/locations/${locationIndex}`);
        ref.set(newObject)
        setIsDeleting(false);
        setIsLoading(false);
            
        generateData(forExerciseID, locationIndex);

    } 


    async function confirmCopy() {
        
        let forExerciseID = copyID;
        let thisExerciseID = props.selectedExerciseSession.id;
        let locationIndex = props.locationIndex;

        if (!thisExerciseID || thisExerciseID === "" || !forExerciseID || forExerciseID === "" || locationIndex === undefined || locationIndex < 0 || locationIndex > 11) { 
            // console.log("> NodeStreamAnalyzeAverageRep | confirmCopy(): ERROR in copy exercise ID, this exercise ID, or locationIndex", forExerciseID, thisExerciseID, locationIndex);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        setPrepCopy(false);
        setCopyPassword("");
        setShowRemovingPage(false);
        setIsCopying(true);

        // Pull logs from thisID
        let database = firebase.database();
        let ref = database.ref(`ml_data/exercises/${thisExerciseID}/locations/${locationIndex}`);
        let snapshot = await ref.once('value');

        if (!snapshot.exists()) {
            // console.log("> NodeStreamAnalyzeAverageRep | confirmCopy(): ERROR in pulling this exercise log data. No data exists. Will exit.", thisExerciseID, locationIndex);
            setIsLoading(false);
            setIsDeleting(false);
            return;
        }

        let data = snapshot.val();

        if (!data || !data.data || data.data.length === 0) {
            setIsLoading(false);
            setIsDeleting(false);
            return;
        }

        let logEntries: number[][] = data.data;

        // Pull logs to copy
        let ref_toCopy = database.ref(`ml_data/exercises/${forExerciseID}/locations/${locationIndex}/data`);
        let snapshot_toCopy = await ref_toCopy.once('value');

        if (!snapshot_toCopy.exists()) {
            // console.log("> NodeStreamAnalyzeAverageRep | confirmCopy(): ERROR in pulling to copy log data. No data exists. Will exit.", forExerciseID, locationIndex);
            setIsLoading(false);
            setIsDeleting(false);
            return;
        }

        let data_topCopy: number[][] = snapshot_toCopy.val();
        if (!data_topCopy || data_topCopy.length === 0) {
            setIsLoading(false);
            setIsDeleting(false);
            return;
        }

        let newEntryList: number[][] = JSON.parse(JSON.stringify(logEntries)).concat(data_topCopy)

        // console.log("NEW ENTRY LIST:", logEntries.length, data_topCopy.length, newEntryList.length, newEntryList)

        let newObject = {
            data: newEntryList,
            last_updated: Date.now(),
            length: newEntryList.length
        };

        // console.log("newObject:", newObject)

        if (!newObject || !newObject.data || !thisExerciseID || thisExerciseID === "" || locationIndex === undefined || locationIndex < 0 || locationIndex > 11) {
            setIsDeleting(false);
            setIsLoading(false);
            return;
        }

        let ref_set = database.ref(`ml_data/exercises/${thisExerciseID}/locations/${locationIndex}`);
        ref.set(newObject)
        setIsCopying(false);
        setIsLoading(false);
            
        generateData(thisExerciseID, locationIndex);

        // ref.once('value', function(snapshot) {
        //     if (snapshot.exists() === true) {
        //         let data = snapshot.val();
        //         // console.log("Data loaded", data)
        //         if (!data.data || data.data.length === 0) {
        //             setIsLoading(false);
        //             setIsDeleting(false);
        //             return;
        //         } else {
                    
        //         }
        //     } else {
        //         // console.log("> NodeStreamAnalyzeAverageRep | confirmCopy(): ERROR in pulling log data. No data exists. Will exit.", forExerciseID, locationIndex);
        //         setIsLoading(false);
        //         setIsDeleting(false);
        //     }
        // });
    }

	return (
		<div className={ "node-stream-analysis-average-rep-container" }>
			<div className={ "node-stream-analysis-average-rep-header" }>
                <div className={ "node-stream-analysis-average-rep-header-inner" }>
                    <h4>Average Rep from { validLength } reps | Z δ: { Math.round(STD_z * 10000) / 100 }%</h4>
                    {
                        lastRecordedTimestamp > 0 && <p>Last window update on { getLastRecordedTimestampString() }</p>
                    }
                </div>
                
                <div hidden={ isLoading || !getLocationHasData() } onClick={() => setShowRemovingPage(!showRemovingPage)} className="node-stream-analysis-upper-container-select-session-header-button node-stream-analysis-average-rep-header-button">
                    <p>{ showRemovingPage ? "Show Logs" : "Window MGMT" }</p>
                </div>
            </div>
            {
                isLoading ?
                <div className={ "node-stream-analysis-average-rep-body node-stream-analysis-average-rep-body-loading" }>
                    <div className="create-workout-exercise-configure-loading-spinner"/>
                    <p>{ isDeleting ? "Deleting Data..." : isCopying ? `Copying from ${copyID}...` : "Loading preview..." }</p>
                </div>
                :
                <div className={ "node-stream-analysis-average-rep-body" }>
                    {
                        getLocationHasData() ?
                        <div className={ "node-stream-analysis-average-rep-body-inner" }>
                            {
                                showRemovingPage ? 
                                <div className={ "node-stream-analysis-average-rep-management-container" }>
                                    <div className={ "node-stream-analysis-upper-params-body-row" }>
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                            <p style={{ color: removeIndex_start < 1 || removeIndex_end <= removeIndex_start ? "#FF4D4D" : "#ffffff" }}>Remove Window Start</p>
                                        </div> 
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                            <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                                <IonInput 
                                                    value={ removeIndex_start } 
                                                    placeholder="Starting Index"
                                                    inputmode="numeric"
                                                    clearOnEdit={true}
                                                    autofocus={false}
                                                    type="number"
                                                    onKeyPress={e => null}
                                                    onIonChange={e => inputChanged(e.detail.value!, "start")}/>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className={ "node-stream-analysis-upper-params-body-row" }>
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                            <p style={{ color: removeIndex_end > validLength || removeIndex_end <= removeIndex_start ? "#FF4D4D" : "#ffffff" }}>Remove Window End</p>
                                        </div> 
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                            <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                                <IonInput 
                                                    value={ removeIndex_end } 
                                                    placeholder="Ending Index"
                                                    inputmode="numeric"
                                                    clearOnEdit={true}
                                                    autofocus={false}
                                                    type="number"
                                                    onKeyPress={e => null}
                                                    onIonChange={e => inputChanged(e.detail.value!, "end")}/>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div hidden={ removeIndex_end > validLength || removeIndex_end <= removeIndex_start || removeIndex_start < 1 } className={ "node-stream-analysis-upper-params-body-row node-stream-analysis-average-rep-body-row-bottom" }>
                                        {
                                            prepDelete ? 
                                            <div className={ "node-stream-analysis-average-rep-delete-container" }>
                                                <div className={ "node-stream-analysis-average-rep-delete-input-container" }>
                                                    <IonInput 
                                                        value={ removePassword } 
                                                        placeholder="Password"
                                                        inputmode="text"
                                                        clearOnEdit={true}
                                                        autofocus={false}
                                                        type="text"
                                                        onKeyPress={e => null}
                                                        onIonChange={e => setRemovePassword(e.detail.value!)}/>
                                                </div>
                                                <div className={ `node-stream-analysis-average-rep-prep-delete-button ${confirmPassword("remove") ? 'node-stream-analysis-average-rep-prep-delete-button-valid' : 'node-stream-analysis-average-rep-prep-delete-button-invalid'}` } onClick={ () => confirmPassword("remove") ? confirmDelete() : null}>
                                                    <p>CONFIRM DELETE</p>
                                                </div>
                                            
                                            </div>
                                            :
                                            <div className={ `node-stream-analysis-average-rep-prep-delete-button` } onClick={ () => setPrepDelete(true)}>
                                                <p>Delete Data</p>
                                            </div>
                                        }
                                    </div>
                                    <div className={ "node-stream-analysis-upper-params-body-row" } style={{ marginTop: '12px' }}>
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-title" }>
                                            <p>Copy windows from ID</p>
                                        </div> 
                                        <div className={ "node-stream-analysis-upper-params-body-col node-stream-analysis-upper-params-body-col-min" }>
                                            <div className={ "node-stream-analysis-upper-params-body-input-container" }>
                                                <IonInput 
                                                    value={ copyID } 
                                                    placeholder="Copy ID"
                                                    inputmode="text"
                                                    clearOnEdit={true}
                                                    autofocus={false}
                                                    type="text"
                                                    onKeyPress={e => null}
                                                    onIonChange={e => setCopyID(e.detail.value!)}/>
                                            </div>
                                        </div> 
                                    </div>
                                    <div hidden={ copyID === "" || copyID.split("").length < 8 } className={ "node-stream-analysis-upper-params-body-row node-stream-analysis-average-rep-body-row-bottom" }>
                                        {
                                            prepCopy ? 
                                            <div className={ "node-stream-analysis-average-rep-delete-container" }>
                                                <div className={ "node-stream-analysis-average-rep-delete-input-container" }>
                                                    <IonInput 
                                                        value={ copyPassword } 
                                                        placeholder="Password"
                                                        inputmode="text"
                                                        clearOnEdit={true}
                                                        autofocus={false}
                                                        type="text"
                                                        onKeyPress={e => null}
                                                        onIonChange={e => setCopyPassword(e.detail.value!)}/>
                                                </div>
                                                <div className={ `node-stream-analysis-average-rep-prep-delete-button ${confirmPassword("copy") ? 'node-stream-analysis-average-rep-prep-delete-button-valid' : 'node-stream-analysis-average-rep-prep-delete-button-invalid'}` } onClick={ () => confirmPassword("copy") ? confirmCopy() : null}>
                                                    <p>CONFIRM COPY</p>
                                                </div>
                                            
                                            </div>
                                            :
                                            <div className={ `node-stream-analysis-average-rep-prep-delete-button` } onClick={ () => setPrepCopy(true)}>
                                                <p>Copy Data</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <Line
                                    data={dataSets_q}
                                    options={chartSettings_q}/>
                            }
                        </div>
                        :
                        <div className={ "node-stream-analysis-average-rep-body node-stream-analysis-average-rep-body-loading" }>
                            <p>No data for this location</p>
                        </div>
                    }
                    
                </div>
            }
            <div hidden={ isLoading || !getLocationHasData() || showRemovingPage } className={ "node-stream-analysis-average-rep-bottom" } ref={containerRef} onMouseMove={(e: any) => mouseMoveDiv(e)} >
                
                <div className={ "node-stream-analysis-average-rep-bottom-progress-bar" } style={{ width: `${validLength === 0 ? 0 : viewingLogIndex / validLength * 100}%` }}></div>
                <div className={ "node-stream-analysis-average-rep-bottom-text-container" }>
                    <p>Move mouse here | Showing { viewingLogIndex + 1 } of { validLength }</p>
                </div>
            </div>
		</div>
	)
}

export default NodeStreamAnalyzeAverageRep;