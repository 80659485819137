import React, { useState } from 'react';
import './WorkoutList.css';
import '../DashboardPageCommon.css';
import '../../../components/PageElements/BlankPage.css';
import firebase from '../../../Firebase';
import Mixpanel from '../../../Mixpanel';


import Search from '../../../components/Base/Search';
import TextInput from '../../../components/Base/TextInput';
import StandardButton from '../../../components/Base/StandardButton';
import WorkoutCell from '../../../components/Workouts/WorkoutCell';

import Workout_t from '../../../Interfaces/Workout_t';
import Exercise_t from '../../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../../Interfaces/ExerciseSeries_t';
import CreatedBy_t from '../../../Interfaces/CreatedBy_t';
import MotionInfo_t from '../../../Interfaces/MotionInfo_t';
import Tempo_t from '../../../Interfaces/Tempo_t';

import '@material-ui/core';
import Add from '@material-ui/icons/Add';

function WorkoutList(props: any) {

	let initWorkout: Workout_t = {
						title: '',
						id: '',
						createdBy: {name: '', id: ''},
						duration: 0,
						imageName: '',
						targetGender: '',
						totalSets: 0,
						totalReps: 0,
						difficulty: 0
					};


	const [workoutsBlank, setWorkoutsBlank] = useState(false);
	const [workoutsPulled, setWorkoutsPulled] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [workouts, setWorkouts] = useState<Workout_t[]>([]);
	const [allWorkouts, setAllWorkouts] = useState<Workout_t[]>([]);
	const [selectedWorkout, setSelectedWorkout] = useState<Workout_t>(initWorkout);

	getWorkouts();

	function getWorkouts() {

		if (workoutsPulled === true) { return; }
		setWorkoutsPulled(true);

		if (props.personalWorkouts === true) {
			getPersonalWorkouts();
			return;
		}

		let database = firebase.database();
		let ref = database.ref('workouts')
		ref.on('value', function(snapshot) {
			parseWorkouts(snapshot);
		});
	}

	function getPersonalWorkouts() {
		let thisUser = firebase.auth().currentUser;
		if (!thisUser) { return; }

		let database = firebase.database();
		let ref = database.ref(`personal_trainer_workouts/${thisUser.uid}`)
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				// Currently logged in trainer has previously created workouts
				let workoutList: string[] = snapshot.val();

				let workoutListLength = workoutList.length;
				//var temp: Workout_t[] = [];
				var temp: any = {};
				for (var i = 0; i < workoutListLength; i++) {
					let workoutID = workoutList[i];
					let thisWorkoutRef = database.ref(`workouts/${workoutID}`)
					thisWorkoutRef.on('value', function(snapshotWorkout) {
						if (snapshotWorkout.exists() === true) {
							let thisWorkout = snapshotWorkout.val();

							temp[workoutID] = thisWorkout;
							let keys = Object.keys(temp);
							if (keys.length === workoutListLength) {
								// all workouts have been pulled - set the state vars to temp
								parsePersonalWorkouts(temp);
							}
						}
					});
				}
			} else {
				// No workouts have been created for the currently logged in user
				setWorkoutsBlank(true);
				setIsLoading(false);
			}
		});
	}

	async function parsePersonalWorkouts(workoutList: any) {

		var temp: Workout_t[] = [];
		for (const key of Object.keys(workoutList)) {
			let thisWorkout = workoutList[key];

			let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

			// Configure Exercise Series
			var seriesList_temp: ExerciseSeries_t[] = [];
			let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
			if (containsExerciseSeries === true) {
				for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
					let thisSeriesObj = thisWorkout.exercise_series[i];
					let thisSetLetter = getSetLetter(i);

					var exerciseList_temp: Exercise_t[] = [];
					if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
						for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
							let thisExerciseObj = thisSeriesObj.exercises[j];
							let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

							exerciseList_temp.push(exTemp);
						}
					}

					var seriesTemp: ExerciseSeries_t = {
						title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
						index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
						type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
						exercises: exerciseList_temp
					};

					seriesList_temp.push(seriesTemp);
				}
			} else {
				// Workout was created "pre-series". Create a new placeholder series for each existing exercise
				if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
					for (var i = 0; i < thisWorkout.exercises.length; i++) {
						let thisExerciseTemp: any = thisWorkout.exercises[i];
						let thisSetLetter = getSetLetter(i);
						var seriesTemp: ExerciseSeries_t = {
							title: thisSetLetter,
							index: i,
							type: 0,
							exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
						};
						seriesList_temp.push(seriesTemp);
					}
				}
			}

			var exerciseList: Exercise_t[] = [];
			if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

				var exerciseReps: any = {};
				for (var i = 0; i < thisWorkout.exercises.length; i++) {
					var a: number[] = [];
					if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
						for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
							let thisRep = thisWorkout.exercises[i].reps[j];
							a.push(thisRep);
							
						}
					}
					exerciseReps[thisWorkout.exercises[i].key] = a;
				}

				var exerciseTempos: any = {};
				for (var i = 0; i < thisWorkout.exercises.length; i++) {
					var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
					if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
						for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
							let thisTempoObj = thisWorkout.exercises[i].tempos[j];
							let thisTempo: Tempo_t = {
								concentric: thisTempoObj.concentric,
								eccentric: thisTempoObj.eccentric,
								first_pause: thisTempoObj.first_pause,
								second_pause: thisTempoObj.second_pause
							};
							b.push(thisTempo);
						}
					}
					exerciseTempos[thisWorkout.exercises[i].key] = b;
				}

				let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
				exerciseList = e;


				// push exercises into according slots in seriesList_temp
				if (exerciseList.length > 0) {
					for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
						let thisExercise: Exercise_t = exerciseList[ex_index];
						let thisExercise_ID = thisExercise.id;

						// Loop through seriesList_temp to find thisExercise's matching location
						if (seriesList_temp.length > 0) {
							for (var i = 0; i < seriesList_temp.length; i++) {
								let thisSeries = seriesList_temp[i];
								if (thisSeries.exercises.length > 0) {
									for (var j = 0; j < thisSeries.exercises.length; j++) {
										let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
										let thisSeriesExercise_ID = thisSeriesExercise.id;
										if (thisSeriesExercise_ID === thisExercise_ID) {
											// ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
											// for the case of multiple of the same exercises in a workout
											if (thisSeriesExercise.title === '') {
												seriesList_temp[i].exercises[j] = thisExercise;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
			let newWorkout: Workout_t = {
				title: thisWorkout.title,
				description: thisWorkout.description !== undefined ? thisWorkout.description: '',
				id: key,
				createdBy: createdBy,
				exercises: exerciseList,
				exerciseSeries: seriesList_temp,
				duration: Number(thisWorkout.duration),
				imageName: thisWorkout.image_name,
				targetGender: thisWorkout.target_gender,
				totalSets: Number(thisWorkout.total_sets),
				totalReps: Number(thisWorkout.total_reps),
				difficulty: Number(thisWorkout.difficulty),
				goals: thisWorkout.goals,
				downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
				timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
			};
			temp.push(newWorkout);
		}

		setWorkouts(workouts.concat(temp));
		setAllWorkouts(allWorkouts.concat(temp));
		setIsLoading(false);
	}

	async function getPersonalWorkoutExercises() {
		return new Promise<Workout_t[]>(resolve => {

		});
	}

	async function parseWorkouts(snapshot: any) {
		if (snapshot.exists() === true) {
			let workoutList = snapshot.val();

			var temp: Workout_t[] = [];
			for (const key of Object.keys(workoutList)) {
				let thisWorkout = workoutList[key];

				let restTimes: string[] = thisWorkout.rest_times !== null && thisWorkout.rest_times !== undefined ? thisWorkout.rest_times : [];

				// Configure Exercise Series
				var seriesList_temp: ExerciseSeries_t[] = [];
				let containsExerciseSeries = thisWorkout.exercise_series !== undefined && thisWorkout.exercise_series.length > 0;
				if (containsExerciseSeries === true) {
					for (var i = 0; i < thisWorkout.exercise_series.length; i++) {
						let thisSeriesObj = thisWorkout.exercise_series[i];
						let thisSetLetter = getSetLetter(i);

						var exerciseList_temp: Exercise_t[] = [];
						if (thisSeriesObj.exercises !== undefined && thisSeriesObj.exercises.length > 0) {
							for (var j = 0; j < thisSeriesObj.exercises.length; j++) {
								let thisExerciseObj = thisSeriesObj.exercises[j];
								let exTemp: Exercise_t = {title:'',id:thisExerciseObj.id,type: 0,muscles:[]};

								exerciseList_temp.push(exTemp);
							}
						}

						var seriesTemp: ExerciseSeries_t = {
							title: thisSeriesObj.title !== undefined ? thisSeriesObj.title : thisSetLetter,
							index: thisSeriesObj.index !== undefined ? thisSeriesObj.index : i,
							type: thisSeriesObj.type !== undefined ? thisSeriesObj.type : (thisSeriesObj.exercises !== undefined ? (thisSeriesObj.exercises.length > 0 ? 1 : 0) : 0),
							exercises: exerciseList_temp
						};

						seriesList_temp.push(seriesTemp);
					}
				} else {
					// Workout was created "pre-series". Create a new placeholder series for each existing exercise
					if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {
						for (var i = 0; i < thisWorkout.exercises.length; i++) {
							let thisExerciseTemp: any = thisWorkout.exercises[i];
							let thisSetLetter = getSetLetter(i);
							var seriesTemp: ExerciseSeries_t = {
								title: thisSetLetter,
								index: i,
								type: 0,
								exercises: [{title:'', id:thisExerciseTemp.key, type: 0, muscles:[]}]
							};
							seriesList_temp.push(seriesTemp);
						}
					}
				}

				var exerciseList: Exercise_t[] = [];
				if (thisWorkout.exercises !== null && thisWorkout.exercises.length > 0) {

					var exerciseReps: any = {};
					for (var i = 0; i < thisWorkout.exercises.length; i++) {
						var a: number[] = [];//{key: thisWorkout.exercises.key, reps:};
						if (thisWorkout.exercises[i].reps !== null && thisWorkout.exercises[i].reps !== undefined && thisWorkout.exercises[i].reps.length > 0) {
							for (var j = 0; j < thisWorkout.exercises[i].reps.length; j++) {
								let thisRep = thisWorkout.exercises[i].reps[j];
								a.push(thisRep);
								
							}
						}
						exerciseReps[thisWorkout.exercises[i].key] = a;
					}

					var exerciseTempos: any = {};
					for (var i = 0; i < thisWorkout.exercises.length; i++) {
						var b: Tempo_t[] = [];//{key: thisWorkout.exercises.key, tempos:};
						if (thisWorkout.exercises[i].tempos !== null && thisWorkout.exercises[i].tempos !== undefined && thisWorkout.exercises[i].tempos.length > 0) {
							for (var j = 0; j < thisWorkout.exercises[i].tempos.length; j++) {
								let thisTempoObj = thisWorkout.exercises[i].tempos[j];
								let thisTempo: Tempo_t = {
									concentric: thisTempoObj.concentric,
									eccentric: thisTempoObj.eccentric,
									first_pause: thisTempoObj.first_pause,
									second_pause: thisTempoObj.second_pause
								};
								b.push(thisTempo);
							}
						}
						exerciseTempos[thisWorkout.exercises[i].key] = b;
					}

					let e: Exercise_t[] = await getExercises(thisWorkout.exercises, restTimes, exerciseReps, exerciseTempos);
					exerciseList = e;

					// push exercises into according slots in seriesList_temp
					if (exerciseList.length > 0) {
						for (var ex_index = 0; ex_index < exerciseList.length; ex_index++) {
							let thisExercise: Exercise_t = exerciseList[ex_index];
							let thisExercise_ID = thisExercise.id;

							// Loop through seriesList_temp to find thisExercise's matching location
							if (seriesList_temp.length > 0) {
								for (var i = 0; i < seriesList_temp.length; i++) {
									let thisSeries = seriesList_temp[i];
									if (thisSeries.exercises.length > 0) {
										for (var j = 0; j < thisSeries.exercises.length; j++) {
											let thisSeriesExercise: Exercise_t = thisSeries.exercises[j];
											let thisSeriesExercise_ID = thisSeriesExercise.id;
											if (thisSeriesExercise_ID === thisExercise_ID) {
												// ID's of exercise in series and exercise attempting to find its location match! Check if already assigned
												// for the case of multiple of the same exercises in a workout
												if (thisSeriesExercise.title === '') {
													seriesList_temp[i].exercises[j] = thisExercise;
												}
											}
										}
									}
								}
							}
						}
					}

				}

				let createdBy: CreatedBy_t = {name: thisWorkout.created_by.name, id: thisWorkout.created_by.id};
				let newWorkout: Workout_t = {
					title: thisWorkout.title,
					description: thisWorkout.description !== undefined ? thisWorkout.description: '',
					id: key,
					createdBy: createdBy,
					exercises: exerciseList,
					exerciseSeries: seriesList_temp,
					duration: Number(thisWorkout.duration),
					imageName: thisWorkout.image_name,
					targetGender: thisWorkout.target_gender,
					totalSets: Number(thisWorkout.total_sets),
					totalReps: Number(thisWorkout.total_reps),
					difficulty: Number(thisWorkout.difficulty),
					goals: thisWorkout.goals,
					downloads: thisWorkout.downloads !== undefined && thisWorkout.downloads !== null ? thisWorkout.downloads : 0,
					timesCompleted: thisWorkout.times_complete !== undefined && thisWorkout.times_complete !== null ? thisWorkout.times_complete : 0
				};
				temp.push(newWorkout);
			}
			setWorkouts(workouts.concat(temp));
			setAllWorkouts(allWorkouts.concat(temp));
			setIsLoading(false);
		} else {
			setWorkoutsBlank(true);
			setIsLoading(false);
		}
	}

	async function getExercises(exercises: any, restTimes: string[], repBreakdown: any[], exerciseTempos: any[]) {
		return new Promise<Exercise_t[]>(resolve => {
			let database = firebase.database();
			var completedIDs = [];
			var exerciseList: Exercise_t[] = [];

			for (var i = 0; i < exercises.length; i++) {
				let thisExercise = exercises[i];
				let thisExerciseKey = thisExercise.key;
				let thisExerciseReps = repBreakdown[thisExerciseKey];
				let thisExerciseTempos: Tempo_t[] = exerciseTempos[thisExerciseKey];

				let ref = database.ref(`exercises/${thisExerciseKey}`) 
				ref.on('value', function(snapshot) {
					if (snapshot.exists() === true) {
						let key = snapshot.key;
						let thisExercise = snapshot.val();
						completedIDs.push(thisExercise.title);
						
						if (thisExercise !== null) {

							let thisRestTime: number = restTimes[i] !== undefined && restTimes[i] !== null ? Number(restTimes[i]) : -1;
							let newExercise: Exercise_t = {
								title:thisExercise.title,
								id: key !== null && key !== undefined ? key : "",
								type: Number(thisExercise.type),
								muscles:thisExercise.muscles,
								equipment: thisExercise.equipment,
								setup: thisExercise.setup,
								steps: thisExercise.steps,
								createdBy: thisExercise.created_by,
								restTime: [thisRestTime],
								reps: thisExerciseReps,
								tempos: thisExerciseTempos
							}

							if (thisExercise.motion_info !== undefined && thisExercise.motion_info !== null) {
								// Motion info data exists - assign to thisMotionInfo

								var thisMotionInfo = thisExercise.motion_info;
								newExercise.motionInfo = thisMotionInfo;
							}

							exerciseList.push(newExercise);
						}

						if (completedIDs.length >= exercises.length) {
							resolve(exerciseList);
						}
					}
				});
			}
		});
	}

	function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }


	function textInputChanged(toString: string) {
		var temp = [];
		for (var i = 0; i < allWorkouts.length; i++) {
			let searchString = toString.toUpperCase();
			let nameString = allWorkouts[i].title.toUpperCase();
			let positiveSearch_name = nameString.includes(searchString);

			if (positiveSearch_name === true) {
				temp.push(allWorkouts[i]);
			}
		}

		setWorkouts(temp);
	}

	function workoutSelected(workout: Workout_t) {
		// console.log(`${workout.title} selected`);
		// console.log("WORKOUT EX SERIES: ", workout.exerciseSeries);

		setWorkouts(
			workouts.map(item => {
				if (item.id === workout.id) {
					setSelectedWorkout(workout);
					props.workoutSelected(workout);
					return item;
				} else {
					return item;
				}
			})
		);
	}

	function moreInfoSelected(workout: Workout_t) {
		props.moreInfoSelected(workout);
	}

	function addNewWorkoutPressed() {
		props.addNewWorkoutPressed();
	}

	return (
		<div className="page-full-height-width">
			<div hidden={workoutsBlank === true || isLoading === true} className="client-container">
				<div hidden={props.showTitle === undefined || props.showTitle === false}><h3>Select a Workout</h3></div>
				<div className="client-list-upper-content">
					<div className={`client-list-upper-content-search-container ${props.showTitle !== undefined && props.showTitle === true ? 'client-list-upper-content-search-container-full' : ''}`}>
						<Search 
							placeholder="Search by name or muslce..." 
							inputChanged={(e: string) => textInputChanged(e)}/>
					</div>
					<div hidden={props.showTitle !== undefined && props.showTitle === true} className="client-list-upper-content-showing-number-container">
						<p>Showing {workouts.length} of {allWorkouts.length}</p>
					</div>
					<div hidden={props.showTitle !== undefined && props.showTitle === true} className="client-list-upper-content-add-button-container workout-list-upper-content-add-button-container">
						<div hidden={props.hideAddNewWorkout !== undefined && props.hideAddNewWorkout === true} onClick={() => addNewWorkoutPressed()} className="client-list-upper-content-add-button workout-list-upper-content-add-button">
							<Add className="client-list-upper-content-add-button-icon"/>
							<p>New Workout</p>
						</div>
					</div>
				</div>
				<div className="client-list-list-headding">
					<div className="client-list-list-headding-row client-list-list-headding-row-first">
						<p>NAME</p>
					</div>
					<div className="client-list-list-headding-row">
						<p>DURATION</p>
					</div>
					<div className="client-list-list-headding-row">
						<p>VOLUME</p>
					</div>
					<div className="client-list-list-headding-row">
						<p>POPULARITY</p>
					</div>
				</div>
				<div className="cell-container">
					{workouts.map(item => (
						<WorkoutCell 
							key={item.id} 
							workout={item} 
							cellSelected={(e: Workout_t) => workoutSelected(e)} 
							moreInfoSelected={(e: Workout_t) => moreInfoSelected(e)}/>
					))}
				</div>
			</div>
			<div hidden={workoutsBlank === false || isLoading === true} className="client-container">
				<div className="blank-page-container">
					<div className="blank-page-content">
		                <h4>No workouts added</h4>
		                <p>It looks like you haven't created any workouts yet.<br/>Not to worry, just press 'Add new workout' to create one!</p>
		            </div>
				</div>
			</div>
			<div hidden={isLoading === false} className="client-container">
				<div className="client-list-container-loading-container">
                    <div className="client-list-container-loading-spinner"/>
                    <p>Loading Workouts...</p>
                </div>
			</div>
		</div>
	)

}

export default WorkoutList;

