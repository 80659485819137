import React from 'react';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './TextInput.css';

interface TextInputProps {
  placeholder: string;
  input: string;
  inputChanged: any;
  inputMode?: "decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url" | undefined;
  hideText?: boolean;
  isSearch?: boolean;
}



interface TextInputState {
  labelIsHidden: boolean;
  message: string;
}

class TextInput extends React.Component<TextInputProps, TextInputState> {
  static defaultProps: TextInputProps = {
    placeholder: "Enter value",
    input: "string",
    inputChanged: null,
    inputMode: "text",
    hideText: false,
    isSearch: false
  }
  constructor(props: any) {
    super(props);
    this.state = {labelIsHidden: true, message: this.props.input}
  }
  setText(newText: string) {
    let shouldHide = newText === "" || newText === null;
    this.setState({labelIsHidden: shouldHide, message: newText});
    this.props.inputChanged(newText);
  }
  render() {
    return (
      <div className="input-container">
       <p hidden={this.state.labelIsHidden || this.props.isSearch === true} className="text-input-label">{this.props.placeholder}</p>
       <div className={this.props.isSearch ? "text-input-container-search" : "text-input-container"}>
         <IonInput value={this.state.message} 
                   placeholder={this.props.placeholder} 
                   inputmode={this.props.inputMode}
                   clearOnEdit={this.props.hideText}
                   type={this.props.hideText ? "password" : "text"}
                   onIonChange={e => this.setText(e.detail.value!)}></IonInput>
       </div>
     </div>
    )
  }
}


export default TextInput;
