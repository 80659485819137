import React, { useState } from 'react';
import firebase from '../../../Firebase';
import axios from 'axios';

import CreatedBy_t from '../../../Interfaces/CreatedBy_t';
import Workout_t from '../../../Interfaces/Workout_t';

import './TopClientCell.css';

import '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';


function TopClientCell(props: any) {

    let initWorkout: Workout_t = {
                    title: '',
                    id: '',
                    createdBy: {name: '', id: ''},
                    duration: 0,
                    imageName: '',
                    targetGender: '',
                    totalSets: 0,
                    totalReps: 0,
                    difficulty: 3,
                    startDate: undefined,
                    endDate: undefined
                };

    const [imgUrl, setImgUrl] = useState('');
    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [workout, setWorkout] = useState<Workout_t>(initWorkout);
    const [muscleGoups, setMuscleGroups] = useState<number[]>([]);
    const [tonnage, setTonnage] = useState(0);
    const [endingDate, setEndingDate] = useState("-- --");
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.id !== undefined) {
            getImage();
            getWorkoutHistory();
        }
    }

    function getImage() {
        const storage = firebase.storage();
        storage.ref('profileImages').child(props.id).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {})
    }

    function getWorkoutHistory() {
        setIsLoading(true);
        let sendData = {
            user_id: props.id,
            get_number: 1,
            starting_at: 0
        };

        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getUserHistory`,
        sendData,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {

            setIsLoading(false);
            let logData = response.data;
            let loggedWorkouts = response.data.logs;
            //// console.log("loggedWorkouts: ", loggedWorkouts);

            if (loggedWorkouts.length === 0) { return; }

            var tempWorkoutBuffer: string[] = [];
            for (var i = 0; i < loggedWorkouts.length; i++) {
                tempWorkoutBuffer.push(loggedWorkouts[i]);
            }

            if (loggedWorkouts[0].workout_id !== undefined) {
                getCompletionString(loggedWorkouts[0].completed_date);
                getWorkoutDetails(loggedWorkouts[0].workout_id, loggedWorkouts[0].start_date, loggedWorkouts[0].completed_date);
            }


            
             
        })
        .catch(e => {
            // console.log(e);
            setIsLoading(false);
        })
    }

    function getWorkoutDetails(workoutID: string, start_date: string, completed_date: string) {
        let database = firebase.database();
        let ref = database.ref(`workouts/${workoutID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                //// console.log(data);
                let createdByData = data.created_by;
                let createdBy:CreatedBy_t = {
                    name: createdByData.name,
                    id: createdByData.id
                };

                var muscleList: number[] = [];
                let exerciseKeys = Object.keys(data.exercises);
                for (var i = 0; i < exerciseKeys.length; i++) {
                    let thisExercise = data.exercises[exerciseKeys[i]];
                    //// console.log(thisExercise);
                    let muscles: any[] = thisExercise.muscles;
                    for (var j = 0; j < muscles.length; j++) {
                        let thisMuscleGroup: number = muscles[j].group;
                        if (!muscleList.includes(thisMuscleGroup)) {
                            muscleList.push(thisMuscleGroup);
                        }
                    }
                }
                //// console.log(muscleList);
                setMuscleGroups(muscleGoups.concat(muscleList));

                let newWorkout: Workout_t = {
                    title: data.title,
                    id: workoutID,
                    createdBy: createdBy,
                    duration: data.duration,
                    imageName: data.image_name,
                    targetGender: data.target_gender,
                    totalSets: Number(data.total_sets),
                    totalReps: Number(data.total_reps),
                    difficulty: Number(data.difficulty),
                    startDate: start_date,
                    endDate: completed_date
                }
                //getImage(data.image_name);
                setWorkout(newWorkout);
                //;
            }
        });
    }

    function getLastWorkoutString() {
        if (props.last_workout_date === undefined || props.last_workout_date === null) { return 'No workouts'; }

        let today = new Date();
        let prevWorkoutParsed = props.last_workout_date.split('-');

        let woDate = new Date(prevWorkoutParsed[0],prevWorkoutParsed[1],prevWorkoutParsed[2]);

        let timeDiff = today.getTime() - woDate.getTime();
        let daysDiff = timeDiff / (1000 * 3600 * 24);
        let filteredDay = Math.ceil(daysDiff) - 1;
        return filteredDay === 0 ? "Today" : ( filteredDay === 1 ? "Yesterday" : (filteredDay === 7 ? "1 week ago" : `${filteredDay} days ago`));
    }

    function getCellColour() {
        if (props.last_workout_date === undefined || props.last_workout_date === null) { return 'No workouts'; }

        let today = new Date();
        let prevWorkoutParsed = props.last_workout_date.split('-');

        let woDate = new Date(prevWorkoutParsed[0],prevWorkoutParsed[1],prevWorkoutParsed[2]);

        let timeDiff = today.getTime() - woDate.getTime();
        let daysDiff = timeDiff / (1000 * 3600 * 24);
        let filteredDay = Math.ceil(daysDiff) - 1;

        return filteredDay > 7 ? "top-client-cell-last-workout-container-red" : "top-client-cell-last-workout-container";
    }

    function cardPressed() {
        //// console.log(`Client ID: ${props.id}`);
        if (props.clientPressed !== undefined) {
            props.clientPressed(props.id);
        }
    }

    function getMuscleNames() {
        if (isLoading) { return []; }
        let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
        var muscleString = " ";
        var muscleList: string[] = [];
        if (muscleGoups.length > 0) {
            for (var i = 0; i < muscleGoups.length; i++) {
                let thisMuscleGroup: number = muscleGoups[i];
                let thisMuscleGroupString = muscle_groups[thisMuscleGroup];
                muscleString += `${i === 0 ? '' : ','} ${thisMuscleGroupString}`;
                muscleList.push(thisMuscleGroupString);
            }
        } else {
            muscleString = " No muscles specified"
        }
        return muscleList;
    }

    function getCompletionString(withTimestamp: string) {

        let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        let date_time = withTimestamp.split(' ');
        let date = date_time[0];
        let time = date_time[1];

        let dates = date.split('-');
        setEndingDate(`${moStrings[Number(dates[1]) - 1]} ${dates[2]}`);
    }

	return (
		<div onClick={() => cardPressed()} className="top-client-cell-container">
			<div className="client-card-image-container">
                <img  
                    className="client-card-image" 
                    hidden={imgUrl === ''}
                    src={imgUrl} 
                    alt={`Image for ${props.name}`} />
            </div>
            <div className="top-client-cell-name-container">
                <h4>{props.name}</h4>
            </div>
            <div className="top-client-cell-col-container">
                <h4>{endingDate}</h4>
            </div>
            <div className="top-client-cell-col-container">
                <h4>{tonnage} LBS</h4>
            </div>
            <div className="top-client-cell-col-container top-client-cell-col-container-pills">
                <div className="top-client-cell-col-container-pills-inner">
                    {getMuscleNames().map((item: string, index: number) => (
                        <div className="top-client-cell-pill-container">
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="top-client-cell-arrow-icon-container">
                <ArrowForwardIos className="top-client-cell-arrow-icon"/>
            </div>
		</div>
	)
}
/*
<div className={getCellColour()}>{getLastWorkoutString()}</div>
*/
export default TopClientCell;