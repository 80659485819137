import React, { useState, useEffect } from 'react';
import './NodeListCell.css';
import './NodeSetCell.css'

import NodeInstance from '../../NodeInstance';

import '@material-ui/core';
import BluetoothConnected from '@material-ui/icons/BluetoothConnected';
import BluetoothDisabled from '@material-ui/icons/BluetoothDisabled';
import BatteryStd from '@material-ui/icons/BatteryStd';
import BatteryChargingFull from '@material-ui/icons/BatteryChargingFull';
import SignalCellularAlt from '@material-ui/icons/SignalCellularAlt';
import Warning from '@material-ui/icons/Warning';
import ControlCamera from '@material-ui/icons/ControlCamera';

function NodeListCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [cardAdditionalCSSClass, setCardAdditionalCSSClass] = useState("");
    const [connected, setConnected] = useState(false);
    const [reconnectFailed, setReconnectFailed] = useState(false);
    const [isCharging, setIsCharging] = useState(false);
    const [battery, setBattery] = useState(100);
    const [signal, setSignal] = useState(100);

    const [calibratingState, setCalibratingState] = useState(0); // 0 = ignore calibration, 1 = is calibrating, 2 = calibration complete, 3 = calibration failed
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        // console.log(`>: NodeListCell - initialize | Running`);
        setTimeout(() => {
            requestBatteryPercentage();
            requestSignalStrength();

            if (props.nodeManager !== undefined) {
                let thisNode: NodeInstance = props.nodeManager.getNodeInstanceByUUID(props.uuid);
                if (thisNode === null) { 
                    // console.log(`>: NodeListCell - initialize | thisNode is null! UUID: ${props.uuid}`);
                    return; 
                }

                let isConnectedTemp = thisNode.getIsConnected();
                // console.log(`>: NodeListCell - initialize | thisNode\'s UUID is: ${props.uuid}\t\tConnected state: ${isConnectedTemp}`);

                
                setConnected(isConnectedTemp);

                thisNode.setConnectionCallback(connectionCallback);
                thisNode.setBatteryCallback(batteryPercentageCallback);
                thisNode.setSignalStrengthCallback(signalStrengthCallback);
                thisNode.setChargingCallback(chargingCallback);
                thisNode.setCalibrationCallback(calibrationCallback);

                if (props.showCalibration !== undefined && props.showCalibration === true) {
                    let nodeCalibrated = thisNode.getCalibrationState();
                    let calState = nodeCalibrated === true ? 2 : 3;
                    setCalibratingState(calState);
                }
            } else {
                // console.log(`>: NodeListCell - initialize | nodeManager DNE`);
            }
        }, 500);
    }

    useEffect(() => {        
        if (props.pingState !== undefined && props.pingState === true) {
            sendPing();
        }
    }, [props.pingState]);

    function cellTapped() {

        if (connected === false) {
            if (props.nodeManager !== undefined) {
                let thisNode = props.nodeManager.getNodeInstanceByUUID(props.uuid);
                let isConnectedTemp = thisNode.getIsConnected();
                // console.log(`>: NodeListCell - initialize | thisNode\'s UUID is: ${props.uuid}\t\tConnected state: ${isConnectedTemp}`);

                
                setConnected(isConnectedTemp);
                props.nodeManager.scanForNodes();
                setReconnectFailed(false);
                attemptReconnect();

                return;
            }
        }

        if (calibratingState === 3) {
            runCalibration();
        } else {
            sendPing()
        }
    }

    function sendPing() {

        if (props.allowPing !== undefined && props.allowPing === false) { return; }

        props.pingPressed();

        let blinkTime = 400;
        setCardAdditionalCSSClass("node-set-cell-container-green");
        setTimeout(() => {
            setCardAdditionalCSSClass("");
            setTimeout(() => {
                setCardAdditionalCSSClass("node-set-cell-container-green");
                setTimeout(() => {
                    setCardAdditionalCSSClass("");
                }, blinkTime);
            }, blinkTime);
        }, blinkTime);
    }

    function runCalibration() {
        setCalibratingState(1);
    }

    function calibrationCallback(success: boolean) {
        let calState = success === true ? 2 : 3;
        setCalibratingState(calState);
    }

    function requestSignalStrength() {
        if (props.nodeManager !== undefined) {
            let thisNode: NodeInstance = props.nodeManager.getNodeInstanceByUUID(props.uuid);
            if (thisNode === null) { 
                return; 
            }
            thisNode.setSignalStrengthCallback(signalStrengthCallback);
            thisNode.requestSignalStrength();

            setTimeout(() => {
                if (connected === true) {
                    requestSignalStrength();
                }
            }, 2000);
        }
    }

    function signalStrengthCallback(uuid: string, rssi: number) {
        let q = (2 * (rssi + 100)) + 20;
        let quality = q > 100 ? 100 : (q < 0 ? 0 : q);

        //let power = Math.pow(10, rssi / 10);
        //// console.log(`${power / 1000}uW received signal power`);
        setSignal(quality);
    }

    function requestBatteryPercentage() {
        if (props.nodeManager !== undefined) {
            let thisNode: NodeInstance = props.nodeManager.getNodeInstanceByUUID(props.uuid);
            if (thisNode === null) { 
                return; 
            }
            thisNode.setBatteryCallback(batteryPercentageCallback);
            thisNode.requestBatteryPercentage();
        }
    }

    function batteryPercentageCallback(uuid: string, battery: number) {
        setBattery(battery);
    }

    function chargingCallback(isCharging: boolean) {
        setIsCharging(isCharging);
    } 

    function connectionCallback(isConnected: boolean) {
        setConnected(isConnected);

        if (isConnected === false) {
            attemptReconnect();
        } else { 
            requestSignalStrength();
            requestBatteryPercentage();

            if (props.showCalibration !== undefined && props.showCalibration === true) {
                setCalibratingState(1);
            }
        }
    }

    function attemptReconnect() {
        if (connected === false) {
            if (props.nodeManager !== undefined) {
                props.nodeManager.scanForNodes();

                setReconnectFailed(false);

                setTimeout(() => {
                    // Do some stuff
                    let thisNode: NodeInstance = props.nodeManager.getNodeInstanceByUUID(props.uuid);
                    if (thisNode === null) { 
                        return; 
                    }
                    let nodeIsConnected = thisNode.getIsConnected();
                    setReconnectFailed(!nodeIsConnected);
                }, 8000);
            }
        }
    }

    function getBatteryCircles() {
        let batteryPercentage = battery;//getBatteryPercentage();
        let dividedBattery = Math.ceil(batteryPercentage / 20);
        var composedSet = [];
        for (var i = 0; i < 5; i++) {
            composedSet.push(<div key={i} className={`node-set-cell-info-node-connected-cirlce ${i < dividedBattery ? "node-set-cell-info-node-connected-cirlce-connected" : "node-set-cell-info-node-connected-cirlce-notconnected"}`}/>);
        }

        return composedSet;
    }

    function getSignalCircles() {
        let signalPercentage = signal;
        let dividedSignal = Math.ceil(signalPercentage / 20);
        var composedSet = [];
        for (var i = 0; i < 5; i++) {
            composedSet.push(<div key={i} className={`node-set-cell-info-node-connected-cirlce ${i < dividedSignal ? "node-set-cell-info-node-connected-cirlce-connected" : "node-set-cell-info-node-connected-cirlce-notconnected"}`}/>);
        }

        return composedSet;
    }

    return (
        <div onClick={() => cellTapped()} className={`node-cell-container ${cardAdditionalCSSClass}`}>
            <div className="node-cell-header-container">
                <div className="node-cell-title">
                    <h2>{props.index !== undefined ? `Unassigned Node ${props.index + 1}` : (props.title !== undefined ? props.title : "Unassigned Node")}</h2>
                </div>
                <div className="node-cell-ble-icon-container">
                    <div hidden={connected} className="node-cell-ble-icon-not-connected">
                         <BluetoothDisabled style={{ fontSize: 18 }}/>
                    </div>
                     <div hidden={!connected} className="node-cell-ble-icon-connected">
                         <BluetoothConnected style={{ fontSize: 18 }}/>
                    </div>
                </div>
            </div>
            <div hidden={!connected || calibratingState === 3 || calibratingState === 1} className="node-cell-info-container">
                <div className="node-cell-info-content">
                    <div className={ isCharging ? "node-cell-battery-green" : "node-cell-battery-dark"}>
                        <div hidden={isCharging}><BatteryStd style={{ fontSize: 12 }}/></div> 
                        <div hidden={!isCharging}><BatteryChargingFull style={{ fontSize: 12 }}/></div> 
                        <p>{ isCharging ? "Charging" : "Battery"} </p>
                    </div>
                    <div className="node-cell-info-content-circle-container">{getBatteryCircles()}<p>{battery}%</p></div>
                </div>
                <div className="node-cell-info-content">
                    <p><SignalCellularAlt style={{ fontSize: 12 }}/> Signal Strength</p>
                    <div className="node-cell-info-content-circle-container">{getSignalCircles()}<p>{signal}%</p></div>
                </div>
            </div>
            <div hidden={!connected || calibratingState !== 3} className="node-cell-info-failed-calibration">
                <Warning style={{ fontSize: 14 }}/>
                <p>Node was disconnected. Tap to recalibrate before using Node.</p>
            </div>
            <div hidden={connected} className="node-cell-info-disconnected-container">
                <p>{reconnectFailed ? "Tap to reconnect" : "Connecting to Node..."}</p>
            </div>
            <div hidden={!connected || calibratingState !== 1} className="node-cell-info-recalibration">
                <ControlCamera style={{ fontSize: 14 }}/>
                <p>Calibrating Node...</p>
            </div>
        </div>
    )
}

export default NodeListCell;