import React, { useState, useEffect } from 'react';
import Mixpanel from '../../Mixpanel';
import firebase from '../../Firebase';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import './CreateWorkoutExerciseConfigure.css';
import axios from 'axios';
import { FileDrop } from 'react-file-drop';


import Exercise_t from '../../Interfaces/Exercise_t';
import Tempo_t from '../../Interfaces/Tempo_t';
import Muscles_t from '../../Interfaces/Muscles_t';
import Equipment_t from '../../Interfaces/Equipment_t';
import LoadingPattern_t from '../../Interfaces/LoadingPattern_t';
import LoadingPatternSet_t from '../../Interfaces/LoadingPattern_t';

import Selector from '../Base/Selector';
import FilterSingle from '../Base/FilterSingle';
import FormTextInput from '../Base/FormTextInput';
import FormLongTextInput from '../Base/FormLongTextInput';
import FormTempoInput from '../Base/FormTempoInput';
import CreateWorkoutSearchListCell from '../Workouts/WorkoutBuilder/CreateWorkoutSearchListCell';
import StandardButton from '../Base/StandardButton';
import InfoHover from '../Base/InfoHover';
import CreateWorkoutSetRepInput from '../Workouts/WorkoutBuilder/CreateWorkoutSetRepInput';

import '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Options from '@material-ui/icons/ListAlt'
import Add from '@material-ui/icons/Add';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowForward from '@material-ui/icons/ArrowForward';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import FileCopy from '@material-ui/icons/Ballot';
import Delete from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Timer from '@material-ui/icons/Timer';
import AvTimer from '@material-ui/icons/AvTimer';
import ImportExport from '@material-ui/icons/ImportExport'
import DataUsage from '@material-ui/icons/DataUsage'
import HelpOutline from '@material-ui/icons/HelpOutline';
import Image from '@material-ui/icons/Image';

function CreateWorkoutExerciseConfigure(props: any) {

    let poliquin_LUT: number[] = [100, 100, 94.3, 90.6, 90.6, 88.1, 85.6, 83.1, 80.7, 78.6, 76.5, 74.4, 72.3, 70.3, 68.8, 67.5, 66.2, 65, 63.8, 62.7, 61.6, 60.6];
    let standard_LUT: number[] = [100, 100, 95, 93, 90, 87, 85, 83, 80, 77, 75, 70, 67, 66, 65, 65, 60]
    let muscleNames: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]]

//    let loadingPatternTypes: string[] = ["All", "Relative Strength", "Absolute Strength", "Functional Hypertrophy", "Hypertrophy"];
    const [loadingPatternTypes, setLoadingPatternTypes] = useState<string[]>(["All", 
                                                                              "Relative Strength", 
                                                                              "Absolute Strength", 
                                                                              "Functional Hypertrophy", 
                                                                              "Hypertrophy"]);

    let loadingPatterns_default: LoadingPattern_t[] = [
        // Relative Strength
        {
            title: "Broad Pyramid 1",
            type: 0,
            sets: [{ reps: 3, percentWM: 93 }, { reps: 2, percentWM: 95 }, { reps: 1, percentWM: 100 }, { reps: 1, percentWM: 100 }, { reps: 2, percentWM: 95 }, { reps: 3, percentWM: 93 }]
        },
        {
            title: "Broad Pyramid 2",
            type: 0,
            sets: [{ reps: 5, percentWM: 87 }, { reps: 3, percentWM: 93 }, { reps: 2, percentWM: 95 }, { reps: 2, percentWM: 95 }, { reps: 3, percentWM: 93 }, { reps: 5, percentWM: 87 }]
        },
        {
            title: "Ascending Pyramid",
            type: 0,
            sets: [{ reps: 6, percentWM: 85 }, { reps: 5, percentWM: 87 }, { reps: 4, percentWM: 90 }, { reps: 3, percentWM: 93 }, { reps: 2, percentWM: 95 }, { reps: 1, percentWM: 100 }]
        },
        {
            title: "Ascending Plateau",
            type: 0,
            sets: [{ reps: 5, percentWM: 87 }, { reps: 5, percentWM: 87 }, { reps: 3, percentWM: 93 }, { reps: 3, percentWM: 93 }, { reps: 1, percentWM: 100 }, { reps: 1, percentWM: 100 }]
        },
        {
            title: "Ascending Plateau - Back Off Set",
            type: 0,
            sets: [{ reps: 3, percentWM: 93 }, { reps: 3, percentWM: 93 },{ reps: 2, percentWM: 95 }, { reps: 2, percentWM: 95 }, { reps: 1, percentWM: 100 }, { reps: 4, percentWM: 90 }]
        },
        {
            title: "Wave Loading 1 (3-2-1)",
            type: 0,
            sets: [{ reps: 3, percentWM: 93 },{ reps: 2, percentWM: 95 },{ reps: 1, percentWM: 100 },{ reps: 3, percentWM: 93 },{ reps: 2, percentWM: 95 },{ reps: 1, percentWM: 100 }]
        },
        {
            title: "Wave Loading 2 (2-1-1)",
            type: 0,
            sets: [{ reps: 2, percentWM: 95 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 },{ reps: 2, percentWM: 95 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 }]
        },
        {
            title: "Wave Loading 3 (5-3-1)",
            type: 0,
            sets: [{ reps: 5, percentWM: 87 },{ reps: 3, percentWM: 93 },{ reps: 1, percentWM: 100 },{ reps: 5, percentWM: 87 },{ reps: 3, percentWM: 93 },{ reps: 1, percentWM: 100 }]
        },
        {
            title: "1-3 Method Contrast",
            type: 0,
            sets: [{ reps: 1, percentWM: 100 },{ reps: 3, percentWM: 93 },{ reps: 1, percentWM: 100 },{ reps: 3, percentWM: 93 },{ reps: 1, percentWM: 100 },{ reps: 3, percentWM: 93 }]
        },
        {
            title: "Progressive Plateau",
            type: 0,
            sets: [{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 },{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 }]
        },

        // Absolute Strength
        {
            title: "Broad Pyramid",
            type: 1,
            sets: [{ reps: 6, percentWM: 85 },{ reps: 5, percentWM: 87 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 5, percentWM: 87 },{ reps: 6, percentWM: 85 }]
        },
        {
            title: "Ascending Pyramid",
            type: 1,
            sets: [{ reps: 12, percentWM: 67 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 },{ reps: 2, percentWM: 95 }]
        },
        {
            title: "Narrow Descending Pyramid",
            type: 1,
            sets: [{ reps: 1, percentWM: 100 },{ reps: 2, percentWM: 95 },{ reps: 3, percentWM: 93 },{ reps: 4, percentWM: 90 },{ reps: 5, percentWM: 87 },{ reps: 6, percentWM: 85 }]
        },
        {
            title: "Descending Plateau",
            type: 1,
            sets: [{ reps: 2, percentWM: 95 },{ reps: 2, percentWM: 95 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 6, percentWM: 85 },{ reps: 6, percentWM: 85 }]
        },
        {
            title: "Ascending Plateau - Back Off Set",
            type: 1,
            sets: [{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 },{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 1, percentWM: 100 },{ reps: 6, percentWM: 85 }]
        },
        {
            title: "Wave Loading 1 (6-4-2)",
            type: 1,
            sets: [{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 },{ reps: 2, percentWM: 95 },{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 },{ reps: 2, percentWM: 95 }]
        },
        {
            title: "Wave Loading 2 (7-5-3)",
            type: 1,
            sets: [{ reps: 7, percentWM: 83 },{ reps: 5, percentWM: 87 },{ reps: 3, percentWM: 93 },{ reps: 7, percentWM: 83 },{ reps: 5, percentWM: 87 },{ reps: 3, percentWM: 93 }]
        },
        {
            title: "Wave Loading 3 (7-6-5)",
            type: 1,
            sets: [{ reps: 7, percentWM: 83 },{ reps: 6, percentWM: 85 },{ reps: 5, percentWM: 87 },{ reps: 7, percentWM: 83 },{ reps: 6, percentWM: 85 },{ reps: 5, percentWM: 87 }]
        },
        {
            title: "1-6 Method Contrast",
            type: 1,
            sets: [{ reps: 1, percentWM: 100 },{ reps: 6, percentWM: 85 },{ reps: 1, percentWM: 100 },{ reps: 6, percentWM: 85 },{ reps: 1, percentWM: 100 },{ reps: 6, percentWM: 85 }]
        },
        {
            title: "Progressive Plateau",
            type: 1,
            sets: [{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 },{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 7, percentWM: 83 },{ reps: 7, percentWM: 83 }]
        },

        // Functional Hypertrophy
        {
            title: "Broad Pyramid",
            type: 2,
            sets: [{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 6, percentWM: 85 },{ reps: 8, percentWM: 80 }]
        },
        {
            title: "Descending Pyramid",
            type: 2,
            sets: [{ reps: 2, percentWM: 95 },{ reps: 4, percentWM: 90 },{ reps: 6, percentWM: 85 },{ reps: 8, percentWM: 80 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 67 }]
        },
        {
            title: "Ascending Pyramid - Back Off Set",
            type: 2,
            sets: [{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 6, percentWM: 85 },{ reps: 12, percentWM: 67 }]
        },
        {
            title: "Ascending Plateau - Back Off Set",
            type: 2,
            sets: [{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 6, percentWM: 85 },{ reps: 10, percentWM: 75 }]
        },
        {
            title: "1-8 Method Contrast",
            type: 2,
            sets: [{ reps: 1, percentWM: 100 },{ reps: 8, percentWM: 80 },{ reps: 1, percentWM: 100 },{ reps: 8, percentWM: 80 },{ reps: 1, percentWM: 100 },{ reps: 8, percentWM: 80 }]
        },
        {
            title: "Wave Loading (8-6-4)",
            type: 2,
            sets: [{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 },{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 }]
        },
        {
            title: "Progressive Plateau 1",
            type: 2,
            sets: [{ reps: 6, percentWM: 85 },{ reps: 6, percentWM: 85 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 }]
        },
        {
            title: "Progressive Plateau 2",
            type: 2,
            sets: [{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 },{ reps: 6, percentWM: 85 },{ reps: 6, percentWM: 85 },{ reps: 10, percentWM: 75 },{ reps: 10, percentWM: 75 }]
        },

        // Hypertrophy
        {
            title: "Broad Pyramid",
            type: 3,
            sets: [{ reps: 15, percentWM: 65 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 },{ reps: 10, percentWM: 75 },{ reps: 15, percentWM: 65 }]
        },
        {
            title: "Ascending Plateau - Back Off Set 1",
            type: 3,
            sets: [{ reps: 12, percentWM: 67 },{ reps: 12, percentWM: 67 },{ reps: 10, percentWM: 75 },{ reps: 10, percentWM: 75 },{ reps: 15, percentWM: 65 }]
        },
        {
            title: "Ascending Plateau - Back Off Set 2",
            type: 3,
            sets: [{ reps: 10, percentWM: 75 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 },{ reps: 12, percentWM: 67 }]
        },
        {
            title: "Wave Loading (15-10-8)",
            type: 3,
            sets: [{ reps: 15, percentWM: 65 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 },{ reps: 15, percentWM: 65 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 }]
        },
        {
            title: "Progressive Plateau",
            type: 3,
            sets: [{ reps: 10, percentWM: 75 },{ reps: 10, percentWM: 75 },{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 },{ reps: 12, percentWM: 67 },{ reps: 12, percentWM: 67 }]
        },

        // Straight Sets
        {
            title: "Peak Strength (6x1)",
            type: 0,
            sets: [{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 },{ reps: 1, percentWM: 100 }]
        },
        {
            title: "Peak Strength - Non-Powerlifting (5x2)",
            type: 0,
            sets: [{ reps: 2, percentWM: 95 },{ reps: 2, percentWM: 95 },{ reps: 2, percentWM: 95 },{ reps: 2, percentWM: 95 },{ reps: 2, percentWM: 95 }]
        },
        {
            title: "Absolute Strength (6x3)",
            type: 1,
            sets: [{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 },{ reps: 3, percentWM: 93 }]
        },
        {
            title: "Absolute Strength (5x4)",
            type: 1,
            sets: [{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 },{ reps: 4, percentWM: 90 }]
        },
        {
            title: "Absolute Strength (5x5)",
            type: 1,
            sets: [{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 },{ reps: 5, percentWM: 87 }]
        },
        {
            title: "Functional Hypertrophy (3x8)",
            type: 2,
            sets: [{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 },{ reps: 8, percentWM: 80 }]
        },
        {
            title: "Hypertrophy (3x10)",
            type: 3,
            sets: [{ reps: 10, percentWM: 75 },{ reps: 10, percentWM: 75 },{ reps: 10, percentWM: 75 }]
        },
        {
            title: "Hypertrophy (3x12)",
            type: 3,
            sets: [{ reps: 12, percentWM: 67 },{ reps: 12, percentWM: 67 },{ reps: 12, percentWM: 67 }]
        },
    ]

    let defaultNumSets = 3;
    let defaultSearchString = "Bench";

    let exerciseTypeStrings = ["Isotonic", "Isometric", "Cardio"];
    let muscle_groups = ["All Muscle Groups", "Abs","Arms","Back","Chest","Shoulders","Legs","Butt","Node Compatible"];
    let blankTempo = {eccentric: -1, first_pause: -1, concentric: -1, second_pause: -1};
    let pWM_models = ["Advanced", "Standard"];
    let pWM_models_ex = ["100, 94.3, 90.6, 90.6, 88.1, 85.6, 83.1, 80.7, 78.6, 76.5", "100, 95, 93, 90, 87, 85, 83, 80, 77, 75"];
    const [initSet, setInitSet] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);
    const [searchStringError, setSearchStringError] = useState(false);
    const [searchState, setSearchState] = useState(0); // 0 = no search, 1 = loading, 2 = display results, 3 = no results found
    const [searchString, setSearchString] = useState('');
    const [searchGroup, setSearchGroup] = useState(0);
    const [searchResults, setSearchResults] = useState<Exercise_t[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
    const [exerciseToEdit, setExerciseToEdit] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[]});
    const [extraVideoLink, setExtraVideoLink] = useState("");
    const [loadingPatterns, setLoadingPatterns] = useState<LoadingPattern_t[]>([]);

    const [showOptions, setShowOptions] = useState(false);
    const [filterNodeCompatible, setFilterNodeCompatible] = useState(true);

    const [defaultRepRangeMin, setDefaultRepRangeMin] = useState(0);
    const [defaultRepRangeMax, setDefaultRepRangeMax] = useState(1);
    const [defaultRestTime, setDefaultRestTime] = useState(0);
    const [defaultPWMs, setDefaultPWMs] = useState(0);
    const [defaultDistance, setDefaultDistance] = useState(100);
    const [defaultTempo, setDefaultTempo] = useState({eccentric: -1, first_pause: -1, concentric: -1, second_pause: -1});

    const [useRepRange, setUseRepRange] = useState(false);
    const [useDefaultRestTime, setUseDefaultRestTime] = useState(true);
    const [usePercentWMs, setUsePercentWMs] = useState(false);
    const [forcePercentWMs, setForcePercentWMs] = useState(true);
    const [useTempos, setUseTempos] = useState(true);
    const [useDefaultDistance, setUseDefaultDistance] = useState(false);

    const [useLoadingPatterns, setUseLoadingPatterns] = useState(false);
    const [showSets, setShowSets] = useState(true);
    const [showNotes, setShowNotes] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showLoadingPatternMenu, setShowLoadingPatternMenu] = useState(false);
    const [showPWMsModelMenu, setShowPWMsModelMenu] = useState(false);

    const [notesFocused, setNotesFocused] = useState(false);

    const [selectedPWMsModel, setSelectedPWMsModel] = useState(1);

    const [loadingPatternGroup, setLoadingPatternGroup] = useState(0);
    const [loadingPatternSearchResults, setLoadingPatternSearchResults] = useState<LoadingPattern_t[]>(loadingPatterns);
    const [selectedLoadingPattern, setSelectedLoadingPattern] = useState<LoadingPattern_t>({title: '', type: -1, sets: []});
    const [hasEditedLoadingPattern, setHasEditedLoadingPattern] = useState(false);

    const [searchFocus, setSearchFocus] = useState(false);

    const [reps, setReps] = useState<number[]>([]);
    const [hasEditedSet, setHasEditedSet] = useState<boolean[]>([]);
    const [reps_max, setReps_max] = useState<number[]>([]);
    const [rest, setRest] = useState<number[]>([]);
    const [pWMs, setPWMs] = useState<number[]>([]);
    const [distances, setDistances] = useState<number[]>([]);
    const [tempos, setTempos] = useState<Tempo_t[]>([]); //[{eccentric: 1, first_pause: 0, concentric: 1, second_pause: 0}]
    const [showingSetMenu, setShowingSetMenu] = useState<boolean[]>([]);

    const [videoUrl, setVideoUrl] = useState("");
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedVideoUrl, setUploadedVideoUrl] = useState<string | null>(null);
    const [videoFile, setVideoFile] = useState<FileList | null>(null);
    const [isUploadingVideo, setIsUploadingVideo] = useState(false);
    const [isCustomVideo, setIsCustomVideo] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    // forced inputs
    const [reps_forced, setReps_forced] = useState<number[]>([]);

    const [note, setNote] = useState("");
    const [noteTemp, setNoteTemp] = useState("");

    const [willSaveLoadingPattern, setWillSaveLoadingPattern] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setSearchState(0);
        configureLoadingPatterns();
        //searchExercises("Bench", 0);
    }

    useEffect(() => {
        if (props.forceSetCount !== undefined && props.forceSetCount > 0) setupInitialSets(props.forceSetCount)
    }, [props.forceSetCount])

    function backButtonPressed() {
        if (workflowState === 0) {
            props.backToExerciseList(null);
            setSearchStringError(false);
            setSearchResults([]);
            setSearchState(0);
        } else if (workflowState === 1) {
            if (props.exerciseToEdit.id !== '') {
                props.backToExerciseList(null);
                setSearchStringError(false);
                setSearchResults([]);
                setSearchState(0);
                cleanUpExercise();
            } else {
                setWorkflowState(0);
            }
        }
    }

    function backToExerciseList() {
        props.backToExerciseList(null);
    }

    function configureLoadingPatterns() {

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`loading_patterns/${trainerUID}`);
        ref.on('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data: any = snapshot.val();
                // console.log("DATA:", data);
                let keys: string[] = Object.keys(data);

                // 1. Compose category list
                var categoryList: string[] = ["All",
                                              "Relative Strength", 
                                              "Absolute Strength", 
                                              "Functional Hypertrophy", 
                                              "Hypertrophy"];

                for (var i = 0; i < keys.length; i++) {
                    let thisKey: string = keys[i];
                    let thisLPObj: any = data[thisKey];
                    
                    if (thisLPObj !== undefined && thisLPObj.category !== undefined) {
                        if (categoryList.indexOf(thisLPObj.category) < 0) {
                            categoryList.push(thisLPObj.category);
                        }
                    }                    
                }

                setLoadingPatternTypes(categoryList);
                
                // 2. Compose loading pattern List
                var loadingPatterns_temp: LoadingPattern_t[] = loadingPatterns_default;
                for (var i = 0; i < keys.length; i++) {
                    let thisKey: string = keys[i];
                    let thisLPObj: any = data[thisKey];
                    
                    var categoryIndex = 0;
                    if (thisLPObj !== undefined && thisLPObj.category !== undefined) {
                        categoryIndex = categoryList.indexOf(thisLPObj.category) - 1;
                    }      

                    // Compose set list
                    var setList: any[] = [];
                    if (thisLPObj !== undefined && thisLPObj.reps !== undefined) {
                        for (var j = 0; j < thisLPObj.reps.length; j++) {
                            let thisRep: number = thisLPObj.reps[j];
                            var thisRepPWM = 0;
                            if (thisLPObj.pwms !== undefined && thisLPObj.pwms[j] !== undefined) {
                                thisRepPWM = thisLPObj.pwms[j]
                            } else {
                                thisRepPWM = thisRep < standard_LUT.length && thisRep >= 0 ? standard_LUT[thisRep] : 50;
                            }
                            setList.push({
                                reps: thisRep,
                                percentWM: thisRepPWM
                            })
                        }
                    }

                    let thisLoadingPattern: LoadingPattern_t = {
                        title: thisLPObj.title !== undefined ? thisLPObj.title : "Custom loading pattern",
                        type: categoryIndex,
                        sets: setList
                    }

                    loadingPatterns_temp.push(thisLoadingPattern);

                }

                setLoadingPatterns(loadingPatterns_temp);

                // console.log("LOADING PATTERN CATEGORY List:", categoryList, loadingPatterns_temp);
                //setCategories(categoryList);
                //setIsLoading(false);
            } else {
                //setIsLoading(false);
                setLoadingPatterns(loadingPatterns_default);
            }
        });
    }

    useEffect(() => {
        if (willSaveLoadingPattern) {
            return;
        }
        setSelectedExercise(props.exerciseToEdit);

        // console.log("useEffect | EDITING: ", props.exerciseToEdit);

        if (props.exerciseToEdit.id !== ''  && props.exerciseToEdit.reps !== undefined) {
            // console.log('A');
            // Check if using rest times
            var useRest_temp = true;
            if (props.exerciseToEdit.restTime !== undefined && props.exerciseToEdit.restTime.length > 0) {
                for (var i = 0; i < props.exerciseToEdit.restTime.length; i++) {
                    if (props.exerciseToEdit.restTime[i] === -1) {
                        useRest_temp = false;
                    }
                }
            } else {
                useRest_temp = false;
            }

            // Check if using %WMs
            var usePWM_temp = true;
            if (props.exerciseToEdit.percent_wms !== undefined && props.exerciseToEdit.percent_wms.length > 0) {
                for (var i = 0; i < props.exerciseToEdit.percent_wms.length; i++) {
                    if (props.exerciseToEdit.percent_wms[i] === -1) {
                        usePWM_temp = false;
                    }
                }
            } else {
                usePWM_temp = false;
            }

            // Check if using Tempo
            var useTempo_temp = true;
            if (props.exerciseToEdit.tempos !== undefined && props.exerciseToEdit.tempos.length > 0) {
                for (var i = 0; i < props.exerciseToEdit.tempos.length; i++) {
                    if (props.exerciseToEdit.tempos[i].concentric === -1) {
                        useTempo_temp = false;
                    }
                }
            } else {
                useTempo_temp = false;
            }

            setUseDefaultRestTime(useRest_temp); //(props.exerciseToEdit.restTime !== undefined && props.exerciseToEdit.restTime.length > 0);
            setUsePercentWMs(usePWM_temp); //(props.exerciseToEdit.percent_wms !== undefined && props.exerciseToEdit.percent_wms.length > 0)
            setUseTempos(useTempo_temp); //(props.exerciseToEdit.tempos !== undefined && props.exerciseToEdit.tempos.length > 0)

            setReps(props.exerciseToEdit.reps);
            setReps_forced(props.exerciseToEdit.reps);
            setReps_max(props.exerciseToEdit.reps);

            setRest(props.exerciseToEdit.restTime === undefined ? [] : props.exerciseToEdit.restTime);
            setPWMs(props.exerciseToEdit.percent_wms === undefined ? [] : props.exerciseToEdit.percent_wms);
            setTempos(props.exerciseToEdit.tempos === undefined ? [] : props.exerciseToEdit.tempos);

            setShowSets(true);

            if (props.exerciseToEdit.loadingPattern !== undefined && props.exerciseToEdit.loadingPattern.type >= 0) {
                setSelectedLoadingPattern(props.exerciseToEdit.loadingPattern);
                setUseLoadingPatterns(true);
            }

            if (props.exerciseToEdit.note !== undefined) {
                setNote(props.exerciseToEdit.note);
            }

            setWorkflowState(1);
        } else if (props.exerciseToEdit.id !== ''  && props.exerciseToEdit.reps === undefined) {
            // console.log('B');
            setupInitialSets(props.forceSetCount !== undefined && props.forceSetCount > 0 ? props.forceSetCount : 3);
            setWorkflowState(1);
        } else {
            // console.log('C');
            setReps([]);
            setReps_max([]);
            setHasEditedSet([]);
            setRest([]);
            setPWMs([]);
            setTempos([]);
            setReps_forced([]);
            setLoadingPatternSearchResults(loadingPatterns);
            setLoadingPatternGroup(0);
            setupInitialSets(props.forceSetCount !== undefined && props.forceSetCount > 0 ? props.forceSetCount : 3);
            //setUseDefaultRestTime(false);

            setWorkflowState(0);
            setUseTempos(true);

            setSearchState(0);
            setSearchString("");
            setNote("");
            //searchExercises(searchGroup === 0 ? defaultSearchString : muscle_groups[searchGroup], searchGroup);
            
        }
    }, [props.exerciseToEdit]);

    useEffect(() => {
        updateVideo();
    }, [selectedExercise])

    useEffect(() => {
        if (!filterNodeCompatible) {
            setSearchState(1);
            searchExercises(searchString);
        }
        
    }, [filterNodeCompatible])

    function updateVideo() {
        if (selectedExercise && selectedExercise.id !== "") {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { return; }
            let trainerUID = currentUser.uid;
            setIsCustomVideo(false);
            setLoadingVideo(true);

            let storage = firebase.storage();
            let storageRef_trainer = storage.ref(`videos/exercise/${selectedExercise.id}/${trainerUID}/video.mov`);
            storageRef_trainer.getDownloadURL()
            .then(fireBaseUrl_trainer => {
                setVideoUrl(fireBaseUrl_trainer);
                setLoadingVideo(false);
                setIsCustomVideo(true);
            })
            .catch(error => {
                // No video found
                setIsCustomVideo(false);
                let storageRef = storage.ref(`videos/exercise/${selectedExercise.id}/video.mov`);
                storageRef.getDownloadURL()
                .then(fireBaseUrl => {
                    setVideoUrl(fireBaseUrl);
                    setLoadingVideo(false);
                })
                .catch(error => {
                    setVideoUrl("");
                    setLoadingVideo(false);
                })
           })
        }
    }

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'muscle-group':
                let indexOfGroup = muscle_groups.indexOf(toString);
                if (indexOfGroup >= 0) {
                    if (indexOfGroup === muscle_groups.length - 1) {
                        setFilterNodeCompatible(false);
                        // setSearchState(1);
                        // searchExercises(searchString);
                    } else {
                        setSearchGroup(indexOfGroup);
                        setFilterNodeCompatible(true);
                        setSearchState(1);
                        searchExercises(indexOfGroup === 0 ? defaultSearchString : muscle_groups[indexOfGroup], indexOfGroup);
                    }

                    // setSearchState(1);
                    //     searchExercises(indexOfGroup === 0 ? defaultSearchString : muscle_groups[indexOfGroup], indexOfGroup);
                    
                } else {
                    setFilterNodeCompatible(true);
                    if (searchState === 3) {
                        setSearchState(0);
                    }
                }
                break;
            case 'exercise-name':
                setSearchString(toString);
                if (searchStringError === true && toString.split('').length > 2) {
                    setSearchStringError(false);
                } else if (toString.split('').length > 2) {
                    setSearchState(1);
                    searchExercises(toString)
                    return;
                }

                if (searchState === 2 || searchState === 3) {
                    setSearchState(0);
                }

                break;
            case 'num-sets':
                setNumberOfSets(Number(toString));
                break;
            case 'loading-pattern':
                searchLoadingPatterns(toString);
                break;
            case 'loading-pattern-type':
                setShowLoadingPatternMenu(true);
                let indexOfPatternGroup = loadingPatternTypes.indexOf(toString);
                if (indexOfPatternGroup >= 0) {
                    setLoadingPatternGroup(indexOfPatternGroup);

                    setSearchState(1);
                    searchLoadingPatterns("", indexOfPatternGroup);
                }
                break;
            case 'note':
                setNoteTemp(toString);

                break;
            default:
                break;
        }
    }

    function updateNoteFocusState(toState: boolean) {
        setNotesFocused(toState);
        setNote(noteTemp);
    }

    function searchLoadingPatterns(withString: string, groupNumber?: number) {

        var loadingPatterns_temp: LoadingPattern_t[] = [];
        let useGroupNum = groupNumber === undefined ? loadingPatternGroup : groupNumber;


        if (withString.split('').length <= 2) {
            if (groupNumber === undefined) {
                loadingPatterns_temp = loadingPatterns;
            } else if (useGroupNum === 0) {
                loadingPatterns_temp = loadingPatterns;
            } else {
                for (var i = 0; i < loadingPatterns.length; i++) {
                    let thisLoadingPattern: LoadingPattern_t = loadingPatterns[i];
                    if (thisLoadingPattern.type + 1 === useGroupNum) {
                        loadingPatterns_temp.push(thisLoadingPattern);
                    }
                }
            }
        } else {

            for (var i = 0; i < loadingPatterns.length; i++) {
                let thisLoadingPattern: LoadingPattern_t = loadingPatterns[i];
                if (thisLoadingPattern.title.toUpperCase().includes(withString.toUpperCase()) && (thisLoadingPattern.type + 1 === useGroupNum || useGroupNum === 0)) {
                    loadingPatterns_temp.push(thisLoadingPattern);
                }
            }
        }

        setLoadingPatternSearchResults(loadingPatterns_temp);
    }

    function setNumberOfSets(toNum: number) {
        //setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN setNumberOfSets')

        if (toNum < reps.length && reps.length > 1) {


            setReps(reps.filter((item: number, index: number) => { return index + 1 <= toNum }));
            setReps_max(reps_max.filter((item: number, index: number) => { return index + 1 <= toNum }));
            setHasEditedSet(hasEditedSet.filter((item: boolean, index: number) => { return index + 1 <= toNum }));
            setRest(rest.filter((item: number, index: number) => { return index + 1 <= toNum }));
            setPWMs(pWMs.filter((item: number, index: number) => { return index + 1 <= toNum }));
            setTempos(tempos.filter((item: Tempo_t, index: number) => { return index + 1 <= toNum }));
            setShowingSetMenu(showingSetMenu.filter((item: boolean, index: number) => { return index + 1 <= toNum }))

        } else if (toNum > reps.length) {
            let addReps = useRepRange ? defaultRepRangeMin : (reps.length > 0 ? reps[reps.length - 1] : 0);
            
            let addReps_max = defaultRepRangeMax;
            let addRest = defaultRestTime; //rest.length > 0 ? rest[rest.length - 1] : defaultRestTime;
            let addPWMs = defaultPWMs;
            let addTempo = defaultTempo;

            var reps_temp: number[] = [];
            var reps_max_temp: number[] = [];
            var has_edited_temp: boolean[] = [];
            var rest_temp: number[] = [];
            var pWMs_temp: number[] = [];
            var tempos_temp: Tempo_t[] = [];

            for (var i = reps.length; i < toNum; i++) {
                reps_temp.push(addReps);
                reps_max_temp.push(addReps_max);
                rest_temp.push(addRest);
                pWMs_temp.push(addPWMs);
                tempos_temp.push(addTempo);
            }

            setReps(reps.concat(reps_temp));
            setReps_max(reps_max.concat(reps_max_temp));
            setHasEditedSet(hasEditedSet.concat(has_edited_temp));
            setRest(rest.concat(rest_temp));
            setPWMs(pWMs.concat(pWMs_temp));
            setTempos(tempos.concat(tempos_temp));
            setShowingSetMenu(showingSetMenu.concat(false));
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            searchButtonPressed()
        }
    }

    function searchButtonPressed() {
        //// console.log(`Searching in ${muscle_groups[searchGroup]} for ${searchString}`);
        let searchCriteriaPassed = searchString.split('').length > 2;
        setSearchStringError(!searchCriteriaPassed);
        if (searchCriteriaPassed === true) {
            // Hit search API
            setSearchState(1);
            searchExercises();
        }
    }

    function searchExercises(searchWith?: string, withGroup?: number) {
        let sendData = {
            text: searchWith === undefined || searchWith === null ? searchString : searchWith,
            muscle_group: withGroup === undefined || withGroup === null ? searchGroup - 1 : withGroup - 1
        };
        // console.log("SEARCHING WITH: ", sendData);
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/searchExercises`,
            sendData,
            { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            // console.log(response.data);
            setSearchState(2);
            let responseData = response.data;
            let responseExerciseArray: any[] = responseData.resp;
            if (responseExerciseArray.length === 0) {
                // No exercises found for search. Present no results found page (state 3)
                setSearchResults(searchResults.filter(item => false));
                setSearchState(3);
            } else {
                var tempExerciseArray: Exercise_t[] = [];
                for (var i = 0; i < responseExerciseArray.length; i++) {
                    let thisExerciseObj = responseExerciseArray[i];
                    let thisExerciseMuscles: Muscles_t[] = [];

                    if (thisExerciseObj.muscles !== null && thisExerciseObj.muscles !== undefined) {

                        for (var j = 0; j < thisExerciseObj.muscles.length; j++) {
                            let thisMuscle = thisExerciseObj.muscles[j];
                            let newMuscle: Muscles_t = {
                                group: Number(thisMuscle.group),
                                muscle: Number(thisMuscle.muscle)
                            }
                            thisExerciseMuscles.push(newMuscle);
                        }
                    }

                    let newExercise: Exercise_t = {
                        title: thisExerciseObj.title,
                        id: thisExerciseObj.id,
                        createdBy: {name: thisExerciseObj.created_by.name, id: thisExerciseObj.created_by.id},
                        muscles: thisExerciseMuscles,
                        type: thisExerciseObj.type,
                        equipment: thisExerciseObj.equipment,
                        tags: thisExerciseObj.tags,
                        setup: thisExerciseObj.setup,
                        steps: thisExerciseObj.steps,
                        motionInfo: thisExerciseObj.motion_info 
                    }
                    if (filterNodeCompatible && thisExerciseObj.approved) {
                        tempExerciseArray.push(newExercise);
                    } else if (!filterNodeCompatible) {
                        tempExerciseArray.push(newExercise);
                    }
                    
                }
                setSearchResults(tempExerciseArray);
            }
        })
        .catch(e => {
            // console.log(e);
            setSearchState(3);
        })
    }

    function exerciseSelected(e: Exercise_t) {
        //// console.log(`${e.title} selected`);
        // console.log("SELECTED:", e.title, e.id);
        setSelectedExercise(e);
        //setupInitialSets(numSets);
        
        setWorkflowState(1);
    }

    function getMuscleNames() {
        if (selectedExercise.muscles && selectedExercise.muscles.length > 0) {
           
            var muscleStringTemp = '';
            for (var i = 0; i < selectedExercise.muscles.length; i++) {
                let thisMuscle = selectedExercise.muscles[i];
                let thisString = muscleNames[thisMuscle.group][thisMuscle.muscle];
                muscleStringTemp += i === 0 ? thisString : `, ${thisString}`;
            }
            return muscleStringTemp;
        }

        return 'No muscles specified'
    }

    function toggleUseRestTime() {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN toggleUseRestTime')

        if (useDefaultRestTime === false) {
            setRest(
                rest.map((item, index) => {
                    return defaultRestTime;
                })
            );
        }
        setUseDefaultRestTime(!useDefaultRestTime);
    }

    function toggleUseDistance() {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN toggleUseDistance')

        if (useDefaultDistance === false) {
            setDistances(
                distances.map((item, index) => {
                    return defaultDistance;
                })
            );
        }
        setUseDefaultDistance(!useDefaultDistance);
    }

    function toggleUsePWMs() {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN toggleUsePWMs')

        if (usePercentWMs === false) {
            setPWMs(
                pWMs.map((item, index) => {
                    return defaultPWMs;
                })
            );
        }

        if (usePercentWMs === false) {
            setForcePercentWMs(true);
            switch(selectedPWMsModel) {
                case 0:         // Poliquin
                    setPWMs(
                        pWMs.map((item, index) => {
                            let thisNumReps = reps[index];
                            let thisPWM = thisNumReps < poliquin_LUT.length && thisNumReps >= 0 ? poliquin_LUT[thisNumReps] : 50;
                            return thisPWM;
                        })
                    );
                    break;
                case 1:         // Standard
                    setPWMs(
                        pWMs.map((item, index) => {
                            let thisNumReps = reps[index];
                            let thisPWM = thisNumReps < standard_LUT.length && thisNumReps >= 0 ? standard_LUT[thisNumReps] : 50;
                            return thisPWM;
                        })
                    );
                    break;
                default:
                    break;
            }
        }
        setUsePercentWMs(!usePercentWMs);
    }

    function toggleForcePWMs() {
        setHasEditedLoadingPattern(true);

        if (forcePercentWMs === false) {
            switch(selectedPWMsModel) {
                case 0:         // Poliquin
                    setPWMs(
                        pWMs.map((item, index) => {
                            let thisNumReps = reps[index];
                            let thisPWM = thisNumReps < poliquin_LUT.length && thisNumReps >= 0 ? poliquin_LUT[thisNumReps] : 50;
                            return thisPWM;
                        })
                    );
                    break;
                case 1:         // Standard
                    setPWMs(
                        pWMs.map((item, index) => {
                            let thisNumReps = reps[index];
                            let thisPWM = thisNumReps < standard_LUT.length && thisNumReps >= 0 ? standard_LUT[thisNumReps] : 50;
                            return thisPWM;
                        })
                    );
                    break;
                default:
                    break;
            }
        }

         setForcePercentWMs(!forcePercentWMs);

        // if (usePercentWMs === false) {
        //     setPWMs(
        //         pWMs.map((item, index) => {
        //             return defaultPWMs;
        //         })
        //     );
        // }
        //setUsePercentWMs(!usePercentWMs);
    }

    function toggleUseTempos() {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN toggleUseTempos')

        if (useTempos === false) {
            setTempos(
                tempos.map((item, index) => {
                    return defaultTempo;
                })
            );
        }
        setUseTempos(!useTempos);
    }

    function toggleUseRepRanges() {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN toggleUseRepRanges')

        if (useDefaultRestTime === false) {
            setReps(
                reps.map((item, index) => {
                    return defaultRepRangeMin;
                })
            );

            setReps_max(
                reps_max.map((item, index) => {
                    return defaultRepRangeMax;
                })
            );
        }
        
        setUseRepRange(!useRepRange);
    }

    function toggleShowingSetMenu(forIndex: number) {

        setShowingSetMenu(showingSetMenu.map((item: boolean, index) => {
            if (index === forIndex) {
                return !item;
            } else {
                return item;
            }
        }));
    }

    function setupInitialSets(numSets: number) {
        let addReps = useRepRange ? defaultRepRangeMin : (reps.length > 0 ? reps[reps.length - 1] : 0);
        let addReps_max = defaultRepRangeMax;
        let addRest = defaultRestTime; //rest.length > 0 ? rest[rest.length - 1] : defaultRestTime;
        let addPWMs = defaultPWMs;
        let addTempo = defaultTempo;
        let addDistance = defaultDistance;

        var initReps: any = [];
        var initReps_max: any = [];
        var initHasEdited: any = [];
        var initRests: any = [];
        var initPWMs: any = [];
        var initTempos: any = [];
        var initDistances: any = [];
        var initShowSetMenus: any = [];

        for (var i = 0; i < numSets; i++) {
            initReps.push(addReps);
            initReps_max.push(addReps_max);
            initHasEdited.push(false);
            initRests.push(addRest);
            initPWMs.push(addPWMs);
            initTempos.push(addTempo);
            initDistances.push(addDistance);
            initShowSetMenus.push(false);
        }

        setReps(initReps);
        setReps_max(initReps_max);
        setHasEditedSet(initHasEdited);
        setRest(initRests);
        setPWMs(initPWMs);
        setTempos(initTempos);
        setDistances(initDistances);
        setShowingSetMenu(initShowSetMenus);
    }

    function addAnotherSet() {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN add another set')

        let addReps = useRepRange ? defaultRepRangeMin : (reps.length > 0 ? reps[reps.length - 1] : 0);
        let addReps_max = defaultRepRangeMax;
        let addHasEdited = false;
        let addRest = rest.length > 0 ? rest[rest.length - 1] : defaultRestTime;
        var addPWMs = defaultPWMs;
        let addTempo = tempos.length > 0 ? tempos[tempos.length - 1] : defaultTempo; //defaultTempo;

        if (forcePercentWMs === true) {
            switch(selectedPWMsModel) {
                case 0:         // Poliquin
                    addPWMs = addReps < poliquin_LUT.length && addReps >= 0 ? poliquin_LUT[addReps] : 50;
                    break;
                case 1:         // Standard
                    addPWMs = addReps < standard_LUT.length && addReps >= 0 ? standard_LUT[addReps] : 50;
                    break;
                default:
                    break;
            }
        }

        setReps(reps.concat(addReps));
        setReps_max(reps_max.concat(addReps_max));
        setHasEditedSet(hasEditedSet.concat(addHasEdited));
        setRest(rest.concat(addRest));
        setPWMs(pWMs.concat(addPWMs));
        setTempos(tempos.concat(addTempo));
        setShowingSetMenu(showingSetMenu.concat(false));

    }

    function setRestTime(toTime: any) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN setRestTime')

        // console.log(`Setting rest time to: ${toTime}s`);
        setDefaultRestTime(Number(toTime));
        setRest(
            rest.map((item, index) => {
                return Number(toTime);
            })
        );
    }

    function setDefaultPWM(toPWM: any) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN setDefaultPWM')

        setDefaultPWMs(Number(toPWM));
        setPWMs(
            pWMs.map((item, index) => {
                return toPWM;
            })
        );
    }

    function updateDefaultDistance(toDist: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN updateDefaultDistance')

        setDefaultDistance(Number(toDist));
        setDistances(
            distances.map((item, index) => {
                return toDist;
            })
        );
    }

    function updateDefaultTempo(toTempo: Tempo_t) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN updateDefaultTempo')

        setDefaultTempo(toTempo);
        setTempos(
            tempos.map((item, index) => {
                return toTempo;
            })
        );
    }

    function updateDefaultRepRangeMin(toVal: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN updateDefaultRepRangeMin')

        setDefaultRepRangeMin(toVal);
        setReps(
            reps.map((item, index) => {
                return toVal;
            })
        );
    }

    function updateDefaultRepRangeMax(toVal: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN updateDefaultRepRangeMax')

        setDefaultRepRangeMax(toVal);
        setReps_max(
            reps_max.map((item, index) => {
                return toVal;
            })
        );
    }

    function getRepString(forRep: any) {
        return forRep === -1 ? 'AMRAP' : forRep;
    }

    function repInputUpdated(e: any, atIndex: number) {
        //// console.log(e)
        if (e === "a" || e === "A") {
            setReps(
                reps.map((item, index) => {
                    return index === atIndex ? -1 : item;
                })
            );
        }
    }

    function changeReps(toReps: any, atIndex: number) {

        
        // console.log("CreateWorkoutSetRepInput: changeReps | EDITED: ", toReps, reps);
        
        if (isNaN(toReps) === true || isNaN(Number(toReps))) {
            setReps(
                reps.map((item, index) => {
                    return index === atIndex ? -1 : item;
                })
            );
            return;
        }


        //// console.log(`Setting reps to: ${Number(toReps)}`);
        if (!useLoadingPatterns) {
            setReps(
                reps.map((item, index) => {
                    return index >= atIndex ? Number(toReps) : item;
                    // return index === atIndex ? Number(toReps) : (hasEditedSet[index] === false ? Number(toReps) : item);
                })
            );

            var reps_forced_temp: number[] = [];
            for (var i = 0; i < reps.length; i++) {
                if (i < atIndex) {
                    reps_forced_temp.push(reps[i]);
                } else if (i >= atIndex) {
                    reps_forced_temp.push(Number(toReps));
                }
            }
            // console.log("-- reps_forced_temp: ", reps_forced_temp);
            setReps_forced(reps_forced_temp);
        } else {
            setReps(
                reps.map((item, index) => {
                    return index === atIndex ? Number(toReps) : item;
                    // return index === atIndex ? Number(toReps) : (hasEditedSet[index] === false ? Number(toReps) : item);
                })
            );
        }
 
        if (useRepRange === false) {
            changeReps_max(Number(toReps), atIndex);
        }

        setHasEditedLoadingPattern(true);
        updatePWMsFromReps(Number(toReps), atIndex);
    }

    function updatePWMsFromReps(toReps: number, atIndex: number) {
        if (forcePercentWMs === true) {
            switch(selectedPWMsModel) {
                case 0:         // Poliquin
                    setPWMs(
                        pWMs.map((item, index) => {
                            let thisNumReps = index !== atIndex ? reps[index] : toReps;
                            let thisPWM = thisNumReps < poliquin_LUT.length && thisNumReps >= 0 ? poliquin_LUT[thisNumReps] : 50;
                            return thisPWM;
                        })
                    );
                    break;
                case 1:         // Standard
                    setPWMs(
                        pWMs.map((item, index) => {
                            let thisNumReps = index !== atIndex ? reps[index] : toReps;
                            let thisPWM = thisNumReps < standard_LUT.length && thisNumReps >= 0 ? standard_LUT[thisNumReps] : 50;
                            return thisPWM;
                        })
                    );
                    break;
                default:
                    break;
            }
        }
    }

    function changeDistance(toDist: any, atIndex: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN changeDistance')

        //// console.log(`Changing set ${atIndex + 1} to ${toDist}m distance`);
        setDistances(
            distances.map((item, index) => {
                return index === atIndex ? Number(toDist) : item;
            })
        );
    }

    function changeReps_max(toReps: any, atIndex: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN changeReps_max')

        //// console.log(`Changing MAX set ${atIndex + 1} to ${toReps} reps`);

        if (isNaN(toReps) === true || isNaN(Number(toReps))) {
            setReps_max(
                reps_max.map((item, index) => {
                    return index === atIndex ? -1 : item;
                })
            );
            return;
        }
        setReps_max(
            reps_max.map((item, index) => {
                return index === atIndex ? Number(toReps) : item;
            })
        );
    }

    function changePWMs(toPWM: any, atIndex: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN changePWMs')

        if (isNaN(toPWM)) {
            return;
        }

        //// console.log(`Changing set ${atIndex + 1} to ${toPWM} sets`);
        setPWMs(
            pWMs.map((item, index) => {
                return index === atIndex ? Number(toPWM) : item;
            })
        );

        // if (forcePercentWMs) {
        //     switch (selectedPWMsModel) {
        //         case 0: // Poliquin
        //             setReps(reps.map((item: number, index: number) => {
        //                 if (index !== atIndex) { return item; }

        //                 var thisReps = 1;
        //                 for (var i = 0; i < poliquin_LUT.length; i++) {
        //                     if (toPWM < poliquin_LUT[i]) {
        //                         thisReps = i + 1;
        //                     }
        //                 }
        //                 return thisReps;
        //             }))

        //         case 1: // Standard
        //             setReps(reps.map((item: number, index: number) => {
        //                 if (index !== atIndex) { return item; }

        //                 var thisReps = 1;
        //                 for (var i = 0; i < standard_LUT.length; i++) {
        //                     if (toPWM < poliquin_LUT[i]) {
        //                         thisReps = i + 1;
        //                     }
        //                 }
        //                 return thisReps;
        //             }))

        //             break;
        //         default: 
        //             break;
        //     }
        // }
    }

    function PWMsModelSelected(index_model: number) {
        setSelectedPWMsModel(index_model);
        setShowPWMsModelMenu(false);

        switch(index_model) {
            case 0:         // Poliquin
                setPWMs(
                    pWMs.map((item, index) => {
                        let thisNumReps = reps[index];
                        let thisPWM = thisNumReps < poliquin_LUT.length && thisNumReps >= 0 ? poliquin_LUT[thisNumReps] : 50;
                        return thisPWM;
                    })
                );

                break;
            case 1:         // Standard
                setPWMs(
                    pWMs.map((item, index) => {
                        let thisNumReps = reps[index];
                        let thisPWM = thisNumReps < standard_LUT.length && thisNumReps >= 0 ? standard_LUT[thisNumReps] : 50;
                        return thisPWM;
                    })
                );

                break;
            default:
                break;
        }
    }

    function getPWMFromRep(thisNumReps: number) {
        switch(selectedPWMsModel) {
            case 0:         // Poliquin
                return thisNumReps < poliquin_LUT.length && thisNumReps >= 0 ? poliquin_LUT[thisNumReps] : 50;

                break;
            case 1:         // Standard
                return thisNumReps < standard_LUT.length && thisNumReps >= 0 ? standard_LUT[thisNumReps] : 50;

                break;
            default:
                break;
        }
    }

    function changeRest(toRest: any, atIndex: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN changeRest')

        //// console.log(`Changing rest ${atIndex + 1} to ${toRest}s`);
        setRest(
            // rest.map((item, index) => {
            //     return index === atIndex ? Number(toRest) : item;
            // })
            rest.map((item, index) => {
                return index >= atIndex ? Number(toRest) : item;
            })
        );
    }

    function changeTempo(toTempo: Tempo_t, atIndex: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN changeTempo')

        setTempos(
            tempos.map((item: Tempo_t, index: number) => {
                return index >= atIndex ? toTempo : item;
            })
        );
    }

    function removeSet(atIndex: number) {
        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN removeSet')

        setReps(reps.filter((item, index) => index !== atIndex));
        setReps_max(reps_max.filter((item, index) => index !== atIndex));
        setRest(rest.filter((item, index) => index !== atIndex));
        setTempos(tempos.filter((item, index) => index !== atIndex));
        setPWMs(pWMs.filter((item, index) => index !== atIndex));
        setDistances(distances.filter((item, index) => index !== atIndex));
        setShowingSetMenu(showingSetMenu.filter((item, index) => index !== atIndex));
    }

    async function finishPressed() {
        // Set rest times to N/A (-1) or entered rest time
        var restTimes_temp: number[] = [];
        for (var i = 0; i < rest.length; i++) {
            restTimes_temp.push(useDefaultRestTime ? rest[i] : -1);
        }

        // Set distances to N/A (-1) or entered distance
        var distances_temp: number[] = [];
        if (selectedExercise.type === 2) {
            for (var i = 0; i < distances.length; i++) {
                distances_temp.push(useDefaultDistance ? distances[i] : -1);
            }
        }

        // Set % WM's to N/A (-1) or entered %WM
        var pWMs_temp: number[] = [];
        for (var i = 0; i < pWMs.length; i++) {
            pWMs_temp.push(usePercentWMs ? pWMs[i] : -1);
        }

        // Set tempos to N/A (-1) or entered tempos
        var tempos_temp: Tempo_t[] = [];
        for (var i = 0; i < tempos.length; i++) {
            tempos_temp.push(useTempos ? tempos[i] : blankTempo);
        }

        // Create list of rep ranges, if neccessary
        var repRangeList: {min: number, max: number}[] = [];
        if (useRepRange) {
            for (var i = 0; i < reps.length; i++) {
                let thisRepRange = {min: reps[i], max: i < reps_max.length ? reps_max[i] : reps[i]};
                repRangeList.push(thisRepRange);
            }
        }

        var finalExercise: Exercise_t = selectedExercise;
        finalExercise.reps = reps;
        finalExercise.restTime = restTimes_temp;
        finalExercise.percent_wms = pWMs_temp;
        finalExercise.tempos = tempos_temp;
        finalExercise.repRanges = repRangeList;
        if (selectedExercise.type === 2) {
            finalExercise.distances = distances_temp;
        }

        if (selectedLoadingPattern.type >= 0) {
            finalExercise.loadingPattern = selectedLoadingPattern;
        }

        if (note !== "") {
            finalExercise.note = note;
        }

        if (extraVideoLink !== undefined && extraVideoLink !== "") {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { return; }
            let trainerUID = currentUser.uid;
            let database = firebase.database();
            let ref = database.ref(`/exercises_trainer_addons/${trainerUID}/${finalExercise.id && finalExercise.id !== "" ? finalExercise.id : "_ERROR_"}/external_video_link`);
            await ref.set(extraVideoLink);
        }

        props.backToExerciseList(finalExercise);

        cleanUpExercise();
        
    }

    function cleanUpExercise() {
        setSearchString("");
        setSelectedLoadingPattern({title: '', type: -1, sets: []});
        setHasEditedLoadingPattern(false);
        setUseLoadingPatterns(false);
        setShowSets(true);
        setDefaultPWM(0);
        setSearchStringError(false);
        setSearchResults([]);
        setSearchState(0);
        setWorkflowState(0);
        setRest([]);
        setReps([]);
        setReps_max([]);
        setPWMs([]);
        setTempos([]);
        setDistances([]);
        setShowingSetMenu([]);
        setNote("");
        setExtraVideoLink("");
        setShowNotes(false);
        setDefaultRestTime(30);
        setSelectedExercise({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
        setWillSaveLoadingPattern(false);
    }

    function toggleShowOptions() {
        setShowOptions(!showOptions);
    }

    function toggleShowSets() {
        setShowSets(!showSets);
    }

    function toggleShowNotes() {
        setShowNotes(!showNotes);
    }

    function toggleShowVideo() {
        setShowVideo(!showVideo);
    }

    function toggleShowLoadingPatternMenu() {
        setShowLoadingPatternMenu(!showLoadingPatternMenu);
    }

    function toggleShowPWMsModelMenu() {
        setShowPWMsModelMenu(!showPWMsModelMenu);
    }

    function getSetLetter(index: number) {
        let offsetASCIICode = index + 65;
        return String.fromCharCode(offsetASCIICode);
    }

    function getExerciseDurationString() {
        let defaultRestTime = 45;
        let defaultRepTime = 3;
        var totalSeconds = 0;

        let isEstimated = useTempos === false || useDefaultRestTime === false;

        // Sum tempos
        if (useTempos === true) {
            for (var i = 0; i < tempos.length; i++) {
                var thisTempo: Tempo_t = tempos[i];
                thisTempo = {
                    eccentric: thisTempo.eccentric < 0 ? 2 : thisTempo.eccentric,
                    first_pause: thisTempo.first_pause < 0 ? 0 : thisTempo.first_pause,
                    concentric: thisTempo.concentric < 0 ? 1 : thisTempo.concentric,
                    second_pause: thisTempo.second_pause < 0 ? 0 : thisTempo.second_pause
                };
                let thisTempoTime = thisTempo.concentric + thisTempo.eccentric + thisTempo.first_pause + thisTempo.second_pause;
                totalSeconds += thisTempoTime * (reps[i] < 0 ? 12 : reps[i]);
            }
        } else {
            for (var i = 0; i < reps.length; i++) {
                totalSeconds += defaultRepTime * (reps[i] < 0 ? 12 : reps[i]);
            }
        }
        
        // Sum rests
        if (useDefaultRestTime) {
            for (var i = 0; i < rest.length; i++) {
                totalSeconds += rest[i]
            }
        } else {
            for (var i = 0; i < reps.length; i++) {
                totalSeconds += defaultRestTime
            }
        }

        if (totalSeconds === 0) {
            return 'Add sets for estimated time';
        }

        let mins = Math.floor(totalSeconds / 60);
        let secs = Math.floor(totalSeconds % 60);

        let finalString = `${isEstimated ? 'Estimated' : 'Total'} Time: ${mins < 10 ? '0' : ''}${mins}m ${secs < 10 ? '0' : ''}${secs}m`
        return finalString;
    }

    function resetSelectedExercise() {
        setSelectedExercise({title:'',id:'',type: 0,muscles:[],reps:[],restTime:[]});
        setShowSets(false);
    }


    function loadingPatternSelected(pattern: LoadingPattern_t) {
        setSelectedLoadingPattern(pattern);
        

        setUsePercentWMs(true);
        setUseRepRange(false);

        setShowSets(true);
        setShowLoadingPatternMenu(false);

        var newReps: number[] = [];
        var newPWMs: number[] = [];
        var newTempos: Tempo_t[] = [];
        var newRest: number[] = [];
        var newMenuShowing: boolean[] = [];

        for (var i = 0; i < pattern.sets.length; i++) {
            newReps.push(pattern.sets[i].reps);
            newPWMs.push(pattern.sets[i].percentWM);
            newTempos.push(defaultTempo);
            newRest.push(defaultRestTime);
            newMenuShowing.push(false);
        }

        let minPWM = Math.min.apply(Math, newPWMs);
        //setDefaultPWMs(minPWM);

        // console.log("SETTING REPS TO: ", newReps);
        setReps(newReps);
        setReps_forced(newReps);
        setPWMs(newPWMs);
        setTempos(newTempos);
        setRest(newRest);
        setShowingSetMenu(newMenuShowing);

        setHasEditedLoadingPattern(false);
        setTimeout(() => {
            setHasEditedLoadingPattern(false);
        }, 500);
    }
    function clearSelectedLoadingPattern() {
        setSelectedLoadingPattern({title: '', type: -1, sets: []});
    }


    function createNewExerciseFromWorkout() {
        props.createNewExerciseFromWorkout(searchString);
    }

    function autofillReps(fromIndex: number, toNum?: number) {

        setHasEditedLoadingPattern(true);
        let fillTo = toNum !== undefined ? toNum : reps[fromIndex];
        setReps(reps.map((item: number, index: number) => {
            return fillTo;
        }));
        if (reps_forced.length < reps.length) {
            let tempRepsForced: number[] = [];
            for (var i = 0; i < reps.length; i++) {
                tempRepsForced.push(fillTo);
            }
            setReps_forced(tempRepsForced);
        } else {
            setReps_forced(reps_forced.map((item: number, index: number) => {
                return fillTo;
            }));
        }
        setShowingSetMenu(showingSetMenu.map(item => { return false; }));
        if (forcePercentWMs === true) {
            setForcePercentWMs(false);
        }
    }
    
    function autofillPWM(fromIndex: number) {
        setHasEditedLoadingPattern(true);
        setPWMs(pWMs.map((item: number, index: number) => {
            return pWMs[fromIndex];
        }));
        setShowingSetMenu(showingSetMenu.map(item => { return false; }));
        if (forcePercentWMs === true) {
            setForcePercentWMs(false);
        }
    }

    function autofillRest(fromIndex: number) {
        setHasEditedLoadingPattern(true);
        setRest(rest.map((item: number, index: number) => {
            return rest[fromIndex];
        }));
        setShowingSetMenu(showingSetMenu.map(item => { return false; }));
    }

    function autofillTempos(fromIndex: number) {
        setHasEditedLoadingPattern(true);
        setTempos(tempos.map((item: Tempo_t, index: number) => {
            return tempos[fromIndex];
        }));
        setShowingSetMenu(showingSetMenu.map(item => { return false; }));
    }

    function autoFill(fromIndex: number) {

        setHasEditedLoadingPattern(true);
        //// console.log('SETTING HAS EDITED TRUE IN autoFill')

        Mixpanel.track("Copy set configuration",{from_index: fromIndex});
        //if (forcePercentWMs === false) {
        setReps(reps.map((item: number, index: number) => {
            return reps[fromIndex];
        }));

        if (reps_forced.length < reps.length) {
            let tempRepsForced: number[] = [];
            for (var i = 0; i < reps.length; i++) {
                tempRepsForced.push(reps[fromIndex]);
            }
            setReps_forced(tempRepsForced);
        } else {
            setReps_forced(reps_forced.map((item: number, index: number) => {
                return reps[fromIndex];
            }));
        }
        
        //}

        setReps_max(reps_max.map((item: number, index: number) => {
            return reps_max[fromIndex];
        }));

        setRest(rest.map((item: number, index: number) => {
            return rest[fromIndex];
        }));

        //if (forcePercentWMs === false) {
        setPWMs(pWMs.map((item: number, index: number) => {
            return pWMs[fromIndex];
        }));
        //}
        if (forcePercentWMs === true) {
            setForcePercentWMs(false);
        }

        setTempos(tempos.map((item: Tempo_t, index: number) => {
            return tempos[fromIndex];
        }));

        setDistances(distances.map((item: number, index: number) => {
            return distances[fromIndex];
        }));

        setShowingSetMenu(showingSetMenu.map(item => { return false; }));

    }

    window.addEventListener('click', function(e){   

        //checkCloseLoadingPatternMenu(e);
        checkClosePWMsModelMenu(e);
        checkSetRowMenus(e);
    });

    function checkCloseLoadingPatternMenu(e: any) {
        let buttonID = `create-workout-exercise-configure-loading-pattern-menu-button`;
        let menuID = `create-workout-exercise-configure-loading-pattern-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showLoadingPatternMenu === true) {
                    setShowLoadingPatternMenu(false);
                }
            }
        }
    }

    function checkClosePWMsModelMenu(e: any) {
        let buttonID = `pwms-model-selector-button`;
        let menuID = `pwms-model-selector-menu`;

        if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { return; }
        if (document.getElementById(menuID)!.contains(e.target as Node)){
        // Clicked in box
        } else {
            if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                if (showPWMsModelMenu === true) {
                    setShowPWMsModelMenu(false);
                }
            }
        }
    }

    function checkSetRowMenus(e: any) {
        var shouldCloseMenu = false;

        for (var i = 0; i < showingSetMenu.length; i++) {
            let buttonID = `set-${i}-${selectedExercise.id}-row-button`;
            let menuID = `set-${i}-${selectedExercise.id}-row-menu`;

            if (document === null || document.getElementById(menuID) === null || document.getElementById(buttonID) === null || e.target === null) { 
                //shouldCloseMenu = true; 
                //i = showingSetMenu.length;
                //break;
            } else {
                if (document.getElementById(menuID)!.contains(e.target as Node)){
                // Clicked in box
                } else {
                    if (!(document.getElementById(buttonID)!.contains(e.target as Node))) {
                        if (showingSetMenu[i] === true) {
                            shouldCloseMenu = true;
                        }
                    }
                }
            }
            
        }

        if (shouldCloseMenu === true) {
            setShowingSetMenu(showingSetMenu.map(item => { return false; }));
        }

    }


    

    const [showHelpMenu, setShowHelpMenu] = useState(false);

    function toggleHelpMenu() {
        setShowHelpMenu(!showHelpMenu);
    }

    function checkAllowShowSaveLoadingPattern() {

        // Allow saving loading pattern if not using a pre-set loading pattern
        // if (selectedLoadingPattern.type < 0) {
        //     return true;
        // }

        // // Allow saving if selected loading pattern has been edited 
        // if (selectedLoadingPattern.type >= 0 && hasEditedLoadingPattern) {
        //     return true;
        // }


        // Check if any reps are un-entered or 0
        var invalidReps = false;
        for (var i = 0; i < reps.length; i++) {
            let thisRep: number = reps[i];
            if (thisRep === undefined || isNaN(thisRep) || thisRep === 0) {
                invalidReps = true;
            }
        }

        return (!invalidReps) && (selectedLoadingPattern.type < 0 || (selectedLoadingPattern.type >= 0 && hasEditedLoadingPattern));

    }

    function saveLoadingPatternPressed() {
        if (!checkAllowShowSaveLoadingPattern()) { return; }
        setWillSaveLoadingPattern(true);
        props.saveLoadingPatternPressed(reps);
    }

    const handleFileDropped = (files: FileList | null, event: any) => {
        // console.log(">>handleFileDropped:", files ? files[0] : "NADA");
        setVideoFile(files ? files : null);

        // Upload to temp
        if (files === null) return;
        
        if (!selectedExercise || selectedExercise.id === "") return;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        
        const file = files[0];
        setUploadStatus(`File ${files[0].name} uploaded successfully`);
        setUploadProgress(0);
        const storageRef = firebase.storage().ref(`videos/exercise/${selectedExercise.id}/${trainerUID ?? "abcd"}/video.mov`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                // Handle upload progress
                setIsUploadingVideo(true);
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(Math.floor(progress));
                // console.log(`Upload progress: ${progress}%`);
            },
            (error) => {
                // Handle upload error
                setIsUploadingVideo(false);
                // console.log('Upload error: ', error);
                setUploadProgress(0);
            },
            async () => {
                // Handle successful upload
                // console.log('Upload completed');
                // Get the URL of the uploaded video from Firebase Storage
                
                
                const uploadedVideoUrl = await storageRef.getDownloadURL();
                setUploadedVideoUrl(uploadedVideoUrl);
                setVideoUrl(uploadedVideoUrl)
                
                setTimeout(() => {
                    setIsUploadingVideo(false);
                    setUploadProgress(0);
                    setIsCustomVideo(true);
                }, 1000)
                // console.log('Here is the video link', uploadedVideoUrl);
            }
        );
    }

    async function removeCustomVideo() {
        if (!selectedExercise || selectedExercise.id === "") return;

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        setIsCustomVideo(false);
        setLoadingVideo(true);
        setUploadedVideoUrl("");
        const storageRef = firebase.storage().ref(`videos/exercise/${selectedExercise.id}/${trainerUID ?? "abcd"}/video.mov`);
        try {
            let res = await storageRef.delete();
            setTimeout(() => {
                setLoadingVideo(false);
                updateVideo();
            }, 1000);
        } catch (err) {
            setLoadingVideo(false);
            updateVideo();
        }
        
    }

    window.addEventListener('click', function(e){   
        if (document === null || document.getElementById('help-menu') === null || document.getElementById('help-button') === null || e.target === null) { 
             
        } else {
            // Check for click outside of "Create" menu
            if (document.getElementById('help-menu')!.contains(e.target as Node)){
            // Clicked in box
            } else {
                setWillSaveLoadingPattern(false);
                if (!(document.getElementById('help-button')!.contains(e.target as Node))) {
                    if (showHelpMenu === true) {
                        setShowHelpMenu(false);
                    }
                }
            }
        }
    });

    window.addEventListener('keypress', function(e) {   
        // console.log("PRESSED:", e.keyCode);
        /*
        if (willSaveLoadingPattern) { return; }

        if ( e.keyCode === 27 ) {
            // 'esc'
            if (props.closePressed !== undefined) {
                props.closePressed();
            }
            
        }

        if (selectedExercise.id !== '') {
            // user is configuring some selected exercise
            if ( e.keyCode === 114 ) {
                // 'r'
                setUseRepRange(!useRepRange);
            }
            if ( e.keyCode === 116 ) {
                // 't'
                setUseTempos(!useTempos);
            }
            if ( e.keyCode === 119 ) {
                // 'w'
                setUsePercentWMs(!usePercentWMs);
            }
        }
        */
    });

    function onKeyPress(e: any) {
        // console.log("onKeyPress, create workout exercise configure:", e.key);
        if ( e.key === "Escape" ) {
            // 'esc'
            if (props.closePressed !== undefined) {
                props.closePressed();
            }
            
        }

        if (notesFocused) return;

        if (selectedExercise.id !== '') {
            // user is configuring some selected exercise
            if ( e.key === "r" || e.key === "R" ) {
                // 'r'
                setUseRepRange(!useRepRange);
            }
            if ( e.key === "t" || e.key === "T" ) {
                // 't'
                setUseTempos(!useTempos);
            }
            if ( e.key === "w" || e.key === "W") {
                // 'w'
                setUsePercentWMs(!usePercentWMs);
            }
        }
    }



	return (
		<div tabIndex={0} onKeyDown={(e: any) => onKeyPress(e)} className="create-workout-exercise-configure-container">
            <div id="help-button" onClick={() => toggleHelpMenu()} className="add-exercise-to-workout-modal-help-button">
                <HelpOutline className="add-exercise-to-workout-modal-help-button-icon"/>
            </div>
            <CSSTransition in={showHelpMenu} timeout={200} classNames="menu" unmountOnExit appear>
                <div id="help-menu" className="add-exercise-to-workout-modal-help-menu">
                    <div className="add-exercise-to-workout-modal-help-menu-header">
                        <h4>Keyboard Shortcuts</h4>
                    </div>
                    <div className="add-exercise-to-workout-modal-help-menu-content">
                        <div className="add-exercise-to-workout-modal-help-menu-content-row">
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-text">
                                <p>Toggle rep ranges</p>
                            </div>
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-letter-container">
                                <p>r</p>
                            </div>
                        </div>
                        <div className="add-exercise-to-workout-modal-help-menu-content-row">
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-text">
                                <p>Toggle working max percentages</p>
                            </div>
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-letter-container">
                                <p>w</p>
                            </div>
                        </div>
                        <div className="add-exercise-to-workout-modal-help-menu-content-row">
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-text">
                                <p>Toggle tempo</p>
                            </div>
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-letter-container">
                                <p>t</p>
                            </div>
                        </div>
                        <div className="add-exercise-to-workout-modal-help-menu-content-row">
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-text">
                                <p>As many reps as possible</p>
                            </div>
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-letter-container">
                                <p>a</p>
                            </div>
                        </div>
                        <div className="add-exercise-to-workout-modal-help-menu-content-row">
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-text">
                                <p>Autofill all reps</p>
                            </div>
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-letter-container">
                                <p>f</p>
                            </div>
                        </div>
                        <div className="add-exercise-to-workout-modal-help-menu-content-row">
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-text">
                                <p>Close window</p>
                            </div>
                            <div className="add-exercise-to-workout-modal-help-menu-content-row-letter-container">
                                <p>esc</p>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <div hidden={props.topButton === undefined || props.topButton === false} onClick={() => selectedExercise.id !== '' && reps.length > 0 ? finishPressed() : null} className={`create-workout-exercise-configure-add-button create-workout-exercise-configure-add-button-floating ${selectedExercise.id !== '' && reps.length > 0 ? 'create-workout-exercise-configure-add-button-selected' : ''}`}>
                <p>{props.exerciseToEdit.id !== '' ? 'Save Changes' : 'Finish & Add Exercise'}</p>
            </div>
			<div hidden={props.hideTitle !== undefined && props.hideTitle === true} className="create-workout-exercise-configure-header-container">
                <p className="create-workout-exercise-configure-header-back-text" onClick={() => backToExerciseList()}>&larr; Back to added Exercises</p>
                <h1>Configure Exercise</h1>
            </div>
            <div className="create-workout-exercise-configure-body-container">
                <div className="create-workout-exercise-configure-section-container">
                    <div className="create-workout-exercise-configure-section-header-container">
                        <h2>Exercise</h2>
                    </div>
                    <div hidden={selectedExercise.id !== ''} className="create-workout-exercise-configure-section-content create-workout-exercise-configure-section-search-exercises">
                        <div className={`new-exercise-add-step-input-container create-workout-exercise-configure-muscle-search-content ${searchStringError ? 'create-workout-exercise-configure-muscle-search-content-error' : ''} ${searchFocus ? 'create-workout-exercise-configure-muscle-search-content-focus' : ''}`}>
                            <IonInput value={searchString} 
                                placeholder="Search for an exercise by name..."
                                inputmode="text"
                                clearOnEdit={true}
                                autofocus={true}
                                onFocus={() => setSearchFocus(true)}
                                onBlur={() => setSearchFocus(false)}
                                type="text"
                                onKeyPress={e => inputUpdated(e.key)}
                                onIonChange={e => inputChanged(e.detail.value!, 'exercise-name')}/>
                        </div>
                        <div className="create-workout-exercise-configure-muscle-seletor-content">
                            <FilterSingle 
                                placeholder="Muscle Group" 
                                alert={false} 
                                options={muscle_groups}
                                showTitle={false} 
                                id="create-workout-exercise-configure"
                                inputChanged={(e: string) => inputChanged(e, 'muscle-group')}/>
                        </div>
                        <div onClick={() => searchButtonPressed()} className="create-workout-exercise-configure-search-button">
                            <Search className="create-workout-exercise-configure-search-icon" style={{ fontSize: 18 }}/>
                            <p>Search</p>
                        </div>
                        <CSSTransition in={searchState !== 0} timeout={200} classNames="menu" unmountOnExit>
                            <div className="create-workout-exercise-configure-section-search-result-container">
                                <div hidden={searchState !== 1} className="create-workout-exercise-configure-section-search-result-content-loading">
                                    <div className="create-workout-exercise-configure-loading-spinner"/>
                                    <p>Searching for '{searchString}'...</p>
                                </div>
                                <div hidden={searchState !== 2}>
                                    {searchResults.map((item, index) => (
                                        <CreateWorkoutSearchListCell
                                            key={`${item.title}-${index}`}
                                            exercise={item}
                                            cellPressed={(e: Exercise_t, n?: number) => exerciseSelected(e)}/>
                                    ))}
                                    <div onClick={() => createNewExerciseFromWorkout()} className="create-workout-exercise-configure-add-exercise-button">
                                        
                                        <div className="create-workout-exercise-configure-add-exercise-button-icon-container">
                                            <Add className="create-workout-exercise-configure-add-exercise-button-icon"/>
                                        </div>
                                        <h4>Add '{searchString}' as new Exercise</h4>
                                    </div>
                                </div>
                                <div hidden={searchState !== 3} className="create-workout-exercise-configure-section-search-result-content-empty">
                                    <h4>No Exercises found matching '{searchString}' in {muscle_groups[searchGroup]}</h4>
                                    <p>Exercises with names similar to '{searchString}' in {muscle_groups[searchGroup]} couldn't be found in your Exercise Library, or in the Eigen Fitness library.</p>
                                    <div onClick={() => createNewExerciseFromWorkout()} className="create-workout-exercise-configure-add-exercise-button">
                                        <div className="create-workout-exercise-configure-add-exercise-button-icon-container">
                                            <Add className="create-workout-exercise-configure-add-exercise-button-icon"/>
                                        </div>
                                        <h4>Add '{searchString}' as new Exercise</h4>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                    <div hidden={selectedExercise.id === ''} className="create-workout-exercise-configure-section-content">
                        <div className="create-workout-exercise-configure-selected-exercise-card">
                            <div className="create-workout-exercise-configure-selected-exercise-card-col create-workout-exercise-configure-selected-exercise-card-col-title">
                                <h4>{selectedExercise.title}</h4>
                            </div>
                            <div className="create-workout-exercise-configure-selected-exercise-card-col create-workout-exercise-configure-selected-exercise-card-col-muscles">
                                <h4>{getMuscleNames()}</h4>
                            </div>
                            <div onClick={() => resetSelectedExercise()} className="create-workout-exercise-configure-selected-exercise-card-col create-workout-exercise-configure-selected-exercise-card-col-remove">
                                <Close className="create-workout-exercise-configure-selected-exercise-card-col-remove-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-workout-exercise-configure-section-container">
                    <div hidden={selectedExercise.id !== ''} className="create-workout-exercise-configure-section-curtain create-workout-exercise-configure-section-curtain-options"/>
                    <div className="create-workout-exercise-configure-section-header-container">
                        <h2>Options</h2>
                    </div>
                    <div className="create-workout-exercise-configure-section-content">
                        <div className="create-workout-exercise-configure-rest-select-loading-pattern-container">
                            <div onClick={() => setUseLoadingPatterns(false)} className={`create-workout-exercise-configure-rest-select-loading-pattern-card ${useLoadingPatterns === false ? 'create-workout-exercise-configure-rest-select-loading-pattern-card-selected' : ''}`}>
                                <div className={useLoadingPatterns === false ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                    <div hidden={useLoadingPatterns === true}>
                                        <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                    </div>
                                </div>
                                <h4>Set custom number of sets</h4>
                            </div>
                            <div onClick={() => setUseLoadingPatterns(true)} className={`create-workout-exercise-configure-rest-select-loading-pattern-card ${useLoadingPatterns === true ? 'create-workout-exercise-configure-rest-select-loading-pattern-card-selected' : ''}`}>
                                <div className={useLoadingPatterns === true ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                    <div hidden={useLoadingPatterns === false}>
                                        <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                    </div>
                                </div>
                                <h4>Use a pre-defined Loading Pattern</h4>
                            </div>
                        </div>
                        <div className="create-workout-exercise-configure-rest-select-sets-container">
                            <div hidden={useLoadingPatterns} className="create-workout-exercise-configure-rest-select-sets-container-sets">
                                <div className="create-workout-exercise-configure-rest-select-sets-container-sets-text-container">
                                    <h4>Enter a number of sets</h4>
                                </div>
                                <div onClick={() => removeSet(reps.length - 1)} className="create-workout-exercise-configure-rest-select-sets-container-sets-button">
                                    <p>-</p>
                                </div>
                                <div className={`new-exercise-add-step-input-container create-workout-exercise-configure-rest-select-sets-container-sets-input`}>
                                    <IonInput
                                        value={reps.length}
                                        placeholder="0"
                                        inputmode="numeric"
                                        clearOnEdit={true}
                                        type="number"
                                        onKeyPress={e => inputUpdated(e.key)}
                                        onIonChange={e => inputChanged(e.detail.value!, 'num-sets')}/>
                                </div>
                                <div onClick={() => addAnotherSet()} className="create-workout-exercise-configure-rest-select-sets-container-sets-button">
                                    <p>+</p>
                                </div>
                            </div>
                            <div hidden={!useLoadingPatterns} className={`create-workout-exercise-configure-rest-select-sets-container-loading-patterns ${selectedLoadingPattern.type >= 0 ? 'create-workout-exercise-configure-rest-select-sets-container-loading-patterns-block' : ''}`}>
                                <div hidden={selectedLoadingPattern.type >= 0} className="create-workout-exercise-configure-loading-pattern-filter-container">
                                    <FilterSingle 
                                        title="Strength Group" 
                                        alert={false} 
                                        options={loadingPatternTypes}
                                        showTitle={false} 
                                        forceOpenState={useLoadingPatterns}
                                        id="create-workout-loading-pattern-configure"
                                        inputChanged={(e: string) => inputChanged(e, 'loading-pattern-type')}/>
                                </div>
                                <div id="create-workout-exercise-configure-loading-pattern-menu-button" hidden={selectedLoadingPattern.type >= 0} onClick={() => toggleShowLoadingPatternMenu()} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector">
                                    <p>Select a Loading Pattern...</p>
                                    <div hidden={showLoadingPatternMenu} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector-expand-icon-container">
                                        <ExpandMore className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector-expand-icon"/>
                                    </div>
                                    <div hidden={!showLoadingPatternMenu} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector-expand-icon-container">
                                        <ExpandLess className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector-expand-icon"/>
                                    </div>
                                </div>
                                
                                <CSSTransition in={!(!showLoadingPatternMenu || selectedLoadingPattern.type >= 0)} timeout={200} classNames="menu" unmountOnExit>
                                    <div id="create-workout-exercise-configure-loading-pattern-menu" className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu">
                                        <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-search-container">
                                            <IonInput
                                                placeholder="Search for a Loading Pattern..."
                                                inputmode="text"
                                                clearOnEdit={true}
                                                type="text"
                                                onIonChange={e => inputChanged(e.detail.value!, 'loading-pattern')}/>
                                        </div>
                                        {
                                            loadingPatternSearchResults.map((item: LoadingPattern_t, index: number) => (
                                                <div onClick={() => loadingPatternSelected(item)} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell">
                                                    <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper">
                                                        <h4>{item.type + 1 < loadingPatternTypes.length ? `${loadingPatternTypes[item.type + 1]} |` : ''} {item.title}</h4>
                                                        <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper-reps-container">{
                                                            item.sets.map((item_set: any, index_set: number) => (
                                                                <p>{item_set.reps} </p>
                                                            ))
                                                        }</div>
                                                    </div>
                                                    <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower">
                                                        {
                                                            item.sets.map((item_set: any, index_set: number) => (
                                                                <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower-set">
                                                                    <p>{item_set.reps} at {getPWMFromRep(item_set.reps)}%</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-container">
                                                        <ArrowForward className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-icon"/>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </CSSTransition>
                                <div hidden={selectedLoadingPattern.type < 0} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selected-card">
                                    <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper">
                                    <h4>{selectedLoadingPattern.type + 1 < loadingPatternTypes.length ? `${loadingPatternTypes[selectedLoadingPattern.type + 1]} |` : ''} {selectedLoadingPattern.title} {`| ${selectedLoadingPattern.sets.length} sets`}</h4>
                                    </div>
                                    <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower-selected">
                                        {
                                            selectedLoadingPattern.sets.map((item_set: any, index_set: number) => (
                                                <div className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower-set">
                                                    <p>{item_set.reps} at {getPWMFromRep(item_set.reps)}%</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div onClick={() => clearSelectedLoadingPattern()} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-container">
                                        <Close className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-icon"/>
                                    </div>
                                </div>
                                <div onClick={() => loadingPatternSelected(selectedLoadingPattern)} hidden={!hasEditedLoadingPattern || selectedLoadingPattern.type < 0} className="create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selected-revert-container">
                                    <p>Revert to Loading Pattern Defaults</p>
                                </div>
                            </div>
                        </div>

                        <div className="create-workout-exercise-configure-section-header-container create-workout-exercise-configure-section-header-container-small">
                            <div onClick={() => toggleShowOptions()}><h2>Advanced Options</h2></div>
                            <div onClick={() => toggleShowOptions()} className="create-workout-exercise-configure-section-header-expand-container">
                                <div hidden={showOptions} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                    <ExpandMore className="create-workout-exercise-configure-options-header-expand-icon"/>
                                </div>
                                <div hidden={!showOptions} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                    <ExpandLess className="create-workout-exercise-configure-options-header-expand-icon"/>
                                </div>
                            </div>
                        </div>

                        <CSSTransition in={showOptions} timeout={200} classNames="menu" unmountOnExit>
                            <div>
                                <div className="create-workout-exercise-configure-rest-select-container">
                                    <div 
                                        onClick={() => toggleUseRestTime()}
                                        className={useDefaultRestTime ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                            <div hidden={useDefaultRestTime === false}>
                                                <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                            </div>
                                    </div>
                                    <div onClick={() => toggleUseRestTime()} className="create-workout-exercise-configure-rest-select-text-container">
                                        <h4>Set rest time between sets</h4>
                                        <p>Check to setup a mandatory rest time between sets with a default time</p>
                                    </div>
                                    <div hidden={!useDefaultRestTime} className="create-workout-exercise-configure-rest-input-container">
                                        <IonInput value={defaultRestTime} 
                                            placeholder="0"
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onIonChange={e => setRestTime(e.detail.value!)}/>
                                        <p>s2</p>
                                    </div>
                                </div>
                                <div hidden={selectedExercise.type !== 0} className="create-workout-exercise-configure-rest-select-container">
                                    <div 
                                        onClick={() => toggleUseTempos()}
                                        className={useTempos ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                        <div hidden={useTempos === false}>
                                            <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                        </div>
                                    </div>
                                    <div onClick={() => toggleUseTempos()} className="create-workout-exercise-configure-rest-select-text-container">
                                        <h4>Set target rep tempo</h4>
                                        <p>Prioritize eccentric, concentric, or rest portions of your client's reps</p>
                                    </div>
                                    <div hidden={!useTempos} className="create-workout-exercise-configure-tempo-input-container">
                                        <FormTempoInput 
                                            thisTempo={defaultTempo}
                                            tempoUpdated={(e: Tempo_t) => updateDefaultTempo(e)}/>
                                    </div>
                                </div>
                                <div hidden={selectedExercise.type !== 2} className="create-workout-exercise-configure-rest-select-container">
                                    <div 
                                        onClick={() => toggleUseDistance()}
                                        className={useDefaultDistance ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                        <div hidden={useDefaultDistance === false}>
                                            <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                        </div>
                                    </div>
                                    <div onClick={() => toggleUseDistance()} className="create-workout-exercise-configure-rest-select-text-container">
                                        <h4>Set target exercise distances</h4>
                                        <p>Set the target distance for each repetition of this cardio-based exercise</p>
                                    </div>
                                    <div hidden={!useDefaultDistance} className="create-workout-exercise-configure-rest-input-container">
                                        <IonInput value={defaultDistance} 
                                            placeholder="0"
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onIonChange={e => updateDefaultDistance(Number(e.detail.value!))}/>
                                        <p>m</p>
                                    </div>
                                </div>
                                <div className="create-workout-exercise-configure-rest-select-container">
                                    <div 
                                        onClick={() => toggleUsePWMs()}
                                        className={usePercentWMs ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                        <div hidden={usePercentWMs === false}>
                                            <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                        </div>
                                    </div>
                                    <div onClick={() => toggleUsePWMs()} className="create-workout-exercise-configure-rest-select-text-container">
                                        <h4>{selectedExercise.type === 2 ? 'Use target max. HR percentages' : 'Use working max percentages'}</h4>
                                        <p>{selectedExercise.type === 2 ? 'Select a target percent maximum HR for your client to target' : 'Select your client\'s weight for this exercise from a %WM'}</p>
                                    </div>
                                    <div hidden={!usePercentWMs || forcePercentWMs === true} className="create-workout-exercise-configure-rest-input-container">
                                        <IonInput value={defaultPWMs} 
                                            placeholder="no"
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onIonChange={e => setDefaultPWM(e.detail.value!)}/>
                                        <p>%</p>
                                    </div>
                                </div>
                                <div hidden={selectedExercise.type === 2 || !usePercentWMs} className="create-workout-exercise-configure-rest-select-container create-workout-exercise-configure-rest-select-container-indented">
                                    <div 
                                        onClick={() => toggleForcePWMs()}
                                        className={forcePercentWMs ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                        <div hidden={forcePercentWMs === false}>
                                            <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                        </div>
                                    </div>
                                    <div onClick={() => toggleForcePWMs()} className="create-workout-exercise-configure-rest-select-text-container">
                                        <h4>Force working max percentages from model</h4>
                                        <p>Automatically fill in the %WM, or reps, from the selected model</p>
                                    </div>
                                    <div hidden={!forcePercentWMs} className="create-workout-exercise-configure-rest-input-container create-workout-exercise-configure-rest-input-container-dropdown-container">
                                        <div id="pwms-model-selector-button" onClick={() => toggleShowPWMsModelMenu()} className="create-workout-exercise-configure-rest-input-container-selector-input">
                                            <p>{pWM_models[selectedPWMsModel]}</p>
                                            <div className="create-workout-exercise-configure-section-header-expand-container">
                                                <div hidden={showPWMsModelMenu} className="create-workout-exercise-configure-rest-input-container-selector-input-expand-icon-container">
                                                    <ExpandMore className="create-workout-exercise-configure-rest-input-container-selector-input-expand-icon"/>
                                                </div>
                                                <div hidden={!showPWMsModelMenu} className="create-workout-exercise-configure-rest-input-container-selector-input-expand-icon-container">
                                                    <ExpandLess className="create-workout-exercise-configure-rest-input-container-selector-input-expand-icon"/>
                                                </div>
                                            </div>
                                        </div>
                                        <CSSTransition in={showPWMsModelMenu === true} timeout={200} classNames="menu" unmountOnExit>
                                            <div id="pwms-model-selector-menu" className="create-workout-exercise-configure-rest-input-container-selector-menu-container">
                                                { pWM_models.map((item: string, index: number) => (
                                                    <div onClick={() => PWMsModelSelected(index)} className={`create-workout-exercise-configure-rest-input-container-selector-menu-row ${index === selectedPWMsModel ? 'create-workout-exercise-configure-rest-input-container-selector-menu-row-selected' : ''}`}>
                                                        <p>{item}</p>
                                                        <h4>{pWM_models_ex[index] === undefined ? "" : pWM_models_ex[index]}</h4>
                                                    </div>
                                                ))}
                                            </div>
                                        </CSSTransition>
                                    </div>
                                </div>
                                <div hidden={selectedExercise.type !== 0} className="create-workout-exercise-configure-rest-select-container">
                                    <div onClick={() => toggleUseRepRanges()} className={useRepRange ? "create-workout-exercise-configure-selector-container-selected" : "create-workout-exercise-configure-selector-container-unselected"}>
                                        <div hidden={useRepRange === false}>
                                            <Check className="create-workout-exercise-configure-selector-container-icon"/>
                                        </div>
                                    </div>
                                    <div onClick={() => toggleUseRepRanges()} className="create-workout-exercise-configure-rest-select-text-container">
                                        <h4>Use rep ranges</h4>
                                        <p>Indicate a range of reps to be completed for the given set</p>
                                    </div>
                                    <div hidden={!useRepRange} className="create-workout-exercise-configure-rest-input-container-small-container">
                                        <div className="create-workout-exercise-configure-rest-input-container create-workout-exercise-configure-rest-input-container-small">
                                            <IonInput value={defaultRepRangeMin} 
                                                placeholder="12"
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                type="number"
                                                onIonChange={e => updateDefaultRepRangeMin(Number(e.detail.value!))}/>
                                        </div>
                                        <p>-</p>
                                        <div className="create-workout-exercise-configure-rest-input-container create-workout-exercise-configure-rest-input-container-small">
                                            <IonInput value={defaultRepRangeMax} 
                                                placeholder="16"
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                type="number"
                                                onIonChange={e => updateDefaultRepRangeMax(Number(e.detail.value!))}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>

                <div className="create-workout-exercise-configure-section-container">
                    <div className="create-workout-exercise-configure-section-header-container">
                        <div onClick={() => toggleShowSets()}><h2>Set-by-set Configuration</h2></div>
                        <div onClick={() => toggleShowSets()} className="create-workout-exercise-configure-section-header-expand-container">
                            <div hidden={showSets} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                <ExpandMore className="create-workout-exercise-configure-options-header-expand-icon"/>
                            </div>
                            <div hidden={!showSets} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                <ExpandLess className="create-workout-exercise-configure-options-header-expand-icon"/>
                            </div>
                        </div>
                        <div className="create-workout-exercise-configure-section-header-exercise-duration-container">
                            <p>{getExerciseDurationString()}</p>
                        </div>
                    </div>
                    <div hidden={!showSets} className="create-workout-exercise-configure-section-content">
                        <div className="create-workout-exercise-configure-set-list-container-header">
                            <div className="create-workout-exercise-configure-set-list-container-header-text-container">
                                <p>SET</p>
                            </div>
                            <div className="create-workout-exercise-configure-set-list-container-header-stat-container">
                                <p>{selectedExercise.type === 0 ? (useRepRange ? "REPS [min:max]" : "REPS") : "DURATION"}</p>
                                <div hidden={selectedExercise.type !== 0}>
                                    <InfoHover text="Enter the number of reps you wish to perform, or type 'A' for As Many Reps As Possible (AMRAP)"/>
                                </div>
                            </div>
                            <div className="create-workout-exercise-configure-set-list-container-header-stat-container">
                                <p>{selectedExercise.type === 2 ? '% MAX. HR' : '%WM'}</p>
                            </div>
                            <div className="create-workout-exercise-configure-set-list-container-header-stat-container">
                                <p>{selectedExercise.type === 0 ? 'TEMPO' : (selectedExercise.type === 1 ? 'REST' : 'DISTANCE / COUNT')}</p>
                            </div>
                            <div className="create-workout-exercise-configure-set-list-container-header-stat-container">
                                <p>{selectedExercise.type === 1 ? '' : 'REST'}</p>
                            </div>
                        </div>
                        {reps.map((item: number, index: number) => (
                            <div key={index} className="create-workout-exercise-configure-set-cell">
                                <div className="create-workout-exercise-configure-set-cell-name-container">Set {index + 1 < 10 ? '0' : ''}{index + 1}</div>
                                <div className="create-workout-exercise-configure-set-cell-sets-container">
                                    <div hidden={useRepRange} className="create-workout-exercise-configure-set-cell-input-container">
                                        <CreateWorkoutSetRepInput 
                                            selectedExercise={selectedExercise}
                                            startingItem={item}
                                            autofillRepsPressed={() => autofillReps(index)}
                                            inputChanged={(e: number) => changeReps(e, index)}
                                            forceVal={reps_forced[index]}/>
                                    </div>
                                    <div hidden={!useRepRange} className="create-workout-exercise-configure-set-cell-input-container-small-container">
                                        <div className="create-workout-exercise-configure-set-cell-input-container create-workout-exercise-configure-set-cell-input-container-small">
                                            
                                            <div hidden={true}>
                                                <IonInput value={getRepString(item)} 
                                                    className="create-workout-exercise-configure-set-cell-input"
                                                    placeholder="0"
                                                    inputmode="numeric"
                                                    clearOnEdit={true}
                                                    type="number"

                                                    onIonChange={e => changeReps(e.detail.value!, index)}/>
                                           </div>
                                            <CreateWorkoutSetRepInput 
                                                className="create-workout-exercise-configure-set-cell-input"
                                                selectedExercise={selectedExercise}
                                                startingItem={item}
                                                inputChanged={(e: number) => changeReps(e, index)}
                                                forceVal={reps_forced[index]}/>
                                        </div>
                                        <p>-</p>
                                        <div className="create-workout-exercise-configure-set-cell-input-container-styled create-workout-exercise-configure-set-cell-input-container-small">
                                            <IonInput value={getRepString(index < reps_max.length ? reps_max[index] : defaultRepRangeMax)} 
                                                className="create-workout-exercise-configure-set-cell-input"
                                                placeholder="0"
                                                inputmode="numeric"
                                                clearOnEdit={true}
                                                type="number"
                                                onIonChange={e => changeReps_max(e.detail.value!, index)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-workout-exercise-configure-set-cell-sets-container">
                                    <div hidden={!usePercentWMs} className="create-workout-exercise-configure-set-cell-input-container-styled">
                                        <IonInput value={index < pWMs.length ? pWMs[index] : defaultPWMs} 
                                            className="create-workout-exercise-configure-set-cell-input"
                                            placeholder="0"
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onIonChange={e => changePWMs(e.detail.value!, index)}/>
                                        <p>%</p>
                                    </div>
                                    <div onClick={() => toggleUsePWMs()} hidden={usePercentWMs} className="create-workout-exercise-configure-set-cell-input-container-styled create-workout-exercise-configure-set-cell-input-container-blank">
                                        <p>--.- %</p>
                                    </div>
                                </div>
                                <div hidden={selectedExercise.type !== 2} className="create-workout-exercise-configure-set-cell-sets-container">
                                    <div hidden={!useDefaultDistance} className="create-workout-exercise-configure-set-cell-input-container-styled">
                                        <IonInput value={index < distances.length ? distances[index] : defaultDistance} 
                                            className="create-workout-exercise-configure-set-cell-input"
                                            placeholder="0"
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onIonChange={e => changeDistance(e.detail.value!, index)}/>
                                        <p>m</p>
                                    </div>
                                    <div onClick={() => toggleUseDistance()} hidden={useDefaultDistance} className="create-workout-exercise-configure-set-cell-input-container-styled create-workout-exercise-configure-set-cell-input-container-blank">
                                        <p>--.- m</p>
                                    </div>
                                </div>
                                <div hidden={selectedExercise.type !== 0} className="create-workout-exercise-configure-set-cell-sets-container">
                                    <div hidden={!useTempos} className="create-workout-exercise-configure-set-cell-sets-tempo-input-container">
                                        <FormTempoInput 
                                            thisTempo={tempos[index]}
                                            tempoUpdated={(e: Tempo_t) => changeTempo(e, index)}/>
                                    </div>
                                    <div onClick={() => toggleUseTempos()} hidden={useTempos} className="create-workout-exercise-configure-set-cell-input-container-styled create-workout-exercise-configure-set-cell-input-container-blank">
                                        <p>-:-:-:-</p>
                                    </div>
                                </div>
                                <div className="create-workout-exercise-configure-set-cell-sets-container">
                                    <div hidden={!useDefaultRestTime} className="create-workout-exercise-configure-set-cell-input-container-styled">
                                        <IonInput value={index < rest.length ? rest[index] : defaultRestTime} 
                                            className="create-workout-exercise-configure-set-cell-input"
                                            placeholder="0"
                                            inputmode="numeric"
                                            clearOnEdit={true}
                                            type="number"
                                            onIonChange={e => changeRest(e.detail.value!, index)}/>
                                        <p>s1</p>
                                    </div>
                                    <div onClick={() => toggleUseRestTime()} hidden={useDefaultRestTime} className="create-workout-exercise-configure-set-cell-input-container-styled create-workout-exercise-configure-set-cell-input-container-blank">
                                        <p>--.- s</p>
                                    </div>
                                </div>
                                <div hidden={true} onClick={() => autoFill(index)} className="create-workout-exercise-configure-set-cell-button create-workout-exercise-configure-set-cell-autofill-container">
                                    <FileCopy className="create-workout-exercise-configure-set-cell-autofill-icon" style={{ fontSize: 18 }}/>
                                    <p>Copy</p>
                                </div>
                                <div hidden={true} onClick={() => removeSet(index)} className="create-workout-exercise-configure-set-cell-button create-workout-exercise-configure-set-cell-remove-container">
                                    <Delete className="create-workout-exercise-configure-set-cell-remove-icon" style={{ fontSize: 18 }}/>
                                </div>
                                <div id={`set-${index}-${selectedExercise.id}-row-button`} onClick={() => toggleShowingSetMenu(index)} className="create-workout-exercise-configure-set-cell-button create-workout-exercise-configure-set-cell-autofill-container">
                                    <MoreHoriz className="create-workout-exercise-configure-set-cell-autofill-icon" style={{ fontSize: 18 }}/>
                                    <p>Options</p>
                                </div>
                                <CSSTransition in={showingSetMenu[index] === true} timeout={200} classNames="menu" unmountOnExit>
                                    <div id={`set-${index}-${selectedExercise.id}-row-menu`} className="create-workout-exercise-configure-set-cell-menu-container">
                                        <div className="create-workout-exercise-configure-set-cell-menu-header">
                                            <h4>Set {index + 1} options</h4>
                                        </div>
                                        <div className="create-workout-exercise-configure-set-cell-menu-body">
                                            <div onClick={() => autoFill(index)} className="create-workout-exercise-configure-set-cell-menu-body-row">
                                                <ImportExport className="create-workout-exercise-configure-set-cell-menu-icon" style={{ fontSize: 18 }}/>
                                                <p>Autofill All</p>
                                            </div>
                                            <div onClick={() => autofillReps(index)} className="create-workout-exercise-configure-set-cell-menu-body-row">
                                                <FitnessCenter className="create-workout-exercise-configure-set-cell-menu-icon" style={{ fontSize: 18 }}/>
                                                <p>Autofill Reps</p>
                                            </div>
                                            <div onClick={() => autofillPWM(index)} className="create-workout-exercise-configure-set-cell-menu-body-row">
                                                <DataUsage className="create-workout-exercise-configure-set-cell-menu-icon" style={{ fontSize: 18 }}/>
                                                <p>Autofill %WM</p>
                                            </div>
                                            <div onClick={() => autofillTempos(index)} className="create-workout-exercise-configure-set-cell-menu-body-row">
                                                <AvTimer className="create-workout-exercise-configure-set-cell-menu-icon" style={{ fontSize: 18 }}/>
                                                <p>Autofill Tempo</p>
                                            </div>
                                            <div onClick={() => autofillRest(index)} className="create-workout-exercise-configure-set-cell-menu-body-row">
                                                <Timer className="create-workout-exercise-configure-set-cell-menu-icon" style={{ fontSize: 18 }}/>
                                                <p>Autofill Rest</p>
                                            </div>
                                            <div onClick={() => removeSet(index)} className="create-workout-exercise-configure-set-cell-menu-body-row create-workout-exercise-configure-set-cell-menu-body-row-line create-workout-exercise-configure-set-cell-menu-body-row-remove">
                                                <Delete className="create-workout-exercise-configure-set-cell-menu-icon create-workout-exercise-configure-set-cell-remove-ico" style={{ fontSize: 18 }}/>
                                                <p>Remove Set</p>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                            </div>
                        ))}
                        <div className="create-workout-exercise-configure-new-exercise-container-add-buttons-container">
                            <div onClick={() => addAnotherSet()} className="create-workout-exercise-configure-new-exercise-container-add-another-set">
                                <p>+ Add Another Set</p>
                            </div>
                            <div onClick={() => saveLoadingPatternPressed()} 
                                 className={`create-workout-exercise-configure-new-exercise-container-add-another-set 
                                             create-workout-exercise-configure-new-exercise-container-save-loading-pattern
                                             ${checkAllowShowSaveLoadingPattern() ? '' : 'create-workout-exercise-configure-new-exercise-container-add-another-set-unselected'}`}>
                                <p>Save Loading Pattern</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="create-workout-exercise-configure-section-container">
                    <div onClick={() => toggleShowNotes()} className="create-workout-exercise-configure-section-header-container">
                        <h2>Exercise Notes</h2>
                        <div className="create-workout-exercise-configure-section-header-expand-container">
                            <div hidden={showNotes} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                <ExpandMore className="create-workout-exercise-configure-options-header-expand-icon"/>
                            </div>
                            <div hidden={!showNotes} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                <ExpandLess className="create-workout-exercise-configure-options-header-expand-icon"/>
                            </div>
                        </div>
                    </div>
                    <div hidden={!showNotes} className="create-workout-exercise-configure-note-input-container">
                        <FormLongTextInput 
                            value={ undefined }
                            placeholder="Exercise Notes..." 
                            alert={false} 
                            alertText=""
                            isSearch={true}
                            isOptional={false}
                            focusChanged={ (f: boolean) => updateNoteFocusState(f) }
                            inputChanged={(e: string) => inputChanged(e, 'note')}/>
                    </div>
                </div>

                <div className="create-workout-exercise-configure-section-container">
                    <div onClick={() => toggleShowVideo()} className="create-workout-exercise-configure-section-header-container">
                        <h2>Exercise Video</h2>
                        <div className="create-workout-exercise-configure-section-header-expand-container">
                            <div hidden={showVideo} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                <ExpandMore className="create-workout-exercise-configure-options-header-expand-icon"/>
                            </div>
                            <div hidden={!showVideo} className="create-workout-exercise-configure-options-header-expand-icon-container">
                                <ExpandLess className="create-workout-exercise-configure-options-header-expand-icon"/>
                            </div>
                        </div>
                    </div>
                    <div hidden={!showVideo} className={ "create-workout-exercise-configure-video-entry-wrapper" }>
                        <div className={ "create-workout-exercise-configure-video-entry-container" }>
                            <IonInput value={ extraVideoLink } 
                                placeholder="Enter a video link..."
                                inputmode="text"
                                clearOnEdit={true}
                                type="text"
                                onIonChange={e => setExtraVideoLink(e.detail.value!)}/>
                        </div>
                    </div>

                    <div hidden={!showVideo} className="create-workout-exercise-configure-video-input-container">


                        <div className={ "create-workout-exercise-configure-video-container" }>
                            {    
                                isUploadingVideo ?
                                <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-loading" }>
                                    <p>Uploading Video...</p>
                                </div>
                                :
                                loadingVideo ?
                                <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-loading" }>
                                    <p>Loading Video...</p>
                                </div>
                                :
                                videoUrl === "" ?
                                <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-loading" }>
                                    <p>No video for this exercise</p>
                                </div>
                                :
                                <div className={ "create-workout-exercise-configure-video-container-inner create-workout-exercise-configure-video-container-inner-video-container" }>
                                    <video controls={false} loop autoPlay muted>
                                        <source src={ uploadedVideoUrl && uploadedVideoUrl !== "" ? uploadedVideoUrl : videoUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    {

                                        isCustomVideo &&
                                        <div className={ "create-workout-exercise-configure-video-remove-custom-button" } onClick={ () => removeCustomVideo() }>
                                            <p>Remove Custom Video</p>
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        <div className={ "create-workout-exercise-configure-drop-area" }>
                            <FileDrop
                                onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                onDragOver={(event) => console.log('onDragOver', event)}
                                onDragLeave={(event) => console.log('onDragLeave', event)}
                                onDrop={(files, event) => handleFileDropped(files, event)}>
                                <div className="create-workout-exercise-configure-drop-area-inner-container">
                                    <div className="create-workout-exercise-configure-drop-area-icon-container">
                                        <Image className="create-workout-exercise-configure-drop-area-icon" />
                                    </div>
                                    <h4>{isUploadingVideo ? `${uploadProgress}% Uploaded...` : "Drop your own video here to upload"}</h4>
                                    <p>Files less than 20MB are recommended, in .mp4 .mov formats.</p>
                                </div>
                            </FileDrop>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="create-workout-exercise-configure-add-button-container">

            </div>
		</div>
	)
}

export default CreateWorkoutExerciseConfigure;