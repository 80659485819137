import React, { useState, useEffect } from 'react';
import firebase from '../../../Firebase';

import './CreateWorkoutExerciseListCellVideo.css';


function CreateWorkoutExerciseListCellVideo(props: any) {

    const [videoUrl, setVideoUrl] = useState("");
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [isCustomVideo, setIsCustomVideo] = useState(false);

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (props.exerciseID) updateVideo(props.exerciseID)
    }, [props.exerciseID])

    function updateVideo(forID: string) {
        if (forID && forID !== "") {
            let currentUser = firebase.auth().currentUser;
            if (!currentUser) { return; }
            let trainerUID = currentUser.uid;
            setIsCustomVideo(false);
            setLoadingVideo(true);

            let storage = firebase.storage();
            let storageRef_trainer = storage.ref(`videos/exercise/${forID}/${trainerUID}/video.mov`);
            storageRef_trainer.getDownloadURL()
            .then(fireBaseUrl_trainer => {
                setVideoUrl(fireBaseUrl_trainer);
                setLoadingVideo(false);
                setIsCustomVideo(true);
            })
            .catch(error => {
                // No video found
                setIsCustomVideo(false);
                let storageRef = storage.ref(`videos/exercise/${forID}/video.mov`);
                storageRef.getDownloadURL()
                .then(fireBaseUrl => {
                    setVideoUrl(fireBaseUrl);
                    setLoadingVideo(false);
                })
                .catch(error => {
                    setVideoUrl("");
                    setLoadingVideo(false);
                })
           })
        }
    }

    return (
        <div className={ "create-workout-exercise-list-cell-video" }>
            {
                loadingVideo ?
                <div className={ "create-workout-exercise-list-cell-video-container-inner create-workout-exercise-list-cell-video-container-inner-loading" }>
                    <p>Loading Video...</p>
                </div>
                :
                videoUrl === "" ?
                <div className={ "create-workout-exercise-list-cell-video-container-inner create-workout-exercise-list-cell-video-container-inner-loading" }>
                    <p>Video<br/>coming soon</p>
                </div>
                :
                <div className={ "create-workout-exercise-list-cell-video-container-inner create-workout-exercise-list-cell-video-container-inner-video-container" }>
                    <video controls={false} loop autoPlay muted>
                        <source src={ videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {
                        isCustomVideo &&
                        <div className={ "create-workout-exercise-list-cell-video-custom-button" }>
                            <p>Custom Video</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default CreateWorkoutExerciseListCellVideo;