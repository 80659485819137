import React, { useState, useEffect } from 'react';
import './GoalChart.css';


function GoalChart(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [finalData, setFinalData] = useState<number[]>([])

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            generateData();
        }
    }, [initSet])

    useEffect(() => {
        generateData();
    }, [props.goalData])

    const generateData = () => {
        if (!props.goalData || !props.goalData.goal || !props.goalData.logs) return;

        let goalData = props.goalData.goal;
        let logData = props.goalData.logs;

        let startingTS = goalData.starting_timestamp;
        let endingTS = goalData.target_timestamp;
        var latestLog = 0.0;
        var firstLog = 0.0;
        let targetMetric = goalData.target_metric;
        if (logData.length > 0) {
            firstLog = logData[0].metric;
            latestLog = logData[logData.length - 1].metric;
        }
        let targetDelta = Math.floor(Math.abs(targetMetric - firstLog));
        targetDelta = targetDelta === 0 ? 0.1 : targetDelta;

        if (!logData || logData.length < 1 || !startingTS || startingTS < 1) return;
        let progressData_temp = formatData(logData, startingTS);
        var progressData: number[] = [];
        for (var i = 0; i < progressData_temp.length; i++) {
            let thisData = progressData_temp[i];
            let progressDelta = Math.abs(targetMetric - thisData);

            let data_adj = 100 - (Math.floor((progressDelta / targetDelta) * 1000) / 10);
            progressData.push(data_adj);
        }

        let msBetweenTimestamps = endingTS - startingTS;
        let daysBetweenTimestamps = Math.ceil(msBetweenTimestamps / (24 * 60 * 60 * 1000))

        var zeroFillDayCount = daysBetweenTimestamps - progressData.length;
        zeroFillDayCount = zeroFillDayCount > 30 ? 30 : zeroFillDayCount
        let paddedData = padDataWithZeros(progressData, zeroFillDayCount)
        setFinalData(paddedData)

    }

    const formatData = (data: any[], startingTS: number) => {

        var finalData_temp: number[] = [];

        for (var i = 0; i < data.length; i++) {
            let thisPoint = data[i];
            let thisValue = thisPoint.metric;
            let thisTimestamp = thisPoint.timestamp;

            if (i > 0) {
                let lastPoint = data[i - 1];
                let lastValue = lastPoint.metric;
                let lastTimestamp = lastPoint.timestamp;

                let msDelta = thisTimestamp - lastTimestamp;
                let dayDelta = Math.floor(msDelta / (24 * 3600 * 1000));
                if (dayDelta > 1) {
                    for (var j = 0; j < dayDelta; j++) {
                        finalData_temp.push(lastValue)
                    }
                    finalData_temp.push(lastValue)
                } else if (dayDelta <= 1) {
                    finalData_temp.push(thisValue)
                }
            } else {
                let msDelta_start = thisTimestamp - startingTS;
                let dayDelta_start = Math.floor(msDelta_start / (24 * 3600 * 1000));
                if (dayDelta_start > 1) {
                    for (var j = 0; j < dayDelta_start; j++) {
                        finalData_temp.push(thisValue)
                    }
                }
                finalData_temp.push(thisValue)
            }
        }

        return finalData_temp;
    }

    const padDataWithZeros = (data: (number | null)[], desiredLength: number) => {
        const sanitizedData = data.map((value) => (value === null ? 0 : value));
        while (sanitizedData.length < desiredLength) {
            sanitizedData.push(0);
        }
        return sanitizedData;
    }

    const getBarStyle = (val: number, index: number) => {
        let lastVal = index === 0 ? 0 : finalData[index - 1];
        let color = !lastVal ? "#ffffff10" : val >= lastVal ? "#5B62FF" : "#FF4D4D"
        return { height: `${val}%`, backgroundColor: color };
    }

    const getStartingDateString = () => {
        if (!props.goalData || !props.goalData.goal) return "";
        let goalData = props.goalData.goal;
        let startingTS = goalData.starting_timestamp;
        if (startingTS < 1) return "";
        return getDateString(startingTS, false, false);
    }

    const getTargetDateString = () => {
        if (!props.goalData || !props.goalData.goal) return "";
        let goalData = props.goalData.goal;
        let endingTS = goalData.target_timestamp;
        if (endingTS < 1) return "";
        return getDateString(endingTS, false, false);
    }

    const getDateString = (timestamp: number, fullMonth: boolean, includeYear?: boolean) => {
        const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const moStringsFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        let thisDate = new Date(timestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo_index = thisDate.getMonth();
        let thisDate_mo = fullMonth ? moStringsFull[thisDate_mo_index] : moStrings[thisDate_mo_index];
        let thisDate_yr = thisDate.getFullYear();

        return `${thisDate_mo} ${thisDate_day}${includeYear ? `, ${thisDate_yr}` : ''}`;
    }

	return (
		<div className={ "goal-chart" }>
			<div className={ "goal-chart-bar-container" }>
                {
                    finalData.map((item: number, index: number) => (
                        <div className={ "goal-chart-bar" } style={ getBarStyle(item, index) } key={ `goal-progress-bar-${index}` }/>
                    ))
                }
            </div>
            <div className={ "goal-chart-target-line" }></div>
            <div className={ "goal-chart-target-gradient-container" }>
                <img className="goal-chart-target-gradient-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/goal-chart-overlay.png`}/>
            </div>
            <div className={ "goal-chart-date-container" }>
                <div className={ "goal-chart-date goal-chart-date-starting" }>
                    <p>{ getStartingDateString() }</p>
                </div>
                <div className={ "goal-chart-date goal-chart-date-ending" }>
                    <p>{ getTargetDateString() }</p>
                </div>
            </div>
		</div>
	)
}

export default GoalChart;