import React, { useState, useEffect } from 'react';
import './PerformanceAnalysisLogCard.css';

import firebase from '../../../../Firebase';


function PerformanceAnalysisLogCard(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingWorkout, setIsLoadingWorkout] = useState(true);

    const [workoutTitle, setWorkoutTitle] = useState("");
    const [logID, setLogID] = useState("");
    const [durationString, setDurationString] = useState("00m 00s");
    const [volumeString, setVolumeString] = useState("00 of 00 sets");
    const [tonnageString, setTonnageString] = useState("00 LBS");
    const [isRecording, setIsRecording] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        checkForLog();
    }

    useEffect(() => {
        checkForLog();
    }, [props.clientID])

    useEffect(() => {
        checkForLog();
    }, [props.timestamp])


    useEffect(() => {
        setIsSelected(props.selectedLogID !== "" && props.selectedLogID === logID);
    }, [props.selectedLogID, logID])

    

    const getDateString = (timestamp: number, fullMonth: boolean, includeYear?: boolean) => {
        const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const moStringsFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        let thisDate = new Date(timestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo_index = thisDate.getMonth();
        let thisDate_mo = fullMonth ? moStringsFull[thisDate_mo_index] : moStrings[thisDate_mo_index];
        let thisDate_yr = thisDate.getFullYear();

        return `${thisDate_mo} ${thisDate_day}${includeYear ? `, ${thisDate_yr}` : ''}`;
    }

    function getFormattedTime(time: number) {
        let time_seconds = Math.floor(time % 60);
        let time_mins = Math.floor(time / 60);

        return `${time_mins < 10 ? '0' : ''}${time_mins}m ${time_seconds < 10 ? '0' : ''}${time_seconds}s`

    }

    function getStringFormattedInt(num: number) {
        let flooredNum = Math.floor(num);
        let flooredNum_thousand = Math.floor(flooredNum % 1000);
        return flooredNum < 10 ? `0${flooredNum}` : (flooredNum > 1000 ? `${Math.floor(flooredNum / 1000)},${ flooredNum_thousand < 1 ? '000' : flooredNum_thousand < 100 ? `0${flooredNum_thousand}` : (flooredNum_thousand < 10 ? `00${flooredNum_thousand}` : flooredNum_thousand)}` : `${flooredNum}`);
    }

    function clearData() {
        setWorkoutTitle("");
        setDurationString("00m 00s");
        setVolumeString("0.0%");
        setTonnageString("00 LBS")
    }

    function checkForLog() {
        if (!props.timestamp || !props.clientID || props.clientID === "") return; 
        setIsLoading(true);
        let thisDate = new Date(props.timestamp);
        let d_d = thisDate.getDate();
        let d_m = thisDate.getMonth();
        let d_y = thisDate.getFullYear();

        let logLocation = `workout_plans/client_assigned_plans/${props.clientID}/schedule/${d_y}/${d_m}/${d_d}/log/log_id`;
        let database = firebase.database();
        let ref = database.ref(logLocation);

        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let val = snapshot.val();
                configureLogData(val);
            } else {
                clearData();
                setIsLoading(false);
            }
        })
        .catch((err: any) => {
            clearData();
            setIsLoading(false);
        })

    }

    function configureLogData(log_id: string) {
        if (!log_id || log_id === "" || !props.clientID || props.clientID === "") {
            setIsLoading(false);
            return;
        }

        setLogID(log_id);

        let logLocation = `workout_logs/${props.clientID}/${log_id}`;
        let database = firebase.database();
        let ref = database.ref(logLocation);

        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let val = snapshot.val();
                configureWorkoutName(val.workout_id);
                parseLogData(val);

                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        })
        .catch((err: any) => {
            setIsLoading(false);
        })

    }

    function configureWorkoutName(workout_id: string) {
        if (!workout_id || workout_id === "") {
            setWorkoutTitle("Unnamed Workout")
            return;
        }
        setIsLoadingWorkout(true);

        let workoutLocation = `workouts/${workout_id}/title`;
        let database = firebase.database();
        let ref = database.ref(workoutLocation);

        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let val = snapshot.val();
                setWorkoutTitle(val && val !== "" ? val : "Unnamed Workout");
                setIsLoadingWorkout(true);
            } else {
                setIsLoadingWorkout(true);
            }
        })
        .catch((err: any) => {
            setIsLoadingWorkout(true);
        })
    }

    function parseLogData(logData: any) {
        if (!logData) return;

        // Generate duration
        let startingTS = logData.starting_timestamp ?? -1;
        let endingTS = logData.ending_timestamp ?? -1;
        let lastSetTS = logData.last_set_timestamp ?? -1;

        let duration_ms = (endingTS > 0 ? endingTS : lastSetTS > 0 ? lastSetTS : 0) - startingTS;
        duration_ms = duration_ms < 0 ? 0 : duration_ms;
        let durationStringTemp = getFormattedTime(Math.floor(duration_ms / 1000));
        setDurationString(durationStringTemp);

        // Check if workout is still active
        setIsRecording(endingTS <= 0 && Date.now() - startingTS <= (6 * 3600 * 1000));

        // Generate Volume and Tonnage
        var vol_complete = 0;
        var vol_total = 0;
        var tonnageSum = 0;
        var tonnageUnits = 'LBS'

        if (logData.exercise_series) {
            for (var i = 0; i < logData.exercise_series.length; i++) {
                let thisSeries = logData.exercise_series[i];
                if (thisSeries && thisSeries.exercises) {
                    for (var j = 0; j < thisSeries.exercises.length; j++) {
                        let thisExercise = thisSeries.exercises[j];
                        if (thisExercise && thisExercise.sets) {
                            for (var k = 0; k < thisExercise.sets.length; k++) {
                                let thisSet = thisExercise.sets[k];
                                if (thisSet) {
                                    vol_complete += thisSet.is_complete !== undefined && thisSet.is_complete === true ? 1 : 0;
                                    vol_total += 1;
                                    let w = thisSet.weight ?? -1;
                                    let repsCompleted = thisSet.reps_completed ?? 0;
                                    let thisTonnage = w * repsCompleted;
                                    if (thisTonnage > 0 && !isNaN(thisTonnage)) tonnageSum += thisTonnage;
                                    if (thisSet.weight_units && thisSet.weight_units !== "") tonnageUnits = thisSet.weight_units
                                }
                            }
                        }
                    }
                }
            }
        }

        let volumeStringTemp = `${Math.floor(vol_complete / vol_total * 100)}%`
        let tonnageStringTemp = getStringFormattedInt(tonnageSum);

        setVolumeString(volumeStringTemp);
        setTonnageString(`${tonnageStringTemp} ${tonnageUnits}`)
    }


    function logPressed() {
        props.logSelected(logID, workoutTitle);
    }


	return (
		<div className={"performance-analysis-log-card"} onClick={ () => logPressed() }>
			<div className={"performance-analysis-log-card-header"}>
                <div className={`performance-analysis-log-card-header-text-container ${workoutTitle === "" ? "performance-analysis-log-card-header-text-container-unselected" : ""}`}>
                    <div className={ "performance-analysis-log-card-header-date-container" }>
                        
                        <p>{ getDateString(props.timestamp ?? Date.now(), true, true) }</p>
                        <div className={ "performance-analysis-log-card-recording-container" } hidden={ !isRecording }>
                            <p>Currently Recording</p>
                        </div>
                    </div>
                    <h4>{ isLoading ? "Loading..." : workoutTitle === "" ? "No Workout Log" : workoutTitle }</h4>
                    
                </div>
                <div className={ `performance-analysis-log-card-isselected ${ isSelected ? 'performance-analysis-log-card-isselected-selected' : '' }` } hidden={ workoutTitle === "" }/>
            </div>
            <div className={ "performance-analysis-log-card-body" } hidden={ isLoading || workoutTitle === "" }>
                <div className={ "performance-analysis-log-card-body-col" }>
                    <p>Duration</p>
                    <h4>{ durationString }</h4>
                </div>
                
                <div className={ "performance-analysis-log-card-body-col" }>
                    <p>Tonnage</p>
                    <h4>{ tonnageString }</h4>
                </div>
                <div className={ "performance-analysis-log-card-body-col" }>
                    <p>Completion</p>
                    <h4>{ volumeString }</h4>
                </div>
            </div>
		</div>
	)
}

export default PerformanceAnalysisLogCard;