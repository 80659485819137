import React, { useState } from 'react';
import './WorkoutCard.css';
import firebase from '../../Firebase';


function WorkoutCard(props: any) {

	const [imgUrl, setImgUrl] = useState('');

	getImage();

	function getImage() {
		const storage = firebase.storage();
		storage.ref('images/workouts/').child(props.workout.imageName).getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       })
	}


	return (
		<div className="workout-card">
			<div className="workout-card-upper-container">
				<div className="workout-card-image-container">
					<img className="workout-card-image" src={imgUrl} alt={`Image for ${props.workout.title}`} />
				</div>
				<div className="workout-card-info-container">
					<h3>{props.workout.title}</h3>
				</div>
			</div>	
			<div className="workout-card-lower-container">
				
			</div>	
		</div>
	)
}

export default WorkoutCard;