import React, { useState, useEffect } from 'react';
import './WorkoutPlanListCycleOverviewCard.css';

import Workout_t from '../../../../Interfaces/Workout_t'
import Folder_t from '../../../../Interfaces/Folder_t';
import Plan_t from '../../../../Interfaces/Plan_t';

import WorkoutPlanListCycleOverviewCardPlan from './WorkoutPlanListCycleOverviewCardPlan';

import '@material-ui/core';
import List from '@material-ui/icons/List';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import ArrowForward from '@material-ui/icons/ArrowForward';
import DragIndicator from '@material-ui/icons/DragIndicator';
import FileCopy from '@material-ui/icons/FileCopy';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Add from '@material-ui/icons/Add';



function WorkoutPlanListCycleOverviewCard(props: any) {

    let initFolder = {
            title: "",
            id: "",
            type: "folder",
            path: "",
            children: [],
            isPlan: false,
            isOpen: false
        };

    let initPlan: Plan_t = {
        title: "",
        description: "",
        id: "",
        createdBy_ID: "",
        created_timestamp: 0,
        workout_ids: [],
        availability: 0,
        path: "",
        repScheme: []
    }

    const [initSet, setInitSet] = useState(false);
    const [cycle, setCycle] = useState<Folder_t>(initFolder);
    const [phases, setPhases] = useState<Folder_t[]>([]);//useState<Plan_t[]>([]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        if (props.cycle !== undefined) {
            setCycle(props.cycle);
            getPlans(props.cycle);
        }
    }, [props.cycle])

    function initialize() {
        if (props.cycle !== undefined) {
            setCycle(props.cycle);
            getPlans(props.cycle);
        }
    }

    function getPlans(forCycle: Folder_t) {
        // var phaseListTemp: Plan_t[] = [];
        // if (forCycle.children !== undefined && forCycle.children.length > 0) {
        //     for (var i = 0; i < forCycle.children.length; i++) {
        //         let thisChild = forCycle.children[i];
        //         if (thisChild.type === "plan") {
        //             let newPlan: Plan_t = {
        //                 title: thisChild.title,
        //                 description: "",
        //                 id: thisChild.id,
        //                 createdBy_ID: "",
        //                 created_timestamp: 0,
        //                 workout_ids: [],
        //                 availability: 0,
        //                 path: thisChild.path,
        //                 repScheme: []
        //             }
        //             phaseListTemp.push(newPlan);
        //         }
        //     }
        // }

        var phaseListTemp: Folder_t[] = [];
        if (forCycle.children !== undefined && forCycle.children.length > 0) {
            phaseListTemp = forCycle.children;
        }

        setPhases(phaseListTemp);
    }

    function cycleSelected() {
        props.cycleSelected();
    }

    function planPressed(p: Folder_t) {
        // console.log(`WorkoutPlanListCycleOverviewCard | planPressed: `, p);
        if (p.plan_id === undefined || p.plan_id === "") {
            props.addPlan(p);
        } else {
            props.planSelected(p);
        }
    }

	return (
		<div draggable={false} className="workout-plan-list-cycle-overview-card">
			<div className="workout-plan-list-cycle-overview-card-header">
                <div className={ "workout-plan-list-cycle-overview-card-header-icon-wrapper" }>
                    <div className="workout-plan-list-cycle-overview-card-header-icon-container">
                        <img className="workout-plan-list-cycle-overview-card-header-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/cycle.png`}/>
                    </div>
                </div>
                <div className={ "workout-plan-list-cycle-overview-card-header-content-wrapper" }>
                    <div className={ "workout-plan-list-cycle-overview-card-header-drag-handle-container" }>
                        <DragIndicator className={ "workout-plan-list-cycle-overview-card-header-drag-handle-icon" }/>
                    </div>
                    <div className="workout-plan-list-cycle-overview-card-header-text-container">
                        <h4>{cycle.title}</h4>
                    </div>
                    <div className={ "workout-plan-list-cycle-overview-card-header-phase-count-text-container" }>
                        <p>{phases.length} Phase{ phases.length === 1 ? '' : 's' }</p>
                    </div>
                    <div className={ "workout-plan-list-cycle-overview-card-header-buttons-container" }>
                        <div className={ "workout-plan-list-cycle-overview-card-header-button workout-plan-list-cycle-overview-card-header-button-delete" } onClick={ () => props.deleteCyclePressed(cycle) }>
                            <DeleteOutline className={ "workout-plan-list-cycle-overview-card-header-button-icon" }/>
                        </div>
                        {/*<div className={ "workout-plan-list-cycle-overview-card-header-button" } onClick={ () => null }>
                            <FileCopy className={ "workout-plan-list-cycle-overview-card-header-button-icon" }/>
                        </div>*/}
                        <div className={ "workout-plan-list-cycle-overview-card-header-button" } onClick={ () => props.addPhaseToCycle(cycle) }>
                            <Add className={ "workout-plan-list-cycle-overview-card-header-button-icon" }/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="workout-plan-list-cycle-overview-card-content-container">
                {phases.map((item: Folder_t, index: number) => (
                    <div className="workout-plan-list-cycle-overview-card-content-container-inner">
                        <div style={{ zIndex: phases.length - 1 - index }} className={ "workout-plan-list-cycle-overview-card-content-branch-container" }>
                            <div className={ `workout-plan-list-cycle-overview-card-content-branch ${ !item || !item.plan_id || item.plan_id === "" ? "workout-plan-list-cycle-overview-card-content-branch-unselected" : "" }` }/>
                        </div>
                        <div style={{ zIndex: phases.length }} className="workout-plan-list-cycle-overview-card-content-phase-container">
                            <WorkoutPlanListCycleOverviewCardPlan
                                phase={item}
                                index={index}
                                maxIndex={phases.length - 1}
                                numberOfPhases={ phases.length }
                                planPressed={() => planPressed(item)}
                                copiedPhase={ props.copiedPhase }
                                pastePhasePressed={(p: Plan_t) => props.pastePhasePressed(p)}
                                assignClientToPlan={(p: Plan_t) => props.assignClientToPlan(p)} 
                                duplicatePlanPressed={(p: Plan_t) => props.duplicatePlanPressed(p)}
                                deletePlanPressed={(p: Plan_t) => props.deletePlanPressed(p)}
                                editPlanPressed={(p: Plan_t) => props.editPlanPressed(p)}
                                cellDropped={() => props.cellDropped(item,index)}
                                cellPicked={() => props.cellPicked(item,index)}
                                dragPosUpdated={(x: number, y: number) => props.updateDraggingPosition_phase(item,index,x,y)}
                                isPurposed={props.phaseIndex_purposed === index && props.cycleIndex_purposed === props.cycleIndex}/>
                        </div>
                    </div>
                ))}
                <div className={ `workout-plan-list-cycle-overview-card-content-container-linking-line-container` } hidden={ props.isLast === undefined || props.isLast === true }>
                    <div className={ `workout-plan-list-cycle-overview-card-content-container-linking-line ${ phases.length === 0 || phases[phases.length - 1] === undefined || phases[phases.length - 1].plan_id === undefined || phases[phases.length - 1].plan_id === "" ? "workout-plan-list-cycle-overview-card-content-container-linking-line-dark" : ""}` }/>
                </div>
            </div>
		</div>
	)
}

export default WorkoutPlanListCycleOverviewCard;