import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import './CreateWorkoutSearchListCell.css';

import Exercise_t from '../../../Interfaces/Exercise_t';
import Muscles_t from '../../../Interfaces/Muscles_t';

import '@material-ui/core';
import Speed from '@material-ui/icons/Speed';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import ArrowForward from '@material-ui/icons/ArrowForward';

function CreateWorkoutSearchListCell(props: any) {

    let muscleNames: string[][] = [
            [ // Abs
              "Rectus Abdominis",
              "External Obliques",
              "Internal Obliques"
            ],
            [ // Arms
              "Biceps Short Head",
              "Biceps Long Head",
              "Triceps Long Head",
              "Triceps Lateral Head",
              "Triceps Medial Head",
              "Brachioradialis",
              "Flexor Carpi"
            ],
            [ // Back
              "Trapezius",
              "Rhomboid Major",
              "Latissimus Dorsi",
              "Teres Major",
              "Erector Spinae"
            ],
            [ // Chest
              "Pectoralis Minor", //Clavicular Head
              "Pectoralis Major" //Sternocosial Head
            ],
            [ // Shoulders
              "Deltoid Anterior Head",
              "Deltoid Posterior Head",
              "Deltoid Lateral Head"
            ],
            [ // Legs
              "Gastrocnemius Lateral Head",
              "Gastrocnemius Medial Head",
              "Soleus",
              "Tibialis Anterior",
              "Rectus Femoris",
              "Vastus Lateralis",
              "Vastus Medialis",
              "Adductor Longus",
              "Adductor Mangus",
              "Bicep Femoris",
              "Semitendinosus"
            ],
            [ // Butt
              "Gluteus Medius",
              "Gluteus Maximus"
            ]
        ]

    let equipment = {
        machines: [
          "None",
          "Single Cables",
          "Double Cables",
          "Weighted Ab Crunch",
          "Weighted Trunk Roatator",
          "Parallel Bars",
          "Incline Chest Press",
          "Chest Press",
          "Decline Chest Press",
          "Pec Fly",
          "Shoulder Press",
          "Lateral Shoulder Raise",
          "Rear Delt Extensor",
          "Shrug Machine",
          "Deadlift Machine",
          "Spinal Errector",
          "Cable Pulldown",
          "Pulldown",
          "Low Pull Back",
          "High Pull Back",
          "Straight Pull Cable",
          "Leg Press",
          "Quad Extensor",
          "Lying Hamstring Curls",
          "Seated Hamstring Curls",
          "Standing Calves Raises",
          "Seated Calves Raises",
          "Hip Abductor",
          "Hip Adductor",
          "Squat Rack",
          "Bench Rack",
          "Shoudler Rack",
          "Double Arm Curl",
          "Single Arm Curl",
          "Tricep Extensor",
          "Overhead Tricep Extensor",
          "Machine Dips"
        ],
        benches: [
          "None",
          "Flat",
          "Large Incline",
          "Slight Incline",
          "Large Decline",
          "Slight Decline",
          "L-seat",
          "Bench Press",
          "Shoulder Press"
        ],
        bars: [
          "None",
          "Full Bar",
          "Short Bar",
          "Curl Bar",
          "Swiss Bar",
          "Tricep Bar",
          "Fixed Weight Barbell"
        ],
        cable_attachments: [
          "None",
          "Double Ropes",
          "Single Rope",
          "Flat Bar",
          "Curl Bar",
          "Double-D Handles",
          "Signle-D Handle",
          "Short Parallel Bar",
          "Pulldown Bar",
          "Side Grip Lat Bar",
          "Tricep V-Bar",
          "Head Harness",
          "Foot Harness",
        ]
    };

    const [initSet, setInitSet] = useState(false);
    const [exercise, setExercise] = useState<Exercise_t>({title:'',id:'',type: 0,muscles:[]});
    const [muscleString, setMuscleString] = useState('');
    const [showingSets, setShowingSets] = useState(false);
    const [numSets, setNumSets] = useState(3);
    const [hasMotionInfo, setHasMotionInfo] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setExercise(props.exercise);
        // console.log(props.exercise.muscles);
        if (props.exercise.muscles.length > 0) {
           
            var muscleStringTemp = '';
            for (var i = 0; i < props.exercise.muscles.length; i++) {
                let thisMuscle = props.exercise.muscles[i];
                let thisString = muscleNames[thisMuscle.group][thisMuscle.muscle];
                muscleStringTemp += i === 0 ? thisString : `, ${thisString}`;
            }
            setMuscleString(muscleStringTemp);
            // console.log(muscleStringTemp);
        }
        setHasMotionInfo(props.exercise.motionInfo !== undefined && props.exercise.motionInfo.counting !== undefined);
    }

    function getTypeString() {
        let typeNames = ["Isotonic", "Isometric", "Cardio"];
        return `${typeNames[exercise.type]} exercise`
    }

    function getEquipmentString() {
        if (exercise.equipment === null || exercise.equipment === undefined) { return 'No equipment'; }
        var equipmentString = '';
        var addedCount = 0
        if (exercise.equipment.bar > 0) {
            equipmentString += `${equipment.bars[exercise.equipment.bar + 1]}`;
            addedCount += 1;
        }
        if (exercise.equipment.machine > 0) {
            equipmentString += `${addedCount > 0 ? ', ' : ''}${equipment.machines[exercise.equipment.machine + 1]}`;
            addedCount += 1;
        }
        if (exercise.equipment.bench > 0) {
            equipmentString += `${addedCount > 0 ? ', ' : ''}${equipment.benches[exercise.equipment.bench + 1]}`;
            addedCount += 1;
        }
        if (exercise.equipment.cable_attachment > 0) {
            equipmentString += `${addedCount > 0 ? ', ' : ''}${equipment.cable_attachments[exercise.equipment.cable_attachment + 1]}`;
            addedCount += 1;
        }
        if (equipmentString === '') { return 'No equipment'; }
        return equipmentString;
    }

    function cellPressed() {
        // if (showingSets === false) {
        //     setShowingSets(true);
        // }
        props.cellPressed(exercise);
    }

    window.addEventListener('click', function(e){   
        if (document === null || document.getElementById(`search-list-cell-${exercise.id}`) === null || document.getElementById(`search-list-cell-${exercise.id}`) === null || e.target === null) { return; }
    
        if (document.getElementById(`search-list-cell-${exercise.id}`)!.contains(e.target as Node)){
            // Clicked in box
            } else {
                if (showingSets === true) {
                    setShowingSets(false);
                }
            }
    });

    function adjustSets(byNum: number) {
        if (numSets + byNum > 0) {
            setNumSets(byNum + numSets);
        }
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            configureExercise()
        }
    }

    function inputChanged(toString: string, forInput: string) {
        switch (forInput) {
            case 'num-sets':
                setNumSets(Number(toString));
                break;
            default:
                break;
        }
    }

    function configureExercise() {
        props.cellPressed(exercise, numSets);
    }

    return (
        <div id={`search-list-cell-${exercise.id}`} className="create-workout-search-list-cell-container">
            <CSSTransition in={showingSets === false} timeout={200} classNames="slide" unmountOnExit>
                <div onClick={() => cellPressed()} className="create-workout-search-list-cell-container-content">
                    <div className="create-workout-search-list-cell-name-container">
                        <h2>{exercise.title}</h2>
                        <p>{muscleString}</p>
                    </div>
                    <div className="create-workout-search-list-cell-info-container">
                        {/*<div className="create-workout-search-list-cell-info-content">
                            <Speed className="create-workout-search-list-cell-info-icon" style={{ fontSize: 14 }}/>
                            <p>{getTypeString()}</p>
                        </div>*/}
                        {
                            hasMotionInfo ?
                            <div className="create-workout-search-list-cell-info-content create-workout-search-list-cell-info-content-nodes">
                                <Check className="create-workout-search-list-cell-info-icon" style={{ fontSize: 14 }}/>
                                <p>Works with Nodes</p>
                            </div>
                            :
                            <div className="create-workout-search-list-cell-info-content">
                                <Close className="create-workout-search-list-cell-info-icon" style={{ fontSize: 14 }}/>
                                <p>Nodes Unavailable</p>
                            </div>
                        }
                        <div className="create-workout-search-list-cell-info-content">
                            <FitnessCenter className="create-workout-search-list-cell-info-icon" style={{ fontSize: 14 }}/>
                            <p>{getEquipmentString()}</p>
                        </div>
                    </div>
                    <div className="create-workout-search-list-cell-arrow-container">
                        <ArrowForward className="create-workout-search-list-cell-select-icon" style={{ fontSize: 18 }}/>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={showingSets === true} timeout={200} classNames="slide" unmountOnExit>
                <div className="create-workout-search-list-cell-add-sets-container-content">
                    <div className="create-workout-search-list-cell-add-sets-container-content-col">
                        <h4>Select Number of Sets</h4>
                        <p>Begin with the default number of sets</p>
                    </div>
                    <div className="create-workout-search-list-cell-add-sets-container-content-col create-workout-search-list-cell-add-sets-container-content-col-sets">
                        <div onClick={() => adjustSets(-1)} className="create-workout-search-list-cell-add-sets-container-content-col-button">
                            <p>-</p>
                        </div>
                        <div className="create-workout-search-list-cell-add-sets-container-content-col-input-container">
                            <IonInput value={numSets} 
                                placeholder="3 sets"
                                inputmode="numeric"
                                clearOnEdit={true}
                                type="number"
                                onKeyPress={e => inputUpdated(e.key)}
                                onIonChange={e => inputChanged(e.detail.value!, 'num-sets')}/>
                        </div>
                        <div onClick={() => adjustSets(1)} className="create-workout-search-list-cell-add-sets-container-content-col-button">
                            <p>+</p>
                        </div>
                    </div>
                    <div onClick={() => configureExercise()} className="create-workout-search-list-cell-add-sets-container-content-button">
                        <p>Configure Exercise</p>
                        <ArrowForward className="create-workout-search-list-cell-add-sets-container-content-button-icon"/>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default CreateWorkoutSearchListCell;