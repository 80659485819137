import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './CalendarMonth.css';

import CalendarMonthCell from './CalendarMonthCell';

import Client_t from '../../Interfaces/Client_t';
import Workout_t from '../../Interfaces/Workout_t';
import Plan_t from '../../Interfaces/Plan_t';
import ScheduleDay_t from '../../Interfaces/ScheduleDay_t';
import WorkoutLogBrief_t from '../../Interfaces/WorkoutLogBrief_t';
import CalendarDateCell_t from '../../Interfaces/CalendarDateCell_t';

import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Add from '@material-ui/icons/Add';



function CalendarMonth(props: any) {

    let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let wkDayStrings = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const [initSet, setInitSet] = useState(false);
    const [selectedTimestamp, setSelectedTimestamp] = useState(0);
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedYear, setSelectedYear] = useState(0);

    const [allDays, setAllDays] = useState<CalendarDateCell_t[]>([]);
    const [scheduledDays, setScheduledDays] = useState<ScheduleDay_t[]>([]);

    const [scheduledEvents, setScheduledEvents] = useState<ScheduleDay_t[]>([]);

    const [draggingDay, setDraggingDay] = useState<CalendarDateCell_t | undefined>();
    const [pickedDay, setPickedDay] = useState<CalendarDateCell_t | undefined>();
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setSelectedTimestamp(props.selectedTimestamp === undefined ? 0 : props.selectedTimestamp);
        setSelectedDay(props.selectedDay === undefined ? 0 : props.selectedDay);
        setSelectedMonth(props.selectedMonth === undefined ? 0 : props.selectedMonth);
        setSelectedYear(props.selectedYear === undefined ? 0 : props.selectedYear);

        if (props.selectedMonth !== undefined && props.selectedYear !== undefined) {
            configureAllDays(props.selectedMonth, props.selectedYear);
        }

        if (props.scheduledEvents !== undefined) {
            setScheduledEvents(props.scheduledEvents);
        }
    }

    useEffect(() => {
        setSelectedTimestamp(props.selectedTimestamp === undefined ? 0 : props.selectedTimestamp);
        setSelectedDay(props.selectedDay === undefined ? 0 : props.selectedDay);
        setSelectedMonth(props.selectedMonth === undefined ? 0 : props.selectedMonth);
        setSelectedYear(props.selectedYear === undefined ? 0 : props.selectedYear);

        if (props.selectedMonth !== undefined && props.selectedYear !== undefined) {
            configureAllDays(props.selectedMonth, props.selectedYear);
        }

    }, [props.selectedTimestamp, props.selectedDay, props.selectedMonth, props.selectedYear]);

    useEffect(() => {
        //// console.log("CalendarMonth: props.scheduledEvents ->", props.scheduledEvents);
        if (props.scheduledEvents !== undefined) {
            setScheduledEvents(props.scheduledEvents);
        }
    }, [props.scheduledEvents])

    function configureAllDays(mo: number, yr: number) {
        // Set all days for current month
        let allDaysTemp = getAllDaysForMoYr(mo, yr);
        setAllDays(allDaysTemp);
        setSelectedMonth(mo);
        setSelectedYear(yr);
    }

    function getAllDaysForMoYr(mo: number, yr: number) {
        let numberOfShowingDays = getDaysInMonth(mo, yr);
        let startingDateIndex = new Date(yr, mo, 1).getDay();

        var tempDayBuffer: CalendarDateCell_t[] = [];

        if (startingDateIndex > 0) {
            for (var i = 0; i < startingDateIndex; i++) {
                tempDayBuffer.push({
                    timestamp: -1,
                    workoutState: "none",
                    show_proposed: false
                });
            }
        }
        for (var i = 0; i < numberOfShowingDays; i++) {
            let thisDayNum = i + 1;
            let newDate = new Date(yr, mo, thisDayNum);

            tempDayBuffer.push({
                timestamp: newDate.getTime(),
                workoutState: "none",
                show_proposed: false,
                // scheduleDay: i !== 3 ? undefined : {
                //     timestamp: newDate.getTime(),
                //     day_number: newDate.getDate(),
                //     mo_number: newDate.getMonth(),
                //     year_number: newDate.getFullYear(),
                //     workout_id: "",
                //     path: "",
                //     phase: {
                //         id: "",
                //         name: "",
                //         index: 0,
                //         path: "",
                //     },
                //     cycle: {
                //         id: "",
                //         name: "",
                //         index: 0,
                //         path: "",
                //     }
                // }
            });
        }

        return tempDayBuffer;
    }

    function getDaysInMonth(mo: number, yr: number) {
        return new Date(yr, mo + 1, 0).getDate();
    }

    function getCalDateClass(calDate: CalendarDateCell_t) {
        let todayTS = Date.now();
        let newDate = new Date(todayTS);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        let selectedDate = new Date(calDate.timestamp);
        let selectedDateMonth = selectedDate.getMonth();
        let selectedDateYear = selectedDate.getFullYear();
        let selectedDateDay = selectedDate.getDate();

        if (newDateDay === selectedDateDay && newDateMonth === selectedDateMonth && newDateYear === selectedDateYear) {
            return 'new-workout-workout-plan-container-schedule-day-content-inner-today'
        }

        // if (todayTS < calDate.timestamp) {
        //     return 'new-workout-workout-plan-container-schedule-day-content-inner-dark';
        // };



        let workoutComplete = checkForCompletedWorkout(calDate.timestamp);
        if (workoutComplete) {
            return 'new-workout-workout-plan-container-schedule-day-content-inner-complete'
        }

        if (calDate.timestamp < props.programStartTimestamp || calDate.timestamp > props.programEndTimestamp) {
            return 'new-workout-workout-plan-container-schedule-day-content-inner-dark';
        }
    }

    function checkForCompletedWorkout(ts: number) {
        let newDate = new Date(ts);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        for (var i = 0; i < scheduledDays.length; i++) {
            let thisScheduledDay: ScheduleDay_t = scheduledDays[i];
            let endDate = new Date(thisScheduledDay.timestamp);
            let endDateMonth = endDate.getMonth();
            let endDateYear = endDate.getFullYear();
            let endDateDay = endDate.getDate();

            if (newDateDay === endDateDay && newDateMonth === endDateMonth && newDateYear === endDateYear) {
                if (thisScheduledDay.workout_log !== undefined) {
                    return true;
                }
            }
        }

        return false;
    }

    function getDateSelected(calDate: CalendarDateCell_t) {
        let newDate = new Date(calDate.timestamp);
        let newDateMonth = newDate.getMonth();
        let newDateYear = newDate.getFullYear();
        let newDateDay = newDate.getDate();

        let selectedDate = new Date(selectedTimestamp);
        let selectedDateMonth = selectedDate.getMonth();
        let selectedDateYear = selectedDate.getFullYear();
        let selectedDateDay = selectedDate.getDate();

        return newDateDay === selectedDateDay && newDateMonth === selectedDateMonth && newDateYear === selectedDateYear;
    }

    function getDayString(forDateCell: CalendarDateCell_t) {
        let thisDate = new Date(forDateCell.timestamp);
        let d = thisDate.getDate();
        return `${d < 10 ? '0' : ''}${d}`;
    }


    function dateSelected(forDateCell: CalendarDateCell_t) {


        setSelectedTimestamp(forDateCell.timestamp);
        // props.newDateSelected(forDateCell.timestamp);

        // get workout
        // let newDate = new Date(forDateCell.timestamp);
        // let newDateDay = newDate.getDate();
        // var selectedScheduleDayTemp: ScheduleDay_t | undefined = undefined;
        // for (var i = 0; i < scheduledDays.length; i++) {
        //     let thisScheduledDay = scheduledDays[i];
        //     if (thisScheduledDay.day_number === newDateDay && thisScheduledDay.mo_number === newDate.getMonth() && thisScheduledDay.year_number === newDate.getFullYear()) {
        //         selectedScheduleDayTemp = thisScheduledDay;
        //     }
        // }

        
        //props.scheduledDaySelected(selectedScheduleDay);

        //if (selectedScheduleDayTemp === undefined) { return; }

        //setSelectedScheduleDay(selectedScheduleDayTemp);

    }

    function cellDragPositionUpdated(x: number, y: number, dayIndex: number, dayCal: CalendarDateCell_t) {
        let calContainer = document.getElementById('month-calendar')
        if (calContainer !== null && calContainer !== undefined) {

           

            let rect = calContainer.getBoundingClientRect();
            let h = rect.height;
            let w = rect.width;
            let xStart = rect.x;
            let yStart = rect.y;

            let colW = w / 7;

            let cellCurrentX = xStart + (dayIndex * colW);

            // determind new col
            var newCol = -1;
            for (var i = 0; i < 7; i++) {
                let thisX_min = xStart + (i * colW);
                let thisX_max = thisX_min + colW;
                if (x > thisX_min && x <= thisX_max) {
                    newCol = i;
                }
            }

            newCol = newCol < 0 ? 0 : newCol;
            newCol = newCol >= 6 ? 6 : newCol;

            // determine new row
            let numDaysThisMonth = new Date(selectedYear, selectedMonth, 0).getDate();
            let numRows = Math.ceil(numDaysThisMonth / 7);

            let rowH = h / numRows;
            var newRow = -1;
            for (var i = 0; i < numRows; i++) {
                let thisY_min = yStart + (i * rowH);
                let thisY_max = thisY_min + rowH;
                if (y > thisY_min && y <= thisY_max) {
                    newRow = i;
                }
            }

            newRow = newRow < 0 ? 0 : newRow;
            newRow = newRow >= numRows - 1 ? numRows - 1 : newRow;
            //// console.log("ROW, COL: ", newRow, newCol);

            var proposedDate: {y: number, m: number, d: number} = {y: -1, m: -1, d: -1};
            for (var i = 0; i < allDays.length; i++) {
                let thisDay: CalendarDateCell_t = allDays[i];
                let thisCol = i % 7;
                let thisRow = Math.floor(i / 7);
                if (thisRow === newRow && thisCol === newCol) {
                    let thisTS = thisDay.timestamp;
                    let thisDate = new Date(thisTS);
                    proposedDate = {y: thisDate.getFullYear(), m: thisDate.getMonth(), d: thisDate.getDate()};
                }
            }

            setAllDays(allDays.map((item: CalendarDateCell_t, index: number) => {
                let thisCol = index % 7;
                let thisRow = Math.floor(index / 7);
                if (thisRow === newRow && thisCol === newCol) {
                    let thisTS = item.timestamp;
                    let thisDate = new Date(thisTS);
                    let calDateTemp = item;
                    calDateTemp.proposed_date = {y: thisDate.getFullYear(), m: thisDate.getMonth(), d: thisDate.getDate()};
                    // if (draggingDay !== undefined && draggingDay.scheduleDay !== undefined) {
                    //     calDateTemp.scheduleDay = draggingDay.scheduleDay;
                    // }
                    
                    setDraggingDay(calDateTemp);
                    return calDateTemp;
                } else {
                    return item;
                }
            }));

        } else {
            // console.log("CANT FIND CAL CONTAINER!");
        }
    }

    function cellDropped() {
        if (draggingDay === undefined || draggingDay.proposed_date === undefined || pickedDay === undefined) { return; }

        let proposedDate: any = draggingDay.proposed_date;
        let dragTemp = JSON.parse(JSON.stringify(draggingDay));

        setAllDays(allDays.map((item: CalendarDateCell_t, index: number) => {
            
            let thisDate = new Date(item.timestamp);

            let draggedDate = new Date(pickedDay.timestamp);

            if (thisDate.getDate() === proposedDate.d && thisDate.getMonth() === proposedDate.m && thisDate.getFullYear() === proposedDate.y) {
                let newCalDate: CalendarDateCell_t = JSON.parse(JSON.stringify(item));

                newCalDate.scheduleDay = {
                    timestamp: item.timestamp,
                    day_number: proposedDate.d,
                    mo_number: proposedDate.m,
                    year_number: proposedDate.y,
                    workout_id: "",
                    path: "",
                    phase: {
                        id: "",
                        name: "",
                        index: 0,
                        path: "",
                    },
                    cycle: {
                        id: "",
                        name: "",
                        index: 0,
                        path: "",
                    }
                }
                return newCalDate;
            }

            if (thisDate.getDate() === draggedDate.getDate() && thisDate.getMonth() === draggedDate.getMonth() && thisDate.getFullYear() === draggedDate.getFullYear()) {
                let newCalDate_a: CalendarDateCell_t = JSON.parse(JSON.stringify(item));
                newCalDate_a.scheduleDay = undefined;
                return newCalDate_a;
            }
            
            return item;                
        
        }));

        setDraggingDay(undefined);
        setPickedDay(undefined);
    }

    function cellPicked(calDay: CalendarDateCell_t) {
        setPickedDay(calDay);
    }

    function addNewAppointmentPressed(calDate: CalendarDateCell_t) {
        //// console.log("CalendarMonth - addNewAppointmentPressed")
        props.addNewAppointmentPressed(calDate.timestamp);
    }

    function getScheduleCell(item: CalendarDateCell_t, index: number) {
        /*    
            timestamp: number;
            workoutState: "none" | "not_complete" | "completed" | "today";
            scheduleDay?: ScheduleDay_t;
            show_proposed: boolean;
            proposed_date?: {y: number, m: number, d: number}
        */

        if (item.timestamp < 0) {
            return null;
        }

        let cellDate = new Date(item.timestamp);

        var cellList: any[] = [];

        for (var i = 0; i < scheduledEvents.length; i++) {
            let thisEvent = scheduledEvents[i];
            //// console.log("getScheduleCell: ", thisEvent);
            let thisEventDate = new Date(thisEvent.timestamp);
            if (cellDate.getDate() === thisEventDate.getDate() && cellDate.getMonth() === thisEventDate.getMonth() && cellDate.getFullYear() === thisEventDate.getFullYear()) {
                // It's a match!
                let dateAmmended = JSON.parse(JSON.stringify(item));
                dateAmmended.scheduleDay = thisEvent;
                cellList.push(
                    <CalendarMonthCell 
                        key={`cal-mo-cell-${thisEvent.timestamp}`}
                        smallCard={props.client !== undefined && props.client.id === ""}
                        calendarDay={dateAmmended}
                        isCreatingProgram={props.isCreatingProgram}
                        workoutsInPhase={props.workoutsInPhase}
                        addToProgramPressed={() => props.addToProgramPressed(dateAmmended)}
                        removeFromProgramPressed={() => props.removeFromProgramPressed(dateAmmended)}
                        addNewAppointmentPressed={() => props.addNewAppointmentPressedForClient(item.timestamp)}
                        editAppointmentPressedForClient={() => dateAmmended.scheduleDay !== undefined && dateAmmended.scheduleDay.scheduled !== undefined ? props.editAppointmentPressedForClient(dateAmmended.scheduleDay.scheduled.starting_timestamp, dateAmmended.scheduleDay.client_id) : null}
                        draggingDay={draggingDay}
                        dragPosUpdated={(x: number, y: number) => cellDragPositionUpdated(x, y, index, item)}
                        cellPicked={() => cellPicked(item)}
                        cellDropped={() => cellDropped()}
                        editWorkoutForDay={(workout: Workout_t, calendarDay: any, client: Client_t) => props.editWorkoutForDay(workout, calendarDay, client)}/>
                );
            }
        }

        return cellList;
    }

    function createWorkoutPressed(item: CalendarDateCell_t, index: number) {
        props.createNewWorkout({
            selectedClient: props.client,
            selectedTimestamp: item.timestamp,
            showCreateMore: false
            //completedCallback: workoutCreatedCallback
        })
    }

    function workoutCreatedCallback(info: any) {}

    function getShowAddWorkout(item: CalendarDateCell_t) {

        if (item.timestamp < 0 || item.scheduleDay !== undefined || props.client === undefined || props.client.id === "") { return false; }

        let cellDate = new Date(item.timestamp);

        for (var i = 0; i < scheduledEvents.length; i++) {
            let thisEvent = scheduledEvents[i];
            let thisEventDate = new Date(thisEvent.timestamp);
            if (cellDate.getDate() === thisEventDate.getDate() && cellDate.getMonth() === thisEventDate.getMonth() && cellDate.getFullYear() === thisEventDate.getFullYear()) {
                return false;
            }
        }



        return true;
    }

	return (
		<div className="calendar-month">
			<div className="calendar-month-weekdays-container">
                {
                    wkDayStrings.map((item: string, index: number) => (
                        <div className="calendar-month-weekdays">
                            <p>{item}</p>
                        </div>
                    ))
                }
            </div>
            <div id={`month-calendar`} className="calendar-month-content-container">
                {
                    allDays.map((item: CalendarDateCell_t, index: number) => (
                        <div onClick={() => dateSelected(item)} className={`calendar-month-day ${item.timestamp === -1 ? 'calendar-month-day-invisible' : ''}`}>
                            <div hidden={item.timestamp === -1} className={`calendar-month-day-line-upper`}/>
                            <div hidden={item.timestamp === -1} className={`calendar-month-day-inner ${getCalDateClass(item)} ${getDateSelected(item) ? 'calendar-month-day-selected' : '' }`}>
                                <p>{getDayString(item)}</p>
                                <div hidden={props.isBookable === undefined || props.isBookable === false || (props.client !== undefined && props.client.id !== "")} onClick={() => addNewAppointmentPressed(item)} className={`calendar-month-day-inner-book-button ${props.isBookable === true ? '' : 'calendar-month-day-inner-book-button-hide'}`}>
                                    <p>Book</p>
                                    <Add className="calendar-month-day-inner-book-button-icon"/>
                                </div>
                                <div className="calendar-month-day-inner-cell-container">
                                    { getScheduleCell(item, index) }
                                </div>
                                <div 
                                    className="calendar-month-day-create-workout-cell-container"
                                    onClick={() => createWorkoutPressed(item, index)}
                                    hidden={!getShowAddWorkout(item) || props.isCreatingProgram}>
                                    <div className="calendar-month-day-create-workout-cell-add-container">
                                        <Add className="calendar-month-day-create-workout-cell-add-icon"/>
                                    </div>
                                    <p>Add Workout</p>
                                </div>

                                {/*<CalendarMonthCell 
                                    calendarDay={item}
                                    addNewAppointmentPressed={() => addNewAppointmentPressed(item)}
                                    draggingDay={draggingDay}
                                    dragPosUpdated={(x: number, y: number) => cellDragPositionUpdated(x, y, index, item)}
                                    cellPicked={() => cellPicked(item)}
                                    cellDropped={() => cellDropped()}/>*/}
                            </div>
                            <div hidden={item.timestamp !== -1} className="calendar-month-day-inner-invisible">
                                <p>x</p>
                            </div>
                        </div>
                    ))
                }
            </div>
		</div>
	)
}

export default CalendarMonth;