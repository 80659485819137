import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './ScheduledMessagesRow.css';

// Import components
import DateSelector from '../Base/DateSelector';
import FormLongTextInput from '../Base/FormLongTextInput';

// UI imports
import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Timer from '@material-ui/icons/Timer';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

function ScheduledMessagesRow(props: any) {

    const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const moStrings_full = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const wkDayStrings = ["S", "M", "T", "W", "T", "F", "S"];

    const [initSet, setInitSet] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showingDateSelector, setShowingDateSelector] = useState(false);
    const [showingHoursSelector, setShowingHoursSelector] = useState(false);
    const [showingMinsSelector, setShowingMinsSelector] = useState(false);

    const [selectedDateTS, setSelectedDateTS] = useState(0);
    const [defaultDateTS, setDefaultDateTS] = useState(0);
    
    const [message, setMessage] = useState("");

    useEffect(() => {

        let oneWkTS = Date.now() + (7 * 24 * 3600 * 1000);
        let oneWkTS_rounded = roundToNearestTenMinutes(oneWkTS);

        setDefaultDateTS(oneWkTS_rounded)
        setSelectedDateTS(oneWkTS_rounded); // Set in 1wk
    }, [])

    useEffect(() => {
        if (props.id !== undefined && props.id !== "") {
            
        }
    }, [selectedDateTS])

    const roundToNearestTenMinutes = (timestamp: number) => {
        let date = new Date(timestamp);
        let minutes = date.getMinutes();
        let roundedMinutes = Math.round(minutes / 10) * 10;
        date.setMinutes(roundedMinutes, 0, 0); // Set minutes, seconds, and milliseconds
        return date.getTime(); // Get the rounded timestamp
    }

    

    const getDateString = () => {
        let thisDate = new Date(selectedDateTS);

        let thisMoIndex = thisDate.getMonth();
        let thisDay = thisDate.getDate();
        let thisYear = thisDate.getFullYear();

        return `${moStrings[thisMoIndex]} ${thisDay}, ${thisYear}`
    }

    const getDateHourString = () => {
        let thisDate = new Date(selectedDateTS);
        let hrs = thisDate.getHours();
        let hrs_adj = hrs % 12;
        hrs_adj = hrs_adj === 0 ? 12 : hrs_adj;
        return hrs_adj
    }

    const getDateMinsString = () => {
        let thisDate = new Date(selectedDateTS);
        let mins = thisDate.getMinutes();
        return mins === 60 ? 0 : mins;
    }

    const getDateAmPm = () => {
        let thisDate = new Date(selectedDateTS);
        let hrs = thisDate.getHours();
        return hrs >= 12;
    }

    const toggleAmPm = () => {
        let isPM = getDateAmPm();
        if (isPM) {
            setSelectedDateTS(selectedDateTS - (12 * 3600 * 1000));
        } else {
            setSelectedDateTS(selectedDateTS + (12 * 3600 * 1000));
        }
    }

    const hoursSelected = (hrs: number) => {
        let thisDate = new Date(selectedDateTS);
        let hrsCurrent = thisDate.getHours();
        let hrsCurrentAdj = getDateAmPm() ? hrsCurrent - 12 : hrsCurrent;
        let ts = selectedDateTS - (hrsCurrentAdj * 3600 * 1000);
        ts = ts + (hrs * 3600 * 1000);

        setSelectedDateTS(ts);
        setShowingHoursSelector(false);
    }

    const minsSelected = (mins: number) => {
        let thisDate = new Date(selectedDateTS);
        let minsCurrent = thisDate.getMinutes();
        let ts = selectedDateTS - (minsCurrent * 60 * 1000);
        ts = ts + (mins * 60 * 1000);

        setSelectedDateTS(ts);
        setShowingMinsSelector(false);
    }

    const dateSelected = (ts: number) => {
        if (ts <= 0) return;

        let tsDate = new Date(ts);
        let ts_hrs = tsDate.getHours();
        // let ts_mins = tsDate.getMinutes();
        let needsTimeAdj = ts_hrs === 0;

        let thisDate = new Date(selectedDateTS);
        let hrs = thisDate.getHours();
        let mins = thisDate.getMinutes();
        let finalTS = needsTimeAdj ? ts + (hrs * 3600 * 1000) + (mins * 60 * 1000) : ts;
        setSelectedDateTS(finalTS);
        setShowingDateSelector(false);
    }

    const saveChanges = () => {

    }

	return (
		<div className={ "scheduled-message-row" }>
			<div className={ "scheduled-message-row-header" }>
                <div className={ "scheduled-message-row-header-icon-container" }>
                    <Timer className={ "scheduled-message-row-header-icon" }/>
                </div>   
                <div className={ "scheduled-message-row-header-input-container scheduled-message-row-header-input-container-date" } onClick={ () => setShowingDateSelector(!showingDateSelector) }>
                    <h4>{ getDateString() }</h4>
                </div> 
                <h4>at</h4>
                <div className={ "scheduled-message-row-header-input-container scheduled-message-row-header-input-container-hrs" } onClick={ () => setShowingHoursSelector(!showingHoursSelector) }>
                    <h4>{ getDateHourString() }</h4>
                </div> 
                <h4>:</h4>
                <div className={ "scheduled-message-row-header-input-container scheduled-message-row-header-input-container-mins" } onClick={ () => setShowingMinsSelector(!showingMinsSelector) }>
                    <h4>{ `${ getDateMinsString() < 10 ? '0' : '' }${ getDateMinsString() }` }</h4>
                </div> 
                
                <CSSTransition in={ showingHoursSelector } timeout={200} classNames="menu" unmountOnExit appear>
                    <div className={ "scheduled-message-row-header-input-dropdown-container scheduled-message-row-header-input-dropdown-container-hours" }>
                        {
                            Array.from(new Array(12)).map((item: any, index: number) => (
                                <div className={ "scheduled-message-row-header-input-dropdown-row" } onClick={ () => hoursSelected(index + 1) }>
                                    <p>{ `${index + 1 < 10 ? '0' : ''}${ index + 1 }` }</p>
                                </div>
                            ))
                        }
                    </div>
                </CSSTransition>
                <CSSTransition in={ showingMinsSelector } timeout={200} classNames="menu" unmountOnExit appear>
                    <div className={ "scheduled-message-row-header-input-dropdown-container scheduled-message-row-header-input-dropdown-container-mins" }>
                        {
                            Array.from(new Array(6)).map((item: any, index: number) => (
                                <div className={ "scheduled-message-row-header-input-dropdown-row" } onClick={ () => minsSelected((index) * 10) }>
                                    <p>{ `${index === 0 ? '0' : ''}${(index) * 10}` }</p>
                                </div>
                            ))
                        }
                    </div>
                </CSSTransition>
                <div className={ "scheduled-message-row-header-date-selector-container" }>
                    <DateSelector 
                        defaultTimestamp={ defaultDateTS }
                        hideInput={ true }
                        isOpen={ showingDateSelector }
                        dateSelected={ (t: number) => dateSelected(t) }/>
                </div>   
                <div className={ "scheduled-message-row-header-input-container scheduled-message-row-header-input-container-ampm" } onClick={ () => toggleAmPm() }>
                    <h4>{ getDateAmPm() ? "PM" : "AM" }</h4>
                </div> 
                <div className={ "scheduled-message-row-header-buttons-container" }>
                    <div className={ "scheduled-message-row-header-button" } onClick={ () => setIsOpen(!isOpen) }>
                        {
                            isOpen ?
                            <ArrowDropUp className={ "scheduled-message-row-header-button-icon" }/>
                            :
                            <ArrowDropDown className={ "scheduled-message-row-header-button-icon" }/>
                        }
                    </div>
                </div>
            </div>
            <CSSTransition in={ isOpen } timeout={200} classNames="menu" unmountOnExit appear>
                <div className={ "scheduled-message-row-body" }>
                    <div className={ "scheduled-message-row-body-input-wrapper" }>
                        <FormLongTextInput
                            placeholder={ `What would you like this message to say?` }
                            inputChanged={ (t: string) => null }
                            alert={ false }
                            isSearch={ true }
                            value={ undefined }
                            inputMode={ "text" }
                            focusChanged={ (s: boolean) => null }/>
                    </div>
                </div>
            </CSSTransition>
            
		</div>
	)
}

export default ScheduledMessagesRow;