import React, { useState, useEffect } from 'react';
import './ScoringMetricCard.css';

import ScoringMetricRow from './ScoringMetricRow';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function ScoringMetricCard(props: any) {

    const [scoringMetrics, setScoringMetrics] = useState<any[]>([]);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.scoringMetrics) setScoringMetrics(props.scoringMetrics);
    }, [props.scoringMetrics])

    const initialize = () => {
        
    }

    function morePressed() {
        props.openPerformancePressed()
    }

	return (
		<div className={ "scoring-metric-card" }>
			<div className={ "scoring-metric-card-header" }>
                <div className={ "scoring-metric-card-header-icon-container" }>
                    <GraphicEq className={ "scoring-metric-card-header-icon" }/>
                </div>
                <h4>Scoring Metrics</h4>
                <div className={ "scoring-metric-card-header-more-button" } onClick={ () => morePressed() }>
                    <p>See more</p>
                </div>
            </div>
            <div className={ "scoring-metric-card-body-container" }>
                <div className={ "scoring-metric-card-body-header" }>
                    <div className={ "scoring-metric-card-body-col-header-col scoring-metric-card-body-col scoring-metric-card-body-col-name" }>
                        <p>Metric Name</p>
                    </div>
                    <div className={ "scoring-metric-card-body-col-header-col scoring-metric-card-body-col scoring-metric-card-body-col-measurement" }>
                        <p>Measurement</p>
                    </div>
                    <div className={ "scoring-metric-card-body-col-header-col scoring-metric-card-body-col scoring-metric-card-body-col-datapoints" }>
                        <p>Target</p>
                    </div>
                    <div className={ "scoring-metric-card-body-col-header-col scoring-metric-card-body-col scoring-metric-card-body-col-score" }>
                        <p>Score</p>
                    </div>
                </div>
                <div className={ "scoring-metric-card-body-content" }>
                    {
                        scoringMetrics.map((item: any, index: number) => (
                            <ScoringMetricRow key={`scoring-metric-row-${index}`} scoringMetric={ item }/>
                        ))
                    }
                </div>
            </div>
            <div className={ "scoring-metric-card-selector-container" }>
                <div className={ "scoring-metric-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default ScoringMetricCard;