// Define constants
const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
const dateTimeParser = (timeStamp: number): string => {
    const dateTime = new Date(timeStamp)
    const formattedTime = dateTime.toLocaleString("en-CA", {
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    })
    const dateLabel = getDateLabel(timeStamp)
    if (dateLabel === 'Today') {
        const trimmedDateTime = formattedTime.substring(3)
        return `${dateLabel} ${trimmedDateTime}`
    }
    else if (dateLabel === 'Yesterday') {
        const trimmedDateTime = formattedTime.substring(3)
        return `${dateLabel} ${trimmedDateTime}`
    }
    else {
        return `${dateLabel} ${formattedTime}`
    }

}

const getMonthAbbreviation = (timeStamp: number): string => {
    const timeStampToDate = new Date(timeStamp)
    return `${moStrings[timeStampToDate.getMonth()]} `
}

/**
 * Get a date label based on the provided timestamp.
 * @param timeStamp - The timestamp in milliseconds.
 * @returns The date label as a string.
 */
const getDateLabel = (timeStamp: number): string => {
    const currentDate = new Date();
    const inputDate = new Date(timeStamp);

    const isToday =
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
        inputDate.getDate() === currentDate.getDate() - 1 &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear();


    if (isToday){
        return "Today"
    }
    else if (isYesterday){
        return "Yesterday"
    }
    else {
        return getMonthAbbreviation(timeStamp)
    }
}

export default dateTimeParser
