import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';
import './CalendarDayTimelineCell.css';


function CalendarDayTimelineCell(props: any) {

    const [selectedDuration, setSelectedDuration] = useState<number>(60);
    const [selectedStartTime, setSelectedStartTime] = useState<number>(0);

    const [clientName, setClientName] = useState("New Appointment");

    const [cellHeight_css, setCellHeight_css] = useState<string>("40px");
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.selectedDuration !== undefined) {
            setSelectedDuration(props.selectedDuration);
            // let newHeight = getCellHeight(props.selectedDuration + 15);
            // // console.log("New height:",newHeight, props.selectedDuration + 15);
            // setCellHeight_css(newHeight);
        }
    }, [props.selectedDuration])

    useEffect(() => {
        if (props.selectedStartTime !== undefined) {
            setSelectedStartTime(props.selectedStartTime);
        }
    }, [props.selectedStartTime])

    useEffect(() => {
        configureClientName();
    }, [props.clientID, props.selectedClient])

    


    function getCellHeight(forDuration: number) {
        let hourHeight = 50;
        let thisHeight = (forDuration / 60) * hourHeight;

        return `${thisHeight}px`
    }

    function getCellYPos(forStartTime: number) {
        let hourHeight = 50;
        var adjStartTime = props.isScheduling ? forStartTime : (props.dayStartTime !== undefined ? forStartTime - props.dayStartTime : forStartTime);
        let thisYPos = (adjStartTime / 60) * hourHeight;

        return `${thisYPos + 20}px`
    }

    function configureClientName() {

        if (props.isScheduling !== undefined && props.isScheduling === true) {
            setClientName(props.selectedClient === undefined ? "New Appointment" :props.selectedClient.full_name);
            return;
        }

        if (props.clientID === undefined) {
            setClientName("New Appointment");
            return;
        }

        // Existing appointment -- pull name using client ID
        let database = firebase.database();
        let ref = database.ref(`user_profile/${props.clientID}/full_name`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();
                setClientName(data);
            } else {
                setClientName("New Appointment");
            }
        });
    }

	return (
		<div 
            hidden={props.selectedStartTime === undefined}
            className={`calendar-day-timeline-cell 
                        ${props.isScheduling === undefined || props.isScheduling === false ? '' : 'calendar-day-timeline-cell-scheduling'}
                        ${props.schedulingConflict === undefined || props.schedulingConflict === false ? '' : 'calendar-day-timeline-cell-error'}`}
            style={{height: getCellHeight(selectedDuration), top: getCellYPos(selectedStartTime)}}>
            <div className="calendar-day-timeline-cell-inner">
                <h4>{clientName}</h4>
                <p>{selectedDuration} mins</p>
            </div>
		</div>
	)
}

export default CalendarDayTimelineCell;