import React, { useState, useEffect, useRef } from 'react';
import { IonInput } from '@ionic/react';

import './CreateWorkoutSetRepInput.css';


function CreateWorkoutSetRepInput(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [item, setItem] = useState(0);
    const [item_temp, setItem_temp] = useState(0);
    const [isFocused, setIsFocused] = useState(false);
    const [inputString, setInputString] = useState('');

    const [updatedByForce, setUpdatedByForce] = useState(false);

    const descRef = useRef();

    //const [timesUpdated, setTimesUpdated] = useState(0);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        if (props.startingItem !== undefined) {
            setItem(props.startingItem === 0 ? undefined : props.startingItem);
            let thisString = props.startingItem === -1 ? 'AMRAP' : (props.startingItem === 0 ? '' : String(props.startingItem));
            setInputString(thisString);
        }
    }

    useEffect(() => {
        // console.log("ITEM use effect triggered: ",item)
        if (item <= -2) {
            // console.log("--- item <= -2: ", -1 * item)
            setItem(-1 * item);
            return;
        }

        if (props.inputChanged !== undefined && updatedByForce === false) {
            props.inputChanged(isNaN(item) === true ? 0 : item);
        }

        
    }, [item]);

    useEffect(() => {
        if (props.forceVal !== undefined) {
            // console.log("props.forceVal useEffect triggered: ", props.forceVal);
            setUpdatedByForce(true);
            setItem(props.forceVal);
            //setTimesUpdated(timesUpdated + 1);
        }
    }, [props.forceVal]);

    function getRepString(forRep: any) {
        // console.log("GET REP STRING | forRep = ", forRep);
        return forRep === -1 ? 'AMRAP' : forRep;
    }

    function repInputUpdated(e: any) {
        // console.log("******------******------******------(repInputUpdated)", e);
        setUpdatedByForce(false);
        if (e === "a" || e === "A") {
            setItem(-1);
        } else if (e === "f" || e === "F") {
            //setItem(-1 * item);
            props.autofillRepsPressed();
        }
    }

    function changeReps(toReps: any) {
        
        var toRepsEdited: any = toReps;
        // console.log("------(changeReps) Changing reps to: ", toReps, Number(toReps));
        
        if (typeof toReps === 'string') {

            if (toReps === inputString) { return; }
            //if (toReps === "AMRAP") {return;}
            let toRepsCharArray = toReps.split('');
            // console.log('Entered with this char array:', toRepsCharArray);
            if (toRepsCharArray.length !== 0) {
                let lastChar = toRepsCharArray[toRepsCharArray.length - 1];
                let lastCharCode = lastChar.charCodeAt(0);
                // console.log('Last char:', lastChar, lastCharCode);
                if (lastCharCode >= 0x30 && lastCharCode <= 0x39) {
                    // the last character entered is a number
                    // console.log('Last char is a number');
                    var toRepsEdited_temp = "";
                    if (toReps.includes("AMRAP")) {
                        toRepsEdited_temp = lastChar;
                    } else {
                        for (var i = 0; i < toRepsCharArray.length; i++) {
                            toRepsEdited_temp += i === 0 && toRepsCharArray[i] === '0' ? '' : toRepsCharArray[i];
                        }
                    }
                    if (toReps.includes("NaN") && toReps !== "NaN") {
                        toRepsEdited_temp = lastChar;
                    }
                    if (toReps.includes("-1") && toReps !== "-1") {
                        toRepsEdited_temp = lastChar;
                    }

                    toRepsEdited = toRepsEdited_temp;

                    setItem(Number(toRepsEdited));
                    // console.log("SETTING ITEM TO: ", toRepsEdited, Number(toRepsEdited));
                    return;

                } else if (lastCharCode === 0x41 || lastCharCode === 0x61) {
                    // the last character entered is either 'A' or 'a'. Set string to AMRAP
                    // console.log('Last char is an a or A');
                    setItem(-1);
                    setInputString('AMRAP');
                    //updatePWMsFromReps(-1, atIndex);
                    return;
                } else if (lastCharCode === 0x46 || lastCharCode === 0x66) {
                    // the last character entered is either 'F' or 'f'.
                    // console.log('Last character code if F or f', lastCharCode);
                    var composedNumString = '0';
                    if (toRepsCharArray.length > 1) {
                        for (var i = 0; i < toRepsCharArray.length - 1; i++){
                            composedNumString += toRepsCharArray[i];
                            // console.log(composedNumString, toRepsCharArray[i]);
                        }
                    }
                    // console.log("composedNumString:", Number(composedNumString));
                    setItem(Number(composedNumString));
                    props.inputChanged(Number(composedNumString));
                    return;
                } else {
                    // invalid character entered

                    return;
                }
            }
            

        } else if (typeof toReps === 'number') {

        } else {
            return;
        }
         
        if (isNaN(Number(toRepsEdited))) {
            // console.log("isNaN: ", toRepsEdited);
            setItem(-1);
            setInputString('AMRAP');
            return;
        }
        
       
        // // console.log(`Setting reps to: ${Number(toReps)}`);
        // setItem(Number(toRepsEdited));
        // setInputString(String(toRepsEdited));

        // if (useRepRange === false) {
        //     changeReps_max(toReps, atIndex);
        // }

        // if (forcePercentWMs === true) {
        //     switch(selectedPWMsModel) {
        //         case 0:         // Poliquin
        //             setPWMs(
        //                 pWMs.map((item, index) => {
        //                     let thisNumReps = reps[index];
        //                     let thisPWM = thisNumReps < poliquin_LUT.length && thisNumReps >= 0 ? poliquin_LUT[thisNumReps] : 50;
        //                     return thisPWM;
        //                 })
        //             );
        //             break;
        //         case 1:         // Standard
        //             setPWMs(
        //                 pWMs.map((item, index) => {
        //                     let thisNumReps = reps[index];
        //                     let thisPWM = thisNumReps < standard_LUT.length && thisNumReps >= 0 ? standard_LUT[thisNumReps] : 50;
        //                     return thisPWM;
        //                 })
        //             );
        //             break;
        //         default:
        //             break;
        //     }
        // }

        //updatePWMsFromReps(Number(toRepsEdited), atIndex);
    }

    function updateFocusState(toState: boolean) {
        if (toState === true && updatedByForce === true) {
            setItem(0);
        }

        setIsFocused(toState);
    }


	return (
		<div className={`create-workout-set-rep-input ${isFocused ? 'create-workout-set-rep-input-focus' : ''}`}>
            <div className={`create-workout-set-rep-input-amrap ${item !== undefined && item < 0 ? 'create-workout-set-rep-input-amrap-show' : 'create-workout-set-rep-input-amrap-hide'}`}>
                <h4>AMRAP</h4>
                <div hidden={!isFocused} className="create-workout-set-rep-input-typing-blinker"/>
            </div>
            <div className={`create-workout-set-rep-input-empty ${item === undefined ? 'create-workout-set-rep-input-empty-show' : 'create-workout-set-rep-input-empty-hide'}`}>
                <h4>{ props.selectedExercise.type === 0 ? "Reps..." : "Duration..." }</h4>
                <div hidden={!isFocused} className="create-workout-set-rep-input-typing-blinker"/>
            </div>
            <div className={`create-workout-set-rep-input-number ${item !== undefined && item >= 0 ? 'create-workout-set-rep-input-number-show' : 'create-workout-set-rep-input-number-hide'}`}>
    			<IonInput 
                    value={item === 0 ? '' : item} 
                    id={`rep-input`}

                    className="create-workout-exercise-configure-set-cell-input"
                    placeholder="0"
                    inputmode="text"
                    clearOnEdit={true}
                    type="text"
                    onFocus={() => updateFocusState(true)}
                    onBlur={() => updateFocusState(false)}
                    onIonChange={e => changeReps(e.detail.value!)}
                    onKeyPress={e => repInputUpdated(e.key)}/>
            </div>
            <div className={ "create-workout-set-rep-input-floating-unit" } hidden={ props.selectedExercise.type === 0 }>
                <p>{props.selectedExercise.type === 0 ? '' : 's'}</p>
            </div>
            <div hidden={!isFocused && item !== 0} className="create-workout-set-rep-input-key-hint">
                <p>F</p>
            </div>
		</div>
	)
}

export default CreateWorkoutSetRepInput;