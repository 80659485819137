import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import firebase from '../../Firebase';
import './NewLoadingPatternModal.css';

import FormTextInput from '../Base/FormTextInput';
import Selector from '../Base/Selector';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

function NewLoadingPatternModal(props: any) {


    const [initSet, setInitSet] = useState(false);

    const [name, setName] = useState("");
    const [categoryIndex, setCategoryIndex] = useState(0);
    const [customCategory, setCustomCategory] = useState(""); 
    const [categories, setCategories] = useState<string[]>(["Relative Strength", 
                                                            "Absolute Strength", 
                                                            "Functional Hypertrophy", 
                                                            "Hypertrophy", 
                                                            "Custom"]);

    const [reps, setReps] = useState<number[]>([0,0,0,0]);
    const [repFocus, setRepFocus] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [usingPresetConfiguration, setUsingPresetConfiguration] = useState(false);
     
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        setIsLoading(true);

        // Check if reps exist
        if (props.information !== undefined && props.information.withReps !== undefined && props.information.withReps.length >= 2) {
            setReps(props.information.withReps);
            setUsingPresetConfiguration(true);
        } 

        // Pull saved loading patterns
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`loading_patterns/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data: any = snapshot.val();
                // console.log("DATA:", data);
                let keys: string[] = Object.keys(data);
                var categoryList: string[] = ["Relative Strength", 
                                                  "Absolute Strength", 
                                                  "Functional Hypertrophy", 
                                                  "Hypertrophy"];

                for (var i = 0; i < keys.length; i++) {
                    let thisKey: string = keys[i];
                    let thisLPObj: any = data[thisKey];
                    
                    if (thisLPObj !== undefined && thisLPObj.category !== undefined) {
                        if (categoryList.indexOf(thisLPObj.category) < 0) {
                            categoryList.push(thisLPObj.category);
                        }
                        
                    }
                    
                }
                categoryList.push("Custom");

                // console.log("List:", categoryList);
                setCategories(categoryList);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        if (props.information !== undefined && props.information.withReps !== undefined && props.information.withReps.length >= 2) {
            setReps(props.information.withReps);
            setUsingPresetConfiguration(true);
        } 
    }, [props.information])

    function closePressed() {
        props.closeModal();
    }

    function removeSet(atIndex: number) {
        if (reps.length > 2) {
            setReps(reps.filter((item: number, index: number) => {return index !== atIndex}));
        }
        
    }
    function addSet() {
        // Add another of the last rep entered
        setReps(reps.concat(reps[reps.length - 1]));
    }

    function inputChanged(toString: any, fromInput: string) {
        // console.log(`INPUT UPDATED     ${fromInput} --- to --- ${toString}`)
        
        switch (fromInput) {
            case 'title':
                setName(toString);
                break;
            case 'category':

                let thisIndex = categories.indexOf(toString);
                if (thisIndex !== categories.length - 1) {
                    setCustomCategory("");
                } 
                setCategoryIndex(thisIndex);
                break;

            case 'custom-category': 
                setCustomCategory(toString);
                break;
        }
    }

    function inputUpdated(atIndex: number, toThing: any) {
        setReps(reps.map((item: number, index: number) => {
            if (index === atIndex) {
                if (toThing === "") {
                    return 0;
                } else if (isNaN(toThing)) {
                    return 0;
                } else {
                    return Number(toThing);
                }
            } else {
                return item;
            }
        }))
    }

    function updateFocusState(index: number, state: boolean) {
        if (index === repFocus) {
            setRepFocus(-1)
        } else {
            setRepFocus(index);
        }
    }

    function repKeyPress(atIndex: number, toThing: any) {

    }

    function getOkayToProceed() {

        // Reject names too short
        if (name.split("").length < 3) {
            return false;
        }

        // Reject custom names too short
        if (categoryIndex === categories.length - 1 && customCategory.split("").length < 3) {
            return false;
        }

        var okayToProceed = true;
        for (var i = 0; i < reps.length; i++) {
            if (isNaN(Number(reps[i])) || reps[i] <= 0) {
                okayToProceed = false;
            }
        }

        return okayToProceed;
    }

    function submit() {
        if (!getOkayToProceed()) { return; }

        setIsLoading(true);

        let loadingPatternObject: any = {
            title: name,
            category: categoryIndex === categories.length - 1 ? customCategory : categories[categoryIndex],
            reps: reps
        }

        // console.log(loadingPatternObject);

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`loading_patterns/${trainerUID}`);
        ref.push(loadingPatternObject);

        setTimeout(() => {
            setIsLoading(false);
            closePressed();
        }, 500);
    }

	return (
		<div className="modal-background new-loading-pattern-modal-background">
			<div className="assign-modal assign-modal-min new-loading-pattern-modal">
                <div hidden={!isLoading} className="new-loading-pattern-modal-loading-curtain">
                    <div className="create-workout-exercise-configure-loading-spinner new-loading-pattern-modal-loading-curtain-spinner"/>
                    <p>Loading...</p>
                </div>
                <div className="new-loading-pattern-modal-header">
                    <h1>Create New Loading Pattern</h1>
                    <div onClick={() => closePressed()} className="new-loading-pattern-modal-header-close-button">
                        <Close className="new-loading-pattern-modal-header-close-button-icon"/>
                    </div>
                </div>
                <div className="new-loading-pattern-modal-content">
                    <div className="new-loading-pattern-modal-content-name-container">
                        <div className="new-loading-pattern-modal-content-name-input-container">
                            <h4>Loading Pattern Name</h4>
                            <FormTextInput 
                                value={name}
                                placeholder="Enter a loading pattern name..." 
                                alert={false} 
                                isSearch={true}
                                autofocus={true}
                                alertText="Enter a loading pattern name..."
                                inputChanged={(e: string) => inputChanged(e, 'title')}/>
                        </div>
                        <div className="new-loading-pattern-modal-content-name-input-container new-loading-pattern-modal-content-name-input-container-selector">
                            <h4>Category</h4>
                            <Selector
                                placeholder="Category"
                                forceSelected={categoryIndex}
                                showTitle={false} 
                                options={categories}
                                inputChanged={(e: number) => inputChanged(e, 'category')}/>
                            <div hidden={categoryIndex !== categories.length - 1} className="new-loading-pattern-modal-content-name-input-container-selector-custom">
                                <FormTextInput 
                                    value={customCategory}
                                    placeholder="Enter a custom category..." 
                                    alert={false} 
                                    isSearch={true}
                                    alertText="Enter a custom category..."
                                    inputChanged={(e: string) => inputChanged(e, 'custom-category')}/>
                            </div>
                        </div>
                    </div>
                    <div className="new-loading-pattern-modal-content-sets-container">
                        <div className="new-loading-pattern-modal-content-sets-header-container">
                            <h4>Configure Loading Pattern</h4>
                            <div hidden={usingPresetConfiguration} onClick={() => addSet()} className="new-loading-pattern-modal-content-sets-add-more-button">
                                <p>+ Add another set</p>
                            </div>
                        </div>
                        <div className="new-loading-pattern-modal-content-sets-content">
                            {
                                reps.map((item: number, index: number) => (
                                    <div className="new-loading-pattern-modal-content-sets-row">
                                        <div className="new-loading-pattern-modal-content-sets-row-title-container">
                                            <h4>Set {index + 1 < 10 ? '0' : ''}{index + 1}</h4>
                                        </div>
                                        <div className="new-loading-pattern-modal-content-sets-row-input-container">
                                            <div hidden={usingPresetConfiguration} className={`new-loading-pattern-modal-content-sets-row-input-container-inner ${index === repFocus ? 'new-loading-pattern-modal-content-sets-row-input-container-inner-focus' : ''}`}>
                                                <IonInput 
                                                    value={item === 0 ? '' : item} 
                                                    id={`rep-input`}
                                                    className="new-loading-pattern-modal-content-sets-row-input"
                                                    placeholder="Enter reps..."
                                                    inputmode="text"
                                                    clearOnEdit={true}
                                                    type="text"
                                                    onFocus={() => updateFocusState(index, true)}
                                                    onBlur={() => updateFocusState(index, false)}
                                                    onIonChange={e => inputUpdated(index, e.detail.value!)}
                                                    onKeyPress={e => repKeyPress(index, e.key)}/>
                                            </div>
                                            <div hidden={!usingPresetConfiguration} className={`new-loading-pattern-modal-content-sets-row-input-container-inner new-loading-pattern-modal-content-sets-row-input-container-inner-no-select`}>
                                                <h4>{item}</h4>
                                            </div>
                                        </div>
                                        <div onClick={() => removeSet(index)} hidden={reps.length <= 2 || usingPresetConfiguration} className="new-loading-pattern-modal-content-sets-row-close-container">
                                            <Close className="new-loading-pattern-modal-content-sets-row-close-icon"/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        
                    </div>
                </div>
                <div className="new-loading-pattern-modal-bottom">
                    <div hidden={isLoading} onClick={() => submit()} className={`new-loading-pattern-modal-bottom-button ${getOkayToProceed() && isLoading === false ? '' : 'new-loading-pattern-modal-bottom-button-unselected'}`}>
                        <p>Finish & Create</p>
                        <ArrowForwardIos className="new-loading-pattern-modal-bottom-button-icon"/>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default NewLoadingPatternModal;