import React, { useState, useEffect } from 'react';
import './NewLocationModal.css';
import GoogleMapReact from 'google-map-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';

import WeekDayTimes_t from '../../Interfaces/Locations/WeekDayTimes_t';
import Amenity_t from '../../Interfaces/Locations/Amenity_t';

import CheckBox from '../Base/CheckBox';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import BlurCircular from '@material-ui/icons/BlurCircular';
import LocalOffer from '@material-ui/icons/LocalOffer';
import FitnessCenter from '@material-ui/icons/FitnessCenter';



function NewLocationModal(props: any) {

    // Maps API key - AIzaSyCv6A-XMT8r20eB6WKUfFRZAZEu4bZUWy4

    let timeDelta = 15;

    const [showEdit, setShowEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [workflowState, setWorkflowState] = useState(0);

    const [isHQ, setIsHQ] = useState(false);
    const [additionalAddressFocus, setAdditionalAddressFocus] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    const [address, setAddress] = useState<any>();
    const [name, setName] = useState("");
    const [additionalAddress, setAdditionalAddress] = useState("")
    const [coordinates, setCoordindates] = useState({lat: 0.0, lng: 0.0});

    const [weekDayTimes, setWeekDayTimes] = useState<WeekDayTimes_t[]>([]);
    const [timeOptions, setTimeOptions] = useState<string[]>([]);
    const [initSet, setInitSet] = useState(false);

    const [amenities, setAmenities] = useState<Amenity_t[]>([
            // Space
            { title: "1-on-1 Training Area", desc: "Dedicated area for one on one training sessions", isSelected: false, iconElement: <BlurCircular className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "space", typeTitle: "Space", jsonName: "one_on_one_training" },
            { title: "Women Only Space", desc: "Seperate space for women to workout", isSelected: false, iconElement: <BlurCircular className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "space", typeTitle: "Space", jsonName: "women_only" },
            { title: "Change Rooms", desc: "Secluded space for athletes to change", isSelected: false, iconElement: <BlurCircular className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "space", typeTitle: "Space", jsonName: "change_rooms" },
            { title: "Lockers", desc: "Secure lockers for athletes to store their belongings ", isSelected: false, iconElement: <BlurCircular className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "space", typeTitle: "Space", jsonName: "lockers" },
            { title: "Showers", desc: "This location has access to showers", isSelected: false, iconElement: <BlurCircular className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "space", typeTitle: "Space", jsonName: "showers" },
            { title: "Sauna", desc: "This location has access to Saunas", isSelected: false, iconElement: <BlurCircular className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "space", typeTitle: "Space", jsonName: "sauna" },

            // Offerings
            { title: "Parking", desc: "Dedicated parking for athletes", isSelected: false, iconElement: <LocalOffer className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "offerings", typeTitle: "Offerings", jsonName: "parking" },
            { title: "Bike Storage", desc: "Secure area to secure and lock bikes", isSelected: false, iconElement: <LocalOffer className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "offerings", typeTitle: "Offerings", jsonName: "bike_storage" },

            // Workouts
            { title: "Classes", desc: "This location offers fitness classes", isSelected: false, iconElement: <FitnessCenter className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "workouts", typeTitle: "Workouts",jsonName: "classes" },
            { title: "Free Weights", desc: "This location has access to free weights", isSelected: false, iconElement: <FitnessCenter className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "workouts", typeTitle: "Workouts",jsonName: "free_weights" },
            { title: "Olympic Platform", desc: "This location has olympic lifting platforms", isSelected: false, iconElement: <FitnessCenter className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "workouts", typeTitle: "Workouts",jsonName: "olympic_platform" },
            { title: "Studio Room", desc: "Seperate studio room for yoga, stretching, ab work, etc.", isSelected: false, iconElement: <FitnessCenter className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "workouts", typeTitle: "Workouts",jsonName: "studio_room" },
            { title: "Cardio Space", desc: "Dedicated space for cardio machines", isSelected: false, iconElement: <FitnessCenter className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "workouts", typeTitle: "Workouts",jsonName: "cardio_space" },
            { title: "Functional Space", desc: "Dedicated space for functional training", isSelected: false, iconElement: <FitnessCenter className="new-location-modal-amenity-row-content-text-container-upper-icon"/>, type: "workouts", typeTitle: "Workouts", jsonName: "functional_space" },
        ])
    

    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

        // init week day times
        let dayStrings = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        let defaultOpenTime = 420; // in mins -- 420 = 7am
        let defaultCloseTime = 1080; // 1080 = 6pm

        var weekDayTimes_temp: WeekDayTimes_t[] = [];
        for (var i = 0; i < dayStrings.length; i++) {
            weekDayTimes_temp.push({
                title: dayStrings[i],
                open: defaultOpenTime,
                close: defaultCloseTime,
                isOpen: true,
                is24h: false,
                menu_open_openTime: false,
                menu_open_closeTime: false
            });
        }

        setWeekDayTimes(weekDayTimes_temp);


        // init time options
        
        let numOptions = (24 * 60) / timeDelta;

        var timeOptions_temp: string[] = [];
        for (var i = 0; i < numOptions; i++) {
            timeOptions_temp.push(getTimeStringFromMinutes(i * timeDelta));
        }

        setTimeOptions(timeOptions_temp);
    }

    function getTimeStringFromMinutes(forMinutes: number) {

        let hrs = Math.floor(forMinutes / 60);
        let mins = Math.floor(forMinutes % 60);
        let isAm = hrs < 12;
        var hrs_adj = !isAm ? hrs - 12 : hrs;
        hrs_adj = hrs_adj === 0 ? 12 : hrs_adj;

        return `${hrs_adj}:${mins < 10 ? '0' : ''}${mins} ${isAm ? 'am' : 'pm'}`;
    }

    useEffect(() => {
        // console.log("UPDATED VALUE", address);
        if (address !== null && address !== undefined && address.label !== "") {
            geocodeByAddress(address.label)
            .then(results => getLatLng(results[0]))
            .then((e: { lat: number, lng: number }) => {
                // console.log('Successfully got latitude and longitude', e.lat, e.lng );

                if (e !== undefined && e !== null) {
                    setCoordindates({ lat: e.lat, lng: e.lng });
                }
            });
        }
    }, [address]);

    function inputChanged(toString: any, forInput: string) {
        switch (forInput) {
            case 'name':
                setName(toString);

                break;
            case 'additional-address':
                setAdditionalAddress(toString);
                break;
            default:
                break;
        }
    }

    function weekdayOpenStateChanged(newState: boolean, forIndex: number) {
        setWeekDayTimes(weekDayTimes.map((item: WeekDayTimes_t, index: number) => {
            if (index === forIndex) {
                return {...item, isOpen: newState};
            } else {
                return item;
            }
        }))
    }

    function toggleOpenTimeMenu(forIndex: number) {
        setWeekDayTimes(weekDayTimes.map((item: WeekDayTimes_t, index: number) => {
            if (index === forIndex) {
                return {...item, menu_open_openTime: !item.menu_open_openTime, menu_open_closeTime: false};
            } else {
                return item;
            }
        }))
    }

    function toggleCloseTimeMenu(forIndex: number) {
        setWeekDayTimes(weekDayTimes.map((item: WeekDayTimes_t, index: number) => {
            if (index === forIndex) {
                return {...item, menu_open_closeTime: !item.menu_open_closeTime, menu_open_openTime: false};
            } else {
                return item;
            }
        }))
    }

    function updateOpeningTime(optionIndex: number, forIndex: number) {
        setWeekDayTimes(weekDayTimes.map((item: WeekDayTimes_t, index: number) => {
            if (index >= forIndex) {
                return {...item, open: optionIndex * timeDelta, menu_open_openTime: false};
            } else {
                return item;
            }
        }))
    }

    function updateClosingTime(optionIndex: number, forIndex: number) {
        setWeekDayTimes(weekDayTimes.map((item: WeekDayTimes_t, index: number) => {
            if (index >= forIndex) {
                return {...item, close: optionIndex * timeDelta, menu_open_closeTime: false};
            } else {
                return item;
            }
        }))
    }

    function amenitiyStateUpdate(newState: boolean, forIndex: number) {
        setAmenities(amenities.map((item: Amenity_t, index: number) => {
            if (index === forIndex) {
                return {...item, isSelected: newState};
            } else {
                return item;
            }
        }))
    }

    function showRow(wkDayObj: WeekDayTimes_t, index_time: number) {
        let thisTimeMins = timeDelta * index_time;
        return thisTimeMins > wkDayObj.open;
    }

    function validateAddress() {
        let addressPlaceIDValid = address !== undefined && address !== null && address.value !== undefined && address.value !== null && address.value.place_id !== undefined && address.value.place_id !== null && address.value.place_id !== "";
        return addressPlaceIDValid;
    }

    function validatePage() {
        // validate name
        let nameSplit = name.split("");
        let nameValid = nameSplit.length >= 3;

        // validate address input
        let addressPlaceIDValid = validateAddress();
        return nameValid && addressPlaceIDValid;
    }

    function submit() {

        if (!validatePage()) { return; }

        setIsLoading(true);

        var amenitiesObj_space: any = {};
        var amenitiesObj_offerings: any = {};
        var amenitiesObj_workouts: any = {};
        for (var i = 0; i < amenities.length; i++) {
            let thisAmenity: Amenity_t = amenities[i];
            switch (thisAmenity.type) {
                case "space":
                    amenitiesObj_space[thisAmenity.jsonName] = thisAmenity.isSelected;
                    break;
                case "offerings":
                    amenitiesObj_offerings[thisAmenity.jsonName] = thisAmenity.isSelected;
                    break;
                case "workouts":
                    amenitiesObj_workouts[thisAmenity.jsonName] = thisAmenity.isSelected;
                    break;
                default:
                    break;
            }
        }

        let locationObj = {
            name: name,
            location: {
                coordinates: coordinates,
                google_place_id: address.value.place_id,
                address_string: address.value.description,
                unit_string: additionalAddress,
                isHQ: isHQ
            },
            times: {
                is24h: false,
                monday: {
                    open: weekDayTimes[0].open,
                    close: weekDayTimes[0].close,
                    isOpen: weekDayTimes[0].isOpen
                },
                tuesday: {
                    open: weekDayTimes[1].open,
                    close: weekDayTimes[1].close,
                    isOpen: weekDayTimes[1].isOpen
                },
                wednesday: {
                    open: weekDayTimes[2].open,
                    close: weekDayTimes[2].close,
                    isOpen: weekDayTimes[2].isOpen
                },
                thursday: {
                    open: weekDayTimes[3].open,
                    close: weekDayTimes[3].close,
                    isOpen: weekDayTimes[3].isOpen
                },
                friday: {
                    open: weekDayTimes[4].open,
                    close: weekDayTimes[4].close,
                    isOpen: weekDayTimes[4].isOpen
                },
                saturday: {
                    open: weekDayTimes[5].open,
                    close: weekDayTimes[5].close,
                    isOpen: weekDayTimes[5].isOpen
                },
                sunday: {
                    open: weekDayTimes[6].open,
                    close: weekDayTimes[6].close,
                    isOpen: weekDayTimes[6].isOpen
                }
            },
            amenities: {
                "space": amenitiesObj_space,
                "offerings": amenitiesObj_offerings,
                "workouts": amenitiesObj_workouts
            }
        }

        // 1. Pull trainer profile for studio ID
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        let database = firebase.database();
        let ref = database.ref(`personal_trainer_profile/${trainerUID}`);
        ref.once('value', function(snapshot) {
            if (snapshot.exists() === true) {
                let data = snapshot.val();

                // console.log("PROFILE FOR", trainerUID, data)

                if (data.studio_id === undefined || data.studio_id === null || data.studio_id === "") {
                    // Create a new studio
                    createNewStudio(locationObj, data);
                } else {
                    addObjAsLocation(locationObj, data.studio_id);
                }

            } else {
                
            }
        });

        // console.log(locationObj);
    }

    function createNewStudio(locationObj: any, profileObj: any) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) { return; }
        let trainerUID = currentUser.uid;

        // console.log("createNewStudio", locationObj, profileObj);

        let database = firebase.database();
        let studiosRef = database.ref('training_studios');
        let pushRef = studiosRef.push({
            city: profileObj.city !== undefined ? profileObj.city : '',
            created: Date.now(),
            name: profileObj.company_name !== undefined ? profileObj.company_name : 'My Studio',
            trainers: [
                {
                    id: trainerUID,
                    permission: 0,
                    email: profileObj.email !== undefined ? profileObj.email : ''
                }
            ]
        })

        if (pushRef.key !== null) {
            addObjAsLocation(locationObj, pushRef.key);
        }
        
    }

    function addObjAsLocation(locationObj: any, studioID: string) {

        let database = firebase.database();
        let studiosRef = database.ref(`training_studios/${studioID}/locations`);
        let pushRef = studiosRef.push(locationObj);

        let pushKey = pushRef.key;

        // console.log("LOCATION CREATED WITH ID", pushKey);

        setTimeout(() => {
            setIsLoading(false);
            props.hideModalPressed();
        }, 1000);
        

    }


	return (
		<div className="modal-background assign-modal-background">
            <div className={`assign-modal assign-modal-${showEdit ? 'max' : 'min'} new-location-modal`}>
                <div className="assign-modal-upper">
                    <h4>Add a New Location to Your Gym</h4>
                    <div onClick={() => props.hideModalPressed()} className="assign-modal-upper-close-button-container">
                        <Close className="assign-modal-upper-close-button-icon"/>
                    </div>
                </div>
                <div hidden={isLoading || workflowState !== 0} className="assign-modal-body new-location-modal-body">
                    <div className={`assign-modal-body-left assign-modal-body-left-${showEdit ? 'max' : 'min'}`}>
                        <div className="new-location-modal-body-section">
                            <div className="assign-modal-body-row-header">
                                <h4>Setup the new location’s name and address</h4>
                            </div>
                            <div className="assign-modal-body-row">
                                <div className="assign-modal-body-row-label-container">
                                    <h4>New Location Name</h4>
                                </div>
                                <div className="assign-modal-body-row-input-container">
                                    <div className={`assign-modal-body-client-name-input-container ${nameFocus ? "assign-modal-body-client-name-input-container-focus" : ""}`}>
                                        <IonInput value={name} 
                                            id="assign-to-client-input"
                                            autofocus={true}
                                            placeholder="Enter a location name..."
                                            inputmode="text"
                                            clearOnEdit={true}
                                            type="text"
                                            onIonChange={e => inputChanged(e.detail.value!, 'name')}
                                            onFocus={(e) => setNameFocus(true)}
                                            onBlur={(e) => setNameFocus(false)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="assign-modal-body-row">
                                <div className="assign-modal-body-row-label-container">
                                    <h4>Location Street Address</h4>
                                </div>
                                <div className="">
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            isClearable: true,
                                            value: address,
                                            onChange: (val: any) => {
                                                setAddress(val);
                                            },
                                            placeholder: "Enter a street address...",
                                            styles: {
                                                input: (provided: any) => ({
                                                    ...provided,
                                                    color: 'white',
                                                    fontSize: '13.5px'
                                                }),
                                                option: (provided: any, state: any) => ({
                                                    ...provided,
                                                    background:'#1F1F21',
                                                    color: '#ffffff',
                                                    fontSize: '13.5px',
                                                    '&:hover': {
                                                        background: '#1F1F21',
                                                        cursor: 'pointer'
                                                    }
                                                }),
                                                singleValue: (provided: any) => ({
                                                    ...provided,
                                                    color: 'white',
                                                    fontSize: '13.5px',
                                                }),
                                                menu: (provided: any) => ({
                                                    ... provided,
                                                    background: '#1F1F21',
                                                    boxShadow: '0px 0px 16px 10px rgba(0,0,0,0.08)',
                                                }),
                                                valueContainer: (provided: any) => ({
                                                    ... provided,
                                                }),
                                                indicatorsContainer: (provided: any) => ({
                                                    ... provided
                                                }),
                                                control: (provided: any, state: any) => ({
                                                    ... provided,
                                                    background: state.isFocused ? 'rgba(0,0,0,0.12)' : '#1F1F21',
                                                    borderRadius: '5px',
                                                    borderColor: state.isFocused ? '#5B62FF' : '#2B2B30',
                                                    borderWidth: '1px',
                                                    fontSize: '13.5px',
                                                    boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.08)',
                                                    paddingLeft: '8px',
                                                    '&:hover': {
                                                        background: 'rgba(0,0,0,0.12)',
                                                        cursor: 'pointer'
                                                    }
                                                }),
                                                dropdownIndicator: (provided: any) => ({
                                                    ... provided,
                                                    color: '#6C6C6C'
                                                }),
                                                indicatorSeparator: (provided: any) => ({
                                                    ... provided,
                                                    background: '#2B2B30',
                                                }),
                                                loadingIndicator: (provided: any) => ({
                                                    ... provided,
                                                    color: '#5B62FF',
                                                }),
                                            },
                                        }}
                                        apiKey="AIzaSyCv6A-XMT8r20eB6WKUfFRZAZEu4bZUWy4"
                                    />
                                </div>
                            </div>
                            <div hidden={!validateAddress()} className="assign-modal-body-row">
                                <div className="assign-modal-body-row-label-container new-location-modal-row-label-container">
                                    <h4>Unit Number & Additional Info</h4>
                                    <div className="new-location-modal-row-label-optional-container">
                                        <p>Optional</p>
                                    </div>
                                </div>
                                <div className="assign-modal-body-row-input-container">
                                    <div className={`assign-modal-body-client-name-input-container ${additionalAddressFocus ? "assign-modal-body-client-name-input-container-focus" : ""}`}>
                                        <IonInput value={additionalAddress} 
                                            id="assign-to-client-input"
                                            placeholder="Enter unit number or Additional Info"
                                            inputmode="text"
                                            clearOnEdit={true}
                                            type="text"
                                            onIonChange={e => inputChanged(e.detail.value!, 'additional-address')}
                                            onFocus={(e) => setAdditionalAddressFocus(true)}
                                            onBlur={(e) => setAdditionalAddressFocus(false)}/>
                                    </div>
                                </div>
                            </div>
                            <div hidden={!validateAddress()} className="assign-modal-body-row">
                                <div className="assign-modal-body-row-input-row-container new-location-modal-hq-container">
                                    <div className="assign-modal-body-row-input-container-checkbox-container">
                                        <CheckBox 
                                            forceState={isHQ}
                                            stateChanged={(s: boolean) => setIsHQ(s)}/>
                                    </div>
                                    <div className="assign-modal-body-row-input-container-checkbox-text-container">
                                        <h4>Location is Headquaters</h4>
                                        <p>Set this location as our gym headquarters to help keep track of your locations</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="new-location-modal-body-section">
                            <div className="assign-modal-body-row-header">
                                <h4>Configure Open Times</h4>
                            </div>
                            <div className="new-location-modal-times-header">
                                <div className="new-location-modal-times-header-col">
                                    <p>Open Time</p>
                                </div>
                                <div className="new-location-modal-times-header-col">
                                    <p>Close Time</p>
                                </div>
                            </div>
                            {
                                weekDayTimes.map((item: WeekDayTimes_t, index: number) => (
                                    <div className={`new-location-modal-day-time-row ${item.isOpen ? '' : 'new-location-modal-day-time-row-unselected'}`}>
                                        <div className="new-location-modal-day-time-row-checkbox-container">
                                            <CheckBox 
                                                forceState={item.isOpen}
                                                stateChanged={(s: boolean) => weekdayOpenStateChanged(s, index)}/>
                                        </div>
                                        <div onClick={() => toggleOpenTimeMenu(index)} className="new-location-modal-day-time-row-title-container">
                                            <p>{item.title}</p>
                                        </div>
                                        <div className="new-location-modal-day-time-row-input-container">
                                            <div onClick={() => toggleOpenTimeMenu(index)}  className="new-location-modal-day-time-row-input-container-inner">
                                                <p>{getTimeStringFromMinutes(item.open)}</p>
                                            </div>
                                            <CSSTransition in={item.menu_open_openTime && item.isOpen === true} timeout={200} classNames="menu" unmountOnExit>
                                                <div className="new-location-modal-day-time-row-input-menu">
                                                    {
                                                        timeOptions.map((item_time: string, index_time: number) => (
                                                            <div onClick={() => updateOpeningTime(index_time, index)} className="new-location-modal-day-time-row-input-menu-row">
                                                                <p>{item_time}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </CSSTransition>
                                        </div>
                                        <div className="new-location-modal-day-time-row-input-container">
                                            <div onClick={() => toggleCloseTimeMenu(index)}  className="new-location-modal-day-time-row-input-container-inner">
                                                <p>{getTimeStringFromMinutes(item.close)}</p>
                                            </div>
                                            <CSSTransition in={item.menu_open_closeTime && item.isOpen === true} timeout={200} classNames="menu" unmountOnExit>
                                                <div className="new-location-modal-day-time-row-input-menu">
                                                    {
                                                        timeOptions.map((item_time: string, index_time: number) => (
                                                            <div hidden={!showRow(item, index_time)} onClick={() => updateClosingTime(index_time, index)} className="new-location-modal-day-time-row-input-menu-row">
                                                                <p>{item_time}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </CSSTransition>
                                        </div>
                                    </div>
                                ))
                            }
                            
                        </div>
                        <div className="new-location-modal-body-section">
                            <div className="assign-modal-body-row-header">
                                <h4>Location Amenities</h4>
                            </div>
                            <div className="new-location-modal-amenity-container">
                                {
                                    amenities.map((item: Amenity_t, index: number) => (
                                        <div className="new-location-modal-amenity-row">
                                            <div hidden={index !== 0 && !(amenities[index - 1] !== undefined && item.type !== amenities[index - 1].type)} className="new-location-modal-amenity-row-header">
                                                <h4>{item.typeTitle}</h4>
                                            </div>
                                            <div className="new-location-modal-amenity-row-content">
                                                <div className="new-location-modal-amenity-row-content-checkbox-container">
                                                    <CheckBox 
                                                        forceState={item.isSelected}
                                                        stateChanged={(s: boolean) => amenitiyStateUpdate(s, index)}/>
                                                </div>
                                                <div className="new-location-modal-amenity-row-content-text-container">
                                                    <div className="new-location-modal-amenity-row-content-text-container-upper">
                                                        {item.iconElement}
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                    <p>{item.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={!isLoading} className="assign-modal-body-loading">
                    <div className="assign-modal-body-loading-inner">
                        <div className="client-list-container-loading-spinner"/>
                        <p>Saving Location...</p>
                    </div>
                </div>    
                <div className="assign-modal-lower">
                    <div hidden={isLoading || workflowState !== 0} onClick={() => submit()} className={`feedback-modal-cta-button ${validatePage() ? "" : "assign-modal-cta-button-unselected"}`}>Finish & Add Location</div>
                </div>     
            </div>
		</div>
	)
}

export default NewLocationModal;