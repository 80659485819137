
import React, { useState, useEffect } from 'react';
import './ExerciseBreakdownCard.css';

import SetBreakdownRow from './SetBreakdownRow';

import '@material-ui/core';
import GraphicEq from '@material-ui/icons/GraphicEq';

function SetBreakdownCard(props: any) {

    const [setList, setSetList] = useState<any[]>([]);
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) initialize();
    }, [initSet])

    useEffect(() => {
        if (props.setList) setSetList(props.setList);
    }, [props.setList])

    const initialize = () => {
        
    }


    const getVolumePercentage = () => {
    	if (!props.totalVolume || props.totalVolume === 0 || !props.setList || props.setList.length === 0) return 0;

    	var thisVol = 0;
		for (var j = 0; j < props.setList.length; j++) {
			let thisSet = props.setList[j];
			if (thisSet && thisSet.is_complete !== undefined && thisSet.is_complete === true) {
				thisVol += thisSet.reps_completed ?? 0;
			}
		}

    	return Math.floor(thisVol / props.totalVolume * 1000) / 10
    }

	return (
		<div className={ "exercise-breakdown-card" }>
			<div className={ "exercise-breakdown-card-header" }>
                <h4>{ props.title }</h4>
                <div className={ "exercise-breakdown-card-header-summary-container" }>
                	<p>{ setList.length } set{ setList.length === 1 ? '' : 's'}  |  { getVolumePercentage() }% Total Volume</p>
                </div>
            </div>
            {
            	setList.length === 0 ?
            	<div className={ "exercise-breakdown-card-body-empty-container" }>
            		<p>No sets completed</p>
            	</div>
            	:
            	<div className={ "exercise-breakdown-card-body-container" }>
	                <div className={ "exercise-breakdown-card-body-header" }>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-setnum" }>
	                        <p>Set Number</p>
	                    </div>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-reps" }>
	                        <p>Reps</p>
	                    </div>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-cross" }>
	                        
	                    </div>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-weight" }>
	                        <p>Weight</p>
	                    </div>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-tempo" }>
	                        <p>Tempo</p>
	                    </div>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-rest" }>
	                        <p>Rest Time</p>
	                    </div>
	                    <div className={ "exercise-breakdown-card-body-col-header-col exercise-breakdown-card-body-col exercise-breakdown-card-body-col-score" }>
	                        <p>Performance Score</p>
	                    </div>
	                </div>
	                <div className={ "exercise-breakdown-card-body-content" }>
	                    {
	                        setList.map((item: any, index: number) => (
	                            <SetBreakdownRow 
	                            	set={ item }
	                            	setNum={ index + 1 }/>
	                        ))
	                    }
	                </div>
	            </div>
            }
            
            <div className={ "exercise-breakdown-card-selector-container" }>
                <div className={ "exercise-breakdown-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default SetBreakdownCard;