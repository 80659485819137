import React, { useState, useEffect } from 'react';
import './PairNodesModal.css';

import NodeSet_t from '../../Interfaces/NodeSet_t';
import NodeInstance from '../../NodeInstance';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Bluetooth from '@material-ui/icons/Bluetooth';


function PairNodesModal(props: any) {

    const [initSet, setInitSet] = useState(false);

    const [workflowState, setWorkflowState] = useState(0);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [pairedCount, setPairedCount] = useState(0);

    const [nodeSets, setNodeSets] = useState<NodeSet_t[]>([
        {
            setSIN: "000001",
            UUIDs: ["BBA111","BBA112","ABC113","AAA114","AAA115","AAA116","AAA117","AAA118","AAA119"],
            setSize: 9
        },
        {
            setSIN: "00000A",
            UUIDs: ["AAA111","AAA112","AAA113","AAA114","AAA115","AAA116","AAA117","AAA118"],
            setSize: 9
        },
        {
            setSIN: "0000FF",
            UUIDs: ["AAA111","AAA112","AAA113","AAA114","AAA115"],
            setSize: 9
        },
        {
            setSIN: "00004B",
            UUIDs: ["AAA111","AAA112","AAA113","AAA114","AAA115","AAA116","AAA117"],
            setSize: 9
        }
    ]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        var tempNodeSets: NodeSet_t[] = [];

        if (props.discoveredNodesList.length > 0) {
            // console.log("PAIR NODES MODAL: disocvered list length is ", props.discoveredNodesList.length);
            for (var i = 0; i < props.discoveredNodesList.length; i++) {
                let thisNode: NodeInstance = props.discoveredNodesList[i];
                let thisSetSIN = thisNode.getSetSIN();

                var tempSetSINIndex = -1;
                if (tempNodeSets.length > 0) {
                    for (var j = 0; j < tempNodeSets.length; j++) {
                        let thisSet = tempNodeSets[j];
                        if (thisSet.setSIN === thisSetSIN) {
                            tempSetSINIndex = j;
                        }
                    }
                }

                if (tempSetSINIndex === -1) {
                    let newSet: NodeSet_t = {setSIN: thisSetSIN, UUIDs: [thisNode.getUUID()], setSize: 9, pairingState: 0, isOpen: false};
                    tempNodeSets.push(newSet);
                } else {
                    tempNodeSets[tempSetSINIndex].UUIDs.push(thisNode.getUUID());
                }
            }
        }

        setNodeSets(tempNodeSets);
    }, [props.discoveredNodesList]);

    useEffect(() => {
        if (props.myNodesList !== undefined && props.myNodesList.length > 0) {
            var NodesListTemp: NodeInstance[] = props.myNodesList;

            setNodeSets(nodeSets.map((item: NodeSet_t, index: number) => {
                if (item.pairingState !== undefined && item.pairingState === 1) {
                    // Node Set is in process of pairing - check if Set's Nodes have been added to My Nodes list
                    let temp = item;
                    var setPaired = true;
                    for (var i = 0; i < temp.UUIDs.length; i++) {
                        var nodeIsPaired = false;
                        let thisUUID = temp.UUIDs[i];
                        for (var j = 0; j < NodesListTemp.length; j++) {
                            let thisNode: NodeInstance = NodesListTemp[j];
                            if (thisNode.getUUID() === thisUUID) {
                                nodeIsPaired = true;
                            }
                        }

                        if (nodeIsPaired === false) {
                            setPaired = false;
                        }
                    }

                    temp.pairingState = setPaired === true ? 2 : temp.pairingState;

                    if (setPaired === true) {
                        setPairedCount(pairedCount + 1);
                    }

                    return temp;
                } else {
                    return item;
                }
            }));
        }
    }, [props.myNodesList])

    function closePressed() {
        props.closeModal();
    }


    function searchForNodes() {
        if (props.nodeManager === undefined) { return; }
        props.nodeManager.scanForNodes();
        setWorkflowState(1);
        setLoadingProgress(0);

        setTimeout(() => {
            advanceLoading(0);
        }, 800);

        setTimeout(() => {
            setWorkflowState(2);
        }, 8000);
    }

    function advanceLoading(thisLoadingPhase: number) {
        let nextLoadingPhase = thisLoadingPhase >= 5 ? 0 : thisLoadingPhase + 1;
        setLoadingProgress(nextLoadingPhase);

        if (workflowState <= 1) {
            setTimeout(() => {
                advanceLoading(nextLoadingPhase);
            }, 800);
        }
    }

    function pairSetPressed(forSet: NodeSet_t, forIndex: number) {
        // console.log(`> NODES.tsx: Adding set ${forSet.setSIN} to my list`);

        if (props.nodeManager !== undefined) {
            props.nodeManager.addSetToMyList(forSet.setSIN);
        }

        setNodeSets(nodeSets.map((item: NodeSet_t, index: number) => {
            if (forIndex === index) {
                let temp = item;
                temp.pairingState = 1;
                return temp;
            } else {
                return item;
            }
        }));

        setTimeout(() => {
            //setPaired(forSet, forIndex);
        }, 800);
    }

    function setPaired(forSet: NodeSet_t, forIndex: number) {
        setNodeSets(nodeSets.map((item: NodeSet_t, index: number) => {
            if (forSet.setSIN === item.setSIN) {
                let temp = item;
                temp.pairingState = 2;
                return temp;
            } else {
                return item;
            }
        }))
    }


	return (
		<div className="modal-background pair-nodes-modal-background">
            <div className="new-element-modal-container pair-nodes-modal">
                <div onClick={() => closePressed()} hidden={pairedCount > 0} className="new-element-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
                <div onClick={() => closePressed()} hidden={pairedCount === 0} className="new-element-modal-floating-close pair-nodes-modal-floating-close"><p>Done</p></div>
                <div hidden={workflowState !== 0} className="pair-nodes-modal-content-container">
                    <div className="pair-nodes-modal-header">
                        <h2>Begin Pairing your Nodes</h2>
                        <p>Make sure that your Nodes are ON, <span>BLUE</span>, and within 10m of your tablet.</p>
                    </div>
                    <div className="pair-nodes-modal-body">
                        <div className="pair-nodes-modal-body-image-container">
                            <img className="pair-nodes-modal-body-image" src={`${process.env.PUBLIC_URL}/assets/images/devices/tablet-node-pair-1.png`}/>
                        </div>
                        <div onClick={() => searchForNodes()} className="pair-nodes-modal-body-button pair-nodes-modal-body-button-search">
                            <p>Search for Nodes</p>
                        </div>
                    </div>
                </div>
                <div hidden={workflowState !== 1} className="pair-nodes-modal-content-container">
                    <div className="pair-nodes-modal-header">
                        <h2>Searching for Nodes...</h2>
                        <div className="pair-nodes-modal-header-loading-circles-container">
                            <div className={`pair-nodes-modal-header-loading-circle ${loadingProgress <= 0 ? '' : 'pair-nodes-modal-header-loading-circle-highlighted'}`}></div>
                            <div className={`pair-nodes-modal-header-loading-circle ${loadingProgress <= 1 ? '' : 'pair-nodes-modal-header-loading-circle-highlighted'}`}></div> 
                            <div className={`pair-nodes-modal-header-loading-circle ${loadingProgress <= 2 ? '' : 'pair-nodes-modal-header-loading-circle-highlighted'}`}></div> 
                            <div className={`pair-nodes-modal-header-loading-circle ${loadingProgress <= 3 ? '' : 'pair-nodes-modal-header-loading-circle-highlighted'}`}></div> 
                            <div className={`pair-nodes-modal-header-loading-circle ${loadingProgress <= 4 ? '' : 'pair-nodes-modal-header-loading-circle-highlighted'}`}></div> 
                        </div>
                    </div>
                    <div className="pair-nodes-modal-body">
                        <div className="pair-nodes-modal-body-nodes-list-header-container">
                            <h4>Discovered Nodes</h4>
                            <p>{nodeSets.length} Set{nodeSets.length === 1 ? '' : 's'} discovered</p>
                        </div>
                        <div className="pair-nodes-modal-body-nodes-list-container">
                            { nodeSets.map((item: NodeSet_t, index: number) => (
                                <div className="pair-nodes-modal-body-nodes-list-cell-container">
                                    <div className="pair-nodes-modal-body-nodes-list-cell-header-container">
                                        <div className="pair-nodes-modal-body-nodes-list-cell-header-bt-circle">
                                            <Bluetooth className="pair-nodes-modal-body-nodes-list-cell-header-bt-circle-icon"/>
                                        </div>
                                        <div className="pair-nodes-modal-body-nodes-list-cell-header-name">
                                            <h4>Node Set {item.setSIN}</h4>
                                        </div>
                                        <div className="pair-nodes-modal-body-nodes-list-cell-header-count">
                                            <p>{item.UUIDs.length} Nodes discovered</p>
                                        </div>
                                    </div>
                                    <div className="pair-nodes-modal-body-nodes-list-cell-body-container">
                                        { item.UUIDs.map((item_node: string, index_node: number) => (
                                            <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-container">
                                                <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-circle"/>
                                                <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-name">
                                                    <h4>Node 0{index_node + 1}</h4>
                                                </div>
                                                <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-id">
                                                    <p>{item_node}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div hidden={workflowState !== 2} className="pair-nodes-modal-content-container">
                    <div className="pair-nodes-modal-header">
                        <h2>Select Node Sets to Pair</h2>
                        <p>Pair with Nodes from the discovered Nodes list below</p>
                    </div>
                    <div className="pair-nodes-modal-body">
                        <div className="pair-nodes-modal-body-nodes-list-header-container">
                            <h4>Discovered Nodes</h4>
                            <p>{nodeSets.length} Set{nodeSets.length === 1 ? '' : 's'} discovered</p>
                        </div>
                        <div className="pair-nodes-modal-body-nodes-list-container">
                            { nodeSets.map((item: NodeSet_t, index: number) => (
                                <div className="pair-nodes-modal-body-nodes-list-cell-container">
                                    <div className="pair-nodes-modal-body-nodes-list-cell-header-container">
                                        <div className="pair-nodes-modal-body-nodes-list-cell-header-bt-circle">
                                            <Bluetooth className="pair-nodes-modal-body-nodes-list-cell-header-bt-circle-icon"/>
                                        </div>
                                        <div className="pair-nodes-modal-body-nodes-list-cell-header-name">
                                            <h4>Node Set {item.setSIN}</h4>
                                        </div>
                                        <div className="pair-nodes-modal-body-nodes-list-cell-header-pair-state-container">
                                            <div hidden={item.pairingState === undefined || item.pairingState !== 0} onClick={() => pairSetPressed(item, index)} className="pair-nodes-modal-body-nodes-list-cell-header-button">
                                                <p>Pair this Set</p>
                                            </div>
                                            <div hidden={item.pairingState === undefined || item.pairingState !== 1} onClick={() => pairSetPressed(item, index)} className="pair-nodes-modal-body-nodes-list-cell-header-pairing-container">
                                                <div className="pair-nodes-modal-body-nodes-list-cell-header-pairing-spinner"/>
                                                <p>Pairing...</p>
                                            </div>
                                            <div hidden={item.pairingState === undefined || item.pairingState !== 2} onClick={() => pairSetPressed(item, index)} className="pair-nodes-modal-body-nodes-list-cell-header-pairing-container">
                                                <Check className="pair-nodes-modal-body-nodes-list-cell-header-pairing-check"/>
                                                <p>Set Paired</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pair-nodes-modal-body-nodes-list-cell-body-container">
                                        { item.UUIDs.map((item_node: string, index_node: number) => (
                                            <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-container">
                                                <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-circle"/>
                                                <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-name">
                                                    <h4>Node 0{index_node + 1}</h4>
                                                </div>
                                                <div className="pair-nodes-modal-body-nodes-list-cell-body-cell-id">
                                                    <p>{item_node}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default PairNodesModal;