import React from 'react';
import axios from 'axios'
import firebase from '../../../Firebase';

import getUserPushId from './getUserPushId';


const sendPushNotification = async (
		user_uid: string,
		loud_notification: boolean,
		include_badge: boolean,
		title: string,
		body: string,
		data: any,
		subtitle?: string,
	) => {
    
    return new Promise<boolean>( async function(resolve, reject) {
    	
    	try {
    		

			let sendData = {
			    user_uid: user_uid,
	        	title: title,
	        	body: body,
	        	subtitle: subtitle ?? "",
	        	send_data: data
			};

			let resp = await axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/sendPushNotification`,
				sendData,
				{ headers: {'Content-Type': 'application/json'} });

			let respData = resp ? resp.data : null;
			
			console.log(respData);

			resolve(true);

    		/*
    		// Get the logged in user
    		let userPushId = await getUserPushId(user_uid);
    		if (!userPushId || userPushId === "") reject(`API/sendPushNotification (${user_uid}). No valid push notification ID found for given user UID. <${ userPushId }>`)

			let sendData = {
			    to: userPushId,
			    sound: "default",
			    // badge: include_badge ? 1 : null,
			    title: title ?? "",
			    body: body ?? "",
			    data: data ?? { data: "none" },
			    // subtitle: subtitle ?? null
			};

			let headerData = {
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip,deflate,br',
				'Connection': 'keep-alive',
			}

			fetch('https://exp.host/--/api/v2/push/send', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Accept-Encoding': 'gzip, deflate',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*'
				},
				body: JSON.stringify(sendData),
			})
			*/
			/*
			let response = await axios.post(`https://exp.host/--/api/v2/push/send`, sendData, { headers: headerData })
			
			if (response && response.data) {
				console.log("sendPushNotification sent!", response.data);
				resolve(true);
			} else {
				reject(`API/sendPushNotification (${user_uid}). Push notification appears to have sent with no response.`);
			}
    		*/
			
    	} catch (err) {
    		//console.log("ERROR (sendPushNotification)", err);
    		reject(err);
    	}
	});
}

export default sendPushNotification;