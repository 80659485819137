import React, { useState, useEffect } from 'react';
import firebase from '../../Firebase';

import './AssignModalClientCell.css';


function AssignModalClientCell(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
        
    }

    useEffect(() => {
        if (imgUrl === '') {
            getImage();
        }
    }, [props.client])

    function initialize() {
        getImage();
    }

    function getImage() {
        if (props.client === undefined || props.client.id === '') { return; }
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(props.client.id).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

	return (
		<div onClick={() => props.clientSelected()} className="assign-modal-client-cell">
			<div className="assign-modal-client-cell-image-container">
                <div className="client-card-image-container">
                    <img  
                        className="client-card-image" 
                        hidden={imgUrl === ''}
                        src={imgUrl} 
                        alt={`Image for ${props.client.first_name}`} />
                </div>
            </div>
            <div className="assign-modal-client-cell-text-container">
                <h4>{props.client.full_name}</h4>
            </div>
		</div>
	)
}

export default AssignModalClientCell;