import React, { useState, useEffect } from 'react';
import './GoalCard.css';

import '@material-ui/core';
import BarChart from '@material-ui/icons/BarChart';
import GoalChart from './GoalChart';


function GoalCard(props: any) {

    const [percentComplete, setPercentComplete] = useState(0);
    const [progressStatus, setProgressStatus] = useState<"none" | "behind" | "on_schedule" | "ahead">("none");
    const [initSet, setInitSet] = useState(false);

    if (!initSet) setInitSet(true);

    useEffect(() => {
        if (initSet) {
            generatePercentComplete()
        }
    }, [initSet])

    useEffect(() => {
        generatePercentComplete()
    }, [props.goalData])

    const morePressed = () => {
        props.openGoalsPressed()
    }

    const generatePercentComplete = () => {
        if (!props.goalData) return;

        let goalOverviewData = props.goalData.goal;
        let goalLogData = props.goalData.logs;

        if (!goalOverviewData || !goalLogData || goalLogData.length < 1) return;

        let metricTarget = goalOverviewData.target_metric ?? 0;
        let timeStart = goalOverviewData.starting_timestamp;
        let timeEnd = goalOverviewData.target_timestamp;
        let dt = timeEnd - timeStart;
        let m = 1 / dt;

        // Get first and latest logs
        let firstLog = goalLogData[0]
        let latestLog = goalLogData[goalLogData.length - 1];

        let firstLogMetric = firstLog ? firstLog.metric : 0;
        let lateststLogMetric = latestLog ? latestLog.metric : 0;

        let d_metric_target = Math.abs(metricTarget - firstLogMetric);
        let d_metric_achieved = Math.abs(metricTarget - lateststLogMetric);
        let percentCompleteTemp = 1 - (d_metric_achieved / d_metric_target);
        setPercentComplete(Math.floor(percentCompleteTemp * 100));

        // Check if above or behind schedule
        let progressBuffer = 0.02;
        let latestLogTime = latestLog.timestamp;
        let dt_latest = latestLogTime - timeStart;
        let targetProgress = m * dt_latest;
        let targetProgress_min = targetProgress - progressBuffer;
        let targetProgress_max = targetProgress + progressBuffer;
        // console.log("TARGET:", targetProgress)

        setProgressStatus(percentCompleteTemp <= targetProgress_min ? "behind" : percentCompleteTemp >= targetProgress_max ? "ahead" : "on_schedule");
    }

    const getProgressString = () => {
        return progressStatus === "none" ? "No Data" : progressStatus === "behind" ? "Behind Schedule" : progressStatus === "ahead" ? "Ahead of Schedule" : "On Track";
    }

    const getTargetDateString = () => {
        if (!props.goalData || !props.goalData.goal) return "";
        let timeEnd = props.goalData.goal.target_timestamp;
        return getDateString(timeEnd, true, true);
    }

    const getDateString = (timestamp: number, fullMonth: boolean, includeYear?: boolean) => {
        const moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const moStringsFull = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        let thisDate = new Date(timestamp);
        let thisDate_day = thisDate.getDate();
        let thisDate_mo_index = thisDate.getMonth();
        let thisDate_mo = fullMonth ? moStringsFull[thisDate_mo_index] : moStrings[thisDate_mo_index];
        let thisDate_yr = thisDate.getFullYear();

        return `${thisDate_mo} ${thisDate_day}${includeYear ? `, ${thisDate_yr}` : ''}`;
    }

    const getGoalString = () => {

        if (!props.goalData || !props.goalData.goal || !props.goalData.logs) return "";
        let goalID = props.goalData.goal.type;
        if (!goalID) return "";
        let goalStringMap: any = {
            "get-fit": { 
                action: "reach", 
                units: "wo/wk"
            },
            "loose-weight": { 
                action: "loose", 
                units: props.goalData.goal.target_units_kg ? "KGs" : "LBS",
            }, 
            "gain-weight":  { 
                action: "gain", 
                units: props.goalData.goal.target_units_kg ? "KGs" : "LBS",
            }, 
            "muscle-toning":  { 
                action: "cut to", 
                units: "BF%"
            }, 
            "body-building":  { 
                action: "reach", 
                units: props.goalData.goal.target_units_kg ? "KGs" : "LBS",
            }, 
            "power-lifting": { 
                action: "reach", 
                units: props.goalData.goal.target_units_kg ? "KGs" : "LBS",
            }, 
            "cardio-endurance": { 
                action: "reach", 
                units: "workouts per week"
            },
            "custom": {
                action: "hit",
                units: ""
            }
        };

        let metricTarget = props.goalData.goal.target_metric ?? 0;
        let firstLog = props.goalData.logs[0];
        let firstLogMetric = firstLog ? firstLog.metric : 0;
        let d_metric_target = Math.floor(Math.abs(metricTarget - firstLogMetric));

        return `${ goalStringMap[goalID].action } ${ d_metric_target } ${ goalStringMap[goalID].units }`
    }

	return (
		<div className={ "goal-card" }>
			<div className={ "goal-card-header" }>
                <div className={ "goal-card-header-icon-container" }>
                    <BarChart className={ "goal-card-header-icon" }/>
                </div>
                <h4>Goal Progress</h4>
                <div className={ "goal-card-header-more-button" } onClick={ () => morePressed() } hidden={ props.hideShowMore !== undefined && props.hideShowMore === true }>
                    <p>See more</p>
                </div>
            </div>
            <div className={ "goal-card-body-container" }>
                <div className={ "goal-card-body-content-contianer" }>
                    <div className={ "goal-card-body-status-container" }>
                        <h1>{ getProgressString() }</h1>
                        <h2>{ percentComplete }% Complete</h2>
                    </div>
                    <div className={ "goal-card-body-status-info-container" }>
                        <h4><span>{ props.clientName ?? "Your client" } is { getProgressString().toLowerCase() }</span> to { getGoalString() } by { getTargetDateString() }.</h4>
                        <p>{ props.hideShowMore !== undefined && props.hideShowMore === true ? `Scroll down to see all of ${props.clientName ?? "Your client" }'s logs towards their goal.` : `Click see more for a list of all ${ props.clientName ?? "Your client" }'s goal log data.` }</p>
                    </div>
                </div>
                
                <div className={ "goal-card-chart-container" }>
                    <GoalChart goalData={ props.goalData }/>
                </div>
            </div>
            <div className={ "goal-card-selector-container" }>
                <div className={ "goal-card-selector-demo" }></div>
            </div>
		</div>
	)
}

export default GoalCard;