import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
import firebase from '../../Firebase';

import './CopyFolderToClient.css';
import './RecordMessageModal.css'
import './CopyFolderModal.css';
import './AssignModal.css';

import Client_t from '../../Interfaces/Client_t';
import Folder_t from '../../Interfaces/Folder_t';
import Workout_t from '../../Interfaces/Workout_t';
import Exercise_t from '../../Interfaces/Exercise_t';
import ExerciseSeries_t from '../../Interfaces/ExerciseSeries_t';

import PlanFolderSelector from '../Base/PlanFolderSelector';
import AssignModalClientCell from '../Clients/AssignModalClientCell';
import AssignModalClientFolders from './AssignComponents/AssignModalClientFolders';

import '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForward from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';

function DuplicatePhase(props: any) {

    let initClient: Client_t = {first_name: '',last_name: '',full_name: '',id: '',isSelected: false};

    const [initSet, setInitSet] = useState(false);
    const [copyingFolder, setCopyingFolder] = useState<Folder_t | undefined>();
    const [selectedFolder, setSelectedFolder] = useState<Folder_t | undefined>();
    const [errorString, setErrorString] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedClient, setSelectedClient] = useState<Client_t>(initClient);
    const [imgUrl, setImgUrl] = useState('');


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
        if (props.information !== undefined && props.information.plan !== undefined) {
            // setCopyingFolder({ ...props.information.plan, type: "phase", isPlan: true } as Folder_t);
        }
    }

    useEffect(() => {
        if (props.information !== undefined && props.information.plan !== undefined) {
            // setCopyingFolder({ ...props.information.plan, type: "phase", isPlan: true } as Folder_t);
        }
    }, [props.information])




    function getImage(forID: string) {
        const storage = firebase.storage();
        //// console.log(props.client.id);
        storage.ref('profileImages').child(forID).child('profileImage/image.jpg').getDownloadURL()
           .then(fireBaseUrl => {
             setImgUrl(fireBaseUrl);
           })
           .catch(error => {
               //// console.log('No image found');
               //// console.log(error);
           })
    }

    function closePressed() {
        props.closeModal();
    }

    async function submit() {
        setIsLoading(true);

        
    }


	return (
		<div className="modal-background copy-folder-modal-background copy-folder-to-client-modal-background">
            <div className="new-element-modal-container copy-folder-modal-container copy-folder-to-client-modal-container">
                <div onClick={() => closePressed()} className="new-element-modal-floating-close"><Close style={{ fontSize: 20 }}/></div>
                <div className="new-element-modal-top copy-folder-modal-top">
                    <h3>Record a video for {selectedClient === undefined || selectedClient.first_name === "" ? "your Client" : selectedClient.first_name}</h3>
                    <p>Select an empty Phase below where you would like to duplicate "{copyingFolder === undefined ? "this Phase" : copyingFolder.title}" to.</p>
                    <div hidden={errorString === ""} className="copy-folder-modal-top-error">
                        <p>{errorString}</p>
                    </div>
                </div>
                <div hidden={isLoading} className="copy-folder-to-client-modal-body">
                    
                </div>
                <div hidden={!isLoading} className="copy-folder-to-client-modal-body copy-folder-to-client-modal-body-loading">
                    <div className="client-list-container-loading-spinner"/>
                    <p>Sending Video...</p>
                </div>
                <div className="copy-folder-modal-button-container">
                    <div hidden={isLoading} onClick={() => submit()} className={`copy-folder-modal-button ${selectedFolder === undefined || selectedFolder.id === "" ? 'copy-folder-modal-button-unselected' : ''}`}>
                        <p>Send Video</p>
                    </div>
                </div>

            </div>
			
		</div>
	)
}

export default DuplicatePhase;