import React from 'react';
import { IonLabel, IonInput, IonItem } from '@ionic/react';
import './DualSelector.css';

interface DualSelectorProps {
	optionAText: string;
	optionBText: string;
	optionChanged: any;
}

interface DualSelectorState {
	currentPosition: number;
}

class DualSelector extends React.Component<DualSelectorProps, DualSelectorState> {

	static defaultProps = {
		buttonText: "Next",
		callBack: null,
		isValid: true
	}
	constructor(props: any) {
		super(props);
		this.state = {currentPosition: 0};
	}
	buttonPressed(pressed: number) {
		if (pressed === this.state.currentPosition) { return; }
		this.setState({currentPosition: pressed});
		this.props.optionChanged(pressed);
	}
	render() {
		return (
			<div className="dual-selector-container">
				<div onClick={() => this.buttonPressed(0)} className={this.state.currentPosition === 0 ? "dual-selector-selected-container" : "dual-selector-unselected-container"}>
					<p>{this.props.optionAText}</p>
				</div>
				<div onClick={() => this.buttonPressed(1)} className={this.state.currentPosition === 1 ? "dual-selector-selected-container" : "dual-selector-unselected-container"}>
					<p>{this.props.optionBText}</p>
				</div>
			</div>
			
		)
	}
}

export default DualSelector;

/*
<div onClick={e => this.buttonPressed()} className={this.props.isValid? "standard-button-container" : "standard-button-container-unselected"}>
				<p>{this.props.buttonText}</p>
			</div>
*/