import React, { useState } from 'react';
import './Name.css';
import TextInput from '../../components/Base/TextInput';
import { IonReactRouter } from '@ionic/react-router';


function Password(props: any) {

  function textInputChanged(text: string) {
    let regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/;
    let passedRegEx = regEx.test(text);

    let isValid = passedRegEx;
    props.validation(isValid);

    // console.log(`${text},${isValid}`);

    if (isValid === true) {
      props.resultUpdated(text);
    }
  }

  return (
    <div className="header-container">
      <h1>Great! Next step,</h1>
      <h1>let's set up a password for your account.</h1>
      <p>Go ahead and enter your a password below. Make sure you take the following into account:</p>
      <p className="bullet-points">• Needs at least 8 characters</p>
      <p className="bullet-points">• One or more numbers</p>
      <p className="bullet-points">• One or more capital and small letters</p>
      <div>
        <TextInput hideText={true} placeholder="Password" input="" inputChanged={(e: string) => textInputChanged(e)}></TextInput>
        <p hidden={!props.showError} className="error-text">{ props.errorDesc }</p>
      </div>
   </div>
  )
}

export default Password;