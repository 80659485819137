import React, { useState, useEffect } from 'react';
import './NewWorkoutClientCard.css';
import firebase from '../../../Firebase';
import axios from 'axios';

import Client_t from '../../../Interfaces/Client_t';

import '@material-ui/core';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Accessibility from '@material-ui/icons/Accessibility';

function NewWorkoutClientCard(props: any) {

	let initClient: Client_t = {first_name:'',
								last_name:'',
								full_name:'',
								id:'', 
								isMetric: true, 
								gender: 'b', 
								birthdate: '00-00-0000',
								height: 0, 
								weight: 0,
								goalIndex: 0,
								experienceIndex: 0};

	const [client, setClient] = useState<Client_t>(initClient);
	const [imgUrl, setImgUrl] = useState('');
	const [initSet, setInitSet] = useState(false);
	const [userGoalIndex, setUserGoalIndex] = useState(0);
	const [prevWorkoutName, setPrevWorkoutName] = useState('No workouts logged')
	const [prevWorkoutDate, setPrevWorkoutDate] = useState('No workouts logged')
	const [userTags, setUserTags] = useState<string[]>([]);
	const [prevWorkoutTags, setPrevWorkoutTags] = useState<string[]>([]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

    	if (props.client != undefined) {
    		setClient(props.client);
    	}

        getImage();
        getUserPrefs();
        getLastWorkoutString();
    }

     useEffect(() => {
        setClient(props.client);
        initialize();
    }, [props.client]);

	function getImage() {
		const storage = firebase.storage();
		//// console.log(props.client.id);
		storage.ref('profileImages').child(props.client.id).child('profileImage/image.jpg').getDownloadURL()
       	.then(fireBaseUrl => {
         	setImgUrl(fireBaseUrl);
       	})
       	.catch(error => {
       		//// console.log('No image found');
       		//// console.log(error);
       	})
	}

	function getUserPrefs() {
		if (props.client.id === '') { return; }

		let database = firebase.database();
		let ref = database.ref(`user_pref/${props.client.id}`);
		ref.on('value', function(snapshot) {
			if (snapshot.exists() === true) {
				let data = snapshot.val();
				let experienceIndex = data.experience;
				let goalIndex = data.goal;

				setClient({...props.client, goalIndex: goalIndex, experienceIndex: experienceIndex});
				let workoutGoalString = getWorkoutGoalString(goalIndex);

				setUserTags(userTags.concat(workoutGoalString));
			}
		});
	}

	function getWorkoutGoalString(goalIndex: number) {
		let workoutGoals = ['Get Fit', 'Loose Weight', 'Gain Muscle', 'Muscle Toning', 'Body Building', 'Power Lifting']
		return workoutGoals[goalIndex];
	}

	function getClientSubtitleString() {
		// Determine age
		// TODO: Fix this shitty thing to work with Date() for iOS
		let dob = client.birthdate;
		let dob_dates = dob === undefined ? ["0", "0", "0"] : dob.split('-');
		let year = Number(dob_dates[2]);
		let age = 2020 - year;
		let ageString = age > 1000 ? '' : `${age} year old `;

		// Determine Gender
		let gender = client.gender;
		let genderString = gender === 'f' ? 'female' : (gender === 'm' ? 'male' : 'person');

		// Determine weight
		let weight = client.weight;
		let units = client.isMetric ? 'KGs' : 'LBS';

		return `${ageString}${genderString}, ${weight} ${units}`;
	}

	function getLastWorkoutString() {
		let sendData = {
			user_id: props.client.id,
			get_number: 1,
			starting_at: 0
		};

		axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/getUserHistory`,
		sendData,
		{ headers: {'Content-Type': 'application/json'} })
		.then(response => {
		 	let logData = response.data;
		 	let loggedWorkouts = response.data.logs;

		 	if (loggedWorkouts.length === 0) { 
		 		setPrevWorkoutName("No workouts logged");
		 		return;
		    }

		    let lastWorkoutObj = loggedWorkouts[0];
		    let workoutID = lastWorkoutObj.workout_id;

		    let database = firebase.database();
			let ref = database.ref(`workouts/${workoutID}`);
			ref.on('value', function(snapshot) {
				if (snapshot.exists() === true) {
					let workoutObj = snapshot.val();

					// Set previous workout title
					let title = workoutObj.title;
					setPrevWorkoutName(title);

					// console.log("WORKOUT OBJ:");
					// console.log(lastWorkoutObj);

					// Get previous workout date
					if (lastWorkoutObj.completed_date !== undefined && lastWorkoutObj.completed_date !== null && lastWorkoutObj.completed_date !== "") {
						let workoutDateTime = lastWorkoutObj.completed_date.split(' ');
						let workoutDates = workoutDateTime[0].split('-');
						let workoutTimes = workoutDateTime.length > 1 ? workoutDateTime[1].split(':') : ['0','0','0'];
						let moStrings = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

						let dateString = `${moStrings[Number(workoutDates[1] - 1)]} ${workoutDates[2]}, ${workoutDates[0]}`
						setPrevWorkoutDate(dateString);
					}

					// Determine previous workout muscle groups
					let muscle_groups: string[] = ["Abs","Arms","Back","Chest","Shoulders","Legs","Butt"];
					var muscleGroupTags_temp: string[] = [];
					let exercises = workoutObj.exercises;
					let exerciseKeys = Object.keys(exercises);
					for (var i = 0; i < exerciseKeys.length; i++) {
						let thisKey = exerciseKeys[i];
						let thisExercise = exercises[thisKey];

						let thisExerciseMuscles = thisExercise.muscles;
						let thisExerciseMusclesKeys = Object.keys(thisExerciseMuscles);

						for (var j = 0; j < thisExerciseMusclesKeys.length; j++) {
							let thisMuscleKey = thisExerciseMusclesKeys[j];
							let thisMuscle = thisExerciseMuscles[thisMuscleKey];
							let thisMuscleGroup = Number(thisMuscle.group);

							let thisMuscleString = muscle_groups[thisMuscleGroup];
							if (!muscleGroupTags_temp.includes(thisMuscleString)) {
								muscleGroupTags_temp.push(thisMuscleString);
							}
						}
					}
					setPrevWorkoutTags(prevWorkoutTags.concat(muscleGroupTags_temp));
				}
			});
		})
		.catch(e => {
			// console.log(e);
			return;
		})
	}

	function showLastWorkoutDetails() {

	}

	function getMusclesString() {
		if (prevWorkoutTags.length === 0) {
			return 'No workouts logged';
		}

		var temp = '';
		for (var i = 0; i < prevWorkoutTags.length; i++) {
			temp += prevWorkoutTags[i];
			temp += i === prevWorkoutTags.length - 1 ? '' : ', ';
		}

		return temp;
	}

	return (
		<div className="new-workout-client-card-container">
			<div className="new-workout-client-card-image-container">
				<div className="new-workout-client-profile-general-profile-circle">
					<img  
						className="new-workout-client-profile-general-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${client.first_name}`} />
				</div>
			</div>
			<div className="new-workout-client-name-container">
				<p>Selected Client</p>
				<h2>{client.full_name}</h2>
			</div>
			<div className="new-workout-client-info-container" onClick={() => showLastWorkoutDetails()}>
				<p>Last Workout</p>
				<h2>{prevWorkoutName}</h2>
			</div>
			<div className="new-workout-client-info-container" onClick={() => showLastWorkoutDetails()}>
				<p>Last Workout Date</p>
				<h2>{prevWorkoutDate}</h2>
			</div>
			<div className="new-workout-client-info-container" onClick={() => showLastWorkoutDetails()}>
				<p>Worked Muscles</p>
				<h2>{getMusclesString()}</h2>
			</div>
		</div>
	)
}

/*
<p>{getClientSubtitleString()}</p>


<div className="new-workout-client-card-image-container">
				<div className="new-workout-client-profile-general-profile-circle">
					<img  
						className="new-workout-client-profile-general-image" 
						hidden={imgUrl === ''}
						src={imgUrl} 
						alt={`Image for ${client.first_name}`} />
				</div>
			</div>
			<div className="new-workout-client-name-container">
				<p>Selected Client</p>
				<h2>{client.full_name}</h2>
				
				<div className="new-workout-client-name-tag-container">
					<LocalOffer className="new-workout-client-name-tag-icon" style={{ fontSize: 14 }}/>
					{userTags.map(item => (
						<div className="new-workout-client-name-tag-content">{item}</div>
					))}
				</div>
			</div>
			<div className="new-workout-client-info-container" onClick={() => showLastWorkoutDetails()}>
				<p>Last Workout</p>
				<h2>{prevWorkoutName}</h2>
				<div className="new-workout-client-info-tag-container">
					<Accessibility className="new-workout-client-info-tag-icon" style={{ fontSize: 14 }}/>
					{prevWorkoutTags.map(item => (
						<div className="new-workout-client-info-tag-content">{item}</div>
					))}
				</div>
			</div>
*/

export default NewWorkoutClientCard;